import isCommitHash from './isCommitHash';
import isProduction from './isProduction';
import { logMessageToSentry } from './logToSentry';

function handleMaybeHash(maybeHash: string): string | null | undefined {
  if (isCommitHash(maybeHash)) {
    return maybeHash;
  }

  if (isProduction()) {
    const message =
      'Failed to fetch revision.txt, cannot determine backend commit hash';

    // eslint-disable-next-line
    console.warn(message);
    logMessageToSentry(message);
  }

  return null;
}

/** In all browsers except for Firefox, body of the fetch response below can be
 * read in as a Uint8Array. This function converts that Uint8Array to a string,
 * chops off the newline if necessary, and returns the result if it is a SHA1
 * hash.
 */
export function getHashFromStream(
  readRevisionValue: Uint8Array,
): string | null | undefined {
  const maybeHash = String.fromCharCode
    .apply(null, readRevisionValue as any)
    .replace(/\n/gi, '');

  return handleMaybeHash(maybeHash);
}

/** In Firefox, the a fetch response has a text function that returns a string.
 * This function chops off the newline from that string and returns the result
 * if it is a SHA1 hash.
 */
function getHashFromText(revisionText: string): string | null | undefined {
  const maybeHash = revisionText.replace(/\n/gi, '');

  return handleMaybeHash(maybeHash);
}

/**
 * Fetches uncached revision.txt, which is only available in staging, and
 * returns commit hash if contents of file is a SHA1 string. In development,
 * the server responds with the index.html and this function returns null.
 */
async function fetchCommitHash(): Promise<string | null | undefined> {
  const revision = await fetch('/revision.txt', { cache: 'no-store' });
  if (revision.body) {
    const readRevision = await revision.body.getReader().read();
    const readRevisionValue = readRevision.value;
    if (readRevisionValue) {
      return getHashFromStream(readRevisionValue);
    }
  } else if (revision.text) {
    const revisionText = await revision.text();
    return getHashFromText(revisionText);
  }
  return null;
}

export default fetchCommitHash;
