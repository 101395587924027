import * as React from 'react';
import uuid from 'uuid/v4';
import cx from 'classnames';
import TextCell from '../textCell/TextCell';
import {
  mapTierFeePercentageByTierId,
  getKeyPath,
  generateProRataHandlers,
} from '../utils';
import { invariant, ux } from 'utils';
import { KeyPath } from 'lsredux';
import { DataGrid, Percentage, Money, DataGridInputProps } from 'components';
import { FeeType, FeeKindType, LoanTrancheTierType } from 'types';

type FeeRow = {
  Amount?: string;
  Percentage?: string;
  Tier?: string;
  id?: string;
};

type Props = {
  canEdit: boolean;
  fee: FeeType;
  feeIndex: string;
  feeKind: FeeKindType | null | undefined;
  isDisplayedFromModal?: boolean;
  replaceEntity: (keyPath: KeyPath, entity: FeeType) => void;
  tiers: ReadonlyArray<LoanTrancheTierType>;
  trancheIndex: string;
};

function PercentTextCell(value: string) {
  return (
    <Percentage className="feeAmountPercentageGrid__Readonly" value={value} />
  );
}

function MoneyTextCell(value: string) {
  return (
    <Money
      className="feeAmountPercentageGrid__Readonly"
      decimalPlaces={2}
      value={value}
    />
  );
}

const percentageEditor = (inputProps: DataGridInputProps<FeeRow, any>) => (
  <DataGrid.PercentageInput {...inputProps} />
);

const moneyEditor = (inputProps: DataGridInputProps<FeeRow, any>) => (
  <DataGrid.MoneyInput {...inputProps} />
);

class FeeRowDataGrid extends DataGrid<FeeRow, any> {}

class FeeAmountPercentageGrid extends React.Component<Props> {
  mutateAmount: (
    trancheKeyPath: KeyPath,
    fee: FeeType,
    newValue: string,
  ) => void;

  mutatePercent: (
    trancheKeyPath: KeyPath,
    fee: FeeType,
    newValue: string,
  ) => void;

  constructor(props: Props) {
    super(props);
    this.mutateAmount = generateProRataHandlers(
      'AMOUNT',
      props.replaceEntity,
    ).bind(this);
    this.mutatePercent = generateProRataHandlers(
      'PERCENT',
      props.replaceEntity,
    ).bind(this);
  }

  static renderTierColumnHeader = () => (
    <div className="feeAmountPercentageGrid__ColumnHeader--Tier">Tier</div>
  );

  static renderBlankTierColumnHeader = () => (
    <div className="feeAmountPercentageGrid__ColumnHeader--Tier" />
  );

  buildFeeColumns = () => {
    const { fee, feeKind, isDisplayedFromModal, canEdit } = this.props;
    invariant(fee, 'fee was not found!');
    invariant(feeKind, 'feeKind of fee was not found!');
    const feeColumns = [];

    const hasAmountAndPercentage = feeKind.hasAmount && feeKind.hasPercentage;
    const amountClassName = cx(
      ux(isDisplayedFromModal, 'feeAmountPercentageGrid__ColumnHeader--Modal'),
      ux(
        hasAmountAndPercentage && isDisplayedFromModal,
        'feeAmountPercentageGrid__ColumnHeader--Amount',
      ),
    );
    const percentClassName = cx(
      ux(isDisplayedFromModal, 'feeAmountPercentageGrid__ColumnHeader--Modal'),
      ux(
        hasAmountAndPercentage && isDisplayedFromModal,
        'feeAmountPercentageGrid__ColumnHeader--Percentage',
      ),
    );

    if (fee.sharing === 'NON_PRO_RATA') {
      feeColumns.push({
        className: 'feeAmountPercentageGrid__ColumnHeader--Tier',
        columnName: 'Tier',
        displayName: 'Tier',
        customHeaderCell: FeeAmountPercentageGrid.renderTierColumnHeader,
        renderer: TextCell,
        readOnly: true,
      });
    } else if (!isDisplayedFromModal) {
      feeColumns.push({
        className: 'feeAmountPercentageGrid__ColumnHeader--Tier',
        columnName: 'Tier',
        displayName: 'Tier',
        customHeaderCell: FeeAmountPercentageGrid.renderBlankTierColumnHeader,
        readOnly: true,
      });
    }

    if (feeKind.hasAmount) {
      feeColumns.push({
        className: amountClassName,
        columnName: 'Amount',
        onValueChange: this.handleAmountChange,
        displayName: 'Amount',
        editor: moneyEditor,
        readOnly: !canEdit,
        renderer: MoneyTextCell,
      });
    }

    if (feeKind.hasPercentage) {
      feeColumns.push({
        className: percentClassName,
        columnName: 'Percentage',
        onValueChange: this.handlePercentChange,
        displayName: 'Percentage',
        editor: percentageEditor,
        readOnly: !canEdit,
        renderer: PercentTextCell,
      });
    }
    return feeColumns;
  };

  handleTierPercentChange = (newValue: string, tier: string) => {
    const { replaceEntity, fee, trancheIndex, feeIndex, tiers } = this.props;
    const { tierfeepercentageSet } = fee;
    const tierOfFeeTierBeingEdited = tiers.filter(
      loanTrancheTier => String(loanTrancheTier.tierNumber) === String(tier),
    )[0];
    const tierfeepercentageSetkeyPath = [
      ...getKeyPath(trancheIndex, feeIndex),
      'tierfeepercentageSet',
    ];

    invariant(
      tierOfFeeTierBeingEdited,
      'Tier of TierFeePercentage was not found',
    );

    const tierfeepercentageToEdit = (tierfeepercentageSet || []).filter(
      tierFeePercentage => {
        if (tierFeePercentage.loanTrancheTier) {
          return (
            tierFeePercentage.loanTrancheTier.id === tierOfFeeTierBeingEdited.id
          );
        }
        return false;
      },
    )[0];

    if (tierfeepercentageToEdit) {
      //  In this case we are using replace entity on a tierfeepercentage item, instead of a fee item like in the type
      replaceEntity(tierfeepercentageSetkeyPath, {
        ...tierfeepercentageToEdit,
        appliedRate: newValue,
      } as any);
    } else {
      const trancheKeyPath = getKeyPath(trancheIndex);

      const newtierfeepercentageSet = [
        ...(tierfeepercentageSet || []),
        {
          __typename: 'TierFeePercentageType',
          id: uuid(),
          loanTrancheTier: {
            id: tierOfFeeTierBeingEdited.id,
            __typename: tierOfFeeTierBeingEdited.__typename,
          },
          loanTrancheTierId: tierOfFeeTierBeingEdited.id,
          appliedRate: newValue,
        },
      ];
      replaceEntity(trancheKeyPath, {
        ...fee,
        // need to flag the loanTrancheTier item as isDirty
        tierfeepercentageSet: newtierfeepercentageSet as any,
      });
    }
  };

  buildFeeRows = () => {
    const { fee, tiers } = this.props;
    if (fee.sharing === 'NON_PRO_RATA') {
      if (tiers && tiers.length > 0) {
        const tierfeepercentageSet = fee.tierfeepercentageSet || [];
        const tierFeePercentageMap = mapTierFeePercentageByTierId(
          tierfeepercentageSet,
        );

        return tiers.map<FeeRow>(tier => ({
          Tier: tier.tierNumber || '',
          Percentage: tierFeePercentageMap.get(String(tier.id)) || '',
        }));
      }
      return [];
    }

    const { feecalculationrule } = fee;
    const amount = feecalculationrule ? feecalculationrule.amount : '';
    const percentage = feecalculationrule ? feecalculationrule.appliedRate : '';

    return [
      {
        id: 'None',
        Amount: amount || '',
        Percentage: percentage || '',
      },
    ];
  };

  handleAmountChange = (rowKey: string, newValue: string) => {
    const { fee, trancheIndex } = this.props;
    const trancheKeyPath = getKeyPath(trancheIndex);
    this.mutateAmount(trancheKeyPath, fee, newValue);
  };

  handlePercentChange = (rowKey: string, newValue: string) => {
    if (newValue && newValue !== '0') {
      const { fee, trancheIndex } = this.props;

      if (fee.sharing === 'NON_PRO_RATA') {
        this.handleTierPercentChange(newValue, rowKey);
      } else {
        const trancheKeyPath = getKeyPath(trancheIndex);
        this.mutatePercent(trancheKeyPath, fee, newValue);
      }
    }
  };

  render() {
    const { fee } = this.props;

    return (
      <FeeRowDataGrid
        borderlessHeader
        columns={this.buildFeeColumns()}
        rowKey={fee.sharing === 'NON_PRO_RATA' ? 'Tier' : 'id'}
        rows={this.buildFeeRows()}
      />
    );
  }
}

export default FeeAmountPercentageGrid;
