import { PublicInvitationType } from 'types';

/* eslint-disable import/prefer-default-export */

/**
 * If the invite contains an institutionName, assume the user is joining an institution
 * @param {*} e
 */
export function isJoiningInstitution(e: PublicInvitationType): boolean {
  return Boolean(e.institutionName);
}
