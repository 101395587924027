import { compose } from 'recompose';
import { connect } from 'react-redux';
import FolderShareModal from './FolderShareModal';
import { withRouteParams, RouteParams } from 'routing';
import {
  RolePermissionMutationMethods,
  FolderOrganizationFolderRolesQuery,
  DealOrganizationDealRolesQuery,
  MyUserQuery,
} from 'lsgql';

const mapStateToProps = () => ({
  ...RolePermissionMutationMethods,
});

export default compose(
  withRouteParams([RouteParams.dealId]),
  DealOrganizationDealRolesQuery,
  MyUserQuery,
  FolderOrganizationFolderRolesQuery,
  connect(mapStateToProps, null),
)(FolderShareModal);
