import { gql } from '@apollo/client';
import { CalendarItemCategoryShallowFields } from '../fragments';

import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { CalendarItemCategoryType } from 'types';

const TaskCategoriesQueryDefinition = gql`
  query TaskCategories {
    taskCategories {
      ...CalendarItemCategoryShallowFields
    }
  }
  ${CalendarItemCategoryShallowFields}
`;

const TaskCategoriesQuery = standardQueryBuilder(
  TaskCategoriesQueryDefinition,
  {
    typename: 'TaskCategories',
    results: (data: {
      taskCategories: Array<CalendarItemCategoryType> | null | undefined;
    }) => {
      const { taskCategories = [] } = data;
      return { taskCategories };
    },
  },
);

export default TaskCategoriesQuery;
