const pdfContentType = 'application/pdf';
const octetContentType = 'application/octet-stream';
const pdfExtension = '.pdf';

/**
 * Identify a PDF file based on filename or content type
 *
 * @param {*} name File name (e.g. somefile.pdf)
 * @param {*} contentType  (e.g. 'application/pdf, application/octet-stream)
 */
const isPdf: (name?: string | null, contentType?: string | null) => boolean = (
  name?: string | null,
  contentType?: string | null,
) => {
  if (contentType !== null && contentType !== undefined) {
    if (contentType === pdfContentType) {
      return true;
    }
    if (name !== null && name !== undefined) {
      if (contentType === octetContentType && name.endsWith(pdfExtension)) {
        return true;
      }
    }
  }
  return false;
};

export default isPdf;
