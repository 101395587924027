import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  changePage,
  changeScale,
  changeRotations,
} from '../../redux/actions/termsheet';
import { initialPdfProps } from '../../redux/reducer/presentational/termsheet';
import PDFViewer from './LoadablePdfViewer';
import { withLoadingIndicator } from 'components';
import { ReduxDirectory } from 'lsredux';
import { DocumentExpiringUrlQuery } from 'lsgql';

// import PDFViewer from './PdfViewer';

const mapStateToProps = (state, props) => {
  const keyPath = [...ReduxDirectory.TermSheetPdfKeyPath, props.id];
  return state.getIn(keyPath)
    ? {
        ...initialPdfProps,
        ...state.getIn(keyPath).toJS(),
        renderError: props.renderError,
      }
    : {
        ...initialPdfProps,
        renderError: props.renderError,
      };
};

type OwnProps = {
  fileUri: string;
  id: string;
  renderError: () => React.ReactNode;
};

export default compose(
  connect(mapStateToProps, {
    changePage,
    changeScale,
    changeRotations,
  }),
  DocumentExpiringUrlQuery,
  withLoadingIndicator('loading'),
)(PDFViewer);
