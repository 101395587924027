import uuid from 'uuid/v4';
import { PaymentRecordLenderMutationMethods } from '../../../graphql/mutations';
import { createForm } from '../../genericForms';
import Directory from '../../directory';
import { PaymentRecordType } from 'types';

export type PaymentRecordLenderTypeWithPrefillFlag = PaymentRecordType & {
  wasDataPrefilled: boolean;
};

const initialPaymentRecordLender: PaymentRecordType = {
  id: uuid(),
  __typename: 'PaymentRecordType',
  lenderAmount: '',
  lenderDate: '',
  lenderReference: '',
  borrowerDate: '',
  borrowerNotes: '',
  borrowerReference: '',
  lenderNotes: '',
  paymentDue: {
    __typename: 'PaymentDueType',
    id: uuid(),
    principalAmount: '',
    date: '',
  },
};

const lifecycleMethods = {
  mutations: {
    ...PaymentRecordLenderMutationMethods,
  },
  validators: {},
};

function addPrefillFlag(
  input: PaymentRecordType,
): PaymentRecordLenderTypeWithPrefillFlag {
  if (!(input.lenderAmount && input.lenderDate)) {
    return {
      ...input,
      wasDataPrefilled: true,
      __typename: 'PaymentRecordLender',
    } as any;
  }
  return {
    ...input,
    wasDataPrefilled: false,
    __typename: 'PaymentRecordLender',
  } as any;
}

const paymentRecordLenderRedux = createForm<
  PaymentRecordLenderTypeWithPrefillFlag,
  PaymentRecordType
>(
  Directory.PaymentRecordLenderKey,
  initialPaymentRecordLender,
  lifecycleMethods,
  {
    enableAutoSave: false,
    dataConverter: addPrefillFlag,
  },
);

export default paymentRecordLenderRedux;
