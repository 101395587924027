import isEmpty from 'lodash/isEmpty';

import { FormReduxState } from '../../redux/genericForms/types';

import { MutationMethod, FetchMethod } from '../types';
import { DispatchResponse, BatchEntry } from './types';
import { InputScrubberMap } from './validationDispatch';
import deleteMutationBuilder from './deleteMutationBuilder';
import mutationBuilder from './mutationBuilder';
import mutationTreeExecutor from './mutationTreeExecutor';
import validateObjectTree from './utils/validateObjectTree';
import { invariant } from 'utils';
import {
  BaseType,
  ObjectTypeNames,
  ObjectBaseTypeNames,
  ID,
  ValidationType,
} from 'types';

export const typeNameField = '__typename';

/**
 * Deeply examine an object and generate a tree of GraphQL mutation operations.  The resulting tree is
 * provided as Promise chain, executing in order, passing the result of one operation into the next.
 * Once the tree has successfully executed, our changes have been fully applied to the backend.
 *
 * As an example: Object Id's are generated in the backend, so if two objects have Parent -> Child relationship, represented as:
 *
 * {
 *    id: '',
 *    __typename: 'ObjectA'
 *    child: { id: '', __typename: 'ObjectB' }
 * }
 *
 * This method will created a Promise to save ObjectA, that will pass the mutation response into the ObjectB promise, allowing the
 * id generated for ObjectA to be applied to ObjectB prior to save
 *
 * @param {*} rootObject
 * @param {*} props
 * @param {*} pendingDeletes
 */
async function mutationsDispatch<T extends BaseType>(
  formData: FormReduxState<T>,
  enableStrictValidation: boolean,
  mutationMethods: {
    [key: string]: MutationMethod<any, any>;
  },
  fetchMethod: FetchMethod<T>,
  validators?: Partial<
    {
      [key in ObjectBaseTypeNames]: (arg0: ID) => Promise<ValidationType>;
    }
  >,
  propertyWhitelist?: Partial<
    {
      [key in ObjectBaseTypeNames]: Set<string>;
    }
  >,
  inputScrubbers?: InputScrubberMap,
): Promise<DispatchResponse<T>> {
  invariant(formData, 'formData is required');
  invariant(
    formData.isDirty,
    'mutationsDispatch should not be invoke for isDirty === false',
  );
  invariant(
    formData && !isEmpty(formData) && !isEmpty(formData.data),
    'Expected formData',
  );
  invariant(fetchMethod, 'mutationsDispatch expects a fetch method');
  invariant(
    mutationMethods && !isEmpty(mutationMethods),
    'mutationsDispatch requires mutationMethods',
  );

  invariant(formData, 'formData must be defined');
  invariant(formData.data, 'formData.data must be defined');
  invariant(formData.data.id, 'data.id must be provided');

  // Enforce that the object tree is valid
  validateObjectTree<T>(formData.data);

  const rootObjectTypeName: ObjectTypeNames = formData.data[typeNameField];

  // get the delete mutation batch
  const deleteMutations: Array<() => Promise<
    DispatchResponse<any>
  >> = deleteMutationBuilder(mutationMethods, formData.pendingDelete);

  // recursively build the promise tree.  This creates a tree of mutation
  // methods arranged in a tree by dependency.  When this method is complete
  // all changes have been successfully applied to the backend
  const mutationTree: BatchEntry = mutationBuilder(
    formData.data,
    mutationMethods,
    formData.cleanData,
    propertyWhitelist,
  );

  const mutationResult: DispatchResponse<T> = await mutationTreeExecutor(
    deleteMutations,
    mutationTree,
    fetchMethod,
    rootObjectTypeName,
    formData.data.id,
    enableStrictValidation,
    validators,
    inputScrubbers,
  );

  return mutationResult;
}

export default mutationsDispatch;
