import * as React from 'react';
import { useParams } from 'react-router-dom';
import { RouteParamsType } from './tokens';

/**
 * Extract `params` from the current route and inject as props into the Wrapped component
 * e.g. if route contains 'dealId', the wrapped component will have `this.props.dealId`
 * @param {*} params
 */
export default function withRouteParams(
  requestedParams: Array<RouteParamsType>,
) {
  function extractParams(params) {
    return requestedParams.reduce((acc, param) => {
      const value = params[param] ?? '';
      return { ...acc, [param]: value };
    }, {});
  }

  return function withRouteParamsHoC(
    WrappedComponent: React.ComponentType<any>,
  ) {
    return function WithRouteParams(props: any) {
      const params = useParams();
      const routeParams = extractParams(params);

      return <WrappedComponent {...props} {...routeParams} />;
    };
  };
}
