import * as React from 'react';

const isMouseOverElement = (
  elem: HTMLElement,
  e: React.MouseEvent,
): boolean => {
  if (!elem || !e) return false;

  /*
  This method is invoked excessively, lets avoid destructuring so
  as not excessively create vars as well
  const { pageY, pageX } = e;
  const { left, right, bottom, top } = elem.getBoundingClientRect();
  */
  const rect: ClientRect = elem.getBoundingClientRect();

  return (
    e.clientX >= rect.left &&
    e.clientX <= rect.right &&
    e.clientY >= rect.top &&
    e.clientY <= rect.bottom
  );
};

export default isMouseOverElement;
