import React, { useState } from 'react';
import { WorkflowHelpBox } from '../workflowList';
import { HelpBoxCallbackType } from '../workflowList/WorkflowHelpBox';
import { CalculationHelpBox } from './partials';
import { ConfirmSwitchModal } from './modals';
import { getCalculatedValues } from './utils';
import { HelpBoxType, ForgivenessFormProps } from './types';
import StandardForm from './StandardForm';
import EzForm from './EzForm';
import SForm from './SForm';
import { RouteTable } from 'routing';
import { Button, RouteTo } from 'components';
import './ForgivenessCalculationForm.scss';

const TypeName = 'SbaPppForgivenessCalculation';

function ForgivenessCalculationForm(props: ForgivenessFormProps) {
  const { data, disabled } = props;
  const { sbaPppForgivenessWorkflow } = data;
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  if (!sbaPppForgivenessWorkflow) {
    return null;
  }

  const calculatedValues = getCalculatedValues(data);

  const { sbapppforgivenesscalculation } = sbaPppForgivenessWorkflow;

  const {
    noFteReduction,
    safeHarbor1HasBeenMetFteReduction,
    safeHarbor2HasBeenMetFteReduction,
    useEzForm,
  } = sbapppforgivenesscalculation;

  const anySafeHarbor =
    noFteReduction ||
    safeHarbor1HasBeenMetFteReduction ||
    safeHarbor2HasBeenMetFteReduction;

  const updatedProps = {
    ...props,
    data: {
      sbaPppForgivenessWorkflow,
      ...sbapppforgivenesscalculation,
      ...calculatedValues,
    },
  };

  const handleChange = (
    value:
      | (string | null | undefined)
      | (boolean | null | undefined)
      | (Array<any> | null | undefined),
    fieldId: string,
  ) => {
    const path = [
      'sbaPppForgivenessWorkflow',
      'sbapppforgivenesscalculation',
      fieldId,
    ];
    props.mutateProperty(value, path);
  };

  const handleSwitchForms = (toEzForm: boolean) => () => {
    if (toEzForm) {
      handleChange(calculatedValues.payrollCostsCalculated, 'payrollCosts');
      handleChange(null, 'cashCompensationLte100');
      handleChange(null, 'averageFteLte100');
      handleChange(null, 'salaryHourlyWageReductionLte100');
      handleChange(null, 'cashCompensationGt100');
      handleChange(null, 'averageFteGt100');
      handleChange(null, 'healthInsuranceContributions');
      handleChange(null, 'retirementPlanContributions');
    } else {
      handleChange(null, 'payrollCosts');
    }
    handleChange(toEzForm, 'useEzForm');
    setIsConfirmModalOpen(false);
  };

  const confirmSwitchForms = () => {
    setIsConfirmModalOpen(true);
  };

  const renderHelpBox = (
    id: HelpBoxType,
    focusId?: HelpBoxType,
    callback?: HelpBoxCallbackType,
  ) => {
    if (focusId === id || focusId === undefined) {
      return (
        <WorkflowHelpBox header={CalculationHelpBox[id].header}>
          {callback
            ? CalculationHelpBox[id].control(callback)
            : CalculationHelpBox[id].body}
        </WorkflowHelpBox>
      );
    }
  };

  const renderCtaButtons = () => (
    <div className="CtaButtons__Wrapper">
      <Button
        disabled={disabled}
        label="Save Forgiveness Calculation"
        onClick={props.onSave}
      />
      <Button.Secondary
        as={RouteTo}
        label="Next: Documentation"
        to={RouteTable.deal.toSbaPppForgivenessWorkflowDocumentation(data.id)}
      />
    </div>
  );

  if (sbaPppForgivenessWorkflow.isSFormEligible) {
    return (
      <SForm
        {...updatedProps}
        TypeName={TypeName}
        calculatedValues={calculatedValues}
        disabled={disabled}
        onChange={handleChange}
        renderCtaButtons={renderCtaButtons}
        renderHelpBox={renderHelpBox}
      />
    );
  }
  return (
    <>
      {useEzForm ? (
        <EzForm
          {...updatedProps}
          TypeName={TypeName}
          calculatedValues={calculatedValues}
          onChange={handleChange}
          onSwitchForms={confirmSwitchForms}
          renderCtaButtons={renderCtaButtons}
          renderHelpBox={renderHelpBox}
        />
      ) : (
        <StandardForm
          {...updatedProps}
          TypeName={TypeName}
          anySafeHarbor={anySafeHarbor}
          calculatedValues={calculatedValues}
          onChange={handleChange}
          onSwitchForms={confirmSwitchForms}
          renderCtaButtons={renderCtaButtons}
          renderHelpBox={renderHelpBox}
        />
      )}
      <ConfirmSwitchModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={handleSwitchForms(!useEzForm)}
        toEzForm={!useEzForm}
      />
    </>
  );
}

export default ForgivenessCalculationForm;
