import { mutationBuilder } from './builders';
import {
  CreateLoanTrancheGuarantorMutationDefinition,
  SetLoanTrancheGuarantorMutationDefinition,
  DeleteLoanTrancheGuarantorMutationDefinition,
} from './definitions';
import { LoanTrancheGuarantorInput, LoanTrancheGuarantorType, ID } from 'types';

const LoanTrancheGuarantorMutationMethods = {
  createLoanTrancheGuarantor: mutationBuilder<
    LoanTrancheGuarantorInput,
    LoanTrancheGuarantorType
  >(CreateLoanTrancheGuarantorMutationDefinition, {
    build: mutate => loanTrancheGuarantorData =>
      mutate({ variables: { loanTrancheGuarantorData } }),
  }),
  setLoanTrancheGuarantor: mutationBuilder<
    LoanTrancheGuarantorInput,
    LoanTrancheGuarantorType
  >(SetLoanTrancheGuarantorMutationDefinition, {
    build: mutate => loanTrancheGuarantorData =>
      mutate({ variables: { loanTrancheGuarantorData } }),
  }),
  deleteLoanTrancheGuarantor: mutationBuilder<ID, void>(
    DeleteLoanTrancheGuarantorMutationDefinition,
    {
      build: mutate => (id: string) => mutate({ variables: { id } }),
    },
  ),
};

export default LoanTrancheGuarantorMutationMethods;
