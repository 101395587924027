import React, { useState, useEffect } from 'react';
import noop from 'lodash/noop';
import { Form, Checkbox, TextLink, RouteTo } from 'components';
import { FormFieldsType } from 'types';
import { RouteTable } from 'routing';

const MAX_SBA_LOAN = 10000000;

const fields: FormFieldsType = {
  averageMonthlyPayroll: {
    id: 'averageMonthlyPayroll',
    propertyName: 'averageMonthlyPayroll',
    fieldName: 'Average Monthly Payroll',
    width: '158px',
    required: true,
  },
  eidlAmount: {
    id: 'eidlAmount',
    propertyName: 'eidlAmount',
    fieldName: 'EIDL Value (Minus Advance)',
    width: '158px',
  },
  loanAmount: {
    id: 'loanAmount',
    propertyName: 'loanAmount',
    fieldName: 'Loan Amount',
    width: '158px',
    disabled: true,
  },
  numberOfJobs: {
    id: 'numberOfJobs',
    propertyName: 'numberOfJobs',
    fieldName: 'Number of Employees',
    width: '158px',
    required: true,
  },
  purposeOfLoan: {
    id: 'purposeOfLoan',
    propertyName: 'purposeOfLoan',
    fieldName: 'Purpose of the Loan (check all that apply):',
    required: true,
    options: [
      { label: 'Payroll', id: 'PAYROLL' },
      { label: 'Lease / Mortgage Interest', id: 'RENT_MORTGAGE_INTEREST' },
      { label: 'Utilities', id: 'UTILITIES' },
      { label: 'Other', id: 'OTHER' },
    ],
  },
  otherReason: {
    id: 'otherReason',
    propertyName: 'otherReason',
    fieldName: 'Please explain other purpose',
    width: '332px',
  },
};

const SbaPppLoanFinancials = 'SbaPppLoanFinancials';

function LoanFinancials(props: any) {
  const { data } = props;
  const [hasEIDL, setHasEIDL] = useState(Boolean(Number(data.eidlAmount)));

  const averageMonthlyPayroll = Number(data.averageMonthlyPayroll) || 0;
  const eidlAmount = Number(data.eidlAmount) || 0;
  const loanAmount = averageMonthlyPayroll * 2.5 + eidlAmount;
  const displayedLoanAmount =
    loanAmount === 0 ? '-' : Math.min(loanAmount, MAX_SBA_LOAN).toString();

  useEffect(() => {
    if (Number(data.eidlAmount)) setHasEIDL(true);
  }, [data.eidlAmount]);

  function handleCheckboxChange() {
    if (hasEIDL) props.mutateProperty('0', 'eidlAmount');
    setHasEIDL(!hasEIDL);
  }

  return (
    <div className="LoanAmount">
      <TextLink.External
        as={RouteTo}
        label="Instructions"
        target="newTab"
        to={RouteTable.application.toSbaPppApplicationInstructions}
      />

      <Checkbox
        checked={hasEIDL}
        disabled={props.disabled}
        disabledReadable={props.disabled}
        id="hasEidl"
        label="Applicant is refinancing an Economic Injury Disaster Loan (EIDL)"
        onChange={handleCheckboxChange}
      />
      <Form.Group className="baseline">
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.averageMonthlyPayroll,
            onChange: props.mutateProperty,
          },
          props,
          SbaPppLoanFinancials,
          data.id,
        )}
        {hasEIDL &&
          Form.FieldRenderer(
            Form.Money,
            { ...fields.eidlAmount, onChange: props.mutateProperty },
            props,
            SbaPppLoanFinancials,
            data.id,
          )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.loanAmount,
            value: displayedLoanAmount,
            helpText: `2.5 x Average Monthly Payroll ${
              hasEIDL ? '+ EIDL Value' : ''
            }`,
            error:
              loanAmount > MAX_SBA_LOAN && 'Maximum Loan Amount is $10,000,000',
            onChange: noop,
            disabled: true,
            disabledReadable: true,
          },
          props,
          SbaPppLoanFinancials,
          data.id,
        )}
      </Form.Group>
      {Form.FieldRenderer(
        Form.Numeric,
        {
          ...fields.numberOfJobs,
          onChange: props.mutateProperty,
          allowLeadingZeroes: false,
        },
        props,
        SbaPppLoanFinancials,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.CheckboxGroup,
        { ...fields.purposeOfLoan, onChange: props.mutateProperty },
        props,
        SbaPppLoanFinancials,
        data.id,
      )}
      {data.purposeOfLoan?.includes('OTHER') &&
        Form.FieldRenderer(
          Form.Input,
          {
            ...fields.otherReason,
            onChange: props.mutateProperty,
            required: true,
          },
          props,
          SbaPppLoanFinancials,
          data.id,
        )}
    </div>
  );
}

export default LoanFinancials;
