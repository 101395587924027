import * as React from 'react';
import moment from 'moment';
import { noop } from 'lodash';
import { Form } from 'components';

export type SelectOption = {
  disabled?: boolean;
  text: string;
  value: string;
};

type Props = {
  fromDate: string | null | undefined;
  onFromDateChange: (value: string | null | undefined) => void;
  onToDateChange: (value: string | null | undefined) => void;
  onTrancheChange: (value: string | null | undefined) => void;
  toDate: string | null | undefined;
  trancheId: string;
  trancheOptions: Array<SelectOption>;
};

class PaymentFilterHeader extends React.Component<Props> {
  handleChangeFromDate = (value: string | null | undefined) => {
    const isOtherNull =
      this.props.toDate === null || this.props.toDate === undefined;

    if (value === null || value === undefined) {
      this.props.onFromDateChange(null);
    } else if (Form.Calendar.isValidDate(value)) {
      const { toDate: toDateString, onFromDateChange } = this.props;
      if (isOtherNull) {
        onFromDateChange(value);
      } else {
        const toDate = moment(toDateString);
        const fromDate = moment(value);
        if (fromDate.isSameOrBefore(toDate, 'day')) {
          onFromDateChange(value);
        }
      }
    }
  };

  handleChangeToDate = (value: string | null | undefined) => {
    const isOtherNull =
      this.props.fromDate === null || this.props.fromDate === undefined;

    if (value === null || value === undefined) {
      this.props.onToDateChange(null);
    } else if (Form.Calendar.isValidDate(value)) {
      const { fromDate: fromDateString, onToDateChange } = this.props;
      if (isOtherNull) {
        onToDateChange(value);
      } else {
        const toDate = moment(value);
        const fromDate = moment(fromDateString);
        if (toDate.isSameOrAfter(fromDate, 'day')) {
          onToDateChange(value);
        }
      }
    }
  };

  render() {
    const {
      fromDate,
      toDate,
      trancheId,
      onTrancheChange,
      trancheOptions,
    } = this.props;
    return (
      <Form id="paymentFilterHeader" onSubmit={noop}>
        <Form.Group className="paymentFilterHeader">
          <Form.Calendar
            allowNull
            fieldName="From Date"
            id="paymentFromDate"
            onChange={this.handleChangeFromDate}
            propertyName="fromDate"
            value={fromDate}
            width="four"
          />
          <Form.Calendar
            allowNull
            fieldName="To Date"
            id="paymentToDate"
            onChange={this.handleChangeToDate}
            propertyName="toDate"
            value={toDate}
            width="four"
          />
          <Form.Select
            allowEmpty={false}
            fieldName="Tranche"
            id="paymentTranche"
            onChange={onTrancheChange}
            options={trancheOptions}
            propertyName="trancheId"
            value={trancheId}
            width="four"
          />
        </Form.Group>
      </Form>
    );
  }
}

export default PaymentFilterHeader;
