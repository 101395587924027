import * as React from 'react';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router-dom';
import MenuItem from './menuItems/MenuItem';
import SecureMenuItem from './menuItems/SecureMenuItem';
import PortfolioMenuItem from './menuItems/PortfolioMenuItem';
import { DealQuery, SbaPppForgivenessWorkflowQuery, MyUserQuery } from 'lsgql';
import { FontAwesomeIconTypes, Divider } from 'components';
import { RouteTable } from 'routing/RouteTable';
import {
  DealPerspectivePermissions,
  DealPerspectivePermissionSets,
} from 'security';
import { ID } from 'types';
import withRouteParams from 'routing/withRouteParams';
import { RouteParams } from 'routing/index';
import {
  SbaPppForgivenessWorkflowState,
  SbaPppForgivenessWorkflowType,
  MyUserType,
} from 'types/backend';
import GoBackMenuItem from './menuItems/GoBackMenuItem';

type Props = RouteComponentProps & {
  dealId: ID;
  sbaPppForgivenessWorkflow: SbaPppForgivenessWorkflowType;
  user: MyUserType;
};

const forgivenessSbaFinalStates: SbaPppForgivenessWorkflowState[] = [
  'SBA_PAYMENT_CONFIRMED',
  // Added during a Forgiveness Shutdown conversation
  'SBA_FULLY_APPROVED',
  'SBA_PARTIALLY_APPROVED',
  'SBA_NOT_APPROVED',
  'SBA_PAYMENT_SENT',
  'SBA_PAYMENT_FAILED',
];

const peopleIcon: FontAwesomeIconTypes = ['fal', 'users'];
const dealTermsIcon: FontAwesomeIconTypes = ['fal', 'file-alt'];
const fileIcon: FontAwesomeIconTypes = ['fal', 'file-alt'];
const databaseIcon: FontAwesomeIconTypes = ['fal', 'database'];
const calendarIcon: FontAwesomeIconTypes = ['fal', 'calendar-alt'];
const moneyCheckIcon: FontAwesomeIconTypes = ['fal', 'money-check-alt'];
const moneyCheckEditIcon: FontAwesomeIconTypes = [
  'fal',
  'money-check-edit-alt',
];
const newspaperIcon: FontAwesomeIconTypes = ['fal', 'newspaper'];
const checkCircleIcon: FontAwesomeIconTypes = ['fal', 'check-circle'];
const chartBarIcon: FontAwesomeIconTypes = ['fal', 'chart-bar'];
const homeIcon: FontAwesomeIconTypes = ['fal', 'home'];
const applicationIcon: FontAwesomeIconTypes = ['fal', 'file-signature'];

function DealMenu(props: Props) {
  function isActive(path: string) {
    return props.location.pathname.includes(path);
  }

  function isDealTermsActive() {
    return (
      (isActive('overview') && !isActive('termsheet')) ||
      isActive('edit') ||
      isActive('tranches')
    );
  }

  const { dealId, sbaPppForgivenessWorkflow } = props;

  const forgivenessSbaFinal = forgivenessSbaFinalStates.includes(
    sbaPppForgivenessWorkflow?.state as SbaPppForgivenessWorkflowState,
  );

  // SBA HACK: Round Two - Figure out if a) is a borrower and b) should see the Portfolio
  const isSpecialCase =
    props.user.isDealBorrower && !props.user.isSingleDealBorrower;

  // SBA HACK: Round Two
  if (props.location.pathname.endsWith('first-draw')) {
    return (
      <>
        <GoBackMenuItem />
        <Divider className="transparent" />
        <MenuItem
          active={true}
          icon={dealTermsIcon}
          route={RouteTable.deal.toSbaPppRoundTwoFirstDrawForm(dealId)}
          title="PPP First Draw Loan"
        />
      </>
    );
  }
  if (props.location.pathname.endsWith('second-draw')) {
    return (
      <>
        <GoBackMenuItem />
        <Divider className="transparent" />
        <MenuItem
          active={true}
          icon={dealTermsIcon}
          route={RouteTable.deal.toSbaPppRoundTwoSecondDrawForm(dealId)}
          title="PPP Second Draw Loan"
        />
      </>
    );
  }
  return (
    <>
      {/* SBA HACK: Round Two */}
      {isSpecialCase ? (
        <>
          <MenuItem
            active={props.location.pathname === '/'}
            className="MenuItem--Home"
            icon={homeIcon}
            route={RouteTable.application.toHome}
            title="Portfolio"
          />
          <Divider className="transparent" />
        </>
      ) : (
        <PortfolioMenuItem location={location.pathname} />
      )}
      <MenuItem
        active={isDealTermsActive()}
        icon={dealTermsIcon}
        route={RouteTable.deal.toLoanOverviewSummary(dealId)}
        title="Loan Overview"
      />
      <SecureMenuItem
        activePath="termsheet"
        icon={fileIcon}
        location={props.location.pathname}
        permission={DealPerspectivePermissions.view_term_sheet}
        route={RouteTable.deal.toDealTermSheet(dealId)}
        title="Term Sheet"
      />

      <SecureMenuItem
        activePath="dataroom"
        icon={databaseIcon}
        location={props.location.pathname}
        permission={DealPerspectivePermissionSets.view_dataroom}
        route={RouteTable.deal.toDealDataroom(dealId)}
        title="Dataroom"
      />
      <SecureMenuItem
        activePath="people"
        icon={peopleIcon}
        location={props.location.pathname}
        permission={DealPerspectivePermissionSets.view_people}
        route={RouteTable.deal.toDealPeopleMyTeam(dealId)}
        title="People"
      />
      <SecureMenuItem
        activePath="calendar"
        icon={calendarIcon}
        location={props.location.pathname}
        permission={DealPerspectivePermissions.view_calendar_internal}
        route={RouteTable.deal.toDealCalendar(dealId)}
        title="Activity"
      />

      <SecureMenuItem
        activePath="fees"
        icon={moneyCheckEditIcon}
        location={props.location.pathname}
        permission={DealPerspectivePermissions.view_fees}
        route={RouteTable.deal.toDealFees(dealId)}
        title="Fees"
      />
      <SecureMenuItem
        activePath="sale"
        icon={newspaperIcon}
        location={props.location.pathname}
        permission={DealPerspectivePermissionSets.view_sales}
        route={RouteTable.deal.toDealSales(dealId)}
        title="Sales"
      />
      <SecureMenuItem
        activePath="payment"
        icon={moneyCheckIcon}
        location={props.location.pathname}
        permission={DealPerspectivePermissions.view_payments}
        route={RouteTable.deal.toDealPayments(dealId)}
        title="Payments"
      />
      <SecureMenuItem
        activePath="report"
        icon={chartBarIcon}
        location={props.location.pathname}
        permission={DealPerspectivePermissions.administer_deal}
        route={RouteTable.deal.toDealReports(dealId)}
        title="Reporting"
      />
      <SecureMenuItem
        activePath="sba-application"
        icon={applicationIcon}
        location={props.location.pathname}
        permission={DealPerspectivePermissions.view_sba_ppp_application}
        route={RouteTable.deal.toDealSbaApplication(dealId)}
        title="Borrower Application"
      />
      {!props.user.isDealBorrower && (
        <SecureMenuItem
          activePath="sba-lender-application"
          icon={applicationIcon}
          location={props.location.pathname}
          permission={DealPerspectivePermissions.view_closing}
          route={RouteTable.deal.toDealSbaLenderApplication(dealId)}
          title="Lender Application"
        />
      )}
      <SecureMenuItem
        activePath="closing"
        icon={checkCircleIcon}
        location={props.location.pathname}
        permission={DealPerspectivePermissions.view_closing}
        route={RouteTable.deal.toDealClosingWorkflow(dealId)}
        title="Closing"
      />
      {sbaPppForgivenessWorkflow?.canSeeForgiveness && !forgivenessSbaFinal && (
        <SecureMenuItem
          activePath="forgiveness-workflow"
          icon={chartBarIcon}
          location={props.location.pathname}
          permission={DealPerspectivePermissions.administer_sba_ppp_forgiveness}
          route={RouteTable.deal.toSbaPppForgivenessWorkflow(dealId)}
          title="PPP Loan Forgiveness"
        />
      )}
    </>
  );
}

const DealMenuContainer: any = compose(
  withRouteParams([RouteParams.dealId]),
  MyUserQuery,
  DealQuery,
  SbaPppForgivenessWorkflowQuery,
)(DealMenu);

export default DealMenuContainer;
