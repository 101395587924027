import * as React from 'react';
import noop from 'lodash/noop';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setAlert } from '../../../redux/actions/alerts';
import { InvitationGuardChildProps } from '../InvitationGuard';
import { REACT_APP_DJANGO_INVITE_URL } from '../../../url';
import { Form, Button, IFrame } from 'components';

import { AcceptInvitationMutation } from 'lsgql';
import { AlertType, PublicInvitationType } from 'types';
import { RouteTable } from 'routing';
import { ReduxDirectory } from 'lsredux';
import { invariant, isBorroweradmin } from 'utils';

import './AcceptInviteForm.scss';

type Props = {
  canRequestAccess: boolean;
  invitation: PublicInvitationType;
  invitationKey: string;
  setAlert: (message: string, type: AlertType) => void;
  successRoute: string;
} & InvitationGuardChildProps &
  RouteComponentProps & {
    displayName: string;
    refetchInvitation: () => Promise<any>;
  };

type State = {
  invitation: PublicInvitationType;
  showLogin: boolean;
};

/**
 * Determine the correct action to take for an invite
 *
 * - Display Registration Form if user not logged in?
 *
 *
 * - Display Confirmation button if user is logged in,
 *    and can accept
 */
class AcceptInviteForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    invariant(props.history, 'Router history is required');
    this.state = {
      invitation: props.invitation,
      showLogin: false,
    };
  }

  handleOnChange = (value: string | null | undefined, fieldId: string) => {
    this.setState(prevState => ({
      invitation: { ...prevState.invitation, [fieldId]: value },
    }));
  };

  handleSubmit = async () => {
    const { invitationKey } = this.props;

    try {
      const response = await AcceptInvitationMutation(invitationKey);

      if (
        response.data &&
        response.data.acceptInvitation &&
        response.data.acceptInvitation.ok
      ) {
        this.props.history.push(RouteTable.application.toHome);
        this.props.setAlert('Success!', 'success');
      } else {
        this.props.setAlert('Invitation failed', 'error');
        throw new Error('User registration has failed');
      }
    } catch (ex) {
      // eslint-disable-next-line
      console.log('Accept failed', ex);
    }
  };

  handleLogout = () => {
    // eslint-disable-next-line
    console.error('not implemented');
  };

  handleSubmitClick = () => {
    this.handleSubmit();
  };

  canSubmit = () => {
    const { invitation } = this.state;
    return Boolean(invitation.firstName && invitation.lastName);
  };

  handleToggleLoginForm = () => {
    this.setState(prev => ({ showLogin: !prev.showLogin }));
  };

  handleInvitationStatus = async () => {
    const invitation = await this.props.refetchInvitation();
    const {
      data: {
        viewInvitation: { accepted },
      },
    } = invitation;
    if (accepted) {
      this.props.history.push('/');
    }
  };

  handleOnLoad = () => {
    this.handleInvitationStatus();
  };

  renderRegisterForm = () => {
    const { displayName, invitationKey } = this.props;
    const companyName = isBorroweradmin() ? 'BorrowerAdmin' : 'LoanStreet';
    const iframeUrl = `${REACT_APP_DJANGO_INVITE_URL}${invitationKey}`;
    return (
      <div id="AcceptInviteForm">
        <Button.Text
          label="Already Registered? Login Here"
          onClick={this.handleToggleLoginForm}
        />

        <h4 className="ui header formHeader">Registration</h4>
        <div className="AcceptInviteForm__Description">
          Welcome to {displayName} {companyName}. Enter your name and password
          to complete your registration.
        </div>
        <IFrame onLoad={this.handleOnLoad} src={iframeUrl} title="register" />
      </div>
    );
  };

  renderLoginForm = () => (
    <Redirect
      to={{
        pathname: RouteTable.application.toLogin,
        state: { referrer: this.props.location.pathname },
      }}
    />
  );

  renderLoginPrompt = () => {
    const { displayName } = this.props;
    const companyName = isBorroweradmin() ? 'BorrowerAdmin' : 'LoanStreet';

    return (
      <div id="AcceptInviteForm">
        <h4 className="ui header formHeader">Registration</h4>
        <div className="AcceptInviteForm__Description">
          <p>
            Welcome to {displayName} {companyName}. Please log in to accept this
            invitation.
          </p>
          <Button.Text
            label="Login Here"
            onClick={this.handleToggleLoginForm}
          />
        </div>
      </div>
    );
  };

  renderAcceptInviteForm = () => {
    // User is authenticated, & can accept
    // 'Accept Invite';
    const { invitation } = this.props;
    return (
      <div>
        <p>
          {`You've been invited to join the Deal ${invitation.dealName ||
            'Unknown'}`}
        </p>
        <Button label="Accept Invitation" onClick={this.handleSubmitClick} />
      </div>
    );
  };

  renderRequestAccessFrom = () => (
    /*
    User is authenticated but is not
    permitted to accept the invite
       */
    <Form id="RequestAccessForm" onSubmit={noop}>
      <Form.Header as="h4" header="Request Access" />
      <Form.Input
        disabled
        fieldName="Email"
        id="email"
        onChange={noop}
        propertyName="email"
        value=""
        width="sixteen"
      />
      <Form.Group>
        <Form.Input
          disabled
          fieldName="First Name"
          id="InstitutionInvitationFirstName"
          onChange={this.handleOnChange}
          propertyName="firstName"
          value=""
          width="sixteen"
        />
        <Form.Input
          disabled
          fieldName="Last Name"
          id="InstitutionInvitationLastName"
          onChange={this.handleOnChange}
          propertyName="lastName"
          value=""
          width="sixteen"
        />
      </Form.Group>
      <Form.TextArea
        disabled
        fieldName="Message"
        id="message"
        onChange={noop}
        propertyName="message"
        value=""
      />
      <Form.FooterButton.Submit disabled onClick={noop} />
    </Form>
  );

  renderCannotAcceptInvite = () => {
    const { currentUser } = this.props;

    /*
      User has mistakenly loaded this invite, as it's for another
      user
    */
    return (
      <div>
        <p>
          {`You are already logged in as ${
            currentUser && currentUser.email ? currentUser.email : 'Unknown'
          }.  This invite was intended for another user and cannot be shared.`}
        </p>
        <div>
          You may <Button.Text label="Log out" onClick={this.handleLogout} />{' '}
          and try again
        </div>
      </div>
    );
  };

  render() {
    const {
      isAuthenticated,
      inviteIsForCurrentUser,
      canRequestAccess,
      invitation: { isExistingUser },
    } = this.props;

    if (isAuthenticated) {
      if (inviteIsForCurrentUser) {
        // User is logged in and can accept this invite
        return this.renderAcceptInviteForm();
      }

      // User is logged, cannot accept this invite, but can request access
      if (!inviteIsForCurrentUser && canRequestAccess)
        return this.renderRequestAccessFrom();

      // User is not permitted to use this invite
      return this.renderCannotAcceptInvite();
    }

    // User is not logged in
    const { showLogin } = this.state;

    /*
      Determine if login is required?
      - Can it be required?
    */
    if (showLogin) return this.renderLoginForm();

    // User is not logged in but invitation is for existing user
    if (isExistingUser) return this.renderLoginPrompt();

    return this.renderRegisterForm();
  }
}

const mapStateToProps = state => ({
  displayName: state.getIn([
    ...ReduxDirectory.LenderCustomKeyPath,
    'displayName',
  ]),
});

const mapDispatchToProps = (dispatch: any) => {
  const res: {
    setAlert: (alert: string, type: AlertType, title?: string) => void;
  } = {
    setAlert: (alert: string, type: AlertType, title?: string) => {
      dispatch(setAlert(alert, type, title));
    },
  };

  return res;
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AcceptInviteForm);
