import { compose } from 'recompose';
import { connect } from 'react-redux';
import LenderPaymentView from './LenderPaymentView';
import { withLoadingIndicator, FormMediator } from 'components';

import { RouteParams, withRouteParams } from 'routing';
import { PaymentRecordFormQuery, DealQuery } from 'lsgql';
import paymentRecordLenderRedux from 'lsredux/reducer/forms/paymentRecordLender';

const BorrowerPaymentViewContainer = compose(
  withRouteParams([RouteParams.dealId, RouteParams.paymentRecordId]),
  DealQuery,
  PaymentRecordFormQuery,
  withLoadingIndicator('loading'),
  connect(
    paymentRecordLenderRedux.mapStateToProps,
    paymentRecordLenderRedux.actions.generateActions,
  ),
  FormMediator({
    formId: 'paymentRecordBorrowerForm',
    formHeader: 'Payment Record Borrower',
    baseObjectTypeName: 'PaymentRecordBorrower',
    disableFrame: true,
  }),
)(LenderPaymentView);

export default BorrowerPaymentViewContainer;
