import * as React from 'react';
import { Header, Text, TextLink } from 'components';

import './RoundTwoSupportDocsPopup.scss';

const RoundTwoSupportDocsPopup = () => {
  return (
    <div className="RoundTwoSupportDocsWrapper">
      <div className="SupportDocs">
        <Header as="h1" className="bold steel">
          Supporting Documents Examples
        </Header>
        <Text className="steel">
          To support your application, you must submit documents to establish
          evidence of formation for the Business, eligibility of the loan / loan
          amount. Below are some examples but not an exhaustive list of evidence
          that may be provided.
        </Text>

        <Header as="h4" className="bold steel">
          Business Formation
        </Header>
        <Text className="steel">
          Corporate By-Laws, Articles of Incorporation or Partnership Agreements
          as applicable.
        </Text>
        <Text className="steel">Filing Receipts.</Text>
        <Text className="steel">Borrowing Resolutions or Authorizations.</Text>
        <Text className="steel">Tax returns (if sole proprietorship).</Text>

        <Header as="h4" className="bold steel">
          Personal Identification
        </Header>
        <Text className="steel">
          Driver’s License (if sole proprietorship).
        </Text>
        <Text className="steel">
          2019 W-3 Statement along with supporting documentation itemizing each
          employee’s annual pay (including benefits for sole proprietorship).
        </Text>

        <Header as="h4" className="bold steel">
          Loan and Amount Eligibility
        </Header>
        <Text className="steel">
          Supporting documentation for one of the following three periods,
          depending on whether your business is seasonal:
        </Text>
        <Text className="steel">
          <ul>
            <li>Trailing 12 months</li>
            <li>2019</li>
            <li>2020</li>
            <li>12 weeks following February 15, 2019, or</li>
            <li>March 1, 2019 — June 30, 2019</li>
            <li>
              Average total monthly payments for payroll during any consecutive
              12-week period between May 1, 2019 and September 15, 2019
            </li>
          </ul>
        </Text>
        <Text className="steel">
          Payroll Reports for the relevant pay period. Reports should include
          gross wages including PTO (vacation, sick and other PTO). If required,
          a supporting letter from a PEO and any payroll processor records.
        </Text>
        <Text className="steel">
          Documentation showing Health Insurance Premiums Paid by the Company
          Under a Group Health Plan including Owners of the Company for the
          immediately preceding 12 months prior to the date of SBA PPP loan
          application.
        </Text>
        <Text className="steel">
          Payroll Tax Reports filed with the IRS (Including Forms 941, 944,
          State Income and Unemployment Tax Filing Reports).
        </Text>
        <Text className="steel">
          Documentation of all retirement plan funding by the employer.
        </Text>
        <Text className="steel">
          Income and expenses from a sole proprietorship, or Form 1099-MISC for
          contractors.
        </Text>
        <Text className="steel">
          List of Employees salary, wages, commissions and tips in 2019 or 2020,
          who earn above $100,000, if any.
        </Text>

        <Text className="steel">
          For more detailed circumstances on First Draw loans please refer to
          this{' '}
          <TextLink.External
            label="Treasury guidance"
            target="newTab"
            to="https://home.treasury.gov/system/files/136/PPP-IFR-Paycheck-Protection-Program-as-Amended-by-Economic-Aid-Act.pdf"
          />
        </Text>
      </div>
    </div>
  );
};

export default RoundTwoSupportDocsPopup;
