import raiseAlert from '../../redux/raiseAlert';
import { TransitionForgivenessWorkflowMutationDefinition } from './definitions';
import { mutationBuilder } from './builders';
import { ID } from 'types';

type TriggerForgivenessWorkflowTransitionInput = {
  workflowId: ID;
  state: string;
  validate?: boolean;
  successToast?: string;
  errorToast?: string;
};

type TriggerForgivenessWorkflowTransitionType = {
  ok: boolean;
};

const triggerForgivenessWorkflowTransition = mutationBuilder<
  TriggerForgivenessWorkflowTransitionInput,
  TriggerForgivenessWorkflowTransitionType
>(TransitionForgivenessWorkflowMutationDefinition, {
  build: mutate => async (data: TriggerForgivenessWorkflowTransitionInput) => {
    const res = await mutate({
      variables: {
        workflowId: data.workflowId,
        state: data.state,
        validate: data.validate ? data.validate : false,
      },
      refetchQueries: ['DealQuery'],
    });

    const ok = res?.data?.transitionForgivenessWorkflow?.ok;

    if (ok && !data.validate) {
      raiseAlert(data.successToast ? data.successToast : 'Success', 'success');
    }
    if (!ok && data.errorToast) {
      raiseAlert(data.errorToast, 'error');
    }

    return res;
  },
});

export default triggerForgivenessWorkflowTransition;
