import * as React from 'react';
import { compose } from 'recompose';
import FileRow from '../fileRow/FileRowContainer';
import FolderRow from '../folderRow/FolderRowContainer';
import { FixedSizeListProps, DataroomRowData } from '../types';
import RowSecurityHoC from '../RowSecurityHoC';
import { PermissionPerspective } from 'security';
import { invariant } from 'utils';

type Props = FixedSizeListProps & {
  data: Array<DataroomRowData>;
  dragHoverRootId: string;
  index: number;
  leaveDragHover: () => void;
  levelMap: {
    [key: string]: number;
  };
  style: any;
};

const SecuredFolderRow = compose(RowSecurityHoC)(FolderRow);
const SecuredFileRow = compose(RowSecurityHoC)(FileRow);

const FolderTypeName = 'FolderType';
const typeNameField = '__typename';

class RowFork extends React.PureComponent<Props> {
  render() {
    const {
      data,
      index,
      style,
      leaveDragHover,
      levelMap,
      dragHoverRootId,
    } = this.props;
    const rowData = data[index];
    invariant(rowData, 'row item not found!');
    const { item } = rowData;

    // this needs to be done in javascript. pseudo selectors will cause bad flickering
    const isEven = Boolean(index % 2 === 0);

    let isInDropArea = false;
    let isDropAreaTop = false;
    let isDropAreaBot = false;

    if (dragHoverRootId) {
      if (dragHoverRootId === item.id) {
        isDropAreaTop = true;
      }
      const firstIndex = data.findIndex(
        element => element.item.id === dragHoverRootId,
      );
      invariant(firstIndex > -1, 'First Index of dropArea was not found!');
      const lastIndex = levelMap[dragHoverRootId];
      if (lastIndex) {
        if (lastIndex === index) {
          isDropAreaBot = true;
        }
        isInDropArea = index >= firstIndex && index <= lastIndex;
      }
    }
    if (item[typeNameField] === FolderTypeName) {
      return (
        <PermissionPerspective data={rowData.permissionsObject} disableLoader>
          <SecuredFolderRow
            entity={item}
            index={index}
            isDropAreaBot={isDropAreaBot}
            isDropAreaTop={isDropAreaTop}
            isEven={isEven}
            isInDropArea={isInDropArea}
            isOpen={rowData.isOpen}
            key={item.id}
            leaveDragHover={leaveDragHover}
            level={rowData.level}
            listStyle={style}
          />
        </PermissionPerspective>
      );
    }
    return (
      <PermissionPerspective data={rowData.permissionsObject} disableLoader>
        <SecuredFileRow
          entity={item}
          index={index}
          isDropAreaBot={isDropAreaBot}
          isEven={isEven}
          isInDropArea={isInDropArea}
          leaveDragHover={leaveDragHover}
          level={rowData.level}
          listStyle={style}
        />
      </PermissionPerspective>
    );
  }
}

export default RowFork;
