import Raven from 'raven-js';
import isProduction from './isProduction';

/* eslint-disable import/prefer-default-export */

/**
 * Report exception information to Sentry.  This should be used inside
 * the `catch` clause of a try/catch block.  Will only send the error
 * if the current build is 'production'
 * @param {*} e
 */
export function logToSentry(e: Error) {
  if (isProduction() && e) {
    Raven.captureException(e);
  }
}

/**
 * Report information to Sentry.  Will only send the message
 * if the current build is 'production'
 * @param {string} message
 */
export function logMessageToSentry(message: string) {
  if (isProduction() && message) {
    Raven.captureMessage(message);
  }
}
