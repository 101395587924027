import * as React from 'react';
import uuid from 'uuid/v4';
import { InvitationType, SearchFieldNameType } from '../types';
import { DealJob, InvitationTypes } from '../constants';
import LenderInvitationRow from './LenderInvitationRow';
import { Form, StandardModal, WrappedMediatorFormProps } from 'components';
import { isDealClosed } from 'utils';
import {
  DealType,
  InstitutionInvitationsInput,
  ID,
  UserType,
  InstitutionInviteeInput,
  ValidationType,
  ObjectBaseTypeNames,
  ValidationMap,
  SelectOptionType,
  MyUserType,
} from 'types';

import { DealNameSearch, QueryResult } from 'lsgql';

type Props = WrappedMediatorFormProps<InstitutionInvitationsInput> & {
  currentUser: MyUserType;
  deal: DealType;
  getRoleId: (args: {
    context?: string;
    name: string;
    postClosing: boolean | null | undefined;
  }) => ID;
  initializeState: () => void;
  invitationTypeSelected: InvitationType;
  isOpen: boolean;
  lead?: boolean;
  onClose: () => void;
  onInvitationTypeChange: (arg0: string | null | undefined) => void;
  onSave: () => Promise<{ entityId: ID; success: boolean }>;
  options: Array<SelectOptionType>;
  validationMapBuilder: (
    typeName: ObjectBaseTypeNames,
    entityId: ID,
    response: ValidationType,
    map: ValidationMap,
  ) => ValidationMap;
  fetchDealOrganizationDealRoles: () => Promise<QueryResult<any>>;
  validator: (entity: InstitutionInviteeInput) => Promise<ValidationType>;
};

type State = {
  dealNames: Array<UserType>;
  searchValue: string;
};

const parentType = 'InstitutionInvitations';
const leadLenderFieldName = 'Lead Lender';
const colenderFieldName = 'Co-Lender';

class LenderInvitationForm extends React.Component<Props, State> {
  fields: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      dealNames: [],
      searchValue: '',
    };

    this.fields = {
      invitationType: {
        id: 'invitationType',
        fieldName: 'Invite',
        propertyName: 'invitationType',
        width: 'six',
        onChange: this.handleInvitationTypeChange,
      },
      userSearch: {
        id: 'userSearch',
        propertyName: 'userSearch',
        width: 'ten',
        onChange: this.handleSearchChange,
        onFilterChange: this.handleFilterChange,
        placeholder: 'Search...',
      },
    };
  }

  componentDidMount() {
    this.props.initializeState();
  }

  handleSubmit = () => {
    const {
      onSave,
      setValidationErrors,
      fetchDealOrganizationDealRoles,
    } = this.props;
    this.validate().then(result => {
      if (Object.entries(result).length) {
        setValidationErrors(result);
      } else {
        onSave().then(response => {
          if (response && response.success) {
            fetchDealOrganizationDealRoles();
            this.handleOnClose();
          }
        });
      }
    });
  };

  validate = async () => {
    const { data, validator, validationMapBuilder } = this.props;
    const results = {};

    await Promise.all(
      data.invitees.map(async invitee => {
        const res = await validator(invitee);
        validationMapBuilder('InstitutionInvitee', invitee.id, res, results);
      }),
    );

    return results;
  };

  handleOnClose = () => {
    this.props.onClose();
  };

  handleSearchChange = (value?: string | null | undefined) => {
    if (!value) return;
    const { dealNames } = this.state;
    const { data, lead, invitationTypeSelected } = this.props;

    const contact = dealNames.find(dealName => dealName.id === value);
    const existingInvitee = data.invitees.find(
      invitee => invitee.email === value,
    );

    if (!existingInvitee) {
      if (
        !lead ||
        data.invitees.length === 0 ||
        invitationTypeSelected === InvitationTypes.MY_TEAM
      ) {
        if (contact) this.addEntity({ user: contact });
        else this.addEntity({ email: value });
      } else {
        const invitee = data.invitees[0];
        if (contact) {
          this.props.replaceEntity('invitees', {
            ...invitee,
            email: contact.email,
            firstName: contact.firstName,
            inviteeInstitutionId: null,
            lastName: contact.lastName,
            userId: contact.id,
          });
        } else if (dealNames.find(dealName => dealName.id === invitee.email)) {
          this.props.replaceEntity('invitees', {
            ...invitee,
            email: value,
          });
        } else {
          this.props.replaceEntity('invitees', {
            ...invitee,
            email: value,
            firstName: null,
            inviteeInstitutionId: null,
            lastName: null,
            userId: null,
          });
        }
      }
    }
  };

  getRoleId = (args: { context: string; name: string }) => {
    const { getRoleId, deal } = this.props;
    const { name, context } = args;
    const postClosing = context === 'deal' ? isDealClosed(deal.stage) : null;
    return getRoleId({ name, context, postClosing });
  };

  addEntity = (args: { email?: string; user?: UserType }) => {
    const { user, email } = args;
    const { lead, invitationTypeSelected, currentUser } = this.props;

    const inviteeInstitutionId =
      invitationTypeSelected === InvitationTypes.MY_TEAM &&
      currentUser.institution
        ? currentUser.institution.id
        : null;

    const dealRoleId = lead
      ? this.getRoleId({
          name: 'Administrator',
          context: 'deal',
        })
      : this.getRoleId({
          name: 'Co-Lender Administrator',
          context: 'deal',
        });

    this.props.addEntity('invitees', {
      id: uuid(),
      dealRoleId,
      email: user ? user.email : email,
      firstName: user ? user.firstName : '',
      inviteeInstitutionId,
      lastName: user ? user.lastName : '',
      dealJob: lead ? DealJob.LEAD_LENDER : DealJob.CO_LENDER,
      organizationRoleId: this.getRoleId({
        name: 'Administrator',
        context: 'institution',
      }),
      userId: user ? user.id : null,
      __typename: 'InstitutionInviteeInput',
    });
  };

  handleInvitationTypeChange = (value?: string | null | undefined) => {
    this.props.onInvitationTypeChange(value);
  };

  handleFilterChange = (searchValue: string) => {
    DealNameSearch(searchValue).then(result => {
      this.setState({
        dealNames: result.data.results,
        searchValue,
      });
    });
  };

  transformDealNamesToOptions = (
    dealNames: Array<UserType>,
  ): Array<SelectOptionType> => {
    const options = dealNames.map(user => ({
      text: `${user.fullName || ''}, ${user.email || ''}, ${(user.institution &&
        user.institution.name) ||
        ''}`,
      value: user.id,
    }));
    const searchValueOption = {
      text: this.state.searchValue,
      value: this.state.searchValue,
    };
    return [searchValueOption, ...options];
  };

  getSearchFieldName = (): SearchFieldNameType => {
    const { invitationTypeSelected } = this.props;

    if (invitationTypeSelected === InvitationTypes.LEAD_LENDER)
      return leadLenderFieldName;
    if (invitationTypeSelected === InvitationTypes.CO_LENDERS)
      return colenderFieldName;

    return 'Team Member';
  };

  render() {
    const { data, isOpen, options, lead, invitationTypeSelected } = this.props;
    const { dealNames } = this.state;
    const invitations = data.invitees.length;
    const dealNameOptions = this.transformDealNamesToOptions(dealNames);
    const fieldName: SearchFieldNameType = this.getSearchFieldName();
    const isOnlyInvitation = invitations === 1;

    let invitationType;
    if (
      invitationTypeSelected === InvitationTypes.LEAD_LENDER ||
      invitationTypeSelected === InvitationTypes.CO_LENDERS
    ) {
      invitationType = lead
        ? InvitationTypes.LEAD_LENDER
        : InvitationTypes.CO_LENDERS;
    } else {
      invitationType = InvitationTypes.MY_TEAM;
    }

    return (
      <StandardModal
        confirmButtonText="Send"
        denyConfirm={!invitations}
        header="Send Invitation"
        isOpen={isOpen}
        onClose={this.handleOnClose}
        onConfirm={this.handleSubmit}
      >
        <Form id="lenderInvitationForm" onSubmit={this.handleOnClose}>
          <>
            <Form.Group>
              {Form.FieldRenderer(
                Form.Select,
                {
                  ...this.fields.invitationType,
                  options,
                  value: invitationType,
                  disabled: Boolean(invitations) || options.length <= 1,
                  allowEmpty: false,
                },
                this.props,
                parentType as any,
                data.id,
              )}
              {Form.FieldRenderer(
                Form.Search,
                {
                  ...this.fields.userSearch,
                  allowEmpty: false,
                  hideIcon: true,
                  options: dealNameOptions,
                  onFilterChange: this.handleFilterChange,
                  fieldName,
                },
                this.props,
                parentType as any,
                data.id,
              )}
            </Form.Group>
            {data.invitees.map<any>((invitee, index) => (
              <LenderInvitationRow
                data={invitee}
                disabled={Boolean(this.props.disabled)}
                errors={this.props.errors}
                getRoleId={this.getRoleId}
                isHeader={isOnlyInvitation || !index}
                isSaving={this.props.isSaving}
                key={invitee.id}
                lead={lead}
                removeEntity={this.props.removeEntity}
                replaceEntity={this.props.replaceEntity}
              />
            ))}
          </>
        </Form>
      </StandardModal>
    );
  }
}

export default LenderInvitationForm;
