import { extname } from 'path';
import * as React from 'react';
import {
  ConnectDragSource,
  ConnectDragPreview,
  ConnectDropTarget,
} from 'react-dnd';
import cx from 'classnames';
import DataRoomRow, {
  DataRoomRowRenderProps,
  MenuRenderProps,
  EditRenderProps,
} from '../dataroomRow/DataroomRow';
import { handleCanDrop } from '../utils/dragDropMethods';
import {
  ContextPropTypes,
  FileWithDate,
  DataroomContext,
  DragSourceEntity,
} from '../types';
import FileRowMenu from './FileRowMenu';
import FileRowRenameInputContainer from './fileRowRenameInput/FileRowRenameInputContainer';
import { ID } from 'types';
import isPdf from 'utils/fileIdentification';
import { getDownloadLink, pascalCase, isWholeNumber } from 'utils';
import {
  getFileIconName,
  Icon,
  ProgressBar,
  Button,
  FontAwesomeIconTypes,
} from 'components';

import './FileRow.scss';

/* eslint-disable @typescript-eslint/no-unused-vars */

const unPinnedIcon: FontAwesomeIconTypes = ['far', 'thumbtack'];

function renderEditCell(props: EditRenderProps<FileWithDate>) {
  const name: string = props.entity.name || '';
  return (
    <span className="FileRow-RenameInput">
      <Icon
        className="FileRow-NameCell-FileIcon"
        icon={getFileIconName(props.entity.name)}
      />
      <FileRowRenameInputContainer
        documentId={props.entity.id}
        onClose={props.onEndEdit}
      />
      <span className="FileRow-RenameInput-Suffix">{extname(name)}</span>
    </span>
  );
}

function renderMenu(props: MenuRenderProps<FileWithDate>) {
  return <FileRowMenu {...props} />;
}

export type Props = {
  accessDenied: boolean;
  canDrop: boolean;
  connectDragPreview: ConnectDragPreview;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
  dragSourceEntity: DragSourceEntity;
  dragSourceType: string | symbol;
  entity: FileWithDate;
  handleEnterDragHover: (folderId: ID, canDropInRootFolder: boolean) => void;
  isDragging: boolean;
  isDropAreaBot: boolean;
  isEven: boolean;
  isInDropArea: boolean;
  isOver: boolean;
  level: number;
  listStyle: any;
};

class FileRow extends React.Component<Props> {
  static contextTypes = ContextPropTypes;

  getContext = () => this.context as DataroomContext;

  handleOpenPdfViewer = () => {
    const { entity } = this.props;
    this.getContext().openPdfViewer(entity);
  };

  handleEnterDragHover = (fileId: ID) => {
    const {
      dragSourceType,
      dragSourceEntity,
      handleEnterDragHover,
    } = this.props;
    handleEnterDragHover(
      fileId,
      handleCanDrop(fileId, dragSourceType, dragSourceEntity),
    );
  };

  renderNameCell = () => {
    const { entity } = this.props;
    const isUploading = !isWholeNumber(entity.id);
    const isContentPdf = isPdf(entity.name, entity.mimeType);
    const fileIconName = getFileIconName(entity.name);
    const className = cx(
      'FileRow-NameCell-FileIcon',
      `FileRow-NameCell-FileIcon--${pascalCase(fileIconName)}`,
    );
    const cellContent = (
      <>
        <Icon className={className} icon={fileIconName} />
        {entity.name}
      </>
    );

    return (
      <span
        className={`FileRow-NameCell ${
          isUploading ? 'FileRow-NameCell__Uploading' : ''
        }`}
      >
        {isUploading || !isContentPdf ? (
          cellContent
        ) : (
          <div onClick={this.handleOpenPdfViewer}>{cellContent}</div>
        )}
      </span>
    );
  };

  renderStatusContent = (entity: FileWithDate, isHovered: boolean) => {
    const { isNew } = entity;
    const isUploading = !isWholeNumber(entity.id);

    if (isNew) {
      return (
        <Icon
          className="FileRow__Icon FileRow__Icon--Check"
          color="loanstreetGreen"
          icon="check"
        />
      );
    }
    const downloadLink = getDownloadLink(entity);
    if (!isUploading && isHovered && downloadLink) {
      return <Button.Text isDownloadLink label="Download" to={downloadLink} />;
    }

    return <div className="FileRow--Icon" />;
  };

  renderAccessCell = (
    props: DataRoomRowRenderProps<FileWithDate>,
    isHovered = false,
  ) => {
    const { entity } = props;
    const isUploading = !isWholeNumber(entity.id);
    const isFailed = false;

    return (
      <span className="FileRow__AccessCell">
        {entity.isNew || isUploading ? (
          <ProgressBar value={entity.isNew ? 100 : props.entity.percent} />
        ) : (
          <span />
        )}
        <div className="FileRow--AccessContainer">
          {this.renderStatusContent(entity, isHovered)}
        </div>
      </span>
    );
  };

  render() {
    const {
      accessDenied,
      entity,
      level,
      listStyle,
      canDrop,
      isInDropArea,
      isDragging,
      isDropAreaBot,
      connectDragSource,
      connectDragPreview,
      connectDropTarget,
      isOver,
      isEven,
    } = this.props;

    return (
      <DataRoomRow
        accessDenied={accessDenied}
        canDrop={canDrop}
        connectDragPreview={connectDragPreview}
        connectDragSource={connectDragSource}
        connectDropTarget={connectDropTarget}
        entity={entity}
        isDragging={isDragging}
        isDropAreaBot={isDropAreaBot}
        isDropAreaTop={false}
        isEven={isEven}
        isInDropArea={isInDropArea}
        isOver={isOver}
        level={level}
        listStyle={listStyle}
        onEnterDragHover={this.handleEnterDragHover}
        renderAccessCell={this.renderAccessCell}
        renderEditCell={renderEditCell}
        renderMenu={renderMenu}
        renderNameCell={this.renderNameCell}
      />
    );
  }
}

export default FileRow;
