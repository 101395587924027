import * as React from 'react';
import uuid from 'uuid/v4';
import { LoanTrancheFormProps } from '../types';
import TrancheGroup from './TrancheGroup';
import MultiDrawRulesFieldsFeeItem from './multiDrawRulesForm/MultiDrawRulesFieldsFeeItem';
import {
  isLetterOfCredit,
  isMultiDraw,
  isRevolver,
  isSwingline,
} from './utils';
import { timeZoneOptions } from 'resources';
import { Percentage, SelectOptionType } from 'types';
import { Form } from 'components';

const width = '158px';
const loanTranche = 'LoanTranche';
const loanTrancheMultiDrawData = 'LoanTrancheMultiDrawData';
const feeType = 'FeeType';
const feeCalculationRuleType = 'FeeCalculationRuleType';

class MultiDrawRulesFields extends React.Component<LoanTrancheFormProps> {
  mutateMultiDrawRulesData = (
    value: string | null | undefined,
    property: string,
  ) => {
    const { data } = this.props;
    const { loantranchemultidrawdata } = data;
    this.props.replaceEntity('loantranchemultidrawdata', {
      ...loantranchemultidrawdata,
      [property]: value,
    });
  };

  /* eslint-disable-next-line */
  handleUpdateLOCProperty = (
    value: string | null | undefined,
    fieldId: string,
  ) => {
    this.props.onUpdateLoanTrancheRevolverSwinglineLOCData({
      ...this.props.data.loantrancherevolverswinglinelocdata,
      [fieldId]: value,
    });
  };

  /* eslint-enable react/sort-comp */

  fields = {
    maxNumberOfDraws: {
      id: 'maxNumberOfDraws',
      propertyName: 'maxNumberOfDraws',
      fieldName: 'Max Number of Draws',
      onChange: this.mutateMultiDrawRulesData,
      width,
    },
    minimumSizeForDraw: {
      id: 'minimumSizeForDraw',
      propertyName: 'minimumSizeForDraw',
      fieldName: 'Draw Minimum Size',
      onChange: this.mutateMultiDrawRulesData,
      width,
    },
    minimumSizeForRepay: {
      id: 'minimumSizeForRepay',
      propertyName: 'minimumSizeForRepay',
      fieldName: 'Repay Minimum Size',
      onChange: this.mutateMultiDrawRulesData,
      width,
    },
    minimumIncrementForDraw: {
      id: 'minimumIncrementForDraw',
      propertyName: 'minimumIncrementForDraw',
      fieldName: 'Draw Minimum Increment',
      onChange: this.mutateMultiDrawRulesData,
      width,
    },
    minimumIncrementForRepay: {
      id: 'minimumIncrementForRepay',
      propertyName: 'minimumIncrementForRepay',
      fieldName: 'Repay Minimum Increment',
      onChange: this.mutateMultiDrawRulesData,
      width,
    },
    availabilityTerminationDate: {
      id: 'availabilityTerminationDate',
      propertyName: 'availabilityTerminationDate',
      fieldName: 'Availability Termination Date',
      onChange: this.mutateMultiDrawRulesData,
      width,
    },
    availabilityTerminationTimeForDraw: {
      id: 'availabilityTerminationTimeForDraw',
      propertyName: 'availabilityTerminationTimeForDraw',
      fieldName: 'Cutoff Time',
      onChange: this.mutateMultiDrawRulesData,
      width,
    },
    availabilityTerminationTimeZoneForDraw: {
      id: 'availabilityTerminationTimeZoneForDraw',
      propertyName: 'availabilityTerminationTimeZoneForDraw',
      fieldName: 'Time Zone',
      onChange: this.mutateMultiDrawRulesData,
      options: timeZoneOptions,
      width,
    },
    availabilityTerminationTimeForRepay: {
      id: 'availabilityTerminationTimeForRepay',
      propertyName: 'availabilityTerminationTimeForRepay',
      fieldName: 'Cutoff Time',
      onChange: this.mutateMultiDrawRulesData,
      width,
    },
    availabilityTerminationTimeZoneForRepay: {
      id: 'availabilityTerminationTimeZoneForRepay',
      propertyName: 'availabilityTerminationTimeZoneForRepay',
      fieldName: 'Time Zone',
      onChange: this.mutateMultiDrawRulesData,
      options: timeZoneOptions,
      width,
    },
    noticePeriodToDraw: {
      id: 'noticePeriodToDraw',
      propertyName: 'noticePeriodToDraw',
      fieldName: 'Draw Notice Period',
      onChange: this.mutateMultiDrawRulesData,
      width,
      suffix: 'days',
    },
    noticePeriodToRepay: {
      id: 'noticePeriodToRepay',
      propertyName: 'noticePeriodToRepay',
      fieldName: 'Repay Notice Period',
      onChange: this.mutateMultiDrawRulesData,
      width,
      suffix: 'days',
    },
    incrementalEquityInterest: {
      id: 'incrementalEquityInterest',
      propertyName: 'incrementalEquityInterest',
      fieldName: 'Incremental Equity Interest',
      onChange: this.mutateMultiDrawRulesData,
      width,
      rows: 1,
    },
    loanTrancheChargedAgainst: {
      id: 'loanTrancheChargedAgainst',
      propertyName: 'loanTrancheChargedAgainstId',
      onChange: this.handleUpdateLOCProperty,
      options: this.props.letterOfCreditChargeableLoanTranches.map<
        SelectOptionType
      >(lt => ({
        value: lt.id,
        text: lt.name || '',
      })),
      fieldName: 'Charged Against',
      width,
    },
  };

  getFee = (
    feeKindSlug: string,
    defaultRate: Percentage | null | undefined,
  ) => {
    const { data } = this.props;
    const { feeSet } = data;
    let desiredFee;
    if (feeSet) {
      desiredFee = feeSet.find(fee => fee.feeKindSlug === feeKindSlug);
    }
    if (!desiredFee) {
      desiredFee = {
        id: uuid(),
        feeKindSlug,
        __typename: feeType,
        feecalculationrule: {
          id: uuid(),
          __typename: feeCalculationRuleType,
          appliedRate: defaultRate,
        },
      };
    }
    return desiredFee;
  };

  getDiscountToParFee = () => this.getFee('discount-to-par', '1.00');

  getFeeToDraw = () => this.getFee('fee-to-draw', null);

  render() {
    const { data, errors, disabled, isSaving } = this.props;

    const letterOfCredit = isLetterOfCredit(data);
    const multiDraw = isMultiDraw(data);
    const revolver = isRevolver(data);
    const swingline = isSwingline(data);

    if (!letterOfCredit && !multiDraw && !revolver && !swingline) return null;

    const discountToParFee = this.getDiscountToParFee();
    const feeToDrawFee = this.getFeeToDraw();

    const { loantranchemultidrawdata } = data;
    const id = (loantranchemultidrawdata && loantranchemultidrawdata.id) || '';
    const multiDrawMediatorProps = {
      data: loantranchemultidrawdata,
      isSaving,
      errors,
      disabled,
    };

    return (
      <TrancheGroup>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Numeric,
            this.fields.noticePeriodToDraw,
            multiDrawMediatorProps,
            loanTrancheMultiDrawData,
            id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Time,
            this.fields.availabilityTerminationTimeForDraw,
            multiDrawMediatorProps,
            loanTrancheMultiDrawData,
            id,
          )}
          {Form.FieldRenderer(
            Form.Select,
            this.fields.availabilityTerminationTimeZoneForDraw,
            multiDrawMediatorProps,
            loanTrancheMultiDrawData,
            id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Money,
            this.fields.minimumSizeForDraw,
            multiDrawMediatorProps,
            loanTrancheMultiDrawData,
            id,
          )}
          {Form.FieldRenderer(
            Form.Money,
            this.fields.minimumIncrementForDraw,
            multiDrawMediatorProps,
            loanTrancheMultiDrawData,
            id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Numeric,
            this.fields.noticePeriodToRepay,
            multiDrawMediatorProps,
            loanTrancheMultiDrawData,
            id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Time,
            this.fields.availabilityTerminationTimeForRepay,
            multiDrawMediatorProps,
            loanTrancheMultiDrawData,
            id,
          )}
          {Form.FieldRenderer(
            Form.Select,
            this.fields.availabilityTerminationTimeZoneForRepay,
            multiDrawMediatorProps,
            loanTrancheMultiDrawData,
            id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Money,
            this.fields.minimumSizeForRepay,
            multiDrawMediatorProps,
            loanTrancheMultiDrawData,
            id,
          )}
          {Form.FieldRenderer(
            Form.Money,
            this.fields.minimumIncrementForRepay,
            multiDrawMediatorProps,
            loanTrancheMultiDrawData,
            id,
          )}
        </Form.Group>
        {isLetterOfCredit(data) && (
          <Form.Group>
            {Form.FieldRenderer(
              Form.Select,
              {
                ...this.fields.loanTrancheChargedAgainst,
                value:
                  (data.loantrancherevolverswinglinelocdata &&
                    (data.loantrancherevolverswinglinelocdata as any)
                      .loanTrancheChargedAgainstId) ||
                  '',
              },
              this.props,
              loanTranche,
              data.id,
            )}
          </Form.Group>
        )}
        {multiDraw && (
          <>
            <Form.Group>
              {Form.FieldRenderer(
                Form.Numeric,
                this.fields.maxNumberOfDraws,
                multiDrawMediatorProps,
                loanTrancheMultiDrawData,
                id,
              )}
            </Form.Group>
            <Form.Group>
              <MultiDrawRulesFieldsFeeItem
                data={discountToParFee}
                disabled={disabled}
                errors={this.props.errors}
                fieldName="Discount to Par"
                isSaving={this.props.isSaving}
                replaceEntity={this.props.replaceEntity}
              />
              <MultiDrawRulesFieldsFeeItem
                data={feeToDrawFee}
                disabled={disabled}
                errors={this.props.errors}
                fieldName="Fee to Draw"
                isSaving={this.props.isSaving}
                replaceEntity={this.props.replaceEntity}
              />
            </Form.Group>
            <Form.Group>
              {Form.FieldRenderer(
                Form.Money,
                this.fields.incrementalEquityInterest,
                multiDrawMediatorProps,
                loanTrancheMultiDrawData,
                id,
              )}
              {Form.FieldRenderer(
                Form.Calendar,
                this.fields.availabilityTerminationDate,
                multiDrawMediatorProps,
                loanTrancheMultiDrawData,
                id,
              )}
            </Form.Group>
          </>
        )}
      </TrancheGroup>
    );
  }
}

export default MultiDrawRulesFields;
