import * as React from 'react';
import { compose } from 'recompose';
import InvitationFork from './InvitationFork';
import { RouteParams, withRouteParams } from 'routing';
import { PublicInvitationQuery, MyUserQuery } from 'lsgql';
import { withLoadingIndicator } from 'components';
import { PublicInvitationType, UserType } from 'types';
import { invariant } from 'utils';

const initialDealArranger: PublicInvitationType = {
  accepted: null,
  dealName: '',
  email: 'initial-institution@loan-street.com',
  expiration: '2019-10-09T20:09:51+00:00',
  firstName: 'First',
  institutionName: 'Initial Institution',
  isExpired: false,
  isUsable: true,
  lastName: 'User',
  message: 'Testing for initial instituion',
  isSharable: false,
  isExistingUser: false,
  cancelled: null,
};

const existingBorrowerToDeal: PublicInvitationType = {
  accepted: null,
  dealName: 'Borrowers Deal',
  email: 'borrower@loan-street.com',
  expiration: '2019-10-09T20:09:51+00:00',
  firstName: 'Borrower',
  institutionName: 'Does this exist?',
  isExpired: false,
  isUsable: true,
  lastName: 'User',
  message: 'Testing for borrower joining a deal',
  isSharable: false,
  isExistingUser: false,
  cancelled: null,
};

const newBorrowerToDeal: PublicInvitationType = {
  accepted: null,
  dealName: 'Borrowers Deal',
  email: 'borrower@loan-street.com',
  expiration: '2019-10-09T20:09:51+00:00',
  firstName: 'Borrower',
  institutionName: 'Does this exist?',
  isExpired: false,
  isUsable: true,
  lastName: 'User',
  message: 'Testing for borrower joining a deal',
  isSharable: false,
  isExistingUser: false,
  cancelled: null,
};

const userAddedToExistingInstitution: PublicInvitationType = {
  accepted: null,
  dealName: '',
  email: 'existing-institution-new-user@loan-street.com',
  expiration: '2019-10-09T20:09:51+00:00',
  firstName: 'First',
  institutionName: 'Existing Institution',
  isExpired: false,
  isUsable: true,
  lastName: 'User',
  message: 'User is new, but is joining an existing institution',
  isSharable: false,
  isExistingUser: false,
  cancelled: null,
};

const userAddedToExistingInstitutionAndDeal: PublicInvitationType = {
  accepted: null,
  dealName: 'Existing Deal',
  email: 'existing-institution-existing-deal-new-user@loan-street.com',
  expiration: '2019-10-09T20:09:51+00:00',
  firstName: 'New',
  institutionName: 'Existing Institution',
  isExpired: false,
  isUsable: true,
  lastName: 'User',
  message:
    'User is new, but is joining an existing institution AND existing deal',
  isSharable: false,
  isExistingUser: false,
  cancelled: null,
};

const existingUserToExistingDeal = {
  accepted: null,
  dealName: 'Existing Deal',
  email: 'existing-institution-existing-deal-new-user@loan-street.com',
  expiration: '2019-10-09T20:09:51+00:00',
  firstName: 'New',
  institutionName: 'Existing Institution',
  isExpired: false,
  isUsable: true,
  lastName: 'User',
  message:
    'User is new, but is joining an existing institution AND existing deal',
  isExistingUser: false,
  cancelled: null,
  isSharable: false,
};

function debugQueryIntercept(
  Wrapped: React.ComponentType<{
    invitation: PublicInvitationType | null | undefined;
  }>,
) {
  return function debugQueryRes(props: {
    invitation: PublicInvitationType | null | undefined;
    invitationKey: string | null | undefined;
    user: UserType | null | undefined;
  }) {
    if (props.invitationKey) {
      const { invitationKey } = props;

      if (invitationKey === 'initialDealArranger')
        return <Wrapped {...props} invitation={initialDealArranger} />;

      if (invitationKey === 'existingBorrowerToDeal')
        return <Wrapped {...props} invitation={existingBorrowerToDeal} />;

      if (invitationKey === 'newBorrowerToDeal')
        return <Wrapped {...props} invitation={newBorrowerToDeal} />;

      if (invitationKey === 'userAddedToExistingInstitution') {
        return (
          <Wrapped {...props} invitation={userAddedToExistingInstitution} />
        );
      }

      if (invitationKey === 'userAddedToExistingInstitutionAndDeal') {
        return (
          <Wrapped
            {...props}
            invitation={userAddedToExistingInstitutionAndDeal}
          />
        );
      }

      if (invitationKey === 'existingUserToExistingDeal') {
        invariant(
          props.user,
          'The debug invitation: ExistingUserToExistingDeal requires you to login first',
        );
        const { user } = props;
        return (
          <Wrapped
            {...props}
            invitation={{
              ...existingUserToExistingDeal,
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
            }}
          />
        );
      }
    }
    return <Wrapped {...props} />;
  };
}

export default compose(
  MyUserQuery,
  withLoadingIndicator('loading'),
  withRouteParams([RouteParams.invitationKey]),
  PublicInvitationQuery,
  debugQueryIntercept,
  withLoadingIndicator('loading'),
)(InvitationFork);
