import { gql } from '@apollo/client';
import {
  DealShallowFields,
  TierFeePercentageFields,
  LoanTrancheTierFields,
  LoanTrancheShallowFields,
  FeeShallowFields,
  FeeCalculationRuleShallowFields,
  PrepaymentPenaltyRangeShallowFields,
} from '../fragments';
import { getAuthenticatedClient } from '../authenticatedClient';
import formQueryBuilder from './queryBuilders/formQueryBuilder';
import { FeesInput } from 'types';

/* eslint-disable import/prefer-default-export */
export const FeesFormQueryDefinition = gql`
  query DealQuery($id: ID!) {
    deal(id: $id) {
      ...DealShallowFields
      loantrancheSet {
        ...LoanTrancheShallowFields
        prepaymentpenaltyrangeSet {
          ...PrepaymentPenaltyRangeShallowFields
        }
        feeSet {
          ...FeeShallowFields
          tierfeepercentageSet {
            ...TierFeePercentageFields
          }
          feecalculationrule {
            ...FeeCalculationRuleShallowFields
          }
          loanTranche {
            id
          }
        }
        loantranchetierSet {
          ...LoanTrancheTierFields
        }
      }
    }
  }
  ${LoanTrancheShallowFields}
  ${DealShallowFields}
  ${FeeShallowFields}
  ${FeeCalculationRuleShallowFields}
  ${LoanTrancheTierFields}
  ${TierFeePercentageFields}
  ${PrepaymentPenaltyRangeShallowFields}
`;

export const FeesFormQuery = formQueryBuilder(FeesFormQueryDefinition, {
  // The root object is actually a deal, and mutationBuilder requires a fetch method
  // for the root object, that is computable from typename
  typename: 'Deal',
  skip: (props: { dealId: string | null | undefined }) => !props.dealId,
  variables: (props: { dealId: string | null | undefined }) => {
    const { dealId } = props;
    return { id: dealId };
  },
  results: (data: { deal: FeesInput | null | undefined }) => {
    const { deal } = data;
    return deal;
  },
});

export const FeesFetchMethod = async ({ id }) => {
  const variables: {
    [key: string]: any;
  } = { id };
  const res = await getAuthenticatedClient().query({
    query: FeesFormQueryDefinition,
    variables,
    fetchPolicy: 'network-only',
  });

  return {
    ...res,
    data: res.data.deal,
  };
};
