import { gql } from '@apollo/client';
import { getAuthenticatedClient } from '../authenticatedClient';
import { invariant } from 'utils';
import { ID } from 'types';

/* eslint-disable */
const OnboardExistingUserFirstDrawApplicationMutationDefinition = gql`
  mutation OnboardExistingUserFirstDrawApplicationMutation(
    $applicationId: ID!
    $firstDrawDealId: ID!
  ) {
    onboardExistingUserFirstDrawApplication(
      applicationId: $applicationId
      firstDrawDealId: $firstDrawDealId
    ) {
      ok
    }
  }
`;
/* eslint-enable */

export function TriggerExistingUserFirstDrawOnboardingMutation(
  applicationId: ID,
  firstDrawDealId: ID,
) {
  invariant(applicationId, 'applicationId is required');
  invariant(firstDrawDealId, 'firstDrawDealId is required');

  const client = getAuthenticatedClient();
  invariant(client, 'Failed to create Public Client');

  return client.mutate<any>({
    mutation: OnboardExistingUserFirstDrawApplicationMutationDefinition,
    variables: {
      applicationId,
      firstDrawDealId,
    },
    refetchQueries: [
      'DealQuery',
      'DealsQuery',
      'UserQuery',
      'MyUserQuery',
      'LegacyDealsQuery',
    ],
  });
}

export default TriggerExistingUserFirstDrawOnboardingMutation;
