// !!!!! THIS IS AN AUTO-GENERATED FILE, DO NOT EDIT !!!!!

import { gql } from '@apollo/client';
import { SbaPppForgivenessResubmitRequestInput } from 'types';
import { invariant, strictConvertToInput } from 'utils';
import {
  default as validationBuilder,
  ValidationData,
  AdditionalOptions,
} from './builder/validationBuilder';
import { ApolloQueryResult } from '../types';
import { inputScrubber } from '../mutations/utils';

/* eslint-disable import/prefer-default-export */

const SbaPppForgivenessResubmitRequestValidatorDefinition = gql`
  query validateSbaPppForgivenessResubmitRequest(
    $sbaPppForgivenessResubmitRequestData: SbaPppForgivenessResubmitRequestInput
  ) {
    validateSbaPppForgivenessResubmitRequest(
      sbaPppForgivenessResubmitRequestData: $sbaPppForgivenessResubmitRequestData
    ) {
      errors
      errorFields {
        name
        value
      }
      warnings
      warningFields {
        name
        value
      }
    }
  }
`;

const propName = 'validateSbaPppForgivenessResubmitRequest';

/**
 * Auto-Generated from the GraphQL Schema
 */
export const SbaPppForgivenessResubmitRequestValidator = validationBuilder<
  SbaPppForgivenessResubmitRequestInput
>(SbaPppForgivenessResubmitRequestValidatorDefinition, {
  build: (
    validation: (
      options: AdditionalOptions,
    ) => Promise<ApolloQueryResult<ValidationData>>,
  ) => async (entity: SbaPppForgivenessResubmitRequestInput) => {
    const res: ApolloQueryResult<ValidationData> = await validation({
      variables: {
        sbaPppForgivenessResubmitRequestData: { id: entity.id },
      },
    });
    invariant(
      res.data[propName],
      'Expected res.data to contain validateSbaPppForgivenessResubmitRequest',
    );
    return res.data[propName];
  },
});

export const SbaPppForgivenessResubmitRequestEntityValidator = validationBuilder<
  SbaPppForgivenessResubmitRequestInput
>(SbaPppForgivenessResubmitRequestValidatorDefinition, {
  build: (
    validation: (
      options: AdditionalOptions,
    ) => Promise<ApolloQueryResult<ValidationData>>,
  ) => async (entity: SbaPppForgivenessResubmitRequestInput) => {
    const res: ApolloQueryResult<ValidationData> = await validation({
      variables: {
        sbaPppForgivenessResubmitRequestData: strictConvertToInput({
          ...inputScrubber(entity),
          submitted: undefined,
          approved: undefined,
        }),
      },
    });
    invariant(
      res.data[propName],
      'Expected res.data to contain validateSbaPppForgivenessResubmitRequest',
    );
    return res.data[propName];
  },
});
