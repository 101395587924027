import {
  SetOrganizationFolderRoleMutationDefinition,
  SetOrganizationDealRoleMutationDefinition,
  SetUserDealRoleMutationDefinition,
} from './definitions';
import { mutationBuilder } from './builders';
import {
  DeleteUserDealRoleType,
  SetUserDealRoleType,
  DeleteOrganizationDealRoleType,
  SetOrganizationDealRoleType,
  DeleteOrganizationFolderRoleType,
  SetOrganizationFolderRoleType,
} from 'types';
import { invariant } from 'utils';

const setUserDealRole = mutationBuilder<SetUserDealRoleType, void>(
  SetUserDealRoleMutationDefinition,
  {
    build: mutate => userDealRole => {
      const { dealId, roleId, userId } = userDealRole;
      return mutate({
        variables: {
          dealId,
          roleId,
          userId,
        },
        refetchQueries: ['DealOrganizationsQuery'],
      });
    },
  },
);

const deleteUserDealRole = mutationBuilder<DeleteUserDealRoleType, void>(
  SetUserDealRoleMutationDefinition,
  {
    build: mutate => userDealRole => {
      const { dealId, userId } = userDealRole;
      return mutate({
        variables: {
          dealId,
          roleId: null,
          userId,
        },
        refetchQueries: ['DealOrganizationsQuery'],
      });
    },
  },
);

const setOrganizationDealRole = mutationBuilder<
  SetOrganizationDealRoleType,
  void
>(SetOrganizationDealRoleMutationDefinition, {
  build: mutate => userDealRole => {
    const { dealId, roleId, institutionId } = userDealRole;
    invariant(institutionId, 'institutionId is required');
    return mutate({
      variables: {
        dealId,
        roleId,
        institutionId,
      },
      refetchQueries: ['DealOrganizationsQuery'],
    });
  },
});

const deleteOrganizationDealRole = mutationBuilder<
  DeleteOrganizationDealRoleType,
  void
>(SetOrganizationDealRoleMutationDefinition, {
  build: mutate => userDealRole => {
    const { dealId, institutionId } = userDealRole;
    invariant(institutionId, 'institutionId is required');
    return mutate({
      variables: {
        dealId,
        roleId: null,
        institutionId,
      },
      refetchQueries: ['DealOrganizationsQuery'],
    });
  },
});

const setOrganizationFolderRole = mutationBuilder<
  SetOrganizationFolderRoleType,
  void
>(SetOrganizationFolderRoleMutationDefinition, {
  build: mutate => userFolderRole => {
    const { folderId, roleId, institutionId } = userFolderRole;
    invariant(institutionId, 'institutionId is required');
    return mutate({
      variables: {
        folderId,
        roleId,
        institutionId,
      },
      refetchQueries: ['FolderOrganizationFolderRolesQuery'],
    });
  },
});

const deleteOrganizationFolderRole = mutationBuilder<
  DeleteOrganizationFolderRoleType,
  void
>(SetOrganizationFolderRoleMutationDefinition, {
  build: mutate => userFolderRole => {
    const { folderId, institutionId } = userFolderRole;
    invariant(institutionId, 'institutionId is required');
    return mutate({
      variables: {
        folderId,
        roleId: null,
        institutionId,
      },
      refetchQueries: ['FolderOrganizationFolderRolesQuery'],
    });
  },
});

const RolePermissionMutations = {
  setOrganizationFolderRole,
  deleteOrganizationFolderRole,
  setOrganizationDealRole,
  deleteOrganizationDealRole,
  setUserDealRole,
  deleteUserDealRole,
};

export default RolePermissionMutations;
