import { compose, mapProps } from 'recompose';
import DealPeopleView from './DealPeopleView';
import organizationMapperHoC, {
  MappedOrganizations,
} from './organizationMapperHoC';
import {
  VIEW_COLENDERS,
  VIEW_BORROWERS,
  VIEW_SERVICING_AGENTS,
  VIEW_LEAD_LENDERS,
  VIEW_OWN_TEAM,
} from 'security/constants';

type MappedProps = {
  showBorrower: boolean;
  showCoLenders: boolean;
  showLeadLender: boolean;
  showMyTeam: boolean;
  showServicingAgents: boolean;
};

function propsMapper(ownProps: MappedOrganizations): MappedProps {
  const {
    coLenders,
    servicingAgents,
    leadLenders,
    borrowers,
    permissionsMap,
  } = ownProps;
  const showCoLenders =
    !(permissionsMap && permissionsMap.get(VIEW_COLENDERS)) &&
    Boolean(coLenders) &&
    coLenders.length > 0;
  const showServicingAgents =
    !(permissionsMap && permissionsMap.get(VIEW_SERVICING_AGENTS)) &&
    Boolean(servicingAgents) &&
    servicingAgents.length > 0;
  const showLeadLender =
    !(permissionsMap && permissionsMap.get(VIEW_LEAD_LENDERS)) &&
    Boolean(leadLenders) &&
    leadLenders.length > 0;
  const showBorrower =
    !(permissionsMap && permissionsMap.get(VIEW_BORROWERS)) &&
    Boolean(borrowers) &&
    borrowers.length > 0;
  const showMyTeam = !(permissionsMap && permissionsMap.get(VIEW_OWN_TEAM));

  return {
    showBorrower,
    showCoLenders,
    showMyTeam,
    showServicingAgents,
    showLeadLender,
  };
}

const Composed = compose(
  organizationMapperHoC,
  mapProps(propsMapper),
)(DealPeopleView);

export default Composed;
