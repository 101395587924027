import { compose, withProps } from 'recompose';
import GenericWorkflow from './GenericWorkflow';
import { RouteParams, withRouteParams } from 'routing';
import { DealClosingWorkflowStandardQuery, DealMutationMethods } from 'lsgql';
import { withLoadingIndicator } from 'components';

import { DealInput, ID } from 'types';

export default compose(
  withRouteParams([RouteParams.dealId]),
  DealClosingWorkflowStandardQuery,
  withLoadingIndicator('loading'),
  withProps((ownProps: any) => {
    /* eslint-disable */
    const res = {
      dealClosingWorkflow: null,

      updateLoanFunding(value: string, property: string) {
        // eslint-disable-next-line
        console.log('Disabled updateLoanFunding');
      },

      closeDeal(dealId: ID) {
        const args: DealInput = {
          id: dealId,
          __typename: 'DealInput',
        };
        DealMutationMethods.closeDeal(args);
      },
    };

    const {
      deal: { dealClosingWorkflow },
    } = ownProps;

    const steps = dealClosingWorkflow?.steps;

    if (steps && steps.length > 0) {
      res['dealClosingWorkflow'] = dealClosingWorkflow;
      res['borrower'] = dealClosingWorkflow.isBorrower;
    } else {
      res['borrower'] = false;
    }

    return res;
    /* eslint-enable */
  }),
)(GenericWorkflow);
