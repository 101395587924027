import * as React from 'react';
import noop from 'lodash/noop';
import cx from 'classnames';
import { ux } from '../../utils';
import DataGridIconCell from './DataGridIconCell';
import DataGridHeaderCell from './DataGridHeaderCell';
import { Column } from './types';
import './DataGridHeaderRow.scss';

/**
 * A data grid header row.
 */

interface DataGridHeaderRowProps<T, K extends keyof T> {
  /**
   * The column configuration objects of the data grid.
   */
  borderless?: boolean;

  /**
   * The callback issued when when a header cell is clicked, if
   * any.
   */
  className?: string;

  /**
   * The callback issued when a user drags a header column to
   * another location.
   */
  columns: Array<Column<T, K>>;

  deleteColumn?: boolean;

  /**
   * If true, renders space-filling cell for datagrid's delete column
   */
  enableChildRows?: boolean;

  onClickHeaderCell?: (x0: string) => void;

  onMoveColumn?: (x: number, y: number) => void;
}

const collapseColumn = {
  columnName: '',
  displayName: '',
  index: -1,
};

class DataGridHeaderRow<T, K extends keyof T> extends React.Component<
  DataGridHeaderRowProps<T, K>
> {
  static defaultProps = {
    onClickHeaderCell: () => undefined,
    onMoveColumn: () => undefined,
    borderless: false,
  };

  render() {
    const { borderless } = this.props;
    const headerClass = cx(
      'DataGridHeaderRow',
      ux(borderless, 'DataGridHeaderRow-Borderless'),
      ux(this.props.className, this.props.className),
    );
    return (
      <div className={headerClass}>
        {this.props.enableChildRows && (
          <DataGridHeaderCell {...collapseColumn} />
        )}
        {this.props.columns.map(column =>
          column.icon ? (
            <DataGridIconCell
              className={`DataGridHeaderCell ${column.className || ''}`}
              icon={column.icon}
              key={column.columnName}
              onClick={noop}
            />
          ) : (
            <DataGridHeaderCell
              className={column.className}
              columnName={column.columnName}
              customHeaderCell={column.customHeaderCell}
              displayName={
                column.displayName ? column.displayName : column.columnName
              }
              key={column.columnName}
              onClickHeaderCell={this.props.onClickHeaderCell}
            />
          ),
        )}
        {this.props.deleteColumn && (
          <DataGridIconCell
            className="DataGridHeaderCell DataGridCell-DeleteColumn"
            icon="trash"
            onClick={noop}
          />
        )}
      </div>
    );
  }
}

export default DataGridHeaderRow;
