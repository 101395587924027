import React, { useState } from 'react';
import noop from 'lodash/noop';
import moment from 'moment';
import { StepProps } from '../StepProps';
import WorkflowStep from '../WorkflowStep';
import { LenderApplicationQuestions } from '../modals';
import LenderManualSbaApprovalForm from '../modals/LenderManualSbaApproval/LenderManualSbaApprovalContainer';
import LenderEtranReadyCheck from './LenderEtranReadyCheck';
import sendLenderApplication from 'lsgql/mutations/SendLenderApplication';
import triggerClosingWorkflowTransition from 'lsgql/mutations/TransitionClosingWorkflow';
import raiseAlert from 'lsredux/raiseAlert';
import { Button, Text, TextLink, Divider, Summary } from 'components';
import { invariant, haltBubble } from 'utils';
import { RouteTable } from 'routing';

function LenderLoanApplication(props: StepProps) {
  const { step, isDimmed, canEdit, dealClosingWorkflow, deal } = props;
  const { state } = dealClosingWorkflow;

  invariant(step.slug === 'aw_lender_lender_loan_application', 'invalid slug');

  const [isQuestionsModalOpen, setIsQuestionsModalOpen] = useState(false);

  const [isManualApprovalModalOpen, setIsManualApprovalModelOpen] = useState(
    false,
  );

  const handleTransitionState = (state: string) => {
    triggerClosingWorkflowTransition({
      workflowId: dealClosingWorkflow.id,
      state,
    });
  };

  const handleDocuSignRequest = async () => {
    const res = await sendLenderApplication({
      workflowId: dealClosingWorkflow.id,
      returnUrl: window.location.href,
    });
    if (res?.data?.sendLenderApplication?.ok) {
      window.location.href = res?.data?.sendLenderApplication?.redirectUrl;
    } else {
      raiseAlert(
        "The request has failed. Please try again. If that doesn't work, please contact technical support",
        'error',
      );
    }
  };

  const handleAlreadySigned = (e: React.SyntheticEvent) => {
    haltBubble(e);
    handleTransitionState('lender_submit_to_sba');
  };

  const handleSubmitToSba = (e: React.SyntheticEvent) => {
    haltBubble(e);
    handleTransitionState('lender_awaiting_sba_response');
  };

  const handleDenied = (e: React.SyntheticEvent) => {
    haltBubble(e);
    handleTransitionState('lender_rejected_by_sba');
  };

  const handleBackToBorrowerApplication = (e: React.SyntheticEvent) => {
    haltBubble(e);
    handleTransitionState('lender_approve_borrower_application');
  };

  const support = (
    <TextLink
      label="support@loan-street.atlassian.net"
      to="mailto:support@loan-street.atlassian.net"
    />
  );

  const editApp = (
    <Button.Text
      className="noMinWidth"
      label="here"
      to={RouteTable.deal.toDealSbaLenderApplication(deal.id)}
    />
  );

  // SBA Origination stuff
  const sbaOrigination = deal?.sbaoriginationstate;

  const lastSbaUpdateDate: string | undefined = sbaOrigination?.sbaDecisionDate
    ? moment(sbaOrigination?.sbaDecisionDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
    : undefined;

  function getContent() {
    if (isDimmed) {
      return (
        <>
          <Text>
            1. Please complete the lender loan application for signature and
            submission.
          </Text>
          <Text>2. Sign the completed application.</Text>
          <Text>3. Send the completed and signed application to the SBA.</Text>
        </>
      );
    } else if (state === 'lender_complete_lender_application') {
      return (
        <>
          <Text>
            1. Please complete the lender loan application for signature and
            submission.
          </Text>
          <Button
            label="Complete Lender Loan Application"
            to={RouteTable.deal.toDealSbaLenderApplication(deal.id)}
          />
          <Text>
            <strong>
              2. Confirm that the assignee is an Authorized signer,{' '}
            </strong>
            then sign the completed application.
          </Text>
          <Text>3. Send the completed and signed application to the SBA.</Text>
        </>
      );
    } else if (
      state === 'lender_review_lender_application' ||
      state === 'lender_sign_lender_application'
    ) {
      return (
        <>
          <div className="Group left">
            <div>
              1. The application is ready for signature and submission. You can
              make any changes&nbsp;
            </div>
            {editApp}.
          </div>
          <Text>
            <strong>
              2. Confirm that the assignee is an Authorized signer,{' '}
            </strong>
            then sign the completed application.
          </Text>
          <div className="Group left padded">
            <Button.Primary
              // disabled={props.deal.category === 'SBA_PPP_ROUND_TWO_SECOND_DRAW'}
              label="Sign the Completed Application"
              onClick={handleDocuSignRequest}
            />
            <Button.Text
              label="Or, already signed"
              onClick={handleAlreadySigned}
            />
          </div>
          <Text>3. Send the completed and signed application to the SBA.</Text>
        </>
      );
    } else if (state === 'lender_submit_to_sba') {
      return (
        <>
          <div className="Group left">
            <div>
              1. The application is ready for submission. You can make any
              changes&nbsp;
            </div>
            {editApp}.
          </div>
          <Text>2. The completed application was signed.</Text>
          <Text>3. Submit the application to the SBA.</Text>
          <LenderEtranReadyCheck
            deal={deal}
            dealClosingWorkflow={dealClosingWorkflow}
            handleDenied={handleDenied}
            handleSubmitToSba={handleSubmitToSba}
            id={deal.id}
            onOpenLenderForm={() => setIsQuestionsModalOpen(true)}
            onReadyChange={noop}
            setIsManualApprovalModelOpen={() =>
              setIsManualApprovalModelOpen(true)
            }
          />

          <div className="Group justified">
            <Button
              disabled={props.dealClosingWorkflow.disableSbaSubmit}
              label="Submit Application to the SBA"
              onClick={handleSubmitToSba}
            />
            <Button.Text
              label="Or, already submitted and approved"
              onClick={() => setIsManualApprovalModelOpen(true)}
            />
            <Button.Text label="(Or denied)" onClick={handleDenied} />
          </div>
        </>
      );
    } else if (state === 'lender_awaiting_sba_response') {
      if (sbaOrigination?.isOnHold) {
        return (
          <>
            <Text>
              The SBA has sent the following update regarding your application
              at{' '}
              {lastSbaUpdateDate ??
                moment(sbaOrigination?.modified, 'YYYY-MM-DD').format(
                  'MM/DD/YYYY',
                )}
              :
            </Text>
            <Text className="bold">
              {sbaOrigination?.errors?.[0] ??
                'View the SBA portal for more information.'}
            </Text>
            <Text>
              Should you need to make any changes or resubmit, please contact{' '}
              {support}.
            </Text>
          </>
        );
      } else {
        return (
          <>
            <Text>
              The application was submitted to the SBA and a response may take
              several days. Should you need to make any changes or resubmit,
              please contact {support}.
            </Text>
            {lastSbaUpdateDate && (
              <Text>Last update from SBA received at {lastSbaUpdateDate}</Text>
            )}
          </>
        );
      }
    } else if (
      state === 'lender_review_promissory_note' ||
      state === 'borrower_sign_promissory_note' ||
      state == 'lender_originate_loan'
    ) {
      return (
        <div className="loanDisplay">
          <div className="Group justified">
            <Text>The loan application has been approved by the SBA.</Text>
            <Button.Text
              label="Edit"
              onClick={() => setIsManualApprovalModelOpen(true)}
            />
          </div>
          <div className="Group left">
            <Summary.TextField
              label="SBA Loan #"
              value={dealClosingWorkflow?.loanfunding?.sbaLoanNumber}
            />
            <Summary.MoneyField
              label="Loan Amount"
              value={dealClosingWorkflow?.loanfunding?.loanAmount}
            />
            <Summary.DateField
              label="SBA Approval Date"
              value={dealClosingWorkflow?.loanfunding?.sbaApprovalDate}
            />
          </div>
        </div>
      );
    } else if (state === 'lender_rejected_by_sba') {
      return (
        <div className="LenderRejected">
          <Text>
            The loan application has been{' '}
            {sbaOrigination?.isErrored
              ? 'returned with errors'
              : 'denied by the SBA'}
            .
          </Text>
          <Text>See below for the explanation provided by the SBA:</Text>
          <div className="reasons">
            {sbaOrigination?.errors?.length > 0 ? (
              sbaOrigination?.errors?.map((error, id) => (
                <p key={`${id}_${error}`}>{error}</p>
              ))
            ) : (
              <p>None given</p>
            )}
          </div>
          <Button
            label="Unlock Borrower Application"
            onClick={handleBackToBorrowerApplication}
          />
          <Text>
            If you think this is a technical issue, please contact {support}.
          </Text>
        </div>
      );
    }
  }

  return (
    <WorkflowStep
      canEdit={canEdit}
      header="Lender Loan Application"
      isDimmed={isDimmed}
      step={step}
    >
      <div className="LenderLoanApplication">
        <LenderApplicationQuestions
          deal={deal}
          isOpen={isQuestionsModalOpen}
          onClose={() => setIsQuestionsModalOpen(false)}
          setIsOpen={setIsQuestionsModalOpen}
          workflowId={dealClosingWorkflow.id}
        />
        <Divider className="largePad" />
        {getContent()}
      </div>
      <LenderManualSbaApprovalForm
        dealClosingWorkflowId={dealClosingWorkflow.id}
        id={dealClosingWorkflow?.loanfunding?.id}
        isOpen={isManualApprovalModalOpen}
        onClose={() => setIsManualApprovalModelOpen(false)}
        setIsOpen={setIsManualApprovalModelOpen}
      />
    </WorkflowStep>
  );
}

export default LenderLoanApplication;
