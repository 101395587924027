import { mutationBuilder } from './builders';
import {
  CreateSbaPppLoanApplicationMutationDefinition,
  SetSbaPppLoanApplicationMutationDefinition,
} from './definitions';
import { SbaPppLoanApplicationInput, SbaPppLoanApplicationType } from 'types';

const PublicSbaPppLoanApplicationMutationMethods = {
  createSbaPppLoanApplication: mutationBuilder<
    SbaPppLoanApplicationInput,
    SbaPppLoanApplicationType
  >(CreateSbaPppLoanApplicationMutationDefinition, {
    build: mutate => sbaPppLoanApplicationData =>
      mutate({ variables: { sbaPppLoanApplicationData } }),
  }),
  setSbaPppLoanApplication: mutationBuilder<
    SbaPppLoanApplicationInput,
    SbaPppLoanApplicationType
  >(SetSbaPppLoanApplicationMutationDefinition, {
    build: mutate => sbaPppLoanApplicationData =>
      mutate({ variables: { sbaPppLoanApplicationData } }),
  }),
};

export default PublicSbaPppLoanApplicationMutationMethods;
