import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'components';
import { FormFieldsType } from 'types';
import { states } from 'resources';

const fields: FormFieldsType = {
  businessLegalName: {
    id: 'businessLegalName',
    propertyName: 'businessLegalName',
    fieldName: 'Business Legal Name',
    width: '332px',
    required: true,
  },
  addressOne: {
    id: 'addressOne',
    propertyName: 'addressOne',
    fieldName: 'Business Primary Address',
    width: '332px',
    required: true,
  },
  city: {
    id: 'city',
    propertyName: 'city',
    fieldName: 'City',
    width: '158px',
    required: true,
  },
  state: {
    id: 'state',
    propertyName: 'state',
    fieldName: 'State',
    placeholder: '--',
    typeName: 'SbaPppInstitutionState',
    width: '52px',
    options: states.map(state => ({
      text: state.abbreviation,
      value: state.abbreviation,
    })),
    required: true,
  },
  zip: {
    id: 'zip',
    propertyName: 'zip',
    fieldName: 'Zip',
    width: '90px',
    required: true,
  },
  phone: {
    id: 'phone',
    propertyName: 'phone',
    fieldName: 'Business Phone',
    width: '168px',
    required: true,
  },
  dbaOrTradename: {
    id: 'dbaOrTradename',
    propertyName: 'dbaOrTradename',
    fieldName: 'DBA or Tradename (if applicable)',
    width: '332px',
  },
  businessTinType: {
    id: 'businessTinType',
    propertyName: 'businessTinType',
    fieldName: 'Business Taxpayer Identification Number (TIN)',
    width: '332px',
    options: [
      { label: 'EIN', id: 'EIN' },
      { label: 'SSN', id: 'SSN' },
    ],
    required: true,
  },
  businessTinEinSsn: {
    id: 'businessTinEinSsn',
    propertyName: 'businessTinEinSsn',
    fieldName: '',
    width: '332px',
  },
  primaryContact: {
    id: 'primaryContact',
    propertyName: 'primaryContact',
    fieldName: 'Primary Contact Name',
    width: '332px',
    required: true,
  },
  email: {
    id: 'email',
    propertyName: 'email',
    fieldName: 'Email Address',
    width: '332px',
    required: true,
  },
  institutionType: {
    id: 'institutionType',
    propertyName: 'institutionType',
    fieldName: 'Is the business a:',
    width: '332px',
    placeholder: 'Please Select',
    options: [
      { text: 'Sole Proprietor', value: 'SOLE_PROPRIETOR' },
      { text: 'Partnership', value: 'PARTNERSHIP' },
      { text: 'C-Corp', value: 'C_CORP' },
      { text: 'S-Corp', value: 'S_CORP' },
      { text: 'LLC', value: 'LLC' },
      { text: 'Independent Contractor', value: 'IND_CONT' },
      { text: 'Eligible Self-Employed Individual', value: 'SELF_EMPLOYED' },
      { text: '501(c)(3) Nonprofit', value: 'NON_PROFIT' },
      { text: '501(c)(19) Veterans Organization', value: 'VET_ORG' },
      {
        text: 'Tribal Business (sec. 31(b)(2)(C) of Small Business Act)',
        value: 'TRIBAL',
      },
      { text: 'Other', value: 'OTHER' },
    ],
    required: true,
  },
};

const SbaPppInstitution = 'SbaPppInstitution';

function Institution(props: any) {
  const { data, mutateProperty } = props;
  const {
    institutionTicker,
    institutionJob,
    disableOnboarding = false,
  } = useParams();

  useEffect(() => {
    mutateProperty(institutionTicker, 'institutionTicker');
    mutateProperty(institutionJob, 'institutionJob');
    mutateProperty(disableOnboarding, 'disableOnboarding');
  }, [institutionTicker, institutionJob, mutateProperty, disableOnboarding]);

  const placeholderBusinessTinEinSsn = data.businessTinEinSsn
    ? '*********'
    : 'Enter EIN or SSN, numbers only';

  return (
    <div className="Institution">
      {Form.FieldRenderer(
        Form.Input,
        { ...fields.businessLegalName, onChange: props.mutateProperty },
        props,
        SbaPppInstitution,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Input,
        { ...fields.addressOne, onChange: props.mutateProperty },
        props,
        SbaPppInstitution,
        data.id,
      )}
      <Form.Group>
        {Form.FieldRenderer(
          Form.Input,
          { ...fields.city, onChange: props.mutateProperty },
          props,
          SbaPppInstitution,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Select,
          { ...fields.state, onChange: props.mutateProperty },
          props,
          SbaPppInstitution,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Zip,
          { ...fields.zip, onChange: props.mutateProperty },
          props,
          SbaPppInstitution,
          data.id,
        )}
      </Form.Group>
      {Form.FieldRenderer(
        Form.Phone,
        { ...fields.phone, onChange: props.mutateProperty },
        props,
        SbaPppInstitution,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Input,
        { ...fields.dbaOrTradename, onChange: props.mutateProperty },
        props,
        SbaPppInstitution,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Radio,
        { ...fields.businessTinType, onChange: props.mutateProperty },
        props,
        SbaPppInstitution,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Numeric,
        {
          ...fields.businessTinEinSsn,
          onChange: props.mutateProperty,
          maxLength: 9,
          placeholder: placeholderBusinessTinEinSsn,
        },
        props,
        SbaPppInstitution,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Input,
        { ...fields.primaryContact, onChange: props.mutateProperty },
        props,
        SbaPppInstitution,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Input,
        { ...fields.email, onChange: props.mutateProperty },
        props,
        SbaPppInstitution,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Select,
        { ...fields.institutionType, onChange: props.mutateProperty },
        props,
        SbaPppInstitution,
        data.id,
      )}
    </div>
  );
}

export default Institution;
