import { SbaPppForgivenessWorkflowState } from 'types';

export const StateMap: { [key: string]: SbaPppForgivenessWorkflowState } = {
  UNSUBMITTED: 'UNSUBMITTED',
  CHANGES_REQUESTED: 'CHANGES_REQUESTED',
  CHANGE_SUBMITTED: 'CHANGE_SUBMITTED',
  IN_REVIEW: 'IN_REVIEW',
  BORROWER_SIGNATURE_REQUESTED: 'BORROWER_SIGNATURE_REQUESTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  SBA_PENDING_VALIDATION: 'SBA_PENDING_VALIDATION',
  SBA_UNDER_REVIEW: 'SBA_UNDER_REVIEW',
  SBA_FULLY_APPROVED: 'SBA_FULLY_APPROVED',
  SBA_NOT_APPROVED: 'SBA_NOT_APPROVED',
  SBA_PARTIALLY_APPROVED: 'SBA_PARTIALLY_APPROVED',
  SBA_LENDER_ADDITIONAL_INFO_REQUESTED: 'SBA_LENDER_ADDITIONAL_INFO_REQUESTED',
  SBA_PAYMENT_SENT: 'SBA_PAYMENT_SENT',
  SBA_PAYMENT_CONFIRMED: 'SBA_PAYMENT_CONFIRMED',
  SBA_PAYMENT_FAILED: 'SBA_PAYMENT_FAILED',
};
