import { fromJS, Map } from 'immutable';
import {
  CHANGE_PAGE,
  CHANGE_SCALE,
  CHANGE_ROTATION,
} from '../../actions/termsheet';
import standardReducerFactory from '../standardReducer';

export const initialPdfProps = {
  pageIndex: 0,
  scale: 1.5,
  rotations: Map<number, number>(),
};
const initialState = fromJS({
  pdfProps: {},
});

const reducerMap = {};

reducerMap[CHANGE_PAGE] = (state, action) =>
  state.updateIn(['pdfProps'], pdfProps =>
    pdfProps.setIn([action.payload.id, 'pageIndex'], action.payload.pageIndex),
  );

reducerMap[CHANGE_SCALE] = (state, action) =>
  state.updateIn(['pdfProps'], pdfProps =>
    pdfProps.setIn([action.payload.id, 'scale'], action.payload.scale),
  );

reducerMap[CHANGE_ROTATION] = (state, action) =>
  state.updateIn(['pdfProps'], pdfProps =>
    pdfProps.setIn([action.payload.id, 'rotations'], action.payload.rotations),
  );

export default standardReducerFactory(reducerMap, initialState);
