import * as React from 'react';
import uuid from 'uuid/v4';
import FeePrepaymentItem from './FeePrepaymentItem';
import { Button } from 'components';
import { LoanTrancheType, PrepaymentPenaltyRangeType } from 'types';
import { KeyPath } from 'lsredux';

type Props = {
  addEntity: (keyPath: KeyPath, entity: PrepaymentPenaltyRangeType) => void;
  loanTranche: LoanTrancheType;
  removeEntity: (keyPath: KeyPath, entity: any) => void;
  replaceEntity: (keyPath: KeyPath, entity: any) => void;
  trancheIndex: string;
};

class FeePrepayment extends React.Component<Props> {
  handleMonthChange: (
    range: PrepaymentPenaltyRangeType,
    value: string | null | undefined,
  ) => void;

  handlePenaltyChange: (
    range: PrepaymentPenaltyRangeType,
    value: string | null | undefined,
  ) => void;

  constructor(props: Props) {
    super(props);
    this.handleMonthChange = this.getChangeHandler('month').bind(this);
    this.handlePenaltyChange = this.getChangeHandler('penalty').bind(this);
  }

  getChangeHandler = (property: 'month' | 'penalty') => (
    range: PrepaymentPenaltyRangeType,
    value: string | null | undefined,
  ) => {
    const { replaceEntity, trancheIndex } = this.props;
    const prepaymentPenaltyRangeSetKeyPath = [
      'loantrancheSet',
      trancheIndex,
      'prepaymentpenaltyrangeSet',
    ];
    const newPrepaymentPenaltyRange = { ...range };
    newPrepaymentPenaltyRange[property] = value;
    replaceEntity(prepaymentPenaltyRangeSetKeyPath, newPrepaymentPenaltyRange);
  };

  handleAddPrepaymentPenaltyRange = () => {
    const { addEntity, trancheIndex } = this.props;

    const newPrepaymentPenaltyRange = {
      __typename: 'PrepaymentPenaltyRangeType',
      id: uuid(),
      month: '',
      penalty: '',
    };

    const prepaymentPenaltyRangeSetKeyPath = [
      'loantrancheSet',
      trancheIndex,
      'prepaymentpenaltyrangeSet',
    ];

    addEntity(
      prepaymentPenaltyRangeSetKeyPath,
      newPrepaymentPenaltyRange as any,
    );
  };

  handleDeleteRange = (range: PrepaymentPenaltyRangeType) => {
    const { trancheIndex, removeEntity } = this.props;
    const prepaymentPenaltyRangeSetKeyPath = [
      'loantrancheSet',
      trancheIndex,
      'prepaymentpenaltyrangeSet',
    ];
    removeEntity(prepaymentPenaltyRangeSetKeyPath, range);
  };

  render() {
    const { loanTranche } = this.props;

    const prepaymentpenaltyrangeSet =
      loanTranche.prepaymentpenaltyrangeSet || [];
    if (prepaymentpenaltyrangeSet.length === 0) {
      this.handleAddPrepaymentPenaltyRange();
    }

    return (
      <>
        {prepaymentpenaltyrangeSet.map((range, index) => (
          <FeePrepaymentItem
            key={range.id}
            onDelete={this.handleDeleteRange}
            onMonthChange={this.handleMonthChange}
            onPenaltyChange={this.handlePenaltyChange}
            prepaymentPenaltyRange={range}
            withLabels={index === 0}
          />
        ))}
        <Button.Text
          className="feePrepayment__AddRangeBtn"
          label="+ Add a Period"
          onClick={this.handleAddPrepaymentPenaltyRange}
        />
      </>
    );
  }
}

export default FeePrepayment;
