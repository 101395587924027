import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import {
  LoanTrancheBasicFields,
  EventScheduleShallowFields,
} from './generated';

const EventScheduleFields: DocumentNode = gql`
  fragment EventScheduleFields on EventScheduleType {
    ...EventScheduleShallowFields
    loanTranches {
      ...LoanTrancheBasicFields
    }
  }
  ${LoanTrancheBasicFields}
  ${EventScheduleShallowFields}
`;

export default EventScheduleFields;
