import isEmpty from 'lodash/isEmpty';
import { QueryResult, FetchMethod } from '../types';
import validationDispatch, { InputScrubberMap } from './validationDispatch';
import { DispatchResponse, BatchEntry } from './types';
import parseResponse from './parseResponse';
import { isWholeNumber, invariant } from 'utils';
import {
  BaseType,
  ObjectBaseTypeNames,
  ObjectTypeNames,
  ID,
  ValidationType,
  ValidationMap,
} from 'types';

/**
 * Dispatch all pending mutations to the backend, execute the refresh query,
 * and assemble the final DispatchResponse
 */
async function mutationTreeExecutor<T extends BaseType>(
  deleteMutations: Array<() => Promise<DispatchResponse<any>>>,
  mutationTree: BatchEntry,
  fetch: FetchMethod<T>,
  rootObjectTypeName: ObjectTypeNames,
  rootObjectId: ID,
  enableStrictValidation: boolean,
  validators?: Partial<
    {
      [key in ObjectBaseTypeNames]: (arg0: ID) => Promise<ValidationType>;
    }
  >,
  inputScrubbers?: InputScrubberMap,
): Promise<DispatchResponse<T>> {
  invariant(fetch, 'fetch method is required');
  invariant(mutationTree, 'Expected a mutationTree');

  const validationResults: ValidationMap = {};
  try {
    /*
      1. Execute the delete mutation batch
    */
    let deleteResult: Array<DispatchResponse<any>> = [];
    try {
      if (deleteMutations && deleteMutations.length > 0) {
        deleteResult = await Promise.all(
          deleteMutations.map(toDelete => toDelete()),
        );
      }
    } catch (ex) {
      // eslint-disable-next-line
      console.error('The DeleteMutation Batch has failed');
      // eslint-disable-next-line
      console.error(ex);
    }

    /*
       2. Execute the mutation tree
        this contains only the response of the root mutation
       We need this object in order to get the id value
      for use in the fetch
    */
    let rootMutationResponse: DispatchResponse<T> | null | undefined;
    try {
      rootMutationResponse = await mutationTree(null, null, validationResults);
    } catch (ex) {
      // eslint-disable-next-line
      console.error('The MutationTree Batch has failed');
      // eslint-disable-next-line
      console.error(ex);
    }

    /*
      3. Determine the id of the root object.

      This value is required for executing the fetch query.  This value
      could have been just created from a create mutation, so we must
      get it from the mutation response
    */
    let id: ID | null | undefined;

    if (isWholeNumber(rootObjectId)) {
      id = rootObjectId;
    } else if (
      rootMutationResponse &&
      rootMutationResponse.entity &&
      isWholeNumber(rootMutationResponse.entity.id)
    ) {
      // eslint-disable-next-line
      id = rootMutationResponse.entity.id;
    }

    /*
    if (!id || !isWholeNumber(id)) {
      throw new Error(
        'mutationTree - Failed to determine id for post-mutation Fetch',
      );
    }
    */

    /*
      4. Execute fetch
       We must re-execute the fetch query in order to get the complete
      updated object tree.
       TODO: Assemble updated diff object from mutations
    */
    let fetched: QueryResult<T> | null | undefined = null;
    let transformed: DispatchResponse<T> | null | undefined;

    try {
      if (id && isWholeNumber(id)) {
        fetched = await fetch({ id, validationResults });
        transformed = parseResponse(
          rootObjectTypeName,
          fetched,
          validationResults,
          id,
        );
      }
    } catch (ex) {
      // eslint-disable-next-line
      console.error('Post-MutationTree Fetch has failed');
      // eslint-disable-next-line
      console.error(ex);
    }

    /*
      5. Execute Validators
       Execute a validator for every object for which a validator is
      available
    */
    try {
      if (enableStrictValidation && validators && transformed) {
        await validationDispatch(
          transformed.entity,
          validators as any,
          validationResults,
          inputScrubbers,
        );
      }
    } catch (ex) {
      // eslint-disable-next-line
      console.error('Post-MutationTree Validation has failed');
      // eslint-disable-next-line
      console.error(ex);
    }

    const res: DispatchResponse<T> = {
      entity: transformed ? transformed.entity : null,
      errors: isEmpty(validationResults) ? null : validationResults,
      ok:
        rootMutationResponse !== undefined &&
        rootMutationResponse !== null &&
        rootMutationResponse.ok === true &&
        deleteResult.every(de => de.ok),
    };

    /* 6. Done */
    return res;
  } catch (ex) {
    // eslint-disable-next-line
    console.error('Mutations Dispatch Failure: ', ex);
    // eslint-disable-next-line
    console.log(ex);
  }
  return { errors: null, entity: null, ok: false };
}

export default mutationTreeExecutor;
