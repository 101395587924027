import * as React from 'react';
import LoanTrancheTierGrid from '../loanTrancheTierGrid/LoanTrancheTierGrid';
import { LoanTrancheTierType } from 'types';
import { Column } from 'components';
import { invariant, isWholeNumber } from 'utils';

type TrancheTierFormAdapterProps = {
  onAddTierRow: (trancheIndex: number, trancheId: string) => void;
  onDeleteTierRow: (trancheIndex: number, tier: LoanTrancheTierType) => void;
  onEditTierRow: (
    rowKey: any,
    column: Column<LoanTrancheTierType, any>,
    value: string,
    row: LoanTrancheTierType,
    trancheIndex: number,
  ) => void;
  canEdit?: boolean;
  tranche: any;

  trancheIndex: number;
};

class TrancheTierFormAdapter extends React.Component<
  TrancheTierFormAdapterProps
> {
  constructor(props: TrancheTierFormAdapterProps) {
    super(props);

    invariant(props.tranche, 'props.tranche is required');
    invariant(
      props.trancheIndex >= 0,
      'props.trancheIndex is required to be >= 0',
    );
    invariant(
      isWholeNumber(props.tranche.id),
      'props.tranche is not a pre-existing tranche',
    );
  }

  handleAddTier = () => {
    // Scoped to an individual tranche
    this.props.onAddTierRow(this.props.trancheIndex, this.props.tranche.id);
  };

  handleDeleteTierRow = (e: LoanTrancheTierType) => {
    this.props.onDeleteTierRow(this.props.trancheIndex, e);
  };

  handleEditTierRow = (
    rowKey: any,
    column: Column<LoanTrancheTierType, any>,
    value: string,
    row: LoanTrancheTierType,
  ) => {
    this.props.onEditTierRow(
      rowKey,
      column,
      value,
      row,
      this.props.trancheIndex,
    );
  };

  render() {
    const { tranche, trancheIndex, canEdit } = this.props;

    return (
      <LoanTrancheTierGrid
        canEdit={canEdit}
        index={trancheIndex}
        loantranchetierSet={tranche.loantranchetierSet}
        name={tranche.name}
        onAddTierRow={this.handleAddTier}
        onDeleteTierRow={this.handleDeleteTierRow}
        onEditTierRow={this.handleEditTierRow}
      />
    );
  }
}

export default TrancheTierFormAdapter;
