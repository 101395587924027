import * as React from 'react';
import { LoanTrancheFormProps } from './types';
import { isWholeNumber } from 'utils';

interface LoanTrancheFormPropsWithId extends LoanTrancheFormProps {
  trancheId: string;
}
interface Props extends LoanTrancheFormPropsWithId {
  children: (props: LoanTrancheFormPropsWithId) => React.ReactNode;
}

class ContainerAggregator extends React.Component<Props> {
  componentDidMount() {
    const { trancheId, isSaving, onSave } = this.props;
    if (!isWholeNumber(trancheId) && !isSaving) onSave();
  }

  render() {
    const { children, ...rest } = this.props;
    return children(rest);
  }
}

export default ContainerAggregator;
