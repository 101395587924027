import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import {
  AmortizationReaderShallowFields,
  LoanTrancheAmortizationShallowFields,
} from './generated';

const AmortizationResponseFields: DocumentNode = gql`
  fragment AmortizationResponseFields on AmortizationResponseType {
    result {
      ...AmortizationReaderShallowFields
      processedPrincipalPayments {
        count
        created
        date
        modified
        principalAfter
        principalAmount
        principalBefore
        interestAmount
      }
      loanTrancheAmortization {
        ...LoanTrancheAmortizationShallowFields
      }
    }
    amortizationErrors {
      errors
      errorFields {
        name
        value
      }
      warnings
      warningFields {
        name
        value
      }
    }
  }
  ${AmortizationReaderShallowFields}
  ${LoanTrancheAmortizationShallowFields}
`;

export default AmortizationResponseFields;
