import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import { LoanSummaryShallowFields } from '../fragments';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { LoanSummaryType } from 'types';

const LoanSummaryQueryDefinition: DocumentNode = gql`
  query LoanSummaryQuery($id: ID) {
    loanSummary(id: $id) {
      ...LoanSummaryShallowFields
      loanPurpose
    }
  }
  ${LoanSummaryShallowFields}
`;

const LoanSummaryQuery = standardQueryBuilder(LoanSummaryQueryDefinition, {
  typename: 'LoanSummary',
  skip: (props: { dealId?: string | null }) => !props.dealId,
  variables: (props: { dealId?: string | null }) => ({ id: props.dealId }),
  results: (data: { loanSummary?: LoanSummaryType | null }) => data,
});

export default LoanSummaryQuery;
