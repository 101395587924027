import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import DocumentFields from './DocumentFields';
import { FolderShallowFields } from './generated';

const FolderFields: DocumentNode = gql`
  fragment FolderFields on FolderType {
    ...FolderShallowFields
    parent {
      id
    }
    files {
      ... on DocumentType {
        ...DocumentFields
      }
      ... on FolderType {
        id
        parent {
          id
        }
        name
        path
        fileType
        modified
      }
    }
  }
  ${FolderShallowFields}
  ${DocumentFields}
`;

export default FolderFields;
