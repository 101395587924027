import uuid from 'uuid/v4';
import {
  AdditionalSbaPppBorrowerInformationMutationMethods,
  PrivateSbaPppLoanApplicationMutationMethods,
  PrivateSbaPppInstitutionMutationMethods,
  PrivateSbaPppApplicantOwnershipMutationMethods,
  PrivateSbaPppLoanFinancialsMutationMethods,
} from '../../../graphql';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import { DealType } from 'types';

const initialSbaPppLoanApplication: DealType = {
  id: uuid(),
  __typename: 'DealType',
  additionalSbaPppBorrowerInformation: {
    id: uuid(),
    __typename: 'AdditionalSbaPppBorrowerInformationType',
  },
};

const lifecycle = {
  mutations: {
    ...AdditionalSbaPppBorrowerInformationMutationMethods,
    ...PrivateSbaPppLoanApplicationMutationMethods,
    ...PrivateSbaPppInstitutionMutationMethods,
    ...PrivateSbaPppApplicantOwnershipMutationMethods,
    ...PrivateSbaPppLoanFinancialsMutationMethods,
  },
  validators: {},
};

export default createForm<DealType, DealType>(
  Directory.AdditionalSbaPppBorrowerInformationKey,
  initialSbaPppLoanApplication,
  lifecycle,
);
