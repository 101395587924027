import * as React from 'react';
import cx from 'classnames';
import { DealClosingWorkflowStepType } from 'types';
import { Header, Divider } from 'components';

import '../DealClosingWorkflow.scss';

type Props = {
  header: string;
  step: DealClosingWorkflowStepType;
  children: React.ReactNode;
  canEdit?: boolean;
  isDimmed?: boolean;
};

function WorkflowStep(props: Props) {
  const { canEdit, isDimmed } = props;

  const className = cx('DealClosingWorkflowStep', { canEdit, isDimmed });
  return (
    <div className={className}>
      <Divider thin />
      <Header as="h5" className="caps">
        {props.header}
      </Header>
      {props.children}
    </div>
  );
}

export default WorkflowStep;
