import uuid from 'uuid/v4';
import { InstitutionInvitationMutationMethods } from '../../../graphql';
import { createForm } from '../../genericForms';
import Directory from '../../directory';
import { InstitutionInvitationsInput } from 'types';

export const initialInput: InstitutionInvitationsInput = {
  __typename: 'InstitutionInvitationsInput',
  invitees: [],
  id: uuid(),
  institutionDealRoleId: null,
  isLeaf: true,
} as any;

const InstitutionInviteFormRedux = createForm<
  InstitutionInvitationsInput,
  InstitutionInvitationsInput
>(Directory.InstitutionInviteKey, initialInput, {
  mutations: InstitutionInvitationMutationMethods,
  validators: {},
});
export default InstitutionInviteFormRedux;
