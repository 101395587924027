import * as React from 'react';

import { CalendarActionButtons } from '../../features/calendar';
import { DealPeopleActionButtons } from '../../features/dealPeople';

import DataroomDealActionButtons from '../../features/dataroom/actionButtons/DataroomDealActionButtons';
import DealTermsActionButtons from '../../features/applicationContext/topbarNavigation/headers/DealHeaderActions/DealTermsActionButtons';
import LoanTrancheActionButtons from '../../features/applicationContext/topbarNavigation/headers/DealHeaderActions/LoanTrancheActionButtons';
import PdfViewerActionButtons from '../../features/pdfViewer/PdfViewerActionButtons';

import { RoutingContext } from '../types';
import { RouteTable } from '../RouteTable';
import { SecuredRoute } from './RouteRenderers';
import { DealPerspectivePermissionSets } from 'security';

/* eslint-disable react/display-name */
const DealActionButtonRoutes: RoutingContext = {
  routes: [
    {
      render: SecuredRoute(
        () => <DataroomDealActionButtons />,
        DealPerspectivePermissionSets.view_dataroom,
      ),
      path: RouteTable.deal.toDealDataroom(),
      exact: true,
    },
    {
      render: () => <DealTermsActionButtons />,
      path: RouteTable.deal.toLoanOverviewSummary(),
      exact: true,
    },
    {
      render: () => <PdfViewerActionButtons />,
      path: RouteTable.deal.toDealTermSheet(),
      exact: true,
    },
    {
      render: () => <CalendarActionButtons onCalendarPage />,
      path: RouteTable.deal.toDealCalendar(),
      exact: true,
    },
    {
      render: () => <DealPeopleActionButtons />,
      path: RouteTable.deal.toDealPeople(),
      exact: false,
    },
    {
      render: () => <LoanTrancheActionButtons />,
      path: RouteTable.deal.toDealLoanTrancheEdit(),
      exact: true,
    },
  ],
};

export default DealActionButtonRoutes;
