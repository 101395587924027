import { gql } from '@apollo/client';
import { PaymentDueShallowFields } from '../fragments';

// import PaymentsScheduleQueryDefinition from './definitions/PaymentsSchedule';

import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { PaymentDueType } from 'types';

type OwnProps = {
  fromDate: string | null | undefined;
  toDate: string | null | undefined;
  trancheId: string | null | undefined;
};

const PaymentScheduleQueryDefinition = gql`
  query PaymentScheduleQuery(
    $loanTrancheId: ID!
    $firstDate: Date
    $lastDate: Date
  ) {
    paymentSchedule(
      loanTrancheId: $loanTrancheId
      firstDate: $firstDate
      lastDate: $lastDate
    ) {
      ...PaymentDueShallowFields
      paymentrecord {
        id
      }
    }
  }
  ${PaymentDueShallowFields}
`;

const PaymentScheduleQuery = standardQueryBuilder(
  PaymentScheduleQueryDefinition,
  {
    notifyOnNetworkStatusChange: true,
    disableCache: true,
    typename: 'PaymentDueType',
    variables: (props: OwnProps) => {
      const { trancheId, fromDate, toDate } = props;
      return {
        loanTrancheId: trancheId,
        firstDate: fromDate,
        lastDate: toDate,
      };
    },
    results: (data: {
      paymentSchedule: Array<PaymentDueType> | null | undefined;
    }) => {
      const { paymentSchedule } = data;
      return { paymentSchedule: paymentSchedule || [] };
    },
  },
);

export default PaymentScheduleQuery;
