import { fromJS } from 'immutable';
import {
  UPLOAD_START,
  UPLOAD_SUCCESS_OR_FAILURE,
} from '../../actions/uploading';
import standardReducerFactory from '../standardReducer';

const initialState = fromJS({
  termsheet: false,
  dataroom: false,
});

const reducerMap: {
  [key: string]: (state: any, action: any) => Record<string, any>;
} = {};

reducerMap[UPLOAD_START] = (state, action) =>
  state.set(action.payload.uploadType, true);

reducerMap[UPLOAD_SUCCESS_OR_FAILURE] = (state, action) =>
  state.set(action.payload.uploadType, false);

export default standardReducerFactory(reducerMap, initialState);
