import { MutationMethod } from '../types';
import { inputScrubber } from './utils';
import { mutationBuilder } from './builders';
import { MutationExecutor } from './builders/mutationBuilder';
import { GenerateDebugDealMutationDefinition } from './definitions';
import { GenerateDebugDealInput, DealType, UserRoleType } from 'types';

function ScrubUserRoleInput(
  e: UserRoleType,
): { roleId: string; userId: string } {
  return { roleId: e.roleId || '', userId: e.userId || '' };
}

const GenerateDebugDeal: MutationMethod<
  GenerateDebugDealInput,
  DealType
> = mutationBuilder<GenerateDebugDealInput, DealType>(
  GenerateDebugDealMutationDefinition,
  {
    build: (mutate: MutationExecutor<DealType>) => (
      generateDebugDealData: GenerateDebugDealInput,
    ) =>
      mutate({
        variables: {
          ...inputScrubber(generateDebugDealData),
          dealData: inputScrubber(generateDebugDealData.dealData),
          leadLenderRoleList: generateDebugDealData.leadLenderRoleList.map(
            ScrubUserRoleInput,
          ),
          servicingAgentRoleList: generateDebugDealData.servicingAgentRoleList.map(
            ScrubUserRoleInput,
          ),
          borrowerRoleList: generateDebugDealData.borrowerRoleList.map(
            ScrubUserRoleInput,
          ),
        },
      }),
  },
);

export default GenerateDebugDeal;
