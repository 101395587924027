import * as React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import {
  AccessDeniedGuard,
  PermissionDefinition,
  DisabledChildProps,
} from 'security';
import NoAccess from 'security/authorization/noAccess/NoAccess';

/* eslint-disable react/display-name, react/display-name */

export function RenderWithRouter(
  Component: React.ComponentType<RouteComponentProps>,
) {
  return (router: RouteComponentProps) => <Component {...router} />;
}

/**
 * Route Securing HoC.
 * @param {*} SecuredComponent
 * @param {*} permission
 * @param {*} fallbackPath
 */
export function SecuredRoute(
  SecuredComponent: React.ComponentType<any>,
  permission: PermissionDefinition | Array<PermissionDefinition>,
  fallbackPath?: string | ((...args: string[]) => string),
) {
  return function SecuredRouteComponent(router: RouteComponentProps) {
    const renderChildren = (security: DisabledChildProps) => {
      const params: { [key: string]: string } = router.match?.params;

      if (!security.accessDenied) {
        return <SecuredComponent {...router} />;
      } else {
        if (fallbackPath) {
          // `fallbackPath` should be a `RouteTable` value that is either a string literal or a function that accepts the same path parameters as from the calling path.
          const fallbackRoute =
            typeof fallbackPath == 'string'
              ? fallbackPath
              : fallbackPath(...Object.values(params));
          return <Redirect to={fallbackRoute} />;
        } else {
          return <NoAccess />;
        }
      }
    };

    return (
      <AccessDeniedGuard permission={permission}>
        {renderChildren}
      </AccessDeniedGuard>
    );
  };
}
