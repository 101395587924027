import { DealType } from 'types';
import { WrappedMediatorFormProps } from 'components';

export type CalculatedValuesType = {
  modifiedTotal: string;
  payrollCostRequirement: string;
  pppLoanAmount: string;
  payrollCostsCalculated: string;
  totalAverageFteFteReduction: string;
  fteReductionQuotientFteReduction: string;
  forgivenessAmount: string;
};

export enum HelpBoxType {
  standardMain = 'standardMain',
  ezMain = 'ezMain',
  lte100 = 'lte100',
  gt100 = 'gt100',
  nonCash = 'nonCash',
  ownerCompensation = 'ownerCompensation',
  payrollTotal = 'payrollTotal',
  payrollTotalEz = 'payrollTotalEz',
  nonPayroll = 'nonPayroll',
  fteReduction = 'fteReduction',
  forgivenessAmount = 'forgivenessAmount',
  sForm = 'sForm',
}

type ForgivenessCalculationType = DealType & {
  modifiedTotal: string;
  payrollCostRequirement: string;
  pppLoanAmount: string;
  payrollCostsCalculated: string;
  totalAverageFteFteReduction: string;
  fteReductionQuotientFteReduction: string;
  forgivenessAmount: string;
};
export type ForgivenessFormProps = WrappedMediatorFormProps<
  ForgivenessCalculationType
>;
