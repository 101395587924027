import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getHeader, getBody } from '../Resources';
import DealClosingWorkflowStep from './DealClosingWorkflowStep';
import LoanFunding from './LoanFunding';
import {
  Form,
  Text,
  Header,
  WrappedMediatorFormProps,
  Button,
  ConfirmModal,
} from 'components';
import {
  DealClosingWorkflowStepType,
  DealClosingWorkflowType,
  DealType,
  ID,
  ValidationMap,
} from 'types';

import '../DealClosingWorkflow.scss';

interface Props extends WrappedMediatorFormProps<DealType> {
  dealClosingWorkflow: DealClosingWorkflowType;
  closeDeal: (dealId: ID) => void;
  updateStep: (stepData: DealClosingWorkflowStepType) => void;
  updateLoanFunding: (property: string, value: string) => void;
  disabled: boolean;
  canClose: boolean;
  errors: ValidationMap;
}

function LegacyLenderWorkflow(props: Props) {
  const { dealId } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    data: { dealClosingWorkflow },
  } = props;

  function handleOrigination() {
    if (modalOpen) {
      setModalOpen(false);
      props.closeDeal(dealId);
    } else setModalOpen(true);
  }

  function handleCloseModal() {
    setModalOpen(false);
  }

  function confirmationModalBody() {
    return (
      <div>
        <b>Are you ready to originate this loan?</b>
        <p>
          When you click Originate Loan, you will no longer be able to make any
          changes.
        </p>
      </div>
    );
  }

  const steps = dealClosingWorkflow?.steps;
  if (!steps || steps.length === 0) return null;

  const loanFunding = dealClosingWorkflow?.loanfunding;

  return (
    <div className="LenderWorkflow">
      {steps.map(step => {
        return (
          <div className="section" key={step.slug}>
            <div className="header">
              <Form.Divider />
              <Header as="h5" className="caps steel">
                {getHeader(step.slug)}
              </Header>
            </div>
            {step.slug !== 'lender-borrower-required-docs' ? (
              <Text className="steel" value={getBody(step.slug)} />
            ) : (
              <>
                <div className="readOnlyText">
                  <p className="textLine steel">
                    The borrower needs to upload supporting documentation about
                    their business, owners and loan eligibility to the Dataroom
                    for your review.
                  </p>
                </div>
                <div className="readOnlyText">
                  <p className="textLine steel">
                    If they answered YES to questions 3 or 4 on their
                    application, they must provide:
                  </p>
                </div>
                <div className="readOnlyText">
                  <ul>
                    <li className="textLine steel">
                      Addendum A: List and relationship of other owned/managed
                      businesses
                    </li>
                    <li className="textLine steel">
                      Addendum B: Details of SBA Economic Injury Disaster Loan
                    </li>
                  </ul>
                </div>
              </>
            )}
            <DealClosingWorkflowStep
              data={step}
              disabled={props.disabled}
              updateStep={props.updateStep}
            />
          </div>
        );
      })}
      <div className="section">
        <div className="header">
          <Form.Divider />
          <Header as="h5" className="caps steel">
            Loan Funding
          </Header>
        </div>
        <Text
          className="steel"
          value="Confirm the SBA Loan #, Loan Amount and Funding Date below. Then, click Originate Loan to close the loan!"
        />
        {loanFunding && (
          <LoanFunding
            data={loanFunding}
            disabled={props.disabled}
            errors={props.errors}
            updateLoanFunding={props.updateLoanFunding}
          />
        )}
        <ConfirmModal
          confirmButtonText="Originate Loan"
          header="Confirm Loan Origination"
          isOpen={modalOpen}
          message={confirmationModalBody()}
          onConfirm={handleOrigination}
          onReject={handleCloseModal}
        />
        <Button
          className="submit"
          disabled={!props.canClose}
          label="Originate Loan"
          onClick={handleOrigination}
        />
      </div>
    </div>
  );
}

export default LegacyLenderWorkflow;
