import * as React from 'react';
import noop from 'lodash/noop';
import { Form, IconButton } from 'components';
import { hoverMethods } from 'utils';
import { ApplicableMarginType, FieldRendererGeneralPropsType } from 'types';

import './ApplicableMarginsItem.scss';

type Props = FieldRendererGeneralPropsType & {
  applicableMarginType: 'abr' | 'libor' | 'prime' | 'fedFunds';
  data: ApplicableMarginType;
  handleRemoveApplicableMarginsItem: () => void;
  isFirstSelected: boolean;
  onChange: (margin: ApplicableMarginType) => void;
  showDelete: boolean;
};

type HoverableState = { isHovered: boolean };

const applicableMargin = 'ApplicableMargin';

const baseClassName = 'ApplicableMarginsItem';
const levelTextClassName = `${baseClassName}__LevelText`;
const nameInputClassName = `${baseClassName}__NameInput`;
const deleteButtonClassName = `${baseClassName}__DeleteButton`;

class ApplicableMarginsItem extends React.Component<Props, HoverableState> {
  marginKey = `${this.props.applicableMarginType}Margin`;

  handleUpdateApplicableMargin = (
    value: string | null | undefined,
    propertyName: string,
  ) => {
    this.props.onChange({ ...this.props.data, [propertyName]: value });
  };

  fields = {
    level: {
      id: 'level',
      propertyName: 'level',
      onChange: noop,
      disabled: true,
      className: levelTextClassName,
      width: '90px',
    },
    name: {
      id: 'name',
      propertyName: 'name',
      onChange: noop,
      className: nameInputClassName,
      width: '156px',
    },
    marginLevel: {
      id: `${this.props.applicableMarginType}Margin`,
      propertyName: `${this.props.applicableMarginType}Margin`,
      onChange: noop,
      width: '120px',
    },
  };

  /* eslint-enable react/sort-comp */

  constructor(props: Props) {
    super(props);
    this.state = { isHovered: false };
    this.handleHoverLeave = hoverMethods.handleHoverLeave.bind(this);
    this.handleMouseEnter = hoverMethods.handleMouseEnter.bind(this);
    this.handleMouseLeave = hoverMethods.handleMouseLeave.bind(this);
    this.handleMouseMove = hoverMethods.handleMouseMove.bind(this);
  }

  handleHoverLeave: () => void;

  handleMouseEnter: () => void;

  handleMouseLeave: () => void;

  handleMouseMove: (e: React.MouseEvent<any>) => void;
  render() {
    const {
      isFirstSelected,
      data,
      handleRemoveApplicableMarginsItem,
      disabled,
      showDelete,
    } = this.props;
    const { isHovered } = this.state;

    return (
      <Form.Group
        className="lsFormGroup--Lowered"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {Form.FieldRenderer(
          Form.Text,
          {
            ...this.fields.level,
            value: data.level,
          },
          this.props,
          applicableMargin,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Input,
          {
            ...this.fields.name,
            value: data.name,
            onChange: this.handleUpdateApplicableMargin,
            disabled: !isFirstSelected,
            disabledReadable: !isFirstSelected,
          },
          this.props,
          applicableMargin,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Percentage,
          {
            ...this.fields.marginLevel,
            value: data[this.marginKey],
            onChange: this.handleUpdateApplicableMargin,
          },
          this.props,
          applicableMargin,
          data.id,
        )}
        {isHovered && isFirstSelected && !disabled && showDelete && (
          <IconButton
            alt="Remove Margin Level"
            className={deleteButtonClassName}
            icon="trash"
            onClick={handleRemoveApplicableMarginsItem}
          />
        )}
      </Form.Group>
    );
  }
}

export default ApplicableMarginsItem;
