import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { ReportsQueryDefinition } from './definitions';
import { ReportType } from 'types';

const ReportsQuery = standardQueryBuilder(ReportsQueryDefinition, {
  typename: 'Report',
  results: (data: { reports: ReportType[] }) => data,
  variables: ({ reportType }: { reportType: string }) => ({
    reportType,
  }),
  disableCache: true,
});

export default ReportsQuery;
