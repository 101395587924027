import groupBy from 'lodash/groupBy';
import moment from 'moment';
import {
  SortBy,
  DataRoomBase,
  RoomMap,
  FolderMap,
  OpenMap,
  DataroomRowData,
} from '../types';
import { lsSort, invariant } from 'utils';
import { PermissionsBaseType, FolderType } from 'types';

type IDictionary<V> = {
  [key: string]: V;
};
const typeNameField = '__typename';
const empty = [];
const folderTypeName = 'FolderType';

const momentFormat = 'MMM D, YYYY';

const dataroomPropMap = {
  modified: 'modifiedPosixTimestamp',
};

/**
 * Sort the Array and precompute the modified date format, storing into
 * the dataString property
 *
 * @param {*} entries
 * @param {*} sortBy
 */
function sortCollection<T extends DataRoomBase>(
  entries: T[],
  sortBy: SortBy,
  percentMap: Map<string, number>,
  newFileSet: Set<string>,
): T[] {
  // the initial list is already sorted by name, in ascending order
  // if (sortBy.column === 'name' && !sortBy.reverse) return entries;

  const converted = entries.map(e => {
    const res: T = ({
      ...e,
      dateString: moment(e.modified).format(momentFormat),
      percent:
        percentMap.get(e.id) !== undefined ? percentMap.get(e.id) : undefined,
      isNew: newFileSet.has(e.id),
    } as any) as T;
    return res;
  });

  const folders = converted.filter(x => x[typeNameField] === 'FolderType');
  const files = converted.filter(x => x[typeNameField] !== 'FolderType');
  return [
    ...lsSort(folders, sortBy, dataroomPropMap),
    ...lsSort(files, sortBy, dataroomPropMap),
  ] as T[];
}

/**
 * Recursively process the folder tree, creating a FolderMap object for each folder.
 * The resulting FolderMap is placed into the roomMap object: [folderId: string]: FolderMap
 *
 * @param {*} folderId
 * @param {*} childrenByParentId
 * @param {*} roomMap
 * @param {*} filesByParentId
 */
function processFolderEntity<T extends DataRoomBase>(
  folderId: string,
  childrenByParentId: IDictionary<T[]>,
  roomMap: RoomMap<T>,
  filesByParentId: IDictionary<T[]>,
  newFileSet: Set<string>,
): number {
  // if a folder is already in the map, we've already processed it, just return it's count
  if (roomMap[folderId]) return roomMap[folderId].fileCount;

  // retrieve the full child array, folders & files, from the byParent map
  const children: T[] = childrenByParentId[folderId] || empty;

  // only files are included in child count
  let fileCount: number = filesByParentId[folderId]
    ? filesByParentId[folderId].length
    : 0;
  let folderCount = 0;
  let uploadingCount = 0;
  let completedUploadCount = 0;

  // recursively process all folders that are children of the current folder
  children.forEach(child => {
    if (child[typeNameField] === folderTypeName) {
      fileCount += processFolderEntity(
        child.id,
        childrenByParentId,
        roomMap,
        filesByParentId,
        newFileSet,
      );
      folderCount += 1;
    } else if (!Number.isInteger(Number(child.id))) {
      uploadingCount += 1;
    } else if (newFileSet.has(child.id)) {
      completedUploadCount += 1;
    }
  });

  // eslint considers this re-assignment, but thats precisely the behavior we need here
  // eslint-disable-next-line
  roomMap[folderId] = {
    children,
    fileCount,
    folderCount,
    uploadingCount,
    completedUploadCount,
  } as FolderMap<T>;

  return fileCount;
}

/**
 * William Cheng 3-22-19:
 * This is a function to build a level map that allows us to determine which rows should be highlighted.
 * @param dataList: The final array of objects that will be rendered to the dataroom table.
 *
 * @param byParentFolder: This is purely to check whether or not a folder is empty. IsOpen (derived from
 * the openMap is not enough because if you delete all files within a folder, it will remain "open" until a refresh
 */
// This is purely to facilitate unit testing.
type LevelMapDataType = {
  isOpen: boolean;
  item: {
    __typename: string;
    id: string;
  };
  level: number;
};

function buildLevelMap(
  dataList: Array<LevelMapDataType>,
  byParentFolder: IDictionary<Array<DataRoomBase>>,
) {
  const traversalStack = [];
  const final = {};

  let prevLevel = 0;
  let popData;

  dataList.forEach((data, index) => {
    const isLastRow = index === dataList.length - 1;

    /**
     *  Two conditions to let us check whether or not we need to pop:
     *    1. We reach the last row, in that case we will pop everything on the remaining stack
     *    2. There was a change in direction so we will pop our traversal stack until there are only
     *      rows that have a lower level left.
     */
    if (traversalStack.length > 0 && (data.level < prevLevel || isLastRow)) {
      if (isLastRow) {
        traversalStack.forEach(pop => {
          if (pop.level >= data.level) {
            final[pop.item.id] = index - 1;
          } else {
            final[pop.item.id] = index;
          }
        });
      } else {
        /**
         * Pop and record until we hit a row with a lower level than the row we are on
         */
        while (
          traversalStack.length > 0 &&
          traversalStack[traversalStack.length - 1].level >= data.level
        ) {
          popData = traversalStack.pop();
          final[popData.item.id] = index - 1;
        }
      }
    }

    /**
     * If we have an open folder, push to stack. We are not concerned about rows that are
     * closed folders or files
     */
    if (
      data.item[typeNameField] === folderTypeName &&
      data.isOpen &&
      byParentFolder[data.item.id].length > 0
    ) {
      traversalStack.push(data);
    }

    prevLevel = data.level;
  });
  return final;
}

function walkTreeAndAppend(
  node: DataRoomBase,
  openMap: OpenMap,
  final: Array<DataroomRowData>,
  level: number,
  byParentFolder: IDictionary<Array<DataRoomBase>>,
  permissionsObject: PermissionsBaseType,
) {
  const isFolder = node[typeNameField] === folderTypeName;
  const isOpen = isFolder && !openMap[node.id];

  final.push({
    item: node,
    level,
    isOpen,
    permissionsObject,
  });

  if (isFolder && isOpen) {
    (byParentFolder[node.id] || []).forEach(child =>
      walkTreeAndAppend(
        child,
        openMap,
        final,
        level + 1,
        byParentFolder,
        permissionsObject,
      ),
    );
  }
}

function transformDataroomData(
  topLevelFolderId: string,
  data: Array<DataRoomBase>,
  sortBy: SortBy,
  openMap: OpenMap,
  percentMap: Map<string, number>,
  newFileSet: Set<string>,
) {
  const entries = sortCollection(data, sortBy, percentMap, newFileSet);

  const byParentFolder = groupBy(entries, e => {
    invariant(e.parent, 'parent not found');
    return e.parent.id;
  });

  entries.forEach(e => {
    // All folders must exist in this collection
    if (e[typeNameField] === folderTypeName && !byParentFolder[e.id])
      byParentFolder[e.id] = [];
  });

  // create map of parentId: FileChildren[]
  const filesByParentFolder = groupBy(
    entries.filter(e => e[typeNameField] !== folderTypeName),
    e => e.parent && e.parent.id,
  );

  const res: RoomMap<DataRoomBase> = {} as RoomMap<DataRoomBase>;

  // const root = [topLevelFolderId, ...Object.keys(byParentFolder)]
  // walk the tree, recur on folders until parentId is not present in the map
  Object.keys(byParentFolder).forEach(parentId => {
    processFolderEntity(
      parentId,
      byParentFolder,
      res,
      filesByParentFolder,
      newFileSet,
    );
  });

  const baseLevelFolders = byParentFolder[topLevelFolderId];
  const final = [];
  invariant(baseLevelFolders, 'Base Folders were not found!');

  baseLevelFolders.forEach((child: DataRoomBase) => {
    invariant(
      child[typeNameField] === folderTypeName,
      'Files should not exist on the base level',
    );
    const permissionObject = {
      __typename: child.__typename,
      id: child.id,
      // We know for sure that the child is actually a folder and will have the permissions property
      permissions: (child as FolderType).permissions,
    };

    walkTreeAndAppend(
      child,
      openMap,
      final,
      0,
      byParentFolder,
      permissionObject,
    );
  });

  const levelMap = buildLevelMap(final, byParentFolder);

  return {
    roomMap: res,
    dataList: final,
    levelMap,
  };
}

export default transformDataroomData;
