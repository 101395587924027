/* eslint-disable react/display-name */
import React from 'react';
import { compose } from 'recompose';
import Summary from './summary/SummaryContainer';
import ForgivenessTracker from './forgivenessTracker/ForgivenessTrackerContainer';
import {
  TabNavigationFrame,
  TabItemType,
  withLoadingIndicator,
} from 'components';
import {
  RoutingContext,
  RouteTable,
  RouteParams,
  withRouteParams,
} from 'routing';
import { ID } from 'types';
import { MountGuard, DealPerspectivePermissions } from 'security';

const routes: RoutingContext = {
  context: 'Deal',
  routes: [
    {
      render: () => <Summary />,
      path: RouteTable.deal.toLoanOverviewSummary(),
      exact: true,
    },
    {
      render: () => <ForgivenessTracker />,
      path: RouteTable.deal.toLoanOverviewForgiveness(),
      exact: true,
    },
  ],
};

type Props = {
  dealId: ID;
};

function LoanOverview(props: Props) {
  const { dealId } = props;

  const tabs: TabItemType[] = [
    {
      text: 'SUMMARY',
      to: RouteTable.deal.toLoanOverviewSummary(dealId),
    },
    {
      text: 'FORGIVENESS',
      to: RouteTable.deal.toLoanOverviewForgiveness(dealId),
    },
  ];

  return (
    <MountGuard permission={DealPerspectivePermissions.view_deal}>
      <TabNavigationFrame routeContext={routes} tabs={tabs} />
    </MountGuard>
  );
}

export default compose(
  withRouteParams([RouteParams.dealId]),
  withLoadingIndicator('loading'),
)(LoanOverview);
