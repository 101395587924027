const creditRatings = {
  moodys: {
    name: "Moody's",
    ratings: [
      '',
      'Aaa',
      'Aa1',
      'Aa2',
      'Aa3',
      'A1',
      'A2',
      'A3',
      'Baa1',
      'Baa2',
      'Baa3',
      'Ba1',
      'Ba2',
      'Ba3',
      'B1',
      'B2',
      'B3',
      'Caa1',
      'Caa2',
      'Caa3',
      'Ca',
      'C',
    ],
  },
  sAndP: {
    name: 'S&P',
    ratings: [
      '',
      'AAA',
      'AA+',
      'AA',
      'AA-',
      'A+',
      'A',
      'A-',
      'BBB+',
      'BBB',
      'BBB-',
      'BB+',
      'BB',
      'BB-',
      'B+',
      'B',
      'B-',
      'CCC+',
      'CCC',
      'CCC-',
      'CC',
      'C',
      'D',
    ],
  },
  fitch: {
    name: 'Fitch',
    ratings: [
      '',
      'AAA',
      'AA+',
      'AA',
      'AA-',
      'A+',
      'A',
      'A-',
      'BBB+',
      'BBB',
      'BBB-',
      'BB+',
      'BB',
      'BB-',
      'B+',
      'B',
      'B-',
      'CCC',
      'DDD',
      'DD',
      'D',
    ],
  },
};

export default creditRatings;
