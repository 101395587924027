import formQueryBuilder from './queryBuilders/formQueryBuilder';
import { DealType } from 'types';
import { gql } from '@apollo/client';
import { DocumentNode } from 'lsgql/types';
import uuid from 'uuid/v4';

import {
  SbaPppRoundTwoLenderApplicationShallowFields,
  SbaPppLoanApplicationShallowFields,
  DealClosingWorkflowShallowFields,
  SbaPppRoundTwoSecondDrawLenderApplicationShallowFields,
} from 'lsgql/fragments';

/* eslint-disable */
const SbaPppRoundTwoSecondDrawLenderApplicationQueryDefinition: DocumentNode = gql`
  query DealQuery($id: ID) {
    deal(id: $id) {
      id
      name
      category
      sbaPppRoundTwoLenderApplication {
        ...SbaPppRoundTwoLenderApplicationShallowFields
      }
      sbaPppRoundTwoSecondDrawLenderApplication {
        ...SbaPppRoundTwoSecondDrawLenderApplicationShallowFields
      }
      sbaPppLoanApplication {
        ...SbaPppLoanApplicationShallowFields
      }
      dealClosingWorkflow {
        ...DealClosingWorkflowShallowFields
      }
    }
  }
  ${SbaPppRoundTwoLenderApplicationShallowFields}
  ${SbaPppLoanApplicationShallowFields}
  ${DealClosingWorkflowShallowFields}
  ${SbaPppRoundTwoSecondDrawLenderApplicationShallowFields}
`;
/* eslint-enable */

const SbaPppRoundTwoSecondDrawLenderApplicationFormQuery = formQueryBuilder(
  SbaPppRoundTwoSecondDrawLenderApplicationQueryDefinition,
  {
    typename: 'Deal',
    skip: (props: { dealId: string | null | undefined }) => {
      const { dealId } = props;
      return !dealId;
    },
    variables: (props: { dealId: string | null | undefined }) => {
      const { dealId } = props;
      return { id: dealId };
    },
    results: (data: { deal: DealType | null | undefined }) => {
      const { deal } = data;

      if (
        (deal as any).sbaPppRoundTwoSecondDrawLenderApplication === null ||
        (deal as any).sbaPppRoundTwoSecondDrawLenderApplication === undefined
      ) {
        return {
          ...deal,
          sbaPppRoundTwoSecondDrawLenderApplication: {
            id: uuid(),
            __typename: 'SbaPppRoundTwoSecondDrawLenderApplicationType',
          },
        };
      }
      return deal;
    },
  },
);

export default SbaPppRoundTwoSecondDrawLenderApplicationFormQuery;
