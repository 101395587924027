import * as React from 'react';
import orderBy from 'lodash/orderBy';
import DataGridDropDownInput from './DataGridDropdownInput';
import DataGridHeaderRow from './DataGridHeaderRow';
import DataGridMoneyInput from './DataGridMoneyInput';
import DataGridPercentageInput from './DataGridPercentageInput';
import DataGridRow from './DataGridRow';
import DataGridSummaryRow from './DataGridSummaryRow';
import DataGridTextInput from './DataGridTextInput';
import { Column, Value } from './types';
import './DataGrid.scss';

/**
 * A data grid for presentation and input of data in highly
 * customizable ways.
 */

// TODO: Once component enters isEditing state, reverting to !isEditing is not possible
// TODO: Add a rowClass getter for setting extra row classes based
//       on a given row.
// TODO: Implement two-way sorting.
// TODO: Implement filtering.
// TODO: Implement global readOnly value (to avoid setting on every column).

type Props<T, K extends keyof T> = {
  /**
   * A distinct key used to identify a row.
   */
  borderlessHeader?: boolean;

  /**
   * A list of column specifications, which serve as the primary
   * means through which access to row data, as well as presentation,
   * aggregation, and sorting logic, is configured.
   */
  childRowAccessor?: (
    row: T,
    column: Array<Column<T, K>>,
    index: number,
  ) => T[];

  columns: Array<Column<T, K>>;

  disabled?: boolean;

  /**
   * Determine if sort should be enable for columns.  Default=true
   */
  hideHeader?: boolean;

  /**
   * The actual data.  Although any data structure might eventually
   * be supported, at this time we support only an array of row items.
   * (The individual items are assumed to be objects, keyed by
   * column name, but this can be overriden by configuration
   * options.
   */
  initialEditState?: boolean;

  /**
   * Whether to display a summary (aggregate) row.
   */
  isSortable?: boolean;

  onDelete?: (row: T) => any;

  /**
   * The callback executed when a cell value has been changed.
   *
   * The callback accepts three arguments:
   *   * The row key of the changed cell.
   *   * The column (configuration) of the changed cell.
   *   * The new value entered into the cell.
   */
  onEndEdit?: () => void;

  // prevents DataGrid from rendering a HeaderRow
  onValueChanged?: (
    rowKey: K,
    column: Column<T, K>,
    value: Value,
    row: T,
    entity?: any,
  ) => void;

  // onDelete Row callback which creates a generic last column with trash icon if provided
  rowKey: K;

  // accessor (see above) for possible child rows
  rows: T[];

  showSummaryRow?: boolean;
};

type State = {
  isEditing: boolean;
  reverseSort: boolean;
  sortBy: string | null;
};

class DataGrid<T, K extends keyof T> extends React.Component<
  Props<T, K>,
  State
> {
  static Dropdown = DataGridDropDownInput;

  static HeaderRow = DataGridHeaderRow;

  static MoneyInput = DataGridMoneyInput;

  static PercentageInput = DataGridPercentageInput;

  static TextInput = DataGridTextInput;

  static defaultProps = {
    showSummaryRow: false,
    onValueChanged: () => undefined,
    isSortable: true,
    initialEditState: true,
  };

  constructor(props: Props<T, K>) {
    super(props);
    this.state = {
      sortBy: null,
      reverseSort: false,
      isEditing: !!props.initialEditState,
    };
  }

  handleOnBeginEdit = () => {
    if (!this.state.isEditing) this.setState({ isEditing: true });
  };

  handleOnEndEdit = () => {
    if (this.props.onEndEdit) this.props.onEndEdit();
  };

  handleSetSortBy = (columnName: string) => {
    const { isSortable } = this.props;
    if (isSortable) {
      this.setState(prev => ({
        sortBy: columnName,
        reverseSort: prev.sortBy !== columnName ? false : !prev.reverseSort,
      }));
    }
  };

  handleOnValueChanged: (
    rowKey: K,
    column: Column<T, K>,
    value: Value,
    row: T,
    entity?: any,
  ) => void = (
    rowKey: K,
    column: Column<T, K>,
    value: Value,
    row: T,
    entity?: any,
  ) => {
    //
    // If we have a column-specific valueChanged handler, we pass
    // it the rowKey and value only.
    //
    if (column.onValueChange) column.onValueChange(rowKey, value, row, entity);
    //
    // Otherwise, we use the default, passing in the column, too.
    //
    else if (this.props.onValueChanged)
      this.props.onValueChanged(rowKey, column, value, row, entity);
  };

  sortedRows(
    rows: T[],
    sortByColumnName: string,
    reverse: boolean | 'none',
  ): Array<T> {
    return orderBy(
      [...rows],
      [
        row => {
          const column: Column<T, K> | undefined = this.props.columns.find(
            x => x.columnName === sortByColumnName,
          );
          if (!column) return '';
          if (column.sortValue) return column.sortValue(row, column);
          return column.accessor
            ? column.accessor(row, column, 0)
            : (row as any)[sortByColumnName];
        },
      ],
      reverse ? ['desc'] : ['asc'],
    );
  }

  render() {
    const {
      disabled,
      isSortable,
      hideHeader,
      onDelete,
      childRowAccessor,
      columns,
      rowKey,
      borderlessHeader,
    } = this.props;
    const { sortBy, reverseSort, isEditing } = this.state;
    const rows: T[] =
      isSortable && sortBy
        ? this.sortedRows(this.props.rows, sortBy, reverseSort)
        : this.props.rows;
    return (
      <div className="DataGrid">
        {!hideHeader && (
          <div className="DataGridHeader">
            <DataGridHeaderRow
              borderless={borderlessHeader}
              columns={columns}
              deleteColumn={!!onDelete}
              enableChildRows={!!childRowAccessor}
              onClickHeaderCell={this.handleSetSortBy}
            />
          </div>
        )}
        <div className="DataGridBody">
          {rows.map((row: T, i: number) => (
            <DataGridRow
              childRowAccessor={childRowAccessor}
              columns={columns}
              disabled={disabled}
              index={i}
              isEditing={isEditing}
              key={row[rowKey] as any}
              onBeginEdit={this.handleOnBeginEdit}
              onEndEdit={this.handleOnEndEdit}
              onRowDelete={onDelete}
              onValueChanged={this.handleOnValueChanged}
              row={row}
              rowKey={rowKey}
              rowKeyValue={row[rowKey]}
            />
          ))}
        </div>
        {this.props.showSummaryRow && (
          <DataGridSummaryRow
            columns={this.props.columns}
            deleteColumn={!!onDelete}
            rows={rows}
          />
        )}
      </div>
    );
  }
}

export default DataGrid;
