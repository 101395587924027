import * as React from 'react';
import { Button, Icon, FontAwesomeIconTypes } from 'components';
import { ReportType } from 'types';

const fileIcon: FontAwesomeIconTypes = ['fal', 'file-excel'];

type Props = {
  report: ReportType;
};

const ReportLink = ({ report }: Props) => (
  <div className="FileWrapper" key={report.url}>
    <Icon icon={fileIcon} />
    <Button.Text isDownloadLink label={report.fileName} to={report.url} />
  </div>
);

export default ReportLink;
