import React, { useState } from 'react';
import noop from 'lodash/noop';
import { Form, TabNavigationMenu } from 'components';
import { UserType, FormFieldsType } from 'types';

import './UserProfileView.scss';

type Props = {
  user: UserType;
};

const tabs = ['Contact', 'Login Info'];
const width = 'sixteen';

const fields: FormFieldsType = {
  fullName: {
    width,
  },
  email: {
    width,
  },
  title: {
    width,
  },
  primaryPhone: {
    id: 'primaryPhone',
    fieldName: 'Primary',
    width,
  },
  secondaryPhone: {
    id: 'secondaryPhone',
    fieldName: 'Secondary',
    width,
  },
  fax: {
    id: 'fax',
    fieldName: 'Fax',
    width,
  },
  username: {
    id: 'username',
    fieldName: 'Username',
    propertyName: 'username',
    width,
  },
  password: {
    id: 'password',
    fieldName: 'Password',
    propertyName: 'password',
    width,
  },
  organizationRole: {
    id: 'organizationRole',
    fieldName: 'Organization Role',
    propertyName: 'organizationRole',
    width,
  },
  dealRole: {
    id: 'dealRole',
    fieldName: 'Deal Role',
    propertyName: 'dealRole',
    width,
  },
};

function UserProfileView(props: Props) {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const { user } = props;

  return (
    <>
      <TabNavigationMenu
        activeTab={activeTab}
        onClick={setActiveTab}
        tabs={tabs}
      />
      <Form id="UserProfileView" onSubmit={noop}>
        {activeTab === 'Contact' && <Contact {...user} />}
        {activeTab === 'Login Info' && <LoginInfo {...user} />}
      </Form>
    </>
  );
}

function Contact(user) {
  return (
    <div className="Contact">
      <p className="Contact__FullName">{user.fullName}</p>
      <p>{user.title}</p>
      <p>{user.email}</p>
      {(user.primaryPhone || user.secondaryPhone || user.fax) && (
        <>
          <Form.Divider />
          <Form.Group>
            <Form.ReadOnly {...fields.primaryPhone} value={user.primaryPhone} />
            <Form.ReadOnly
              {...fields.secondaryPhone}
              value={user.secondaryPhone}
            />
          </Form.Group>
          <Form.ReadOnly {...fields.fax} value={user.fax} />
        </>
      )}
    </div>
  );
}

function LoginInfo(user) {
  return (
    <>
      <Form.ReadOnly {...fields.username} value={user.username} />
      <Form.ReadOnly {...fields.password} value="••••••••••••" />
    </>
  );
}

export default UserProfileView;
