import * as React from 'react';
import { noop } from 'lodash';
import cx from 'classnames';
import { calculateUnallocatedAmount } from '../../utils';
import LendingEntityRow from './LendingEntityRow';
import { invariant, ux } from 'utils';
import {
  LoanTrancheOwnershipPortionInputWithDisplayInfo,
  LoanTrancheOwnershipPortionInputWithInstitutionPortionId,
  SortBy,
  ValidationMap,
  ID,
} from 'types';
import { Money, Form, ConfirmModal } from 'components';
import { KeyPath } from 'lsredux';

type Props = {
  disabled: boolean;
  errors: ValidationMap | null | undefined;
  institutionPortionId: ID;
  institutionTotalAmount: string;
  institutionTotalPortion: string;
  lendingEntityKeyPath: KeyPath;
  ownershipPortions: LoanTrancheOwnershipPortionInputWithDisplayInfo[];
  removeEntity: (
    keyPath: KeyPath,
    entity: LoanTrancheOwnershipPortionInputWithDisplayInfo,
  ) => void;
  replaceEntity: (
    keyPath: KeyPath,
    entity: LoanTrancheOwnershipPortionInputWithInstitutionPortionId,
  ) => void;
  sortBy: SortBy;
  trancheTotalAmount: string;
};

type State = {
  lendingEntityIdToDelete: string | null | undefined;
};

class LendingEntityGroup extends React.Component<Props, State> {
  state = {
    lendingEntityIdToDelete: null,
  };

  handleCloseDeleteModal = () => {
    this.setState({
      lendingEntityIdToDelete: null,
    });
  };

  handleOpenDeleteModal = (lendingEntityId: string) => {
    this.setState({
      lendingEntityIdToDelete: lendingEntityId,
    });
  };

  handleDelete = () => {
    const { lendingEntityIdToDelete } = this.state;
    const {
      ownershipPortions,
      removeEntity,
      lendingEntityKeyPath,
    } = this.props;

    if (lendingEntityIdToDelete) {
      const ownershipPortion = ownershipPortions.find(
        portion => portion.id === lendingEntityIdToDelete,
      );
      if (ownershipPortion) {
        removeEntity(lendingEntityKeyPath, ownershipPortion);
        this.handleCloseDeleteModal();
      }
    }
  };

  getDeleteMessage = () => {
    const { ownershipPortions } = this.props;
    const { lendingEntityIdToDelete } = this.state;
    if (lendingEntityIdToDelete) {
      const ownershipPortion = ownershipPortions.find(
        portion => portion.id === lendingEntityIdToDelete,
      );
      if (ownershipPortion) {
        return `Are you sure you want to delete ${ownershipPortion.institutionName} ? `;
      }
    }
    return '';
  };

  getOwnershipPortionsSum = (): { amount: string; portion: string } => {
    const { ownershipPortions, institutionTotalPortion } = this.props;
    let amountTotal = 0;
    let portionTotal = 0;
    const institutionTotalPortionNumber = Number(institutionTotalPortion);
    invariant(
      !Number.isNaN(institutionTotalPortionNumber),
      'institution Total Portion is not a number!',
    );
    ownershipPortions.forEach(ownershipPortion => {
      const amount = Number(ownershipPortion.amount);
      const portion = Number(ownershipPortion.portion);
      invariant(!Number.isNaN(amount), 'amount is not a number!');
      invariant(!Number.isNaN(portion), 'portion is not a number!');
      amountTotal += amount;
      portionTotal += portion;
    });

    return {
      amount: amountTotal.toFixed(2),
      portion: (institutionTotalPortionNumber - portionTotal).toFixed(6),
    };
  };

  getSortedOwnershipPortions = () => {
    const { sortBy, ownershipPortions } = this.props;

    if (sortBy.column === '') {
      return ownershipPortions;
    }
    const sorted: LoanTrancheOwnershipPortionInputWithDisplayInfo[] = [
      ...ownershipPortions,
    ];
    sorted.sort((a, b) => {
      switch (sortBy.column) {
        case 'NAME':
          if (sortBy.reverse) {
            return b.institutionName.toLowerCase() <
              a.institutionName.toLowerCase()
              ? -1
              : 1;
          }

          return a.institutionName.toLowerCase() <
            b.institutionName.toLowerCase()
            ? -1
            : 1;

        case 'OWNERSHIP':
          return sortBy.reverse
            ? Number(a.portion || '0') - Number(b.portion || '0')
            : Number(b.portion || '0') - Number(a.portion || '0');
        default:
          return sortBy.reverse
            ? Number(a.amount || '0') - Number(b.amount || '0')
            : Number(b.amount || '0') - Number(a.amount || '0');

        // Use amount as default for now
      }
    });
    return sorted;
  };

  render = () => {
    const {
      disabled,
      institutionPortionId,
      institutionTotalAmount,
      replaceEntity,
      lendingEntityKeyPath,
      trancheTotalAmount,
      errors,
    } = this.props;

    const { lendingEntityIdToDelete } = this.state;
    const sums = this.getOwnershipPortionsSum();
    const unAllocatedAmount = calculateUnallocatedAmount(
      institutionTotalAmount,
      sums.amount,
    );

    const isOverAllocated = unAllocatedAmount.startsWith('-');
    const unAllocatedPortionClassName = cx(
      'lenderRegistryTranche__ColumnItem',
      'lenderRegistryTrancheFooter__PercentColumn',
      ux(
        isOverAllocated,
        'lenderRegistryTrancheFooter__PercentColumn--OverAllocated',
      ),
      ux(
        !isOverAllocated,
        'lenderRegistryTrancheFooter__PercentColumn--Normal',
      ),
    );

    return (
      <>
        {this.getSortedOwnershipPortions().map(ownershipPortion => (
          <LendingEntityRow
            disabled={disabled}
            errors={errors}
            institutionPortionId={institutionPortionId}
            key={ownershipPortion.id}
            lendingEntityKeyPath={lendingEntityKeyPath}
            onOpenDeleteModal={this.handleOpenDeleteModal}
            ownershipPortion={ownershipPortion}
            replaceEntity={replaceEntity}
            trancheTotalAmount={trancheTotalAmount}
          />
        ))}
        <div className="lenderRegistryInstitutionFooter">
          <div className="lenderRegistryTranche__ColumnItem--LeftAlignText lenderRegistryInstitutionFooter__NameColumn">
            Unallocated
          </div>
          <Form.Percentage
            className={unAllocatedPortionClassName}
            customIntegerLimit={10}
            disabled
            id="unallocatedPortionInstitution"
            onChange={noop}
            propertyName="portion"
            value={sums.portion}
          />
          <Money
            className={`lenderRegistryTranche__MoneyDisplay ${
              isOverAllocated
                ? 'lenderRegistryTrancheFooter--OverAllocated'
                : ''
            }`}
            value={unAllocatedAmount}
          />
          <div className="lenderRegistryTranche__MoneyDisplay">-</div>
          <div className="lenderRegistryTranche__MoneyDisplay">-</div>
        </div>
        <ConfirmModal
          confirmButtonText="Delete"
          deleteAction
          header="Delete"
          isOpen={Boolean(lendingEntityIdToDelete)}
          message={this.getDeleteMessage()}
          onConfirm={this.handleDelete}
          onReject={this.handleCloseDeleteModal}
        />
      </>
    );
  };
}

export default LendingEntityGroup;
