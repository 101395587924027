import React from 'react';
import { Prompt } from 'react-router-dom';
import WorkflowContentShell from '../WorkflowContentShell';
import { WorkflowSection } from '../workflowList';
import { fields } from './utils';
import {
  CalculatedValuesType,
  HelpBoxType,
  ForgivenessFormProps,
} from './types';
import { LessThanFullForgivenessCauses } from './partials';
import { Text, Form, Button, Tooltip } from 'components';
import { HelpBoxCallbackType } from 'features/sbaPppForgivenessWorkflow/workflowList/WorkflowHelpBox';

interface Props extends ForgivenessFormProps {
  calculatedValues: CalculatedValuesType;
  onChange: (
    value:
      | (string | null | undefined)
      | (boolean | null | undefined)
      | (Array<any> | null | undefined),
    fieldId: string,
  ) => void;
  renderCtaButtons: () => React.ReactElement;
  renderHelpBox: (
    id: HelpBoxType,
    focusId?: HelpBoxType,
    callback?: HelpBoxCallbackType,
  ) => React.ReactElement;
  TypeName: string;
}

const SForm = (props: Props) => {
  const {
    calculatedValues,
    onChange,
    renderCtaButtons,
    renderHelpBox,
    TypeName,
    disabled,
  } = props;

  const {
    sbapppforgivenesscalculation,
  } = props?.data?.sbaPppForgivenessWorkflow;

  function handleShowCalculations() {
    onChange(true, 'showCalculation');
    onChange(null, 'forgivenessAmountInputted');
  }

  function handleHideCalculations() {
    onChange(false, 'showCalculation');
    onChange(props.data?.forgivenessAmount, 'forgivenessAmountInputted');
    onChange(null, 'payrollCosts');
    onChange(null, 'mortgageInterest');
    onChange(null, 'rentOrLease');
    onChange(null, 'utilities');
    onChange(null, 'operationsExpenditures');
    onChange(null, 'propertyDamageCosts');
    onChange(null, 'supplierCosts');
    onChange(null, 'workerProtectionExpenditures');
  }

  if (sbapppforgivenesscalculation.showCalculation) {
    return (
      <WorkflowContentShell
        className="ForgivenessCalculationForm"
        ctaButtons={renderCtaButtons}
        helpBox={renderHelpBox(HelpBoxType.sForm)}
        title="Forgiveness Amount"
      >
        <Prompt
          message="There are unsaved changes on this form that will be lost if you continue."
          when={props.isDirty}
        />
        <div className="FormController">
          <Text className="bold">I know the forgiveness amount</Text>
          {!disabled && (
            <Button.Text
              label="Hide calculations"
              onClick={handleHideCalculations}
            />
          )}
        </div>
        <WorkflowSection header="Total payroll costs">
          <Form.Group className="Field-Tooltip">
            {Form.FieldRenderer(
              Form.Money,
              {
                ...fields.payrollCosts,
                onChange,
              },
              props,
              TypeName,
              props.data.id,
            )}
            <Tooltip>
              Total eligible payroll costs incurred or paid during the selected
              covered period. These costs are the sum of cash compensation,
              benefits contributions and taxes paid.
            </Tooltip>
          </Form.Group>
        </WorkflowSection>
        <WorkflowSection header="Nonpayroll business payments">
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.mortgageInterest,
              onChange,
              placeholder: '',
            },
            props,
            TypeName,
            props.data.id,
          )}
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.rentOrLease,
              onChange,
              placeholder: '',
            },
            props,
            TypeName,
            props.data.id,
          )}
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.utilities,
              onChange,
              placeholder: '',
            },
            props,
            TypeName,
            props.data.id,
          )}
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.operationsExpenditures,
              onChange,
              placeholder: '',
            },
            props,
            TypeName,
            props.data.id,
          )}
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.propertyDamageCosts,
              onChange,
              placeholder: '',
            },
            props,
            TypeName,
            props.data.id,
          )}
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.supplierCosts,
              onChange,
              placeholder: '',
            },
            props,
            TypeName,
            props.data.id,
          )}
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.workerProtectionExpenditures,
              onChange,
              placeholder: '',
            },
            props,
            TypeName,
            props.data.id,
          )}
        </WorkflowSection>
        <WorkflowSection className="ForgivenessAmount" header="Forgiveness">
          <Form.Group className="Field-Tooltip">
            {Form.FieldRenderer(
              Form.Money,
              {
                ...fields.forgivenessAmount,
                onChange,
              },
              props,
              TypeName,
              props.data.id,
            )}
          </Form.Group>
          <Text className="ForgivenessIsSmallest">
            The Forgiveness Amount is the smallest of:
          </Text>
          <Form.Group className="Field-Tooltip ForgivenessAmountSubValue">
            {Form.FieldRenderer(
              Form.Money,
              {
                ...fields.modifiedTotal,
                onChange,
              },
              props,
              TypeName,
              props.data.id,
            )}
            <Text>Payroll Costs + Nonpayroll Costs</Text>
          </Form.Group>
          <Form.Group className="Field-Tooltip ForgivenessAmountSubValue">
            {Form.FieldRenderer(
              Form.Money,
              {
                ...fields.pppLoanAmount,
                onChange,
              },
              props,
              TypeName,
              props.data.id,
            )}
            <Text>PPP Loan Amount</Text>
          </Form.Group>
          <Form.Group className="Field-Tooltip ForgivenessAmountSubValue">
            {Form.FieldRenderer(
              Form.Money,
              {
                ...fields.payrollCostRequirement,
                onChange,
              },
              props,
              TypeName,
              props.data.id,
            )}
            <Text>Payroll Cost 60% Requirement</Text>
          </Form.Group>
          <LessThanFullForgivenessCauses
            calculatedValues={calculatedValues}
            sbapppforgivenesscalculation={sbapppforgivenesscalculation}
          />
        </WorkflowSection>
      </WorkflowContentShell>
    );
  } else {
    return (
      <WorkflowContentShell
        className="ForgivenessCalculationForm SForm"
        ctaButtons={renderCtaButtons}
        helpBox={renderHelpBox(HelpBoxType.sForm)}
        title="Forgiveness Amount"
      >
        <WorkflowSection className="HideCalculation">
          <Form.Group>
            {Form.FieldRenderer(
              Form.Money,
              {
                ...fields.forgivenessAmountInputted,
                onChange,
              },
              props,
              TypeName,
              props.data.id,
            )}
            {!disabled && (
              <Button.Text
                className="HelpMe"
                label="Help me calculate"
                onClick={handleShowCalculations}
              />
            )}
          </Form.Group>
        </WorkflowSection>
      </WorkflowContentShell>
    );
  }
};

export default SForm;
