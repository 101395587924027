/**
 * Datarooms-related reducer.
 */
import { fromJS } from 'immutable';
import { generateTable } from '../../generate';

import standardReducerFactory from '../standardReducer';

// Assumes a single folder view at any given time (no scoping).
const initialState = fromJS({
  sortBy: { column: 'contact', reverse: false },
});

const reducerMap: {
  [key: string]: (
    arg0: Record<string, any>,
    arg1: Record<string, any>,
  ) => Record<string, any>;
} = {
  ...generateTable.reducerMap('people'),
};

export default standardReducerFactory(reducerMap, initialState);
