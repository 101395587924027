import * as React from 'react';
import { InvitedUsersSection } from '../types';
import BorrowerContainer from './BorrowerContainer';
import InstitutionContainer from './InstitutionContainer';
import { ViewType } from './types';
import InvitedSection from './sections/invitedSection/InvitedSection';
import { AccessGuard, DealPerspectivePermissions } from 'security';
import { DealInstitutionType, DealUserType, SortBy, ID } from 'types';
import { DisplayTable, ConfirmModal } from 'components';

const columns = [
  { name: '', className: 'Section-Contact', disableSort: true },
  { name: 'email', className: 'Section-Email' },
  { name: 'deal role', className: 'Section-DealAccess' },
  {
    name: 'actions',
    className: 'Section-Actions',
    disableSort: true,
  },
];

const permissionMap = {
  MyTeam: DealPerspectivePermissions.view_own_team,
  CoLenders: DealPerspectivePermissions.view_colenders,
  Borrower: DealPerspectivePermissions.view_borrowers,
  ServicingAgents: DealPerspectivePermissions.view_servicing_agents,
  LeadLender: DealPerspectivePermissions.view_lead_lenders,
};

// typeguard
function isInvitedSection(
  entity: DealInstitutionType | InvitedUsersSection,
): entity is InvitedUsersSection {
  return Boolean((entity as InvitedUsersSection).isInvitedSection);
}

function isBorrower(e: DealInstitutionType | InvitedUsersSection): boolean {
  return e && e.__typename.toLowerCase().startsWith('dealborrower');
}

type Props = {
  changeSortField: (sortBy: SortBy) => void;
  currentUserId: ID;
  dealId: ID;
  entities: Array<DealInstitutionType | InvitedUsersSection>;
  onRemoveUser: (userId: ID) => void;
  sortBy: SortBy;
  view: ViewType;
};

type State = {
  removeUser: DealUserType | null | undefined;
};

class DealPeopleContent extends React.Component<Props, State> {
  state = {
    removeUser: null,
  };

  handleRemoveUser = (user: DealUserType) => {
    this.setState({ removeUser: user });
  };

  handleClearPendingDelete = () => {
    this.setState({ removeUser: null });
  };

  handleConfirmDelete = () => {
    if (this.state.removeUser) {
      const userId = this.state.removeUser.id;
      this.props.onRemoveUser(userId);
    }
    this.handleClearPendingDelete();
  };

  handleToggleSort = (sortBy: SortBy) => this.props.changeSortField(sortBy);

  renderEntity = (entity: DealInstitutionType | InvitedUsersSection) => {
    const { currentUserId, sortBy, dealId } = this.props;
    if (!entity) return null;

    if (isInvitedSection(entity)) {
      return (
        <InvitedSection
          invitedSection={entity}
          key={entity.id}
          sortBy={sortBy}
        />
      );
    }

    if (isBorrower(entity)) {
      return (
        <BorrowerContainer
          borrowerId={entity.id}
          currentUserId={currentUserId}
          dealId={dealId}
          key={entity.id}
          onRemoveUser={this.handleRemoveUser}
          organization={entity}
          sortBy={sortBy}
        />
      );
    }

    return (
      <InstitutionContainer
        currentUserId={currentUserId}
        dealId={dealId}
        key={entity && entity.id}
        onRemoveUser={this.handleRemoveUser}
        organization={entity}
        sortBy={sortBy}
      />
    );
  };

  renderEntities = (
    entities: Array<DealInstitutionType | InvitedUsersSection>,
  ) => (entities || []).map<any>(e => this.renderEntity(e));

  renderSections = () => {
    const { view, entities } = this.props;

    return (
      <AccessGuard permission={permissionMap[view]}>
        {this.renderEntities(entities)}
      </AccessGuard>
    );
  };

  render() {
    return (
      <>
        <DisplayTable
          className="Section-Table"
          columns={columns}
          hoverable
          onChangeSortField={this.handleToggleSort}
          sortBy={this.props.sortBy}
        >
          {this.renderSections()}
        </DisplayTable>
        <ConfirmModal
          header="Remove User from Deal?"
          isOpen={Boolean(this.state.removeUser)}
          message={`${
            this.state.removeUser && this.state.removeUser.fullName
              ? this.state.removeUser.fullName
              : ''
          } will no longer have access to the deal`}
          onConfirm={this.handleConfirmDelete}
          onReject={this.handleClearPendingDelete}
        />
      </>
    );
  }
}

export default DealPeopleContent;
