import * as React from 'react';
import { connect } from 'react-redux';

import { compose } from 'recompose';
import DataroomPdfViewer from './DataroomPdfViewer';
import { initialPdfProps } from 'lsredux/reducer/presentational/dealpdfviewer';
import { ReduxDirectory } from 'lsredux';
import { DocumentExpiringUrlQuery } from 'lsgql';

import {
  changePage as reduxChangePage,
  changeScale as reduxChangeScale,
  changeRotations as reduxChangeRotations,
  closeFile as reduxcloseFile,
} from 'lsredux/actions/dealpdfviewer';

function mapStateToProps(state) {
  const keyPath = ReduxDirectory.DealPDFViewerKeyPath;
  const pdfState = state.getIn(keyPath);
  const currentlyOpen = pdfState.getIn(['currentlyOpen']);
  if (currentlyOpen && currentlyOpen.id) {
    const currentPdf = pdfState.getIn(['files', currentlyOpen.id]);
    const currentPdfProps = currentPdf ? currentPdf.toJS() : {};
    return {
      ...initialPdfProps,
      currentlyOpen,
      ...currentPdfProps,
      id: currentlyOpen.id,
    };
  }
  return {
    ...initialPdfProps,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  const additional = {
    changePage(data: { id: string; pageIndex: number }): void {
      const { id, pageIndex } = data;
      dispatch(reduxChangePage({ id, pageIndex }));
    },
    changeScale(data: { id: string; scale: number }): void {
      const { id, scale } = data;
      dispatch(reduxChangeScale({ id, scale }));
    },
    changeRotations(data: {
      id: string;
      rotations: Map<number, number>;
    }): void {
      const { id, rotations } = data;
      dispatch(reduxChangeRotations({ id, rotations }));
    },
    closeFile(): void {
      dispatch(reduxcloseFile());
    },
  };
  return { ...additional };
};

type OwnProps = {
  fileUri: string;
  id: string;
  renderError: () => React.ReactNode;
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  DocumentExpiringUrlQuery,
)(DataroomPdfViewer);
