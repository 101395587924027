import * as React from 'react';
import { Section } from 'components';

type Props = {
  children: React.ReactNode;
};

class DebugSection extends React.Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <Section basic={false} className="DebugSection">
        {children}
      </Section>
    );
  }
}

export default DebugSection;
