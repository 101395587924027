import * as React from 'react';
import {
  VIEW_OWN_TEAM,
  VIEW_COLENDERS,
  VIEW_BORROWERS,
  VIEW_LEAD_LENDERS,
  VIEW_SERVICING_AGENTS,
  VIEW_INVESTOR_BOOK,
  VIEW_LENDER_REGISTRY,
} from '../../security/constants';
import SbaPppForgivenessWorkflow from '../../features/sbaPppForgivenessWorkflow/SbaPppForgivenessWorkflowContainer';
import { EditDealFormContainer } from '../../features/dealForm/DealFormContainer';
import { DealPeopleContainer } from '../../features/dealPeople';
import DataroomContainer from '../../features/dataroom/DataroomContainer';
import DealClosingWorkflowFork from '../../features/dealClosingWorkflow/WorkfowFork';
import RoundTwoLenderApplicationFork from '../../features/roundTwoSecondDrawLenderApplication/LenderApplicationFork';
import LoanOverview from '../../features/loanOverview/LoanOverview';
import TermSheetContainer from '../../features/termSheet/TermSheetContainer';
import FeesGridFormContainer from '../../features/feesGridForm/FeesGridFormContainer';
import GeneralNoticeFormContainer from '../../features/generalNoticeForm/GeneralNoticeFormContainer';
import CalendarContainer from '../../features/calendar/CalendarContainer';
import CalendarTaskViewContainer from '../../features/calendar/calendarTaskView/CalendarTaskViewContainer';
import ReportContainer from '../../features/reporting/ReportContainer';
import { SalesView } from '../../features/salesView';
import PaymentsContext from '../../features/paymentsView/PaymentsContext';
import PaymentRecordFork from '../../features/paymentsView/PaymentRecordView/PaymentRecordFork';
import MultiTrancheFormContainer from '../../features/loanTrancheForm/MultiTrancheFormContainer';
import PrivateSbaBorrowerApplicationFork from '../../features/roundTwoSbaPppLoanApplication/PrivateBorrowerApplicationFork';
import CreateFirstDrawLoanApplication from '../../features/roundTwoSbaPppLoanApplication/CreateFirstDrawLoanApplicationFormContainer';
import CreateSecondDrawLoanApplication from '../../features/roundTwoSbaPppLoanApplication/CreateSecondDrawLoanApplicationFormContainer';
import { RoutingContext } from '../types';
import { RouteTable } from '../RouteTable';
import { SecuredRoute, RenderWithRouter } from './RouteRenderers';
import { PermissionFlags } from 'types';
import {
  DealPerspectivePermissions,
  DealPerspectivePermissionSets,
  PermissionsGuard,
  PermissionRequest,
} from 'security';

const peoplePermissionRequests: Array<PermissionRequest> = [
  {
    definition: DealPerspectivePermissions.view_own_team,
    flag: VIEW_OWN_TEAM,
  },
  {
    definition: DealPerspectivePermissions.view_colenders,
    flag: VIEW_COLENDERS,
  },
  {
    definition: DealPerspectivePermissions.view_lead_lenders,
    flag: VIEW_LEAD_LENDERS,
  },
  {
    definition: DealPerspectivePermissions.view_borrowers,
    flag: VIEW_BORROWERS,
  },
  {
    definition: DealPerspectivePermissions.view_servicing_agents,
    flag: VIEW_SERVICING_AGENTS,
  },
];

const salesPermissionRequests: Array<PermissionRequest> = [
  {
    definition: DealPerspectivePermissions.view_investor_book,
    flag: VIEW_INVESTOR_BOOK,
  },
  {
    definition: DealPerspectivePermissions.view_lender_registry,
    flag: VIEW_LENDER_REGISTRY,
  },
];

const renderPeopleView = (permissionsMap: Map<PermissionFlags, boolean>) => (
  <DealPeopleContainer permissionsMap={permissionsMap} />
);

const renderSalesView = (permissionsMap: Map<PermissionFlags, boolean>) => (
  <SalesView permissionsMap={permissionsMap} />
);

/* eslint-disable react/display-name */

/**
 * Valid routes within the context of a deal
 */
const dealRoutes: RoutingContext = {
  context: 'Deal',
  routes: [
    {
      render: SecuredRoute(
        SbaPppForgivenessWorkflow,
        DealPerspectivePermissions.administer_sba_ppp_forgiveness,
      ),
      path: RouteTable.deal.toSbaPppForgivenessWorkflow(),
    },
    {
      render: SecuredRoute(
        () => (
          <PermissionsGuard permissionRequests={peoplePermissionRequests}>
            {renderPeopleView}
          </PermissionsGuard>
        ),
        DealPerspectivePermissions.view_own_team,
      ),
      path: RouteTable.deal.toDealPeople(),
      exact: false,
    },
    {
      render: SecuredRoute(
        DataroomContainer,
        DealPerspectivePermissionSets.view_dataroom,
      ),
      path: RouteTable.deal.toDealDataroom(),
      exact: true,
    },
    {
      render: () => <LoanOverview />,
      path: RouteTable.deal.toLoanOverviewSummary(),
    },
    {
      render: SecuredRoute(
        TermSheetContainer,
        DealPerspectivePermissions.view_term_sheet,
      ),
      path: RouteTable.deal.toDealTermSheet(),
      exact: true,
    },
    {
      render: () => <CalendarContainer />,
      path: RouteTable.deal.toDealCalendar(),
      exact: true,
    },
    {
      render: () => <CalendarTaskViewContainer />,
      path: RouteTable.deal.toCalendarTaskView(),
      exact: true,
    },
    {
      render: SecuredRoute(
        FeesGridFormContainer,
        DealPerspectivePermissions.view_fees,
      ),
      path: RouteTable.deal.toDealFees(),
      exact: true,
    },
    {
      render: RenderWithRouter(EditDealFormContainer),
      path: RouteTable.deal.toDealEdit(),
      exact: true,
    },
    {
      render: SecuredRoute(
        MultiTrancheFormContainer as any,
        DealPerspectivePermissions.view_loan_tranches,
      ),
      path: RouteTable.deal.toDealLoanTrancheEdit(),
      exact: true,
    },
    {
      render: SecuredRoute(
        DealClosingWorkflowFork,
        DealPerspectivePermissions.view_closing,
        RouteTable.deal.toLoanOverviewSummary,
      ),
      path: RouteTable.deal.toDealClosingWorkflow(),
      exact: true,
    },
    {
      render: () => (
        <PermissionsGuard permissionRequests={salesPermissionRequests}>
          {renderSalesView}
        </PermissionsGuard>
      ),
      path: RouteTable.deal.toDealSales(),
      exact: false,
    },
    {
      render: SecuredRoute(
        PaymentsContext,
        DealPerspectivePermissions.view_payments,
      ),
      path: RouteTable.deal.toDealPayments(),
      exact: false,
    },
    {
      render: SecuredRoute(
        PaymentRecordFork,
        DealPerspectivePermissions.view_payments,
      ),
      path: RouteTable.deal.toDealPaymentRecord(),
      exact: true,
    },
    {
      render: SecuredRoute(
        ReportContainer,
        DealPerspectivePermissions.administer_deal,
      ),
      path: RouteTable.deal.toDealReports(),
      exact: true,
    },
    {
      render: () => <GeneralNoticeFormContainer />,
      path: RouteTable.deal.toGeneralNoticeCreate(),
      exact: true,
    },
    {
      render: () => <GeneralNoticeFormContainer />,
      path: RouteTable.deal.toGeneralNoticeFork(),
    },
    {
      render: SecuredRoute(
        RenderWithRouter(PrivateSbaBorrowerApplicationFork),
        DealPerspectivePermissions.view_sba_ppp_application,
        RouteTable.deal.toLoanOverviewSummary,
      ),
      path: RouteTable.deal.toDealSbaApplication(),
    },
    {
      render: SecuredRoute(
        RenderWithRouter(RoundTwoLenderApplicationFork),
        DealPerspectivePermissions.administer_deal_stage,
        RouteTable.deal.toLoanOverviewSummary,
      ),
      path: RouteTable.deal.toDealSbaLenderApplication(),
    },
    {
      render: SecuredRoute(
        (props: any) => (
          <CreateFirstDrawLoanApplication
            {...props}
            // isInitialSecondDraw
            // isSecondDrawRoute
          />
        ),
        DealPerspectivePermissions.view_deal,
      ),
      path: RouteTable.deal.toSbaPppRoundTwoFirstDrawForm(),
    },
    {
      render: SecuredRoute(
        (props: any) => (
          <CreateSecondDrawLoanApplication
            {...props}
            isInitialSecondDraw
            isSecondDrawRoute
          />
        ),
        DealPerspectivePermissions.view_deal,
      ),
      path: RouteTable.deal.toSbaPppRoundTwoSecondDrawForm(),
    },
  ],
};

export default dealRoutes;
