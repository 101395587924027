import { compose } from 'recompose';
import { connect } from 'react-redux';
import CancelLoanForm from './CancelLoanForm';
import { RouteParams, withRouteParams, RouteTable } from 'routing';
import { FormMediator } from 'components';
import CancelLoan from 'lsredux/reducer/forms/cancelLoan';
import { CancelLoanFormQuery, DealQuery } from 'lsgql';

const CancelLoanFormContainer = compose(
  withRouteParams([RouteParams.dealId]),
  DealQuery,
  CancelLoanFormQuery,
  connect(CancelLoan.mapStateToProps, CancelLoan.actions.generateActions),
  FormMediator({
    formId: 'CancelLoanForm',
    baseObjectTypeName: 'CancelLoan',
    blurEventForm: true,
    disableFrame: true,
    successAlert: { message: 'Loan was successfully canceled' },
    postSaveRoutePath: RouteTable.application.toHome,
  }),
)(CancelLoanForm);

export default CancelLoanFormContainer;
