import React, { useState } from 'react';
import noop from 'lodash/noop';
import InstitutionBankAccountCollection from './InstitutionBankAccountCollection';
import InstitutionEntityCollection from './InstitutionEntityCollection';
import SbaAccountForm from './SbaAccountForm';
import {
  Header,
  Form,
  TabNavigationMenu,
  Tiler,
  WrappedMediatorFormProps,
  AddressFields,
} from 'components';

import { InstitutionType, FormFieldsType } from 'types';

import './InstitutionProfileForm.scss';

type Props = WrappedMediatorFormProps<InstitutionType> & {
  institutionSection: string;
};

const width = 'four';
const DealInstitution = 'Institution';
const tabs = ['Overview', 'Bank Accounts'];

const fields: FormFieldsType = {
  name: {
    id: 'name',
    fieldName: 'Company Name',
    propertyName: 'name',
    required: true,
    placeholder: 'Company Name',
    width: '256px',
  },
  ticker: {
    id: 'ticker',
    fieldName: 'Short Name',
    propertyName: 'ticker',
    placeholder: 'Company Name',
    width: '120px',
    helpText: 'For display in this platform',
  },
  website: {
    id: 'website',
    propertyName: 'website',
    fieldName: 'Website',
    placeholder: 'https://',
    width: '256px',
  },
  description: {
    id: 'description',
    propertyName: 'description',
    fieldName: 'Description',
    placeholder: 'Some help text about what this is a description of...',
    rows: 4,
    width: '392px',
  },
  fundsHeader: {
    id: 'fundsHeader',
    propertyName: 'fundsHeader',
    fieldName: 'Receiving Party',
    onChange: noop,
    width,
  },
  fundsReceivedBy: {
    id: 'fundsReceivedBy',
    propertyName: 'fundsReceivedBy',
    options: [
      {
        id: 'LoanStreet',
        label: 'LoanStreet',
      },
      {
        id: 'Other',
        label: 'Other',
      },
    ],
    onChange: noop,
    width,
  },
  paymentType: {
    id: 'paymentType',
    propertyName: 'paymentType',
    fieldName: 'Payment Type',
    width,
    typeName: 'InstitutionPaymentType',
  },
  phoneNumber: {
    id: 'phoneNumber',
    propertyName: 'phoneNumber',
    fieldName: 'Phone Number',
    width: '158px',
  },
  sbaPrimaryContact: {
    id: 'sbaPrimaryContactId',
    propertyName: 'sbaPrimaryContactId',
    fieldName: 'Contact Name',
    helpText: 'For your SBA Loan Applications',
    allowEmpty: false,
    required: true,
    width: '392px',
  },
};

function InstitutionProfileForm(props: Props) {
  const { institutionSection } = props;
  const [activeTab, setActiveTab] = useState(
    institutionSection === 'sba' ? 'SBA Info' : tabs[0],
  );
  if (props?.data?.isBorrower == false && !tabs.includes('SBA Info')) {
    tabs.push('SBA Info');
  }

  return (
    <Tiler>
      <Tiler.Tile title="Institution Information" width="Half">
        <TabNavigationMenu
          activeTab={activeTab}
          onClick={setActiveTab}
          tabs={tabs}
        />
        {activeTab === 'Overview' && <Overview {...props} />}
        {activeTab === 'Bank Accounts' && <BankAccounts {...props} />}
        {activeTab === 'SBA Info' && <SbaInfo {...props} />}
      </Tiler.Tile>
    </Tiler>
  );
}

function BankAccounts(props) {
  return <InstitutionBankAccountCollection {...props} />;
}

function Overview(props) {
  const { data } = props;

  function handleConfirmationChange(isConfirmed: boolean) {
    props.mutateProperty(isConfirmed, 'hasAgreedToTerms');
  }

  function handleMutateProperty(
    value: (string | null | undefined) | (boolean | null | undefined),
    fieldId: string,
  ) {
    props.mutateProperty(value, fieldId);
  }

  function handleFundsReceivedBy(value: string) {
    handleMutateProperty(value === 'LoanStreet', 'fundsReceivedByLoanStreet');
  }

  function handleMutateTicker(
    value: string | null | undefined,
    fieldId: string,
  ) {
    const capitalizedValue = (value && value.toUpperCase()) || null;
    props.mutateProperty(capitalizedValue, fieldId);
  }

  return (
    <>
      <div>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Input,
            {
              ...fields.name,
              onChange: props.mutateProperty,
            },
            props,
            DealInstitution,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Input,
            {
              ...fields.ticker,
              value: data.ticker && data.ticker.toUpperCase(),
              onChange: handleMutateTicker,
            },
            props,
            DealInstitution,
            data.id,
          )}
        </Form.Group>
        {Form.FieldRenderer(
          Form.Input,
          {
            ...fields.website,
            onChange: props.mutateProperty,
          },
          props,
          DealInstitution,
          data.id,
        )}
        <AddressFields
          data={data}
          disabled={props.disabled}
          handleChange={handleMutateProperty}
          isSaving={props.isSaving}
          parentType={DealInstitution}
          small
          typeName="InstitutionState"
        />
        {Form.FieldRenderer(
          Form.Phone,
          {
            ...fields.phoneNumber,
            onChange: props.mutateProperty,
          },
          props,
          DealInstitution,
          data.id,
        )}
      </div>
      <Form.Divider />
      <div>
        <Header as="h5" className="caps steel">
          Entities
        </Header>
        <InstitutionEntityCollection {...props} />
      </div>
      <Form.Divider />
      <div>
        <Header as="h5" className="caps steel">
          Payments
        </Header>
        {Form.FieldRenderer(
          Form.InputHeader,
          fields.fundsHeader,
          props,
          DealInstitution,
          data.id,
        )}
        <Form.Group>
          {Form.FieldRenderer(
            Form.Radio,
            {
              ...fields.fundsReceivedBy,
              onChange: handleFundsReceivedBy,
              value: data.fundsReceivedByLoanStreet ? 'LoanStreet' : 'Other',
            },
            props,
            DealInstitution,
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {data.fundsReceivedByLoanStreet &&
            Form.FieldRenderer(
              Form.ReferenceSelect,
              {
                ...fields.paymentType,
                onChange: props.mutateProperty,
              },
              props,
              DealInstitution,
              data.id,
            )}
          {data.fundsReceivedByLoanStreet &&
            data.paymentType === 'ACH_DEBIT' && (
              <Form.TermsAgreement
                disabled={props.disabled}
                isConfirmed={Boolean(data.hasAgreedToTerms)}
                onChange={handleConfirmationChange}
              />
            )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.TextArea,
            {
              ...fields.description,
              onChange: props.mutateProperty,
            },
            props,
            DealInstitution,
            data.id,
          )}
        </Form.Group>
      </div>
    </>
  );
}

function SbaInfo(props) {
  const { data, ...rest } = props;

  const sbaPrimaryContactOptions = (data.userSet || []).map(user => ({
    text: user.fullName,
    value: user.id,
  }));

  return (
    <SbaAccountForm {...rest} data={data.sbaAccount}>
      <>
        {/* TODO: SBA-HACK: To render an Institution property within SbaAccount form */}
        <Form.Divider />
        <Header as="h5" className="steel caps">
          Primary Contact to SBA
        </Header>
        {Form.FieldRenderer(
          Form.Select,
          {
            ...fields.sbaPrimaryContact,
            onChange: props.mutateProperty,
            options: sbaPrimaryContactOptions,
            value: data.sbaPrimaryContactId ?? data.sbaPrimaryContact?.id,
          },
          props,
          DealInstitution,
          data.id,
        )}
      </>
    </SbaAccountForm>
  );
}

export default InstitutionProfileForm;
