import { OriginateLoanMutationDefinition } from './definitions';

import { mutationBuilder } from './builders';
import { ID, DateTime } from 'types';

type OriginateLoanInput = {
  dealId: ID;
  fundingDate: DateTime;
};

type OriginateLoanType = {
  ok: boolean;
};

const originateLoan = mutationBuilder<OriginateLoanInput, OriginateLoanType>(
  OriginateLoanMutationDefinition,
  {
    build: mutate => (data: OriginateLoanInput) => {
      return mutate({
        variables: {
          dealId: data.dealId,
          fundingDate: data.fundingDate,
        },
        refetchQueries: ['DealQuery'],
      });
    },
  },
);

export default originateLoan;
