import memoize from 'lodash/memoize';
import { DealJob } from '../invitations/constants';
import { isDealClosed } from 'utils';
import {
  RoleType,
  DealType,
  SelectOptionType,
  InvitationDealJob,
  OrganizationDealRoleType,
  MyUserType,
} from 'types';

/* eslint-disable import/prefer-default-export */

export type RoleContext =
  | 'deal_institution'
  | 'deal_borrower'
  | 'folder'
  | 'institution';

export type CommonProps = {
  deal: DealType;
  dealOrganizationDealRoles: Array<OrganizationDealRoleType>;
  roleContext: RoleContext;
  roles: ReadonlyArray<RoleType>;
  user: MyUserType;
};

/**
 * Translate the roleContext to the proper value and load
 * it's full roles list
 * @param {*} props
 */
function getRolesForContext(props: CommonProps): ReadonlyArray<RoleType> {
  const { deal, roles = [], roleContext } = props;

  /*
    deal_borrower and deal_institution are made up by the frontend,
    there is only a 'deal' context.  Update roleContext to reflect this
  */
  if (roleContext.startsWith('deal')) {
    const dealIsClosed = isDealClosed(deal.stage);
    return roles.filter(
      e => e.context === 'deal' && e.postClosing === dealIsClosed,
    );
  }
  return roles.filter(e => e.context === roleContext);
}

function getDealJobForUser(
  props: CommonProps,
): InvitationDealJob | null | undefined {
  const { user, dealOrganizationDealRoles } = props;

  const dealOrganizationDealRole = dealOrganizationDealRoles.find(
    e =>
      e.institution &&
      user.institution &&
      e.institution.id === user.institution.id,
  );
  return dealOrganizationDealRole ? dealOrganizationDealRole.dealJob : null;
}

/**
 * Get the full role list for a context, and filter those roles
 * down the final selectable set
 */
const getFilteredRoleOptions: (arg0: CommonProps) => Array<SelectOptionType> = (
  props: CommonProps,
) => {
  // get all roles for the context
  const contextRoles = getRolesForContext(props);

  // get deal job for current user
  const dealJobKey = getDealJobForUser(props);

  let filterMethod: (arg0: RoleType) => boolean | null | undefined = null;
  // Determine if/how we will filter the list further
  switch (dealJobKey && DealJob[dealJobKey]) {
    case DealJob.BORROWER:
      // A borrower should only see borrower roles
      filterMethod = (e: RoleType) =>
        Boolean(e.name) && (e.name || '').toLowerCase().includes('borrower');

      break;
    case DealJob.CO_LENDER:
      // A co-lender should only see co-lender roles
      filterMethod = (e: RoleType) =>
        Boolean(e.name) && (e.name || '').toLowerCase().includes('co-lender');

      break;
    case DealJob.SERVICE_PROVIDER:
      // A service provider should only see service provider roles
      filterMethod = (e: RoleType) =>
        Boolean(e.name) && (e.name || '').toLowerCase().includes('servicer');

      break;
    case DealJob.LEAD_LENDER:
      // A lead lender should only see lead lender roles
      filterMethod = (e: RoleType) =>
        Boolean(e.slug) &&
        (e.slug === 'deal_administrator' || e.slug === 'deal_view_only');

      break;
    default:
      // If the deal job is unknown, we don't want to return any roles
      filterMethod = () => false;
  }

  if (props.roleContext === 'folder') {
    filterMethod = (e: RoleType) =>
      Boolean(e.name) && (e.name || '').toLowerCase().includes('folder');
  }

  return contextRoles
    .filter(filterMethod)
    .map(e => ({ text: e.name || 'Unknown', value: e.id }))
    .sort((a, b) => {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    });
};

export const getRoleOptions = memoize(
  getFilteredRoleOptions,
  (props: CommonProps) => props.roleContext,
);
