import * as React from 'react';
import MenuItem, { Props } from './MenuItem';
import { MountGuard, PermissionDefinition } from 'security';

export type SecureMenuItemProps = Props & {
  permission: PermissionDefinition | Array<PermissionDefinition>;
};

/**
 * A Permissions aware MenuItem, if the user does not
 * possess the provided permission flag, the MenuItem
 * will not render
 */
function SecureMenuItem(props: SecureMenuItemProps) {
  const { permission, ...rest } = props;
  return (
    <MountGuard permission={permission}>
      <MenuItem {...rest} />
    </MountGuard>
  );
}

export default SecureMenuItem;
