import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { isImmutable, isKeyed } from 'immutable';
import isEmpty from 'lodash/isEmpty';

import { FormFrame } from '@loanstreet-usa/design-system';
import { setAlert } from '../../redux/actions/alerts';
import store from '../../redux/reducer';
import { FormReduxState } from '../../redux/genericForms/types';

import EventsProcessor from './events/EventsProcessor';

import {
  FormMediatorProps,
  FormMediatorArgs,
  WrappedMediatorFormProps,
} from './types';
import { AccessDeniedGuard, DisabledChildProps } from 'security';
import { routeTo } from 'routing';
import { BaseType, ID } from 'types';
import { invariant } from 'utils';

const defaultSuccessAlert = 'Save Successful!';
const enabled = { accessDenied: false };

function inspectProps<TIn extends BaseType>(
  props: FormMediatorProps<TIn>,
  args: FormMediatorArgs<TIn>,
) {
  invariant(args, 'Mediator args were not provided');
  invariant(args.baseObjectTypeName, 'baseObjectTypeName is required');

  const fetchMethod = `fetch${args.baseObjectTypeName}`;

  if (!(args.postSaveRoutePath || props[fetchMethod] || props.history)) {
    // eslint-disable-next-line
    console.warn(
      'Either postSaveRoutePath or a props.fetchMethod or router must be provided',
    );
  }

  if (!args.postSaveRoutePath && args.withSaveAsDraft) {
    throw new Error(
      'postSaveRoutePath must be provided when saving as draft is enabled',
    );
  }

  if (args.confirmBeforeSubmit && args.disableFrame) {
    throw new Error('confirmBeforeSubmit and disableFrame cannot both be true');
  }

  invariant(
    !args.confirmBeforeSubmit || props.setConfirmSubmitModalContent,
    'props.setConfirmSubmitModalContent is required if args.confirmBeforeSubmit is true',
  );

  const expected: Array<string> = ['rawData', 'mutateProperty'];

  expected.forEach(x => {
    if (!props[x]) {
      throw new Error(`Expected prop ${x} but was undefined`);
    }
  });
}

interface WrappedProps<TIn extends BaseType>
  extends WrappedMediatorFormProps<TIn> {
  // allow extra properties
  [propName: string]: any;
}

/* eslint-enable */

/**
 * Mediate between a FormReduxState<T> based Container and a FormProps<T> based component.
 * The Child Component<FormProps<T>> will only need to provide Form.* Fields, and mediator
 * handles the complete editing lifecycle
 *
 * @param {*} args - Additional Props to be passed to the child Form element.  If
 * undefined, FormFrame will not be rendered
 */
function FormMediator<TIn extends BaseType>(args: FormMediatorArgs<TIn>) {
  return function FormMediatorComponent(
    FormComponent: React.ComponentType<WrappedProps<TIn>>,
  ) {
    const MediatorWrapperComponent = class MediatorWrapper extends React.Component<
      FormMediatorProps<TIn>
    > {
      constructor(props: FormMediatorProps<TIn>) {
        super(props);

        // Inspect that required props were provided
        inspectProps(props, args);
        const hasExistingData =
          !this.props.preExistingEntity &&
          this.props.rawData &&
          isImmutable(this.props.rawData) &&
          isKeyed(this.props.rawData) &&
          this.props.rawData.has('data');

        if (!hasExistingData) {
          this.invokeStateInitialization(this.props);
        }
        invariant(
          !(args.withApproval && args.withSaveAsDraft),
          'FormMediator can only accept withSaveAsDraft or withApproval, not both',
        );
      }

      UNSAFE_componentWillReceiveProps(nextProps: FormMediatorProps<TIn>) {
        // If the preExistingEntity reference has changed, we need to re-initialize Redux state
        if (
          (!this.props.preExistingEntity && nextProps.preExistingEntity) ||
          (this.props.preExistingEntity &&
            nextProps.preExistingEntity &&
            this.props.preExistingEntity.id !== nextProps.preExistingEntity.id)
        ) {
          /*
           * Only re-init state if:
           *   1) We lacked an entity but now have one
           *   2) We had an entity but the ID changed
           */
          this.invokeStateInitialization(nextProps);
        }

        /* On Props change, process events, if any exist */
        EventsProcessor(
          this.props,
          nextProps,
          args,
          this.getFormReduxStateProps,
        );
      }

      componentWillUnmount() {
        const { clearState } = this.props;
        if (clearState) {
          // Don't leave partial items in redux state
          clearState();
        }
      }

      getFormReduxStateProps = (
        props: FormMediatorProps<TIn> = this.props,
      ): FormReduxState<TIn> => {
        const { rawData } = props;
        const res: FormReduxState<TIn> = rawData.toJS() as FormReduxState<TIn>;

        invariant(
          isEmpty(res) || res.errors === null || !isEmpty(res.errors),
          'Errors should be null or not-empty',
        );

        return res;
      };

      parseResponse = (
        res: { entityId: ID | null | undefined; success: boolean },
        isSubmit?: boolean,
      ) => {
        invariant(res, 'Expected to receive a save response');
        invariant(
          res.success === true || res.success === false,
          'Expected res.success to be defined',
        );

        if (res.success) {
          if (!args.disableAlerts) {
            const successMessage = args.successAlert
              ? args.successAlert.message
              : defaultSuccessAlert;
            const successTitle = args.successAlert && args.successAlert.title;
            store.dispatch(setAlert(successMessage, 'success', successTitle));
          }
          if (isSubmit) {
            if (args.postSaveRoutePath) {
              const { entityId } = res;
              const invokeRoute = routeTo.bind(this);

              if (typeof args.postSaveRoutePath === 'function') {
                if (args.postSaveRoutePath.length > 1) {
                  invokeRoute(
                    args.postSaveRoutePath(entityId || '', this.props),
                  );
                } else {
                  invokeRoute(args.postSaveRoutePath(entityId));
                }
              } else if (typeof args.postSaveRoutePath === 'string') {
                const route: string = args.postSaveRoutePath;
                invokeRoute(route);
              }
            } else if (this.props.history) {
              this.props.history.goBack();
            } else {
              // eslint-disable-next-line
              console.error(
                'Unable to determine a routing action after submit!',
              );
            }
          }
        }
      };

      invokeStateInitialization = (props: any) => {
        const pending: any = props.preExistingEntity;
        props.initializeState(pending);
        if (pending) this.invokeValidation();
      };

      invokeValidation = async () => this.props.validate();

      handleCancel = () => {
        this.invokeStateInitialization(this.props);

        // Send the user back to the view that loaded this Form
        if (this.props.history && this.props.history) {
          this.props.history.goBack();
        }
        if (this.props.onCancelEdit) {
          this.props.onCancelEdit();
        }
      };

      handleSaveClick: () => Promise<any> = () =>
        this.handleSave(this.props, false);

      handleDoneClick: () => Promise<any> = () =>
        this.handleSave(
          this.props,
          !Boolean(args.disableRedirectOnSubmit) && true,
        );

      handleSave: (
        pending: any,
        isSubmit?: boolean,
      ) => Promise<{
        entityId: ID | null | undefined;
        success: boolean;
      }> = async (pending: any, isSubmit?: boolean) => {
        const res = await this.props.save(this.props.formQueryRefetch);

        this.parseResponse(res, isSubmit);
        return res;
      };

      transformProps: (
        inputProps: FormMediatorProps<TIn>,
      ) => WrappedProps<TIn> = (inputProps: FormMediatorProps<TIn>) => {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const {
          history,
          location,
          match,
          staticContext,
          onCancelEdit,
          rawData,
          preExistingEntity,
          loading,
          confirmSubmitModalContent,
          setConfirmSubmitModalContent,
          ...rest
        } = inputProps;

        const fromRedux = this.getFormReduxStateProps(inputProps);
        const res: WrappedProps<TIn> = {
          ...rest,
          ...this.state,
          ...fromRedux,
          confirmSubmitModalContent,
          setConfirmSubmitModalContent,
          onSave: this.handleSaveClick,
          onCancel: this.handleCancel,
          onSubmit: this.handleDoneClick,
          loading: !!loading,
        };

        return res;
      };

      isFormDisabled = (disabledProps: DisabledChildProps) => {
        // From Permission Guards
        const { accessDenied = false } = disabledProps;
        const { canEditState = true } = this.props;

        // Disabled Forms Redux?  How is this cleared?
        const disabledFormsKeyPath = ['nonpersisted', 'disabledForms'];
        const reduxState = store.getState();
        const disabledForms =
          reduxState && (reduxState as any).getIn(disabledFormsKeyPath);

        const disabledByRedux = !!(
          disabledForms && disabledForms.get(args.formId)
        );

        /*
          Not all queryied types implement the EditStateType interface, so there's no universal
          bound for TIn.  I'm forced to ignore that typing gap here.  

          We are looking for the case in which we have a query result, 
          the canEdit property is defined, and it equals *exactly* true
        */
        const canEditDisabled = Boolean(
          //@ts-ignore
          this.props.preExistingEntity?.canEdit === false,
        );

        // Disabledby permissionds, by redux, or by backend state
        const disabled =
          accessDenied || disabledByRedux || !canEditState || canEditDisabled;

        return disabled;
      };

      renderWithFrame = (disabledProps: DisabledChildProps) => {
        const { accessDenied = false } = disabledProps;

        const { confirmSubmitModalContent, mutateProperty } = this.props;

        const { withApproval, withSaveAsDraft } = args;

        const childProps = this.transformProps(this.props);

        const disabled = this.isFormDisabled(disabledProps);
        // Provide a Header and Footer, including Save & Cancel Buttons

        return (
          <FormFrame
            confirmBeforeSubmit={args.confirmBeforeSubmit}
            confirmSubmitModalContent={confirmSubmitModalContent}
            disabled={disabled}
            footerMessage={args.footerMessage}
            formHeader={args.formHeader}
            formId={args.formId}
            isDirty={childProps.isDirty}
            loading={childProps.loading}
            mutateProperty={mutateProperty}
            onCancel={this.handleCancel}
            onSave={this.handleSaveClick}
            onSubmit={this.handleDoneClick}
            saving={childProps.isSaving}
            simpleFooter={args.simpleFooter}
            withApproval={withApproval}
            withSaveAsDraft={withSaveAsDraft}
          >
            <FormComponent
              {...childProps}
              blurEventForm={args.blurEventForm}
              disabled={accessDenied || !!disabled}
            />
          </FormFrame>
        );
      };

      renderWithoutFrame = (disabledProps: DisabledChildProps) => {
        //  Render without Header & Footer.  The child component will need to wire up
        const childProps = this.transformProps(this.props);

        // const disabled = disabledForms && disabledForms.get(args.formId);
        const disabled = this.isFormDisabled(disabledProps);

        return (
          <FormComponent
            {...childProps}
            blurEventForm={args.blurEventForm}
            disabled={disabled}
          />
        );
      };

      render() {
        const renderMethod =
          args && !args.disableFrame
            ? this.renderWithFrame
            : this.renderWithoutFrame;

        return !args.editSecurity ? (
          renderMethod(enabled)
        ) : (
          <AccessDeniedGuard permission={args.editSecurity}>
            {renderMethod}
          </AccessDeniedGuard>
        );
      }
    };
    return withRouter(MediatorWrapperComponent);
  };
}

export default FormMediator;
