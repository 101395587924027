import { MutatePropertyAction } from '../types';
import { rootKey, isDirty, getKeyPath } from '../methods';

/* eslint-disable import/prefer-default-export */
const isDirtyKeyPath = [rootKey, isDirty];

export function handleMutateProperty(state: any, action: MutatePropertyAction) {
  const { property, value } = action.payload;

  const keyPath = getKeyPath(property);

  if (state.getIn(keyPath) === value) return state;

  let updatedState;
  if (!Array.isArray(property)) {
    // This simply edits the root object
    updatedState = state.setIn(keyPath, value).setIn(isDirtyKeyPath, true);
  } else {
    // this deeply edits
    const dirtyPath = [
      rootKey,
      ...property.slice(0, property.length - 1),
      isDirty,
    ];

    updatedState = state.setIn(keyPath, value).setIn(dirtyPath, true);
  }

  return updatedState;
}
