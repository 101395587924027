import React from 'react';
import moment from 'moment';
import { Icon, Popup } from 'components';
import { SbaPppForgivenessTrackerType } from 'types';
import { formatMoney } from 'utils';

interface Props {
  rows: Array<SbaPppForgivenessTrackerType>;
  dealName: string;
}

const columns: {
  id: string;
  header: string;
  cleaner: (value?: string) => string;
}[] = [
  {
    id: 'eventTimestamp',
    header: 'Date',
    cleaner: (value?: string) =>
      value ? moment(value).format('MM/DD/YY') : '-',
  },
  {
    id: 'event',
    header: 'Event',
    cleaner: (value?: string) => value || '-',
  },
  {
    id: 'amount',
    header: 'Amount ($)',
    cleaner: (value?: string) => `$${formatMoney(value)}`,
  },
];

const DownloadButton = (props: Props) => {
  const { rows: trackerRows, dealName } = props;

  const timestamp = moment().format('MM-DD-YY HH:mm');
  const csvName = `Forgiveness ${dealName} ${timestamp}.csv`;

  const headers = columns.map(column => column.header).join(',');

  const handleOnClick = (e?: React.SyntheticEvent<HTMLAnchorElement>) => {
    const rows = trackerRows.map(deal =>
      columns.map(column => `"${column.cleaner(deal[column.id])}"`).join(','),
    );

    const csvHeader = 'data:text/csv;charset=utf-8,';
    const csvContent = csvHeader + headers + '\n' + rows.join('\n');
    const encodedUri = encodeURI(csvContent);

    // Fill in the data for the anchor-tag
    e.currentTarget.setAttribute('href', encodedUri);
    e.currentTarget.setAttribute('download', csvName);

    // ... and actually trigger the download by just letting the event continue bubbling
  };

  return (
    <Popup
      basic
      on="hover"
      position="bottom right"
      trigger={
        <a className="DownloadButton" onClick={handleOnClick}>
          <Icon icon={['fal', 'arrow-to-bottom']} />
        </a>
      }
    >
      Download this view as .csv
    </Popup>
  );
};

export default DownloadButton;
