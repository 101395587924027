import * as React from 'react';
import { OrganizationPermissions } from '../../../../types';
import { formatDate } from '../../utils';
import UserRoleCell from './UserRoleCell';
import RemoveUserCell from './RemoveUserCell';
import { ID, DealUserType } from 'types';
import { DisplayTable } from 'components';

const sectionBaseClass = 'Section';
const userRowBaseClass = `${sectionBaseClass}-UserRow`;

type Props = {
  currentUserId: ID;
  dealId: ID;
  index: number;
  isBorrower: boolean;
  onRemoveUser: (user: DealUserType) => void;
  permissions: OrganizationPermissions;
  user: DealUserType;
};

function renderInfo(user: DealUserType) {
  const infoMessage =
    user && user.lastLogin && `Last login: ${formatDate(user.lastLogin)}`;

  return (
    <span className={`${userRowBaseClass}-Contact--Info`}>{infoMessage}</span>
  );
}

function UserRow(props: Props) {
  const {
    currentUserId,
    user,
    index,
    permissions,
    onRemoveUser,
    isBorrower,
    dealId,
  } = props;

  return (
    <DisplayTable.TR
      className={`${userRowBaseClass} ${userRowBaseClass}-${
        index % 2 ? 'Even' : 'Odd'
      }`}
    >
      <DisplayTable.TD className={`${userRowBaseClass}-Contact`}>
        {user.fullName || user.email}
        {renderInfo(user)}
      </DisplayTable.TD>
      <DisplayTable.TD className={`${userRowBaseClass}-Email`}>
        {user.email}
      </DisplayTable.TD>
      <DisplayTable.TD className={`${userRowBaseClass}-DealAccess`}>
        <UserRoleCell
          currentUserId={currentUserId}
          dealId={dealId}
          isBorrower={isBorrower}
          permissions={permissions}
          user={user}
        />
      </DisplayTable.TD>
      <DisplayTable.TD className={`${userRowBaseClass}-Actions`}>
        <RemoveUserCell
          currentUserId={currentUserId}
          onRemoveUser={onRemoveUser}
          permissions={permissions}
          user={user}
        />
      </DisplayTable.TD>
    </DisplayTable.TR>
  );
}

export default UserRow;
