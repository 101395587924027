import * as React from 'react';
import uuid from 'uuid/v4';
import TrancheGroup from '../TrancheGroup';
import { LoanTrancheFormProps } from '../../types';
import CollateralFields from './CollateralFields';
import { Button } from 'components';
import { ID, LoanTrancheCollateralType } from 'types';
import { KeyPath } from 'lsredux';

import './CollateralFields.scss';

type CollateralFieldsCollectionProps = LoanTrancheFormProps & {
  addEntity: (keyPath: KeyPath, entity: any) => void;
  newCollateralId: ID;
};

class CollateralFieldsCollection extends React.Component<
  CollateralFieldsCollectionProps
> {
  newCollateralId: string;

  constructor(props: CollateralFieldsCollectionProps) {
    super(props);
    this.newCollateralId = uuid();
  }

  handleAddCollateral = () => {
    // Generate a new id for the new collateral
    this.newCollateralId = uuid();

    this.props.addEntity('loantranchecollateralSet', {
      id: this.newCollateralId,
      __typename: 'LoanTrancheCollateralType',
    });
  };

  canAddCollateral = () => {
    const {
      data: { loantranchecollateralSet = [] },
    } = this.props;

    const lastCollateral: LoanTrancheCollateralType | null | undefined =
      loantranchecollateralSet.length > 0
        ? loantranchecollateralSet[loantranchecollateralSet.length - 1]
        : null;

    return (
      // We need to check if the collateral has more than 3 properties because
      // every new guarantor has 3 default properties: id, isDirty, and __typename
      lastCollateral && Object.values(lastCollateral).filter(Boolean).length > 3
    );
  };

  renderCollaterals() {
    const {
      data: { loantranchecollateralSet = [] },
    } = this.props;

    if (loantranchecollateralSet.length > 0) {
      return loantranchecollateralSet.map<any>(
        (e: LoanTrancheCollateralType) => (
          <CollateralFields key={e.id} {...this.props} data={e} />
        ),
      );
    }

    return (
      <CollateralFields
        {...this.props}
        data={null}
        newCollateralId={this.newCollateralId}
      />
    );
  }

  render() {
    return (
      <TrancheGroup>
        {this.renderCollaterals()}
        <div>
          <Button.Text
            disabled={!this.canAddCollateral()}
            label="+ Add New Collateral"
            onClick={this.handleAddCollateral}
          />
        </div>
      </TrancheGroup>
    );
  }
}

export default CollateralFieldsCollection;
