import * as React from 'react';
import { Form } from 'components';
import { FormFieldsType } from 'types';

const fields: FormFieldsType = {
  question1: {
    id: 'question1',
    propertyName: 'question1',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
  },
  question2: {
    id: 'question2',
    propertyName: 'question2',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
  },
  question3: {
    id: 'question3',
    propertyName: 'question3',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    helpText:
      'If YES, you will be asked later to provide a listing of affiliate businesses.',
  },
  question4: {
    id: 'question4',
    propertyName: 'question4',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    helpText:
      'If YES, you will be asked later to provide details about your EIDL.',
  },
  question5: {
    id: 'question5',
    propertyName: 'question5',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    helpText:
      'You will later initial to confirm your response to this question when signing your application.',
  },
  question6: {
    id: 'question6',
    propertyName: 'question6',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    helpText:
      'You will later initial to confirm your response to this question when signing your application.',
  },
  question7: {
    id: 'question7',
    propertyName: 'question7',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
  },
  question8: {
    id: 'question8',
    propertyName: 'question8',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    helpText:
      'If YES, you will be asked later to provide your franchise number and/or name.',
  },
};

const SbaPppLoanApplication = 'SbaPppLoanApplication';

function OwnershipForm(props: any) {
  const { data } = props;

  return (
    <div className="Questions">
      <Form.Text
        className="required"
        value="1. Is the Applicant or any other owner of the Applicant presently suspended, 
        debarred, proposed for debarment, declared ineligible, voluntarily excluded 
        from participation in this transaction by any Federal department or agency, or 
        presently involved in any bankruptcy?"
      />
      {Form.FieldRenderer(
        Form.Radio,
        { ...fields.question1, onChange: props.mutateProperty },
        props,
        SbaPppLoanApplication,
        data.id,
      )}
      <Form.Text
        className="required"
        value="2. Has the Applicant, or any owner of the Applicant, or any business owned or 
        controlled by any of them, ever obtained a direct or guaranteed loan from SBA or 
        any other Federal agency that is currently delinquent or has defaulted in the last
      7 years and caused a loss to the government?"
      />

      {Form.FieldRenderer(
        Form.Radio,
        { ...fields.question2, onChange: props.mutateProperty },
        props,
        SbaPppLoanApplication,
        data.id,
      )}
      <Form.Text
        className="required"
        value="3. Is the Applicant or any owner of the Applicant an owner of any other business, 
        or have common management with, any other business?"
      />

      {Form.FieldRenderer(
        Form.Radio,
        { ...fields.question3, onChange: props.mutateProperty },
        props,
        SbaPppLoanApplication,
        data.id,
      )}
      <Form.Text
        className="required"
        value="4. Has the Applicant received an SBA Economic Injury Disaster Loan between
        January 31, 2020 and April 3, 2020?"
      />

      {Form.FieldRenderer(
        Form.Radio,
        { ...fields.question4, onChange: props.mutateProperty },
        props,
        SbaPppLoanApplication,
        data.id,
      )}
      <Form.Text
        className="required"
        value="5. Is the Applicant (if an individual) or any individual owning 20% or more of the equity of the Applicant
        presently incarcerated or, for any felony, presently subject to an indictment, criminal information,
        arraignment, or other means by which formal criminal charges are brought in any jurisdiction?"
      />

      {Form.FieldRenderer(
        Form.Radio,
        { ...fields.question5, onChange: props.mutateProperty },
        props,
        SbaPppLoanApplication,
        data.id,
      )}
      <Form.Text
        className="required"
        value="6. Within the last 5 years, for any felony involving fraud, bribery, embezzlement, or a false statement in a loan
        application or an application for federal financial assistance, or within the last year, for any other felony, has
        the Applicant (if an individual) or any owner of the Applicant 1) been convicted; 2) pleaded guilty; 3) pleaded
        nolo contendere; or 4) commenced any form of parole or probation (including probation before judgment)?"
      />

      {Form.FieldRenderer(
        Form.Radio,
        { ...fields.question6, onChange: props.mutateProperty },
        props,
        SbaPppLoanApplication,
        data.id,
      )}
      <Form.Text
        className="required"
        value="7. Is the United States the principal place of residence for all employees of 
        the Applicant included in the Applicant’s payroll calculation above?"
      />

      {Form.FieldRenderer(
        Form.Radio,
        { ...fields.question7, onChange: props.mutateProperty },
        props,
        SbaPppLoanApplication,
        data.id,
      )}
      <Form.Text
        className="required"
        value="8. Is the Applicant a franchise that is listed in the SBA’s Franchise Directory?"
      />

      {Form.FieldRenderer(
        Form.Radio,
        { ...fields.question8, onChange: props.mutateProperty },
        props,
        SbaPppLoanApplication,
        data.id,
      )}
    </div>
  );
}

export default OwnershipForm;
