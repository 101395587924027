import * as React from 'react';

import './DataGridEmptyCell.scss';

/**
 * An empty data grid cell.
 */

const DataGridEmptyCell = ({ className = '' }: { className: string }) => (
  <div className={`DataGridEmptyCell ${className}`} />
);

export default DataGridEmptyCell;
