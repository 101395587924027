import { LoanFundingQueryDefinition } from './definitions';
import formQueryBuilder from './queryBuilders/formQueryBuilder';
import { LoanFundingType } from 'types';

export const LoanFundingFormQuery = formQueryBuilder(
  LoanFundingQueryDefinition,
  {
    typename: 'LoanFunding',
    skip: (props: { id: string | null | undefined }) => {
      const { id } = props;
      return !id;
    },
    variables: (props: { id: string | null | undefined }) => {
      const { id } = props;
      return { id };
    },
    results: (data: { loanFunding: LoanFundingType | null | undefined }) => {
      const { loanFunding } = data;
      return loanFunding;
    },
  },
);
