import { fromJS } from 'immutable';
import { SORT_BY } from '../../actions/auditTrail';
import standardReducerFactory from '../standardReducer';

const initialState = fromJS({
  sortBy: { column: 'date', reverse: false },
});

const auditTrailReducerMap: {
  [key: string]: (state: any, action: any) => Record<string, any>;
} = {};

auditTrailReducerMap[SORT_BY] = (state, action) =>
  state.set('sortBy', fromJS(action.payload));

export default standardReducerFactory(auditTrailReducerMap, initialState);
