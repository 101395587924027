import Cookies from 'js-cookie';
import { noop } from 'lodash';
import { REACT_APP_UPLOAD_URL, urlJoin } from '../url';
import { ID } from 'types';
import { invariant } from 'utils';

const token = 'csrftoken';
const XCSRFTokenHeader = 'X-CSRFToken';
const Auth0AuthorizationHeader = 'Authorization';

type UploadResponse = {
  id: number;
  success: boolean;
};

export type FileUploadData = {
  description?: string | null | undefined;
  file: File;
  parentId: ID;
};

export type TermSheetUploadData = {
  dealId: ID;
  file: File;
};

function parseResponse(response) {
  try {
    return JSON.parse(response);
  } catch {
    return response;
  }
}

async function uploadFile<T extends { file: File }>(
  fileData: T,
  setData: (data: FormData) => void,
  endpoint: string,
  progressCallback?: (event: ProgressEvent, percent: number) => void,
) {
  invariant(this, '"this" needs to be binded');
  const auth0 = this.context;
  const accessToken = await auth0.getTokenSilently();

  return new Promise<number>((resolve, reject) => {
    const data = new FormData();

    const { file } = fileData;
    invariant(file, 'UploadFile was invoked with an invalid File object');
    const { size } = file;
    invariant(size >= 0, 'File did not have a valid size value');

    data.append('name', fileData.file.name);
    data.append('mime_type', fileData.file.type);
    data.append('file', file);
    data.append('size', size.toString());

    setData(data);

    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        let response: UploadResponse | null | undefined;
        if (xhr.response) response = JSON.parse(xhr.response);

        if (!response || !response.id) {
          reject(
            new TypeError(`Invalid UploadResponse: ${xhr.response || ''}`),
          );
        }
        resolve(response.id);
      } else {
        const response = xhr.response
          ? parseResponse(xhr.response)
          : xhr.statusText;

        reject(response);
      }
    };

    xhr.onerror = () => {
      reject(new TypeError(`Upload failed: ${xhr.statusText}`));
    };

    xhr.ontimeout = () => {
      reject(new TypeError(`Upload timed out: ${xhr.statusText}`));
    };

    const csrf = Cookies.get(token);

    invariant(csrf, 'Failed to get CSRF Token');
    invariant(accessToken, 'Failed to get Access Token');

    xhr.open('POST', endpoint, true);

    // Set CSRF Header and include cookie
    xhr.withCredentials = true;
    xhr.setRequestHeader(XCSRFTokenHeader, csrf);
    xhr.setRequestHeader(Auth0AuthorizationHeader, `Bearer ${accessToken}`);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    if (xhr.upload && progressCallback) {
      xhr.upload.onprogress = (ev: ProgressEvent) => {
        const percent = Math.min(100, (ev.loaded / ev.total) * 100);
        progressCallback(ev, percent);
      };
    }

    xhr.send(data);
  });
}

export function uploadDataroomFile(
  fileData: FileUploadData,
  progressCallback?: (event: ProgressEvent, percent: number) => void,
) {
  return uploadFile.bind(
    this,
    fileData,
    (data: FormData) => {
      data.append('parent_id', fileData.parentId);
      if (fileData.description)
        data.append('description', fileData.description);
    },
    urlJoin(REACT_APP_UPLOAD_URL, 'document/'),
    progressCallback,
  )();
}

export function uploadTermSheet(
  fileData: TermSheetUploadData,
  progressCallback?: (event: ProgressEvent, percent: number) => void,
) {
  return uploadFile.bind(
    this,
    fileData,
    (data: FormData) => {
      data.append('deal_id', fileData.dealId);
    },
    urlJoin(REACT_APP_UPLOAD_URL, 'term_sheet/'),
    progressCallback,
  )();
}

export function uploadActionFormDocument(entityName: string) {
  return function uploadDocument(
    file: File,
    entityId: string,
    progressCallback?: (event: ProgressEvent, percent: number) => void,
  ) {
    const fileUploadData = { file, parentId: entityId };

    return uploadFile<FileUploadData>(
      fileUploadData,
      noop,
      urlJoin(REACT_APP_UPLOAD_URL, entityName, entityId, '/'),
      progressCallback,
    );
  };
}
