import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import { LoanTrancheBasicFields } from '../fragments/generated/LoanTrancheFields';
import {
  DealBasicFields,
  LoanTrancheMultiDrawDataShallowFields,
  LoanTrancheInstitutionOwnershipPortionShallowFields,
  LoanTrancheOwnershipShallowFields,
  LoanTrancheRevolverSwinglineLOCDataShallowFields,
} from '../fragments/generated';
import { LoanTrancheOwnershipPortionShallowFields } from '../fragments/generated/LoanTrancheOwnershipPortionFields';
import { getAuthenticatedClient } from '../authenticatedClient';
import formQueryBuilder from './queryBuilders/formQueryBuilder';
import { DealType } from 'types';

const LenderRegistryFormQueryDefinition: DocumentNode = gql`
  query DealQuery($id: ID) {
    deal(id: $id) {
      ...DealBasicFields
      loantrancheSet {
        ...LoanTrancheBasicFields

        loantranchemultidrawdata {
          ...LoanTrancheMultiDrawDataShallowFields
        }
        loantrancherevolverswinglinelocdata {
          ...LoanTrancheRevolverSwinglineLOCDataShallowFields
        }

        commitmentDrawType
        initialDrawAmount
        ownership {
          ...LoanTrancheOwnershipShallowFields
        }
        loantrancheinstitutionownershipportionSet {
          ...LoanTrancheInstitutionOwnershipPortionShallowFields
          institution {
            id
            name
          }
          loantrancheownershipportionSet {
            ...LoanTrancheOwnershipPortionShallowFields
            institution {
              id
              name
            }
          }
        }
      }
    }
  }
  ${LoanTrancheInstitutionOwnershipPortionShallowFields}
  ${LoanTrancheMultiDrawDataShallowFields}
  ${LoanTrancheRevolverSwinglineLOCDataShallowFields}
  ${LoanTrancheOwnershipShallowFields}
  ${LoanTrancheOwnershipPortionShallowFields}
  ${LoanTrancheBasicFields}
  ${DealBasicFields}
`;

type DealId = {
  dealId: string | null | undefined;
};

export const LenderRegistryFormQuery = formQueryBuilder(
  LenderRegistryFormQueryDefinition,
  {
    typename: 'Deal',
    skip: (props: DealId) => {
      const { dealId } = props;
      return !dealId;
    },
    variables: (props: DealId) => {
      const { dealId } = props;
      return { id: dealId };
    },
    results: (data: { deal?: DealType | null | undefined }) => {
      const { deal } = data;
      return deal;
    },
  },
);

export const LenderRegistryFetchMethod = async ({ id }) => {
  const variables: {
    [key: string]: any;
  } = { id };
  const res = await getAuthenticatedClient().query({
    query: LenderRegistryFormQueryDefinition,
    variables,
    fetchPolicy: 'network-only',
  });

  return {
    ...res,
    data: res.data.deal,
  };
};
