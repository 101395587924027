import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';

import RoundTwoLenderApplication from './RoundTwoLenderApplication';
import { RouteParams, withRouteParams } from 'routing';
import { SbaPppRoundTwoLenderApplicationFormQuery } from 'lsgql';
import { DealPerspectivePermissions } from 'security/authorization/definitions/Deal';

import sbaPppRoundLenderApplicationFormRedux from 'lsredux/reducer/forms/sbaPppRoundTwoLenderApplication';

import { FormMediator, withLoadingIndicator } from 'components';
import triggerClosingWorkflowTransition from 'lsgql/mutations/TransitionClosingWorkflow';
// import { isWholeNumber } from 'utils';

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  const bound = sbaPppRoundLenderApplicationFormRedux.actions.generateActions(
    dispatch,
  );

  const save = async (formQueryRefetch: any) => {
    const res = await bound.save(formQueryRefetch);

    if (res.success) {
      try {
        if (
          ownProps?.preExistingEntity?.dealClosingWorkflow?.state !==
          'lender_submit_to_sba'
        ) {
          await triggerClosingWorkflowTransition({
            workflowId: ownProps?.preExistingEntity?.dealClosingWorkflow?.id,
            state: 'lender_review_lender_application',
          });
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    }

    return res;
  };

  const onChangeLenderApplication = (
    value: string | null | undefined,
    keyPath: string,
  ) => {
    bound.mutateProperty(value, ['sbaPppRoundTwoLenderApplication', keyPath]);
  };

  const onChangeBorrowerApplication = (
    value: string | null | undefined,
    keyPath: string,
  ) => {
    bound.mutateProperty(value, ['sbaPppLoanApplication', keyPath]);
  };

  return {
    ...bound,
    save,
    onChangeBorrowerApplication,
    onChangeLenderApplication,
  };
};

export default compose(
  withRouteParams([RouteParams.dealId]),
  withProps((ownProps: any) => {
    return {
      id: ownProps.dealId,
    };
  }),
  SbaPppRoundTwoLenderApplicationFormQuery,
  withLoadingIndicator('loading'),
  withProps((ownProps: any) => {
    return {
      isSecondDraw:
        ownProps?.preExistingEntity?.category ===
        'SBA_PPP_ROUND_TWO_SECOND_DRAW',
    };
  }),
  connect(
    sbaPppRoundLenderApplicationFormRedux.mapStateToProps,
    mapDispatchToProps,
  ),
  FormMediator({
    formId: 'roundTwoLenderApplicationForm',
    blurEventForm: true,
    baseObjectTypeName: 'Deal',
    simpleFooter: true,
    editSecurity: DealPerspectivePermissions.view_closing,
  }),
)(RoundTwoLenderApplication);
