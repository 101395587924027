import * as React from 'react';

import moment from 'moment';
import { noop, merge } from 'lodash';
import ReportViewTableContainer from './ReportViewTableContainer';
import {
  AuditTrailEntryType,
  AuditTrailCollectionsType,
  AuditTrailSingleCollectionType,
  SelectOptionType,
  VersionType,
} from 'types';
import { Form, momentDateFormat } from 'components';
import {
  shallowComparison,
  capitalizeSentence,
  sortDropdownOptions,
} from 'utils';

const width = 'four';
const ALL = 'All';
const defaultOption = [{ text: 'All', value: 'All' }];
const DATE_FORMAT = 'YYYY/MM/DD - h:mm A';
const DATE_IN_MILLISECONDS_FORMAT = 'YYYY/MM/DD - h:mm:ss fff A';
const DAY = 'day';
const DAYS = 'days';
const SECTION_FILTER = 'sectionName';
const USER_FILTER = 'user';
const ITEM_FILTER = 'itemName';

type Props = {
  changedItem: string;
  resetFilters: () => void;
  section: string;
  setChangedItem: (arg0: string) => void;
  setSection: (arg0: string) => void;
  setUser: (arg0: string) => void;
  setViewEnd: (date: Date) => void;
  setViewStart: (date: Date) => void;
  user: string;
  versions: Array<VersionType>;
  viewEnd: Date;
  viewStart: Date;
};

type SerializedData = {
  date_created: string;
  fields: any;
  institution: string;
  model: string;
  pk: string;
  section: string;
  user: string;
};

class ReportView extends React.Component<Props> {
  componentDidMount = () => {
    const { resetFilters } = this.props;
    resetFilters();
  };

  compareEntries = (
    firstEntry: AuditTrailSingleCollectionType | { data: {} },
    secondEntry: AuditTrailSingleCollectionType,
  ): Array<AuditTrailEntryType> => {
    const difference:
      | {
          [key: string]: { from: any; to: any };
        }
      | null
      | undefined = shallowComparison(firstEntry.data, secondEntry.data);

    const validEntries: Array<AuditTrailEntryType> = [];
    if (!difference) return [];

    Object.keys(difference).forEach(field => {
      const diff = difference[field];

      const validatedEntry = merge({}, secondEntry, {
        // We need to do this because one entry could
        // have multiple changes. Therefore, we end up with multiple
        // rows at times with the same id.
        id: `${secondEntry.id}_${field}`,
      });

      const {
        id,
        user,
        institution,
        date,
        dateInMilliseconds,
      } = validatedEntry;

      validEntries.push(
        merge({
          // We need to convert both of these values to strings
          // in order to keep the right order during sorting
          changedFrom: String(diff.from),
          changedTo: String(diff.to),
          date,
          dateInMilliseconds,
          id,
          itemName: capitalizeSentence(field),
          sectionName: capitalizeSentence(validatedEntry.section),
          user,
          institution,
        }),
      );
    });

    return validEntries;
  };

  filterBy = (
    rows: Array<AuditTrailEntryType>,
    propertyValue: string,
    propertyName: string,
  ): Array<AuditTrailEntryType> =>
    propertyValue === ALL
      ? rows
      : rows.filter(
          (entry: AuditTrailEntryType) => entry[propertyName] === propertyValue,
        );

  filterByDate = (
    rows: Array<AuditTrailEntryType>,
    viewRange: Array<Date>,
  ): Array<AuditTrailEntryType> => {
    const [viewStart, viewEnd] = viewRange;
    return (
      rows &&
      rows.filter(
        (entry: AuditTrailEntryType) =>
          moment(viewStart) // We need to subtract one day to include the range start
            .subtract(1, DAYS)
            .toDate() < moment(entry.date).toDate() &&
          moment(entry.date).toDate() <
            moment(viewEnd) // We need to add one day to include the range end
              .add(1, DAYS)
              .toDate(),
      )
    );
  };

  filterRows = (validatedEntries: Array<AuditTrailEntryType>) => {
    const { changedItem, viewStart, viewEnd, section, user } = this.props;

    // The filtering needs to happen in this specific
    // order to give the user a good UX
    const filteredRowsByDate: Array<AuditTrailEntryType> = this.filterByDate(
      validatedEntries,
      [viewStart, viewEnd],
    );
    const filteredRowsBySection: Array<AuditTrailEntryType> = this.filterBy(
      filteredRowsByDate,
      section,
      SECTION_FILTER,
    );
    const filteredRowsByItemName: Array<AuditTrailEntryType> = this.filterBy(
      filteredRowsBySection,
      changedItem,
      ITEM_FILTER,
    );
    const filteredRowsByUser: Array<AuditTrailEntryType> = this.filterBy(
      filteredRowsByItemName,
      user,
      USER_FILTER,
    );

    return {
      filteredRowsByDate,
      filteredRowsBySection,
      filteredRowsByItemName,
      filteredRowsByUser,
    };
  };

  getOptions = (
    filter: string,
    filteredRows: Array<AuditTrailEntryType>,
    filterName: string,
  ) => {
    // We need to make sure the filters are unique
    // before adding them to our dropdown options
    const options: Array<SelectOptionType> = defaultOption.slice();
    // We have to push the current filter into the dropdown options
    // so that the option is always available even during re-rendering
    if (filter !== ALL) options.push({ text: filter, value: filter });

    const optionNames: Array<string> = this.uniqueNames(
      filteredRows,
      filterName,
    );

    optionNames.forEach((name: string) => {
      // Avoid duplicating filters due to the prepushed filter
      if (name !== filter) {
        options.push({ text: name, value: name });
      }
    });

    return sortDropdownOptions(options);
  };

  handleChangeItem = (value: string | null | undefined) => {
    if (!value) return;
    this.props.setChangedItem(value);
  };

  handleChangeSection = (value: string | null | undefined) => {
    if (!value) return;
    this.props.setSection(value);
  };

  handleChangeUser = (value: string | null | undefined) => {
    if (!value) return;
    this.props.setUser(value);
  };

  handleChangeStartDate = (value: string | null | undefined) => {
    if (!value) return;

    const { viewEnd, setViewStart } = this.props;
    const endDate = moment(viewEnd);
    const startDate = moment(value);
    if (startDate.isSameOrBefore(endDate, DAY)) {
      setViewStart(startDate.toDate());
    }
  };

  handleChangeEndDate = (value: string | null | undefined) => {
    if (!value) return;

    const { viewStart, setViewEnd } = this.props;
    const endDate = moment(value);
    const startMoment = moment(viewStart);
    if (endDate.isSameOrAfter(startMoment, DAY)) {
      setViewEnd(endDate.toDate());
    }
  };

  organizeVersionsByModelName = (
    versions: Array<VersionType> = [],
  ): AuditTrailCollectionsType => {
    const collections = {};

    versions.forEach(entry => {
      // A record response could have multiple changed entries, although
      // in most cases it only contains one
      const serializedData: Array<SerializedData> = entry.serializedData
        ? JSON.parse(entry.serializedData.replace(/null/gi, '""'))
        : [];

      serializedData.forEach(data => {
        const { fields, pk, section, model, user, institution } = data;

        const formattedEntry = {
          id: entry.id,
          data: fields,
          parentId: pk,
          date: moment
            .utc(data.date_created, DATE_FORMAT)
            .local()
            .toDate(),
          dateInMilliseconds: moment(
            data.date_created,
            DATE_IN_MILLISECONDS_FORMAT,
          ).valueOf(),
          section,
          user,
          institution,
        };

        collections[model] = collections[model]
          ? [...collections[model], formattedEntry]
          : [formattedEntry];
      });
    });

    return collections;
  };

  sortByIdThenDate = (
    collection: Array<AuditTrailSingleCollectionType>,
  ): Array<AuditTrailSingleCollectionType> =>
    collection.sort(
      (a, b) =>
        a.parentId - b.parentId ||
        (a.dateInMilliseconds as any) - (b.dateInMilliseconds as any),
    );

  uniqueNames = (
    rowsToFilter: Array<AuditTrailEntryType>,
    propertyName: string,
  ) =>
    Array.from(
      new Set(
        rowsToFilter.map((entry: AuditTrailEntryType) => entry[propertyName]),
      ),
    );

  validateEntries = (
    objectCollections: AuditTrailCollectionsType,
  ): Array<AuditTrailEntryType> => {
    const validEntries: Array<AuditTrailEntryType> = [];
    const emptyObject = { data: {} };

    // There seems to be an issue with flow using `Object.values` and `forEach`.
    // `forEach` is expecing a `mixed` type from the core library
    Object.values(objectCollections).forEach(
      (collection: Array<AuditTrailSingleCollectionType>) => {
        const sortedCollection: Array<AuditTrailSingleCollectionType> = this.sortByIdThenDate(
          collection,
        );

        if (sortedCollection.length === 1) {
          // If the sortedCollection has only one entry, we compare that entry with
          // an empty object because it means that it is a new object.
          validEntries.push(
            ...this.compareEntries(emptyObject, sortedCollection[0]),
          );
        }

        for (let j = 0; j < sortedCollection.length - 1; j++) {
          const firstEntry: AuditTrailSingleCollectionType =
            sortedCollection[j];
          const secondEntry: AuditTrailSingleCollectionType =
            sortedCollection[j + 1];

          if (j === 0) {
            // In the first step of the iteration, we compare the first entry with
            // an empty object because it means that it is a new object.
            validEntries.push(...this.compareEntries(emptyObject, firstEntry));
          }

          validEntries.push(...this.compareEntries(firstEntry, secondEntry));
        }
      },
    );

    return validEntries;
  };

  render() {
    const { versions } = this.props;
    const { changedItem, viewStart, viewEnd, section, user } = this.props;

    // We want to sort the versions to maintain consistency
    const sortedVersions =
      versions &&
      versions.sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10));

    const startDate = moment(viewStart).format(momentDateFormat);
    const endDate = moment(viewEnd).format(momentDateFormat);

    const objectCollections = this.organizeVersionsByModelName(sortedVersions);
    const validatedRows = this.validateEntries(objectCollections);

    const filteredRows = this.filterRows(validatedRows);
    const {
      filteredRowsByDate,
      filteredRowsBySection,
      filteredRowsByItemName,
      filteredRowsByUser,
    } = filteredRows;

    const sectionOptions = this.getOptions(
      section,
      filteredRowsByDate,
      SECTION_FILTER,
    );
    const changedItemOptions = this.getOptions(
      changedItem,
      filteredRowsBySection,
      ITEM_FILTER,
    );
    const userOptions = this.getOptions(
      user,
      filteredRowsByItemName,
      USER_FILTER,
    );

    return (
      <div className="auditTrailView">
        <Form
          className="auditTrailView__DateRanges"
          id="auditTrailView"
          onSubmit={noop}
        >
          <Form.Group>
            <Form.Calendar
              fieldName="From Date"
              id="auditTrailViewFromDate"
              onChange={this.handleChangeStartDate}
              propertyName="startDate"
              value={startDate}
              width={width}
            />
            <Form.Calendar
              fieldName="To Date"
              id="auditTrailViewToDate"
              onChange={this.handleChangeEndDate}
              propertyName="endDate"
              value={endDate}
              width={width}
            />
            <Form.Select
              allowEmpty={false}
              fieldName="Section"
              id="reportSection"
              onChange={this.handleChangeSection}
              options={sectionOptions}
              propertyName="reportSection"
              value={section}
              width={width}
            />
            <Form.Select
              allowEmpty={false}
              fieldName="Changed Item"
              id="changedItem"
              onChange={this.handleChangeItem}
              options={changedItemOptions}
              propertyName={changedItem}
              value={changedItem}
              width={width}
            />
            <Form.Select
              allowEmpty={false}
              fieldName="Changed By"
              id="user"
              onChange={this.handleChangeUser}
              options={userOptions}
              propertyName={user}
              value={user}
              width={width}
            />
          </Form.Group>
        </Form>
        {/* We only want to render the records after they have passed
           through all filters, in this case it would be the
           `filteredRowsByUser` */}
        <ReportViewTableContainer rows={filteredRowsByUser} />
      </div>
    );
  }
}

export default ReportView;
