import { ActionBaseType } from '../types';

type MethodMap = {
  [key: string]: (state: any, action: any) => Record<string, any>;
};

function standardReducerFactory<A extends ActionBaseType>(
  methodMap: MethodMap,
  initialState: any,
) {
  return function standardReducer(state: any = initialState, action: A) {
    if (methodMap[action.type]) {
      return methodMap[action.type](state, action);
    }
    return state;
  };
}

export default standardReducerFactory;
