import memoize from 'lodash/memoize';
import {
  MutationMethod,
  FetchMethod,
  MutationResult,
  LSMutationData,
} from '../../types';
import {
  Mutations,
  createPrefix,
  setPrefix,
  deletePrefix,
  stubPrefix,
  fetchPrefix,
} from '../types';
import {
  BaseType,
  ObjectTypeNames,
  ID,
  ValidationType,
  ObjectBaseTypeNames,
  ObjectCamelBaseTypeNames,
} from 'types';
import { getTypeName, getCamelTypeNameString, getTypeNameString } from 'utils';

const typeNameField = '__typename';

/* eslint-disable import/prefer-default-export */

/**
 * For an object base type, calculate the standard CRUD mutation names.
 * e.g. for 'Deal':
 *  createDeal
 *  setDeal
 *  deleteDeal
 *  fetchDeal
 *  stubDeal
 */
export const getMethodNames = memoize((typeName: ObjectBaseTypeNames) => ({
  setMethodName: `${setPrefix}${typeName}`,
  createMethodName: `${createPrefix}${typeName}`,
  stubMethodName: `${stubPrefix}${typeName}`,
  deleteMethodName: `${deletePrefix}${typeName}`,
  fetchMethodName: `${fetchPrefix}${typeName}`,
}));

/**
 * Create a (e: T) => Promise<*> stub function
 * that only serves to return e.  This allows
 * paths through the tree to remain unbroken, but not
 * issue a mutation for a clean object
 *
 * @param {*} typeName
 */
function createStubMutation<T extends BaseType>(
  typeName: ObjectTypeNames,
): (arg0: T) => Promise<MutationResult<T>> {
  const typename: ObjectBaseTypeNames = getTypeNameString(typeName);
  const camelTypeName: ObjectCamelBaseTypeNames = getCamelTypeNameString(
    typeName,
  );
  const methodName = getMethodNames(typename).stubMethodName;

  return function stubMutation(e: T): Promise<MutationResult<T>> {
    const data: LSMutationData<T> = {
      [methodName]: {
        ok: true,
        [camelTypeName]: e,
      },
    } as any;

    const res: MutationResult<T> = {
      data,
    };

    return Promise.resolve(res);
  };
}

type ValidationMethod = (arg0: ID) => Promise<ValidationType>;

/**
 * Find the create/set/delete mutation methods present on props
 * and map them into a Mutations<T> object
 * @param {*} e
 * @param {*} props
 */
export function extractMutationMethods<T extends BaseType>(
  e: T,
  mutationMethods: {
    validators?: Partial<
      {
        [key in ObjectBaseTypeNames]: ValidationMethod;
      }
    >;
  } & { [key: string]: MutationMethod<any, any> },
): Mutations<any, any> {
  const typeName: ObjectBaseTypeNames = getTypeName(e);

  // Compute Method names
  const methodNames = getMethodNames(typeName);

  // Extract methods
  const deleteMethod:
    | MutationMethod<any, any>
    | null
    | undefined = mutationMethods[methodNames.deleteMethodName]
    ? ((mutationMethods[methodNames.deleteMethodName] as any) as MutationMethod<
        any,
        any
      >)
    : null;
  const create: MutationMethod<any, any> =
    mutationMethods[methodNames.createMethodName];
  const edit: MutationMethod<any, any> =
    mutationMethods[methodNames.setMethodName];
  const fetch: FetchMethod<T> | null | undefined = mutationMethods[
    methodNames.fetchMethodName
  ]
    ? ((mutationMethods[methodNames.fetchMethodName] as any) as FetchMethod<T>)
    : null;

  const validate: ValidationMethod | null | undefined =
    mutationMethods.validators && mutationMethods.validators[typeName]
      ? mutationMethods.validators[typeName]
      : null;

  const res: Mutations<any, any> = {
    create,
    edit,
    delete: deleteMethod,
    fetch,
    stub: createStubMutation(e[typeNameField]),
    validate,
  };

  return res;
}
