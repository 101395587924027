import { fromJS } from 'immutable';
import uuid from 'uuid/v4';
import { InitializeStateAction, FormReduxArgs } from '../types';
import { getFormShape, flagDirty } from '../methods';
import { BaseType } from 'types';

/* eslint-disable import/prefer-default-export */

/**
 * Initialize the state.  Use the action entity, if provided,
 * otherwise initialize with a copy of the initialState.data object
 * @param {*} action
 * @param {*} initialState
 */
export function handleClearState<TOut extends BaseType, TIn extends BaseType>(
  action: InitializeStateAction<TOut>,
  initialEntityState: any,
  args?: FormReduxArgs<TOut, TIn>,
): any {
  let initialEntity = { ...initialEntityState.toJS(), id: uuid() };
  if (args && args.dataConverter) {
    initialEntity = args.dataConverter(initialEntity);
  }
  const validatedEntity = flagDirty(initialEntity);
  const formShape = getFormShape<TOut, TIn>(validatedEntity, args);
  return fromJS(flagDirty(formShape));
}
