import { SendPromissoryNoteMutationDefinition } from './definitions';

import { mutationBuilder } from './builders';
import { ID } from 'types';

type SendPromissoryNoteInput = {
  workflowId: ID;
  returnUrl: string;
};

type SendPromissoryNoteType = {
  ok: boolean;
  redirectUrl: string;
};

const sendPromissoryNote = mutationBuilder<
  SendPromissoryNoteInput,
  SendPromissoryNoteType
>(SendPromissoryNoteMutationDefinition, {
  build: mutate => (data: SendPromissoryNoteInput) => {
    return mutate({
      variables: {
        workflowId: data.workflowId,
        returnUrl: data.returnUrl,
      },
      refetchQueries: ['DealQuery'],
    });
  },
});

export default sendPromissoryNote;
