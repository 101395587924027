import * as React from 'react';
import uuid from 'uuid/v4';
import InstitutionEntityForm from './InstitutionEntityForm';
import { Button, WrappedMediatorFormProps } from 'components';
import { DealInstitutionType, InstitutionType } from 'types';
import { MountGuard, InstitutionPerspectivePermissions } from 'security';

type EntityCollectionProps = WrappedMediatorFormProps<InstitutionType> & {
  data: InstitutionType;
};

class InstitutionEntityCollection extends React.Component<
  EntityCollectionProps
> {
  newEntityId: string;

  constructor(props: EntityCollectionProps) {
    super(props);
    this.newEntityId = uuid();
  }

  handleAddEntity = () => {
    // Generate a new id for the new Entity
    this.newEntityId = uuid();

    this.props.addEntity('subsidiaries', {
      id: this.newEntityId,
      __typename: 'DealInstitutionType',
    });
  };

  canAddEntity = () => {
    const { data } = this.props;
    const subsidiaries = data.subsidiaries || [];

    const lastEntity: DealInstitutionType | null | undefined =
      subsidiaries.length > 0 ? subsidiaries[subsidiaries.length - 1] : null;

    return !lastEntity || (lastEntity && lastEntity.name);
  };

  renderEntities() {
    const { data, ...rest } = this.props;
    const subsidiaries = data.subsidiaries || [];

    if (subsidiaries.length > 0) {
      return subsidiaries.map<any>((e: InstitutionType) => (
        <InstitutionEntityForm
          key={e.id}
          {...rest}
          data={e}
          newEntityId={this.newEntityId}
        />
      ));
    }

    return null;
  }

  render() {
    return (
      <>
        {this.renderEntities()}
        <MountGuard
          permission={InstitutionPerspectivePermissions.administer_institution}
        >
          <Button
            disabled={!this.canAddEntity()}
            label="Add Entity"
            onClick={this.handleAddEntity}
          />
        </MountGuard>
      </>
    );
  }
}

export default InstitutionEntityCollection;
