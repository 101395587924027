export type ActionTypesMap = {
  addEntitiesActionType: string;
  addEntityActionType: string;
  clearStateActionType: string;
  createNewStateActionType: string;
  deleteCollectionActionType: string;
  editActionType: string;
  handleResponseActionType: string;
  initializeStateActionType: string;
  mutatePropertiesActionType: string;
  removeEntityActionType: string;
  removeSelfActionType: string;
  replaceEntityActionType: string;
  saveActionType: string;
  setValidationErrors: string;
  toggleAutoSaveActionType: string;
  toggleDirtyFlagActionType: string;
  toggleSaveActionType: string;
  undoActionType: string;
};

/* eslint-disable import/prefer-default-export */
export function createActionTypes(key: string): ActionTypesMap {
  return {
    addEntitiesActionType: `${key}_ADD_ENTITIES`,
    addEntityActionType: `${key}_ADD_ENTITY`,
    clearStateActionType: `${key}_CLEAR_STATE`,
    createNewStateActionType: `${key}_CREATE_NEW_STATE`,
    deleteCollectionActionType: `${key}_DELETE_COLLECTION`,
    editActionType: `${key}_EDIT_PROPERTY`,
    handleResponseActionType: `${key}_HANDLE_GQL_RESPONSE`,
    initializeStateActionType: `${key}_INITIALIZE_STATE`,
    mutatePropertiesActionType: `${key}_MUTATE_PROPERTIES`,
    removeEntityActionType: `${key}_REMOVE_ENTITY`,
    removeSelfActionType: `${key}_REMOVE_SELF`,
    replaceEntityActionType: `${key}_REPLACE_ENTITY`,
    saveActionType: `${key}_SAVE`,
    toggleAutoSaveActionType: `${key}_TOGGLE_AUTO_SAVE`,
    toggleDirtyFlagActionType: `${key}_TOGGLE_DIRTY_FLAG`,
    toggleSaveActionType: `${key}_TOGGLE_SAVE`,
    undoActionType: `${key}_UNDO`,
    setValidationErrors: `${key}_SET_VALIDATION_ERRORS`,
  };
}
