import React, { useEffect } from 'react';
import { Form, Header, Modal, WrappedMediatorFormProps } from 'components';
import {
  FormFieldsType,
  ID,
  SbaPppLenderClosingWorkflowQuestionsType,
} from 'types';

import './LenderApplicationQuestions.scss';

const fields: FormFieldsType = {
  question1: {
    id: 'question1',
    propertyName: 'question1',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
  },
  question2: {
    id: 'question2',
    propertyName: 'question2',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
  },
  question3: {
    id: 'question3',
    propertyName: 'question3',
    label: 'True',
  },
  question4: {
    id: 'question4',
    propertyName: 'question4',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
  },
  question5: {
    id: 'question5',
    propertyName: 'question5',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
  },
  question6: {
    id: 'question6',
    propertyName: 'question6',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
  },
  question7: {
    id: 'question7',
    propertyName: 'question7',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
  },
  question8: {
    id: 'question8',
    propertyName: 'question8',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
  },
  question9: {
    id: 'question9',
    propertyName: 'question9',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
  },
  question10: {
    id: 'question10',
    propertyName: 'question10',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    helpText:
      'If YES, the Lender may not pass any agent fee through to the Applicant or offset or pay the fee with the proceeds of this loan.',
  },
};

const SbaPppLoanApplication = 'SbaPppLenderClosingWorkflowQuestions';

interface Props
  extends WrappedMediatorFormProps<SbaPppLenderClosingWorkflowQuestionsType> {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onClose: () => void;
  dealId: ID;
}
function LenderApplicationQuestions(props: Props) {
  const { data, isOpen, onClose, onSave, setIsOpen } = props;
  const { mutateProperty, dealId } = props;

  useEffect(() => {
    mutateProperty(dealId, 'dealId');
  }, [dealId, mutateProperty]);
  async function handleSave() {
    const res = await onSave();
    if (res.success) {
      setIsOpen(false);
    }
  }

  return (
    <Modal
      className="steel"
      confirmButtonText="Save to Loan Application"
      header="Lender Application Questions: Sections E-K (Revised June 24th, 2020)"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSave}
      requireResponse
      width="Half"
    >
      <div className="LenderApplicationQuestions">
        <div className="section">
          <Form.Divider thin />
          <Header as="h5" className="caps">
            General Eligibility
          </Header>
          <Form.Text
            className="required"
            value="The Applicant has certified to the Lender that (1) it was in operation on February 15, 2020 and had employees for whom the Applicant paid salaries and payroll taxes or paid independent contractors, as reported on Form(s) 1099-MISC, (2) current economic uncertainty makes this loan request necessary to support the ongoing operations of the Applicant, (3) the funds will be used to retain workers and maintain payroll or make mortgage interest payments, lease payments, and utility payments, and (4) the Applicant has not received another Paycheck Protection Program loan."
          />
          {Form.FieldRenderer(
            Form.Radio,
            { ...fields.question1, onChange: props.mutateProperty },
            props,
            SbaPppLoanApplication,
            data.id,
          )}
          <Form.Text
            className="required"
            value="The Applicant has certified to the Lender that it (1) is an independent contractor, eligible self-employed individual, or sole proprietor or (2) employs no more than the greater of 500 employees or, if applicable, meets the size standard in number of employees established by the SBA in 13 C.F.R. 121.201 for the Applicant’s industry."
          />
          {Form.FieldRenderer(
            Form.Radio,
            { ...fields.question2, onChange: props.mutateProperty },
            props,
            SbaPppLoanApplication,
            data.id,
          )}
        </div>
        <Form.Divider />
        <Header as="h5" className="caps">
          Applicant Certification of Eligibility
        </Header>
        <div className="section">
          <Form.Text
            className="required"
            value="The Applicant has certified to the Lender that the Applicant is eligible under the Paycheck Protection Program Rule."
          />
          {Form.FieldRenderer(
            Form.Checkbox,
            { ...fields.question3, onChange: props.mutateProperty },
            props,
            SbaPppLoanApplication,
            data.id,
          )}
        </div>
        <div className="section">
          <Form.Divider />
          <Header as="h5" className="caps">
            Franchise / License / Jobber / Membership or Similar Agreement
          </Header>
          <Form.Text
            className="required"
            value="The Applicant has represented to the Lender that it is a franchise that is listed in the SBA’s Franchise Directory."
          />
          {Form.FieldRenderer(
            Form.Radio,
            { ...fields.question4, onChange: props.mutateProperty },
            props,
            SbaPppLoanApplication,
            data.id,
          )}
        </div>
        <Form.Divider />
        <Header as="h5" className="caps">
          Character Determination
        </Header>
        <div className="section">
          <Form.Text
            className="required"
            value="The Applicant has represented to the Lender that neither the Applicant (if an individual) nor any individual owning
            20% or more of the equity of the Applicant is presently incarcerated or, for any felony, presently subject to an
            indictment, criminal information, arraignment, or other means by which formal criminal charges are brought in any
            jurisdiction."
          />

          {Form.FieldRenderer(
            Form.Radio,
            { ...fields.question5, onChange: props.mutateProperty },
            props,
            SbaPppLoanApplication,
            data.id,
          )}
          <Form.Text
            className="required"
            value="The Applicant has represented to the Lender that neither the Applicant (if an individual) nor any individual owning
            20% or more of the equity of the Applicant has within the last 5 years, for any felony involving fraud, bribery,
            embezzlement, or a false statement in a loan application or an application for federal financial assistance, or within
            the last year for any other felony: 1) been convicted; 2) pleaded guilty; 3) pleaded nolo contendere; or 4)
            commenced any form of parole or probation (including probation before judgment)."
          />
          {Form.FieldRenderer(
            Form.Radio,
            { ...fields.question6, onChange: props.mutateProperty },
            props,
            SbaPppLoanApplication,
            data.id,
          )}
        </div>
        <div className="section">
          <Form.Divider />
          <Header as="h5" className="caps">
            Prior Loss to Government / Delinquent Federal Debt
          </Header>
          <Form.Text
            className="required"
            value="The Applicant has certified to the Lender that neither the Applicant nor any owner (as defined in the Applicant’s SBA Form 2483) is presently suspended, debarred, proposed for debarment, declared ineligible, voluntarily excluded from participation in this transaction by any Federal department or agency, or presently involved in any bankruptcy."
          />
          {Form.FieldRenderer(
            Form.Radio,
            { ...fields.question7, onChange: props.mutateProperty },
            props,
            SbaPppLoanApplication,
            data.id,
          )}
          <Form.Text
            className="required"
            value="The Applicant has certified to the Lender that neither the Applicant nor any of its owners, nor any business owned or controlled by any of them, ever obtained a direct or guaranteed loan from SBA or any other Federal agency that is currently delinquent or has defaulted in the last 7 years and caused a loss to the government."
          />
          {Form.FieldRenderer(
            Form.Radio,
            { ...fields.question8, onChange: props.mutateProperty },
            props,
            SbaPppLoanApplication,
            data.id,
          )}
        </div>
        <div className="section">
          <Form.Divider />
          <Header as="h5" className="caps">
            U.S. Employees
          </Header>
          <Form.Text
            className="required"
            value="The Applicant has certified that the principal place of residence for all employees included in the Applicant’s payroll calculation is the United States."
          />
          {Form.FieldRenderer(
            Form.Radio,
            { ...fields.question9, onChange: props.mutateProperty },
            props,
            SbaPppLoanApplication,
            data.id,
          )}
        </div>
        <div className="section fees">
          <Form.Divider />
          <Header as="h5" className="caps">
            Fees
          </Header>
          <Form.Text
            className="required"
            value="Is the Lender using a third party to assist in the preparation of the loan application or application materials, or to perform other services in connection with this loan?"
          />
          {Form.FieldRenderer(
            Form.Radio,
            { ...fields.question10, onChange: props.mutateProperty },
            props,
            SbaPppLoanApplication,
            data.id,
          )}
        </div>
      </div>
    </Modal>
  );
}

export default LenderApplicationQuestions;
