const _object = 'object';
const _function = 'function';
const _caller = 'caller';
const _callee = 'callee';
const _args = 'arguments';

/* eslint-disable valid-typeof */

/**
 * Deeply apply Object.freeze() to an object
 * @param {*} obj
 */
export default function deepFreeze(obj: { [key: string]: any }) {
  Object.freeze(obj);

  const oIsFunction = typeof obj === _function;
  const hasOwnProp = Object.prototype.hasOwnProperty;

  Object.getOwnPropertyNames(obj).forEach(prop => {
    if (
      hasOwnProp.call(obj, prop) &&
      (oIsFunction
        ? prop !== _caller && prop !== _callee && prop !== _args
        : true) &&
      obj[prop] !== null &&
      (typeof obj[prop] === _object || typeof obj[prop] === _function) &&
      !Object.isFrozen(obj[prop])
    ) {
      deepFreeze(obj[prop]);
    }
  });

  return obj;
}
