import { MutationMethod } from '../types';
import { mutationBuilder } from './builders';
import {
  SetCreditRatingMutationDefinition,
  CreateCreditRatingMutationDefinition,
  DeleteCreditRatingMutationDefinition,
} from './definitions';
import { CreditRatingInput, CreditRatingType, ID } from 'types';

const createCreditRating: MutationMethod<
  CreditRatingInput,
  CreditRatingType
> = mutationBuilder<CreditRatingInput, CreditRatingType>(
  CreateCreditRatingMutationDefinition,
  {
    build: mutate => creditRatingData =>
      mutate({
        variables: {
          creditRatingData,
        },
      }),
  },
);

const setCreditRating: MutationMethod<
  CreditRatingInput,
  CreditRatingType
> = mutationBuilder<CreditRatingInput, CreditRatingType>(
  SetCreditRatingMutationDefinition,
  {
    build: mutate => creditRatingData =>
      mutate({
        variables: {
          creditRatingData,
        },
      }),
  },
);

const deleteCreditRating: MutationMethod<ID, void> = mutationBuilder<ID, void>(
  DeleteCreditRatingMutationDefinition,
  {
    build: mutate => (id: ID) =>
      mutate({
        variables: {
          id,
        },
      }),
  },
);

const CreditRatingMutationMethods = {
  createCreditRating,
  setCreditRating,
  deleteCreditRating,
};

export default CreditRatingMutationMethods;
