import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import { LoanTrancheFormFields } from '../fragments';
import formQueryBuilder from './queryBuilders/formQueryBuilder';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import {
  LoanTrancheFormQueryType,
  LoanTrancheFormQueryResponseType,
} from 'types';

type TrancheId = {
  trancheId: string | null | undefined;
};

const LoanTrancheQueryDefinition: DocumentNode = gql`
  query LoanTrancheQuery($id: ID) {
    loanTranche(id: $id) {
      ...LoanTrancheFormFields
    }
  }
  ${LoanTrancheFormFields}
`;

export const LoanTrancheFormQuery = formQueryBuilder(
  LoanTrancheQueryDefinition,
  {
    typename: 'LoanTranche',
    skip: (props: TrancheId) => {
      const { trancheId } = props;
      return !trancheId;
    },
    variables: (props: TrancheId) => {
      const { trancheId } = props;
      return { id: trancheId };
    },
    results: (data: any | null) => {
      const res: LoanTrancheFormQueryType = {
        loanTranche: data?.loanTranche,
        amortizationPrincipalPayments:
          data?.loanTrancheInitialAmortizationPrincipalPayments,
        id: data?.loanTranche?.id ?? 'InvalidId',
        __typename: 'LoanTrancheType',
      };
      return res;
    },
  },
);

export const LoanTrancheQuery = standardQueryBuilder(
  LoanTrancheQueryDefinition,
  {
    typename: 'LoanTranche',
    skip: (props: TrancheId) => {
      const { trancheId } = props;
      return !trancheId;
    },
    variables: (props: TrancheId) => {
      const { trancheId } = props;
      return { id: trancheId };
    },
    results: (data: LoanTrancheFormQueryResponseType | null) => {
      const res: LoanTrancheFormQueryType = {
        loanTranche: data?.loanTranche,
        amortizationPrincipalPayments:
          data?.loanTrancheInitialAmortizationPrincipalPayments,
        id: data?.loanTranche?.id ?? 'InvalidId',
        __typename: 'LoanTrancheType',
      };
      return res;
    },
  },
);

export default LoanTrancheFormQuery;
