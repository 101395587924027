import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import {
  Form,
  Header,
  IconButton,
  Button,
  WrappedMediatorFormProps,
  StandardModal,
  Tiler,
  TextLink,
} from 'components';
import {
  SbaPppLoanApplicationType,
  PublicInstitutionType,
  SbaPppApplicantOwnershipType,
  PublicIsValidJobType,
  ID,
} from 'types';
import AlertSystem from '../applicationContext/alerts/AlertSystemContainer';
import RoundTwoInstitution from './formPartials/RoundTwoInstitution';
import RoundTwoLoanFinancials from './formPartials/RoundTwoLoanFinancials';
import Ownership from './formPartials/RoundTwoOwnership';
import Questions from './formPartials/RoundTwoQuestions';
import { RoundTwoGrossReciepts } from './formPartials/RoundTwoGrossReciepts';
import { PublicBanner } from './PublicBanner';
import {
  RoundTwoFirstDrawHelpBox,
  RoundTwoSecondDrawHelpBox,
} from './helpBox/RoundTwoHelpBox';
import { RouteTable } from 'routing';

import './RoundTwoLoanApplication.scss';

interface CommonProps
  extends WrappedMediatorFormProps<SbaPppLoanApplicationType> {
  onInstitutionChange: (value: string, fieldId: string) => void;
  onLoanFinancialsChange: (value: string, fieldId: string) => void;
  addOwner: () => void;
  deleteOwner: (entity: SbaPppApplicantOwnershipType) => void;
  isPublic: boolean;
  institution?: PublicInstitutionType;
  dealId?: ID;
  disableForm: (disable: boolean) => void;
  generateSbaLenderPdf?: (dealId: ID) => Promise<any>;
  generateSbaBorrowerPdf?: (dealId: ID) => Promise<any>;
  isSecondDraw: boolean;

  isOnboarding: boolean;

  mergeApplications?: (
    stale: SbaPppLoanApplicationType,
    fresh: SbaPppLoanApplicationType,
  ) => SbaPppLoanApplicationType;

  roundOneApplication?: SbaPppLoanApplicationType;
}

interface PublicProps extends CommonProps {
  isValidJob: PublicIsValidJobType;
}

function RoundTwoSbaPppLoanApplicationForm(props: CommonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    data,
    isPublic,
    onInstitutionChange,
    disabled,
    isSecondDraw,
    mergeApplications,
    isOnboarding,
  } = props;
  //const disabled = props.disabled || data?.submittedToSba;

  if (
    (isOnboarding || isPublic) &&
    data.sbapppapplicantownershipSet.length === 0
  ) {
    props.addOwner();
  }

  useEffect(
    () => {
      if (mergeApplications) {
        const merged = mergeApplications(props.roundOneApplication, props.data);
        if (merged) {
          props.mutateProperties([], merged);
          // props.initializeState(merged);
        }
      }
    },
    // eslint-disable-next-line
    [],
  );

  function handleSubmit() {
    isPublic ? props.onSave() : props.onSubmit();

    if (isPublic) {
      setIsModalOpen(false);
    }
  }

  function handleOpen() {
    setIsModalOpen(true);
  }

  function handleClose() {
    setIsModalOpen(false);
  }

  const renderPublicButtons = () => {
    return (
      <Button
        disabled={disabled || props.isSaving}
        label="Send Application"
        onClick={handleOpen}
      />
    );
  };

  // SBA HACK: Validation for "Business Types and Number of Employees"
  const sbaPppLoanFinancialsId = data.sbappploanfinancials?.id;
  const numberOfJobsError =
    props.errors?.SbaPppLoanFinancials?.[sbaPppLoanFinancialsId]?.errors
      ?.numberOfJobs;
  const institution = data.sbapppinstitution;
  if (institution.businessTinType == 'SSN') {
    if (Number(data.sbappploanfinancials?.numberOfJobs) > 1) {
      // Set special error if not already set
      if (!numberOfJobsError) {
        const updatedErrors = {
          ...props.errors,
          SbaPppLoanFinancials: {
            [sbaPppLoanFinancialsId]: {
              ...props.errors?.SbaPppLoanFinancials?.[sbaPppLoanFinancialsId],
              errors: {
                ...props.errors?.SbaPppLoanFinancials?.[sbaPppLoanFinancialsId]
                  ?.errors,
                numberOfJobs: 'Must be 1 employee for this TIN Type',
              },
            },
          },
        };
        props.setValidationErrors(updatedErrors);
      }
    } else {
      // Clear error condition
      if (numberOfJobsError) {
        delete props.errors?.SbaPppLoanFinancials[
          `${data.sbappploanfinancials?.id}`
        ]?.errors?.numberOfJobs;
        props.setValidationErrors(props.errors);
      }
    }
  } else {
    // Clear error condition
    if (numberOfJobsError) {
      delete props.errors?.SbaPppLoanFinancials[sbaPppLoanFinancialsId]?.errors
        ?.numberOfJobs;
      props.setValidationErrors(props.errors);
    }
  }

  return (
    <div className="RoundTwoSbaPppLoanApplicationFormWrapper">
      {isPublic && (
        <div className="header">
          <Header as="h1" className="bold ink">
            {props?.institution?.name}
          </Header>
        </div>
      )}
      {isPublic && <PublicBanner />}
      <div className="TileHelpBoxWrapper">
        <Tiler>
          <Tiler.Column>
            <Tiler.Tile
              className="steel"
              ctaButtons={isPublic && renderPublicButtons}
              ctaLeftAligned
              minWidth="OneThird"
              title={`Paycheck Protection Program ${
                isSecondDraw ? 'Second Draw' : 'First Time'
              } Borrower Application`}
              width="Half"
            >
              <div className="SbaPppLoanApplicationForm">
                <StandardModal
                  confirmButtonText="Confirm and Send"
                  header="Confirm Email Address"
                  isOpen={isModalOpen}
                  onClose={handleClose}
                  onConfirm={handleSubmit}
                >
                  <p>
                    You will receive an email at
                    {` ${data.sbapppinstitution?.email}`}. Please confirm that
                    this is the correct email address. The email will include a
                    link to register on our loan portal, where you’ll collect
                    the necessary documentation, finalize your loan application
                    and receive your approval. Check your spam or junk folder if
                    you do not receive the invitation. Thank you.
                  </p>
                </StandardModal>
                <Form.Text
                  className="bold IntroMessage ink"
                  value="Please complete to the best of your ability at this stage."
                />
                <Form.Divider />
                <Header as="h5" className="caps steel">
                  Business Information
                </Header>
                <RoundTwoInstitution
                  data={data.sbapppinstitution}
                  disabled={disabled}
                  errors={props.errors}
                  isSaving={props.isSaving}
                  isSecondDraw={isSecondDraw}
                  mutateProperty={onInstitutionChange}
                />
                <Form.Divider />
                <Header as="h5" className="caps steel">
                  Loan Amount
                </Header>
                <RoundTwoLoanFinancials
                  data={data.sbappploanfinancials}
                  disabled={disabled}
                  errors={props.errors}
                  institutionType={data.sbapppinstitution?.institutionType}
                  isSaving={props.isSaving}
                  isSecondDraw={isSecondDraw}
                  mutateProperty={props.onLoanFinancialsChange}
                  naicsCode={data.sbapppinstitution?.naicsCode}
                />
                <Form.Divider />
                {isSecondDraw && (
                  <>
                    <Header as="h5" className="caps steel">
                      Reduction in Gross Receipts of at Least 25%
                    </Header>
                    <RoundTwoGrossReciepts
                      data={data.sbappploanfinancials}
                      disabled={disabled}
                      errors={props.errors}
                      isSaving={props.isSaving}
                      mutateProperty={props.onLoanFinancialsChange}
                    />
                    <Form.Divider />
                  </>
                )}
                <Header as="h5" className="caps steel">
                  Applicant Ownership
                </Header>
                <Form.Text
                  className="steel"
                  value="List all owners of 20% or more of the equity of the Applicant. For non-profit, select ‘Business’ owner type, use the Business Name and EIN."
                />
                <>
                  {(data?.sbapppapplicantownershipSet || []).map(
                    (ownership, index) => (
                      <div className="OwnershipWrapper" key={ownership.id}>
                        {index >= 1 && (
                          <>
                            <Form.Divider />
                            <IconButton.Delete
                              onClick={() => props.deleteOwner(ownership)}
                            />
                          </>
                        )}
                        <Ownership
                          data={ownership}
                          disabled={disabled}
                          errors={props.errors}
                          isSaving={props.isSaving}
                          mutateProperty={(value: string, fieldId: string) =>
                            props.mutateProperty(value, [
                              'sbapppapplicantownershipSet',
                              index.toString(),
                              fieldId,
                            ])
                          }
                        />
                      </div>
                    ),
                  )}
                </>
                <Button.Secondary
                  className="AddOwner"
                  disabled={disabled}
                  icon={['fal', 'plus-circle']}
                  label={'Add Another Owner'}
                  onClick={props.addOwner}
                />
                <Form.Divider />
                <Header as="h5" className="caps steel">
                  Questions
                </Header>
                <Questions {...props} isSecondDraw={isSecondDraw} />
                <Form.Divider />
                <Header as="h5" className="caps steel">
                  Next Steps
                </Header>
                <Form.Text
                  className="steel next-steps-description"
                  value={
                    isPublic
                      ? "After clicking the Send Application button below, you'll receive a confirmation email. It will include a link to register on our loan portal, where you’ll collect supporting documentation, finalize and sign your application and receive your approval."
                      : 'After clicking the Save button below, follow the instructions in the Closing Checklist to complete application submission.'
                  }
                />
              </div>
              <Form.Text
                className="bold ink EndNotice"
                value="You will have an opportunity to make updates or corrections to this application."
              />
              <div className="termsOfService">
                {Form.FieldRenderer(
                  Form.Checkbox,
                  {
                    id: 'termsOfService',
                    propertyName: 'termsOfService',
                    /* eslint-disable react/display-name */
                    control: () => (
                      <>
                        By {isPublic ? 'sending' : 'saving'} the application,
                        you agree to the{' '}
                        <TextLink
                          label="Terms of Use"
                          target="newTab"
                          to="https://cdn2.hubspot.net/hubfs/4019390/assets/docs/Terms+of+Use+Agreement+(04-08-16).pdf"
                        />{' '}
                        and{' '}
                        <TextLink
                          label="Privacy Policy"
                          target="newTab"
                          to="https://cdn2.hubspot.net/hubfs/4019390/assets/docs/Privacy+Policy+(04-08-16).pdf"
                        />
                        .
                      </>
                    ),
                    onChange: props.mutateProperty,
                    required: true,
                  },
                  props,
                  'SbaPppLoanApplication',
                  data.id,
                )}
              </div>
            </Tiler.Tile>
          </Tiler.Column>
        </Tiler>
        {isSecondDraw ? (
          <RoundTwoSecondDrawHelpBox />
        ) : (
          <RoundTwoFirstDrawHelpBox />
        )}
      </div>
    </div>
  );
}

type Params = {
  institutionTicker?: string;
  institutionJob?: string;
};

export function RoundTwoPublicSbaPppLoanApplicationForm(props: PublicProps) {
  const { institutionTicker, institutionJob } = useParams<Params>();
  const { data, onInstitutionChange } = props;
  const { sbapppinstitution } = data;

  if (
    !(sbapppinstitution as any).institutionTicker ||
    !(sbapppinstitution as any).institutionJob
  ) {
    onInstitutionChange(institutionTicker, 'institutionTicker');
    onInstitutionChange(institutionJob, 'institutionJob');
  }

  if (!props.institution || (props.isValidJob && !props.isValidJob.valid)) {
    return <Redirect to={RouteTable.application.to404} />;
  }

  return (
    <>
      <AlertSystem />
      <RoundTwoSbaPppLoanApplicationForm
        {...props}
        disabled={false}
        isPublic={true}
        isSecondDraw={false}
      />
    </>
  );
}

export function RoundTwoPrivateSbaPppLoanApplicationForm(
  props: CommonProps & { isSecondDrawRoute: boolean; history: any },
) {
  const { data, isSecondDrawRoute } = props;
  const isSecondDraw =
    isSecondDrawRoute || data?.category === 'SBA_PPP_ROUND_TWO_SECOND_DRAW';

  return (
    <RoundTwoSbaPppLoanApplicationForm
      {...props}
      isPublic={false}
      isSecondDraw={isSecondDraw}
    />
  );
}
