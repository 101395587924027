import { gql } from '@apollo/client';
import { InstitutionShallowFields } from '../fragments';
import { ApolloQueryResult, DocumentNode } from '../types';
import {
  filterKeyValueBuilders,
  executeFilterQuery,
} from './queryBuilders/filterKeyValueBuilders';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { InstitutionsQueryDefinition } from './definitions';
import { InstitutionType } from 'types';

const InstitutionsQueryDefinitionWithParent: DocumentNode = gql`
  query InstitutionsQuery($filters: [FilterKeyValue]) {
    institutions(filters: $filters) {
      ...InstitutionShallowFields
      parent {
        id
      }
    }
  }
  ${InstitutionShallowFields}
`;

export function InstitutionNameSearch(
  name: string,
): Promise<ApolloQueryResult<{ institutions: Array<InstitutionType> }>> {
  return executeFilterQuery(InstitutionsQueryDefinition, [
    filterKeyValueBuilders.propertyContains('name', name),
  ]);
}

export function InstitutionNameSearchWithParent(
  name: string,
): Promise<ApolloQueryResult<{ institutions: Array<InstitutionType> }>> {
  return executeFilterQuery(InstitutionsQueryDefinitionWithParent, [
    filterKeyValueBuilders.propertyContains('name', name),
  ]);
}

export const InstitutionsQuery = standardQueryBuilder(
  InstitutionsQueryDefinition,
  {
    typename: 'Institutions',
    disableCache: true,
    variables: () => ({}),
    results: (data: {
      institutions: Array<InstitutionType> | null | undefined;
    }) => {
      const { institutions } = data;
      return { institutions };
    },
  },
);

const InstitutionsQueryWithUsersDefinition: DocumentNode = gql`
  query InstitutionsQuery($filters: [FilterKeyValue]) {
    institutions(filters: $filters) {
      ...InstitutionShallowFields
      userSet {
        id
        username
      }
    }
  }
  ${InstitutionShallowFields}
`;

export const InstitutionsQueryWithUsers = standardQueryBuilder(
  InstitutionsQueryWithUsersDefinition,
  {
    typename: 'Institutions',
    disableCache: true,
    variables: () => ({}),
    results: (data: {
      institutions: Array<InstitutionType> | null | undefined;
    }) => {
      const { institutions } = data;
      return { institutions };
    },
  },
);
