import * as React from 'react';

import Main from '../../features/main/MainContainer';
import { CreateDealFormContainer } from '../../features/dealForm/DealFormContainer';
import DealContextContainer from '../../features/dealContext/DealContextContainer';
import ProfileView from '../../features/profileView/ProfileView';
import UserProfileForm from '../../features/profileView/user/UserProfileFormContainer';
import InstitutionProfileForm from '../../features/profileView/institution/InstitutionProfileFormContainer';
import Debug from '../../features/debug/Debug';
import HelpView from '../../features/helpView/HelpView';
import ReportsView from '../../features/reports/ReportsView';
import NewUserCreateSecondDrawLoanApp from '../../features/roundTwoSbaPppLoanApplication/NewUserCreateSecondDrawLoanApplicationContainer';

import { RoutingContext } from '../types';
import { RouteTable } from '../RouteTable';
import { SecuredRoute, RenderWithRouter } from './RouteRenderers';
import { GlobalPerspectivePermissions } from 'security';

/* eslint-disable react/display-name */

/**
 * Routes that exist within the Application Context
 */
const applicationRoutes: RoutingContext = {
  routes: [
    {
      render: () => <Debug />,
      path: RouteTable.application.toDebug,
    },
    {
      render: SecuredRoute(
        CreateDealFormContainer,
        GlobalPerspectivePermissions.add_deal,
      ),
      path: RouteTable.application.toCreateDeal,
      exact: true,
    },
    {
      render: () => <HelpView />,
      path: RouteTable.application.toHelp,
      exact: true,
    },
    {
      render: RenderWithRouter(ProfileView),
      path: RouteTable.application.toProfile,
      exact: true,
    },
    {
      render: RenderWithRouter(ReportsView),
      path: RouteTable.application.toReports,
      exact: true,
    },
    {
      render: RenderWithRouter(UserProfileForm),
      path: RouteTable.user.toUserProfile,
    },
    {
      render: RenderWithRouter(DealContextContainer),
      path: RouteTable.deal.toDeal(),
    },
    {
      render: RenderWithRouter(InstitutionProfileForm),
      path: RouteTable.institution.toEdit(),
    },
    {
      render: (props: any) => (
        <NewUserCreateSecondDrawLoanApp
          {...props}
          isInitialSecondDraw
          isSecondDrawRoute
        />
      ),

      path: RouteTable.application.toSecondDrawApp(),
    },

    /** CATCH ALL ROUTE */
    {
      render: () => <Main />,
      path: RouteTable.application.toHome,
    },
  ],
};

export default applicationRoutes;
