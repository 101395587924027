import * as React from 'react';
import cx from 'classnames';
import { Icon, Money, FontAwesomeIconTypes } from 'components';
import { ux, invariant } from 'utils';
import { LenderRegistryFormTrancheType, SortBy } from 'types';

type Props = {
  onSortByChange: (column: string) => void;
  sortBy: SortBy;
  totalAmount: string | null | undefined;
  tranche: LenderRegistryFormTrancheType;
};

type ColumnType = {
  column: string;
  display: string;
};
const categories: ColumnType[] = [
  { column: 'NAME', display: 'LENDERS' },
  { column: 'OWNERSHIP', display: 'OWNERSHIP(%)' },
  { column: 'AMOUNT', display: 'COMMITMENT($)' },
  { column: 'OUTSTANDING', display: 'OUTSTANDING BALANCE($)' },
  { column: 'UNUSED', display: 'UNUSED COMMITMENTS($)' },
];

const defaultIcon: FontAwesomeIconTypes = ['far', 'angle-down'];
const reversedIcon: FontAwesomeIconTypes = ['far', 'angle-up'];
function LenderRegistryStatHeader(props: Props) {
  const { totalAmount, tranche, sortBy } = props;
  invariant(tranche.ownership, "Ownership object doesn't exist!");

  function handleSortByChange(column: string) {
    return function OnSortByChange() {
      props.onSortByChange(column);
    };
  }

  return (
    <div className="lenderRegistryStatHeader">
      {categories.map<React.ReactNode>((category, index) => {
        const className = cx(
          'lenderRegistryTranche__ColumnItem',
          'lenderRegistryStatHeader__Category',
          ux(index === 0, 'lenderRegistryTranche__ColumnItem--LeftAlignText'),
        );

        const icon =
          sortBy.column === category.column && sortBy.reverse
            ? reversedIcon
            : defaultIcon;
        const color =
          sortBy.column === category.column ? 'marshmellow' : 'steel';

        return (
          <button
            className={className}
            key={category.column}
            onClick={handleSortByChange(category.column)}
            type="button"
          >
            <div className="lenderRegistryStatHeader__CategoryText">
              {category.display}
            </div>
            <Icon color={color} icon={icon} />
          </button>
        );
      })}
      <div />
      <div />
      <div />
      <Money
        className="lenderRegistryTranche__MoneyDisplay"
        value={totalAmount || ''}
      />
      <div className="lenderRegistryTranche__MoneyDisplay">-</div>
      <div className="lenderRegistryTranche__MoneyDisplay">-</div>
      <div />
    </div>
  );
}

export default LenderRegistryStatHeader;
