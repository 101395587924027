import * as React from 'react';
import noop from 'lodash/noop';
import { InterestScheduleRowProps } from './InterestScheduleRow';
import { DisplayTable, TableColumn } from 'components';
import { AmortizationWithReaderDataType } from 'types';

type Props = {
  amortization?: AmortizationWithReaderDataType | null | undefined;
  onRateChange: (
    value: string | null | undefined,
    fieldId: string,
    index: number,
  ) => void;
};

type Args = {
  columns: ReadonlyArray<TableColumn>;
  interestRowComponent: React.ComponentType<InterestScheduleRowProps>;
};

const sortBy = {
  column: 'Period',
  reverse: false,
};

function createInterestScheduleTable(args: Args) {
  const InterestRowComponent = args.interestRowComponent;
  return class InterestScheduleTable extends React.PureComponent<Props> {
    render() {
      const { amortization, onRateChange } = this.props;
      const schedule =
        amortization && amortization.loantrancheamortizationinterestperiodSet
          ? amortization.loantrancheamortizationinterestperiodSet
          : [];

      return (
        <DisplayTable
          className="AmortizationTable__DisplayTable"
          columns={args.columns}
          hoverable
          onChangeSortField={noop}
          sortBy={sortBy}
        >
          {schedule.map((e, i) => (
            <InterestRowComponent
              index={i}
              interestRow={e}
              key={e.startPeriod}
              onRateChange={onRateChange}
            />
          ))}
        </DisplayTable>
      );
    }
  };
}

export default createInterestScheduleTable;
