import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import AlertSystem from '../applicationContext/alerts/AlertSystemContainer';
import Institution from './Institution';
import Ownership from './Ownership';
import LoanFinancials from './LoanFinancials';
import Questions from './Questions';
import { RouteTable } from 'routing/RouteTable';
import {
  Form,
  Header,
  IconButton,
  Button,
  WrappedMediatorFormProps,
  StandardModal,
  Tiler,
  TextLink,
} from 'components';

import {
  SbaPppLoanApplicationType,
  PublicInstitutionType,
  SbaPppApplicantOwnershipType,
  PublicIsValidJobType,
  ID,
} from 'types';

interface CommonProps
  extends WrappedMediatorFormProps<SbaPppLoanApplicationType> {
  onInstitutionChange: (value: string, fieldId: string) => void;
  onLoanFinancialsChange: (value: string, fieldId: string) => void;
  addOwner: () => void;
  deleteOwner: (entity: SbaPppApplicantOwnershipType) => void;
  isPublic: boolean;
  institution?: PublicInstitutionType;
  dealId?: ID;
  disableForm: (disable: boolean) => void;
  generateSbaLenderPdf?: (dealId: ID) => Promise<any>;
  generateSbaBorrowerPdf?: (dealId: ID) => Promise<any>;
}

interface PublicProps extends CommonProps {
  isValidJob: PublicIsValidJobType;
}

function RoundOneSbaPppLoanApplicationForm(props: CommonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, isPublic, onInstitutionChange, disabled } = props;
  //const disabled = props.disabled || data?.submittedToSba;

  if (props.isPublic && data.sbapppapplicantownershipSet.length === 0) {
    props.addOwner();
  }

  function handleSubmit() {
    props.onSubmit();

    if (isPublic) {
      setIsModalOpen(false);
    }
  }

  function handleOpen() {
    setIsModalOpen(true);
  }

  function handleClose() {
    setIsModalOpen(false);
  }

  const renderPublicButtons = () => {
    return (
      <>
        <Button.Text
          disabled={disabled || props.isSaving}
          label={'Cancel'}
          onClick={props.onCancel}
        />
        <Button
          disabled={disabled || props.isSaving}
          label="Send Application"
          onClick={handleOpen}
        />
      </>
    );
  };
  return (
    <>
      <div className="RoundOneSbaPppLoanApplicationFormWrapper">
        {isPublic && (
          <div className="header">
            <Header as="h1" className="bold ink">
              {props?.institution?.name}
            </Header>
          </div>
        )}
        <Tiler>
          <Tiler.Column>
            <Tiler.Tile
              className="steel"
              ctaButtons={isPublic && renderPublicButtons}
              ctaLeftAligned
              minWidth="OneThird"
              title="Paycheck Protection Program Application (Revised June 24th, 2020)"
              width="Half"
            >
              <div className="SbaPppLoanApplicationForm">
                <StandardModal
                  confirmButtonText="Confirm and Send"
                  header="Confirm Email Address"
                  isOpen={isModalOpen}
                  onClose={handleClose}
                  onConfirm={handleSubmit}
                >
                  <p>
                    You will receive an email at
                    {` ${data.sbapppinstitution?.email}`}. Please confirm that
                    this is the correct email address. The email will include a
                    link to register on our loan portal, where you’ll collect
                    the necessary documentation, finalize your loan application
                    and receive your approval. Check your spam or junk folder if
                    you do not receive the invitation. Thank you.
                  </p>
                </StandardModal>
                {isPublic && (
                  <Form.Text
                    className="bold IntroMessage ink"
                    value="Please complete to the best of your ability at this stage."
                  />
                )}
                <Form.Divider />
                <Header as="h5" className="caps steel">
                  Business Information
                </Header>
                <Institution
                  data={data.sbapppinstitution}
                  disabled={disabled}
                  errors={props.errors}
                  isSaving={props.isSaving}
                  mutateProperty={onInstitutionChange}
                />
                <Form.Divider />
                <Header as="h5" className="caps steel">
                  Loan Amount
                </Header>
                <LoanFinancials
                  data={data.sbappploanfinancials}
                  disabled={disabled}
                  errors={props.errors}
                  isSaving={props.isSaving}
                  mutateProperty={props.onLoanFinancialsChange}
                />
                <Form.Divider />
                <Header as="h5" className="caps steel">
                  Applicant Ownership
                </Header>

                <Form.Text
                  className="steel"
                  value="List all owners of Applicant with greater than 20% ownership
                  stakes."
                />
                <>
                  {data.sbapppapplicantownershipSet.map((ownership, index) => (
                    <div className="OwnershipWrapper" key={ownership.id}>
                      <Ownership
                        data={ownership}
                        disabled={disabled}
                        errors={props.errors}
                        isSaving={props.isSaving}
                        mutateProperty={(value: string, fieldId: string) =>
                          props.mutateProperty(value, [
                            'sbapppapplicantownershipSet',
                            index.toString(),
                            fieldId,
                          ])
                        }
                      />
                      {index >= 1 && (
                        <IconButton.Delete
                          onClick={() => props.deleteOwner(ownership)}
                        />
                      )}
                    </div>
                  ))}
                </>
                <Button.Secondary
                  className="AddOwner"
                  disabled={disabled}
                  icon={['fal', 'plus-circle']}
                  label={'Add Another Owner'}
                  onClick={props.addOwner}
                />
                <Form.Divider />
                <Header as="h5" className="caps steel">
                  Questions
                </Header>
                <Questions {...props} />
                {isPublic && (
                  <>
                    <Form.Divider />
                    <Header as="h5" className="caps steel">
                      Next Steps
                    </Header>
                    <Form.Text
                      className="steel"
                      value="After clicking the Send Application button below, you’ll
                      receive a confirmation email. It will include a link to register
                      on our loan portal, where you’ll collect supporting documentation,
                      finalize and sign your application and receive your approval."
                    />
                  </>
                )}
              </div>
              <>
                {isPublic && (
                  <Form.Text
                    className="bold ink EndNotice"
                    value="You will have an opportunity to make updates or corrections to this application."
                  />
                )}
                <div className="termsOfService">
                  {Form.FieldRenderer(
                    Form.Checkbox,
                    {
                      id: 'termsOfService',
                      propertyName: 'termsOfService',
                      /* eslint-disable react/display-name */
                      control: () => (
                        <>
                          By {isPublic ? 'sending' : 'saving'} the application,
                          you agree to the{' '}
                          <TextLink
                            label="Terms of Use"
                            target="newTab"
                            to="https://cdn2.hubspot.net/hubfs/4019390/assets/docs/Terms+of+Use+Agreement+(04-08-16).pdf"
                          />{' '}
                          and{' '}
                          <TextLink
                            label="Privacy Policy"
                            target="newTab"
                            to="https://cdn2.hubspot.net/hubfs/4019390/assets/docs/Privacy+Policy+(04-08-16).pdf"
                          />
                          .
                        </>
                      ),
                      onChange: props.mutateProperty,
                      required: true,
                    },
                    props,
                    'SbaPppLoanApplication',
                    data.id,
                  )}
                </div>
              </>
            </Tiler.Tile>
          </Tiler.Column>
        </Tiler>
      </div>
    </>
  );
}

export function RoundOnePublicSbaPppLoanApplicationForm(props: PublicProps) {
  if (!props.institution || (props.isValidJob && !props.isValidJob.valid)) {
    return <Redirect to={RouteTable.application.to404} />;
  }

  return (
    <>
      <AlertSystem />
      <RoundOneSbaPppLoanApplicationForm
        {...props}
        disabled={false}
        isPublic={true}
      />
    </>
  );
}

export function PrivateSbaPppLoanApplicationForm(props: CommonProps) {
  return <RoundOneSbaPppLoanApplicationForm {...props} isPublic={false} />;
}
