import { fromJS } from 'immutable';
import { SortBy } from 'types';

const generateTableActions = (reducerKey: string) => ({
  SORT_BY: `${reducerKey}_SORT_BY`,
});

export const generateTableMethods = (reducerKey: string) => {
  const { SORT_BY } = generateTableActions(reducerKey);
  return {
    changeSortField: (payload: SortBy) => ({
      type: SORT_BY,
      payload,
    }),
  };
};

export const generateTableReducerMap = (reducerKey: string) => {
  const { SORT_BY } = generateTableActions(reducerKey);
  return {
    [SORT_BY]: (state, action) => state.set('sortBy', fromJS(action.payload)),
  };
};

export default {
  actions: generateTableActions,
  methods: generateTableMethods,
  reducerMap: generateTableReducerMap,
};
