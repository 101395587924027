import * as React from 'react';
import { Form } from 'components';
import {
  BenchmarkOptionType,
  BenchmarkOptionPaymentFrequency,
  FieldRendererGeneralPropsType,
} from 'types';

const loanTranche = 'LoanTranche';
const width = '158px';

type Props = FieldRendererGeneralPropsType & {
  data: BenchmarkOptionType;
  fieldName: string;
  onUpdateBenchmarkOption: (option: BenchmarkOptionType) => void;
};

type State = { selected: boolean };

class PaymentPeriodNonAbrExceptionItem extends React.Component<Props, State> {
  state = { selected: Boolean(this.props.data.paymentFrequency) };

  handleUpdateBenchmarkOption = (
    value: BenchmarkOptionPaymentFrequency | null | undefined,
  ) => {
    this.props.onUpdateBenchmarkOption({
      ...this.props.data,
      paymentFrequency: value,
    });
  };

  handleToggleCheckmark = (value: boolean | null | undefined) => {
    if (!value) {
      this.handleUpdateBenchmarkOption(null);
    }
    this.setState(state => ({ selected: !state.selected }));
  };

  /* eslint-enable react/sort-comp */

  fields = {
    checkbox: {
      id: 'nonAbrCheckbox',
      propertyName: 'nonAbrCheckbox',
      label: this.props.fieldName,
      onChange: this.handleToggleCheckmark,
      width,
    },
    select: {
      id: 'nonAbrPaymentFrequency',
      propertyName: 'paymentFrequency',
      fieldName: '',
      onChange: this.handleUpdateBenchmarkOption,
      width,
      typeName: 'BenchmarkOptionPaymentFrequency',
    },
  };

  render() {
    const { data } = this.props;

    return (
      <Form.Group>
        {Form.FieldRenderer(
          Form.Checkbox,
          { ...this.fields.checkbox, value: this.state.selected },
          this.props,
          loanTranche,
          data.id,
        )}
        {this.state.selected &&
          Form.FieldRenderer(
            Form.ReferenceSelect,
            {
              ...this.fields.select,
              value: data.paymentFrequency,
            },
            this.props,
            loanTranche,
            data.id,
          )}
      </Form.Group>
    );
  }
}

export default PaymentPeriodNonAbrExceptionItem;
