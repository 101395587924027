import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import TierFeePercentageFields from './TierFeePercentageFields';
import { LoanTrancheTierShallowFields } from './generated';

const LoanTrancheTierFields: DocumentNode = gql`
  fragment LoanTrancheTierFields on LoanTrancheTierType {
    ...LoanTrancheTierShallowFields
    tierfeepercentageSet {
      ...TierFeePercentageFields
    }
  }
  ${LoanTrancheTierShallowFields}
  ${TierFeePercentageFields}
`;

export default LoanTrancheTierFields;
