// eslint-disable-next-line
import * as React from 'react';
import { DropTargetEntity, MutationMethodsProps } from '../types';

export const closeFolderOnDropIfOpenAndNowEmpty = (
  props: MutationMethodsProps,
  dropTargetEntity: DropTargetEntity,
) => {
  if (
    !props.openFolders ||
    !dropTargetEntity.entity ||
    !dropTargetEntity.entity.entity ||
    !dropTargetEntity.entity.entity.id
  )
    return;

  const parentId = dropTargetEntity.entity.entity.parent.id;
  const siblingsAndSelf = props.dataroomFiles.filter(
    file => file && file.parent && file.parent.id === parentId,
  );
  if (props.openFolders[parentId] && siblingsAndSelf.length === 1)
    props.closeFolder(parentId);
};

export const openFolderOnDropIfClosed = (
  props: MutationMethodsProps,
  dropTargetEntity: DropTargetEntity,
) => {
  if (!props.openFolders[dropTargetEntity.dropTargetId])
    props.openFolder(dropTargetEntity.dropTargetId);
};

export const alertsMiddleware = (
  props: MutationMethodsProps,
  dropTargetEntity: DropTargetEntity,
) => {
  // handle native file also
  if (dropTargetEntity.entity.entity) {
    const { entity } = dropTargetEntity.entity;
    const targetFolderChildNames = props.dataroomFiles
      .filter(
        file =>
          file.parent &&
          file.parent.id &&
          file.parent.id === dropTargetEntity.dropTargetId,
      )
      .map(child => child.name);
    if (targetFolderChildNames.includes(entity.name)) {
      props.setAlert(
        entity.name
          ? `${entity.fileType || ''} with name "${
              entity.name
            }" already exists in target folder.`.trim()
          : `${entity.fileType ||
              ''} with that name already exists in target folder.`,
        'error',
      );
    }
  }
};

export const thenMiddleware = [
  closeFolderOnDropIfOpenAndNowEmpty,
  openFolderOnDropIfClosed,
];
