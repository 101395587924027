import * as React from 'react';
import moment from 'moment';
import noop from 'lodash/noop';
import {
  Tiler,
  Summary,
  Button,
  Icon,
  WrappedMediatorFormProps,
} from 'components';
import { EventDueInput, EventDueType } from 'types';

import './CalendarTaskView.scss';

type CalendarTaskViewProps = WrappedMediatorFormProps<EventDueInput> & {
  eventDue: EventDueType;
};

function CalendarTaskView(props: CalendarTaskViewProps) {
  const { dueDatetime, isCompleted, status } = props.eventDue;
  const loanTrancheName = props.eventDue?.loanTranche?.name;
  const {
    name,
    description,
    initialDueDatetime,
    initialDueTimezone,
    eventFrequency,
    reminderOffset,
    overdueAlertOffset,
    category,
    assignedPeople,
  } = props.eventDue?.eventSchedule;
  const emptyField = '–';
  const datetimeFormat = 'YYYY-MM-DDTHH:mm:ssZ';

  // TODO: Turn this into a proper utility function
  function generateStatusIcon() {
    const icons = {
      Draft: ['fal', 'badge'],
      Active: null,
      Complete: ['fal', 'check-circle'],
      Overdue: ['fas', 'exclamation-triangle'],
    };
    const icon = icons[status];

    return !icon ? null : (
      <>
        <Icon alt={status.toLowerCase() + 'Icon'} icon={icon} /> {status}
      </>
    );
  }

  const handleSubmit = () => {
    props.mutateProperty(!isCompleted, 'isCompleted');
    props.onSave();
  };

  function renderActionButtons(): React.ReactElement {
    return isCompleted ? (
      <>
        {generateStatusIcon()}
        <Button.Primary label="Reopen Task" onClick={handleSubmit} />
      </>
    ) : (
      <>
        {generateStatusIcon()}
        <Button.Secondary label="Edit Task" onClick={noop} />
        <Button.Primary label="Complete" onClick={handleSubmit} />
      </>
    );
  }

  function renderDueTime(): string {
    const timeStr = moment(initialDueDatetime, datetimeFormat).format('h:mm a');
    const timezoneStr = initialDueTimezone.replace('_', ' ');

    return `${timeStr} (${timezoneStr})`;
  }

  function renderDayCount(dayCount: string, icon: string): string {
    return dayCount ? `T ${icon} ${dayCount} Days` : emptyField;
  }

  return (
    <div className="CalendarTaskView">
      <Tiler>
        <Tiler.Tile
          secondarySection={renderActionButtons}
          title={name}
          width="Half"
        >
          <Summary layout="rows">
            <Summary.Row withDivider={false}>
              <Summary.TextField label="Template" value={emptyField} />
              <Summary.TextField label="Category" value={category?.name} />
            </Summary.Row>
            <Summary.Row withDivider={false}>
              <Summary.TextField label="Tranche" value={loanTrancheName} />
              <Summary.DateField
                label="Due Date"
                value={moment(dueDatetime, datetimeFormat).format('MM/DD/YYYY')}
              />
              <Summary.TextField label="Time" value={renderDueTime()} />
            </Summary.Row>
            <Summary.Row withDivider={false}>
              <Summary.TextField
                label="Repeats"
                value={`${eventFrequency.charAt(0)}${eventFrequency
                  .substr(1)
                  .toLowerCase()}`}
              />
            </Summary.Row>
            <Summary.Row withDivider={false}>
              <Summary.TextField
                label="Reminder"
                value={renderDayCount(reminderOffset, '-')}
              />
              <Summary.TextField
                label="Overdue Alert"
                value={renderDayCount(overdueAlertOffset, '+')}
              />
            </Summary.Row>
            <Summary.Row withDivider={false}>
              <Summary.TextField label="Description" value={description} />
            </Summary.Row>
            <Summary.Row withDivider={false}>
              <Summary.ArrayField
                label="Assignees"
                value={assignedPeople.map(person => person.fullName)}
              />
            </Summary.Row>
            {/* <Summary.Row withDivider={false}>
              <Summary.TextField label="Files" value={files} />
            </Summary.Row> */}
          </Summary>
        </Tiler.Tile>
      </Tiler>
    </div>
  );
}

export default CalendarTaskView;
