import * as React from 'react';
import { Form } from 'components';
import { FormFieldsType } from 'types';

const fields: FormFieldsType = {
  ownerName: {
    id: 'ownerName',
    propertyName: 'ownerName',
    fieldName: 'Owner Name',
    width: '332px',
    required: true,
    placeholder: 'If nonprofit enter authorized signatory',
  },
  title: {
    id: 'title',
    propertyName: 'title',
    fieldName: 'Title',
    width: '256px',
    required: true,
  },
  ownershipPercent: {
    id: 'ownershipPercent',
    propertyName: 'ownershipPercent',
    fieldName: 'Ownership %',
    width: '60px',
    required: true,
  },
  businessTinType: {
    id: 'businessTinType',
    propertyName: 'businessTinType',
    fieldName: 'Taxpayer Identity Number (TIN)',
    required: true,
    options: [
      { label: 'EIN', id: 'EIN' },
      { label: 'SSN', id: 'SSN' },
    ],
    width: '332px',
  },
  tinEinSsn: {
    id: 'tinEinSsn',
    propertyName: 'tinEinSsn',
    fieldName: '',
    width: '332px',
    required: true,
  },
  addressOne: {
    id: 'addressOne',
    propertyName: 'addressOne',
    fieldName: 'Address',
    placeholder: 'Street, City, St, Zip',
    width: '332px',
  },
};

const SbaPppApplicantOwnership = 'SbaPppApplicantOwnership';

function OwnershipForm(props: any) {
  const { data } = props;
  const placeholderTinEinSsn = data.tinEinSsn
    ? '*********'
    : 'Enter EIN or SSN';

  return (
    <div className="Ownership">
      {Form.FieldRenderer(
        Form.Input,
        { ...fields.ownerName, onChange: props.mutateProperty },
        props,
        SbaPppApplicantOwnership,
        data.id,
      )}
      <Form.Group>
        {Form.FieldRenderer(
          Form.Input,
          { ...fields.title, onChange: props.mutateProperty },
          props,
          SbaPppApplicantOwnership,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Percentage,
          { ...fields.ownershipPercent, onChange: props.mutateProperty },
          props,
          SbaPppApplicantOwnership,
          data.id,
        )}
      </Form.Group>
      {Form.FieldRenderer(
        Form.Radio,
        {
          ...fields.businessTinType,
          onChange: props.mutateProperty,
        },
        props,
        SbaPppApplicantOwnership,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Numeric,
        {
          ...fields.tinEinSsn,
          onChange: props.mutateProperty,
          maxLength: 9,
          placeholder: placeholderTinEinSsn,
        },
        props,
        SbaPppApplicantOwnership,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Input,
        { ...fields.addressOne, onChange: props.mutateProperty },
        props,
        SbaPppApplicantOwnership,
        data.id,
      )}
    </div>
  );
}

export default OwnershipForm;
