import * as React from 'react';
import { compose } from 'recompose';
import { MutationFunction } from '../types';
import { mutationBuilderHoC } from './builders';
import {
  CreateDealFavoriteMutationDefinition,
  DeleteDealFavoriteMutationDefinition,
} from './definitions';

type M = MutationFunction;
const CreateDealFavorite = mutationBuilderHoC(
  CreateDealFavoriteMutationDefinition,
  {
    type: 'CreateDealFavoriteMutation',
    cr: (mutate: M) => ({
      createDealFavorite: (dealId: string) => {
        return mutate({
          variables: {
            dealId,
          },
          refetchQueries: ['DealsQuery'],
        });
      },
    }),
  },
);

const DeleteDealFavorite = mutationBuilderHoC(
  DeleteDealFavoriteMutationDefinition,
  {
    type: 'DeleteDealFavoriteMutation',
    cr: (mutate: M) => ({
      deleteDealFavorite: (dealId: string) => {
        return mutate({
          variables: {
            dealId,
          },
          refetchQueries: ['DealsQuery'],
        });
      },
    }),
  },
);
const Composed: (arg0: any) => React.ComponentType<{}> = compose(
  DeleteDealFavorite,
  CreateDealFavorite,
);

export default Composed;
