import * as React from 'react';

import { getPortionFromAmount, getAmountFromPortion } from '../../utils';
import { hoverMethods } from 'utils';
import { Form, IconButton } from 'components';
import {
  LoanTrancheOwnershipPortionInputWithDisplayInfo,
  LoanTrancheOwnershipPortionInputWithInstitutionPortionId,
  ValidationMap,
  ID,
} from 'types';
import { KeyPath } from 'lsredux';

type Props = {
  disabled: boolean;

  /* eslint-disable react/no-unused-prop-types */
  errors: ValidationMap | null | undefined;

  /* eslint-enable react/no-unused-prop-types */
  institutionPortionId: ID;
  lendingEntityKeyPath: KeyPath;
  onOpenDeleteModal: (id: string) => void;
  ownershipPortion: LoanTrancheOwnershipPortionInputWithDisplayInfo;
  replaceEntity: (
    keyPath: KeyPath,
    entity: LoanTrancheOwnershipPortionInputWithInstitutionPortionId,
  ) => void;
  trancheTotalAmount: string;
};

type State = {
  isHovered: boolean;
};

type HandlerType = 'COMMITMENT' | 'OWNERSHIP';

class LendingEntityRow extends React.Component<Props, State> {
  fields: {
    [key: string]: any;
  };

  state = {
    isHovered: false,
  };

  handleHoverLeave: () => void;

  handleMouseEnter: () => void;

  handleMouseLeave: () => void;

  handleMouseMove: (e: React.MouseEvent<any>) => void;

  handleCommitmentChange: (value: string | null | undefined) => void;

  handlePortionChange: (value: string | null | undefined) => void;

  constructor(props: Props) {
    super(props);

    this.handleHoverLeave = hoverMethods.handleHoverLeave.bind(this);
    this.handleMouseEnter = hoverMethods.handleMouseEnter.bind(this);
    this.handleMouseLeave = hoverMethods.handleMouseLeave.bind(this);
    this.handleMouseMove = hoverMethods.handleMouseMove.bind(this);

    this.handleCommitmentChange = this.getInputChangeHandler('COMMITMENT').bind(
      this,
    );
    this.handlePortionChange = this.getInputChangeHandler('OWNERSHIP').bind(
      this,
    );

    this.fields = {
      portion: {
        id: 'portion',
        propertyName: 'portion',
        allowNegative: false,
        className: 'lenderRegistryInstitutionRow__InputColumn',
        onChange: this.handlePortionChange,
      },
      amount: {
        id: 'amount',
        propertyName: 'amount',
        allowNegative: false,
        className: 'lenderRegistryInstitutionRow__InputColumn',
        onChange: this.handleCommitmentChange,
      },
    };
  }

  getInputChangeHandler = (type: HandlerType) => (
    value: string | null | undefined,
  ) => {
    if (value) {
      const {
        ownershipPortion,
        institutionPortionId,
        replaceEntity,
        lendingEntityKeyPath,
        trancheTotalAmount,
      } = this.props;

      let amount;
      let portion;

      if (type === 'COMMITMENT') {
        amount = Number(value).toFixed(2);
        portion = getPortionFromAmount(trancheTotalAmount, amount);
      } else {
        portion = value;
        amount = getAmountFromPortion(trancheTotalAmount, portion);
      }

      replaceEntity(lendingEntityKeyPath, {
        ...ownershipPortion,
        loanTrancheInstitutionOwnershipPortionId: institutionPortionId,
        amount,
        portion,
      });
    }
  };

  handleOpenDeleteModal = () => {
    const { onOpenDeleteModal, ownershipPortion } = this.props;
    onOpenDeleteModal(ownershipPortion.id);
  };

  renderIconColumn = () => {
    const { isHovered } = this.state;
    const { disabled } = this.props;
    return (
      <div className="lenderRegistryInstitutionRow__IconColumn">
        {isHovered && (
          <IconButton
            alt="Delete Icon"
            disabled={disabled}
            icon="trash"
            onClick={this.handleOpenDeleteModal}
          />
        )}
      </div>
    );
  };

  render = () => {
    const { ownershipPortion } = this.props;

    return (
      <div
        className="lendingEntityRow"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className="lendingEntityRow__NameColumn">
          {ownershipPortion.institutionName}
        </div>
        {Form.FieldRenderer(
          Form.Percentage,
          this.fields.portion,
          {
            ...this.props,
            data: ownershipPortion,
          },
          'LoanTrancheOwnershipPortion',
          ownershipPortion.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          this.fields.amount,
          {
            ...this.props,
            data: ownershipPortion,
          },
          'LoanTrancheOwnershipPortion',
          ownershipPortion.id,
        )}
        <div className="lenderRegistryTranche__MoneyDisplay">-</div>
        <div className="lenderRegistryTranche__MoneyDisplay">-</div>
        {this.renderIconColumn()}
      </div>
    );
  };
}

export default LendingEntityRow;
