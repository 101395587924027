import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { DealInvitationsQueryDefinition } from './definitions';
import { InvitationType } from 'types';

const DealInvitationsQuery = standardQueryBuilder(
  DealInvitationsQueryDefinition,
  {
    typename: 'Invitations',
    addFetchMethod: true,
    disableCache: true,
    skip: ({ dealId }: { dealId: string | null | undefined }) => !dealId,
    variables: ({ dealId }: { dealId: string | null | undefined }) => ({
      dealId,
    }),
    results: (data: {
      dealInvitations: Array<InvitationType> | null | undefined;
    }) => {
      const { dealInvitations } = data;
      return { dealInvitations };
    },
  },
);

export default DealInvitationsQuery;
