import { mutationBuilder } from './builders';
import {
  CreateInstitutionMutationDefinition,
  SetInstitutionMutationDefinition,
  DeleteInstitutionMutationDefinition,
} from './definitions';
import { ID, InstitutionInput, InstitutionType } from 'types';

type InstitutionWithEntitiesInput = InstitutionInput & {
  institutionId: ID;
};

const InstitutionMutationMethods = {
  createInstitution: mutationBuilder<
    InstitutionWithEntitiesInput,
    InstitutionType
  >(CreateInstitutionMutationDefinition, {
    build: mutate => ({ institutionId, ...institutionData }) =>
      mutate({
        variables: {
          institutionData: {
            ...institutionData,
            parentId: institutionId,
          },
        },
      }),
  }),
  setInstitution: mutationBuilder<
    InstitutionWithEntitiesInput,
    InstitutionType
  >(SetInstitutionMutationDefinition, {
    build: mutate => ({ institutionId, ...institutionData }) =>
      mutate({
        variables: {
          institutionData: {
            ...institutionData,
            parentId: institutionId,
          },
        },
      }),
  }),
  deleteInstitution: mutationBuilder<ID, void>(
    DeleteInstitutionMutationDefinition,
    {
      build: mutate => (id: string) =>
        mutate({ variables: { id }, refetchQueries: ['InstitutionQuery'] }),
    },
  ),
};

export default InstitutionMutationMethods;
