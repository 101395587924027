import React from 'react';
import moment from 'moment';
import { parseEnum } from 'utils';
import { Tiler, Summary } from 'components';
import { LoanSummaryType, DealType } from 'types';

import './LoanTranche.scss';

type Props = {
  summary: LoanSummaryType;
  deal: DealType;
};

function LoanTrancheSummary(props: Props) {
  const { summary, deal } = props;

  const loanPurpose = summary.loanPurpose
    ?.map(purpose => parseEnum(purpose))
    ?.join(', ');

  return (
    <Tiler.Tile title={`${summary.originalTerm} Year Fixed Rate`}>
      <Summary layout="sections">
        <Summary.Section title="Loan Information">
          <Summary.TextField
            label="Borrower"
            value={deal.borrowerName || deal.borrower?.name}
          />
          <Summary.TextField label="Loan ID" value={deal.identity} />
        </Summary.Section>
        <Summary.Section title="Loan Type">
          <Summary.TextField
            label="Interest Type"
            value={parseEnum(summary.interestType)}
          />
          <Summary.TextField
            label="Draw Type"
            value={parseEnum(summary.commitmentDrawType)}
          />
        </Summary.Section>

        <Summary.Section title="Loan Amount">
          <Summary.MoneyField
            label="Original Principal"
            value={summary.totalCommitmentAmount}
          />
        </Summary.Section>

        <Summary.Section title="Dates &amp; Payments">
          <Summary.DateField
            label="Origination"
            value={summary.originationDate}
            withPlaceholder
          />
          <FirstPaymentDate {...props} />
          <Summary.TextField
            label="Payment Frequency"
            value={parseEnum(summary.paymentFrequency)}
          />
          <Summary.DateField
            label="Final Maturity"
            value={summary.finalMaturityDate}
            withPlaceholder
          />
        </Summary.Section>

        <Summary.Section title="Interest">
          <Summary.PercentageField
            label="Fixed Rate"
            value={summary.fixedInterestRate || '0.01'}
          />
        </Summary.Section>

        <Summary.Section title="SBA Information">
          <Summary.TextField
            label="SBA Loan #"
            value={summary.sbaLoanNumber?.toString()}
            withPlaceholder
          />
          <Summary.DateField
            label="SBA Approval"
            value={summary.sbaApprovalDate}
            withPlaceholder
          />
          <Summary.TextField
            label="Program"
            value="Paycheck Protection Program"
          />
          <Summary.TextField label="Purpose of Loan" value={loanPurpose} />
          <Summary.TextField
            label="Number of Employees"
            value={summary.numberOfEmployees?.toString()}
          />
          <Summary.PercentageField label="Guarantee" value="1" />
        </Summary.Section>
      </Summary>
    </Tiler.Tile>
  );
}

const FirstPaymentDate = (props: Props) => {
  const {
    summary: { firstPaymentDate },
    deal,
  } = props;

  let firstPayment: string;

  if (!deal.fundingDate) {
    firstPayment = undefined;
  } else if (firstPaymentDate) {
    firstPayment = moment(firstPaymentDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
  } else {
    // Use Funding Date to provide the potential dates for the first payment with this equation:
    // ("Disbursement/Funding Date” + 10 months) + ”Covered Period”; “Covered Period” is either 8 weeks or 24 weeks
    const getPaymentDate = (weeks: number): string => {
      // NOTE: The use of 'day' vs 'week' and order of addition appears to matter...
      const paymentDate = moment(deal.fundingDate, 'YYYY-MM-DD')
        .add(7 * weeks, 'day')
        .add(10, 'month');

      let adjustedPaymentDate = paymentDate;

      // if falls on Sunday, move to this Monday
      if (paymentDate.day() == 0) {
        adjustedPaymentDate = paymentDate.day(1);
      }
      // if falls on Saturday, move to next Monday
      else if (paymentDate.day() == 6) {
        adjustedPaymentDate = paymentDate.day(8);
      }

      return adjustedPaymentDate.format('MM/DD/YYYY');
    };

    firstPayment =
      `${getPaymentDate(8)} (if 8 wk. period) ` +
      `${getPaymentDate(24)} (if 24 wk. period)`;
  }

  return (
    <Summary.TextField
      label="First Payment"
      value={firstPayment}
      withPlaceholder
    />
  );
};

export default LoanTrancheSummary;
