import * as React from 'react';

import { OrderBookContainerProps } from './types';

interface Props extends OrderBookContainerProps {
  children: (props: OrderBookContainerProps) => React.ReactNode;
}

class ContainerAggregator extends React.Component<Props> {
  render() {
    const { children, ...rest } = this.props;
    return children(rest);
  }
}

export default ContainerAggregator;
