import { gql } from '@apollo/client';

import { invariant } from 'utils';
import getPublicClient from 'lsgql/publicClient';
import { ID } from 'types';

/* eslint-disable */
const OnboardSbaPppApplicationMutationDefinition = gql`
  mutation OnboardSbaApplicationMutation(
    $applicationId: ID!
    $ticker: String!
    $job: String!
  ) {
    onboardSbaApplication(
      applicationId: $applicationId
      ticker: $ticker
      job: $job
    ) {
      ok
    }
  }
`;
/* eslint-enable */

export function TriggerSbaOnboardingMutation(
  applicationId: ID,
  ticker: string,
  job: string,
) {
  invariant(applicationId, 'applicationId is required');
  invariant(ticker, 'ticker is required');
  invariant(job, 'job is required');

  const client = getPublicClient();
  invariant(client, 'Failed to create Public Client');

  return client.mutate<any>({
    mutation: OnboardSbaPppApplicationMutationDefinition,
    variables: {
      applicationId,
      ticker,
      job,
    },
  });
}

export default TriggerSbaOnboardingMutation;
