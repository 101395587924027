import * as React from 'react';
import { LoanTrancheFormProps } from '../../types';
import { Form, AddressFields } from 'components';

import { ID, LoanTrancheGuarantorType, FormFieldsType } from 'types';
import { KeyPath } from 'lsredux';

type GuarantorFieldsProps = LoanTrancheFormProps & {
  addEntity: (keyPath: KeyPath, entity: any) => void;
  data: LoanTrancheGuarantorType | null | undefined;
  isSaving: boolean;
  newGuarantorId: ID;
  removeEntity: (keyPath: KeyPath, entity: any) => void;
  replaceEntity: (keyPath: KeyPath, entity: any) => void;
  tabIndex: number;
};

const width = '158px';
const loanTrancheGuarantor = 'LoanTrancheGuarantor';

function BusinessGuarantorFields(
  props: GuarantorFieldsProps & {
    onChange: (
      value: (string | boolean) | null | undefined,
      fieldId: ID,
    ) => void;
  },
) {
  const { data, onChange, tabIndex } = props;
  const id = data ? data.id : null;

  const businessEntityFields: FormFieldsType = {
    association: {
      fieldName: 'Association',
      onChange,
      propertyName: 'association',
      typeName: 'LoanTrancheGuarantorAssociation',
      width,
      tabIndex,
    },
    entityType: {
      fieldName: 'Entity Type',
      onChange,
      propertyName: 'entityType',
      typeName: 'LoanTrancheGuarantorEntityType',
      width,
      tabIndex,
    },
    guarantorName: {
      fieldName: 'Guarantor Name',
      onChange,
      propertyName: 'guarantorName',
      width,
      tabIndex,
    },
    includeAddress: {
      propertyName: 'includeAddress',
      label: 'Include Address',
      onChange,
      width,
      tabIndex,
    },
  };

  const sharedFields = {
    guarantorId: {
      fieldName: 'Guarantor ID',
      onChange,
      propertyName: 'guarantorId',
      width,
      tabIndex,
    },
  };

  return id ? (
    <>
      <Form.Group>
        {Form.FieldRenderer(
          Form.Input,
          {
            ...businessEntityFields.guarantorName,
            id: 'guarantorName',
          },
          props,
          loanTrancheGuarantor,
          id,
        )}
        {Form.FieldRenderer(
          Form.Input,
          {
            ...sharedFields.guarantorId,
            id: 'guarantorId',
          },
          props,
          loanTrancheGuarantor,
          id,
        )}
      </Form.Group>
      <Form.Group>
        {Form.FieldRenderer(
          Form.ReferenceSelect,
          {
            id: 'association',
            ...businessEntityFields.association,
          },
          props,
          loanTrancheGuarantor,
          id,
        )}
        {Form.FieldRenderer(
          Form.ReferenceSelect,
          {
            id: 'entityType',
            ...businessEntityFields.entityType,
          },
          props,
          loanTrancheGuarantor,
          id,
        )}
      </Form.Group>
      <Form.Group>
        {data
          ? Form.FieldRenderer(
              Form.Checkbox,
              {
                id: 'includeAddress',
                value: data.includeAddress,
                ...businessEntityFields.includeAddress,
              },
              props,
              loanTrancheGuarantor,
              id,
            )
          : null}
      </Form.Group>
      {data && data.includeAddress ? (
        <AddressFields
          disabled={props.disabled}
          handleChange={onChange}
          isSaving={props.isSaving}
          parentType={loanTrancheGuarantor}
          typeName="LoanTrancheGuarantorState"
          {...props}
        />
      ) : null}
    </>
  ) : null;
}

export default BusinessGuarantorFields;
