import { mutationBuilder } from './builders';
import {
  CreateSbaPppApplicantOwnershipMutationDefinition,
  SetSbaPppApplicantOwnershipMutationDefinition,
  DeleteSbaPppApplicantOwnershipMutationDefinition,
} from './definitions';
import {
  SbaPppApplicantOwnershipInput,
  SbaPppApplicantOwnershipType,
  ID,
} from 'types';

const PrivateSbaPppApplicantOwnershipMutationMethods = {
  createSbaPppApplicantOwnership: mutationBuilder<
    SbaPppApplicantOwnershipInput,
    SbaPppApplicantOwnershipType
  >(CreateSbaPppApplicantOwnershipMutationDefinition, {
    build: mutate => sbaPppApplicantOwnershipData =>
      mutate({ variables: { sbaPppApplicantOwnershipData } }),
  }),
  setSbaPppApplicantOwnership: mutationBuilder<
    SbaPppApplicantOwnershipInput,
    SbaPppApplicantOwnershipType
  >(SetSbaPppApplicantOwnershipMutationDefinition, {
    build: mutate => sbaPppApplicantOwnershipData =>
      mutate({ variables: { sbaPppApplicantOwnershipData } }),
  }),
  deleteSbaPppApplicantOwnership: mutationBuilder<ID, void>(
    DeleteSbaPppApplicantOwnershipMutationDefinition,
    {
      build: mutate => (id: ID) => mutate({ variables: { id } }),
    },
  ),
};

export default PrivateSbaPppApplicantOwnershipMutationMethods;
