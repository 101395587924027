/* eslint-disable react/jsx-handler-names */

import * as React from 'react';
import uuid from 'uuid/v4';
import {
  Form,
  IconButton,
  ConfirmModal,
  AddressFields,
  WrappedMediatorFormProps,
} from 'components';

import { ID, InstitutionType, InstitutionBankAccountType } from 'types';
import { MountGuard, InstitutionPerspectivePermissions } from 'security';
import { getEncryptedDisplayValue, getEncryptedPlaceholder } from 'utils';

const bankAccount = 'InstitutionBankAccount';

type Props = WrappedMediatorFormProps<InstitutionType> & {
  data: InstitutionBankAccountType | null | undefined;
  disabled?: boolean;
  emptyForm?: boolean;
  newBankAccountId?: string;
  onChangeIsPrimary?: (arg0: string) => void;
};

type State = {
  isModalOpen: boolean;
  fields: any;
};

const initialState: State = {
  isModalOpen: false,
  fields: {},
};

class InstitutionBankAccountForm extends React.Component<Props, State> {
  fields: any;
  state = initialState;

  constructor(props: Props) {
    super(props);
    const { data } = props;
    this.fields = {
      nickName: {
        id: 'accountNickname',
        propertyName: 'accountNickname',
        fieldName: 'Nickname',
        placeholder: getEncryptedPlaceholder(
          data,
          'accountNickname',
          'Nickname',
        ),
        onChange: this.handleMutateBankAccount,
        width: 'four',
      },
      invoicePartyName: {
        id: 'invoicePartyName',
        propertyName: 'invoicePartyName',
        fieldName: 'Account Holder',
        required: true,
        placeholder: getEncryptedPlaceholder(
          data,
          'invoicePartyName',
          'Invoice Party Name',
        ),
        onChange: this.handleMutateBankAccount,
        width: 'five',
      },
      accountNumber: {
        id: 'hiddenAccountNumber',
        propertyName: 'hiddenAccountNumber',
        fieldName: 'Account Number',
        required: true,
        onChange: this.handleMutateBankAccount,
        width: '188px',
        placeholder: getEncryptedPlaceholder(
          data,
          'hiddenAccountNumber',
          'Account Number',
        ),
        maxLength: 12,
      },
      routingNumber: {
        id: 'abaNumber',
        propertyName: 'abaNumber',
        fieldName: 'ABA Number',
        required: true,
        placeholder: getEncryptedPlaceholder(data, 'abaNumber', 'ABA Number'),
        onChange: this.handleMutateBankAccount,
        width: '158px',
        maxLength: 9,
      },
      isPrimary: {
        id: 'isPrimary',
        propertyName: 'isPrimary',
        label: 'Primary',
      },
    };
  }
  handleCancelDelete = () => this.setState({ isModalOpen: false });

  handleConfirmDelete = () => this.setState({ isModalOpen: true });

  handleDelete = () => {
    this.props.removeEntity('institutionbankaccountSet', this.props.data);
  };

  handleMutateBankAccount = (
    value: (string | null | undefined) | (boolean | null | undefined),
    fieldId: string,
  ) => {
    const { data, emptyForm = false } = this.props;
    const field = fieldId.split('_')[0];

    if (data) {
      // edit
      this.props.replaceEntity('institutionbankaccountSet', {
        ...data,
        [field]: value,
      });
    } else {
      // add
      this.props.addEntity('institutionbankaccountSet', {
        ...{
          id: this.props.newBankAccountId,
          isPrimary: emptyForm,
          __typename: 'InstitutionBankAccountType',
        },
        [field]: value,
      });
    }
  };

  handleChangePrimaryAccount = (id: ID) => {
    const { onChangeIsPrimary } = this.props;
    if (onChangeIsPrimary) onChangeIsPrimary(id);
  };

  canChangePrimaryAccount = () => {
    const { data } = this.props;
    return (
      data &&
      data.invoicePartyName &&
      data.hiddenAccountNumber &&
      data.abaNumber &&
      !data.isPrimary
    );
  };

  render() {
    const { data, emptyForm = false } = this.props;

    const account = {
      ...data,
      accountNickname: getEncryptedDisplayValue(data, 'accountNickname'),
      invoicePartyName: getEncryptedDisplayValue(data, 'invoicePartyName'),
      hiddenAccountNumber: getEncryptedDisplayValue(
        data,
        'hiddenAccountNumber',
      ),
    } || {
      // Default bank account entity
      id: uuid(),
      isPrimary: emptyForm,
      __typename: 'InstitutionBankAccountType',
      accountNickname: '',
    };

    const accountProps = { ...this.props, data: account };
    const id = account && account.id ? account.id : 'temp';

    return (
      <div className="MyInstitution__BankAccountRow">
        <Form.Group className="MyInstitution__BankAccountFieldsDeleteButtonRow lsFormGroup--Lowered">
          {Form.FieldRenderer(
            Form.Input,
            this.fields.nickName,
            accountProps,
            bankAccount,
            id,
          )}
          {Form.FieldRenderer(
            Form.Input,
            this.fields.invoicePartyName,
            accountProps,
            bankAccount,
            id,
          )}
          {Form.FieldRenderer(
            Form.Checkbox,
            {
              ...this.fields.isPrimary,
              disabled: !this.canChangePrimaryAccount(),
              onChange: () => this.handleChangePrimaryAccount(id),
            },
            accountProps,
            bankAccount,
            id,
          )}
          {Object.values(account).length > 3 ? (
            <MountGuard
              permission={
                InstitutionPerspectivePermissions.administer_institution
              }
            >
              <IconButton.Delete
                alt="Remove Bank Account"
                className="MyInstitution__BankAccountFieldsDeleteButton"
                onClick={this.handleConfirmDelete}
              />
            </MountGuard>
          ) : null}
          <ConfirmModal
            header="Warning"
            isOpen={this.state.isModalOpen}
            message="Are you sure you want to permanently remove this bank account?"
            onConfirm={this.handleDelete}
            onReject={this.handleCancelDelete}
          />
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Numeric,
            this.fields.routingNumber,
            accountProps,
            bankAccount,
            id,
          )}
          {Form.FieldRenderer(
            Form.Numeric,
            this.fields.accountNumber,
            accountProps,
            bankAccount,
            id,
          )}
        </Form.Group>
        <AddressFields
          data={account}
          disabled={accountProps.disabled}
          handleChange={this.handleMutateBankAccount}
          isSaving={accountProps.isSaving}
          parentType={bankAccount}
          small
          typeName="InstitutionState"
        />
      </div>
    );
  }
}

export default InstitutionBankAccountForm;
