import { DropdownProps } from 'semantic-ui-react';
import React from 'react';
import { AssignableUserType, DealType } from 'types';
import { haltBubble } from 'utils';
// eslint-disable no-restricted-imports
import { assignDeal, unassignDeal, AssignableUsersQuery } from 'lsgql';
import { Dropdown } from 'components';

interface AssignToDropdownProps {
  assignableUsers: AssignableUserType[];
  deal: DealType;
  dealId: string;
  shouldFetch: boolean;
  parentClassName?: string;
  isOpen: boolean;
  onToggle: () => void;
  onEditChange: (editing: boolean) => void;
}
const AssignToDropdown = (props: AssignToDropdownProps) => {
  const {
    deal,
    assignableUsers,
    shouldFetch,
    dealId,
    onEditChange,
    ...rest
  } = props;
  const placeholderText = '— no selection —';
  const handleChangeAssignee = (e: React.ChangeEvent, data: DropdownProps) => {
    const newValue = data.value as string;
    haltBubble(e);
    if (newValue) {
      assignDeal({
        dealId: deal.id,
        userId: newValue,
        id: deal.dealassignee?.id,
        __typename: 'DealAssigneeInput',
      });
    } else if (deal.dealassignee) {
      unassignDeal(deal.dealassignee.id);
    }
    onEditChange(false);
  };
  const options = [
    { text: placeholderText, value: null },
    ...(assignableUsers?.map(user => {
      return { text: `${user.firstName} ${user.lastName}`, value: user.id };
    }) || []),
  ];
  const currentValue = options.find(
    user => user.text === deal?.dealassignee?.name,
  )?.value;
  return (
    <Dropdown
      {...rest}
      id="dropdown"
      onChange={handleChangeAssignee}
      options={options}
      placeholder={deal?.dealassignee?.name || placeholderText}
      value={currentValue}
    />
  );
};
export default AssignableUsersQuery(AssignToDropdown);
