import * as React from 'react';
import uuid from 'uuid/v4';
import difference from 'lodash/difference';
import {
  BenchmarkTextValueSortOrderType,
  BenchmarkOptionsFieldsProps,
  SelectedBenchmarkOptions,
} from '../../types';
import { parseDayCountText } from '../utils';

import ApplicableMargins from './ApplicableMargins';
import { BenchmarkOptionType, ID, FieldRendererGeneralPropsType } from 'types';
import {
  Form,
  FormSelectProps,
  TypeQueryResult,
  FormReferenceSelectProps,
} from 'components';

type Props = BenchmarkOptionsFieldsProps &
  FieldRendererGeneralPropsType & {
    benchmarkOptionsBenchmarkIds: ReadonlyArray<ID>;
    benchmarks: ReadonlyArray<BenchmarkTextValueSortOrderType>;
    data: ReadonlyArray<BenchmarkOptionType>;
    isSelected: boolean;
    onChange: (option: BenchmarkOptionType) => void;
    onDelete: (option: BenchmarkOptionType) => void;
    onToggleSelected: (option: SelectedBenchmarkOptions) => void;
  };
const largeWidth = '256px';
const benchmarkOptionName = 'BenchmarkOption';
const groupClassName = 'OtherBenchmarkOptionsFields__FormGroup';

class LiborBenchmarkOptionFields extends React.Component<Props> {
  fields: {
    benchmark: FormSelectProps;
    dayCountConvention: FormReferenceSelectProps;
    libor: any;
  };

  constructor(props: Props) {
    super(props);
    const { benchmarks } = this.props;

    this.fields = {
      libor: {
        id: 'libor',
        propertyName: 'libor',
        label: 'LIBOR',
        onChange: this.handleToggleLibor,
        disabled: Boolean(this.props.disabled),
      },
      benchmark: {
        id: 'benchmarkId',
        propertyName: 'benchmarkId',
        fieldName: 'Benchmark',
        onChange: this.handleChangeBenchmarkId,
        options: benchmarks.map(benchmark => ({
          text: benchmark.text || '',
          value: benchmark.value,
        })),
        multiple: true,
        width: largeWidth,
      },
      dayCountConvention: {
        id: 'dayCountConvention',
        propertyName: 'dayCountConvention',
        fieldName: 'Interest Day Count Rule',

        onChange: this.handleChangeDayCountConvention,
        typeName: 'BenchmarkOptionDayCountConvention',
        width: largeWidth,
      },
    };
  }

  handleChangeBenchmarkId = (value: any) => {
    const { benchmarkOptionsBenchmarkIds, data } = this.props;
    if (benchmarkOptionsBenchmarkIds.length > value.length) {
      const deletedIds = difference(benchmarkOptionsBenchmarkIds, value);
      const deletedBenchmarkOption = data.find(
        option => option.benchmarkId === deletedIds[0],
      );
      if (deletedBenchmarkOption) {
        this.props.onDelete(deletedBenchmarkOption);
      }
    } else {
      const addedIds = difference(value, benchmarkOptionsBenchmarkIds);
      const firstBenchmarkOption = data[0];
      this.props.onChange({
        id: uuid(),
        benchmarkId: addedIds[0],
        dayCountConvention:
          firstBenchmarkOption &&
          (firstBenchmarkOption.dayCountConvention as any),
        rateMinimum: firstBenchmarkOption && firstBenchmarkOption.rateMinimum,
        rateMaximum: firstBenchmarkOption && firstBenchmarkOption.rateMaximum,
        __typename: 'BenchmarkOptionType',
      });
    }
  };

  handleToggleLibor = (value: boolean | null | undefined) => {
    const { onToggleSelected } = this.props;
    onToggleSelected('libor');
    if (!value) {
      this.props.data.forEach(option => {
        this.props.onDelete(option);
      });
    }
  };

  handleChangeDayCountConvention = (value: string) => {
    this.props.data.forEach(benchmarkOption => {
      this.props.onChange({
        ...benchmarkOption,
        dayCountConvention: value as any,
      });
    });
  };

  render() {
    const {
      applicablemarginSet,
      blurEventForm,
      data,
      benchmarkOptionsBenchmarkIds,
      disabled,
      onUpdateApplicableMargin,
      isFirstSelected,
      isSelected,
      handleRemoveApplicableMargin,
      trancheId,
    } = this.props;
    const firstBenchmarkOption = data[0];
    return (
      <>
        <Form.Group className={groupClassName}>
          {Form.FieldRenderer(
            Form.Checkbox,
            { ...this.fields.libor, value: isSelected },
            this.props,
            benchmarkOptionName,
            trancheId,
          )}
        </Form.Group>
        {isSelected && (
          <>
            <Form.Group className={groupClassName}>
              {Form.FieldRenderer(
                Form.Select,
                {
                  ...this.fields.benchmark,
                  value: benchmarkOptionsBenchmarkIds,
                },
                this.props,
                benchmarkOptionName,
                firstBenchmarkOption && firstBenchmarkOption.id,
              )}
            </Form.Group>
            {!!data.length && (
              <>
                {Form.FieldRenderer(
                  Form.ReferenceSelect,
                  {
                    ...this.fields.dayCountConvention,
                    disabled: Boolean(disabled),
                    value:
                      firstBenchmarkOption &&
                      firstBenchmarkOption.dayCountConvention,
                    resultFilter: (queryResults: Array<TypeQueryResult>) =>
                      queryResults.map(parseDayCountText),
                  },
                  this.props,
                  benchmarkOptionName,
                  firstBenchmarkOption && firstBenchmarkOption.id,
                )}
                <ApplicableMargins
                  applicableMarginType="libor"
                  blurEventForm={blurEventForm}
                  data={applicablemarginSet}
                  disabled={Boolean(disabled)}
                  errors={this.props.errors}
                  handleRemoveApplicableMargin={handleRemoveApplicableMargin}
                  isFirstSelected={isFirstSelected}
                  isSaving={this.props.isSaving}
                  onChange={onUpdateApplicableMargin}
                />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default LiborBenchmarkOptionFields;
