import { DealType, BaseType } from 'types';

type FolderProps = {
  deal: DealType;
};

/**
 * deal.topLevelFolders is a collection, this method reliably
 * extracts the actual root folder from this collection
 */
export function getRootFolder(props: FolderProps): BaseType {
  // TODO: This could be better
  const { topLevelFolders } = props.deal;

  if (topLevelFolders && topLevelFolders.length > 0) {
    return topLevelFolders[0];
  }
  throw new Error('Unable to determine root folder for deal');
}

export function getRootFolderId(props: FolderProps) {
  const res = getRootFolder(props);
  return res.id;
}
