import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import {
  FeeTrackersShallowFields,
  LoanServicingMoneyShallowFields,
} from '../fragments';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { FeeTrackersType } from 'types';

type OwnProps = {
  dealId: string;
};

const FeeTrackersQueryDefinition: DocumentNode = gql`
  query FeeTrackersQuery($dealId: ID) {
    feeTrackers(dealId: $dealId) {
      ...FeeTrackersShallowFields
      amount {
        ...LoanServicingMoneyShallowFields
      }
      balance {
        ...LoanServicingMoneyShallowFields
      }
    }
  }
  ${FeeTrackersShallowFields}
  ${LoanServicingMoneyShallowFields}
`;

export const FeeTrackersQuery = standardQueryBuilder(
  FeeTrackersQueryDefinition,
  {
    typename: 'FeeTrackers',
    skip: (props: OwnProps) => {
      const { dealId } = props;
      return !dealId;
    },
    variables: (props: OwnProps) => {
      return props;
    },
    results: (data: { feeTrackers: FeeTrackersType | null | undefined }) => {
      const { feeTrackers } = data;
      return { feeTrackers };
    },
  },
);
