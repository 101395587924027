import * as React from 'react';
import RoleSelect from '../../../../roleSelect/RoleSelect';
import { InstitutionType, ID } from 'types';
import { IconButton } from 'components';

type Props = {
  institution: InstitutionType;
  onChange: (roleId: ID, institution: ID, type: string) => void;
  onDelete: (instId: ID, type: string) => void;
  role: string;
};

class ShareInstitutionItem extends React.PureComponent<Props> {
  handleOnChange = (roleId: ID) => {
    const { onChange, institution } = this.props;
    onChange(roleId, institution.id, institution.__typename);
  };

  handleDelete = () => {
    const { institution, onDelete } = this.props;
    onDelete(institution.id, institution.__typename);
  };

  render() {
    const { institution, role } = this.props;
    return (
      <div className="shareInstitutionItem" key={institution.id}>
        <div className="shareInstitutionItem--text">{institution.name}</div>
        <RoleSelect
          className="shareInstitutionItem--dropDown"
          onChange={this.handleOnChange}
          roleContext="folder"
          selected={role}
        />
        <IconButton.Delete
          alt="Remove Permission"
          className="folderShareModal--DeleteButton"
          onClick={this.handleDelete}
        />
      </div>
    );
  }
}

export default ShareInstitutionItem;
