import { gql } from '@apollo/client';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { PublicIsValidJobType } from 'types';

/* eslint-disable */
const PublicIsValidJobQueryDefinition = gql`
  query isValidJob($job: String) {
    isValidJob(job: $job) {
      valid
    }
  }
`;

/* eslint-enable */
type Props = { institutionJob: string | null | undefined };

const PublicIsValidJobQuery = standardQueryBuilder(
  PublicIsValidJobQueryDefinition,
  {
    typename: 'PublicIsValidJobType',
    skip: ({ institutionJob }: Props) => !institutionJob,
    variables: ({ institutionJob }: Props) => ({
      job: institutionJob,
    }),
    results: (data: {
      isValidJob: PublicIsValidJobType | null | undefined;
    }) => {
      const { isValidJob } = data;
      return { isValidJob };
    },
  },
);

export default PublicIsValidJobQuery;
