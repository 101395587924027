import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import ServicingAgentInvitationForm from './ServicingAgentInvitationForm';
import { RouteParams, withRouteParams } from 'routing';
import institutionInviteFormRedux from 'lsredux/reducer/forms/institutionInvitation';
import { FormMediator } from 'components';
import { RoleType, ID } from 'types';
import { invariant } from 'utils';
import {
  DealQuery,
  RolesQuery,
  InstitutionInviteeEntityValidator,
} from 'lsgql';
import validationMapBuilder from 'lsgql/mutationsDispatch/utils/validationMapBuilders';

function setUpGetRoleId(roles: Array<RoleType>) {
  if (!roles) {
    return null;
  }
  return function getRoleId(args: {
    context: string;
    name: string;
    postClosing: boolean | null | undefined;
  }): ID {
    const { name, context, postClosing } = args;
    const foundRole = roles.find(
      role =>
        role.name === name &&
        role.context === context &&
        role.postClosing === postClosing,
    );
    invariant(foundRole, 'Unable to find role');
    return foundRole.id;
  };
}

const successAlert = {
  message: 'Successsfully invited servicing agent to the deal',
  title: 'Invitation Sent',
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  const bound = institutionInviteFormRedux.actions.generateActions(dispatch);
  const getRoleId = setUpGetRoleId(ownProps.roles);
  const initializeState = () => {
    bound.mutateProperty(ownProps.dealId, 'dealId');
  };
  const save = async (refetch: any) => {
    const res = await bound.save(refetch);
    if (res.success) {
      bound.clearState();
      initializeState();
    }

    return res;
  };

  const onClose = async () => {
    ownProps.onClose();
    bound.clearState();
    initializeState();
  };

  const additional = {
    onClose,
    save,
    initializeState,
  };

  return {
    ...bound,
    ...additional,
    getRoleId,
    validator: InstitutionInviteeEntityValidator,
    validationMapBuilder,
  };
};

const Composed = compose(
  withRouteParams([RouteParams.dealId]),
  RolesQuery,
  DealQuery,
  withProps(() => ({
    formQueryRefetch: () => {
      const res = {
        data: {},
        ok: true,
      };

      return Promise.resolve(res);
    },
  })),
  connect(institutionInviteFormRedux.mapStateToProps, mapDispatchToProps),
  FormMediator({
    successAlert,
    formHeader: 'Send Invitation',
    disableFrame: true,
    formId: 'servicingAgentInvitation',
    // @ts-ignore
    baseObjectTypeName: 'InstitutionInvitationsInput',
  }),
)(ServicingAgentInvitationForm);

export default Composed;
