import * as React from 'react';
import { invariant } from 'utils';
import { DealType } from 'types';

interface SummaryPageProps {
  deal: DealType;
  [propName: string]: any;
}

function isSaving(props: any): boolean {
  return props.rawData.toJS().isSaving;
}

function withSummaryPageHoC(
  SummaryPageComponent: React.ComponentType<SummaryPageProps>,
  entityName: string,
) {
  invariant(
    SummaryPageComponent !== undefined,
    'A summary page component must be provided',
  );
  invariant(
    entityName !== undefined,
    'The name of the entity to populate the summary page is missing',
  );

  return function summaryPageWrapper<T extends SummaryPageProps>(
    WrappedFormComponent: React.ComponentType<T>,
  ) {
    return class SummaryPageWrapper extends React.Component<T> {
      render() {
        const entity = this.props[entityName];
        const entityObj = { [entityName]: entity };

        return entity && entity.submitted && !isSaving(this.props) ? (
          <SummaryPageComponent {...entityObj} deal={this.props.deal} />
        ) : (
          <WrappedFormComponent {...this.props} />
        );
      }
    };
  };
}

export default withSummaryPageHoC;
