/* eslint-disable react/display-name */
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import Documentation from './documentation/DocumentationContainer';
import ForgivenessCalculation from './forgivenessCalculation/ForgivenessCalculationFormContainer';
import Instructions from './instructions/InstructionsContainer';
import LoanInformation from './loanInformation/LoanInformationFormContainer';
import Debug from './Debug';
import { RoutingContext, RouteTable } from 'routing';
import { ID, DealType, SbaPppForgivenessWorkflowType } from 'types';
import { TabNavigationFrame, TabItemType } from 'components';

const routes: RoutingContext = {
  context: 'Deal',
  routes: [
    {
      render: () => <Instructions />,
      path: RouteTable.deal.toSbaPppForgivenessWorkflowInstructions(),
    },
    {
      render: () => <Documentation />,
      path: RouteTable.deal.toSbaPppForgivenessWorkflowDocumentation(),
    },
    {
      render: () => <ForgivenessCalculation />,
      path: RouteTable.deal.toSbaPppForgivenessWorkflowCalculation(),
    },
    {
      render: () => <LoanInformation />,
      path: RouteTable.deal.toSbaPppForgivenessWorkflowLoanInformation(),
    },
    {
      render: () => <Instructions />,
      path: RouteTable.deal.toSbaPppForgivenessWorkflow(),
    },
  ],
};

type Props = {
  dealId: ID;
  deal: DealType;
  sbaPppForgivenessWorkflow: SbaPppForgivenessWorkflowType;
};

function SbaPppForgivenessWorkflow(props: Props) {
  const { dealId, sbaPppForgivenessWorkflow } = props;

  const tabs: TabItemType[] = [
    {
      text: 'INSTRUCTIONS',
      to: RouteTable.deal.toSbaPppForgivenessWorkflow(dealId),
    },
    {
      text: 'LOAN INFORMATION',
      to: RouteTable.deal.toSbaPppForgivenessWorkflowLoanInformation(dealId),
    },
    {
      text: sbaPppForgivenessWorkflow?.isSFormEligible
        ? 'FORGIVENESS AMOUNT'
        : 'FORGIVENESS CALCULATION',
      to: RouteTable.deal.toSbaPppForgivenessWorkflowCalculation(dealId),
    },
    {
      text: 'DOCUMENTATION',
      to: RouteTable.deal.toSbaPppForgivenessWorkflowDocumentation(dealId),
    },
  ];

  if (!sbaPppForgivenessWorkflow?.canSeeForgiveness) {
    return <Redirect to={RouteTable.application.toHome} />;
  }

  return (
    <>
      <Debug sbaPppForgivenessWorkflow={sbaPppForgivenessWorkflow} />
      <TabNavigationFrame routeContext={routes} tabs={tabs} />
    </>
  );
}

export default SbaPppForgivenessWorkflow;
