import Mustache from 'mustache';

const props = {
  protocol: window.location.protocol.replace(/:$/, ''),
  host: window.location.hostname,
  port: window.location.port,
};

export const REACT_APP_API_URL = Mustache.render(
  process.env.REACT_APP_API_URL || 'http://localhost:8001/api/',
  props,
);

export const REACT_APP_PUBLIC_API_URL = Mustache.render(
  process.env.REACT_APP_PUBLIC_API_URL || 'http://localhost:8001/public_api/',
  props,
);

export const REACT_APP_TEST_DATA_API_URL = Mustache.render(
  process.env.REACT_APP_TEST_DATA_API_URL ||
    'http://localhost:8001/test_data_api/',
  props,
);

export const REACT_APP_CSRF_URL = Mustache.render(
  process.env.REACT_APP_CSRF_URL || 'http://localhost:8001/csrf/',
  props,
);

export const REACT_APP_DJANGO_LOGIN_URL = Mustache.render(
  process.env.REACT_APP_DJANGO_LOGIN_URL || 'http://localhost:8001/login/',
  props,
);

export const REACT_APP_DJANGO_LOGOUT_URL = Mustache.render(
  process.env.REACT_APP_DJANGO_LOGOUT_URL || 'http://localhost:8001/logout/',
  props,
);

export const REACT_APP_AUTHENTICATION_CHECK_URL = Mustache.render(
  process.env.REACT_APP_AUTHENTICATION_CHECK_URL ||
    'http://localhost:8001/authenticated/',
  props,
);

export const REACT_APP_DJANGO_INVITE_URL = Mustache.render(
  process.env.REACT_APP_DJANGO_INVITE_URL ||
    'http://localhost:8001/invitation-registration/',
  props,
);

export const REACT_APP_UPLOAD_URL = Mustache.render(
  process.env.REACT_APP_UPLOAD_URL || 'http://localhost:8001/upload/',
  props,
);

export function urlJoin(...segments: string[]): string {
  return segments.reduce((acc, segment) => {
    return [acc.replace(/\/+$/, ''), segment].join('/');
  });
}
