import * as React from 'react';
import { compose } from 'recompose';
import PaymentFilterHeader from './PaymentFilterHeader/PaymentFilterHeader';
import PaymentScheduleTable from './PaymentScheduleTable/PaymentScheduleTable';
import PaymentActivityTable from './PaymentActivityTable/PaymentActivityTable';
import { RouteParams, withRouteParams } from 'routing';
import { invariant } from 'utils';
import { DealQuery } from 'lsgql';
import { DealType, LoanTrancheType } from 'types';
import './PaymentsView.scss';

type Props = {
  deal: DealType;
  viewType: 'SCHEDULE' | 'ACTIVITY';
};

type State = {
  fromDate: string | null | undefined;
  toDate: string | null | undefined;
  trancheId: string;
};
function transformTrancheSet(
  tranches: ReadonlyArray<LoanTrancheType> | null | undefined,
) {
  if (tranches) {
    return tranches.map(tranche => ({
      text: tranche.name || '',
      value: tranche.id,
    }));
  }
  return [];
}

class PaymentsView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { viewType, deal } = props;
    invariant(viewType, 'View Type Not Found!');
    let trancheId = '';
    if (deal && deal.loantrancheSet && deal.loantrancheSet.length > 0) {
      trancheId = deal.loantrancheSet[0].id;
    }
    this.state = {
      fromDate: null,
      toDate: null,
      trancheId,
    };
  }

  handleFromDateChange = (value: string | null | undefined) => {
    this.setState({ fromDate: value });
  };

  handleToDateChange = (value: string | null | undefined) => {
    this.setState({ toDate: value });
  };

  handleTrancheChange = (value: string | null | undefined) => {
    this.setState({ trancheId: value || '' });
  };

  renderTable = () => {
    const { viewType, deal } = this.props;
    const { trancheId, fromDate, toDate } = this.state;
    if (!trancheId) {
      return null;
    }
    if (viewType === 'SCHEDULE') {
      return (
        <PaymentScheduleTable
          dealId={deal.id}
          fromDate={fromDate}
          toDate={toDate}
          trancheId={trancheId}
        />
      );
    }
    return (
      <PaymentActivityTable
        fromDate={fromDate}
        toDate={toDate}
        trancheId={trancheId}
      />
    );
  };

  render() {
    const { deal } = this.props;
    const { fromDate, toDate, trancheId } = this.state;

    invariant(deal, 'Deal was not found!');
    const trancheOptions = transformTrancheSet(deal.loantrancheSet);

    return (
      <div className="paymentsView">
        <PaymentFilterHeader
          fromDate={fromDate}
          onFromDateChange={this.handleFromDateChange}
          onToDateChange={this.handleToDateChange}
          onTrancheChange={this.handleTrancheChange}
          toDate={toDate}
          trancheId={trancheId}
          trancheOptions={trancheOptions}
        />
        {this.renderTable()}
      </div>
    );
  }
}

export default compose(
  withRouteParams([RouteParams.dealId]),
  DealQuery,
)(PaymentsView);
