import { fromJS } from 'immutable';
import { identity } from 'lodash';
import uuid from 'uuid/v4';
import { InitializeStateAction, FormReduxState, FormReduxArgs } from '../types';
import {
  entityValidator,
  flagClean,
  flagDirty,
  getFormShape,
} from '../methods';
import { invariant, isWholeNumber, mergeEntities } from 'utils';
import { BaseType } from 'types';

/* eslint-disable import/prefer-default-export */

/**
 * Initialize the state.  Use the action entity, if provided,
 * otherwise initialize with a copy of the initialState.data object
 * @param {*} action
 * @param {*} initialState
 */
export function handleInitializeState<
  TOut extends BaseType,
  TIn extends BaseType
>(
  action: InitializeStateAction<TOut>,
  initialState: any,
  args?: FormReduxArgs<TOut, TIn>,
): any {
  const {
    payload: { entity },
  } = action;
  invariant(initialState, 'initialState is required');
  const dataConverter = (args && args.dataConverter) || identity;
  const mergedEntity: any = mergeEntities(
    /* Apply initial state as base */
    initialState.toJS(),
    /* Generate an ID for potentially new object */

    /* Replaces any ID potentially provided in initialState */
    { id: uuid() },
    /* Apply entity properties if provided */

    /* Omitting entity properties will result in a new entity */
    entity || {},
  );
  const convertedEntity = dataConverter(mergedEntity);

  /* Ensure that the final entity is valid */
  entityValidator(convertedEntity);

  /* Adjust dirty flags and produce final state for form usage */
  const isPersisted = isWholeNumber(convertedEntity.id);

  const flaggedEntity = isPersisted
    ? flagClean(convertedEntity)
    : flagDirty(convertedEntity);

  const formShape = getFormShape<TOut, TIn>(flaggedEntity, args);

  const flaggedFormShape: FormReduxState<TOut> = isPersisted
    ? formShape
    : flagDirty(formShape);

  return fromJS(flaggedFormShape);
}
