import * as React from 'react';
import { DropTarget } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import './TermSheet.scss';
import PdfViewerContainer from '../pdfViewer/PdfViewerContainer';
import { UploadDropzone } from 'components';
import {
  AccessDeniedGuard,
  DealPerspectivePermissions,
  DisabledChildProps,
} from 'security';

import { getDownloadLink } from 'utils';
import { DocumentType } from 'types';

const monitorProps = { shallow: true };

const viewOnlyErrorMessage =
  'The Term Sheet for this deal failed to load properly.';
const errorMessage = `${viewOnlyErrorMessage} To upload another one: `;

type TermSheetProps = {
  connectDropTarget: (component: React.ReactNode) => React.ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  isUploading: boolean;
  setUploading: (uploadType: string) => void;
  termSheet: DocumentType;
  uploadFiles: (file: any) => void;
  uploadSuccessOrFailure: (uploadType: string) => void;
};

// @ts-ignore
@DropTarget(
  ['FILE', NativeTypes.FILE],
  {
    drop: (props, monitor) => {
      if (!monitor.isOver(monitorProps)) return;

      if (monitor.getItemType() === NativeTypes.FILE) {
        const file = monitor.getItem().files[0];
        this.props.uploadFiles(file);
      }
    },
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(monitorProps),
  }),
)
class TermSheet extends React.Component<TermSheetProps> {
  UNSAFE_componentWillReceiveProps(nextProps: TermSheetProps) {
    if (
      this.props.isUploading &&
      !this.props.termSheet &&
      nextProps.termSheet
    ) {
      this.props.uploadSuccessOrFailure('termsheet');
    }
  }

  shouldComponentUpdate(nextProps: TermSheetProps) {
    if (this.props.termSheet && nextProps.termSheet) {
      // if we have a termSheet, only update if the downloadLink changes
      return (
        nextProps.termSheet.relativeDownloadLink !==
        this.props.termSheet.relativeDownloadLink
      );
    }
    return true;
  }

  renderUpload = (alternateText?: string) => (
    <UploadDropzone
      alternateText={alternateText}
      className="TermSheet__Dropzone"
      dropzoneLabel="Term Sheet"
      isUploading={this.props.isUploading}
      setUploading={this.props.setUploading}
      singleFileOnly
      uploadFiles={this.props.uploadFiles}
      uploadTarget="termsheet"
    />
  );

  renderError = () => this.renderUpload(errorMessage);

  renderViewOnlyError = () => <div>{viewOnlyErrorMessage}</div>;

  renderPdf = (canEdit: boolean) => (
    <div className="TermSheet-PDF">
      <PdfViewerContainer
        fileUri={getDownloadLink(this.props.termSheet)}
        id={this.props.termSheet.id}
        renderError={canEdit ? this.renderError : this.renderViewOnlyError}
      />
    </div>
  );
  // TODO: We want to show all the pages at once, or allow the user
  //       to page up/down like an embedded document, instead of
  //       the left/right method we have now.

  renderViewOnly = () => {
    const { termSheet } = this.props;
    return !termSheet ? (
      <div>A Term Sheet is not currently available</div>
    ) : (
      this.renderPdf(false)
    );
  };

  renderEditable = () => {
    const { termSheet, connectDropTarget } = this.props;
    return !termSheet
      ? this.renderUpload()
      : connectDropTarget(this.renderPdf(true));
  };

  renderChildren = (security: DisabledChildProps) => (
    <div id="TermSheet">
      {security.accessDenied ? this.renderViewOnly() : this.renderEditable()}
    </div>
  );

  render() {
    return (
      <AccessDeniedGuard
        permission={DealPerspectivePermissions.administer_term_sheet}
      >
        {this.renderChildren}
      </AccessDeniedGuard>
    );
  }
}

export default TermSheet;
