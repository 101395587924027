import { basename, extname } from 'path';
import * as React from 'react';
import { Form, WrappedMediatorFormProps } from 'components';

import { DocumentInput } from 'types';
import { HotkeyWrapper } from 'utils';

const nameField = {
  id: 'name',
  fieldName: 'Folder Name',
  propertyName: 'name',
};

type Props = WrappedMediatorFormProps<DocumentInput> & {
  onClose: () => void;
  setAlert: (alert: string, type?: 'success' | 'error') => void;
};

const all = 'all';

export default class RowRenameInput extends React.Component<Props> {
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { props } = this;
    if (nextProps.errors) {
      const error = Form.parseError(
        nextProps.data.id,
        'Document',
        all,
        nextProps.errors,
      );

      if (error) {
        props.setAlert(
          `Document with name '${nextProps.data.name ||
            ''}' already exists in this directory.`,
          'error',
        );
        props.onClose();
      }
    }
  }

  handleChange = (name?: string | null | undefined) => {
    const oldName = this.props.data.name || '';
    const newName = (name || '') + extname(oldName);
    this.props.mutateProperty(newName, nameField.id);
  };

  value = (name: string) => basename(name, extname(name));

  handleSubmit = () => {
    this.props.onSave().then(this.props.onClose);
  };

  handleCancel = () => {
    this.props.onClose();
  };

  render() {
    const { errors, data } = this.props;

    const name = data.name || '';
    const error = errors
      ? Form.parseError(data.id, 'Document', nameField.id, errors)
      : null;
    return (
      <HotkeyWrapper hotkey="Escape" onPress={this.handleCancel}>
        <Form.Input
          {...nameField}
          autoFocus
          error={error}
          fieldName="File Name"
          onBlur={this.handleSubmit}
          onChange={this.handleChange}
          onChangeEventType="onKeyPress"
          value={this.value(name)}
        />
      </HotkeyWrapper>
    );
  }
}
