import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'components';
import { DealPerspectivePermissions, MountGuard } from 'security';
import { RouteTable } from 'routing/RouteTable';

function DealTermsActionButtons() {
  const { dealId } = useParams();

  return (
    <MountGuard
      permission={DealPerspectivePermissions.administer_loan_tranches}
    >
      <div className="ActionButtons ActionButtons--DealTerms">
        <Button
          label="Edit Tranche"
          to={RouteTable.deal.toDealLoanTrancheEdit(dealId)}
        />
      </div>
    </MountGuard>
  );
}

export default DealTermsActionButtons;
