import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import { InstitutionShallowFields, UserShallowFields } from './generated';

const UserFields: DocumentNode = gql`
  fragment UserFields on UserType {
    ...UserShallowFields
    institution {
      ...InstitutionShallowFields
    }
  }
  ${UserShallowFields}
  ${InstitutionShallowFields}
`;

export default UserFields;
