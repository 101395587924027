import * as React from 'react';
import noop from 'lodash/noop';
import { LoanTrancheFormProps } from '../types';
import TrancheGroup from './TrancheGroup';
import {
  getAmortization,
  isLetterOfCredit,
  isMultiDraw,
  isRevolver,
  isSwingline,
  getSuffixFromPaymentFrequency,
} from './utils';
import { FormFieldsType } from 'types';
import { Form } from 'components';

const loanTranche = 'LoanTranche';
const loanTrancheMultiDrawData = 'LoanTrancheMultiDrawData';
const amortization = 'LoanTrancheAmortization';
const width = '158px';

type Props = LoanTrancheFormProps & {
  onSave: () => Promise<any>;
};
class PrincipalFields extends React.Component<Props> {
  handleUpdateProperty = (
    value: string | null | undefined,
    fieldId: string,
  ) => {
    this.props.onUpdateLoanTrancheRevolverSwinglineLOCData({
      ...this.props.data.loantrancherevolverswinglinelocdata,
      [fieldId]: value,
    });
  };

  fields: FormFieldsType = {};

  constructor(props: Props) {
    super(props);

    this.fields = {
      totalCommitmentAmount: {
        id: 'initialDrawAmount',
        propertyName: 'initialDrawAmount',
        fieldName: 'Original Balance',
        onChange: this.props.mutateProperty,
        width,
        tabIndex: this.props.tabIndex,
      },
      interestOnlyPeriods: {
        id: 'interestOnlyPeriods',
        propertyName: 'interestOnlyPeriods',
        fieldName: 'Interest Only Period',
        onChange: this.props.mutateAmortization,
        width,
        tabIndex: this.props.tabIndex,
      },
      totalCommitment: {
        id: 'totalCommitment',
        propertyName: 'commitment',
        fieldName: 'Total Commitment',
        onChange: this.mutateMultiDrawRulesData,
        width,
        tabIndex: this.props.tabIndex,
      },
      remainingCommitment: {
        id: 'remainingCommitment',
        propertyName: 'remainingCommitment',
        fieldName: 'Remaining Commitment',
        onChange: noop,
        disabled: true,
        width,
      },
      balloonPayment: {
        id: 'balloonPayment',
        propertyName: 'balloonPayment',
        fieldName: 'Final Payment/Balloon',
        onChange: this.props.mutateAmortization,
        width,
        tabIndex: this.props.tabIndex,
      },
      issuePrice: {
        id: 'issuePrice',
        propertyName: 'issuePrice',
        fieldName: 'Issue Price',
        onChange: this.props.mutateProperty,
        width,
        tabIndex: this.props.tabIndex,
      },
      facilityMaxSize: {
        id: 'facilityMaxSize',
        propertyName: 'facilityMaxSize',
        fieldName: 'Facility Max Size',
        onChange: this.handleUpdateProperty,
        typeName: 'LoanTrancheRevolverSwinglineLOCDataType',
        width,
      },
    };
  }

  mutateMultiDrawRulesData = (
    value: string | null | undefined,
    property: string,
  ) => {
    const { data } = this.props;
    const { loantranchemultidrawdata } = data;
    this.props.replaceEntity('loantranchemultidrawdata', {
      ...loantranchemultidrawdata,
      [property]: value,
    });
  };

  getRemainingCommitment = () => {
    const { data } = this.props;
    const { loantranchemultidrawdata } = data;
    return String(
      Number(loantranchemultidrawdata && loantranchemultidrawdata.commitment) -
        Number(data.initialDrawAmount),
    );
  };

  render() {
    const { data, errors, isSaving } = this.props;
    const amortizationData = getAmortization(data);
    if (!amortizationData) return null;

    const letterOfCredit = isLetterOfCredit(data);
    const multiDraw = isMultiDraw(data);
    const revolver = isRevolver(data);
    const swingline = isSwingline(data);
    const amortProps = { ...this.props, data: amortizationData };

    const { loantranchemultidrawdata } = data;
    const multiDrawDataId =
      (loantranchemultidrawdata && loantranchemultidrawdata.id) || '';
    const multiDrawMediatorProps = {
      data: loantranchemultidrawdata,
      isSaving,
      errors,
      disabled: this.props.disabled,
    };

    if (letterOfCredit || revolver || swingline) {
      return (
        <TrancheGroup>
          <Form.Group>
            {Form.FieldRenderer(
              Form.Money,
              this.fields.facilityMaxSize,
              {
                ...this.props,
                data: this.props.data.loantrancherevolverswinglinelocdata,
              },
              loanTranche,
              data.id,
            )}
          </Form.Group>
        </TrancheGroup>
      );
    }

    return (
      <TrancheGroup>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Money,
            multiDraw
              ? {
                  ...this.fields.totalCommitmentAmount,
                  fieldName: 'Initial Draw Amount',
                }
              : this.fields.totalCommitmentAmount,
            this.props,
            loanTranche,
            data.id,
          )}
          {isMultiDraw(data) &&
            Form.FieldRenderer(
              Form.Money,
              this.fields.balloonPayment,
              amortProps,
              amortization,
              amortProps.data.id,
            )}
          {!isMultiDraw(data) &&
            Form.FieldRenderer(
              Form.Numeric,
              {
                ...this.fields.interestOnlyPeriods,
                suffix: getSuffixFromPaymentFrequency(data),
              },
              amortProps,
              amortization,
              amortProps.data.id,
            )}
        </Form.Group>
        {isMultiDraw(data) && (
          <Form.Group>
            {Form.FieldRenderer(
              Form.Money,
              this.fields.totalCommitment,
              multiDrawMediatorProps,
              loanTrancheMultiDrawData,
              multiDrawDataId,
            )}
            {Form.FieldRenderer(
              Form.Money,
              {
                ...this.fields.remainingCommitment,
                value: this.getRemainingCommitment(),
              },
              multiDrawMediatorProps,
              loanTrancheMultiDrawData,
              multiDrawDataId,
            )}
          </Form.Group>
        )}
        <Form.Group>
          {Form.FieldRenderer(
            Form.Percentage,
            this.fields.issuePrice,
            this.props,
            loanTranche,
            data.id,
          )}
          {!isMultiDraw(data) &&
            Form.FieldRenderer(
              Form.Money,
              this.fields.balloonPayment,
              amortProps,
              amortization,
              amortProps.data.id,
            )}
        </Form.Group>
      </TrancheGroup>
    );
  }
}

export default PrincipalFields;
