import * as React from 'react';
import keyCodes from '../resources/testUtils/keyCodes';

let addEventListener;
let removeEventListener;

type Props = {
  children: React.ReactNode;
  hotkey: string | Array<string>;
  onPress: () => void;
};
type State = {
  active: boolean;
};

const keydown = 'keydown';
const keyup = 'keyup';
export default class HotkeyWrapper extends React.Component<Props, State> {
  state = {
    active: false,
  };

  componentDidMount() {
    const { hotkey } = this.props;
    ({ addEventListener, removeEventListener } = window.document);
    if (Array.isArray(hotkey)) {
      hotkey.forEach(key => {
        if (!keyCodes[key]) {
          throw new Error(
            `The requested hotkey '${key}' was not found in the keyCodes definition`,
          );
        }
      });
    } else if (!keyCodes[hotkey]) {
      throw new Error(
        `The requested hotkey '${hotkey}' was not found in the keyCodes definition`,
      );
    }

    addEventListener(keydown, this.onKeyDown);
    addEventListener(keyup, this.onKeyUp);
  }

  componentWillUnmount() {
    removeEventListener(keydown, this.onKeyDown);
    removeEventListener(keyup, this.onKeyUp);
  }

  onKeyDown = (e: any) => {
    if (this.isThisKey(e) && !this.state.active) {
      this.setState({ active: true });
      this.props.onPress();
    }
  };

  onKeyUp = (e: any) => {
    if (this.isThisKey(e) && this.state.active) {
      this.setState({ active: false });
    }
  };

  isThisKey = (data: { key: string; which: string; keyCode: string }) => {
    const { key, which, keyCode } = data;

    if (Array.isArray(this.props.hotkey)) {
      const hotkeys = this.props.hotkey.map(hotkey => keyCodes[hotkey]);
      return hotkeys.some(
        hotkey =>
          key === hotkey.key ||
          which === hotkey.which ||
          keyCode === hotkey.keyCode,
      );
    }
    const hotkey = keyCodes[this.props.hotkey];
    return (
      key === hotkey.key || which === hotkey.which || keyCode === hotkey.keyCode
    );
  };

  render() {
    const { onPress, children } = this.props;
    const { active } = this.state;
    return (
      <>
        {React.Children.map(children, child =>
          React.cloneElement(child as any, {
            className: `${active ? 'active' : ''}`.trim(),
            onClick: onPress,
          }),
        )}
      </>
    );
  }
}
