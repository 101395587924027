import * as React from 'react';
import { StepProps } from '../StepProps';
import WorkflowStep from '../WorkflowStep';
import { LenderRequestResubmissionForm as LenderResubmissionRequests } from '../modals';
import { invariant } from 'utils';
import { Button, Text } from 'components';
import raiseAlert from 'lsredux/raiseAlert';
import triggerClosingWorkflowTransition from 'lsgql/mutations/TransitionClosingWorkflow';

function BorrowerSubmitApplication(props: StepProps) {
  const { step, dealClosingWorkflow, isDimmed, canEdit } = props;
  invariant(
    step.slug === 'aw_borrower_submit_borrower_application',
    'invalid slug',
  );

  function onButtonClick() {
    /** TODO: SBA-HACK: Integrate to e-sign tool
     * Sends the lender the email notification, shown in Zeplin https://zpl.io/adJ10BL
     */
    raiseAlert(
      'The Lender has been notified that your materials are ready for review.',
      'success',
    );
    triggerClosingWorkflowTransition({
      workflowId: dealClosingWorkflow.id,
      state: 'lender_approve_borrower_application',
    });
  }

  const renderStepText = () => {
    switch (dealClosingWorkflow?.state) {
      case 'borrower_finish_application':
        return 'When you think everything is ready, click below to notify your lender.';
      case 'lender_approve_borrower_application':
        return 'You have submitted your application for review.';
      case 'borrower_lender_rejected_application':
        return 'Your lender needs you to fix the following items before resubmitting your application.';
      case 'borrower_application_signature_requested':
        return 'Your application is complete and ready for your signature.';
      default:
        return '';
    }
  };

  return (
    <WorkflowStep
      canEdit={canEdit}
      header="SUBMIT APPLICATION"
      isDimmed={isDimmed}
      step={step}
    >
      <Text>{renderStepText()}</Text>
      {dealClosingWorkflow?.state ===
        'borrower_lender_rejected_application' && (
        <LenderResubmissionRequests disableFormFields />
      )}
      {canEdit && (
        <div className="Group left padded3">
          <Button.Primary
            label={
              dealClosingWorkflow?.state ===
              'borrower_lender_rejected_application'
                ? 'Resubmit Application for Review'
                : 'Submit Application for Review'
            }
            onClick={onButtonClick}
          />
        </div>
      )}
    </WorkflowStep>
  );
}

export default BorrowerSubmitApplication;
