import * as React from 'react';
import './HeaderCellWithUnit.scss';

const HeaderCellWithUnit = (display: string, unit = '$') => (
  <div>
    <span>{`${display} `}</span>
    <span className="HeaderCellWithUnit">{`(${unit})`}</span>
  </div>
);

export default HeaderCellWithUnit;
