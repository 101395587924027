import * as React from 'react';
import { compose, withProps, withState } from 'recompose';
import { connect } from 'react-redux';
import { withStagedFilesHoC } from '../../../HOCs';
import BorrowerPaymentView from './BorrowerPaymentView';
import { withLoadingIndicator, FormMediator } from 'components';

import { RouteParams, withRouteParams } from 'routing';
import {
  PaymentRecordFormQuery,
  DocumentMutations,
  uploadActionFormDocument,
} from 'lsgql';
import paymentRecordBorrowerRedux from 'lsredux/reducer/forms/paymentRecordBorrower';

import { setAlert } from 'lsredux/actions/alerts';
import { AlertType, DocumentType } from 'types';

const mapDispatchToProps = (dispatch: any) => {
  const bound = paymentRecordBorrowerRedux.actions.generateActions(dispatch);

  const res: {
    setAlert: (alert: string, type: AlertType, title?: string) => void;
  } = {
    setAlert: (alert: string, type: AlertType, title?: string) => {
      dispatch(setAlert(alert, type, title));
    },
  };
  return { ...bound, ...res };
};
const BorrowerPaymentViewContainer: React.ComponentType<{
  dealId: string;
  documents: DocumentType[];
}> = compose(
  withState('stagedFiles', 'setStagedFiles', []),
  withRouteParams([RouteParams.dealId, RouteParams.paymentRecordId]),
  PaymentRecordFormQuery,
  withLoadingIndicator('loading'),
  DocumentMutations,
  connect(paymentRecordBorrowerRedux.mapStateToProps, mapDispatchToProps),
  withProps({ uploadType: 'payment' }),
  withStagedFilesHoC(uploadActionFormDocument),
  FormMediator({
    formId: 'paymentRecordBorrowerForm',
    formHeader: 'Payment',
    baseObjectTypeName: 'PaymentRecordBorrower',
    disableFrame: true,
  }),
)(BorrowerPaymentView);

export default BorrowerPaymentViewContainer;
