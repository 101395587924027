import * as React from 'react';
import { compose } from 'recompose';
import LegacyDealClosingWorkflowContainer from './legacyWorkflows/LegacyDealClosingWorkfowContainer';
import SbaDealClosingWorkflowContainer from './sbaWorkflows/SbaClosingWorkflowContainer';
import { RouteParams, withRouteParams } from 'routing';
import { DealClosingWorkflowStandardQuery } from 'lsgql';
import { DealType } from 'types';
import { withLoadingIndicator } from 'components';

type ForkProps = {
  deal: DealType;
};

function WorkflowFork(props: ForkProps) {
  const { deal } = props;

  const steps = deal?.dealClosingWorkflow?.steps;
  if (!steps || steps.length === 0) return null;

  const { workflowType } = deal?.dealClosingWorkflow;

  if (workflowType == 'SBA_PPP_CLOSING_WORKFLOW') {
    return <SbaDealClosingWorkflowContainer />;
  }
  return <LegacyDealClosingWorkflowContainer />;
}

export default compose(
  withRouteParams([RouteParams.dealId]),
  DealClosingWorkflowStandardQuery,
  withLoadingIndicator('loading'),
)(WorkflowFork);
