import { compose } from 'recompose';
import { connect } from 'react-redux';
import Documentation from './Documentation';
import {
  SbaPppForgivenessWorkflowFormQuery,
  SbaPppForgivenessWorkflowFoldersQuery,
} from 'lsgql';
import { withRouteParams, RouteParams } from 'routing';
import { withLoadingIndicator, FormMediator } from 'components';
import sbaPppForgivnessWorkflowFormRedux from 'lsredux/reducer/forms/sbaPppForgivenessWorkflow';

export default compose(
  withRouteParams([RouteParams.dealId]),
  SbaPppForgivenessWorkflowFoldersQuery,
  SbaPppForgivenessWorkflowFormQuery,
  withLoadingIndicator('loading'),
  connect(
    sbaPppForgivnessWorkflowFormRedux.mapStateToProps,
    sbaPppForgivnessWorkflowFormRedux.actions.generateActions,
  ),
  FormMediator({
    formId: 'sbaPppForgivenessWorkflowForm',
    baseObjectTypeName: 'Deal',
    disableFrame: true,
    disableAlerts: true,
  }),
)(Documentation);
