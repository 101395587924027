import { createStore, compose, applyMiddleware } from 'redux';
import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import ReduxThunk from 'redux-thunk';
import { DehydratedStateType } from '../types';

/* Forms */
import localStorageMiddleware from '../middleWare/localStorage';
import Directory from '../directory';
import addtionalSbaPppBorrowerInformationForm from './forms/additionalSbaPppBorrowerInformation';
import auditTrailFilters from './forms/auditTrailFilters';
import cancelLoanForm from './forms/cancelLoan';
import calendarViewStartEnd from './forms/calendarViewStartEnd';
import dealClosingWorkflowForm from './forms/dealClosingWorkflow';
import dealForm from './forms/deal';
import documentForm from './forms/document';
import eventDueForm from './forms/eventDue';
import feesForm from './forms/fees';
import folderForm from './forms/folder';
import orderBookForm from './forms/orderBook';
import generalNoticeForm from './forms/generalNotice';
import institutionForm from './forms/institution';
import institutionInviteForm from './forms/institutionInvitation';
import registrationForm from './forms/register';
import loanFundingForm from './forms/loanFunding';
import loanTrancheForm from './forms/loanTranche';
import lenderRegistryForm from './forms/lenderRegistry';
import paymentRecordBorrowerForm from './forms/paymentRecordBorrower';
import paymentRecordLenderForm from './forms/paymentRecordLender';
import taskForm from './forms/task';
import userForm from './forms/user';
import userProfileForm from './forms/userProfile';
import SbaPppLoanApplicationForm from './forms/publicSbaPppLoanApplication';
import SbaPppForgivenessCalculationForm from './forms/sbaPppForgivenessCalculation';
import SbaPppForgivenessLoanInformationForm from './forms/sbaPppForgivenessLoanInformation';
import SbaPppForgivenessLoanInformationDemographicsForm from './forms/sbaPppForgivenessLoanInformationDemographics';
import SbaPppForgivenessResubmitRequestForm from './forms/sbaPppForgivenessResubmitRequest';
import SbaPppForgivenessEzFormQualificationsForm from './forms/sbaPppForgivenessEzFormQualifications';
import SbaPppForgivenessDenialForm from './forms/sbaPppForgivenessDenial';
import SbappplenderclosingworkflowquestionsForm from './forms/sbaPppLenderClosingWorkflowQuestions';
import SbaPppRoundTwoLenderApplicationForm from './forms/sbaPppRoundTwoLenderApplication';
import sbaLenderRequestResubmissionForm from './forms/sbaPppApplicationResubmitRequest';
import sbaSecondDrawOnboardingForm from './forms/secondDrawOnboarding';

/* Debug */

import generateDealForm from './debugForms/generateDeal';

/* Presentational */

import auditTrail from './presentational/auditTrail';
import calendar from './presentational/calendar';
import dataroom from './presentational/dataroom';
import dataroomrow from './presentational/dataroomrow';
import disabledForms from './presentational/disabledForms';
import main from './presentational/main';
import menu from './presentational/menu';
import people from './presentational/people';
import termsheet from './presentational/termsheet';
import dealpdfviewer from './presentational/dealpdfviewer';

/* Utility */

import alerts from './utility/alerts';
import uploading from './utility/uploading';
import { logToSentry } from 'utils';

const persistedShape = {
  [Directory.AuditTrailKey]: auditTrail,
  [Directory.CalendarKey]: calendar,
  [Directory.DataroomKey]: dataroom,
  [Directory.DealPDFViewerKey]: dealpdfviewer,
  [Directory.MainKey]: main,
  [Directory.MenuKey]: menu,
  [Directory.DealPeopleKey]: people,
  [Directory.TermSheetKey]: termsheet,
};

// persisted reducers
const persisted = combineReducers(persistedShape);

// non-persisted reducers
const nonPersisted = combineReducers({
  [Directory.AdditionalSbaPppBorrowerInformationKey]:
    addtionalSbaPppBorrowerInformationForm.reducer,
  [Directory.PaymentRecordBorrowerKey]: paymentRecordBorrowerForm.reducer,
  [Directory.PaymentRecordLenderKey]: paymentRecordLenderForm.reducer,
  [Directory.AuditTrailFiltersKey]: auditTrailFilters,
  [Directory.CalendarViewStartEndKey]: calendarViewStartEnd,
  [Directory.CancelLoanKey]: cancelLoanForm.reducer,
  [Directory.DataroomRowKey]: dataroomrow,
  [Directory.DealClosingWorkflowKey]: dealClosingWorkflowForm.reducer,
  [Directory.DealKey]: dealForm.reducer,
  [Directory.DisabledFormsKey]: disabledForms,
  [Directory.DocumentKey]: documentForm.reducer,
  [Directory.EventDueKey]: eventDueForm.reducer,
  [Directory.FeesKey]: feesForm.reducer,
  [Directory.FolderKey]: folderForm.reducer,
  [Directory.GeneralNoticeKey]: generalNoticeForm.reducer,
  [Directory.InstitutionInviteKey]: institutionInviteForm.reducer,
  [Directory.InstitutionKey]: institutionForm.reducer,
  [Directory.RegistrationKey]: registrationForm.reducer,
  [Directory.LenderRegistryKey]: lenderRegistryForm.reducer,
  [Directory.LoanFundingKey]: loanFundingForm.reducer,
  [Directory.LoanTrancheKey]: loanTrancheForm.reducer,
  [Directory.AlertsKey]: alerts,
  [Directory.OrderBookKey]: orderBookForm.reducer,
  [Directory.TaskKey]: taskForm.reducer,
  [Directory.UploadingKey]: uploading,
  [Directory.UserKey]: userForm.reducer,
  [Directory.UserProfileKey]: userProfileForm.reducer,
  [Directory.SbaPppLoanApplicationKey]: SbaPppLoanApplicationForm.reducer,
  [Directory.SbaPppLenderClosingWorkflowQuestionsKey]:
    SbappplenderclosingworkflowquestionsForm.reducer,
  [Directory.SbaPppRoundTwoLenderApplicationKey]:
    SbaPppRoundTwoLenderApplicationForm.reducer,
  [Directory.SbaPppApplicationResubmitRequestKey]:
    sbaLenderRequestResubmissionForm.reducer,
  [Directory.SbaPppForgivenessCalculationKey]:
    SbaPppForgivenessCalculationForm.reducer,
  [Directory.SbaPppForgivenessLoanInformationKey]:
    SbaPppForgivenessLoanInformationForm.reducer,
  [Directory.SbaPppForgivenessLoanInformationDemographicsKey]:
    SbaPppForgivenessLoanInformationDemographicsForm.reducer,
  [Directory.SbaPppForgivenessResubmitRequestKey]:
    SbaPppForgivenessResubmitRequestForm.reducer,
  [Directory.SbaPppForgivenessDenialKey]: SbaPppForgivenessDenialForm.reducer,
  [Directory.SbaPppForgivenessEzFormQualificationsKey]:
    SbaPppForgivenessEzFormQualificationsForm.reducer,
  [Directory.GenerateDealKey]: generateDealForm.reducer,
  [Directory.SbaSecondDrawOnboardingKey]: sbaSecondDrawOnboardingForm.reducer,
});

const reducerShape = {};
reducerShape[Directory.PersistedKey] = persisted;
reducerShape[Directory.NonPersistedKey] = nonPersisted;

const reducer = combineReducers(reducerShape);

/**
 * Attempt to read persisted data from localStorage, if data
 * exists and can be read without errors, parse into an
 * Immutable object tree and return as the initial state
 */
function hydrateFromPersistedState(): any | null {
  try {
    const persistedRaw = localStorage.getItem(
      (Directory.LocalStorageKey as any) as string,
    );
    const currentCommitHash = process.env.REACT_APP_GIT_COMMIT;

    if (persistedRaw) {
      const parsed: DehydratedStateType = JSON.parse(persistedRaw);

      if (currentCommitHash && currentCommitHash === parsed.hash) {
        const hydrated = {};
        hydrated[Directory.PersistedKey] = parsed.data;
        hydrated[Directory.NonPersistedKey] = {};
        return fromJS(hydrated);
      }
      localStorage.removeItem(Directory.LocalStorageKey);
    }
  } catch (e) {
    // eslint-disable-next-line
    console.error('State Hydration Failed', e);
    localStorage.removeItem(Directory.LocalStorageKey);
    logToSentry(e);
  }
  return undefined;
}

// eslint-disable-next-line
const composeEnhancers =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as any) || compose;

const store = createStore(
  reducer,
  hydrateFromPersistedState(),
  /**
   * ReduxThunk should come first, Thunk is broken if localStorage is first in chain
   */
  composeEnhancers(applyMiddleware(ReduxThunk, localStorageMiddleware)),
);

export default store;
