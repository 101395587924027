import * as React from 'react';
import uuid from 'uuid/v4';
import noop from 'lodash/noop';
import cx from 'classnames';
import ApplicableMarginsItem from './ApplicableMarginsItem';
import { ux } from 'utils';
import { Form, Button } from 'components';
import { ApplicableMarginType, FieldRendererGeneralPropsType } from 'types';

import './ApplicableMargins.scss';

type Props = FieldRendererGeneralPropsType & {
  applicableMarginType: 'abr' | 'libor' | 'prime' | 'fedFunds';
  className?: string;
  data: ReadonlyArray<ApplicableMarginType>;
  handleRemoveApplicableMargin: (margin: ApplicableMarginType) => void;
  isFirstSelected: boolean;
  onChange: (margin: ApplicableMarginType) => void;
};

const applicableMargin = 'ApplicableMargin';

class ApplicableMargins extends React.Component<Props> {
  marginKey = `${this.props.applicableMarginType}Margin`;

  fields = {
    nameHeader: {
      id: 'nameHeader',
      propertyName: 'nameHeader',
      fieldName: 'Margin Name',
      header: 'Margin Name',
      width: '158px',
      onChange: noop,
    },
    valueHeader: {
      id: 'valueHeader',
      propertyName: 'valueHeader',
      fieldName: 'Margin Value',
      header: 'Margin Value',
      width: '120px',
      onChange: noop,
    },
  };

  handleAddApplicableMargin = () => {
    this.props.onChange({
      id: uuid(),
      level: String(this.props.data.length + 1),
      __typename: 'ApplicableMarginType',
    });
  };

  handleRemoveApplicableMarginsItem = (idx: number) => () => {
    const { data, onChange, handleRemoveApplicableMargin } = this.props;
    handleRemoveApplicableMargin(data[idx]);
    const nextLevelMargins = data.slice(idx + 1);
    nextLevelMargins.forEach((margin, jdx) => {
      onChange({
        ...margin,
        level: String(idx + jdx + 1),
      });
    });
  };

  render() {
    const {
      applicableMarginType,
      blurEventForm,
      className,
      data,
      isFirstSelected,
      disabled,
      onChange,
    } = this.props;
    const computedClassName = cx(
      'ApplicableMargins',
      'lsFormGroup--Lowered',
      ux(className, className),
    );

    return (
      <>
        <Form.Group className={computedClassName}>
          {Form.FieldRenderer(
            Form.InputHeader,
            this.fields.nameHeader,
            this.props,
            applicableMargin,
            data[0].id,
          )}
          {Form.FieldRenderer(
            Form.InputHeader,
            this.fields.valueHeader,
            this.props,
            applicableMargin,
            data[0].id,
          )}
        </Form.Group>
        {data.map((margin, idx) => (
          <ApplicableMarginsItem
            applicableMarginType={applicableMarginType}
            blurEventForm={blurEventForm}
            data={margin}
            disabled={Boolean(disabled)}
            errors={this.props.errors}
            handleRemoveApplicableMarginsItem={this.handleRemoveApplicableMarginsItem(
              idx,
            )}
            isFirstSelected={isFirstSelected}
            isSaving={this.props.isSaving}
            key={margin.id}
            onChange={onChange}
            showDelete={idx !== 0}
          />
        ))}
        {isFirstSelected && !disabled && (
          <Button.Text
            className="ApplicableMargins__AddLevelButton"
            label="+ Add another level"
            onClick={this.handleAddApplicableMargin}
          />
        )}
      </>
    );
  }
}

export default ApplicableMargins;
