import * as React from 'react';
import { useLocation } from 'react-router-dom';
import PortfolioMenuItem from './menuItems/PortfolioMenuItem';
import GoBackMenuItem from './menuItems/GoBackMenuItem';

function ProfileContextMenu() {
  const location = useLocation();

  return (
    <>
      <GoBackMenuItem />
      <PortfolioMenuItem location={location.pathname} />
    </>
  );
}

export default ProfileContextMenu;
