import * as React from 'react';
import { WorkflowHelpBox } from '../workflowList';
import { ResourceLink } from '../forgivenessCalculation/partials';
import { Text } from 'components';

export const InstructionsHelpBox = (props: {
  isLender: boolean;
  isSFormEligible: boolean;
}) => {
  const { isLender, isSFormEligible } = props;

  return (
    <WorkflowHelpBox header="Resources and Help">
      <>
        <Text value="Reference information and definitions will display in this space as you work through the forms on the next tabs." />
        <Text value="The LoanStreet Excel worksheets can be used to organize, record and calculate your totals." />
        {isSFormEligible ? (
          <ResourceLink.SFormExcel
            icon
            label="Excel Worksheet (for Form 3508S)"
          />
        ) : (
          <>
            <ResourceLink.StandardExcel
              icon
              label="Excel Worksheet (for standard form)"
            />
            <ResourceLink.EzExcel icon label="Excel Worksheet (for EZ form)" />
            <Text
              value={`Not sure if ${
                isLender ? 'your borrower qualifies' : 'you qualify'
              } for the EZ application? Use the standard form. The calculated amount won’t be impacted by the form.`}
            />
          </>
        )}
      </>
    </WorkflowHelpBox>
  );
};
