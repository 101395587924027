import React from 'react';
import { Header } from 'components';
import './WorkflowHelpBox.scss';
import { invariant } from 'utils';

export type HelpBoxCallbackType = (e: React.SyntheticEvent<any>) => void;
interface Props {
  header?: string;
  children?: React.ReactNode;
  control?: (callback: HelpBoxCallbackType) => React.ReactNode;
  callback?: (e: React.SyntheticEvent<any>) => void;
}
/** This box sits next to sections on a form to provide guiding text
 * for user to fill out form.
 */
const WorkflowHelpBox = (props: Props) => {
  const { header, children, control, callback } = props;
  if (callback)
    invariant(
      callback && control,
      `Invariant on ${header} HelpBox. control must be provided when there is a callback`,
    );
  else
    invariant(
      !callback && children,
      `Invariant on ${header} HelpBox. children must be provided when there is no callback`,
    );
  return (
    <div className="HelpBox">
      <div className="Contents">
        <Header as="h4" className="bold">
          {header}
        </Header>
        <div className="Body">{callback ? control(callback) : children}</div>
      </div>
    </div>
  );
};

export default WorkflowHelpBox;
