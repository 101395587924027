import { compose } from 'recompose';
import { connect } from 'react-redux';

import { alerts } from '../../../../resources';
import FolderRowRenameInput from './FolderRowRenameInput';
import { FormMediator } from 'components';
import { FolderType, FolderInput } from 'types';
import { RouteParams, withRouteParams } from 'routing';
import { FolderFormQuery, DispatchResponse } from 'lsgql';

import { FolderPerspectivePermissions } from 'security';

import folderFormRedux from 'lsredux/reducer/forms/folder';
import { setAlert } from 'lsredux/actions/alerts';

import { DispatchMethods } from 'lsredux/genericForms';

type OwnProps = {
  isOpen: boolean;
  onClose: () => void;
  parentFolderId: string;
  preExistingEntity: any;

  toEdit: FolderType | null | undefined;
};
type AdditionalMethods = {
  handleResponse: (response: any) => void;
  onCancel: () => void;
  setAlert: (arg0: string, arg1: 'success' | 'error') => void;
  setParentFolderId: (parentFolderId: string) => void;
};

const mapDispatchToProps: (
  dispatch: any,
  ownProps: OwnProps,
) => DispatchMethods<FolderInput> & AdditionalMethods = (
  dispatch: any,
  ownProps: OwnProps,
) => {
  const bound = folderFormRedux.actions.generateActions(dispatch);

  const additional: AdditionalMethods = {
    setParentFolderId(parentFolderId: string) {
      bound.mutateProperty(parentFolderId, 'parentId');
    },
    onCancel() {
      ownProps.onClose();
    },

    setAlert: (error, type) => {
      dispatch(setAlert(error, type));
    },

    handleResponse: (response: DispatchResponse<FolderInput>) => {
      bound.handleResponse(response);
      ownProps.onClose();
    },
  };

  const overridenSave = async (formQueryRefetch: any) => {
    const res = await bound.save(formQueryRefetch);
    ownProps.onClose();
    return res;
  };

  return { ...bound, save: overridenSave, ...additional };
};

export default compose(
  withRouteParams([RouteParams.dealId]),
  FolderFormQuery,
  connect(folderFormRedux.mapStateToProps, mapDispatchToProps),
  FormMediator({
    editSecurity: FolderPerspectivePermissions.change_folder,
    formId: 'FolderRowRenameInput',
    successAlert: alerts.saveFolderSuccess,
    baseObjectTypeName: 'Folder',
  }),
)(FolderRowRenameInput);
