import * as React from 'react';
import Secured, { DisabledChildProps } from './Secured';

import { PermissionDefinition } from './definitions/types';

interface AccessDeniedGuardProps {
  permission: PermissionDefinition | Array<PermissionDefinition>;
  children: (childProps: DisabledChildProps) => React.ReactNode;
}

/* eslint-disable import/prefer-default-export */

/**
 * Check that the user has the required permissions.  This component expects
 * children to be a render function that accepts props of type `DisabledChildProps`.
 * The children render method will be invoked with either { accessDenied: true } or
 * { accessDenied: false } depending on whether the user has the required permissions.
 * This component _will always invoke_ the `children` render method.  If you need
 * to prevent mounting, look to @class MountGuard or @class AccessGuard
 */
export class AccessDeniedGuard extends React.Component<AccessDeniedGuardProps> {
  render() {
    return (
      <Secured denyAction="disable" permission={this.props.permission}>
        {this.props.children}
      </Secured>
    );
  }
}
