import { mutationBuilder } from './builders';

import {
  CreateTierFeePercentageMutationDefinition,
  SetTierFeePercentageMutationDefinition,
  DeleteTierFeePercentageMutationDefinition,
} from './definitions';
import { TierFeePercentageInput, TierFeePercentageType, ID } from 'types';

const TierFeePercentageMutationMethods = {
  createTierFeePercentage: mutationBuilder<
    TierFeePercentageInput,
    TierFeePercentageType
  >(CreateTierFeePercentageMutationDefinition, {
    build: mutate => tierFeePercentageData =>
      mutate({ variables: { tierFeePercentageData } }),
  }),
  setTierFeePercentage: mutationBuilder<
    TierFeePercentageInput,
    TierFeePercentageType
  >(SetTierFeePercentageMutationDefinition, {
    build: mutate => tierFeePercentageData =>
      mutate({ variables: { tierFeePercentageData } }),
  }),
  deleteTierFeePercentage: mutationBuilder<ID, void>(
    DeleteTierFeePercentageMutationDefinition,
    {
      build: mutate => (id: string) => mutate({ variables: { id } }),
    },
  ),
};

export default TierFeePercentageMutationMethods;
