import { mutationBuilder } from './builders';
import {
  CreateReserveMutationDefinition,
  SetReserveMutationDefinition,
  DeleteReserveMutationDefinition,
} from './definitions';
import { ReserveInput, ReserveType, ID } from 'types';

const ReserveMutationMethods = {
  createReserve: mutationBuilder<ReserveInput, ReserveType>(
    CreateReserveMutationDefinition,
    {
      build: mutate => reserveData => mutate({ variables: { reserveData } }),
    },
  ),
  setReserve: mutationBuilder<ReserveInput, ReserveType>(
    SetReserveMutationDefinition,
    {
      build: mutate => reserveData => mutate({ variables: { reserveData } }),
    },
  ),
  deleteReserve: mutationBuilder<ID, void>(DeleteReserveMutationDefinition, {
    build: mutate => (id: string) => mutate({ variables: { id } }),
  }),
};

export default ReserveMutationMethods;
