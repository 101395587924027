import * as React from 'react';
import { compose } from 'recompose';
import {
  withLoadingIndicator,
  DisplayTable,
  Money,
  Date,
  RouteTo,
} from 'components';
import { PaymentDueType, SortBy } from 'types';
import { PaymentScheduleQuery } from 'lsgql';
import { lsSort } from 'utils';
import { RouteTable } from 'routing/RouteTable';
import { ID } from 'types/primitives';

type Props = {
  dealId: ID;
  paymentSchedule: Array<PaymentDueType>;
};

type State = {
  sortBy: SortBy;
};

const columns = [
  { name: 'date' },
  { name: 'principal' },
  { name: 'interest' },
  { name: 'total' },
  { name: 'balance' },
];

const columnMap = {
  principal: 'principalAmount',
  interest: 'interestAmount',
  balance: 'balanceAfterPayment',
};

class PaymentSchedule extends React.Component<Props, State> {
  state = {
    sortBy: {
      column: 'date',
      reverse: false,
    },
  };

  handleChangeSortField = (sortBy: SortBy) => {
    this.setState({ sortBy });
  };

  renderTableRow = (paymentDue: PaymentDueType) => {
    if (paymentDue.paymentrecord && paymentDue.paymentrecord.id) {
      const { dealId } = this.props;
      const paymentRecordRoute = RouteTable.deal.toDealPaymentRecord(
        dealId,
        paymentDue.paymentrecord.id,
      );
      return (
        <DisplayTable.TR key={paymentDue.id}>
          <DisplayTable.TDRouteTo as={RouteTo} to={paymentRecordRoute}>
            <Date format="YYYY/MM/DD" value={paymentDue.date} />
          </DisplayTable.TDRouteTo>
          <DisplayTable.TDRouteTo as={RouteTo} to={paymentRecordRoute}>
            <Money value={paymentDue.principalAmount} />
          </DisplayTable.TDRouteTo>
          <DisplayTable.TDRouteTo as={RouteTo} to={paymentRecordRoute}>
            <Money value={paymentDue.interestAmount} />
          </DisplayTable.TDRouteTo>
          <DisplayTable.TDRouteTo as={RouteTo} to={paymentRecordRoute}>
            <Money value={paymentDue.totalAmount} />
          </DisplayTable.TDRouteTo>
          <DisplayTable.TDRouteTo as={RouteTo} to={paymentRecordRoute}>
            {paymentDue.balanceAfterPayment ? (
              <Money value={paymentDue.balanceAfterPayment} />
            ) : (
              '-'
            )}
          </DisplayTable.TDRouteTo>
        </DisplayTable.TR>
      );
    }
    return (
      <DisplayTable.TR key={paymentDue.id}>
        <DisplayTable.TD>
          <Date format="YYYY/MM/DD" value={paymentDue.date} />
        </DisplayTable.TD>
        <DisplayTable.TD>
          <Money value={paymentDue.principalAmount} />
        </DisplayTable.TD>
        <DisplayTable.TD>
          <Money value={paymentDue.interestAmount} />
        </DisplayTable.TD>
        <DisplayTable.TD>
          <Money value={paymentDue.totalAmount} />
        </DisplayTable.TD>
        <DisplayTable.TD>
          {paymentDue.balanceAfterPayment ? (
            <Money value={paymentDue.balanceAfterPayment} />
          ) : (
            '-'
          )}
        </DisplayTable.TD>
      </DisplayTable.TR>
    );
  };

  render() {
    const { paymentSchedule } = this.props;
    const { sortBy } = this.state;

    const sortedItems = lsSort(paymentSchedule, sortBy, columnMap);
    return (
      <DisplayTable
        columns={columns}
        hoverable
        onChangeSortField={this.handleChangeSortField}
        sortBy={sortBy}
      >
        {sortedItems.map(payment => this.renderTableRow(payment))}
      </DisplayTable>
    );
  }
}

export default compose(
  PaymentScheduleQuery,
  withLoadingIndicator('loading'),
)(PaymentSchedule);
