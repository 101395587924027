import { connect } from 'react-redux';
import { compose } from 'recompose';
import ReportViewTable from './ReportViewTable';
import { ReduxDirectory } from 'lsredux';
import { withToJS } from 'utils';

import { changeSortField as changeSortFieldAction } from 'lsredux/actions/auditTrail';

const mapStateToProps: (state: any) => { rawData: any } = state => ({
  rawData: state.getIn(ReduxDirectory.AuditTrailKeyPath),
});

export default compose(
  connect(mapStateToProps, { changeSortField: changeSortFieldAction }),
  withToJS,
)(ReportViewTable);
