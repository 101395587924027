import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RoutingContext } from './types';
import { invariant } from 'utils';

type Props = {
  context: RoutingContext;
};

export default class RoutePortal extends React.Component<Props> {
  routeContext: RoutingContext;

  constructor(props: Props) {
    super(props);

    invariant(props.context, 'RoutingContext must be provided');
    invariant(
      props.context.routes && props.context.routes.length > 0,
      'props.routes cannot be null/empty',
    );

    const uniqueRoutes = new Set(
      props.context.routes.map(e => e.path || 'Empty'),
    );

    invariant(
      uniqueRoutes.size === props.context.routes.length,
      'Duplicate routes are not permitted',
    );

    // we don't expect Routes to ever change
    this.routeContext = props.context;
  }

  render() {
    return (
      <Switch>
        {this.routeContext.routes.map(e => (
          <Route key={e.path} {...e} />
        ))}
      </Switch>
    );
  }
}
