import React from 'react';
import { ConfirmModal } from 'components';

import {
  isProduction,
  getLocalHash,
  fetchCommitHash,
  logMessageToSentry,
} from 'utils';

export function notEqual(
  stagingHash: string | null | undefined,
  localHash: string | null | undefined,
) {
  return stagingHash && localHash && stagingHash !== localHash;
}

/**
 * VersionCop checks every 20 minutes whether the local frontend bundle is out
 * of date with the current staging build. If it is, it renders a modal
 * prompting the user to refresh the page. On confirm, the modal will refresh
 * the page. On cancel, the component will prompt again 20 minutes later.
 */
class VersionCop extends React.Component<{}, { modalIsOpen: boolean }> {
  intervalId: any;

  localHash: string | null | undefined;

  constructor(props: {}) {
    super(props);

    /**
     * Start with the modal closed
     */
    this.state = { modalIsOpen: false };

    /**
     * Gets local commit hash of the frontend bundle (only available in
     * production)
     */
    this.localHash = getLocalHash();

    if (isProduction() && !this.localHash) {
      const message =
        'Unable to determine localHash, VersionCop will fail to identify updates';
      // eslint-disable-next-line
      console.warn(message);
      logMessageToSentry(message);
    }
  }

  componentDidMount() {
    /**
     * In production, check commit hash every 4 minutes
     */
    if (this.localHash) {
      this.checkCommitHash();
      this.intervalId = setInterval(this.checkCommitHash, 240000);
    }
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  handleRejectModal = () => {
    /**
     * Close modal on reject
     */
    this.setState({ modalIsOpen: false });
  };

  handleConfirmModal = () => {
    /**
     * Hard refresh on confirm
     */
    window.location.reload(true);
  };

  checkCommitHash = () => {
    /**
     * Fetch commit hash of current staging build (only available in
     * production), compare against local hash, and open modal if unequal
     */
    fetchCommitHash().then((stagingHash: string | null | undefined): void => {
      if (notEqual(stagingHash, this.localHash)) {
        this.setState({ modalIsOpen: true });
      }
    });
  };

  render() {
    const modalHeader = <div>Site update</div>;
    const modalMessage = (
      <div>
        <p>
          We&apos;ve updated our site. Please refresh your browser for optimal
          performance.
        </p>
      </div>
    );
    return (
      <ConfirmModal
        confirmButtonText="Refresh"
        header={modalHeader}
        isOpen={this.state.modalIsOpen}
        message={modalMessage}
        onConfirm={this.handleConfirmModal}
        onReject={this.handleRejectModal}
      />
    );
  }
}

export default VersionCop;
