import { deepFreeze } from 'utils';

const NonPersistedKey = 'nonpersisted';
const AdditionalSbaPppBorrowerInformationKey =
  'additionalSbaPppBorrowerInformation';
const AuditTrailKey = 'auditTrail';
const AuditTrailFiltersKey = 'auditTrailFilters';
const AlertsKey = 'alerts';
const CancelLoanKey = 'cancelLoan';
const CalendarKey = 'calendar';
const CalendarViewStartEndKey = 'calendarViewStartEnd';
const DataroomKey = 'dataroom';
const DataroomRowKey = 'dataroomrow';
const DealKey = 'deal';
const DealClosingWorkflowKey = 'dealClosingWorkflow';
const DealContextKey = 'dealContext';
const DealPeopleKey = 'people';
const DisabledFormsKey = 'disabledForms';
const DocumentKey = 'document';
const EventDueKey = 'eventDue';
const FeesKey = 'fees';
const FeeRangesKey = 'feeRanges';
const FolderKey = 'folder';
const GeneralNoticeKey = 'generalNotice';
const InstitutionInviteKey = 'institutionInvite';
const LoanFundingKey = 'loanFunding';
const OrderBookKey = 'orderBook';
const InstitutionKey = 'institution';
const LenderCustomKey = 'lenderCustom';
const LenderRegistryKey = 'lenderRegistry';
const TaskKey = 'task';
const MenuKey = 'menu';
const SbaSecondDrawOnboardingKey = 'SbaSecondDrawOnboarding';

const LoanTrancheKey = 'loanTranche';
const MainKey = 'main';
const MinimizedKey = 'minimized';
const OpenFoldersKey = 'openFolders';
const OpenKey = 'open';
const PanelsKey = 'panels';
const PersistedKey = 'persisted';
const RegistrationKey = 'registration';
const TermSheetKey = 'termsheet';
const DealPDFViewerKey = 'dealpdfviewer';
const UploadingKey = 'uploading';
const UserKey = 'user';
const UserProfileKey = 'userProfile';

const SbaPppLoanApplicationKey = 'sbaPppLoanApplication';
const SbaPppLenderClosingWorkflowQuestionsKey =
  'sbaPppLenderClosingWorkflowQuestions';
const SbaPppRoundTwoLenderApplicationKey = 'sbaPppRoundTwoLenderApplication';
const SbaPppApplicationResubmitRequestKey = 'sbaPppApplicationResubmitRequest';
const SbaPppForgivenessCalculationKey = 'SbaPppForgivenessCalculation';
const SbaPppForgivenessLoanInformationKey = 'SbaPppForgivenessLoanInformation';
const SbaPppForgivenessLoanInformationDemographicsKey =
  'SbaPppForgivenessLoanInformationDemographics';
const SbaPppForgivenessResubmitRequestKey = 'SbaPppForgivenessResubmitRequest';
const SbaPppForgivenessDenialKey = 'SbaPppForgivenessDenial';
const SbaPppForgivenessEzFormQualificationsKey =
  'SbaPppForgivenessEzFormQualifications';
const PaymentRecordBorrowerKey = 'paymentRecordBorrower';
const PaymentRecordLenderKey = 'paymentRecordLender';
// Debug
const GenerateDealKey = 'generateDeal';

const Keys = {
  AdditionalSbaPppBorrowerInformationKey,
  AuditTrailKey,
  AuditTrailFiltersKey,
  AlertsKey,
  CancelLoanKey,
  CalendarKey,
  CalendarViewStartEndKey,
  DataroomKey,
  DataroomRowKey,
  DealClosingWorkflowKey,
  DealPDFViewerKey,
  DealKey,
  DealPeopleKey,
  DisabledFormsKey,
  DocumentKey,
  EventDueKey,
  FeeRangesKey,
  FeesKey,
  FolderKey,
  GeneralNoticeKey,
  GenerateDealKey,
  InstitutionKey,
  InstitutionInviteKey,
  LenderRegistryKey,
  LoanFundingKey,
  LoanTrancheKey,
  MainKey,
  MenuKey,
  MinimizedKey,
  NonPersistedKey,
  OpenFoldersKey,
  OpenKey,
  OrderBookKey,
  PaymentRecordBorrowerKey,
  PaymentRecordLenderKey,
  PersistedKey,
  RegistrationKey,
  TaskKey,
  TermSheetKey,
  UploadingKey,
  UserKey,
  UserProfileKey,
  SbaPppForgivenessCalculationKey,
  SbaPppForgivenessLoanInformationKey,
  SbaPppForgivenessLoanInformationDemographicsKey,
  SbaPppForgivenessResubmitRequestKey,
  SbaPppForgivenessDenialKey,
  SbaPppForgivenessEzFormQualificationsKey,
  SbaPppLoanApplicationKey,
  SbaPppLenderClosingWorkflowQuestionsKey,
  SbaPppApplicationResubmitRequestKey,
  SbaPppRoundTwoLenderApplicationKey,
  SbaSecondDrawOnboardingKey,
};

/**
 * Represents the possible keyPaths of the Redux object tree.
 * When referencing a slice of the Redux state, a property
 * of this object should be used as the keyPath.  This allows us
 * to avoid hardcoding string values throughout our application
 */
const Directory = {
  /* Keys */
  ...Keys,

  /**
   * Persistence Key
   */
  LocalStorageKey: 'LSPersist',

  /* Persisted Key Paths */
  AuditTrailKeyPath: [PersistedKey, AuditTrailKey],
  CalendarKeyPath: [PersistedKey, CalendarKey],
  DataroomKeyPath: [PersistedKey, DataroomKey],
  DataRoomOpenFoldersKeyPath: [PersistedKey, DataroomKey, OpenFoldersKey],
  DealContextKeyPath: [PersistedKey, DealContextKey],
  DealPeopleKeyPath: [PersistedKey, DealPeopleKey],
  DealPDFViewerKeyPath: [PersistedKey, DealPDFViewerKey],
  MainKeyPath: [PersistedKey, MainKey, OpenKey],
  PanelsKeyPath: [PersistedKey, PanelsKey, MinimizedKey],
  TermSheetPdfKeyPath: [PersistedKey, TermSheetKey, 'pdfProps'],
  MenuKeyPath: [PersistedKey, MenuKey, 'menuOpen'],
  MultiLenderViewKeyPath: [
    PersistedKey,
    DealContextKey,
    'enableMultiLenderView',
  ],

  /* NonPersisted Key Paths */
  AdditionalSbaPppBorrowerInformationPath: [
    NonPersistedKey,
    AdditionalSbaPppBorrowerInformationKey,
  ],
  AlertsKeyPath: [NonPersistedKey, AlertsKey, AlertsKey],
  CancelLoanKeyPath: [NonPersistedKey, CancelLoanKey],
  CalendarViewStartEndKeyPath: [NonPersistedKey, CalendarViewStartEndKey],
  AuditTrailFiltersKeyPath: [NonPersistedKey, AuditTrailFiltersKey],
  DataroomRowKeyPath: [NonPersistedKey, DataroomRowKey],
  DealClosingWorkflowKeyPath: [NonPersistedKey, DealClosingWorkflowKey],
  DealFormKeyPath: [NonPersistedKey, DealKey],
  DisabledFormsKeyPath: [NonPersistedKey, DisabledFormsKey],
  DocumentFormKeyPath: [NonPersistedKey, DocumentKey],
  EventDuePath: [NonPersistedKey, EventDueKey],
  FeesFormKeyPath: [NonPersistedKey, FeesKey],
  FolderFormKeyPath: [NonPersistedKey, FolderKey],
  GeneralNoticePath: [NonPersistedKey, GeneralNoticeKey],
  InstitutionFormKeyPath: [NonPersistedKey, InstitutionKey],
  InstitutionInviteKeyPath: [NonPersistedKey, InstitutionInviteKey],
  LenderCustomKeyPath: [NonPersistedKey, LenderCustomKey],
  LenderRegistryFormKeyPath: [NonPersistedKey, LenderRegistryKey],
  LoanFundingKeyPath: [NonPersistedKey, LoanFundingKey],
  LoanTrancheFormKeyPath: [NonPersistedKey, LoanTrancheKey],
  OrderBookKeyPath: [NonPersistedKey, OrderBookKey],
  PaymentRecordBorrowerKeyPath: [NonPersistedKey, PaymentRecordBorrowerKey],
  PaymentRecordLenderKeyPath: [NonPersistedKey, PaymentRecordLenderKey],
  RegistrationFormKeyPath: [NonPersistedKey, RegistrationKey],
  TaskFormKeyPath: [NonPersistedKey, TaskKey],
  UploadingKeyPath: [NonPersistedKey, UploadingKey],
  UploadingTermSheetKeyPath: [NonPersistedKey, UploadingKey, TermSheetKey],
  UserKeyPath: [NonPersistedKey, UserKey],
  UserProfileFormKeyPath: [NonPersistedKey, UserProfileKey],
  SbaPppLoanApplicationKeyPath: [NonPersistedKey, SbaPppLoanApplicationKey],
  SbaPppLenderClosingWorkflowQuestionsKeyPath: [
    NonPersistedKey,
    SbaPppLenderClosingWorkflowQuestionsKey,
  ],
  SbaPppRoundTwoLenderApplicationKeyPath: [
    NonPersistedKey,
    SbaPppRoundTwoLenderApplicationKey,
  ],
  SbaPppApplicationResubmitRequestKeyPath: [
    NonPersistedKey,
    SbaPppApplicationResubmitRequestKey,
  ],
  SbaPppForgivenessCalculationKeyPath: [
    NonPersistedKey,
    SbaPppForgivenessCalculationKey,
  ],
  SbaPppForgivenessLoanInformationKeyPath: [
    NonPersistedKey,
    SbaPppForgivenessLoanInformationKey,
  ],
  SbaPppForgivenessLoanInformationDemographicsKeyPath: [
    NonPersistedKey,
    SbaPppForgivenessLoanInformationDemographicsKey,
  ],
  SbaSecondDrawOnboardingKeyPath: [NonPersistedKey, SbaSecondDrawOnboardingKey],
  SbaPppForgivenessResubmitRequestKeyPath: [
    NonPersistedKey,
    SbaPppForgivenessResubmitRequestKey,
  ],
  SbaPppForgivenessDenialKeyPath: [NonPersistedKey, SbaPppForgivenessDenialKey],

  SbaPppForgivenessEzFormQualificationsKeyPath: [
    NonPersistedKey,
    SbaPppForgivenessEzFormQualificationsKey,
  ],
  /* Debug */
  GenerateDealKeyPath: [NonPersistedKey, GenerateDealKey],
};

deepFreeze(Keys);
deepFreeze(Directory);

export type ReduxKeys = string;
export type ReduxDirectory = keyof typeof Directory;

export default Directory;
