import * as React from 'react';

import { Button, Form } from 'components';
import { getTypeNameString } from 'utils';

type Props = {
  institutionOptions: Array<any>;
  onAddInstitute: (instituteId: string, instituteType: string) => void;
  userInstitution: { id: string; typeName: string };
};

type State = {
  selectedValue: string | null | undefined;
};

class ShareModalContent extends React.PureComponent<Props, State> {
  state = {
    selectedValue: null,
  };

  handleAddButton = () => {
    const { selectedValue } = this.state;
    const typeId = selectedValue ? selectedValue.split('_') : ['0', ''];
    const id = typeId[0];
    const type = typeId[1];
    this.props.onAddInstitute(id, type);

    this.setState({
      selectedValue: null,
    });
  };

  handleOnSelectionChange = (value: string | null | undefined) => {
    this.setState({
      selectedValue: value,
    });
  };

  render() {
    const { selectedValue } = this.state;
    const { institutionOptions, userInstitution } = this.props;
    const options = (institutionOptions || [])
      .filter(option =>
        option.institution
          ? option.institution.id !== userInstitution.id ||
            getTypeNameString(option.institution.__typename).replace(
              'Name',
              '',
            ) !==
              getTypeNameString(userInstitution.typeName as any).replace(
                'Name',
                '',
              )
          : true,
      )
      .map(option => ({
        text: option.institution ? option.institution.name : '-',
        value: option.institution
          ? `${option.institution.id}_${option.institution.__typename}`
          : '',
      }));

    return (
      <div className="shareModalContent">
        <Form.Select
          className="shareModalContent--input"
          id=""
          onChange={this.handleOnSelectionChange}
          options={options}
          placeholder="Type Institution to add"
          propertyName=""
          value={selectedValue}
        />
        <Button
          className="shareModalContent--addButton"
          disabled={!selectedValue}
          label="Add"
          onClick={this.handleAddButton}
        />
      </div>
    );
  }
}

export default ShareModalContent;
