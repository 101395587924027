import * as React from 'react';
import {
  getSuffixFromPaymentFrequency,
  parseDayCountText,
} from '../sections/utils';
import CreateAmortizationForm, {
  GenericSolveForRowProps,
  GenericAmortizationFormProps,
  Args,
} from './createLoanTrancheAmortizationForm/CreateLoanTrancheAmortizationForm';

import PaymentRow from './createLoanTrancheAmortizationForm/PaymentRow';
import SolveForRow from './solveForButtons/SolveFor';
import {
  AmortizationColumns,
  TrancheFieldRenderer,
  AmortFieldRenderer,
  validAmortizationTypes,
} from './shared';
import { FormFieldsType } from 'types';
import { Form, TypeQueryResult } from 'components';

const FieldError = ' ';

/* eslint-disable react/no-multi-comp */

class FixedPaymentSolveForRow extends React.Component<GenericSolveForRowProps> {
  handleSolveForClick = (value: string) => {
    this.props.handleSolveFor(value);
  };

  render() {
    return (
      <>
        <SolveForRow>
          <SolveForRow.Item>Solve For:</SolveForRow.Item>
        </SolveForRow>
        <SolveForRow>
          <SolveForRow.Button
            onChange={this.handleSolveForClick}
            text="Fixed Payment Amount"
            value="fixed_payment_amount"
          />
          <SolveForRow.Button
            onChange={this.handleSolveForClick}
            text="Fixed Interest Rate"
            value="fixed_interest_rate"
          />
          <SolveForRow.Button
            onChange={this.handleSolveForClick}
            text="Final Payment/Balloon"
            value="balloon_payment"
          />
          <SolveForRow.Button
            onChange={this.handleSolveForClick}
            text="Original Balance"
            value="initial_draw_amount"
          />
        </SolveForRow>
      </>
    );
  }
}

class FixedPaymentForm extends React.Component<GenericAmortizationFormProps> {
  fields: FormFieldsType = {};

  constructor(props: GenericAmortizationFormProps) {
    super(props);

    this.fields = {
      originalBalance: {
        id: 'initialDrawAmount',
        propertyName: 'initialDrawAmount',
        fieldName: 'Original Balance',
        onChange: this.props.mutateProperty,
        width: 'four',
      },
      originalTerm: {
        id: 'originalTerm',
        propertyName: 'originalTerm',
        fieldName: 'Original Term',
        onChange: this.props.mutateProperty,
        width: 'two',
        suffix: 'years',
      },

      balloonPayment: {
        id: 'balloonPayment',
        propertyName: 'balloonPayment',
        fieldName: 'Final Payment/Balloon',
        onChange: this.props.mutateAmortization,
        width: 'three',
      },
      amortizationType: {
        id: 'amortizationType',
        propertyName: 'amortizationType',
        fieldName: 'Amortization Type',
        onChange: this.props.mutateAmortization,
        typeName: 'LoanTrancheAmortizationAmortizationType',
        width: 'four',
        resultFilter: (
          res: Array<TypeQueryResult>,
          // @ts-ignore
        ) => res.filter(e => validAmortizationTypes.has(e.value)),
      },
      paymentFrequency: {
        id: 'paymentFrequency',
        propertyName: 'paymentFrequency',
        fieldName: 'Payment Frequency',
        onChange: this.props.mutateProperty,
        typeName: 'LoanTranchePaymentFrequency',
        width: 'four',
      },
      interestOnlyPeriods: {
        id: 'interestOnlyPeriods',
        propertyName: 'interestOnlyPeriods',
        fieldName: 'Interest Only Periods',
        onChange: this.props.mutateAmortization,
        width: 'three',
      },
      firstPrincipalPayment: {
        id: 'initialAmount',
        propertyName: 'initialAmount',
        fieldName: 'First Principal Pmt',
        onChange: this.props.mutateAmortization,
        width: 'three',
      },

      fixedInterestRate: {
        id: 'fixedInterestRate',
        propertyName: 'fixedInterestRate',
        fieldName: 'Fixed Interest Rate',
        onChange: this.props.mutateProperty,
        width: 'three',
        tabIndex: this.props.tabIndex,
      },
      dayCountConvention: {
        id: 'dayCountConvention',
        propertyName: 'dayCountConvention',
        fieldName: 'Day Count',
        onChange: this.props.mutateProperty,
        typeName: 'LoanTrancheDayCountConvention',
        width: 'four',
      },
      fixedPayment: {
        id: 'fixedPaymentAmount',
        propertyName: 'fixedPaymentAmount',
        fieldName: 'Fixed Payment',
        onChange: this.props.mutateProperty,
        width: 'four',
      },
      term: {
        id: 'term',
        propertyName: 'term',
        fieldName: 'Amortization Term',
        onChange: this.props.mutateAmortization,
        width: 'two',
      },
    };
  }

  render() {
    const { amortization, data } = this.props;
    const amortProps = { ...this.props, data: amortization };
    const queryError = Boolean(
      amortization.readerData && (amortization.readerData as any).hasErrors,
    );

    const { readerData } = amortization;

    return (
      <>
        <Form.Group unstackable>
          {TrancheFieldRenderer(
            Form.Money,
            this.fields.originalBalance,
            this.props,
            amortization.id,
            readerData,
          )}
          {TrancheFieldRenderer(
            Form.Percentage,
            { ...this.fields.fixedInterestRate, width: 'four' },
            this.props,
            amortization.id,
            readerData,
          )}
          {TrancheFieldRenderer(
            Form.ReferenceSelect,
            {
              ...this.fields.dayCountConvention,
              width: 'four',
              resultFilter: (queryResults: Array<TypeQueryResult>) =>
                queryResults.map(parseDayCountText),
            },
            this.props,
            amortization.id,
            readerData,
          )}
          {Form.FieldRenderer(
            Form.Money,
            { ...this.fields.fixedPayment, width: 'four' },
            this.props,
            amortization.id,
            readerData,
          )}
        </Form.Group>
        <Form.Group unstackable>
          {TrancheFieldRenderer(
            Form.Decimal,
            this.fields.originalTerm,
            this.props,
            amortization.id,
            readerData,
          )}
          {AmortFieldRenderer(
            Form.Decimal,
            { ...this.fields.term, fieldName: 'Amort. Term' },
            amortProps,
            amortization.id,
            readerData,
          )}
          {TrancheFieldRenderer(
            Form.ReferenceSelect,
            { ...this.fields.paymentFrequency, width: 'four' },
            this.props,
            amortization.id,
            readerData,
          )}
          {AmortFieldRenderer(
            Form.Numeric,
            {
              ...(!queryError
                ? {
                    ...this.fields.interestOnlyPeriods,
                    suffix: getSuffixFromPaymentFrequency(data),
                  }
                : {
                    ...this.fields.interestOnlyPeriods,
                    suffix: getSuffixFromPaymentFrequency(data),
                    error: FieldError,
                  }),
              width: 'four',
            },
            amortProps,
            amortization.id,
            readerData,
          )}
          {AmortFieldRenderer(
            Form.Money,
            {
              ...(!queryError
                ? this.fields.balloonPayment
                : {
                    ...this.fields.balloonPayment,
                    error: FieldError,
                  }),
              width: 'four',
            },
            amortProps,
            amortization.id,
            readerData,
          )}
        </Form.Group>
      </>
    );
  }
}

const args: Args = {
  columns: [
    AmortizationColumns.count,
    AmortizationColumns.date,
    AmortizationColumns.beginningBalance,
    AmortizationColumns.interestAmount,
    AmortizationColumns.principalPayment,
    AmortizationColumns.endingBalance,
    AmortizationColumns.errorColumn,
  ],
  paymentRowComponent: PaymentRow as any,
  formComponent: FixedPaymentForm,
  solveForRowComponent: FixedPaymentSolveForRow,
};

const FixedPaymentAmortizationModalForm = CreateAmortizationForm(args);

export default FixedPaymentAmortizationModalForm;
