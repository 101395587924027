import * as React from 'react';
import { Text, Tooltip } from 'components';

import './WorkflowItem.scss';

type WorkflowItemProps = {
  label: string;
  children: React.ReactNode;
  boldLabel?: boolean;
  tooltip?: string;
};

function WorkflowItem(props: WorkflowItemProps) {
  const { label, boldLabel = true, tooltip, children: action } = props;
  return (
    <div className="WorkflowItem">
      <div className="WorkflowItem__Label">
        <Text className={boldLabel ? 'bold' : null} value={label} />
        {tooltip && (
          <Tooltip>
            <Text className="steel" value={tooltip} />
          </Tooltip>
        )}
      </div>
      {action}
    </div>
  );
}

export default WorkflowItem;
