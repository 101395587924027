import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  ConnectDragSource,
  ConnectDropTarget,
  ConnectDragPreview,
} from 'react-dnd';
import PropTypes from 'prop-types';
import { ID } from '../../../types/primitives';
import { DataRoomBase, DataroomContext } from '../types';
import DataroomRowMenu from './dataroomRowMenu/DataroomRowMenu';
import { ReduxDirectory } from 'lsredux';
import { ux, isWholeNumber, hoverMethods } from 'utils';
import { DisabledChildProps } from 'security';

import './DataroomRow.scss';

export const computeIndent = (level: number) => `${level * 26 + 5}px`;

export type DataRoomRowRenderProps<T extends DataRoomBase> = {
  entity: T;
};

export type MenuRenderProps<T extends DataRoomBase> = DataRoomRowRenderProps<
  T
> & {
  onBeginEdit: () => void;
  renderItem: (
    name: string,
    onClick?: () => void,
    href?: string,
  ) => React.ReactNode;
};

export type EditRenderProps<T extends DataRoomBase> = DataRoomRowRenderProps<
  T
> & { onEndEdit: () => void };

export type DataRoomRowProps<T extends DataRoomBase> = {
  canDrop: boolean;
  canDropInRootFolder: boolean;
  connectDragPreview: ConnectDragPreview;
  connectDragSource?: ConnectDragSource;
  connectDropTarget?: ConnectDropTarget;
  entity: T;
  isDragging: boolean;
  isDropAreaBot: boolean;
  isDropAreaTop: boolean;
  isEven: boolean;
  isInDropArea: boolean;
  isOver: boolean;
  level: number;
  listStyle: any;
  onEnterDragHover: (folderId: ID) => void;
  onOpenFolder?: () => void;
  renderAccessCell: (
    entity: DataRoomRowRenderProps<T>,
    isHovered: boolean,
  ) => React.ReactNode;
  renderEditCell: (entity: EditRenderProps<T>) => React.ReactNode;
  renderMenu: (arg0: any) => React.ReactNode;
  renderNameCell: (arg0: DataRoomRowRenderProps<T>) => React.ReactNode;
} & DisabledChildProps;

type State = {
  isEditing: boolean;
  isHovered: boolean;
  style: {};
};

const contextPropType: any = {
  data: PropTypes.object,
  onRowDragEnter: PropTypes.func,
  onRowDragLeave: PropTypes.func,
};

class DataRoomRow<T extends DataRoomBase> extends React.PureComponent<
  DataRoomRowProps<T>,
  State
> {
  static stRenderModified(entity: any, isUploading = false) {
    return (
      <div className="dataroomRow__ModifiedCell">
        {!isUploading && (entity.dateString || 'Unknown')}
      </div>
    );
  }

  static contextTypes = contextPropType;

  static defaultProps = {
    isOver: false,
    canDrop: false,
    accessDenied: false,
  };

  _isMounted: boolean;

  handleHoverLeave: () => void;

  handleMouseEnter: () => void;

  handleMouseLeave: () => void;

  handleMouseMove: (e: React.MouseEvent<any>) => void;

  /**
   * Required by hover methods
   */
  rowElement: HTMLDivElement | null | undefined;

  constructor(props: DataRoomRowProps<T>) {
    super(props);
    this.state = {
      isEditing: false,
      isHovered: false,
      style: { paddingLeft: computeIndent(props.level) },
    };
    this._isMounted = false;
    this.handleHoverLeave = hoverMethods.handleHoverLeave.bind(this);
    this.handleMouseEnter = hoverMethods.handleMouseEnter.bind(this);
    this.handleMouseLeave = hoverMethods.handleMouseLeave.bind(this);
    this.handleMouseMove = hoverMethods.handleMouseMove.bind(this);
  }

  /* Events */
  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps: DataRoomRowProps<T>) {
    const { entity, onEnterDragHover } = this.props;

    if (this.props.level !== prevProps.level) {
      /* eslint-disable */
      this.setState({
        style: { paddingLeft: computeIndent(this.props.level) },
      });
      /* eslint-enable */
    }

    if (this.props.isOver && !prevProps.isOver) {
      let rootId;
      if (entity.__typename === 'FolderType') {
        rootId = entity.id;
      } else if (entity.parent) {
        rootId = entity.parent.id;
      } else {
        rootId = '';
      }
      onEnterDragHover(rootId);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getContext = () => this.context as DataroomContext;

  handleBeginEdit = () => {
    this.setState({ isEditing: true });
  };

  handleEndEdit = () => {
    if (this._isMounted) {
      this.setState({ isEditing: false });
    }
  };

  renderRow() {
    const {
      accessDenied,
      onOpenFolder,
      entity,
      isDropAreaBot,
      isDropAreaTop,
      isOver,
      isEven,
      canDrop,
      level,
      connectDragPreview,
      isDragging,
      isInDropArea,
      renderAccessCell,
      renderEditCell,
      renderNameCell,
      renderMenu,
      listStyle,
      canDropInRootFolder,
    } = this.props;

    const { isEditing, isHovered, style } = this.state;

    const isUploading = !isWholeNumber(entity.id);
    const shouldRenderHovered = isHovered && !(isOver && canDrop) && !isEditing;

    const rowClassName = cx(
      'dataroomRow',
      ux(isDragging, 'dataroomRow--Dragging'),
      ux(isEven, 'dataroomRow--Even'), // for rows that are in a drop area (open folder rows)
      ux(isInDropArea, 'dataroomRow__DropArea'),
      ux(
        isInDropArea && (!canDropInRootFolder || accessDenied),
        'dataroomRow__DropArea--Disabled',
      ),
      ux(isDropAreaTop, 'dataroomRow__DropArea--First'),
      ux(isDropAreaBot, 'dataroomRow__DropArea--Last'), // for rows that are not in a drop area (closed folder rows)
      ux(isOver && !isInDropArea, 'dataroomRow--IsOver'),
      ux(
        (accessDenied || !canDrop) && isOver && !isInDropArea,
        'dataroomRow--DenyDrop',
      ),
    );

    const childProps: DataRoomRowRenderProps<T> = { entity };
    return (
      <div
        className={rowClassName}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={listStyle}
      >
        <div
          className={isDragging ? '' : `dataroomRow__NameCell`}
          onClick={onOpenFolder || undefined}
          style={style}
        >
          {connectDragPreview(
            <span className="dataroomRow__DragPreview">
              {isEditing
                ? renderEditCell({
                    ...childProps,
                    onEndEdit: this.handleEndEdit,
                  })
                : renderNameCell(childProps)}
            </span>,
          )}
        </div>
        {DataRoomRow.stRenderModified(childProps.entity, isUploading)}
        <div className="dataroomRow__AccessCell">
          {renderAccessCell(childProps, shouldRenderHovered)}
          <DataroomRowMenu shouldRenderMenu={!isUploading}>
            {renderMenu({
              ...childProps,
              level,
              renderItem: DataroomRowMenu.renderItem,
              onBeginEdit: this.handleBeginEdit,
            })}
          </DataroomRowMenu>
        </div>
      </div>
    );
  }

  render() {
    const { connectDragSource, connectDropTarget } = this.props;
    if (connectDropTarget && connectDragSource) {
      return connectDropTarget(connectDragSource(this.renderRow()));
    }
    if (connectDragSource) {
      return connectDragSource(this.renderRow());
    }
    return this.renderRow();
  }
}

const mapStateToProps = state => ({
  canDropInRootFolder: state.getIn(ReduxDirectory.DataroomRowKeyPath)
    .canDropInRootFolder,
});

export default connect(mapStateToProps)(DataRoomRow);
