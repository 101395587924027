import * as React from 'react';
import DebugSection from './DebugSection';
import { Button, Checkbox, Header } from 'components';
import {
  isDebug,
  toggleDebug,
  isPermissionDebug,
  togglePermissionDebug,
} from 'utils';
import { debugOptions } from 'resources';
import './Toggle.scss';

class Toggle extends React.Component<{}> {
  handleClick = () => {
    toggleDebug();
    this.forceUpdate();
  };

  handlePermissionClick = () => {
    togglePermissionDebug();
    this.forceUpdate();
  };

  handleToggleErrors = () => {
    debugOptions.formsFieldsAlwaysError = !debugOptions.formsFieldsAlwaysError;
    this.forceUpdate();
  };

  renderStatus = (flag: boolean) => {
    const green = { color: 'green' };
    const warningRed = { color: 'warningRed' };

    return (
      <span style={flag ? warningRed : green}>
        {flag ? 'ENABLED' : 'DISABLED'}
      </span>
    );
  };

  render() {
    return (
      <>
        <DebugSection>
          <div>
            Debug Mode is
            {this.renderStatus(isDebug())}
          </div>
          <div>
            <p>General Debug mode.</p>
            <ul>
              <li>Output verbose info to console</li>
              <li>Stricter invariant checks</li>
              <li>mutationsDispatch operation logging</li>
              <li>Form Field parentObject.propertyName popups</li>
            </ul>
          </div>
          <div>
            <Button label="Toggle Debug Mode" onClick={this.handleClick} />
          </div>
        </DebugSection>
        <DebugSection>
          <Header as="h3">Additional Debug Options</Header>
          <div>
            <div className="Debug-Toggle-Option">
              <Checkbox
                checked={isPermissionDebug()}
                id="TogglePermissionDebug"
                label="Highlight Authorized Components"
                onChange={this.handlePermissionClick}
              />
              <p>
                Will cause Authorization related components to render
                information regarding permitted/denied actions. Note that this
                mode can break styles and layout
              </p>
            </div>
            <div className="Debug-Toggle-Option">
              <Checkbox
                checked={debugOptions.formsFieldsAlwaysError}
                id="FormFieldsAlwaysError"
                label="formsFieldsAlwaysError"
                onChange={this.handleToggleErrors}
              />
              <p>
                When enabled all Form Fields will always display in an error
                state. If a field has a valid error, that error will be
                displayed, otherwise a standard debug error message is displayed
              </p>
            </div>
          </div>
        </DebugSection>
      </>
    );
  }
}

export default Toggle;
