import * as React from 'react';
import uuid from 'uuid/v4';
import { AmortizationColumns } from '../shared';
import { DisplayTable, MoneyInput, Money, Icon } from 'components';
import { LoanTrancheAmortizationPrincipalPaymentType } from 'types';
import { haltBubble, formatMoney } from 'utils';

const EmptyPayment = {
  id: uuid(),
  count: '-',
  principalBefore: '-',
  principalAfter: '-',
  principalAmount: '-',
  __typename: 'LoanTrancheAmortizationPrincipalPaymentType',
};

export type PaymentRowProps = {
  className?: string;
  disabled?: boolean;
  errored?: boolean;
  index: number;
  onChange: (
    value: string | null | undefined,
    fieldId: string,
    index: number,
  ) => void;
  payment: LoanTrancheAmortizationPrincipalPaymentType;
  principalAfter?: string | null | undefined;
  principalBefore?: string | null | undefined;
};

const DecimalMoney = (props: { value?: string | null | undefined }) => {
  if (!props.value) return <>'-'</>;
  return <Money decimalPlaces={2} value={props.value} />;
};

class PaymentRow extends React.Component<PaymentRowProps> {
  static stRenderMoneyCell = (
    value: string | null | undefined,
  ): React.ReactNode => {
    const hideValue = !value || value === '-';
    return (
      <div className="AmortizationTable__MoneyCell">
        {hideValue ? '-' : <DecimalMoney value={value} />}
      </div>
    );
  };

  static defaultProps = {
    errored: false,
    // eslint-disable-next-line react/default-props-match-prop-types
    payment: EmptyPayment,
  };

  shouldComponentUpdate(nextProps: PaymentRowProps) {
    return (
      this.props.index !== nextProps.index ||
      this.props.payment !== nextProps.payment ||
      this.props.errored !== nextProps.errored ||
      this.props.principalBefore !== nextProps.principalBefore ||
      this.props.principalAfter !== nextProps.principalAfter
    );
  }

  handleChange = (e: React.SyntheticEvent<any>) => {
    haltBubble(e);
    this.props.onChange(
      e.currentTarget.value,
      'principalAmount',
      this.props.index,
    );
  };

  render() {
    const {
      payment,
      className,
      errored,
      principalAfter,
      principalBefore,
      disabled,
    } = this.props;
    return (
      <DisplayTable.TR
        className={className}
        key={payment.count}
        title={`Total Payment: $${formatMoney(
          (+payment.interestAmount + +payment.principalAmount).toString(),
        )}`}
      >
        <DisplayTable.TD className={AmortizationColumns.count.className}>
          {payment.count}
        </DisplayTable.TD>
        <DisplayTable.TD className={AmortizationColumns.date.className}>
          {payment.date}
        </DisplayTable.TD>
        <DisplayTable.TD
          className={AmortizationColumns.beginningBalance.className}
        >
          {PaymentRow.stRenderMoneyCell(principalBefore)}
        </DisplayTable.TD>
        <DisplayTable.TD
          className={AmortizationColumns.interestAmount.className}
        >
          {PaymentRow.stRenderMoneyCell(payment.interestAmount)}
        </DisplayTable.TD>
        <DisplayTable.TD
          className={AmortizationColumns.principalPayment.className}
        >
          {disabled ||
          !payment.principalAmount ||
          payment.principalAmount === '-' ? (
            PaymentRow.stRenderMoneyCell(payment.principalAmount)
          ) : (
            <MoneyInput
              addDollarSymbol
              className="PaymentRow__PrincipalPaymentInput"
              disabled={disabled}
              id={`principalAmount_${payment.count || '-'}`}
              name="principalAmount"
              onChange={this.handleChange}
              value={payment.principalAmount}
            />
          )}
        </DisplayTable.TD>
        <DisplayTable.TD
          className={AmortizationColumns.endingBalance.className}
        >
          {PaymentRow.stRenderMoneyCell(principalAfter)}
        </DisplayTable.TD>
        <DisplayTable.TD className={AmortizationColumns.errorColumn.className}>
          {errored && <Icon icon="exclamation-triangle" />}
        </DisplayTable.TD>
      </DisplayTable.TR>
    );
  }
}

export default PaymentRow;
