import uuid from 'uuid/v4';

import { FolderMutationMethods } from '../../../graphql/mutations';
import Directory from '../../directory';

import { createForm } from '../../genericForms';
import { FolderInput } from 'types';

const initialFolder: FolderInput = {
  id: uuid(),
  name: null,
  description: '',
  userId: null,
  parentId: '',
  __typename: 'FolderType',
};

const lifeCycleMethods = {
  mutations: {
    ...FolderMutationMethods,
  },
  validators: {},
};

const folderReduxForm = createForm<FolderInput, FolderInput>(
  Directory.FolderKey,
  initialFolder,
  lifeCycleMethods,
);

export default folderReduxForm;
