import { isImmutable } from 'immutable';
import { typeNameField } from './constants';
import { isDebug, invariant } from 'utils';
import { BaseType } from 'types';

const empty = '';
const MissingTypeNameError = 'Entity lacks a __typename property';
const ImmutableError = 'Expected non-Immutable objects';
const MissingIdError =
  'Entity Ids must have a value, if you are creating a new entity, generate one with uuid';

/* eslint-disable import/prefer-default-export */

/**
 * Validate expected state of an object pending immutable conversion
 * - entity is not an immutable object
 * - object contains a valid __typename property from GraphQL
 * @param {*} entity
 */
export function entityValidator<TOut extends BaseType>(entity: TOut) {
  if (!isDebug()) return;
  invariant(!isImmutable(entity), ImmutableError);

  if (!entity[typeNameField] || (entity[typeNameField] as any) === empty) {
    const errorMessage = `${MissingTypeNameError}: ${JSON.stringify(entity)}`;
    throw new Error(errorMessage);
  }

  if (!entity.id || entity.id === empty) {
    throw new Error(MissingIdError);
  }
}

/**
 * Validate the expected state of a collection of non-Immutable objects
 * @param {*} entities
 */
export function entitiesValidator<TOut extends BaseType>(
  entities: Array<TOut>,
) {
  if (!isDebug()) return;

  entities.forEach(e => entityValidator(e));
}
