import * as React from 'react';
import uuid from 'uuid/v4';
import ActiveWindowObserver from '../../ActiveWindowObserver';
import { invariant } from 'utils';

/**
 * PollingMediator is intended for use with our Apollo queryBuilders.
 * If the queryBuilder provides `startPolling{typename}` and
 * `stopPolling{typename}` methods, then they will be registered for
 * automatic handling by the ActiveWindowObserver
 * @param {*} typeName
 */
export default function PollingMediatorHoC(typeName: string) {
  return function PollingMediatorBuilder(Wrapped: React.ComponentType<any>) {
    const startPolling = `startPolling${typeName}`;
    const stopPolling = `stopPolling${typeName}`;

    return class PollingMediator extends React.Component<any> {
      registered: boolean;

      guid: string;

      constructor(props: any) {
        super(props);

        this.registered = false;
        this.guid = uuid();
      }

      componentDidMount() {
        this.register();
      }

      UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (nextProps[startPolling] !== this.props[startPolling]) {
          this.unregister();
          this.register();
        }
      }

      componentWillUnmount() {
        this.unregister();
      }

      register = () => {
        if (this.registered) return;

        invariant(
          this.props[startPolling],
          `Polling method ${startPolling} was not found on props`,
        );
        invariant(
          this.props[stopPolling],
          `Polling method ${stopPolling} was not found on props`,
        );
        this.registered = true;
        ActiveWindowObserver.Register({
          id: this.guid,
          visible: this.props[startPolling],
          hidden: this.props[stopPolling],
        });
      };

      unregister = () => {
        if (!this.registered) return;

        ActiveWindowObserver.Unregister(this.guid);
        this.registered = false;
      };

      render() {
        return <Wrapped {...this.props} />;
      }
    };
  };
}
