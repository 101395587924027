import memoize from 'lodash/memoize';
import camelCase from 'lodash/camelCase';
import invariant from './invariant';
import {
  BaseType,
  ObjectTypeNames,
  ObjectBaseTypeNames,
  ObjectCamelBaseTypeNames,
} from 'types';

export const typeNameField = '__typename';

const Type = 'Type';
const Input = 'Input';
const Create = 'Create';
const Stub = 'Stub';
const SetStr = 'Set';
const Empty = '';

/**
 * Remove suffixes from a typename
 * 'DealType' => 'Deal'
 * 'DealInput' => 'Deal'
 * @param {*} name
 */
export const getTypeNameString: (
  typename: ObjectTypeNames,
) => ObjectBaseTypeNames = memoize(
  (name: ObjectTypeNames) =>
    (name
      .replace(Type, Empty)
      .replace(Create, Empty)
      .replace(SetStr, Empty)
      .replace(Stub, Empty)
      .replace(Input, Empty) as any) as ObjectBaseTypeNames,
);

/**
 * Extract an object's typename and remove and suffixes
 * 'DealType' => 'Deal'
 * 'DealInput' => 'Deal'
 * @param {*} e
 */
export function getTypeName<T extends BaseType>(e: T): ObjectBaseTypeNames {
  if (!e[typeNameField]) {
    throw new Error();
  }

  return getTypeNameString(e[typeNameField]);
}

/**
 * Return the camel cased, suffix-free, typename
 * 'LoanTrancheInput' => 'loanTranche';
 *
 * @param {*} name
 */
export const getCamelTypeNameString: (
  typename: ObjectTypeNames,
) => ObjectCamelBaseTypeNames = memoize(
  (name: ObjectTypeNames) =>
    (camelCase(getTypeNameString(name)) as any) as ObjectCamelBaseTypeNames,
);

/**
 * Return the camel cased, suffix-free, typename
 * 'LoanTrancheInput' => 'loanTranche';
 */
export function getCamelTypeName<T extends BaseType>(
  e: T,
): ObjectCamelBaseTypeNames {
  return getCamelTypeNameString(e[typeNameField]);
}

/**
 * Check if an object has a valid `__typename` property
 * @param {*} e
 */
export function hasTypeName<T extends BaseType>(e: T): boolean {
  return !!e[typeNameField];
}

/**
 * Enforce that an object contains a valid __typename property.
 * If none is found, an exception is raised
 * @param {*} e
 * @param {*} props
 */
export function requireTypeName<T extends BaseType>(e: T): void {
  invariant(
    hasTypeName(e),
    `Object lacks a ${typeNameField} field, unable to process mutations`,
  );
}
