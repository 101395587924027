import * as React from 'react';
import cx from 'classnames';
import { IconButton } from 'components';
import { ux } from 'utils';
import { OrderBookProspect } from 'types';

type Props = {
  className?: string;
  icon: 'note' | 'add-note';
  onClick: (row: OrderBookProspect) => void;
  row: OrderBookProspect;
};

const stackedIconTransform = { size: 6 };

export default class NotesIconButton extends React.Component<Props> {
  static defaultProps = {
    className: '',
  };

  handleClick = () => {
    this.props.onClick(this.props.row);
  };

  render() {
    const { className, icon } = this.props;
    const addNote = icon === 'add-note';
    const computedClass = cx(
      'IconButton-Centered',
      ux(icon, 'stacked'),
      ux(className, className),
    );
    return (
      <IconButton
        alt="Propspect Notes"
        className={computedClass}
        color="secondaryYellow"
        icon="sticky-note"
        onClick={this.handleClick}
        stackedIcon={addNote ? 'plus' : null}
        stackedIconTransform={stackedIconTransform}
      />
    );
  }
}
