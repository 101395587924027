import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import {
  DealInstitutionShallowFields,
  DealUserShallowFields,
  RoleNameShallowFields,
} from '../fragments/generated';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { DealInstitutionType } from 'types';

const DealInstitutionsQueryDefinition: DocumentNode = gql`
  query DealOrganizationsQuery($dealId: ID!, $filters: [FilterKeyValue]) {
    dealInstitutions: dealOrganizations(dealId: $dealId, filters: $filters) {
      ... on DealInstitutionType {
        ...DealInstitutionShallowFields
        dealUsers(dealId: $dealId) {
          ...DealUserShallowFields
          dealRole {
            ...RoleNameShallowFields
          }
        }
        parent {
          id
          name
        }
        subsidiaries {
          id
          name
        }
      }
    }
  }
  ${RoleNameShallowFields}
  ${DealUserShallowFields}
  ${DealInstitutionShallowFields}
`;

const DealInstitutionsQuery = standardQueryBuilder(
  DealInstitutionsQueryDefinition,
  {
    typename: 'DealInstitutions',
    disableCache: true,
    skip: ({ dealId }: { dealId: string | null | undefined }) => !dealId,
    variables: ({ dealId }: { dealId: string | null | undefined }) => ({
      dealId,
    }),
    results: (data: {
      dealInstitutions: Array<DealInstitutionType> | null | undefined;
    }) => {
      const { dealInstitutions } = data;
      return { dealInstitutions };
    },
  },
);

export default DealInstitutionsQuery;
