import * as React from 'react';
import {
  LoanTrancheType,
  ID,
  ValidationMap,
  ObjectBaseTypeNames,
  LoanTrancheAmortizationAmortizationType,
} from 'types';
import { Form } from 'components';
import { invariant } from 'utils';

/* eslint-disable import/prefer-default-export */

export const undoKeyPath: keyof LoanTrancheType = 'loantrancheamortizationSet';

export const AmortizationColumns = {
  count: {
    name: 'Count',
    disableSort: true,
    className: 'AmortizationTable__CountColumn',
  },
  date: {
    name: 'Date',
    disableSort: true,
    className: 'AmortizationTable__DateColumn',
  },
  beginningBalance: {
    name: 'Beginning Balance',
    disableSort: true,
    className: 'AmortizationTable__BeginningBalanceColumn',
  },
  interestAmount: {
    name: 'Interest Payment',
    disableSort: true,
    className: 'AmortizationTable__InterestPaymentColumn',
  },
  principalPayment: {
    name: 'Principal Payment',
    disableSort: true,
    className: 'AmortizationTable__PrincipalPaymentColumn',
  },
  endingBalance: {
    name: 'Ending Balance',
    disableSort: true,
    className: 'AmortizationTable__EndingPrincipalColumn',
  },
  errorColumn: {
    name: '',
    disableSort: true,
    className: 'AmortizationTable__ErrorColumn',
  },
};

export const InterestScheduleColumns = {
  periods: {
    name: 'Periods',
    disableSort: true,
    className: 'AmortizationTable--InterestSchedule--Periods',
  },
  startDate: {
    name: 'Start Date',
    disableSort: true,
    className: 'AmortizationTable--InterestSchedule--StartDate',
  },
  interestRate: {
    name: 'Interest Rate',
    disableSort: true,
    className: 'AmortizationTable--InterestSchedule--InterestRate',
  },
  totalPayment: {
    name: 'Total Payment',
    disableSort: true,
    className: 'AmortizationTable--InterestSchedule--TotalPayment',
  },
};

export const validAmortizationTypes = new Set<
  LoanTrancheAmortizationAmortizationType
>([
  'EQUAL_AMORTIZATION',
  'LINEAR_AMORTIZATION',
  'FIXED_PRINCIPAL_AND_INTEREST',
]);

const fieldMap: any = {
  originalTerm: 'term',
  initialDrawAmount: 'initialDrawAmount',
  fixedInterestRate: 'fixedInterestRate',
  paymentFrequency: 'paymentFrequency',
  dayCountConvention: 'dayCountConvention',
  initialIndicativeBenchmark: 'initialIndicativeBenchmark',
  indicativeFixedRate: 'indicativeFixedRate',
  indicativeFixedSpread: 'indicativeFixedSpread',
  calculatedInterest: 'calculatedInterest',
};

function translateAmortizationErrorToTrancheError(
  propertyName: keyof LoanTrancheType,
  amortizationId: ID,
  errors: ValidationMap | null | undefined,
): string | null | undefined {
  invariant(
    fieldMap[propertyName],
    `Cannot determine associated amortization field for ${propertyName} field`,
  );

  if (!errors || !errors.LoanTrancheAmortization) return null;

  if (!errors.LoanTrancheAmortization[amortizationId]) {
    // eslint-disable-next-line
    console.warn(
      `Amortization error entries were found, but not for ${amortizationId}.  This is likely an error condition`,
    );
    return null;
  }

  const amortizationField = fieldMap[propertyName];

  return errors.LoanTrancheAmortization[amortizationId][amortizationField];
}

const loanTrancheAmortization: ObjectBaseTypeNames = 'LoanTrancheAmortization';
const loanTranche: ObjectBaseTypeNames = 'LoanTranche';

export function TrancheFieldRenderer(
  FieldComponent: React.ComponentType<any>,
  fieldProps: any,
  trancheProps: any,
  amortizationId: ID,
  readerData?: any,
) {
  let shouldHighlight = false;
  if (readerData && readerData.solvedFor === fieldProps.propertyName) {
    shouldHighlight = true;
  }

  return Form.FieldRenderer(
    FieldComponent,
    {
      ...fieldProps,
      error: translateAmortizationErrorToTrancheError(
        fieldProps.propertyName,
        amortizationId,
        trancheProps.errors,
      ),
      highlight: shouldHighlight,
    },
    trancheProps,
    loanTranche,
    trancheProps.data.id,
  );
}

export function AmortFieldRenderer(
  FieldComponent: React.ComponentType<any>,
  fieldProps: any,
  amortProps: any,
  amortizationId: ID,
  readerData: any,
) {
  let shouldHighlight = false;

  if (readerData && readerData.solvedFor === fieldProps.propertyName) {
    shouldHighlight = true;
  }

  return Form.FieldRenderer(
    FieldComponent,
    {
      ...fieldProps,
      highlight: shouldHighlight,
    },
    amortProps,
    loanTrancheAmortization,
    amortizationId,
  );
}
