import * as React from 'react';
import noop from 'lodash/noop';
import { WrappedMediatorFormProps, Form } from 'components';
import { DealType } from 'types';
import { getDealCategory } from 'utils';

type DealFormProps = WrappedMediatorFormProps<DealType> & {
  rawMutateProperty: (
    value: string | boolean | Array<any> | null | undefined,
    fieldId: string | Array<string>,
  ) => void;
};

const width = '158px';
const maxWidth = '332px';

const disabledProps = {
  disabled: true,
  disabledReadable: true,
  onChange: noop,
};

const fields = {
  sbaPppLoanNumber: {
    id: 'sbaPppLoanNumber',
    propertyName: 'sbaPppLoanNumber',
    fieldName: 'SBA PPP Loan Number',
    ...disabledProps,
    required: true,
    width,
  },
  lenderPppLoanNumber: {
    id: 'lenderPppLoanNumber',
    propertyName: 'lenderPppLoanNumber',
    fieldName: 'Lender PPP Loan Number',
    ...disabledProps,
    width,
  },
  pppLoanAmount: {
    id: 'pppLoanAmount',
    propertyName: 'pppLoanAmount',
    fieldName: 'PPP Loan Amount',
    ...disabledProps,
    required: true,
    width,
  },
  pppLoanDisbursementDate: {
    id: 'pppLoanDisbursementDate',
    propertyName: 'pppLoanDisbursementDate',
    fieldName: 'PPP Loan Disbursement',
    ...disabledProps,
    required: true,
    width,
  },
  employeesAtTimeOfLoanApplication: {
    id: 'employeesAtTimeOfLoanApplication',
    propertyName: 'employeesAtTimeOfLoanApplication',
    fieldName: 'Employees at Time of Loan Application',
    required: true,
    width: maxWidth,
  },
  employeesAtTimeOfForgivenessApplication: {
    id: 'employeesAtTimeOfForgivenessApplication',
    propertyName: 'employeesAtTimeOfForgivenessApplication',
    fieldName: 'Employees at Time of Forgiveness Application',
    required: true,
    width: maxWidth,
  },
  amountOfLoanSpentOnPayroll: {
    id: 'amountOfLoanSpentOnPayroll',
    propertyName: 'amountOfLoanSpentOnPayroll',
    fieldName: 'Add Amount of Loan Spent on Payroll Costs',
    required: true,
    width: maxWidth,
  },
  borrowerPppLoansExceedThreshold: {
    id: 'borrowerPppLoansExceedThreshold',
    propertyName: 'borrowerPppLoansExceedThreshold',
    label:
      'Borrower (together with affiliates) has received First Draw PPP loans or Second Draw PPP loans in excess of $2 million',
    required: true,
  },
};

const TypeName = 'SbaPppForgivenessLoanInformation';

function LoanInformationSection(props: DealFormProps) {
  const { data } = props;
  const {
    sbaPppForgivenessWorkflow,
    sbaPppLoanApplication,
    dealClosingWorkflow,
  } = data;

  if (
    !sbaPppForgivenessWorkflow ||
    !sbaPppLoanApplication ||
    !dealClosingWorkflow
  ) {
    return null;
  }

  const { sbapppforgivenessloaninformation } = sbaPppForgivenessWorkflow;

  const updatedProps = {
    ...props,
    data: {
      ...sbapppforgivenessloaninformation,
      sbaPppLoanNumber: dealClosingWorkflow.loanfunding?.sbaLoanNumber,
      lenderPppLoanNumber: data.identity,
      employeesAtTimeOfLoanApplication:
        sbaPppLoanApplication?.sbappploanfinancials?.numberOfJobs,
      pppLoanAmount: dealClosingWorkflow.loanfunding?.loanAmount,
      pppLoanDisbursementDate: data.fundingDate,
    },
  };

  // For adjusting "SBA Loan #" or "PPP Loan Amount" fields via the `SbaHelpBox`
  function mutateLoanFundingProperty(value: any, fieldId: string) {
    props.rawMutateProperty(value, [
      'dealClosingWorkflow',
      'loanfunding',
      fieldId == 'sbaPppLoanNumber' ? 'sbaLoanNumber' : 'loanAmount',
    ]);
  }

  // For adjusting "Employees at time of Loan Application" field
  function mutatePppLoanFinancialsProperty(value: any, fieldId: string) {
    props.rawMutateProperty(value, [
      'sbaPppLoanApplication',
      'sbappploanfinancials',
      fieldId,
    ]);
  }

  return (
    <div>
      <Form.Group>
        {Form.FieldRenderer(
          Form.Numeric,
          {
            ...fields.sbaPppLoanNumber,
            // ...(sbaPppForgivenessWorkflow?.sbaForgivenessErrors?.includes(
            //   'SBA Number is not valid for forgiveness',
            // )
            //   ? { onChange: mutateLoanFundingProperty }
            //   : disabledProps),
            onChange: mutateLoanFundingProperty,
          },
          updatedProps,
          TypeName,
          updatedProps.data.id,
        )}
        {Form.FieldRenderer(
          Form.Input,
          { ...fields.lenderPppLoanNumber, onChange: noop },
          updatedProps,
          TypeName,
          updatedProps.data.id,
        )}
      </Form.Group>
      <Form.Input
        disabled
        disabledReadable
        fieldName="PPP Draw"
        id="pppDraw"
        onChange={noop}
        value={getDealCategory(data.category)}
        width={width}
      />
      <Form.Group>
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.pppLoanAmount,
            // ...(sbaPppForgivenessWorkflow?.sbaForgivenessErrors?.includes(
            //   "Loan amount does not match SBA's record",
            // )
            //   ? { onChange: mutateLoanFundingProperty }
            //   : disabledProps),
            onChange: noop,
          },
          updatedProps,
          TypeName,
          updatedProps.data.id,
        )}
        {Form.FieldRenderer(
          Form.Calendar,
          { ...fields.pppLoanDisbursementDate, onChange: noop },
          updatedProps,
          TypeName,
          updatedProps.data.id,
        )}
      </Form.Group>
      {Form.FieldRenderer(
        Form.Numeric,
        {
          ...fields.employeesAtTimeOfLoanApplication,
          onChange: mutatePppLoanFinancialsProperty,
        },
        updatedProps,
        TypeName,
        updatedProps.data.id,
      )}
      {Form.FieldRenderer(
        Form.Numeric,
        {
          ...fields.employeesAtTimeOfForgivenessApplication,
          onChange: props.mutateProperty,
        },
        updatedProps,
        TypeName,
        updatedProps.data.id,
      )}
      {sbaPppForgivenessWorkflow.isSFormEligible &&
        Form.FieldRenderer(
          Form.Money,
          {
            ...fields.amountOfLoanSpentOnPayroll,
            onChange: props.mutateProperty,
          },
          updatedProps,
          TypeName,
          updatedProps.data.id,
        )}

      {!sbaPppForgivenessWorkflow.isSFormEligible &&
        Form.FieldRenderer(
          Form.Checkbox,
          {
            ...fields.borrowerPppLoansExceedThreshold,
            onChange: props.mutateProperty,
          },
          updatedProps,
          TypeName,
          updatedProps.data.id,
        )}
    </div>
  );
}

export default LoanInformationSection;
