import * as React from 'react';
import { useMutation } from '@apollo/client';

import { DocumentNode, FetchResult, MutationFunction } from '../../types';

/* eslint-disable @typescript-eslint/no-unused-vars */
type Vars = {
  [key: string]: any;
};

/* eslint-disable import/prefer-default-export */

// TOut = Return Type
// TVariables = Vars passed into mutate
// PIn = Props

type MutationArgs<TOut = any, TVariables = Vars, PIn = Vars> = {
  cr: (
    mutate: MutationFunction,
    ownProps: PIn,
  ) => {
    [key: string]: (...args: Array<any>) => Promise<void | FetchResult<TOut>>;
  };
  type: string;
};

export function mutationBuilderHoC<TOut, TVariables>(
  mutation: DocumentNode,
  args: MutationArgs<TOut, TVariables>,
) {
  return function mutationWrapperHoC(
    Wrapped: React.ComponentType<any>,
  ): React.ComponentType {
    return function MutationWrapper(props: any) {
      let mutationMethod: {
        [key: string]: (
          ...args: Array<any>
        ) => Promise<void | FetchResult<TOut>>;
      } | null;

      const [mutate] = useMutation(mutation);

      if (!mutationMethod) {
        const mutationAdapter: MutationFunction = (options: any) => {
          // Used to force injection of 'errorPolicy'
          const finalOptions = {
            errorPolicy: 'all',
            ...options,
          };

          return mutate(finalOptions);
        };

        mutationMethod = args.cr(mutationAdapter, props);
      }
      return <Wrapped {...props} {...mutationMethod} />;
    };
  };
}
