import { fromJS, List } from 'immutable';
import { EntityPayloadAction } from '../types';
import { idKey, pendingDeleteKey, getKeyPath, isDirty } from '../methods';
import { isWholeNumber, getTypeNameString } from 'utils';
import { ObjectBaseTypeNames } from 'types';

/* eslint-disable import/prefer-default-export */

/**
 * Within a collection at the specified keyPath, remove an object
 * by it's id value
 * @param {*} state
 * @param {*} action
 */
export function handleRemoveEntity(
  state: any,
  action: EntityPayloadAction,
): Record<string, any> {
  const { keyPath: pendingKeyPath, entity } = action.payload;
  const keyPath = getKeyPath(pendingKeyPath);

  const { __typename } = entity;
  const collection: List<any> = state.getIn(keyPath);

  // all entites should be immutable
  const index = collection.findIndex(x => x.get(idKey) === entity.id);

  // nothing to remove, return
  if (index < 0) return state;

  // remove it
  const updated = collection.delete(index);

  if (!isWholeNumber(entity.id)) {
    // not a valid id, do not add to pending deletes
    return state.setIn(keyPath, updated);
  }

  const typeKey: ObjectBaseTypeNames = getTypeNameString(__typename);
  // otherwise, must be queued for delete
  const deleteCollection = (state.hasIn([pendingDeleteKey, typeKey])
    ? state.getIn([pendingDeleteKey, typeKey])
    : List([])
  ).push(fromJS(entity));

  return state
    .setIn(keyPath, updated)
    .setIn([pendingDeleteKey, typeKey], deleteCollection)
    .set(isDirty, true);
}
