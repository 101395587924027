import { DispatchResponse } from '../../graphql/mutationsDispatch/types';
import { FetchMethod } from '../../graphql/types';
import { ReduxKeys } from '../directory';
import generateActionCreatorsFor from './actionCreatorFactory';
import { DispatchMethods, Factory, KeyPath, LifecycleMethods } from './types';
import { BaseType, ValidationMap } from 'types';

/**
 * To be used in tandem with a genericFormReducer.  This Factory method
 * generates the Action creators for a particular key. For example, the
 * Deal form has the key 'deal', which is used by the genericFormReducer
 * to create a reducer.  This Factory create Actions for that Reducer
 *
 * @param {*} reduxKey
 */
export default function createDispatchActionsFor<TOut extends BaseType>(
  reduxKey: ReduxKeys,
  lifeCycleMethods: LifecycleMethods<TOut>,
): Factory<TOut> {
  function generateActions(dispatch: any): DispatchMethods<TOut> {
    const actionCreators = generateActionCreatorsFor(
      reduxKey,
      lifeCycleMethods,
    );

    const tryInvokeAutoSave = () => {
      const { fetch } = lifeCycleMethods;

      dispatch(actionCreators.handleAutoSave(fetch, reduxKey));
    };

    const tryInvokeAutoValidate = () => {
      dispatch(actionCreators.handleAutoValidate());
    };

    const actions: DispatchMethods<TOut> = {
      // Add Entity
      addEntity: (keyPath: KeyPath, entity: any) => {
        dispatch(actionCreators.handleAddEntity(keyPath, entity));

        tryInvokeAutoSave();
      },

      addEntities: (keyPath: KeyPath, entities: Array<any>) => {
        dispatch(actionCreators.handleAddEntities(keyPath, entities));

        tryInvokeAutoSave();
      },
      clearState: () => {
        dispatch(actionCreators.handleClearState());
      },

      deleteCollection: (keyPath: KeyPath, entities: Array<any>) => {
        dispatch(actionCreators.handleDeleteCollection(keyPath, entities));

        tryInvokeAutoSave();
      },
      mutateProperty: (
        value:
          | (string | null | undefined)
          | (boolean | null | undefined)
          | (Array<any> | null | undefined),
        property: KeyPath,
      ) => {
        dispatch(actionCreators.handleEditPropertyAction(property, value));

        tryInvokeAutoSave();
        tryInvokeAutoValidate();
      },

      mutateProperties: (keyPath: KeyPath, slice: {}) => {
        dispatch(actionCreators.handleMutateProperties(keyPath, slice));

        tryInvokeAutoSave();
      },

      initializeState: (entity?: TOut) => {
        dispatch(actionCreators.handleInitializeState(entity));
      },

      removeEntity: (keyPath: KeyPath, entity: any) => {
        dispatch(actionCreators.handleRemoveEntityAction(keyPath, entity));

        tryInvokeAutoSave();
      },

      replaceEntity: (keyPath: KeyPath, entity: any) => {
        dispatch(actionCreators.handleReplaceEntity(keyPath, entity));

        tryInvokeAutoSave();
        tryInvokeAutoValidate();
      },

      handleResponse: (e: DispatchResponse<TOut>) => {
        dispatch(actionCreators.handleResponseAction(e));
        dispatch(actionCreators.handleToggleSave(false));
      },

      setValidationErrors: (errors: ValidationMap) => {
        dispatch(actionCreators.handleSetValidationErrors(errors));
      },
      save: (fetchMethod?: FetchMethod<TOut>) =>
        dispatch(actionCreators.handleSave(reduxKey, fetchMethod)) as any,

      toggleAutoSave: (enable: boolean) => {
        dispatch(actionCreators.handleToggleAutoSave(enable));
        if (enable === true) {
          tryInvokeAutoSave();
        }
      },
      toggleDirtyFlag: (
        keyPath: KeyPath,
        isDirty: boolean,
        recursive = false,
      ) => {
        dispatch(
          actionCreators.handleToggleDirtyFlagAction(
            keyPath,
            isDirty,
            recursive,
          ),
        );

        tryInvokeAutoSave();
      },
      undo: (keyPath: KeyPath) => {
        dispatch(actionCreators.handleUndo(keyPath));
        tryInvokeAutoSave();
      },

      validate: async () => dispatch(actionCreators.handleValidate()) as any,
    };

    return actions;
  }

  const res: Factory<TOut> = {
    generateActions,
  };

  return res as Factory<TOut>;
}
