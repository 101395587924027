// !!!!! THIS IS AN AUTO-GENERATED FILE, DO NOT EDIT !!!!!

import { gql } from '@apollo/client';
import { InstitutionInviteeInput } from 'types';
import { invariant, strictConvertToInput } from 'utils';
import {
  default as validationBuilder,
  ValidationData,
  AdditionalOptions,
} from './builder/validationBuilder';
import { ApolloQueryResult } from '../types';
import { inputScrubber } from '../mutations/utils';

/* eslint-disable import/prefer-default-export */

const InstitutionInviteeValidatorDefinition = gql`
  query validateInstitutionInvitee(
    $institutionInviteeData: InstitutionInviteeInput
  ) {
    validateInstitutionInvitee(
      institutionInviteeData: $institutionInviteeData
    ) {
      errors
      errorFields {
        name
        value
      }
      warnings
      warningFields {
        name
        value
      }
    }
  }
`;

const propName = 'validateInstitutionInvitee';

/**
 * Auto-Generated from the GraphQL Schema
 */
export const InstitutionInviteeValidator = validationBuilder<
  InstitutionInviteeInput
>(InstitutionInviteeValidatorDefinition, {
  build: (
    validation: (
      options: AdditionalOptions,
    ) => Promise<ApolloQueryResult<ValidationData>>,
  ) => async (entity: InstitutionInviteeInput) => {
    const res: ApolloQueryResult<ValidationData> = await validation({
      variables: {
        institutionInviteeData: { id: entity.id },
      },
    });
    invariant(
      res.data[propName],
      'Expected res.data to contain validateInstitutionInvitee',
    );
    return res.data[propName];
  },
});

export const InstitutionInviteeEntityValidator = validationBuilder<
  InstitutionInviteeInput
>(InstitutionInviteeValidatorDefinition, {
  build: (
    validation: (
      options: AdditionalOptions,
    ) => Promise<ApolloQueryResult<ValidationData>>,
  ) => async (entity: InstitutionInviteeInput) => {
    const res: ApolloQueryResult<ValidationData> = await validation({
      variables: {
        institutionInviteeData: strictConvertToInput({
          ...inputScrubber(entity),
          submitted: undefined,
          approved: undefined,
        }),
      },
    });
    invariant(
      res.data[propName],
      'Expected res.data to contain validateInstitutionInvitee',
    );
    return res.data[propName];
  },
});
