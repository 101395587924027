import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import { FeeKindShallowFields } from '../fragments';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { FeeKindType } from 'types';

const FeeKindsQueryDefinition: DocumentNode = gql`
  query FeeKindsQuery {
    feeKinds {
      ...FeeKindShallowFields
      sharingOptions
    }
  }
  ${FeeKindShallowFields}
`;

const FeeKindsQuery = standardQueryBuilder(FeeKindsQueryDefinition, {
  typename: 'FeeKindType',
  disableCache: true,
  variables: ({ searchQuery }: { searchQuery: string | null | undefined }) => ({
    nameSearch: searchQuery,
  }),
  results: (data: { feeKinds: Array<FeeKindType> | null | undefined }) => {
    const { feeKinds } = data;

    const feeKindSlugMap = (feeKinds || []).reduce(
      (map, feeKind: FeeKindType) => map.set(feeKind.slug, feeKind),
      new Map(),
    );

    return { feeKinds, feeKindSlugMap };
  },
});

export default FeeKindsQuery;
