import * as React from 'react';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import printJS from 'print-js';
import { UploadTermSheetHoC } from '../termSheet/TermSheetContainer';
import { DocumentType } from 'types';
import { Button, withLoadingIndicator } from 'components';
import { getDownloadLink } from 'utils';
import { RouteParams, withRouteParams } from 'routing';
import { TermSheetQuery, DocumentExpiringUrlQuery } from 'lsgql';
import { ReduxDirectory } from 'lsredux';
import {
  setUploading,
  uploadSuccessOrFailure,
} from 'lsredux/actions/uploading';

type Props = {
  documentExpiringUrl: string | null | undefined;
  termSheet: DocumentType;
  uploadFiles: (file: any) => void;
};

function PdfViewerActionButtons(props: Props) {
  function handlePrint() {
    const { documentExpiringUrl } = props;
    if (documentExpiringUrl) {
      fetch(documentExpiringUrl)
        .then(response => response.blob())
        .then(blob => {
          const pdfUrl = URL.createObjectURL(blob);
          printJS({ printable: pdfUrl, type: 'pdf', showModal: true });
        });
    }
  }

  function handleUpload(e: React.SyntheticEvent<HTMLInputElement>) {
    const { uploadFiles } = props;
    const { files } = e.target as any;
    const file = files && files[0];
    if (file) uploadFiles(file);
  }

  const { termSheet } = props;
  if (!termSheet) return null;

  return (
    <div className="ActionButtons ActionButtons--PdfViewer">
      <label
        className="ActionButtons__Item ActionButtons__Item--Upload TextButton"
        htmlFor="replaceTermSheet"
      >
        Replace
        <input
          aria-hidden="true"
          id="replaceTermSheet"
          multiple
          name="selectFiles"
          onChange={handleUpload}
          type="file"
          value=""
        />
      </label>
      <Button.Text
        className="ActionButtons__Item"
        label="Print"
        onClick={handlePrint}
      />
      <Button
        className="ActionButtons__Item"
        isDownloadLink
        label="Download"
        to={getDownloadLink(termSheet)}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  isUploading: state.getIn(ReduxDirectory.UploadingTermSheetKeyPath),
});

export default compose(
  withRouteParams([RouteParams.dealId]),
  connect(mapStateToProps, {
    setUploading,
    uploadSuccessOrFailure,
  }),
  TermSheetQuery,
  withProps((ownProps: any) => ({
    id: (ownProps.termSheet && ownProps.termSheet.id) || null,
  })),
  DocumentExpiringUrlQuery,
  UploadTermSheetHoC, // Required by `UploadTermSheetHoC`, but don't need loading
  // indicator here since we already have another loading indicator
  // in the `TermSheet` component
  withLoadingIndicator('null'),
)(PdfViewerActionButtons);
