import { gql } from '@apollo/client';
import {
  LoanTrancheTierFields,
  ProspectFields,
  LoanTrancheShallowFields,
} from '../fragments';
import { getAuthenticatedClient } from '../authenticatedClient';
import formQueryBuilder from './queryBuilders/formQueryBuilder';
import { OrderBookInput } from 'types';

/* eslint-disable import/prefer-default-export */

export const OrderBookQueryDefinition = gql`
  query OrderBookQuery($id: ID!) {
    loanTranche(id: $id) {
      ...LoanTrancheShallowFields
      loantranchetierSet {
        ...LoanTrancheTierFields
      }
      prospectSet {
        ...ProspectFields
      }
    }
  }
  ${LoanTrancheShallowFields}
  ${ProspectFields}
  ${LoanTrancheTierFields}
`;

export const OrderBookFormQuery = formQueryBuilder(OrderBookQueryDefinition, {
  typename: 'LoanTranche',
  variables: ({ loanTrancheId }: { loanTrancheId: string }) => ({
    id: loanTrancheId,
  }),
  results: ({
    loanTranche,
  }: {
    loanTranche: OrderBookInput | null | undefined;
  }) => loanTranche,
});

export const OrderBookFetchMethod = async ({ id }) => {
  const variables: {
    [key: string]: any;
  } = { id };

  const res = await getAuthenticatedClient().query({
    query: OrderBookQueryDefinition,
    variables,
    fetchPolicy: 'network-only',
  });

  const parsed = {
    ...res,
    data: res.data.loanTranche,
  };

  return parsed;
};

/* eslint-enable */
