import * as React from 'react';

/**
 * Prevent default handling and bubbling for React SyntheticEvents
 * @param {SyntheticEvent<*>} e Event object
 */
function haltBubble(e: React.SyntheticEvent | Event) {
  if (e) {
    if (e.preventDefault) e.preventDefault();
    if (e.stopPropagation) e.stopPropagation();
  }
}

export default haltBubble;
