import * as React from 'react';
import noop from 'lodash/noop';
import DataGridIconCell from './DataGridIconCell';
import DataGridSummaryCell from './DataGridSummaryCell';
import DataGridEmptyCell from './DataGridEmptyCell';
import { Column } from './types';

import './DataGridSummaryRow.scss';

// TODO: Do we want to perform aggregation here or at a higher level?

/**
 * A summary (aggregate) row in the data grid.
 */

type Props<T, K extends keyof T> = {
  /**
   * All data grid rows, used for aggregation.
   */
  columns: Array<Column<T, K>>;

  /**
   * The column configurations of the data grid.
   */
  deleteColumn?: boolean;

  /**
   * If true, renders space-filling cell for datagrid's delete column
   */
  rows: T[];
};

class DataGridSummaryRow<T, K extends keyof T> extends React.Component<
  Props<T, K>
> {
  render() {
    const { columns, rows, deleteColumn } = this.props;
    return (
      <div className="DataGridSummaryRow">
        {columns.map((column: any) => {
          const { columnName, aggregator, icon } = column;
          if (icon)
            return (
              <DataGridIconCell
                className={`DataGridSummaryCell ${column.className || ''}`}
                icon={icon}
                key={columnName}
                onClick={noop}
              />
            );
          if (!aggregator)
            return (
              <DataGridEmptyCell
                className={`DataGridSummaryCell ${column.className || ''}`}
                key={columnName}
              />
            );
          const summaryValue = aggregator(rows, column.columnName);
          return (
            <DataGridSummaryCell
              className={column.className}
              key={columnName}
              value={summaryValue}
            />
          );
        })}
        {deleteColumn && (
          <DataGridIconCell
            className="DataGridSummaryCell DataGridCell-DeleteColumn"
            icon="trash"
            onClick={noop}
          />
        )}
      </div>
    );
  }
}

export default DataGridSummaryRow;
