import React, { useState } from 'react';
import noop from 'lodash/noop';
import SbaAccount from './SbaAccountView';
import { Divider, Header, Form, TabNavigationMenu } from 'components';
import { parseEnum } from 'utils';
import { InstitutionType, FormFieldsType } from 'types';

import './InstitutionProfileView.scss';

type Props = {
  institution: InstitutionType;
};

const tabs = ['Overview', 'Bank Accounts'];

const fields: FormFieldsType = {
  subsidiaries: {
    fieldName: 'Lending Entities',
    propertyName: 'subsidiaries',
    htmlFor: '',
  },
  receivingParty: {
    id: 'receivingParty',
    fieldName: 'Receiving Party',
    propertyName: 'receivingParty',
    width: 'eight',
  },
  paymentType: {
    id: 'paymentType',
    fieldName: 'Payment Type',
    propertyName: 'paymentType',
    width: 'eight',
  },
  notes: {
    id: 'notes',
    fieldName: 'Notes',
    propertyName: 'notes',
    width: 'sixteen',
  },
  abaNumber: {
    id: 'abaNumber',
    fieldName: 'ABA Number',
    propertyName: 'abaNumber',
    width: 'eight',
  },
  accountNumber: {
    id: 'hiddenAccountNumber',
    fieldName: 'Account Number',
    propertyName: 'hiddenAccountNumber',
    width: 'eight',
  },
  accountHolder: {
    id: 'accountHolder',
    fieldName: 'Account Holder',
    propertyName: 'accountHolder',
    width: 'four',
  },
  address: {
    fieldName: 'Address',
    propertyName: 'address',
    htmlFor: '',
  },
  type: {
    id: 'type',
    fieldName: 'Type',
    propertyName: 'type',
    width: 'sixteen',
    helpText: 'Lead or Co-lender on deals.',
  },
  dealRole: {
    id: 'dealRole',
    fieldName: '',
    propertyName: 'dealRole',
    width: 'sixteen',
    helpText: 'This preset can be changed for individual deals.',
  },
};

function InstitutionProfileView(props: Props) {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const { institution } = props;

  if (institution?.isBorrower == false && !tabs.includes('SBA Info')) {
    tabs.push('SBA Info');
  }

  return (
    <>
      <TabNavigationMenu
        activeTab={activeTab}
        onClick={setActiveTab}
        tabs={tabs}
      />
      <Form id="InstitutionProfileView" onSubmit={noop}>
        {activeTab === 'Overview' && <Overview {...institution} />}
        {activeTab === 'Bank Accounts' && (
          <BankAccounts accounts={institution.institutionbankaccountSet} />
        )}
        {activeTab === 'SBA Info' && <SbaAccount {...institution} />}
      </Form>
    </>
  );
}

function Overview(props: InstitutionType) {
  const receivingParty = props.fundsReceivedByLoanStreet
    ? 'LoanStreet'
    : 'Other';
  const paymentType =
    props.paymentType === 'ACH_DEBIT'
      ? 'ACH Debit'
      : parseEnum(props.paymentType);

  return (
    <div className="Overview">
      <p>
        <span className="Overview__InstitutionName">{props.name}</span>
        {props.ticker && ` [${props.ticker.toUpperCase()}]`}
      </p>
      <p>{props.website}</p>
      <Address {...props} />
      <p>{props.phoneNumber}</p>
      <Divider />
      {props.subsidiaries && props.subsidiaries.length > 0 && (
        <Subsidiaries subsidiaries={props.subsidiaries} />
      )}
      <Header as="h5">Payments</Header>
      <Form.Group>
        <Form.ReadOnly {...fields.receivingParty} value={receivingParty} />
        {props.fundsReceivedByLoanStreet && (
          <Form.ReadOnly {...fields.paymentType} value={paymentType} />
        )}
      </Form.Group>
      <Form.ReadOnly {...fields.notes} value={props.notes} />
    </div>
  );
}

function Subsidiaries(props) {
  return (
    <>
      <Form.FieldLabel {...fields.subsidiaries} />
      <>
        {props.subsidiaries.map(subsidiary => (
          <p key={subsidiary.id}>{subsidiary.name}</p>
        ))}
      </>
      <Divider />
    </>
  );
}

function BankAccounts(props) {
  const { accounts = [] } = props;
  return (
    <>
      {accounts.map((account, index, array) => (
        <BankAccount
          hasNext={Boolean(array[index + 1])}
          key={account.id}
          {...account}
        />
      ))}
    </>
  );
}

function isAddress(props) {
  return (
    props.addressLine1 ||
    props.addressLine2 ||
    props.city ||
    props.state ||
    props.zipCode
  );
}

function BankAccount(props) {
  const hasAddress = isAddress(props);
  return (
    <div className="BankAccount">
      <div className="BankAccount__Header">
        {props.accountNickname && (
          <p className="AccountNickname">{props.accountNickname}</p>
        )}
        {props.isPrimary && <PrimaryAccountIndicator />}
      </div>
      <div className="BankAccount__AccountInfo">
        <div>
          <Form.Group>
            <Form.ReadOnly {...fields.abaNumber} value={props.abaNumber} />
            <Form.ReadOnly
              {...fields.accountNumber}
              value={props.hiddenAccountNumber}
            />
          </Form.Group>
          <Form.ReadOnly
            {...fields.accountHolder}
            value={props.invoicePartyName}
          />
        </div>
        <div>
          {hasAddress && (
            <>
              <Form.FieldLabel {...fields.address} />
              <Address {...props} />
            </>
          )}
        </div>
      </div>
      {props.hasNext && <Divider />}
    </div>
  );
}

function Address(props) {
  return (
    <>
      <p className="InstitutionProfile__Address">{props.addressLine1}</p>
      <p className="InstitutionProfile__Address">{props.addressLine2}</p>
      {(props.city || props.state || props.zipCode) && (
        <p className="InstitutionProfile__Address">
          {props.city}
          {props.city && props.state && ','} {props.state} {props.zipCode}
        </p>
      )}
    </>
  );
}

function PrimaryAccountIndicator() {
  return (
    <div className="PrimaryAccountIndicator">
      <p>Primary</p>
    </div>
  );
}

export default InstitutionProfileView;
