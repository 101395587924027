import { mutationBuilder } from './builders';
import {
  CreateSbaAccountMutationDefinition,
  SetSbaAccountMutationDefinition,
  DeleteSbaAccountMutationDefinition,
} from './definitions';
import { ID, SbaAccountInput, SbaAccountType } from 'types';

const SbaAccountMutationMethods = {
  createSbaAccount: mutationBuilder<SbaAccountInput, SbaAccountType>(
    CreateSbaAccountMutationDefinition,
    {
      build: mutate => sbaAccountData =>
        mutate({
          variables: {
            sbaAccountData: sbaAccountData,
          },
        }),
    },
  ),
  setSbaAccount: mutationBuilder<SbaAccountInput, SbaAccountType>(
    SetSbaAccountMutationDefinition,
    {
      build: mutate => sbaAccountData =>
        mutate({
          variables: {
            sbaAccountData: sbaAccountData,
          },
        }),
    },
  ),
  deleteSbaAccount: mutationBuilder<ID, void>(
    DeleteSbaAccountMutationDefinition,
    {
      build: mutate => (id: string) => mutate({ variables: { id } }),
    },
  ),
};

export default SbaAccountMutationMethods;
