import * as React from 'react';
import { StepProps } from '../StepProps';
import WorkflowStep from '../WorkflowStep';
import { invariant } from 'utils';
import { Text, TextLink, RouteTo } from 'components';
import { RouteTable } from 'routing';
import { FileUploaderCheck } from '../../../sbaPppForgivenessWorkflow/documentation/FileUploader';

function BorrowerSupportingDocuments(props: StepProps) {
  const { step, canEdit, isDimmed, dealId, dealClosingWorkflow } = props;

  invariant(step.slug === 'aw_borrower_supporting_documents', 'invalid slug');

  const { state } = dealClosingWorkflow;

  const isSecondDraw =
    props?.deal?.category === 'SBA_PPP_ROUND_TWO_SECOND_DRAW';

  // eslint-disable-next-line no-nested-ternary
  const instructionalText = canEdit
    ? isSecondDraw
      ? "Upload any needed documentation. The materials provided from your first loan may be sufficient. Please check with your lender if you're uncertain."
      : 'The lender needs you to provide documentation.'
    : 'You’ve requested lender review of your supporting documents:';

  const showDetails =
    canEdit || state === 'lender_approve_borrower_application';

  const fileUploaderProps = {
    folders: props.deal?.folders,
    dataroomUrl: RouteTable.deal.toDealDataroom(dealId),
  };

  const details = (
    <>
      <div className="Group justified">
        <div style={{ width: '370px' }}>
          <Text value={instructionalText} />
        </div>
        <TextLink.External
          as={RouteTo}
          label="Review Examples"
          target="newTab"
          to={RouteTable.application.toSbaPppSupportingDocuments}
        />
      </div>
      <div className="Group justified">
        <Text className="bold">
          Documents about your business{' '}
          {/* <span className="unbold">(1)</span> */}
        </Text>
        {canEdit && (
          <FileUploaderCheck
            {...fileUploaderProps}
            destination="CORPORATE_DOCUMENTS"
          />
        )}
      </div>
      <div className="Group justified">
        <Text className="bold">
          Documents about yourself {/* <span className="unbold">(1)</span> */}
        </Text>
        {canEdit && (
          <FileUploaderCheck
            {...fileUploaderProps}
            destination="PERSONAL_IDENTIFICATION"
          />
        )}
      </div>
      <div className="Group justified">
        <Text className="bold">
          Documents about your business income, payroll and benefits{' '}
          {/* <span className="unbold">(1)</span> */}
        </Text>
        {canEdit && (
          <FileUploaderCheck {...fileUploaderProps} destination="ELIGIBILITY" />
        )}
      </div>
      <Text
        value={
          isSecondDraw
            ? 'If you answered YES to question 3 on your application, provide:'
            : 'If you answered YES to questions 3 or 4 on your application, provide:'
        }
      />
      <div className="Group justified">
        <Text className="bold">
          Addendum A: List and relationship of other owned/managed businesses{' '}
          {/* <span className="unbold">(1)</span> */}
        </Text>
        {canEdit && (
          <FileUploaderCheck {...fileUploaderProps} destination="ADDENDUMS" />
        )}
      </div>
      {!isSecondDraw && (
        <div className="Group justified">
          <Text className="bold">
            Addendum B: Details of your SBA Economic Injury Disaster Loan{' '}
            {/* <span className="unbold">(1)</span> */}
          </Text>
          {canEdit && (
            <FileUploaderCheck {...fileUploaderProps} destination="ADDENDUMS" />
          )}
        </div>
      )}
    </>
  );

  const incomplete =
    state === 'borrower_finish_application' ||
    state === 'lender_approve_borrower_application' ||
    state === 'borrower_lender_rejected_application';

  return (
    <WorkflowStep
      canEdit={canEdit}
      header="SUPPORTING DOCUMENTS"
      isDimmed={isDimmed}
      step={step}
    >
      {showDetails && details}
      <Text>
        {incomplete
          ? 'Your uploaded documents are always available in the'
          : ' The lender has reviewed the supporting documents you uploaded to the'}{' '}
        <TextLink
          as={RouteTo}
          label="Dataroom"
          to={RouteTable.deal.toDealDataroom(dealId)}
        />
        .
      </Text>
    </WorkflowStep>
  );
}

export default BorrowerSupportingDocuments;
