import { WeightScope, PermissionScopeMap, PermissionKey } from '../types';
import getKey from './getKey';
import { invariant } from 'utils';
import { PermissionsBaseType } from 'types';

/* eslint-disable no-param-reassign */
export default function initializePermissionPerspective(
  existingMap: PermissionScopeMap,
  perspective: PermissionsBaseType,
): PermissionScopeMap {
  const scope: PermissionKey = getKey(perspective);

  // get the existing reference count and increment by 1
  const referenceCount =
    (existingMap[scope] ? existingMap[scope].referenceCount : 0) + 1;

  if (referenceCount > 1) {
    // this is just a new instance of an existing permission map
    // increment the reference count and return
    existingMap[scope].referenceCount = referenceCount;
    return existingMap;
  }

  // create a new entry
  const pending: WeightScope = {
    referenceCount,
    source: new Set(perspective.permissions || []),
  };
  invariant(perspective, 'Perspective was undefined');
  invariant(perspective.permissions, 'Perspective.permissions was undefined');

  const toMerge: PermissionScopeMap = {};
  toMerge[scope] = pending;

  return { ...existingMap, ...toMerge } as PermissionScopeMap;
}
/* eslint-enable */
