import * as React from 'react';
import uuid from 'uuid/v4';
import { InvitationType, SearchFieldNameType } from '../types';
import { DealJob, InvitationTypes } from '../constants';
import ServicingAgentInvitationRow from './ServicingAgentInvitationRow';
import { Form, StandardModal, WrappedMediatorFormProps } from 'components';
import { isDealClosed } from 'utils';
import {
  DealType,
  InstitutionInvitationsInput,
  ID,
  UserType,
  InstitutionInviteeInput,
  ValidationType,
  ObjectBaseTypeNames,
  ValidationMap,
  SelectOptionType,
  MyUserType,
} from 'types';
import { DealNameSearch, QueryResult } from 'lsgql';

type Props = WrappedMediatorFormProps<InstitutionInvitationsInput> & {
  fetchDealOrganizationDealRoles: () => Promise<QueryResult<any>>;
  currentUser: MyUserType;
  deal: DealType;
  getRoleId: (args: {
    context?: string;
    name: string;
    postClosing: boolean | null | undefined;
  }) => ID;
  initializeState: () => void;
  invitationTypeSelected: InvitationType;
  isOpen: boolean;
  onClose: () => void;
  onInvitationTypeChange: (arg0: string | null | undefined) => void;
  onSave: () => Promise<{ entityId: ID; success: boolean }>;
  options: Array<SelectOptionType>;
  selected: InvitationType;
  validationMapBuilder: (
    typeName: ObjectBaseTypeNames,
    entityId: ID,
    response: ValidationType,
    map: ValidationMap,
  ) => ValidationMap;
  validator: (entity: InstitutionInviteeInput) => Promise<ValidationType>;
};
type State = {
  dealNames: Array<UserType>;
  searchValue: string;
};

const parentType = 'InstitutionInvitations';
const servicingAgentFieldName = 'Servicing Agent';

class LenderInvitationForm extends React.Component<Props, State> {
  fields: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      dealNames: [],
      searchValue: '',
    };

    this.fields = {
      invitationType: {
        id: 'invitationType',
        fieldName: 'Invite',
        propertyName: 'invitationType',
        width: 'six',
        onChange: this.handleInvitationTypeChange,
      },
      userSearch: {
        id: 'userSearch',
        propertyName: 'userSearch',
        width: 'ten',
        onChange: this.handleSearchChange,
        onFilterChange: this.handleFilterChange,
        placeholder: 'Search...',
      },
    };
  }

  componentDidMount() {
    this.props.initializeState();
  }

  handleSubmit = () => {
    const {
      onSave,
      setValidationErrors,
      fetchDealOrganizationDealRoles,
    } = this.props;
    this.validate().then(result => {
      if (Object.entries(result).length) {
        setValidationErrors(result);
      } else {
        onSave().then(response => {
          if (response && response.success) {
            fetchDealOrganizationDealRoles();
            this.handleOnClose();
          }
        });
      }
    });
  };

  validate = async () => {
    const { data, validator, validationMapBuilder } = this.props;
    const results = {};

    await Promise.all(
      data.invitees.map(async invitee => {
        const res = await validator(invitee);
        validationMapBuilder('InstitutionInvitee', invitee.id, res, results);
      }),
    );

    return results;
  };

  handleOnClose = () => {
    this.props.onClose();
  };

  handleInviteeChange = (value: string | null | undefined, fieldId: string) => {
    this.props.mutateProperty(value, ['invitees', fieldId]);
  };

  handleSearchChange = (value?: string | null | undefined) => {
    if (!value) return;
    const { dealNames } = this.state;
    const { data } = this.props;

    const contact = dealNames.find(dealName => dealName.id === value);
    const existingInvitee = data.invitees.find(
      invitee => invitee.email === value,
    );
    if (!existingInvitee) {
      if (contact) this.addEntity({ user: contact });
      else this.addEntity({ email: value });
    }
  };

  getRoleId = (args: { context: string; name: string }) => {
    const { getRoleId, deal } = this.props;
    const { name, context } = args;
    const postClosing = context === 'deal' ? isDealClosed(deal.stage) : null;
    return getRoleId({ name, context, postClosing });
  };

  addEntity = (args: { email?: string; user?: UserType }) => {
    const { user, email } = args;
    const { invitationTypeSelected, currentUser } = this.props;

    const inviteeInstitutionId =
      invitationTypeSelected === InvitationTypes.MY_TEAM &&
      currentUser.institution
        ? currentUser.institution.id
        : null;

    this.props.addEntity('invitees', {
      id: uuid(),
      dealRoleId: this.getRoleId({
        name: 'Loan Servicer',
        context: 'deal',
      }),
      email: user ? user.email : email,
      firstName: user ? user.firstName : '',
      inviteeInstitutionId,
      lastName: user ? user.lastName : '',
      dealJob: DealJob.SERVICE_PROVIDER,
      organizationRoleId: this.getRoleId({
        name: 'Administrator',
        context: 'institution',
      }),
      userId: user ? user.id : null,
      __typename: 'InstitutionInviteeInput',
    });
  };

  handleInvitationTypeChange = (value?: string | null | undefined) => {
    this.props.onInvitationTypeChange(value);
  };

  handleFilterChange = (searchValue: string) => {
    DealNameSearch(searchValue).then(result => {
      this.setState({
        dealNames: result.data.results,
        searchValue,
      });
    });
  };

  transformDealNamesToOptions = (
    dealNames: Array<UserType>,
  ): Array<SelectOptionType> => {
    const options = dealNames.map(user => ({
      text: `${user.fullName || ''}, ${user.email || ''}, ${(user.institution &&
        user.institution.name) ||
        ''}`,
      value: user.id,
    }));
    const searchValueOption = {
      text: this.state.searchValue,
      value: this.state.searchValue,
    };
    return [searchValueOption, ...options];
  };

  getSearchFieldName = (): SearchFieldNameType => {
    const { invitationTypeSelected } = this.props;

    if (invitationTypeSelected === InvitationTypes.SERVICING_AGENTS)
      return servicingAgentFieldName;

    return 'Team Member';
  };

  render() {
    const { data, isOpen, options, invitationTypeSelected } = this.props;
    const { dealNames } = this.state;
    const invitations = data.invitees.length;
    const dealNameOptions = this.transformDealNamesToOptions(dealNames);
    const fieldName: SearchFieldNameType = this.getSearchFieldName();
    const isOnlyInvitation = invitations === 1;

    let invitationType: InvitationType;
    if (invitationTypeSelected === InvitationTypes.SERVICING_AGENTS) {
      invitationType = InvitationTypes.SERVICING_AGENTS;
    } else {
      invitationType = InvitationTypes.MY_TEAM;
    }

    return (
      <StandardModal
        confirmButtonText="Send"
        denyConfirm={!invitations}
        header="Send Invitation"
        isOpen={isOpen}
        onClose={this.handleOnClose}
        onConfirm={this.handleSubmit}
      >
        <Form id="servicingAgentInvitationForm" onSubmit={this.handleOnClose}>
          <>
            <Form.Group>
              {Form.FieldRenderer(
                Form.Select,
                {
                  ...this.fields.invitationType,
                  options,
                  value: invitationType,
                  disabled: Boolean(invitations) || options.length <= 1,
                  allowEmpty: false,
                },
                this.props,
                parentType as any,
                data.id,
              )}
              {Form.FieldRenderer(
                Form.Search,
                {
                  ...this.fields.userSearch,
                  allowEmpty: false,
                  hideIcon: true,
                  options: dealNameOptions,
                  onFilterChange: this.handleFilterChange,
                  fieldName,
                },
                this.props,
                parentType as any,
                data.id,
              )}
            </Form.Group>
            {data.invitees.map<any>((invitee, index) => (
              <ServicingAgentInvitationRow
                data={invitee}
                disabled={Boolean(this.props.disabled)}
                errors={this.props.errors}
                isHeader={isOnlyInvitation || !index}
                isSaving={this.props.isSaving}
                key={invitee.id}
                removeEntity={this.props.removeEntity}
                replaceEntity={this.props.replaceEntity}
              />
            ))}
          </>
        </Form>
      </StandardModal>
    );
  }
}

export default LenderInvitationForm;
