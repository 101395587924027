import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import { DocumentShallowFields } from './generated';

const DocumentFields: DocumentNode = gql`
  fragment DocumentFields on DocumentType {
    ...DocumentShallowFields
    parent {
      id
    }
  }
  ${DocumentShallowFields}
`;

export default DocumentFields;
