import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import {
  PaymentTrackersShallowFields,
  LoanServicingMoneyShallowFields,
} from '../fragments';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { PaymentTrackersType } from 'types';

type OwnProps = {
  dealId: string;
};

const PaymentTrackersQueryDefinition: DocumentNode = gql`
  query PaymentTrackersQuery($dealId: ID) {
    paymentTrackers(dealId: $dealId) {
      ...PaymentTrackersShallowFields
      amount {
        ...LoanServicingMoneyShallowFields
      }
      fees {
        ...LoanServicingMoneyShallowFields
      }
      principal {
        ...LoanServicingMoneyShallowFields
      }
      interest {
        ...LoanServicingMoneyShallowFields
      }
      totalAmount {
        ...LoanServicingMoneyShallowFields
      }
      totalInterest {
        ...LoanServicingMoneyShallowFields
      }
      totalFees {
        ...LoanServicingMoneyShallowFields
      }
      totalPrincipal {
        ...LoanServicingMoneyShallowFields
      }
    }
  }
  ${PaymentTrackersShallowFields}
  ${LoanServicingMoneyShallowFields}
`;

export const PaymentTrackersQuery = standardQueryBuilder(
  PaymentTrackersQueryDefinition,
  {
    typename: 'PaymentTrackers',
    skip: (props: OwnProps) => {
      const { dealId } = props;
      return !dealId;
    },
    variables: (props: OwnProps) => {
      return props;
    },
    results: (data: {
      paymentTrackers: PaymentTrackersType | null | undefined;
    }) => {
      const { paymentTrackers } = data;
      return { paymentTrackers };
    },
  },
);
