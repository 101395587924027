import * as React from 'react';
import { LoanTrancheFormProps } from '../types';
import CreditRatingsForm from '../../creditRatingsForm/CreditRatingsForm';
import { isLetterOfCredit } from './utils';
import TrancheGroup from './TrancheGroup';
import { FormFieldsType } from 'types';
import { Form } from 'components';

const loanTranche = 'LoanTranche';
const width = '158px';

class DetailsFields extends React.Component<LoanTrancheFormProps> {
  fields: FormFieldsType = {};

  constructor(props: LoanTrancheFormProps) {
    super(props);

    this.fields = {
      seniority: {
        id: 'seniority',
        propertyName: 'seniority',
        fieldName: 'Ranking',
        onChange: this.props.mutateProperty,
        width,
        typeName: 'LoanTrancheSeniority',
        tabIndex: this.props.tabIndex,
      },
      lien: {
        id: 'lien',
        propertyName: 'lien',
        fieldName: 'Lien',
        onChange: this.props.mutateProperty,
        width,
        typeName: 'LoanTrancheLien',
        tabIndex: this.props.tabIndex,
      },
    };
  }

  render() {
    const { data } = this.props;

    if (isLetterOfCredit(data)) return false;

    return (
      <TrancheGroup>
        <Form.Group>
          {Form.FieldRenderer(
            Form.ReferenceSelect,
            this.fields.seniority,
            this.props,
            loanTranche,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.ReferenceSelect,
            this.fields.lien,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
        <CreditRatingsForm
          creditratingSet={data.creditratingSet}
          disabled={this.props.disabled}
          errors={this.props.errors}
          isSaving={this.props.isSaving}
          onChange={this.props.onUpdateCreditRating}
          onDelete={this.props.handleRemoveCreditRating}
          tabIndex={this.props.tabIndex}
        />
      </TrancheGroup>
    );
  }
}

export default DetailsFields;
