import React from 'react';
import noop from 'lodash/noop';
import { Modal, Text, Form, WrappedMediatorFormProps } from 'components';
import { DealType, FormFieldsType } from 'types';
import './DemographicsForm.scss';

const width = '158px';
const maxWidth = '332px';

const disabledProps = {
  disabled: true,
  disabledReadable: true,
  onChange: noop,
};

const fields: FormFieldsType = {
  ownerName: {
    id: 'ownerName',
    propertyName: 'ownerName',
    fieldName: 'Principal Name',
    width,
    ...disabledProps,
  },
  title: {
    id: 'title',
    propertyName: 'title',
    fieldName: 'Position',
    width,
    ...disabledProps,
  },
  veteranStatus: {
    id: 'veteranStatus',
    propertyName: 'veteranStatus',
    fieldName: 'Veteran Status',
    options: [
      { text: 'Non-Veteran', value: 'NON_VETERAN' },
      { text: 'Veteran', value: 'VETERAN' },
      { text: 'Service-Disabled Veteran', value: 'SERVICE_DISABLED_VETERAN' },
      { text: 'Spouse of Veteran', value: 'SPOUSE_OF_VETERAN' },
      { text: 'Not Disclosed', value: 'NOT_DISCLOSED' },
    ],
    width,
  },
  gender: {
    id: 'gender',
    propertyName: 'gender',
    fieldName: 'Gender',
    options: [
      { text: 'Male', value: 'MALE' },
      { text: 'Female', value: 'FEMALE' },
      { text: 'Not Disclosed', value: 'NOT_DISCLOSED' },
    ],
    width,
  },
  race: {
    id: 'race',
    propertyName: 'race',
    fieldName: 'Race',
    options: [
      {
        text: 'American Indian or Alaska Native',
        value: 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
      },
      { text: 'Asian', value: 'ASIAN' },
      { text: 'Black or African-American', value: 'BLACK_OR_AFRICAN_AMERICAN' },
      {
        text: 'Native Hawaiian or Pacific Islander',
        value: 'NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER',
      },
      { text: 'White', value: 'WHITE' },
      { text: 'Not Disclosed', value: 'NOT_DISCLOSED' },
    ],
    multiple: true,
    width: maxWidth,
  },
  ethnicity: {
    id: 'ethnicity',
    propertyName: 'ethnicity',
    fieldName: 'Ethnicity',
    options: [
      { text: 'Hispanic or Latino', value: 'HISPANIC_OR_LATINO' },
      { text: 'Not Hispanic or Latino', value: 'NOT_HISPANIC_OR_LATINO' },
      { text: 'Not Disclosed', value: 'NOT_DISCLOSED' },
    ],
    width: maxWidth,
  },
};

const TypeName = 'AdditionalSbaPppBorrowerInformation';

interface ModalFormProps extends WrappedMediatorFormProps<DealType> {
  isOpen: boolean;
  onClose: (e?: React.SyntheticEvent) => void;
}

const DemographicsForm = (props: ModalFormProps) => {
  const { isOpen, onClose, data, onSave, disabled } = props;
  const { sbaPppLoanApplication } = data;

  if (
    !sbaPppLoanApplication ||
    !sbaPppLoanApplication.sbapppapplicantownershipSet
  ) {
    return null;
  }

  const dataOwners = sbaPppLoanApplication.sbapppapplicantownershipSet;

  const handleMutateProperty = (index: number) => (
    value:
      | (string | null | undefined)
      | (boolean | null | undefined)
      | (Array<any> | null | undefined),
    fieldId: string,
  ) => {
    const path = [
      'sbaPppLoanApplication',
      'sbapppapplicantownershipSet',
      index.toString(),
      fieldId,
    ];
    props.mutateProperty(value, path);
  };

  return (
    <Modal
      className="steel DemographicInformationModal"
      confirmButtonText="Save"
      denyConfirm={disabled}
      header="Demographic Information"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onSave}
      width="Half"
    >
      <Text>
        Provide demographic information about the principals with greater than
        20% ownership stake that were listed on the Borrower Application.
        Disclosure is <strong>voluntary</strong> and will have no bearing on the
        loan forgiveness decision.
      </Text>
      {dataOwners.map((owner, index) => (
        <div className="Owner" key={owner.id}>
          <Form.Group>
            {Form.FieldRenderer(
              Form.Input,
              fields.ownerName,
              { ...props, data: owner },
              TypeName,
              owner.id,
            )}
            {Form.FieldRenderer(
              Form.Input,
              fields.title,
              { ...props, data: owner },
              TypeName,
              owner.id,
            )}
          </Form.Group>
          <Form.Group>
            {Form.FieldRenderer(
              Form.Select,
              {
                ...fields.veteranStatus,
                onChange: handleMutateProperty(index),
              },
              { ...props, data: owner },
              TypeName,
              owner.id,
            )}
            {Form.FieldRenderer(
              Form.Select,
              { ...fields.gender, onChange: handleMutateProperty(index) },
              { ...props, data: owner },
              TypeName,
              owner.id,
            )}
          </Form.Group>
          {Form.FieldRenderer(
            Form.Select,
            { ...fields.race, onChange: handleMutateProperty(index) },
            { ...props, data: owner },
            TypeName,
            owner.id,
          )}
          {Form.FieldRenderer(
            Form.Select,
            { ...fields.ethnicity, onChange: handleMutateProperty(index) },
            { ...props, data: owner },
            TypeName,
            owner.id,
          )}
          {index !== dataOwners.length - 1 && <Form.Divider />}
        </div>
      ))}
    </Modal>
  );
};

export default DemographicsForm;
