import { DealCategory } from 'types';

/** Returns a human friendly version of the deal category */
export const getDealCategory = (category: DealCategory | string): string => {
  const DEAL_CATEGORY_CONVERSION: Record<DealCategory, string> = {
    STANDARD: '',
    SBA_PP: '2020 1st Draw',
    SBA_PPP_ROUND_TWO_FIRST_DRAW: '2021 1st Draw',
    SBA_PPP_ROUND_TWO_SECOND_DRAW: '2021 2nd Draw',
  };

  return DEAL_CATEGORY_CONVERSION[category] || '';
};
