import * as React from 'react';
import { compose } from 'recompose';
import { isJoiningInstitution } from './utils';
import { withLoadingIndicator } from 'components';
import { MyUserQuery } from 'lsgql';
import { UserType, PublicInvitationType } from 'types';

export type InvitationGuardChildProps = {
  currentUser: UserType | null | undefined;
  inviteIsForCurrentUser: boolean;
  isAuthenticated: boolean;
  isJoiningInstitution: boolean;
  isSharable: boolean;
};

type Props = {
  children: (childProps: InvitationGuardChildProps) => React.ReactNode;
  invitation: PublicInvitationType;
  loading: boolean;
  user: UserType | null | undefined;
};

/**
 * If the user is logged in, verify that the invitation is for the logged in user
 */
class InvitationGuard extends React.PureComponent<Props> {
  inviteIsForCurrentUser = () => {
    if (!this.props.user) return false;
    if (!this.props.invitation) return false;

    return this.props.user.email === this.props.invitation.email;
  };

  getChildProps = () => ({
    inviteIsForCurrentUser: this.inviteIsForCurrentUser(),
    isAuthenticated: Boolean(this.props.user) && this.props.loading !== true,
    isJoiningInstitution: isJoiningInstitution(this.props.invitation),
    currentUser: this.props.user,
    isSharable: this.props.invitation.isSharable,
  });

  render() {
    return this.props.children(this.getChildProps());
  }
}

export default compose(
  MyUserQuery,
  withLoadingIndicator('loading'),
)(InvitationGuard);
