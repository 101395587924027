import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useLocation } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import Logo from '../../logo/Logo';

import DealContextMenu from './DealContextMenu';
import ProfileContextMenu from './ProfileContextMenu';
import MainContextMenu from './MainContextMenu';

import MenuItem from './menuItems/MenuItem';
import { toggleMenu } from 'lsredux/actions/menu';
import { ReduxDirectory } from 'lsredux';
import { RouteTable, RoutePortal, RoutingContext } from 'routing';
import { FontAwesomeIconTypes, IconButton } from 'components';
import { RenderWithRouter } from 'routing/routes/RouteRenderers';
import { useAuth0, useGlobalPermissionPerspective } from 'security';

import './SidebarMenu.scss';

/* eslint-disable react/display-name */

const routes: RoutingContext = {
  routes: [
    {
      render: () => <ProfileContextMenu />,
      path: RouteTable.application.toCreateDeal,
    },
    {
      render: RenderWithRouter(DealContextMenu),
      path: RouteTable.deal.toDeal(),
    },
    {
      render: () => <ProfileContextMenu />,
      path: '/profile',
    },
    {
      render: () => <ProfileContextMenu />,
      path: '/help',
    },
    {
      render: () => <MainContextMenu />,
      path: '/',
    },
  ],
};

type Props = {
  isMenuOpen: boolean;
  toggleMenu: () => void;
};

const userIcon: FontAwesomeIconTypes = ['fal', 'user'];
const settingsIcon: FontAwesomeIconTypes = ['fal', 'cog'];
const hamburgerIcon: FontAwesomeIconTypes = ['fal', 'bars'];
const signOutIcon: FontAwesomeIconTypes = ['fal', 'power-off'];
const helpIcon: FontAwesomeIconTypes = ['far', 'question-circle'];

function SidebarMenu(props: Props) {
  const { logout } = useAuth0();
  const { isSuperUser } = useGlobalPermissionPerspective();
  const location = useLocation();
  const { isMenuOpen } = props;

  function handleToggleMenu(): void {
    props.toggleMenu();
  }

  function handleSignOut(): void {
    logout();
  }

  const menuClass = isMenuOpen ? 'Menu--Open' : 'Menu--Closed';

  return (
    <Menu attached className={menuClass} icon="labeled" id="Menu" vertical>
      {isMenuOpen && (
        <div className="SignOut MenuItem">
          <p>Log Out</p>
          <IconButton
            alt="sign out"
            boxSize={24}
            className="SignOut__Icon"
            color="robinLight"
            icon={signOutIcon}
            onClick={handleSignOut}
          />
        </div>
      )}
      <IconButton
        alt="toggle menu"
        boxSize={24}
        className="MenuToggle MenuItem"
        color="robinLight"
        icon={hamburgerIcon}
        onClick={handleToggleMenu}
      />
      <div>
        <RoutePortal context={routes} />
      </div>
      <div>
        {isSuperUser && (
          <MenuItem
            activePath="debug"
            icon={settingsIcon}
            location={location.pathname}
            route={RouteTable.application.toDebug}
            title="Settings"
          />
        )}
        <MenuItem
          activePath={['profile', 'institution']}
          icon={userIcon}
          location={location.pathname}
          route={RouteTable.application.toProfile}
          title="Profile"
        />
        <MenuItem
          activePath="help"
          icon={helpIcon}
          location={location.pathname}
          route={RouteTable.application.toHelp}
          title="Help"
        />
        <Logo className="MenuLogo" detailOnly={!isMenuOpen} withRouteTo />
      </div>
    </Menu>
  );
}

const mapStateToProps = state => ({
  isMenuOpen: state.getIn(ReduxDirectory.MenuKeyPath),
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleMenu() {
    dispatch(toggleMenu());
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SidebarMenu,
);
