import React from 'react';
import { compose } from 'recompose';
import RoundOnePrivateSbaPppLoanApplicationForm from '../roundOneSbaPppLoanApplication/RoundOnePrivateLoanApplicationFormContainer';
import RoundTwoPrivateSbaPppLoanApplicationForm from './RoundTwoPrivateLoanApplicationFormContainer';
import { withLoadingIndicator } from 'components';
import { RouteParams, withRouteParams } from 'routing';
import { DealQuery } from 'lsgql';
import { DealCategory, DealType } from 'types';

const OriginalAppCategory: DealCategory = 'SBA_PP';

function PrivateBorrowerApplicationFork(props: { deal: DealType }) {
  return props.deal.category === OriginalAppCategory ? (
    <RoundOnePrivateSbaPppLoanApplicationForm />
  ) : (
    <RoundTwoPrivateSbaPppLoanApplicationForm />
  );
}

const PrivateBorrowerApplicationForkContainer: any = compose(
  withRouteParams([RouteParams.dealId]),
  DealQuery,
  withLoadingIndicator('loading', 'deal'),
)(PrivateBorrowerApplicationFork);

export default PrivateBorrowerApplicationForkContainer;
