const alerts = {
  saveCompanySuccess: {
    message: 'Company saved successfully',
  },
  saveDealSuccess: {
    message: 'Ready for details, working group members, documents.',
    title: 'Deal Created',
  },
  saveTrancheSuccess: {
    message: 'Ready for fees, or to upload the term sheet and other documents.',
    title: 'Details Saved',
  },
  saveOrderBook: {
    message: 'Order Book Saved',
  },
  saveFolderSuccess: {
    message: 'Folder saved',
  },
  savePersonSuccess: {
    message: 'Person saved successfully',
  },
  generalError: {
    message: 'Something went wrong with the requested operation',
  },
};

export default alerts;
