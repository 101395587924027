import React, { useState } from 'react';
import Deals from './legacyDeals/DealsContainer';
import ForgivenessRequests from './forgivenessRequests/ForgivenessRequestsContainer';
import SingleDealBorrower from './singleDealBorrower/SingleDealBorrower';
import BorrowerDeals from './borrowerDeals/BorrowerDealsContainer';
import { WorkflowType, MyUserType } from 'types';
import { TabNavigationMenu } from 'components';
import { Redirect } from 'react-router-dom';

import './Main.scss';
import { RouteTable } from 'routing';

let singleBorrowerRedirectCount = 0;
let newSecondDrawUserRedirectCount = 0;

enum TabChoice {
  AllLoans = 'All Loans',
  PPPForgivenessRequests = 'PPP Forgiveness Requests',
}

type Props = {
  user: MyUserType;
};

const Main = (props: Props) => {
  const { user } = props;

  const defaultTabName = 'ALL_LOANS';
  const allTabs = [
    {
      name: TabChoice.AllLoans,
      id: defaultTabName,
    },
    {
      name: TabChoice.PPPForgivenessRequests,
      id: WorkflowType.SBA_PPP_FORGIVENESS_WORKFLOW,
    },
  ];

  const tabs = allTabs
    .filter(
      tab =>
        tab.id === defaultTabName ||
        user?.activePortfolioWorkflows.includes(tab.id),
    )
    .map(tab => tab.name);

  const [activeTab, setActiveTab] = useState<string>(tabs[0]);

  // Special cases for Borrowers
  if (
    newSecondDrawUserRedirectCount === 0 &&
    user.requiresSecondDrawOnboarding
  ) {
    newSecondDrawUserRedirectCount += 1;
    return <Redirect to={RouteTable.application.toSecondDrawApp()} />;
  }
  if (user.isDealBorrower) {
    if (singleBorrowerRedirectCount === 0 && user.isSingleDealBorrower) {
      singleBorrowerRedirectCount += 1;
      return <SingleDealBorrower />;
    } else {
      return <BorrowerDeals user={user} />;
    }
  }

  const renderContent = () => {
    if (activeTab === TabChoice.AllLoans) {
      return <Deals user={user} />;
    }
    if (activeTab === TabChoice.PPPForgivenessRequests) {
      return <ForgivenessRequests user={user} />;
    }
  };
  return (
    <div id="Main">
      {tabs.length > 1 && (
        <TabNavigationMenu
          activeTab={activeTab}
          onClick={setActiveTab}
          tabs={tabs}
        />
      )}
      {renderContent()}
    </div>
  );
};

export default Main;
