// !!!!! THIS IS AN AUTO-GENERATED FILE, DO NOT EDIT !!!!!

import { gql } from '@apollo/client';
import { FeeInput } from 'types';
import { invariant, strictConvertToInput } from 'utils';
import {
  default as validationBuilder,
  ValidationData,
  AdditionalOptions,
} from './builder/validationBuilder';
import { ApolloQueryResult } from '../types';
import { inputScrubber } from '../mutations/utils';

/* eslint-disable import/prefer-default-export */

const FeeValidatorDefinition = gql`
  query validateFee($feeData: FeeInput) {
    validateFee(feeData: $feeData) {
      errors
      errorFields {
        name
        value
      }
      warnings
      warningFields {
        name
        value
      }
    }
  }
`;

const propName = 'validateFee';

/**
 * Auto-Generated from the GraphQL Schema
 */
export const FeeValidator = validationBuilder<FeeInput>(
  FeeValidatorDefinition,
  {
    build: (
      validation: (
        options: AdditionalOptions,
      ) => Promise<ApolloQueryResult<ValidationData>>,
    ) => async (entity: FeeInput) => {
      const res: ApolloQueryResult<ValidationData> = await validation({
        variables: {
          feeData: { id: entity.id },
        },
      });
      invariant(res.data[propName], 'Expected res.data to contain validateFee');
      return res.data[propName];
    },
  },
);

export const FeeEntityValidator = validationBuilder<FeeInput>(
  FeeValidatorDefinition,
  {
    build: (
      validation: (
        options: AdditionalOptions,
      ) => Promise<ApolloQueryResult<ValidationData>>,
    ) => async (entity: FeeInput) => {
      const res: ApolloQueryResult<ValidationData> = await validation({
        variables: {
          feeData: strictConvertToInput({
            ...inputScrubber(entity),
            submitted: undefined,
            approved: undefined,
          }),
        },
      });
      invariant(res.data[propName], 'Expected res.data to contain validateFee');
      return res.data[propName];
    },
  },
);
