import isEmpty from 'lodash/isEmpty';
import { MutationMethod } from '../types';
import { extractMutationMethods } from './utils/extractMutationMethods';
import { Mutations, DispatchResponse } from './types';
import parseResponse from './parseResponse';
import { invariant, debug } from 'utils';

function debugLog(msg: string): void {
  debug(
    `%c▓ ${msg}`,
    'font-family:monospace; font-weight: bold; color: #db2828',
  );
}

const typeNameField = '__typename';

export default function deleteMutationBuilder(
  mutationMethods: {
    [key: string]: MutationMethod<any, any>;
  },
  pendingDeletes: {},
): Array<() => Promise<DispatchResponse<any>>> {
  invariant(
    mutationMethods && !isEmpty(mutationMethods),
    'mutationsDispatch requires mutationMethods',
  );

  if (!pendingDeletes || isEmpty(pendingDeletes)) return [];

  const deleteBatch = [];

  Object.keys(pendingDeletes).forEach(key => {
    if (Array.isArray(pendingDeletes[key]) && pendingDeletes[key].length > 0) {
      // get the delete mutation handler
      const first = pendingDeletes[key][0];
      const mutations: Mutations<any, any> = extractMutationMethods(
        first,
        mutationMethods,
      );

      invariant(
        mutations.delete,
        `Failed to find a delete method for ${first.__typename}`,
      );
      const deleteMutation = mutations.delete;
      deleteBatch.push(
        ...pendingDeletes[key].map(e => {
          async function executeDelete() {
            debugLog(`Delete id: ${e.id} - ${e[typeNameField]}`);
            const deleteRes = await deleteMutation(e.id);
            return parseResponse(key as any, deleteRes as any, {}, e.id);
          }

          return executeDelete;
        }),
      );
    }
  });

  return deleteBatch;
}
