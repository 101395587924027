import { Map, fromJS } from 'immutable';
import { MutatePropertiesAction } from '../types';
import { rootKey, flagDirty, getKeyPath } from '../methods';

/* eslint-disable import/prefer-default-export */

export function handleMutateProperties(
  state: any,
  action: MutatePropertiesAction,
) {
  const { keyPath: pendingKeyPath, slice } = action.payload;
  // this ensures the root object itself can be replaced
  const keyPath = pendingKeyPath ? getKeyPath(pendingKeyPath) : [rootKey];

  const pending = flagDirty(slice);
  const target: Map<string, any> = state.getIn(keyPath);
  const sliceMap: Map<string, any> = fromJS(pending);

  return state.setIn(keyPath, target.merge(sliceMap));
}
