import { fromJS, List, isIndexed } from 'immutable';
import { DeleteCollectionPayloadAction } from '../types';
import { getKeyPath, pendingDeleteKey, isDirty } from '../methods';
import {
  invariant,
  isWholeNumber,
  arrayToObject,
  getTypeNameString,
} from 'utils';
import { ObjectBaseTypeNames } from 'types';

/* eslint-disable import/prefer-default-export */

/**
 * Delete the full contents of a child collection
 * TODO: This currently does not move deleted entities to the pendingDeletes collection
 * @param {*} state
 * @param {*} action
 */
export function handleDeleteCollection(
  state: any,
  action: DeleteCollectionPayloadAction,
): Record<string, any> {
  const { keyPath: pendingKeyPath, discard } = action.payload;
  const keyPath = getKeyPath(pendingKeyPath);

  const toRemoveFromList = state.getIn(keyPath);

  invariant(isIndexed(toRemoveFromList), 'Expected a collection property');
  const toRemoveFrom = toRemoveFromList.toJS();

  invariant(Array.isArray(toRemoveFrom), 'KeyPath did not contain an array');

  // nothing to do
  if (toRemoveFrom.length === 0 || action.payload.entities.length === 0)
    return state;

  const typeKey: ObjectBaseTypeNames = getTypeNameString(
    toRemoveFrom[0].__typename,
  );

  const removeMap = arrayToObject(action.payload.entities, 'id', true);
  const itemsToDelete = action.payload.entities.filter(e =>
    isWholeNumber(e.id),
  );

  const toApply = fromJS(toRemoveFrom.filter(e => !removeMap[e.id]));

  if (discard || itemsToDelete.length === 0) {
    /*
    Either:
    a) Every entity was unsaved, so we don't need to let the backend know
    b) `discard` was true, regardless of saved status, we don't need to
    let the backend to know what we just did.  This would mean the backend
    is doing the delete for us
    */
    return state.setIn(keyPath, toApply);
  }

  const deleteCollection = (state.hasIn([pendingDeleteKey, typeKey])
    ? state.getIn([pendingDeleteKey, typeKey])
    : List([])
  ).toJS();

  return state
    .setIn(keyPath, toApply)
    .setIn([pendingDeleteKey, typeKey], [...deleteCollection, ...itemsToDelete])
    .set(isDirty, true);
}
