import * as React from 'react';
import cx from 'classnames';
import { Menu } from 'semantic-ui-react';
import { Icon, FontAwesomeIconTypes, RouteTo } from 'components';

import { ux, hoverMethods, invariant } from 'utils';

const MenuItemClassName = 'MenuItem';
export type Props = {
  /**
   * Current route path
   */
  location?: string;

  /**
   * Toggle as acive
   */
  active?: boolean;

  /**
   * if location is provided, and it includes
   * the value of activePath, toggle the MenuItem
   * as active
   */
  activePath?: string | Array<string>;

  className?: string;

  icon: FontAwesomeIconTypes;

  onClick?: () => void;

  route?: string;

  title?: string;
};

type State = {
  isHovered: boolean;
};

class MenuItem extends React.Component<Props, State> {
  handleHoverLeave: () => void;

  handleMouseEnter: () => void;

  handleMouseLeave: () => void;

  handleMouseMove: (e: React.MouseEvent<any>) => void;

  static defaultProps = {
    active: false,
  };

  constructor(props: Props) {
    super(props);

    invariant(
      (props.onClick || props.route) && !(props.route && props.onClick),
      'route or onClick must be provided, but not both',
    );

    this.state = { isHovered: false };
    this.handleHoverLeave = hoverMethods.handleHoverLeave.bind(this);
    this.handleMouseEnter = hoverMethods.handleMouseEnter.bind(this);
    this.handleMouseLeave = hoverMethods.handleMouseLeave.bind(this);
    this.handleMouseMove = hoverMethods.handleMouseMove.bind(this);
  }

  isActive() {
    const { active, activePath, location } = this.props;

    if (active) {
      return true;
    }

    if (activePath && location) {
      if (Array.isArray(activePath)) {
        return activePath.some(path => location.includes(path));
      }
      return location.includes(activePath);
    }

    return false;
  }
  render() {
    const { icon, route, onClick, title, className } = this.props;
    const { isHovered } = this.state;
    const active = this.isActive();

    const cs = cx(
      MenuItemClassName,
      ux(active, 'active'),
      ux(className, className),
      ux(isHovered, `${MenuItemClassName}--Hovered`),
    );

    return (
      <Menu.Item
        as={route ? RouteTo : undefined}
        className={cs}
        onClick={onClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        to={route}
      >
        <Icon
          boxSize={24}
          className={`${MenuItemClassName}__Icon`}
          color="fog"
          icon={icon}
        />
        <span className={`${MenuItemClassName}__Text`}>{title}</span>
      </Menu.Item>
    );
  }
}

export default MenuItem;
