import { SortBy } from 'types';

export const SORT_BY = 'auditTrail_SORT_BY';

export function changeSortField(payload: SortBy) {
  return {
    type: SORT_BY,
    payload,
  };
}

export type Action = { payload: Record<string, any>; type: string };
