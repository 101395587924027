import React from 'react';

import { Form, FormFieldProps } from 'components';

import { SelectOptionType } from 'types';
import { isWholeNumber } from 'utils';
import { IndustryCodeSearch } from 'lsgql';

type Props = FormFieldProps;
type State = {
  options: Array<SelectOptionType>;
};

export default class IndustryCodeContainer extends React.Component<
  Props,
  State
> {
  static defaultProps = {
    value: '',
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      options: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value && this.props.value !== nextProps.value) {
      const searchValue = this.getDescription(nextProps.value);
      this.handleFilterChange(searchValue);
    }
  }

  // remove six digit code and colon
  getDescription = (searchString: string) => searchString.substring(7).trim();

  handleFilterChange = (searchValue: string) => {
    if (searchValue.length > 2) {
      const isCode: boolean = isWholeNumber(searchValue);
      IndustryCodeSearch(
        isCode ? searchValue : undefined,
        !isCode ? searchValue : undefined,
      ).then(success => {
        const {
          data: { industryCodes = [] },
        } = success;
        const options = industryCodes.map(codeObj => ({
          value: `${codeObj.code}: ${codeObj.description}`,
          text: `${codeObj.code}: ${codeObj.description}`,
        }));
        this.setState({ options });
      });
    }
  };

  render() {
    return (
      <div style={{ width: this.props.width }}>
        <Form.Search
          {...this.props}
          onFilterChange={this.handleFilterChange}
          options={this.state.options}
        />
      </div>
    );
  }
}
