import * as React from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { salesRoutes } from '../../routing/routes/DealSubRoutes';
import {
  VIEW_INVESTOR_BOOK,
  VIEW_LENDER_REGISTRY,
} from '../../security/constants';
import { TabNavigationFrame } from 'components';
import { RouteTable } from 'routing';
import { PermissionFlags } from 'types';

type Props = {
  permissionsMap: Map<PermissionFlags, boolean>;
};

function SalesView(props: Props) {
  const location = useLocation();
  const { dealId } = useParams();
  const { permissionsMap } = props;

  function getTabsFromPermissions() {
    const tabs = [];
    if (permissionsMap.get(VIEW_INVESTOR_BOOK) === false) {
      tabs.push({
        text: 'Order Book',
        to: RouteTable.deal.toDealOrderBook(dealId),
      });
    }
    if (permissionsMap.get(VIEW_LENDER_REGISTRY) === false) {
      tabs.push({
        text: 'Lender Registry',
        to: RouteTable.deal.toDealLenderRegistryForm(dealId),
      });
    }
    return tabs;
  }

  if (location.pathname === RouteTable.deal.toDealSales(dealId)) {
    // we are on the root sales route and want to redirect to a default route.

    if (permissionsMap.get(VIEW_INVESTOR_BOOK) === false) {
      return <Redirect to={RouteTable.deal.toDealOrderBook(dealId)} />;
    }
    if (permissionsMap.get(VIEW_LENDER_REGISTRY) === false) {
      return <Redirect to={RouteTable.deal.toDealLenderRegistryForm(dealId)} />;
    }
    return <Redirect to={RouteTable.application.to404} />;
  }

  return (
    <TabNavigationFrame
      routeContext={salesRoutes}
      tabs={getTabsFromPermissions()}
    />
  );
}

export default SalesView;
