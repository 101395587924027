import * as React from 'react';
import { StandardModal, Form, WrappedMediatorFormProps } from 'components';

import { FolderType, FolderInput, ID, ValidationMap } from 'types';

type OwnProps = {
  dealId: string;
  formId: string;
  initializeState: () => void;
  isBorrower: boolean;
  isOpen: boolean;
  isSubfolder: boolean | null | undefined;
  loading: boolean;
  onClose: () => void;
  onSave: () => Promise<{ entityId: ID; success: boolean }>;
  openFolder: (folderId: string) => void;
  parentFolder?: FolderType | null | undefined;
  parentFolderId: string;
  setParentFolderId: (parentFolderId: string) => void;
  siblingNames: Array<string>;
};
type Props = WrappedMediatorFormProps<FolderInput> & OwnProps;

const nameField = {
  autoFocus: true,
  id: 'name',
  propertyName: 'name',
  fieldName: 'Folder Name',
  required: true,
  onChangeEventType: 'onKeyPress',
};

const shareCheckbox = {
  id: 'isPrivate',
  propertyName: 'isPrivate',
  label: 'Share with all deal participants',
};

class CreateFolderModal extends React.Component<Props> {
  static defaultProps = {
    siblingNames: [],
    parentFolder: null,
  };

  componentDidMount() {
    this.trySetParentFolderId(this.props);
    if (!this.props.isSubfolder) {
      this.props.mutateProperty(true, 'isPrivate');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.data && nextProps.parentFolder) {
      if (nextProps.data.parentId !== nextProps.parentFolder.id)
        nextProps.setParentFolderId(nextProps.parentFolder.id);
    }
    // modal closed, lets clear state
    if (nextProps.isOpen === false) this.props.initializeState();
  }

  componentWillUnmount() {
    this.props.initializeState();
  }

  handleSubmit = () => {
    if (
      !this.props.siblingNames
        .map(s => (s || '').toLowerCase())
        .includes((this.props.data.name || '').toLowerCase())
    ) {
      this.props.onSave().then(response => {
        if (response && response.success) {
          this.props.onClose();
          if (this.props.isSubfolder && this.props.parentFolderId) {
            this.props.openFolder(this.props.parentFolderId);
          }
        }
      });
    }
  };

  handleType = (
    value: (string | null | undefined) | (boolean | null | undefined),
    fieldId: string,
  ) => {
    this.props.mutateProperty(value, fieldId);
  };

  handleCheckboxChange = (value: boolean, fieldId: string) => {
    this.handleType(!value, fieldId);
  };

  getLocalError: () => ValidationMap | null | undefined = () => {
    const { errors, data, siblingNames } = this.props;

    const siblingNameAlreadyExistsError:
      | string
      | null
      | undefined = siblingNames.find(
      sibling =>
        (sibling || '').toLowerCase() ===
        (this.props.data.name || '').toLowerCase(),
    );

    if (siblingNameAlreadyExistsError) {
      const dupeError: ValidationMap = {
        Folder: {
          [data.id]: {
            errors: {
              name: `Subfolder "${siblingNameAlreadyExistsError}" already exists.`,
            },
            warnings: {},
          },
        },
      };

      const final = { ...errors, ...dupeError };

      return final;
    }

    return errors;
  };

  trySetParentFolderId(props: Props) {
    if (!props || !props.data) return;
    if (!props.parentFolder) return;
    const { parentFolder } = props;
    this.props.setParentFolderId(parentFolder.id);
  }

  renderBody = () => {
    const { data, loading, isSubfolder, parentFolder, isBorrower } = this.props;

    if (loading || !parentFolder) return null;

    return (
      <Form id={this.props.formId} onSubmit={this.handleSubmit}>
        {Form.FieldRenderer(
          Form.Input,
          { ...nameField, onChange: this.handleType },
          { ...this.props, errors: this.getLocalError() },
          'Folder',
          data.id,
        )}
        {!isSubfolder &&
          Form.FieldRenderer(
            Form.Checkbox,
            {
              ...shareCheckbox,
              onChange: this.handleCheckboxChange,
              value: !data.isPrivate,
              disabled: isBorrower,
            },
            this.props,
            'Folder',
            data.id,
          )}
        {isSubfolder && (
          <p>Subfolders will have the same access as the top-level folder.</p>
        )}
      </Form>
    );
  };

  render() {
    const { onCancel, isOpen, isSubfolder, parentFolder } = this.props;

    const name =
      this.props.parentFolder && this.props.parentFolder.name
        ? this.props.parentFolder.name
        : '';

    let header;
    if (parentFolder) {
      const folderOrSubfolder = isSubfolder ? 'Subfolder' : 'Folder';
      header = `Create ${folderOrSubfolder} in ${name}`;
    } else {
      header = 'Edit Folder';
    }
    return (
      <StandardModal
        header={header}
        isOpen={isOpen}
        onClose={onCancel}
        onConfirm={this.handleSubmit}
      >
        {isOpen && this.renderBody()}
      </StandardModal>
    );
  }
}

export default CreateFolderModal;
