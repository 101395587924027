// eslint-disable-next-line
const NODE_ENV = process.env.NODE_ENV;
const production = 'production';

/**
 * Determine if the current process is a production build
 */
export default function isProduction(): boolean {
  return NODE_ENV === production;
}
