import * as React from 'react';
import ReportLink from './ReportLink';
import { Divider, Text, Tiler } from 'components';
import { ReportType } from 'types';
import './ReportView.scss';

type Props = {
  latestReports: ReportType[];
  title: string;
};

function ReportView({ latestReports, title }: Props) {
  if (!latestReports) return null;

  return (
    <Tiler.Tile title={title} width="OneThird">
      <Text className="steel ReportViewSectionHeader">Current</Text>
      {latestReports.length > 0 && <ReportLink report={latestReports[0]} />}
      <Divider className="ReportViewDivider" />
      <Text className="steel ReportViewSectionHeader">Previous</Text>
      {latestReports.slice(1).map((report: ReportType) => (
        <ReportLink key={report.url} report={report} />
      ))}
    </Tiler.Tile>
  );
}

export default ReportView;
