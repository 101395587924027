import * as React from 'react';
import { noop } from 'lodash';
import {
  getKeyPath,
  getFeeSharingOptions,
  getFeeKindOptions,
  isFeeValid,
  removeExcessDataFromFee,
  getFeeSharingDisplayText,
  generateProRataHandlers,
} from '../utils';
import FeeAmountPercentageGrid from '../feeAmountPercentageGrid/FeeAmountPercentageGrid';
import FeePrepayment from '../feePrepayment/FeePrepayment';
import { KeyPath } from 'lsredux';
import {
  StandardModal,
  Form,
  Button,
  ConfirmModal,
  Divider,
  Header,
} from 'components';
import {
  FeeType,
  FeeKindType,
  FeeSharing,
  FeePaymentFrequency,
  LoanTrancheTierType,
  LoanTrancheType,
} from 'types';
import { isWholeNumber, invariant } from 'utils';

const width = '158px';
const maxWidth = '332px';
export type SelectOption = {
  text: string;
  value: string | null | undefined;
};

type Props = {
  addEntity: (keyPath: KeyPath, entity: any) => void;
  feeIndex: string | null | undefined;
  feeKindSlugMap: Map<string, FeeKindType>;
  feeToEdit: FeeType | null | undefined;
  onClose: () => void;
  removeEntity: (keyPath: KeyPath, entity: any) => void;
  replaceEntity: (keyPath: KeyPath, entity: any) => void;
  tiers: Array<LoanTrancheTierType>;
  trancheFeeGroupSetMap: Map<string, Set<string>>;
  trancheIndex: string | null | undefined;
  tranches: ReadonlyArray<LoanTrancheType>;
  undo: (keyPath: KeyPath) => void;
};

type State = {
  isConfirmDeleteModalOpen: boolean;
};

class FeeModalForm extends React.Component<Props, State> {
  state = {
    isConfirmDeleteModalOpen: false,
  };

  mutatePercent: (
    trancheKeyPath: KeyPath,
    fee: FeeType,
    newValue: string,
  ) => void;

  constructor(props: Props) {
    super(props);
    this.mutatePercent = generateProRataHandlers(
      'PERCENT',
      props.replaceEntity,
    ).bind(this);
  }

  handleClose = () => {
    const { undo, trancheIndex, onClose } = this.props;
    invariant(trancheIndex, 'TrancheIndex was not found!');
    undo(['loantrancheSet', trancheIndex]);
    onClose();
  };

  handleConfirm = () => {
    this.cleanFeeToEdit();
    this.props.onClose();
  };

  cleanFeeToEdit = () => {
    const { feeToEdit, feeKindSlugMap } = this.props;
    invariant(feeToEdit, 'Fee was not found!');

    const feeKindSlug = feeToEdit.feeKindSlug || '';

    const feeKind = feeKindSlugMap.get(feeKindSlug);
    if (feeToEdit && feeKind) {
      this.handleReplaceEntity(removeExcessDataFromFee(feeToEdit, feeKind));
    }
  };

  handleOpenConfirmDeleteModal = () =>
    this.setState({ isConfirmDeleteModalOpen: true });

  handleCloseConfirmDeleteModal = () =>
    this.setState({ isConfirmDeleteModalOpen: false });

  handleOnMakeWholeMonthsChange = (value: string | null | undefined) => {
    const { tranches, trancheIndex, replaceEntity } = this.props;
    const loanTranche = tranches[Number(trancheIndex)];

    const keyPath = ['loantrancheSet', trancheIndex || ''];
    if (loanTranche) {
      replaceEntity(keyPath, {
        ...loanTranche,
        prepaymentMakewholeMonths: value,
      });
    }
  };

  handleDeleteFee = () => {
    const { feeToEdit, removeEntity, trancheIndex, onClose } = this.props;
    this.handleCloseConfirmDeleteModal();
    if (feeToEdit) {
      const feeSetKeyPath = getKeyPath(trancheIndex);
      removeEntity(feeSetKeyPath, feeToEdit);
      onClose();
    }
  };

  handleOnFrequencyChange = (value: string | null | undefined) => {
    const { feeToEdit } = this.props;
    if (feeToEdit) {
      this.handleReplaceEntity({
        ...feeToEdit,
        paymentFrequency: (value as any) as
          | FeePaymentFrequency
          | null
          | undefined,
      });
    }
  };

  handleOnFeeTypeChange = (value: string | null | undefined) => {
    const { feeToEdit, feeKindSlugMap } = this.props;
    const feeKind = feeKindSlugMap.get(value || '');
    if (feeKind && feeToEdit) {
      let newSharing;
      if (
        feeToEdit.sharing &&
        feeKind.sharingOptions &&
        feeKind.sharingOptions.includes(feeToEdit.sharing)
      ) {
        newSharing = feeToEdit.sharing;
      } else {
        newSharing = feeKind.defaultSharingOption;
      }

      this.handleReplaceEntity({
        ...feeToEdit,
        sharing: newSharing,
        feeKindSlug: value,
      });
    }
  };

  handleOnFeeSharingChange = (value: string | null | undefined) => {
    const { feeToEdit } = this.props;

    if (feeToEdit) {
      this.handleReplaceEntity({
        ...feeToEdit,
        sharing: (value as any) as FeeSharing | null | undefined,
      });
    }
  };

  handleOnTitleChange = (value: string | null | undefined) => {
    const { feeToEdit } = this.props;
    if (feeToEdit) {
      this.handleReplaceEntity({
        ...feeToEdit,
        customLabel: value,
      });
    }
  };

  handleOnBorrowerPercentageChange = (value: string | null | undefined) => {
    const { feeToEdit, trancheIndex } = this.props;
    const trancheKeyPath = getKeyPath(trancheIndex);
    if (feeToEdit) {
      this.mutatePercent(trancheKeyPath, feeToEdit, value || '');
    }
  };

  handleReplaceEntity = (updatedFee: any) => {
    const { replaceEntity, trancheIndex } = this.props;

    const trancheKeyPath = getKeyPath(trancheIndex);
    replaceEntity(trancheKeyPath, updatedFee);
  };

  renderFormContent = () => {
    const {
      feeToEdit,
      feeKindSlugMap,
      feeIndex,
      tranches,
      trancheIndex,
      tiers,
      addEntity,
      replaceEntity,
      removeEntity,
    } = this.props;
    if (feeToEdit && feeIndex && trancheIndex) {
      const slug = feeToEdit.feeKindSlug || '';
      const feeKind = feeKindSlugMap.get(slug);
      const loanTranche = tranches[Number(trancheIndex)];

      if (feeKind) {
        return (
          <>
            {(Boolean(feeKind.hasPercentage) || Boolean(feeKind.hasAmount)) && (
              <div className="FeeModalForm__AmountPercentageContent">
                <div
                  className={`FeeModalForm__AmountPercentage${
                    !feeKind.hasAmount || !feeKind.hasPercentage ? '--Half' : ''
                  }`}
                >
                  <FeeAmountPercentageGrid
                    canEdit
                    fee={feeToEdit}
                    feeIndex={feeIndex}
                    feeKind={feeKind}
                    isDisplayedFromModal
                    replaceEntity={replaceEntity}
                    tiers={tiers}
                    trancheIndex={trancheIndex}
                  />
                </div>
              </div>
            )}
            {feeKind.slug === 'pre-payment-make-whole' && (
              <Form.Group>
                <Form.Numeric
                  fieldName="Months"
                  id={`Prepayment_Make_Whole_Months${feeToEdit.id}`}
                  onChange={this.handleOnMakeWholeMonthsChange}
                  propertyName="prepaymentMakewholeMonths"
                  value={loanTranche.prepaymentMakewholeMonths}
                  width={width}
                />
              </Form.Group>
            )}
            {feeKind.slug === 'pre-payment-optional' && (
              <FeePrepayment
                addEntity={addEntity}
                loanTranche={loanTranche}
                removeEntity={removeEntity}
                replaceEntity={replaceEntity}
                trancheIndex={trancheIndex}
              />
            )}
            {Boolean(feeKind.hasFrequency) && (
              <Form.Group>
                <Form.ReferenceSelect
                  fieldName="Payment Frequency"
                  id={`PaymentFrequency${feeToEdit.id}`}
                  onChange={this.handleOnFrequencyChange}
                  propertyName="paymentFrequency"
                  typeName="FeePaymentFrequency"
                  value={feeToEdit.paymentFrequency}
                  width={width}
                />
              </Form.Group>
            )}
            {this.renderFeeSettings(feeToEdit, feeKind)}
          </>
        );
      }
    }
    return null;
  };

  renderHeader = () => {
    const { feeToEdit } = this.props;
    if (feeToEdit && isWholeNumber(feeToEdit.id)) {
      return `Edit Fee - `;
    }

    return 'Add New Fee';
  };

  renderFeeSettings = (fee: FeeType, feeKind: FeeKindType) => {
    const feeKindSharingOptions = feeKind.sharingOptions || [];
    if (feeKindSharingOptions.length > 0) {
      if (feeKindSharingOptions.length === 1) {
        return (
          <Form.Group>
            <Form.ReadOnly
              fieldName="Fee Settings"
              id={`Sharing${fee.id}`}
              onChange={noop}
              propertyName="sharing"
              value={getFeeSharingDisplayText(fee.sharing)}
              width={width}
            />
          </Form.Group>
        );
      }
      const options = getFeeSharingOptions(feeKind);
      return (
        <Form.Group>
          <Form.Select
            allowEmpty={false}
            fieldName="Fee Settings"
            id={`Sharing${fee.id}`}
            onChange={this.handleOnFeeSharingChange}
            options={options}
            propertyName="sharing"
            value={fee.sharing}
            width={width}
          />
        </Form.Group>
      );
    }
    return null;
  };

  renderFooter = () => {
    const {
      feeToEdit,
      feeKindSlugMap,
      tiers,
      tranches,
      trancheIndex,
    } = this.props;
    const { isConfirmDeleteModalOpen } = this.state;
    const numberOfTiers = tiers.length;
    const loanTranche = tranches[Number(trancheIndex)];
    if (feeToEdit) {
      const feeKind = feeKindSlugMap.get(feeToEdit.feeKindSlug || '');
      const feeLabel = feeKind && feeKind.label ? feeKind.label : 'Fee';

      return (
        <div className="FeeModalForm__Footer">
          <div className="FeeModalForm__DeleteButton">
            {isWholeNumber(feeToEdit.id) && (
              <Button.Text
                label="Delete this Fee"
                onClick={this.handleOpenConfirmDeleteModal}
              />
            )}
          </div>
          <div className="FeeModalForm__ButtonGroup">
            <Button.Secondary label="Cancel" onClick={this.handleClose} />
            <Button
              className="FeeModalForm__SubmitButton"
              disabled={
                !isFeeValid(loanTranche, feeToEdit, feeKind, numberOfTiers)
              }
              label="Submit"
              onClick={this.handleConfirm}
            />
          </div>
          <ConfirmModal
            header="Delete Fee"
            isOpen={isConfirmDeleteModalOpen}
            message={`Are you sure you want to delete this ${feeLabel}
            ?`}
            onConfirm={this.handleDeleteFee}
            onReject={this.handleCloseConfirmDeleteModal}
          />
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      feeToEdit,
      feeKindSlugMap,
      trancheFeeGroupSetMap,
      tranches,
      trancheIndex,
    } = this.props;

    const loanTranche = tranches[Number(trancheIndex)];
    if (loanTranche === null || loanTranche === undefined) {
      return null;
    }

    const loanTrancheName = loanTranche ? loanTranche.name : '';
    const slug = (feeToEdit && feeToEdit.feeKindSlug) || '';
    const feeKind = feeKindSlugMap.get(slug);

    const feeKindOptions = getFeeKindOptions(
      feeKindSlugMap,
      trancheFeeGroupSetMap,
      loanTranche,
      feeKind,
    );

    return (
      <StandardModal
        className="FeeModal steel"
        confirmButtonText="Submit"
        footer={this.renderFooter()}
        header={this.renderHeader()}
        isOpen={Boolean(feeToEdit)}
        onClose={this.handleClose}
        onConfirm={this.handleConfirm}
        requireResponse
        width="Half"
      >
        {feeToEdit && (
          <Form className="FeeModalForm" id={feeToEdit.id} onSubmit={noop}>
            <Divider />
            <Header as="h5" className="caps steel">
              {loanTrancheName}
            </Header>
            <Divider className="transparent" thin />
            <Form.Group
              className={`${
                feeKind && feeKind.slug === 'pre-payment-optional'
                  ? 'FeeModalForm__Group--PaddedRight'
                  : ''
              }`}
            >
              <Form.Select
                fieldName="Fee Type"
                id={`FeeType${feeToEdit.id}`}
                onChange={this.handleOnFeeTypeChange}
                options={feeKindOptions}
                propertyName="feeKindSlug"
                value={feeToEdit.feeKindSlug}
                width={maxWidth}
              />
              {feeKind && feeKind.slug === 'agent' && (
                <Form.Input
                  fieldName="Title"
                  id={`TitleInput${feeToEdit.id}`}
                  onChange={this.handleOnTitleChange}
                  placeholder="Title"
                  propertyName="customLabel"
                  value={feeToEdit.customLabel}
                  width={width}
                />
              )}
              {feeKind &&
                feeKind.slug === 'initial-drawdown' &&
                feeToEdit.sharing === 'NON_PRO_RATA' && (
                  <Form.Percentage
                    fieldName="Borrower Percentage"
                    id={`Borrower_Percentage${feeToEdit.id}`}
                    onChange={this.handleOnBorrowerPercentageChange}
                    propertyName="appliedRate"
                    value={
                      feeToEdit.feecalculationrule
                        ? feeToEdit.feecalculationrule.appliedRate
                        : ''
                    }
                    width={width}
                  />
                )}
            </Form.Group>
            {this.renderFormContent()}
          </Form>
        )}
      </StandardModal>
    );
  }
}

export default FeeModalForm;
