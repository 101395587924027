import { DealStage } from 'types';

const postCloseStageSet = new Set<DealStage>(['SERVICING', 'INACTIVE']);

/**
 * Determine if deal is closed (i.e. stage is either "SERVICING" or "INACTIVE"
 */
function isDealClosed(dealStage: DealStage | null | undefined): boolean {
  return Boolean(dealStage && postCloseStageSet.has(dealStage));
}

export default isDealClosed;
