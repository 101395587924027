import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import {
  DealInstitutionBasicFields,
  OrganizationFolderRoleShallowFields,
  RoleShallowFields,
} from '../fragments/generated';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { FolderOrganizationFolderRolesType } from 'types';

const FolderOrganizationFolderRolesDefinition: DocumentNode = gql`
  query FolderOrganizationFolderRolesQuery($folderId: ID) {
    folderOrganizationFolderRoles(folderId: $folderId) {
      ...OrganizationFolderRoleShallowFields
      role {
        ...RoleShallowFields
      }
      institution {
        ...DealInstitutionBasicFields
      }
    }
  }
  ${OrganizationFolderRoleShallowFields}
  ${RoleShallowFields}
  ${DealInstitutionBasicFields}
`;

const FolderOrganizationFolderRolesQuery = standardQueryBuilder(
  FolderOrganizationFolderRolesDefinition,
  {
    typename: 'FolderOrganizationFolderRoles',
    disableCache: true,
    skip: ({ folderId }: { folderId: string | null | undefined }) => !folderId,
    variables: ({ folderId }: { folderId: string | null | undefined }) => ({
      folderId,
    }),
    results: (data: {
      folderOrganizationFolderRoles:
        | Array<FolderOrganizationFolderRolesType>
        | null
        | undefined;
    }) => {
      const { folderOrganizationFolderRoles } = data;
      return { folderOrganizationFolderRoles };
    },
  },
);

export default FolderOrganizationFolderRolesQuery;
