import React, { useState } from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { compose } from 'recompose';
import { SbaPppForgivenessWorkflowType, UserType } from 'types';
import { MyUserQuery } from 'lsgql/queries';
import { Dropdown } from 'components';
import transitionForgivenessWorkflowMutation from 'lsgql/mutations/TransitionForgivenessWorkflow';

type Props = {
  sbaPppForgivenessWorkflow: SbaPppForgivenessWorkflowType;
  user: UserType;
};

const options = [
  { text: 'Unsubmitted', value: 'UNSUBMITTED' },
  { text: 'In Review', value: 'IN_REVIEW' },
  { text: 'Changes Requested', value: 'CHANGES_REQUESTED' },
  { text: 'Change Submitted', value: 'CHANGE_SUBMITTED' },
  {
    text: 'Borrower Signature Requested',
    value: 'BORROWER_SIGNATURE_REQUESTED',
  },
  { text: 'Approved', value: 'APPROVED' },
  { text: 'Rejected', value: 'REJECTED' },
  { text: 'SBA Approved', value: 'SBA_APPROVED' },
  { text: 'SBA Pending Validation', value: 'SBA_PENDING_VALIDATION' },
  { text: 'SBA Under Review', value: 'SBA_UNDER_REVIEW' },
  { text: 'SBA Fully Approved', value: 'SBA_FULLY_APPROVED' },
  { text: 'SBA Not Approved', value: 'SBA_NOT_APPROVED' },
  { text: 'SBA Partially Approved', value: 'SBA_PARTIALLY_APPROVED' },
  {
    text: 'SBA Lender Additional Info Requested',
    value: 'SBA_LENDER_ADDITIONAL_INFO_REQUESTED',
  },
  { text: 'SBA Payment Sent', value: 'SBA_PAYMENT_SENT' },
  { text: 'SBA Payment Confirmed', value: 'SBA_PAYMENT_CONFIRMED' },
  { text: 'SBA Payment Failed', value: 'SBA_PAYMENT_FAILED' },
];

function Debug(props: Props) {
  const { user, sbaPppForgivenessWorkflow } = props;
  const [isOpen, setIsOpen] = useState(false);

  if (!sbaPppForgivenessWorkflow) return null;

  function handleStateChange(e: React.ChangeEvent, data: DropdownProps) {
    const newState = data.value;
    transitionForgivenessWorkflowMutation({
      workflowId: sbaPppForgivenessWorkflow.id,
      state: newState as string,
    });
  }

  return (
    user &&
    user.isSuperuser && (
      <div>
        <Dropdown
          id="debug-state-toggle"
          isOpen={isOpen}
          onChange={handleStateChange}
          onToggle={() => setIsOpen(!isOpen)}
          options={options}
          value={sbaPppForgivenessWorkflow.state}
        />
      </div>
    )
  );
}

export default compose(MyUserQuery)(Debug);
