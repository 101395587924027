import * as React from 'react';
import moment from 'moment';
import { DisplayTable } from 'components';
import { lsSort } from 'utils';
import { AuditTrailEntryType, SortBy } from 'types';

const columns = [
  { name: 'date' },
  { name: 'section' },
  { name: 'item' },
  { name: 'changed from' },
  { name: 'changed to' },
  { name: 'changed by' },
  { name: 'institution' },
];

type Props = {
  changeSortField: (arg0: SortBy) => void;
  rows: Array<AuditTrailEntryType>;
  sortBy: SortBy;
};

const columnMap = {
  date: 'dateInMilliseconds',
  section: 'sectionName',
  item: 'itemName',
  'changed from': 'changedFrom',
  'changed to': 'changedTo',
  'changed by': 'user',
};

class ReportViewTable extends React.Component<Props> {
  render() {
    const { rows, sortBy, changeSortField } = this.props;

    const sortedItems = lsSort(rows, sortBy, columnMap);

    return (
      <DisplayTable
        className="auditTrailViewTable"
        columns={columns}
        hoverable
        onChangeSortField={changeSortField}
        sortBy={sortBy}
      >
        {sortedItems.map((item: AuditTrailEntryType) => (
          <DisplayTable.TR
            className="DisplayTable-TableRowCell__Clickable"
            key={item.id}
          >
            <DisplayTable.TD>
              {moment(item.date).format('YYYY/MM/DD - h:mm A')}
            </DisplayTable.TD>
            <DisplayTable.TD>{item.sectionName}</DisplayTable.TD>
            <DisplayTable.TD>{item.itemName}</DisplayTable.TD>
            <DisplayTable.TD>{item.changedFrom}</DisplayTable.TD>
            <DisplayTable.TD>{item.changedTo}</DisplayTable.TD>
            <DisplayTable.TD>{item.user}</DisplayTable.TD>
            <DisplayTable.TD>{item.institution}</DisplayTable.TD>
          </DisplayTable.TR>
        ))}
      </DisplayTable>
    );
  }
}

export default ReportViewTable;
