import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StepProps } from '../StepProps';
import WorkflowStep from '../WorkflowStep';
import { invariant } from 'utils';
import originateLoan from 'lsgql/mutations/OriginateLoan';
import raiseAlert from 'lsredux/raiseAlert';
import { Text, Form, Button, Modal } from 'components';
import { RouteTable } from 'routing';

function LenderLoanFunding(props: StepProps) {
  const { step, dealId, isDimmed, canEdit, deal } = props;
  invariant(step.slug === 'aw_lender_loan_funding', 'invalid slug');

  const [fundingDate, setFundingDate] = useState(deal.fundingDate);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  async function handleConfirm() {
    const res = await originateLoan({ dealId, fundingDate });

    if (res.errors) {
      raiseAlert(
        'Something went wrong. Please try again or contact technical support.',
        'error',
      );
    } else {
      raiseAlert('Success! This loan has been recorded as funded.', 'success');
      history.push(RouteTable.deal.toLoanOverviewSummary(dealId));
    }
  }

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    <WorkflowStep
      canEdit={canEdit}
      header="LOAN FUNDING"
      isDimmed={isDimmed}
      step={step}
    >
      <div className="Group padded">
        <Text>
          {(!deal.fundingDate || step.isEditable) &&
            `Enter the funding date and click below to originate the loan.`}
        </Text>
      </div>
      <div className="Group padded">
        <Form.Calendar
          disabled={!step.isEditable}
          disabledReadable={!!deal.fundingDate && !step.isEditable}
          fieldName="Funding Date"
          id="fundingDate"
          onChange={setFundingDate}
          picking="day"
          propertyName="fundingDate"
          required={true}
          value={fundingDate}
          width="150px"
        />
      </div>
      <div className="Group padded4">
        {step.isEditable && (
          <Button
            disabled={!fundingDate}
            label={'Originate Loan'}
            onClick={openModal}
          />
        )}
      </div>
      <Modal
        className="steel confirmLoan"
        confirmButtonText="Originate Loan"
        header="Confirm Loan Origination"
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleConfirm}
        requireResponse
        width="OneThird"
      >
        <Text className="bold ink originate">
          Are you ready to originate this loan?{' '}
        </Text>
        <Text>
          When you click below, the loan will enter the Funded stage and you
          will no longer be able to make any changes.
        </Text>
      </Modal>
    </WorkflowStep>
  );
}

export default LenderLoanFunding;
