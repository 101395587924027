import * as React from 'react';
import WorkflowContentShell from '../WorkflowContentShell';
import { WorkflowItem, WorkflowSection } from '../workflowList';
import { InstructionsHelpBox } from './InstructionsHelpBox';
import { InstructionsCTAButtons } from './ctaButtons/InstructionsCTAButtons';
import { InstructionsPropsType } from './types';
import { ApplicationSubmissionSection } from './ApplicationSubmissionSection';
import { getResources } from './Resources';
import { RouteTable } from 'routing';
import { Text, RouteTo, TextLink } from 'components';

const SUPPORT_EMAIL = 'support@loan-street.com';

function Instructions(props: InstructionsPropsType) {
  const { deal, sbaPppForgivenessWorkflow } = props;

  if (!sbaPppForgivenessWorkflow) {
    return null;
  }

  const { canEdit, isLender, isSFormEligible } = sbaPppForgivenessWorkflow;
  const dealId = deal.id;

  const resources = getResources(isLender, isSFormEligible);

  const formLinkText = isLender ? 'Review' : 'Edit';

  return (
    <WorkflowContentShell
      ctaButtons={() => <InstructionsCTAButtons {...props} />}
      helpBox={
        <InstructionsHelpBox
          isLender={isLender}
          isSFormEligible={isSFormEligible}
        />
      }
      title={
        isSFormEligible
          ? 'PPP Loan Forgiveness Form 3508S Instructions'
          : 'PPP Loan Forgiveness Instructions'
      }
    >
      {!isLender && isSFormEligible ? (
        <>
          <div className="readOnlyText">
            <p className="textLine ConstrictedContent">
              A Borrower may use Form 3508S only if the Borrower received a PPP
              loan of $150,000 or less. A Borrower that, together with its
              affiliates, received PPP loans $2 million or greater cannot use
              this form. If you have any questions, please contact{' '}
              <TextLink label={SUPPORT_EMAIL} to={`mailto:${SUPPORT_EMAIL}`} />
              <br />
              To apply for loan forgiveness, complete the following for your
              Lender’s review:
            </p>
          </div>
          <br />
          <Text
            className="ConstrictedContent"
            value="To apply for loan forgiveness, complete the following for your Lender’s review:"
          />
        </>
      ) : (
        <Text className="ConstrictedContent" value={resources.titleText} />
      )}

      <WorkflowSection header="1. APPLICATION FORMS">
        <WorkflowItem label="Loan Information">
          {canEdit && (
            <TextLink
              as={RouteTo}
              label={formLinkText}
              to={RouteTable.deal.toSbaPppForgivenessWorkflowLoanInformation(
                dealId,
              )}
            />
          )}
        </WorkflowItem>
        <WorkflowItem label={resources.forgivenessTitle}>
          {canEdit && (
            <TextLink
              as={RouteTo}
              label={formLinkText}
              to={RouteTable.deal.toSbaPppForgivenessWorkflowCalculation(
                dealId,
              )}
            />
          )}
        </WorkflowItem>
      </WorkflowSection>

      <WorkflowSection header="2. DOCUMENTATION">
        <WorkflowItem label={resources.documentationTitle}>
          {canEdit && (
            <TextLink
              as={RouteTo}
              label={formLinkText}
              to={
                isLender
                  ? RouteTable.deal.toDealDataroom(dealId)
                  : RouteTable.deal.toSbaPppForgivenessWorkflowDocumentation(
                      dealId,
                    )
              }
            />
          )}
        </WorkflowItem>
        <WorkflowItem boldLabel={!isLender} label={resources.worksheetTitle}>
          {canEdit && !isLender && (
            <TextLink
              as={RouteTo}
              label="Upload"
              to={RouteTable.deal.toSbaPppForgivenessWorkflowDocumentation(
                dealId,
              )}
            />
          )}
        </WorkflowItem>
      </WorkflowSection>

      <ApplicationSubmissionSection {...props} />
    </WorkflowContentShell>
  );
}

export default Instructions;
