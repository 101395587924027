import { gql } from '@apollo/client';

const NextBusinessDayQuery = gql`
  query BusinessDay($businessDay: Date!) {
    businessDay(businessDay: $businessDay) {
      followingBusinessDay
    }
  }
`;

export default NextBusinessDayQuery;
