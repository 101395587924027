import { mutationBuilder } from './builders';
import {
  CreateAdditionalSbaPppBorrowerInformationMutationDefinition,
  SetAdditionalSbaPppBorrowerInformationMutationDefinition,
} from './definitions';
import {
  AdditionalSbaPppBorrowerInformationInput,
  AdditionalSbaPppBorrowerInformationType,
} from 'types';

const AdditionalSbaPppBorrowerInformationMutationMethods = {
  createAdditionalSbaPppBorrowerInformation: mutationBuilder<
    AdditionalSbaPppBorrowerInformationInput,
    AdditionalSbaPppBorrowerInformationType
  >(CreateAdditionalSbaPppBorrowerInformationMutationDefinition, {
    build: mutate => additionalSbaPppBorrowerInformationData =>
      mutate({ variables: { additionalSbaPppBorrowerInformationData } }),
  }),
  setAdditionalSbaPppBorrowerInformation: mutationBuilder<
    AdditionalSbaPppBorrowerInformationInput,
    AdditionalSbaPppBorrowerInformationType
  >(SetAdditionalSbaPppBorrowerInformationMutationDefinition, {
    build: mutate => additionalSbaPppBorrowerInformationData =>
      mutate({ variables: { additionalSbaPppBorrowerInformationData } }),
  }),
};

export default AdditionalSbaPppBorrowerInformationMutationMethods;
