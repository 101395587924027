import uuid from 'uuid/v4';
import * as React from 'react';
import { getConvertToSelectOption } from './utils';
import { SelectOptionType, RoleType, UserRoleType, AlertType } from 'types';
import { Button, Form, IconButton } from 'components';
import { KeyPath } from 'lsredux';

import { invariant } from 'utils';

type Props = {
  addEntity: (keyPath: KeyPath, entity: UserRoleType) => void;
  keyPath: KeyPath;
  organizationId: string | null | undefined;
  removeEntity: (keyPath: KeyPath, entity: UserRoleType) => void;
  roles: RoleType[];
  setAlert: (alert: string, alertType: AlertType | null | undefined) => void;
  userOptions: SelectOptionType[];
  userRoleList: UserRoleType[];
  whitelist: Set<string>;
};

const DEAL = 'Deal';

type State = {
  selectedRoleId: string | null | undefined;
  selectedUserId: string | null | undefined;
};

function getDisplayMapFromOptions(
  map: Map<string, string>,
  option: SelectOptionType,
) {
  return map.set(option.value, option.text);
}

class UserRoleTable extends React.Component<Props, State> {
  fields: {
    [key: string]: any;
  };

  state = {
    selectedRoleId: null,
    selectedUserId: null,
  };

  constructor(props: Props) {
    super(props);
    this.fields = {
      users: {
        id: 'users',
        fieldName: 'Users',
        propertyName: 'Users',
        onChange: this.handleUserChange,
        width: 'four',
      },
      roles: {
        id: 'roles',
        fieldName: 'Role',
        propertyName: 'Role',
        onChange: this.handleRoleChange,
        width: 'four',
      },
    };
  }

  handleUserChange = (value: string) => {
    this.setState({ selectedUserId: value });
  };

  handleRoleChange = (value: string) => {
    this.setState({ selectedRoleId: value });
  };

  getHandleRemove = (entity: UserRoleType) => () => {
    const { removeEntity, keyPath } = this.props;
    removeEntity(keyPath, entity);
  };

  handleAddUserRole = () => {
    const { addEntity, setAlert, userRoleList, keyPath } = this.props;
    const { selectedRoleId, selectedUserId } = this.state;
    invariant(selectedUserId, 'Selected User Not Found');
    invariant(selectedRoleId, 'Selected Role Not Found');
    if (
      userRoleList.some(
        userRole =>
          userRole.roleId === selectedRoleId &&
          userRole.userId === selectedUserId,
      )
    ) {
      setAlert(
        'This User and Role combination has already been added',
        'error',
      );
    } else {
      addEntity(keyPath, {
        userId: selectedUserId,
        roleId: selectedRoleId,
        id: uuid(),
        __typename: 'UserRoleInput',
      });
      this.handleClear();
    }
  };

  handleClear = () => {
    this.setState({
      selectedUserId: null,
      selectedRoleId: null,
    });
  };

  render() {
    const {
      userOptions,
      roles,
      organizationId,
      userRoleList,
      whitelist,
    } = this.props;
    const { selectedUserId, selectedRoleId } = this.state;
    const canAdd = selectedUserId && selectedRoleId;
    const roleOptions = (roles || [])
      .filter(role => whitelist.has(role.slug || ''))
      .map(getConvertToSelectOption('name'));
    const roleIdMap = roleOptions.reduce(getDisplayMapFromOptions, new Map());
    const userIdMap = userOptions.reduce(getDisplayMapFromOptions, new Map());

    return (
      <>
        {userOptions.length === 0 && organizationId !== '' && (
          <div className="UserRoleTable__Error">
            There are no users in this institution!
          </div>
        )}
        <Form.Group>
          {Form.FieldRenderer(
            Form.Select,
            {
              ...this.fields.users,
              options: userOptions,
              value: selectedUserId,
            },
            { data: this.props },
            DEAL,
            'DebugDeal__Names',
          )}
          {Form.FieldRenderer(
            Form.Select,
            {
              ...this.fields.roles,
              options: roleOptions,
              value: selectedRoleId,
            },
            { data: this.props },
            DEAL,
            'DebugDeal__Roles',
          )}
        </Form.Group>
        <Button
          disabled={!canAdd}
          label="Add User"
          onClick={this.handleAddUserRole}
        />
        {userRoleList.length > 0 && (
          <div className="UserRoleTable__UserContainer">
            {userRoleList.map(userRole => (
              <Form.Group className="UserRoleTable__Row" key={userRole.id}>
                <div className="UserRoleTable__Text">
                  {userIdMap.get(userRole.userId || '')}
                </div>
                <div className="UserRoleTable__Text">
                  {roleIdMap.get(userRole.roleId || '')}
                </div>
                <IconButton
                  alt="delete"
                  icon="trash"
                  onClick={this.getHandleRemove(userRole)}
                />
              </Form.Group>
            ))}
          </div>
        )}
      </>
    );
  }
}

export default UserRoleTable;
