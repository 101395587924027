import * as React from 'react';
import AmortizationForm from '../amortization/AmortizationForm';
import { LoanTrancheFormProps } from '../types';
import {
  isLetterOfCredit,
  isRevolver,
  isSwingline,
  synchronizeInterestAndAmortizationTypes,
} from './utils';
import TrancheGroup from './TrancheGroup';
import { MountGuard, DealPerspectivePermissions } from 'security';
import { isWholeNumber } from 'utils';
import { ConfirmModal, Form, IconButton, TypeQueryResult } from 'components';

import './BaseFields.scss';

const loanTranche = 'LoanTranche';
const width = '158px';
const largeWidth = '332px';

const validInterestTypes = new Set([
  'FIXED_RATE',
  'FIXED_PAYMENT',
  'FLOATING',
  'ADJUSTABLE_RATE',
]);

function interestTypeFilter(
  queryResults: Array<TypeQueryResult>,
): Array<TypeQueryResult> {
  /*
  TODO: Remove this demo hack NX-154
  */
  return queryResults
    .filter(e => validInterestTypes.has(e.value))
    .map(e => {
      if (e.value === 'FLOATING') {
        return { ...e, text: 'Floating Rate' };
      }
      return e;
    });
}

type State = {
  isModalOpen: boolean;
};

/**
 * Provides the initial fields common to all Loan Tranche states
 *
 */
class BaseFields extends React.Component<LoanTrancheFormProps, State> {
  state = { isModalOpen: false };

  /* eslint-disable-next-line */
  commitmentDrawTypeOnChange(
    newValue: string | null | undefined,
    propertyName: string,
  ) {
    this.props.mutateProperty(newValue, propertyName);
    if (newValue === 'LETTER_OF_CREDIT') {
      this.props.mutateProperty(null, 'interestType');
    }
  }

  fields = {
    name: {
      id: 'name',
      propertyName: 'name',
      fieldName: 'Name',
      onChange: this.props.mutateProperty,
      fluid: true,
      width: largeWidth,
      tabIndex: this.props.tabIndex,
    },
    interestType: {
      id: 'interestType',
      propertyName: 'interestType',
      fieldName: 'Interest Type',
      onChange: this.props.mutateProperty,
      typeName: 'LoanTrancheInterestType',
      width: width,
      resultFilter: interestTypeFilter,
      tabIndex: this.props.tabIndex,
    },
    commitmentDrawType: {
      id: 'commitmentDrawType',
      propertyName: 'commitmentDrawType',
      fieldName: 'Draw Type',
      onChange: this.commitmentDrawTypeOnChange.bind(this),
      typeName: 'LoanTrancheCommitmentDrawType',
      width: width,
      tabIndex: this.props.tabIndex,
    },
  };

  componentDidMount() {
    const {
      dealId,
      data,
      mutateProperty,
      handleSetNewTrancheName,
    } = this.props;

    if (!isWholeNumber(data.id) && !data.name) {
      handleSetNewTrancheName();
    }
    if (data.issuePrice === null || data.issuePrice === undefined) {
      mutateProperty('1.00', 'issuePrice');
    }

    if (dealId !== (data as any).dealId) {
      mutateProperty(dealId, 'dealId');
    }
  }

  componentDidUpdate(prevProps: LoanTrancheFormProps) {
    if (prevProps.data.interestType !== this.props.data.interestType) {
      synchronizeInterestAndAmortizationTypes(this.props);
    }
  }

  getDeleteTrancheWarning = () => {
    const { data } = this.props;
    const { name, id } = data;
    return `Are you sure you want to permanently remove ${name ||
      'this loan tranche'}?${
      isWholeNumber(id)
        ? ' Note that all changes to other tranches will be saved.'
        : ''
    }`;
  };

  handleDeleteTranche = () => {
    const { data, removeSelf, handleDeleteTranche } = this.props;
    const { id } = data;
    this.setState({ isModalOpen: false });
    if (isWholeNumber(id)) {
      if (handleDeleteTranche) handleDeleteTranche(id);
    } else {
      removeSelf(data);
    }
  };

  handleCancelDelete = () => this.setState({ isModalOpen: false });

  handleConfirmDelete = () => this.setState({ isModalOpen: true });

  render() {
    const { data, isOnlyTranche } = this.props;
    const letterOfCredit = isLetterOfCredit(data);
    const revolver = isRevolver(data);
    const swingline = isSwingline(data);

    return (
      <TrancheGroup>
        <Form.Group>
          <Form.Header
            as="h3"
            className="BaseFields__Header steel"
            header={data.name || ''}
          />
          {!isOnlyTranche && (
            <MountGuard
              permission={DealPerspectivePermissions.administer_loan_tranches}
            >
              <IconButton.Delete
                alt="Delete Tranche"
                className="BaseFields__DeleteTrancheButton"
                onClick={this.handleConfirmDelete}
              />
            </MountGuard>
          )}
        </Form.Group>
        <ConfirmModal
          header="Warning"
          isOpen={this.state.isModalOpen}
          message={this.getDeleteTrancheWarning()}
          onConfirm={this.handleDeleteTranche}
          onReject={this.handleCancelDelete}
        />
        <Form.Group>
          {Form.FieldRenderer(
            Form.Input,
            this.fields.name,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {!letterOfCredit &&
            Form.FieldRenderer(
              Form.ReferenceSelect,
              {
                ...this.fields.interestType,
                disabled: letterOfCredit,
              },
              this.props,
              loanTranche,
              data.id,
            )}
          {Form.FieldRenderer(
            Form.ReferenceSelect,
            {
              ...this.fields.commitmentDrawType,
              className: letterOfCredit
                ? 'BaseFields__CommitmentDrawTypeSelect--LetterOfCreditTranche'
                : '',
            },
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
        {!(letterOfCredit || revolver || swingline) && (
          <AmortizationForm {...this.props} />
        )}
      </TrancheGroup>
    );
  }
}

export default BaseFields;
