import { MutationMethod } from '../types';
import { inputScrubber } from './utils';
import { mutationBuilder } from './builders';
import { MutationExecutor } from './builders/mutationBuilder';
import { CreateEventScheduleTemplateMutationDefinition } from './definitions';
import { EventScheduleTemplateInput, EventScheduleTemplateType } from 'types';

const createEventScheduleTemplate: MutationMethod<
  EventScheduleTemplateInput,
  EventScheduleTemplateType
> = mutationBuilder<EventScheduleTemplateInput, EventScheduleTemplateType>(
  CreateEventScheduleTemplateMutationDefinition,
  {
    build: (mutate: MutationExecutor<EventScheduleTemplateType>) => (
      eventScheduleTemplateData: EventScheduleTemplateInput,
    ) =>
      mutate({
        variables: {
          eventScheduleTemplateData: inputScrubber(eventScheduleTemplateData),
        },
      }),
  },
);

const EventScheduleTemplateMutationMethods = {
  createEventScheduleTemplate,
};

export default EventScheduleTemplateMutationMethods;
