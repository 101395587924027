import { Global } from '../constants';
import { ADD_DEAL, VIEW_HOME_BUTTON } from '../../constants';
import { PermissionDefinitions } from './types';

const GlobalPerspectivePermissions: PermissionDefinitions = {
  add_deal: {
    flag: ADD_DEAL,
    perspective: Global,
  },
  view_home_button: {
    flag: VIEW_HOME_BUTTON,
    perspective: Global,
  },
};

export default GlobalPerspectivePermissions;
