import React, { useEffect } from 'react';
import { Form, WrappedMediatorFormProps } from 'components';
import { FormFieldsType, SbaPppLoanApplicationType } from 'types';

import './RoundTwoQuestions.scss';

const fields: FormFieldsType = {
  question1: {
    id: 'question1',
    propertyName: 'question1',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
  },
  question2: {
    id: 'question2',
    propertyName: 'question2',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
  },
  question3: {
    id: 'question3',
    propertyName: 'question3',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    helpText:
      'If YES, you will be asked later to provide a listing of affiliate businesses.',
  },
  question4: {
    id: 'question4',
    propertyName: 'question4',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    helpText:
      'If YES, you will be asked later to provide details about your EIDL.',
  },
  question5: {
    id: 'question5',
    propertyName: 'question5',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    helpText:
      'You will later initial to confirm your response to this question when signing your application.',
  },
  question6: {
    id: 'question6',
    propertyName: 'question6',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    helpText:
      'You will later initial to confirm your response to this question when signing your application.',
  },
  question7: {
    id: 'question7',
    propertyName: 'question7',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    helpText:
      'If this question is answered No the application will not be approved',
  },
  question8: {
    id: 'question8',
    propertyName: 'question8',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
  },
  question9: {
    id: 'question9',
    propertyName: 'question9',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
  },
  sbaFranchiseIdentifierCode: {
    id: 'sbaFranchiseIdentifierCode',
    propertyName: 'sbaFranchiseIdentifierCode',
    required: true,
    fieldName: 'SBA Franchise Identifier Code',
    width: '162px',
  },
};

const SbaPppLoanApplication = 'SbaPppLoanApplication';

type Props = WrappedMediatorFormProps<SbaPppLoanApplicationType> & {
  isSecondDraw: boolean;
};

function RoundTwoQuestionsForm(props: Props) {
  const { data, isSecondDraw } = props;

  const enableQuestion9 = Boolean(data.question8);

  const requireFranchiseCode = enableQuestion9 && Boolean(data.question9);

  useEffect(() => {
    if (isSecondDraw) {
      props.mutateProperty(false, fields.question4.propertyName);
    }
  }, [isSecondDraw, props]);

  const handleQuestionNine = (
    value:
      | (string | null | undefined)
      | (boolean | null | undefined)
      | (Array<any> | null | undefined),
    fieldId: string,
  ) => {
    props.mutateProperty(value, fieldId);
    props.mutateProperty(value, 'question9');
  };

  return (
    <div className="RoundTwoQuestions">
      <Form.Text
        className="steel help-text"
        value={
          isSecondDraw
            ? 'If questions (1), (2), (4), or (5) are answered “Yes,” the loan will not be approved.'
            : 'If questions (1), (2), (5), or (6) are answered “Yes,” the loan will not be approved'
        }
      />

      {/* QUESTION 1 */}
      <>
        <Form.Text
          className="required"
          value="1. Is the Applicant or any owner of the Applicant presently suspended, 
        debarred, proposed for debarment, declared ineligible, voluntarily excluded 
        from participation in this transaction by any Federal department or agency, 
        or presently involved in any bankruptcy?"
        />
        {Form.FieldRenderer(
          Form.Radio,
          { ...fields.question1, onChange: props.mutateProperty },
          props,
          SbaPppLoanApplication,
          data.id,
        )}
      </>

      {/* QUESTION 2 */}
      <>
        <Form.Text
          className="required"
          value="2. Has the Applicant, any owner of the Applicant, or any business 
        owned or controlled by any of them, ever obtained a direct or guaranteed 
        loan from SBA or any other Federal agency that is (a) currently delinquent, 
        or (b) has defaulted in the last 7 years and caused a loss to the government?"
        />
        {Form.FieldRenderer(
          Form.Radio,
          { ...fields.question2, onChange: props.mutateProperty },
          props,
          SbaPppLoanApplication,
          data.id,
        )}
      </>

      {/* QUESTION 3 */}
      <>
        <Form.Text
          className="required"
          value="3. Is the Applicant or any owner of the Applicant an owner of any other business, or have common management (including a management agreement) with any other business? If yes, list all such businesses (including their TINs if available) and describe the relationship on a separate sheet identified as addendum A."
        />
        {Form.FieldRenderer(
          Form.Radio,
          { ...fields.question3, onChange: props.mutateProperty },
          props,
          SbaPppLoanApplication,
          data.id,
        )}
      </>

      {/* QUESTION 4 (FIRST DRAW only) */}
      {!isSecondDraw && (
        <>
          <Form.Text
            className="required"
            value="4. Did the Applicant receive an SBA Economic Injury Disaster Loan between January 31, 2020 and April 3, 2020? If yes, provide details on a separate sheet identified as addendum B. "
          />
          {Form.FieldRenderer(
            Form.Radio,
            { ...fields.question4, onChange: props.mutateProperty },
            props,
            SbaPppLoanApplication,
            data.id,
          )}
        </>
      )}

      {/* QUESTION 5 (or 4) */}
      <>
        <Form.Text
          className="required"
          value={`${isSecondDraw ? '4' : '5'}.
          Is the Applicant (if an individual) or any individual owning 20% or more of the equity of the Applicant presently incarcerated or, for any felony, presently subject to an indictment, criminal information, arraignment, or other means by which formal criminal charges are brought in any jurisdiction?`}
        />
        {Form.FieldRenderer(
          Form.Radio,
          { ...fields.question5, onChange: props.mutateProperty },
          props,
          SbaPppLoanApplication,
          data.id,
        )}
      </>

      {/* QUESTION 6 (or 5) */}
      <>
        <Form.Text
          className="required"
          value={`${
            isSecondDraw ? '5' : '6'
          }. Within the last 5 years, for any felony involving fraud, bribery, embezzlement, or a false statement in a loan application or an application for federal financial assistance, or within the last year, for any other felony, has the Applicant (if an individual) or any owner of the Applicant 1) been convicted; 2) pleaded guilty; 3) pleaded nolo contendere; or 4) commenced any form of parole or probation (including probation before judgment)?`}
        />
        {Form.FieldRenderer(
          Form.Radio,
          { ...fields.question6, onChange: props.mutateProperty },
          props,
          SbaPppLoanApplication,
          data.id,
        )}
      </>

      {/* QUESTION 7 (or 6) */}
      <>
        <Form.Text
          className="required"
          value={`${
            isSecondDraw ? '6' : '7'
          }. Is the United States the principal place of residence for all employees included in the Applicant’s payroll calculation above?`}
        />
        {Form.FieldRenderer(
          Form.Radio,
          { ...fields.question7, onChange: props.mutateProperty },
          props,
          SbaPppLoanApplication,
          data.id,
        )}
      </>

      {/* QUESTION 8 (or 7) */}
      <>
        <Form.Text
          className="required"
          value={`${isSecondDraw ? '7' : '8'}. Is the Applicant a franchise?`}
        />
        {Form.FieldRenderer(
          Form.Radio,
          { ...fields.question8, onChange: handleQuestionNine },
          props,
          SbaPppLoanApplication,
          data.id,
        )}
      </>

      {/* QUESTION 9 (or 8) */}
      <>
        <Form.Text
          className="required"
          value={`${
            isSecondDraw ? '8' : '9'
          }. Is the franchise listed in the SBA’s Franchise Directory?`}
        />
        {Form.FieldRenderer(
          Form.Radio,
          {
            ...fields.question9,
            onChange: props.mutateProperty,
            disabled: !enableQuestion9,
          },
          props,
          SbaPppLoanApplication,
          data.id,
        )}
        {requireFranchiseCode &&
          Form.FieldRenderer(
            Form.Input,
            {
              ...fields.sbaFranchiseIdentifierCode,
              onChange: props.mutateProperty,
              disabled: !enableQuestion9,
            },
            props,
            SbaPppLoanApplication,
            data.id,
          )}
      </>
    </div>
  );
}

export default RoundTwoQuestionsForm;
