import { gql } from '@apollo/client';
import { DocumentFields, FolderShallowFields } from '../fragments';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';

/* eslint-disable import/prefer-default-export */

const DataroomFolderFields = gql`
  fragment DataroomFolderFields on FolderType {
    ...FolderShallowFields
    parent {
      id
    }
  }
  ${FolderShallowFields}
`;

export const DataroomQueryDefinition = gql`
  query DataroomFiles($dealId: ID!) {
    dataroomFiles(dealId: $dealId) {
      ... on DocumentType {
        ...DocumentFields
      }
      ... on FolderType {
        ...DataroomFolderFields
      }
    }
  }
  ${DocumentFields}
  ${DataroomFolderFields}
`;

export const DataroomQuery = standardQueryBuilder(DataroomQueryDefinition, {
  typename: 'DataroomFiles',
  includePollingMethods: true,
  addFetchMethod: true,
  disableCache: true,
  skip: ({ dealId }: { dealId: string | null | undefined }) => !dealId,
  variables: ({ dealId }: { dealId: string | null | undefined }) => ({
    dealId,
  }),
  results: (data: { dataroomFiles: Array<any> | null | undefined }) => {
    const { dataroomFiles } = data;
    return { dataroomFiles };
  },
});

/* eslint-enable */
