import * as React from 'react';
import {
  BenchmarkTextValueSortOrderType,
  BenchmarkOptionsFieldsProps,
  SelectedBenchmarkOptions,
} from '../../types';
import { parseDayCountText } from '../utils';
import ApplicableMargins from './ApplicableMargins';
import {
  AlternateBaseRateBenchmarkType,
  FieldRendererGeneralPropsType,
} from 'types';
import { Form, TypeQueryResult } from 'components';
import './AbrBenchmarkOptionsFields.scss';

type Props = BenchmarkOptionsFieldsProps &
  FieldRendererGeneralPropsType & {
    benchmarks: {
      fedFunds: BenchmarkTextValueSortOrderType | null | undefined;
      liborOneMonth: BenchmarkTextValueSortOrderType | null | undefined;
      prime: BenchmarkTextValueSortOrderType | null | undefined;
    };
    data: {
      fedFunds: AlternateBaseRateBenchmarkType;
      liborOneMonth: AlternateBaseRateBenchmarkType;
      prime: AlternateBaseRateBenchmarkType;
    };
    isSelected: boolean;
    onChange: (option: AlternateBaseRateBenchmarkType) => void;
    onDelete: (option: AlternateBaseRateBenchmarkType) => void;
    onToggleSelected: (option: SelectedBenchmarkOptions) => void;
  };

const benchmarkOptionName = 'AlternateBaseRateBenchmark';
const groupClassName = 'AbrBenchmarkOptionsFields__FormGroup';
const width = '158px';
const smallWidth = '120px';
const baseDayCountConvention = {
  id: 'dayCountConvention',
  propertyName: 'dayCountConvention',
  fieldName: 'Interest Day Count Rule',
  typeName: 'AlternateBaseRateBenchmarkDayCountConvention',
  width,
};

class AbrBenchmarkOptionsFields extends React.Component<Props> {
  static abrTypes = ['prime', 'fedFunds', 'liborOneMonth'];

  handleChangeDayCountConvention = (type: string) => (
    value: string | null | undefined,
  ) => {
    const benchmarkOption = this.props.data[type];
    if (benchmarkOption && benchmarkOption.dayCountConvention !== value)
      this.props.onChange({ ...benchmarkOption, dayCountConvention: value });
  };

  handleToggleAbr = (value: boolean | null | undefined) => {
    const { onToggleSelected } = this.props;
    onToggleSelected('abr');
    if (!value) {
      AbrBenchmarkOptionsFields.abrTypes.forEach(type => {
        this.props.onDelete(this.props.data[type]);
      });
    }
  };

  handleChangeCheckbox = (type: 'prime' | 'fedFunds' | 'liborOneMonth') => (
    value: boolean | null | undefined,
  ) => {
    const { data, benchmarks } = this.props;
    const benchmark = benchmarks[type];
    const option = data[type];
    if (value && benchmark) {
      this.props.onChange({
        ...option,
        benchmarkId: benchmark.value,
      });
    } else if (option) {
      this.props.onDelete(option);
    }
  };

  handleMarginChange = (type: 'prime' | 'fedFunds' | 'liborOneMonth') => (
    value: string | null | undefined,
  ) => {
    const { data } = this.props;
    const benchmarkOption = data[type];
    if (benchmarkOption.margin !== value) {
      this.props.onChange({
        ...benchmarkOption,
        margin: value,
      });
    }
  };

  /* eslint-enable react/sort-comp */

  fields = {
    abr: {
      id: 'abr',
      propertyName: 'alternateBaseRate',
      label: 'Alternate Base Rate',
      onChange: this.handleToggleAbr,
      width,
    },
    prime: {
      id: 'benchmarkId',
      propertyName: 'benchmarkId',
      label: 'Prime Rate',
      onChange: this.handleChangeCheckbox('prime'),
      width,
    },
    fedFunds: {
      id: 'benchmarkId',
      propertyName: 'benchmarkId',
      label: 'Federal Funds',
      onChange: this.handleChangeCheckbox('fedFunds'),
      width,
    },
    liborOneMonth: {
      id: 'benchmarkId',
      propertyName: 'benchmarkId',
      label: 'LIBOR 1 Month',
      onChange: this.handleChangeCheckbox('liborOneMonth'),
      width,
    },
    dayCountConventions: {
      prime: {
        ...baseDayCountConvention,
        onChange: this.handleChangeDayCountConvention('prime'),
      },
      fedFunds: {
        ...baseDayCountConvention,
        onChange: this.handleChangeDayCountConvention('fedFunds'),
      },
      liborOneMonth: {
        ...baseDayCountConvention,
        onChange: this.handleChangeDayCountConvention('liborOneMonth'),
      },
    },
    margins: {
      prime: {
        id: 'primeMargin',
        propertyName: 'margin',
        fieldName: 'Margin',
        onChange: this.handleMarginChange('prime'),
        width: smallWidth,
        withPlus: true,
      },
      fedFunds: {
        id: 'fedFundsMargin',
        fieldName: 'Margin',
        propertyName: 'margin',
        onChange: this.handleMarginChange('fedFunds'),
        width: smallWidth,
        withPlus: true,
      },
      liborOneMonth: {
        id: 'liborOneMonthMargin',
        fieldName: 'Margin',
        propertyName: 'margin',
        onChange: this.handleMarginChange('liborOneMonth'),
        width: smallWidth,
        withPlus: true,
      },
    },
  };

  render() {
    const {
      applicablemarginSet,
      blurEventForm,
      data,
      disabled,
      isSaving,
      errors,
      isFirstSelected,
      onUpdateApplicableMargin,
      handleRemoveApplicableMargin,
      trancheId,
      isSelected,
    } = this.props;
    return (
      <>
        <Form.Group className={groupClassName}>
          {Form.FieldRenderer(
            Form.Checkbox,
            { ...this.fields.abr, value: isSelected },
            this.props,
            benchmarkOptionName,
            trancheId,
          )}
        </Form.Group>
        {isSelected && (
          <ApplicableMargins
            applicableMarginType="abr"
            blurEventForm={blurEventForm}
            data={applicablemarginSet}
            disabled={Boolean(disabled)}
            errors={errors}
            handleRemoveApplicableMargin={handleRemoveApplicableMargin}
            isFirstSelected={isFirstSelected}
            isSaving={isSaving}
            onChange={onUpdateApplicableMargin}
          />
        )}
        {isSelected && (
          <div className="AbrBenchmarkOptionsFields__Benchmarks">
            {AbrBenchmarkOptionsFields.abrTypes.map(type => {
              const option = data[type];
              const optionIsChecked = Boolean(option.benchmarkId);
              return (
                <React.Fragment key={type}>
                  <div className="AbrBenchmarkOptionsFields__BenchmarkSubGroup">
                    {Form.FieldRenderer(
                      Form.Checkbox,
                      {
                        ...this.fields[type],
                        value: optionIsChecked,
                      },
                      this.props,
                      benchmarkOptionName,
                      option.id,
                    )}
                  </div>
                  {optionIsChecked && (
                    <Form.Group>
                      {Form.FieldRenderer(
                        Form.ReferenceSelect,
                        {
                          ...this.fields.dayCountConventions[type],
                          resultFilter: (
                            queryResults: Array<TypeQueryResult>,
                          ) => queryResults.map(parseDayCountText),
                        },
                        {
                          ...this.props,
                          data: option,
                        },
                        benchmarkOptionName,
                        option.id,
                      )}
                      {Form.FieldRenderer(
                        Form.Percentage,
                        {
                          ...this.fields.margins[type],
                          value: option && option.margin,
                          disabled: Boolean(disabled),
                        },
                        this.props,
                        benchmarkOptionName,
                        option.id,
                      )}
                    </Form.Group>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        )}
      </>
    );
  }
}

export default AbrBenchmarkOptionsFields;
