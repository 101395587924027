import uuid from 'uuid/v4';
import {
  SbaPppForgivenessDenialMutationMethods,
  SbaPppForgivenessDenialValidator,
} from '../../../graphql';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import { DealType } from 'types';

const initialDeal: DealType = {
  id: uuid(),
  __typename: 'DealType',
};

const lifecycleMethods = {
  mutations: SbaPppForgivenessDenialMutationMethods,
  validators: {
    SbaPppForgivenessDenial: SbaPppForgivenessDenialValidator,
  },
};

const sbaPppForgivenessDenialFormRedux = createForm<DealType, DealType>(
  Directory.SbaPppForgivenessDenialKey,
  initialDeal,
  lifecycleMethods,
);

export default sbaPppForgivenessDenialFormRedux;
