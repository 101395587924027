import { KeyPath } from '../types';
import { rootKey } from './constants';

/* eslint-disable import/prefer-default-export */

/**
 * The edited object always lives on the 'data' property,
 * this method ensures edit actions always operate against
 * this 'data' property
 * @param {*} relativePath
 */
export const getKeyPath: (arg0: KeyPath) => Array<string> = (
  keyPath: KeyPath,
) => {
  if (Array.isArray(keyPath)) {
    return [rootKey, ...keyPath];
  }

  if (keyPath === '' || !keyPath) return [rootKey];

  return [rootKey, keyPath];
};
