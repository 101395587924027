import * as React from 'react';
import { Form } from 'components';
import {
  AlternateBaseRateBenchmarkType,
  AlternateBaseRateBenchmarkPaymentFrequency,
  FieldRendererGeneralPropsType,
} from 'types';

const loanTranche = 'LoanTranche';
const width = '158px';

type Props = FieldRendererGeneralPropsType & {
  data: ReadonlyArray<AlternateBaseRateBenchmarkType>;
  fieldName: string;
  onUpdateAbrOption: (option: AlternateBaseRateBenchmarkType) => void;
};

type State = { selected: boolean };

class PaymentPeriodAbrExceptionItem extends React.Component<Props, State> {
  handleUpdateAlternateBaseRateBenchmark = (
    value: AlternateBaseRateBenchmarkPaymentFrequency | null | undefined,
  ) => {
    const { data } = this.props;
    data.forEach(option => {
      if (option.benchmarkId) {
        this.props.onUpdateAbrOption({
          ...option,
          paymentFrequency: value,
        });
      }
    });
  };

  handleToggleCheckmark = (value: boolean | null | undefined) => {
    if (!value) {
      this.handleUpdateAlternateBaseRateBenchmark(null);
    }
    this.setState(state => ({ selected: !state.selected }));
  };

  /* eslint-enable react/sort-comp */

  fields = {
    checkbox: {
      id: 'abrCheckbox',
      propertyName: 'abrCheckbox',
      label: this.props.fieldName,
      onChange: this.handleToggleCheckmark,
      width,
    },
    select: {
      id: 'abrPaymentFrequency',
      propertyName: 'paymentFrequency',
      fieldName: '',
      onChange: this.handleUpdateAlternateBaseRateBenchmark,
      width,
      typeName: 'AlternateBaseRateBenchmarkPaymentFrequency',
    },
  };

  constructor(props: Props) {
    super(props);
    const firstAbrOption = this.getFirstAbrBenchmark();
    this.state = {
      selected: Boolean(firstAbrOption && firstAbrOption.paymentFrequency),
    };
  }

  getFirstAbrBenchmark = () =>
    this.props.data.find(option => Boolean(option.benchmarkId));

  render() {
    const firstAbrBenchmark = this.getFirstAbrBenchmark();

    return (
      <Form.Group>
        {Form.FieldRenderer(
          Form.Checkbox,
          { ...this.fields.checkbox, value: this.state.selected },
          this.props,
          loanTranche,
          (firstAbrBenchmark && firstAbrBenchmark.id) || '',
        )}
        {this.state.selected &&
          Form.FieldRenderer(
            Form.ReferenceSelect,
            {
              ...this.fields.select,
              value: firstAbrBenchmark && firstAbrBenchmark.paymentFrequency,
            },
            this.props,
            loanTranche,
            (firstAbrBenchmark && firstAbrBenchmark.id) || '',
          )}
      </Form.Group>
    );
  }
}

export default PaymentPeriodAbrExceptionItem;
