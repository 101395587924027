import React from 'react';
import WorkflowStep from '../WorkflowStep';
import { StepProps } from '../StepProps';
import triggerClosingWorkflowTransition from 'lsgql/mutations/TransitionClosingWorkflow';
import raiseAlert from 'lsredux/raiseAlert';
import sendPromissoryNote from 'lsgql/mutations/SendPromissoryNote';
import { RouteTable } from 'routing';
import { invariant } from 'utils';
import { Button, TextLink, Text, RouteTo } from 'components';

function LenderPromissoryNote(props: StepProps) {
  const { step, dealClosingWorkflow, dealId, isDimmed, canEdit } = props;
  invariant(step.slug === 'aw_lender_promissory_note', 'invalid slug');
  const isState6 =
    dealClosingWorkflow?.state === 'lender_review_promissory_note';

  const handleSendNote = async () => {
    const res = await sendPromissoryNote({
      workflowId: dealClosingWorkflow.id,
      returnUrl: window.location.href,
    });
    if (res.data.sendPromissoryNote.ok) {
      window.location.href = res.data.sendPromissoryNote.redirectUrl;
    } else {
      raiseAlert(
        "The request has failed. Please try again. If that doesn't work, please contact technical support",
        'error',
      );
    }
  };

  const handleSkipNote = () => {
    //TODO: the fact that the note is skipped needs to be sent to the backend
    // so that conditional Stage 8 messages can be rendered
    triggerClosingWorkflowTransition({
      workflowId: dealClosingWorkflow.id,
      state: 'lender_originate_loan',
    });
  };

  const renderStepText = () => {
    if (isDimmed) {
      return (
        <Text>
          After receiving SBA approval, complete and send the Promissory Note
          and any other loan documents to the Borrower for signature.
        </Text>
      );
    } else if (!isDimmed && canEdit) {
      if (isState6) {
        return (
          <Text>
            Please send the Note to the borrower for their signature. You can
            review and make changes before sending.
          </Text>
        );
      } else {
        return (
          <>
            <Text>
              You will receive an email when the borrower has signed the Note.
            </Text>

            {/* <Text>
              If you think the borrower has not received their request for
              signature, you can send it to them again:
            </Text> */}
          </>
        );
      }
    } else {
      return (
        <Text>
          The borrower signed the note, and it is saved in the{' '}
          <TextLink
            as={RouteTo}
            label="Dataroom"
            to={RouteTable.deal.toDealDataroom(dealId)}
          />
          .
        </Text>
      );
    }
  };

  return (
    <WorkflowStep
      canEdit={canEdit}
      header="PROMISSORY NOTE"
      isDimmed={isDimmed}
      step={step}
    >
      {renderStepText()}
      {canEdit && (
        <div className="Group left padded">
          {/*  TODO: SBA-HACK Replace with "Review and Send Note" & in app tool once ESign tool ready */}
          {isState6 ? (
            <Button label="Review and Send Note" onClick={handleSendNote} />
          ) : (
            <Button.Secondary
              label="Resend Signature Request"
              onClick={handleSendNote}
            />
          )}
          <Button.Text
            id="hasOtherDocument"
            label="Already Signed"
            onClick={handleSkipNote}
          />
        </div>
      )}
    </WorkflowStep>
  );
}

export default LenderPromissoryNote;
