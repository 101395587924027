import { mutationBuilder } from './builders';
import { SetSbaPppForgivenessWorkflowMutationDefinition } from './definitions';
import {
  SbaPppForgivenessWorkflowInput,
  SbaPppForgivenessWorkflowType,
} from 'types';

const SbaPppForgivenessWorkflowMutationMethods = {
  setSbaPppForgivenessWorkflow: mutationBuilder<
    SbaPppForgivenessWorkflowInput,
    SbaPppForgivenessWorkflowType
  >(SetSbaPppForgivenessWorkflowMutationDefinition, {
    build: mutate => sbaPppForgivenessWorkflowData =>
      mutate({
        variables: { sbaPppForgivenessWorkflowData },
      }),
  }),
};

export default SbaPppForgivenessWorkflowMutationMethods;
