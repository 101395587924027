import { isEqual, merge, isEmpty } from 'lodash';

/**
 * Shallow diff between two objects
 * @param  {Object} obj1   Object compared
 * @param  {Object} obj2   Object to compare with
 * @return {Object}        Return a new object that represents the diff
 */
function compareDifference(obj1: any, obj2: any, keys: Array<any>) {
  const diff = {};

  keys.forEach(key => {
    const oldValue = obj1[key] !== undefined ? obj1[key] : '';
    const newValue = obj2[key] !== undefined ? obj2[key] : '';
    if (oldValue !== newValue) {
      diff[key] = { from: oldValue, to: newValue };
    }
  });

  return diff;
}

function shallowComparison(obj1: any, obj2: any) {
  if (isEqual(obj1, obj2)) return null;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  const firstDifference = compareDifference(obj1, obj2, keys1);
  const secondDifference = compareDifference(obj1, obj2, keys2);

  const diff = merge(firstDifference, secondDifference);
  return !isEmpty(diff) ? diff : null;
}

export default shallowComparison;
