const numericRegex = /^[0-9]+$/;

/* eslint-disable import/prefer-default-export */

/**
 * Determine if a value is a whole number
 * @param {*} value
 */
export function isWholeNumber(value: string): boolean {
  return numericRegex.test(value);
}
