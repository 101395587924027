import { DispatchResponse } from '../../graphql/mutationsDispatch/types';
import { FetchMethod } from '../../graphql/types';
import { PayloadAction } from '../types';
import { ReduxKeys } from '../directory';
import {
  MultiDispatchMethods,
  MultiEntityFactory,
  KeyPath,
  LifecycleMethods,
} from './types';
import generateActionCreatorsFor, {
  ActionsResult,
} from './actionCreatorFactory';

import { createActionTypes } from './createActionTypes';

import { invokeSaveAction, invokeValidationAction } from './saveAction';
import { BaseType, ValidationMap } from 'types';

/* entity id must be on the action */

function withEntityId<A>(entityId: string, action: A) {
  return { ...action, entityId };
}

export default function createMultiEntityActionFactoryFor<
  TOut extends BaseType
>(
  reduxKey: ReduxKeys,
  lifeCycleMethods: LifecycleMethods<TOut>,
): MultiEntityFactory<TOut> {
  // Get the action creators of the generic form,
  // we will wrap them withEntityId(action)
  function generateActions(
    dispatch: any,
    entityId: string,
  ): MultiDispatchMethods<TOut> {
    const actionCreators: ActionsResult<TOut> = generateActionCreatorsFor(
      reduxKey,
      lifeCycleMethods,
    );

    const actionTypes = createActionTypes(reduxKey);

    const createResponseAction = (e: DispatchResponse<TOut>) =>
      withEntityId(entityId, actionCreators.handleResponseAction(e));

    const handleToggleSave = (isSaving: boolean) => {
      const res: PayloadAction<boolean> = {
        type: actionTypes.toggleSaveActionType,
        payload: isSaving,
        entityId,
      } as any;

      return res;
    };

    const tryInvokeAutoSave = () => {
      /*
      invariant(
        lifeCycleMethods.fetch,
        'LifecycleMethods is required to provide fetch for autoSave forms',
      );
      */
      dispatch(
        invokeSaveAction(
          [reduxKey, entityId],
          lifeCycleMethods,
          createResponseAction,
          handleToggleSave,
          lifeCycleMethods.fetch,
          true,
        ),
      );
    };

    const actions: MultiDispatchMethods<TOut> = {
      // Add Entity
      createNewState: (entity?: TOut) => {
        dispatch(actionCreators.handleCreateNewState(entity));
      },
      addEntity: (keyPath: KeyPath, entity: any) => {
        dispatch(
          withEntityId(
            entityId,
            actionCreators.handleAddEntity(keyPath, entity),
          ),
        );

        tryInvokeAutoSave();
      },

      addEntities: (keyPath: KeyPath, entities: Array<any>) => {
        dispatch(
          withEntityId(
            entityId,
            actionCreators.handleAddEntities(keyPath, entities),
          ),
        );

        tryInvokeAutoSave();
      },
      clearState: () => {
        dispatch(withEntityId(entityId, actionCreators.handleClearState()));
      },

      deleteCollection: (
        keyPath: KeyPath,
        entities: Array<any>,
        discard?: boolean,
      ) => {
        dispatch(
          withEntityId(
            entityId,
            actionCreators.handleDeleteCollection(keyPath, entities, discard),
          ),
        );

        tryInvokeAutoSave();
      },
      mutateProperty: (
        value:
          | (string | null | undefined)
          | (boolean | null | undefined)
          | (Array<any> | null | undefined),
        property: KeyPath,
      ) => {
        dispatch(
          withEntityId(
            entityId,
            actionCreators.handleEditPropertyAction(property, value),
          ),
        );

        tryInvokeAutoSave();
      },

      initializeState: (entity?: TOut) => {
        dispatch(
          withEntityId(entityId, actionCreators.handleInitializeState(entity)),
        );
      },

      mutateProperties: (keyPath: KeyPath, slice: {}) => {
        dispatch(
          withEntityId(
            entityId,
            actionCreators.handleMutateProperties(keyPath, slice),
          ),
        );

        tryInvokeAutoSave();
      },

      removeEntity: (keyPath: KeyPath, entity: any) => {
        dispatch(
          withEntityId(
            entityId,
            actionCreators.handleRemoveEntityAction(keyPath, entity),
          ),
        );

        tryInvokeAutoSave();
      },

      removeSelf: (entity: TOut) => {
        dispatch(actionCreators.handleRemoveSelfAction(entity));
      },

      replaceEntity: (keyPath: KeyPath, entity: any) => {
        dispatch(
          withEntityId(
            entityId,
            actionCreators.handleReplaceEntity(keyPath, entity),
          ),
        );

        tryInvokeAutoSave();
      },

      handleResponse: (e: DispatchResponse<TOut>) => {
        dispatch(
          withEntityId(entityId, actionCreators.handleResponseAction(e)),
        );
      },

      save: async (fetchMethod?: FetchMethod<TOut>) => {
        const action = invokeSaveAction(
          [reduxKey, entityId],
          lifeCycleMethods,
          createResponseAction,
          handleToggleSave,
          fetchMethod,
          false,
        );
        const res = await dispatch(action);

        return res as any;
      },

      toggleAutoSave: (enable: boolean) => {
        dispatch(
          withEntityId(entityId, actionCreators.handleToggleAutoSave(enable)),
        );

        if (enable === true) {
          tryInvokeAutoSave();
        }
      },

      toggleDirtyFlag: (
        keyPath: KeyPath,
        isDirty: boolean,
        recursive = false,
      ) => {
        dispatch(
          withEntityId(
            entityId,
            actionCreators.handleToggleDirtyFlagAction(
              keyPath,
              isDirty,
              recursive,
            ),
          ),
        );

        tryInvokeAutoSave();
      },
      undo: (keyPath: KeyPath) => {
        dispatch(withEntityId(entityId, actionCreators.handleUndo(keyPath)));
        tryInvokeAutoSave();
      },

      validate: async () =>
        dispatch(
          invokeValidationAction(
            [reduxKey, entityId],
            lifeCycleMethods,
            createResponseAction,
            handleToggleSave,
          ) as any,
        ),

      setValidationErrors: (errors: ValidationMap) => {
        dispatch(
          withEntityId(
            entityId,
            actionCreators.handleSetValidationErrors(errors),
          ),
        );
      },
    };
    return actions;
  }

  const res: MultiEntityFactory<TOut> = {
    generateActions,
  };

  return res as MultiEntityFactory<TOut>;
}
