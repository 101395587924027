import invariant from './invariant';

const error = 'Duplicate key values';

type NonEmptyObject = { [key: string]: any };

/**
 * Convert an array into an object map, using object values
 * at the specified keyField as the key value.  An error will be
 * thrown when duplicate key values are encountered
 *
 * arrayToObject([{ name: 'First' }], 'name') = { First: { name: 'First' }}
 */
function arrayToObject<TIn extends NonEmptyObject, TOut>(
  arr: Array<TIn>,
  keyField: keyof TIn,
  allowDuplicates = false,
): { [key: string]: TOut } {
  const usedKeys: { [propName: string]: any } = {};
  return Object.assign(
    {},
    ...arr.map(item => {
      if (!allowDuplicates) {
        invariant(
          !usedKeys[item[keyField]],
          `${error} Key: ${keyField} V: ${item[keyField]}`,
        );
      }
      usedKeys[item[keyField]] = true;
      return { [item[keyField]]: item };
    }),
  );
}

export default arrayToObject;
