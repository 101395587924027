import * as React from 'react';
import { compose } from 'recompose';
import { Redirect } from 'react-router-dom';
import { RouteTable, RouteParams, withRouteParams } from 'routing';
import { PublicSbaPppLoanApplicationQuery } from 'lsgql';
import {
  Tiler,
  Header,
  Form,
  TextLink,
  withLoadingIndicator,
} from 'components';
import { PublicSbaPppLoanApplicationType } from 'types';

import './Success.scss';

interface Props {
  sbaPppLoanApplication: PublicSbaPppLoanApplicationType;
}

function SbaPppSuccessPage(props: Props) {
  if (!props.sbaPppLoanApplication) {
    return <Redirect to={RouteTable.application.to404} />;
  }

  return (
    <div className="RoundOneSbaPppSuccessPage">
      <div className="header">
        <Header as="h1" className="bold ink">
          {props.sbaPppLoanApplication?.institutionName}
        </Header>
      </div>
      <Tiler>
        <Tiler.Column>
          <Tiler.Tile className="steel" width="ThreeFourths">
            <Header as="h3">Your loan request was successfully sent.</Header>
            <br />
            <Header as="h3">
              <b>Confirmation number:</b>{' '}
              {props.sbaPppLoanApplication?.confirmationNumber}
            </Header>
            <Form.Divider />
            <Header as="h5" className="caps">
              What happens next
            </Header>
            <ul>
              <li>You will receive an invitation to the loan platform.</li>
              <li>
                Check your spam and junk folder if you do not receive your
                invitation.
              </li>
              <li>
                Once you register and log in, you will need to compile any
                necessary supporting documents and upload.
              </li>
              <li>
                If you have any questions about your loan status you can call
                your credit union and provide them with the confirmation number
                above.
              </li>
            </ul>
            <Header as="h5" className="caps">
              Resources
            </Header>
            <TextLink.External
              label="Filing a PPP application as a Borrower Walkthrough"
              target="newTab"
              to="https://www.loan-street.com/hubfs/Filing%20a%20PPP%20application%20as%20a%20Borrower.pdf"
            />
            <TextLink.External
              label="Supporting Document Examples"
              target="newTab"
              to={RouteTable.application.toSbaPppSupportingDocuments}
            />
          </Tiler.Tile>
        </Tiler.Column>
      </Tiler>
    </div>
  );
}

export default compose(
  withRouteParams([RouteParams.sbaPppApplicationId]),
  PublicSbaPppLoanApplicationQuery,
  withLoadingIndicator('loading'),
)(SbaPppSuccessPage);
