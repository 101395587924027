import { RouteTable } from '../RouteTable';

const ApplicationBreadcrumbRoutes = [
  {
    label: 'Facility',
    route: RouteTable.application.toCreateDeal,
  },
  {
    label: 'My Information & Institution',
    route: RouteTable.application.toProfile,
    children: [
      {
        label: 'Edit My Information',
        route: RouteTable.user.toUserProfile,
      },
      {
        label: 'Edit Institution',
        route: RouteTable.institution.toEdit,
      },
    ],
  },
];

export default ApplicationBreadcrumbRoutes;
