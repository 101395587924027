import { compose, withProps } from 'recompose';
import OrganizationSection from './sections/organizationSection/OrganizationSection';
import { RouteTable } from 'routing';
import { InstitutionPerspectivePermissions } from 'security';

const composed = compose(
  withProps((ownProps: any) => {
    const {
      organization: { dealUsers },
    } = ownProps;

    return {
      permissions: {
        changePermission: InstitutionPerspectivePermissions.change_institution,
        adminPermission:
          InstitutionPerspectivePermissions.administer_institution,
      },
      userSet: dealUsers,
      editOrganizationRoute: RouteTable.institution.toEdit,
      isBorrower: false,
    };
  }),
)(OrganizationSection);

export default composed;
