import { mutationBuilder } from './builders';
import { SetSbaPppForgivenessCalculationMutationDefinition } from './definitions';
import {
  SbaPppForgivenessCalculationInput,
  SbaPppForgivenessCalculationType,
} from 'types';

const SetSbaPppForgivenessCalculationMutationMethods = {
  setSbaPppForgivenessCalculation: mutationBuilder<
    SbaPppForgivenessCalculationInput,
    SbaPppForgivenessCalculationType
  >(SetSbaPppForgivenessCalculationMutationDefinition, {
    build: mutate => sbaPppForgivenessCalculationData =>
      mutate({ variables: { sbaPppForgivenessCalculationData } }),
  }),
};

export default SetSbaPppForgivenessCalculationMutationMethods;
