/**
 * If `value` is defined, return `result`
 * @param {*} value
 * @param {*} result
 */
function useIfDef(value: any | null | undefined, result = '') {
  return value && result;
}

export default useIfDef;
