import * as React from 'react';

import { noop } from 'lodash';
import cx from 'classnames';
// eslint-disable-next-line import/named
import moment, { Moment } from 'moment';
import { ux } from 'utils';
import { Form, Icon, threeMonthConst, weekConst } from 'components';

type Props = {
  onDateChange: (arg0: Date) => void;
  viewRange: 'week' | 'month' | '3 month';
  viewStart: Date;
};

class CalendarHeaderDateDisplay extends React.Component<Props> {
  static mainClass = 'calendarHeaderDateDisplay';

  static buttonTextClass = `${CalendarHeaderDateDisplay.mainClass}__ButtonText`;

  static buttonTextClassYear = `${CalendarHeaderDateDisplay.buttonTextClass}--Year`;

  static buttonTextClassMonth = `${CalendarHeaderDateDisplay.buttonTextClass}--Month`;

  static buttonTextClassDash = `${CalendarHeaderDateDisplay.buttonTextClass}--Dash`;

  renderButtonText = () => {
    const { viewRange, viewStart } = this.props;

    if (viewRange === threeMonthConst) {
      const startMoment = moment(viewStart);
      const endMoment = moment(viewStart).add(2, 'months');
      return (
        <>
          <div
            className={cx(
              CalendarHeaderDateDisplay.buttonTextClass,
              CalendarHeaderDateDisplay.buttonTextClassMonth,
            )}
          >
            {startMoment.format('MMM')}
          </div>
          <div
            className={cx(
              CalendarHeaderDateDisplay.buttonTextClass,
              CalendarHeaderDateDisplay.buttonTextClassYear,
            )}
          >
            {startMoment.format('YYYY')}
          </div>
          <div
            className={cx(
              CalendarHeaderDateDisplay.buttonTextClass,
              CalendarHeaderDateDisplay.buttonTextClassDash,
            )}
          >
            -
          </div>
          <div
            className={cx(
              CalendarHeaderDateDisplay.buttonTextClass,
              CalendarHeaderDateDisplay.buttonTextClassMonth,
            )}
          >
            {endMoment.format('MMM')}
          </div>
          <div
            className={cx(
              CalendarHeaderDateDisplay.buttonTextClass,
              CalendarHeaderDateDisplay.buttonTextClassYear,
            )}
          >
            {endMoment.format('YYYY')}
          </div>
        </>
      );
    }
    if (viewRange === weekConst) {
      return this.renderWeekButtonText();
    }

    const viewMoment = moment(viewStart);
    const viewMonth = viewMoment.format('MMMM');
    const viewYear = viewMoment.format('YYYY');

    return (
      <>
        <div
          className={cx(
            CalendarHeaderDateDisplay.buttonTextClass,
            CalendarHeaderDateDisplay.buttonTextClassMonth,
          )}
        >
          {viewMonth}
        </div>
        <div
          className={cx(
            CalendarHeaderDateDisplay.buttonTextClass,
            CalendarHeaderDateDisplay.buttonTextClassYear,
          )}
        >
          {viewYear}
        </div>
      </>
    );
  };

  renderWeekButtonText = () => {
    const { viewStart } = this.props;
    const startMoment = moment(viewStart);
    const endMoment = moment(viewStart)
      .add(1, 'weeks')
      .subtract(1, 'days');
    const startDate = startMoment.format('D');
    const endDate = endMoment.format('D');
    const startMonth = startMoment.format('MMM');
    const endMonth = endMoment.format('MMM');
    const startYear = startMoment.format('YYYY');
    const endYear = endMoment.format('YYYY');
    return (
      <>
        <div
          className={cx(
            CalendarHeaderDateDisplay.buttonTextClass,
            CalendarHeaderDateDisplay.buttonTextClassMonth,
          )}
        >
          {startMonth}
        </div>
        <div
          className={cx(
            CalendarHeaderDateDisplay.buttonTextClass,
            ux(
              startYear !== endYear,
              CalendarHeaderDateDisplay.buttonTextClassMonth,
            ),
          )}
        >
          {startDate}
        </div>
        {startYear !== endYear && (
          <div
            className={cx(
              CalendarHeaderDateDisplay.buttonTextClass,
              CalendarHeaderDateDisplay.buttonTextClassYear,
            )}
          >
            {startYear}
          </div>
        )}
        <div
          className={cx(
            CalendarHeaderDateDisplay.buttonTextClass,
            CalendarHeaderDateDisplay.buttonTextClassDash,
          )}
        >
          -
        </div>
        {startMonth !== endMonth && (
          <div
            className={cx(
              CalendarHeaderDateDisplay.buttonTextClass,
              CalendarHeaderDateDisplay.buttonTextClassMonth,
            )}
          >
            {endMonth}
          </div>
        )}
        <div
          className={cx(
            CalendarHeaderDateDisplay.buttonTextClass,
            CalendarHeaderDateDisplay.buttonTextClassMonth,
          )}
        >
          {endDate}
        </div>
        <div
          className={cx(
            CalendarHeaderDateDisplay.buttonTextClass,
            CalendarHeaderDateDisplay.buttonTextClassYear,
          )}
        >
          {endYear}
        </div>
      </>
    );
  };

  renderTrigger = () => (
    <div
      className={`${CalendarHeaderDateDisplay.mainClass}__Button`}
      onClick={noop}
    >
      <div className={`${CalendarHeaderDateDisplay.mainClass}__Content`}>
        {this.renderButtonText()}
        <Icon
          className={`${CalendarHeaderDateDisplay.mainClass}__Icon`}
          icon="caret-down"
        />
      </div>
    </div>
  );

  handleChange = (date: Moment) => {
    const { onDateChange } = this.props;
    onDateChange(date.toDate());
  };

  render() {
    const { viewRange, viewStart } = this.props;
    return (
      <div className={CalendarHeaderDateDisplay.mainClass}>
        <Form.DatePickerPopup
          onChange={this.handleChange}
          picking={viewRange}
          selected={moment(viewStart)}
          trigger={this.renderTrigger}
        />
      </div>
    );
  }
}

export default CalendarHeaderDateDisplay;
