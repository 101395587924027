import * as React from 'react';
import uuid from 'uuid/v4';

import HeaderCellWithUnit from './HeaderCellWithUnit';
import {
  Header,
  Money,
  Button,
  ConfirmModal,
  Column,
  Value,
  DataGridInputProps,
  DataGrid,
} from 'components';

import './LoanTrancheTierGrid.scss';

import { LoanTrancheTierType } from 'types';

const renderMoney = (value: string) => <Money value={value} />;

const emptyTrancheTier = {
  id: uuid(),
  lowAmount: '0',
  highAmount: '1',
  __typename: 'LoanTrancheTierType',
};

const MoneyEditorWithZero = <T extends any>(
  rowProps: DataGridInputProps<T, any>,
) => <DataGrid.MoneyInput addDollarSymbol={false} {...rowProps} />;

// type Props = OrderBookFormProps & TrancheTierFormProps;
class TrancheTierFormGrid extends DataGrid<LoanTrancheTierType, any> {}

type State = {
  pendingDelete: LoanTrancheTierType | null | undefined;
};

type Props = {
  canEdit?: boolean;
  index: number;
  loantranchetierSet: ReadonlyArray<LoanTrancheTierType>;
  name?: string | null | undefined;
  onAddTierRow: () => void;
  onDeleteTierRow: (e: LoanTrancheTierType) => void;

  onEditTierRow: (
    any: any,
    column: Column<LoanTrancheTierType, any>,
    value: Value,
    row: LoanTrancheTierType,
  ) => void;
};

class LoanTrancheTierGrid extends React.Component<Props, State> {
  static HeaderCellWithUnit = HeaderCellWithUnit;

  columns = [
    {
      className: 'TierForm-TierNumberColumn',
      columnName: 'tierNumber',
      displayName: 'Tier',
      readOnly: true,
    },
    {
      className: 'TierForm-MoneyColumn',
      columnName: 'lowAmount',
      displayName: 'Low',
      customHeaderCell: () => HeaderCellWithUnit('Low'),
      renderer: renderMoney,
      editor: MoneyEditorWithZero,
      readOnly: !this.props.canEdit,
    },
    {
      className: 'TierForm-MoneyColumn',
      columnName: 'highAmount',
      displayName: 'High',
      customHeaderCell: () => HeaderCellWithUnit('High'),
      renderer: renderMoney,
      editor: MoneyEditorWithZero,
      readOnly: !this.props.canEdit,
    },
  ] as any;

  static defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    loantranchetierSet: [emptyTrancheTier],
    name: 'Tranche',
  };

  state = {
    pendingDelete: null,
  };

  handleAddTier = () => {
    this.props.onAddTierRow();
  };

  handleRequestDelete = (row: LoanTrancheTierType) => {
    if (this.props.canEdit) {
      this.setState({
        pendingDelete: row,
      });
    }
  };

  handleConfirmDelete = () => {
    if (this.state.pendingDelete) {
      this.props.onDeleteTierRow(this.state.pendingDelete);
      this.setState({ pendingDelete: null });
    }
  };

  handleCancelDelete = () => {
    this.setState({
      pendingDelete: null,
    });
  };

  deleteModalMessageAccessor = (row: LoanTrancheTierType) =>
    `Delete Tier ${row.tierNumber || ''}?`;

  render() {
    const {
      loantranchetierSet,
      name,
      onEditTierRow,
      index,
      canEdit,
    } = this.props;
    const rows: ReadonlyArray<LoanTrancheTierType> = loantranchetierSet;
    return (
      <div className="TrancheTierForm">
        <Header as="h5">{name || `Tranche ${index || ''}`}</Header>
        <TrancheTierFormGrid
          borderlessHeader
          columns={this.columns}
          initialEditState
          isSortable={false}
          onDelete={canEdit ? this.handleRequestDelete : undefined}
          onValueChanged={onEditTierRow}
          rowKey="id"
          rows={rows as any}
        />
        {canEdit && (
          <>
            <Button.Text
              className="addTier"
              label="+ Add Tier"
              onClick={this.handleAddTier}
            />
            <ConfirmModal
              header="Delete Tier?"
              isOpen={!!this.state.pendingDelete}
              message={
                this.state.pendingDelete
                  ? this.deleteModalMessageAccessor(this.state.pendingDelete)
                  : ''
              }
              onConfirm={this.handleConfirmDelete}
              onReject={this.handleCancelDelete}
            />
          </>
        )}
      </div>
    );
  }
}

export default LoanTrancheTierGrid;
