import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import { AdditionalFeesFormDispatch, FeesGridFormProps } from './types';
import FeesGridForm from './FeesGridForm';
import { invariant } from 'utils';
import { RouteParams, withRouteParams } from 'routing';
import {
  FormReduxRawState,
  Column,
  FormMediator,
  withLoadingIndicator,
} from 'components';

import { ReduxDirectory } from 'lsredux';
import {
  FeesFormQuery,
  FeeKindsQuery,
  MyUserQuery,
  DealOrganizationDealRolesQuery,
} from 'lsgql';
import feesFormRedux from 'lsredux/reducer/forms/fees';

import { DispatchMethods } from 'lsredux/genericForms';
import { DealType, LoanTrancheTierType } from 'types';

const GenericError = 'Error occurred in Dispatch handler';

function mapStateToProps(
  state: any,
): FormReduxRawState & {
  enableMultiLenderView: boolean;
} {
  return {
    rawData: state.getIn(ReduxDirectory.FeesFormKeyPath),
    enableMultiLenderView: state.getIn(ReduxDirectory.MultiLenderViewKeyPath),
  };
}

type FeesDispatchMethods = DispatchMethods<DealType> &
  AdditionalFeesFormDispatch;

function propsMapper(ownProps: FeesGridFormProps) {
  const { user, dealOrganizationDealRoles } = ownProps;

  const userInstitutionId = user && user.institution && user.institution.id;
  const userDealOrganizationDealRole =
    dealOrganizationDealRoles &&
    dealOrganizationDealRoles.find(
      e => e.institution && e.institution.id === userInstitutionId,
    );

  const isBorrower =
    userDealOrganizationDealRole &&
    userDealOrganizationDealRole.dealJob === 'BORROWER';
  return { isBorrower };
}

function mapDispatchToProps(dispatch: any): FeesDispatchMethods {
  const bound: DispatchMethods<DealType> = feesFormRedux.actions.generateActions(
    dispatch,
  );

  const additional = {
    handleAddTierRow(trancheIndex: number, trancheId: string) {
      invariant(trancheIndex >= 0, GenericError);
      // tranches, trancheIndex, loantrancheTierSet
      const keyPath = [
        'loantrancheSet',
        trancheIndex.toString(),
        'loantranchetierSet',
      ];
      bound.addEntity(keyPath, {
        id: uuid(),
        __typename: 'LoanTrancheTierType',
        lowAmount: '0',
        highAmount: null,
        loanTrancheId: trancheId,
      });
    },

    handleDeleteTierRow(trancheIndex: number, tier: LoanTrancheTierType) {
      invariant(trancheIndex >= 0, GenericError);
      invariant(tier, GenericError);

      const keyPath = [
        'loantrancheSet',
        trancheIndex.toString(),
        'loantranchetierSet',
      ];

      bound.removeEntity(keyPath, tier);
    },

    handleEditTierRow(
      rowKey: any,
      column: Column<LoanTrancheTierType, any>,
      value: string,
      row: LoanTrancheTierType,
      trancheIndex: number,
    ) {
      invariant(trancheIndex >= 0, GenericError);

      const keyPath = [
        'loantrancheSet',
        trancheIndex.toString(),
        'loantranchetierSet',
      ];

      bound.replaceEntity(keyPath, {
        ...row,
        [column.columnName]: value,
      });
    },
  };

  return { ...bound, ...additional };
}

export default compose(
  FeeKindsQuery,
  withRouteParams([RouteParams.dealId]),
  FeesFormQuery,
  MyUserQuery,
  DealOrganizationDealRolesQuery,
  withLoadingIndicator('loading'),
  withProps(propsMapper),
  connect(mapStateToProps, mapDispatchToProps),
  FormMediator({
    formId: 'feesForm',
    disableFrame: true,
    baseObjectTypeName: 'Deal',
  }),
)(FeesGridForm);
