import { gql } from '@apollo/client';
import moment from 'moment';
import { CalendarItemFields } from '../fragments';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { momentDateFormat, threeMonthConst, weekConst } from 'components';
import { CalendarItemType, CalendarItemCategoryType } from 'types';

const CalendarItemsAllDealsQueryDefinition = gql`
  query CalendarItemsQuery(
    $viewStart: String
    $viewEnd: String
    $categories: [String]
  ) {
    calendarItems(
      filters: [
        { name: "date__gte", value: $viewStart }
        { name: "date__lt", value: $viewEnd }
        { name: "category__slug__in", listValue: $categories }
      ]
    ) {
      ...CalendarItemFields
    }
  }
  ${CalendarItemFields}
`;
const empty = [];

type OwnProps = {
  calendarItemCategories: Array<CalendarItemCategoryType>;
  categoryFilterIgnoreMap: {
    [key: string]: boolean;
  };
  isListView?: boolean;
  viewEnd?: Date;
  viewRange: 'week' | 'month' | '3 month';
  viewStart: Date;
};

const CalendarItemsAllDealsQuery = standardQueryBuilder(
  CalendarItemsAllDealsQueryDefinition,
  {
    typename: 'CalendarItemsAllDeals',
    skip: ({ calendarItemCategories }: OwnProps) => !calendarItemCategories,
    variables: (props: OwnProps) => {
      const {
        viewStart,
        viewEnd,
        viewRange,
        calendarItemCategories,
        categoryFilterIgnoreMap,
        isListView,
      } = props;
      const startMoment = moment(viewStart);
      const endMoment =
        viewRange === threeMonthConst
          ? moment(viewStart).add(3, 'months')
          : moment(viewStart).add(1, `${viewRange}s` as any);
      if (viewRange !== weekConst) {
        startMoment.startOf('month');
        startMoment.startOf('week');
        endMoment.endOf('week');
      }
      return {
        viewStart: startMoment.format(momentDateFormat),
        viewEnd: isListView
          ? moment(viewEnd).format(momentDateFormat)
          : endMoment.format(momentDateFormat),
        categories: (calendarItemCategories || empty)
          .map(category => category.slug)
          .filter(category => category && !categoryFilterIgnoreMap[category]),
      };
    },
    results: (
      data: { calendarItems: Array<CalendarItemType> | null | undefined },
      props: { calendarItems: Array<CalendarItemType> | null | undefined },
    ) => ({
      calendarItems: [
        ...(props.calendarItems || []),
        ...(data.calendarItems || []),
      ],
    }),
  },
);

export default CalendarItemsAllDealsQuery;
