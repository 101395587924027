import { FormReduxState, FormReduxArgs } from '../types';
import { BaseType } from 'types';

/* eslint-disable import/prefer-default-export */

/**
 * Convert state entity into the state shape
 * @param {*} initial
 */
export function getFormShape<TOut extends BaseType, TIn extends BaseType>(
  initial: TOut,
  args?: FormReduxArgs<TOut, TIn>,
): FormReduxState<TOut> {
  return {
    data: initial,
    errors: null,
    pendingDelete: {},
    cleanData: initial,
    isDirty: false,
    isSaving: false,
    autoSave: !!(args && args.enableAutoSave === true),
    autoValidate: !!(args && args.enableAutoValidate === true),
  };
}
