import camelCase from 'lodash/camelCase';

import { GraphQLError } from 'graphql';

import { MutationResult, QueryResult } from '../types';
import { DispatchResponse } from './types';

import { getMethodNames } from './utils/extractMutationMethods';
import { getTypeNameString, getCamelTypeNameString, invariant } from 'utils';
import {
  BaseType,
  ObjectTypeNames,
  ObjectBaseTypeNames,
  ID,
  ValidationMap,
} from 'types';

/* eslint-disable no-param-reassign */

const double = '"';
const singleRegex = /'/g;

function parseErrorObject(
  e: GraphQLError,
  results: {
    [fields: string]: string;
  },
): void {
  if (!e || !e.message) return;

  try {
    // message can contain many field errors

    const parsed = JSON.parse(e.message.replace(singleRegex, double));

    Object.keys(parsed).forEach(propertyName => {
      if (parsed[propertyName] && parsed[propertyName].length > 0) {
        if (propertyName === '__all__') {
          const messages = parsed[propertyName];
          results[propertyName] = messages;
        } else {
          if (parsed[propertyName].length > 1) {
            // eslint-disable-next-line
            console.warn(
              `ValidationError: ${propertyName} was already present in the validation map; multiple errors for a field are not supported`,
            );
          }
          const message = parsed[propertyName][0];
          results[camelCase(propertyName)] = message;
        }
      }
    });
  } catch (ex) {
    // eslint-disable-next-line
    console.error('Failed to parse error response', e);

    // eslint-disable-next-line
    console.error(ex);
  }
}

/**
 * For a typename and GraphQL response, determine the property
 * which contains the response data.  Typically this property name
 * corresponds to the operation name.  e.g. if `createDeal` was
 * the executed mutation, then the created deal will be on the
 * `createDeal` property of `response.data`
 *
 * @param {*} typeName
 * @param {*} response
 */
function getResponseDataName<T extends BaseType>(
  typeName: ObjectBaseTypeNames,
  response: MutationResult<T> | QueryResult<T>,
) {
  const { data } = response;

  if (!data) return null;

  const names = getMethodNames(typeName);

  if (data[names.createMethodName]) return names.createMethodName;

  if (data[names.setMethodName]) return names.setMethodName;

  if (data[names.stubMethodName]) return names.stubMethodName;

  if (data[names.deleteMethodName]) return names.deleteMethodName;

  return null;
}

/**
 * Extract results and errors from a GraphQL Response object
 * @param {*} entityType
 * @param {*} response
 */
export default function parseResponse<T extends BaseType>(
  entityType: ObjectTypeNames,
  response: MutationResult<T> | QueryResult<T>,
  validationMap: ValidationMap,
  entityId: ID,
): DispatchResponse<T> {
  invariant(entityId, 'entityId must be provided for parseResponse');
  invariant(validationMap, 'parseResponse requires a ValidationMap');

  /*
  invariant(entityType, 'entityType was null of undef');

  invariant(
    response.data || response.errors,
    'Expected ExecutionResult<T> to have a data or errors property',
  );
  */
  const typeName: ObjectBaseTypeNames = getTypeNameString(entityType);
  const camelTypeName: string = getCamelTypeNameString(entityType);

  const { data } = response;

  let entity: T | null | undefined = null;

  let ok: boolean | null | undefined = null;

  if (data) {
    const methodName: string | null | undefined = getResponseDataName<T>(
      typeName,
      response,
    );

    if (methodName && data[methodName]) {
      /*
        This is a mutation result, cast it so that flow understands that
        it has the `ok` property, which is absent from QueryResult types
      */
      ({ ok } = ((data as any) as MutationResult<T>)[methodName]);

      if (data[methodName] && data[methodName][camelTypeName]) {
        entity = data[methodName][camelTypeName];
      }
    } else {
      // This means a query has executed, which lack `ok`
      // Should not need to extract result from a query
      entity = data as any;

      /*
        Query does not provide 'ok' on the result object
        Let's interpret the presence of an entity as
        ok = true
      */
      ok = entity !== undefined && entity !== null;
    }
  }

  if (response.errors && response.errors.length > 0) {
    // We're not ok if we have errors
    ok = false;

    const fieldResults: {
      [fields: string]: string;
    } = {};
    response.errors.forEach(e => parseErrorObject(e, fieldResults));

    const id: ID = entityId;

    if (!validationMap[typeName]) {
      validationMap[typeName] = {};
    }
    if (!validationMap[typeName][id]) {
      validationMap[typeName][id] = { errors: {}, warnings: {} };
    }
    validationMap[typeName][id].errors = {
      ...validationMap[typeName][id].errors,
      ...fieldResults,
    };
  }

  invariant(
    ok !== null && ok !== undefined,
    `Failed to determine "ok" value for type ${camelTypeName}`,
  );

  const res: DispatchResponse<T> = {
    entity: entity || null,
    ok,
  };

  return res;
}
