import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import {
  DealAssigneeBasicFields,
  SbaPppForgivenessWorkflowShallowFields,
  SbaPppForgivenessCalculationShallowFields,
} from '../fragments';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { DealType, WorkflowType } from 'types';

/* eslint-disable graphql/template-strings*/

export const DEALS_QUERY: DocumentNode = gql`
  query DealsQuery(
    $filters: DealFiltersInput
    $sortBy: SortByInput
    $search: String
    $cursor: Int
    $first: Int
  ) {
    deals(
      filters: $filters
      sortBy: $sortBy
      search: $search
      after: $cursor
      first: $first
    ) @connection(key: "deals") {
      edges {
        node {
          id
          name
          borrowerName
          created
          identity
          isFavorite
          modified
          stage
          status
          sbaApprovalDate
          totalCommitmentAmount
          dealassignee {
            ...DealAssigneeBasicFields
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      rowCount
      totalSize
      filterOptions {
        dealAssignee
        stage
      }
    }
  }
  ${DealAssigneeBasicFields}
`;

const LegacyDealsQueryDefinition: DocumentNode = gql`
  query LegacyDealsQuery(
    $filters: DealFiltersInput
    $search: String
    $workflow: String
  ) {
    legacyDeals(filters: $filters, search: $search, workflow: $workflow) {
      edges {
        node {
          id
          name
          borrowerName
          created
          category
          identity
          isFavorite
          lenderName
          modified
          stage
          status
          sbaApprovalDate
          totalCommitmentAmount
          sbappploanapplication {
            sbaLoanNumber
          }
          dealassignee {
            ...DealAssigneeBasicFields
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      rowCount
    }
  }
  ${DealAssigneeBasicFields}
`;

const ForgivenessDealsQueryDefinition: DocumentNode = gql`
  query LegacyDealsQuery(
    $filters: DealFiltersInput
    $search: String
    $workflow: String
  ) {
    legacyDeals(filters: $filters, search: $search, workflow: $workflow) {
      edges {
        node {
          id
          name
          borrowerName
          category
          created
          identity
          isFavorite
          modified
          stage
          status
          sbaApprovalDate
          totalCommitmentAmount
          dealassignee {
            ...DealAssigneeBasicFields
          }
          sbaPppForgivenessWorkflow {
            ...SbaPppForgivenessWorkflowShallowFields
            sbapppforgivenesscalculation {
              ...SbaPppForgivenessCalculationShallowFields
            }
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      rowCount
    }
  }
  ${DealAssigneeBasicFields}
  ${SbaPppForgivenessWorkflowShallowFields}
  ${SbaPppForgivenessCalculationShallowFields}
`;

interface DealsQueryVariablesType {
  searchQuery?: string | null;
  workflow?: WorkflowType;
}
export const DealsQuery = standardQueryBuilder(LegacyDealsQueryDefinition, {
  typename: 'Deals',

  /**
   * Per a slack discussion with an apollo-client dev:
   *  There is no reasonable and effective method for updating
   * cached filtered and/or paginated queries after a mutation.
   * The only reasonable current approach is to disable caching
   * for the query.  A future version of apollo *may* include
   * an `invalidateQuery` cache method :(
   */
  disableCache: true,
  includePollingMethods: true,
  variables: ({ searchQuery, workflow }: DealsQueryVariablesType) => ({
    search: searchQuery,
    workflow,
  }),
  results: (data: {
    legacyDeals: {
      edges: Array<{ node: DealType }> | null | undefined;
      rowCount: number | null | undefined;
    };
  }) => {
    const {
      legacyDeals: { edges, rowCount },
    } = data;

    return { deals: edges.map(edge => edge.node), rowCount };
  },
});

export const ForgivenessDealsQuery = standardQueryBuilder(
  ForgivenessDealsQueryDefinition,
  {
    typename: 'Deals',

    /**
     * Per a slack discussion with an apollo-client dev:
     *  There is no reasonable and effective method for updating
     * cached filtered and/or paginated queries after a mutation.
     * The only reasonable current approach is to disable caching
     * for the query.  A future version of apollo *may* include
     * an `invalidateQuery` cache method :(
     */
    disableCache: true,
    includePollingMethods: true,
    variables: ({ searchQuery, workflow }: DealsQueryVariablesType) => ({
      search: searchQuery,
      workflow,
    }),
    results: (data: {
      legacyDeals: {
        edges: Array<{ node: DealType }> | null | undefined;
        rowCount: number | null | undefined;
      };
    }) => {
      const {
        legacyDeals: { edges, rowCount },
      } = data;

      return { deals: edges.map(edge => edge.node), rowCount };
    },
  },
);
