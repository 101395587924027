import { setContext } from '@apollo/link-context';
import Cookies from 'js-cookie';

const token = 'csrftoken';
const XCSRFTokenHeader = 'X-CSRFToken';
const Auth0AuthorizationHeader = 'Authorization';

/**
 * Add authentication headers to request
 */
const AuthLink = (authToken?: string) =>
  setContext(() => ({
    headers: {
      [XCSRFTokenHeader]: Cookies.get(token),
      [Auth0AuthorizationHeader]: `Bearer ${authToken}`,
    },
  }));

export default AuthLink;
