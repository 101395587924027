import uuid from 'uuid/v4';
import {
  DealMutationMethods,
  CreditRatingMutationMethods,
  DealValidator,
} from '../../../graphql';
import Directory from '../../directory';

import { createForm } from '../../genericForms';
import { DealType } from 'types';

const initialDeal: DealType = {
  id: uuid(),
  name: '',
  category: 'STANDARD',
  creditratingSet: [],
  __typename: 'DealType',
};

const lifecycleMethods = {
  mutations: {
    ...DealMutationMethods,
    ...CreditRatingMutationMethods,
  },
  validators: {
    Deal: DealValidator,
  },
};

const dealFormRedux = createForm<DealType, DealType>(
  Directory.DealKey,
  initialDeal,
  lifecycleMethods,
);

export default dealFormRedux;
