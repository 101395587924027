import uuid from 'uuid/v4';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import SbaSecondDrawOnboardingMutations from '../../../graphql/mutations/SbaSecondDrawOnboardingMutations';

const initialSbaSecondDrawOnboarding = {
  id: uuid(),
  __typename: 'SbaSecondDrawOnboarding',
};

const lifecycle = {
  mutations: {
    ...SbaSecondDrawOnboardingMutations,
  },
  validators: {},
};

export default createForm<any, any>(
  Directory.SbaSecondDrawOnboardingKey,
  initialSbaSecondDrawOnboarding as any,
  lifecycle,
);
