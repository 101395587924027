import * as React from 'react';
import { getCompleteFormsError } from '../Resources';
import { Modal, Text, Summary } from 'components';
import { SbaPppForgivenessWorkflowType } from 'types';
import transitionForgivenessWorkflowMutation from 'lsgql/mutations/TransitionForgivenessWorkflow';

interface ModalFormProps {
  isOpen: boolean;
  handleModalToggle: (e?: React.SyntheticEvent) => void;
  noModal?: boolean;
  sbaPppForgivenessWorkflow: SbaPppForgivenessWorkflowType;
}

const ForgivenessApprovalForm = (props: ModalFormProps) => {
  const { sbaPppForgivenessWorkflow, handleModalToggle, isOpen } = props;

  if (!sbaPppForgivenessWorkflow) {
    return null;
  }

  const forgivenessAmount =
    sbaPppForgivenessWorkflow?.sbapppforgivenesscalculation
      ?.forgivenessAmountCalculated;

  const handleSave = async () => {
    const res = await transitionForgivenessWorkflowMutation({
      workflowId: sbaPppForgivenessWorkflow.id,
      state: 'BORROWER_SIGNATURE_REQUESTED',
      errorToast: getCompleteFormsError(
        sbaPppForgivenessWorkflow.isSFormEligible,
      ),
    });

    if (res.data?.transitionForgivenessWorkflow?.ok) {
      handleModalToggle();
    }
  };

  return (
    <Modal
      className="steel"
      confirmButtonText="Submit"
      header="Approve and Submit to SBA"
      isOpen={isOpen}
      onClose={handleModalToggle}
      onConfirm={handleSave}
      width="OneThird"
    >
      <Text value="Upon submit, the Borrower will be prompted to DocuSign the forgiveness application. The signed request will then be sent directly to the SBA." />
      <br />
      <Summary.MoneyField
        label="Forgiveness Amount"
        value={forgivenessAmount}
        withPlaceholder
      />
    </Modal>
  );
};

export default ForgivenessApprovalForm;
