import { mutationBuilder } from './builders';
import {
  CreateSbaPppForgivenessEzFormQualificationsMutationDefinition,
  SetSbaPppForgivenessEzFormQualificationsMutationDefinition,
} from './definitions';
import {
  SbaPppForgivenessEzFormQualificationsInput,
  SbaPppForgivenessEzFormQualificationsType,
} from 'types';

const SbaPppForgivenessEzFormQualificationsMutationMethods = {
  createSbaPppForgivenessEzFormQualifications: mutationBuilder<
    SbaPppForgivenessEzFormQualificationsInput,
    SbaPppForgivenessEzFormQualificationsType
  >(CreateSbaPppForgivenessEzFormQualificationsMutationDefinition, {
    build: mutate => sbaPppForgivenessEzFormQualificationsData =>
      mutate({
        variables: { sbaPppForgivenessEzFormQualificationsData },
      }),
  }),
  setSbaPppForgivenessEzFormQualifications: mutationBuilder<
    SbaPppForgivenessEzFormQualificationsInput,
    SbaPppForgivenessEzFormQualificationsType
  >(SetSbaPppForgivenessEzFormQualificationsMutationDefinition, {
    build: mutate => sbaPppForgivenessEzFormQualificationsData =>
      mutate({ variables: { sbaPppForgivenessEzFormQualificationsData } }),
  }),
};

export default SbaPppForgivenessEzFormQualificationsMutationMethods;
