import * as React from 'react';
import { Button } from 'components';
import './SolveFor.scss';

/* eslint-disable react/no-multi-comp */

function SolveFor(props: { children: React.ReactNode }) {
  return <span className="SolveFor__Item">{props.children}</span>;
}

class SolveForButton extends React.Component<{
  onChange: (arg0: string) => void;
  text: string;
  value: string;
}> {
  handleOnChange = () => {
    this.props.onChange(this.props.value);
  };

  render() {
    const { text } = this.props;

    return (
      <SolveFor>
        <Button
          compact
          isActionButton
          label={text}
          onClick={this.handleOnChange}
        />
      </SolveFor>
    );
  }
}

export default class SolveForRow extends React.Component<{
  children: React.ReactNode;
}> {
  static Item = SolveFor;

  static Button = SolveForButton;

  render() {
    const { children } = this.props;
    return <div className="SolveForRow">{children}</div>;
  }
}
