import moment from 'moment';
import { fromJS } from 'immutable';

import { monthConst, threeMonthConst } from '@loanstreet-usa/design-system';
import {
  SET_VIEW_START,
  SET_VIEW_END,
  RESET_LISTVIEW_DATES,
  RESET_CALENDARVIEW_DATES,
} from '../../actions/calendarViewStartEnd';
import standardReducerFactory from '../standardReducer';

function getViewStartFromRange(range: 'week' | 'month' | '3 month') {
  return moment(new Date())
    .startOf(range === threeMonthConst ? monthConst : range)
    .toDate();
}

const initialState = fromJS({
  viewStart: new Date(),
  viewEnd: moment().add(1, 'years'),
});

const calendarViewStartEndReducerMap: {
  [key: string]: (state: any, action: any) => Record<string, any>;
} = {};

calendarViewStartEndReducerMap[SET_VIEW_START] = (state, action) =>
  state.set('viewStart', action.payload.viewStart);

calendarViewStartEndReducerMap[SET_VIEW_END] = (state, action) =>
  state.set('viewEnd', action.payload.viewEnd);

calendarViewStartEndReducerMap[RESET_LISTVIEW_DATES] = state => {
  const newState = state.set('viewStart', new Date());
  return newState.set('viewEnd', moment().add(1, 'years'));
};

calendarViewStartEndReducerMap[RESET_CALENDARVIEW_DATES] = (state, action) =>
  state.set('viewStart', getViewStartFromRange(action.payload.viewRange));

export default standardReducerFactory(
  calendarViewStartEndReducerMap,
  initialState,
);
