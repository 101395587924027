export const RouteNouns = {
  calendar: 'calendar',
  dataroom: 'dataroom',
  deal: 'deal',
  fees: 'fees',
  info: 'info',
  orderbook: 'orderbook',
  people: 'people',
  termsheet: 'termsheet',
  invitation: 'invitation',
  sbaPpp: 'sba-ppp',
};

/**
 * Contains the list of possible values
 * contained within a Route
 */
export type RouteParamsType =
  | 'trancheId'
  | 'dealId'
  | 'feeId'
  | 'institutionId'
  | 'institutionSection'
  | 'institutionTicker'
  | 'institutionJob'
  | 'sbaPppApplicationId'
  | 'userId'
  | 'create'
  | 'none'
  | 'invitationKey'
  | 'loanId'
  | 'paymentRecordId'
  | 'applicableMarginChangeId'
  | 'generalNoticeId'
  | 'interestPeriodElectionId'
  | 'facilityId'
  | 'taskId'
  | 'disableOnboarding';

export const RouteParams: { [key: string]: RouteParamsType } = {
  disableOnboarding: 'disableOnboarding',
  trancheId: 'trancheId',
  dealId: 'dealId',
  // orderBookId: 'orderBookId',
  feeId: 'feeId',
  institutionId: 'institutionId',
  institutionSection: 'institutionSection',
  institutionTicker: 'institutionTicker',
  institutionJob: 'institutionJob',
  sbaPppApplicationId: 'sbaPppApplicationId',
  userId: 'userId',

  /**
   * A new object(s) should be created
   */
  create: 'create',

  /**
   * No valid value available
   */
  none: 'none',

  /**
   * UUID Invitation key generated by the backend
   */
  invitationKey: 'invitationKey',
  loanId: 'loanId',
  paymentRecordId: 'paymentRecordId',
  applicableMarginChangeId: 'applicableMarginChangeId',
  generalNoticeId: 'generalNoticeId',
  interestPeriodElectionId: 'interestPeriodElectionId',
  facilityId: 'facilityId',
  taskId: 'taskId',
};

export const RouteTokens = {
  disableOnboarding: `:${RouteParams.disableOnboarding}`,
  dealId: `:${RouteParams.dealId}`,
  trancheId: `:${RouteParams.trancheId}`,
  // orderBookId: `:${RouteParams.orderBookId}`,
  institutionId: `:${RouteParams.institutionId}`,
  institutionTicker: `:${RouteParams.institutionTicker}`,
  institutionJob: `:${RouteParams.institutionJob}`,
  sbaPppApplicationId: `:${RouteParams.sbaPppApplicationId}`,
  loanId: `:${RouteParams.loanId}`,
  facilityId: `:${RouteParams.facilityId}`,
  feeId: `:${RouteParams.feeId}`,
  create: `:${RouteParams.create}`,
  userId: `:${RouteParams.userId}`,
  invitationKey: `:${RouteParams.invitationKey}`,
  institutionSection: `:${RouteParams.institutionSection}`,
  paymentRecordId: `:${RouteParams.paymentRecordId}`,
  applicableMarginChangeId: `:${RouteParams.applicableMarginChangeId}`,
  generalNoticeId: `:${RouteParams.generalNoticeId}`,
  interestPeriodElectionId: `:${RouteParams.interestPeriodElectionId}`,
  taskId: `:${RouteParams.taskId}`,
};
export type RouteTokensType = keyof typeof RouteTokens;
export type RoutePathsType = keyof typeof RouteNouns;
