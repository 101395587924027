import { fromJS, Map } from 'immutable';

import {
  DEALPDF_CHANGE_PAGE,
  DEALPDF_CHANGE_SCALE,
  DEALPDF_CHANGE_ROTATION,
  DEALPDF_OPEN_FILE,
  DEALPDF_CLOSE_FILE,
} from '../../actions/dealpdfviewer';
import standardReducerFactory from '../standardReducer';
import { PDFViewerPropsType } from 'types';

export const initialPdfProps: PDFViewerPropsType = {
  currentlyOpen: null,
  pageIndex: 0,
  scale: 1,
  rotations: Map() as any,
};
const initialState = fromJS({});

const reducerMap = {};

reducerMap[DEALPDF_OPEN_FILE] = (state, action) =>
  state.setIn(['currentlyOpen'], action.payload);

reducerMap[DEALPDF_CLOSE_FILE] = state => state.setIn(['currentlyOpen'], null);

reducerMap[DEALPDF_CHANGE_PAGE] = (state, action) =>
  state.setIn(
    ['files', action.payload.id, 'pageIndex'],
    action.payload.pageIndex,
  );

reducerMap[DEALPDF_CHANGE_SCALE] = (state, action) =>
  state.setIn(['files', action.payload.id, 'scale'], action.payload.scale);

reducerMap[DEALPDF_CHANGE_ROTATION] = (state, action) =>
  state.setIn(
    ['files', action.payload.id, 'rotations'],
    action.payload.rotations,
  );

export default standardReducerFactory(reducerMap, initialState);
