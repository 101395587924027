import * as React from 'react';
import LenderRegistryForm from '../../features/lenderRegistryForm/LenderRegistryFormContainer';
import { OrderBook } from '../../features/orderBookForm';
import DealPeopleContent from '../../features/dealPeople/dealPeopleContent/DealPeopleContentContainer';
import PaymentsView from '../../features/paymentsView/PaymentsView';
import { RoutingContext } from '../types';
import { RouteTable } from '../RouteTable';
import { SecuredRoute } from './RouteRenderers';
import { DealPerspectivePermissions } from 'security';

/* eslint-disable react/display-name */

/**
 * Valid routes within the context of a deal
 */
export const salesRoutes: RoutingContext = {
  context: 'Deal',
  routes: [
    {
      render: SecuredRoute(
        OrderBook,
        DealPerspectivePermissions.view_investor_book,
      ),

      path: RouteTable.deal.toDealOrderBook(),
      exact: true,
    },
    {
      render: SecuredRoute(
        LenderRegistryForm,
        DealPerspectivePermissions.view_lender_registry,
      ),
      path: RouteTable.deal.toDealLenderRegistryForm(),
      exact: true,
    },
  ],
};

export const peopleRoutes: RoutingContext = {
  context: 'Deal',
  routes: [
    {
      render: SecuredRoute(
        () => <DealPeopleContent view="MyTeam" />,
        DealPerspectivePermissions.view_own_team,
      ),
      path: RouteTable.deal.toDealPeopleMyTeam(),
      exact: true,
    },
    {
      render: SecuredRoute(
        () => <DealPeopleContent view="CoLenders" />,
        DealPerspectivePermissions.view_colenders,
      ),
      path: RouteTable.deal.toDealPeopleCoLenders(),
      exact: true,
    },
    {
      render: SecuredRoute(
        () => <DealPeopleContent view="ServicingAgents" />,
        DealPerspectivePermissions.view_servicing_agents,
      ),
      path: RouteTable.deal.toDealPeopleServicingAgents(),
      exact: true,
    },
    {
      render: SecuredRoute(
        () => <DealPeopleContent view="LeadLender" />,
        DealPerspectivePermissions.view_lead_lenders,
      ),
      path: RouteTable.deal.toDealPeopleLeadLender(),
      exact: true,
    },
    {
      render: SecuredRoute(
        () => <DealPeopleContent view="Borrower" />,
        DealPerspectivePermissions.view_borrowers,
      ),
      path: RouteTable.deal.toDealPeopleBorrower(),
      exact: true,
    },
  ],
};

export const paymentRoutes: RoutingContext = {
  context: 'Deal',
  routes: [
    {
      render: () => <PaymentsView viewType="SCHEDULE" />,
      path: RouteTable.deal.toDealPaymentSchedule(),
      exact: true,
    },
    {
      render: () => <PaymentsView viewType="ACTIVITY" />,
      path: RouteTable.deal.toDealPaymentActivity(),
      exact: true,
    },
  ],
};
