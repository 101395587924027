import * as React from 'react';
import { haltBubble } from 'utils';
import { uploadDataroomFile, FileUploadData } from 'lsgql/uploadFile';
import raiseAlert from 'lsredux/raiseAlert';
import { Auth0Context } from 'security';
import { StringPair } from 'types';
import { RouteTo, TextLink } from 'components';

const supportedFormats = [
  'pdf',
  'xls',
  'xlsx',
  'csv',
  'doc',
  'docx',
  'jpg',
  'jpeg',
  'png',
];

type PropsType = {
  destination: string;
};

class FileUploader extends React.Component<PropsType> {
  static contextType = Auth0Context;
  uploadFile = uploadDataroomFile;

  constructor(props: any) {
    super(props);
    this.uploadFile = this.uploadFile.bind(this);
  }

  handleUpload = async (e: React.SyntheticEvent<HTMLInputElement>) => {
    haltBubble(e);

    const fileList = e.currentTarget.files;
    // for (let i = 0; i < fileList.length; i++) {
    const file = fileList[0];

    const fileData: FileUploadData = {
      file: file,
      parentId: this.props.destination,
    };
    try {
      const res = await this.uploadFile(fileData);

      if (res) {
        const supportedFile = supportedFormats.some(format =>
          file.name.toLowerCase().endsWith(format),
        );
        if (supportedFile) {
          raiseAlert(
            `${file.name} successfully uploaded to the Dataroom`,
            'success',
          );
        } else {
          raiseAlert(
            'Warning: Your file has been successfully uploaded to the Dataroom but is not in a format accepted by the SBA (pdf, xls, xlsx, csv, doc, docx, jpg, jpeg, png).',
            'warning',
          );
        }
      }
    } catch (error) {
      raiseAlert(
        `${file.name} failed to upload. ${error?.file[0]} Please try again.`,
        'error',
      );
    }
    // }
  };

  render() {
    const { destination } = this.props;

    return (
      <>
        <div className="FileUploader">
          <label
            className="FileUploader__Label"
            htmlFor={`uploadFile+${destination}`}
          >
            Upload
          </label>
          <input
            // accept=".pdf"
            className="FileUploader__Input"
            id={`uploadFile+${destination}`}
            // multiple
            name={`uploadFile+${destination}`}
            onChange={this.handleUpload}
            type="file"
          />
        </div>
      </>
    );
  }
}

export default FileUploader;

export function FileUploaderCheck(props: {
  folders: StringPair[];
  destination: string;
  dataroomUrl: string;
}) {
  const { destination, folders, dataroomUrl } = props;

  const folder = folders.find(({ name }) => name === destination);

  return folder ? (
    <FileUploader destination={folder.value} />
  ) : (
    <TextLink as={RouteTo} label="Upload" to={dataroomUrl} />
  );
}
