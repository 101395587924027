import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client, {
  IdToken,
  RedirectLoginResult,
  RedirectLoginOptions,
  Auth0ClientOptions,
  PopupLoginOptions,
  getIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
} from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { executeLogout } from './authentication/authMethods';
import { purgeAuthenticatedClient } from 'lsgql/authenticatedClient';
import { logToSentry } from 'utils';

export interface Auth0RedirectState {
  targetUrl?: string;
}

export type Auth0User = Omit<IdToken, '__raw'>;

interface Auth0ProviderOptions {
  children: React.ReactElement;
  onRedirectCallback(result: RedirectLoginResult): void;
}

interface Auth0ProviderProps extends Auth0ProviderOptions, Auth0ClientOptions {}

interface Auth0ContextType {
  user?: Auth0User;
  isAuthenticated: boolean;
  isInitializing: boolean;
  isPopupOpen: boolean;
  loginWithPopup(o?: PopupLoginOptions): Promise<void>;
  handleRedirectCallback(): Promise<RedirectLoginResult>;
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>;
  loginWithRedirect(o?: RedirectLoginOptions): Promise<void>;
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>;
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>;
  logout(o?: LogoutOptions): void;
}

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<Auth0ContextType | null>(null);

export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = (props: Auth0ProviderProps) => {
  const {
    children,
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    ...initOptions
  } = props;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<Auth0User>();
  const [auth0Client, setAuth0Client] = useState<Auth0Client>();
  const [isInitializing, setIsInitializing] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0Client(auth0FromHook);

      if (window.location.search.includes('code=')) {
        let newAppState: RedirectLoginResult = {};
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          newAppState = appState;
        } finally {
          onRedirectCallback(newAppState);
        }
      }

      const authed = await auth0FromHook.isAuthenticated();

      if (authed) {
        const userProfile = await auth0FromHook.getUser();

        setIsAuthenticated(true);
        setUser(userProfile);
      }

      setIsInitializing(false);
    };

    initAuth0();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const loginWithPopup = async (options?: PopupLoginOptions) => {
    setIsPopupOpen(true);

    try {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      await auth0Client!.loginWithPopup(options);
    } catch (error) {
      /* eslint-disable no-console*/
      console.error(error);
    } finally {
      setIsPopupOpen(false);
    }

    const userProfile = await auth0Client!.getUser();
    setUser(userProfile);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setIsInitializing(true);

    const result = await auth0Client!.handleRedirectCallback();
    const userProfile = await auth0Client!.getUser();

    setIsInitializing(false);
    setIsAuthenticated(true);
    setUser(userProfile);

    return result;
  };

  const loginWithRedirect = (options?: RedirectLoginOptions) =>
    auth0Client!.loginWithRedirect(options);

  const getTokenSilently = (options?: GetTokenSilentlyOptions) =>
    auth0Client!.getTokenSilently(options);

  const logout = (options?: LogoutOptions) => {
    try {
      // eslint-disable-next-line
      console.log('Execute Logout');
      executeLogout();
    } catch (e) {
      console.error('ExecuteLogout failed', e);
      logToSentry(e);
    }

    try {
      // eslint-disable-next-line
      console.log('Auth0 Logout');
      auth0Client!.logout(options);
    } catch (e) {
      console.error('Client logout failed', e);
      logToSentry(e);
    }

    try {
      // eslint-disable-next-line
      console.log('Clear Authenticated Client');
      purgeAuthenticatedClient();
    } catch (e) {
      console.error('Client purge failed', e);
      logToSentry(e);
    }
  };

  const getIdTokenClaims = (options?: getIdTokenClaimsOptions) =>
    auth0Client!.getIdTokenClaims(options);

  const getTokenWithPopup = (options?: GetTokenWithPopupOptions) =>
    auth0Client!.getTokenWithPopup(options);

  const getAuth0ContextProviderData = () => {
    return {
      user,
      isAuthenticated,
      isInitializing,
      isPopupOpen,
      loginWithPopup,
      loginWithRedirect,
      logout,
      getTokenSilently,
      handleRedirectCallback,
      getIdTokenClaims,
      getTokenWithPopup,
    };
  };
  return (
    <Auth0Context.Provider value={getAuth0ContextProviderData()}>
      {children}
    </Auth0Context.Provider>
  );
};
