import uuid from 'uuid/v4';
import {
  LoanTrancheMutationMethods,
  LoanTrancheTierMutationMethods,
  ProspectMutationMethods,
  OrderBookFetchMethod,
} from '../../../graphql';

import Directory from '../../directory';
import { createMultiEntityForm } from '../../genericForms';
import { OrderBookInput } from 'types';

const initialInvestorBook: OrderBookInput = {
  id: uuid(),
  name: '',
  loantranchetierSet: [],
  prospectSet: [],
  // seniority: '',
  // interestType: '',
  // totalCommitmentAmount: '',
  __typename: 'LoanTrancheInput',
};

function dataConverter(data: any): OrderBookInput {
  return {
    ...data,
    prospectSet:
      data.prospectSet &&
      data.prospectSet.map(p => ({
        ...p,
        tierId: p.tier ? p.tier.id : null,
        customTierId: p.customTier ? p.customTier.id : null,
      })),
  };
}

const lifecycleMethods = {
  mutations: {
    ...LoanTrancheMutationMethods,
    ...LoanTrancheTierMutationMethods,
    ...ProspectMutationMethods,
  },
  validators: {},
  fetch: OrderBookFetchMethod,
};

const orderBookFormRedux = createMultiEntityForm<
  OrderBookInput,
  OrderBookInput
>(Directory.OrderBookKey, initialInvestorBook, lifecycleMethods, {
  enableAutoSave: true,
  dataConverter,
});

export default orderBookFormRedux;
