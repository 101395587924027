export const SET_VIEW_START = 'calendarViewStartEnd_SET_VIEW_START';
export const SET_VIEW_END = 'calendarViewStartEnd_SET_VIEW_END';
export const RESET_LISTVIEW_DATES = 'calendarViewStartEnd_RESET_LISTVIEW_DATES';
export const RESET_CALENDARVIEW_DATES =
  'calendarViewStartEnd_RESET_CALENDARVIEW_DATES';

export function setViewStart(viewStart: Date) {
  return {
    type: SET_VIEW_START,
    payload: {
      viewStart,
    },
  };
}

export function setViewEnd(viewEnd: Date) {
  return {
    type: SET_VIEW_END,
    payload: {
      viewEnd,
    },
  };
}

export function resetListViewDates() {
  return {
    type: RESET_LISTVIEW_DATES,
  };
}

export function resetCalendarViewDates(viewRange: string) {
  return {
    type: RESET_CALENDARVIEW_DATES,
    payload: {
      viewRange,
    },
  };
}

export type Action = { payload: Record<string, any>; type: string };
