/* eslint-dsiable import/prefer-default-export */
export const typeNameField = '__typename';
export const isDirty: 'isDirty' = 'isDirty';
export const rootKey: 'data' = 'data';

export const cleanDataKey: 'cleanData' = 'cleanData';

export const errorsKey: 'errors' = 'errors';
export const idKey: 'id' = 'id';
export const pendingDeleteKey: 'pendingDelete' = 'pendingDelete';

export const isSaving: 'isSaving' = 'isSaving';

export const autoSave: 'autoSave' = 'autoSave';
