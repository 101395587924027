import React from 'react';
import { Button } from '@loanstreet-usa/design-system';
import { Text, Header, Divider } from 'components';
import { ID, DealClosingWorkflowType } from 'types';
import { haltBubble } from 'utils';
import triggerClosingWorkflowTransition from 'lsgql/mutations/TransitionClosingWorkflow';

interface Props {
  dealClosingWorkflow: DealClosingWorkflowType;
  dealId: ID;
}
const GenericWorkflowHeader = (props: Props) => {
  const { dealClosingWorkflow } = props;
  const { isBorrower, state } = dealClosingWorkflow;
  const handleGoBackToBorrowerReview = (e: React.SyntheticEvent) => {
    haltBubble(e);
    triggerClosingWorkflowTransition({
      workflowId: dealClosingWorkflow.id,
      state: 'lender_approve_borrower_application',
    });
  };
  const headerText = {
    borrowerPrep:
      'The borrower will notify you when their application and documents are ready for your review.',
    lenderReview:
      'The borrower has requested your review of their application materials.',
    borrowerSign: 'The borrower has been asked to sign their application.',
    finalSteps: 'The borrower has signed their application.',
  };
  const { borrowerPrep, lenderReview, borrowerSign, finalSteps } = headerText;
  const headerMap = {
    borrower_finish_application: { text: borrowerPrep },
    lender_approve_borrower_application: { text: lenderReview },
    borrower_lender_rejected_application: {
      text: borrowerPrep,
      escapeText: 'Or, skip ahead',
    },
    borrower_application_signature_requested: { text: borrowerSign },
    lender_complete_lender_application: {
      text: finalSteps,
      escapeText: 'Or, go back for further review',
    },
    lender_sign_lender_application: {
      text: finalSteps,
      escapeText: 'Or, go back for further review',
    },
    lender_submit_to_sba: { text: finalSteps, escapeText: 'Or, go back' },
    lender_awaiting_sba_response: { text: finalSteps },
    lender_review_promissory_note: { text: finalSteps },
    lender_rejected_by_sba: { text: finalSteps },
    borrower_sign_promissory_note: { text: finalSteps },
    lender_originate_loan: { text: finalSteps },
    loan_originated: { text: '' },
  };
  if (headerMap[state]) {
    const { text, escapeText } = headerMap[state];
    return (
      <div className="GenericWorkflowHeader">
        <Header as="h3">Closing Checklist</Header>
        <Divider className="mediumPad" />
        <div className="Group justified">
          <Text>
            {isBorrower
              ? 'Follow the steps below to provide supporting documents to the lender and complete your loan application.'
              : text}
          </Text>

          {escapeText && !isBorrower && (
            <Button.Text
              className="escapeHatch"
              label={escapeText}
              onClick={handleGoBackToBorrowerReview}
            />
          )}
        </div>
      </div>
    );
  }
  return null;
};
export default GenericWorkflowHeader;
