import { DispatchResponse } from '../../graphql/mutationsDispatch/types';
import { FetchMethod } from '../../graphql/types';
import { setAlert } from '../actions/alerts';
import { ActionBaseType, PayloadAction } from '../types';
import { ReduxKeys } from '../directory';
import {
  KeyPath,
  EntityPayloadAction,
  EntitiesPayloadAction,
  InitializeStateAction,
  MultiEntityActionBaseType,
  MutationResponseAction,
  MutatePropertyAction,
  MutatePropertiesAction,
  LifecycleMethods,
  SaveResponse,
  KeyPathAction,
  ToggleDirtyFlagAction,
  DeleteCollectionPayloadAction,
  SetValidationErrorsAction,
} from './types';
import { createActionTypes } from './createActionTypes';

import {
  invokeSaveAction,
  invokeValidationAction,
  invokeEntityValidationAction,
} from './saveAction';
import { invariant } from 'utils';
import { AlertType, BaseType, ValidationMap } from 'types';

export type ActionsResult<TOut extends BaseType> = {
  handleAddEntities: (
    keyPath: KeyPath,
    entities: Array<any>,
  ) => EntitiesPayloadAction;
  handleAddEntity: (keyPath: KeyPath, entity: any) => EntityPayloadAction;

  handleAutoSave: (
    fetchMethod: FetchMethod<TOut>,
    saveReduxKey: ReduxKeys | Array<ReduxKeys>,
  ) => (dispatch: any, getState: any) => Promise<SaveResponse>;

  handleAutoValidate: () => (dispatch: any, getState: any) => Promise<void>;

  handleClearState: () => ActionBaseType;

  handleCreateNewState: (entity?: TOut) => InitializeStateAction<TOut>;

  handleDeleteCollection: (
    keyPath: KeyPath,
    entities: Array<any>,
    discard?: boolean,
  ) => DeleteCollectionPayloadAction;

  handleEditPropertyAction: (
    property: KeyPath,
    value:
      | (string | null | undefined)
      | (boolean | null | undefined)
      | (Array<any> | null | undefined),
  ) => MutatePropertyAction;

  handleInitializeState: (entity?: TOut) => InitializeStateAction<TOut>;

  handleMutateProperties: (
    keyPath: KeyPath,
    slice: {},
  ) => MutatePropertiesAction;

  handleRemoveEntityAction: (
    keyPath: KeyPath,
    entity: any,
  ) => EntityPayloadAction;

  handleRemoveSelfAction: (entity: TOut) => MultiEntityActionBaseType;

  handleReplaceEntity: (keyPath: KeyPath, entity: any) => EntityPayloadAction;

  handleResponseAction: (
    e: DispatchResponse<TOut>,
  ) => MutationResponseAction<TOut>;

  handleSave: (
    saveReduxKey: ReduxKeys | Array<ReduxKeys>,
    fetchMethod?: FetchMethod<TOut>,
  ) => (dispatch: any, getState: any) => Promise<SaveResponse>;

  handleSetAlert: (
    message: string,
    type: AlertType,
  ) => PayloadAction<{
    linkText?: string;
    linkTo?: string;
    message: string;
    title?: string;
    type: AlertType;
  }>;

  handleSetValidationErrors: (
    errors: ValidationMap,
  ) => SetValidationErrorsAction;

  handleToggleAutoSave: (arg0: boolean) => PayloadAction<boolean>;

  handleToggleDirtyFlagAction: (
    keyPath: KeyPath,
    isDirty: boolean,
    recursive: boolean,
  ) => ToggleDirtyFlagAction;

  handleToggleSave: (arg0: boolean) => PayloadAction<boolean>;

  handleUndo: (keyPath: KeyPath) => KeyPathAction;

  handleValidate: () => (dispatch: any, getState: any) => Promise<void>;
};

export default function generateActionCreatorsFor<TOut extends BaseType>(
  reduxKey: ReduxKeys,
  lifeCycleMethods: LifecycleMethods<TOut>,
): ActionsResult<TOut> {
  const actionTypes = createActionTypes(reduxKey);

  const handleResponseAction = (e: DispatchResponse<TOut>) => {
    const res: MutationResponseAction<TOut> = {
      type: actionTypes.handleResponseActionType,
      payload: {
        response: e,
      },
    };
    return res;
  };

  const handleToggleSave = (isSaving: boolean) => {
    const res: PayloadAction<boolean> = {
      type: actionTypes.toggleSaveActionType,
      payload: isSaving,
    };

    return res;
  };

  const actionsResult: ActionsResult<TOut> = {
    // actionTypes,
    handleRemoveEntityAction: (keyPath: KeyPath, entity: any) => {
      const res: EntityPayloadAction = {
        type: actionTypes.removeEntityActionType,
        payload: {
          keyPath,
          entity,
        },
      };
      return res;
    },

    handleRemoveSelfAction: (entity: TOut) => {
      const res: MultiEntityActionBaseType = {
        type: actionTypes.removeSelfActionType,
        entityId: entity.id,
      };
      return res;
    },

    handleAddEntity: (keyPath: KeyPath, entity: any) => {
      const res: EntityPayloadAction = {
        type: actionTypes.addEntityActionType,
        payload: {
          keyPath,
          entity,
        },
      };
      return res;
    },

    handleAddEntities: (keyPath: KeyPath, entities: Array<any>) => {
      const res: EntitiesPayloadAction = {
        type: actionTypes.addEntitiesActionType,
        payload: {
          keyPath,
          entities,
        },
      };
      return res;
    },

    handleCreateNewState: (entity?: TOut) => {
      const res: InitializeStateAction<TOut> = {
        type: actionTypes.createNewStateActionType,
        payload: {
          entity,
        },
      };
      return res;
    },

    handleReplaceEntity: (keyPath: KeyPath, entity: any) => {
      const res: EntityPayloadAction = {
        type: actionTypes.replaceEntityActionType,
        payload: {
          keyPath,
          entity,
        },
      };
      return res;
    },

    handleDeleteCollection: (
      keyPath: KeyPath,
      entities: Array<any>,
      discard?: boolean,
    ) => {
      const res: DeleteCollectionPayloadAction = {
        type: actionTypes.deleteCollectionActionType,
        payload: {
          keyPath,
          entities,
          discard,
        },
      };
      return res;
    },
    handleMutateProperties: (keyPath: KeyPath, slice: {}) => {
      const res: MutatePropertiesAction = {
        type: actionTypes.mutatePropertiesActionType,
        payload: {
          keyPath,
          slice,
        },
      };
      return res;
    },

    handleResponseAction,

    handleEditPropertyAction: (
      property: KeyPath,
      value:
        | (string | null | undefined)
        | (boolean | null | undefined)
        | (Array<any> | null | undefined),
    ) => {
      const res: MutatePropertyAction = {
        type: actionTypes.editActionType,
        payload: {
          property,
          value,
        },
      };

      return res;
    },

    handleInitializeState: (entity?: TOut) => {
      const res: InitializeStateAction<TOut> = {
        type: actionTypes.initializeStateActionType,
        payload: {
          entity,
        },
      };
      return res;
    },

    handleClearState: () => {
      const res: ActionBaseType = {
        type: actionTypes.clearStateActionType,
      };
      return res;
    },

    handleSetAlert: (message: string, type: AlertType) =>
      setAlert(message, type),

    handleUndo: (keyPath: KeyPath) => {
      const res: KeyPathAction = {
        type: actionTypes.undoActionType,
        payload: {
          keyPath,
        },
      };
      return res;
    },

    handleAutoSave: (
      fetchMethod: FetchMethod<TOut>,
      saveReduxKey: ReduxKeys | Array<ReduxKeys> = reduxKey,
    ) =>
      invokeSaveAction(
        saveReduxKey,
        lifeCycleMethods,
        handleResponseAction,
        handleToggleSave,
        fetchMethod,
        true,
      ),

    handleSave: (
      saveReduxKey: ReduxKeys | Array<ReduxKeys> = reduxKey,
      fetchMethod?: FetchMethod<TOut>,
    ) => {
      invariant(
        fetchMethod,
        'formQueryRefetch must be provided for non-autoSave forms',
      );
      return invokeSaveAction(
        saveReduxKey,
        lifeCycleMethods,
        handleResponseAction,
        handleToggleSave,
        fetchMethod,
        false,
      );
    },

    handleToggleAutoSave: (enable: boolean) => {
      const res: PayloadAction<boolean> = {
        type: actionTypes.toggleAutoSaveActionType,
        payload: enable,
      };

      return res;
    },

    handleToggleDirtyFlagAction: (
      keyPath: KeyPath,
      isDirty: boolean,
      recursive = false,
    ) => {
      const res: ToggleDirtyFlagAction = {
        type: actionTypes.toggleDirtyFlagActionType,
        payload: {
          isDirty,
          keyPath,
          recursive,
        },
      };
      return res;
    },
    handleToggleSave,

    handleValidate: (saveReduxKey: ReduxKeys | Array<ReduxKeys> = reduxKey) =>
      invokeValidationAction(
        saveReduxKey,
        lifeCycleMethods,
        handleResponseAction,
        handleToggleSave,
      ),

    handleAutoValidate: (
      saveReduxKey: ReduxKeys | Array<ReduxKeys> = reduxKey,
    ) =>
      invokeEntityValidationAction(
        saveReduxKey,
        lifeCycleMethods,
        handleResponseAction,
        handleToggleSave,
      ),

    handleSetValidationErrors: (errors: ValidationMap) => {
      const res: SetValidationErrorsAction = {
        type: actionTypes.setValidationErrors,
        payload: {
          errors,
        },
      };
      return res;
    },
  };

  return actionsResult;
}
