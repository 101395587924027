import * as React from 'react';
import { Dimmer, Header } from 'semantic-ui-react';
import { logToSentry } from 'utils';

type Props = {
  children: React.ReactNode;
};

type State = {
  error: Error | null | undefined;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  handleSetError = (e: Error) => {
    this.setState({
      error: e,
    });
  };

  handleClearError = () => {
    this.setState({
      error: null,
    });
  };

  componentDidCatch(error: Error) {
    this.handleSetError(error);
    logToSentry(error);
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        <Dimmer active onClickOutside={this.handleClearError}>
          <Header as="h2" icon inverted>
            {error && error.message}
          </Header>
        </Dimmer>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
