import { gql } from '@apollo/client';
import { BenchmarkRateShallowFields } from '../fragments';
import { getAuthenticatedClient } from '../authenticatedClient';

export const currentBenchmarkRateQueryDefinition = gql`
  query CurrentBenchmarkRateQuery($benchmarkIdSet: [ID]!) {
    currentBenchmarkRates(benchmarkIdSet: $benchmarkIdSet) {
      ...BenchmarkRateShallowFields
    }
  }
  ${BenchmarkRateShallowFields}
`;

export const CurrentBenchmarksQuery = async (
  benchmarkIds: Array<string | null | undefined>,
) => {
  const variables: {
    [key: string]: any;
  } = { benchmarkIdSet: benchmarkIds };

  const response = await getAuthenticatedClient().query({
    query: currentBenchmarkRateQueryDefinition,
    variables,
    fetchPolicy: 'network-only',
  });

  const { currentBenchmarkRates } = response.data;
  if (!currentBenchmarkRates?.length) return {};

  // https://medium.com/dailyjs/rewriting-javascript-converting-an-array-of-objects-to-an-object-ec579cafbfc7
  return Object.assign(
    {},
    ...currentBenchmarkRates.map(benchmark => ({
      [benchmark.id]: benchmark,
    })),
  );
};
