import { mutationBuilder, MutationExecutor } from './builders';
import {
  CreateDealAssigneeMutationDefinition,
  DeleteDealAssigneeMutationDefinition,
} from './definitions';
import { inputScrubber } from './utils';
import { DealAssigneeInput, DealAssigneeType, ID } from 'types';

export const assignDeal = mutationBuilder<DealAssigneeInput, DealAssigneeType>(
  CreateDealAssigneeMutationDefinition,
  {
    build: (mutate: MutationExecutor<DealAssigneeType>) => (
      dealAssigneeData: DealAssigneeInput,
    ) =>
      mutate({
        variables: {
          dealAssigneeData: inputScrubber(dealAssigneeData),
        },
        refetchQueries: ['DealQuery', 'DealsQuery'],
      }),
  },
);

export const unassignDeal = mutationBuilder<ID, void>(
  DeleteDealAssigneeMutationDefinition,
  {
    build: mutate => (id: string) =>
      mutate({
        variables: { id },
        refetchQueries: ['DealQuery', 'DealsQuery'],
      }),
  },
);
