import React, { useState } from 'react';
import WorkflowContentShell from '../WorkflowContentShell';
import {
  WorkflowItem,
  WorkflowSection,
  WorkflowHelpBox,
} from '../workflowList';
import { FileUploaderCheck } from './FileUploader';
import { DocumentationModal } from './DocumentationModal';
import { DealType, StringPair } from 'types';
import {
  Text,
  RouteTo,
  Button,
  Form,
  WrappedMediatorFormProps,
} from 'components';
import { RouteTable } from 'routing';

interface DealFormProps extends WrappedMediatorFormProps<DealType> {
  folders: Array<StringPair>;
}

function Documentation(props: DealFormProps) {
  const { data, folders } = props;
  const { sbaPppForgivenessWorkflow, id: dealId } = data;

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!sbaPppForgivenessWorkflow) {
    return null;
  }

  const updatedProps = { ...props, data: sbaPppForgivenessWorkflow };

  const handleChange = () => setIsModalOpen(true);

  const handleOnConfirm = () => {
    updatedProps.mutateProperty(true, [
      'sbaPppForgivenessWorkflow',
      'isDocumentationTos',
    ]);
    updatedProps.onSave();
    setIsModalOpen(false);
  };

  const renderControl = () => (
    <>
      Before proceeding please{' '}
      <Button.Text
        className="TosCheckboxButton"
        label="read the document retention instructions"
        onClick={handleChange}
      />
      {isModalOpen && (
        <DocumentationModal
          isOpen={isModalOpen}
          isSForm={sbaPppForgivenessWorkflow.isSFormEligible}
          onConfirm={handleOnConfirm}
        />
      )}
    </>
  );

  const renderCtaButtons = () => (
    <Button
      as={RouteTo}
      label="Return to Instructions"
      to={RouteTable.deal.toSbaPppForgivenessWorkflowInstructions(dealId)}
    />
  );

  const fileUploaderProps = {
    folders: folders,
    dataroomUrl: RouteTable.deal.toDealDataroom(dealId),
  };

  return (
    <WorkflowContentShell
      ctaButtons={renderCtaButtons}
      title="Supporting Documentation"
    >
      <WorkflowSection
        header="COMPENSATION AND BENEFITS PAYMENTS"
        helpBox={
          <WorkflowHelpBox header="Document Types">
            <Text>
              Any supporting documentation you provide should be in a file
              format accepted by the SBA. These formats include:
            </Text>
            <Text>
              <strong>Portable Document Format</strong> (pdf)
            </Text>
            <Text>
              <strong>Excel Spreadsheet</strong> (xls, xlsx)
            </Text>
            <Text>
              <strong>Comma-Separated Values</strong> (csv)
            </Text>
            <Text>
              <strong>Word Document</strong> (doc, docx)
            </Text>
            <Text>
              <strong>Image</strong> (jpg, jpeg, png)
            </Text>
          </WorkflowHelpBox>
        }
      >
        <Text value="Documentation about compensation and benefit payments made during the Covered Period or the Alternative Covered Period, including each of the following:" />
        <WorkflowItem
          label="Payroll Documentation"
          tooltip="Bank statements or payroll provider reports showing cash compensation paid to employees."
        >
          <FileUploaderCheck {...fileUploaderProps} destination="PAYROLL" />
        </WorkflowItem>
        <WorkflowItem
          label="Tax Filing"
          tooltip="Documents may cover periods longer than the specific time period, and may include tax filings, such as Form 941, state quarterly business and wage reports, and state unemployment insurance tax filings."
        >
          <FileUploaderCheck {...fileUploaderProps} destination="TAX_FILING" />
        </WorkflowItem>
        <WorkflowItem
          label="Benefits Contributions"
          tooltip="Receipts, cancelled checks, or account statements of any employer contributions to employee health insurance and retirement plans that were included in the forgiveness amount."
        >
          <FileUploaderCheck {...fileUploaderProps} destination="BENEFITS" />
        </WorkflowItem>
      </WorkflowSection>

      {!sbaPppForgivenessWorkflow.isSFormEligible && (
        <WorkflowSection header="FULL-TIME EQUIVALENCY (FTE) EMPLOYEES">
          <WorkflowItem
            label="Average Number of FTE Employees Per Month"
            tooltip="Documents may cover periods longer than the specific time period, and may include tax filings, such as Form 941, state quarterly business and wage reports, and state unemployment insurance tax filings."
          >
            <FileUploaderCheck {...fileUploaderProps} destination="FTE" />
          </WorkflowItem>
          <Text value="The selected reference period must be the same as the one used in the FTE Reduction Calculation, between either:" />
          <ol className="OrderedList" type="a">
            <li>February 15, 2019 — June 30, 2019;</li>
            <li>January 1, 2020 — February 29, 2020; or</li>
            <li>
              Any consecutive 12 weeks between May 1, 2019 and September 15,
              2019 (seasonal employers only)
            </li>
          </ol>
        </WorkflowSection>
      )}

      <WorkflowSection header="BUSINESS NONPAYROLL PAYMENTS">
        <Text value="Documentation verifying existence of the obligations/services prior to February 15, 2020 and eligible payments from the Covered Period." />
        <WorkflowItem
          label="Mortgage Interest"
          tooltip="Copy of lender amortization schedule and receipts or cancelled checks verifying eligible payments from the Covered Period; or lender account statements from February 2020 and the months of the Covered Period through one month after the end of the Covered Period verifying interest amounts and eligible payments."
        >
          <FileUploaderCheck {...fileUploaderProps} destination="MORTGAGE" />
        </WorkflowItem>
        <WorkflowItem
          label="Rent or Lease"
          tooltip="Copy of current lease agreement and receipts or cancelled checks verifying eligible payments from the Covered Period; or lessor account statements from February 2020 and from the Covered Period through one month after the end of the Covered Period verifying eligible payments."
        >
          <FileUploaderCheck {...fileUploaderProps} destination="RENT" />
        </WorkflowItem>
        <WorkflowItem
          label="Utilities"
          tooltip="Copy of invoices from February 2020 and those paid during the Covered Period and receipts, cancelled checks, or account statements verifying those eligible payments."
        >
          <FileUploaderCheck {...fileUploaderProps} destination="UTILITIES" />
        </WorkflowItem>
      </WorkflowSection>

      <WorkflowSection header="WORKSHEET (RECOMMENDED)">
        <Text value="Uploading the completed worksheet provides a useful reference for your lender, and for your future use." />
        <WorkflowItem label="Completed LoanStreet Excel Worksheet">
          <FileUploaderCheck {...fileUploaderProps} destination="FORGIVENESS" />
        </WorkflowItem>
      </WorkflowSection>

      <WorkflowSection header="OTHER DOCUMENTS">
        <Text value="In order to comply with the SBA requirements related to your PPP loan and its partial or total forgiveness, you are required to retain certain documents for a period of six years." />
        {Form.FieldRenderer(
          Form.Checkbox,
          {
            id: 'isDocumentationTos',
            propertyName: 'isDocumentationTos',
            control: renderControl,
            required: true,
            onChange: handleChange,
          },
          updatedProps,
          'SbaPppForgivenessWorkflow',
          updatedProps.data.id,
        )}
      </WorkflowSection>
    </WorkflowContentShell>
  );
}

export default Documentation;
