import uuid from 'uuid/v4';

import { DocumentMutationMethods } from '../../../graphql/mutations/DocumentMutations';
import Directory from '../../directory';

import { createForm } from '../../genericForms';
import { DocumentInput } from 'types';

const initialDocument: DocumentInput = {
  id: uuid(),
  name: null,
  description: null,
  mimeType: null,
  parentId: '',
  __typename: 'DocumentType',
};

const lifeCycleMethods = {
  mutations: {
    ...DocumentMutationMethods,
  },
  validators: {},
};

export default createForm<DocumentInput, DocumentInput>(
  Directory.DocumentKey,
  initialDocument,
  lifeCycleMethods,
);
