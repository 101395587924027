import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import WorkflowContentShell from '../WorkflowContentShell';
import { WorkflowSection } from '../workflowList';
import { HelpBoxCallbackType } from '../workflowList/WorkflowHelpBox';
import { fields } from './utils';
import {
  CalculatedValuesType,
  HelpBoxType,
  ForgivenessFormProps,
} from './types';
import { LessThanFullForgivenessCauses } from './partials';
import { Text, Form, Button, Tooltip } from 'components';

interface Props extends ForgivenessFormProps {
  anySafeHarbor: boolean;
  calculatedValues: CalculatedValuesType;
  onChange: (
    value:
      | (string | null | undefined)
      | (boolean | null | undefined)
      | (Array<any> | null | undefined),
    fieldId: string,
  ) => void;
  onSwitchForms: () => void;
  renderCtaButtons: () => React.ReactElement;
  renderHelpBox: (
    id: HelpBoxType,
    focusId?: HelpBoxType,
    control?: HelpBoxCallbackType,
  ) => React.ReactElement;
  TypeName: string;
}

const StandardForm = (props: Props) => {
  const {
    anySafeHarbor,
    calculatedValues,
    onChange,
    onSwitchForms,
    renderCtaButtons,
    renderHelpBox,
    TypeName,
  } = props;

  const [focusedSection, setFocusedSection] = useState<HelpBoxType>(
    HelpBoxType.standardMain,
  );

  const {
    sbapppforgivenesscalculation,
  } = props?.data?.sbaPppForgivenessWorkflow;

  return (
    <WorkflowContentShell
      className="ForgivenessCalculationForm"
      ctaButtons={renderCtaButtons}
      helpBox={renderHelpBox(HelpBoxType.standardMain, focusedSection)}
      title="PPP Loan Forgiveness Calculation"
    >
      <Prompt
        message="There are unsaved changes on this form that will be lost if you continue."
        when={props.isDirty}
      />
      <div className="FormController">
        <Text className="bold">
          Looking for the EZ form calculation instead?
        </Text>
        <Button.Text label="Switch to EZ form" onClick={onSwitchForms} />
      </div>
      <WorkflowSection
        header="Employees with annualized 2019 compensation up to $100,000"
        helpBox={renderHelpBox(HelpBoxType.lte100, focusedSection)}
        onClick={() => setFocusedSection(HelpBoxType.lte100)}
      >
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.cashCompensationLte100,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
        {Form.FieldRenderer(
          Form.Decimal,
          {
            ...fields.averageFteLte100,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
        <Form.Group className="Field-Tooltip">
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.salaryHourlyWageReductionLte100,
              onChange,
            },
            props,
            TypeName,
            props.data.id,
          )}
          <Tooltip>
            Enter ‘0’ if the employees’ average annual compensation during the
            selected covered period was at least 75% of their average annual
            compensation between Jan 1 – Mar 31, 2020.
          </Tooltip>
        </Form.Group>
      </WorkflowSection>
      <WorkflowSection
        header="Employees with annualized 2019 compensation above $100,000"
        helpBox={renderHelpBox(HelpBoxType.gt100, focusedSection)}
        onClick={() => setFocusedSection(HelpBoxType.gt100)}
      >
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.cashCompensationGt100,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
        {Form.FieldRenderer(
          Form.Decimal,
          {
            ...fields.averageFteGt100,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
      </WorkflowSection>
      <WorkflowSection
        header="Non-cash compensation payroll costs during covered period"
        helpBox={renderHelpBox(HelpBoxType.nonCash, focusedSection)}
        onClick={() => setFocusedSection(HelpBoxType.nonCash)}
      >
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.healthInsuranceContributions,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.retirementPlanContributions,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.stateAndLocalPayrollTaxes,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
      </WorkflowSection>
      <WorkflowSection
        header="Compensation to owners"
        helpBox={renderHelpBox(HelpBoxType.ownerCompensation, focusedSection)}
        onClick={() => setFocusedSection(HelpBoxType.ownerCompensation)}
      >
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.totalAmountPaidCompensationToOwners,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
      </WorkflowSection>
      <WorkflowSection
        header="Total payroll costs"
        helpBox={renderHelpBox(HelpBoxType.payrollTotal, focusedSection)}
        onClick={() => setFocusedSection(HelpBoxType.payrollTotal)}
      >
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.payrollCostsCalculated,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
      </WorkflowSection>
      <WorkflowSection
        header="Nonpayroll business payments"
        helpBox={renderHelpBox(HelpBoxType.nonPayroll, focusedSection)}
        onClick={() => setFocusedSection(HelpBoxType.nonPayroll)}
      >
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.mortgageInterest,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.rentOrLease,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.utilities,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.operationsExpenditures,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.propertyDamageCosts,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.supplierCosts,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.workerProtectionExpenditures,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
      </WorkflowSection>
      <WorkflowSection
        header="Full-Time Equivalency (FTE) reduction"
        helpBox={renderHelpBox(HelpBoxType.fteReduction, focusedSection)}
        onClick={() => setFocusedSection(HelpBoxType.fteReduction)}
      >
        <Form.Group className="Checkbox-Tooltip">
          {Form.FieldRenderer(
            Form.Checkbox,
            {
              ...fields.noFteReduction,
              onChange,
            },
            props,
            TypeName,
            props.data.id,
          )}
          <Tooltip>
            Check this box if the number of employees or average paid hours of
            employees has not been reduced between Jan 1, 2020 and the end of
            Covered Period.
          </Tooltip>
        </Form.Group>
        <Form.Group className="Checkbox-Tooltip">
          {Form.FieldRenderer(
            Form.Checkbox,
            {
              ...fields.safeHarbor1HasBeenMetFteReduction,
              onChange,
            },
            props,
            TypeName,
            props.data.id,
          )}
          <Tooltip>
            Check this box if business activity was reduced between Feb 15, 2020
            and the end of the Covered Period due to compliance with government
            standards for sanitation, social distancing, or any other worker or
            customer safety requirement related to COVID-19.
          </Tooltip>
        </Form.Group>
        <Form.Group className="Checkbox-Tooltip">
          {Form.FieldRenderer(
            Form.Checkbox,
            {
              ...fields.safeHarbor2HasBeenMetFteReduction,
              onChange,
            },
            props,
            TypeName,
            props.data.id,
          )}
          <Tooltip>
            Check this box if the average FTE was reduced between Feb 15 – Apr
            26, 2020, but has been restored to a total FTE equal to or greater
            than the total on Feb 15.
          </Tooltip>
        </Form.Group>
        {!anySafeHarbor && (
          <>
            {Form.FieldRenderer(
              Form.Decimal,
              {
                ...fields.averageFteFteReduction,
                onChange,
              },
              props,
              TypeName,
              props.data.id,
            )}
            {Form.FieldRenderer(
              Form.Input,
              {
                ...fields.totalAverageFteFteReduction,
                onChange,
              },
              props,
              TypeName,
              props.data.id,
            )}
          </>
        )}
        {Form.FieldRenderer(
          Form.Input,
          {
            ...fields.fteReductionQuotientFteReduction,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
      </WorkflowSection>
      <WorkflowSection
        className="ForgivenessAmount"
        header="Forgiveness"
        helpBox={renderHelpBox(HelpBoxType.forgivenessAmount, focusedSection)}
        onClick={() => setFocusedSection(HelpBoxType.forgivenessAmount)}
      >
        <Form.Group className="Field-Tooltip">
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.forgivenessAmount,
              onChange,
            },
            props,
            TypeName,
            props.data.id,
          )}
        </Form.Group>
        <Text>The Forgiveness Amount is the smallest of:</Text>
        <Form.Group className="Field-Tooltip ForgivenessAmountSubValue">
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.modifiedTotal,
              onChange,
            },
            props,
            TypeName,
            props.data.id,
          )}
          <Text>Modified Total</Text>
          <Tooltip>
            (Payroll Costs + Nonpayroll Costs - Wage Reduction) x (FTE Reduction
            Quotient)
          </Tooltip>
        </Form.Group>
        <Form.Group className="Field-Tooltip ForgivenessAmountSubValue">
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.pppLoanAmount,
              onChange,
            },
            props,
            TypeName,
            props.data.id,
          )}
          <Text>PPP Loan Amount</Text>
        </Form.Group>
        <Form.Group className="Field-Tooltip ForgivenessAmountSubValue">
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.payrollCostRequirement,
              onChange,
            },
            props,
            TypeName,
            props.data.id,
          )}
          <Text>Payroll Cost 60% Requirement</Text>
        </Form.Group>
        <LessThanFullForgivenessCauses
          calculatedValues={calculatedValues}
          sbapppforgivenesscalculation={sbapppforgivenesscalculation}
        />
      </WorkflowSection>
    </WorkflowContentShell>
  );
};

export default StandardForm;
