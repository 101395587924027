const dateRegex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

/**
 * Checks if a string is a date in the format: YYYY-MM-DD
 * @param dateString A given string that may or may not be a YYYY-MM-DD format date.
 */
const isDateString = (dateString: string): boolean =>
  !!dateString && dateString.length === 10 && dateRegex.test(dateString);

export default isDateString;
