import padStart from 'lodash/padStart';
import padEnd from 'lodash/padEnd';
import trimStart from 'lodash/trimStart';
import trimEnd from 'lodash/trimEnd';
import memoize from 'lodash/memoize';

const empty = '';
const decimal = '.';
const zero = '0';

/**
 * Within a string, shift the first occurence of '.' by `shiftBy` positions
 * @param {*} value String containing a '.'
 * @param {*} shiftBy Postions to shift the '.', can be negative or positive
 */
function shiftDecimal(value: string, shiftBy: number): string {
  if (!value || shiftBy === 0 || +value === 0) return value || '';

  let isNegative = false;
  if (value.charAt(0) === '-') {
    isNegative = true;
  }

  let final = '';
  const absValue = isNegative ? value.slice(1) : value;

  const find = absValue.indexOf(decimal);

  if (find < 0 && shiftBy > 0) {
    // this is right shift of a value lacking a decimal
    // to maintain compatibility with Form.Component, we do not add
    // a decimal, so just pad zero's and return
    final = padEnd(absValue, absValue.length + shiftBy, zero);
    if (isNegative) final = '-'.concat(final);
    return final;
  }
  // unshifted position of existing decimal
  const originalPosition = find >= 0 ? find : absValue.length; // Math.max(0, value.indexOf(decimal));

  //  if (originalPosition < 0) return value;

  // unpadded final position, can be negative
  const finalPosition = originalPosition + shiftBy;

  const padding = finalPosition < 0 ? Math.abs(finalPosition) : 0;

  const root = padStart(absValue, padding + value.length, zero);

  const rootPosition = root.indexOf(decimal);
  const actualPosition =
    (rootPosition >= 0 ? rootPosition : root.length) +
    shiftBy +
    (shiftBy > 0 ? 1 : 0);

  const mappedRes = root.split(empty).map((e, i) => {
    if (e !== decimal) {
      if (actualPosition === i) {
        return `.${e}`;
      }
      return e;
    }
    return empty;
  });
  if (actualPosition === root.length) {
    mappedRes.push(decimal);
  } else if (actualPosition > root.length) {
    for (let i = 0; i < actualPosition - root.length; i += 1) {
      mappedRes.push(zero);
    }
  }
  final = trimEnd(trimStart(mappedRes.join(empty), zero), decimal);
  if (isNegative) final = '-'.concat(final);
  return final;
}

export default memoize(
  shiftDecimal,
  (value: string, shiftBy: number) => `${value || '-'}_${shiftBy}`,
);
