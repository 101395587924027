import { gql } from '@apollo/client';
import { LoanEventShallowFields } from '../fragments';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { LoanEventType } from 'types';

type OwnProps = {
  fromDate: string | null | undefined;
  toDate: string | null | undefined;
  trancheId: string | null | undefined;
};
const PaymentActivityQueryDefinition = gql`
  query PaymentActivityQuery(
    $loanTrancheId: ID!
    $firstDate: Date
    $lastDate: Date
  ) {
    paymentActivity(
      loanTrancheId: $loanTrancheId
      firstDate: $firstDate
      lastDate: $lastDate
    ) {
      ...LoanEventShallowFields
    }
  }
  ${LoanEventShallowFields}
`;

const PaymentActivityQuery = standardQueryBuilder(
  PaymentActivityQueryDefinition,
  {
    disableCache: true,
    typename: 'LoanEventType',
    notifyOnNetworkStatusChange: true,
    variables: (props: OwnProps) => ({
      loanTrancheId: props.trancheId,
      firstDate: props.fromDate,
      lastDate: props.toDate,
    }),
    results: (data: {
      paymentActivity: Array<LoanEventType> | null | undefined;
    }) => {
      const { paymentActivity } = data;
      return { paymentActivity };
    },
  },
);

export default PaymentActivityQuery;
