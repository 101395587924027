import { gql } from '@apollo/client';

import { BusinessDayShallowFields } from '../fragments';

const BusinessDaysQuery = gql`
  query BusinessDay($businessDay: Date!) {
    businessDay(businessDay: $businessDay) {
      ...BusinessDayShallowFields
    }
  }
  ${BusinessDayShallowFields}
`;

export default BusinessDaysQuery;
