import * as React from 'react';
import { LoanTrancheFormProps } from '../types';
import TrancheGroup from './TrancheGroup';
import { Form } from 'components';

const loanTranche = 'LoanTranche';

class NotesFields extends React.Component<LoanTrancheFormProps> {
  fields = {
    notes: {
      id: 'notes',
      propertyName: 'notes',
      fieldName: 'Notes',
      fluid: true,
      onChange: this.props.mutateProperty,
      tabIndex: this.props.tabIndex,
      width: '332px',
    },
  };

  render() {
    const { data } = this.props;
    return (
      <TrancheGroup>
        {Form.FieldRenderer(
          Form.TextArea,
          this.fields.notes,
          this.props,
          loanTranche,
          data.id,
        )}
      </TrancheGroup>
    );
  }
}

export default NotesFields;
