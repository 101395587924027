/** String starts with * and all but last 4 digits must be * */
const defaultMatch = /^\*+.{4}$/;

/** These functions move the value of encrypted fields that are
 * sent from the backend to the placeholder so that users can type
 * in new inputs without having to delete asterisks before
 */

/** Returns blank string if string is encrypted
 */
export const getEncryptedDisplayValue = (
  data: any,
  fieldName: string,
  regEx = defaultMatch,
) => {
  if (!data || data[fieldName]?.match(regEx)) {
    return '';
  } else {
    return data[fieldName];
  }
};

/** Returns encrypted display as the placeholder if it matches
 * the regex for an encrypted string
 */
export const getEncryptedPlaceholder = (
  data: any,
  fieldName: string,
  placeholder = '',
  regEx = defaultMatch,
) => {
  if (data && data[fieldName]?.match(regEx)) {
    return data[fieldName];
  } else {
    return placeholder;
  }
};
