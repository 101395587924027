import * as React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import ApplicationBreadcrumbRoutes from 'routing/routes/ApplicationBreadcrumbRoutes';
import { Breadcrumbs, Header, RouteTo } from 'components';

type Props = {
  children?: React.ReactNode;
  name: string;
  withBreadcrumbs?: boolean;
};

export default function TextHeader(props: Props) {
  const { children, name, withBreadcrumbs } = props;
  const params = useParams();
  const computedClassName = `TopbarNavigation TopbarNavigation--${name}`;

  return (
    <div className={computedClassName}>
      <div>
        <Header as="h1" className="TopbarNavigation__Header">
          {name}
        </Header>
        {Boolean(withBreadcrumbs) && (
          <Breadcrumbs
            link={RouteTo}
            params={params}
            routes={ApplicationBreadcrumbRoutes}
            useRouteMatch={useRouteMatch}
          />
        )}
      </div>
      {children}
    </div>
  );
}
