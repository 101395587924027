import { Map } from 'immutable';
import * as React from 'react';
import printJS from 'print-js';
import { Icon } from 'semantic-ui-react';
import PdfViewer from '../PdfViewer';
import { StandardModal, LoadingIndicator } from 'components';
import { OpenPDFProps } from 'types';
import './DataroomPdfViewer.scss';

type Props = {
  changePage: (data: { id: string; pageIndex: number }) => void;
  changeRotations: (data: {
    id: string;
    rotations: Map<number, number>;
  }) => void;
  changeScale: (data: { id: string; scale: number }) => void;
  closeFile: () => void;
  currentlyOpen: OpenPDFProps;
  documentExpiringUrl: string | null | undefined;
  loading: boolean;
  pageIndex: number;
  rotations: Map<number, number>;
  scale: number;
};

class DataroomPdfViewer extends React.Component<Props> {
  handlePrint = () => {
    const { currentlyOpen } = this.props;
    if (!currentlyOpen) return;
    fetch(currentlyOpen.fileUri, {
      method: 'GET',
      credentials: 'include',
    })
      .then(response => response.blob())
      .then(blob => {
        const pdfUrl = URL.createObjectURL(blob);
        printJS({ printable: pdfUrl, type: 'pdf', showModal: true });
      });
  };

  renderHeader = () => {
    const { currentlyOpen } = this.props;
    if (!currentlyOpen) return null;
    const { fileUri, fileName } = currentlyOpen;
    return (
      <div className="dataroomPdfViewer__Header">
        <div className="dataroomPdfViewer__Title">{fileName}</div>
        <div className="dataroomPdfViewer__IconGroup">
          <a
            className="dataroomPdfViewer__Icon dataroomPdfViewer__Icon--Download"
            href={fileUri}
          >
            <Icon link name="download" />
          </a>
          {/* Broken due to an infrastructure problem
          for now we will omit printing */}
          {/* <div className="dataroomPdfViewer__Icon">
           <Icon link name="print" onClick={this.handlePrint} />
          </div> */}
        </div>
      </div>
    );
  };

  render() {
    const { closeFile, currentlyOpen, loading, ...rest } = this.props;
    const isOpen = Boolean(currentlyOpen);
    return (
      <StandardModal
        footerless
        header={this.renderHeader()}
        isOpen={isOpen}
        onClose={closeFile}
      >
        {loading ? (
          <LoadingIndicator />
        ) : (
          <PdfViewer {...rest} {...currentlyOpen} />
        )}
      </StandardModal>
    );
  }
}

export default DataroomPdfViewer;
