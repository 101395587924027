const timeZoneOptions = [
  { value: 'Europe/London', text: 'Europe/London' },
  { value: 'America/New_York', text: 'America/New York' },
  { value: 'America/Chicago', text: 'America/Chicago' },
  { value: 'America/Denver', text: 'America/Denver' },
  { value: 'America/Los_Angeles', text: 'America/Los Angeles' },
  { value: 'America/Anchorage', text: 'America/Anchorage' },
  { value: 'US/Hawaii', text: 'America/Hawaii' },
];

export default timeZoneOptions;
