import { mutationBuilder } from './builders';
import {
  CreateEscrowAccountMutationDefinition,
  SetEscrowAccountMutationDefinition,
  DeleteEscrowAccountMutationDefinition,
} from './definitions';
import { EscrowAccountInput, EscrowAccountType, ID } from 'types';

const EscrowAccountMutationMethods = {
  createEscrowAccount: mutationBuilder<EscrowAccountInput, EscrowAccountType>(
    CreateEscrowAccountMutationDefinition,
    {
      build: mutate => escrowAccountData =>
        mutate({ variables: { escrowAccountData } }),
    },
  ),
  setEscrowAccount: mutationBuilder<EscrowAccountInput, EscrowAccountType>(
    SetEscrowAccountMutationDefinition,
    {
      build: mutate => escrowAccountData =>
        mutate({ variables: { escrowAccountData } }),
    },
  ),
  deleteEscrowAccount: mutationBuilder<ID, void>(
    DeleteEscrowAccountMutationDefinition,
    {
      build: mutate => (id: string) => mutate({ variables: { id } }),
    },
  ),
};

export default EscrowAccountMutationMethods;
