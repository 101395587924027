import { noop } from 'lodash';
import * as React from 'react';
import {
  mapTierFeePercentageByTierId,
  getFeeSharingDisplayText,
  generateProRataHandlers,
  getKeyPath,
} from '../utils';
import FeeAmountPercentageGrid from '../feeAmountPercentageGrid/FeeAmountPercentageGrid';
import FeePrepaymentDisplay from '../feePrepayment/FeePrepaymentDisplay';
import {
  FeeType,
  FeeKindType,
  LoanTrancheTierType,
  LoanTrancheType,
} from 'types';
import { Form, IconButton, Header } from 'components';
import { KeyPath } from 'lsredux';
import { invariant, formatTitleCase } from 'utils';

type Props = {
  canEdit: boolean;
  fee: FeeType;
  feeIndex: string;
  feeKind: FeeKindType | null | undefined;
  isBorrower: boolean;
  onFeeEditClick: (feeToEdit: FeeType, trancheIndex: string) => void;
  replaceEntity: (keyPath: KeyPath, entity: FeeType | LoanTrancheType) => void;
  tiers: ReadonlyArray<LoanTrancheTierType>;
  tranche: LoanTrancheType;
  trancheIndex: string;
};

type FeeRow = {
  Percentage: string;
  Tier: string;
};

const width = '158px';
class FeeItem extends React.Component<Props> {
  mutatePercent: (
    trancheKeyPath: KeyPath,
    fee: FeeType,
    newValue: string,
  ) => void;

  constructor(props: Props) {
    super(props);

    this.mutatePercent = generateProRataHandlers(
      'PERCENT',
      props.replaceEntity,
    ).bind(this);
  }

  buildFeeRows = () => {
    const { fee, tiers } = this.props;
    if (fee.sharing === 'NON_PRO_RATA') {
      if (tiers && tiers.length > 0) {
        const tierfeepercentageSet = fee.tierfeepercentageSet || [];
        const tierFeePercentageMap = mapTierFeePercentageByTierId(
          tierfeepercentageSet,
        );

        return tiers.map<FeeRow>(tier => ({
          Tier: tier.tierNumber || '',
          Percentage: tierFeePercentageMap.get(tier.id) || '',
        }));
      }
      return [];
    }

    const { feecalculationrule } = fee;
    const amount = feecalculationrule ? feecalculationrule.amount : '';
    const percentage = feecalculationrule ? feecalculationrule.appliedRate : '';

    return [
      {
        id: 'None',
        Amount: amount || '',
        Percentage: percentage || '',
      },
    ];
  };

  handleOnEdit = () => {
    const { fee, onFeeEditClick, trancheIndex } = this.props;
    onFeeEditClick(fee, trancheIndex);
  };

  handleOnBorrowerPercentageChange = (value: string | null | undefined) => {
    const { fee, trancheIndex } = this.props;
    const trancheKeyPath = getKeyPath(trancheIndex);
    if (fee) {
      this.mutatePercent(trancheKeyPath, fee, value || '');
    }
  };

  handleOnMakeWholeMonthsChange = (value: string | null | undefined) => {
    const { tranche, trancheIndex, replaceEntity } = this.props;

    const keyPath = ['loantrancheSet', trancheIndex || ''];
    replaceEntity(keyPath, {
      ...tranche,
      prepaymentMakewholeMonths: value,
    });
  };

  renderEditIconButton = () => (
    <IconButton
      alt="edit fee"
      className="feeItem__EditIcon"
      icon="pencil"
      onClick={this.handleOnEdit}
    />
  );

  renderFeeDescription = (
    fee: FeeType,
    feeKind: FeeKindType,
    isBorrower: boolean,
  ) => (
    <div className="feeItem__Description">
      {!isBorrower && getFeeSharingDisplayText(fee.sharing)}
      {feeKind.slug === 'agent' && (
        <div>
          Frequency: <b>{`${formatTitleCase(fee.paymentFrequency)}`}</b>
        </div>
      )}
    </div>
  );

  renderBorrowerPercentage = () => {
    const { fee } = this.props;
    if (fee.feecalculationrule) {
      return (
        <Form.PercentageReadOnly
          className="feeItem__Display"
          fieldName="Borrower Percentage"
          id={`BorrowerPercentage__${fee.id}`}
          onChange={noop}
          propertyName="appliedRate"
          value={fee.feecalculationrule.appliedRate}
          width={width}
        />
      );
    }
    return null;
  };

  renderPrepaymentMonths = () => {
    const { fee, tranche, canEdit } = this.props;
    if (canEdit) {
      return (
        <Form.Numeric
          className="feeItem__Input"
          fieldName="Months"
          id={`prepaymentMakewholeMonths__${fee.id}`}
          onChange={this.handleOnMakeWholeMonthsChange}
          propertyName="prepaymentMakewholeMonths"
          value={tranche.prepaymentMakewholeMonths}
        />
      );
    }
    return (
      <Form.ReadOnly
        className="feeItem__Display"
        fieldName="Months"
        id={`PrepaymentMakewholeMonths__${fee.id}`}
        onChange={noop}
        propertyName="prepaymentMakewholeMonths"
        value={tranche.prepaymentMakewholeMonths}
        width={width}
      />
    );
  };

  render() {
    const {
      isBorrower,
      canEdit,
      fee,
      feeKind,
      feeIndex,
      tiers,
      tranche,
      trancheIndex,
      replaceEntity,
    } = this.props;
    invariant(fee, 'fee was not found!');
    if (!feeKind) {
      return null;
    }

    let header = feeKind.label || '';
    if (feeKind.slug === 'agent' && fee.customLabel) {
      header = `${feeKind.label || ''} (${fee.customLabel})`;
    }

    return (
      !(
        !canEdit &&
        fee.feeKindSlug === 'initial-drawdown' &&
        fee.sharing === 'NON_PRO_RATA'
      ) && (
        <div className="feeItem">
          <Form.Section
            header={
              <Header as="h5" className="caps steel">
                {header}
              </Header>
            }
            headerRight={canEdit ? this.renderEditIconButton() : ''}
            id={fee.id}
            undivided
          >
            {this.renderFeeDescription(fee, feeKind, isBorrower)}
            {fee.feeKindSlug === 'initial-drawdown' &&
              fee.sharing === 'NON_PRO_RATA' &&
              this.renderBorrowerPercentage()}
            {fee.feeKindSlug === 'pre-payment-make-whole' &&
              this.renderPrepaymentMonths()}
            {(feeKind.hasAmount || feeKind.hasPercentage) && (
              <FeeAmountPercentageGrid
                canEdit={canEdit}
                fee={fee}
                feeIndex={feeIndex}
                feeKind={feeKind}
                replaceEntity={replaceEntity}
                tiers={tiers}
                trancheIndex={trancheIndex}
              />
            )}
            {feeKind.slug === 'pre-payment-optional' && (
              <FeePrepaymentDisplay
                penaltyRangeSet={tranche.prepaymentpenaltyrangeSet}
              />
            )}
          </Form.Section>
        </div>
      )
    );
  }
}

export default FeeItem;
