import { fromJS } from 'immutable';
import uuid from 'uuid/v4';
import { SET_ALERT, CLEAR_ALERT } from '../../actions/alerts';
import Directory from '../../directory';
import standardReducerFactory from '../standardReducer';
import { isDebug } from 'utils';

const initialState = fromJS({
  alerts: [],
});

const reducerMap: {
  [key: string]: (state: any, action: any) => Record<string, any>;
} = {};

reducerMap[SET_ALERT] = (state, action) => {
  if (!isDebug() && action.payload.type === 'debug') return state;

  return state.set(
    Directory.AlertsKey,
    state.get(Directory.AlertsKey).push({
      ...action.payload,
      id: uuid(),
    }),
  );
};

reducerMap[CLEAR_ALERT] = (state, action) =>
  state.set(
    Directory.AlertsKey,
    state.get(Directory.AlertsKey).delete(
      state
        .get(Directory.AlertsKey)
        .map(alert => alert.id)
        .indexOf(action.payload.id),
    ),
  );

export default standardReducerFactory(reducerMap, initialState);
