import { mutationBuilder } from './builders';
import {
  CreateLoanTrancheAmortizationMutationDefinition,
  SetLoanTrancheAmortizationMutationDefinition,
  DeleteLoanTrancheAmortizationMutationDefinition,
} from './definitions';
import { inputScrubber } from './utils';
import {
  LoanTrancheAmortizationInput,
  LoanTrancheAmortizationType,
  ID,
  LoanTrancheAmortizationPrincipalPaymentInput,
} from 'types';

function paymentScrubber(
  e: LoanTrancheAmortizationPrincipalPaymentInput,
): LoanTrancheAmortizationPrincipalPaymentInput {
  return {
    ...inputScrubber(e),
    principalBefore: undefined,
    principalAfter: undefined,
  } as any;
}

function amortizationScrubber(e: LoanTrancheAmortizationInput) {
  const initialIndicativeBenchmarkId = (e as any).initialIndicativeBenchmark
    ? (e as any).initialIndicativeBenchmark.id
    : undefined;
  return {
    ...inputScrubber(e),
    fixedPayment: undefined,
    readerData: undefined,
    initialIndicativeBenchmarkId,
    initialIndicativeBenchmark: undefined,
    loantrancheamortizationprincipalpaymentSet: (
      e.loantrancheamortizationprincipalpaymentSet || []
    ).map(p => paymentScrubber(p)),
    loantrancheamortizationinterestperiodSet: (
      e.loantrancheamortizationinterestperiodSet || []
    ).map(ip => inputScrubber(ip)),
  };
}

const setLoanTrancheAmortization = mutationBuilder<
  LoanTrancheAmortizationInput,
  LoanTrancheAmortizationType
>(SetLoanTrancheAmortizationMutationDefinition, {
  build: mutate => (
    loanTrancheAmortizationData: LoanTrancheAmortizationInput,
  ) =>
    mutate({
      variables: {
        loanTrancheAmortizationData: amortizationScrubber(
          loanTrancheAmortizationData,
        ),
      },
    }),
});

const createLoanTrancheAmortization = mutationBuilder<
  LoanTrancheAmortizationInput,
  LoanTrancheAmortizationType
>(CreateLoanTrancheAmortizationMutationDefinition, {
  build: mutate => (
    loanTrancheAmortizationData: LoanTrancheAmortizationInput,
  ) =>
    mutate({
      variables: {
        loanTrancheAmortizationData: amortizationScrubber(
          loanTrancheAmortizationData,
        ),
      },
    }),
});

const deleteLoanTrancheAmortization = mutationBuilder<ID, void>(
  DeleteLoanTrancheAmortizationMutationDefinition,
  {
    build: mutate => (id: ID) =>
      mutate({
        variables: {
          id,
        },
      }),
  },
);

const LoanTrancheAmortizationMutationMethods = {
  createLoanTrancheAmortization,
  setLoanTrancheAmortization,
  deleteLoanTrancheAmortization,
};

export default LoanTrancheAmortizationMutationMethods;
