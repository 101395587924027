import uuid from 'uuid/v4';
import { UserMutationMethods } from '../../../graphql/mutations';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import { UserType } from 'types';

const initialUser: UserType = {
  id: uuid(),
  permissions: [],
  dateJoined: null,
  email: null,
  firstName: null,
  fullName: null,
  lastName: null,
  username: null,
  __typename: 'UserType',
};

const lifecycle = {
  mutations: {
    ...UserMutationMethods,
  },
  validators: {},
};

export default createForm<UserType, UserType>(
  Directory.UserKey,
  initialUser,
  lifecycle,
);
