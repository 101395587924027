export const borrowerSlugs: Set<string> = new Set([
  'borrower-sba-application-corrections',
  'borrower-sba-application-signed',
  'borrower-documents-about-business',
  'borrower-documents-about-self',
  'borrower-documents-financials',
  'borrower-addendum-a',
  'borrower-addendum-b',
  'borrower-signed-note',
]);

export const lenderSlugs: Set<string> = new Set([
  'lender-borrower-has-signed',
  'lender-borrower-required-docs',
  'lender-approved-lender-application',
  'lender-borrower-signed-all',
]);

export function getBody(slug: string) {
  if (slug == 'lender-borrower-has-signed') {
    return 'The borrower must download, sign and then upload their loan application to the Dataroom.';
  }
  if (slug == 'lender-borrower-required-docs') {
    return 'The borrower needs to upload supporting documentation about their business, owners and loan eligibility to the Dataroom for your review.';
  }
  if (slug == 'lender-approved-lender-application') {
    return 'After confirming eligibility and verifying Borrower’s materials, downloand, complete and sign your application PDF below, and send it to the SBA.';
  }
  if (slug == 'lender-borrower-signed-all') {
    return 'After receiving SBA approval, complete and send the Promissory Note and any other loan documents to the Borrower for signature.';
  }
  if (slug == 'borrower-sba-application-corrections') {
    return '';
  }
  if (slug == 'borrower-sba-application-signed') {
    return 'After making any changes, download the PDF below. Initial and sign it where indicated.';
  }
  if (slug == 'borrower-signed-note') {
    return 'Sign the Promissory Note and any other loan documents required by the Lender.';
  }
  if (slug == 'borrower-documents-about-business') {
    return '';
  }
  if (slug == 'borrower-documents-about-self') {
    return '';
  }
  if (slug == 'borrower-documents-financials') {
    return '';
  }
  if (slug == 'borrower-addendum-a') {
    return '';
  }
  if (slug == 'borrower-addendum-b') {
    return '';
  }

  return 'Unknown Body';
}

export function getHeader(slug: string) {
  if (slug == 'borrower-sba-application-corrections') {
    return '';
  }
  if (slug == 'borrower-sba-application-signed') {
    return 'LOAN APPLICATION';
  }
  if (slug == 'borrower-documents-about-business') {
    return '';
  }
  if (slug == 'borrower-documents-about-self') {
    return '';
  }
  if (slug == 'borrower-documents-financials') {
    return '';
  }
  if (slug == 'borrower-addendum-a') {
    return '';
  }
  if (slug == 'borrower-addendum-b') {
    return '';
  }
  if (slug == 'borrower-signed-note') {
    return 'PROMISSORY NOTE';
  }
  if (slug == 'lender-borrower-has-signed') {
    return 'LOAN APPLICATION';
  }
  if (slug == 'lender-borrower-required-docs') {
    return 'SUPPORTING DOCUMENTS';
  }
  if (slug == 'lender-approved-lender-application') {
    return 'LENDER LOAN APPLICATION';
  }
  if (slug == 'lender-borrower-signed-all') {
    return 'PROMISSORY NOTE';
  }

  return 'Unknown Header';
}
