import { PermissionScopeMap, PermissionKey } from '../types';
import { invariant } from 'utils';
import { PermissionFlags } from 'types';

/**
 * Determine if a user has the requested permissions level
 * @param {*} permissionMap
 * @param {*} perspective
 * @param {*} level
 * @param {*} subject
 */
export default function canDoAction(
  permissionMap: PermissionScopeMap,
  perspective: PermissionKey,
  flag: PermissionFlags,
): boolean {
  // perspective: 2_deal || global
  // level 1,2,4,8
  // subject: deal || loantranche
  if (!permissionMap[perspective]) {
    const asString = perspective as any;
    invariant(
      permissionMap[perspective],
      `Perspective was absent from the permission store (${asString})`,
    );
  }

  return (
    permissionMap[perspective] && permissionMap[perspective].source.has(flag)
  );
}
