import * as React from 'react';

import { LoanTrancheFormProps } from '../types';
import {
  isFixedInterest,
  isFloatingRate,
  isAdjustableRate,
  getAmortization,
  synchronizeInterestAndAmortizationTypes,
} from '../sections/utils';

import './AmortizationForm.scss';

import EqualPrincipalAmortizationForm from './EqualPrincipalAmortizationForm';
import LinearAmortizationForm from './LinearAmortizationForm';
import FixedPaymentAmortizationForm from './FixedPaymentAmortizationForm';
import AdjustableRateAmortizationForm from './AdjustableRateAmortizationForm';
import FloatingRateAmortizationForm from './FloatingRateAmortizationForm';
import { Button, Modal } from 'components';
import { DealPerspectivePermissions, MountGuard } from 'security';

type State = {
  isOpen: boolean;
};

type Props = LoanTrancheFormProps & {
  onSave: () => Promise<any>;
};

class AmortizationForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleClick = () => {
    synchronizeInterestAndAmortizationTypes(this.props);

    this.setState(prev => ({ isOpen: !prev.isOpen }));
  };

  handleConfirm = () => {
    this.setState({ isOpen: false });
    this.props.onSave();
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  getAmortization = () => {
    const amortization = getAmortization(this.props.data);

    const isStaged = Boolean((amortization as any).isDirty);
    return { amortization, isStaged };
  };

  renderForm = () => {
    const { data } = this.props;
    const { amortization } = this.getAmortization();

    const { amortizationType } = amortization;

    if (isAdjustableRate(data))
      return <AdjustableRateAmortizationForm {...this.props} />;
    if (isFloatingRate(data))
      return <FloatingRateAmortizationForm {...this.props} />;

    if (amortizationType === 'LINEAR_AMORTIZATION')
      return <LinearAmortizationForm {...this.props} />;

    if (amortizationType === 'FIXED_PRINCIPAL_AND_INTEREST')
      return <FixedPaymentAmortizationForm {...this.props} />;

    return <EqualPrincipalAmortizationForm {...this.props} />;
  };

  renderModalFooter = () => {
    const { disabled } = this.props;
    const { isStaged } = this.getAmortization();
    const actionsCx =
      'StandardModal-Actions LoanTrancheAmortization__ModalFooter';

    return (
      <Modal.Actions className={actionsCx}>
        <MountGuard
          permission={DealPerspectivePermissions.administer_loan_tranches}
        >
          <Button.Text
            className="LoanTrancheAmortization__ResetButton"
            disabled={!isStaged}
            label="Reset To Last Values"
            onClick={this.props.handleResetAmortization}
          />
        </MountGuard>
        <Button.Text label="Cancel" onClick={this.handleClose} />

        <Button label={disabled ? 'OK' : 'Save'} onClick={this.handleConfirm} />
      </Modal.Actions>
    );
  };

  render() {
    const { disabled } = this.props;
    const { isOpen } = this.state;

    const modalName = isFixedInterest(this.props.data)
      ? 'Amortization'
      : 'Payment Calculator';

    return (
      <>
        <Modal
          className="LoanTrancheAmortization__Modal"
          confirmButtonText={disabled ? 'OK' : 'Save'}
          footer={isOpen ? this.renderModalFooter() : null}
          header={modalName}
          isOpen={isOpen}
          onClose={this.handleClose}
          onConfirm={this.handleConfirm}
        >
          {isOpen && this.renderForm()}
        </Modal>
        <Button.Text
          className="AmortizationForm-ModalButton"
          label={modalName}
          onClick={this.handleClick}
          tabIndex={this.props.tabIndex}
        />
      </>
    );
  }
}

export default AmortizationForm;
