import * as React from 'react';
import {
  ConnectDragSource,
  ConnectDropTarget,
  ConnectDragPreview,
} from 'react-dnd';
import DataRoomRow, {
  DataRoomRowRenderProps,
  MenuRenderProps,
  EditRenderProps,
} from '../dataroomRow/DataroomRow';
import { handleCanDrop } from '../utils/dragDropMethods';

import {
  FolderWithDate,
  FolderMap,
  DataroomContext,
  DataRoomBase,
  DragSourceEntity,
  ContextPropTypes,
} from '../types';
import { ID } from '../../../types/primitives';
import FolderAccessPopup from './folderAccessPopup/FolderAccessPopup';
import FolderRowRenameInputContainer from './folderRowRenameInput/FolderRowRenameInputContainer';

import FolderIcon from './folderIcon/FolderIcon';

import FolderRowMenu from './folderRowMenu/FolderRowMenu';
import { MountGuard, FolderPerspectivePermissions } from 'security';
import { Icon, FontAwesomeIconTypes } from 'components';
import './FolderRow.scss';

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unused-vars */

type ReactDnDProps = {
  canDrop: boolean;
  connectDragPreview: ConnectDragPreview;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
  isDragging: boolean;
  isOver: boolean;
};

export type Props = ReactDnDProps & {
  accessDenied?: boolean;
  dragSourceEntity: DragSourceEntity;
  dragSourceType: string | symbol;
  entity: FolderWithDate;
  handleEnterDragHover: (folderId: ID, canDropInRootFolder: boolean) => void;
  isDropAreaBot: boolean;
  isDropAreaTop: boolean;
  isEven: boolean;
  isInDropArea: boolean;
  isOpen: boolean;
  leaveDragHover: () => void;
  level: number;
  listStyle: any;
};

const unPinnedIcon: FontAwesomeIconTypes = ['far', 'thumbtack'];

class FolderRow extends React.Component<Props> {
  static contextTypes = ContextPropTypes;

  componentDidUpdate = () => {
    const { isOpen, entity } = this.props;
    if (isOpen && this.canDelete()) this.getContext().closeFolder(entity.id);
  };

  getContext = () => this.context as DataroomContext;

  handleEnterDragHover = (folderId: ID) => {
    const {
      dragSourceType,
      dragSourceEntity,
      handleEnterDragHover,
    } = this.props;

    handleEnterDragHover(
      folderId,
      handleCanDrop(folderId, dragSourceType, dragSourceEntity),
    );
  };

  getFolderData = () => {
    const { data } = this.getContext();
    const folderData = data.roomMap[this.props.entity.id];
    return folderData;
  };

  canToggleOpenState = () => !this.canDelete();

  canDelete = () => {
    const folderData = this.getFolderData();
    return folderData.children.length === 0;
  };

  handleFolderClick = () => {
    const { entity, isOpen } = this.props;
    if (this.canToggleOpenState()) {
      const context = this.getContext();
      if (!isOpen) {
        context.closeFolder(entity.id);
      } else {
        context.openFolder(entity.id);
      }
    }
  };

  handleUpload = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { entity } = this.props;
    this.getContext().handleUploadFiles(
      entity.id,
      Array.from((e.target as any).files),
    );
  };

  renderEditCell = (props: EditRenderProps<FolderWithDate>) => (
    <span className="FolderRow-RenameInput">
      <FolderIcon
        canOpen={this.canToggleOpenState()}
        isOpen={this.props.isOpen}
        onClick={this.handleFolderClick}
      />
      <FolderRowRenameInputContainer
        folderId={props.entity.id}
        onClose={props.onEndEdit}
      />
    </span>
  );

  renderMenu = (props: MenuRenderProps<FolderWithDate>) => (
    <FolderRowMenu
      {...props}
      canDelete={this.canDelete()}
      isOpen={this.props.isOpen}
    />
  );

  renderNameCell = (props: DataRoomRowRenderProps<FolderWithDate>) => (
    <span className="FolderRow__NameCell">
      <FolderIcon
        canOpen={this.canToggleOpenState()}
        isOpen={this.props.isOpen}
        onClick={this.handleFolderClick}
      />
      {props.entity.name}
      {this.getFolderData().fileCount > 0 && (
        <span className="FolderRow__FileCount">
          ({this.getFolderData().fileCount} files)
        </span>
      )}
    </span>
  );

  renderAccessCell = (
    props: DataRoomRowRenderProps<FolderWithDate>,
    isHovered: boolean,
  ) => {
    const folderData: FolderMap<DataRoomBase> = this.getFolderData();
    const { entity } = props;

    let isUploading = false;

    if (folderData.uploadingCount + folderData.completedUploadCount > 0) {
      isUploading = true;
    }

    if (isUploading) {
      return this.renderUploadingAccessCell(props);
    }

    const accessLevel = entity.access;
    return (
      <div className="FolderRow__AccessCell">
        <div className="FolderRow__AccessLevel">
          <FolderAccessPopup accessLevel={accessLevel} folderId={entity.id} />
        </div>
        <div className="FolderRow__AccessAction">
          {isHovered && (
            <>
              <div />
              <MountGuard
                permission={FolderPerspectivePermissions.change_folder}
              >
                <label
                  className="FolderRow__Link"
                  htmlFor={`uploadDataroomFiles+${entity.id}`}
                >
                  Upload
                </label>
              </MountGuard>
            </>
          )}
        </div>
        <input
          className="FolderRow__UploadInput"
          id={`uploadDataroomFiles+${entity.id}`}
          multiple
          name="selectFiles"
          onChange={this.handleUpload}
          type="file"
          value=""
        />
      </div>
    );
  };

  renderUploadingAccessCell = (
    props: DataRoomRowRenderProps<FolderWithDate>,
  ) => {
    const folderData: FolderMap<DataRoomBase> = this.getFolderData();
    const { entity } = props;
    return (
      <div className="FolderRow__AccessCell">
        <div className="FolderRow__Progress">
          {`${
            folderData.completedUploadCount
          } of ${folderData.completedUploadCount +
            folderData.uploadingCount} files uploaded`}
          {folderData.uploadingCount === folderData.completedUploadCount && (
            <Icon
              className="FolderRow__CheckIcon"
              color="ivyLight3"
              icon="check"
            />
          )}
        </div>
        <input
          className="FolderRow__UploadInput"
          id={`uploadDataroomFiles+${entity.id}`}
          multiple
          name="selectFiles"
          onChange={this.handleUpload}
          type="file"
          value=""
        />
      </div>
    );
  };

  render() {
    const {
      accessDenied,
      entity,
      level,
      canDrop,
      isDropAreaTop,
      isDropAreaBot,
      connectDragPreview,
      connectDragSource,
      connectDropTarget,
      isDragging,
      isInDropArea,
      isOver,
      listStyle,
      isEven,
    } = this.props;

    return (
      <DataRoomRow
        accessDenied={accessDenied}
        canDrop={canDrop}
        connectDragPreview={connectDragPreview}
        connectDragSource={connectDragSource}
        connectDropTarget={connectDropTarget}
        entity={entity}
        isDragging={isDragging}
        isDropAreaBot={isDropAreaBot}
        isDropAreaTop={isDropAreaTop}
        isEven={isEven}
        isInDropArea={isInDropArea}
        isOver={isOver}
        level={level}
        listStyle={listStyle}
        onEnterDragHover={this.handleEnterDragHover}
        onOpenFolder={this.handleFolderClick}
        renderAccessCell={this.renderAccessCell}
        renderEditCell={this.renderEditCell}
        renderMenu={this.renderMenu}
        renderNameCell={this.renderNameCell}
      />
    );
  }
}

export default FolderRow;
