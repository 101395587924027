import { compose } from 'recompose';
import { connect } from 'react-redux';
import CalendarTaskView from './CalendarTaskView';
import { RouteParams, withRouteParams } from 'routing';
import { withLoadingIndicator, FormMediator } from 'components';
import { EventDueStandardQuery, EventDueFormQuery } from 'lsgql';

import eventDueFormRedux from 'lsredux/reducer/forms/eventDue';

export default compose(
  withRouteParams([RouteParams.dealId, RouteParams.taskId]),
  connect(
    eventDueFormRedux.mapStateToProps,
    eventDueFormRedux.actions.generateActions,
  ),
  EventDueStandardQuery,
  EventDueFormQuery,
  FormMediator({
    formId: 'CalendarTaskViewIsComplete',
    baseObjectTypeName: 'EventDue',
    disableFrame: true,
  }),
  withLoadingIndicator('loading'),
)(CalendarTaskView);
