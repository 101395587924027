import React from 'react';
import {
  Tiler,
  TextLink,
  Text,
  Header,
  Divider,
  Icon,
  FontAwesomeIconTypes,
} from 'components';

import './HelpView.scss';

function HelpView() {
  const supportEmail = 'support@loan-street.atlassian.net';

  /** Add the Atlassian Service Desk widget to the page */
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script['data-jsd-embedded'] = true;
  //   script['data-key'] = '0cb9bcbb-4f52-447e-9884-6189c21b9a23';
  //   script['data-base-url'] = 'https://jsd-widget.atlassian.com';
  //   script.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
  //   document.body.appendChild(script);
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  const pdfIcon: FontAwesomeIconTypes = ['fal', 'file-pdf'];

  return (
    <div className="HelpView">
      <Tiler>
        <Tiler.Tile width="Half">
          <Header as="h5" className="steel caps">
            Support
          </Header>
          <Text className="steel">
            Need technical assistance with LoanStreet’s PPP application?
          </Text>
          <Text className="steel">
            Many questions can be answered by our{' '}
            <TextLink
              label="Support Articles"
              target="newTab"
              to="https://loan-street.atlassian.net/servicedesk/customer/portal/2"
            />
            .
          </Text>
          <Text className="steel">
            You can also contact us by{' '}
            <TextLink
              label="raising a request"
              target="newTab"
              to="https://loan-street.atlassian.net/servicedesk/customer/portal/2/group/-1"
            />{' '}
            on the Support Desk.
          </Text>
          <Text className="steel">
            Our support hours are 9:00am - 5:00pm ET on business days. Requests
            are processed in the order that they are received.
          </Text>

          <Divider />

          {/* TODO: Enable following after F.A.Q. page is ready */}
          {/* <Text className="steel">
            Access our{' '}
            <TextLink to={'faqPage'} label="Knowledge Base and FAQs" />.
          </Text>
          <Text className="steel">
            For answers to legal or financial questions contact your credit
            union directly [CU support number].
          </Text> */}
          <Text className="steel">
            For technical support with the application, email{' '}
            <TextLink label={supportEmail} to={`mailto:${supportEmail}`} />
          </Text>
          <Text className="steel">
            This support ticket system does not request or collect{' '}
            <TextLink
              label="Personal Identifiable Information (PII)"
              target="newTab"
              to="https://www.dol.gov/general/ppii"
            />
            , other than your basic contact information for communications
            purposes. As such you should never enter personal or confidential
            information, such as passwords, account numbers, social security
            numbers or PII in a support ticket.
          </Text>

          <Text className="steel">
            PPP application process step-by-step instructions:
          </Text>
          <div className="HelpView__SpecialIconButton">
            <Icon icon={pdfIcon} />{' '}
            <TextLink
              label="Apply as a first-time borrower"
              target="newTab"
              to="https://www.loan-street.com/hubfs/Filing%20a%20PPP%20application%20as%20a%20Borrower.pdf"
            />
          </div>
          <div className="HelpView__SpecialIconButton">
            <Icon icon={pdfIcon} />{' '}
            <TextLink
              label="Apply as a second-time borrower"
              target="newTab"
              to="https://www.loan-street.com/hubfs/SBA%20PPP%20Materials/Applying%20for%20a%20PPP%20loan%20as%20a%20Second-Time%20Borrower.pdf"
            />
          </div>
        </Tiler.Tile>
      </Tiler>
    </div>
  );
}

export default HelpView;
