/** Access a potentially nested property by key.
 * This is similar to basic object/key access `obj[key]`,
 * but also works when your keys are nested.
 *
 * For example, if you are trying to get `deal.dealassignee.name` from a Deal object,
 * you can call `accessProperty('dealassignee.name', deals)`
 */
const accessProperty = (key: string, obj?: object) => {
  let property = obj;
  key.split('.').forEach(segment => {
    property = property ? property[segment] : undefined;
  });
  return property as any;
};
export default accessProperty;
