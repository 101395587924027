import { FormEvent } from 'components';
import { LoanTrancheType } from 'types';
import { FormReduxState } from 'lsredux/genericForms';

/**
 * Calculate Final Maturity date if OriginalTerm and Initial Funding Date
 * both exist.
 *
 * @param {*} dispatch
 * @param {*} fresh
 */
function calculateFinalMaturity(
  dispatch: any,
  fresh: FormReduxState<LoanTrancheType>,
) {
  if (!fresh.data.originalTerm || !fresh.data.initialFundingDate) return;

  if (+fresh.data.originalTerm < 1) return;

  const asDate = new Date(fresh.data.initialFundingDate);
  asDate.setFullYear(+asDate.getFullYear() + +fresh.data.originalTerm);
  const justDate = asDate.toISOString().split('T')[0];
  dispatch.fetchNextBusinessDay(justDate).then(e => {
    const results = e.data.businessDay.followingBusinessDay;
    dispatch.mutateProperty(results, 'finalMaturityDate');
  });
}

const originalTermCalculation: FormEvent<LoanTrancheType> = {
  property: 'originalTerm',
  handler: calculateFinalMaturity,
};
const initialFundingDateCalculation: FormEvent<LoanTrancheType> = {
  property: 'initialFundingDate',
  handler: calculateFinalMaturity,
};

const LoanTrancheEvents = [
  originalTermCalculation,
  initialFundingDateCalculation,
];

export default LoanTrancheEvents;
