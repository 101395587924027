import { gql } from '@apollo/client';
import { DocumentNode } from '../types';

import { EventScheduleFields } from '../fragments';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { EventScheduleType } from 'types';

const EventSchedulesQueryDefinition: DocumentNode = gql`
  query EventSchedulesQuery($dealId: ID) {
    eventSchedules(dealId: $dealId) {
      ...EventScheduleFields
    }
  }
  ${EventScheduleFields}
`;

const EventSchedulesQuery = standardQueryBuilder(
  EventSchedulesQueryDefinition,
  {
    typename: 'EventSchedules',
    disableCache: true,
    skip: ({ dealId }: { dealId: string | null | undefined }) => !dealId,
    variables: ({ dealId }: { dealId: string | null | undefined }) => ({
      dealId,
    }),
    results: (data: {
      eventSchedules: Array<EventScheduleType> | null | undefined;
    }) => {
      const { eventSchedules } = data;
      return { eventSchedules };
    },
  },
);

export default EventSchedulesQuery;
