import { connect } from 'react-redux';
import { compose } from 'recompose';
import { merge } from 'immutable';
import CalendarEventViewTable from './CalendarEventViewTable';
import { ReduxDirectory } from 'lsredux';
import { withToJS } from 'utils';
import {
  DealQuery,
  CalendarItemCategoriesQuery,
  CalendarItemsAllDealsQuery,
  CalendarItemsHolidaysQuery,
  CalendarItemsQuery,
  TaskCategoriesQuery,
} from 'lsgql';
import { RouteParams, withRouteParams } from 'routing';

import {
  changeSortField as changeSortFieldAction,
  toggleFilterCategory,
  resetFilters,
} from 'lsredux/actions/calendar';
import { withLoadingIndicator } from 'components';

const params = [RouteParams.dealId];
const mapStateToProps: (state: any) => { rawData: any } = state => ({
  rawData: merge(
    state.getIn(ReduxDirectory.CalendarKeyPath),
    state.getIn(ReduxDirectory.CalendarViewStartEndKeyPath),
  ),
});

export const CalendarEventTableDealContainer = compose(
  withRouteParams(params),
  connect(mapStateToProps, {
    changeSortField: changeSortFieldAction,
    onToggleFilter: toggleFilterCategory,
    onResetFilters: resetFilters,
  }),
  withToJS,
  TaskCategoriesQuery,
  CalendarItemCategoriesQuery,
  DealQuery,
  CalendarItemsQuery,
  CalendarItemsHolidaysQuery,
)(CalendarEventViewTable);

export const CalendarEventTableContainer = compose(
  connect(mapStateToProps, {
    changeSortField: changeSortFieldAction,
    onToggleFilter: toggleFilterCategory,
    onResetFilters: resetFilters,
  }),
  withToJS,
  TaskCategoriesQuery,
  CalendarItemCategoriesQuery,
  CalendarItemsAllDealsQuery,
  withLoadingIndicator('loading'),
)(CalendarEventViewTable);
