import * as React from 'react';
import { useLocation, useParams, Redirect } from 'react-router-dom';

import { TabNavigationFrame } from 'components';
import { RouteTable } from 'routing';
import { paymentRoutes } from 'routing/routes/DealSubRoutes';

function PaymentsContext() {
  const location = useLocation();
  const { dealId } = useParams();

  function getTabs() {
    return [
      {
        text: 'Payment Schedule',
        to: RouteTable.deal.toDealPaymentSchedule(dealId),
      },
      {
        text: 'Payment Activity',
        to: RouteTable.deal.toDealPaymentActivity(dealId),
      },
    ];
  }

  if (location.pathname === RouteTable.deal.toDealPayments(dealId)) {
    return <Redirect to={RouteTable.deal.toDealPaymentSchedule(dealId)} />;
  }

  return <TabNavigationFrame routeContext={paymentRoutes} tabs={getTabs()} />;
}

export default PaymentsContext;
