import React, { useEffect, useState } from 'react';
import AssociatedFiles from '../associatedFiles/AssociatedFiles';
import { invariant, sortDropdownOptions } from 'utils';
import { Form, WrappedMediatorFormProps } from 'components';
import {
  DealType,
  SelectOptionType,
  GeneralNoticeType,
  FormFieldsType,
  ID,
} from 'types';
import { QueryResult } from 'lsgql';

import './GeneralNotice.scss';

type GeneralNoticeTypeWithTrancheId = GeneralNoticeType & {
  loanTranches?: Array<ID>;
};

type Props = WrappedMediatorFormProps<GeneralNoticeTypeWithTrancheId> & {
  /* eslint-disable react/no-unused-prop-types */
  deal: DealType;

  /* eslint-enable */
  deleteDocument: (id: number) => Promise<QueryResult<any>>;
  refetchDocuments: (data: any) => Promise<any>;
  setStagedFiles: (files: Array<File>) => void;
  stagedFiles: Array<File>;
};

const thirteen = 'thirteen';
const sixteen = 'sixteen';
const objectType = 'GeneralNotice';

function getLoanTranches(props: Props) {
  const loanTrancheSet = props.deal && props.deal.loantrancheSet;
  invariant(loanTrancheSet && loanTrancheSet.length, 'No loan tranches found');
  return loanTrancheSet;
}

function getLoanTrancheOptions(props: Props): Array<SelectOptionType> {
  const loanTrancheSet = getLoanTranches(props);

  const loanTrancheOptions: Array<SelectOptionType> = loanTrancheSet.map(
    loanTranche => ({
      text: loanTranche.name || '',
      value: loanTranche.id,
    }),
  );

  return sortDropdownOptions(loanTrancheOptions);
}

const fields: FormFieldsType = {
  agreementSectionReference: {
    id: `agreementSectionReference`,
    fieldName: 'Agreement Section Reference',
    propertyName: 'agreementSectionReference',
    width: thirteen,
  },
  tranches: {
    id: 'tranches',
    fieldName: 'Tranches',
    propertyName: 'loanTranches',
    width: thirteen,
    allowEmpty: false,
    multiple: true,
    required: true,
  },
  notes: {
    id: 'notes',
    propertyName: 'notes',
    width: sixteen,
    rows: 4,
  },
  noticeType: {
    id: `noticeType`,
    fieldName: 'Notice Type',
    propertyName: 'noticeType',
    width: thirteen,
    required: true,
  },
};

function GeneralNoticeForm(props: Props) {
  const {
    data,
    deleteDocument,
    refetchDocuments,
    stagedFiles,
    setStagedFiles,
    mutateProperty,
    isDirty,
  } = props;
  const [loanTranches, setLoanTranches] = useState(
    getLoanTranches(props).map(loanTranche => loanTranche.id),
  );

  useEffect(() => {
    if (isDirty) {
      mutateProperty(loanTranches, 'loanTranches');
    }
  });

  const loanTrancheOptions = getLoanTrancheOptions(props);

  return (
    <div className="GeneralNoticeForm__Container">
      <Form.Group>
        {Form.FieldRenderer(
          Form.Select,
          {
            ...fields.tranches,
            options: loanTrancheOptions,
            onChange: setLoanTranches,
            value: loanTranches,
          },
          props,
          objectType,
          data.id,
        )}
      </Form.Group>
      <Form.Group>
        {Form.FieldRenderer(
          Form.Input,
          {
            ...fields.noticeType,
            onChange: mutateProperty,
          },
          props,
          objectType,
          data.id,
        )}
      </Form.Group>
      <Form.Group>
        {Form.FieldRenderer(
          Form.Input,
          {
            ...fields.agreementSectionReference,
            onChange: mutateProperty,
          },
          props,
          objectType,
          data.id,
        )}
      </Form.Group>
      <AssociatedFiles
        data={data}
        deleteDocument={deleteDocument}
        header="Documents"
        refetchDocuments={refetchDocuments}
        setStagedFiles={setStagedFiles}
        stagedFiles={stagedFiles}
        uploadTarget="General Notice"
      />
      <Form.Header as="h3" header="Notes (Optional)" />
      <Form.Group>
        {Form.FieldRenderer(
          Form.TextArea,
          {
            ...fields.notes,
            onChange: mutateProperty,
          },
          props,
          objectType,
          data.id,
        )}
      </Form.Group>
    </div>
  );
}

export default GeneralNoticeForm;
