import * as React from 'react';
import { LoanTrancheFormProps } from '../../types';
import { Form, AddressFields } from 'components';

import { ID, LoanTrancheGuarantorType } from 'types';
import { KeyPath } from 'lsredux';

const width = '158px';
const loanTrancheGuarantor = 'LoanTrancheGuarantor';

type GuarantorFieldsProps = LoanTrancheFormProps & {
  addEntity: (keyPath: KeyPath, entity: any) => void;
  data: LoanTrancheGuarantorType | null | undefined;
  isSaving: boolean;
  newGuarantorId: ID;
  removeEntity: (keyPath: KeyPath, entity: any) => void;
  replaceEntity: (keyPath: KeyPath, entity: any) => void;
  tabIndex: number;
};

function IndividualGuarantorFields(
  props: GuarantorFieldsProps & {
    onChange: (
      value: (string | boolean) | null | undefined,
      fieldId: ID,
    ) => void;
  },
) {
  const { tabIndex, data, onChange } = props;
  const id = data ? data.id : null;

  const individualEntityFields = {
    creditScore: {
      fieldName: 'Credit Score',
      onChange,
      propertyName: 'creditScore',
      width,
      tabIndex,
    },
    firstName: {
      fieldName: 'First Name',
      onChange,
      propertyName: 'firstName',
      width,
      tabIndex,
    },
    lastName: {
      fieldName: 'Last Name',
      onChange,
      propertyName: 'lastName',
      width,
      tabIndex,
    },
  };

  const sharedFields = {
    guarantorId: {
      fieldName: 'Guarantor ID',
      onChange,
      propertyName: 'guarantorId',
      width,
      tabIndex,
    },
  };

  return id ? (
    <>
      <Form.Group>
        {Form.FieldRenderer(
          Form.Input,
          {
            id: 'firstName',
            ...individualEntityFields.firstName,
          },
          props,
          loanTrancheGuarantor,
          id,
        )}
        {Form.FieldRenderer(
          Form.Input,
          {
            id: 'lastName',
            ...individualEntityFields.lastName,
          },
          props,
          loanTrancheGuarantor,
          id,
        )}
      </Form.Group>
      <Form.Group>
        {Form.FieldRenderer(
          Form.Numeric,
          {
            id: 'creditScore',
            ...individualEntityFields.creditScore,
          },
          props,
          loanTrancheGuarantor,
          id,
        )}
        {Form.FieldRenderer(
          Form.Input,
          {
            id: 'guarantorId',
            ...sharedFields.guarantorId,
          },
          props,
          loanTrancheGuarantor,
          id,
        )}
      </Form.Group>
      <AddressFields
        disabled={props.disabled}
        handleChange={onChange}
        isSaving={props.isSaving}
        parentType={loanTrancheGuarantor}
        typeName="LoanTrancheGuarantorState"
        {...props}
      />
    </>
  ) : null;
}

export default IndividualGuarantorFields;
