export const getCompleteFormsError = (isSFormEligible: boolean): string =>
  isSFormEligible
    ? 'Please complete all required fields in the Loan Information and Forgiveness Amount       sections, and check the box at the bottom of the Documentation section.'
    : 'Please complete all required fields in the Loan Information and Forgiveness Calculations sections, and check the box at the bottom of the Documentation section.';

export type InstructionsResources = {
  titleText: string;
  forgivenessTitle: string;
  documentationTitle: string;
  worksheetTitle: string;
};

export function getResources(
  isLender: boolean,
  isSFormEligible: boolean,
): InstructionsResources {
  const lenderResources: InstructionsResources = {
    titleText:
      'Borrower loan forgiveness application materials for your review:',
    forgivenessTitle: isSFormEligible
      ? 'Forgiveness Amount'
      : 'Forgiveness Calculation form (for standard or EZ application)',
    documentationTitle: isSFormEligible
      ? 'Borrower must upload required Payroll and Nonpayroll documents'
      : 'Borrower must upload required Payroll, FTE and Nonpayroll documents',
    worksheetTitle:
      'It is recommended that the borrower also upload the completed Worksheet to the Dataroom Forgiveness folder',
  };

  const borrowerResources: InstructionsResources = {
    titleText:
      'In order to submit your standard or EZ forgiveness application to your lender for review, you must complete the following:',
    forgivenessTitle: isSFormEligible
      ? 'Forgiveness Amount'
      : 'Forgiveness Calculation form (for standard or EZ application)',
    documentationTitle: isSFormEligible
      ? 'Payroll, Benefits and Nonpayroll Payment documents'
      : 'Payroll, FTE, Benefits and Nonpayroll Payment documents',
    worksheetTitle: 'Completed LoanStreet Excel Worksheet (Recommended)',
  };

  return isLender ? lenderResources : borrowerResources;
}
