import { compose } from 'recompose';
import { connect } from 'react-redux';
import DemographicsForm from './DemographicsForm';
import { SbaPppForgivenessWorkflowFormQuery } from 'lsgql';
import { withRouteParams, RouteParams } from 'routing';
import { FormMediator } from 'components';
import sbaPppForgivenessDemographicsFormRedux from 'lsredux/reducer/forms/sbaPppForgivenessLoanInformationDemographics';

export default compose(
  withRouteParams([RouteParams.dealId]),
  SbaPppForgivenessWorkflowFormQuery,
  connect(
    sbaPppForgivenessDemographicsFormRedux.mapStateToProps,
    sbaPppForgivenessDemographicsFormRedux.actions.generateActions,
  ),
  FormMediator({
    formId: 'sbaPppForgivenessDemographicsForm',
    baseObjectTypeName: 'Deal',
    disableFrame: true,
  }),
)(DemographicsForm);
