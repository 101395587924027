import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import LenderApplicationQuestions from './LenderApplicationQuestions';
import { RouteParams, withRouteParams } from 'routing';
import { SbaPppLenderClosingWorkflowQuestionsFormQuery } from 'lsgql';
import lenderApplicationQuestionsFormRedux from 'lsredux/reducer/forms/sbaPppLenderClosingWorkflowQuestions';
import { FormMediator, withLoadingIndicator } from 'components';
import triggerClosingWorkflowTransition from 'lsgql/mutations/TransitionClosingWorkflow';
import { isWholeNumber } from 'utils';

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  const bound = lenderApplicationQuestionsFormRedux.actions.generateActions(
    dispatch,
  );

  const save = async (formQueryRefetch: any) => {
    const res = await bound.save(formQueryRefetch);

    if (res.success) {
      try {
        if (
          ownProps?.deal?.dealClosingWorkflow?.state !== 'lender_submit_to_sba'
        ) {
          await triggerClosingWorkflowTransition({
            workflowId: ownProps.workflowId,
            state: 'lender_review_lender_application',
          });
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    }

    return res;
  };

  return {
    ...bound,
    save,
  };
};

export default compose(
  withProps((ownProps: any) => {
    const id = ownProps?.deal?.sbappplenderclosingworkflowquestions?.id;

    if (id) {
      return { id };
    } else {
      return { id: uuid() };
    }
  }),
  withRouteParams([RouteParams.dealId]),
  SbaPppLenderClosingWorkflowQuestionsFormQuery,
  withLoadingIndicator('loading'),
  withProps((ownProps: any) => {
    let canEdit = true;

    if (ownProps && ownProps.preExistingEntity) {
      // We have an object
      const data = ownProps.preExistingEntity;
      if (isWholeNumber(data.id)) {
        canEdit = !!data.canEditState;
      }
    }

    return { canEditState: canEdit };
  }),
  connect(
    lenderApplicationQuestionsFormRedux.mapStateToProps,
    mapDispatchToProps,
  ),
  FormMediator({
    formId: 'lenderApplicationQuestionsForm',
    disableFrame: true,
    blurEventForm: true,
    baseObjectTypeName: 'SbaPppLenderClosingWorkflowQuestions',
  }),
)(LenderApplicationQuestions);
