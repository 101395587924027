import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import {
  DealUserShallowFields,
  DealInstitutionShallowFields,
  RoleNameShallowFields,
  InvitationShallowFields,
} from '../fragments/generated';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { DealOrganizationDealRolesType } from 'types';

const DealOrganizationDealRolesDefinition: DocumentNode = gql`
  query DealOrganizationDealRolesQuery($dealId: ID!) {
    dealOrganizationDealRoles(dealId: $dealId) {
      id
      institution {
        ...DealInstitutionShallowFields
        dealUsers(dealId: $dealId) {
          ...DealUserShallowFields
          dealRole {
            ...RoleNameShallowFields
          }
          organizationRole {
            ...RoleNameShallowFields
          }
        }
        invitationSet(dealId: $dealId) {
          ...InvitationShallowFields
          dealRole {
            id
            name
          }
          organizationRole {
            id
            name
          }
          deal {
            id
          }
        }
      }
      dealJob
    }
  }
  ${DealInstitutionShallowFields}
  ${DealUserShallowFields}
  ${RoleNameShallowFields}
  ${InvitationShallowFields}
`;

const DealOrganizationDealRolesQuery = standardQueryBuilder(
  DealOrganizationDealRolesDefinition,
  {
    typename: 'DealOrganizationDealRoles',
    disableCache: true,
    addFetchMethod: true,
    skip: ({ dealId }: { dealId: string | null | undefined }) => !dealId,
    variables: ({ dealId }: { dealId: string | null | undefined }) => ({
      dealId,
    }),
    results: (data: {
      dealOrganizationDealRoles:
        | Array<DealOrganizationDealRolesType>
        | null
        | undefined;
    }) => {
      const { dealOrganizationDealRoles } = data;
      return { dealOrganizationDealRoles };
    },
  },
);

export default DealOrganizationDealRolesQuery;
