import * as React from 'react';
import { Form, IconButton } from 'components';
import { hoverMethods } from 'utils';
import {
  CovenantType,
  CovenantCovenantType,
  ValidationMap,
  FormFieldsType,
} from 'types';

import './OtherCovenantItem.scss';

const loanTranche = 'LoanTranche';

type Props = {
  addCovenant: (covenant: CovenantCovenantType, description: string) => void;
  data: CovenantType;
  disabled?: boolean;

  /* eslint-disable react/no-unused-prop-types */
  errors?: ValidationMap | null | undefined;
  fieldName: string;
  isSaving: boolean;

  /* eslint-enable react/no-unused-prop-types */
  mutateCovenant: (covenant: CovenantType) => void;
  removeCovenant: (covenant: CovenantType) => void;
  tabIndex: number;
};

type State = {
  isHovered: boolean;
};

const OTHER = 'OTHER';
const other = OTHER.toLowerCase();

class OtherCovenantItem extends React.Component<Props, State> {
  fields: FormFieldsType = {};

  handleHoverLeave: () => void;

  handleMouseEnter: () => void;

  handleMouseLeave: () => void;

  handleMouseMove: (e: React.MouseEvent<any>) => void;

  constructor(props: Props) {
    super(props);

    this.fields = {
      other: {
        id: other,
        propertyName: other,
        tabIndex: this.props.tabIndex,
        width: '332px',
        onChange: this.handleMutateCovenant,
        fieldName: this.props.fieldName,
      },
    };

    this.state = { isHovered: false };
    this.handleHoverLeave = hoverMethods.handleHoverLeave.bind(this);
    this.handleMouseEnter = hoverMethods.handleMouseEnter.bind(this);
    this.handleMouseLeave = hoverMethods.handleMouseLeave.bind(this);
    this.handleMouseMove = hoverMethods.handleMouseMove.bind(this);
  }

  handleAddCovenant = () => {
    this.props.addCovenant(OTHER, '');
  };

  handleMutateCovenant = (value: string | null | undefined) => {
    if (value) {
      this.props.mutateCovenant({ ...this.props.data, description: value });
    } else {
      this.handleRemoveCovenant();
    }
  };

  handleRemoveCovenant = () => {
    this.props.removeCovenant(this.props.data);
  };

  render() {
    const { data, disabled } = this.props;
    const { isHovered } = this.state;

    return (
      <Form.Group>
        <div
          className="OtherCovenantItem"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {Form.FieldRenderer(
            Form.Input,
            {
              ...this.fields.other,
              value: data.description,
            },
            this.props,
            loanTranche,
            data.id,
          )}
          {isHovered && !disabled && (
            <IconButton
              alt="Remove Covenant"
              className="OtherCovenantItem__DeleteButton"
              icon="trash"
              onClick={this.handleRemoveCovenant}
            />
          )}
        </div>
      </Form.Group>
    );
  }
}

export default OtherCovenantItem;
