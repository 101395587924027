export const SET_VIEW_START = 'auditTrailFilters_SET_VIEW_START';
export const SET_VIEW_END = 'auditTrailFilters_SET_VIEW_END';
export const SET_SECTION = 'auditTrailFilters_SET_SECTION';
export const SET_USER = 'auditTrailFilters_SET_USER';
export const SET_CHANGED_ITEM = 'auditTrailFilters_SET_CHANGED_ITEM';
export const RESET_FILTERS = 'auditTrailFilters_RESET_FILTERS';

export function setViewStart(viewStart: Date) {
  return {
    type: SET_VIEW_START,
    payload: {
      viewStart,
    },
  };
}

export function setViewEnd(viewEnd: Date) {
  return {
    type: SET_VIEW_END,
    payload: {
      viewEnd,
    },
  };
}

export function setSection(section: Date) {
  return {
    type: SET_SECTION,
    payload: {
      section,
    },
  };
}

export function setUser(user: string) {
  return {
    type: SET_USER,
    payload: {
      user,
    },
  };
}

export function setChangedItem(changedItem: Date) {
  return {
    type: SET_CHANGED_ITEM,
    payload: {
      changedItem,
    },
  };
}

export function resetFilters() {
  return {
    type: RESET_FILTERS,
  };
}

export type Action = { payload: Record<string, any>; type: string };
