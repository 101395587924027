import moment from 'moment';
import { fromJS } from 'immutable';

import {
  SET_VIEW_START,
  SET_VIEW_END,
  SET_SECTION,
  SET_USER,
  SET_CHANGED_ITEM,
  RESET_FILTERS,
} from '../../actions/auditTrailFilters';
import standardReducerFactory from '../standardReducer';

const All = 'All';
const viewStart = moment().subtract(1, 'months');
const viewEnd = new Date();

const initialState = fromJS({
  viewStart,
  viewEnd,
  section: All,
  changedItem: All,
  user: All,
});

const auditTrailFiltersReducerMap: {
  [key: string]: (state: any, action: any) => Record<string, any>;
} = {};

auditTrailFiltersReducerMap[SET_VIEW_START] = (state, action) =>
  state.set('viewStart', action.payload.viewStart);

auditTrailFiltersReducerMap[SET_VIEW_END] = (state, action) =>
  state.set('viewEnd', action.payload.viewEnd);

auditTrailFiltersReducerMap[SET_SECTION] = (state, action) =>
  state.set('section', action.payload.section);

auditTrailFiltersReducerMap[SET_USER] = (state, action) =>
  state.set('user', action.payload.user);

auditTrailFiltersReducerMap[SET_CHANGED_ITEM] = (state, action) =>
  state.set('changedItem', action.payload.changedItem);

auditTrailFiltersReducerMap[RESET_FILTERS] = () => initialState;

export default standardReducerFactory(
  auditTrailFiltersReducerMap,
  initialState,
);
