import React from 'react';
import { Text, Modal } from 'components';

interface ModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  isSForm: boolean;
}

export function DocumentationModal(props: ModalProps) {
  const { isOpen, onConfirm, isSForm } = props;

  return (
    <Modal
      className="Steel"
      header="Document Retention Instructions"
      isOpen={isOpen}
      minWidth="Half"
      onConfirm={onConfirm}
      width="Half"
    >
      {isSForm ? <SFormBody /> : <NormalBody />}
    </Modal>
  );
}

function NormalBody() {
  return (
    <>
      <Text
        className="bold"
        value="Borrower Must Maintain but is Not Required to Submit"
      />
      <Text value="PPP Schedule A Worksheet or its equivalent and the following:" />
      <ol className="OrderedList" type="a">
        <li>
          Documentation supporting the listing of each individual employee in
          PPP Schedule A Worksheet Table 1, including the “Salary/Hourly Wage
          Reduction” calculation, if necessary.
        </li>
        <li>
          Documentation supporting the listing of each individual employee in
          PPP Schedule A Worksheet Table 2; specifically, that each listed
          employee received during any single pay period in 2019 compensation at
          an annualized rate of more than $100,000.
        </li>
        <li>
          Documentation regarding any employee job offers and refusals, firings
          for cause, voluntary resignations, and written requests by any
          employee for reductions in work schedule.
        </li>
        <li>
          Documentation supporting the PPP Schedule A Worksheet “FTE Reduction
          Safe Harbor.”
        </li>
      </ol>
      <Text value="All records relating to the Borrower’s PPP loan, including documentation submitted with its PPP loan application, documentation supporting the Borrower’s certifications as to the necessity of the loan request and its eligibility for a PPP loan, documentation necessary to support the Borrower’s loan forgiveness application, and documentation demonstrating the Borrower’s material compliance with PPP requirements. The Borrower must retain all such documentation in its files for six years after the date the loan is forgiven or repaid in full, and permit authorized representatives of SBA, including representatives of its Office of Inspector General, to access such files upon request." />
    </>
  );
}

function SFormBody() {
  return (
    <>
      <Text
        className="bold"
        value="Documents that Each Borrower Must Maintain but is Not Required to Submit"
      />
      <br />
      <Text value="All records relating to the Borrower’s PPP loan, including documentation submitted with its PPP loan application, documentation supporting the Borrower’s certifications as to its eligibility for a PPP loan, documentation necessary to support the Borrower’s loan forgiveness application, and documentation demonstrating the Borrower’s material compliance with PPP requirements. The Borrower must retain all such documentation in its files for six years after the date the loan is forgiven or repaid in full, and permit authorized representatives of SBA, including representatives of its Office of Inspector General, to access such files upon request." />
    </>
  );
}
