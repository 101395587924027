import * as React from 'react';
import cx from 'classnames';
import { Form } from 'components';

import './TrancheGroup.scss';

function TrancheGroup(props: {
  children: React.ReactNode;
  grouped?: boolean;
  className?: string;
}) {
  return (
    <Form.Group
      className={cx('TrancheRow--FormGroup', props.className)}
      grouped={props.grouped}
      unstackable
      widths="equal"
    >
      {props.children}
    </Form.Group>
  );
}

TrancheGroup.defaultProps = {
  grouped: true,
};

export default TrancheGroup;
