import { PayloadAction } from '../types';

export const SET_FORM_DISABLED = 'SET_FORM_DISABLED';

export function setFormDisabled(
  formId: string,
  value: boolean,
): PayloadAction<{ formId: string; value: boolean }> {
  return {
    type: SET_FORM_DISABLED,
    payload: {
      formId,
      value,
    },
  };
}
