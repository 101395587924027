import * as React from 'react';
import noop from 'lodash/noop';
import { Form } from 'components';
import { BenchmarkOptionType, ValidationMap } from 'types';

type Props = {
  data: ReadonlyArray<BenchmarkOptionType>;

  description: string;

  disabled?: boolean;

  /* eslint-disable react/no-unused-prop-types */
  errors?: ValidationMap | null | undefined;

  isSaving: boolean;

  /* eslint-enable react/no-unused-prop-types */

  onChange: (option: BenchmarkOptionType) => void;
};

const loanTranche = 'LoanTranche';
const width = '90px';
const rateMinimum = 'rateMinimum';
const rateMaximum = 'rateMaximum';

class FloorAndCapFieldsLiborItem extends React.Component<Props> {
  handlePercentageChange = (field: string) => (
    value: string | null | undefined,
  ) => {
    const benchmarkOptions = this.props.data;
    benchmarkOptions.forEach(option => {
      if (option[field] !== value)
        this.props.onChange({ ...option, [field]: value });
    });
  };

  /* eslint-enable react/sort-comp */

  fields = {
    description: {
      id: 'description',
      propertyName: 'description',
      value: this.props.description,
      onChange: noop,
      width,
      disabled: true,
      disabledReadable: true,
    },
    floor: {
      id: rateMinimum,
      propertyName: rateMinimum,
      onChange: this.handlePercentageChange(rateMinimum),
      disabled: Boolean(this.props.disabled),
      width,
      fieldName: 'Floor',
    },
    cap: {
      id: rateMaximum,
      propertyName: rateMaximum,
      onChange: this.handlePercentageChange(rateMaximum),
      disabled: Boolean(this.props.disabled),
      width,
      fieldName: 'Cap',
    },
  };

  render() {
    const { data, disabled } = this.props;
    const firstBenchmarkOption = data[0];

    const PercentageComponent = disabled
      ? Form.PercentageReadOnly
      : Form.Percentage;

    return (
      <Form.Group className="FloorAndCapGroup">
        {Form.FieldRenderer(
          Form.Text,
          this.fields.description,
          this.props,
          loanTranche,
          firstBenchmarkOption.id,
        )}
        {Form.FieldRenderer(
          PercentageComponent,
          {
            ...this.fields.floor,
            value: firstBenchmarkOption && firstBenchmarkOption.rateMinimum,
          },
          this.props,
          loanTranche,
          firstBenchmarkOption.id,
        )}
        {Form.FieldRenderer(
          PercentageComponent,
          {
            ...this.fields.cap,
            value: firstBenchmarkOption && firstBenchmarkOption.rateMaximum,
          },
          this.props,
          loanTranche,
          firstBenchmarkOption.id,
        )}
      </Form.Group>
    );
  }
}

export default FloorAndCapFieldsLiborItem;
