import isEmpty from 'lodash/isEmpty';
import { fromJS } from 'immutable';
import { MutationResponseAction, FormReduxArgs } from '../types';
import {
  entityValidator,
  rootKey,
  errorsKey,
  cleanDataKey,
  isDirty,
  pendingDeleteKey,
} from '../methods';
import { invariant } from 'utils';
import { BaseType } from 'types';

/* eslint-disable import/prefer-default-export */

/**
 * Examine a GraphQL Mutation response object and populate state appropriately
 * If the reponse contained errors, these will populate into the 'errors' property,
 * otherwise 'data' will be refreshed.
 * @param {*} state
 * @param {*} action
 */
export function handleMutationResponse<
  TOut extends BaseType,
  TIn extends BaseType
>(
  state: any, // This is an Immutable version of FormReduxState<TOut>
  action: MutationResponseAction<TOut>,
  args?: FormReduxArgs<TOut, TIn>,
): Record<string, any> {
  const {
    response: { errors, ok },
  } = action.payload;
  let {
    response: { entity },
  } = action.payload;
  let result = state;

  // Update data & cleanData
  if (entity) {
    if (args && args.dataConverter) {
      // entity can be TOut or TIn
      entity = args.dataConverter(entity as any);
    }

    /**
     * We expect the entire entity tree to be free of the
     * isDirty flag, but checking the entire tree is expensive
     * Checking just the root object should suffice
     */
    invariant(
      !(entity as any).isDirty,
      'Expected mutation response to not contain an isDirty flag',
    );
    entityValidator(entity);

    const fresh = fromJS(entity);

    /**
     * The `ok` property assures us that all mutations have succeeded.
     * This may be set even when there are errors, which are also
     * populated by validations.
     */
    if (ok) {
      result = state
        .set(rootKey, fresh)
        .set(cleanDataKey, fresh)
        .set(isDirty, false);
    }
  }

  // update Errors
  result = result
    .set(errorsKey, errors === null || isEmpty(errors) ? null : fromJS(errors))
    .set(pendingDeleteKey, {});

  return result;
}
