import uuid from 'uuid/v4';
import { EventDueMutationMethods } from '../../../graphql';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import { EventDueType } from 'types';

const initialEventDueForm: EventDueType = {
  id: uuid(),
  __typename: 'EventDueType',
  isLeaf: true,
} as any;

const lifecycleMethods = {
  mutations: {
    ...EventDueMutationMethods,
  },
  validators: {},
};

const eventDueFormRedux = createForm<EventDueType, EventDueType>(
  Directory.EventDueKey,
  initialEventDueForm,
  lifecycleMethods,
);

export default eventDueFormRedux;
