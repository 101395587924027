import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import { InvitationShallowFields } from './generated';

const InvitationFields: DocumentNode = gql`
  fragment InvitationFields on InvitationType {
    ...InvitationShallowFields
    inviter {
      id
    }
    dealRole {
      id
    }
    deal {
      id
    }
  }
  ${InvitationShallowFields}
`;

export default InvitationFields;
