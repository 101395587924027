const keyCodes = {
  A: { keyCode: 65, which: 65, key: 'KeyA' },
  Enter: { keyCode: 13, which: 13, key: 'Enter' },
  Escape: { keyCode: 27, which: 27, key: 'Escape' },

  up: { keyCode: 38, which: 38, key: 'ArrowUp' },
  down: { keyCode: 40, which: 40, key: 'ArrowDown' },
  left: { keyCode: 37, which: 37, key: 'ArrowLeft' },
  right: { keyCode: 39, which: 39, key: 'ArrowRight' },
  r: { keyCode: 82, which: 82, key: 'r' },
  e: { keyCode: 69, which: 69, key: 'e' },
};

export default keyCodes;
