export const ADD_ALTERNATEBASERATEBENCHMARK = 'add_alternatebaseratebenchmark';
export const ADD_APPLICABLEMARGIN = 'add_applicablemargin';
export const ADD_APPLICABLEMARGINCHANGE = 'add_applicablemarginchange';
export const ADD_BENCHMARK = 'add_benchmark';
export const ADD_BENCHMARKOPTION = 'add_benchmarkoption';
export const ADD_BENCHMARKRATE = 'add_benchmarkrate';
export const ADD_BORROWER = 'add_borrower';
export const ADD_BORROWERBANKACCOUNT = 'add_borrowerbankaccount';
export const ADD_CALENDARITEM = 'add_calendaritem';
export const ADD_CALENDARITEMCATEGORY = 'add_calendaritemcategory';
export const ADD_CLOCKEDSCHEDULE = 'add_clockedschedule';
export const ADD_CONTENTTYPE = 'add_contenttype';
export const ADD_COVENANT = 'add_covenant';
export const ADD_CREDITRATING = 'add_creditrating';
export const ADD_CRONTABSCHEDULE = 'add_crontabschedule';
export const ADD_DEAL = 'add_deal';
export const ADD_DOCUMENT = 'add_document';
export const ADD_DOCUMENTRECORDASSOCIATION = 'add_documentrecordassociation';
export const ADD_DRAWREQUEST = 'add_drawrequest';
export const ADD_ESCROWACCOUNT = 'add_escrowaccount';
export const ADD_ESPCOMMANDLOG = 'add_espcommandlog';
export const ADD_EVENTAUDIT = 'add_eventaudit';
export const ADD_EVENTDUE = 'add_eventdue';
export const ADD_EVENTSCHEDULE = 'add_eventschedule';
export const ADD_EVENTSCHEDULETEMPLATE = 'add_eventscheduletemplate';
export const ADD_FEE = 'add_fee';
export const ADD_FEECALCULATIONRULE = 'add_feecalculationrule';
export const ADD_FEEDIVISION = 'add_feedivision';
export const ADD_FEEPAYMENTDUE = 'add_feepaymentdue';
export const ADD_FILE = 'add_file';
export const ADD_FOLDER = 'add_folder';
export const ADD_GENERALNOTICE = 'add_generalnotice';
export const ADD_GROUP = 'add_group';
export const ADD_GUARANTEE = 'add_guarantee';
export const ADD_INDUSTRYCODE = 'add_industrycode';
export const ADD_INSTITUTION = 'add_institution';
export const ADD_INSTITUTIONBANKACCOUNT = 'add_institutionbankaccount';
export const ADD_INSTITUTIONDISPLAYCUSTOMIZATION =
  'add_institutiondisplaycustomization';
export const ADD_INTERESTPERIODELECTION = 'add_interestperiodelection';
export const ADD_INTERVALSCHEDULE = 'add_intervalschedule';
export const ADD_INVITATION = 'add_invitation';
export const ADD_LOANSLICE = 'add_loanslice';
export const ADD_LOANTRANCHE = 'add_loantranche';
export const ADD_LOANTRANCHEADJUSTABLERATEDATA =
  'add_loantrancheadjustableratedata';
export const ADD_LOANTRANCHEADJUSTABLERATEPERIOD =
  'add_loantrancheadjustablerateperiod';
export const ADD_LOANTRANCHEAMORTIZATION = 'add_loantrancheamortization';
export const ADD_LOANTRANCHEAMORTIZATIONINTERESTPERIOD =
  'add_loantrancheamortizationinterestperiod';
export const ADD_LOANTRANCHEAMORTIZATIONPRINCIPALPAYMENT =
  'add_loantrancheamortizationprincipalpayment';
export const ADD_LOANTRANCHECOLLATERAL = 'add_loantranchecollateral';
export const ADD_LOANTRANCHEFLOATINGRATEDATA =
  'add_loantranchefloatingratedata';
export const ADD_LOANTRANCHEGUARANTOR = 'add_loantrancheguarantor';
export const ADD_LOANTRANCHEINSTITUTIONOWNERSHIPPORTION =
  'add_loantrancheinstitutionownershipportion';
export const ADD_LOANTRANCHEMULTIDRAWDATA = 'add_loantranchemultidrawdata';
export const ADD_LOANTRANCHEOWNERSHIPPORTION =
  'add_loantrancheownershipportion';
export const ADD_LOANTRANCHEREVOLVERSWINGLINELOCDATA =
  'add_loantrancherevolverswinglinelocdata';
export const ADD_LOANTRANCHETIER = 'add_loantranchetier';
export const ADD_LOGENTRY = 'add_logentry';
export const ADD_NOTIFICATION = 'add_notification';
export const ADD_ORGANIZATIONDEALROLE = 'add_organizationdealrole';
export const ADD_ORGANIZATIONFOLDERROLE = 'add_organizationfolderrole';
export const ADD_PAYMENTDUE = 'add_paymentdue';
export const ADD_PAYMENTRECORD = 'add_paymentrecord';
export const ADD_PERIODICTASK = 'add_periodictask';
export const ADD_PERIODICTASKS = 'add_periodictasks';
export const ADD_PERMISSION = 'add_permission';
export const ADD_PREPAYMENTPENALTYRANGE = 'add_prepaymentpenaltyrange';
export const ADD_PROSPECT = 'add_prospect';
export const ADD_RESERVE = 'add_reserve';
export const ADD_REVISION = 'add_revision';
export const ADD_ROLE = 'add_role';
export const ADD_SESSION = 'add_session';
export const ADD_SITE = 'add_site';
export const ADD_SOLARSCHEDULE = 'add_solarschedule';
export const ADD_TIERFEEPERCENTAGE = 'add_tierfeepercentage';
export const ADD_TOTPDEVICE = 'add_totpdevice';
export const ADD_USER = 'add_user';
export const ADD_USERDEALROLE = 'add_userdealrole';
export const ADD_USERORGANIZATIONROLE = 'add_userorganizationrole';
export const ADD_USERPSWTRIED = 'add_userpswtried';
export const ADD_VERSION = 'add_version';
export const ADD_WIDGET = 'add_widget';
export const ADMINISTER_APPLICABLE_MARGIN_CHANGES =
  'administer_applicable_margin_changes';
export const ADMINISTER_BORROWERS = 'administer_borrowers';
export const ADMINISTER_CALENDAR_INTERNAL = 'administer_calendar_internal';
export const ADMINISTER_CANCEL_LOAN = 'administer_cancel_loan';
export const ADMINISTER_CALENDAR_PUBLIC = 'administer_calendar_public';
export const ADMINISTER_CLOSING = 'administer_closing';
export const ADMINISTER_COLENDERS = 'administer_colenders';
export const ADMINISTER_DATAROOM = 'administer_dataroom';
export const ADMINISTER_DATAROOM_PRIVATE = 'administer_dataroom_private';
export const ADMINISTER_DATAROOM_PUBLIC = 'administer_dataroom_public';
export const ADMINISTER_DEAL = 'administer_deal';
export const ADMINISTER_DEAL_BORROWER = 'administer_deal_borrower';
export const ADMINISTER_DEAL_DEALASSIGNEE = 'administer_deal_dealassignee';
export const ADMINISTER_DEAL_STAGE = 'administer_deal_stage';
export const ADMINISTER_DRAW_REQUESTS_BORROWER =
  'administer_draw_requests_borrower';
export const ADMINISTER_DRAW_REQUESTS_LENDER =
  'administer_draw_requests_lender';
export const ADMINISTER_FEES = 'administer_fees';
export const ADMINISTER_FOLDER = 'administer_folder';
export const ADMINISTER_INSTITUTION = 'administer_institution';
export const ADMINISTER_INTEREST_PERIOD_ELECTIONS =
  'administer_interest_period_elections';
export const ADMINISTER_INVESTOR_BOOK = 'administer_investor_book';
export const ADMINISTER_LEAD_LENDERS = 'administer_lead_lenders';
export const ADMINISTER_LENDER_REGISTRY = 'administer_lender_registry';
export const ADMINISTER_LOAN_TRANCHES = 'administer_loan_tranches';
export const ADMINISTER_OWN_TEAM = 'administer_own_team';
export const ADMINISTER_PAYMENTS_BORROWER = 'administer_payments_borrower';
export const ADMINISTER_PAYMENTS_LENDER = 'administer_payments_lender';
export const ADMINISTER_SERVICING_AGENTS = 'administer_servicing_agents';
export const ADMINISTER_TERM_SHEET = 'administer_term_sheet';
export const CHANGE_ALTERNATEBASERATEBENCHMARK =
  'change_alternatebaseratebenchmark';
export const CHANGE_APPLICABLEMARGIN = 'change_applicablemargin';
export const CHANGE_APPLICABLEMARGINCHANGE = 'change_applicablemarginchange';
export const CHANGE_BENCHMARK = 'change_benchmark';
export const CHANGE_BENCHMARKOPTION = 'change_benchmarkoption';
export const CHANGE_BENCHMARKRATE = 'change_benchmarkrate';
export const CHANGE_BORROWER = 'change_borrower';
export const CHANGE_BORROWERBANKACCOUNT = 'change_borrowerbankaccount';
export const CHANGE_CALENDARITEM = 'change_calendaritem';
export const CHANGE_CALENDARITEMCATEGORY = 'change_calendaritemcategory';
export const CHANGE_CLOCKEDSCHEDULE = 'change_clockedschedule';
export const CHANGE_CONTENTTYPE = 'change_contenttype';
export const CHANGE_COVENANT = 'change_covenant';
export const CHANGE_CREDITRATING = 'change_creditrating';
export const CHANGE_CRONTABSCHEDULE = 'change_crontabschedule';
export const CHANGE_DEAL = 'change_deal';
export const CHANGE_DOCUMENT = 'change_document';
export const CHANGE_DOCUMENTRECORDASSOCIATION =
  'change_documentrecordassociation';
export const CHANGE_DRAWREQUEST = 'change_drawrequest';
export const CHANGE_ESCROWACCOUNT = 'change_escrowaccount';
export const CHANGE_ESPCOMMANDLOG = 'change_espcommandlog';
export const CHANGE_EVENTAUDIT = 'change_eventaudit';
export const CHANGE_EVENTDUE = 'change_eventdue';
export const CHANGE_EVENTSCHEDULE = 'change_eventschedule';
export const CHANGE_EVENTSCHEDULETEMPLATE = 'change_eventscheduletemplate';
export const CHANGE_FEE = 'change_fee';
export const CHANGE_FEECALCULATIONRULE = 'change_feecalculationrule';
export const CHANGE_FEEDIVISION = 'change_feedivision';
export const CHANGE_FEEPAYMENTDUE = 'change_feepaymentdue';
export const CHANGE_FILE = 'change_file';
export const CHANGE_FOLDER = 'change_folder';
export const CHANGE_GENERALNOTICE = 'change_generalnotice';
export const CHANGE_GROUP = 'change_group';
export const CHANGE_GUARANTEE = 'change_guarantee';
export const CHANGE_INDUSTRYCODE = 'change_industrycode';
export const CHANGE_INSTITUTION = 'change_institution';
export const CHANGE_INSTITUTIONBANKACCOUNT = 'change_institutionbankaccount';
export const CHANGE_INSTITUTIONDISPLAYCUSTOMIZATION =
  'change_institutiondisplaycustomization';
export const CHANGE_INTERESTPERIODELECTION = 'change_interestperiodelection';
export const CHANGE_INTERVALSCHEDULE = 'change_intervalschedule';
export const CHANGE_INVITATION = 'change_invitation';
export const CHANGE_LOANSLICE = 'change_loanslice';
export const CHANGE_LOANTRANCHE = 'change_loantranche';
export const CHANGE_LOANTRANCHEADJUSTABLERATEDATA =
  'change_loantrancheadjustableratedata';
export const CHANGE_LOANTRANCHEADJUSTABLERATEPERIOD =
  'change_loantrancheadjustablerateperiod';
export const CHANGE_LOANTRANCHEAMORTIZATION = 'change_loantrancheamortization';
export const CHANGE_LOANTRANCHEAMORTIZATIONINTERESTPERIOD =
  'change_loantrancheamortizationinterestperiod';
export const CHANGE_LOANTRANCHEAMORTIZATIONPRINCIPALPAYMENT =
  'change_loantrancheamortizationprincipalpayment';
export const CHANGE_LOANTRANCHECOLLATERAL = 'change_loantranchecollateral';
export const CHANGE_LOANTRANCHEFLOATINGRATEDATA =
  'change_loantranchefloatingratedata';
export const CHANGE_LOANTRANCHEGUARANTOR = 'change_loantrancheguarantor';
export const CHANGE_LOANTRANCHEINSTITUTIONOWNERSHIPPORTION =
  'change_loantrancheinstitutionownershipportion';
export const CHANGE_LOANTRANCHEMULTIDRAWDATA =
  'change_loantranchemultidrawdata';
export const CHANGE_LOANTRANCHEOWNERSHIPPORTION =
  'change_loantrancheownershipportion';
export const CHANGE_LOANTRANCHEREVOLVERSWINGLINELOCDATA =
  'change_loantrancherevolverswinglinelocdata';
export const CHANGE_LOANTRANCHETIER = 'change_loantranchetier';
export const CHANGE_LOGENTRY = 'change_logentry';
export const CHANGE_NOTIFICATION = 'change_notification';
export const CHANGE_ORGANIZATIONDEALROLE = 'change_organizationdealrole';
export const CHANGE_ORGANIZATIONFOLDERROLE = 'change_organizationfolderrole';
export const CHANGE_PAYMENTDUE = 'change_paymentdue';
export const CHANGE_PAYMENTRECORD = 'change_paymentrecord';
export const CHANGE_PERIODICTASK = 'change_periodictask';
export const CHANGE_PERIODICTASKS = 'change_periodictasks';
export const CHANGE_PERMISSION = 'change_permission';
export const CHANGE_PREPAYMENTPENALTYRANGE = 'change_prepaymentpenaltyrange';
export const CHANGE_PROSPECT = 'change_prospect';
export const CHANGE_RESERVE = 'change_reserve';
export const CHANGE_REVISION = 'change_revision';
export const CHANGE_ROLE = 'change_role';
export const CHANGE_SESSION = 'change_session';
export const CHANGE_SITE = 'change_site';
export const CHANGE_SOLARSCHEDULE = 'change_solarschedule';
export const CHANGE_TIERFEEPERCENTAGE = 'change_tierfeepercentage';
export const CHANGE_TOTPDEVICE = 'change_totpdevice';
export const CHANGE_USER = 'change_user';
export const CHANGE_USERDEALROLE = 'change_userdealrole';
export const CHANGE_USERORGANIZATIONROLE = 'change_userorganizationrole';
export const CHANGE_USERPSWTRIED = 'change_userpswtried';
export const CHANGE_VERSION = 'change_version';
export const CHANGE_WIDGET = 'change_widget';
export const DELETE_ALTERNATEBASERATEBENCHMARK =
  'delete_alternatebaseratebenchmark';
export const DELETE_APPLICABLEMARGIN = 'delete_applicablemargin';
export const DELETE_APPLICABLEMARGINCHANGE = 'delete_applicablemarginchange';
export const DELETE_BENCHMARK = 'delete_benchmark';
export const DELETE_BENCHMARKOPTION = 'delete_benchmarkoption';
export const DELETE_BENCHMARKRATE = 'delete_benchmarkrate';
export const DELETE_BORROWER = 'delete_borrower';
export const DELETE_BORROWERBANKACCOUNT = 'delete_borrowerbankaccount';
export const DELETE_CALENDARITEM = 'delete_calendaritem';
export const DELETE_CALENDARITEMCATEGORY = 'delete_calendaritemcategory';
export const DELETE_CLOCKEDSCHEDULE = 'delete_clockedschedule';
export const DELETE_CONTENTTYPE = 'delete_contenttype';
export const DELETE_COVENANT = 'delete_covenant';
export const DELETE_CREDITRATING = 'delete_creditrating';
export const DELETE_CRONTABSCHEDULE = 'delete_crontabschedule';
export const DELETE_DEAL = 'delete_deal';
export const DELETE_DOCUMENT = 'delete_document';
export const DELETE_DOCUMENTRECORDASSOCIATION =
  'delete_documentrecordassociation';
export const DELETE_DRAWREQUEST = 'delete_drawrequest';
export const DELETE_ESCROWACCOUNT = 'delete_escrowaccount';
export const DELETE_ESPCOMMANDLOG = 'delete_espcommandlog';
export const DELETE_EVENTAUDIT = 'delete_eventaudit';
export const DELETE_EVENTDUE = 'delete_eventdue';
export const DELETE_EVENTSCHEDULE = 'delete_eventschedule';
export const DELETE_EVENTSCHEDULETEMPLATE = 'delete_eventscheduletemplate';
export const DELETE_FEE = 'delete_fee';
export const DELETE_FEECALCULATIONRULE = 'delete_feecalculationrule';
export const DELETE_FEEDIVISION = 'delete_feedivision';
export const DELETE_FEEPAYMENTDUE = 'delete_feepaymentdue';
export const DELETE_FILE = 'delete_file';
export const DELETE_FOLDER = 'delete_folder';
export const DELETE_GENERALNOTICE = 'delete_generalnotice';
export const DELETE_GROUP = 'delete_group';
export const DELETE_GUARANTEE = 'delete_guarantee';
export const DELETE_INDUSTRYCODE = 'delete_industrycode';
export const DELETE_INSTITUTION = 'delete_institution';
export const DELETE_INSTITUTIONBANKACCOUNT = 'delete_institutionbankaccount';
export const DELETE_INSTITUTIONDISPLAYCUSTOMIZATION =
  'delete_institutiondisplaycustomization';
export const DELETE_INTERESTPERIODELECTION = 'delete_interestperiodelection';
export const DELETE_INTERVALSCHEDULE = 'delete_intervalschedule';
export const DELETE_INVITATION = 'delete_invitation';
export const DELETE_LOANSLICE = 'delete_loanslice';
export const DELETE_LOANTRANCHE = 'delete_loantranche';
export const DELETE_LOANTRANCHEADJUSTABLERATEDATA =
  'delete_loantrancheadjustableratedata';
export const DELETE_LOANTRANCHEADJUSTABLERATEPERIOD =
  'delete_loantrancheadjustablerateperiod';
export const DELETE_LOANTRANCHEAMORTIZATION = 'delete_loantrancheamortization';
export const DELETE_LOANTRANCHEAMORTIZATIONINTERESTPERIOD =
  'delete_loantrancheamortizationinterestperiod';
export const DELETE_LOANTRANCHEAMORTIZATIONPRINCIPALPAYMENT =
  'delete_loantrancheamortizationprincipalpayment';
export const DELETE_LOANTRANCHECOLLATERAL = 'delete_loantranchecollateral';
export const DELETE_LOANTRANCHEFLOATINGRATEDATA =
  'delete_loantranchefloatingratedata';
export const DELETE_LOANTRANCHEGUARANTOR = 'delete_loantrancheguarantor';
export const DELETE_LOANTRANCHEINSTITUTIONOWNERSHIPPORTION =
  'delete_loantrancheinstitutionownershipportion';
export const DELETE_LOANTRANCHEMULTIDRAWDATA =
  'delete_loantranchemultidrawdata';
export const DELETE_LOANTRANCHEOWNERSHIPPORTION =
  'delete_loantrancheownershipportion';
export const DELETE_LOANTRANCHEREVOLVERSWINGLINELOCDATA =
  'delete_loantrancherevolverswinglinelocdata';
export const DELETE_LOANTRANCHETIER = 'delete_loantranchetier';
export const DELETE_LOGENTRY = 'delete_logentry';
export const DELETE_NOTIFICATION = 'delete_notification';
export const DELETE_ORGANIZATIONDEALROLE = 'delete_organizationdealrole';
export const DELETE_ORGANIZATIONFOLDERROLE = 'delete_organizationfolderrole';
export const DELETE_PAYMENTDUE = 'delete_paymentdue';
export const DELETE_PAYMENTRECORD = 'delete_paymentrecord';
export const DELETE_PERIODICTASK = 'delete_periodictask';
export const DELETE_PERIODICTASKS = 'delete_periodictasks';
export const DELETE_PERMISSION = 'delete_permission';
export const DELETE_PREPAYMENTPENALTYRANGE = 'delete_prepaymentpenaltyrange';
export const DELETE_PROSPECT = 'delete_prospect';
export const DELETE_RESERVE = 'delete_reserve';
export const DELETE_REVISION = 'delete_revision';
export const DELETE_ROLE = 'delete_role';
export const DELETE_SESSION = 'delete_session';
export const DELETE_SITE = 'delete_site';
export const DELETE_SOLARSCHEDULE = 'delete_solarschedule';
export const DELETE_TIERFEEPERCENTAGE = 'delete_tierfeepercentage';
export const DELETE_TOTPDEVICE = 'delete_totpdevice';
export const DELETE_USER = 'delete_user';
export const DELETE_USERDEALROLE = 'delete_userdealrole';
export const DELETE_USERORGANIZATIONROLE = 'delete_userorganizationrole';
export const DELETE_USERPSWTRIED = 'delete_userpswtried';
export const DELETE_VERSION = 'delete_version';
export const DELETE_WIDGET = 'delete_widget';
export const PERFORM_BORROWER_INTEREST_SELECTIONS =
  'perform_borrower_interest_selections';
export const PERFORM_BORROWER_UPLOADS = 'perform_borrower_uploads';
export const SUPERUSER = 'superuser';
export const VIEW_ALTERNATEBASERATEBENCHMARK =
  'view_alternatebaseratebenchmark';
export const VIEW_APPLICABLE_MARGIN_CHANGES = 'view_applicable_margin_changes';
export const VIEW_APPLICABLEMARGIN = 'view_applicablemargin';
export const VIEW_APPLICABLEMARGINCHANGE = 'view_applicablemarginchange';
export const VIEW_BENCHMARK = 'view_benchmark';
export const VIEW_BENCHMARKOPTION = 'view_benchmarkoption';
export const VIEW_BENCHMARKRATE = 'view_benchmarkrate';
export const VIEW_BORROWER = 'view_borrower';
export const VIEW_BORROWERBANKACCOUNT = 'view_borrowerbankaccount';
export const VIEW_BORROWERS = 'view_borrowers';
export const VIEW_CALENDAR_INTERNAL = 'view_calendar_internal';
export const VIEW_CALENDAR_PUBLIC = 'view_calendar_public';
export const VIEW_CALENDARITEM = 'view_calendaritem';
export const VIEW_CALENDARITEMCATEGORY = 'view_calendaritemcategory';
export const VIEW_CLOCKEDSCHEDULE = 'view_clockedschedule';
export const VIEW_CLOSING = 'view_closing';
export const VIEW_COLENDERS = 'view_colenders';
export const VIEW_CONTENTTYPE = 'view_contenttype';
export const VIEW_COVENANT = 'view_covenant';
export const VIEW_CREDITRATING = 'view_creditrating';
export const VIEW_CRONTABSCHEDULE = 'view_crontabschedule';
export const VIEW_DATAROOM_PRIVATE = 'view_dataroom_private';
export const VIEW_DATAROOM_PUBLIC = 'view_dataroom_public';
export const VIEW_DEAL = 'view_deal';
export const VIEW_DEAL_DEALASSIGNEE = 'view_deal_dealassignee';
export const VIEW_DEAL_BORROWER = 'view_deal_borrower';
export const VIEW_DOCUMENT = 'view_document';
export const VIEW_DOCUMENTRECORDASSOCIATION = 'view_documentrecordassociation';
export const VIEW_DRAW_REQUESTS = 'view_draw_requests';
export const VIEW_DRAWREQUEST = 'view_drawrequest';
export const VIEW_ESCROWACCOUNT = 'view_escrowaccount';
export const VIEW_ESPCOMMANDLOG = 'view_espcommandlog';
export const VIEW_EVENTAUDIT = 'view_eventaudit';
export const VIEW_EVENTDUE = 'view_eventdue';
export const VIEW_EVENTSCHEDULE = 'view_eventschedule';
export const VIEW_EVENTSCHEDULETEMPLATE = 'view_eventscheduletemplate';
export const VIEW_FEE = 'view_fee';
export const VIEW_FEECALCULATIONRULE = 'view_feecalculationrule';
export const VIEW_FEEDIVISION = 'view_feedivision';
export const VIEW_FEEPAYMENTDUE = 'view_feepaymentdue';
export const VIEW_FEES = 'view_fees';
export const VIEW_FILE = 'view_file';
export const VIEW_FOLDER = 'view_folder';
export const VIEW_GENERALNOTICE = 'view_generalnotice';
export const VIEW_GROUP = 'view_group';
export const VIEW_GUARANTEE = 'view_guarantee';
export const VIEW_INDUSTRYCODE = 'view_industrycode';
export const VIEW_INSTITUTION = 'view_institution';
export const VIEW_INSTITUTIONBANKACCOUNT = 'view_institutionbankaccount';
export const VIEW_INSTITUTIONDISPLAYCUSTOMIZATION =
  'view_institutiondisplaycustomization';
export const VIEW_INTERESTPERIODELECTION = 'view_interestperiodelection';
export const VIEW_INTERVALSCHEDULE = 'view_intervalschedule';
export const VIEW_INVESTOR_BOOK = 'view_investor_book';
export const VIEW_INVITATION = 'view_invitation';
export const VIEW_LEAD_LENDERS = 'view_lead_lenders';
export const VIEW_LENDER_REGISTRY = 'view_lender_registry';
export const VIEW_LOAN_TRANCHES = 'view_loan_tranches';
export const VIEW_LOANSLICE = 'view_loanslice';
export const VIEW_LOANTRANCHE = 'view_loantranche';
export const VIEW_LOANTRANCHEADJUSTABLERATEDATA =
  'view_loantrancheadjustableratedata';
export const VIEW_LOANTRANCHEADJUSTABLERATEPERIOD =
  'view_loantrancheadjustablerateperiod';
export const VIEW_LOANTRANCHEAMORTIZATION = 'view_loantrancheamortization';
export const VIEW_LOANTRANCHEAMORTIZATIONINTERESTPERIOD =
  'view_loantrancheamortizationinterestperiod';
export const VIEW_LOANTRANCHEAMORTIZATIONPRINCIPALPAYMENT =
  'view_loantrancheamortizationprincipalpayment';
export const VIEW_LOANTRANCHECOLLATERAL = 'view_loantranchecollateral';
export const VIEW_LOANTRANCHEFLOATINGRATEDATA =
  'view_loantranchefloatingratedata';
export const VIEW_LOANTRANCHEGUARANTOR = 'view_loantrancheguarantor';
export const VIEW_LOANTRANCHEINSTITUTIONOWNERSHIPPORTION =
  'view_loantrancheinstitutionownershipportion';
export const VIEW_LOANTRANCHEMULTIDRAWDATA = 'view_loantranchemultidrawdata';
export const VIEW_LOANTRANCHEOWNERSHIPPORTION =
  'view_loantrancheownershipportion';
export const VIEW_LOANTRANCHEREVOLVERSWINGLINELOCDATA =
  'view_loantrancherevolverswinglinelocdata';
export const VIEW_LOANTRANCHETIER = 'view_loantranchetier';
export const VIEW_LOGENTRY = 'view_logentry';
export const VIEW_NOTIFICATION = 'view_notification';
export const VIEW_ORGANIZATIONDEALROLE = 'view_organizationdealrole';
export const VIEW_ORGANIZATIONFOLDERROLE = 'view_organizationfolderrole';
export const VIEW_OWN_TEAM = 'view_own_team';
export const VIEW_PAYMENTDUE = 'view_paymentdue';
export const VIEW_PAYMENTRECORD = 'view_paymentrecord';
export const VIEW_PAYMENTS = 'view_payments';
export const VIEW_PERIODICTASK = 'view_periodictask';
export const VIEW_PERIODICTASKS = 'view_periodictasks';
export const VIEW_PERMISSION = 'view_permission';
export const VIEW_PREPAYMENTPENALTYRANGE = 'view_prepaymentpenaltyrange';
export const VIEW_PROSPECT = 'view_prospect';
export const VIEW_RESERVE = 'view_reserve';
export const VIEW_REVISION = 'view_revision';
export const VIEW_ROLE = 'view_role';
export const VIEW_SERVICING_AGENTS = 'view_servicing_agents';
export const VIEW_SESSION = 'view_session';
export const VIEW_SITE = 'view_site';
export const VIEW_SOLARSCHEDULE = 'view_solarschedule';
export const VIEW_TERM_SHEET = 'view_term_sheet';
export const VIEW_HOME_BUTTON = 'view_home_button';
export const VIEW_TIERFEEPERCENTAGE = 'view_tierfeepercentage';
export const VIEW_TOTPDEVICE = 'view_totpdevice';
export const VIEW_USER = 'view_user';
export const VIEW_USERDEALROLE = 'view_userdealrole';
export const VIEW_USERORGANIZATIONROLE = 'view_userorganizationrole';
export const VIEW_USERPSWTRIED = 'view_userpswtried';
export const VIEW_VERSION = 'view_version';
export const VIEW_WIDGET = 'view_widget';
export const ADMINISTER_SBA_PPP_APPLICATION = 'administer_sba_ppp_application';
export const VIEW_SBA_PPP_APPLICATION = 'view_sba_ppp_application';

export const ADMINISTER_SBA_PPP_FORGIVENESS = 'administer_sba_ppp_forgiveness';
