import uuid from 'uuid/v4';

import {
  LoanTrancheMutationMethods,
  CovenantMutationMethods,
  CreditRatingMutationMethods,
  BenchmarkOptionMutationMethods,
  ApplicableMarginMutationMethods,
  AlternateBaseRateBenchmarkMutationMethods,
  LoanTrancheValidator,
  EscrowAccountMutationMethods,
  ReserveMutationMethods,
  LoanTrancheAmortizationMutationMethods,
  LoanTrancheFloatingRateDataMutationMethods,
  LoanTrancheRevolverSwinglineLOCDataMutationMethods,
  LoanTrancheAdjustableRateDataMutationMethods,
  LoanTrancheAdjustableRatePeriodMutationMethods,
  LoanTrancheCollateralMutationMethods,
  LoanTrancheGuarantorMutationMethods,
  LoanTrancheMultiDrawDataMutationMethods,
  FeeMutationMethods,
  FeeCalculationRuleMutationMethods,
} from '../../../graphql';
import Directory from '../../directory';
import { createMultiEntityForm } from '../../genericForms';
import { arrayToObject, invariant } from 'utils';
import {
  LoanTrancheAmortizationType,
  LoanTrancheFormQueryType,
  LoanTrancheFormType,
  AmortizationReaderRefType,
  AmortizationWithReaderDataType,
  AmortizationReaderType,
  LoanTrancheAmortizationPrincipalPaymentType,
} from 'types';

const initialAmortization: LoanTrancheAmortizationType = {
  id: uuid(),
  loantrancheamortizationprincipalpaymentSet: [],
  loantrancheamortizationinterestperiodSet: [],
  amortizationType: 'EQUAL_AMORTIZATION',
  paymentFrequency: 'MONTHLY',

  __typename: 'LoanTrancheAmortizationType',
};

const initialTranche: Partial<LoanTrancheFormQueryType> = {
  // @ts-ignore
  __typename: 'StubLoanTrancheInit',
  loanTranche: {
    id: uuid(),
    covenantSet: [],
    creditratingSet: [],
    loantrancheamortizationSet: [initialAmortization],
    loantranchecollateralSet: [],
    loantranchefloatingratedata: null,
    loantrancheguarantorSet: [],
    loantranchemultidrawdata: null,
    alternatebaseratebenchmarkSet: [],
    applicablemarginSet: [],
    benchmarkoptionSet: [],
    // dealId,
    escrowaccountSet: [],
    feeSet: [],
    guaranteeSet: [],
    prepaymentpenaltyrangeSet: [],
    reserveSet: [],
    name: '',
    collateralSummary: '',
    notes: '',
    totalCommitmentAmount: '',
    __typename: 'LoanTrancheType',
  },
  amortizationPrincipalPayments: null,
  id: uuid(),
};

const lifecycleMethods = {
  mutations: {
    ...CreditRatingMutationMethods,
    ...BenchmarkOptionMutationMethods,
    ...ApplicableMarginMutationMethods,
    ...AlternateBaseRateBenchmarkMutationMethods,
    ...LoanTrancheMutationMethods,
    ...CovenantMutationMethods,
    ...EscrowAccountMutationMethods,
    ...ReserveMutationMethods,
    ...LoanTrancheAmortizationMutationMethods,
    ...LoanTrancheFloatingRateDataMutationMethods,
    ...LoanTrancheRevolverSwinglineLOCDataMutationMethods,
    ...LoanTrancheAdjustableRateDataMutationMethods,
    ...LoanTrancheAdjustableRatePeriodMutationMethods,
    ...LoanTrancheCollateralMutationMethods,
    ...LoanTrancheGuarantorMutationMethods,
    ...LoanTrancheMultiDrawDataMutationMethods,
    ...FeeMutationMethods,
    ...FeeCalculationRuleMutationMethods,
  },
  validators: {
    LoanTranche: LoanTrancheValidator,
  },
};

/**
 * Accepts a LoanTrancheFormQueryType
 * Correctly applies readerData into the tranche
 */
function convertQueryType(e: LoanTrancheFormQueryType): LoanTrancheFormType {
  const { amortizationPrincipalPayments } = e;
  let { loanTranche } = e;

  invariant(loanTranche, 'Expected LoanTranche');

  if (loanTranche.deal && loanTranche.deal.id) {
    loanTranche = { ...loanTranche, dealId: loanTranche.deal.id } as any;
  }

  if (!loanTranche.loantranchefloatingratedata) {
    loanTranche = {
      ...loanTranche,
      loantranchefloatingratedata: {
        id: uuid(),
        __typename: 'LoanTrancheFloatingRateDataType',
      },
    };
  }

  if (!loanTranche.loantrancherevolverswinglinelocdata) {
    loanTranche = {
      ...loanTranche,
      loantrancherevolverswinglinelocdata: {
        id: uuid(),
        __typename: 'LoanTrancheRevolverSwinglineLOCDataType',
      },
    };
  } else {
    const chargedAgainstId = loanTranche.loantrancherevolverswinglinelocdata
      .loanTrancheChargedAgainst
      ? loanTranche.loantrancherevolverswinglinelocdata
          .loanTrancheChargedAgainst.id
      : null;
    loanTranche = {
      ...loanTranche,
      loantrancherevolverswinglinelocdata: {
        id: uuid(),
        __typename: 'LoanTrancheRevolverSwinglineLOCDataType',
        ...loanTranche.loantrancherevolverswinglinelocdata,
        loanTrancheChargedAgainstId: chargedAgainstId,
      } as any,
    };
  }

  if (!loanTranche.loantranchemultidrawdata) {
    loanTranche = {
      ...loanTranche,
      loantranchemultidrawdata: {
        id: uuid(),
        __typename: 'LoanTrancheMultiDrawDataType',
      },
    };
  }

  if (
    !(loanTranche.applicablemarginSet && loanTranche.applicablemarginSet.length)
  ) {
    loanTranche = {
      ...loanTranche,
      applicablemarginSet: [
        {
          id: uuid(),
          level: '1',
          __typename: 'ApplicableMarginType',
        },
      ],
    };
  } else {
    loanTranche = {
      ...loanTranche,
      applicablemarginSet: [...loanTranche.applicablemarginSet].sort(
        (a, b) => Number(a.level) - Number(b.level),
      ),
    };
  }

  if (!loanTranche.loantrancheguarantorSet) {
    loanTranche = {
      ...loanTranche,
      loantrancheguarantorSet: [],
    };
  }

  if (!loanTranche.loantranchecollateralSet) {
    loanTranche = {
      ...loanTranche,
      loantranchecollateralSet: [],
    };
  }

  if (!loanTranche.loantrancheadjustableratedata) {
    loanTranche = {
      ...loanTranche,
      loantrancheadjustableratedata: {
        id: uuid(),
        resetBenchmarkId: null,
        initialIndicativeBenchmarkId: null,
        treasuryRateSource: 'CONSTANT_MATURITY_TREASURY',
        calculationDayType: 'BUSINESS_DAY',
        noticeDayType: 'BUSINESS_DAY',
        loantrancheadjustablerateperiodSet: [],
        __typename: 'LoanTrancheAdjustableRateDataType',
      } as any,
    };
  } else {
    const { loantrancheadjustableratedata } = loanTranche;
    const resetBenchmarkId = loantrancheadjustableratedata.resetBenchmark
      ? loantrancheadjustableratedata.resetBenchmark.id
      : null;
    const initialIndicativeBenchmarkId = loantrancheadjustableratedata.initialIndicativeBenchmark
      ? loantrancheadjustableratedata.initialIndicativeBenchmark.id
      : null;

    loanTranche = {
      ...loanTranche,
      loantrancheadjustableratedata: {
        ...loantrancheadjustableratedata,
        resetBenchmark: undefined,
        initialIndicativeBenchmark: undefined,
        resetBenchmarkId,
        initialIndicativeBenchmarkId,
      } as any,
    };
  }

  let amortization: LoanTrancheAmortizationType | null | undefined = null;
  if (!loanTranche.loantrancheamortizationSet) {
    amortization = {
      id: uuid(),
      __typename: 'LoanTrancheAmortizationType',
      loantrancheamortizationprincipalpaymentSet: [],
      loantrancheamortizationinterestperiodSet: [],
    };
  } else if (loanTranche.loantrancheamortizationSet.length === 0) {
    amortization = {
      id: uuid(),
      __typename: 'LoanTrancheAmortizationType',
      loantrancheamortizationprincipalpaymentSet: [],
      loantrancheamortizationinterestperiodSet: [],
    };
  } else if (loanTranche.loantrancheamortizationSet.length > 1) {
    [amortization] = loanTranche.loantrancheamortizationSet;
  } else if (!amortization) {
    [amortization] = loanTranche.loantrancheamortizationSet;
  }

  const result: AmortizationReaderType | null | undefined =
    amortizationPrincipalPayments && amortizationPrincipalPayments.result
      ? amortizationPrincipalPayments.result
      : null;

  const freshPayments: ReadonlyArray<LoanTrancheAmortizationPrincipalPaymentType> =
    result && result.processedPrincipalPayments
      ? result.processedPrincipalPayments
      : [];

  // 1 Get the Reader Data
  const readerRefData: AmortizationReaderRefType = {
    ...result,
    loanTrancheAmortization: undefined,
    processedPrincipalPayments: undefined,
    // @ts-ignore
    __typename: 'Reference_StubAmortizationReaderData',
    id: 'NotUsed',
    principalMap: {
      // @ts-ignore
      ...arrayToObject(freshPayments, 'count'),
      __typename: 'PrincipalMap',
    },
  };

  // Reeader data is placed on the amortization object
  const amortizationWithReader: AmortizationWithReaderDataType = {
    ...amortization,
    readerData: readerRefData,
    isLeaf: true,
  };

  const dealId = loanTranche.deal ? loanTranche.deal.id : null;

  const loantrancheamortizationSet: ReadonlyArray<AmortizationWithReaderDataType> = [
    amortizationWithReader,
  ];
  const updatedTranche: LoanTrancheFormType = {
    ...loanTranche,
    dealId,
    loantrancheamortizationSet,
  } as any;

  return updatedTranche;
}

const loanTrancheRedux = createMultiEntityForm<
  LoanTrancheFormType,
  LoanTrancheFormQueryType
>(Directory.LoanTrancheKey, initialTranche as any, lifecycleMethods, {
  enableAutoSave: false,
  dataConverter: convertQueryType,
});

export default loanTrancheRedux;
