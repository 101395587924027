import * as React from 'react';
import uuid from 'uuid/v4';
import { InterestScheduleColumns } from '../shared';
import { DisplayTable, Money, PercentageInput } from 'components';
import { LoanTrancheAmortizationInterestPeriodType } from 'types';

const EmptyRow = {
  id: uuid(),
  endPeriod: '',
  interestRate: '',
  startPeriod: '',
  __typename: 'LoanTrancheAmortizationInterestPeriodType',
};

export type InterestScheduleRowProps = {
  className?: string;
  index: number;
  interestRow: LoanTrancheAmortizationInterestPeriodType;
  onRateChange: (
    value: string | null | undefined,
    fieldId: string,
    index: number,
  ) => void;
};

const DecimalMoney = (props: { value?: string | null | undefined }) => {
  if (!props.value) return <>"-"</>;
  return <Money decimalPlaces={2} value={props.value} />;
};

class InterestScheduleRow extends React.Component<InterestScheduleRowProps> {
  static stRenderMoneyCell = (value: string | null | undefined) => {
    const hideValue = !value || value === '-';
    return (
      <div className="AmortizationTable__MoneyCell">
        {!hideValue && <span>$</span>}
        {hideValue ? '-' : <DecimalMoney value={value} />}
      </div>
    );
  };

  static defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    interestRow: EmptyRow,
  };

  handleRateChange = (e: React.SyntheticEvent<any>) => {
    this.props.onRateChange(
      e.currentTarget.value,
      'interestRate',
      this.props.index,
    );
  };

  render() {
    const { interestRow, className } = this.props;
    return (
      <DisplayTable.TR className={className} key={interestRow.effectiveDate}>
        <DisplayTable.TD className={InterestScheduleColumns.periods.className}>
          {interestRow.startPeriod} -{interestRow.endPeriod}
        </DisplayTable.TD>
        <DisplayTable.TD
          className={InterestScheduleColumns.startDate.className}
        >
          {interestRow.effectiveDate}
        </DisplayTable.TD>
        <DisplayTable.TD
          className={InterestScheduleColumns.interestRate.className}
        >
          <PercentageInput
            id={`${interestRow.startPeriod}_interestRate`}
            name={`${interestRow.startPeriod}_interestRate`}
            onChange={this.handleRateChange}
            value={interestRow.interestRate}
          />
        </DisplayTable.TD>
        <DisplayTable.TD
          className={InterestScheduleColumns.totalPayment.className}
        >
          Unknown
        </DisplayTable.TD>
      </DisplayTable.TR>
    );
  }
}

export default InterestScheduleRow;
