import { mutationBuilder } from './builders';
import {
  CreateSbaPppRoundTwoSecondDrawLenderApplicationMutationDefinition as CreateMutationDefinition,
  SetSbaPppRoundTwoSecondDrawLenderApplicationMutationDefinition as SetMutationDefinition,
} from './definitions';
import {
  SbaPppRoundTwoSecondDrawLenderApplicationType,
  SbaPppRoundTwoSecondDrawLenderApplicationInput,
} from 'types';

const SbaPppRoundTwoSecondDrawLenderApplicationMutations = {
  createSbaPppRoundTwoSecondDrawLenderApplication: mutationBuilder<
    SbaPppRoundTwoSecondDrawLenderApplicationInput,
    SbaPppRoundTwoSecondDrawLenderApplicationType
  >(CreateMutationDefinition, {
    build: mutate => mutationData =>
      mutate({
        variables: {
          sbaPppRoundTwoSecondDrawLenderApplicationData: mutationData,
        },
      }),
  }),
  setSbaPppRoundTwoSecondDrawLenderApplication: mutationBuilder<
    SbaPppRoundTwoSecondDrawLenderApplicationInput,
    SbaPppRoundTwoSecondDrawLenderApplicationType
  >(SetMutationDefinition, {
    build: mutate => mutationData =>
      mutate({
        variables: {
          sbaPppRoundTwoSecondDrawLenderApplicationData: mutationData,
        },
      }),
  }),
};

export default SbaPppRoundTwoSecondDrawLenderApplicationMutations;
