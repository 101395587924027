import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/link-batch-http';
import { onError } from '@apollo/link-error';

import { invariant, isDebug } from '../utils';
import { REACT_APP_API_URL } from '../url';
import possibleTypes from './possibleTypes.json';
import { isBatchingEnabled } from './batchToggle';
import AuthLink from './authLink';

invariant(
  possibleTypes && (possibleTypes as any) !== '',
  'Introspection Query Result data is required for client caching; schema update needs to be ran',
);

let _client: {
  client: ApolloClient<any> | null | undefined;
  token: string | null;
} = null;

export function purgeAuthenticatedClient() {
  if (_client) {
    _client.client.clearStore();
    _client = null;
  }
}

export function getAuthenticatedClient() {
  invariant(_client, 'Client not instantiated!');
  return _client.client;
}

/**
 * Setup Authenticated GraphQL client
 * @param {*} enableBatching
 */
export function createAuthenticatedClient(
  raiseAlert?: (message: string, title?: string) => void,
  authenticationToken?: string,
): ApolloClient<any> {
  // if (_client) {
  //  return _client.client;
  // }
  invariant(authenticationToken, 'AuthenticationToken is required!');
  // eslint-disable-next-line
  console.warn('Creating GQL Client');
  const enableBatching = isBatchingEnabled();
  invariant(
    enableBatching !== null,
    'The batching flag must be set prior to client creation',
  );
  const authenticatedHttpSettings = {
    uri: REACT_APP_API_URL,
    credentials: 'include',
  };

  let httpLink: HttpLink | BatchHttpLink;
  if (enableBatching === true) {
    httpLink = new BatchHttpLink({
      ...authenticatedHttpSettings,
      batchMax: 30,
    });
  } else {
    httpLink = new HttpLink(authenticatedHttpSettings);
  }

  /**
   * Inspect responses for 401/403
   */
  const errorLink = onError(({ graphQLErrors, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(ge => {
        if (isDebug() && raiseAlert) {
          raiseAlert(
            `Operation: ${operation.operationName} - Message: ${ge.message}, Location: ${ge.locations}, Path: ${ge.path}`,
            'GraphQL Error',
          );
        }
        // eslint-disable-next-line
        console.error(
          `OP: ${operation.operationName} - [GraphQL error]: Message: ${ge.message}, Location: ${ge.locations}, Path: ${ge.path}`,
        );
      });
    }
  });

  const link = ApolloLink.from([
    errorLink,
    AuthLink(authenticationToken),
    httpLink,
  ]);

  /**
   * AuthenticatedClient is the main backend client.  All GraphQL
   * operations with the exception of file uploads and the public_api
   * are handled here
   */
  _client = {
    client: new ApolloClient({
      connectToDevTools: true,
      link,
      cache: new InMemoryCache({
        possibleTypes,
      }),
    }),
    token: authenticationToken,
  };

  return _client.client;
}
