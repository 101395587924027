import * as React from 'react';
import { BorrowerInvitationForm } from '../borrowerInvitationForm';
import { LenderInvitationForm } from '../lenderInvitationForm';
import { ServicingAgentInvitationForm } from '../servicingAgentInvitationForm';
import { InvitationTypes } from '../constants';
import { QueryResult } from 'lsgql';
import { SelectOptionType, MyUserType, OrganizationDealRoleType } from 'types';

type Props = {
  currentTab: string;
  dealOrganizationDealRoles: Array<OrganizationDealRoleType>;
  isOpen: boolean;
  onClose: () => void;
  permissions: {
    [key: string]: boolean;
  };
  fetchDealOrganizationDealRoles: () => Promise<QueryResult<any>>;
  user: MyUserType;
};

type State = {
  invitationType: string;
};

class InvitationModal extends React.Component<Props, State> {
  options: {
    [key: string]: SelectOptionType;
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      invitationType: InvitationTypes.MY_TEAM,
    };

    this.options = {
      myteam: {
        text: 'Team Member',
        value: InvitationTypes.MY_TEAM,
      },
      colenders: {
        text: 'Co-Lender',
        value: InvitationTypes.CO_LENDERS,
      },
      borrower: {
        text: 'Borrower',
        value: InvitationTypes.BORROWER,
      },
      servicingAgent: {
        text: 'Servicing Agent',
        value: InvitationTypes.SERVICING_AGENTS,
      },
      leadLender: {
        text: 'Lead Lender',
        value: InvitationTypes.LEAD_LENDER,
      },
    };
  }

  componentDidMount() {
    // adapt default invitation type to user's current people page
    const defaultInvitationType = this.props.currentTab;
    if (defaultInvitationType)
      this.handleInvitationTypeChange(defaultInvitationType);
  }

  componentDidUpdate(prevProps: Props) {
    // adapt default invitation type to user's current people page
    if (prevProps.currentTab === this.props.currentTab) return;

    const defaultInvitationType = this.props.currentTab;
    if (defaultInvitationType)
      this.handleInvitationTypeChange(defaultInvitationType);
  }

  getOptions = () => {
    const {
      permissions: {
        canAdministerColenders,
        canAdministerBorrowers,
        canAdministerServicingAgents,
        canAdministerLeadLenders,
      },
    } = this.props;
    const options = [this.options.myteam];
    if (canAdministerBorrowers) options.push(this.options.borrower);
    if (canAdministerColenders) options.push(this.options.colenders);
    if (canAdministerServicingAgents) options.push(this.options.servicingAgent);
    if (canAdministerLeadLenders) options.push(this.options.leadLender);
    return options;
  };

  handleInvitationTypeChange = (value?: string | null | undefined) => {
    if (value !== null && value !== undefined)
      this.setState({ invitationType: value });
  };

  userDealRole = (): OrganizationDealRoleType | null | undefined => {
    const { user, dealOrganizationDealRoles } = this.props;
    return (
      dealOrganizationDealRoles &&
      dealOrganizationDealRoles.find(
        e =>
          e.institution &&
          user.institution &&
          e.institution.id === user.institution.id,
      )
    );
  };

  renderOwnTeamForm = () => {
    const {
      permissions: { canAdministerOwnTeam },
    } = this.props;

    if (!canAdministerOwnTeam) return null;

    const userDealRole = this.userDealRole();

    switch (userDealRole && userDealRole.dealJob) {
      case 'LEAD_LENDER':
        return this.renderInvitationForm(LenderInvitationForm, { lead: true });
      case 'BORROWER':
        return this.renderInvitationForm(BorrowerInvitationForm);
      case 'CO_LENDER':
        return this.renderInvitationForm(LenderInvitationForm);
      case 'SERVICE_PROVIDER':
        return this.renderInvitationForm(ServicingAgentInvitationForm);
      default:
        return null;
    }
  };

  renderInvitationForm = (
    FormComponent: any,
    customProps?: { [propName: string]: any },
  ) => {
    const { invitationType } = this.state;
    const {
      isOpen,
      onClose,
      user,
      fetchDealOrganizationDealRoles,
    } = this.props;

    return (
      <FormComponent
        currentUser={user}
        fetchDealOrganizationDealRoles={fetchDealOrganizationDealRoles}
        invitationTypeSelected={invitationType}
        isOpen={isOpen}
        onClose={onClose}
        onInvitationTypeChange={this.handleInvitationTypeChange}
        options={this.getOptions()}
        {...customProps}
      />
    );
  };

  render() {
    const { invitationType } = this.state;
    const {
      permissions: {
        canAdministerColenders,
        canAdministerLeadLenders,
        canAdministerServicingAgents,
        canAdministerBorrowers,
      },
    } = this.props;

    switch (invitationType) {
      case InvitationTypes.BORROWER:
        return canAdministerBorrowers
          ? this.renderInvitationForm(BorrowerInvitationForm)
          : this.renderOwnTeamForm();
      case InvitationTypes.CO_LENDERS:
        return canAdministerColenders
          ? this.renderInvitationForm(LenderInvitationForm)
          : this.renderOwnTeamForm();
      case InvitationTypes.LEAD_LENDER:
        return canAdministerLeadLenders
          ? this.renderInvitationForm(LenderInvitationForm, { lead: true })
          : this.renderOwnTeamForm();
      case InvitationTypes.SERVICING_AGENTS:
        return canAdministerServicingAgents
          ? this.renderInvitationForm(ServicingAgentInvitationForm)
          : this.renderOwnTeamForm();
      default:
        return this.renderOwnTeamForm();
    }
  }
}

export default InvitationModal;
