import uuid from 'uuid/v4';
import { SbaPppLenderClosingWorkflowQuestionsMutationMethods } from '../../../graphql/mutations';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import { SbaPppLenderClosingWorkflowQuestionsType } from 'types';

const initialSbaPppLenderClosingWorkflowQuestions: SbaPppLenderClosingWorkflowQuestionsType = {
  id: uuid(),
  __typename: 'SbaPppLenderClosingWorkflowQuestionsType',
};

const lifecycle = {
  mutations: SbaPppLenderClosingWorkflowQuestionsMutationMethods,
  validators: {},
};

export default createForm<
  SbaPppLenderClosingWorkflowQuestionsType,
  SbaPppLenderClosingWorkflowQuestionsType
>(
  Directory.SbaPppLenderClosingWorkflowQuestionsKey,
  initialSbaPppLenderClosingWorkflowQuestions,
  lifecycle,
);
