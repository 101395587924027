import { MutationMethod } from '../types';
import { mutationBuilder } from './builders';
import { MutationExecutor } from './builders/mutationBuilder';
import { CreateEventScheduleMutationDefinition } from './definitions';
import { EventScheduleInput, EventScheduleType } from 'types';

const createEventSchedule: MutationMethod<
  EventScheduleInput,
  EventScheduleType
> = mutationBuilder<EventScheduleInput, EventScheduleType>(
  CreateEventScheduleMutationDefinition,
  {
    build: (mutate: MutationExecutor<EventScheduleType>) => (
      eventScheduleData: EventScheduleInput,
    ) =>
      mutate({
        variables: {
          eventScheduleData,
        },
      }),
  },
);

const EventScheduleMutationMethods = {
  createEventSchedule,
};

export default EventScheduleMutationMethods;
