import { mutationBuilder } from './builders';
import {
  CreateSbaPppLoanFinancialsMutationDefinition,
  SetSbaPppLoanFinancialsMutationDefinition,
} from './definitions';
import { SbaPppLoanFinancialsInput, SbaPppLoanFinancialsType } from 'types';

const PublicSbaPppLoanFinancialsMutationMethods = {
  createSbaPppLoanFinancials: mutationBuilder<
    SbaPppLoanFinancialsInput,
    SbaPppLoanFinancialsType
  >(CreateSbaPppLoanFinancialsMutationDefinition, {
    build: mutate => sbaPppLoanFinancialsData =>
      mutate({ variables: { sbaPppLoanFinancialsData } }),
  }),
  setSbaPppLoanFinancials: mutationBuilder<
    SbaPppLoanFinancialsInput,
    SbaPppLoanFinancialsType
  >(SetSbaPppLoanFinancialsMutationDefinition, {
    build: mutate => sbaPppLoanFinancialsData =>
      mutate({ variables: { sbaPppLoanFinancialsData } }),
  }),
};

export default PublicSbaPppLoanFinancialsMutationMethods;
