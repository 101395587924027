import { invariant } from '../../../utils';
import { BaseType } from '../../../types/baseType';
import { ID } from '../../../types/primitives';
import {
  DropTypeValues,
  DataRoomBase,
  DragSourceEntity,
  DropTargetEntity,
  DropType,
  DataroomProps,
  FolderWithDate,
} from '../types';

import { thenMiddleware, alertsMiddleware } from './dragDropMiddleware';

/**
 * This method should be invoked when a component
 * in the DataRoom tree begins dragging by the user.
 * The resulting DragSourceEntity is passed to DropTargets
 *
 * @param {*} entity - A Component should set this property (e.g. Folder or File)
 * @param {*} files - Files dropped into the browser window should set this property
 */
export function createDragSourceEntity(
  entity?: DataRoomBase | null,
  files?: Array<any> | null,
): DragSourceEntity {
  invariant(
    entity || files,
    'createDragSourceEntity requires an entity or files',
  );
  const res: DragSourceEntity = {
    entity,
    files,
  };

  return res;
}

/**
 * Invoked when a component is the *direct* target of a Drop operation
 * We expect that the Dragged component invoked `createDragSourceEntity`
 * to create the entity to be processed here.
 *
 * @param {*} dragSourceEntity - DragSourceEntity created by the dragged component or dropped file
 * @param {*} dropTargetId - The object id (Folder or File) of the *direct* target of the drop operation
 * @param {*} dropType - Type of entity dropped, specified by the DropType type
 */
export function createDropTargetEntity(
  dragSourceEntity: DragSourceEntity,
  dropTargetId: string,
  dropType: DropType,
): DropTargetEntity {
  invariant(dragSourceEntity, 'createDropTarget dragSourceEntity was undef');
  invariant(dropTargetId, 'createDropTarget requires a dropTargetId');
  invariant(dropType, 'createDropTarget requires a dropType');

  const res: DropTargetEntity = {
    entity: dragSourceEntity,
    dropTargetId,
    dropType,
  };
  return res;
}

/**
 * Determine if a DragSource can be dropped on a DropTarget.  The
 * `canDrop` method of DropTarget should invoke this method.
 *
 * @param {*} dropTargetEntityId - The id of the object rendered by the component (e.g. Folder)
 * @param {*} monitor - Monitor object provided by React-dnd
 */
export function handleCanDrop(
  dropTargetEntityId: ID, // DataRoomBase,
  dragSourceType: string | symbol,
  dragSourceEntity: DragSourceEntity,
): boolean {
  invariant(dropTargetEntityId, 'handleCanDrop requires a dropTargetEntity');
  invariant(dragSourceType, 'handleCanDrop requires a dragSourceType');
  invariant(dragSourceEntity, 'handleCanDrop requires a dragSourceEntity');

  if (dragSourceType === DropTypeValues.__NATIVE_FILE__) {
    // Native Files, meaning files dropped into the browser window,
    // are always droppable
    return true;
  }

  if (dragSourceType === DropTypeValues.FileType) {
    // a pre-existing file component, check that it is not already in this folder
    const file: any | null | undefined = dragSourceEntity.entity;

    return !!(file && dropTargetEntityId !== file.parent.id);
  }

  if (dragSourceType === DropTypeValues.FolderType) {
    // a pre-existing folder, it cannot be made into it's own child
    const folder:
      | BaseType
      | null
      | undefined = (dragSourceEntity.entity as any) as FolderWithDate;
    return !!(
      folder &&
      dropTargetEntityId !== folder.id &&
      (folder as any).parent &&
      (folder as any).parent.id &&
      dropTargetEntityId !== (folder as any).parent.id
    );
  }

  return true;
}

/**
 * All mutations resulting from a Drop operation are handled by the Dataroom Component.
 * When an object is dropped, the DropTarget creates a DropTargetEntity and returns said object
 * from the `drop` method.  This will propagate the DropTargetEntity up the tree to the Dataroom.
 * The Dataroom is responsible for invoking this method, to determine and invoke the appropriate
 * GraphGL mutation for the Drop operation
 *
 * @param {*} props Mutation methods
 * @param {*} dropTargetEntity The DropTargetEntity created by the DropTarget, possible propagated through the tree
 */
export function handleDrop(
  props: DataroomProps,
  dropTargetEntity: DropTargetEntity,
): Promise<any> {
  invariant(props, 'handleDrop props was undef');
  invariant(dropTargetEntity, 'dropTargetEntity was undefined');

  const processAction = (action: Function, ...args: Array<any>) => {
    invariant(action, 'action was not defined');

    return action(...args)
      .then(res => {
        if (res && (res.errors || (res[0] && res[0].errors))) throw res;
        thenMiddleware.forEach(nextAction =>
          nextAction(props, dropTargetEntity),
        );
        return Promise.resolve();
      })
      .catch(() => {
        alertsMiddleware(props, dropTargetEntity);
        return Promise.resolve();
      });
  };

  if (dropTargetEntity.dropType === DropTypeValues.__NATIVE_FILE__) {
    // a file dropped into the browser window
    const newFiles: Array<any> = dropTargetEntity.entity.files as any; // monitor.getItem().files;

    invariant(
      newFiles && newFiles.length,
      'A file upload drop was detected, but the files collection was null or empty',
    );

    return processAction(
      props.createDocuments,
      newFiles,
      dropTargetEntity.dropTargetId,
    );
  }

  if (!dropTargetEntity.entity) {
    throw new Error('DropTargetEntity.entity was null');
  }

  const payload = {
    ...dropTargetEntity.entity.entity,
    parentId: dropTargetEntity.dropTargetId,
  };

  if (dropTargetEntity.dropType === DropTypeValues.FileType) {
    // a pre-existing file was moved between folders
    return processAction(props.setDocument, payload as any);
    // return;
  }

  if (dropTargetEntity.dropType === DropTypeValues.FolderType) {
    // a pre-existing folder was moved to another parent folder
    return processAction(props.setFolder, payload as any);
  }
  return Promise.resolve();
}
/* eslint-enable */
