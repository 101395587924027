import * as React from 'react';
import isMouseOverElement from './isMouseOverElement';

type hoverMethodState = {
  isHovered: boolean;
};
type hoverMethodsThisType = {
  handleHoverLeave: () => void;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
  handleMouseMove: (e: React.MouseEvent) => void;
  rowElement: any;
} & React.Component<any, hoverMethodState>;

export function handleHoverLeave(this: hoverMethodsThisType) {
  this.setState(prev => {
    if (!prev.isHovered) return prev;
    window.removeEventListener('mousemove', this.handleMouseMove as any);
    return { isHovered: false };
  });
}
export function handleMouseEnter(this: hoverMethodsThisType) {
  // event must be permitted to bubble for React-DnD
  this.setState(prev => {
    if (prev.isHovered) return prev;
    window.addEventListener('mousemove', this.handleMouseMove as any);
    return { isHovered: true };
  });
}
export function handleMouseLeave(this: hoverMethodsThisType) {
  // event must be permitted to bubble for React-DnD
  this.handleHoverLeave();
}
export function handleMouseMove(
  this: hoverMethodsThisType,
  e: React.MouseEvent,
) {
  // event must be permitted to bubble for React-DnD
  if (!this.state.isHovered) return;
  if (!this.rowElement?.current) return;
  if (!isMouseOverElement(this.rowElement.current, e)) this.handleHoverLeave();
}

const hoverMethods: {
  //  [key: string]: (() => void) | ((e: SyntheticMouseEvent<*>) => void),
  handleHoverLeave: () => void;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
  handleMouseMove: (e: React.MouseEvent) => void;
} = {
  handleHoverLeave,
  handleMouseEnter,
  handleMouseLeave,
  handleMouseMove,
};

export default hoverMethods;
