import * as React from 'react';

import './DataGridSummaryCell.scss';

/**
 * A summary cell used for aggregations in the data grid.
 */

type Props = {
  /**
   * The value of the summary cell.
   */
  className: string;
  value: React.ReactNode;
};

const DataGridSummaryCell = ({ value, className = '' }: Props) => (
  <div className={`DataGridSummaryCell ${className}`}>{value}</div>
);

export default DataGridSummaryCell;
