import { mutationBuilder } from './builders';
import {
  CreatePrepaymentPenaltyRangeMutationDefinition,
  SetPrepaymentPenaltyRangeMutationDefinition,
  DeletePrepaymentPenaltyRangeMutationDefinition,
} from './definitions';
import {
  PrepaymentPenaltyRangeType,
  PrepaymentPenaltyRangeInput,
  ID,
} from 'types';

const PrepaymentPenaltyRangeMutationMethods = {
  createPrepaymentPenaltyRange: mutationBuilder<
    PrepaymentPenaltyRangeInput,
    PrepaymentPenaltyRangeType
  >(CreatePrepaymentPenaltyRangeMutationDefinition, {
    build: mutate => prepaymentPenaltyRangeData =>
      mutate({ variables: { prepaymentPenaltyRangeData } }),
  }),
  setPrepaymentPenaltyRange: mutationBuilder<
    PrepaymentPenaltyRangeInput,
    PrepaymentPenaltyRangeType
  >(SetPrepaymentPenaltyRangeMutationDefinition, {
    build: mutate => prepaymentPenaltyRangeData =>
      mutate({ variables: { prepaymentPenaltyRangeData } }),
  }),
  deletePrepaymentPenaltyRange: mutationBuilder<ID, void>(
    DeletePrepaymentPenaltyRangeMutationDefinition,
    {
      build: mutate => (id: string) => mutate({ variables: { id } }),
    },
  ),
};

export default PrepaymentPenaltyRangeMutationMethods;
