import * as React from 'react';
import { useLocation } from 'react-router-dom';
import PortfolioMenuItem from './menuItems/PortfolioMenuItem';
import ReportsMenuItem from './menuItems/ReportsMenuItem';

function MainContextMenu() {
  const location = useLocation();

  return (
    <>
      <PortfolioMenuItem location={location.pathname} />
      <ReportsMenuItem location={location.pathname} />
    </>
  );
}

export default MainContextMenu;
