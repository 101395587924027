import { compose, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import ForgivenessRequests from './ForgivenessRequests';
import { WorkflowType } from 'types';
import { withLoadingIndicator } from 'components';
import { generateTable } from 'lsredux/generate';
import { ForgivenessDealsQuery, DealFavoriteMutations } from 'lsgql';
import { ReduxDirectory } from 'lsredux';
import { withToJS } from 'utils';

const { changeSortField } = generateTable.methods('main_deals');

const mapStateToProps: (state: any) => { rawData: any } = state => ({
  rawData: state.getIn([ReduxDirectory.PersistedKey, ReduxDirectory.MainKey]),
});

export default compose(
  connect(mapStateToProps, {
    changeSortField,
  }),
  withStateHandlers({
    workflow: WorkflowType.SBA_PPP_FORGIVENESS_WORKFLOW,
  }),
  withToJS,
  ForgivenessDealsQuery,
  withLoadingIndicator('loading'),
  DealFavoriteMutations,
)(ForgivenessRequests);
