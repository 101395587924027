import * as React from 'react';
import { useQuery, gql } from '@apollo/client';
import {
  FormReferenceSelect,
  FormFieldProps,
} from '@loanstreet-usa/design-system';
import { invariant } from 'utils';

export type QueryResult = { text: string; value: string | null | undefined };
export type FormReferenceSelectProps = FormFieldProps & {
  allowEmpty?: boolean;
  resultFilter?: (queryResults: Array<QueryResult>) => Array<QueryResult>;
  typeName: string;
};

const referenceDataQuery = gql`
  query ReferenceDataQuery($typeName: String!) {
    referenceData: __type(name: $typeName) {
      enumValues {
        name
        description
      }
    }
  }
`;

/**
 * A wrapper around ReferenceSelect component that can issue a GraphQL query to load values
 * of the enum specified by `typeName` and passes those to ReferenceSelect.
 */
function ReferenceSelectWithDataWrapper(
  props: FormReferenceSelectProps,
): React.ReactElement {
  const { typeName, ...rest } = props;
  invariant(typeName, 'typeName is required');

  const { data, loading } = useQuery(referenceDataQuery, {
    variables: { typeName },
    displayName: 'ReferenceDataQuery',
  });

  const referenceData = (data as any)?.referenceData;
  const isLoading = !data || loading || !referenceData;

  const queryResult = !isLoading
    ? referenceData.enumValues.map(({ name, description }) => ({
        text: description,
        value: name,
      }))
    : [];

  return (
    <FormReferenceSelect
      loading={isLoading}
      queryResult={queryResult}
      {...rest}
    />
  );
}

export default ReferenceSelectWithDataWrapper;
