import React from 'react';
import noop from 'lodash/noop';
import { Form } from 'components';
import {
  FormFieldsType,
  SbaPppLoanFinancialsType,
  SbaPppLoanFinancialsSchedCEmployees,
} from 'types';

import './RoundTwoLoanFinancials.scss';

const MAX_SBA_LOAN = 10000000;

const disabledReadableProps = {
  onChange: noop,
  disabled: true,
  disabledReadable: true,
};

const fields: FormFieldsType = {
  averageMonthlyPayroll: {
    id: 'averageMonthlyPayroll',
    propertyName: 'averageMonthlyPayroll',
    fieldName: 'Average Monthly Payroll',
    width: '158px',
    required: true,
  },
  eidlAmount: {
    id: 'eidlAmount',
    propertyName: 'eidlAmount',
    fieldName: 'EIDL Amount (Minus Advance)',
    width: '158px',
    helpText: 'Do not include any EIDL advance',
    required: true,
  },
  eidlLoanNumber: {
    id: 'eidlLoanNumber',
    propertyName: 'eidlLoanNumber',
    fieldName: 'EIDL Loan Number',
    width: '158px',
    helpText: 'Required if submitting an EIDL Amount',
    required: true,
  },
  hasEidlInfo: {
    id: 'hasEidlInfo',
    propertyName: 'hasEidlInfo',
    label:
      'Refinancing an Economic Injury Disaster Loan (EIDL) made between 1/31/20 - 4/3/20?',
  },
  loanAmount: {
    id: 'loanAmount',
    propertyName: 'loanAmount',
    fieldName: 'Loan Request Amount',
    width: '158px',
    disabled: true,
    helpText: '2.5x Avg Monthly Payroll + EIDL',
  },
  numberOfJobs: {
    id: 'numberOfJobs',
    propertyName: 'numberOfJobs',
    fieldName: 'Number of Employees',
    width: '158px',
    required: true,
  },
  purposeOfLoan: {
    id: 'purposeOfLoan',
    propertyName: 'purposeOfLoan',
    fieldName: 'Purpose of the Loan (check all that apply):',
    required: true,
    options: [
      { label: 'Payroll Costs', id: 'PAYROLL' },
      { label: 'Rent / Mortgage Interest', id: 'RENT_MORTGAGE_INTEREST' },
      { label: 'Utilities', id: 'UTILITIES' },
      {
        label: 'Covered Operations Expenditures',
        id: 'COVERED_OPERATIONS_EXPENDITURES',
      },
      { label: 'Covered Property Damage', id: 'COVERED_PROPERTY_DAMAGE' },
      { label: 'Covered Supplier Costs', id: 'COVERED_SUPPLIER_COSTS' },
      {
        label: 'Covered Worker Protection Expenditures',
        id: 'COVERED_WORKER_PROTECTION_EXPENDITURES',
      },
      { label: 'Other', id: 'OTHER' },
    ],
  },
  otherReason: {
    id: 'otherReason',
    propertyName: 'otherReason',
    fieldName: 'Please explain other purpose',
    width: '332px',
  },
  firstDrawSbaLoanNumber: {
    id: 'firstDrawSbaLoanNumber',
    propertyName: 'firstDrawSbaLoanNumber',
    fieldName: 'PPP First Draw SBA Loan Number',
    width: '332px',
    required: true,
  },
  firstDrawSbaLoanAmount: {
    id: 'firstDrawSbaLoanAmount',
    propertyName: 'firstDrawSbaLoanAmount',
    fieldName: 'PPP First Draw SBA Loan Amount',
    width: '332px',
    required: true,
  },
  // Schedule C
  hasSchedC: {
    id: 'hasSchedC',
    propertyName: 'hasSchedC',
    label: 'I want to apply as a Schedule C Filer using gross income',
  },
  schedCEmployees: {
    id: 'schedCEmployees',
    propertyName: 'schedCEmployees',
    fieldName: 'Select an option:',
    options: [
      {
        label: 'I do not have employees (other than owners)',
        id: 'NO_EMPLOYEES',
      },
      { label: 'I have employees (other than owners)', id: 'YES_EMPLOYEES' },
    ],
    required: true,
  },
  schedCTaxYear: {
    id: 'schedCTaxYear',
    propertyName: 'schedCTaxYear',
    fieldName: 'Tax Year',
    options: [
      { label: '2019', id: 'YEAR_2019' },
      { label: '2020', id: 'YEAR_2020' },
    ],
    required: true,
  },
  schedCTotalGrossIncome: {
    id: 'schedCTotalGrossIncome',
    propertyName: 'schedCTotalGrossIncome',
    fieldName: 'Total Amount of Gross Income',
    width: '158px',
    helpText: 'IRS Form 1040, Schedule C, Line 7',
    required: true,
  },
  schedCLineA: {
    id: 'schedCLineA',
    propertyName: 'schedCLineA',
    width: '158px',
  },
  schedCLineB: {
    id: 'schedCLineB',
    propertyName: 'schedCLineB',
    width: '158px',
  },
  schedCLineC: {
    id: 'schedCLineC',
    propertyName: 'schedCLineC',
    width: '158px',
  },
  schedCLineTotal: {
    id: 'schedCLineTotal',
    propertyName: 'schedCLineTotal',
    width: '158px',
  },
};

const SbaPppLoanFinancials = 'SbaPppLoanFinancials';

function RoundTwoLoanFinancials(props: any) {
  const { data: rawData, isSecondDraw, institutionType, naicsCode } = props;
  const data = rawData as SbaPppLoanFinancialsType;

  const hasEIDL = data.hasEidlInfo || Boolean(Number(data.eidlAmount));
  const isSchedCEligible: boolean = [
    'SOLE_PROPRIETOR',
    'SELF_EMPLOYED',
    'IND_CONT',
    'SINGLE_MEMBER_LLC',
    'QUALIFIED_JOINT_VENTURE',
  ].includes(institutionType);
  const mustUseSchedC: boolean = [
    'SINGLE_MEMBER_LLC',
    'QUALIFIED_JOINT_VENTURE',
  ].includes(institutionType);

  // Require Schedule-C based on certain Business Types
  let hasSchedCProps = { onChange: props.mutateProperty, helpText: undefined };
  if (mustUseSchedC) {
    if (data.hasSchedC === undefined || data.hasSchedC === null) {
      props.mutateProperty(true, 'hasSchedC');
    }
    hasSchedCProps = {
      ...disabledReadableProps,
      helpText:
        'Your selected business type requires you to apply as a Schedule C Filer',
    };
  }

  // Reset fields if Checkbox is removed or unchecked
  if ((institutionType && !isSchedCEligible) || data.hasSchedC == false) {
    props.mutateProperty(isSchedCEligible ? data.hasSchedC : null, 'hasSchedC');
    props.mutateProperty(null, 'schedCEmployees');
    props.mutateProperty(null, 'schedCLineA');
    props.mutateProperty(null, 'schedCLineB');
    props.mutateProperty('0', 'schedCLineC');
    props.mutateProperty(null, 'schedCLineTotal');
    props.mutateProperty(null, 'schedCTaxYear');
    props.mutateProperty(null, 'schedCTotalGrossIncome');
  }

  const averageMonthlyPayroll = Number(data.averageMonthlyPayroll) || 0;
  const scheduleCTotal = number(data.schedCLineTotal) || 0;
  const eidlAmount = data.hasEidlInfo ? number(data.eidlAmount) || 0 : 0;
  const multiplier =
    isSecondDraw && String(naicsCode ?? '').startsWith('72') ? 3.5 : 2.5;
  const partialAmount =
    (data.hasSchedC ? scheduleCTotal : averageMonthlyPayroll) * multiplier;
  const loanAmount = number(partialAmount, 3) + eidlAmount;
  const displayedLoanAmount =
    loanAmount === 0
      ? '-'
      : number(Math.min(loanAmount, MAX_SBA_LOAN)).toString();

  const getLoanAmountHelpText = (): string => {
    const schedCText: string =
      data.schedCEmployees == 'YES_EMPLOYEES' ? 'Total' : 'B';

    if (isSecondDraw) {
      return data.schedCEmployees
        ? `${schedCText} x 2.5 (3.5 for NAICS 72)`
        : '2.5 Avg Monthly Payroll (3.5 for NAICS 72)';
    } else {
      return data.schedCEmployees
        ? `${schedCText} x 2.5${hasEIDL ? ' + EIDL' : ''}`
        : `2.5 x Average Monthly Payroll${hasEIDL ? ' + EIDL' : ''}`;
    }
  };

  const getNumberOfJobsHelpText = (): string => {
    if (isSecondDraw) {
      return data.hasSchedC
        ? 'Including owners and affiliates, if applicable; may not exceed 300 unless "per location" exception applies'
        : 'Including affiliates, if applicable; May not exceed 300';
    } else {
      return data.hasSchedC ? 'Including owners' : undefined;
    }
  };

  function handleSchedCEmployeesChange(
    value: SbaPppLoanFinancialsSchedCEmployees | undefined,
    fieldId: string,
  ) {
    props.mutateProperty(value, fieldId);
    // Clear the fields as you toggle (calculated fields will be applied in <ScheduleCFields />)
    props.mutateProperty(null, 'schedCLineA');
    props.mutateProperty(null, 'schedCLineB');
    props.mutateProperty('0', 'schedCLineC');
    props.mutateProperty(null, 'schedCLineTotal');
  }

  return (
    <div className="RoundTwoFinancials">
      {isSchedCEligible &&
        Form.FieldRenderer(
          Form.Checkbox,
          { ...fields.hasSchedC, ...hasSchedCProps },
          props,
          SbaPppLoanFinancials,
          data.id,
        )}
      {isSchedCEligible &&
        data.hasSchedC &&
        Form.FieldRenderer(
          Form.Radio,
          { ...fields.schedCEmployees, onChange: handleSchedCEmployeesChange },
          props,
          SbaPppLoanFinancials,
          data.id,
        )}
      {data.schedCEmployees ? (
        <ScheduleCFields {...props} />
      ) : (
        Form.FieldRenderer(
          Form.Money,
          {
            ...fields.averageMonthlyPayroll,
            onChange: props.mutateProperty,
          },
          props,
          SbaPppLoanFinancials,
          data.id,
        )
      )}
      {!isSecondDraw && (
        <>
          {Form.FieldRenderer(
            Form.Checkbox,
            { ...fields.hasEidlInfo, onChange: props.mutateProperty },
            props,
            SbaPppLoanFinancials,
            data.id,
          )}
          {data.hasEidlInfo && (
            <Form.Group className="baseline">
              {Form.FieldRenderer(
                Form.Money,
                {
                  ...fields.eidlAmount,
                  onChange: props.mutateProperty,
                },
                props,
                SbaPppLoanFinancials,
                data.id,
              )}
              {Form.FieldRenderer(
                Form.Numeric,
                {
                  ...fields.eidlLoanNumber,
                  onChange: props.mutateProperty,
                },
                props,
                SbaPppLoanFinancials,
                data.id,
              )}
            </Form.Group>
          )}
        </>
      )}
      {Form.FieldRenderer(
        Form.Money,
        {
          ...fields.loanAmount,
          value: displayedLoanAmount,
          helpText: getLoanAmountHelpText(),
          error:
            loanAmount > MAX_SBA_LOAN && 'Maximum Loan Amount is $10,000,000',
          ...disabledReadableProps,
        },
        props,
        SbaPppLoanFinancials,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Numeric,
        {
          ...fields.numberOfJobs,
          onChange: props.mutateProperty,
          allowLeadingZeroes: false,
          helpText: getNumberOfJobsHelpText(),
        },
        props,
        SbaPppLoanFinancials,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.CheckboxGroup,
        {
          ...fields.purposeOfLoan,
          onChange: (value: any, fieldId: string) => {
            // Clear `otherReason` if the user unselcts that option
            if (!value?.includes('OTHER')) {
              props.mutateProperty(null, 'otherReason');
            }
            props.mutateProperty(value, fieldId);
          },
        },
        props,
        SbaPppLoanFinancials,
        data.id,
      )}
      {data.purposeOfLoan?.includes('OTHER') &&
        Form.FieldRenderer(
          Form.Input,
          {
            ...fields.otherReason,
            onChange: props.mutateProperty,
            required: true,
          },
          props,
          SbaPppLoanFinancials,
          data.id,
        )}
      {isSecondDraw &&
        Form.FieldRenderer(
          Form.Input,
          {
            ...fields.firstDrawSbaLoanNumber,
            onChange: props.mutateProperty,
          },
          props,
          SbaPppLoanFinancials,
          data.id,
        )}
      {isSecondDraw &&
        Form.FieldRenderer(
          Form.Money,
          {
            ...fields.firstDrawSbaLoanAmount,
            onChange: props.mutateProperty,
          },
          props,
          SbaPppLoanFinancials,
          data.id,
        )}
    </div>
  );
}

function ScheduleCFields(props: any) {
  const { data: rawData } = props;
  const data = rawData as SbaPppLoanFinancialsType;

  const hasSchedCEmployees: boolean = data.schedCEmployees == 'YES_EMPLOYEES';

  const getSchedCLineBValue = (input?: string): string =>
    input ? String(number(Math.min(Number(input || 0) / 12, 8333.33))) : null;

  // Set the calculated fields if applicable
  if (data.schedCTotalGrossIncome) {
    if (hasSchedCEmployees) {
      props.mutateProperty(
        getSchedCLineBValue(data.schedCLineA),
        'schedCLineB',
      );
      if (data.schedCLineB && data.schedCLineC) {
        props.mutateProperty(
          String(Number(data.schedCLineB || 0) + Number(data.schedCLineC || 0)),
          'schedCLineTotal',
        );
      } else {
        props.mutateProperty(null, 'schedCLineTotal');
      }
    } else {
      props.mutateProperty(data.schedCTotalGrossIncome, 'schedCLineA');
      props.mutateProperty(
        getSchedCLineBValue(data.schedCTotalGrossIncome),
        'schedCLineB',
      );
      props.mutateProperty(
        data.schedCLineB ? String(Number(data.schedCLineB || 0)) : null,
        'schedCLineTotal',
      );
    }
  }

  const schedCLineAProps = hasSchedCEmployees
    ? { onChange: props.mutateProperty }
    : {
        value: data.schedCTotalGrossIncome,
        ...disabledReadableProps,
      };

  return (
    <>
      <Form.Group>
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.schedCTotalGrossIncome,
            onChange: props.mutateProperty,
          },
          props,
          SbaPppLoanFinancials,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Radio,
          {
            ...fields.schedCTaxYear,
            onChange: props.mutateProperty,
          },
          props,
          SbaPppLoanFinancials,
          data.id,
        )}
      </Form.Group>
      <Form.Group className="SchedC_InlineText">
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.schedCLineA,
            ...schedCLineAProps,
          },
          props,
          SbaPppLoanFinancials,
          data.id,
        )}
        {hasSchedCEmployees ? (
          <Form.Text
            className="requiredField"
            value="A. Gross Income minus Sched. C expenses (lines 14, 19, 26)"
          />
        ) : (
          <Form.Text value="A. Gross Income" />
        )}
      </Form.Group>
      <Form.Group className="SchedC_InlineText">
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.schedCLineB,
            ...disabledReadableProps,
          },
          props,
          SbaPppLoanFinancials,
          data.id,
        )}
        <Form.Text value="B. Divide A by 12 (cannot be more than $8,333.33)" />
      </Form.Group>
      {hasSchedCEmployees && (
        <Form.Group className="SchedC_InlineText">
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.schedCLineC,
              onChange: props.mutateProperty,
            },
            props,
            SbaPppLoanFinancials,
            data.id,
          )}
          <Form.Text
            className="requiredField"
            value="C. Average Monthly Payroll (not including owners)"
          />
        </Form.Group>
      )}
      {hasSchedCEmployees && (
        <Form.Group className="SchedC_InlineText">
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.schedCLineTotal,
              ...disabledReadableProps,
            },
            props,
            SbaPppLoanFinancials,
            data.id,
          )}
          <Form.Text value="Total B + C" />
        </Form.Group>
      )}
    </>
  );
}

export default RoundTwoLoanFinancials;

// SBA HACK: Super Hack! Dealing with HALF_EVEN rounding on frontend...
import { Big } from 'big.js';

function number(value?: string | number, decimalPlaces = 2): number {
  if (!value) {
    return 0;
  }

  class FormattedNumber {
    private value: Big;

    constructor(input: string | number) {
      Big.RM = 2;
      this.value = new Big(input);
    }

    public round(decimalPlaces = 2): string {
      return this.value.toFixed(decimalPlaces);
    }
  }

  const formattedNumber = new FormattedNumber(value);
  return Number(formattedNumber.round(decimalPlaces));
}
