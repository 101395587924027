import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { invariant } from 'utils';

type NonNullableReactNode =
  | boolean
  | number
  | string
  | React.ReactNode
  | React.ReactPortal
  | Iterable<NonNullableReactNode | null | undefined>;

type Props = LinkProps & {
  children: NonNullableReactNode;
  className?: string;
  id?: string;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onFocus?: (e: React.FocusEvent<any>) => void;
  onMouseEnter?: (e: React.MouseEvent<any>) => void;
  onMouseLeave?: (e: React.MouseEvent<any>) => void;
  tabIndex?: number;
};

const ToError = 'props.to is required';
const expectChildren = 'RouteTo expects props.children';

/**
 * Invoke routing to a path within our application.  All user
 * originated routing actions should be handled by this component
 */
function RouteTo(props: Props) {
  const { children, ...rest } = props;
  invariant(rest.to, ToError);
  invariant(children, expectChildren);

  return (
    <Link to={rest.to} {...rest}>
      {children}
    </Link>
  );
}

export default RouteTo;
