import { ObjectBaseTypeNames } from './backend';

/* eslint-disable import/prefer-default-export */

/**
 * The backend uses a few different names for what are essentially the same types.
 * The frontend has no mechanism for knowing these are the same type, so here
 * we provide a map of aliases for identifying those types
 */

export const TypeAliases: {
  [key in ObjectBaseTypeNames]?: string;
} = {
  DealInstitution: 'Institution',
  MyUser: 'User',
};
