import { DealType } from 'types';

type Props = {
  deal: DealType;
};

function SBASecondDrawButton(props: Props) {
  const { deal } = props;

  if (!deal.isRoundTwoSecondDrawEligible) {
    return null;
  }

  return null;
}

export default SBASecondDrawButton;
