import * as React from 'react';
import { ApolloClient, useQuery, FetchPolicy } from '@apollo/client';
import { DocumentNode, FetchMethod } from '../../types';
import { standardPollingInterval } from '../../shared';
import PollingMediator from './PollingMediator';
import { invariant, isWholeNumber } from 'utils';

interface QueryVariables {
  id?: string;
  [propName: string]: any;
}

interface QueryResult {
  id?: string;
  [propName: string]: any;
}

interface StandardArgs<
  R extends QueryResult,
  TVariables extends QueryVariables
> {
  results: (
    data: {
      [key: string]: any | null | undefined;
    },
    props: any,
  ) => R | null | undefined;
  skip?: (props: any) => boolean;
  typename: string;
  variables?: (props: any) => TVariables;

  addFetchMethod?: boolean;
  disableCache?: boolean;
  includePollingMethods?: boolean;
  notifyOnNetworkStatusChange?: boolean;
}

/**
 * A standard query will not error or redirect
 * to a 404 if no results were found.  This is
 * the appropriate query type for most use-cases
 * @param {*} query
 * @param {*} optionsObj
 * @param {*} propsObj
 * @param {*} skip
 */
function standardQueryBuilder<
  R extends QueryResult,
  TData extends {},
  TVariables extends QueryVariables
>(
  query: DocumentNode,
  args: StandardArgs<R, TVariables>,
  client?: ApolloClient<any>,
) {
  invariant(query, 'GraphQL query must be provided');
  invariant(args, 'formQueryBuilder.args must be provided');
  invariant(args.results, 'Results method must be provided');
  invariant(args.typename, 'args.typename must be provided');

  const displayName = `${args.typename}StandardQuery`;

  const fetchPolicy: FetchPolicy = args.disableCache
    ? 'network-only'
    : undefined;

  return function StandardQueryBuilderHoC(
    Wrapped: React.ComponentType<any>,
  ): React.ComponentType<any> {
    invariant(Wrapped, 'A component must be provided');

    const Composed =
      args && args.includePollingMethods
        ? PollingMediator(args.typename)(Wrapped)
        : Wrapped;
    return function QueryBuilder(props: any) {
      let refetchMethod:
        | {
            [key: string]: FetchMethod<any>;
          }
        | null
        | undefined;

      let pollingMethods:
        | {
            [key: string]: () => void;
          }
        | null
        | undefined;

      let paginationMethod:
        | {
            [key: string]: (cursor: number, dataObjectName: string) => void;
          }
        | undefined
        | null;

      function shouldSkip() {
        if (args.variables) {
          const vars = args.variables(props);
          if (vars && vars.id) {
            if (!isWholeNumber(vars.id)) {
              return true;
            }
          }
        }

        return args.skip !== undefined ? args.skip(props) : false;
      }

      const skip = shouldSkip();
      const vars = skip || !args.variables ? undefined : args.variables(props);
      const isPaginatedQuery = vars?.first || vars?.last;

      const {
        loading,
        error,
        data,
        startPolling,
        stopPolling,
        refetch,
        fetchMore,
      } = useQuery(query, {
        variables: vars,
        skip,
        displayName,
        notifyOnNetworkStatusChange:
          args.notifyOnNetworkStatusChange || isPaginatedQuery,
        fetchPolicy,
        client,
      });

      const queryResult =
        data && args.results ? args.results(data, props) : null;

      /* eslint-disable prefer-destructuring */
      // result returns loading === true if skip was true
      const isLoading = (!skip && loading) || Boolean((props as any).loading);

      if (args.addFetchMethod && !refetchMethod) {
        refetchMethod = {
          [`fetch${args.typename}`]: refetch,
        };
      }

      if (args.includePollingMethods && startPolling && !pollingMethods) {
        pollingMethods = {
          [`startPolling${args.typename}`]: () =>
            startPolling(standardPollingInterval),
          [`stopPolling${args.typename}`]: stopPolling,
        };
      }

      if (isPaginatedQuery) {
        paginationMethod = {
          [`fetchMore${args.typename}`]: (
            cursor: number,
            dataObjectName: string,
            variables?: any,
          ) => {
            fetchMore({
              variables: {
                cursor,
                ...variables,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const previousConnection = previousResult[dataObjectName];
                const newConnection = fetchMoreResult[dataObjectName];
                const newEdges = newConnection.edges;

                return newEdges.length
                  ? {
                      [dataObjectName]: {
                        ...newConnection,
                        edges: [...previousConnection.edges, ...newEdges],
                      },
                    }
                  : previousResult;
              },
            });
          },
        };
      }

      return (
        <Composed
          {...props}
          {...refetchMethod}
          {...queryResult}
          {...pollingMethods}
          {...paginationMethod}
          error={error}
          loading={isLoading}
        />
      );
    };
  };
}

export default standardQueryBuilder;
