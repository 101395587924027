import * as React from 'react';
import { SortBy } from '../types';
import { haltBubble } from 'utils';
import { Icon, FontAwesomeIconTypes } from 'components';

type Props = {
  onToggleSort: (sortBy: SortBy) => void;
  sortBy: SortBy;
};

const columns = ['name', 'modified', 'access'];

class DataroomHeader extends React.PureComponent<Props> {
  handleToggleSort = (columnName: string) => (e: React.SyntheticEvent<any>) => {
    const { sortBy, onToggleSort } = this.props;
    haltBubble(e);
    if (sortBy.column === columnName) {
      let reverse;
      switch (sortBy.reverse) {
        case false:
          reverse = true;
          break;
        case true:
          reverse = 'none';
          break;
        case 'none':
          reverse = false;
          break;
        default:
          reverse = false;
      }
      onToggleSort({ column: columnName, reverse });
    } else {
      this.props.onToggleSort({ column: columnName, reverse: false });
    }
  };

  render() {
    const { sortBy } = this.props;
    return (
      <div className="dataroomHeader">
        {columns.map(name => {
          const isSelected = name === sortBy.column;
          let icon: FontAwesomeIconTypes = 'sort';
          if (isSelected) {
            switch (sortBy.reverse) {
              case false:
                icon = 'sort-up';
                break;
              case true:
                icon = 'sort-down';
                break;
              default:
                break;
            }
          }

          return (
            <div
              className={`dataroomHeader__Column dataroomHeader__Column--${
                isSelected ? 'Selected' : 'NotSelected'
              }`}
              key={name}
              onClick={this.handleToggleSort(name)}
            >
              {name.toUpperCase()}
              <Icon className="dataroomHeader__Icon" icon={icon} />
            </div>
          );
        })}
      </div>
    );
  }
}

export default DataroomHeader;
