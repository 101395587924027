import { gql } from '@apollo/client';
import { DealFields, DocumentShallowFields } from '../fragments';
import { DocumentNode } from '../types';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { DealType, DocumentType } from 'types';

const TermSheetQueryDefinition: DocumentNode = gql`
  query TermSheetQuery($id: ID) {
    deal(id: $id) {
      ...DealFields
      termSheet {
        ...DocumentShallowFields
      }
    }
  }
  ${DealFields}
  ${DocumentShallowFields}
`;

const TermSheetQuery = standardQueryBuilder(TermSheetQueryDefinition, {
  typename: 'TermSheet',
  addFetchMethod: true,
  skip: ({ dealId }: { dealId: string | null | undefined }) => !dealId,
  variables: ({ dealId }: { dealId: string | null | undefined }) => ({
    id: dealId,
  }),
  results: (data: { deal: DealType | null | undefined }) => {
    const { deal } = data;

    if (!deal) {
      return {
        termSheet: null,
      } as { termSheet: DocumentType | null | undefined };
    }
    return {
      termSheet: deal.termSheet,
    } as { termSheet: DocumentType | null | undefined };
  },
});

export default TermSheetQuery;
