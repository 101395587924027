import store from './reducer';
import { setAlert } from './actions/alerts';
import { AlertType } from 'types';

/**
 * Helper function that allows alerts to be dispatched
 * from functions and components that lack a redux connection
 * @param {*} message
 * @param {*} type
 */
function raiseAlert(message: string, type: AlertType, title?: string) {
  store.dispatch(setAlert(message, type, title));
}

export default raiseAlert;
