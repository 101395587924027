import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { Map } from 'immutable';
/**
 * Correctly import react-pdf so that the Pdf Worker is correctly
 * registered and loaded.  Otherwise rendering performance is
 * severely negatively impacted.
 *
 * To know whether the Worker is correctly loaded, in your browser
 * open the console and then load a PDF using this component.  If the
 * worker failed you will see the following message:
 *
 * Warning: Setting up fake worker
 *
 */
import {
  Button,
  EnterTransition,
  DragScroll,
  LoadingIndicator,
  DragScrollChildProps,
} from 'components';

import './PdfViewer.scss';

export type PdfViewProps = {
  changePage: (arg0: { id: string; pageIndex: number }) => void;
  changeRotations: (arg0: {
    id: string;
    rotations: Record<string, any>;
  }) => void;
  changeScale: (arg0: { id: string; scale: number }) => void;
  documentExpiringUrl: string | null | undefined;
  id: string;
  pageIndex: number;
  renderError?: () => React.ReactNode;
  rotations: Map<number, number>;
  scale: number;
};
type State = {
  renderAnnotations: boolean;
  requestHeaders: any;
  selectable: boolean;
  totalPages: number;
};

const maxScale = 3.0;
const scaleFactor = 0.25;

class PdfViewer extends React.Component<PdfViewProps, State> {
  selectButton: React.ComponentClass | null | undefined;

  constructor(props: PdfViewProps) {
    super(props);
    this.state = {
      totalPages: 0,
      selectable: false,
      renderAnnotations: false,
      requestHeaders: PdfViewer.getRequestHeaders(props),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: PdfViewProps) {
    // vague way of making it draggable automatically when zooming in past 100%. in reality 100% doesnt always determine if the doc is large enough to want that, maybe handle in future with getPosPosition etc
    if (this.props.scale <= 1 && nextProps.scale > 1 && this.state.selectable)
      this.setState({ selectable: false });

    if (this.props.documentExpiringUrl !== nextProps.documentExpiringUrl) {
      this.setState({
        totalPages: 0,
        requestHeaders: PdfViewer.getRequestHeaders(nextProps),
      });
    }
  }

  shouldComponentUpdate(nextProps: PdfViewProps, nextState: State): boolean {
    return (
      this.props.documentExpiringUrl !== nextProps.documentExpiringUrl ||
      this.props.pageIndex !== nextProps.pageIndex ||
      this.props.scale !== nextProps.scale ||
      !Object.is(this.props.rotations, nextProps.rotations) ||
      !Object.is(nextState, this.state)
    );
  }

  static getRequestHeaders = (props: PdfViewProps) => {
    const { documentExpiringUrl } = props;

    return { url: documentExpiringUrl, httpHeaders: {} };
  };

  getRotationValue = (): number =>
    this.props.rotations[this.props.pageIndex] || 0;

  handleRotateRight = () => {
    const current = this.getRotationValue();
    const next = current >= 270 ? 0 : current + 90;
    this.props.changeRotations({
      id: this.props.id,
      rotations: { ...this.props.rotations, [this.props.pageIndex]: next },
    });
  };

  handleRotateLeft = () => {
    const current = this.getRotationValue();
    const next = current <= 0 ? 270 : current - 90;
    this.props.changeRotations({
      id: this.props.id,
      rotations: { ...this.props.rotations, [this.props.pageIndex]: next },
    });
  };

  handleItemClick = ({ pageNumber }: { pageNumber: number }) =>
    this.props.changePage({
      id: this.props.id,
      pageIndex: pageNumber - 1,
    });

  handleDocumentLoad = ({ numPages }: { numPages: number }) => {
    this.setState({
      totalPages: numPages,
    });
  };

  handleZoomIn = () =>
    this.props.changeScale({
      id: this.props.id,
      scale: Math.min(maxScale, (this.props.scale || 1.5) + scaleFactor),
    });

  handleZoomOut = () =>
    this.props.changeScale({
      id: this.props.id,
      scale: Math.max(scaleFactor, (this.props.scale || 1.5) - scaleFactor),
    });

  handleNextPage = () =>
    this.props.changePage({
      id: this.props.id,
      pageIndex: Math.min(this.state.totalPages - 1, this.props.pageIndex + 1),
    });

  handlePreviousPage = () =>
    this.props.changePage({
      id: this.props.id,
      pageIndex: Math.max(0, this.props.pageIndex - 1),
    });

  handleSelectClick = () => {
    this.setState(previousState => ({
      selectable: !previousState.selectable,
    }));
  };

  handleTypePageNum = (e: React.SyntheticEvent<any>) => {
    const newPageIndex = Number(e.currentTarget.value) - 1;
    if (
      Number(e.currentTarget.value) &&
      newPageIndex >= 0 &&
      newPageIndex < this.state.totalPages
    ) {
      this.props.changePage({
        id: this.props.id,
        pageIndex: newPageIndex,
      });
    }
  };

  renderDocument = () => {
    const { totalPages, selectable, requestHeaders } = this.state;
    const { renderError, ...rest } = this.props;
    return (
      <Document
        error={renderError ? renderError() : undefined}
        file={requestHeaders}
        loading={<LoadingIndicator absolute />}
        onItemClick={this.handleItemClick}
        onLoadSuccess={this.handleDocumentLoad}
      >
        {totalPages > 0 && (
          <Page
            {...rest}
            {...this.state}
            renderTextLayer={selectable}
            rotate={this.getRotationValue()}
          />
        )}
      </Document>
    );
  };

  renderWithMouseEvents = (mouseProps: DragScrollChildProps) => (
    <div {...mouseProps}>{this.renderDocument()}</div>
  );

  render() {
    const { scale, pageIndex } = this.props;
    const { totalPages, selectable } = this.state;

    return (
      <div id="PdfViewer">
        <div className="pdfViewerControlsTop">
          <EnterTransition
            classNames="pdfUiFadein"
            inVar={totalPages}
            timeout={300}
          >
            <div className="zoom">
              <Icon
                className={scale === maxScale ? 'disabled' : ''}
                name="zoom"
                onClick={this.handleZoomIn}
                title="Press up to zoom in"
              />

              <span>{`${Math.round(scale * 100)}%`}</span>

              <Icon
                className={scale === 0.25 ? 'disabled' : ''}
                name="zoom out"
                onClick={this.handleZoomOut}
                title="Press down to zoom out"
              />
            </div>
          </EnterTransition>
          <EnterTransition
            classNames="pdfUiFadein"
            inVar={totalPages}
            timeout={300}
          >
            <div className="selectOuter">
              <Button
                className={selectable ? 'selectable' : ''}
                label="Click to toggle text selection"
                onClick={this.handleSelectClick}
              />
            </div>
          </EnterTransition>
          <EnterTransition
            classNames="pdfUiFadein"
            inVar={totalPages}
            timeout={300}
          >
            <div className="rotate" key="rotate">
              <Icon name="undo" onClick={this.handleRotateLeft} />
              <Icon name="repeat" onClick={this.handleRotateRight} />
            </div>
          </EnterTransition>
        </div>
        {selectable ? (
          <div className="PdfViewer_DragScroll">{this.renderDocument()}</div>
        ) : (
          <DragScroll className="PdfViewer_DragScroll draggable">
            {this.renderWithMouseEvents}
          </DragScroll>
        )}
        <EnterTransition
          classNames="pdfUiFadein"
          inVar={totalPages}
          timeout={300}
        >
          <div className="pdfViewerControlsBottom">
            <Icon
              className={pageIndex <= 0 ? 'disabled' : ''}
              name="triangle left"
              onClick={this.handlePreviousPage}
              title="Press left to decrement page"
            />
            <span>
              <input onChange={this.handleTypePageNum} value={pageIndex + 1} />
            </span>
            <span className="of">of</span>
            <span>{`${totalPages}`}</span>

            <Icon
              className={pageIndex >= totalPages - 1 ? 'disabled' : ''}
              name="triangle right"
              onClick={this.handleNextPage}
              title="Press right to increment page"
            />
          </div>
        </EnterTransition>
      </div>
    );
  }
}

export default PdfViewer;
