import uuid from 'uuid/v4';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import { SbaPppForgivenessWorkflowMutationMethods } from '../../../graphql';
import { DealType } from 'types';

const initialDeal: DealType = {
  id: uuid(),
  __typename: 'DealType',
};

const lifecycleMethods = {
  mutations: SbaPppForgivenessWorkflowMutationMethods,
  validators: {},
};

const sbaPppForgivnessWorkflowFormRedux = createForm<DealType, DealType>(
  Directory.DealKey,
  initialDeal,
  lifecycleMethods,
);

export default sbaPppForgivnessWorkflowFormRedux;
