import formQueryBuilder from './queryBuilders/formQueryBuilder';
import { DocumentQueryDefinition } from './definitions';
import { DocumentType } from 'types';

export const DocumentFormQuery = formQueryBuilder(DocumentQueryDefinition, {
  typename: 'Document',
  skip: (props: { documentId: string | null | undefined }) => !props.documentId,
  variables: (props: { documentId: string | null | undefined }) => {
    const { documentId } = props;
    return { id: documentId };
  },
  results: (data: { document: DocumentType | null | undefined }) => {
    const { document } = data;
    return document;
  },
});

export default DocumentFormQuery;
