import uuid from 'uuid/v4';
import { LoanFundingMutationMethods } from '../../../graphql';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import { LoanFundingType } from 'types';

const initialLoanFunding: LoanFundingType = {
  id: uuid(),
  __typename: 'LoanFundingType',
} as any;

const lifecycle = {
  mutations: LoanFundingMutationMethods,
  validators: {},
};

export default createForm<LoanFundingType, LoanFundingType>(
  Directory.LoanFundingKey,
  initialLoanFunding,
  lifecycle,
);
