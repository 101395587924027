import { gql } from '@apollo/client';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { EtranReadyCheckType } from 'types';

type OwnProps = {
  id: string;
};
const EtranReadyCheckQuery = standardQueryBuilder(
  gql`
    query EtranReadyCheckQuery($id: ID) {
      etranReadyCheck(id: $id) {
        ok
        items {
          id
          ok
          slug
          details
        }
      }
    }
  `,
  {
    typename: 'EtranReadyCheck',
    includePollingMethods: true,
    variables: (props: OwnProps) => {
      return props;
    },
    disableCache: true,
    results: (data: {
      etranReadyCheck: EtranReadyCheckType | null | undefined;
    }) => {
      const { etranReadyCheck } = data;
      return { etranReadyCheck };
    },
  },
);
export default EtranReadyCheckQuery;
