import { SendLenderApplicationMutationDefinition } from './definitions';

import { mutationBuilder } from './builders';
import { ID } from 'types';

type SendLenderApplicationInput = {
  workflowId: ID;
  returnUrl: string;
};

type SendLenderApplicationType = {
  ok: boolean;
  redirectUrl: string;
};

const sendLenderApplication = mutationBuilder<
  SendLenderApplicationInput,
  SendLenderApplicationType
>(SendLenderApplicationMutationDefinition, {
  build: mutate => (data: SendLenderApplicationInput) => {
    return mutate({
      variables: {
        workflowId: data.workflowId,
        returnUrl: data.returnUrl,
      },
      refetchQueries: ['DealQuery'],
    });
  },
});

export default sendLenderApplication;
