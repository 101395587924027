/* eslint-disable no-console */
import uuid from 'uuid/v4';
import { DealCategory } from 'types';
import { withProps } from 'recompose';
import raiseAlert from 'lsredux/raiseAlert';
import { QueryResult } from 'lsgql';
import isEmpty from 'lodash/isEmpty';

export function buildMockedFormQueryRefetch(
  category: DealCategory,
  onboardingMethod: (id: string, props: any) => Promise<boolean>,
  routeTo: (id: string) => string,
  successMessage?: string,
) {
  return withProps((props: any) => ({
    formQueryRefetch: async ({ id, validationResults }) => {
      console.log('formQueryRefetch Received', validationResults);
      console.log(`isEmpty: ${isEmpty(validationResults)}`);
      // SBA-HACK

      const res: QueryResult<any> = {
        data: {
          id: uuid(),
          category: category as any,
          sbappploanfinancials: {
            id: uuid(),
            category: category as any,
            __typename: 'SbaPppLoanFinancialsType',
          },
          sbapppapplicantownershipSet: [
            {
              id: uuid(),
              category: category as any,
              __typename: 'SbaPppApplicantOwnershipType',
            },
          ],
          sbapppinstitution: {
            id: uuid(),
            category: category as any,
            __typename: 'SbaPppInstitutionType',
          },
          __typename: 'SbaPppLoanApplicationType' as any,
        },
      };

      const hasErrors = !isEmpty(validationResults);

      if (!hasErrors) {
        console.log('No validation errors found; Attempt Onboarding');

        // Here I need to be aware of validation errors
        const success = await onboardingMethod(id, props);

        if (success === true) {
          console.log('Onboarding returned True, Attempt redirect');
          raiseAlert(successMessage, 'success');
          props.history.push(routeTo(id));
        } else {
          raiseAlert('An error occured during onboarding', 'error');
        }
      }
      return res;
    },
  }));
}
