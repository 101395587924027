// eslint-disable-next-line
import * as React from 'react';

/* eslint-disable */
import { DropTarget, DragSource, DropTargetMonitor } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import HTML5Backend from 'react-dnd-html5-backend';

import { ContextPropTypes, DropTypeValues } from '../../types';

import { FileWithDate, DragSourceEntity, DropType } from '../../types';
import { invariant } from '../../../../utils';
import {
  createDropTargetEntity,
  createDragSourceEntity,
  handleCanDrop,
} from '../../utils/dragDropMethods';

import { DataRoomRowProps } from '../../dataroomRow/DataroomRow';

/* eslint-enable */

const shallowArg = { shallow: true };

type Props = DataRoomRowProps<FileWithDate> & {
  leaveDragHover: () => void;
};

const FileDropTarget = DropTarget(
  Object.keys(DropTypeValues),
  {
    drop(props: Props, monitor: DropTargetMonitor) {
      props.leaveDragHover();
      invariant(
        props.entity,
        'FileDropTarget drop received an undefined entity',
      );

      /* eslint-disable consistent-return */

      // folder should only handle drop if folder was the direct drop target
      if (!monitor.isOver(shallowArg)) return;

      const dragSourceType: DropType = (monitor.getItemType() as any) as DropType;
      const dropTarget: FileWithDate = props.entity;
      const dragSourceEntity: DragSourceEntity = monitor.getItem();

      // create the dropTargetEntity and return it, so that the entity
      // will propagate up DropTargets until reaching the Dataroom DropTarget
      // all drop operations

      return createDropTargetEntity(
        dragSourceEntity,
        dropTarget.parent ? dropTarget.parent.id : '',
        dragSourceType,
      );
      /* eslint-enable */
    },
    canDrop(props: DataRoomRowProps<FileWithDate>, monitor: DropTargetMonitor) {
      if (props.accessDenied) return false;

      invariant(
        props.entity,
        'FileDropTarget canDrop received an undefined entity',
      );
      return handleCanDrop(
        props.entity.parent ? props.entity.parent.id : '',
        monitor.getItemType(),
        monitor.getItem(),
      );
    },
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(shallowArg),
    canDrop: monitor.canDrop(),
    dragSourceType: monitor.getItemType(),
    dragSourceEntity: monitor.getItem(),
  }),
);

export default FileDropTarget;
