import * as React from 'react';
import { compose, withProps } from 'recompose';
import UserProfileView from './user/UserProfileView';
import InstitutionProfileView from './institution/InstitutionProfileView';
import { InstitutionQuery, MyUserQuery } from 'lsgql';
import { Tiler, withLoadingIndicator } from 'components';
import { InstitutionType, UserType } from 'types';
import './ProfileView.scss';

type Props = {
  institution: InstitutionType;
  user: UserType;
};

function ProfileView(props: Props) {
  const { institution, user } = props;

  return (
    <div className="ProfileView">
      <Tiler>
        <Tiler.Tile title="My Information" width="Half">
          <UserProfileView user={user} />
        </Tiler.Tile>
        {institution && (
          <Tiler.Tile title="Institution Information" width="Half">
            <InstitutionProfileView institution={institution} />
          </Tiler.Tile>
        )}
      </Tiler>
    </div>
  );
}

export default compose(
  MyUserQuery,
  withProps(({ user }) => ({ institutionId: user?.institution?.id })),
  InstitutionQuery,
  withLoadingIndicator('loading'),
)(ProfileView);
