import { mutationBuilder } from './builders';
import {
  CreateCancelLoanMutationDefinition,
  SetCancelLoanMutationDefinition,
} from './definitions';
import { CancelLoanInput, CancelLoanType } from 'types';

const CancelLoanMutationMethods = {
  createCancelLoan: mutationBuilder<CancelLoanInput, CancelLoanType>(
    CreateCancelLoanMutationDefinition,
    {
      build: mutate => cancelLoanData =>
        mutate({ variables: { cancelLoanData } }),
    },
  ),
  setCancelLoan: mutationBuilder<CancelLoanInput, CancelLoanType>(
    SetCancelLoanMutationDefinition,
    {
      build: mutate => cancelLoanData =>
        mutate({ variables: { cancelLoanData } }),
    },
  ),
};

export default CancelLoanMutationMethods;
