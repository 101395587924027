import { MutationMethod } from '../types';
import { mutationBuilder } from './builders';
import {
  CreateLoanTrancheRevolverSwinglineLocDataMutationDefinition,
  SetLoanTrancheRevolverSwinglineLocDataMutationDefinition,
} from './definitions';
import {
  LoanTrancheRevolverSwinglineLOCDataInput,
  LoanTrancheRevolverSwinglineLOCDataType,
} from 'types';

const createLoanTrancheRevolverSwinglineLOCData: MutationMethod<
  LoanTrancheRevolverSwinglineLOCDataInput,
  LoanTrancheRevolverSwinglineLOCDataType
> = mutationBuilder<
  LoanTrancheRevolverSwinglineLOCDataInput,
  LoanTrancheRevolverSwinglineLOCDataType
>(CreateLoanTrancheRevolverSwinglineLocDataMutationDefinition, {
  build: mutate => loanTrancheRevolverSwinglineLocDataData =>
    mutate({
      variables: {
        loanTrancheRevolverSwinglineLocDataData,
      },
    }),
});

const setLoanTrancheRevolverSwinglineLOCData: MutationMethod<
  LoanTrancheRevolverSwinglineLOCDataInput,
  LoanTrancheRevolverSwinglineLOCDataType
> = mutationBuilder<
  LoanTrancheRevolverSwinglineLOCDataInput,
  LoanTrancheRevolverSwinglineLOCDataType
>(SetLoanTrancheRevolverSwinglineLocDataMutationDefinition, {
  build: mutate => loanTrancheRevolverSwinglineLocDataData =>
    mutate({
      variables: {
        loanTrancheRevolverSwinglineLocDataData,
      },
    }),
});

const LoanTrancheRevolverSwinglineLOCDataMutationMethods = {
  createLoanTrancheRevolverSwinglineLOCData,
  setLoanTrancheRevolverSwinglineLOCData,
};

export default LoanTrancheRevolverSwinglineLOCDataMutationMethods;
