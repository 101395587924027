/* eslint-disable import/prefer-default-export */

/* Mutations */
export { default as DealFavoriteMutations } from './DealFavoriteMutations';
export { DocumentMutations } from './DocumentMutations';

export {
  AcceptInvitationMutations,
  AcceptInvitationMutation,
} from './AcceptInvitationMutation';
export { default as CancelLoanMutationMethods } from './CancelLoanMutations';

export { default as LoanTrancheOwnershipPortionMutationMethods } from './LoanTrancheOwnershipPortionMutations';

export { default as LoanTrancheInstitutionOwnershipPortionMutationMethods } from './LoanTrancheInstitutionOwnershipPortionMutations';

export { UserMutationMethods, UserMutations } from './UserMutations';
export { default as EscrowAccountMutationMethods } from './EscrowAccountMutations';

/* Mutation Method Groups */
export { FolderMutations, FolderMutationMethods } from './FolderMutations';
export { default as CovenantMutationMethods } from './CovenantMutations';
export { default as CreditRatingMutationMethods } from './CreditRatingMutations';
export { default as BenchmarkOptionMutationMethods } from './BenchmarkOptionMutations';
export { default as ApplicableMarginMutationMethods } from './ApplicableMarginMutations';
export { default as AlternateBaseRateBenchmarkMutationMethods } from './AlternateBaseRateBenchmarkMutations';
export { default as DealMutationMethods } from './DealMutations';
export { default as DealClosingWorkflowMutationMethods } from './DealClosingWorkflowMutations';
export { default as DealClosingWorkflowStepTypeMutationMethods } from './DealClosingWorkflowStepMutations';
export { default as EventDueMutationMethods } from './EventDueMutations';
export { default as EventScheduleMutationMethods } from './EventScheduleMutations';
export { default as EventScheduleTemplateMutationMethods } from './EventScheduleTemplateMutations';
export { default as FeeCalculationRuleMutationMethods } from './FeeCalculationRuleMutations';
export { default as FeeMutationMethods } from './FeeMutations';
export { default as GeneralNoticeMutationMethods } from './GeneralNoticeMutations';
export { default as InstitutionMutationMethods } from './InstitutionMutations';
export { default as LoanTrancheMutationMethods } from './LoanTrancheMutations';
export { default as LoanTrancheTierMutationMethods } from './LoanTrancheTierMutations';
export { default as LoanTrancheFloatingRateDataMutationMethods } from './LoanTrancheFloatingRateDataMutations';
export { default as LoanTrancheRevolverSwinglineLOCDataMutationMethods } from './LoanTrancheRevolverSwinglineLOCDataMutations';
export { default as LoanTrancheAdjustableRateDataMutationMethods } from './LoanTrancheAdjustableRateDataMutations';
export { default as LoanTrancheAdjustableRatePeriodMutationMethods } from './LoanTrancheAdjustableRatePeriodMutations';
export { default as LoanTrancheCollateralMutationMethods } from './LoanTrancheCollateralMutations';
export { default as LoanTrancheGuarantorMutationMethods } from './LoanTrancheGuarantorMutations';
export { default as LoanTrancheMultiDrawDataMutationMethods } from './LoanTrancheMultiDrawDataMutations';
export { default as ProspectMutationMethods } from './ProspectMutations';
export { default as TierFeePercentageMutationMethods } from './TierFeePercentageMutations';
export { default as PrepaymentPenaltyRangeMutationMethods } from './PrepaymentPenaltyRangeMutations';
export { default as PaymentRecordBorrowerMutationMethods } from './PaymentRecordBorrowerMutations';
export { default as PaymentRecordLenderMutationMethods } from './PaymentRecordLenderMutations';
export { default as RolePermissionMutationMethods } from './RolePermissionMutations';
export { default as ReserveMutationMethods } from './ReserveMutations';
export { default as ResendInvitationMutation } from './ResendInvitationMutation';
export { default as LoanTrancheAmortizationMutationMethods } from './LoanTrancheAmortizationMutations';

export { default as InstitutionInvitationMutationMethods } from './InstitutionInvitationMutations';

export { default as InstitutionBankAccountMutationMethods } from './InstitutionBankAccountMutations';
export { default as SbaAccountMutationMethods } from './SbaAccountMutations';

export { default as SetupDealLeadLenderMutation } from './SetupDealLeadLenderMutation';

export { default as GenerateDebugDeal } from './GenerateDebugDealMutations';

export { default as AdditionalSbaPppBorrowerInformationMutationMethods } from './AdditionalSbaPppBorrowerInformation';

export { default as PublicSbaPppLoanApplicationMutationMethods } from './PublicSbaPppLoanApplicationMutations';
export { default as PublicSbaPppInstitutionMutationMethods } from './PublicSbaPppInstitutionMutations';
export { default as PublicSbaPppLoanFinancialsMutationMethods } from './PublicSbaPppLoanFinancialsMutations';
export { default as PublicSbaPppApplicantOwnershipMutationMethods } from './PublicSbaPppApplicantOwnershipMutations';

export { default as PrivateSbaPppLoanApplicationMutationMethods } from './PrivateSbaPppLoanApplicationMutations';
export { default as PrivateSbaPppInstitutionMutationMethods } from './PrivateSbaPppInstitutionMutations';
export { default as PrivateSbaPppLoanFinancialsMutationMethods } from './PrivateSbaPppLoanFinancialsMutations';
export { default as PrivateSbaPppApplicantOwnershipMutationMethods } from './PrivateSbaPppApplicantOwnershipMutations';

export { default as SbaPppForgivenessWorkflowMutationMethods } from './SbaPppForgivenessWorkflowMutations';
export { default as SbaPppForgivenessLoanInformationMutationMethods } from './SbaPppForgivenessLoanInformationMutations';
export { default as SbaPppForgivenessCalculationMutationMethods } from './SbaPppForgivenessCalculationMutations';
export { default as SbaPppForgivenessResubmitRequestMutationMethods } from './SbaPppForgivenessResubmitRequestMutations';
export { default as SbaPppForgivenessDenialMutationMethods } from './SbaPppForgivenessDenialMutations';
export { default as SbaPppForgivenessEzFormQualificationsMutationMethods } from './SbaPppForgivenessEzFormQualificationsMutations';
export { default as SbaPppLenderClosingWorkflowQuestionsMutationMethods } from './SbaPppLenderClosingWorkflowQuestionsMutations';
export { default as SbaPppApplicationResubmitRequestMutationMethods } from './SbaPppApplicationResubmitRequestMutations';
export { default as LoanFundingMutationMethods } from './LoanFundingMutations';
export { assignDeal, unassignDeal } from './AssignDealMutations';
