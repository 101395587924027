import * as React from 'react';
import { compose, withProps } from 'recompose';
import ReportView from './ReportView';
import { ReportType } from 'types';
import { withLoadingIndicator } from 'components';
import { ReportsQuery } from 'lsgql';

type Props = {
  reports: ReportType[];
};

/**
 * Higher Order Component that accepts a ReportType and Title,
 * and will create a new ReportQuery wrapped ReportView to handle
 * querying and display of the requested ReportType
 *
 * @param reportType Type of ReportType to query & display
 * @param title Tile Header
 */
function CreateReportView(reportType: string, title: string, hide = false) {
  const ReportsView = ({ reports }: Props) => {
    if (hide && (!reports || reports.length == 0)) return null;
    return <ReportView latestReports={reports} title={title} />;
  };

  return compose(
    withProps({ reportType: reportType }),
    ReportsQuery,
    withLoadingIndicator('loading'),
  )(ReportsView);
}

export default CreateReportView;
