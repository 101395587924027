import React from 'react';
import { Form, TextLink } from 'components';
import { FormFieldsType, SbaPppInstitutionInstitutionType } from 'types';
import { states } from 'resources';
import { isUseSbaBusinessLegalName } from 'utils';

import './RoundTwoInstitution.scss';

function _getInstitutionTypeOptions(): {
  text: string;
  value: SbaPppInstitutionInstitutionType;
}[] {
  return [
    { text: 'Sole Proprietor', value: 'SOLE_PROPRIETOR' },
    { text: 'Partnership', value: 'PARTNERSHIP' },
    { text: 'C-Corp', value: 'C_CORP' },
    { text: 'S-Corp', value: 'S_CORP' },
    { text: 'LLC', value: 'LLC' },
    { text: 'Joint Venture', value: 'JOINT_VENTURE' },
    { text: 'Professional Association', value: 'PROFESSIONAL_ASSOCIATION' },
    { text: 'Trust', value: 'TRUST' },
    { text: 'Cooperative', value: 'COOPERATIVE' },
    { text: 'LLP', value: 'LLP' },
    { text: 'NPO', value: 'NPO' },
    { text: 'Tenant in Common', value: 'TENANT' },
    { text: 'ESOP', value: 'ESOP' },
    { text: 'Non-Profit Child Care', value: 'NONPROFIT_CHILD_CARE' },
    { text: 'ROBS', value: 'ROBS' },
    { text: 'Independent Contractor', value: 'IND_CONT' },
    { text: 'Self-Employed Individual', value: 'SELF_EMPLOYED' },
    { text: '501(c)(3) Nonprofit', value: 'NONPROFIT_C_3_ORGANIZTION' },
    { text: '501(c)(6) Organization', value: 'NONPROFIT_C_6_ORGANIZTION' },
    { text: '501(c)(19) Veterans Organization', value: 'VET_ORG' },
    {
      text: 'Tribal Business (sec. 31(b)(2)(C) of Small Business Act)',
      value: 'TRIBAL',
    },
    { text: 'Housing Cooperative', value: 'HOUSING_COOPERATIVE' },
    // Schedule-C additions
    {
      text: 'Single member LLC (self-employed individual)',
      value: 'SINGLE_MEMBER_LLC',
    },
    {
      text: 'Qualified Joint Venture (self-employed Individual)',
      value: 'QUALIFIED_JOINT_VENTURE',
    },
    { text: 'Other 501(c) organization ', value: 'OTHER_501C_ORGANIZATION' },
  ];
}

// Can be either SSN/EIN: 'SOLE_PROPRIETORSHIP', 'LLC', 'TRUST', 'TENANT_IN_COMMON',
const SSN_EXCLUSIVES: Array<SbaPppInstitutionInstitutionType> = [
  'SELF_EMPLOYED',
  'IND_CONT',
];
const EIN_EXCLUSIVES: Array<SbaPppInstitutionInstitutionType> = [
  'PARTNERSHIP',
  'C_CORP',
  'JOINT_VENTURE',
  'PROFESSIONAL_ASSOCIATION',
  'COOPERATIVE',
  'LLP',
  'S_CORP',
  'NON_PROFIT',
  'ESOP',
  'NONPROFIT_CHILD_CARE',
  'ROBS',
  'NONPROFIT_C_3_ORGANIZTION',
  'NONPROFIT_C_6_ORGANIZTION',
  'VET_ORG',
  'TRIBAL',
  'HOUSING_COOPERATIVE',
  'NPO',
];

const fields: FormFieldsType = {
  applicantMeetsSizeStandard: {
    id: 'applicantMeetsSizeStandard',
    propertyName: 'applicantMeetsSizeStandard',
    fieldName:
      'Applicant (including affiliates, if applicable) Meets Size Standard',
    required: true,
    options: [
      {
        text: 'No more than 500 employees (or 300 employees, if applicable)',
        value: 'LESS_THAN_300_OR_500',
      },
      {
        text: 'SBA industry size standards',
        value: 'SBA_INDUSTRY_SIZE_STANDARD',
      },
      {
        text: 'SBA alternative size standard',
        value: 'SBA_ALTERNATIVE_SIZE_STANDARD',
      },
    ],
    width: '332px',
  },
  naicsCode: {
    id: 'naicsCode',
    propertyName: 'naicsCode',
    fieldName: 'NAICS Code',
    required: true,
    width: '158px',
  },
  dateBusinessEstablished: {
    id: 'dateBusinessEstablished',
    propertyName: 'dateBusinessEstablished',
    fieldName: 'Date Established',
    picking: 'week',
    required: true,
    width: '158px',
  },
  businessLegalName: {
    id: 'businessLegalName',
    propertyName: 'businessLegalName',
    fieldName: 'Business Legal Name',
    width: '332px',
    required: true,
  },
  addressOne: {
    id: 'addressOne',
    propertyName: 'addressOne',
    fieldName: 'Business Street Address (no P.O. Box Addresses allowed)',
    width: '332px',
    required: true,
  },
  addressTwo: {
    id: 'addressTwo',
    propertyName: 'addressTwo',
    fieldName: 'Business Address Line 2 (optional)',
    width: '332px',
    required: false,
  },
  city: {
    id: 'city',
    propertyName: 'city',
    fieldName: 'City',
    width: '158px',
    required: true,
  },
  state: {
    id: 'state',
    propertyName: 'state',
    fieldName: 'State',
    placeholder: '--',
    typeName: 'SbaPppInstitutionState',
    width: '52px',
    options: states.map(state => ({
      text: state.abbreviation,
      value: state.abbreviation,
    })),
    required: true,
  },
  zip: {
    id: 'zip',
    propertyName: 'zip',
    fieldName: 'Zip',
    width: '98px',
    required: true,
    placeholder: '12345-1234',
    helpText: '+ 4 digits is optional',
    className: 'zip-code',
  },
  phone: {
    id: 'phone',
    propertyName: 'phone',
    fieldName: 'Business Phone',
    width: '168px',
    required: true,
  },
  dbaOrTradename: {
    id: 'dbaOrTradename',
    propertyName: 'dbaOrTradename',
    fieldName: 'DBA or Tradename (if applicable)',
    width: '332px',
  },
  businessTinType: {
    id: 'businessTinType',
    propertyName: 'businessTinType',
    fieldName: '',
    width: '67px',
    options: [
      { text: 'EIN', value: 'EIN' },
      { text: 'SSN', value: 'SSN' },
    ],
    required: true,
  },
  businessTinEinSsn: {
    id: 'businessTinEinSsn',
    propertyName: 'businessTinEinSsn',
    fieldName: '',
    width: '256px',
    required: true,
  },
  primaryContactFirstName: {
    id: 'primaryContactFirstName',
    propertyName: 'primaryContactFirstName',
    fieldName: 'Primary Contact First Name',
    width: '158px',
    required: true,
  },
  primaryContactLastName: {
    id: 'primaryContactLastName',
    propertyName: 'primaryContactLastName',
    fieldName: 'Primary Contact Last Name',
    width: '158px',
    required: true,
  },
  email: {
    id: 'email',
    propertyName: 'email',
    fieldName: 'Primary Contact Email Address',
    width: '332px',
    required: true,
  },
  institutionType: {
    id: 'institutionType',
    propertyName: 'institutionType',
    fieldName: 'Business Type',
    width: '332px',
    placeholder: 'Please Select',
    options: _getInstitutionTypeOptions(),
    required: true,
  },
  businessFirstName: {
    id: 'businessFirstName',
    propertyName: 'businessFirstName',
    fieldName: 'First Name',
    width: '158px',
    required: true,
  },
  businessLastName: {
    id: 'businessLastName',
    propertyName: 'businessLastName',
    fieldName: 'Last Name',
    width: '158px',
    required: true,
  },
};

const SbaPppInstitution = 'SbaPppInstitution';

function RoundTwoInstitution(props: any) {
  const { data, isSecondDraw } = props;

  const placeholderBusinessTinEinSsn = data.businessTinEinSsn
    ? '*********'
    : 'Enter numbers only, no dashes or spaces';

  const useBusinessLegalName: boolean = isUseSbaBusinessLegalName(data);

  const setByBizType = [...SSN_EXCLUSIVES, ...EIN_EXCLUSIVES].includes(
    data.institutionType,
  );

  if (setByBizType) {
    if (EIN_EXCLUSIVES.includes(data.institutionType)) {
      props.mutateProperty('EIN', 'businessTinType');
    } else {
      // if (SSN_EXCLUSIVES.includes(data.institutionType))
      props.mutateProperty('SSN', 'businessTinType');
    }
  }

  return (
    <div className="RoundTwoInstitution">
      {Form.FieldRenderer(
        Form.Select,
        { ...fields.institutionType, onChange: props.mutateProperty },
        props,
        SbaPppInstitution,
        data.id,
      )}
      <Form.FieldLabel
        fieldName="Business TIN (EIN / SSN)"
        htmlFor={fields.businessTinType.id}
        propertyName={fields.businessTinType.propertyName}
      />
      <Form.Group>
        {Form.FieldRenderer(
          Form.Select,
          {
            ...fields.businessTinType,
            onChange: props.mutateProperty,
            disabled: setByBizType,
            disabledReadable: setByBizType,
          },
          props,
          SbaPppInstitution,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Numeric,
          {
            ...fields.businessTinEinSsn,
            onChange: props.mutateProperty,
            maxLength: 9,
            placeholder: placeholderBusinessTinEinSsn,
            helpText: setByBizType
              ? 'TIN Type preset based on Owner/Business Type'
              : undefined,
          },
          props,
          SbaPppInstitution,
          data.id,
        )}
      </Form.Group>

      {useBusinessLegalName ? (
        Form.FieldRenderer(
          Form.Input,
          { ...fields.businessLegalName, onChange: props.mutateProperty },
          props,
          SbaPppInstitution,
          data.id,
        )
      ) : (
        <Form.Group>
          {Form.FieldRenderer(
            Form.Input,
            { ...fields.businessFirstName, onChange: props.mutateProperty },
            props,
            SbaPppInstitution,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Input,
            { ...fields.businessLastName, onChange: props.mutateProperty },
            props,
            SbaPppInstitution,
            data.id,
          )}
        </Form.Group>
      )}

      {Form.FieldRenderer(
        Form.Input,
        { ...fields.addressOne, onChange: props.mutateProperty },
        props,
        SbaPppInstitution,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Input,
        { ...fields.addressTwo, onChange: props.mutateProperty },
        props,
        SbaPppInstitution,
        data.id,
      )}
      <Form.Group>
        {Form.FieldRenderer(
          Form.Input,
          { ...fields.city, onChange: props.mutateProperty },
          props,
          SbaPppInstitution,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Select,
          { ...fields.state, onChange: props.mutateProperty },
          props,
          SbaPppInstitution,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Zip,
          { ...fields.zip, onChange: props.mutateProperty },
          props,
          SbaPppInstitution,
          data.id,
        )}
      </Form.Group>
      {Form.FieldRenderer(
        Form.Phone,
        { ...fields.phone, onChange: props.mutateProperty },
        props,
        SbaPppInstitution,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Input,
        { ...fields.dbaOrTradename, onChange: props.mutateProperty },
        props,
        SbaPppInstitution,
        data.id,
      )}
      <Form.Group>
        {Form.FieldRenderer(
          Form.Calendar,
          {
            ...fields.dateBusinessEstablished,
            picking: 'day',
            onChange: props.mutateProperty,
          },
          props,
          SbaPppInstitution,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Numeric,
          { ...fields.naicsCode, onChange: props.mutateProperty },
          props,
          SbaPppInstitution,
          data.id,
        )}
        <TextLink.External
          label="Lookup"
          target="newTab"
          to="https://www.naics.com/search"
        />
      </Form.Group>
      {!isSecondDraw &&
        Form.FieldRenderer(
          Form.Select,
          {
            ...fields.applicantMeetsSizeStandard,
            onChange: props.mutateProperty,
          },
          props,
          SbaPppInstitution,
          data.id,
        )}
      <Form.Group>
        {Form.FieldRenderer(
          Form.Input,
          { ...fields.primaryContactFirstName, onChange: props.mutateProperty },
          props,
          SbaPppInstitution,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Input,
          { ...fields.primaryContactLastName, onChange: props.mutateProperty },
          props,
          SbaPppInstitution,
          data.id,
        )}
      </Form.Group>
      {Form.FieldRenderer(
        Form.Input,
        { ...fields.email, onChange: props.mutateProperty },
        props,
        SbaPppInstitution,
        data.id,
      )}
    </div>
  );
}

export default RoundTwoInstitution;
