import { SbaPppLenderClosingworkflowQuestionsQueryDefinition as QueryDefinition } from './definitions';
import formQueryBuilder from './queryBuilders/formQueryBuilder';
import { SbaPppLenderClosingWorkflowQuestionsType } from 'types';

type Props = { id: string | null | undefined };

const SbaPppLenderClosingWorkflowQuestionsFormQuery = formQueryBuilder(
  QueryDefinition,
  {
    typename: 'SbaPppLenderClosingWorkflowQuestions',
    skip: ({ id }: Props) => !id,
    variables: ({ id }: Props) => ({ id: id }),
    results: (data: {
      sbaPppLenderClosingworkflowQuestions:
        | SbaPppLenderClosingWorkflowQuestionsType
        | null
        | undefined;
    }) => {
      const { sbaPppLenderClosingworkflowQuestions } = data;
      return sbaPppLenderClosingworkflowQuestions;
    },
  },
);

export default SbaPppLenderClosingWorkflowQuestionsFormQuery;
