import { compose } from 'recompose';
import { connect } from 'react-redux';
import DealClosingWorkflow from './LegacyDealClosingWorkflow';
import { RouteParams, withRouteParams } from 'routing';
import { RouteTable } from 'routing/RouteTable';
import { DealClosingWorkflowQuery, DealMutationMethods } from 'lsgql';
import dealClosingWorkflowFormRedux from 'lsredux/reducer/forms/dealClosingWorkflow';
import { DealPerspectivePermissions } from 'security';
import { ReduxDirectory } from 'lsredux';
import {
  FormMediator,
  FormReduxRawState,
  withLoadingIndicator,
} from 'components';
import { DispatchMethods } from 'lsredux/genericForms';
import {
  DealClosingWorkflowType,
  DealClosingWorkflowStepType,
  DealInput,
  ID,
} from 'types';

function mapStateToProps(state: any): FormReduxRawState {
  return {
    rawData: state.getIn(ReduxDirectory.DealClosingWorkflowKeyPath),
  };
}

const mapDispatchToProps: (dispatch: any, ownProps: any) => any = (
  dispatch: any,
) => {
  const generated: DispatchMethods<DealClosingWorkflowType> = dealClosingWorkflowFormRedux.actions.generateActions(
    dispatch,
  );

  function updateLoanFunding(value: string, property: string) {
    generated.mutateProperty(value, [
      'dealClosingWorkflow',
      'loanfunding',
      property,
    ]);
  }

  function updateStep(stepData: DealClosingWorkflowStepType) {
    const isLeafEntity = Object.assign({}, stepData, { isLeaf: true });
    generated.replaceEntity(['dealClosingWorkflow', 'steps'], isLeafEntity);
  }

  function closeDeal(dealId: ID) {
    const args: DealInput = {
      id: dealId,
      __typename: 'DealInput',
    };
    DealMutationMethods.closeDeal(args);
  }

  return {
    ...generated,
    closeDeal,
    updateStep,
    updateLoanFunding,
  };
};

export default compose(
  withRouteParams([RouteParams.dealId]),
  DealClosingWorkflowQuery,
  withLoadingIndicator('loading'),
  connect(mapStateToProps, mapDispatchToProps),
  FormMediator({
    formId: 'dealClosingWorkflowForm',
    disableFrame: true,
    postSaveRoutePath: RouteTable.deal.toLoanOverviewSummary,
    editSecurity: DealPerspectivePermissions.administer_closing,
    baseObjectTypeName: 'Deal',
    blurEventForm: true,
  }),
)(DealClosingWorkflow);
