import uuid from 'uuid/v4';
import { PaymentRecordBorrowerMutationMethods } from '../../../graphql/mutations';
import { createForm } from '../../genericForms';
import Directory from '../../directory';
import { PaymentRecordType } from 'types';

export type PaymentRecordBorrowerTypeWithPrefillFlag = PaymentRecordType & {
  wasDataPrefilled: boolean;
};
const initialPaymentRecordBorrower: PaymentRecordType = {
  id: uuid(),
  __typename: 'PaymentRecordType',
  borrowerAmount: '',
  borrowerDate: '',
  borrowerNotes: '',
  borrowerReference: '',
  paymentDue: {
    __typename: 'PaymentDueType',
    id: uuid(),
    principalAmount: '',
    date: '',
  },
};

const lifecycleMethods = {
  mutations: {
    ...PaymentRecordBorrowerMutationMethods,
  },
  validators: {},
};

function addPrefillFlag(
  input: PaymentRecordType,
): PaymentRecordBorrowerTypeWithPrefillFlag {
  if (!(input.borrowerAmount && input.borrowerDate) && input.paymentDue) {
    return {
      ...input,
      wasDataPrefilled: true,
      __typename: 'PaymentRecordBorrower',
    } as any;
  }
  return {
    ...input,
    wasDataPrefilled: false,
    __typename: 'PaymentRecordBorrower',
  } as any;
}

const paymentRecordBorrowerRedux = createForm<
  PaymentRecordBorrowerTypeWithPrefillFlag,
  PaymentRecordType
>(
  Directory.PaymentRecordBorrowerKey,
  initialPaymentRecordBorrower,
  lifecycleMethods,
  {
    enableAutoSave: false,
    dataConverter: addPrefillFlag,
  },
);

export default paymentRecordBorrowerRedux;
