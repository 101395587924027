import * as React from 'react';
import LenderRegistryTranche from './LenderRegistryTranche/LenderRegistryTranche';
import { getOptionsData, DealInstitutionsOptionsData } from './utils';
import { Form, WrappedMediatorFormProps } from 'components';
import {
  LenderRegistryFormType,
  DealInstitutionType,
  SelectOptionType,
  LenderRegistryFormTrancheType,
  ID,
} from 'types';

const allTranchesOptionValue = 'ALL';

type Props = WrappedMediatorFormProps<LenderRegistryFormType> & {
  dealId: ID;
  dealInstitutions: DealInstitutionType[];
};

type State = { selectedTrancheId: string | null | undefined };

class LenderRegistryForm extends React.Component<Props, State> {
  fields: {
    [key: string]: any;
  };

  constructor(props: Props) {
    super(props);

    this.fields = {
      selectedTranche: {
        id: 'selectedTranche',
        propertyName: 'selectedTrancheId',
        allowEmpty: false,
        fieldName: 'Select Tranche',
        width: 'four',
        onChange: this.handleOnSelectedTrancheChange,
      },
    };

    this.state = {
      selectedTrancheId: allTranchesOptionValue,
    };
  }

  getTrancheOptions = (
    tranches: ReadonlyArray<LenderRegistryFormTrancheType> | null | undefined,
  ): SelectOptionType[] => {
    const options: SelectOptionType[] = [
      {
        text: 'All',
        value: allTranchesOptionValue,
      },
    ];

    if (tranches) {
      const trancheOptions = tranches.map<SelectOptionType>(tranche => ({
        text: tranche.name || '',
        value: tranche.id,
      }));

      return [...options, ...trancheOptions];
    }
    return options;
  };

  handleOnSelectedTrancheChange = (id: string | null | undefined) => {
    this.setState({ selectedTrancheId: id });
  };

  renderTrancheLenderRegistry = () => {
    const { selectedTrancheId } = this.state;
    const { data, dealId, dealInstitutions, errors } = this.props;
    const { loantrancheSet } = data;
    const trancheIndex = (loantrancheSet || []).findIndex(
      tranche => tranche.id === selectedTrancheId,
    );

    if (trancheIndex !== -1) {
      const loanTranche = loantrancheSet[trancheIndex];
      const optionsData: DealInstitutionsOptionsData = getOptionsData(
        dealInstitutions,
      );

      return (
        <LenderRegistryTranche
          addEntity={this.props.addEntity}
          dealId={dealId}
          disabled={Boolean(this.props.disabled)}
          errors={errors}
          removeEntity={this.props.removeEntity}
          replaceEntity={this.props.replaceEntity}
          subsidiaryOptionMap={optionsData.subsidiaryMap}
          topLevelInstitutionOptions={optionsData.topLevelInstitutions}
          tranche={loanTranche}
          trancheIndex={trancheIndex}
        />
      );
    }
    return null;
  };

  renderAllTranchesLenderRegistry = () => {
    const {
      dealId,
      dealInstitutions,
      data: { loantrancheSet },
      errors,
    } = this.props;

    const optionsData: DealInstitutionsOptionsData = getOptionsData(
      dealInstitutions,
    );

    return (
      <>
        {(loantrancheSet || []).map((tranche, index) => (
          <LenderRegistryTranche
            addEntity={this.props.addEntity}
            dealId={dealId}
            disabled={Boolean(this.props.disabled)}
            errors={errors}
            key={tranche.id}
            removeEntity={this.props.removeEntity}
            replaceEntity={this.props.replaceEntity}
            subsidiaryOptionMap={optionsData.subsidiaryMap}
            topLevelInstitutionOptions={optionsData.topLevelInstitutions}
            tranche={tranche}
            trancheIndex={index}
          />
        ))}
      </>
    );
  };

  render() {
    const { data } = this.props;
    const { loantrancheSet } = data;
    const { selectedTrancheId } = this.state;
    const trancheOptions = this.getTrancheOptions(loantrancheSet);

    return (
      <div className="lenderRegistryForm">
        <Form.Group>
          {Form.FieldRenderer(
            Form.Select,
            {
              ...this.fields.selectedTranche,
              options: trancheOptions,
              value: selectedTrancheId,
            },
            this.props,
            'Deal',
            data.id,
          )}
        </Form.Group>

        {selectedTrancheId === allTranchesOptionValue
          ? this.renderAllTranchesLenderRegistry()
          : this.renderTrancheLenderRegistry()}
      </div>
    );
  }
}

export default LenderRegistryForm;
