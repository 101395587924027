import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FilterColumnProps } from '../types';
import { Filter, Input } from 'components';
import { DealType } from 'types';
import { accessProperty } from 'utils';

interface Props {
  dateRangeProps?: { placeholder?: string; propertyName: string };
  deals: DealType[];
  initialColumns: FilterColumnProps[];
  onChange: (filteredDeals: DealType[]) => void;
}

export const DealFilters = (props: Props) => {
  const { initialColumns, onChange, deals, dateRangeProps } = props;

  const [filterColumns, setFilterColumns] = useState(initialColumns);
  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [searchQuery, setSearchQuery] = useState('');

  // This updates the options for the indiv. filters based on all the deals in the view
  useEffect(() => {
    if (deals) {
      setFilterColumns(filterColumns => [
        ...filterColumns.map(column => {
          // Skip columns that have constant option sets
          if (['status', 'category'].includes(column.id)) {
            return column;
          }

          const uniqueOptions = new Set(
            deals.map(deal => accessProperty(column.id, deal)),
          );
          uniqueOptions.delete(undefined);
          uniqueOptions.delete('');
          uniqueOptions.delete(null);
          const { idToLabel } = column;

          column.options = Array.from(uniqueOptions).map(option => {
            const optionId = option.toString();
            return {
              id: optionId,
              label: idToLabel ? idToLabel(optionId) : optionId,
            };
          });

          return column;
        }),
      ]);
    }
  }, [deals]);

  // This filters the deals based on the provided filters
  useEffect(() => {
    onChange(
      deals?.filter(deal => {
        const inFilter = filterColumns.every(
          column =>
            column.value.length === 0 ||
            column.value.includes(accessProperty(column.id, deal) as string),
        );

        const inDateRange =
          !dateRangeValue.startDate ||
          !dateRangeProps.propertyName ||
          moment(accessProperty(dateRangeProps.propertyName, deal)).isBetween(
            dateRangeValue.startDate,
            dateRangeValue.endDate,
            'day',
            '[]',
          );

        const inQuery =
          searchQuery.length < 1 ||
          [deal.borrowerName, deal.identity].some(
            value =>
              typeof value === 'string' &&
              value.toLowerCase().includes(searchQuery.toLowerCase()),
          );

        return inFilter && inDateRange && inQuery;
      }),
    );
  }, [
    deals,
    filterColumns,
    dateRangeValue,
    searchQuery,
    onChange,
    dateRangeProps.propertyName,
  ]);

  const handleFilterChange = (
    value: string[],
    columnChanged: FilterColumnProps,
  ) => {
    setFilterColumns([
      ...filterColumns.map(column => {
        if (column.id === columnChanged.id) {
          column.value = value || [...column.options.map(option => option.id)];
        }
        return column;
      }),
    ]);
  };

  return (
    <div className="Filters">
      {dateRangeProps && (
        <Filter.DatePicker
          align="right"
          id="dateRange"
          onChange={values => setDateRangeValue(values)}
          placeholder={dateRangeProps.placeholder}
          value={dateRangeValue}
        />
      )}
      {filterColumns?.map(column => (
        <Filter.CheckboxGroup
          align="right"
          {...column}
          key={column.id}
          onChange={values => handleFilterChange(values, column)}
        />
      ))}
      <Input
        className="Searchbox"
        icon={['fal', 'search']}
        iconPlacement="left"
        id="search"
        onChange={e => setSearchQuery(e.target.value)}
        onChangeEventType="onKeyPress"
        placeholder="Borrower or Loan ID"
        value={searchQuery}
        width="188px"
      />
    </div>
  );
};

export default DealFilters;
