import uuid from 'uuid/v4';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import {
  SbaPppForgivenessCalculationMutationMethods,
  SbaPppForgivenessCalculationValidator,
} from '../../../graphql';
import { DealType } from 'types';

const initialDeal: DealType = {
  id: uuid(),
  __typename: 'DealType',
};

const lifecycleMethods = {
  mutations: SbaPppForgivenessCalculationMutationMethods,
  validators: {
    SbaPppForgivenessCalculation: SbaPppForgivenessCalculationValidator,
  },
};

const sbaPppForgivnessCalculationFormRedux = createForm<DealType, DealType>(
  Directory.SbaPppForgivenessCalculationKey,
  initialDeal,
  lifecycleMethods,
);

export default sbaPppForgivnessCalculationFormRedux;
