import * as React from 'react';
import noop from 'lodash/noop';
import capitalize from 'lodash/capitalize';
import cx from 'classnames';
import { OrderBookContainerProps } from '../types';
import NotesIconButton from './NotesIconButton';
import {
  Form,
  Button,
  Modal,
  Money,
  Divider,
  TextArea,
  DataGridRowState,
  Column,
  DataGridInputProps,
  DataGrid,
} from 'components';

import { OrderBookProspect } from 'types';

import { haltBubble } from 'utils';
import {
  DealPerspectivePermissions,
  MountGuard,
  AccessDeniedGuard,
  DisabledChildProps,
} from 'security';

import './TrancheOrderBook.scss';

/* eslint-disable react/no-multi-comp, react/require-default-props */

const renderName = (name?: string) => <div>{name || '-'}</div>;
const renderMoney = (value: string) => <Money value={value} />;

const midAccessor = (value: string, row: OrderBookProspect) => (
  <Money
    value={(
      (+row.levelOfInterestHigh + +row.levelOfInterestLow) /
      2
    ).toString()}
  />
);

const aggregator = (
  rows: ReadonlyArray<OrderBookProspect>,
  columnName: string,
) => (
  <Money
    value={rows
      .map(ele => ele[columnName])
      .reduce((sum, current) => +sum + +current, 0)
      .toString()}
  />
);
const midAggregator = (rows = []) => {
  if (rows.length) {
    const levelOfInterestLow = rows
      .map(ele => ele.levelOfInterestLow)
      .reduce((sum, current) => +sum + +current, 0)
      .toString();
    const levelOfInterestHigh = rows
      .map(ele => ele.levelOfInterestHigh)
      .reduce((sum, current) => +sum + +current, 0)
      .toString();
    return (
      <Money
        value={((+levelOfInterestHigh + +levelOfInterestLow) / 2).toString()}
      />
    );
  }
  return <Money />;
};

const moneyEditor = (rowProps: DataGridInputProps<any, any>) => (
  <DataGrid.MoneyInput addDollarSymbol={false} {...rowProps} />
);

const AddInvestor = ({
  onAddInvestor,
  className = '',
}: {
  className?: string;
  onAddInvestor: () => void;
}) => (
  <MountGuard permission={DealPerspectivePermissions.administer_investor_book}>
    <div className={className}>
      <Button.Text label="+ Add Investor" onClick={onAddInvestor} />
    </div>
  </MountGuard>
);

type State = {
  addInvestorModalOpen: boolean;
  addingInvestorName: string;
  columns: Array<any>;
  noteModalEditingRow: OrderBookProspect | null | undefined;
  pendingDelete: OrderBookProspect | null | undefined;
};

class TrancheOrderBookGrid extends DataGrid<OrderBookProspect, any> {}

class TrancheOrderBook extends React.Component<OrderBookContainerProps, State> {
  initialState: State = {
    addInvestorModalOpen: false,
    addingInvestorName: '',
    pendingDelete: null,
    columns: [],
    noteModalEditingRow: null,
  };

  constructor(props: OrderBookContainerProps) {
    super(props);
    this.state = {
      ...this.initialState,
      columns: this.generateLocalColumns(props),
    };
  }

  generateLocalColumns = ({ columns }: OrderBookContainerProps = this.props) =>
    columns.map<any>((e, i) => ({
      ...e,
      ...this.localColumnsProps[i],
    }));

  tierAccessor = (row: OrderBookProspect) => {
    let tierId: string | null | undefined = null;
    if (row.customTierId) tierId = row.customTierId;
    else if (row.tierId) {
      // eslint-disable-next-line
      tierId = row.tierId;
    }
    let tier: any | null | undefined = null;
    if (tierId && this.props.data.loantranchetierSet) {
      tier = this.props.data.loantranchetierSet.find(e => e.id === tierId);
    }
    return tier && tier.tierNumber ? tier.tierNumber : '';
  };

  notesAccessor = (
    row: OrderBookProspect,
    c: Column<OrderBookProspect, any>,
    i: string,
    rowState: DataGridRowState,
  ) => {
    const iconBaseClass = 'TrancheOrderBook-NotesIcon';
    if (row.note) {
      return (
        <NotesIconButton
          className={iconBaseClass}
          icon="note"
          onClick={this.handleToggleNoteModal}
          row={row}
        />
      );
    }
    const iconNoNoteClass = cx(
      iconBaseClass,
      rowState.isHovered ? '' : 'TrancheOrderBook-NotesIcon-Hidden',
    );
    return (
      <MountGuard
        permission={DealPerspectivePermissions.administer_investor_book}
      >
        <NotesIconButton
          className={iconNoNoteClass}
          icon="add-note"
          onClick={this.handleToggleNoteModal}
          row={row}
        />
      </MountGuard>
    );
  };

  tierEditor(rowProps: DataGridInputProps<OrderBookProspect, any>) {
    /* eslint-disable react/jsx-no-bind */
    const onValueChanged = (value: string) => {
      // if (!this.props.data) return;
      const tiers = this.props.data.loantranchetierSet || [];
      const customTier = tiers.find(e => +e.tierNumber === +value);
      if (customTier && rowProps.onValueChanged) {
        rowProps.onValueChanged(customTier.id, undefined);
      }
    };
    const shouldRenderWarning = rowProps.row && rowProps.row.customTierId;
    const investorName = rowProps.row ? rowProps.row.investorName || '' : '';
    return (
      <DataGrid.TextInput
        {...rowProps}
        icon={shouldRenderWarning ? 'exclamation-triangle' : null}
        iconMessage={
          shouldRenderWarning
            ? `${investorName} does not meet the criteria for this tier.`
            : undefined
        }
        onValueChanged={onValueChanged}
      />
    );
  }

  handleToggleInvestorModal = () =>
    this.setState(({ addInvestorModalOpen }) => ({
      addInvestorModalOpen: !addInvestorModalOpen,
    }));

  handleToggleNoteModal = (row?: OrderBookProspect) => {
    this.setState(({ noteModalEditingRow }) => ({
      noteModalEditingRow: noteModalEditingRow ? null : row || null,
    }));
  };

  handleTypeInvestorNote = (e: React.SyntheticEvent<any>) => {
    haltBubble(e);
    const { noteModalEditingRow, columns } = this.state;
    if (noteModalEditingRow) {
      this.props.editProspectRow(
        noteModalEditingRow.id,
        columns[7],
        e.currentTarget.value || '',
        noteModalEditingRow,
      );
    }
  };

  handleAddInvestorConfirm = () => {
    if (this.state.addingInvestorName) {
      this.props.addProspectRow(this.state.addingInvestorName);
      this.handleToggleInvestorModal();
      this.setState(({ columns }) => ({ ...this.initialState, columns }));
    }
  };

  handleInvestorNameType = (addingInvestorName?: string | null | undefined) => {
    if (addingInvestorName) this.setState({ addingInvestorName });
  };

  deleteModalMessageAccessor = (row: OrderBookProspect) =>
    `Delete Investor ${row.investorName || ''}?`;

  handleConfirmDelete = () => {
    if (this.state.pendingDelete) {
      const toDelete = this.state.pendingDelete;
      this.setState(
        {
          pendingDelete: null,
        },
        () => {
          this.props.deleteProspectRow(toDelete);
        },
      );
    }
  };

  handleCancelDelete = () => {
    this.setState({
      pendingDelete: null,
    });
  };

  handleRequestDelete = (row: OrderBookProspect) => {
    this.setState({
      pendingDelete: row,
    });
  };

  localColumnsProps: Array<any> = [
    {
      renderer: renderName,
      aggregator: () => (
        <AddInvestor onAddInvestor={this.handleToggleInvestorModal} />
      ),
    },
    {
      renderer: renderMoney,
      editor: moneyEditor,
      aggregator,
    },
    {
      renderer: renderMoney,
      editor: moneyEditor,
      aggregator,
    },
    {
      renderer: midAccessor,
      aggregator: midAggregator,
    },
    {
      renderer: renderMoney,
      editor: moneyEditor,
      aggregator,
    },
    {
      renderer: renderMoney,
      editor: moneyEditor,
      aggregator,
    },
    {
      accessor: this.tierAccessor,
      editor: this.tierEditor.bind(this),
    },
    {
      accessor: this.notesAccessor,
    },
  ];

  /* eslint-enable */

  renderHeader() {
    const { data, index } = this.props;
    return (
      <div className="TrancheOrderBook-Header">
        <Form.Header
          as="h4"
          className="TrancheOrderBook-Header-FormHeader"
          header={data.name || `Tranche ${index}`}
        />
        {data.seniority && (
          <span className="TrancheOrderBook-Header-Detail">
            {capitalize(data.seniority)}
          </span>
        )}
        {data.interestType && (
          <>
            <Divider vertical />
            <span className="TrancheOrderBook-Header-Detail">
              {capitalize(data.interestType)}
            </span>
          </>
        )}
        {data.totalCommitmentAmount && (
          <>
            {(data.seniority || data.interestType) && <Divider vertical />}
            <Money
              className="TrancheOrderBook-Header-Detail"
              decimalPlaces={2}
              value={data.totalCommitmentAmount.toString()}
            />
          </>
        )}
      </div>
    );
  }

  render() {
    const { data, editProspectRow, loanTrancheId } = this.props;
    const {
      addInvestorModalOpen,
      addingInvestorName,
      pendingDelete,
      columns,
      noteModalEditingRow,
    } = this.state;

    const rows: ReadonlyArray<OrderBookProspect> =
      data.prospectSet && data.prospectSet.length ? data.prospectSet : [];
    const editingNoteValue = noteModalEditingRow
      ? rows.filter(row => row.id === noteModalEditingRow.id)[0].note || ''
      : '';
    return (
      <div className="TrancheOrderBook">
        <Form.Section
          header={this.renderHeader()}
          id={`TrancheOrderBook${loanTrancheId}`}
          undivided
        >
          <AccessDeniedGuard
            permission={DealPerspectivePermissions.administer_investor_book}
          >
            {(security: DisabledChildProps) => {
              const disabled = security.accessDenied;
              return (
                <TrancheOrderBookGrid
                  columns={columns}
                  disabled={disabled}
                  hideHeader
                  initialEditState
                  isSortable={false}
                  onDelete={this.handleRequestDelete}
                  onValueChanged={editProspectRow}
                  rowKey="id"
                  rows={rows as any}
                  showSummaryRow
                />
              );
            }}
          </AccessDeniedGuard>
        </Form.Section>
        <Modal
          header="Add Investor?"
          isOpen={addInvestorModalOpen}
          onClose={this.handleToggleInvestorModal}
          onConfirm={this.handleAddInvestorConfirm}
        >
          <Form id="addInvestor" onSubmit={noop}>
            <Form.Input
              fieldName="Investor name"
              id="addInvestor"
              onChange={this.handleInvestorNameType}
              placeholder="Investor name"
              propertyName="addInvestor"
              value={addingInvestorName}
            />
          </Form>
        </Modal>
        <Modal.Compact
          header={`${
            noteModalEditingRow ? noteModalEditingRow.investorName || '' : ''
          } note`}
          isOpen={!!noteModalEditingRow}
          onClose={this.handleToggleNoteModal}
          onConfirm={this.handleToggleNoteModal}
        >
          <Form id="investorNote" onSubmit={noop}>
            <AccessDeniedGuard
              permission={DealPerspectivePermissions.administer_investor_book}
            >
              {(security: DisabledChildProps) => (
                <TextArea
                  disabled={security.accessDenied}
                  id="OrderBooksForm-InvestorNoteTextArea"
                  minRows={7}
                  name="Field"
                  onChange={this.handleTypeInvestorNote}
                  onChangeEventType="onBlur"
                  placeholder="Write a note..."
                  value={editingNoteValue}
                />
              )}
            </AccessDeniedGuard>
          </Form>
        </Modal.Compact>
        <Modal.Confirm
          header="Delete Investor?"
          isOpen={!!pendingDelete}
          message={
            pendingDelete ? this.deleteModalMessageAccessor(pendingDelete) : ''
          }
          onConfirm={this.handleConfirmDelete}
          onReject={this.handleCancelDelete}
        />
      </div>
    );
  }
}

export default TrancheOrderBook;
/* eslint-enable */
