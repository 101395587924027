import { MutationResult, MutationMethod, FetchMethod } from '../types';
import {
  BaseType,
  ObjectTypeNames,
  ValidationType,
  ID,
  ValidationMap,
} from 'types';

/* Types */

/**
 * Standard response type to any GraphQL mutation.  This is
 * the format we parse into, and not the format directly
 * returned from a mutation
 */
export type DispatchResponse<T extends BaseType> = {
  /**
   * The updated object
   * TODO: This is actually usually keyed by the method name
   *  meaning that entity is really like { [methodName: string]: T }
   * ...but this change breaks *many* typechecks.  Please fix this
   * when we have more time
   */
  entity: T | null | undefined;

  /**
   * Validation data
   */
  errors?: ValidationMap | null | undefined;

  /**
   * True if every GraphQL operation within the
   * mutation tree successfully completed
   */
  ok: boolean;
};

export type BatchEntry = (
  parent?: any | null | undefined,
  parentType?: ObjectTypeNames | null | undefined,
  validationMap?: ValidationMap,
) => Promise<DispatchResponse<any>>;

/**
 * The four standard mutation methods for all types
 */
export type Mutations<TIn extends BaseType, TOut extends BaseType> = {
  create: MutationMethod<TIn, TOut>;
  delete: MutationMethod<ID, void> | null | undefined;
  edit: MutationMethod<TIn, TOut>;
  fetch: FetchMethod<TIn> | null | undefined;
  stub: (arg0: TIn) => Promise<MutationResult<TOut>>;
  validate: (arg0: ID) => Promise<ValidationType> | null | undefined;
};

/* Constants */

export const createPrefix = 'create';

export const setPrefix = 'set';

export const deletePrefix = 'delete';

export const stubPrefix = 'stub';

export const validatePrefix = 'validate';

export const fetchPrefix = 'fetch';
