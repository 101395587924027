import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, TextLink } from 'components';
import SecondDrawModal from './SecondDrawContainer';

import './PublicBanner.scss';

const InviteHash = '#invite';

function PublicBanner() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const { hash } = location;

  useEffect(() => {
    if (hash === InviteHash) {
      setIsModalOpen(true);
    }
  }, [hash]);
  function handleModal() {
    setIsModalOpen(true);
  }

  return (
    <div className="BorrowerApplication-PublicBanner">
      {/* FIRST TIME */}
      <>
        <div className="BorrowerApplication-PublicBanner__Important">
          First-Time PPP Borrowers: Complete and send the application below
        </div>
        <div className="BorrowerApplication-PublicBanner__Detail">
          You’ll then receive an email message with next steps.{' '}
          <TextLink
            label="Instructions for first-time borrowers"
            target="newTab"
            to="https://www.loan-street.com/hubfs/Filing%20a%20PPP%20application%20as%20a%20Borrower.pdf"
          />
        </div>
      </>

      {/* SECOND TIME */}
      <>
        {isModalOpen && (
          <SecondDrawModal onClose={() => setIsModalOpen(false)} />
        )}
        <div className="BorrowerApplication-PublicBanner__Important">
          Second-Time Borrowers on this platform:{' '}
          <Button.Primary
            className="Login__Button"
            label="Log In To Apply"
            to="/"
          />
        </div>
        <div className="BorrowerApplication-PublicBanner__Detail">
          If your first loan was from this lender using LoanStreet, you may
          apply for the second draw by logging in to submit a new application.{' '}
          <TextLink
            label="Instructions for second-time borrowers"
            target="newTab"
            to="https://www.loan-street.com/hubfs/SBA%20PPP%20Materials/Applying%20for%20a%20PPP%20loan%20as%20a%20Second-Time%20Borrower.pdf"
          />
        </div>
        <div className="BorrowerApplication-PublicBanner__Important">
          Second-Time Borrowers who are new to LoanStreet:{' '}
          <Button.Primary
            className="Login__Button"
            label="Request Access"
            onClick={handleModal}
          />
        </div>
        <div className="BorrowerApplication-PublicBanner__Detail">
          If your first loan was NOT made using LoanStreet, you can apply after
          you receive access to the platform.{' '}
          <TextLink
            label="Instructions for second-time borrowers"
            target="newTab"
            to="https://www.loan-street.com/hubfs/SBA%20PPP%20Materials/Applying%20for%20a%20PPP%20loan%20as%20a%20Second-Time%20Borrower.pdf"
          />
        </div>
      </>
    </div>
  );
}

export default PublicBanner;
