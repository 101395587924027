// !!!!! THIS IS AN AUTO-GENERATED FILE, DO NOT EDIT !!!!!

import { gql } from '@apollo/client';
import { EventScheduleInput } from 'types';
import { invariant, strictConvertToInput } from 'utils';
import {
  default as validationBuilder,
  ValidationData,
  AdditionalOptions,
} from './builder/validationBuilder';
import { ApolloQueryResult } from '../types';
import { inputScrubber } from '../mutations/utils';

/* eslint-disable import/prefer-default-export */

const EventScheduleValidatorDefinition = gql`
  query validateEventSchedule($eventScheduleData: EventScheduleInput) {
    validateEventSchedule(eventScheduleData: $eventScheduleData) {
      errors
      errorFields {
        name
        value
      }
      warnings
      warningFields {
        name
        value
      }
    }
  }
`;

const propName = 'validateEventSchedule';

/**
 * Auto-Generated from the GraphQL Schema
 */
export const EventScheduleValidator = validationBuilder<EventScheduleInput>(
  EventScheduleValidatorDefinition,
  {
    build: (
      validation: (
        options: AdditionalOptions,
      ) => Promise<ApolloQueryResult<ValidationData>>,
    ) => async (entity: EventScheduleInput) => {
      const res: ApolloQueryResult<ValidationData> = await validation({
        variables: {
          eventScheduleData: { id: entity.id },
        },
      });
      invariant(
        res.data[propName],
        'Expected res.data to contain validateEventSchedule',
      );
      return res.data[propName];
    },
  },
);

export const EventScheduleEntityValidator = validationBuilder<
  EventScheduleInput
>(EventScheduleValidatorDefinition, {
  build: (
    validation: (
      options: AdditionalOptions,
    ) => Promise<ApolloQueryResult<ValidationData>>,
  ) => async (entity: EventScheduleInput) => {
    const res: ApolloQueryResult<ValidationData> = await validation({
      variables: {
        eventScheduleData: strictConvertToInput({
          ...inputScrubber(entity),
          submitted: undefined,
          approved: undefined,
        }),
      },
    });
    invariant(
      res.data[propName],
      'Expected res.data to contain validateEventSchedule',
    );
    return res.data[propName];
  },
});
