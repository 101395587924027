import * as React from 'react';
import { noop } from 'lodash';
import moment from 'moment';
import cx from 'classnames';
import CalendarHeader from './calendarHeader/CalendarHeader';
import CalendarEventView from './calendarEventView/CalendarEventView';
import { ux } from 'utils';
import {
  CalendarView,
  FiltersColumn,
  weekConst,
  monthConst,
  threeMonthConst,
} from 'components';
import {
  CalendarItemType,
  CalendarItemCategoryType,
  PermissionFlags,
} from 'types';
import { DealPerspectivePermissions, PermissionsGuard } from 'security';
import {
  VIEW_CALENDAR_PUBLIC,
  VIEW_APPLICABLE_MARGIN_CHANGES,
} from 'security/constants';
import './Calendar.scss';

const calendarPermissionRequests = [
  {
    definition: DealPerspectivePermissions.view_calendar_public,
    flag: VIEW_CALENDAR_PUBLIC,
  },
  {
    definition: DealPerspectivePermissions.view_applicable_margin_changes,
    flag: VIEW_APPLICABLE_MARGIN_CHANGES,
  },
];

const emptyArray = [];

type Props = {
  allDeals?: boolean;
  calendarItemCategories: Array<CalendarItemCategoryType>;
  taskCategories: Array<CalendarItemCategoryType>;
  calendarItems: Array<CalendarItemType>;
  categoryFilterIgnoreMap: { [key: string]: boolean };
  hideTable?: boolean;
  isListView: boolean;
  onResetFilters: () => void;
  onToggleFilter: (arg0: string) => void;
  resetCalendarViewDates: () => void;
  resetListViewDates: () => void;
  setViewEnd: (date: Date) => void;
  setViewRange: (arg0: string) => void;
  setViewStart: (date: Date) => void;
  viewEnd: Date;
  viewRange: 'week' | 'month' | '3 month';
  viewStart: Date;
};

function getViewStartFromRange(
  range: 'week' | 'month' | '3 month',
  date: Date = new Date(),
) {
  return moment(date)
    .startOf(range === threeMonthConst ? monthConst : range)
    .toDate();
}

export default class CalendarFrame extends React.Component<Props> {
  static defaultProps = {
    hideTable: false,
  };

  componentDidUpdate = (prevProps: Props) => {
    const { viewRange, viewStart } = this.props;
    if (viewRange !== prevProps.viewRange) {
      let dateToSet = viewStart;
      if (viewRange === weekConst) {
        const startDate = moment(viewStart)
          .endOf(weekConst)
          .toDate();
        if (startDate.getMonth() === new Date().getMonth())
          dateToSet = new Date();
      }
      this.setViewStart(getViewStartFromRange(viewRange, dateToSet));
    }
  };

  getCalendarSection = (sectionName: Array<string>) => {
    const calendarSections = [];
    const filterSections = this.getFilterSections();

    filterSections.forEach(filter => {
      const calendarSection = { ...filter };

      calendarSection.itemCategories = filter.itemCategories.filter(category =>
        sectionName.includes(category.slug),
      );

      calendarSections.push(calendarSection);
    });

    return calendarSections;
  };

  getFilterSections = () => {
    const {
      calendarItemCategories = emptyArray,
      taskCategories = emptyArray,
    } = this.props;
    const allCategories = [...calendarItemCategories, ...taskCategories];
    return allCategories
      ? [{ itemCategories: allCategories, headerName: '' }]
      : [];
  };

  setViewStart = (viewStart: Date) => this.props.setViewStart(viewStart);

  renderEventListView = () => {
    const {
      allDeals,
      viewStart,
      viewEnd,
      setViewEnd,
      setViewStart,
      resetListViewDates,
    } = this.props;

    return (
      <CalendarEventView
        allDeals={Boolean(allDeals)}
        resetListViewDates={resetListViewDates}
        setViewEnd={setViewEnd}
        setViewStart={setViewStart}
        viewEnd={viewEnd}
        viewStart={viewStart}
      />
    );
  };

  renderEventCalendarView = () => {
    const {
      viewRange,
      setViewRange,
      calendarItems = emptyArray,
      viewStart,
      setViewStart,
      resetCalendarViewDates,
    } = this.props;

    return (
      <div className="calendarFrame__EventCalendarView">
        <CalendarHeader
          changeViewStart={setViewStart}
          resetCalendarViewDates={resetCalendarViewDates}
          setViewRange={setViewRange}
          viewRange={viewRange}
          viewStart={viewStart}
        />
        <CalendarView
          calendarItems={calendarItems}
          viewRange={viewRange}
          viewStart={viewStart}
        />
      </div>
    );
  };

  renderCalendarFrameContent = () => {
    const { isListView, hideTable } = this.props;
    if (isListView && !hideTable) {
      return this.renderEventListView();
    }
    return this.renderEventCalendarView();
  };

  renderFilters = (permissionsMap?: Map<PermissionFlags, boolean>) => {
    let authorizedSections = this.getFilterSections();

    const {
      allDeals,
      categoryFilterIgnoreMap,
      onToggleFilter,
      onResetFilters,
    } = this.props;

    if (!allDeals && permissionsMap) {
      if (permissionsMap.get(VIEW_CALENDAR_PUBLIC)) {
        if (permissionsMap.get(VIEW_APPLICABLE_MARGIN_CHANGES)) {
          authorizedSections = this.getCalendarSection(['holiday']);
        } else {
          authorizedSections = this.getCalendarSection(['holiday', 'notice']);
        }
      }
    }

    return (
      <FiltersColumn
        categoryFilterIgnoreMap={categoryFilterIgnoreMap}
        className="calendarFrame__EventFilter"
        formId="eventFilters"
        name="Event types"
        onHideCompleted={noop}
        onReset={onResetFilters}
        onToggleFilter={onToggleFilter}
        sections={authorizedSections as any}
      />
    );
  };

  render() {
    const { isListView, hideTable, allDeals } = this.props;

    const isCalendarView = !isListView || hideTable;
    const className = cx(
      'calendarFrame',
      ux(isCalendarView, 'calendarFrame--CalendarView'),
    );
    return (
      <div className={className}>
        <div className="calendarFrame__Content">
          {this.renderCalendarFrameContent()}
        </div>
        {allDeals ? (
          this.renderFilters()
        ) : (
          <PermissionsGuard
            permissionRequests={calendarPermissionRequests as any}
          >
            {this.renderFilters}
          </PermissionsGuard>
        )}
      </div>
    );
  }
}
