import * as React from 'react';
import { LoanTrancheFormProps } from '../types';
import { Form, Header } from 'components';
import { ID } from 'types';

import './TrancheRow.scss';

function RenderHeader(text: React.ReactNode) {
  return (
    text && (
      <Header as="h5" className="caps steel">
        {text}
      </Header>
    )
  );
}

type RowProps = {
  handleDeleteTranche?: (id: ID) => void;
  header?: React.ReactNode;
  id: string;
  render: (props: LoanTrancheFormProps) => React.ReactNode;
  tranches: ReadonlyArray<LoanTrancheFormProps>;
  undivided?: boolean;
};

class TrancheRow extends React.Component<RowProps> {
  static renderColumn(
    render: (props: LoanTrancheFormProps) => React.ReactNode,
    tranche: LoanTrancheFormProps,
  ) {
    return (
      <div className="TrancheRow--FormSection--Content" key={tranche.data.id}>
        {!tranche.data ? null : render(tranche)}
      </div>
    );
  }

  render() {
    const { header, render, id, undivided, handleDeleteTranche } = this.props;
    let { tranches } = this.props;
    if (handleDeleteTranche) {
      tranches = tranches.map(tranche => ({
        ...tranche,
        handleDeleteTranche,
      }));
    }
    return (
      <Form.Section header={RenderHeader(header)} id={id} undivided={undivided}>
        <div className="TrancheRow--FormSections">
          {tranches.map(tranche => TrancheRow.renderColumn(render, tranche))}
        </div>
      </Form.Section>
    );
  }
}

export default TrancheRow;
