import { gql } from '@apollo/client';
import {
  PaymentRecordShallowFields,
  PaymentDueShallowFields,
  DocumentShallowFields,
} from '../fragments';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import formQueryBuilder from './queryBuilders/formQueryBuilder';
import { PaymentRecordType } from 'types';

type OwnProps = {
  paymentRecordId: string;
};
const PaymentRecordQueryDefinition = gql`
  query PaymentRecordQuery($id: ID) {
    paymentRecord(id: $id) {
      ...PaymentRecordShallowFields
      paymentDue {
        ...PaymentDueShallowFields
      }
    }
  }
  ${PaymentRecordShallowFields}
  ${PaymentDueShallowFields}
`;

/* eslint-disable */
const AttachedDocumentsQueryDefinition = gql`
  query PaymentRecordAttachedDocumentsQuery($paymentRecordId: ID) {
    paymentRecord(id: $paymentRecordId) {
      id
      documents {
        ...DocumentShallowFields
      }
    }
  }
  ${DocumentShallowFields}
`;

/* eslint-enable */
export const PaymentRecordQuery = standardQueryBuilder(
  PaymentRecordQueryDefinition,
  {
    disableCache: true,
    typename: 'PaymentRecordType',
    variables: (props: OwnProps) => ({
      id: props.paymentRecordId,
    }),
    results: (data: {
      paymentRecord: PaymentRecordType | null | undefined;
    }) => ({
      paymentRecord: data.paymentRecord,
    }),
  },
);

export const PaymentRecordFormQuery = formQueryBuilder(
  PaymentRecordQueryDefinition,
  {
    typename: 'PaymentRecordType' as any,
    skip: (props: OwnProps) => !(props && props.paymentRecordId),
    variables: (props: OwnProps) => ({
      id: props.paymentRecordId,
    }),
    results: (data: { paymentRecord: PaymentRecordType | null | undefined }) =>
      data.paymentRecord,
  },
);
export const PaymentRecordAttachedDocumentsQuery = standardQueryBuilder(
  AttachedDocumentsQueryDefinition,
  {
    typename: 'AttachedDocuments',
    addFetchMethod: true,
    variables: (props: OwnProps) => ({
      paymentRecordId: props.paymentRecordId,
    }),
    results: (data: {
      paymentRecord: PaymentRecordType | null | undefined;
    }) => ({
      documents: data.paymentRecord ? data.paymentRecord.documents : [],
    }),
  },
);
