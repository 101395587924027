import React from 'react';
import { DealsTableColumnShape } from '../types';
import { DealType } from 'types';
import { DataTable } from 'components';
import { accessProperty } from 'utils';

export interface DealsFooterProps {
  columns: DealsTableColumnShape[];
  deals: DealType[];
}
const DealsFooter = (props: DealsFooterProps) => {
  const { columns, deals } = props;
  return (
    <DataTable.Footer key="footer">
      {columns.map(column => {
        const { CellType, props, id, total } = column;
        if (total) {
          return (
            <CellType key={id} {...{ ...props, dollarSign: true }}>
              {deals.reduce((a, b) => a + Number(accessProperty(id, b)), 0)}
            </CellType>
          );
        } else {
          return <DataTable.Cell key={`f${id}`}></DataTable.Cell>;
        }
      })}
    </DataTable.Footer>
  );
};
export default DealsFooter;
