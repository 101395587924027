import React, { useState } from 'react';
import { DataTable } from 'components';
import { DealType } from 'types';
import AssignToDropdown from 'features/dealFlowDropdowns/AssignToDropdown';

export interface AssigneeCellProps {
  deal: DealType;
  id: string;
}
const AssigneeCell = (props: AssigneeCellProps) => {
  const { deal, id } = props;
  const value = deal.dealassignee?.name || '';
  const [editing, setEditing] = useState(false);
  const renderDropdown = controlProps => {
    return (
      <AssignToDropdown
        {...controlProps}
        deal={deal}
        dealId={deal.id}
        onEditChange={setEditing}
        shouldFetch={editing}
      />
    );
  };
  return (
    <DataTable.DropdownCell
      control={renderDropdown}
      editing={editing}
      key={id}
      onEditChange={setEditing}
    >
      {value}
    </DataTable.DropdownCell>
  );
};

export default AssigneeCell;
