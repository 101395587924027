import { MutationMethod } from '../types';
import { mutationBuilder } from './builders';
import { MutationExecutor } from './builders/mutationBuilder';
import {
  CreateCovenantMutationDefinition,
  SetCovenantMutationDefinition,
  DeleteCovenantMutationDefinition,
} from './definitions';
import { CovenantInput, CovenantType } from 'types';

const createCovenant: MutationMethod<
  CovenantInput,
  CovenantType
> = mutationBuilder<CovenantInput, CovenantType>(
  CreateCovenantMutationDefinition,
  {
    build: (mutate: MutationExecutor<CovenantType>) => covenantData =>
      mutate({
        variables: {
          covenantData,
        },
      }),
  },
);

const setCovenant: MutationMethod<
  CovenantInput,
  CovenantType
> = mutationBuilder<CovenantInput, CovenantType>(
  SetCovenantMutationDefinition,
  {
    build: (mutate: MutationExecutor<CovenantType>) => covenantData =>
      mutate({
        variables: {
          covenantData,
        },
      }),
  },
);

const deleteCovenant: MutationMethod<string, void> = mutationBuilder<
  string,
  void
>(DeleteCovenantMutationDefinition, {
  build: mutate => (id: string) => mutate({ variables: { id } }),
});

const CovenantMutationMethods = {
  createCovenant,
  setCovenant,
  deleteCovenant,
};

export default CovenantMutationMethods;
