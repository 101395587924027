import { mutationBuilder } from './builders';
import {
  CreateSbaPppRoundTwoLenderApplicationMutationDefinition as CreateMutationDefinition,
  SetSbaPppRoundTwoLenderApplicationMutationDefinition as SetMutationDefinition,
} from './definitions';
import {
  SbaPppRoundTwoLenderApplicationType,
  SbaPppRoundTwoLenderApplicationInput,
} from 'types';

const SbaPppRoundTwoLenderApplicationMutations = {
  createSbaPppRoundTwoLenderApplication: mutationBuilder<
    SbaPppRoundTwoLenderApplicationInput,
    SbaPppRoundTwoLenderApplicationType
  >(CreateMutationDefinition, {
    build: mutate => mutationData =>
      mutate({
        variables: { sbaPppRoundTwoLenderApplicationData: mutationData },
      }),
  }),
  setSbaPppRoundTwoLenderApplication: mutationBuilder<
    SbaPppRoundTwoLenderApplicationInput,
    SbaPppRoundTwoLenderApplicationType
  >(SetMutationDefinition, {
    build: mutate => mutationData =>
      mutate({
        variables: { sbaPppRoundTwoLenderApplicationData: mutationData },
      }),
  }),
};

export default SbaPppRoundTwoLenderApplicationMutations;
