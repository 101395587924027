import * as React from 'react';
import { Redirect, useParams, useLocation } from 'react-router-dom';
import { TabNavigationFrame } from 'components';
import { RouteTable } from 'routing';

import { peopleRoutes } from 'routing/routes/DealSubRoutes';
import './DealPeopleView.scss';

type Props = {
  showBorrower: boolean;
  showCoLenders: boolean;
  showLeadLender: boolean;
  showMyTeam: boolean;
  showServicingAgents: boolean;
};

function DealPeopleView(props: Props) {
  const location = useLocation();
  const { dealId } = useParams();

  function getDealPeopleTabs() {
    const {
      showMyTeam,
      showCoLenders,
      showBorrower,
      showServicingAgents,
      showLeadLender,
    } = props;

    const routes = [];

    if (showMyTeam) {
      routes.push({
        text: 'My Team',
        to: RouteTable.deal.toDealPeopleMyTeam(dealId),
      });
    }

    if (showCoLenders) {
      routes.push({
        text: 'Co-Lenders',
        to: RouteTable.deal.toDealPeopleCoLenders(dealId),
      });
    }

    if (showBorrower) {
      routes.push({
        text: 'Borrower',
        to: RouteTable.deal.toDealPeopleBorrower(dealId),
      });
    }

    if (showServicingAgents) {
      routes.push({
        text: 'Servicing Agents',
        to: RouteTable.deal.toDealPeopleServicingAgents(dealId),
      });
    }

    if (showLeadLender) {
      routes.push({
        text: 'Lead Lender',
        to: RouteTable.deal.toDealPeopleLeadLender(dealId),
      });
    }
    return routes;
  }

  if (location.pathname === RouteTable.deal.toDealPeople(dealId)) {
    return <Redirect to={RouteTable.deal.toDealPeopleMyTeam(dealId)} />;
  }

  return (
    <div className="DealPeopleView">
      <TabNavigationFrame
        routeContext={peopleRoutes}
        tabs={getDealPeopleTabs()}
      />
    </div>
  );
}

export default DealPeopleView;
