import uuid from 'uuid/v4';

import {
  DealMutationMethods,
  FeeCalculationRuleMutationMethods,
  FeeMutationMethods,
  LoanTrancheTierMutationMethods,
  TierFeePercentageMutationMethods,
  PrepaymentPenaltyRangeMutationMethods,
  LoanTrancheMutationMethods,
} from '../../../graphql/mutations';
import { FeesFetchMethod } from '../../../graphql/queries/FeesFormQuery';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import { DealType } from 'types';

const initialFee: DealType = {
  id: uuid(),
  __typename: 'DealType',
  loantrancheSet: [],
};

const lifecycleMethods = {
  fetch: FeesFetchMethod,
  mutations: {
    ...DealMutationMethods,
    ...TierFeePercentageMutationMethods,
    ...FeeCalculationRuleMutationMethods,
    ...FeeMutationMethods,
    ...LoanTrancheTierMutationMethods,
    ...PrepaymentPenaltyRangeMutationMethods,
    ...LoanTrancheMutationMethods,
  },
  validators: {},
};

const feesFormRedux = createForm<DealType, DealType>(
  Directory.FeesKey,
  initialFee,
  lifecycleMethods,
  {
    enableAutoSave: true,
  },
);

export default feesFormRedux;
