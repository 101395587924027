import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@loanstreet-usa/design-system';
import isEqual from 'lodash/isEqual';
import NoAccess from './noAccess/NoAccess';

import {
  CheckPermissionPerspectiveContextType,
  PermissionKey,
  PermissionPerspectiveContextType,
} from './types';

import { PermissionDefinition } from './definitions/types';

import { getPermissionKey } from './methods/getEntityKeyMap';

import { Disable } from './constants';
import { ID } from 'types';
import { invariant, isDebug, isPermissionDebug } from 'utils';

import './Secured.scss';

const NoMount = 'no_mount';

/* eslint-disable react/no-multi-comp */

const PermissionSummary = (
  e: PermissionDefinition | Array<PermissionDefinition>,
) => {
  if (!Array.isArray(e)) {
    return `Perspective: ${e.perspective} - Flag ${e.flag}`;
  }
  const first = e[0];
  return `Perspective: ${first.perspective} One Of: $${e
    .map(p => p.flag)
    .join(', ')}`;
};

function DebugFrame({
  children,
  permission,
  isPermitted,
}: {
  children: React.ReactNode;
  isPermitted: boolean;
  permission: PermissionDefinition | Array<PermissionDefinition>;
}) {
  return (
    <div className="debugFrame" title={PermissionSummary(permission)}>
      {!isPermitted && <Icon icon="exclamation-triangle" size="lg" />}
      {children}
    </div>
  );
}

export type DisabledChildProps = {
  accessDenied?: boolean;
};

type RenderProps = {
  /**
   * A render prop for rendering all children of this component
   */
  children: (childProps: DisabledChildProps) => React.ReactNode;

  /**
   * If user lacks access to the specified resource, what action
   * should this component take?
   * disable: pass { accessDenied: true } to the children method
   * no_mount: children will not be rendered
   *
   */
  denyAction: 'disable' | 'no_mount' | 'no_access';
};

/**
 * Apparently this is how you define combined exact types :/
 * An intersection of exact isn't valid
 * https://github.com/facebook/flow/issues/2626
 */
export interface SecuredProps extends RenderProps {
  permission: PermissionDefinition | Array<PermissionDefinition>;
  perspectiveId?: ID;
}

const disableProps: DisabledChildProps = {
  accessDenied: true,
};

const enableProps: DisabledChildProps = {
  accessDenied: false,
};

const emptyProps = {};

/**
 * Wrap a section of the application that requires a certain permission.
 * The section will either be not mounted, or passed the disabled prop
 * depending on the value of denyAction
 */
class Secured extends React.Component<SecuredProps> {
  static contextTypes = {
    isPermitted: PropTypes.func,
    perspectiveId: PropTypes.string,
  };

  static defaultProps = {
    denyAction: 'no_mount',
  };

  _isPermittedResult: boolean | null | undefined = null;

  permissionKey: PermissionKey;

  constructor(
    props: SecuredProps,
    context: CheckPermissionPerspectiveContextType &
      PermissionPerspectiveContextType,
  ) {
    super(props);
    invariant(props.permission, 'props.permission is required');

    if (Array.isArray(props.permission)) {
      invariant(
        props.permission.length > 0,
        'Permission prop cannot be an empty array',
      );
      invariant(
        new Set(props.permission.map(e => e.perspective)).size === 1,
        'All permission definitions should have the same perspective',
      );
    }
    // Prefer props provided perspectiveId, but fallback to context
    const perspectiveId = props.perspectiveId || context.perspectiveId;

    if (
      !Array.isArray(props.permission) &&
      props.permission.perspective !== 'global'
    ) {
      invariant(perspectiveId, 'Secured failed to determine the perspectiveId');
    }

    invariant(
      context && context.isPermitted,
      'Secured component must be nested within the GlobalPermissionPerspective',
    );

    const actualPerspective = !Array.isArray(props.permission)
      ? props.permission.perspective
      : props.permission[0].perspective;
    // e.g. 2_deal
    this.permissionKey = getPermissionKey(actualPerspective, perspectiveId);

    invariant(this.permissionKey, 'undefined permission key');
  }

  UNSAFE_componentWillReceiveProps(nextProps: SecuredProps) {
    if (isDebug()) {
      invariant(
        this.props.denyAction === nextProps.denyAction,
        'denyAction - props cannot change',
      );
      invariant(
        isEqual(this.props.permission, nextProps.permission),
        'props.permission cannot change',
      );
    }
  }

  getContext: () => CheckPermissionPerspectiveContextType = () =>
    this.context as CheckPermissionPerspectiveContextType;

  isPermitted: () => boolean = () => {
    if (this._isPermittedResult === null) {
      const context = this.getContext();

      if (Array.isArray(this.props.permission)) {
        this._isPermittedResult = this.props.permission.some(p =>
          context.isPermitted(this.permissionKey, p.flag),
        );
      } else {
        this._isPermittedResult = context.isPermitted(
          this.permissionKey,
          this.props.permission.flag,
        );
      }
    }
    return Boolean(this._isPermittedResult);
  };

  renderNoMount() {
    const permitted = this.isPermitted();

    return !permitted ? null : this.props.children(emptyProps);
  }

  renderDisable() {
    const permitted = this.isPermitted();

    return this.props.children(permitted ? enableProps : disableProps);
  }

  renderNoAccess() {
    const permitted = this.isPermitted();

    return permitted ? this.props.children(enableProps) : <NoAccess />;
  }

  renderSwitch() {
    switch (this.props.denyAction) {
      case Disable:
        return this.renderDisable();
      case NoMount:
        return this.renderNoMount();
      default:
        return this.renderNoAccess();
    }
  }

  render() {
    if (!isPermissionDebug()) return this.renderSwitch();

    return (
      <DebugFrame
        isPermitted={this.isPermitted()}
        permission={this.props.permission}
      >
        {this.renderSwitch()}
      </DebugFrame>
    );
  }
}

export default Secured;
