import * as React from 'react';
import { InstructionsPropsType } from '../types';
import { LenderActionButtons } from './LenderActionButtons';
import { BorrowerActionButtons } from './BorrowerActionButtons';

export function InstructionsCTAButtons(props: InstructionsPropsType) {
  const { sbaPppForgivenessWorkflow } = props;
  const { isLender } = sbaPppForgivenessWorkflow;
  return isLender ? (
    <LenderActionButtons {...props} />
  ) : (
    <BorrowerActionButtons {...props} />
  );
}
