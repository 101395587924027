import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { DragDropContext } from 'react-dnd';

import HTML5Backend from 'react-dnd-html5-backend';
import dealRoutes from '../../routing/routes/DealRoutes';
import { RoutePortal } from 'routing';

import './DealContext.scss';

/**
 * DealContext serves as the Parent component for all views that occur
 * within a specific Deal.  This provides the side menu, header, and rendering
 * of all child routes.
 */
function DealContext() {
  return (
    <div id="DealContext">
      <div className="DealContext-Body">
        <RoutePortal context={dealRoutes} />
      </div>
    </div>
  );
}

export default withRouter<any, any>(DragDropContext(HTML5Backend)(DealContext));
