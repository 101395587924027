import { ID } from '../../types/primitives';

export const ENTER_DRAGHOVER = 'dataroomrow_ENTER_DRAGHOVER';
export const LEAVE_DRAGHOVER = 'dataroomrow_LEAVE_DRAGHOVER';

export function enterDragHover(folderId: ID, canDropInRootFolder: boolean) {
  return {
    type: ENTER_DRAGHOVER,
    payload: {
      id: folderId,
      canDropInRootFolder,
    },
  };
}

export function leaveDragHover() {
  return {
    type: LEAVE_DRAGHOVER,
  };
}
