import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import BorrowerAdditionalInformationForm from './BorrowerAdditionalInformationForm';
import { RouteParams, withRouteParams } from 'routing';
import { FormMediator, withLoadingIndicator } from 'components';
import AdditionalSbaPppBorrowerInformation from 'lsredux/reducer/forms/additionalSbaPppBorrowerInformation';
import { setFormDisabled } from 'lsredux';
import { AdditionalSbaPppBorrowerInformationFormQuery, DealQuery } from 'lsgql';
import { isWholeNumber } from 'utils';

const mapDispatchToProps = (dispatch: any) => {
  const generated = AdditionalSbaPppBorrowerInformation.actions.generateActions(
    dispatch,
  );

  const changeAdditionalInformation = (value: any, fieldId: string) =>
    generated.mutateProperty(value, [
      'additionalSbaPppBorrowerInformation',
      fieldId,
    ]);

  const changeOwnershipInfo = (value: any, fieldId: string[]) =>
    generated.mutateProperty(value, [
      'sbaPppLoanApplication',
      'sbapppapplicantownershipSet',
      ...fieldId,
    ]);

  const disableForm = (disable: boolean) =>
    dispatch(
      setFormDisabled('AdditionalSbaPppBorrowerInformationForm', disable),
    );

  const initializeBorrowerInfo = (dealId?: string) => {
    generated.addEntity('additionalSbaPppBorrowerInformation', {
      id: uuid(),
      dealId,
      __typename: 'AdditionalSbaPppBorrowerInformationType',
      jobsCreated: '0',
      jobsRetained: '0',
      payrollAmount: '0',
      mortgageInterestAmount: '0',
      rentAmount: '0',
      utilitiesAmount: '0',
      refinanceEidlAmount: '0',
    });
  };

  return {
    ...generated,
    disableForm,
    changeAdditionalInformation,
    changeOwnershipInfo,
    initializeBorrowerInfo,
  };
};

const BorrowerAdditionalInformationFormContainer = compose(
  withRouteParams([RouteParams.dealId]),
  DealQuery,
  AdditionalSbaPppBorrowerInformationFormQuery,
  withLoadingIndicator('loading'),
  withProps((ownProps: any) => {
    let canEdit = true;

    if (
      ownProps &&
      ownProps.preExistingEntity &&
      ownProps.preExistingEntity.additionalSbaPppBorrowerInformation
    ) {
      // We have an object
      const data =
        ownProps.preExistingEntity.additionalSbaPppBorrowerInformation;
      if (isWholeNumber(data.id)) {
        canEdit = !!data.canEditState;
      }
    }
    return { canEditState: canEdit };
  }),
  connect(
    AdditionalSbaPppBorrowerInformation.mapStateToProps,
    mapDispatchToProps,
  ),
  FormMediator({
    formId: 'AdditionalSbaPppBorrowerInformationForm',
    baseObjectTypeName: 'Deal',
    blurEventForm: false,
    disableFrame: true,
  }),
)(BorrowerAdditionalInformationForm);

export default BorrowerAdditionalInformationFormContainer;
