import * as React from 'react';
import { Redirect } from 'react-router-dom';
import camel from 'lodash/camelCase';
import { useQuery, FetchPolicy } from '@apollo/client';
import { DocumentNode } from '../../types';
import { BaseType, ObjectCamelBaseTypeNames } from 'types';
import { invariant, isWholeNumber } from 'utils';
import PermissionPerspective from 'security/authorization/PermissionPerspective';
import { RouteTable } from 'routing/RouteTable';

/* eslint-disable react/no-multi-comp */

/**
 *
  The query-hoc returns a new function, this should do the same
  this is to be wrapped by the HoC
 */
function WithPermissionInspector<PIn>(
  AuthWrapped: React.ComponentType<PIn>,
  typeName: ObjectCamelBaseTypeNames,
) {
  invariant(typeName, 'typeName must be provided for permissionQueryBuilder');

  return function PermissionInspector(props: any) {
    const { loading } = props;
    const data = props?.[typeName];

    if (!loading && !data) {
      return <Redirect to={RouteTable.application.to404} />;
    }

    return (
      <PermissionPerspective data={data} disableLoader>
        <AuthWrapped {...(props as any)} />
      </PermissionPerspective>
    );
  };
}

type Args<R extends BaseType, TVariables, PIn> = {
  disableCache?: boolean;
  results: (data: {
    [key: string]: R | null | undefined;
  }) => {
    [key: string]: R | null | undefined;
  };
  skip?: (props: PIn) => boolean;
  typename: string;
  variables?: (props: PIn) => TVariables;
};

/**
 * permissionQueryBuilder automatically sets up a PermissionPerspective
 * for the query response object, based upon it's `permissions` property.
 * A null or undefined `permissions` property is treated as an error condition
 * @param {*} query
 * @param {*} optionsObj
 * @param {*} propsObj
 * @param {*} skip
 */
function permissionQueryBuilder<
  R extends BaseType,
  TData extends {},
  TVariables,
  PIn
>(query: DocumentNode, args: Args<R, TVariables, PIn>) {
  invariant(query, 'GraphQL query must be provided');
  invariant(args, 'permissionQueryBuilder.args must be provided');
  invariant(args.results, 'Results method must be provided');
  invariant(args.typename, 'args.typename must be provided');

  const displayName = `${args.typename}PermissionQuery`;

  const fetchPolicy: FetchPolicy = args.disableCache
    ? 'network-only'
    : undefined;

  return function QueryBuilderHoC(
    Wrapped: React.ComponentType<any>,
  ): React.ComponentType<any> {
    invariant(Wrapped, 'A component must be provided');

    const Composed = WithPermissionInspector(
      Wrapped,
      (camel(args.typename) as any) as ObjectCamelBaseTypeNames,
    );
    return function PermissionQueryBuilder(props: PIn) {
      function shouldSkip() {
        if (args.variables) {
          const vars: { id?: string } = args.variables(props);
          if (vars && vars.id) {
            if (!isWholeNumber(vars.id)) return true;
          }
        }

        return args.skip !== undefined ? args.skip(props) : false;
      }

      const skip = shouldSkip();
      const vars = skip || !args.variables ? undefined : args.variables(props);
      const { loading, error, data } = useQuery(query, {
        variables: vars,
        skip: skip,
        displayName: displayName,
        fetchPolicy,
      });
      const queryResult =
        !loading && data && args.results ? args.results(data) : null;

      /* eslint-disable prefer-destructuring */
      // result returns loading === true if skip was true
      const isLoading = (!skip && loading) || Boolean((props as any).loading);

      return (
        <Composed
          {...props}
          {...queryResult}
          error={error}
          loading={isLoading}
        />
      );
    };
  };
}

export default permissionQueryBuilder;
