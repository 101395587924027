import capitalize from 'lodash/capitalize';
import memoize from 'lodash/memoize';
import words from 'lodash/words';

/**
 * Capitalize string separated by `_` (underscores) or ` ` (whitespace);
 * @param  {string} sentence    String to be capitalized
 * @return {string}             Return capitalized string
 */
const capitalizeSentence = memoize((sentence: string) =>
  words(sentence)
    .map(word => capitalize(word))
    .join(' '),
);

export default capitalizeSentence;
