import React, { useEffect } from 'react';
import { Modal, Form, WrappedMediatorFormProps } from 'components';
import { FormFieldsType, CancelLoanType, ID } from 'types';
import './CancelLoanForm.scss';

interface Props extends WrappedMediatorFormProps<CancelLoanType> {
  isOpen: boolean;
  onClose: () => void;
  dealId: ID;
  dealName: string;
}
const fields: FormFieldsType = {
  reasonForCanceling: {
    id: 'reasonForCanceling',
    propertyName: 'reasonForCanceling',
    fieldName: 'Reason for canceling',
    options: [
      {
        id: 'VOLUNTARY_WITHDRAWAL',
        label: 'Voluntary withdrawal',
      },
      {
        id: 'LOAN_MADE_ELSEWHERE',
        label: 'Loan made elsewhere',
      },
      {
        id: 'UNSUPPORTED_BY_DOCUMENTS',
        label: 'Unsupported by documents',
      },
      {
        id: 'OTHER',
        label: 'Other',
      },
    ],
    required: true,
  },
  otherReason: {
    id: 'otherReason',
    propertyName: 'otherReason',
    fieldName: 'If other is selected, please provide reason',
    required: true,
    width: '290px',
    rows: 3,
  },
};
const CancelLoan = 'CancelLoan';
const CancelLoanForm = (props: Props) => {
  const {
    isOpen,
    onClose,
    data,
    mutateProperty,
    dealId,
    onSubmit,
    dealName,
  } = props;
  useEffect(() => {
    mutateProperty(dealId, 'dealId');
  });
  return (
    <Modal
      className="CancelLoanModal steel"
      confirmButtonText="Archive Loan"
      header="Confirm Loan Cancellation"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onSubmit}
      requireResponse
      width="Half"
    >
      <Form.Text
        value={`Canceling ${dealName} loan cannot be undone. The loan wil be archived and will no longer be visible in your portfolio.`}
      />
      {Form.FieldRenderer(
        Form.Radio,
        {
          ...fields.reasonForCanceling,
          onChange: mutateProperty,
        },
        props,
        CancelLoan,
        data.id,
      )}
      {data.reasonForCanceling === 'OTHER' &&
        Form.FieldRenderer(
          Form.TextArea,
          {
            ...fields.otherReason,
            onChange: mutateProperty,
          },
          props,
          CancelLoan,
          data.id,
        )}
    </Modal>
  );
};
export default CancelLoanForm;
