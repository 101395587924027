import * as React from 'react';
import {
  OrganizationPermissions,
  InvitedUsersSection,
} from '../../../../types';
import { DisplayTable, Header, IconButton } from 'components';
import { ID, DealInstitutionType } from 'types';
import { MountGuard } from 'security';

const sectionBaseClass = 'Section';
const sectionRowBaseClass = `${sectionBaseClass}-SectionRow`;

type Props = {
  editOrganizationRoute?: (organizationId: ID) => string;
  organization: DealInstitutionType | InvitedUsersSection;
  permissions?: OrganizationPermissions;
};

class CompanyRow extends React.Component<Props> {
  render() {
    const { organization, permissions, editOrganizationRoute } = this.props;
    return (
      <DisplayTable.TR className={sectionRowBaseClass}>
        <DisplayTable.TD
          className={`${sectionRowBaseClass}-Contact`}
          colSpan={3}
        >
          <Header as="h2" className={`${sectionRowBaseClass}-Name`}>
            {organization.name || 'Unknown'}
          </Header>
          {permissions && editOrganizationRoute && (
            <MountGuard permission={permissions.changePermission}>
              <IconButton
                alt="Edit Institution"
                className={`${sectionRowBaseClass}--EditButton`}
                icon="pencil"
                to={editOrganizationRoute(organization.id)}
              />
            </MountGuard>
          )}
        </DisplayTable.TD>
      </DisplayTable.TR>
    );
  }
}

export default CompanyRow;
