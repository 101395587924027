import * as React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'semantic-ui-react';
import './NoAccess.scss';

type Props = {
  alt?: string;
};

function NoAccess({ alt }: Props) {
  return (
    <div className="NoAccess">
      <h1 className="ui header">
        <Icon alt={alt} className="NoAccess-Icon" name="shield" size="huge" />
        <div className="content">
          Access Denied
          <div className="sub header">
            You don’t have permission to view this page. If you think this is an
            error, please contact your loan administrator for access.
          </div>
        </div>
      </h1>
    </div>
  );
}

NoAccess.propTypes = {
  alt: PropTypes.string,
};

/* eslint-disable react/default-props-match-prop-types */
NoAccess.defaultProps = {
  alt: '',
};

export default NoAccess;
