import * as React from 'react';
import { LoanTrancheFormProps } from '../types';
import TrancheGroup from './TrancheGroup';

import {
  getAmortization,
  isFixedInterest,
  isFixedPayment,
  isSingleDraw,
  isLetterOfCredit,
  isMultiDraw,
  isRevolver,
  isSwingline,
} from './utils';
import { Form } from 'components';

const loanTranche = 'LoanTranche';
const width = '158px';
const amortization = 'LoanTrancheAmortization';

class DatesFields extends React.Component<LoanTrancheFormProps> {
  fields: any = {};

  common: any = {};

  fixedInterestSingleDraw: any = {};

  fixedInterestMultiDraw: any = {};

  fixedPaymentSingleDraw: any = {};

  fixedPaymentMultiDraw: any = {};

  /* eslint-disable-next-line */
  handleUpdateRevSwingLOCProperty = (
    value: string | null | undefined,
    fieldId: string,
  ) => {
    this.props.onUpdateLoanTrancheRevolverSwinglineLOCData({
      ...this.props.data.loantrancherevolverswinglinelocdata,
      [fieldId]: value,
    });
  };

  constructor(props: LoanTrancheFormProps) {
    super(props);

    this.fields = {
      initialFundingDate: {
        id: 'initialFundingDate',
        propertyName: 'initialFundingDate',
        fieldName: 'Origination Date',
        onChange: this.props.mutateProperty,
        width,
        tabIndex: this.props.tabIndex,
      },
      finalMaturityDate: {
        id: 'finalMaturityDate',
        propertyName: 'finalMaturityDate',
        fieldName: 'Final Maturity Date',
        onChange: this.props.mutateProperty,
        width,
        tabIndex: this.props.tabIndex,
      },
      firstPaymentDate: {
        id: 'firstPaymentDate',
        propertyName: 'firstPaymentDate',
        fieldName: 'First Payment Date',
        onChange: this.props.mutateAmortization,
        width,
        tabIndex: this.props.tabIndex,
      },
      commitmentTermination: {
        id: 'commitmentTerminationDate',
        propertyName: 'commitmentTerminationDate',
        fieldName: 'Commitment Termination Date',
        onChange: this.props.mutateProperty,
        width,
        tabIndex: this.props.tabIndex,
      },
      originalTerm: {
        id: 'originalTerm',
        propertyName: 'originalTerm',
        fieldName: 'Original Term',
        onChange: this.props.mutateProperty,
        width,
        tabIndex: this.props.tabIndex,
        suffix: 'years',
      },
      commitmentPeriod: {
        id: 'commitmentPeriod',
        propertyName: 'commitmentPeriod',
        fieldName: 'Commitment Period',
        onChange: this.props.mutateProperty,
        width,
        tabIndex: this.props.tabIndex,
        disabled: true,
      },
      minimumDrawAmount: {
        id: 'drawAmountMinimum',
        propertyName: 'drawAmountMinimum',
        fieldName: 'Minimum Draw Amount',
        onChange: this.props.mutateProperty,
        width,
        tabIndex: this.props.tabIndex,
      },
      maxDrawNumber: {
        id: 'maxDrawNumber',
        propertyName: 'maxDrawNumber',
        fieldName: 'Maximum # of Draws',
        onChange: this.props.mutateProperty,
        width,
        tabIndex: this.props.tabIndex,
        disabled: true,
      },
      startDate: {
        id: 'startDate',
        propertyName: 'startDate',
        fieldName: 'Start Date',
        onChange: this.handleUpdateRevSwingLOCProperty,
        width,
        tabIndex: this.props.tabIndex,
      },
      terminationDate: {
        id: 'terminationDate',
        propertyName: 'terminationDate',
        fieldName: 'Termination Date',
        onChange: this.handleUpdateRevSwingLOCProperty,
        width,
        tabIndex: this.props.tabIndex,
      },
      maximumDuration: {
        id: 'maximumDuration',
        propertyName: 'maximumDuration',
        fieldName: 'Maximum Duration',
        suffix: 'days',
        onChange: this.handleUpdateRevSwingLOCProperty,
        width,
        tabIndex: this.props.tabIndex,
      },
    };

    this.common = {
      finalMaturityDate: this.fields.finalMaturityDate,
      originalTerm: this.fields.originalTerm,
      initialFundingDate: this.fields.initialFundingDate,
      firstPaymentDate: this.fields.firstPaymentDate,
    };

    this.fixedInterestSingleDraw = {
      finalMaturityDate: this.fields.finalMaturityDate,
      firstPaymentDate: this.fields.firstPaymentDate,
      originalTerm: this.fields.originalTerm,
      initialFundingDate: this.fields.initialFundingDate,
    };

    this.fixedInterestMultiDraw = {
      commitmentTermination: this.fields.commitmentTermination,
      finalMaturityDate: this.fields.finalMaturityDate,
      firstPaymentDate: this.fields.firstPaymentDate,
      originalTerm: this.fields.originalTerm,
      initialFundingDate: this.fields.initialFundingDate,
    };

    this.fixedPaymentSingleDraw = {
      commitmentTermination: this.fields.commitmentTermination,
      finalMaturityDate: this.fields.finalMaturityDate,
      originalTerm: this.fields.originalTerm,
      firstPaymentDate: this.fields.firstPaymentDate,
      initialFundingDate: this.fields.initialFundingDate,
    };

    this.fixedPaymentMultiDraw = {
      commitmentPeriod: this.fields.commitmentPeriod,
      commitmentTermination: this.fields.commitmentTermination,
      finalMaturityDate: this.fields.finalMaturityDate,
      firstPaymentDate: this.fields.firstPaymentDate,
      maxDrawNumber: this.fields.maxDrawNumber,
      minimumDrawAmount: this.fields.minimumDrawAmount,
      originalTerm: this.fields.originalTerm,
      initialFundingDate: this.fields.initialFundingDate,
    };
  }

  renderCommon = () => {
    const { data } = this.props;
    const amortizationData = getAmortization(data);

    if (!amortizationData) return null;

    const amortProps = { ...this.props, data: amortizationData };

    return (
      <>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Calendar,
            this.common.initialFundingDate,
            this.props,
            loanTranche,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Calendar,
            this.common.finalMaturityDate,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Calendar,
            this.common.firstPaymentDate,
            amortProps,
            amortization,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Decimal,
            this.common.originalTerm,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
      </>
    );
  };

  renderFixedPaymentMultiDraw = () => {
    const { data } = this.props;
    const amortizationData = getAmortization(data);

    if (!amortizationData) return null;

    const amortProps = { ...this.props, data: amortizationData };

    return (
      <>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Calendar,
            this.fixedPaymentMultiDraw.initialFundingDate,
            this.props,
            loanTranche,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Calendar,
            this.fixedPaymentMultiDraw.finalMaturityDate,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Calendar,
            this.fixedPaymentMultiDraw.firstPaymentDate,
            amortProps,
            amortization,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Calendar,
            this.fixedPaymentMultiDraw.commitmentTermination,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Decimal,
            this.fixedPaymentMultiDraw.originalTerm,
            this.props,
            loanTranche,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Numeric,
            this.fixedPaymentMultiDraw.commitmentPeriod,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Money,
            this.fixedPaymentMultiDraw.minimumDrawAmount,
            this.props,
            loanTranche,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Numeric,
            this.fixedPaymentMultiDraw.maxDrawNumber,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
      </>
    );
  };

  renderFixedPaymentSingleDraw = () => {
    const { data } = this.props;
    const amortizationData = getAmortization(data);

    if (!amortizationData) return null;

    const amortProps = { ...this.props, data: amortizationData };

    return (
      <>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Calendar,
            this.fixedPaymentSingleDraw.initialFundingDate,
            this.props,
            loanTranche,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Calendar,
            this.fixedPaymentSingleDraw.finalMaturityDate,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Calendar,
            this.fixedPaymentSingleDraw.firstPaymentDate,
            amortProps,
            amortization,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Decimal,
            this.fixedPaymentSingleDraw.originalTerm,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
      </>
    );
  };

  renderFixedInterestMultiDraw = (amortizationProps: any) => {
    const { data } = this.props;

    return (
      <>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Calendar,
            this.fixedPaymentMultiDraw.initialFundingDate,
            this.props,
            loanTranche,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Calendar,
            this.fixedPaymentMultiDraw.finalMaturityDate,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Calendar,
            this.fixedPaymentMultiDraw.firstPaymentDate,
            amortizationProps,
            amortization,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Calendar,
            this.fixedPaymentMultiDraw.commitmentTermination,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Decimal,
            this.fixedPaymentMultiDraw.originalTerm,
            this.props,
            loanTranche,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Numeric,
            this.fixedPaymentMultiDraw.commitmentPeriod,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Numeric,
            this.fixedPaymentMultiDraw.maxDrawNumber,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
      </>
    );
  };

  renderFixedInterestSingleDraw = (amortizationProps: any) => {
    const { data } = this.props;

    return (
      <>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Calendar,
            this.fixedInterestSingleDraw.initialFundingDate,
            this.props,
            loanTranche,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Calendar,
            this.fixedInterestSingleDraw.finalMaturityDate,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Calendar,
            this.fixedInterestSingleDraw.firstPaymentDate,
            amortizationProps,
            amortization,
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Decimal,
            this.fixedInterestSingleDraw.originalTerm,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
      </>
    );
  };

  renderRevolverSwinglineLOC = () => {
    const { data } = this.props;

    const letterOfCredit = isLetterOfCredit(data);
    const swingline = isSwingline(data);

    return (
      <>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Calendar,
            {
              ...this.fields.startDate,
              value:
                (data.loantrancherevolverswinglinelocdata &&
                  data.loantrancherevolverswinglinelocdata.startDate) ||
                '',
            },
            this.props,
            loanTranche,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Calendar,
            {
              ...this.fields.terminationDate,
              value:
                (data.loantrancherevolverswinglinelocdata &&
                  data.loantrancherevolverswinglinelocdata.terminationDate) ||
                '',
            },
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
        {(letterOfCredit || swingline) && (
          <Form.Group>
            {Form.FieldRenderer(
              Form.Numeric,
              {
                ...this.fields.maximumDuration,
                value:
                  (data.loantrancherevolverswinglinelocdata &&
                    data.loantrancherevolverswinglinelocdata.maximumDuration) ||
                  '',
              },
              this.props,
              loanTranche,
              data.id,
            )}
          </Form.Group>
        )}
      </>
    );
  };

  renderFork = () => {
    const { data } = this.props;

    const isSingle = isSingleDraw(data);
    const isLOC = isLetterOfCredit(data);
    const isMulti = isMultiDraw(data);
    const isRev = isRevolver(data);
    const isSwing = isSwingline(data);
    const fixedInterest = isFixedInterest(data);
    const fixedPayment = isFixedPayment(data);

    const amortizationData = getAmortization(data);
    if (!amortizationData) return null;
    const amortizationProps = { ...this.props, data: amortizationData };

    if (fixedInterest && isSingle)
      return this.renderFixedInterestSingleDraw(amortizationProps);
    if (fixedInterest && isMulti)
      return this.renderFixedInterestMultiDraw(amortizationProps);

    if (fixedPayment && isSingle) return this.renderFixedPaymentSingleDraw();
    if (fixedPayment && isMulti) return this.renderFixedPaymentMultiDraw();

    if (isRev || isSwing || isLOC) return this.renderRevolverSwinglineLOC();

    return this.renderCommon();
  };

  render() {
    return <TrancheGroup>{this.renderFork()}</TrancheGroup>;
  }
}

export default DatesFields;
