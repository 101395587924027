import React from 'react';
import { Form } from 'components';
import {
  FormFieldsType,
  SbaPppLoanFinancialsQuarter,
  SbaPppLoanFinancialsReferenceQuarter,
} from 'types';

// import './RoundTwoGrossReciepts.scss';

const quarterOptions: Array<{
  text: string;
  value: SbaPppLoanFinancialsQuarter;
}> = [
  { text: '1Q 2020', value: 'Q1_2020' },
  { text: '2Q 2020', value: 'Q2_2020' },
  { text: '3Q 2020', value: 'Q3_2020' },
  { text: '4Q 2020', value: 'Q4_2020' },
  { text: '2020 Annual', value: 'ANNUAL' },
];
const referenceQuarterOptions: Array<{
  text: string;
  value: SbaPppLoanFinancialsReferenceQuarter;
}> = [
  { text: '1Q 2019', value: 'Q1_2019' },
  { text: '2Q 2019', value: 'Q2_2019' },
  { text: '3Q 2019', value: 'Q3_2019' },
  { text: '4Q 2019', value: 'Q4_2019' },
  { text: '2019 Annual', value: 'ANNUAL' },
];

const fields: FormFieldsType = {
  quarter: {
    id: 'quarter',
    propertyName: 'quarter',
    fieldName: '2020 Quarter',
    options: quarterOptions,
    placeholder: 'Please Select',
    width: '120px',
  },
  grossReceipts: {
    id: 'grossReceipts',
    propertyName: 'grossReceipts',
    fieldName: 'Gross Receipts',
    width: '158px',
  },
  referenceQuarter: {
    id: 'referenceQuarter',
    propertyName: 'referenceQuarter',
    fieldName: 'Reference Quarter',
    options: referenceQuarterOptions,
    placeholder: 'Please Select',
    width: '120px',
    disabled: true,
    disabledReadable: true,
  },
  grossReceiptsReference: {
    id: 'grossReceiptsReference',
    propertyName: 'grossReceiptsReference',
    fieldName: 'Gross Receipts',
    width: '158px',
  },
};

const SbaPppLoanFinancials = 'SbaPppLoanFinancials';

export function RoundTwoGrossReciepts(props: any) {
  const { data } = props;

  const isRequired = Number(data.loanAmountDecimal) > 150000;

  const mutateQuarters = (value: SbaPppLoanFinancialsQuarter, path: string) => {
    props.mutateProperty(value, path);

    // Make sure the "Reference Quarter" match
    if (value == 'ANNUAL') {
      props.mutateProperty(value, 'referenceQuarter');
    } else {
      const referenceQuarterValue = `${value?.slice(0, 2)}_2019`;
      props.mutateProperty(referenceQuarterValue, 'referenceQuarter');
    }
  };

  return (
    <div className="RoundTwoGrossReciepts">
      <Form.Text
        className="steel"
        value="Applicants for loans of $150,000 or less may leave blank but must provide upon or before seeking loan forgiveness or upon SBA request."
      />
      <Form.Group>
        {Form.FieldRenderer(
          Form.Select,
          {
            ...fields.quarter,
            onChange: mutateQuarters,
            required: isRequired,
          },
          props,
          SbaPppLoanFinancials,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.grossReceipts,
            onChange: props.mutateProperty,
            required: isRequired,
          },
          props,
          SbaPppLoanFinancials,
          data.id,
        )}
      </Form.Group>
      <Form.Group>
        {Form.FieldRenderer(
          Form.Select,
          {
            ...fields.referenceQuarter,
            onChange: props.mutateProperty,
            required: isRequired,
          },
          props,
          SbaPppLoanFinancials,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.grossReceiptsReference,
            onChange: props.mutateProperty,
            required: isRequired,
          },
          props,
          SbaPppLoanFinancials,
          data.id,
        )}
      </Form.Group>
    </div>
  );
}
