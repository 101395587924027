import * as React from 'react';
import { merge } from 'immutable';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import CalendarFrame from './CalendarFrame';
import { withToJS } from 'utils';

import { RouteParams, withRouteParams } from 'routing';
import {
  TaskCategoriesQuery,
  CalendarItemCategoriesQuery,
  CalendarItemsHolidaysQuery,
  CalendarItemsQuery,
} from 'lsgql';
import { ReduxDirectory } from 'lsredux';
import {
  setViewRange,
  toggleFilterCategory,
  resetFilters,
} from 'lsredux/actions/calendar';
import {
  setViewStart,
  setViewEnd,
  resetListViewDates,
  resetCalendarViewDates,
} from 'lsredux/actions/calendarViewStartEnd';

const mapStateToProps: (state: any) => { rawData: any } = state => ({
  rawData: merge(
    state.getIn(ReduxDirectory.CalendarKeyPath),
    state.getIn(ReduxDirectory.CalendarViewStartEndKeyPath),
  ),
});

const params = [RouteParams.dealId];
const Composed: React.ComponentType<{}> = compose(
  withRouteParams(params),
  connect(mapStateToProps, {
    setViewStart,
    setViewEnd,
    resetListViewDates,
    resetCalendarViewDates,
    dealId: params,
    setViewRange,
    onToggleFilter: toggleFilterCategory,
    onResetFilters: resetFilters,
  }),
  withToJS,
  TaskCategoriesQuery,
  CalendarItemCategoriesQuery,
  CalendarItemsQuery,
  CalendarItemsHolidaysQuery,
)(CalendarFrame);

export default Composed;
