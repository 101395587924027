import React from 'react';
import moment from 'moment';
import { Icon, Popup } from 'components';
import { DealType } from 'types';
import { formatMoney, getDealStage } from 'utils';

interface Props {
  deals: DealType[];
  lenderName: string;
  type: 'lender' | 'borrower';
}

type ColumnType = {
  id: string;
  header: string;
  cleaner: (value?: string) => string;
};

const lenderPortfolioColumns: ColumnType[] = [
  {
    id: 'created',
    header: 'Created',
    cleaner: (value?: string) =>
      value ? moment(value).format('MM/DD/YY') : '-',
  },
  {
    id: 'borrowerName',
    header: 'Borrower',
    cleaner: (value?: string) => value || '-',
  },
  {
    id: 'identity',
    header: 'LS Loan ID',
    cleaner: (value?: string) => value || '-',
  },
  {
    id: 'dealassignee',
    header: 'Assignee',
    cleaner: (props: any) => props?.name || '-',
  },
  {
    id: 'stage',
    header: 'Stage',
    cleaner: (value?: string) => (value ? getDealStage(value) : '-'),
  },
  {
    id: 'status',
    header: 'status',
    cleaner: (value?: string) => value || '-',
  },
  // TODO: SBA-HACK: Uncomment once `upcomingEvent` works
  // {
  //   id: 'upcomingEvent',
  //   header: 'Upcoming',
  //   cleaner: (value?: string) => value || '-',
  // },
  {
    id: 'totalCommitmentAmount',
    header: 'Loan Amount ($)',
    cleaner: (value?: string) => `$${formatMoney(value)}`,
  },
];

const borrowerPortfolioColumns: ColumnType[] = [
  {
    id: 'created',
    header: 'Created',
    cleaner: (value?: string) =>
      value ? moment(value).format('MM/DD/YY') : '-',
  },
  {
    id: 'lenderName',
    header: 'Lender',
    cleaner: (value?: string) => value || '-',
  },
  {
    id: 'identity',
    header: 'LS Loan ID',
    cleaner: (value?: string) => value || '-',
  },
  {
    id: 'sbaLoanNumber',
    header: 'SBA Loan Number',
    cleaner: (props: any) => props?.name || '-',
  },
  {
    id: 'stage',
    header: 'Stage',
    cleaner: (value?: string) => (value ? getDealStage(value) : '-'),
  },
  {
    id: 'status',
    header: 'status',
    cleaner: (value?: string) => value || '-',
  },
  {
    id: 'totalCommitmentAmount',
    header: 'Loan Amount ($)',
    cleaner: (value?: string) => `$${formatMoney(value)}`,
  },
];

const DownloadButton = (props: Props) => {
  const { deals, lenderName, type } = props;

  const timestamp = moment().format('MM-DD-YY HH:mm');
  const csvName = `${lenderName} Portfolio Report ${timestamp}.csv`;

  const columns =
    type === 'borrower' ? borrowerPortfolioColumns : lenderPortfolioColumns;

  const headers = columns.map(column => column.header).join(',');

  const handleOnClick = (e?: React.SyntheticEvent<HTMLAnchorElement>) => {
    const rows = deals.map(deal =>
      columns.map(column => `"${column.cleaner(deal[column.id])}"`).join(','),
    );

    const csvHeader = 'data:text/csv;charset=utf-8,';
    const csvContent = csvHeader + headers + '\n' + rows.join('\n');
    const encodedUri = encodeURI(csvContent);

    // Fill in the data for the anchor-tag
    e.currentTarget.setAttribute('href', encodedUri);
    e.currentTarget.setAttribute('download', csvName);

    // ... and actually trigger the download by just letting the event continue bubbling
  };

  return (
    <Popup
      basic
      on="hover"
      position="bottom right"
      trigger={
        <a className="DownloadButton" onClick={handleOnClick}>
          <Icon icon={['fal', 'arrow-to-bottom']} />
        </a>
      }
    >
      Download this view as .csv
    </Popup>
  );
};

export default DownloadButton;
