import isNumeric from './isNumeric';
import isDateString from './isDateString';
import accessProperty from './accessProperty';
import { SortBy } from 'types';

/**
 * For use in change handlers to determine if a column should initially be sorted ascending (non-numeric strings) or decending (bool, numeric, unixTime)
 * See main > Deals' this.handleSortField() method for example
 * @param {*} array: the entities to be sorted
 * @param {*} currentSortBy: the sortBy object from before the user clicks the column header
 * @param {*} nextSortBy: the sortBy object from after the user clicks the column header
 * @param {*} propMap: object describing the relation between the column header string and the associated prop on the data object in the array.
 */
const inferSortReverse = (
  array: Array<any> = [],
  currentSortBy: SortBy,
  nextSortBy: SortBy,
  propMap: Record<string, any> = {},
): SortBy => ({
  ...nextSortBy,
  reverse:
    currentSortBy.column !== nextSortBy.column &&
    array.some(ele => {
      const nextColumnId = propMap[nextSortBy.column] || nextSortBy.column;
      let value = accessProperty(nextColumnId, ele);
      if (value && typeof value === 'string') {
        value = value.toLowerCase();
        if (!isNumeric(value) && !isDateString(value)) return true;
      }
      return false;
    })
      ? !nextSortBy.reverse
      : nextSortBy.reverse,
});

export default inferSortReverse;
