import { compose } from 'recompose';
import { connect } from 'react-redux';
import ForgivenessDenialForm from './ForgivenessDenialForm';
import { SbaPppForgivenessWorkflowFormQuery } from 'lsgql';
import { withRouteParams, RouteParams } from 'routing';
import { FormMediator } from 'components';
import sbaPppForgivenessDenialFormRedux from 'lsredux/reducer/forms/sbaPppForgivenessDenial';

export default compose(
  withRouteParams([RouteParams.dealId]),
  SbaPppForgivenessWorkflowFormQuery,
  connect(
    sbaPppForgivenessDenialFormRedux.mapStateToProps,
    sbaPppForgivenessDenialFormRedux.actions.generateActions,
  ),
  FormMediator({
    formId: 'sbaPppForgivenessDenialForm',
    baseObjectTypeName: 'Deal',
    disableFrame: true,
    disableAlerts: true,
  }),
)(ForgivenessDenialForm);
