import { compose } from 'recompose';
import DealContext from './DealContext';
import { withLoadingIndicator } from 'components';
import { RouteParams, withRouteParams } from 'routing';
import { DealQuery } from 'lsgql';

const DealContextContainer: any = compose(
  withRouteParams([RouteParams.dealId]),
  DealQuery,
  withLoadingIndicator('loading', 'deal'),
)(DealContext);

export default DealContextContainer;
