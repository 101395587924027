import * as React from 'react';
import { compose } from 'recompose';
import { MutationFunction } from '../types';
import { mutationBuilderHoC, mutationBuilder } from './builders';

import {
  DeleteDocumentMutationDefinition,
  SetDocumentMutationDefinition,
} from './definitions';
import { DocumentType, DocumentInput, ID } from 'types';

export const DocumentMutationMethods = {
  setDocument: mutationBuilder<DocumentInput, DocumentType>(
    SetDocumentMutationDefinition,
    {
      build: mutate => documentData =>
        mutate({
          variables: {
            documentData,
          },
          refetchQueries: ['DataroomFiles', 'LatestDocumentsQuery'],
        }),
    },
  ),
  deleteDocument: mutationBuilder<ID, void>(DeleteDocumentMutationDefinition, {
    build: mutate => (id: ID) =>
      mutate({
        variables: {
          id,
        },
      }),
  }),
};

const SetDocumentMutation = mutationBuilderHoC(SetDocumentMutationDefinition, {
  type: 'SetDocument',
  cr: (mutate: MutationFunction) => ({
    setDocument: (document: DocumentType) => {
      const documentData: DocumentInput = {
        id: document.id,
        name: document.name,
        description: document.description,
        parentId: (document as any).parentId,
      } as any;

      return mutate({
        variables: {
          documentData,
        },
        refetchQueries: ['DataroomFiles', 'LatestDocumentsQuery'],
      });
    },
  }),
});

const DeleteDocumentMutation = mutationBuilderHoC(
  DeleteDocumentMutationDefinition,
  {
    type: 'DeleteDocument',
    cr: (mutate: MutationFunction) => ({
      deleteDocument: (id: string) =>
        mutate({
          variables: {
            id,
          },
          refetchQueries: ['DataroomFiles', 'LatestDocumentsQuery'],
        }),
    }),
  },
);

export const DocumentMutations: (
  arg0: any,
) => React.ComponentType<{}> = compose(
  SetDocumentMutation,
  DeleteDocumentMutation,
);
