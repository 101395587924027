import { MutationMethod } from '../types';
import { mutationBuilder } from './builders';
import { MutationExecutor } from './builders/mutationBuilder';
import { SetEventDueMutationDefinition } from './definitions';
import { EventDueInput, EventDueType } from 'types';

const setEventDue: MutationMethod<
  EventDueInput,
  EventDueType
> = mutationBuilder<EventDueInput, EventDueType>(
  SetEventDueMutationDefinition,
  {
    build: (mutate: MutationExecutor<EventDueType>) => (
      eventDueData: EventDueInput,
    ) =>
      mutate({
        variables: {
          eventDueData,
        },
        refetchQueries: ['EventDueStandardQuery'],
      }),
  },
);

const EventDueMutationMethods = {
  setEventDue,
};

export default EventDueMutationMethods;
