/* eslint-disable import/prefer-default-export */
export function getRelatedFieldsFromEventFrequency(
  frequency: string | null | undefined,
) {
  switch (frequency) {
    case 'ANNUALLY':
      return ['endDate', 'numberOfOccurrences', 'eventDefaultsTo'];
    case 'MONTHLY':
      return [
        'endDate',
        'numberOfOccurrences',
        'eventDefaultsTo',
        'eventRepeatsOnMonthly',
      ];
    case 'WEEKLY':
      return [
        'endDate',
        'numberOfOccurrences',
        'eventDefaultsTo',
        'eventRepeatsOnWeekly',
      ];
    case 'DAILY':
      return ['endDate', 'numberOfOccurrences'];
    case 'QUARTERLY':
      return [
        'endDate',
        'numberOfOccurrences',
        'eventDefaultsTo',
        'eventRepeatsOnQuarterly',
      ];
    case 'AFTER_COMPLETED_EVENT':
      return [];
    case 'DOES_NOT_REPEAT':
      return [];
    default:
      return [];
  }
}
