import React, { useState } from 'react';
import cx from 'classnames';
import noop from 'lodash/noop';
import { invariant, getDownloadLink, pascalCase } from 'utils';
import {
  getFileIconName,
  Icon,
  Form,
  ConfirmModal,
  Button,
  IconButton,
  UploadDropzone,
} from 'components';

import { QueryResult } from 'lsgql';

import './AssociatedFiles.scss';

type Props = {
  data: any;
  deleteDocument?: (id: number) => Promise<QueryResult<any>>;
  header?: string;
  note?: string;
  refetchDocuments?: (data: any) => Promise<any>;
  setStagedFiles?: (files: Array<File>) => void;
  stagedFiles?: Array<File>;
  uploadTarget?: string;
  viewOnly?: boolean;
};

type AttachedFilesProps = {
  data: any;
  deleteDocument?: (id: number) => Promise<QueryResult<any>>;
  refetchDocuments?: (data: any) => Promise<any>;
  viewOnly?: boolean;
};

function AttachedFiles(props: AttachedFilesProps) {
  const {
    deleteDocument,
    refetchDocuments,
    data,
    data: { documents = [] },
    viewOnly,
  } = props;
  if (!viewOnly) invariant(deleteDocument, 'deleteDocument not found in props');

  const iconBaseClassName = 'AttachedFiles__Icon';

  const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] = useState(
    false,
  );
  const [selectedDoc, setSelectedDoc] = useState();
  const createDeleteClickHandler = doc => () => {
    setSelectedDoc(doc);
    setConfirmDeleteModalIsOpen(true);
  };

  async function deleteFile(id: number) {
    invariant(refetchDocuments, 'refetchDocuments not found in props');

    setConfirmDeleteModalIsOpen(false);
    setSelectedDoc(null);
    if (deleteDocument) await deleteDocument(id);
    refetchDocuments(data);
  }

  const handleDeleteFile = () => {
    if (selectedDoc) {
      // eslint-disable-next-line
      const { id } = selectedDoc as any;
      deleteFile(id);
    }
  };

  function closeConfirmModalHandler() {
    setConfirmDeleteModalIsOpen(false);
    setSelectedDoc(null);
  }

  function modalProps(): {
    message: string;
    onConfirm: () => void;
  } {
    if (!confirmDeleteModalIsOpen || !selectedDoc)
      return { message: '', onConfirm: noop };
    return {
      message: `Are you sure you wish to delete the file ${
        (selectedDoc as any).name ? (selectedDoc as any).name : ''
      }?  This operation cannot be undone.`,
      onConfirm: handleDeleteFile,
    };
  }

  return (
    <div className="AttachedFiles">
      <div className="AttachedFiles__FileList">
        {documents.map(doc => {
          const fileIconName = getFileIconName(doc.name);
          const iconClassName = cx(
            iconBaseClassName,
            `${iconBaseClassName}--${pascalCase(fileIconName)}`,
          );
          return (
            <div className="AttachedFiles__FileRow" key={doc.id}>
              <Icon className={iconClassName} icon={fileIconName} />
              <Button.Text
                className="AttachedFiles__FileName"
                isDownloadLink
                label={doc.name || ''}
                to={getDownloadLink(doc)}
              />
              {!viewOnly && (
                <IconButton.Delete
                  alt="csv"
                  className="AttachedFiles__FileRow__Delete"
                  onClick={createDeleteClickHandler(doc)}
                />
              )}
            </div>
          );
        })}
      </div>
      <ConfirmModal
        {...modalProps()}
        deleteAction
        header="Delete?"
        isOpen={confirmDeleteModalIsOpen}
        onReject={closeConfirmModalHandler}
      />
    </div>
  );
}

function AssociatedFiles(props: Props) {
  const {
    setStagedFiles,
    stagedFiles,
    header = 'Documents',
    uploadTarget,
    viewOnly,
    note,
    deleteDocument,
    refetchDocuments,
    data,
    data: { documents = [] },
  } = props;

  if (!viewOnly) {
    invariant(setStagedFiles, 'setStagedFiles not found in props');
    invariant(stagedFiles, 'stagedFiles not found in props');
    invariant(uploadTarget, 'uploadTarget not found in props');
  }

  return (
    <>
      {((viewOnly && Boolean(documents.length)) || !viewOnly) && (
        <Form.Header as="h3" header={header} />
      )}
      {note && <p>{note}</p>}
      {!viewOnly && (
        <UploadDropzone
          alternatePrompt="Drag files to upload or"
          alternateText=""
          buttonText="Upload"
          delayUpload
          isUploading={false}
          setStagedFiles={setStagedFiles}
          setUploading={noop}
          stagedFiles={stagedFiles}
          uploadTarget={uploadTarget || ''}
        />
      )}
      {Boolean(documents.length) && (
        <AttachedFiles
          data={data}
          deleteDocument={deleteDocument}
          refetchDocuments={refetchDocuments}
          viewOnly={viewOnly}
        />
      )}
    </>
  );
}

export default AssociatedFiles;
