import { compose, withProps } from 'recompose';
import uuid from 'uuid/v4';
import { InvitedUsersSection } from './types';
import { withLoadingIndicator } from 'components';
import { MyUserQuery, DealOrganizationDealRolesQuery } from 'lsgql';
import {
  DealInstitutionType,
  MyUserType,
  InvitationType,
  OrganizationDealRoleType,
  PermissionFlags,
} from 'types';
import { invariant } from 'utils';
import { RouteParams, withRouteParams } from 'routing';

type IOwnProps = {
  dealOrganizationDealRoles: Array<OrganizationDealRoleType>;
  user: MyUserType;
};

export type MappedOrganizations = {
  borrowers: Array<
    DealInstitutionType | (InvitedUsersSection | null | undefined)
  >;
  coLenders: Array<
    DealInstitutionType | (InvitedUsersSection | null | undefined)
  >;
  leadLenders: Array<
    DealInstitutionType | (InvitedUsersSection | null | undefined)
  >;
  permissionsMap?: Map<PermissionFlags, boolean>;
  servicingAgents: Array<
    DealInstitutionType | (InvitedUsersSection | null | undefined)
  >;
  userInstitution: Array<
    DealInstitutionType | (InvitedUsersSection | null | undefined)
  >;
};

function getActiveUserOrganizationDealRole(
  ownProps: IOwnProps,
): OrganizationDealRoleType | null | undefined {
  const { user, dealOrganizationDealRoles } = ownProps;

  const userInstitutionId = user && user.institution && user.institution.id;
  const userDealOrganizationDealRole =
    dealOrganizationDealRoles &&
    dealOrganizationDealRoles.find(
      e => e.institution && e.institution.id === userInstitutionId,
    );

  return userDealOrganizationDealRole;
}

function getInstitution(
  dealOrganizationDealRole: OrganizationDealRoleType | null | undefined,
): DealInstitutionType | null | undefined {
  if (!dealOrganizationDealRole) return null;
  return dealOrganizationDealRole && dealOrganizationDealRole.institution;
}

function createInvitedSection(
  invitations: Array<InvitationType>,
): InvitedUsersSection | null | undefined {
  if (!invitations.length) return null;

  // This creates a fake "organization" to render as the Invited section
  return {
    id: uuid(),
    name: 'Invited',
    invitations,
    isInvitedSection: true,
    __typename: 'DealInstitutionType',
  };
}

function sortExistingOrganizations(
  otherOrganizationDealRoles: Array<OrganizationDealRoleType>,
) {
  const activeBorrowers = [];
  const activeColenders = [];
  const activeServicingAgents = [];
  const activeLeadLenders = [];

  otherOrganizationDealRoles.forEach(e => {
    const organization = getInstitution(e);
    if (
      !organization ||
      !organization.dealUsers ||
      !organization.dealUsers.length
    )
      return;

    switch (e.dealJob) {
      case 'BORROWER':
        activeBorrowers.push(organization);
        break;
      case 'CO_LENDER':
        activeColenders.push(organization);
        break;
      case 'SERVICE_PROVIDER':
        activeServicingAgents.push(organization);
        break;
      case 'LEAD_LENDER':
        activeLeadLenders.push(organization);
        break;
      default:
    }
  });

  return {
    activeBorrowers,
    activeColenders,
    activeServicingAgents,
    activeLeadLenders,
  };
}

function sortNewOrganizations(
  invitedOrganizationDealRoles: Array<OrganizationDealRoleType>,
) {
  const borrowerInvitations = [];
  const colenderInvitations = [];
  const servicingAgentInvitations = [];
  const leadLenderInvitations = [];

  invitedOrganizationDealRoles.forEach(e => {
    const institution = getInstitution(e);
    if (!institution || !institution.invitationSet) return;
    institution.invitationSet.forEach(invitation => {
      // We don't want to include users who have already accepted their invitations
      // or invitations not sent by the current user
      if (invitation.accepted) return;

      switch (invitation.dealJob) {
        case 'BORROWER':
          borrowerInvitations.push(invitation);
          break;
        case 'CO_LENDER':
          colenderInvitations.push(invitation);
          break;
        case 'SERVICE_PROVIDER':
          servicingAgentInvitations.push(invitation);
          break;
        case 'LEAD_LENDER':
          leadLenderInvitations.push(invitation);
          break;
        default:
      }
    });
  });

  const invitedBorrowerSection = createInvitedSection(borrowerInvitations);
  const invitedColenderSection = createInvitedSection(colenderInvitations);
  const invitedServicingAgentSection = createInvitedSection(
    servicingAgentInvitations,
  );
  const invitedLeadLenderSection = createInvitedSection(leadLenderInvitations);

  return {
    invitedBorrowerSection,
    invitedColenderSection,
    invitedServicingAgentSection,
    invitedLeadLenderSection,
  };
}

function dealOrganizationBreakdown(ownProps: IOwnProps) {
  const { dealOrganizationDealRoles } = ownProps;

  // Setup current user team member section
  const userOrganizationDealRole = getActiveUserOrganizationDealRole(ownProps);
  const activeUserInstitution = getInstitution(userOrganizationDealRole);
  const userInstitutionInvitations =
    (activeUserInstitution &&
      activeUserInstitution.invitationSet &&
      activeUserInstitution.invitationSet.filter(e => !e.accepted)) ||
    [];
  const invitedUserInstitutionSection = createInvitedSection(
    userInstitutionInvitations,
  );

  const otherOrganizationDealRoles = dealOrganizationDealRoles.filter(
    e =>
      e.institution &&
      activeUserInstitution &&
      e.institution.id !== activeUserInstitution.id,
  );

  // Sort active and invited users who belong to an existing organizations
  const {
    activeBorrowers,
    activeColenders,
    activeServicingAgents,
    activeLeadLenders,
  } = sortExistingOrganizations(otherOrganizationDealRoles);

  // organization deal roles of users who have been invited, but have not yet accepted their invitation
  const invitedOrganizationDealRoles = otherOrganizationDealRoles.filter(e => {
    const institution = getInstitution(e);
    return (
      institution &&
      institution.invitationSet &&
      institution.invitationSet.length
    );
  });

  // Sort invited users, who have not yet accepted their invitation and belong to NEW organizations
  const {
    invitedBorrowerSection,
    invitedColenderSection,
    invitedServicingAgentSection,
    invitedLeadLenderSection,
  } = sortNewOrganizations(invitedOrganizationDealRoles);

  // Filter any null values out
  const userInstitution = [
    activeUserInstitution,
    invitedUserInstitutionSection,
  ].filter(e => e);
  const coLenders = [...activeColenders, invitedColenderSection].filter(e => e);
  const servicingAgents = [
    ...activeServicingAgents,
    invitedServicingAgentSection,
  ].filter(e => e);
  const leadLenders = [...activeLeadLenders, invitedLeadLenderSection].filter(
    e => e,
  );
  const borrowers = [...activeBorrowers, invitedBorrowerSection].filter(e => e);

  return {
    userInstitution,
    coLenders,
    servicingAgents,
    leadLenders,
    borrowers,
  };
}

function propsMapper(ownProps: IOwnProps): MappedOrganizations {
  const { user, dealOrganizationDealRoles } = ownProps;

  invariant(
    dealOrganizationDealRoles,
    'propsMapper requires dealOrganizationDealRoles',
  );
  invariant(user, 'propsMapper requires a user object');

  return dealOrganizationBreakdown(ownProps);
}

/**
 * Load user organization and deal organization information
 * and map into a usable format for People view components
 */
const Composed = compose(
  withRouteParams([RouteParams.dealId]),
  MyUserQuery,
  DealOrganizationDealRolesQuery,
  withLoadingIndicator('loading', 'user', 'dealOrganizationDealRoles'),
  withProps(propsMapper),
);

export default Composed;
