import * as React from 'react';
import DealClosingWorkflowStep from './DealClosingWorkflowStep';
import {
  Button,
  Form,
  Text,
  Header,
  Icon,
  WrappedMediatorFormProps,
  FontAwesomeIconTypes,
  RouteTo,
} from 'components';
import {
  DealClosingWorkflowStepType,
  DealClosingWorkflowType,
  DealType,
  ID,
} from 'types';

import '../DealClosingWorkflow.scss';

interface Props extends WrappedMediatorFormProps<DealType> {
  dealClosingWorkflow: DealClosingWorkflowType;
  closeDeal: (dealId: ID) => void;
  updateStep: (stepData: DealClosingWorkflowStepType) => void;
  updateLoanFunding: (property: string, value: string) => void;
  disabled: boolean;
}

const fileIcon: FontAwesomeIconTypes = ['fal', 'file-alt'];

function LegacyBorrowerWorkflow(props: Props) {
  const {
    data: { dealClosingWorkflow },
  } = props;

  // const { dealId } = useParams();

  const steps = dealClosingWorkflow?.steps;
  if (!steps || steps.length === 0) return null;

  const stepMap = steps.reduce((previous, current) => {
    previous[current.slug] = current;
    return previous;
  }, {});

  // eslint-disable-next-line
  console.log(stepMap);
  return (
    <>
      <div className="section">
        <div className="header">
          <Form.Divider />
          <Header as="h5" className="caps steel">
            Loan Application
          </Header>
        </div>
        <DealClosingWorkflowStep
          data={stepMap['borrower-sba-application-corrections']}
          disabled={props.disabled}
          updateStep={props.updateStep}
        />
        <Text
          className="steel"
          value="After making any changes, download the PDF below. Initial and sign it where indicated."
        />
        {stepMap['borrower-sba-application-corrections']?.bodyLinks?.[0] && (
          <div className="downloadLinkContainer">
            <Icon icon={fileIcon} />
            <Button.Text
              as={RouteTo}
              className="downloadLink"
              label="Borrower Loan Application.pdf"
              to={stepMap['borrower-sba-application-corrections'].bodyLinks[0]}
            />
          </div>
        )}
        <DealClosingWorkflowStep
          data={stepMap['borrower-sba-application-signed']}
          disabled={props.disabled}
          updateStep={props.updateStep}
        />
      </div>
      <div className="section">
        <div className="header">
          <Form.Divider />
          <Header as="h5" className="caps steel">
            Supporting Documents
          </Header>
        </div>
        <DealClosingWorkflowStep
          data={stepMap['borrower-documents-about-business']}
          disabled={props.disabled}
          updateStep={props.updateStep}
        />
        <DealClosingWorkflowStep
          data={stepMap['borrower-documents-about-self']}
          disabled={props.disabled}
          updateStep={props.updateStep}
        />
        <DealClosingWorkflowStep
          data={stepMap['borrower-documents-financials']}
          disabled={props.disabled}
          updateStep={props.updateStep}
        />
        <Text
          className="steel questions3and4"
          value="If you answered YES to questions 3 or 4 on your application, provide:"
        />
        <DealClosingWorkflowStep
          data={stepMap['borrower-addendum-a']}
          disabled={props.disabled}
          updateStep={props.updateStep}
        />
        <DealClosingWorkflowStep
          data={stepMap['borrower-addendum-b']}
          disabled={props.disabled}
          updateStep={props.updateStep}
        />
      </div>
      <div className="section">
        <div className="header">
          <Form.Divider />
          <Header as="h5" className="caps steel">
            Promissory Note
          </Header>
        </div>
        <Text
          className="steel"
          value="Sign the Promissory Note and any other loan documents required by the Lender."
        />
        <DealClosingWorkflowStep
          data={stepMap['borrower-signed-note']}
          disabled={props.disabled}
          updateStep={props.updateStep}
        />
        <div className="section">
          <div className="header">
            <Form.Divider />
            <Header as="h5" className="caps steel">
              Loan Funding
            </Header>
          </div>
          <Text
            className="steel"
            value="The Lender will notify you when the loan is funded!"
          />
        </div>
      </div>
    </>
  );
}

export default LegacyBorrowerWorkflow;
