import * as React from 'react';
import cx from 'classnames';
import { FontAwesomeIconTypes, Input } from '@loanstreet-usa/design-system';
import { haltBubble, ux } from '../../utils';
import { DataGridInputProps } from './types';

import './DataGridTextInput.scss';

type Props<T, K extends keyof T> = DataGridInputProps<T, K> & {
  disabled?: boolean;
  icon?: FontAwesomeIconTypes;
  iconMessage?: string;
};

class DataGridTextInput<T, K extends keyof T> extends React.Component<
  Props<T, K>
> {
  static defaultProps = {
    value: '',
    onCancel: () => undefined,
    onValueChanged: () => undefined,
  };

  inputIdentifier: string;

  constructor(props: Props<T, K>) {
    super(props);

    this.inputIdentifier = `${props.columnName}_${props.rowKey}_${props.index}`;
  }

  handleOnChange = (e: React.SyntheticEvent) => {
    haltBubble(e);

    if (this.props.onValueChanged)
      // @ts-ignore
      this.props.onValueChanged(e.currentTarget.value);
  };

  handleOnCancel = (e: React.SyntheticEvent) => {
    haltBubble(e);
    if (this.props.onCancel) this.props.onCancel();
  };

  handleOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    haltBubble(e);
    if (e.key === 'Escape') {
      if (this.props.onCancel) this.props.onCancel();
    }
  };

  render() {
    const { value = '', icon, iconMessage, disabled } = this.props;
    const computedClass = cx(
      'DataGridTextInput',
      ux(this.props.icon, 'DataGridTextInput-HasIcon'),
    );
    return (
      <Input
        autoFocus={false}
        className={computedClass}
        disabled={disabled}
        icon={icon}
        iconMessage={iconMessage}
        id={this.inputIdentifier}
        name={this.inputIdentifier}
        onBlur={this.handleOnCancel}
        onChange={this.handleOnChange}
        onChangeEventType="onBlur"
        onKeyUp={this.handleOnKeyUp}
        tabIndex={0}
        value={value || ''}
      />
    );
  }
}

export default DataGridTextInput;
