import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ID } from '../../../types/primitives';
import FileDragSource from './dndWrappers/FileDragSource';
import FileRow from './FileRow';
import FileDropTarget from './dndWrappers/FileDropTarget';
import { enterDragHover } from 'lsredux/actions/dataroomrow';

const mapDispatchToProps = (dispatch: any) => ({
  handleEnterDragHover: (folderId: ID, canDropInRootFolder: boolean) => {
    dispatch(enterDragHover(folderId, canDropInRootFolder));
  },
});

export default compose(
  FileDropTarget,
  connect(null, mapDispatchToProps),
  FileDragSource,
)(FileRow);
