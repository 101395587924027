import uuid from 'uuid/v4';
import {
  SbaPppForgivenessResubmitRequestMutationMethods,
  SbaPppForgivenessResubmitRequestValidator,
} from '../../../graphql';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import { DealType } from 'types';

const initialDeal: DealType = {
  id: uuid(),
  __typename: 'DealType',
};

const lifecycleMethods = {
  mutations: SbaPppForgivenessResubmitRequestMutationMethods,
  validators: {
    SbaPppForgivenessResubmitRequest: SbaPppForgivenessResubmitRequestValidator,
  },
};

const sbaPppForgivenessResubmitRequestFormRedux = createForm<
  DealType,
  DealType
>(Directory.SbaPppForgivenessResubmitRequestKey, initialDeal, lifecycleMethods);

export default sbaPppForgivenessResubmitRequestFormRedux;
