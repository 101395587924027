/* eslint-disable */
import { NativeTypes } from 'react-dnd-html5-backend';
import HTML5Backend from 'react-dnd-html5-backend';

/* eslint-enable */

import { connect } from 'react-redux';
import { compose } from 'recompose';

import DocumentMutationHoC from './DocumentMutationHoC';
import { SortBy } from './types';
import Dataroom from './Dataroom';
import { withToJS } from 'utils';
import { withLoadingIndicator } from 'components';
import { RouteParams, withRouteParams } from 'routing';

import {
  DataroomQuery,
  FolderMutations,
  DocumentMutations,
  DealQuery,
} from 'lsgql';
import { ReduxDirectory } from 'lsredux';

import {
  openFolder,
  closeFolder,
  changeSortField,
} from 'lsredux/actions/dataroomPresentationalActions';
import { openFile } from 'lsredux/actions/dealpdfviewer';
import { setAlert } from 'lsredux/actions/alerts';
import { leaveDragHover } from 'lsredux/actions/dataroomrow';

const sortBy: SortBy = {
  column: 'name',
  reverse: false,
};

type MappedState = {
  rawData: any;
};

const mapStateToProps: (state: any) => MappedState = (state: any) => ({
  rawData: state.getIn(ReduxDirectory.DataroomKeyPath),
  sortBy,
});

export default compose(
  withRouteParams([RouteParams.dealId]),
  DealQuery,
  DataroomQuery,
  withLoadingIndicator('loading'),
  DocumentMutations,
  FolderMutations,
  connect(mapStateToProps, {
    openFolder,
    closeFolder,
    changeSortField,
    setAlert,
    leaveDragHover,
    openFile,
  }),
  DocumentMutationHoC,
  withToJS,
)(Dataroom);
