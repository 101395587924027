import { InvitationType, DealJobType } from './types';
import { InvitationDealJob } from 'types';

export const DealJob: {
  [key in InvitationDealJob]: DealJobType;
} = {
  LEAD_LENDER: 'Lead Lender',
  CO_LENDER: 'Co-Lender',
  SERVICE_PROVIDER: 'Service Provider',
  BORROWER: 'Borrower',
};

export const InvitationTypes: {
  [key: string]: InvitationType;
} = {
  MY_TEAM: 'myteam',
  CO_LENDERS: 'colenders',
  BORROWER: 'borrower',
  SERVICING_AGENTS: 'servicingagents',
  LEAD_LENDER: 'leadlender',
};
