import * as React from 'react';
import {
  LoanTrancheFormProps,
  BenchmarkTextValueSortOrderType,
} from '../types';
import TrancheGroup from './TrancheGroup';
import PaymentPeriodNonAbrExceptionItem from './paymentsForm/PaymentPeriodNonAbrExceptionItem';
import PaymentPeriodAbrExceptionItem from './paymentsForm/PaymentPeriodAbrExceptionItem';
import {
  isFloatingRate,
  isFixedPayment,
  isLetterOfCredit,
  isRevolver,
  isSwingline,
  getBenchmarkOptions,
  parseDayCountText,
} from './utils';
import { BenchmarkOptionType, AlternateBaseRateBenchmarkType } from 'types';
import { Form, TypeQueryResult, Header } from 'components';

const loanTranche = 'LoanTranche';
const width = '158px';

class PaymentFields extends React.Component<LoanTrancheFormProps> {
  fixedPaymentFields = {
    fixedPaymentAmount: {
      id: 'fixedPaymentAmount',
      propertyName: 'fixedPaymentAmount',
      fieldName: 'Fixed Payment Amount',
      onChange: this.props.mutateProperty,
      width,
      tabIndex: this.props.tabIndex,
    },
  };

  baseFields = {
    paymentPeriod: {
      id: 'paymentFrequency',
      propertyName: 'paymentFrequency',
      fieldName: 'Payment Period',
      onChange: this.props.mutateProperty,
      typeName: 'LoanTranchePaymentFrequency',
      width,
      tabIndex: this.props.tabIndex,
    },
    dayCountConvention: {
      id: 'dayCountConvention',
      propertyName: 'dayCountConvention',
      fieldName: 'Interest Day Count Rule',
      onChange: this.props.mutateProperty,
      typeName: 'LoanTrancheDayCountConvention',
      width,
      tabIndex: this.props.tabIndex,
    },
    grace: {
      id: 'gracePeriod',
      propertyName: 'gracePeriod',
      fieldName: 'Grace',
      onChange: this.props.mutateProperty,
      width,
      tabIndex: this.props.tabIndex,
      suffix: 'days',
    },
  };

  renderException = (data: BenchmarkOptionType, fieldName: string) => {
    const { onUpdateBenchmarkOption, disabled, isSaving, errors } = this.props;
    return (
      <PaymentPeriodNonAbrExceptionItem
        data={data}
        disabled={disabled}
        errors={errors}
        fieldName={fieldName}
        isSaving={isSaving}
        key={data.id}
        onUpdateBenchmarkOption={onUpdateBenchmarkOption}
      />
    );
  };

  renderLiborExceptions = (
    liborBenchmarkOptions: ReadonlyArray<BenchmarkOptionType>,
    liborBenchmarkMap: {
      [key: string]: BenchmarkTextValueSortOrderType;
    },
  ) =>
    liborBenchmarkOptions.map<any>(option => {
      if (option.benchmarkId) {
        const fieldName = liborBenchmarkMap[option.benchmarkId].text;
        return this.renderException(option, fieldName || '');
      }
      return null;
    });

  render() {
    const {
      data,
      benchmarksReferenceData,
      onUpdateAbrOption,
      disabled,
    } = this.props;
    const { liborBenchmarkMap } = benchmarksReferenceData;

    const {
      liborBenchmarkOptions,
      primeBenchmarkOption,
      fedFundsBenchmarkOption,
      isAbrSelected,
      abrBenchmarkOptions,
    } = getBenchmarkOptions(this.props);

    return (
      <TrancheGroup>
        {isFixedPayment(data) && (
          <Form.Group>
            {Form.FieldRenderer(
              Form.MoneyReadOnly,
              this.fixedPaymentFields.fixedPaymentAmount,
              this.props,
              loanTranche,
              data.id,
            )}
            {Form.FieldRenderer(
              Form.ReferenceSelect,
              this.baseFields.paymentPeriod,
              this.props,
              loanTranche,
              data.id,
            )}
          </Form.Group>
        )}
        {!isFixedPayment(data) && (
          <Form.Group>
            {Form.FieldRenderer(
              Form.ReferenceSelect,
              this.baseFields.paymentPeriod,
              this.props,
              loanTranche,
              data.id,
            )}
            {Form.FieldRenderer(
              Form.Numeric,
              this.baseFields.grace,
              this.props,
              loanTranche,
              data.id,
            )}
          </Form.Group>
        )}
        <Form.Group>
          {!isFloatingRate(data) &&
            !(
              isRevolver(data) ||
              isSwingline(data) ||
              isLetterOfCredit(data)
            ) &&
            Form.FieldRenderer(
              Form.ReferenceSelect,
              {
                ...this.baseFields.dayCountConvention,
                resultFilter: (queryResults: Array<TypeQueryResult>) =>
                  queryResults.map(parseDayCountText),
              },
              this.props,
              loanTranche,
              data.id,
            )}
          {isFixedPayment(data) &&
            Form.FieldRenderer(
              Form.Numeric,
              this.baseFields.grace,
              this.props,
              loanTranche,
              data.id,
            )}
        </Form.Group>
        {isFloatingRate(data) && (
          <>
            <Form.Divider className="transparent" />
            <Header as="h5" className="steel bold">
              Payment Period Exceptions
            </Header>
            {this.renderLiborExceptions(
              liborBenchmarkOptions,
              liborBenchmarkMap,
            )}
            {isAbrSelected && (
              <PaymentPeriodAbrExceptionItem
                data={
                  (abrBenchmarkOptions as any) as ReadonlyArray<
                    AlternateBaseRateBenchmarkType
                  >
                }
                disabled={disabled}
                errors={this.props.errors}
                fieldName="Alternative Base Rate"
                isSaving={this.props.isSaving}
                onUpdateAbrOption={onUpdateAbrOption}
              />
            )}
            {Boolean(primeBenchmarkOption.benchmarkId) &&
              this.renderException(primeBenchmarkOption, 'Prime')}
            {Boolean(fedFundsBenchmarkOption.benchmarkId) &&
              this.renderException(fedFundsBenchmarkOption, 'Federal Funds')}
          </>
        )}
      </TrancheGroup>
    );
  }
}

export default PaymentFields;
