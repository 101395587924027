import React, { useState } from 'react';
import {
  ForgivenessApprovalForm,
  ForgivenessResubmissionForm,
  ForgivenessDenialForm,
} from '../modals';
import { getCompleteFormsError } from '../Resources';
import { StateMap } from '../../Resources';
import { ActionButtonProps } from './ActionButtonProps';
import { Button, RouteTo } from 'components';
import { haltBubble } from 'utils';
import { RouteTable } from 'routing';
import transitionForgivenessWorkflowMutation from 'lsgql/mutations/TransitionForgivenessWorkflow';

export function LenderActionButtons(props: ActionButtonProps) {
  const { deal, sbaPppForgivenessWorkflow } = props;
  const { isLender, state, canEdit } = sbaPppForgivenessWorkflow;

  const disabled = !canEdit;

  const [isOpen, setIsOpen] = useState({
    approveModal: false,
    resubmitModal: false,
    denyModal: false,
  });

  if (!isLender || [StateMap.APPROVED, StateMap.REJECTED].includes(state)) {
    return null;
  }

  const handleApprovalModalToggle = (e?: React.SyntheticEvent) => {
    e && haltBubble(e);
    setIsOpen({ ...isOpen, approveModal: !isOpen.approveModal });
  };

  const handleResubmitModalToggle = (e?: React.SyntheticEvent) => {
    e && haltBubble(e);
    setIsOpen({ ...isOpen, resubmitModal: !isOpen.resubmitModal });
  };

  const handleDenyModalToggle = (e?: React.SyntheticEvent) => {
    e && haltBubble(e);
    setIsOpen({ ...isOpen, denyModal: !isOpen.denyModal });
  };

  const handleOnClick = (
    toggleFunction: (e?: React.SyntheticEvent) => void,
    transitionState?: string,
  ) => async (e?: React.SyntheticEvent) => {
    e && haltBubble(e);

    let res = null;
    if (transitionState) {
      res = await transitionForgivenessWorkflowMutation({
        workflowId: sbaPppForgivenessWorkflow.id,
        state: transitionState,
        validate: true,
        errorToast: getCompleteFormsError(
          sbaPppForgivenessWorkflow.isSFormEligible,
        ),
      });
    }

    if (!transitionState || res?.data?.transitionForgivenessWorkflow?.ok) {
      toggleFunction(e);
    }
  };

  const handleResendClick = async (e?: React.SyntheticEvent) => {
    e && haltBubble(e);

    await transitionForgivenessWorkflowMutation({
      workflowId: sbaPppForgivenessWorkflow.id,
      state: StateMap.BORROWER_SIGNATURE_REQUESTED,
      successToast: 'Your signature request has been resent to the Borrower.',
      errorToast: getCompleteFormsError(
        sbaPppForgivenessWorkflow.isSFormEligible,
      ),
    });
  };

  if (
    [
      StateMap.SBA_PENDING_VALIDATION,
      StateMap.SBA_UNDER_REVIEW,
      StateMap.SBA_FULLY_APPROVED,
      StateMap.SBA_NOT_APPROVED,
      StateMap.SBA_PARTIALLY_APPROVED,
      StateMap.SBA_LENDER_ADDITIONAL_INFO_REQUESTED,
      StateMap.SBA_PAYMENT_SENT,
      StateMap.SBA_PAYMENT_CONFIRMED,
      StateMap.SBA_PAYMENT_FAILED,
    ].includes(state)
  ) {
    return null;
  }

  if (state === StateMap.BORROWER_SIGNATURE_REQUESTED) {
    return (
      <Button.Secondary
        label="Resend Signature Request"
        onClick={handleResendClick}
      />
    );
  }

  return (
    <>
      <div className="CtaButtons__Wrapper">
        <div>
          <Button
            disabled={disabled}
            label="Approve"
            onClick={handleOnClick(
              handleApprovalModalToggle,
              StateMap.BORROWER_SIGNATURE_REQUESTED,
            )}
          />
          <Button.Secondary
            disabled={disabled}
            label="Request Changes"
            onClick={handleOnClick(handleResubmitModalToggle)}
          />
          <Button.Secondary
            disabled={disabled}
            label="Deny"
            onClick={handleOnClick(handleDenyModalToggle)}
          />
        </div>
        <div>
          <Button.Secondary
            as={RouteTo}
            label="Next: Loan Information"
            to={RouteTable.deal.toSbaPppForgivenessWorkflowLoanInformation(
              deal.id,
            )}
          />
        </div>
      </div>
      {/* THESE ARE THE VARIOUS MODALS */}
      {canEdit && isOpen.approveModal && (
        <ForgivenessApprovalForm
          handleModalToggle={handleApprovalModalToggle}
          isOpen={isOpen.approveModal}
          sbaPppForgivenessWorkflow={sbaPppForgivenessWorkflow}
        />
      )}
      {canEdit && isOpen.resubmitModal && (
        <ForgivenessResubmissionForm
          handleModalToggle={handleResubmitModalToggle}
          isOpen={isOpen.resubmitModal}
        />
      )}
      {canEdit && isOpen.denyModal && (
        <ForgivenessDenialForm
          handleModalToggle={handleDenyModalToggle}
          isOpen={isOpen.denyModal}
        />
      )}
    </>
  );
}
