import { FormMediatorArgs } from '../types';
import { BaseType } from 'types';

function EventsProcessor<T extends BaseType>(
  staleProps: any,
  freshProps: any,
  args: FormMediatorArgs<T>,
  getReduxState: (immutable: any) => any,
) {
  if (!args.events) return;

  if (
    freshProps.rawData &&
    staleProps.rawData &&
    freshProps.rawData !== staleProps.rawData
  ) {
    const fresh = getReduxState(freshProps);
    const stale = getReduxState(staleProps);

    if (args.events) {
      args.events.forEach(e => {
        if (
          e.property &&
          fresh.data &&
          stale.data &&
          fresh.data[e.property] !== stale.data[e.property]
        ) {
          e.handler(freshProps, fresh);
        }
      });
    }
  }
}

export default EventsProcessor;
