import { connect } from 'react-redux';

import { compose } from 'recompose';
import lenderRegistryFormRedux from '../../redux/reducer/forms/lenderRegistry';
import { ID } from '../../types/primitives';
import LenderRegistry from './LenderRegistryForm';
import { ReduxDirectory } from 'lsredux';
import { DealInstitutionsQuery, LenderRegistryFormQuery } from 'lsgql';
import { RouteParams, withRouteParams } from 'routing';
import {
  withLoadingIndicator,
  FormReduxRawState,
  FormMediator,
} from 'components';

import './LenderRegistryForm.scss';
import { DealPerspectivePermissions } from 'security';

type OwnProps = {
  dealId: ID;
};

const mapStateToProps = (state: any): FormReduxRawState => {
  const keyPath = ReduxDirectory.LenderRegistryFormKeyPath;
  return {
    rawData: state.getIn(keyPath),
  };
};

const mapDispatchToProps: (dispatch: any) => any = (dispatch: any) =>
  lenderRegistryFormRedux.actions.generateActions(dispatch);

export default compose(
  withRouteParams([RouteParams.dealId]),
  DealInstitutionsQuery,
  LenderRegistryFormQuery,
  withLoadingIndicator('loading'),
  connect(mapStateToProps, mapDispatchToProps),
  FormMediator({
    formId: 'lenderRegistryForm',
    baseObjectTypeName: 'Deal',
    editSecurity: DealPerspectivePermissions.administer_lender_registry,
  }),
)(LenderRegistry);
