import React from 'react';

export const EmptyDocuments = () => (
  <svg height="196px" version="1.1" viewBox="0 0 206 196" width="206px">
    <title>illustration/empty documents</title>
    <defs>
      <linearGradient
        id="linearGradient-1"
        x1="49.9988159%"
        x2="49.9988159%"
        y1="100%"
        y2="0%"
      >
        <stop offset="0%" stopColor="#808080" stopOpacity="0.25"></stop>
        <stop offset="54%" stopColor="#808080" stopOpacity="0.12"></stop>
        <stop offset="100%" stopColor="#808080" stopOpacity="0.1"></stop>
      </linearGradient>
      <linearGradient id="linearGradient-2" x1="50%" x2="50%" y1="100%" y2="0%">
        <stop offset="0%" stopColor="#808080" stopOpacity="0.25"></stop>
        <stop offset="54%" stopColor="#808080" stopOpacity="0.12"></stop>
        <stop offset="100%" stopColor="#808080" stopOpacity="0.1"></stop>
      </linearGradient>
      <linearGradient
        id="linearGradient-3"
        x1="60.2978048%"
        x2="39.7592206%"
        y1="98.8803643%"
        y2="1.02948481%"
      >
        <stop offset="0%" stopColor="#808080" stopOpacity="0.25"></stop>
        <stop offset="54%" stopColor="#808080" stopOpacity="0.12"></stop>
        <stop offset="100%" stopColor="#808080" stopOpacity="0.1"></stop>
      </linearGradient>
      <linearGradient
        id="linearGradient-4"
        x1="50%"
        x2="50%"
        y1="99.9144422%"
        y2="-0.0855578371%"
      >
        <stop offset="0%" stopColor="#808080" stopOpacity="0.25"></stop>
        <stop offset="54%" stopColor="#808080" stopOpacity="0.12"></stop>
        <stop offset="100%" stopColor="#808080" stopOpacity="0.1"></stop>
      </linearGradient>
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      id="illustration/empty-documents"
      stroke="none"
      strokeWidth="1"
    >
      <g fillRule="nonzero" id="undraw_no_data_qbuo-(2)">
        <polygon
          fill="#E0E0E0"
          id="Rectangle"
          points="31.8987223 20.7325382 141.153722 20.7325382 141.153722 171.687538 31.8987223 171.687538"
          transform="translate(86.526222, 96.210038) rotate(-12.110000) translate(-86.526222, -96.210038) "
        ></polygon>
        <g
          fill="url(#linearGradient-1)"
          id="Group"
          opacity="0.5"
          transform="translate(19.500000, 13.750000)"
        >
          <polygon
            id="Rectangle"
            points="14.2464567 9.53474817 119.808957 9.53474817 119.808957 155.387248 14.2464567 155.387248"
            transform="translate(67.027707, 82.460998) rotate(-12.110000) translate(-67.027707, -82.460998) "
          ></polygon>
        </g>
        <polygon
          fill="#FAFAFA"
          id="Rectangle"
          points="34.9760865 24.3703225 137.851086 24.3703225 137.851086 167.000323 34.9760865 167.000323"
          transform="translate(86.413586, 95.685323) rotate(-12.110000) translate(-86.413586, -95.685323) "
        ></polygon>
        <polygon
          fill="url(#linearGradient-2)"
          id="Rectangle"
          points="46.0664148 19.7458389 97.1014148 19.7458389 97.1014148 32.1083389 46.0664148 32.1083389"
          transform="translate(71.583915, 25.927089) rotate(-12.110000) translate(-71.583915, -25.927089) "
        ></polygon>
        <path
          d="M67.7525,8.0775 C63.9,8.905 61.4625,12.7725 62.31,16.715 C63.1575,20.6575 66.965,23.1875 70.81,22.36 C74.655,21.5325 77.1,17.665 76.2525,13.7225 C75.405,9.78 71.605,7.25 67.7525,8.0775 Z M70.17,19.3525 C68.5046298,19.7496678 66.76447,19.1000201 65.7668012,17.7086693 C64.7691323,16.3173185 64.7121873,14.4607207 65.6227109,13.0108424 C66.5332344,11.5609642 68.230309,10.8058906 69.9168895,11.1002484 C71.6034699,11.3946062 72.9444392,12.6799076 73.31,14.3525 C73.559402,15.4321298 73.3683159,16.5666379 72.7790212,17.5050053 C72.1897264,18.4433727 71.2508047,19.10824 70.17,19.3525 Z"
          fill="url(#linearGradient-3)"
          id="Shape"
        ></path>
        <polygon
          fill="#D2D2D2"
          id="Rectangle"
          points="46.4354577 19.2334475 96.3954577 19.2334475 96.3954577 31.0509475 46.4354577 31.0509475"
          transform="translate(71.415458, 25.142198) rotate(-12.110000) translate(-71.415458, -25.142198) "
        ></polygon>
        <path
          d="M67.7525,8.0775 C63.9821458,8.88674731 61.5816368,12.599179 62.3907378,16.3695646 C63.1998388,20.1399502 66.9121774,22.5406032 70.6825944,21.7316485 C74.4530113,20.9226938 76.8538084,17.2104483 76.045,13.44 C75.6565563,11.6291795 74.5646247,10.0468574 73.0094515,9.04117566 C71.4542784,8.03549389 69.5632753,7.68884552 67.7525,8.0775 L67.7525,8.0775 Z M70.0625,18.8525 C68.4687202,19.1944813 66.8258755,18.5426735 65.9000806,17.2010368 C64.9742856,15.8594002 64.9478771,14.0921722 65.8331704,12.7234717 C66.7184636,11.3547712 68.3410986,10.6541659 69.9443852,10.948372 C71.5476718,11.2425781 72.8158401,12.4736513 73.1575,14.0675 C73.6239417,16.2434581 72.238364,18.3856195 70.0625,18.8525 L70.0625,18.8525 Z"
          fill="#D2D2D2"
          id="Shape"
        ></path>
        <rect
          fill="#E0E0E0"
          height="150.955"
          id="Rectangle"
          width="109.255"
          x="64.4725"
          y="29.2275"
        ></rect>
        <g
          fill="url(#linearGradient-1)"
          id="Group"
          opacity="0.5"
          transform="translate(66.250000, 31.750000)"
        >
          <rect
            height="145.8525"
            id="Rectangle"
            width="105.5625"
            x="0.07"
            y="0.03"
          ></rect>
        </g>
        <rect
          fill="#FFFFFF"
          height="142.63"
          id="Rectangle"
          width="102.875"
          x="67.6625"
          y="32.855"
        ></rect>
        <rect
          fill="url(#linearGradient-2)"
          height="12.3625"
          id="Rectangle"
          width="51.035"
          x="93.7175"
          y="26.67"
        ></rect>
        <path
          d="M119.235,14.6025 C115.295,14.6025 112.1,17.8725 112.1,21.9075 C112.1,25.9425 115.295,29.2125 119.235,29.2125 C123.175,29.2125 126.37,25.9425 126.37,21.9075 C126.37,17.8725 123.175,14.6025 119.235,14.6025 Z M119.235,26.1225 C117.521677,26.1631596 115.954312,25.1623879 115.270285,23.5910073 C114.586259,22.0196267 114.921773,20.1905263 116.118983,18.9642233 C117.316192,17.7379203 119.136711,17.3585929 120.724059,18.0046992 C122.311408,18.6508056 123.349518,20.1936947 123.35,21.9075 C123.366678,24.2000579 121.527454,26.0750382 119.235,26.1025 L119.235,26.1225 Z"
          fill="url(#linearGradient-4)"
          id="Shape"
        ></path>
        <rect
          fill="#D2D2D2"
          height="11.8175"
          id="Rectangle"
          width="49.96"
          x="94.255"
          y="26.14"
        ></rect>
        <path
          d="M119.235,14.6025 C116.410842,14.6025 113.864769,16.3037315 112.784011,18.9129129 C111.703253,21.5220944 112.300646,24.5253921 114.297627,26.5223731 C116.294608,28.5193541 119.297906,29.1167472 121.907087,28.0359888 C124.516268,26.9552305 126.2175,24.4091576 126.2175,21.585 C126.2175,17.7286717 123.091328,14.6025 119.235,14.6025 L119.235,14.6025 Z M119.235,25.615 C117.011249,25.6122342 115.210012,23.808749 115.210012,21.5849959 C115.210012,19.3612427 117.011263,17.5577641 119.235014,17.555003 C121.458766,17.5522484 123.26448,19.3512537 123.27,21.575 C123.269338,22.6442561 122.84377,23.6694183 122.086989,24.424793 C121.330208,25.1801677 120.304255,25.6038268 119.235,25.6025 L119.235,25.615 Z"
          fill="#D2D2D2"
          id="Shape"
        ></path>
        <g
          fill="#747574"
          id="Group"
          opacity="0.5"
          transform="translate(1.500000, 184.250000)"
        >
          <rect
            height="5.3325"
            id="Rectangle"
            width="1"
            x="2.3175"
            y="0.0125"
          ></rect>
          <polygon
            id="Rectangle"
            points="2.31875 0.01375 3.25875 0.01375 3.25875 5.34625 2.31875 5.34625"
            transform="translate(2.788750, 2.680000) rotate(90.000000) translate(-2.788750, -2.680000) "
          ></polygon>
        </g>
        <g
          fill="#747574"
          id="Group"
          opacity="0.5"
          transform="translate(110.500000, 0.000000)"
        >
          <rect
            height="5.3325"
            id="Rectangle"
            width="1"
            x="2.3725"
            y="0"
          ></rect>
          <polygon
            id="Rectangle"
            points="2.37375 -0.00125 3.31375 -0.00125 3.31375 5.33125 2.37375 5.33125"
            transform="translate(2.843750, 2.665000) rotate(90.000000) translate(-2.843750, -2.665000) "
          ></polygon>
        </g>
        <path
          d="M192.77,193.14 C192.233275,192.906358 191.956951,192.307478 192.1275,191.7475 C192.140796,191.706125 192.149187,191.663332 192.1525,191.62 L192.1525,191.62 C192.167817,191.36577 192.014779,191.131522 191.775811,191.043421 C191.536843,190.955319 191.268374,191.034168 191.115,191.2375 L191.115,191.2375 C191.089003,191.272326 191.067187,191.310084 191.05,191.35 C190.816358,191.886725 190.217478,192.163049 189.6575,191.9925 C189.616125,191.979204 189.573332,191.970813 189.53,191.9675 L189.53,191.9675 C189.27577,191.952183 189.041522,192.105221 188.953421,192.344189 C188.865319,192.583157 188.944168,192.851626 189.1475,193.005 L189.1475,193.005 C189.182326,193.030997 189.220084,193.052813 189.26,193.07 C189.796725,193.303642 190.073049,193.902522 189.9025,194.4625 C189.889204,194.503875 189.880813,194.546668 189.8775,194.59 L189.8775,194.59 C189.862183,194.84423 190.015221,195.078478 190.254189,195.166579 C190.493157,195.254681 190.761626,195.175832 190.915,194.9725 L190.915,194.9725 C190.940997,194.937674 190.962813,194.899916 190.98,194.86 C191.213642,194.323275 191.812522,194.046951 192.3725,194.2175 C192.413875,194.230796 192.456668,194.239187 192.5,194.2425 L192.5,194.2425 C192.75423,194.257817 192.988478,194.104779 193.076579,193.865811 C193.164681,193.626843 193.085832,193.358374 192.8825,193.205 L192.8825,193.205 C192.847674,193.179003 192.809916,193.157187 192.77,193.14 Z"
          fill="#747574"
          id="Path"
          opacity="0.5"
        ></path>
        <path
          d="M34.1675,141.875 C33.630775,141.641358 33.3544515,141.042478 33.525,140.4825 C33.5382965,140.441125 33.5466873,140.398332 33.55,140.355 L33.55,140.355 C33.5653171,140.10077 33.4122791,139.866522 33.1733111,139.778421 C32.9343432,139.690319 32.6658744,139.769168 32.5125,139.9725 L32.5125,139.9725 C32.4865035,140.007326 32.4646873,140.045084 32.4475,140.085 C32.2138582,140.621725 31.6149781,140.898049 31.055,140.7275 C31.0136254,140.714204 30.9708322,140.705813 30.9275,140.7025 L30.9275,140.7025 C30.6732699,140.687183 30.4390222,140.840221 30.3509207,141.079189 C30.2628193,141.318157 30.3416682,141.586626 30.545,141.74 L30.545,141.74 C30.5798258,141.765997 30.6175845,141.787813 30.6575,141.805 C31.194225,142.038642 31.4705485,142.637522 31.3,143.1975 C31.2867035,143.238875 31.2783127,143.281668 31.275,143.325 L31.275,143.325 C31.2596829,143.57923 31.4127209,143.813478 31.6516889,143.901579 C31.8906568,143.989681 32.1591256,143.910832 32.3125,143.7075 L32.3125,143.7075 C32.3384965,143.672674 32.3603127,143.634916 32.3775,143.595 C32.6111418,143.058275 33.2100219,142.781951 33.77,142.9525 C33.8113746,142.965796 33.8541678,142.974187 33.8975,142.9775 L33.8975,142.9775 C34.1517301,142.992817 34.3859778,142.839779 34.4740793,142.600811 C34.5621807,142.361843 34.4833318,142.093374 34.28,141.94 L34.28,141.94 C34.2451742,141.914003 34.2074155,141.892187 34.1675,141.875 Z"
          fill="#747574"
          id="Path"
          opacity="0.5"
        ></path>
        <path
          d="M166.27,17.045 C165.733275,16.8113582 165.456951,16.2124781 165.6275,15.6525 C165.640796,15.6111254 165.649187,15.5683322 165.6525,15.525 L165.6525,15.525 C165.667817,15.2707699 165.514779,15.0365222 165.275811,14.9484207 C165.036843,14.8603193 164.768374,14.9391682 164.615,15.1425 L164.615,15.1425 C164.589003,15.1773258 164.567187,15.2150845 164.55,15.255 C164.316358,15.791725 163.717478,16.0680485 163.1575,15.8975 C163.116125,15.8842035 163.073332,15.8758127 163.03,15.8725 L163.03,15.8725 C162.77577,15.8571829 162.541522,16.0102209 162.453421,16.2491889 C162.365319,16.4881568 162.444168,16.7566256 162.6475,16.91 L162.6475,16.91 C162.682326,16.9359965 162.720084,16.9578127 162.76,16.975 C163.296725,17.2086418 163.573049,17.8075219 163.4025,18.3675 C163.389204,18.4088746 163.380813,18.4516678 163.3775,18.495 L163.3775,18.495 C163.362183,18.7492301 163.515221,18.9834778 163.754189,19.0715793 C163.993157,19.1596807 164.261626,19.0808318 164.415,18.8775 L164.415,18.8775 C164.440997,18.8426742 164.462813,18.8049155 164.48,18.765 C164.713642,18.228275 165.312522,17.9519515 165.8725,18.1225 C165.913875,18.1357965 165.956668,18.1441873 166,18.1475 L166,18.1475 C166.25423,18.1628171 166.488478,18.0097791 166.576579,17.7708111 C166.664681,17.5318432 166.585832,17.2633744 166.3825,17.11 L166.3825,17.11 C166.347674,17.0840035 166.309916,17.0621873 166.27,17.045 L166.27,17.045 Z"
          fill="#747574"
          id="Path"
          opacity="0.5"
        ></path>
        <circle
          cx="203.16"
          cy="78.6175"
          fill="#747574"
          id="Oval"
          opacity="0.5"
          r="1.8825"
        ></circle>
        <circle
          cx="57.6825"
          cy="186.6625"
          fill="#747574"
          id="Oval"
          opacity="0.5"
          r="1.8825"
        ></circle>
        <circle
          cx="183.8275"
          cy="119.3075"
          fill="#747574"
          id="Oval"
          opacity="0.5"
          r="1.8825"
        ></circle>
        <circle
          cx="21.785"
          cy="24.0875"
          fill="#747574"
          id="Oval"
          opacity="0.5"
          r="1.8825"
        ></circle>
        <circle
          cx="1.8825"
          cy="75.44"
          fill="#747574"
          id="Oval"
          opacity="0.5"
          r="1.8825"
        ></circle>
      </g>
    </g>
  </svg>
);
