import * as React from 'react';
import { noop } from 'lodash';
import './FeesGridForm.scss';
import uuid from 'uuid/v4';
import {
  AccessDeniedGuard,
  DealPerspectivePermissions,
  DisabledChildProps,
} from '../../security';
import { FeesGridFormProps } from './types';
import FeeItem from './feeItem/FeeItem';
import FeeModalForm from './feeModalForm/FeeModalForm';
import { getKeyPath, transformTranchesToFeeGroupSet } from './utils';
import TrancheTierFormAdapter from './TrancheTierFormAdapter';
import { isWholeNumber } from 'utils';
import { FeeType } from 'types';
import { Form, Button, Tiler, Divider, Header } from 'components';

type State = {
  feeToEditId: string | null | undefined;
  trancheIndexToEdit: string | null | undefined;
};

class FeesGridForm extends React.Component<FeesGridFormProps, State> {
  addFeeButton: React.RefObject<HTMLDivElement> | null;

  constructor(props: FeesGridFormProps) {
    super(props);
    this.state = {
      feeToEditId: null,
      trancheIndexToEdit: null,
    };

    this.addFeeButton = React.createRef();
  }

  getHandleAddFee = (trancheIndex: string) => () => {
    const keyPath = getKeyPath(trancheIndex);
    const { toggleAutoSave, addEntity } = this.props;

    toggleAutoSave(false);

    const newFee: FeeType = {
      __typename: 'FeeType',
      id: uuid(),
    };

    addEntity(keyPath, newFee);
    this.setState({
      feeToEditId: newFee.id,
      trancheIndexToEdit: trancheIndex,
    });
  };

  handleEditFee = (feeToEdit: FeeType, trancheIndex: string) => {
    const { toggleAutoSave } = this.props;

    toggleAutoSave(false);

    this.setState({
      feeToEditId: feeToEdit.id,
      trancheIndexToEdit: trancheIndex,
    });
  };

  handleCloseModal = () => {
    const { toggleAutoSave } = this.props;
    toggleAutoSave(true);

    if (this.state.feeToEditId && !isWholeNumber(this.state.feeToEditId)) {
      if (this.addFeeButton) {
        this.addFeeButton.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      }
    }

    this.setState({
      feeToEditId: null,
      trancheIndexToEdit: null,
    });
  };

  getFeeToEdit = () => {
    const { trancheIndexToEdit, feeToEditId } = this.state;
    if (trancheIndexToEdit && feeToEditId) {
      const { data } = this.props;
      const { loantrancheSet } = data;
      const tranche = (loantrancheSet || [])[Number(trancheIndexToEdit)];
      const feeToEdit = (tranche.feeSet || []).filter(
        fee => fee.id === feeToEditId,
      )[0];

      return feeToEdit;
    }
    return null;
  };

  getFeeToEditIndex = () => {
    const { trancheIndexToEdit, feeToEditId } = this.state;
    let final = null;
    if (trancheIndexToEdit && feeToEditId) {
      const { data } = this.props;
      const { loantrancheSet } = data;
      const tranche = (loantrancheSet || [])[Number(trancheIndexToEdit)];
      (tranche.feeSet || []).every((fee, index) => {
        if (fee.id === feeToEditId) {
          final = String(index);
          return false;
        }
        return true;
      });
    }
    return final;
  };

  getTiersFromTrancheId = () => {
    const { trancheIndexToEdit } = this.state;
    if (trancheIndexToEdit) {
      const { data } = this.props;
      const tranches = data.loantrancheSet || [];
      const trancheToEdit = tranches[Number(trancheIndexToEdit)];
      if (trancheToEdit) {
        return [...(trancheToEdit.loantranchetierSet || [])];
      }
    }
    return [];
  };

  renderForm = (childProps: DisabledChildProps) => {
    const {
      data,
      replaceEntity,
      removeEntity,
      addEntity,
      isBorrower,
    } = this.props;
    const {
      undo,
      enableMultiLenderView,
      feeKindSlugMap,
      disabled,
    } = this.props;
    const { trancheIndexToEdit } = this.state;
    const { accessDenied } = childProps;
    const tranches = data.loantrancheSet || [];

    const trancheFeeGroupSetMap = transformTranchesToFeeGroupSet(
      tranches,
      feeKindSlugMap,
    );

    const canEdit: boolean = !accessDenied && !disabled;

    return (
      <Tiler>
        <Tiler.Tile>
          <Form className="feesGridForm" id="feesGridForm" onSubmit={noop}>
            {enableMultiLenderView ? (
              <div className="feesGridForm__Tiers">
                <Form.Header
                  as="h2"
                  className="feesGridForm__Header"
                  header="Tiers"
                />
                <div className="feesGridForm__Content feesGridForm__Content--Tiers">
                  {tranches.map((e, i) => (
                    <div className="feesGridForm__TrancheLane" key={e.id}>
                      <TrancheTierFormAdapter
                        canEdit={canEdit}
                        onAddTierRow={this.props.handleAddTierRow}
                        onDeleteTierRow={this.props.handleDeleteTierRow}
                        onEditTierRow={this.props.handleEditTierRow}
                        tranche={e}
                        trancheIndex={i}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <span />
            )}
            <div className="feesGridForm__Fees">
              <div className="feesGridForm__Content">
                {tranches.map((tranche, trancheIndex) => (
                  <div className="feesGridForm__TrancheLane" key={tranche.id}>
                    {/* hide header if there's no fee and can't edit */}
                    {!(
                      tranche.feeSet &&
                      tranche.feeSet.length === 0 &&
                      !canEdit
                    ) && (
                      <>
                        <Header as="h2" className="steel">
                          {tranche.name || ''}
                        </Header>
                        <Divider className="transparent" />
                      </>
                    )}
                    {(tranche.feeSet || []).map((fee, feeIndex) => {
                      const tiers = tranche.loantranchetierSet || [];
                      return (
                        <FeeItem
                          canEdit={canEdit}
                          fee={fee}
                          feeIndex={String(feeIndex)}
                          feeKind={feeKindSlugMap.get(fee.feeKindSlug || '')}
                          isBorrower={isBorrower}
                          key={fee.id}
                          onFeeEditClick={this.handleEditFee}
                          replaceEntity={replaceEntity}
                          tiers={tiers}
                          tranche={tranche}
                          trancheIndex={String(trancheIndex)}
                        />
                      );
                    })}
                    {canEdit && (
                      <div ref={this.addFeeButton}>
                        <Button.Text
                          className="feesGridForm__AddFeeButton"
                          label="+ Add Fee"
                          onClick={this.getHandleAddFee(String(trancheIndex))}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {canEdit && (
              <FeeModalForm
                addEntity={addEntity}
                feeIndex={this.getFeeToEditIndex()}
                feeKindSlugMap={feeKindSlugMap}
                feeToEdit={this.getFeeToEdit()}
                onClose={this.handleCloseModal}
                removeEntity={removeEntity}
                replaceEntity={replaceEntity}
                tiers={this.getTiersFromTrancheId()}
                trancheFeeGroupSetMap={trancheFeeGroupSetMap}
                trancheIndex={trancheIndexToEdit}
                tranches={tranches}
                undo={undo}
              />
            )}
          </Form>
        </Tiler.Tile>
      </Tiler>
    );
  };

  render() {
    return (
      <AccessDeniedGuard
        permission={DealPerspectivePermissions.administer_fees}
      >
        {this.renderForm}
      </AccessDeniedGuard>
    );
  }
}

export default FeesGridForm;
