import * as React from 'react';
import cx from 'classnames';
import { ux } from '../../utils';
import DataGridIconCell from './DataGridIconCell';
import DataGridTextInput from './DataGridTextInput';
import { Column, Value } from './types';
import './DataGridCell.scss';

/**
 * An individual cell of a DataGrid component.
 */

type Props<T, K extends keyof T> = {
  /**
   * The column configuration for the cell.
   */
  column: Column<T, K>;
  disabled?: boolean;
  index: number;
  isEditing: boolean;
  /**
   * The full row in which the cell exists; this is used
   * for computed cell values.
   */
  onBeginEdit: () => void;
  /**
   * The value, if any, of the cell.  For computed cells or
   * cells with their own renderers, this value can be
   * empty.
   */
  onEndEdit: () => void;
  /**
   * The callback to issue when the cell value changes.
   */
  onValueChanged?: (column: Column<T, K>, value: Value, entity?: any) => void;
  row: T;
  rowKey: K;
  value?: Value;

  visible?: boolean;
};

class DataGridCell<T, K extends keyof T> extends React.Component<Props<T, K>> {
  static defaultProps = {
    value: '',
    onValueChanged: () => undefined,
    visible: true,
  };

  handleOnValueChanged = (value: Value, entity?: any): void => {
    if (value !== this.props.value) {
      if (this.props.onValueChanged)
        this.props.onValueChanged(this.props.column, value, entity);
    }
  };

  handleClick = () => {
    const { row, column, index } = this.props;
    if (column.onClick) column.onClick(row, column, index);
  };

  render() {
    const {
      column,
      disabled,
      row,
      onBeginEdit,
      onEndEdit,
      isEditing,
      value = '',
      visible,
      index,
      rowKey,
    } = this.props;
    const computedClass = cx(
      'DataGridCell',
      ux(column.className, column.className),
      visible ? 'DataGridCell-Visible' : 'DataGridCell-Hidden',
    );
    if (column.icon && !column.accessor) {
      return (
        <DataGridIconCell
          className={computedClass}
          icon={column.icon}
          onClick={this.handleClick}
          visible={visible}
        />
      );
    }
    if (!column.readOnly && isEditing) {
      return (
        <div className={computedClass}>
          {column.editor ? (
            column.editor({
              disabled,
              onCancel: onEndEdit,
              onValueChanged: this.handleOnValueChanged,
              value,
              row,
              index,
              columnName: column.columnName,
              rowKey,
            })
          ) : (
            <DataGridTextInput
              columnName={column.columnName}
              disabled={disabled}
              index={index}
              onCancel={onEndEdit}
              onValueChanged={this.handleOnValueChanged}
              row={row}
              rowKey={rowKey}
              value={value}
            />
          )}
        </div>
      );
    }
    return (
      <div className={computedClass} onClick={onBeginEdit}>
        {column.renderer ? column.renderer(value, row) : value}
      </div>
    );
  }
}

export default DataGridCell;
