import * as React from 'react';
import { Form, WrappedMediatorFormProps } from 'components';

import {
  EventScheduleInput,
  EventScheduleType,
  EventScheduleEventFrequency,
  SelectOptionType,
} from 'types';

const objectType = 'EventSchedule';

type Props = WrappedMediatorFormProps<EventScheduleInput> & {
  eventSchedules: ReadonlyArray<EventScheduleType>;
};

type State = {
  eventEnds: 'NEVER' | 'ON' | 'AFTER';
};

const quarterOptions = [
  { text: '1', value: '1' },
  { text: '2', value: '2' },
  { text: '3', value: '3' },
  { text: '4', value: '4' },
];

const daysOfWeekOptions = [
  { text: 'M', value: '0' },
  { text: 'T', value: '1' },
  { text: 'W', value: '2' },
  { text: 'T', value: '3' },
  { text: 'F', value: '4' },
];

function notTimeBased(
  eventFrequency: EventScheduleEventFrequency | null | undefined,
) {
  return (
    !eventFrequency ||
    eventFrequency === 'AFTER_COMPLETED_EVENT' ||
    eventFrequency === 'DOES_NOT_REPEAT'
  );
}

class CalendarTaskFrequencyBody extends React.Component<Props, State> {
  fields: any;

  constructor(props: Props) {
    super(props);

    this.fields = {
      eventEnds: {
        id: 'eventEnds',
        propertyName: 'eventEnds',
        options: [
          { id: 'NEVER', label: 'Never' },
          { id: 'ON', label: 'On' },
          { id: 'AFTER', label: 'After' },
        ],
        onChange: this.eventEndsOnChange,
        width: 'two',
      },
      endDate: {
        id: 'endDate',
        propertyName: 'endDate',
        onChange: this.props.mutateProperty,
        width: 'eight',
      },
      numberOfOccurrences: {
        id: 'numberOfOccurrences',
        propertyName: 'numberOfOccurrences',
        onChange: this.props.mutateProperty,
        width: 'eight',
        suffix: 'occurences',
      },
      eventDefaultsTo: {
        id: 'eventDefaultsTo',
        fieldName: 'Default To',
        propertyName: 'eventDefaultsTo',
        onChange: this.props.mutateProperty,
        width: 'eight',
        typeName: 'EventScheduleEventDefaultsTo',
        required: true,
      },
      eventRepeatsOnMonthly: {
        id: 'eventRepeatsOnMonthly',
        fieldName: 'Repeat On',
        propertyName: 'eventRepeatsOnMonthly',
        onChange: this.props.mutateProperty,
        width: 'eight',
        typeName: 'EventScheduleEventRepeatsOnMonthly',
        required: true,
      },
      repeatsOnQuarterly: {
        id: 'eventRepeatsOnQuarterly',
        propertyName: 'eventRepeatsOnQuarterly',
        fieldName: 'Repeat on Quarters',
        options: quarterOptions,
        onChange: this.props.mutateProperty,
        width: 'four',
      },
      repeatsOnWeekly: {
        id: 'eventRepeatsOnWeekly',
        propertyName: 'eventRepeatsOnWeekly',
        fieldName: 'Repeat on Days',
        options: daysOfWeekOptions,
        onChange: this.props.mutateProperty,
        width: 'five',
      },
      eventFrequencyPeriod: {
        id: 'eventFrequencyPeriod',
        propertyName: 'eventFrequencyPeriod',
        fieldName: 'Repeat Every',
        onChange: this.props.mutateProperty,
        suffix: 'weeks',
        width: 'five',
      },
      triggeringEventSchedule: {
        id: 'triggeringEventSchedule',
        propertyName: 'triggeringEventScheduleId',
        fieldName: 'Triggered After',
        onChange: this.props.mutateProperty,
        width: 'eight',
        options: this.getTriggeringEventScheduleOptions(),
      },
    };

    this.state = {
      eventEnds: 'NEVER',
    };
  }

  componentDidMount = () => {
    const { data } = this.props;
    if (data.endDate || data.numberOfOccurrences) {
      if (data.endDate) {
        /* eslint-disable react/no-did-mount-set-state */
        this.setState({ eventEnds: 'ON' });
      }
      this.setState({ eventEnds: 'AFTER' });
      /* eslint-enable react/no-did-mount-set-state */
    }
  };
  eventEndsOnChange = (eventEnds: State['eventEnds']) => {
    this.setState({ eventEnds });
    if (eventEnds === 'NEVER') {
      this.props.mutateProperties([], {
        endDate: null,
        numberOfOccurrences: null,
      });
    }
    if (eventEnds === 'ON') {
      this.props.mutateProperty(null, 'numberOfOccurrences');
    }
    if (eventEnds === 'AFTER') {
      this.props.mutateProperty(null, 'endDate');
    }
  };

  getTriggeringEventScheduleOptions = () => {
    const { eventSchedules } = this.props;
    return eventSchedules
      ? eventSchedules.map<SelectOptionType>(schedule => ({
          text: schedule.name || '',
          value: schedule.id,
        }))
      : [];
  };

  renderEventEndSection = () => {
    const { data } = this.props;
    const { eventFrequency } = data;
    const { eventEnds } = this.state;

    if (notTimeBased(eventFrequency)) return null;

    return (
      <>
        <div className="calendarTaskFrequencyBody__Label">Ends</div>
        <Form.Group className="calendarTaskFrequencyBody__Group">
          {Form.FieldRenderer(
            Form.Radio,
            {
              ...this.fields.eventEnds,
              value: eventEnds,
            },
            this.props,
            objectType,
            data.id,
          )}
        </Form.Group>
        <Form.Group className="calendarTaskFrequencyBody__Group">
          {Form.FieldRenderer(
            Form.Calendar,
            { ...this.fields.endDate, disabled: eventEnds !== 'ON' },
            this.props,
            objectType,
            data.id,
          )}
        </Form.Group>
        <Form.Group className="calendarTaskFrequencyBody__Group">
          {Form.FieldRenderer(
            Form.Numeric,
            {
              ...this.fields.numberOfOccurrences,
              disabled: eventEnds !== 'AFTER',
            },
            this.props,
            objectType,
            data.id,
          )}
        </Form.Group>
      </>
    );
  };

  renderDefaultTo = () => {
    const { data } = this.props;
    const { eventFrequency } = data;

    if (notTimeBased(eventFrequency) || eventFrequency === 'DAILY') return null;
    return (
      <Form.Group>
        {Form.FieldRenderer(
          Form.ReferenceSelect,
          this.fields.eventDefaultsTo,
          this.props,
          objectType,
          this.props.data.id,
        )}
      </Form.Group>
    );
  };

  renderTriggeredAfterSection = () => {
    const { data } = this.props;
    const { eventFrequency } = data;
    if (eventFrequency !== 'AFTER_COMPLETED_EVENT') return null;
    return (
      <Form.Group>
        {Form.FieldRenderer(
          Form.Select,
          this.fields.triggeringEventSchedule,
          this.props,
          objectType,
          data.id,
        )}
      </Form.Group>
    );
  };

  renderRepeatsOnSection = () => {
    const { data } = this.props;
    const { eventFrequency } = data;

    switch (eventFrequency) {
      case 'MONTHLY':
        return (
          <Form.Group>
            {Form.FieldRenderer(
              Form.ReferenceSelect,
              this.fields.eventRepeatsOnMonthly,
              this.props,
              objectType,
              this.props.data.id,
            )}
          </Form.Group>
        );
      case 'WEEKLY':
        return (
          <Form.Group>
            {Form.FieldRenderer(
              Form.Numeric,
              this.fields.eventFrequencyPeriod,
              this.props,
              objectType,
              this.props.data.id,
            )}
            {Form.FieldRenderer(
              Form.CheckGroup,
              {
                ...this.fields.repeatsOnWeekly,
                checkedOptions: this.props.data.eventRepeatsOnWeekly || [],
              },
              this.props,
              objectType,
              this.props.data.id,
            )}
          </Form.Group>
        );
      case 'QUARTERLY':
        return (
          <>
            <Form.Group>
              {Form.FieldRenderer(
                Form.CheckGroup,
                {
                  ...this.fields.repeatsOnQuarterly,
                  checkedOptions: this.props.data.eventRepeatsOnQuarterly || [],
                },
                this.props,
                objectType,
                this.props.data.id,
              )}
            </Form.Group>
          </>
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <>
        {this.renderRepeatsOnSection()}
        {this.renderDefaultTo()}
        {this.renderEventEndSection()}
        {this.renderTriggeredAfterSection()}
      </>
    );
  }
}

export default CalendarTaskFrequencyBody;
