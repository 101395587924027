import React from 'react';
import { compose } from 'recompose';
import { Redirect } from 'react-router-dom';
import { DealsQuery } from 'lsgql';
import { RouteTable } from 'routing';
import { isDealClosed } from 'utils';

const SingleDealBorrower = (props: any) => {
  const { deals } = props;

  if (deals && deals[0]) {
    const singleDeal = deals[0];

    return isDealClosed(singleDeal.stage) ? (
      <Redirect to={RouteTable.deal.toLoanOverviewSummary(singleDeal.id)} />
    ) : (
      <Redirect to={RouteTable.deal.toDealClosingWorkflow(singleDeal.id)} />
    );
  }

  return null;
};

const SingleDealBorrowerWithQuery = compose(DealsQuery)(SingleDealBorrower);

export default SingleDealBorrowerWithQuery;
