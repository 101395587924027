import React from 'react';
import { DealType } from 'types';
import { Button } from 'components';
import { RouteTable } from 'routing';

type Props = {
  deal: DealType;
};

function SBAFirstDrawButton(props: Props) {
  const { deal } = props;

  if (!deal.isRoundTwoFirstDrawEligible) {
    return null;
  }

  return (
    <Button.Primary
      label="Apply for PPP First Draw Loan"
      to={RouteTable.deal.toSbaPppRoundTwoFirstDrawForm(deal.id)}
    />
  );
}

export default SBAFirstDrawButton;
