import { ApolloQueryResult } from '../types';
import { getAuthenticatedClient } from '../authenticatedClient';
import { AmortizationPrincipalPaymentsQueryDefinition } from './definitions';
import {
  LoanTrancheInput,
  LoanTrancheAmortizationInput,
  LoanTrancheAmortizationPrincipalPaymentType,
  LoanTrancheAmortizationPrincipalPaymentInput,
  AmortizationResponseType,
  LoanTrancheAmortizationInterestPeriodType,
  LoanTrancheInterestType,
} from 'types';

function setZero(v: string | null | undefined): string {
  return v === null || v === undefined || v === '' ? '0' : v;
}

const fixedPaymentflag: LoanTrancheInterestType = 'FIXED_PAYMENT';

function isFixedPayment(tranche: LoanTrancheInput): boolean {
  return tranche.interestType === fixedPaymentflag;
}

function scrubPeriod(e: LoanTrancheAmortizationInterestPeriodType): any {
  return {
    ...e,
    created: undefined,
    modified: undefined,
    __typename: undefined,
    id: undefined,
    isDirty: undefined,
  };
}

function scrubPayment(
  e: LoanTrancheAmortizationPrincipalPaymentType,
): LoanTrancheAmortizationPrincipalPaymentInput {
  return {
    ...e,
    created: undefined,
    modified: undefined,
    __typename: undefined,
    id: undefined,
    isDirty: undefined,
    principalBefore: undefined,
    principalAfter: undefined,
    fixedPayment: undefined,
    paymentFrequency: undefined,
  } as LoanTrancheAmortizationPrincipalPaymentInput;
}

function scrubAmortization(
  e: LoanTrancheAmortizationInput,
  tranche: LoanTrancheInput,
): LoanTrancheAmortizationInput {
  return {
    ...e,
    interestOnlyPeriods: setZero(e.interestOnlyPeriods),

    /*
    For non-fixed payment types we consider term and
    originalTerm to be the same.  This is a temp work around
    setZero(!isFixedPayment(t) ? t.originalTerm : e.term),
    */
    term: isFixedPayment(tranche)
      ? setZero(e.term) // Use Amortization term for Fixed Payment
      : setZero(tranche.originalTerm), // Else use the tranche term
    created: undefined,
    modified: undefined,
    __typename: undefined,
    id: undefined, // isWholeNumber(e.id) ? e.id : undefined,
    isDirty: undefined,
    principalBefore: undefined,
    principalAfter: undefined,
    fixedPayment: undefined,

    /*
    Temporarily not sending this
    */
    balloonPayment: undefined,
    // paymentFrequency: undefined,
    loantrancheamortizationprincipalpaymentSet: undefined,
    loantrancheamortizationinterestperiodSet: undefined,
    initialIndicativeBenchmark: undefined,
  } as LoanTrancheAmortizationInput;
}

function scrubTranche(e: LoanTrancheInput): LoanTrancheInput {
  return {
    ...e,
    created: undefined,
    modified: undefined,
    __typename: undefined,
    id: undefined, // isWholeNumber(e.id) ? e.id : undefined,
    isDirty: undefined,
    numberOfPayments: undefined,
    actualTerm: undefined,
    totalCommitmentAmount: setZero(e.totalCommitmentAmount),
    loantranchefloatingratedataId: undefined,
    loantrancheadjustableratedataId: undefined,
    loantranchemultidrawdataId: undefined,
    presentApplicableMarginId: undefined,
    presentApplicableMargin: undefined,
    loantrancherevolverswinglinelocdataId: undefined,
  } as LoanTrancheInput;
}

type ResType = { amortizationPrincipalPayments: AmortizationResponseType };
type PrincipalPaymentsQueryType = (
  arg0: LoanTrancheAmortizationInput,
  arg1: LoanTrancheInput,
  arg2: ReadonlyArray<LoanTrancheAmortizationPrincipalPaymentType>,
  arg3: ReadonlyArray<LoanTrancheAmortizationInterestPeriodType>,
  solveFor?: string | null | undefined,
) => Promise<AmortizationResponseType | null | undefined>;

const AmortizationPrincipalPaymentsQuery: PrincipalPaymentsQueryType = async (
  loanTrancheAmortizationData: LoanTrancheAmortizationInput,
  loanTrancheData: LoanTrancheInput,
  principalPayments: ReadonlyArray<LoanTrancheAmortizationPrincipalPaymentType>,
  interestPeriods: ReadonlyArray<LoanTrancheAmortizationInterestPeriodType>,
  solveFor?: string | null | undefined,
) => {
  const amortization: LoanTrancheAmortizationInput = {
    ...scrubAmortization(loanTrancheAmortizationData, loanTrancheData),
    loanTrancheId: undefined,
    amount: setZero(loanTrancheData.initialDrawAmount),
    paymentFrequency: loanTrancheData.paymentFrequency,
    fixedInterestRate: setZero(loanTrancheData.fixedInterestRate),
  };

  const variables: {
    [key: string]: any;
  } = {
    loanTrancheAmortizationData: amortization,
    loanTrancheData: scrubTranche(loanTrancheData),
    principalPayments: principalPayments.map(e => scrubPayment(e)),
    interestPeriods: interestPeriods.map(e => scrubPeriod(e)),
    applyRounding: false,
    solveFor,
  };

  const res: ApolloQueryResult<ResType> = await getAuthenticatedClient().query({
    query: AmortizationPrincipalPaymentsQueryDefinition,
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    variables,
  });

  return res.data && res.data.amortizationPrincipalPayments
    ? res.data.amortizationPrincipalPayments
    : null;
};

export default AmortizationPrincipalPaymentsQuery;
