import * as React from 'react';
import {
  BenchmarkTextValueSortOrderType,
  BenchmarkOptionsFieldsProps,
  SelectedBenchmarkOptions,
} from '../../types';
import { parseDayCountText } from '../utils';
import ApplicableMargins from './ApplicableMargins';
import {
  BenchmarkOptionType,
  FieldRendererGeneralPropsType,
  BenchmarkOptionDayCountConvention,
} from 'types';
import { Form, TypeQueryResult } from 'components';

import './OtherBenchmarkOptionsFields.scss';

type Props = BenchmarkOptionsFieldsProps &
  FieldRendererGeneralPropsType & {
    benchmark: BenchmarkTextValueSortOrderType | null | undefined;
    data: BenchmarkOptionType;
    fieldName: 'Prime' | 'Federal Funds';
    isSelected: boolean;
    onChange: (option: BenchmarkOptionType) => void;
    onDelete: (option: BenchmarkOptionType) => void;
    onToggleSelected: (option: SelectedBenchmarkOptions) => void;
  };

const benchmarkOptionName = 'BenchmarkOption';
const groupClassName = 'OtherBenchmarkOptionsFields__FormGroup';
const width = '158px';

class OtherBenchmarkOptionsFields extends React.Component<Props> {
  handleChangeDayCountConvention = (value: string | null | undefined) => {
    const benchmarkOption = this.props.data;
    if (benchmarkOption.dayCountConvention !== value) {
      this.props.onChange({
        ...benchmarkOption,
        dayCountConvention: value as BenchmarkOptionDayCountConvention,
      });
    }
  };

  handleChangeCheckbox = (value: boolean | null | undefined) => {
    const { data, benchmark, onToggleSelected, fieldName } = this.props;
    const applicableMarginType = fieldName === 'Prime' ? 'prime' : 'fedFunds';
    onToggleSelected(applicableMarginType);

    if (value) {
      this.props.onChange({
        ...data,
        benchmarkId: benchmark && benchmark.value,
      });
    } else {
      this.props.onDelete(data);
    }
  };

  /* eslint-enable react/sort-comp */

  applicableMarginType: 'prime' | 'fedFunds' =
    this.props.fieldName === 'Prime' ? 'prime' : 'fedFunds';

  fields = {
    benchmark: {
      id: 'benchmarkId',
      propertyName: `${this.props.fieldName}__benchmarkId`,
      label: this.props.fieldName,
      onChange: this.handleChangeCheckbox,
      width,
    },
    dayCountConvention: {
      id: 'dayCountConvention',
      propertyName: 'dayCountConvention',
      fieldName: 'Interest Day Count Rule',
      onChange: this.handleChangeDayCountConvention,
      typeName: 'BenchmarkOptionDayCountConvention',
      width,
    },
  };

  handleChangeBenchmarkId: (arg0: string | null | undefined) => void;

  render() {
    const {
      applicablemarginSet,
      blurEventForm,
      data,
      disabled,
      isFirstSelected,
      onUpdateApplicableMargin,
      handleRemoveApplicableMargin,
      trancheId,
      isSelected,
    } = this.props;

    return (
      <>
        <Form.Group className={groupClassName}>
          {Form.FieldRenderer(
            Form.Checkbox,
            { ...this.fields.benchmark, value: isSelected },
            this.props,
            benchmarkOptionName,
            trancheId,
          )}
        </Form.Group>
        {isSelected && (
          <Form.Group className={groupClassName}>
            {Form.FieldRenderer(
              Form.ReferenceSelect,
              {
                ...this.fields.dayCountConvention,
                resultFilter: (queryResults: Array<TypeQueryResult>) =>
                  queryResults.map(parseDayCountText),
              },
              this.props,
              benchmarkOptionName,
              data.id,
            )}
          </Form.Group>
        )}
        {isSelected && (
          <ApplicableMargins
            applicableMarginType={this.applicableMarginType}
            blurEventForm={blurEventForm}
            data={applicablemarginSet}
            disabled={Boolean(disabled)}
            errors={this.props.errors}
            handleRemoveApplicableMargin={handleRemoveApplicableMargin}
            isFirstSelected={isFirstSelected}
            isSaving={this.props.isSaving}
            onChange={onUpdateApplicableMargin}
          />
        )}
      </>
    );
  }
}

export default OtherBenchmarkOptionsFields;
