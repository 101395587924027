import * as React from 'react';
import { creditRatings } from '../../resources';
import { Form, IconButton } from 'components';
import { CreditRatingType, ValidationMap } from 'types';
import { hoverMethods } from 'utils';
import './CreditRatingsFields.scss';

const creditRatingName = 'CreditRating';

/* eslint-disable react/no-multi-comp */
const MoodysOptions = creditRatings.moodys.ratings.map(x => ({
  value: x,
  text: x,
}));

const FitchOptions = creditRatings.fitch.ratings.map(x => ({
  value: x,
  text: x,
}));
const SAndPOptions = creditRatings.sAndP.ratings.map(x => ({
  value: x,
  text: x,
}));

const customRating = 'Custom';

type CreditRatingFieldsProps = {
  data: CreditRatingType;
  disabled?: boolean;
  errors?: ValidationMap | null | undefined;
  isCustomRating: boolean;
  isFixedWidth: boolean;
  isSaving: boolean;
  isStub: boolean;
  onChange: (arg0: CreditRatingType) => void;
  onDelete: (rating: CreditRatingType) => void;
  selectedAgencies: Set<string>;
  tabIndex?: number;
  width: 'four' | 'eight' | 'three';
};

const Agencies = [
  { value: creditRatings.fitch.name, text: creditRatings.fitch.name },
  { value: creditRatings.moodys.name, text: creditRatings.moodys.name },
  { value: creditRatings.sAndP.name, text: creditRatings.sAndP.name },
  { value: customRating, text: customRating },
];

const defaultSelected: Array<string> = [];
const defaultSelectedAgencies: Set<string> = new Set(defaultSelected);

type HoverableState = { isHovered: boolean };

class CreditRatingFields extends React.Component<
  CreditRatingFieldsProps,
  HoverableState
> {
  static defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    selectedAgencies: defaultSelectedAgencies,
  };

  handleHoverLeave: () => void;

  handleMouseEnter: () => void;

  handleMouseLeave: () => void;

  handleMouseMove: (e: React.MouseEvent<any>) => void;

  constructor(props: CreditRatingFieldsProps) {
    super(props);
    this.state = { isHovered: false };
    this.handleHoverLeave = hoverMethods.handleHoverLeave.bind(this);
    this.handleMouseEnter = hoverMethods.handleMouseEnter.bind(this);
    this.handleMouseLeave = hoverMethods.handleMouseLeave.bind(this);
    this.handleMouseMove = hoverMethods.handleMouseMove.bind(this);
  }

  handleChangeAgency = (value: string | null | undefined) => {
    const creditRating = this.props.data;

    if (value === customRating) {
      this.props.onChange({ ...creditRating, agency: '', rating: '' });
    } else if (creditRating.agency !== value)
      this.props.onChange({ ...creditRating, agency: value, rating: '' });
  };

  handleChangeRating = (value: string | null | undefined) => {
    const creditRating = this.props.data;
    this.props.onChange({ ...creditRating, rating: value });
  };

  handleDelete = () => {
    this.props.onDelete(this.props.data);
  };

  renderCustomRating() {
    const {
      data,
      isFixedWidth,
      disabled,
      selectedAgencies,
      isStub,

      /* eslint-disable @typescript-eslint/no-unused-vars */
      isSaving,
      errors,
      /* eslint-enable @typescript-eslint/no-unused-vars */
    } = this.props;

    const { isHovered } = this.state;
    const canDelete = !disabled && !isStub;

    return (
      <Form.Group
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {Form.FieldRenderer(
          Form.Select,
          {
            id: `CustomAgency_${data.id}`,
            fieldName: 'Agency',
            options: Agencies.filter(
              e =>
                e.value === data.agency ||
                e.value === customRating ||
                (e.value && !selectedAgencies.has(e.value)),
            ),
            onChange: this.handleChangeAgency,
            width: '158px',
            allowEmpty: false,
            tabIndex: this.props.tabIndex,
            propertyName: 'agency',
            disabled,
            value: 'Custom',
          },
          this.props,
          creditRatingName,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Input,
          {
            id: `CustomName_${data.id}`,
            fieldName: 'Custom Name',
            onChange: this.handleChangeAgency,
            propertyName: 'agency',
            width: '158px',
            tabIndex: this.props.tabIndex,
          },
          this.props,
          creditRatingName,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Input,
          {
            id: `CustomRating_${data.id}`,
            fieldName: 'Custom Rating',
            onChange: this.handleChangeRating,
            propertyName: 'rating',
            width: '158px',
            tabIndex: this.props.tabIndex,
          },
          this.props,
          creditRatingName,
          data.id,
        )}
        {canDelete && isHovered && (
          <IconButton
            alt="Remove Credit Rating"
            className={
              !isFixedWidth
                ? 'CreditRatings-Form-DeleteButton'
                : 'CreditRatings-Form-DeleteButton--Fixed'
            }
            icon="trash"
            onClick={this.handleDelete}
          />
        )}
      </Form.Group>
    );
  }

  renderStandardRating() {
    const {
      data,
      selectedAgencies,
      isFixedWidth,
      disabled,
      isStub,
    } = this.props;
    const { isHovered } = this.state;
    const canDelete = !disabled && !isStub;

    let options = [];

    if (data && data.agency) {
      if (data.agency === creditRatings.moodys.name) options = MoodysOptions;
      else if (data.agency === creditRatings.fitch.name) options = FitchOptions;
      else if (data.agency === creditRatings.sAndP.name) options = SAndPOptions;
    }
    return (
      <Form.Group
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {Form.FieldRenderer(
          Form.Select,
          {
            id: `Agency_${data.id}`,
            fieldName: 'Agency',
            options: Agencies.filter(
              e =>
                e.value === data.agency ||
                e.value === customRating ||
                (e.value && !selectedAgencies.has(e.value)),
            ),
            onChange: this.handleChangeAgency,
            width: '158px',
            allowEmpty: false,
            tabIndex: this.props.tabIndex,
            propertyName: 'agency',
            disabled,
          },
          this.props,
          creditRatingName,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Select,
          {
            id: `Rating_${data.id}`,
            fieldName: 'Rating',
            options,
            disabled: disabled || options.length === 0 || !data.agency,
            onChange: this.handleChangeRating,
            width: '158px',
            propertyName: 'rating',
            tabIndex: this.props.tabIndex,
          },
          this.props,
          creditRatingName,
          data.id,
        )}
        {canDelete && isHovered && (
          <IconButton
            alt="Remove Credit Rating"
            className={
              !isFixedWidth
                ? 'CreditRatings-Form-DeleteButton'
                : 'CreditRatings-Form-DeleteButton--Fixed'
            }
            icon="trash"
            onClick={this.handleDelete}
          />
        )}
      </Form.Group>
    );
  }

  render() {
    const { isCustomRating } = this.props;
    if (isCustomRating) return this.renderCustomRating();
    return this.renderStandardRating();
  }
}

export default CreditRatingFields;
