import * as React from 'react';
import uuid from 'uuid/v4';
import { LoanTrancheFormProps } from '../types';
import { isLetterOfCredit } from './utils';
import TrancheGroup from './TrancheGroup';
import { Form, Button, IconButton } from 'components';
import { ID, ReserveType, FormFieldsType } from 'types';
import { KeyPath } from 'lsredux';
import { hoverMethods } from 'utils';

import './EscrowFields.scss';

const initialEscrowId = uuid();
const reserveName = 'Reserve';
const escrowName = 'EscrowAccount';
const width = '158px';

/* eslint-disable react/no-multi-comp */

type ReserveFieldsProps = LoanTrancheFormProps & {
  addEntity: (keyPath: KeyPath, entity: any) => void;
  data: ReserveType | null | undefined;
  removeEntity: (keyPath: KeyPath, entity: any) => void;
  replaceEntity: (keyPath: KeyPath, entity: any) => void;
};

type HoverState = { isHovered: boolean };
class ReserveFields extends React.Component<ReserveFieldsProps, HoverState> {
  handleHoverLeave: () => void;

  handleMouseEnter: () => void;

  handleMouseLeave: () => void;

  handleMouseMove: (e: React.MouseEvent<any>) => void;

  constructor(props: ReserveFieldsProps) {
    super(props);
    this.state = { isHovered: false };
    this.handleHoverLeave = hoverMethods.handleHoverLeave.bind(this);
    this.handleMouseEnter = hoverMethods.handleMouseEnter.bind(this);
    this.handleMouseLeave = hoverMethods.handleMouseLeave.bind(this);
    this.handleMouseMove = hoverMethods.handleMouseMove.bind(this);
  }

  getDefaultReserve = (): ReserveType => ({
    id: 'Placeholder',
    reserveType: null,
    originalAmount: '0',
    __typename: 'ReserveType',
  });

  handleChange = (value: string | null | undefined, fieldId: ID) => {
    const { data } = this.props;

    const field = fieldId.split('_')[0];

    if (data) {
      // edit
      this.props.replaceEntity('reserveSet', { ...data, [field]: value });
    } else {
      // add
      this.props.addEntity('reserveSet', {
        ...{
          id: uuid(),
          originalAmount: '0',
          reserveType: null,
          __typename: 'ReserveType',
        },
        [field]: value,
      });
    }
  };

  handleTypeChange = (value: string | null | undefined) => {
    this.handleChange(value, 'reserveType');
  };

  handleAmountChange = (value: string | null | undefined) => {
    this.handleChange(value, 'originalAmount');
  };

  handleDelete = () => {
    this.props.removeEntity('reserveSet', this.props.data);
  };

  render() {
    const { isHovered } = this.state;
    const { disabled } = this.props;
    // eslint-disable-next-line
    const data: ReserveType | null | undefined = this.props.data
      ? ((this.props.data as any) as ReserveType)
      : this.getDefaultReserve();

    const id: ID = data ? data.id : 'New';
    return (
      <Form.Group
        className="EscrowReserve"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {Form.FieldRenderer(
          Form.Input,
          {
            id: `reserveType_${id}`,
            propertyName: 'reserveType',
            fieldName: 'Reserve Name',
            onChange: this.handleTypeChange,
            width,
            tabIndex: this.props.tabIndex,
          },
          { ...this.props, data, saving: this.props.isSaving },
          reserveName,
          id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            id: `reserveAmount_${id}`,
            fieldName: 'Reserve Amount',
            propertyName: 'originalAmount',
            onChange: this.handleAmountChange,
            width,
            tabIndex: this.props.tabIndex,
          },
          { ...this.props, data, saving: this.props.isSaving },
          reserveName,
          id,
        )}
        {isHovered && !disabled && (
          <IconButton
            alt="Remove Reserve"
            className="EscrowFields-DeleteButton"
            icon="trash"
            onClick={this.handleDelete}
          />
        )}
      </Form.Group>
    );
  }
}

class ReserveFieldsCollection extends React.Component<LoanTrancheFormProps> {
  handleAddReserve = () => {
    this.props.addEntity('reserveSet', {
      id: uuid(),
      originalAmount: null,
      reserveType: null,
      __typename: 'ReserveType',
    });
  };

  canAddReserve = () => {
    const {
      data: { reserveSet = [] },
    } = this.props;

    const lastReserve =
      reserveSet.length > 0 ? reserveSet[reserveSet.length - 1] : null;

    return (
      lastReserve !== null &&
      (lastReserve.reserveType !== null || lastReserve.originalAmount !== null)
    );
  };

  renderRows() {
    const {
      data: { reserveSet = [] },
    } = this.props;

    if (reserveSet.length > 0) {
      return reserveSet.map(e => (
        <ReserveFields key={e.id} {...this.props} data={e} />
      ));
    }

    return <ReserveFields {...this.props} data={null} />;
  }

  render() {
    return (
      <>
        {this.renderRows()}
        <div>
          <Button.Text
            disabled={!this.canAddReserve()}
            label="+ Add Another Reserve"
            onClick={this.handleAddReserve}
          />
        </div>
      </>
    );
  }
}

class EscrowFields extends React.Component<LoanTrancheFormProps> {
  fields: FormFieldsType = {};

  constructor(props: LoanTrancheFormProps) {
    super(props);
    this.fields = {
      reserveName: {
        id: 'reserveName',
        propertyName: 'reserveName',
        fieldName: 'Reserve Name',
        onChange: this.props.mutateProperty,
        width,
        tabIndex: this.props.tabIndex,
      },
      reserveAmount: {
        id: 'reserveAmount',
        propertyName: 'originalAmount',
        fieldName: 'Reserve Amount',
        onChange: this.props.mutateProperty,
        width,
        tabIndex: this.props.tabIndex,
      },
      escrowName: {
        id: 'name',
        propertyName: 'name',
        fieldName: 'Escrow Name',
        onChange: this.handleEscrowChange,
        width,
        tabIndex: this.props.tabIndex,
      },
      escrowInitialBalance: {
        id: 'initialBalance',
        propertyName: 'initialBalance',
        fieldName: 'Initial Balance',
        onChange: this.handleEscrowChange,
        width,
        tabIndex: this.props.tabIndex,
      },

      escrowPaymentAmount: {
        id: 'paymentAmount',
        propertyName: 'paymentAmount',
        fieldName: 'Payment Amount',
        onChange: this.handleEscrowChange,
        width,
        tabIndex: this.props.tabIndex,
      },

      escrowPaymentFrequency: {
        id: 'paymentFrequency',
        propertyName: 'paymentFrequency',
        fieldName: 'Payment Frequency',
        onChange: this.handleEscrowChange,
        width,
        tabIndex: this.props.tabIndex,
        typeName: 'EscrowAccountPaymentFrequency',
        allowEmpty: false,
      },
    };
  }

  getInitialEscrow = () => ({
    id: initialEscrowId,
    name: null,
    initialBalance: '0',
    paymentAmount: '0',
    paymentFrequency: this.props.data.paymentFrequency,
    __typename: 'EscrowAccountInput',
  });

  getEscrow = () => {
    const { data } = this.props;
    return data.escrowaccountSet && data.escrowaccountSet.length > 0
      ? data.escrowaccountSet[0]
      : this.getInitialEscrow();
  };

  getEscrowExists = () => {
    const notNull = this.props.data.escrowaccountSet !== null;

    const hasEntry = this.props.data.escrowaccountSet.length > 0;

    return notNull && hasEntry;
  };

  handleEscrowChange = (value: string | null | undefined, fieldId: string) => {
    const escrow = { ...this.getEscrow(), [fieldId]: value };

    if (this.getEscrowExists()) {
      this.props.replaceEntity('escrowaccountSet', escrow);
    } else {
      this.props.addEntity('escrowaccountSet', escrow);
    }
  };

  renderEscrowFields() {
    const escrow = this.getEscrow();

    const escrowProps = {
      ...this.props,
      data: escrow,
    };

    const escrowHasName = Boolean(escrow.name);
    return (
      <>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Input,
            this.fields.escrowName,
            escrowProps,
            escrowName,
            escrow.id,
          )}
          {Form.FieldRenderer(
            Form.Money,
            escrowHasName
              ? this.fields.escrowInitialBalance
              : {
                  ...this.fields.escrowInitialBalance,
                  disabled: true,
                },
            escrowProps,
            escrowName,
            escrow.id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Money,
            escrowHasName
              ? this.fields.escrowPaymentAmount
              : {
                  ...this.fields.escrowPaymentAmount,
                  disabled: true,
                },
            escrowProps,
            escrowName,
            escrow.id,
          )}
          {Form.FieldRenderer(
            Form.ReferenceSelect,
            {
              ...this.fields.escrowPaymentFrequency,
              value: escrow.paymentFrequency,
              disabled: !escrowHasName,
            },
            escrowProps,
            escrowName,
            escrow.id,
          )}
        </Form.Group>
      </>
    );
  }

  render() {
    const { data } = this.props;
    if (isLetterOfCredit(data)) return false;
    return (
      <TrancheGroup>
        <ReserveFieldsCollection {...this.props} />
        {this.renderEscrowFields()}
      </TrancheGroup>
    );
  }
}

export default EscrowFields;
