import * as React from 'react';
import { fromJS } from 'immutable';
import uuid from 'uuid/v4';
import { parseDayCountText } from '../sections/utils';
import CreateAmortizationForm, {
  GenericSolveForRowProps,
  GenericAmortizationFormProps,
  Args,
} from './createLoanTrancheAmortizationForm/CreateLoanTrancheAmortizationForm';

import PaymentRow from './createLoanTrancheAmortizationForm/PaymentRow';
import InterestScheduleRow from './createLoanTrancheAmortizationForm/InterestScheduleRow';
import SolveForRow from './solveForButtons/SolveFor';
import {
  AmortizationColumns,
  InterestScheduleColumns,
  TrancheFieldRenderer,
  AmortFieldRenderer,
  validAmortizationTypes,
} from './shared';
import {
  LoanTrancheAmortizationInterestPeriodType,
  FormFieldsType,
} from 'types';
import { Form, TypeQueryResult } from 'components';

const FieldError = ' ';

function generateInterestScheduleRows(props: GenericAmortizationFormProps) {
  const { data } = props;
  const { loantrancheadjustableratedata } = data;

  if (!loantrancheadjustableratedata) {
    // eslint-disable-next-line
    console.log('Cannot generate interest schedule!');
    return;
  }

  const { originalTerm } = data;
  const { initialPeriod, resetPeriod } = loantrancheadjustableratedata;

  if (!originalTerm || !initialPeriod || !resetPeriod) return;

  const totalPeriods = +originalTerm * 12;

  const schedule = [];
  const initial: LoanTrancheAmortizationInterestPeriodType = {
    id: uuid(),
    __typename: 'LoanTrancheAmortizationInterestPeriodType',
    startPeriod: 1 as any,
    endPeriod: (+initialPeriod * 12) as any,
    interestRate: null,
    effectiveDate: data.initialFundingDate,
  };
  schedule.push(initial);

  const offset = +initial.endPeriod + 1;

  let previousEnd: number = +initial.endPeriod;

  while (previousEnd <= totalPeriods) {
    const next = previousEnd + 1;
    previousEnd = next + offset;
    schedule.push({
      startPeriod: next,
      endPeriod: Math.min(totalPeriods, previousEnd),
      interestRate: null,
      effectiveDate: data.initialFundingDate,
      id: uuid(),
      __typename: 'LoanTrancheAmortizationInterestPeriodType',
    });
  }

  props.mutateAmortization(
    fromJS(schedule),
    'loantrancheamortizationinterestperiodSet',
  );
}

/* eslint-disable react/no-multi-comp */

class AdjustableRateSolveForRow extends React.Component<
  GenericSolveForRowProps
> {
  handleSolveForClick = (value: string) => {
    this.props.handleSolveFor(value);
  };

  render() {
    return (
      <>
        <SolveForRow>
          <SolveForRow.Item>Solve For:</SolveForRow.Item>
        </SolveForRow>
        <SolveForRow>
          <SolveForRow.Button
            onChange={this.handleSolveForClick}
            text="Fixed Payment Amount"
            value="fixed_payment_amount"
          />
          <SolveForRow.Button
            onChange={this.handleSolveForClick}
            text="Fixed Interest Rate"
            value="fixed_interest_rate"
          />
          <SolveForRow.Button
            onChange={this.handleSolveForClick}
            text="Final Payment/Balloon"
            value="balloon_payment"
          />
          <SolveForRow.Button
            onChange={this.handleSolveForClick}
            text="Original Balance"
            value="initial_draw_amount"
          />
        </SolveForRow>
      </>
    );
  }
}

class AdjustableRateForm extends React.Component<GenericAmortizationFormProps> {
  fields: FormFieldsType = {};

  constructor(props: GenericAmortizationFormProps) {
    super(props);

    this.fields = {
      originalBalance: {
        id: 'initialDrawAmount',
        propertyName: 'initialDrawAmount',
        fieldName: 'Original Balance',
        onChange: this.props.mutateProperty,
        width: 'four',
      },
      originalTerm: {
        id: 'originalTerm',
        propertyName: 'originalTerm',
        fieldName: 'Original Term',
        onChange: this.props.mutateProperty,
        width: 'two',
      },

      balloonPayment: {
        id: 'balloonPayment',
        propertyName: 'balloonPayment',
        fieldName: 'Final Payment/Balloon',
        onChange: this.props.mutateAmortization,
        width: 'three',
      },
      amortizationType: {
        id: 'amortizationType',
        propertyName: 'amortizationType',
        fieldName: 'Amortization Type',
        onChange: this.props.mutateAmortization,
        typeName: 'LoanTrancheAmortizationAmortizationType',
        width: 'four',
        resultFilter: (res: Array<TypeQueryResult>) =>
          res.filter(e => validAmortizationTypes.has(e.value as any)),
      },
      paymentFrequency: {
        id: 'paymentFrequency',
        propertyName: 'paymentFrequency',
        fieldName: 'Payment Frequency',
        onChange: this.props.mutateProperty,
        typeName: 'LoanTranchePaymentFrequency',
        width: 'four',
      },
      interestOnlyPeriods: {
        id: 'interestOnlyPeriods',
        propertyName: 'interestOnlyPeriods',
        fieldName: 'Interest Only Periods',
        onChange: this.props.mutateAmortization,
        width: 'three',
      },
      firstPrincipalPayment: {
        id: 'initialAmount',
        propertyName: 'initialAmount',
        fieldName: 'First Principal Pmt',
        onChange: this.props.mutateAmortization,
        width: 'three',
      },

      fixedInterestRate: {
        id: 'fixedInterestRate',
        propertyName: 'fixedInterestRate',
        fieldName: 'Fixed Interest Rate',
        onChange: this.props.mutateProperty,
        width: 'three',
        tabIndex: this.props.tabIndex,
      },
      dayCountConvention: {
        id: 'dayCountConvention',
        propertyName: 'dayCountConvention',
        fieldName: 'Day Count',
        onChange: this.props.mutateProperty,
        typeName: 'LoanTrancheDayCountConvention',
        width: 'four',
      },
      fixedPayment: {
        id: 'fixedPaymentAmount',
        propertyName: 'fixedPaymentAmount',
        fieldName: 'Fixed Payment',
        onChange: this.props.mutateProperty,
        width: 'four',
      },
      term: {
        id: 'term',
        propertyName: 'term',
        fieldName: 'Amortization Term',
        onChange: this.props.mutateAmortization,
        width: 'two',
      },
    };
  }

  componentDidMount() {
    generateInterestScheduleRows(this.props);
  }

  render() {
    const { amortization } = this.props;
    const amortProps = { ...this.props, data: amortization };
    const queryError = Boolean(
      amortization.readerData && (amortization.readerData as any).hasErrors,
    );
    const { readerData } = amortization;

    return (
      <>
        <Form.Group unstackable>
          {TrancheFieldRenderer(
            Form.Money,
            this.fields.originalBalance,
            this.props,
            amortization.id,
            readerData,
          )}
          {TrancheFieldRenderer(
            Form.Percentage,
            { ...this.fields.fixedInterestRate, width: 'four' },
            this.props,
            amortization.id,
            readerData,
          )}
          {TrancheFieldRenderer(
            Form.ReferenceSelect,
            {
              ...this.fields.dayCountConvention,
              width: 'four',
              resultFilter: (queryResults: Array<TypeQueryResult>) =>
                queryResults.map(parseDayCountText),
            },
            this.props,
            amortization.id,
            readerData,
          )}
          {Form.FieldRenderer(
            Form.Money,
            { ...this.fields.fixedPayment, width: 'four' },
            this.props,
            amortization.id,
            readerData,
          )}
        </Form.Group>
        <Form.Group unstackable>
          {TrancheFieldRenderer(
            Form.Decimal,
            this.fields.originalTerm,
            this.props,
            amortization.id,
            readerData,
          )}
          {AmortFieldRenderer(
            Form.Decimal,
            { ...this.fields.term, fieldName: 'Amort. Term' },
            amortProps,
            amortization.id,
            readerData,
          )}
          {TrancheFieldRenderer(
            Form.ReferenceSelect,
            { ...this.fields.paymentFrequency, width: 'four' },
            this.props,
            amortization.id,
            readerData,
          )}
          {AmortFieldRenderer(
            Form.Numeric,
            {
              ...(!queryError
                ? this.fields.interestOnlyPeriods
                : {
                    ...this.fields.interestOnlyPeriods,
                    error: FieldError,
                  }),
              width: 'four',
            },
            amortProps,
            amortization.id,
            readerData,
          )}
          {AmortFieldRenderer(
            Form.Money,
            {
              ...(!queryError
                ? this.fields.balloonPayment
                : {
                    ...this.fields.balloonPayment,
                    error: FieldError,
                  }),
              width: 'four',
            },
            amortProps,
            amortization.id,
            readerData,
          )}
        </Form.Group>
      </>
    );
  }
}

const args: Args = {
  columns: [
    AmortizationColumns.count,
    AmortizationColumns.date,
    AmortizationColumns.beginningBalance,
    AmortizationColumns.interestAmount,
    AmortizationColumns.principalPayment,
    AmortizationColumns.endingBalance,
    AmortizationColumns.errorColumn,
  ],
  paymentRowComponent: PaymentRow as any,
  formComponent: AdjustableRateForm,
  solveForRowComponent: AdjustableRateSolveForRow,
  interestSchedule: {
    columns: [
      InterestScheduleColumns.periods,
      InterestScheduleColumns.startDate,
      InterestScheduleColumns.interestRate,
      InterestScheduleColumns.totalPayment,
    ],
    rowComponent: InterestScheduleRow as any,
  },
};

const AdjustableRateAmortizationModalForm = CreateAmortizationForm(args);

export default AdjustableRateAmortizationModalForm;
