import React, { Component } from 'react';
import uuid from 'uuid/v4';
import { getConvertToSelectOption } from './utils';
import UserRoleTable from './UserRoleTable';
import {
  Form,
  FormFrame,
  LoadingIndicator,
  Button,
  WrappedMediatorFormProps,
} from 'components';
import {
  AlertType,
  InstitutionType,
  GenerateDebugDealInput,
  RoleType,
  UserType,
  NamedBaseType,
} from 'types';

import { GenerateDebugDeal } from 'lsgql';

type Props = WrappedMediatorFormProps<GenerateDebugDealInput> & {
  institutions: InstitutionType[];
  loading: boolean;
  roles: RoleType[];
  setAlert: (alert: string, alertType?: AlertType) => void;
  testDeals: NamedBaseType[];
  testTranches: NamedBaseType[];
  user: UserType;
};

type State = {
  isSaving: boolean;
};

const empty = [];
const LeadLenderRoleListKeyPath = 'leadLenderRoleList';
const ServicingAgentRoleListKeyPath = 'servicingAgentRoleList';
const BorrowerRoleListKeyPath = 'borrowerRoleList';
const leadLenderRoles = new Set(['deal_view_only', 'deal_administrator']);
const borrowerRoles = new Set([
  'deal_borrower_administrator',
  'deal_borrower_view_only',
]);

export default class DealGenerator extends Component<Props, State> {
  fields: {
    [key: string]: any;
  };

  state = { isSaving: false };

  constructor(props: Props) {
    super(props);

    this.fields = {
      name: {
        id: 'name',
        fieldName: 'Deal Name',
        propertyName: 'name',
        onChange: this.mutatePropertyDeal,
        width: 'eight',
      },
      lender: {
        id: 'leadLender',
        fieldName: 'Lead Lender',
        propertyName: 'leadLenderId',
        onChange: this.handleChangeLeadLender,
        width: 'eight',
        allowEmpty: false,
      },
      servicingAgent: {
        id: 'servicingAgent',
        fieldName: 'Servicing Agent',
        propertyName: 'servicingAgentId',
        onChange: this.handleChangeServicingAgent,
        width: 'eight',
      },
      borrower: {
        id: 'borrower',
        fieldName: 'Borrower',
        propertyName: 'borrowerId',
        onChange: this.handleChangeBorrower,
        width: 'eight',
      },
      templateDeals: {
        id: 'templateDeals',
        fieldName: 'Template Deal',
        propertyName: 'templateDealId',
        onChange: this.props.mutateProperty,
        width: 'eight',
      },
      templateTranches: {
        multiple: true,
        id: 'templateTranches',
        fieldName: 'Template Tranches',
        propertyName: 'templatetrancheIdSet',
        width: 'eight',
        onChange: this.props.mutateProperty,
        allowEmpty: false,
      },
    };
  }

  mutatePropertyDeal = (
    value: string | null | undefined,
    fieldName: string,
  ) => {
    this.props.mutateProperty(value, ['dealData', fieldName]);
  };

  handleChangeLeadLender = (value: string, fieldName: string) => {
    const { data, mutateProperty, deleteCollection } = this.props;
    if (data.leadLenderId !== value) {
      mutateProperty(value, fieldName);
      deleteCollection(LeadLenderRoleListKeyPath, data.leadLenderRoleList);
    }
  };

  handleChangeServicingAgent = (value: string, fieldName: string) => {
    const { data, mutateProperty, deleteCollection } = this.props;
    if (data.servicingAgentId !== value) {
      mutateProperty(value, fieldName);
      deleteCollection(
        ServicingAgentRoleListKeyPath,
        data.servicingAgentRoleList,
      );
    }
  };

  handleChangeBorrower = (value: string, fieldName: string) => {
    const { data, mutateProperty, deleteCollection } = this.props;
    if (data.borrowerId !== value) {
      mutateProperty(value, fieldName);
      deleteCollection(BorrowerRoleListKeyPath, data.borrowerRoleList);
    }
  };

  handleQuickAddUser = () => {
    const { user, addEntity, roles, deleteCollection, data } = this.props;
    if (user && user.institution) {
      this.handleChangeLeadLender(user.institution.id, 'leadLenderId');
      const adminRole = roles.find(role => role.slug === 'deal_administrator');
      if (adminRole) {
        deleteCollection(LeadLenderRoleListKeyPath, data.leadLenderRoleList);
        addEntity(LeadLenderRoleListKeyPath, {
          userId: user.id,
          roleId: adminRole.id,
          id: uuid(),
          __typename: 'UserRoleInput',
        });
      }
    }
  };

  handleGenerateDeal = async () => {
    const { setAlert, clearState } = this.props;

    this.setState({
      isSaving: true,
    });
    let res;
    try {
      res = await GenerateDebugDeal(this.props.data);
      if (res.data.generateDebugDeal.ok) {
        setAlert(
          `Deal ${res.data.generateDebugDeal.deal.name} was successfully created!`,
        );
        clearState();
      }
    } catch (e) {
      /* eslint-disable no-console */
      console.warn(`There was an issue generating test deal! ${e}`);
      setAlert('There was an issue generating deal', 'error');

      /* eslint-enable no-console */
      res = Promise.reject();
    }

    this.setState({
      isSaving: false,
    });

    return res;
  };

  render() {
    const {
      data,
      institutions,
      loading,
      roles,
      removeEntity,
      addEntity,
      testDeals,
      testTranches,
    } = this.props;

    const { isSaving } = this.state;

    const institutionOptions = (institutions || []).map(
      getConvertToSelectOption('name'),
    );

    const testDealOptions = (testDeals || []).map(
      getConvertToSelectOption('name'),
    );
    const testTranchesOptions = (testTranches || []).map(
      getConvertToSelectOption('name'),
    );
    const institutionUserMap = (institutions || []).reduce(
      (userMap, institution) => {
        userMap.set(
          institution.id,
          (institution.userSet || []).map(getConvertToSelectOption('username')),
        );
        return userMap;
      },
      new Map(),
    );

    const borrowerUserMap = (institutions || []).reduce(
      (userMap, institution) => {
        userMap.set(
          institution.id,
          (institution.userSet || []).map(getConvertToSelectOption('username')),
        );
        return userMap;
      },
      new Map(),
    );

    const disabled = !data.leadLenderId || !data.dealData.name;

    if (isSaving || loading) {
      return <LoadingIndicator />;
    }

    return (
      <FormFrame
        disabled={disabled}
        formId="generateDeal"
        loading={isSaving}
        onSubmit={this.handleGenerateDeal}
        saving={isSaving}
      >
        <Form.Group className="DealGenerator__QuickAdd">
          <div className="DealGenerator__QuickAddHeader">Quick Add:</div>
          <Button
            label="My Institution & I"
            onClick={this.handleQuickAddUser}
          />
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Input,
            this.fields.name,
            { ...this.props, data: data.dealData },
            'Deal',
            data.id,
          )}
        </Form.Group>
        <Form.Divider />
        <Form.Group>
          {Form.FieldRenderer(
            Form.Select,
            {
              ...this.fields.templateDeals,
              options: testDealOptions,
            },
            this.props,
            'Deal',
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Select,
            {
              ...this.fields.templateTranches,
              options: testTranchesOptions,
            },
            this.props,
            'Deal',
            data.id,
          )}
        </Form.Group>
        <Form.Divider />
        <Form.Header header="Lead Lender" />
        <Form.Group>
          {Form.FieldRenderer(
            Form.Select,
            { ...this.fields.lender, options: institutionOptions },
            this.props,
            'Deal',
            data.id,
          )}
        </Form.Group>
        <UserRoleTable
          addEntity={addEntity}
          keyPath={LeadLenderRoleListKeyPath}
          organizationId={data.leadLenderId}
          removeEntity={removeEntity}
          roles={roles}
          setAlert={this.props.setAlert}
          userOptions={institutionUserMap.get(data.leadLenderId) || empty}
          userRoleList={data.leadLenderRoleList}
          whitelist={leadLenderRoles}
        />
        <Form.Divider />
        <Form.Header header="Servicing Agent" />
        <Form.Group>
          {Form.FieldRenderer(
            Form.Select,
            { ...this.fields.servicingAgent, options: institutionOptions },
            this.props,
            'Deal',
            data.id,
          )}
        </Form.Group>
        <UserRoleTable
          addEntity={addEntity}
          keyPath={ServicingAgentRoleListKeyPath}
          organizationId={data.servicingAgentId}
          removeEntity={removeEntity}
          roles={roles}
          setAlert={this.props.setAlert}
          userOptions={institutionUserMap.get(data.servicingAgentId) || empty}
          userRoleList={data.servicingAgentRoleList}
          whitelist={leadLenderRoles}
        />
        <Form.Divider />
        <Form.Header header="Borrower" />
        <Form.Group>
          {Form.FieldRenderer(
            Form.Select,
            {
              ...this.fields.borrower,
              options: institutionOptions,
            },
            this.props,
            'Deal',
            data.id,
          )}
        </Form.Group>
        <UserRoleTable
          addEntity={addEntity}
          keyPath={BorrowerRoleListKeyPath}
          organizationId={data.borrowerId}
          removeEntity={removeEntity}
          roles={roles}
          setAlert={this.props.setAlert}
          userOptions={borrowerUserMap.get(data.borrowerId) || empty}
          userRoleList={data.borrowerRoleList}
          whitelist={borrowerRoles}
        />
      </FormFrame>
    );
  }
}
