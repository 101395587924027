import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import {
  CalendarItemShallowFields,
  DealBasicFields,
  LoanTrancheBasicFields,
  CalendarItemCategoryShallowFields,
  JSONBlobDictionaryShallowFields,
} from './generated';

const CalendarItemFields: DocumentNode = gql`
  fragment CalendarItemFields on CalendarItemType {
    ...CalendarItemShallowFields
    data {
      ...JSONBlobDictionaryShallowFields
    }
    category {
      ...CalendarItemCategoryShallowFields
    }
    deal {
      ...DealBasicFields
    }
    loanTranche {
      ...LoanTrancheBasicFields
    }
  }
  ${CalendarItemShallowFields}
  ${CalendarItemCategoryShallowFields}
  ${DealBasicFields}
  ${LoanTrancheBasicFields}
  ${JSONBlobDictionaryShallowFields}
`;

export default CalendarItemFields;
