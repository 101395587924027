import { DealStage } from 'types';

/**
 * Returns a human friendly version of the deal stage
 */
export const getDealStage = (value: DealStage | string) => {
  const DEAL_STAGE_CONVERSION = {
    PRE_CLOSE: 'Pre-close',
    SERVICING: 'Servicing',
    INACTIVE: 'Inactive',
  };

  return DEAL_STAGE_CONVERSION[value] || '';
};
