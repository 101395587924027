import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import LenderManualSbaApproval from './LenderManualSbaApproval';
import { RouteParams, withRouteParams } from 'routing';
import { FormMediator, withLoadingIndicator } from 'components';
import { LoanFundingFormQuery } from 'lsgql/queries/LoanFundingQuery';
import LoanFundingFormRedux from 'lsredux/reducer/forms/loanFunding';
import triggerClosingWorkflowTransition from 'lsgql/mutations/TransitionClosingWorkflow';
import { isWholeNumber } from 'utils';

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  const bound = LoanFundingFormRedux.actions.generateActions(dispatch);
  const save = async (formQueryRefetch: any) => {
    const res = await bound.save(formQueryRefetch);
    if (res.success) {
      try {
        await triggerClosingWorkflowTransition({
          workflowId: ownProps.dealClosingWorkflowId,
          state: 'lender_review_promissory_note',
        });
        ownProps.setIsOpen(false);
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    }
    return res;
  };
  return {
    ...bound,
    save,
  };
};
const LenderManualSbaApprovalFormContainer = compose(
  withRouteParams([RouteParams.dealId]),
  LoanFundingFormQuery,
  withLoadingIndicator('loading'),
  withProps((ownProps: any) => {
    let editExisting = false;
    if (ownProps && ownProps.preExistingEntity) {
      // We have an object
      const data = ownProps.preExistingEntity;
      if (isWholeNumber(data.id)) {
        editExisting = true;
      }
    }
    return { editExisting };
  }),
  connect(LoanFundingFormRedux.mapStateToProps, mapDispatchToProps),
  FormMediator({
    formId: 'SbaManualApprovalForm',
    baseObjectTypeName: 'LoanFunding',
    blurEventForm: true,
    disableFrame: true,
  }),
)(LenderManualSbaApproval);

export default LenderManualSbaApprovalFormContainer;
