import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';

import RoundTwoSecondDrawLenderApplication from './RoundTwoSecondDrawLenderApplication';
import { RouteParams, withRouteParams } from 'routing';
import SbaPppRoundTwoSecondDrawLenderApplicationFormQuery from 'lsgql/queries/SbaPppRoundTwoSecondDrawLenderApplicationQuery';
import { DealPerspectivePermissions } from 'security/authorization/definitions/Deal';

import sbaPppRoundTwoSecondDrawLenderApplicationFormRedux from 'lsredux/reducer/forms/sbaPppRoundTwoSecondDrawLenderApplication';

import { FormMediator, withLoadingIndicator } from 'components';
import triggerClosingWorkflowTransition from 'lsgql/mutations/TransitionClosingWorkflow';
// import { isWholeNumber } from 'utils';

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  const bound = sbaPppRoundTwoSecondDrawLenderApplicationFormRedux.actions.generateActions(
    dispatch,
  );

  const save = async (formQueryRefetch: any) => {
    const res = await bound.save(formQueryRefetch);

    if (res.success) {
      try {
        if (
          ownProps?.preExistingEntity?.dealClosingWorkflow?.state !==
          'lender_submit_to_sba'
        ) {
          await triggerClosingWorkflowTransition({
            workflowId: ownProps?.preExistingEntity?.dealClosingWorkflow?.id,
            state: 'lender_review_lender_application',
          });
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    }

    return res;
  };

  const onChangeLenderApplication = (
    value: string | null | undefined,
    keyPath: string,
  ) => {
    bound.mutateProperty(value, [
      'sbaPppRoundTwoSecondDrawLenderApplication',
      keyPath,
    ]);
  };

  const onChangeBorrowerApplication = (
    value: string | null | undefined,
    keyPath: string,
  ) => {
    bound.mutateProperty(value, ['sbaPppLoanApplication', keyPath]);
  };

  return {
    ...bound,
    save,
    onChangeBorrowerApplication,
    onChangeLenderApplication,
  };
};

export default compose(
  withRouteParams([RouteParams.dealId]),
  withProps((ownProps: any) => {
    return {
      id: ownProps.dealId,
    };
  }),
  SbaPppRoundTwoSecondDrawLenderApplicationFormQuery,
  withLoadingIndicator('loading'),
  connect(
    sbaPppRoundTwoSecondDrawLenderApplicationFormRedux.mapStateToProps,
    mapDispatchToProps,
  ),
  FormMediator({
    formId: 'roundTwoSecondDrawLenderApplicationForm',
    blurEventForm: true,
    baseObjectTypeName: 'Deal',
    simpleFooter: true,
    editSecurity: DealPerspectivePermissions.view_closing,
  }),
)(RoundTwoSecondDrawLenderApplication);
