import * as React from 'react';
import uuid from 'uuid/v4';
import {
  Form,
  IconButton,
  ConfirmModal,
  WrappedMediatorFormProps,
} from 'components';
import { InstitutionType, InstitutionBankAccountType } from 'types';
import { MountGuard, InstitutionPerspectivePermissions } from 'security';

const DealInstitution = 'DealInstitution';

interface InstitutionEntityFormProps
  extends WrappedMediatorFormProps<InstitutionType> {
  newEntityId: string;
}

type State = {
  isModalOpen: boolean;
};

const initialState: State = {
  isModalOpen: false,
};

function transformBankAccountSet(
  bankAccounts: Array<InstitutionBankAccountType> | null | undefined,
) {
  if (bankAccounts) {
    return bankAccounts.map(bankAccount => ({
      text:
        bankAccount.accountNickname || bankAccount.hiddenAccountNumber || '',
      value: bankAccount.id,
    }));
  }
  return [];
}

class InstitutionEntityForm extends React.Component<
  InstitutionEntityFormProps,
  State
> {
  fields: { [key: string]: any };

  state = initialState;

  constructor(props: InstitutionEntityFormProps) {
    super(props);

    this.fields = {
      name: {
        id: 'name',
        propertyName: 'name',
        fieldName: 'Entity Name',
        required: true,
        placeholder: 'Entity Name',
        onChange: this.handleMutateEntity,
        width: 'seven',
      },
    };
  }

  handleCancelDelete = () => this.setState({ isModalOpen: false });

  handleConfirmDelete = () => this.setState({ isModalOpen: true });

  handleDelete = () => {
    this.props.removeEntity('subsidiaries', this.props.data);
  };

  handleMutateEntity = (
    value: string | boolean | null | undefined,
    fieldId: string,
  ) => {
    const { data } = this.props;
    const field = fieldId.split('_')[0];

    if (data) {
      // edit
      this.props.replaceEntity('subsidiaries', {
        ...data,
        [field]: value,
      });
    } else {
      // add
      this.props.addEntity('subsidiaries', {
        ...{
          id: this.props.newEntityId,
          __typename: 'DealInstitutionType',
        },
        [field]: value,
      });
    }
  };

  render() {
    const { data, cleanData } = this.props;
    const entity = data || {
      // Default institution entity
      id: uuid(),
      __typename: 'DealInstitutionType',
    };

    const entityProps = { ...this.props, data: entity };
    const id = entity?.id ?? 'temp';

    const bankAccountOptions = transformBankAccountSet(
      cleanData.institutionbankaccountSet,
    );

    return (
      <>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Input,
            this.fields.name,
            entityProps,
            DealInstitution,
            id,
          )}
          <Form.Select
            allowEmpty={false}
            // TODO: Think about better way to represent bank account relationships
            disabled
            fieldName="Bank Account"
            id="associatedBankAccount"
            onChange={this.handleMutateEntity}
            options={bankAccountOptions}
            propertyName="bankAccountId"
            // value={data.bankAccountId}
            width="five"
          />
          {Object.values(entity).length > 3 ? (
            <MountGuard
              permission={
                InstitutionPerspectivePermissions.administer_institution
              }
            >
              <IconButton.Delete
                alt="Remove Bank Account"
                className="MyInstitution__EntityFieldsDeleteButton"
                onClick={this.handleConfirmDelete}
              />
            </MountGuard>
          ) : null}
          <ConfirmModal
            header="Warning"
            isOpen={this.state.isModalOpen}
            message="Are you sure you want to permanently remove this entity?"
            onConfirm={this.handleDelete}
            onReject={this.handleCancelDelete}
          />
        </Form.Group>
      </>
    );
  }
}

export default InstitutionEntityForm;
