import * as React from 'react';
import {
  IconButton as LSIconButton,
  Button as LSButton,
} from '@loanstreet-usa/design-system';
import RouteTo from '../routeTo/RouteTo';
import { haltBubble } from '../../utils';

function makeButtonProps(props: any) {
  const buttonProps: any = { ...props, as: null };
  if (props.isDownloadLink) return buttonProps;
  if (props.to) {
    buttonProps.as = RouteTo;
  } else {
    buttonProps.onClick = (e: React.SyntheticEvent): void => {
      haltBubble(e);
      props.onClick();
    };
  }
  return buttonProps;
}

function IconButton(props: any) {
  return <LSIconButton {...makeButtonProps(props)} />;
}

function DeleteIconButton(props: any) {
  return <LSIconButton.Delete {...makeButtonProps(props)} />;
}

IconButton.Delete = DeleteIconButton;

function Button(props: any) {
  return <LSButton {...makeButtonProps(props)} />;
}

function TextButton(props: any) {
  return <LSButton.Text {...makeButtonProps(props)} />;
}

function PrimaryButton(props: any) {
  return <LSButton.Primary {...makeButtonProps(props)} />;
}

function SecondaryButton(props: any) {
  return <LSButton.Secondary {...makeButtonProps(props)} />;
}

Button.Primary = PrimaryButton;
Button.Secondary = SecondaryButton;
Button.Text = TextButton;

export { Button, IconButton };
