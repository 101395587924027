import * as React from 'react';
import { PopupMenu, IconButton, FontAwesomeIconTypes } from 'components';

type Props = {
  children: React.ReactNode;
  shouldRenderMenu: boolean;
};

type State = {
  isMenuOpen: boolean;
};

class DataroomRowMenu extends React.Component<Props, State> {
  static icon: FontAwesomeIconTypes = ['far', 'ellipsis-v'];

  state = { isMenuOpen: false };

  handleOpenMenu = () => this.setState({ isMenuOpen: true });

  handleCloseMenu = () => this.setState({ isMenuOpen: false });

  static renderItem(name: string, onClick?: () => void, href?: string) {
    if (href) {
      return (
        <a className="item" href={href}>
          {name}
        </a>
      );
    }
    return (
      <a className="item" onClick={onClick}>
        {name}
      </a>
    );
  }

  renderMenuTrigger = () => (
    <IconButton
      alt="open menu"
      className="DataroomRowMenu--Icon DataroomRowMenu--Icon__Action"
      color="tin"
      icon={DataroomRowMenu.icon}
    />
  );

  renderDisabledMenuTrigger = () => <div className="DataroomRowMenu--Icon" />;

  render() {
    const { shouldRenderMenu, children } = this.props;
    const { isMenuOpen } = this.state;

    const disabled = !(shouldRenderMenu || isMenuOpen);
    return (
      <PopupMenu
        on="click"
        onClose={this.handleCloseMenu}
        onOpen={this.handleOpenMenu}
        position="bottom right"
        trigger={
          disabled ? this.renderDisabledMenuTrigger : this.renderMenuTrigger
        }
      >
        {children}
      </PopupMenu>
    );
  }
}

export default DataroomRowMenu;
