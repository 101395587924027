import { mutationBuilder } from './builders';
import {
  CreateSbaPppInstitutionMutationDefinition,
  SetSbaPppInstitutionMutationDefinition,
} from './definitions';
import { SbaPppInstitutionInput, SbaPppInstitutionType } from 'types';

const PublicSbaPppInstitutionMutationMethods = {
  createSbaPppInstitution: mutationBuilder<
    SbaPppInstitutionInput,
    SbaPppInstitutionType
  >(CreateSbaPppInstitutionMutationDefinition, {
    build: mutate => sbaPppInstitutionData =>
      mutate({ variables: { sbaPppInstitutionData } }),
  }),
  setSbaPppInstitution: mutationBuilder<
    SbaPppInstitutionInput,
    SbaPppInstitutionType
  >(SetSbaPppInstitutionMutationDefinition, {
    build: mutate => sbaPppInstitutionData =>
      mutate({ variables: { sbaPppInstitutionData } }),
  }),
};

export default PublicSbaPppInstitutionMutationMethods;
