import treeWalker from './utils/treeWalker';
import validationMapBuilder from './utils/validationMapBuilders';
import {
  BaseType,
  ObjectBaseTypeNames,
  ID,
  ValidationType,
  ValidationMap,
} from 'types';
import { getTypeName, debug } from 'utils';

function debugLog(msg: string): void {
  debug(
    `%c▓ Validate - ${msg}`,
    'font-family:monospace; font-weight: bold; color: #db2828',
  );
}

type ValidatorMap = Partial<
  {
    [type in ObjectBaseTypeNames]: (arg0: ID) => Promise<ValidationType>;
  }
>;

export type InputScrubber = (arg0: BaseType) => BaseType;

export type InputScrubberMap = Partial<
  {
    [type in ObjectBaseTypeNames]: InputScrubber;
  }
>;

type VE = { id: string; type: ObjectBaseTypeNames };

async function Wrapper(
  e: any,
  validator: (arg0: any) => Promise<ValidationType>,
  results: ValidationMap,
  inputScrubber: InputScrubber | null | undefined,
): Promise<void> {
  const res: ValidationType = await validator(
    inputScrubber ? inputScrubber(e) : e,
  );

  if (
    (res.errors && res.errorFields && res.errorFields.length > 0) ||
    (res.warnings && res.warningFields && res.warningFields.length > 0)
  ) {
    validationMapBuilder(e.type, e.id, res, results);
  }
}

/**
 * Recur over the object tree and execute an entity validator for every
 * object for which a validator exists
 * @param {*} data
 * @param {*} validators
 * @param {*} res
 */
export default async function validationDispatch<T extends BaseType>(
  data: T | null | undefined,
  validators: ValidatorMap,
  res: ValidationMap,
  inputScrubbers?: InputScrubberMap,
): Promise<void> {
  if (!data) {
    // eslint-disable-next-line
    console.warn('Validation was requested but the data object was null');
    return;
  }
  debugLog(data.__typename);
  const pairs: Array<VE> = [];

  treeWalker(
    data,
    (e: BaseType) => {
      pairs.push({ ...e, type: getTypeName(e) });
    },
    '',
  );

  await Promise.all(
    pairs
      .filter(p => validators[p.type])
      .map(p =>
        Wrapper(
          p,
          validators[p.type],
          res,
          inputScrubbers && inputScrubbers[p.type],
        ),
      ),
  );
}
