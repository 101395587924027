import memoize from 'lodash/memoize';
import { Money } from 'types';

const regex = /\B(?=(\d{3})+(?!\d))/g;
const period = '.';
const comma = ',';
const zero = '0';

/**
 *  Format Money vlaues for display
 * @param {*} x
 */
function formatMoney(value?: Money | null, decimalPlaces = 2): string {
  let val;
  if (value) {
    val = value;
    if (val.indexOf(period) === -1) {
      val = val.concat('.00');
    }
  } else {
    val = zero;
  }
  const position =
    val.indexOf(period) + (decimalPlaces !== 0 ? decimalPlaces + 1 : 0);

  const x =
    position <= 0 ? val : val.substring(0, Math.min(val.length, position));
  const parts = x.split(period);
  parts[0] = parts[0].replace(regex, comma);

  const final = parts.join(period);
  const indexOfPeriod = final.indexOf(period);
  if (indexOfPeriod > -1) {
    return final.padEnd(indexOfPeriod + 1 + decimalPlaces, zero);
  }
  return final;
}

export default memoize(
  formatMoney,
  (value: Money | null | undefined, decimalPlaces?: number) =>
    `${value || '-'}_${decimalPlaces}`,
);
