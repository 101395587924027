import * as React from 'react';
import { Location } from 'history';
import { TransitionGroup, CSSTransition, ToastAlert } from 'components';
import './AlertSystem.scss';

import { AlertEntityType } from 'types';

const AlertTime = 8000;

type Props = {
  alerts: Array<AlertEntityType>;
  clearAlert: (id: string) => void;
  location: Location;
};

class AlertSystem extends React.PureComponent<Props> {
  activeAlerts: {
    [key: string]: any;
  } = {};

  componentDidUpdate(prevProps: Props) {
    const existingAlertIds = prevProps.alerts.map(alert => alert.id);
    const newAlerts = this.props.alerts.filter(
      alert => !existingAlertIds.includes(alert.id),
    );
    this.sequentiallyDismissAlerts(newAlerts);
  }

  handleMouseEnter = () => {
    Object.keys(this.activeAlerts).forEach(alertId => {
      clearTimeout(this.activeAlerts[alertId]);
    });
  };

  handleMouseLeave = () => {
    this.sequentiallyDismissAlerts();
  };

  sequentiallyDismissAlerts = (
    alerts: Array<AlertEntityType> = this.props.alerts,
  ) => {
    let baseTime = AlertTime;

    // debug alerts require manual dismiss
    alerts.forEach(x => {
      this.dismissWithTimeout(x, x.type !== 'debug' ? baseTime : 15000);
      baseTime += 1000;
    });
  };

  dismissWithTimeout = (alert: AlertEntityType, timeout: number) => {
    this.activeAlerts = {
      ...this.activeAlerts,
      [alert.id]: setTimeout(() => {
        this.props.clearAlert(alert.id);
        const activeAlertsCopy = { ...this.activeAlerts };
        delete activeAlertsCopy[alert.id];
        this.activeAlerts = activeAlertsCopy;
      }, timeout),
    };
  };

  clearAllAlerts() {
    Object.keys(this.activeAlerts).forEach(alertId =>
      this.props.clearAlert(alertId),
    );
    this.activeAlerts = {};
  }

  render() {
    const { alerts, clearAlert } = this.props;
    return (
      <TransitionGroup component="div" id="AlertSystem">
        {alerts.map(alert => (
          <CSSTransition classNames="Alert" key={alert.id} timeout={150}>
            <ToastAlert
              alert={alert}
              key={alert.id}
              onDismiss={clearAlert}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    );
  }
}

export default AlertSystem;
