import * as React from 'react';
import { noop } from 'lodash';
import uuid from 'uuid/v4';
import {
  getRoundedString,
  createAmortizationInterestPeriodsFromAdjustableRatePeriods,
} from '../utils';
import { PercentageInput, MoneyInput } from 'components';
import {
  LoanTrancheAdjustableRatePeriodType,
  LoanTrancheAmortizationInterestPeriodType,
} from 'types';
import { haltBubble, invariant } from 'utils';

type Props = {
  addAdjustableRatePeriodSet: (
    periods: Array<LoanTrancheAdjustableRatePeriodType>,
  ) => void;
  addAmortizationPeriodSet: (
    periods: Array<LoanTrancheAmortizationInterestPeriodType>,
  ) => void;
  adjustableRatePeriodSet: ReadonlyArray<LoanTrancheAdjustableRatePeriodType>;
  deletePeriods: () => void;
  disabled: boolean;
  setAdjustableRatePeriodOverflowAlert: () => void;
};

const columns = ['Period', 'Adjustment Rate', 'Total', 'Total Amount'];

class AdjustableRatePeriodTable extends React.Component<Props> {
  getChangeHandler = (index: number) => (e: React.SyntheticEvent<any>) => {
    const { deletePeriods } = this.props;
    haltBubble(e);
    if (
      e.currentTarget.value !== null &&
      e.currentTarget.value !== undefined &&
      !Number.isNaN(Number(e.currentTarget.value))
    ) {
      const {
        adjustableRatePeriodSet,
        addAmortizationPeriodSet,
        addAdjustableRatePeriodSet,
      } = this.props;

      const newAdjustmentRate = Number(e.currentTarget.value);
      const currentEntity = adjustableRatePeriodSet[index];
      let newTotalRate =
        newAdjustmentRate -
        Number(currentEntity.adjustmentRate) +
        Number(currentEntity.totalRate);

      invariant(!Number.isNaN(newTotalRate), 'New Total Rate is not a number!');

      const newAdjustmentPeriod = {
        period: currentEntity.period,
        adjustmentRate: getRoundedString(newAdjustmentRate),
        totalRate: getRoundedString(newTotalRate),
        id: uuid(),
        __typename: 'LoanTrancheAdjustableRatePeriodType',
      };

      const previous = adjustableRatePeriodSet.slice(0, index).map(period => ({
        period: period.period,
        adjustmentRate: period.adjustmentRate,
        __typename: 'LoanTrancheAdjustableRatePeriodType',
        totalRate: period.totalRate,
        id: uuid(),
      }));

      const after = adjustableRatePeriodSet.slice(index + 1).map(period => {
        newTotalRate += Number(period.adjustmentRate);
        invariant(
          !Number.isNaN(newTotalRate),
          'New Total Rate is not a number!',
        );
        return {
          period: period.period,
          adjustmentRate: period.adjustmentRate,
          __typename: 'LoanTrancheAdjustableRatePeriodType',
          totalRate: getRoundedString(newTotalRate),
          id: uuid(),
        };
      });
      const newTotalRateString = newTotalRate.toString();
      const indexOfPeriod = newTotalRateString.indexOf('.');
      if (indexOfPeriod >= 0 && indexOfPeriod < 2) {
        const periods: LoanTrancheAdjustableRatePeriodType[] = [
          ...previous,
          newAdjustmentPeriod,
          ...after,
        ] as any;
        deletePeriods();
        addAdjustableRatePeriodSet(periods);

        const amortizationPeriods = createAmortizationInterestPeriodsFromAdjustableRatePeriods(
          periods,
        );
        addAmortizationPeriodSet(amortizationPeriods);
      } else {
        // totalRate Overflowed
        this.props.setAdjustableRatePeriodOverflowAlert();
      }
    }
  };

  getPeriodDisplay = (index: number, period: string) => {
    const { adjustableRatePeriodSet } = this.props;
    let fromPeriod = '1';
    if (adjustableRatePeriodSet[index - 1]) {
      const prevPeriod = adjustableRatePeriodSet[index - 1];

      if (!Number.isNaN(Number(prevPeriod.period))) {
        fromPeriod = String(Number(prevPeriod.period) + 1);
      }
    }
    if (fromPeriod === period) {
      return period;
    }
    return `${fromPeriod} - ${period}`;
  };

  render() {
    const { adjustableRatePeriodSet, disabled } = this.props;
    return (
      <div className="adjustableRatePeriodTable">
        <div className="adjustableRatePeriodTable__Row">
          {columns.map(columnName => (
            <div
              className={`adjustableRatePeriodTable__Header adjustableRatePeriodTable__${
                columnName === 'Total Amount' ? 'Total' : ''
              }Column`}
              key={columnName}
            >
              {columnName}
            </div>
          ))}
        </div>
        {adjustableRatePeriodSet.map((period, periodIndex) => (
          <div className="adjustableRatePeriodTable__Row" key={period.id}>
            <div className="adjustableRatePeriodTable__Column">
              {this.getPeriodDisplay(periodIndex, period.period || '')}
            </div>
            <div className=" adjustableRatePeriodTable__Column">
              {periodIndex !== 0 && (
                <PercentageInput
                  className="adjustableRatePeriodTable__ColumnContent"
                  disabled={disabled}
                  id={`adjustmentRate${periodIndex}`}
                  name="adjustmentRate"
                  onChange={this.getChangeHandler(periodIndex)}
                  value={period.adjustmentRate}
                />
              )}
            </div>
            <div className=" adjustableRatePeriodTable__Column">
              <PercentageInput
                className="adjustableRatePeriodTable__ColumnContent"
                disabled
                id={`totalRate__${periodIndex}`}
                name="totalRate"
                onChange={noop}
                value={period.totalRate}
              />
            </div>
            <div className="adjustableRatePeriodTable__Column">
              <MoneyInput
                className="adjustableRatePeriodTable__ColumnContent"
                disabled
                id={`totalAmount__${periodIndex}`}
                name="totalAmount"
                onChange={noop}
                value="0"
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default AdjustableRatePeriodTable;
