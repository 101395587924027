import { MutationMethod } from '../types';
import { mutationBuilder } from './builders';
import {
  SetApplicableMarginMutationDefinition,
  CreateApplicableMarginMutationDefinition,
  DeleteApplicableMarginMutationDefinition,
} from './definitions';
import { ApplicableMarginInput, ApplicableMarginType, ID } from 'types';

const createApplicableMargin: MutationMethod<
  ApplicableMarginInput,
  ApplicableMarginType
> = mutationBuilder<ApplicableMarginInput, ApplicableMarginType>(
  CreateApplicableMarginMutationDefinition,
  {
    build: mutate => applicableMarginData =>
      mutate({
        variables: {
          applicableMarginData,
        },
      }),
  },
);

const setApplicableMargin: MutationMethod<
  ApplicableMarginInput,
  ApplicableMarginType
> = mutationBuilder<ApplicableMarginInput, ApplicableMarginType>(
  SetApplicableMarginMutationDefinition,
  {
    build: mutate => applicableMarginData =>
      mutate({
        variables: {
          applicableMarginData,
        },
      }),
  },
);

const deleteApplicableMargin: MutationMethod<ID, void> = mutationBuilder<
  ID,
  void
>(DeleteApplicableMarginMutationDefinition, {
  build: mutate => (id: ID) =>
    mutate({
      variables: {
        id,
      },
    }),
});

const ApplicableMarginMutationMethods = {
  createApplicableMargin,
  setApplicableMargin,
  deleteApplicableMargin,
};

export default ApplicableMarginMutationMethods;
