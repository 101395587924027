import { gql } from '@apollo/client';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { PublicInvitationType } from 'types';

/* eslint-disable */
const PublicInvitationQueryDefintion = gql`
  query viewInvitation($key: ID) {
    viewInvitation(key: $key) {
      accepted
      cancelled
      dealName
      email
      expiration
      firstName
      institutionName
      isExpired
      isSharable
      isUsable
      isExistingUser
      lastName
      message
    }
  }
`;

/* eslint-enable */

type Props = { invitationKey: string | null | undefined };
const PublicInvitationQuery = standardQueryBuilder(
  PublicInvitationQueryDefintion,
  {
    addFetchMethod: true,
    typename: 'PublicInvitationType',
    skip: ({ invitationKey }: Props) => !invitationKey,
    variables: ({ invitationKey }: Props) => ({ key: invitationKey }),
    results: (data: {
      viewInvitation: PublicInvitationType | null | undefined;
    }) => {
      const { viewInvitation } = data;
      return { invitation: viewInvitation };
    },
  },
);

export default PublicInvitationQuery;
