import * as React from 'react';
import { AccessDeniedGuard, FolderPerspectivePermissions } from 'security';
import { DisabledChildProps } from 'security/authorization/Secured';

function RowSecurityHoC(Secured: any) {
  return class SecuredRowComponent extends React.Component<any> {
    renderSecured = (security: DisabledChildProps) => (
      <Secured {...this.props} accessDenied={security.accessDenied} />
    );

    render() {
      return (
        <AccessDeniedGuard
          permission={FolderPerspectivePermissions.change_folder}
        >
          {this.renderSecured}
        </AccessDeniedGuard>
      );
    }
  };
}

export default RowSecurityHoC;
