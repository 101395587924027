import * as React from 'react';
import { DocumentNode } from './types';
import { getAuthenticatedClient } from './authenticatedClient';

function buildQuery(query: DocumentNode, varName = 'id') {
  function fetch(data: any) {
    const vars: {
      [key: string]: any;
    } = {};
    vars[varName] = data;
    return getAuthenticatedClient().query({
      query,
      variables: vars,
      fetchPolicy: 'network-only',
    });
  }

  return fetch;
}

/**
 * Inject a GraphQL query into a React Component as a fetch(id) prop.
 * This query will specify 'network-only', ensuring that we are pulling
 * latest data from the backend
 *
 * @param {*} query
 */
function queryWrapper(query: DocumentNode, methodName: string, varName = 'id') {
  const fetchMethod = buildQuery(query, varName);
  const fetchProps = {};
  fetchProps[methodName] = fetchMethod;

  function wrapWithQuery(WrappedComponent: React.ComponentType<any>) {
    // define and return a class
    return class QueryWrapped extends React.Component<any> {
      render() {
        return <WrappedComponent {...this.props} {...fetchProps} />;
      }
    };
  }

  return wrapWithQuery;
}

export default queryWrapper;
