import React from 'react';
import { Header, Text, TextLink, Icon } from 'components';

import './RoundTwoHelpBox.scss';

export function RoundTwoFirstDrawHelpBox() {
  const SbaFormLink =
    'https://www.sba.gov/document/sba-form-2483-paycheck-protection-program-borrower-application-form';
  const SbaSchedCLink =
    'https://www.sba.gov/document/sba-form-2483-c-ppp-first-draw-borrower-application-form-schedule-c-filers-using-gross-income';

  return (
    <div className="RoundTwoHelpBox">
      <div className="Contents">
        <Header as="h4" className="bold">
          Additional Resources
        </Header>
        <div className="Body">
          <Text>SBA first draw PPP application and instructions:</Text>
          <TextLink.External
            label="SBA Form 2483"
            target="newTab"
            to={SbaFormLink}
          />

          <Text>SBA Schedule C filer application and instructions:</Text>
          <TextLink.External
            label="SBA Form 2483-C "
            target="newTab"
            to={SbaSchedCLink}
          />

          <Text>Step-by-step instructions for this process:</Text>
          <div className="RoundTwoHelpBox__SpecialIconButton">
            <Icon icon={['fal', 'file-pdf']} />{' '}
            <TextLink
              label="Apply as a first-time borrower"
              target="newTab"
              to="https://www.loan-street.com/hubfs/Filing%20a%20PPP%20application%20as%20a%20Borrower.pdf"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function RoundTwoSecondDrawHelpBox() {
  const SbaFormLink =
    'https://www.sba.gov/document/sba-form-2483-sd-ppp-second-draw-borrower-application-form';
  const SbaSchedCLink =
    'https://www.sba.gov/document/sba-form-2483-sd-c-ppp-second-draw-borrower-application-form-schedule-c-filers-using-gross-income';

  return (
    <div className="RoundTwoHelpBox">
      <div className="Contents">
        <Header as="h4" className="bold">
          Additional Resources
        </Header>
        <div className="Body">
          <Text>SBA second draw PPP application and instructions:</Text>
          <TextLink.External
            label="SBA Form 2483-SD"
            target="newTab"
            to={SbaFormLink}
          />

          <Text>SBA Schedule C filer application and instructions:</Text>
          <TextLink.External
            label="SBA Form 2483-SD-C "
            target="newTab"
            to={SbaSchedCLink}
          />

          <Text>Step-by-step instructions for this process:</Text>
          <div className="RoundTwoHelpBox__SpecialIconButton">
            <Icon icon={['fal', 'file-pdf']} />{' '}
            <TextLink
              label="Apply as a second-time borrower"
              target="newTab"
              to="https://www.loan-street.com/hubfs/SBA%20PPP%20Materials/Applying%20for%20a%20PPP%20loan%20as%20a%20Second-Time%20Borrower.pdf"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
