import uuid from 'uuid/v4';
import { PrivateSbaPppApplicantOwnershipMutationMethods } from '../../../graphql';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import { DealType } from 'types';

const initialDeal: DealType = {
  id: uuid(),
  __typename: 'DealType',
};

const lifecycle = {
  mutations: {
    ...PrivateSbaPppApplicantOwnershipMutationMethods,
  },
  validators: {},
  propertyWhitelist: {
    SbaPppApplicantOwnership: new Set(['race']),
  },
};

export default createForm<DealType, DealType>(
  Directory.SbaPppForgivenessLoanInformationDemographicsKey,
  initialDeal,
  lifecycle,
);
