import { BaseType } from 'types';

/* eslint-disable valid-typeof */
const objectType = 'object';

function isBaseType(e: any): boolean {
  return e && typeof e === objectType; // && e.id && e.__typename) === true;
}

/**
 * Recursively walk the object tree, invoking the callback
 * for each object
 * @param {*} current
 * @param {*} callback
 */
export default function treeWalker<T extends BaseType>(
  current: T,
  callback: (e: T, key: string) => void,
  levelKey = '',
) {
  // We only care about object types
  if (!isBaseType(current)) return;

  // invoke callback for this level
  callback(current, levelKey);

  Object.keys(current).forEach(key => {
    if (current[key]) {
      if (Array.isArray(current[key])) {
        const children: Array<any> = current[key];
        children.forEach(child => treeWalker(child, callback, key));
        // handle array children
      } else if (current[key] !== null && typeof current[key] === objectType) {
        const child: any = current[key];
        treeWalker(child, callback, key);
        // handle child object
      }
    }
  });
}
