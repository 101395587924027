import * as React from 'react';
import { StepProps } from './StepProps';

export type StepMap = {
  [slug: string]: (StepProps) => React.ReactNode;
};

export function createStepRenderer(stepMap: StepMap) {
  function StepRender(props: StepProps) {
    if (props.step.slug in stepMap) {
      return <>{stepMap[props.step.slug](props)}</>;
    }
    // eslint-disable-next-line
    console.warn(`Unable to render ${props.step.slug}`);
    return null;
  }

  return StepRender;
}
