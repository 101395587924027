import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import {
  LoanTrancheShallowFields,
  LoanTrancheCollateralShallowFields,
  LoanTrancheFloatingRateDataShallowFields,
  LoanTrancheGuarantorShallowFields,
  LoanTrancheMultiDrawDataShallowFields,
  LoanTrancheRevolverSwinglineLOCDataShallowFields,
  CovenantShallowFields,
  DealShallowFields,
  InstitutionBankAccountShallowFields,
  CreditRatingShallowFields,
  InstitutionShallowFields,
  EscrowAccountShallowFields,
  ReserveShallowFields,
  BenchmarkOptionBasicFields,
  BenchmarkShallowFields,
  ApplicableMarginShallowFields,
  AlternateBaseRateBenchmarkBasicFields,
  DealAssigneeShallowFields,
  SbaPppForgivenessTrackerShallowFields,
  SbaPppLoanApplicationShallowFields,
} from './generated';

// add borrower, leadLender, creditagreementSet

const DealFields: DocumentNode = gql`
  fragment DealFields on DealType {
    ...DealShallowFields
    sbappploanapplication {
      ...SbaPppLoanApplicationShallowFields
    }
    borrower {
      ...InstitutionShallowFields
      institutionbankaccountSet {
        ...InstitutionBankAccountShallowFields
      }
    }
    loantrancheSet {
      ...LoanTrancheShallowFields
      loantranchecollateralSet {
        ...LoanTrancheCollateralShallowFields
      }
      covenantSet {
        ...CovenantShallowFields
      }
      creditratingSet {
        ...CreditRatingShallowFields
      }
      escrowaccountSet {
        ...EscrowAccountShallowFields
      }
      loantranchefloatingratedata {
        ...LoanTrancheFloatingRateDataShallowFields
      }
      loantrancheguarantorSet {
        ...LoanTrancheGuarantorShallowFields
      }
      loantranchemultidrawdata {
        ...LoanTrancheMultiDrawDataShallowFields
      }
      loantrancherevolverswinglinelocdata {
        ...LoanTrancheRevolverSwinglineLOCDataShallowFields
      }
      reserveSet {
        ...ReserveShallowFields
      }
      presentApplicableMargin {
        ...ApplicableMarginShallowFields
      }
      applicablemarginSet {
        ...ApplicableMarginShallowFields
      }
      benchmarkoptionSet {
        ...BenchmarkOptionBasicFields
        benchmark {
          ...BenchmarkShallowFields
        }
      }
      alternatebaseratebenchmarkSet {
        ...AlternateBaseRateBenchmarkBasicFields
      }
    }
    topLevelFolders {
      id
    }
    creditratingSet {
      ...CreditRatingShallowFields
    }
    dealassignee {
      ...DealAssigneeShallowFields
    }
    sbapppforgivenesstrackerSet {
      ...SbaPppForgivenessTrackerShallowFields
    }
  }
  ${InstitutionShallowFields}
  ${CovenantShallowFields}
  ${DealShallowFields}
  ${CreditRatingShallowFields}
  ${LoanTrancheCollateralShallowFields}
  ${LoanTrancheFloatingRateDataShallowFields}
  ${LoanTrancheMultiDrawDataShallowFields}
  ${LoanTrancheRevolverSwinglineLOCDataShallowFields}
  ${LoanTrancheGuarantorShallowFields}
  ${LoanTrancheShallowFields}
  ${EscrowAccountShallowFields}
  ${ReserveShallowFields}
  ${ApplicableMarginShallowFields}
  ${BenchmarkOptionBasicFields}
  ${InstitutionBankAccountShallowFields}
  ${BenchmarkShallowFields}
  ${LoanTrancheRevolverSwinglineLOCDataShallowFields}
  ${LoanTrancheMultiDrawDataShallowFields}
  ${AlternateBaseRateBenchmarkBasicFields}
  ${DealAssigneeShallowFields}
  ${SbaPppForgivenessTrackerShallowFields}
  ${SbaPppLoanApplicationShallowFields}
`;

export default DealFields;
