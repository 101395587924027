import {
  ADMINISTER_INSTITUTION,
  DELETE_INSTITUTION,
  CHANGE_INSTITUTION,
  VIEW_INSTITUTION,
} from '../../constants';
import { PermissionDefinitions } from './types';

const perspective = 'institution';

const InstitutionPerspectivePermissions: Partial<PermissionDefinitions> = {
  administer_institution: {
    flag: ADMINISTER_INSTITUTION,
    perspective,
  },
  change_institution: {
    flag: CHANGE_INSTITUTION,
    perspective,
  },
  view_institution: {
    flag: VIEW_INSTITUTION,
    perspective,
  },
  delete_institution: {
    flag: DELETE_INSTITUTION,
    perspective,
  },
};

export default InstitutionPerspectivePermissions;
