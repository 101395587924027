import React from 'react';
import { CalculatedValuesType } from '../types';
import { PAYROLL_REQUIREMENT } from '../utils';
import { parseDecimal, formatMoney } from 'utils';
import { SbaPppForgivenessCalculationType } from 'types';

interface Props {
  calculatedValues: CalculatedValuesType;
  sbapppforgivenesscalculation: SbaPppForgivenessCalculationType;
}
const LessThanFullForgivenessCauses = (props: Props) => {
  const { calculatedValues, sbapppforgivenesscalculation } = props;
  const { useEzForm } = sbapppforgivenesscalculation;
  const {
    pppLoanAmount,
    forgivenessAmount,
    fteReductionQuotientFteReduction,
    payrollCostRequirement,
    modifiedTotal,
  } = calculatedValues;
  if (
    sbapppforgivenesscalculation &&
    parseDecimal(forgivenessAmount) < parseDecimal(pppLoanAmount)
  ) {
    const reasons: string[] = [];
    if (
      !useEzForm &&
      fteReductionQuotientFteReduction &&
      parseDecimal(fteReductionQuotientFteReduction, { numDecimals: 2 }) < 1
    ) {
      const {
        averageFteFteReduction,
        totalAverageFteFteReduction,
      } = sbapppforgivenesscalculation;
      const reducedEmployees = parseDecimal(
        parseDecimal(averageFteFteReduction) -
          parseDecimal(totalAverageFteFteReduction),
        { numDecimals: 1 },
      );
      reasons.push(
        `FTE Reduction Quotient: You reduced your employees by ${reducedEmployees} FTE in the Covered Period compared to the Reference period`,
      );
    }
    if (parseDecimal(payrollCostRequirement) < parseDecimal(pppLoanAmount)) {
      const payrollAdditionalSpend = formatMoney(
        String(
          (parseDecimal(pppLoanAmount) - parseDecimal(payrollCostRequirement)) *
            PAYROLL_REQUIREMENT,
        ),
        0,
      );
      reasons.push(
        `${
          useEzForm ? '' : 'Payroll Cost: '
        }You needed to spend $${payrollAdditionalSpend} more in the covered period on payroll to meet full forgiveness`,
      );
    }
    if (parseDecimal(modifiedTotal) < parseDecimal(pppLoanAmount)) {
      const additionalSpend = formatMoney(
        String(parseDecimal(pppLoanAmount) - parseDecimal(modifiedTotal)),
        0,
      );
      reasons.push(
        `${
          useEzForm ? '' : 'Modified Total: '
        }You needed to spend $${additionalSpend} more in the covered period ${
          !useEzForm ? 'or have less wage reduction ' : ''
        }to meet full forgiveness`,
      );
    }
    if (reasons.length > 0) {
      return (
        <div className="LessThanFullForgivenessCauses">
          {useEzForm} Factors that may have caused less than 100% forgiveness
          include:
          <ul className="ReasonList">
            {reasons.map(reason => (
              <li key={reason}>{reason}</li>
            ))}
          </ul>
        </div>
      );
    }
  }
  return null;
};

export default LessThanFullForgivenessCauses;
