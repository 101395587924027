import uuid from 'uuid/v4';
import { GeneralNoticeMutationMethods } from '../../../graphql';
import { createForm } from '../../genericForms';
import Directory from '../../directory';
import { GeneralNoticeType } from 'types';

type GeneralNoticeWithIsLeaf = GeneralNoticeType & {
  isLeaf: boolean;
};

const initialGeneralNotice: GeneralNoticeWithIsLeaf = {
  id: uuid(),
  isLeaf: true,
  __typename: 'GeneralNoticeType',
};

const lifecycleMethods = {
  mutations: { ...GeneralNoticeMutationMethods },
  validators: {},
};

const generalNoticeRedux = createForm<
  GeneralNoticeType,
  GeneralNoticeWithIsLeaf
>(Directory.GeneralNoticeKey, initialGeneralNotice, lifecycleMethods, {
  enableAutoSave: false,
});

export default generalNoticeRedux;
