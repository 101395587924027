import React, { useEffect } from 'react';
import { Icon, Text, Button } from '@loanstreet-usa/design-system';
import { compose, withProps } from 'recompose';
import {
  DealType,
  InstitutionType,
  UserType,
  ID,
  EtranReadyCheckType,
  EtranReadyCheckItemType,
  DealClosingWorkflowType,
} from 'types';
import { RouteTable } from 'routing';
import { RouteTo } from 'components';
import { MyUserQuery, InstitutionQuery, EtranReadyCheckQuery } from 'lsgql';

interface Props {
  etranReadyCheck: EtranReadyCheckType;

  deal: DealType;

  dealClosingWorkflow: DealClosingWorkflowType;

  institution: InstitutionType;
  user: UserType;
  onOpenLenderForm: () => void;
  onReadyChange: (ready: boolean) => void;
  id: ID;

  handleSubmitToSba: (e: React.SyntheticEvent) => void;

  handleDenied: (e: React.SyntheticEvent) => void;

  setIsManualApprovalModelOpen: () => void;
}
const LenderEtranReadyCheck = (props: Props) => {
  const { deal, institution, onReadyChange, etranReadyCheck } = props;
  const ok = etranReadyCheck?.ok || false;
  useEffect(() => onReadyChange(ok), [ok, onReadyChange]);

  const slugDisplayProps = {
    'EtranReadySlugs.has_sba_primary_contact': {
      text: 'Lender’s SBA Contact Information',
      link: RouteTable.institution.toEdit(institution?.id, 'sba'),
    },
    'EtranReadySlugs.has_sba_credentials': {
      text: 'Lender’s SBA Account Login Information',
      link: RouteTable.institution.toEdit(institution?.id, 'sba'),
    },
    'EtranReadySlugs.completed_borrower_app': {
      text: 'Borrower Application Form',
      link: RouteTable.deal.toDealSbaApplication(deal?.id),
    },
    'EtranReadySlugs.completed_lender_app_questions': {
      text: "Lender's Application",
      link: RouteTable.deal.toDealSbaLenderApplication(deal?.id),
    },
    'EtranReadySlugs.validate_etran_schema': {
      text: 'SBA Submission Pre-Check',
    },
  };

  if (deal && etranReadyCheck) {
    const renderItem = (item: EtranReadyCheckItemType) => {
      const isSchemaStep = item.slug == 'EtranReadySlugs.validate_etran_schema';

      const { id, slug, ok, details } = item;
      const { text, handleClick, link } = slugDisplayProps[slug];
      if (ok) {
        return (
          <div className="Group justified" key={id}>
            <div className="Group left checkboxes">
              <Icon icon="check" />
              <Text>{text}</Text>
            </div>
            {!isSchemaStep && (link || handleClick) && (
              <Button.Text
                as={link && RouteTo}
                label="Edit"
                onClick={handleClick && handleClick}
                to={link && link}
              />
            )}
          </div>
        );
      } else {
        return (
          <React.Fragment key={id}>
            <div className="Group justified">
              <div className="Group left checkboxes">
                <Icon icon={['fal', 'times']} />
                <Text>{text}</Text>
              </div>
              {!isSchemaStep && (link || handleClick) && (
                <Button.Text
                  as={link && RouteTo}
                  label="Edit"
                  onClick={handleClick && handleClick}
                  to={link && link}
                />
              )}
            </div>
            <div className="reasons">
              {details.map((e, i) => (
                <Text key={`${e}_${i}`}>{e}</Text>
              ))}
            </div>
          </React.Fragment>
        );
      }
    };
    return (
      <div className="EtranReadyCheck">
        {etranReadyCheck.items.map(renderItem)}

        {/*<BorrowerAdditionalInformationForm
          canEdit={true}
          dealClosingWorkflow={dealClosingWorkflow}
          isOpen={isModalOpen}
          onClose={toggleModal}
        />*/}
      </div>
    );
  }
  return null;
};
export default compose(
  MyUserQuery,
  withProps(({ user }) => ({ institutionId: user?.institution?.id })),
  InstitutionQuery,
  EtranReadyCheckQuery,
)(LenderEtranReadyCheck);
