import * as React from 'react';
import uuid from 'uuid/v4';
import {
  getTotalAmountFromTranche,
  getLenderOptions,
  getSubsidiaryOptions,
} from '../utils';
import StatHeader from './LenderRegistryStatHeader';
import InstitutionRow from './LenderRegistryInstitutionRow/LenderRegistryInstitutionRow';
import TrancheFooter from './LenderRegistryTrancheFooter';
import {
  Button,
  Header,
  StandardModal,
  Form,
  InlineAlert,
  RouteTo,
} from 'components';
import { RouteTable } from 'routing/RouteTable';
import {
  ID,
  SelectOptionType,
  LenderRegistryFormTrancheType,
  LoanTrancheOwnershipPortionInputWithDisplayInfo,
  InstitutionOwnershipPortionTypeWithDisplay,
  SortBy,
  ValidationMap,
  LoanTrancheInstitutionOwnershipPortionInput,
} from 'types';
import { KeyPath } from 'lsredux/genericForms/types';
import { invariant } from 'utils';

type Props = {
  addEntity: (keyPath: KeyPath, entity: any) => void;
  dealId: ID;
  disabled: boolean;
  errors: ValidationMap | null | undefined;
  removeEntity: (keyPath: KeyPath, entity: any) => void;
  replaceEntity: (keyPath: KeyPath, entity: any) => void;
  subsidiaryOptionMap: {
    [key: string]: SelectOptionType[];
  };
  topLevelInstitutionOptions: Array<SelectOptionType>;
  tranche: LenderRegistryFormTrancheType;
  trancheIndex: number;
};

type State = {
  addLendingEntityParentId: string | null | undefined;
  isAddLenderModalOpen: boolean;
  selectedLenderId: string | null | undefined;
  selectedLendingEntityId: string | null | undefined;
  sortBy: SortBy;
};

const initSortBy: SortBy = { column: '', reverse: false };

class LenderRegistryTranche extends React.Component<Props, State> {
  fields: {
    [key: string]: {};
  };

  constructor(props: Props) {
    super(props);

    this.fields = {
      selectedLender: {
        id: 'selectedLender',
        propertyName: 'selectedLenderId',
        allowEmpty: false,
        fieldName: 'Select Lender',
        width: 'four',
        onChange: this.handleOnSelectedLenderChange,
      },
      selectedLendingEntity: {
        id: 'selectedLendingEntity',
        propertyName: 'selectedLendingEntityId',
        allowEmpty: false,
        fieldName: 'Select Lending Entity',
        width: 'four',
        onChange: this.handleOnSelectedLendingEntityChange,
      },
    };
  }

  state = {
    isAddLenderModalOpen: false,
    addLendingEntityParentId: null,
    selectedLendingEntityId: null,
    selectedLenderId: null,
    sortBy: initSortBy,
  };

  componentDidUpdate = (prevProps: Props) => {
    if (
      this.props.tranche.loantrancheinstitutionownershipportionSet.length &&
      prevProps.tranche.loantrancheinstitutionownershipportionSet.length !==
        this.props.tranche.loantrancheinstitutionownershipportionSet.length
    ) {
      this.calculateAndUpdateOwnership();
    }
  };

  handleSortByChange = (column: string) => {
    this.setState(state => {
      if (state.sortBy.column !== '') {
        if (state.sortBy.reverse === false) {
          return { sortBy: { ...state.sortBy, reverse: true } };
        }
        return { sortBy: { column: '', reverse: false } };
      }
      return { sortBy: { column, reverse: false } };
    });
  };

  handleOnSelectedLenderChange = (value: string | null | undefined) => {
    this.setState({ selectedLenderId: value });
  };

  handleOnSelectedLendingEntityChange = (value: string | null | undefined) => {
    this.setState({ selectedLendingEntityId: value });
  };

  handleAddLender = () => {
    const { selectedLenderId } = this.state;
    if (selectedLenderId) {
      const {
        tranche,
        trancheIndex,
        addEntity,
        topLevelInstitutionOptions,
      } = this.props;
      const selectedOption = topLevelInstitutionOptions.find(
        option => option.value === selectedLenderId,
      );
      const name = selectedOption ? selectedOption.text : '';
      const institutionPortion: LoanTrancheInstitutionOwnershipPortionInput = {
        __typename: 'LoanTrancheInstitutionOwnershipPortionType',
        id: uuid(),
        institutionId: selectedLenderId,
        institutionName: name,
        loanTrancheId: tranche.id,
        institutionTotalAmount: '0',
        institutionTotalPortion: '0',
        loantrancheownershipportionSet: [],
      } as any;
      addEntity(
        [
          'loantrancheSet',
          String(trancheIndex),
          'loantrancheinstitutionownershipportionSet',
        ],
        institutionPortion,
      );

      this.handleToggleAddLenderModal();
    }
  };

  handleAddLendingEntity = () => {
    const { selectedLendingEntityId, addLendingEntityParentId } = this.state;
    if (selectedLendingEntityId && addLendingEntityParentId) {
      const {
        trancheIndex,
        addEntity,
        subsidiaryOptionMap,
        tranche,
      } = this.props;

      let selectedOption;

      const institutionPortionIndex = this.getIndexFromId(
        addLendingEntityParentId,
      );
      const institutionPortion =
        tranche.loantrancheinstitutionownershipportionSet[
          institutionPortionIndex
        ];

      if (selectedLendingEntityId === institutionPortion.institutionId) {
        selectedOption = {
          text: institutionPortion.institutionName,
          value: institutionPortion.institutionId,
        };
      } else {
        selectedOption = (
          subsidiaryOptionMap[institutionPortion.institutionId] || []
        ).find(option => option.value === selectedLendingEntityId);
      }

      const name = selectedOption ? selectedOption.text : '';
      const ownershipPortion: LoanTrancheOwnershipPortionInputWithDisplayInfo = {
        __typename: 'LoanTrancheOwnershipPortionType',
        id: uuid(),
        portion: '0',
        institutionId: selectedLendingEntityId,
        institutionName: name,
        amount: '0',
      };
      addEntity(
        [
          'loantrancheSet',
          String(trancheIndex),
          'loantrancheinstitutionownershipportionSet',
          String(institutionPortionIndex),
          'loantrancheownershipportionSet',
        ],
        ownershipPortion,
      );
      this.handleCloseAddLendingEntityModal();
    }
  };

  handleToggleAddLenderModal = () => {
    this.setState(state => ({
      isAddLenderModalOpen: !state.isAddLenderModalOpen,
      selectedLenderId: null,
    }));
  };

  handleOpenAddLendingEntityModal = (institutionPortionId: string) => {
    this.setState({
      addLendingEntityParentId: institutionPortionId,
    });
  };

  handleCloseAddLendingEntityModal = () => {
    this.setState({
      selectedLendingEntityId: null,
      addLendingEntityParentId: null,
    });
  };

  calculateAndUpdateOwnership = () => {
    const { tranche, replaceEntity, trancheIndex } = this.props;
    const institutionPortions =
      tranche.loantrancheinstitutionownershipportionSet;

    const currentTotal: number = institutionPortions.reduce(
      (sum: number, portion: InstitutionOwnershipPortionTypeWithDisplay) => {
        const amountNumber = Number(portion.institutionTotalAmount);

        invariant(
          !Number.isNaN(amountNumber),
          'portion amount is not a number!',
        );
        return sum + amountNumber;
      },
      0,
    );

    const totalAmountNumber = Number(getTotalAmountFromTranche(tranche));
    invariant(!Number.isNaN(totalAmountNumber), 'totalAmount is not a number!');

    const currentPortion = (currentTotal / totalAmountNumber).toFixed(6);

    replaceEntity(['loantrancheSet', String(trancheIndex), 'ownership'], {
      ...tranche.ownership,
      totalAmount: currentTotal.toFixed(2),
      totalPortion: currentPortion,
    });
  };

  getIndexFromId = (id: string) => {
    const { tranche } = this.props;
    return tranche.loantrancheinstitutionownershipportionSet.findIndex(
      institutionPortion => institutionPortion.id === id,
    );
  };

  getSortedInstitutionOwnershipSet = () => {
    const { sortBy } = this.state;
    const { tranche } = this.props;

    if (sortBy.column === null) {
      return tranche.loantrancheinstitutionownershipportionSet;
    }
    const sorted: InstitutionOwnershipPortionTypeWithDisplay[] = [
      ...tranche.loantrancheinstitutionownershipportionSet,
    ];
    sorted.sort((a, b) => {
      switch (sortBy.column) {
        case 'NAME':
          if (sortBy.reverse) {
            return b.institutionName.toLowerCase() <
              a.institutionName.toLowerCase()
              ? -1
              : 1;
          }

          return a.institutionName.toLowerCase() <
            b.institutionName.toLowerCase()
            ? -1
            : 1;

        case 'OWNERSHIP':
          return sortBy.reverse
            ? Number(a.institutionTotalPortion || '0') -
                Number(b.institutionTotalPortion || '0')
            : Number(b.institutionTotalPortion || '0') -
                Number(a.institutionTotalPortion || '0');
        default:
          return sortBy.reverse
            ? Number(a.institutionTotalAmount || '0') -
                Number(b.institutionTotalAmount || '0')
            : Number(b.institutionTotalAmount || '0') -
                Number(a.institutionTotalAmount || '0');

        // Use amount as default for now
      }
    });
    return sorted;
  };

  getErrorMessage = () => (
    <p>
      Lenders can’t be added to a loan tranche without any original balance. You
      can add an Original Balance amount in the{' '}
      <span>
        <RouteTo to={RouteTable.deal.toDealLoanTrancheEdit(this.props.dealId)}>
          Edit Tranche Form.
        </RouteTo>
      </span>
    </p>
  );

  render() {
    const {
      tranche,
      replaceEntity,
      topLevelInstitutionOptions,
      subsidiaryOptionMap,
      trancheIndex,
      errors,
    } = this.props;

    const {
      isAddLenderModalOpen,
      addLendingEntityParentId,
      selectedLenderId,
      selectedLendingEntityId,
      sortBy,
    } = this.state;

    const institutionPortions =
      tranche.loantrancheinstitutionownershipportionSet;

    const trancheProps = { ...this.props, data: tranche };
    const totalAmount = getTotalAmountFromTranche(tranche);

    const disabled =
      this.props.disabled || totalAmount === '0.00' || !totalAmount;
    const lenderOptions = isAddLenderModalOpen
      ? getLenderOptions(topLevelInstitutionOptions, institutionPortions)
      : [];

    return (
      <>
        <div className="lenderRegistryTranche">
          {(totalAmount === '0.00' || !totalAmount) && (
            <InlineAlert
              dismissible={false}
              message={this.getErrorMessage()}
              title="Cannot Add Lender"
              type="warning"
            />
          )}
          <div className="lenderRegistryTranche__Header">
            <Header as="h3">{tranche.name}</Header>
            <Button.Primary
              disabled={disabled}
              label="Add Lender"
              onClick={this.handleToggleAddLenderModal}
            />
          </div>
          <div className="lenderRegistryTranche__Body">
            <StatHeader
              onSortByChange={this.handleSortByChange}
              sortBy={sortBy}
              totalAmount={totalAmount}
              tranche={tranche}
            />
            {this.getSortedInstitutionOwnershipSet().map(institutionPortion => (
              <InstitutionRow
                calculateAndUpdateOwnership={this.calculateAndUpdateOwnership}
                disabled={Boolean(disabled)}
                errors={errors}
                institutionPortion={institutionPortion}
                institutionPortionIndex={this.getIndexFromId(
                  institutionPortion.id,
                )}
                key={institutionPortion.id}
                onOpenAddLendingEntityModal={
                  this.handleOpenAddLendingEntityModal
                }
                ownership={tranche.ownership}
                removeEntity={this.props.removeEntity}
                replaceEntity={replaceEntity}
                sortBy={sortBy}
                totalAmount={totalAmount || '0'}
                trancheIndex={trancheIndex}
              />
            ))}
            <TrancheFooter
              ownership={tranche.ownership}
              totalAmount={totalAmount || '0'}
            />
          </div>
        </div>
        <StandardModal
          confirmButtonText="Add"
          denyConfirm={!selectedLenderId}
          header="Add Lender"
          isOpen={isAddLenderModalOpen}
          onClose={this.handleToggleAddLenderModal}
          onConfirm={this.handleAddLender}
        >
          {Form.FieldRenderer(
            Form.Select,
            {
              ...this.fields.selectedLender,
              options: lenderOptions,
              value: selectedLenderId,
            },
            trancheProps,
            'LoanTranche',
            tranche.id,
          )}
        </StandardModal>
        <StandardModal
          confirmButtonText="Add"
          denyConfirm={!selectedLendingEntityId}
          header="Add Lending Entity"
          isOpen={Boolean(addLendingEntityParentId)}
          onClose={this.handleCloseAddLendingEntityModal}
          onConfirm={this.handleAddLendingEntity}
        >
          {Form.FieldRenderer(
            Form.Select,
            {
              ...this.fields.selectedLendingEntity,
              options: addLendingEntityParentId
                ? getSubsidiaryOptions(
                    institutionPortions[
                      this.getIndexFromId(addLendingEntityParentId)
                    ],
                    subsidiaryOptionMap,
                  )
                : [],
              value: selectedLendingEntityId,
            },
            trancheProps,
            'LoanTranche',
            tranche.id,
          )}
        </StandardModal>
      </>
    );
  }
}

export default LenderRegistryTranche;
