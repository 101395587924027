import * as React from 'react';
import noop from 'lodash/noop';

import { OrderBookContainerProps } from '../types';
import LoanTrancheTierGrid from '../../loanTrancheTierGrid/LoanTrancheTierGrid';
import TrancheOrderBook from '../trancheOrderBook/TrancheOrderBook';
import { OrderBookProspect } from 'types';
import { Column, Form, DataGrid, LoadingIndicator } from 'components';
import './OrderBooksForm.scss';

type Props = {
  orderBooks: ReadonlyArray<OrderBookContainerProps>;
};

class OrderBooksForm extends React.Component<Props> {
  orderBookColumns: Array<Column<OrderBookProspect, any>> = [
    {
      className: 'OrderBooksForm-Tiers-InvestorNameColumn',
      columnName: 'investorName',
      displayName: 'Name',
      readOnly: true,
    },
    {
      columnName: 'levelOfInterestLow',
      displayName: 'Low',
      customHeaderCell: () => LoanTrancheTierGrid.HeaderCellWithUnit('Low'),
      className: 'OrderBooksForm-Tiers-FixedColumn',
    },
    {
      columnName: 'levelOfInterestHigh',
      displayName: 'High',
      customHeaderCell: () => LoanTrancheTierGrid.HeaderCellWithUnit('High'),
      className: 'OrderBooksForm-Tiers-FixedColumn',
    },
    {
      columnName: 'midAmount',
      displayName: 'Mid',
      customHeaderCell: () => LoanTrancheTierGrid.HeaderCellWithUnit('Mid'),
      readOnly: true,
      className:
        'OrderBooksForm-Tiers-MidColumn OrderBooksForm-Tiers-FixedColumn',
    },
    {
      columnName: 'orderAmountUsd',
      displayName: 'Order',
      customHeaderCell: () => LoanTrancheTierGrid.HeaderCellWithUnit('Order'),
      className: 'OrderBooksForm-Tiers-FixedColumn',
    },
    {
      columnName: 'allocationAmountUsd',
      displayName: 'Allocation',
      customHeaderCell: () =>
        LoanTrancheTierGrid.HeaderCellWithUnit('Allocation'),
      className: 'OrderBooksForm-Tiers-FixedColumn',
    },
    {
      columnName: 'customTierId',
      displayName: 'Tier',
      className: 'OrderBooksForm-Tiers-TierColumn',
    },
    {
      columnName: 'note',
      displayName: ' ',
      readOnly: true,
      className: 'DataGridIconCell',
      icon: 'sticky-note',
    },
  ];

  render() {
    const { orderBooks = [] } = this.props;
    let anyLackingData = false;
    let isMultiLenderView = false;
    orderBooks.forEach(e => {
      if (!e.data || !e.data.id || e.loading) anyLackingData = true;
      isMultiLenderView = e.enableMultiLenderView;
    });
    if (anyLackingData) return <LoadingIndicator />;
    return (
      <Form className="OrderBooksForm" id="OrderBooksForm" onSubmit={noop}>
        {isMultiLenderView && (
          <div className="OrderBooksForm-Tiers">
            <Form.Header as="h2" header="Tiers" />
            <div className="OrderBooksForm-Tiers-Forms">
              {orderBooks.map((e, i) => (
                <LoanTrancheTierGrid
                  canEdit
                  index={i + 1}
                  key={e.data.id}
                  loantranchetierSet={e.data.loantranchetierSet}
                  name={e.data.name}
                  onAddTierRow={e.handleAddTierRow}
                  onDeleteTierRow={e.handleDeleteTierRow}
                  onEditTierRow={e.handleEditTierRow}
                />
              ))}
            </div>
          </div>
        )}
        <div className="OrderBooksForm-Tranches">
          <Form.Header as="h2" header="Investors" />
          <DataGrid.HeaderRow
            className="TrancheOrderBooks--Header"
            columns={this.orderBookColumns}
            deleteColumn
          />
          {orderBooks.map((e, i) => (
            <TrancheOrderBook
              columns={this.orderBookColumns}
              index={i + 1}
              key={e.data.id}
              {...e}
            />
          ))}
        </div>
      </Form>
    );
  }
}

export default OrderBooksForm;
