import { mutationBuilder } from './builders';
import { SetSbaPppForgivenessLoanInformationMutationDefinition } from './definitions';
import {
  SbaPppForgivenessLoanInformationInput,
  SbaPppForgivenessLoanInformationType,
} from 'types';

const SbaPppForgivenessLoanInformationMutationMethods = {
  setSbaPppForgivenessLoanInformation: mutationBuilder<
    SbaPppForgivenessLoanInformationInput,
    SbaPppForgivenessLoanInformationType
  >(SetSbaPppForgivenessLoanInformationMutationDefinition, {
    build: mutate => sbaPppForgivenessLoanInformationData =>
      mutate({ variables: { sbaPppForgivenessLoanInformationData } }),
  }),
};

export default SbaPppForgivenessLoanInformationMutationMethods;
