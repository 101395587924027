import PropTypes from 'prop-types';
import { PermissionDefinition } from './definitions/types';
import {
  MyUserType,
  ObjectLowerBaseTypeNames,
  PermissionFlags,
  PermissionsBaseType,
  ID,
} from 'types';

/* eslint-disable import/prefer-default-export */

/**
 * Permissions contexts are indexed by `id_scope`, this is
 * the PermissionKey
 * For Deal with ID === 2, the PermissionKey === '2_deal'
 */
export type PermissionKey = string;

/**
 * A Map of Django Permission flags:
 * {
 *    add_loantranche: true,
 *    change_deal: true,
 * }
 */
export type PermissionMap = {
  [key in PermissionFlags]: boolean;
};

/**
 * Context definition that facilitates communication between
 * GlobalPermissionPerspective, PermissionPerspective, and Secured
 */
export const GlobalPermissionPerspectiveContextPropTypes = {
  user: PropTypes.object,
  isSuperUser: PropTypes.bool,
  initPermissionSet: PropTypes.func,
  disposePermissionSet: PropTypes.func,
  isPermitted: PropTypes.func,
  hasKey: PropTypes.func,
};

export const PermissionPerspectiveContextPropTypes = {
  perspectiveId: PropTypes.string,
};

export type PermissionPerspectiveContextType = {
  perspectiveId: ID | null | undefined;
};

/**
 * Defines the standard method for checking permissions.
 * Exposed as `isPermitted` on GlobalPermissionPerspective's React context
 */
export type CheckPermissionPerspectiveContextType = {
  hasKey: (key: PermissionKey) => boolean;
  isPermitted: (
    perspectiveKey: PermissionKey,
    flag: PermissionFlags,
  ) => boolean;
};

/**
 * Type definition for the context object that facilitates communication
 * between GlobalPermissionPerspective, PermissionPerspective, and Secured
 */
export type GlobalPermissionPerspectiveContextType = CheckPermissionPerspectiveContextType & {
  disposePermissionSet: (subject: PermissionsBaseType) => void;
  initPermissionSet: (
    subject: PermissionsBaseType,
    callback: () => void,
  ) => void;
  isSuperUser: boolean;
  user: MyUserType;
};

/**
 * A map a permission context scopes:
 * ['2_deal']: PermissionMap
 */
export type PermissionsEntityMap = {
  // id_scope
  [key: string]: PermissionMap;
};

export type WeightScope = {
  referenceCount: number;
  source: Set<PermissionFlags>;
} & Partial<
  {
    [key in ObjectLowerBaseTypeNames | PermissionFlags]: number;
  }
>;

export type PermissionScopeMap = {
  [key: string]: WeightScope;
};

export type PermissionFlagToken = {
  type: ObjectLowerBaseTypeNames | PermissionFlags;
};
/* eslint-enable */

export interface PermissionRequest {
  definition: PermissionDefinition;
  flag: PermissionFlags;
  perspectiveId?: ID;
}
