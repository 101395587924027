import * as React from 'react';
import CreditRatingsForm from '../creditRatingsForm/CreditRatingsForm';
import IndustryCodeSelect from './industryCodeSelect/IndustryCodeContainer';
import { DealType, CreditRatingType } from 'types';
import {
  Form,
  WrappedMediatorFormProps,
  Tiler,
  Divider,
  Header,
} from 'components';
import './DealForm.scss';

const largeWidth = '332px';
const width = '188px';
const deal = 'Deal';

interface DealFormProps extends WrappedMediatorFormProps<DealType> {
  handleRemoveCreditRating: (rating: CreditRatingType) => void;
  updateCreditRating: (rating: CreditRatingType) => void;
}

class DealForm extends React.Component<DealFormProps> {
  formFields = {
    name: {
      autoFocus: true,
      id: 'name',
      propertyName: 'name',
      fieldName: 'Name',
      required: true,
      onChange: this.props.mutateProperty,
      width: largeWidth,
    },
    borrower: {
      id: 'borrowerName',
      propertyName: 'borrowerName',
      fieldName: 'Borrower',
      onChange: this.props.mutateProperty,
      width: largeWidth,
    },
    industryCode: {
      id: 'industryCode',
      propertyName: 'industryCode',
      fieldName: 'NAICS Code',
      onChange: this.props.mutateProperty,
      width: '348px',
      className: 'industryCode',
    },
    totalCommitmentAmount: {
      fieldName: 'Target Amount',
      propertyName: 'totalCommitmentAmount',
      id: 'totalCommitmentAmount',
      onChange: this.props.mutateProperty,
      width,
    },
    targetClosingDate: {
      fieldName: 'Target Closing Date',
      propertyName: 'targetClosingDate',
      id: 'targetClosingDate',
      onChange: this.props.mutateProperty,
      width,
    },
    category: {
      fieldName: 'Category',
      propertyName: 'category',
      id: 'category',
      onChange: this.props.mutateProperty,
      typeName: 'DealCategory',
      width,
    },
  };

  renderCreditRatings() {
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars, react/jsx-handler-names */
    const { updateCreditRating, disabled, ...rest } = this.props;

    return (
      <CreditRatingsForm
        creditratingSet={rest.data.creditratingSet}
        disabled={disabled}
        errors={this.props.errors}
        isSaving={this.props.isSaving}
        onChange={this.props.updateCreditRating}
        onDelete={this.props.handleRemoveCreditRating}
        width="three"
      />
    );
  }

  render() {
    const { data } = this.props;

    return (
      <Tiler>
        <Tiler.Tile className="steel" title="Deal Information" width="Half">
          <Form.Group grouped>
            <Form.Group grouped>
              {Form.FieldRenderer(
                Form.Input,
                this.formFields.name,
                this.props,
                deal,
                data.id,
              )}
              {Form.FieldRenderer(
                Form.Input,
                this.formFields.borrower,
                this.props,
                deal,
                data.id,
              )}
              {Form.FieldRenderer(
                IndustryCodeSelect,
                this.formFields.industryCode,
                this.props,
                deal,
                data.id,
              )}
            </Form.Group>
            <div className="DealForm-CreditRatings">
              <Divider />
              <Header as="h5" className="caps">
                Ratings
              </Header>
              {this.renderCreditRatings()}
            </div>
            <Form.Group grouped widths="equal">
              <Divider />
              <Header as="h5" className="caps">
                Closing
              </Header>
              {Form.FieldRenderer(
                Form.Money,
                this.formFields.totalCommitmentAmount,
                this.props,
                deal,
                data.id,
              )}
              {Form.FieldRenderer(
                Form.Calendar,
                this.formFields.targetClosingDate,
                this.props,
                deal,
                data.id,
              )}
              {Form.FieldRenderer(
                Form.ReferenceSelect,
                this.formFields.category,
                this.props,
                deal,
                data.id,
              )}
            </Form.Group>
          </Form.Group>
        </Tiler.Tile>
      </Tiler>
    );
  }
}

export default DealForm;
