import * as React from 'react';
import noop from 'lodash/noop';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

/* eslint-disable */
import { DropTarget, DropTargetMonitor } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import HTML5Backend from 'react-dnd-html5-backend';
import { handleDrop, createDropTargetEntity } from './utils/dragDropMethods';
import { DisplayTable, ConfirmModal } from 'components';
import { handleCanDrop } from './utils/dragDropMethods';

/* eslint-enable */
import { getRootFolderId } from './utils/sharedMethods';
import CreateFolderModal from './createFolderModal/CreateFolderModalContainer';
import FolderShareModal from './folderRow/folderShareModal/FolderShareModalContainer';
import RowFork from './rowFork/RowForkContainer';
import DataroomHeader from './dataroomHeader/DataroomHeader';

import './Dataroom.scss';

import {
  FolderMap,
  DataroomContext,
  DataroomProps,
  SortBy,
  FolderWithDate,
  FileWithDate,
  FixedSizeListProps,
  DataroomRowData,
  TransformedDataroom,
  ContextPropTypes,
  DropTypeValues,
} from './types';

import transformDataroomData from './utils/transformDataroomData';
import { getDownloadLink, inferSortReverse, invariant } from 'utils';

const emptyArray = [];

function getItemKey(index: number, data: Array<DataroomRowData>) {
  const { item } = data[index];
  return item.id;
}

type State = {
  confirmDeleteModalIsOpen: boolean;
  createFolderModalIsSubfolder: boolean | null | undefined;
  createFolderModalOpen: boolean;
  data: TransformedDataroom;
  folderShareModalIsOpen: boolean;
  modalParentFolderId: string | null | undefined;
  modalSelectedFile: FileWithDate | null;
  modalSelectedFolder: FolderWithDate | null;
};

const shallowArg = { shallow: true };
const emptyChildRow = {
  children: [],
  fileCount: 0,
  folderCount: 0,
  uploadingCount: 0,
  completedUploadCount: 0,
};

/* eslint-enable */
const DropTargetWrapper = DropTarget(
  Object.keys(DropTypeValues),
  {
    drop(props: DataroomProps, monitor: DropTargetMonitor) {
      // handle drops for all children
      // we have two cases to handle here
      if (!monitor.isOver(shallowArg)) {
        // 1. A child was a drop target, and has returned an entity
        // a child drop target should have intercepted and created the entity
        // so we retrieve via getDropResult rather than getItem
        const childDropTargetEntity = monitor.getDropResult();
        // nothing to do, the element was not dropped on any target
        if (!childDropTargetEntity) return;
        handleDrop(props, childDropTargetEntity);
      }
    },
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: false,
  }),
);

const dataroomPropMap = {
  modified: 'modifiedPosixTimestamp',
};

class Dataroom extends React.Component<DataroomProps, State> {
  static processData = (props: DataroomProps): TransformedDataroom => {
    const {
      sortBy,
      dataroomFiles = [],
      newFileSet,
      openFolders,
      percentMap,
    } = props;
    const { topLevelFolders = [] } = props.deal;
    const topLevelFolderId =
      topLevelFolders.length > 0 ? topLevelFolders[0].id : '-1';

    return transformDataroomData(
      topLevelFolderId,
      dataroomFiles,
      sortBy,
      openFolders,
      percentMap,
      newFileSet,
    );
  };

  static childContextTypes = ContextPropTypes;

  constructor(props: DataroomProps) {
    super(props);
    this.state = {
      data: Dataroom.processData(props),
      modalSelectedFolder: null,
      modalParentFolderId: null,
      createFolderModalOpen: false,
      createFolderModalIsSubfolder: null,
      confirmDeleteModalIsOpen: false,
      modalSelectedFile: null,
      folderShareModalIsOpen: false,
    };
  }

  getChildContext(): DataroomContext {
    const { deleteFolder, openFolder, closeFolder, dealId } = this.props;
    const { data } = this.state;
    const res: DataroomContext = {
      data,
      dealId,
      deleteFolder,
      openFolder,
      closeFolder,
      handleUploadFiles: this.handleUploadFiles,
      openFolderModal: this.onOpenCreateSubFolderModal,
      deleteFolderModal: this.onOpenDeleteFolderModal,
      deleteFileModal: this.onOpenDeleteFileModal,
      openFolderShareModal: this.onOpenFolderShareModal,
      openPdfViewer: this.onOpenPdfViewer,
    };
    return res as DataroomContext;
  }

  componentDidUpdate(prevProps: DataroomProps) {
    if (!this.props.isOver && prevProps.isOver) {
      this.props.leaveDragHover();
    }
    if (
      !Object.is(prevProps.dataroomFiles, this.props.dataroomFiles) ||
      !Object.is(prevProps.sortBy, this.props.sortBy) ||
      !Object.is(prevProps.newFileSet, this.props.newFileSet)
    ) {
      /* eslint-disable */
      const transformedData = Dataroom.processData(this.props);
      this.setState({
        data: Dataroom.processData(this.props),
      });
      /* eslint-enable */
    }
  }

  onOpenDeleteFileModal = (file?: FileWithDate) =>
    this.setState({
      modalSelectedFile: file || null,
      confirmDeleteModalIsOpen: true,
    });

  onOpenDeleteFolderModal = (folder?: FolderWithDate) =>
    this.setState({
      modalSelectedFolder: folder || null,
      confirmDeleteModalIsOpen: true,
    });

  onOpenCreateSubFolderModal = (parentId: string, child?: FolderWithDate) =>
    this.setState({
      modalSelectedFolder: child || null,
      modalParentFolderId: parentId || null,
      createFolderModalOpen: true,
      createFolderModalIsSubfolder: true,
    });

  onOpenFolderShareModal = (folder: FolderWithDate) => {
    this.setState({
      modalSelectedFolder: folder,
      folderShareModalIsOpen: true,
    });
  };

  onOpenPdfViewer = (file: FileWithDate) => {
    const { name, id } = file;
    const fileUri = getDownloadLink(file);
    if (!fileUri) return;
    this.props.openFile({
      fileName: name,
      fileUri,
      id,
    });
  };

  handleCloseFolderShareModal = () => {
    this.setState({
      modalSelectedFolder: null,
      folderShareModalIsOpen: false,
      createFolderModalIsSubfolder: null,
    });
  };

  handlePermissionChange = (message: string, folderName: string) => {
    this.props.setAlert(`${message} - ${folderName}`, 'success');
  };

  handleCloseConfirmModal = () => {
    this.setState({
      modalSelectedFolder: null,
      confirmDeleteModalIsOpen: false,
      modalSelectedFile: null,
    });
  };

  handleOnCloseFolderModal = () =>
    this.setState({
      modalSelectedFolder: null,
      modalParentFolderId: null,
      createFolderModalOpen: false,
    });

  handleDeleteFolder = () => {
    if (this.state.modalSelectedFolder) {
      const { id } = this.state.modalSelectedFolder;
      this.setState(
        {
          confirmDeleteModalIsOpen: false,
          modalSelectedFolder: null,
        },
        () => {
          this.props.deleteFolder(id);
        },
      );
    }
  };

  handleDeleteFile = () => {
    if (this.state.modalSelectedFile) {
      const { id } = this.state.modalSelectedFile;
      this.setState(
        {
          confirmDeleteModalIsOpen: false,
          modalSelectedFile: null,
        },
        () => {
          this.props.deleteDocument(id);
        },
      );
    }
  };

  handleUploadFiles = (folderId: string, newFiles: Array<File>) => {
    invariant(
      newFiles && newFiles.length,
      'A file upload was detected, but the files collection was null or empty',
    );

    this.props.createDocuments(newFiles, folderId);
  };

  handleChangeSortField = (sortBy: SortBy) => {
    const topLevelFolderId = getRootFolderId(this.props);
    const childRows: FolderMap<any> =
      this.state.data.roomMap[topLevelFolderId] || emptyChildRow;
    this.props.changeSortField(
      inferSortReverse(
        childRows.children,
        this.props.sortBy,
        sortBy,
        dataroomPropMap,
      ),
    );
  };

  modalProps: () => { message: string; onConfirm: () => void } = () => {
    const {
      confirmDeleteModalIsOpen,
      modalSelectedFolder,
      modalSelectedFile,
    } = this.state;
    const source = modalSelectedFile || modalSelectedFolder;
    if (!confirmDeleteModalIsOpen || !source)
      return { message: '', onConfirm: noop };
    const isDocument = source.fileType === 'Document';
    return {
      message: `Are you sure you wish to delete the ${
        isDocument ? 'file' : 'folder'
      } ${source.name ? source.name : ''}?  This operation cannot be undone.`,
      onConfirm: isDocument ? this.handleDeleteFile : this.handleDeleteFolder,
    };
  };

  renderRow = (renderProps: FixedSizeListProps) => {
    const { leaveDragHover } = this.props;
    const { data } = this.state;
    return (
      <RowFork
        leaveDragHover={leaveDragHover}
        levelMap={data.levelMap}
        {...renderProps}
      />
    );
  };

  renderList = (sizes: { height: number }) => {
    const { dataList } = this.state.data;

    // we need to account for the height of the table header
    const height = sizes.height <= 22 ? sizes.height : sizes.height - 22;

    return (
      <List
        height={height}
        itemCount={dataList.length}
        itemData={dataList}
        itemKey={getItemKey}
        itemSize={30}
        width="100%"
      >
        {this.renderRow}
      </List>
    );
  };

  render() {
    const {
      dealId,
      sortBy,
      connectDropTarget,
      fetchDataroomFiles,
    } = this.props;
    const {
      data,
      createFolderModalOpen,
      folderShareModalIsOpen,
      modalSelectedFolder,
    } = this.state;

    return connectDropTarget(
      <div id="Dataroom">
        <DataroomHeader
          onToggleSort={this.handleChangeSortField}
          sortBy={sortBy}
        />
        <AutoSizer dataList={data.dataList} disableWidth>
          {this.renderList}
        </AutoSizer>
        {createFolderModalOpen && (
          <CreateFolderModal
            dealId={dealId}
            folderId={modalSelectedFolder ? modalSelectedFolder.id : null}
            formId="CreateSubFolderModal"
            isOpen={createFolderModalOpen}
            isSubfolder={this.state.createFolderModalIsSubfolder}
            onClose={this.handleOnCloseFolderModal}
            parentFolderId={this.state.modalParentFolderId}
            siblingNames={
              this.state.modalParentFolderId
                ? this.state.data.roomMap[
                    this.state.modalParentFolderId
                  ].children.map(child => child.name)
                : emptyArray
            }
          />
        )}
        <ConfirmModal
          {...this.modalProps()}
          deleteAction
          header="Delete?"
          isOpen={this.state.confirmDeleteModalIsOpen}
          onReject={this.handleCloseConfirmModal}
        />
        <FolderShareModal
          fetchDataroomFiles={fetchDataroomFiles}
          folder={modalSelectedFolder}
          folderId={modalSelectedFolder ? modalSelectedFolder.id : ''}
          folderOrganizationFolderRoles={emptyArray}
          institutions={emptyArray}
          isOpen={folderShareModalIsOpen}
          onClose={this.handleCloseFolderShareModal}
          onPermissionChange={this.handlePermissionChange}
        />
      </div>,
    );
  }
}

export default DropTargetWrapper(Dataroom);
