import React from 'react';
import GenericWorkflow from '../sbaWorkflows/GenericWorkflow';
import DebugBar from '../DebugBar';
import { LegacyBorrowerWorkflow, LegacyLenderWorkflow } from '.';
import { WrappedMediatorFormProps, Tiler, Header } from 'components';
import {
  DealClosingWorkflowStepType,
  DealClosingWorkflowType,
  DealType,
  ID,
  ValidationMap,
} from 'types';
import { isDealClosed } from 'utils';

import '../DealClosingWorkflow.scss';

interface Props extends WrappedMediatorFormProps<DealType> {
  deal: DealType;
  dealClosingWorkflow: DealClosingWorkflowType;
  closeDeal: (dealId: ID) => void;
  updateStep: (stepData: DealClosingWorkflowStepType) => void;
  updateLoanFunding: (property: string, value: string) => void;
  errors: ValidationMap;
  dealId: string;
}

function DealClosingWorkflow(props: Props) {
  const {
    data: { dealClosingWorkflow, stage },
    dealId,
  } = props;

  const steps = dealClosingWorkflow?.steps;
  if (!steps || steps.length === 0) return null;

  const disabled = props.disabled || isDealClosed(stage);

  const canClose =
    !props.disabled && dealClosingWorkflow.isComplete && !isDealClosed(stage);

  const { workflowType } = dealClosingWorkflow;

  function renderBody() {
    switch (workflowType) {
      case 'SBA_PPP_BORROWER':
        return <LegacyBorrowerWorkflow {...props} disabled={disabled} />;
      case 'SBA_PPP_LENDER':
        return (
          <LegacyLenderWorkflow
            {...props}
            canClose={canClose}
            disabled={disabled}
          />
        );
      case 'SBA_PPP_CLOSING_WORKFLOW':
        return (
          <>
            <DebugBar dealClosingWorkflow={dealClosingWorkflow} />
            <Header as="h3">Closing Checklist</Header>
            <GenericWorkflow
              {...props}
              borrower={dealClosingWorkflow.isBorrower}
              dealClosingWorkflow={dealClosingWorkflow}
              dealId={dealId}
            />
          </>
        );
      default:
        throw new Error(`Unknown WorkflowType: ${workflowType}`);
    }
  }

  return (
    <Tiler>
      <Tiler.Tile
        className="DealClosingWorkflow__Wrapper steel"
        minWidth="OneThird"
        width="Half"
      >
        <div className="DealClosingWorkflow">{renderBody()}</div>
      </Tiler.Tile>
    </Tiler>
  );
}

export default DealClosingWorkflow;
