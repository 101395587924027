import * as React from 'react';
import { noop } from 'lodash';
import { isAdjustableRate } from '../utils';
import { LoanTrancheFormProps } from '../../types';
import TrancheGroup from '../TrancheGroup';
import { Form, Header } from 'components';

const totalRateMinimum = 'totalRateMinimum';
const totalRateMaximum = 'totalRateMaximum';
const adjustmentLimitDown = 'adjustmentLimitDown';
const adjustmentLimitUp = 'adjustmentLimitUp';

const smallWidth = '90px';
const width = '120px';
const loanTranche = 'LoanTranche';

class AdjustableRateFloorAndCapFields extends React.Component<
  LoanTrancheFormProps
> {
  fields: {
    [key: string]: any;
  };

  constructor(props: LoanTrancheFormProps) {
    super(props);

    this.fields = {
      totalRateDescription: {
        id: 'totalRateDescription',
        propertyName: 'totalRateDescription',
        value: 'Total Interest Rate',
        onChange: noop,
        disabled: true,
        disabledReadable: true,
      },
      totalRateFloor: {
        id: totalRateMinimum,
        propertyName: totalRateMinimum,
        onChange: this.props.mutateProperty,
        fieldName: 'Floor',
        width: smallWidth,
      },
      totalRateCap: {
        id: totalRateMaximum,
        propertyName: totalRateMaximum,
        onChange: this.props.mutateProperty,
        fieldName: 'Cap',
        width: smallWidth,
      },
      adjustmentLimitDecrease: {
        id: adjustmentLimitDown,
        propertyName: adjustmentLimitDown,
        onChange: this.props.mutateProperty,
        width,
        fieldName: 'Decrease',
      },
      adjustmentLimitIncrease: {
        id: adjustmentLimitUp,
        propertyName: adjustmentLimitUp,
        onChange: this.props.mutateProperty,
        width,
        fieldName: 'Increase',
      },
    };
  }

  render() {
    const { data } = this.props;

    if (!isAdjustableRate(data)) return null;

    return (
      <TrancheGroup>
        <Header as="h5" className="steel bold">
          Tranche
        </Header>
        <Form.Group className="FloorAndCapGroup">
          {Form.FieldRenderer(
            Form.Text,
            this.fields.totalRateDescription,
            this.props,
            loanTranche,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Percentage,
            this.fields.totalRateFloor,
            this.props,
            loanTranche,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Percentage,
            this.fields.totalRateCap,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
        <Form.Divider className="transparent" />
        <Header as="h5" className="steel bold">
          Adjustment Limit
        </Header>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Percentage,
            this.fields.adjustmentLimitDecrease,
            this.props,
            loanTranche,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Percentage,
            this.fields.adjustmentLimitIncrease,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
      </TrancheGroup>
    );
  }
}

export default AdjustableRateFloorAndCapFields;
