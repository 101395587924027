import isEmpty from 'lodash/isEmpty';

import { setAlert } from '../actions/alerts';
import mutationsDispatch from '../../graphql/mutationsDispatch/mutationsDispatch';
import validationDispatch from '../../graphql/mutationsDispatch/validationDispatch';
import { DispatchResponse } from '../../graphql/mutationsDispatch/types';
import { FetchMethod } from '../../graphql/types';
import { PayloadAction } from '../types';
import { ReduxKeys } from '../directory';
import {
  LifecycleMethods,
  MutationResponseAction,
  SaveResponse,
} from './types';
import { BaseType } from 'types';
import { invariant, debug, logToSentry, isWholeNumber } from 'utils';

/* eslint-disable import/prefer-default-export */

function treeWalker(
  current: any,
  callback: (e: any, key: string) => void,
  levelKey = '',
) {
  // invoke callback for this level
  callback(current, levelKey);

  Object.keys(current).forEach(key => {
    if (current[key]) {
      if (Array.isArray(current[key])) {
        const children: Array<any> = current[key];
        children.forEach(child => treeWalker(child, callback, key));
        // handle array children
      } else if (current[key] !== null && typeof current[key] === 'object') {
        const child: any = current[key];
        treeWalker(child, callback, key);
        // handle child object
      }
    }
  });
}

function stateHasErrors(state: any) {
  let hasErrors = false;
  if (state) {
    treeWalker(state, (e: any) => {
      // Need to make sure we're looking at an errors object as opposed to an
      // object with just an errors key.
      if (e && e.errors && e.warnings && !isEmpty(e.errors)) {
        if (e.errors.id && !isWholeNumber(e.errors.id)) {
          // Some auto-validations will return an error because we validated an
          // object with a uuid
          const errorsCopy = { ...e.errors };
          delete errorsCopy.id;
          if (!isEmpty(errorsCopy)) hasErrors = true;
        } else if (!isEmpty(e.errors)) {
          hasErrors = true;
        }
      }
    });
  }
  return hasErrors;
}

export function invokeSaveAction<TOut extends BaseType>(
  reduxKey: ReduxKeys | Array<ReduxKeys>,
  lifeCycle: LifecycleMethods<TOut>,
  responseAction: (e: DispatchResponse<TOut>) => MutationResponseAction<TOut>,
  toggleSave: (arg0: boolean) => PayloadAction<boolean>,
  fetchMethod?: FetchMethod<TOut>,
  isAutoSave?: boolean,
): (dispatch: any, getState: any) => Promise<SaveResponse> {
  invariant(toggleSave, 'toggleSave action is required');

  invariant(reduxKey, 'reduxKey is required to support saving');
  invariant(lifeCycle, 'LifecycleMethods were not provided');
  invariant(responseAction, 'responseAction is required');

  return async function invokeSave(
    dispatch: any,
    getState: any,
  ): Promise<SaveResponse> {
    const keyPath = Array.isArray(reduxKey)
      ? ['nonpersisted', ...reduxKey]
      : ['nonpersisted', reduxKey];

    const fullState = getState();

    invariant(
      fullState,
      `Failed to find FormReduxState data at ${keyPath.join(', ')}`,
    );

    if (isAutoSave) {
      /*
        The call to invokeSave was called from invoke of autoSave,
        let's check to see if autoSave is actually enabled
      */
      if (fullState.getIn(keyPath).get('autoSave') !== true) {
        const updatedState = fullState.getIn(keyPath).toJS();
        const hasErrors = stateHasErrors(updatedState.errors);

        return { success: !hasErrors, entityId: updatedState.data.id };
      }
    }

    invariant(fetchMethod, 'fetchMethod is required');
    const verifiedFetch = fetchMethod;

    const updatedState = fullState.getIn(keyPath).toJS();
    if (updatedState.isDirty === false) {
      debug('FormData was not dirty, returning');
      debug('saveAction return: hard false');

      const hasErrors = stateHasErrors(updatedState.errors);

      return { success: !hasErrors, entityId: updatedState.data.id };
    }
    debug(`Saving Keypath: ${keyPath.join(', ')}`);
    debug('Methods: ', lifeCycle);
    dispatch(toggleSave(true));
    debug('invoking mutationsDispatch');

    let response: DispatchResponse<any> | null | undefined;

    const enableStrictValidation = fullState.getIn([
      'persisted',
      'dealContext',
      'enableStrictValidation',
    ]);

    debug(`enableStrictValidation: ${enableStrictValidation}`);

    const { inputScrubbers } = lifeCycle;

    try {
      response = await mutationsDispatch(
        updatedState,
        enableStrictValidation,
        lifeCycle.mutations,
        verifiedFetch,
        lifeCycle.validators,
        lifeCycle.propertyWhitelist,
        inputScrubbers,
      );
    } catch (ex) {
      logToSentry(ex);

      /* eslint-disable */

      console.warn(
        'FormsRedux invokeSave is not correctly setting the _all_ error when an exception is thrown, this behavior swallows errors',
      );

      console.error(ex);

      /* eslint-enable */

      // errored
      response = {
        entity: null,
        errors: null,
        ok: false,
      };
    }
    debug('Response: ', response);

    if (response.errors) {
      treeWalker(response.errors, (e: any) => {
        if (e.__all__) {
          if (Array.isArray(e.__all__)) {
            e.__all__.forEach(error => dispatch(setAlert(error, 'error')));
          } else {
            dispatch(setAlert(e.__all__, 'error'));
          }
        }
      });
    }

    dispatch(responseAction(response));

    const imSavedState = getState().getIn(keyPath);
    // It's possible for state to have unmounted prior to this action finishing
    const savedState = imSavedState ? imSavedState.toJS() : undefined;

    // invariant(!savedState.isDirty, 'Saved state was flag as isDirty: true');
    debug('Saved State: ', savedState);

    const hasErrors =
      !response.ok || (savedState && stateHasErrors(savedState.errors));
    dispatch(toggleSave(false));

    return {
      success: !hasErrors,
      entityId: savedState ? savedState.data.id : null,
    };
  };
}

export function invokeValidationAction<TOut extends BaseType>(
  reduxKey: ReduxKeys | Array<ReduxKeys>,
  lifeCycle: LifecycleMethods<TOut>,
  responseAction: (e: DispatchResponse<TOut>) => MutationResponseAction<TOut>,
  toggleSave: (arg0: boolean) => PayloadAction<boolean>,
) {
  return async function invokeValidation(dispatch: any, getState: any) {
    const keyPath = Array.isArray(reduxKey)
      ? ['nonpersisted', ...reduxKey]
      : ['nonpersisted', reduxKey];

    const current = getState()
      .getIn(keyPath)
      .toJS();

    const { validators, inputScrubbers } = lifeCycle;
    const { data } = current;

    if (isEmpty(validators)) return;

    const enableStrictValidation = getState().getIn([
      'persisted',
      'dealContext',
      'enableStrictValidation',
    ]);

    if (!enableStrictValidation) return;

    const res = {};
    try {
      dispatch(toggleSave(true));
      await validationDispatch(data, validators, res, inputScrubbers);
    } catch (ex) {
      // eslint-disable-next-line
      console.error(ex);
    }

    dispatch(
      responseAction({
        entity: null,
        errors: isEmpty(res) ? null : res,
        ok: true,
      }),
    );

    dispatch(toggleSave(false));
  };
}

export function invokeEntityValidationAction<TOut extends BaseType>(
  reduxKey: ReduxKeys | Array<ReduxKeys>,
  lifeCycle: LifecycleMethods<TOut>,
  responseAction: (e: DispatchResponse<TOut>) => MutationResponseAction<TOut>,
  toggleSave: (arg0: boolean) => PayloadAction<boolean>,
) {
  return async function invokeValidation(dispatch: any, getState: any) {
    const keyPath = Array.isArray(reduxKey)
      ? ['nonpersisted', ...reduxKey]
      : ['nonpersisted', reduxKey];

    const current = getState()
      .getIn(keyPath)
      .toJS();

    /*
      The call to invokeValidate was called from invoke of autoValidate,
      let's check to see if autoValidate is actually enabled
    */
    if (current.autoValidate !== true) {
      return;
    }

    const { validators, inputScrubbers } = lifeCycle;
    const { data } = current;

    if (isEmpty(validators)) return;

    const res = {};
    try {
      dispatch(toggleSave(true));
      await validationDispatch(data, validators, res, inputScrubbers);
    } catch (ex) {
      // eslint-disable-next-line
      console.error(ex);
    }

    dispatch(
      responseAction({
        entity: null,
        errors: isEmpty(res) ? null : res,
        ok: true,
      }),
    );

    dispatch(toggleSave(false));
  };
}
