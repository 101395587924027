import { compose, withProps } from 'recompose';
import uuid from 'uuid/v4';
import { connect } from 'react-redux';
import { PublicInstitutionQuery, PublicIsValidJobQuery } from 'lsgql';
import TriggerSbaOnboardingMutation from 'lsgql/mutations/TriggerOnboardingMutation';
import PublicSbaPppLoanApplicationRedux from 'lsredux/reducer/forms/publicSbaPppLoanApplication';
import { FormMediator, withLoadingIndicator } from 'components';
import { RouteParams, withRouteParams } from 'routing';
import { SbaPppApplicantOwnershipType, SbaPppLoanApplicationType } from 'types';
import { RoundTwoPublicSbaPppLoanApplicationForm } from './RoundTwoLoanApplicationForm';
import { buildMockedFormQueryRefetch } from './BuildMockedFormQueryRefetch';
import { RouteTable } from 'routing';

import './RoundTwoLoanApplication.scss';

const onboardingMethod = async (id: string, props: any) => {
  const onboardingResponse = await TriggerSbaOnboardingMutation(
    id,
    props.institutionTicker,
    props.institutionJob,
  );

  return Boolean(onboardingResponse?.data?.onboardSbaApplication?.ok);
};

function merge(
  stale: SbaPppLoanApplicationType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fresh: SbaPppLoanApplicationType,
) {
  return {
    ...fresh,
    category: 'SBA_PPP_ROUND_TWO_FIRST_DRAW',
    __typename: 'SbaPppLoanApplicationType',
    sbapppinstitution: {
      ...fresh.sbapppinstitution,
      category: 'SBA_PPP_ROUND_TWO_FIRST_DRAW',
      __typename: 'SbaPppInstitutionType',
    },
    sbappploanfinancials: {
      ...fresh.sbappploanfinancials,
      category: 'SBA_PPP_ROUND_TWO_FIRST_DRAW',
      __typename: 'SbaPppLoanFinancialsType',
    },
    sbapppapplicantownershipSet: (fresh.sbapppapplicantownershipSet || []).map(
      e => {
        return {
          ...e,
          category: 'SBA_PPP_ROUND_TWO_FIRST_DRAW',
          __typename: 'SbaPppApplicantOwnershipType',
        };
      },
    ),
  };
}

const mapDispatchToProps = (dispatch: any) => {
  const generated = PublicSbaPppLoanApplicationRedux.actions.generateActions(
    dispatch,
  );

  const onInstitutionChange = (value: string, fieldId: string) =>
    generated.mutateProperty(value, ['sbapppinstitution', fieldId]);

  const onLoanFinancialsChange = (value: string, fieldId: string) =>
    generated.mutateProperty(value, ['sbappploanfinancials', fieldId]);

  const addOwner = () =>
    generated.addEntity('sbapppapplicantownershipSet', {
      id: uuid(),
      category: 'SBA_PPP_ROUND_TWO_FIRST_DRAW',
      __typename: 'SbaPppApplicantOwnershipType',
    });

  const deleteOwner = (entity: SbaPppApplicantOwnershipType) =>
    generated.removeEntity('sbapppapplicantownershipSet', entity);

  return {
    ...generated,
    onInstitutionChange,
    onLoanFinancialsChange,
    addOwner,
    deleteOwner,
  };
};

const RoundTwoPublicLoanApplicationFormContainer = compose(
  withRouteParams([RouteParams.institutionTicker, RouteParams.institutionJob]),
  PublicInstitutionQuery,
  PublicIsValidJobQuery,
  withLoadingIndicator('loading'),
  buildMockedFormQueryRefetch(
    'SBA_PPP_ROUND_TWO_FIRST_DRAW',
    onboardingMethod,
    RouteTable.application.toSbaPppLoanApplicationSuccess,
    'Save Successful!',
  ),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  withProps((props: any) => ({
    mergeApplications: merge,
  })),
  connect(PublicSbaPppLoanApplicationRedux.mapStateToProps, mapDispatchToProps),
  FormMediator({
    formId: 'RoundTwoSbaPppLoanApplicationForm',
    baseObjectTypeName: 'SbaPppLoanApplication',
    // postSaveRoutePath: RouteTable.application.toSbaPppLoanApplicationSuccess,
    disableAlerts: true,
    disableFrame: true,
    blurEventForm: true,
  }),
)(RoundTwoPublicSbaPppLoanApplicationForm);

export default RoundTwoPublicLoanApplicationFormContainer;
