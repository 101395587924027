import { fromJS } from 'immutable';
import { entitiesValidator, flagManyDirty, getKeyPath } from '../methods';
import { EntitiesPayloadAction } from '../types';

/* eslint-disable import/prefer-default-export */

/**
 * Add entities to the collection at the specified keyPath
 * @param {*} state
 * @param {*} action
 */
export function handleAddEntities(
  state: any,
  action: EntitiesPayloadAction,
): Record<string, any> {
  const { keyPath, entities } = action.payload;
  const path = getKeyPath(keyPath);

  entitiesValidator(entities);
  const pending = flagManyDirty(entities);

  const collection = state.getIn(path).toJS();
  return state.setIn(path, fromJS([...collection, ...pending]));
}
