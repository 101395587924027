import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { DealOrganizationsQueryDefinition } from './definitions';
import { DealInstitutionType } from 'types';

const DealOrganizationsQuery = standardQueryBuilder(
  DealOrganizationsQueryDefinition,
  {
    typename: 'DealOrganizations',
    disableCache: true,
    skip: ({ dealId }: { dealId: string | null | undefined }) => !dealId,
    variables: ({ dealId }: { dealId: string | null | undefined }) => ({
      dealId,
    }),
    results: (data: {
      dealOrganizations: Array<DealInstitutionType> | null | undefined;
    }) => {
      const { dealOrganizations } = data;
      return { dealOrganizations };
    },
  },
);

export default DealOrganizationsQuery;
