import * as React from 'react';
import { ThemeProvider } from '@loanstreet-usa/design-system';
import ActiveWindowObserver from '../../graphql/ActiveWindowObserver';
import authenticationRoutes from '../../routing/routes/AuthenticationRoutes';
import DataroomPdfViewer from '../pdfViewer/DataroomPdfViewer/DataroomPdfViewerContainer';
import TopbarNavigation from './topbarNavigation/TopbarNavigation';
import SidebarMenu from './sidebarMenu/SidebarMenu';
import AlertSystem from './alerts/AlertSystemContainer';
import { useAuth0, GlobalPermissionPerspective } from 'security';
import applicationRoutes from 'routing/routes/ApplicationRoutes';
import { RoutePortal } from 'routing';

import './ApplicationContext.scss';

const AuthenticatedBody = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  return (
    <GlobalPermissionPerspective>
      <div className="ApplicationContext">
        <SidebarMenu />
        <div className="ApplicationContext__Main">
          <TopbarNavigation />
          <div className="ApplicationRoutes">
            <RoutePortal context={applicationRoutes} />
          </div>
        </div>
        <ActiveWindowObserver />
        <AlertSystem />
        <DataroomPdfViewer />
      </div>
    </GlobalPermissionPerspective>
  );
};

const rootContext = {
  routes: [
    ...authenticationRoutes.routes,
    { component: AuthenticatedBody, path: '/' },
  ],
};
const App = () => (
  <ThemeProvider>
    <RoutePortal context={rootContext} />
  </ThemeProvider>
);

export default App;
