export interface ParseDecimalOptions {
  roundMethod?: // roundHalfUp
  /** Rounds 5 up (away from 0) */
  | 'roundHalfUp'
    /** Round 5 down */
    | 'roundDown';
  numDecimals?: number; // 2
}

/**
 * Takes a number of string input, and returns a number
 * Number can be rounded by passing in options object
 *  */
const parseDecimal = (
  input: string | number | null,
  options?: ParseDecimalOptions,
) => {
  if (!input) {
    return parseFloat('0');
  }
  const floatInput = typeof input === 'string' ? parseFloat(input) : input;
  if (!options) {
    return floatInput;
  }
  const { roundMethod = 'roundHalfUp', numDecimals = 2 } = options;

  if (roundMethod === 'roundDown') {
    return (
      Math.floor(floatInput * Math.pow(10, numDecimals)) /
      Math.pow(10, numDecimals)
    );
  }
  return (
    Math.round(floatInput * Math.pow(10, numDecimals)) /
    Math.pow(10, numDecimals)
  );
};
export default parseDecimal;
