import * as React from 'react';
import uuid from 'uuid/v4';
import TrancheGroup from '../TrancheGroup';
import { LoanTrancheFormProps } from '../../types';
import GuarantorFields from './GuarantorFields';
import { Button } from 'components';
import { LoanTrancheGuarantorType } from 'types';
import { KeyPath } from 'lsredux';

import './GuarantorFields.scss';

type GuarantorFieldsCollectionProps = LoanTrancheFormProps & {
  addEntity: (keyPath: KeyPath, entity: any) => void;
  data: LoanTrancheGuarantorType | null | undefined;
};

class GuarantorFieldsCollection extends React.Component<
  GuarantorFieldsCollectionProps
> {
  newGuarantorId: string;

  constructor(props: GuarantorFieldsCollectionProps) {
    super(props);
    this.newGuarantorId = uuid();
  }

  handleAddGuarantor = () => {
    // Generate a new id for the new guarantor
    this.newGuarantorId = uuid();

    this.props.addEntity('loantrancheguarantorSet', {
      isPerson: true,
      id: this.newGuarantorId,
      __typename: 'LoanTrancheGuarantorType',
    });
  };

  canAddGuarantor = () => {
    const {
      data: { loantrancheguarantorSet = [] },
    } = this.props;

    const lastGuarantor: LoanTrancheGuarantorType | null | undefined =
      loantrancheguarantorSet.length > 0
        ? loantrancheguarantorSet[loantrancheguarantorSet.length - 1]
        : null;

    return (
      lastGuarantor &&
      (lastGuarantor.addressLine1 ||
        lastGuarantor.addressLine2 ||
        lastGuarantor.association ||
        lastGuarantor.guarantorName ||
        lastGuarantor.city ||
        lastGuarantor.contactPhoneNumber ||
        lastGuarantor.creditScore ||
        lastGuarantor.customLiabilityText ||
        lastGuarantor.contactEmail ||
        lastGuarantor.entityType ||
        lastGuarantor.firstName ||
        lastGuarantor.formOfGuarantee ||
        lastGuarantor.guarantorId ||
        lastGuarantor.lastName ||
        lastGuarantor.liabilitySharing ||
        lastGuarantor.guaranteeLimit ||
        lastGuarantor.customGuaranteeText ||
        lastGuarantor.notes ||
        lastGuarantor.state ||
        lastGuarantor.zipCode)
    );
  };

  renderGuarantors() {
    const {
      data: { loantrancheguarantorSet = [] },
    } = this.props;

    if (loantrancheguarantorSet.length > 0) {
      return loantrancheguarantorSet.map<any>((e: LoanTrancheGuarantorType) => (
        <GuarantorFields key={e.id} {...this.props} data={e} />
      ));
    }

    return (
      <GuarantorFields
        {...this.props}
        data={null}
        newGuarantorId={this.newGuarantorId}
      />
    );
  }

  render() {
    return (
      <TrancheGroup>
        {this.renderGuarantors()}
        <div>
          <Button.Text
            disabled={!this.canAddGuarantor()}
            label="+ Add New Guarantor"
            onClick={this.handleAddGuarantor}
          />
        </div>
      </TrancheGroup>
    );
  }
}

export default GuarantorFieldsCollection;
