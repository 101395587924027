import { getAuthenticatedClient } from '../authenticatedClient';
import ResendInvitationMutationDefinition from './definitions/ResendInvitation';
import { invariant } from 'utils';

function ResendInvitationMutation(invitationId: string) {
  invariant(invitationId, 'Invitation key is required');
  const client = getAuthenticatedClient();
  invariant(client, 'Failed to create Public Client');

  return client.mutate<any>({
    mutation: ResendInvitationMutationDefinition,
    variables: {
      id: invitationId,
    },
  });
}

export default ResendInvitationMutation;
