import { gql } from '@apollo/client';
import {
  InstitutionShallowFields,
  DealInstitutionBasicFields,
  UserShallowFields,
} from '../fragments';
import { getAuthenticatedClient } from '../authenticatedClient';

const DealNameSearchQueryDefintion = gql`
  query DealNameSearch($searchValue: String) {
    results: dealNameSearch(name: $searchValue) {
      ... on InstitutionType {
        ...InstitutionShallowFields
      }
      ... on UserType {
        ...UserShallowFields
        institution {
          ...DealInstitutionBasicFields
        }
      }
    }
  }
  ${UserShallowFields}
  ${DealInstitutionBasicFields}
  ${InstitutionShallowFields}
`;

const DealNameSearch = async (searchValue: string) => {
  const variables: {
    [key: string]: any;
  } = { searchValue };
  const res = await getAuthenticatedClient().query({
    query: DealNameSearchQueryDefintion,
    variables,
    fetchPolicy: 'network-only',
  });

  return {
    ...res,
    data: res.data,
  };
};

export default DealNameSearch;
