import { gql } from '@apollo/client';
import {
  DealClosingWorkflowShallowFields,
  DealClosingWorkflowStepShallowFields,
  DealShallowFields,
  LoanFundingShallowFields,
  SbaPppLenderClosingWorkflowQuestionsBasicFields,
  SbaPppRoundTwoLenderApplicationShallowFields,
  SbaOriginationStateShallowFields,
} from '../fragments';
import { DocumentNode } from '../types';
import { getAuthenticatedClient } from '../authenticatedClient';
import formQueryBuilder from './queryBuilders/formQueryBuilder';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { DealClosingWorkflowType } from 'types';

type Props = { dealId: string | null | undefined };

const DealClosingWorkflowQueryDefinition: DocumentNode = gql`
  query DealQuery($id: ID) {
    deal(id: $id) {
      ...DealShallowFields
      folders {
        name
        value
      }
      dealClosingWorkflow {
        ...DealClosingWorkflowShallowFields
        steps {
          ...DealClosingWorkflowStepShallowFields
          bodyLinks
        }
        loanfunding {
          ...LoanFundingShallowFields
        }
      }
      sbappplenderclosingworkflowquestions {
        ...SbaPppLenderClosingWorkflowQuestionsBasicFields
      }
      sbaPppRoundTwoLenderApplication {
        ...SbaPppRoundTwoLenderApplicationShallowFields
      }
      sbaoriginationstate {
        ...SbaOriginationStateShallowFields
        errors
      }
    }
  }
  ${DealShallowFields}
  ${DealClosingWorkflowShallowFields}
  ${DealClosingWorkflowStepShallowFields}
  ${LoanFundingShallowFields}
  ${SbaPppLenderClosingWorkflowQuestionsBasicFields}
  ${SbaPppRoundTwoLenderApplicationShallowFields}
  ${SbaOriginationStateShallowFields}
`;

export const DealClosingWorkflowStandardQuery = standardQueryBuilder(
  DealClosingWorkflowQueryDefinition,
  {
    typename: 'Deal',
    includePollingMethods: true,
    addFetchMethod: true,
    disableCache: true,
    skip: ({ dealId }: Props) => !dealId,
    variables: ({ dealId }: Props) => ({ id: dealId }),
    results: (data: { deal: DealClosingWorkflowType | null | undefined }) => {
      const { deal } = data;
      return { deal };
    },
  },
);

const DealClosingWorkflowFormQuery = formQueryBuilder(
  DealClosingWorkflowQueryDefinition,
  {
    typename: 'Deal',
    skip: ({ dealId }: Props) => !dealId,
    variables: ({ dealId }: Props) => ({ id: dealId }),
    results: (data: { deal: DealClosingWorkflowType | null | undefined }) => {
      const { deal } = data;
      return deal;
    },
  },
);

export const DealClosingWorkflowFetchMethod = async ({ id }) => {
  const variables = { id };
  const res = await getAuthenticatedClient().query({
    query: DealClosingWorkflowQueryDefinition,
    variables,
    fetchPolicy: 'network-only',
  });

  return {
    ...res,
    data: res.data.deal,
  };
};

export default DealClosingWorkflowFormQuery;
