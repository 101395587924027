import { gql } from '@apollo/client';
import {
  SbaPppApplicantOwnershipShallowFields,
  SbaPppInstitutionShallowFields,
  SbaPppLoanFinancialsShallowFields,
  SbaPppLoanApplicationShallowFields,
} from '../fragments';
import { DocumentNode } from '../types';
import formQueryBuilder from './queryBuilders/formQueryBuilder';
import { SbaPppLoanApplicationType } from 'types';

/* eslint-disable */
const PrivateSbaPppFormQueryDefinition: DocumentNode = gql`
  query PrivateSbaPppLoanApplicationQuery($id: ID) {
    privateSbaPppLoanApplication(id: $id) {
      ...SbaPppLoanApplicationShallowFields
      sbapppapplicantownershipSet {
        ...SbaPppApplicantOwnershipShallowFields
        race
      }
      sbapppinstitution {
        ...SbaPppInstitutionShallowFields
      }
      sbappploanfinancials {
        purposeOfLoan
        ...SbaPppLoanFinancialsShallowFields
      }
    }
  }
  ${SbaPppLoanApplicationShallowFields}
  ${SbaPppInstitutionShallowFields}
  ${SbaPppApplicantOwnershipShallowFields}
  ${SbaPppLoanFinancialsShallowFields}
`;

export const PrivateSbaApplicationFormQuery = formQueryBuilder(
  PrivateSbaPppFormQueryDefinition,
  {
    typename: 'SbaPppLoanApplicationType' as any,
    disableCache: true,
    skip: (props: {
      dealId: string | null | undefined;
      isInitialSecondDraw: boolean | null | undefined;
    }) => {
      const { dealId, isInitialSecondDraw } = props;
      return !dealId;
    },
    variables: (props: { dealId: string | null | undefined }) => {
      const { dealId } = props;
      return { id: dealId };
    },
    refetchVariables: (props: { dealId: string | null | undefined }) => {
      const { dealId } = props;
      return { id: dealId };
    },
    results: (data: {
      privateSbaPppLoanApplication:
        | SbaPppLoanApplicationType
        | null
        | undefined;
    }) => {
      const { privateSbaPppLoanApplication } = data;
      return privateSbaPppLoanApplication;
    },
  },
);
