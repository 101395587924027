import * as React from 'react';
import { compose } from 'recompose';
import { DataRoomBase } from '../types';
import CreateFolderModal from '../createFolderModal/CreateFolderModalContainer';
import { getRootFolderId } from '../utils/sharedMethods';
import { Button } from 'components';
import { DealType } from 'types';
import { RouteParams, withRouteParams } from 'routing';
import { DealQuery, DataroomQuery } from 'lsgql';
import { MountGuard, DealPerspectivePermissionSets } from 'security';

type Props = {
  dataroomFiles: Array<DataRoomBase>;
  deal: DealType;
  loading?: boolean;
};

type State = {
  createFolderModalOpen: boolean;
  topLevelFolderId: string;
};

class DataroomContextMenu extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      topLevelFolderId: getRootFolderId(props),
      createFolderModalOpen: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.deal !== this.props.deal) {
      this.setState({
        topLevelFolderId: getRootFolderId(nextProps),
      });
    }
  }

  handleClick = () =>
    this.setState({
      createFolderModalOpen: true,
    });

  handleOnCloseFolderModal = () =>
    this.setState({
      createFolderModalOpen: false,
    });

  render() {
    const { deal, loading, dataroomFiles } = this.props;
    if (loading) return null;
    const { topLevelFolderId, createFolderModalOpen } = this.state;
    const siblingNames =
      createFolderModalOpen && topLevelFolderId
        ? dataroomFiles
            .filter(
              file =>
                file.parent &&
                file.parent.id === topLevelFolderId &&
                file.fileType === 'Folder',
            )
            .map(s => s.name)
        : [];

    return (
      <MountGuard
        permission={DealPerspectivePermissionSets.administer_dataroom}
      >
        <div className="ActionButtons--Dataroom">
          <Button
            className="ActionButtons__Item"
            label="Add Folder"
            onClick={this.handleClick}
          />
          {this.state.createFolderModalOpen && (
            <CreateFolderModal
              dealId={deal.id}
              folderId={null}
              formId="CreateRootFolderModal"
              isOpen={this.state.createFolderModalOpen}
              isSubfolder={false}
              onClose={this.handleOnCloseFolderModal}
              parentFolderId={this.state.topLevelFolderId}
              siblingNames={siblingNames}
            />
          )}
        </div>
      </MountGuard>
    );
  }
}

export default compose(
  withRouteParams([RouteParams.dealId]),
  DealQuery,
  DataroomQuery,
)(DataroomContextMenu);
