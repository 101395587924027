import { ReduxKeys } from '../directory';
import {
  LifecycleMethods,
  Factory,
  MultiEntityFactory,
  FormReduxArgs,
} from './types';
import generateDispatchActionsFor from './dispatchFactory';
import { createFormReducer } from './reducerFactory';
import createMultiEntityReducer from './multiEntityReducerFactory';
import createMultiEntityActions from './multiEntityActionsFactory';
import { invariant } from 'utils';
import { BaseType } from 'types';

type ReduxForm<TOut extends BaseType> = {
  actions: Factory<TOut>;

  mapStateToProps: (state: any) => { rawData: any };

  reducer: (state: any, action: any) => any;
};

function verifyInput<TOut extends BaseType, TIn extends BaseType>(
  reduxKey: ReduxKeys,
  initialState: TIn,
  lifecycleMethods: LifecycleMethods<TOut>,
  args?: FormReduxArgs<TOut, TIn>,
) {
  invariant(reduxKey, 'key is required');
  invariant(initialState, 'initialEntityState is required');
  invariant(lifecycleMethods, 'lifecycleMethods is required');

  if (args && args.enableAutoSave) {
    invariant(lifecycleMethods.fetch, 'Auto-Save forms require a fetch method');
  }

  if (args && args.enableAutoValidate) {
    invariant(
      lifecycleMethods.validators,
      'Auto-Validate forms require a validator method',
    );
  }
}

export function createForm<TOut extends BaseType, TIn extends BaseType>(
  reduxKey: ReduxKeys,
  initialState: TIn,
  lifecycleMethods: LifecycleMethods<TOut>,
  args?: FormReduxArgs<TOut, TIn>,
): ReduxForm<TOut> {
  verifyInput<TOut, TIn>(reduxKey, initialState, lifecycleMethods, args);

  const actions: Factory<TOut> = generateDispatchActionsFor(
    reduxKey,
    lifecycleMethods,
  );

  // reducer
  const reducer = createFormReducer<TOut, TIn>(reduxKey, initialState, args);

  const keyPath = Array.isArray(reduxKey) ? reduxKey : [reduxKey];

  return {
    actions,
    reducer,
    mapStateToProps: (state: any) => ({
      rawData: state.getIn(['nonpersisted', ...keyPath]),
    }),
  };
}

type MultiEntityReduxForm<TOut extends BaseType> = {
  actions: MultiEntityFactory<TOut>;
  reducer: (state: any, action: any) => any;
};

export function createMultiEntityForm<
  TOut extends BaseType,
  TIn extends BaseType
>(
  reduxKey: ReduxKeys,
  initialState: TIn,
  lifecycleMethods: LifecycleMethods<TOut>,
  args?: FormReduxArgs<TOut, TIn>,
): MultiEntityReduxForm<TOut> {
  verifyInput<TOut, TIn>(reduxKey, initialState, lifecycleMethods, args);

  const actions: MultiEntityFactory<TOut> = createMultiEntityActions(
    reduxKey,
    lifecycleMethods,
  );

  const reducer = createMultiEntityReducer<TOut, TIn>(
    reduxKey,
    initialState,
    args,
  );

  return {
    actions,
    reducer,
  };
}
