import { RouteTokens, RouteParams } from './tokens';
import { ID } from 'types';

/* eslint-disable import/prefer-default-export */
export const RouteTable = {
  params: RouteParams,

  application: {
    to404: '/404',
    toLogin: '/login',
    toCreateDeal: '/deal/create',
    toHome: '/',
    toDebug: '/debug',
    toProfile: '/profile',
    toHelp: '/help',
    toReports: '/reports',

    toSbaPppApplicationInstructions: '/sba-ppp/instructions',
    toSbaPppSupportingDocuments: '/sba-ppp/supporting-documents',

    toSecondDrawApp: () => '/second-draw',
    toInstitution: (institutionId?: ID) =>
      `/institution/${institutionId || RouteTokens.institutionId}/`,

    toAcceptInvitation: (invitationKey?: string | null) =>
      `/invitation/${invitationKey || RouteTokens.invitationKey}`,

    toSbaPppLoanApplicationOnboardingDisabled: (
      institutionTicker?: string,
      institutionJob?: string,
    ) =>
      `/sba-ppp/institution/${institutionTicker ||
        RouteTokens.institutionTicker}/job/${institutionJob ||
        RouteTokens.institutionJob}/onb/${RouteTokens.disableOnboarding}`,

    toSbaPppLoanApplication: (
      institutionTicker?: string,
      institutionJob?: string,
    ) =>
      `/sba-ppp/institution/${institutionTicker ||
        RouteTokens.institutionTicker}/job/${institutionJob ||
        RouteTokens.institutionJob}`,

    toSbaPppLoanApplicationSuccess: (sbaPppApplicationId?: string) =>
      `/sba-ppp/${sbaPppApplicationId ||
        RouteTokens.sbaPppApplicationId}/success`,
  },

  deal: {
    toDeal: (dealId?: ID) => `/deal/${dealId || RouteTokens.dealId}/`,

    toDealEdit: (dealId?: ID | null | undefined) =>
      `/deal/${dealId || RouteTokens.dealId}/edit`,

    toLoanOverviewSummary: (dealId?: ID | null | undefined) =>
      `/deal/${dealId || RouteTokens.dealId}/overview`,

    toLoanOverviewForgiveness: (dealId?: ID | null | undefined) =>
      `/deal/${dealId || RouteTokens.dealId}/overview/forgiveness`,

    toDealOrderBook: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/sales/orderbook`,

    toDealCalendar: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/calendar`,
    toCalendarTaskView: (
      dealId?: ID | null | undefined,
      taskId?: ID | null | undefined,
    ) =>
      `/deal/${dealId || RouteTokens.dealId}/task/${taskId ||
        RouteTokens.taskId}`,

    toDealPeople: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/people`,
    toDealPeopleMyTeam: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/people/myteam`,
    toDealPeopleCoLenders: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/people/colenders`,
    toDealPeopleServicingAgents: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/people/servicingagents`,
    toDealPeopleLeadLender: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/people/leadlender`,
    toDealPeopleBorrower: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/people/borrower`,

    toDealTermSheet: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/termsheet`,

    toDealDataroom: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/dataroom`,

    toDealReports: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/report`,

    toDealSbaApplication: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/sba-application`,

    toDealSbaLenderApplication: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/sba-lender-application`,

    toDealFees: (dealId?: ID) => `/deal/${dealId || RouteTokens.dealId}/fees`,

    toDealLoanTrancheEdit: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/tranches`,

    toDealInstitutionInviteForm: (dealId?: ID, institutionId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/institution/${institutionId ||
        RouteTokens.institutionId}/invite`,

    toDealLenderInviteForm: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/lender/invite`,

    toDealBorrowerInviteForm: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/borrower/invite`,

    toDealLenderAccessRequests: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/requests`,
    toDealClosingWorkflow: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/closing`,
    toDealLenderRegistryForm: (dealId?: string) =>
      `/deal/${dealId || RouteTokens.dealId}/sales/lenderregistry`,
    toDealSales: (dealId?: string) =>
      `/deal/${dealId || RouteTokens.dealId}/sales`,
    toDealPayments: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/payments`,
    toDealPaymentActivity: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/payments/activity`,
    toDealPaymentSchedule: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/payments/schedule`,
    toDealPaymentRecord: (dealId?: ID, paymentRecordId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/payment/${paymentRecordId ||
        RouteTokens.paymentRecordId}`,
    toGeneralNoticeCreate: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/notice/new`,
    toGeneralNoticeFork: (
      generalNoticeId?: ID | null | undefined,
      props?: any | null | undefined,
    ) =>
      `/deal/${(props && props.dealId) ||
        RouteTokens.dealId}/notice/${generalNoticeId ||
        RouteTokens.generalNoticeId}`,

    toSbaPppRoundTwoFirstDrawForm: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/first-draw`,
    toSbaPppRoundTwoSecondDrawForm: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/second-draw`,

    toSbaPppForgivenessWorkflow: (dealId?: ID) =>
      `/deal/${dealId || RouteTokens.dealId}/forgiveness-workflow`,

    toSbaPppForgivenessWorkflowInstructions: (dealId?: ID) =>
      `/deal/${dealId ||
        RouteTokens.dealId}/forgiveness-workflow/instructions/`,

    toSbaPppForgivenessWorkflowDocumentation: (dealId?: ID) =>
      `/deal/${dealId ||
        RouteTokens.dealId}/forgiveness-workflow/documentation`,

    toSbaPppForgivenessWorkflowCalculation: (dealId?: ID) =>
      `/deal/${dealId ||
        RouteTokens.dealId}/forgiveness-workflow/forgiveness-calculation`,

    toSbaPppForgivenessWorkflowLoanInformation: (dealId?: ID) =>
      `/deal/${dealId ||
        RouteTokens.dealId}/forgiveness-workflow/loan-information`,
  },

  institution: {
    toEdit: (institutionId?: ID, institutionSection?: string) =>
      `/profile/institution/${institutionId ||
        RouteTokens.institutionId}/edit/${institutionSection ||
        RouteTokens.institutionSection}`,

    toInvite: (institutionId?: ID) =>
      `/institution/${institutionId || RouteTokens.institutionId}/invite`,
  },

  user: {
    toUserProfile: '/profile/user',

    toUserEdit: (userId?: ID) => `/user/${userId || RouteTokens.userId}`,
  },
};
