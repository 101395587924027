import uuid from 'uuid/v4';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import {
  SbaPppForgivenessLoanInformationMutationMethods,
  SbaPppForgivenessLoanInformationValidator,
  DealMutationMethods,
  PrivateSbaPppInstitutionMutationMethods,
  LoanFundingMutationMethods,
} from '../../../graphql';
import { DealType } from 'types';

const initialDeal: DealType = {
  id: uuid(),
  __typename: 'DealType',
};

const lifecycleMethods = {
  mutations: {
    ...SbaPppForgivenessLoanInformationMutationMethods,
    ...DealMutationMethods,
    ...PrivateSbaPppInstitutionMutationMethods,
    ...LoanFundingMutationMethods,
  },
  validators: {
    SbaPppForgivenessLoanInformation: SbaPppForgivenessLoanInformationValidator,
  },
};

const sbaPppForgivnessLoanInformationFormRedux = createForm<DealType, DealType>(
  Directory.SbaPppForgivenessLoanInformationKey,
  initialDeal,
  lifecycleMethods,
);

export default sbaPppForgivnessLoanInformationFormRedux;
