import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import { mutationBuilder } from './builders';

export type SbaSecondDrawOnboardingInput = {
  id: string;
  email: string;
  ticker: string;
};

/* eslint-disable */
const CreateSbaSecondDrawOnboardingMutationDefinition: DocumentNode = gql`
  mutation CreateSbaSecondDrawOnboardingMutation(
    $sbaSecondDrawOnboardingData: SbaSecondDrawOnboardingInput
  ) {
    createSbaSecondDrawOnboarding(
      sbaSecondDrawOnboardingData: $sbaSecondDrawOnboardingData
    ) {
      ok
      sbaSecondDrawOnboarding {
        id
      }
    }
  }
`;
/* eslint-enable */

const SbaSecondDrawOnboardingMutations = {
  createSbaSecondDrawOnboarding: mutationBuilder<
    SbaSecondDrawOnboardingInput,
    SbaSecondDrawOnboardingInput
  >(CreateSbaSecondDrawOnboardingMutationDefinition, {
    build: mutate => sbaSecondDrawOnboardingData =>
      mutate({ variables: { sbaSecondDrawOnboardingData } }),
  }),
};

export default SbaSecondDrawOnboardingMutations;
