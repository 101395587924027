import {
  LenderRegistryFormTrancheType,
  LoanTrancheOwnershipPortionInputWithDisplayInfo,
  InstitutionOwnershipPortionTypeWithDisplay,
  SelectOptionType,
  DealInstitutionType,
} from 'types';

import { invariant } from 'utils';

export type DealInstitutionsOptionsData = {
  subsidiaryMap: {
    [key: string]: SelectOptionType[];
  };
  topLevelInstitutions: SelectOptionType[];
};

export type OwnershipPortionData = {
  subsidiaryMap: {
    [key: string]: LoanTrancheOwnershipPortionInputWithDisplayInfo[];
  };
  topLevelInstitutions: LoanTrancheOwnershipPortionInputWithDisplayInfo[];
};

export function getTotalAmountFromTranche(
  tranche: LenderRegistryFormTrancheType,
) {
  const { commitmentDrawType } = tranche;

  switch (commitmentDrawType) {
    case 'SINGLE_DRAW':
      return tranche.initialDrawAmount;
    case 'MULTIPLE_DRAW':
      return tranche.loantranchemultidrawdata
        ? tranche.loantranchemultidrawdata.commitment
        : null;
    case 'REVOLVER':
      return tranche.loantrancherevolverswinglinelocdata
        ? tranche.loantrancherevolverswinglinelocdata.facilityMaxSize
        : null;
    case 'SWINGLINE':
      return tranche.loantrancherevolverswinglinelocdata
        ? tranche.loantrancherevolverswinglinelocdata.facilityMaxSize
        : null;
    case 'LETTER_OF_CREDIT':
      return tranche.loantrancherevolverswinglinelocdata
        ? tranche.loantrancherevolverswinglinelocdata.facilityMaxSize
        : null;
    default:
      return null;
  }
}

function getOption(obj: DealInstitutionType): SelectOptionType {
  return { text: obj.name || '-', value: obj.id };
}

export function getOptionsData(
  dealInstitutions: DealInstitutionType[],
): DealInstitutionsOptionsData {
  const topLevelInstitutions = [];
  const subsidiaryMap: {
    [key: string]: SelectOptionType[];
  } = {};
  (dealInstitutions || []).forEach(dealInstitution => {
    const dealInstitutionOption: SelectOptionType = getOption(dealInstitution);
    topLevelInstitutions.push(dealInstitutionOption);
    (dealInstitution.subsidiaries || []).forEach(subsidiary => {
      const subsidiaryOption: SelectOptionType = getOption(subsidiary);

      if (subsidiaryMap[dealInstitution.id]) {
        subsidiaryMap[dealInstitution.id].push(subsidiaryOption);
      } else {
        subsidiaryMap[dealInstitution.id] = [subsidiaryOption];
      }
    });
  });

  return { topLevelInstitutions, subsidiaryMap };
}

export function getLenderOptions(
  dealInstitutionOptions: SelectOptionType[],
  loantrancheinstitutionownershipportionSet: InstitutionOwnershipPortionTypeWithDisplay[],
) {
  const excludeIdSet = new Set<string>(
    (loantrancheinstitutionownershipportionSet || []).map<string>(
      institutionPortion => institutionPortion.institutionId,
    ),
  );

  const filteredOptions: SelectOptionType[] = dealInstitutionOptions.filter(
    option => !excludeIdSet.has(option.value),
  );
  return filteredOptions;
}

export function getSubsidiaryOptions(
  institutionPortion: InstitutionOwnershipPortionTypeWithDisplay,
  subsidiaryParentMap: {
    [key: string]: SelectOptionType[];
  },
) {
  if (institutionPortion) {
    const excludeIdSet = new Set(
      (institutionPortion.loantrancheownershipportionSet || []).map<string>(
        (ownershipPortion: any) => ownershipPortion.institutionId,
      ),
    );
    // We have to add the institution itself as an option incase the user wants to allocate funds to the parent
    const subsidiaries =
      subsidiaryParentMap[institutionPortion.institutionId] || [];
    const filteredOptions: SelectOptionType[] = subsidiaries.filter(
      option => !excludeIdSet.has(option.value),
    );

    if (!excludeIdSet.has(institutionPortion.institutionId)) {
      filteredOptions.push({
        text: institutionPortion.institutionName,
        value: institutionPortion.institutionId,
      });
    }
    return filteredOptions;
  }
  return [];
}

export function getPortionFromAmount(total: string, amount: string): string {
  const totalNumber = Number(total);
  const amountNumber = Number(amount);

  invariant(!Number.isNaN(totalNumber), 'total commitment is not a number!');
  invariant(!Number.isNaN(amountNumber), 'amount is not a number!');

  return (amountNumber / totalNumber).toFixed(6);
}

export function getAmountFromPortion(total: string, portion: string): string {
  const totalNumber = Number(total);
  const portionNumber = Number(portion);

  invariant(!Number.isNaN(totalNumber), 'total commitment is not a number!');
  invariant(!Number.isNaN(portionNumber), 'portion is not a number!');
  return (portionNumber * totalNumber).toFixed(2);
}

export function calculateUnallocatedPortion(portion: string) {
  const portionNumber = Number(portion);
  invariant(!Number.isNaN(portionNumber), 'Portion is not a number!');
  return (1 - portionNumber).toFixed(6);
}

export function calculateUnallocatedAmount(total: string, current: string) {
  const totalNumber = Number(total);
  const currentNumber = Number(current);
  invariant(!Number.isNaN(totalNumber), 'Total is not a number!');
  invariant(!Number.isNaN(currentNumber), 'Current is not a number!');

  return (totalNumber - currentNumber).toFixed(2);
}
