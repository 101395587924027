import * as React from 'react';
import { TransitionButton } from '../../TransitionButton';
import { getCompleteFormsError } from '../Resources';
import { StateMap } from '../../Resources';
import { ActionButtonProps } from './ActionButtonProps';
import { Button, RouteTo } from 'components';
import { RouteTable } from 'routing';

const successToast =
  'Your application has been submitted to your lender for review.';

export function BorrowerActionButtons(props: ActionButtonProps) {
  const { deal, sbaPppForgivenessWorkflow } = props;
  const { isLender, state, isSFormEligible } = sbaPppForgivenessWorkflow;

  if (isLender || state === StateMap.APPROVED) {
    return null;
  }

  if (state === StateMap.UNSUBMITTED) {
    return (
      <div className="CtaButtons__Wrapper">
        <div>
          <TransitionButton
            errorToast={getCompleteFormsError(isSFormEligible)}
            label="Submit Application For Review"
            successToast={successToast}
            targetState={StateMap.IN_REVIEW}
            workflow={sbaPppForgivenessWorkflow}
          />
        </div>
        <div>
          <Button.Secondary
            as={RouteTo}
            label="Next: Loan Information"
            to={RouteTable.deal.toSbaPppForgivenessWorkflowLoanInformation(
              deal.id,
            )}
          />
        </div>
      </div>
    );
  }

  if (state === StateMap.CHANGES_REQUESTED) {
    return (
      <TransitionButton
        errorToast={getCompleteFormsError(isSFormEligible)}
        label="Resubmit Application For Review"
        successToast={successToast}
        targetState={StateMap.CHANGE_SUBMITTED}
        workflow={sbaPppForgivenessWorkflow}
      />
    );
  }

  return null;
}
