import React from 'react';
import { Text, Button } from 'components';
import { HelpBoxCallbackType } from '../../workflowList/WorkflowHelpBox';
import ResourceLink from './ResourceLink';

const CalculationHelpBox: any = {
  standardMain: {
    header: 'Cost calculations help',
    body: (
      <>
        <Text>
          Fields on this form refer to the Schedule A form and Forgiveness
          Calculation form found on pages 3 and 4 of the{' '}
          <ResourceLink.InstructionsForBorrowerForm />
        </Text>
        <Text>
          You may also use this Excel worksheet to organize your work and
          calculate your totals:
        </Text>
        <ResourceLink.StandardExcel
          icon
          label="Excel Worksheet (for standard form)"
        />
      </>
    ),
  },
  ezMain: {
    header: 'Cost calculations help',
    control: function ezMainHelpBox(
      onCheckQualifications: HelpBoxCallbackType,
    ) {
      return (
        <>
          <Text>
            Fields on this form refer to the the SBA’s{' '}
            <ResourceLink.InstructionsForBorrowerEzForm />
          </Text>
          <Text>
            You may also use this Excel worksheet to organize your work and
            calculate your totals:
          </Text>
          <ResourceLink.EzExcel icon label="Excel Worksheet (for EZ form)" />
          <Text>
            Not sure if you qualify for the EZ application? Use the standard
            form. The calculated amount won’t be impacted by the form.
          </Text>
          <Button.Text
            className="CheckQualificationsButton"
            label="Check EZ form qualifications"
            onClick={onCheckQualifications}
          />
        </>
      );
    },
  },

  lte100: {
    header: 'Employee compensation of $100,000 or less',
    body: (
      <Text>
        Fields in this section refer to boxes 1-3 of the Table 1 of the PPP
        Schedule A Worksheet, on the <ResourceLink.StandardForm /> or in Table 1
        of the <ResourceLink.StandardExcel /> provided by LoanStreet.
      </Text>
    ),
  },
  gt100: {
    header: 'Employee compensation of more than $100,000',
    body: (
      <Text>
        Fields in this section refer to boxes 4-5 of the Table 2 of the PPP
        Schedule A Worksheet, on the <ResourceLink.StandardForm /> or in Table 2
        of the <ResourceLink.StandardExcel /> provided by LoanStreet
      </Text>
    ),
  },
  nonCash: {
    header: 'Non-cash compensation',
    body: (
      <Text>
        Fields in this section refer to the PPP Schedule A Form, found on the{' '}
        <ResourceLink.StandardForm />
      </Text>
    ),
  },
  ownerCompensation: {
    header: 'Compensation to owners',
    body: (
      <Text>
        Fields in this section refer to line 9 of the PPP Schedule A Form, found
        on the <ResourceLink.StandardForm />
      </Text>
    ),
  },
  payrollTotal: {
    header: 'Payroll total',
    body: (
      <Text>
        Fields in this section refer to line 1 of the Loan Forgiveness
        Calculation Form, found on the <ResourceLink.StandardForm />
      </Text>
    ),
  },
  payrollTotalEz: {
    header: 'Payroll total',
    body: (
      <>
        <Text>
          To calculate payroll costs, sum the following for the selected covered
          period:
        </Text>
        <Text>
          <strong>Cash Compensation:</strong> The sum of gross salary, wages,
          tips, commissions, paid leave for each employee.
        </Text>
        <Text>
          <strong>Benefits:</strong> Employer contributions to employee health
          insurance and retirement plans.
        </Text>
        <Text>
          <strong>Taxes:</strong> State and local taxes paid on employee
          compensation.
        </Text>
        <Text>
          <strong>Owner Compensation:</strong> Amounts paid to owners.
        </Text>
        <Text>
          Amounts above are subject to caps. For detailed instructions, refer to
          the SBA’s <ResourceLink.InstructionsForBorrowerEzForm />
        </Text>
      </>
    ),
  },
  nonPayroll: {
    header: 'Nonpayroll business payments',
    body: (
      <Text>
        Fields in this section refer to lines 2-4 of the Loan Forgiveness
        Calculation Form, found of the <ResourceLink.StandardForm />
      </Text>
    ),
  },
  fteReduction: {
    header: 'Full-Time Equivalency Reduction',
    body: (
      <Text>
        Fields in this section refer to lines 11-13 of the PPP Schedule A Form,
        found on the <ResourceLink.StandardForm />
      </Text>
    ),
  },
  forgivenessAmount: {
    header: 'Forgiveness amount',
    body: (
      <Text>
        This is the total forgiveness amount. This value is derived from lines
        5- 10 of the Loan Forgiveness Calculation Form, found on the{' '}
        <ResourceLink.StandardForm />
      </Text>
    ),
  },
  sForm: {
    header: 'Forgiveness Amount Help',
    body: (
      <>
        <Text>
          Fields on this form are used to calculate the Forgiveness Amount. For
          more information, refer to the SBA’s{' '}
          <ResourceLink.InstructionsForBorrowersSForm />
        </Text>
        <Text>
          The LoanStreet Excel worksheet can be used to organize, record and
          calculate your totals.
        </Text>
        <ResourceLink.SFormExcel
          icon
          label="Excel Worksheet (for Form 3508S)"
        />
      </>
    ),
  },
};

export default CalculationHelpBox;
