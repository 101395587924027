import { StepMap, createStepRenderer } from '../StepRenderFactory';
import BorrowerLoanApplication from './BorrowerLoanApplication';
import BorrowerSupportingDocuments from './BorrowerSupportingDocuments';
// import BorrowerAdditionalInformation from './BorrowerAdditionalInformation';
import BorrowerSubmitApplication from './BorrowerSubmitApplication';
import BorrowerNextSteps from './BorrowerNextSteps';
import BorrowerPromissoryNote from './BorrowerPromissoryNote';
import BorrowerSignApplication from './BorrowerSignApplication';

/*
Steps

aw_borrower_next_steps = "aw_borrower_next_steps"
aw_borrower_loan_application = "aw_borrower_loan_application"
aw_borrower_supporting_documents = "aw_borrower_supporting_documents"
aw_borrower_additional_information = "aw_borrower_borrower_additional_information"
aw_borrower_submit_application = "aw_borrower_submit_borrower_application"
aw_borrower_post_submit_application_next_steps = "aw_borrower_post_submit_application_next_steps"
aw_borrower_sign_application = "aw_borrower_sign_application"
aw_borrower_sign_promissory_note = "aw_borrower_sign_promissory_note"
aw_borrower_loan_funding = "aw_borrower_loan_funding"
aw_borrower_has_submitted_borrower_application = "aw_borrower_has_submitted_borrower_application"
aw_borrower_sba_submitted_next_steps = "aw_borrower_sba_submitted_next_steps"
aw_borrower_signed_promissory_note = "aw_borrower_signed_promissory_note"
*/

const stepMap: StepMap = {
  aw_borrower_loan_application: BorrowerLoanApplication,
  aw_borrower_supporting_documents: BorrowerSupportingDocuments,
  aw_borrower_borrower_additional_information: () => null,
  aw_borrower_submit_borrower_application: BorrowerSubmitApplication,
  aw_borrower_submit_application: BorrowerSubmitApplication,
  aw_borrower_next_steps: BorrowerNextSteps,
  aw_borrower_sign_promissory_note: BorrowerPromissoryNote,
  aw_borrower_sign_application: BorrowerSignApplication,
};

/**
 * Map Workflow Step slugs to the components that render them
 */
const BorrowerStepFactory = createStepRenderer(stepMap);

export default BorrowerStepFactory;
