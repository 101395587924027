import { gql } from '@apollo/client';
import {
  DealShallowFields,
  SbaPppForgivenessWorkflowShallowFields,
  SbaPppForgivenessCalculationShallowFields,
  SbaPppForgivenessLoanInformationShallowFields,
  SbaPppLoanApplicationShallowFields,
  SbaPppApplicantOwnershipShallowFields,
  SbaPppInstitutionShallowFields,
  SbaPppLoanFinancialsShallowFields,
  DealClosingWorkflowShallowFields,
  LoanFundingShallowFields,
  SbaPppForgivenessResubmitRequestShallowFields,
  SbaPppForgivenessDenialShallowFields,
  SbaPppForgivenessEzFormQualificationsShallowFields,
  SbaLoanInfoShallowFields,
  AdditionalSbaPppBorrowerInformationShallowFields,
} from '../fragments';
import { DocumentNode } from '../types';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import formQueryBuilder from './queryBuilders/formQueryBuilder';
import { DealType } from 'types';

const SbaPppForgivenessWorkflowQueryDefinition: DocumentNode = gql`
  query DealQuery($id: ID) {
    deal(id: $id) {
      ...DealShallowFields
      additionalSbaPppBorrowerInformation {
        ...AdditionalSbaPppBorrowerInformationShallowFields
      }
      sbaPppForgivenessWorkflow {
        ...SbaPppForgivenessWorkflowShallowFields
        validTransitions
        folders {
          name
          value
        }
        sbapppforgivenesscalculation {
          ...SbaPppForgivenessCalculationShallowFields
        }
        sbapppforgivenessloaninformation {
          ...SbaPppForgivenessLoanInformationShallowFields
        }
        sbapppforgivenessresubmitrequest {
          ...SbaPppForgivenessResubmitRequestShallowFields
        }
        sbapppforgivenessdenial {
          ...SbaPppForgivenessDenialShallowFields
        }
        sbapppforgivenessezformqualifications {
          ...SbaPppForgivenessEzFormQualificationsShallowFields
        }
        sbaloaninfo {
          ...SbaLoanInfoShallowFields
        }
        sbaForgivenessErrors
      }
      sbaPppLoanApplication {
        ...SbaPppLoanApplicationShallowFields
        sbapppinstitution {
          ...SbaPppInstitutionShallowFields
        }
        sbappploanfinancials {
          ...SbaPppLoanFinancialsShallowFields
        }
        sbapppapplicantownershipSet {
          ...SbaPppApplicantOwnershipShallowFields
          race
        }
      }
      dealClosingWorkflow {
        ...DealClosingWorkflowShallowFields
        loanfunding {
          ...LoanFundingShallowFields
        }
      }
    }
  }
  ${DealShallowFields}
  ${SbaPppForgivenessWorkflowShallowFields}
  ${SbaPppForgivenessCalculationShallowFields}
  ${SbaPppForgivenessLoanInformationShallowFields}
  ${SbaPppLoanApplicationShallowFields}
  ${SbaPppForgivenessResubmitRequestShallowFields}
  ${SbaPppForgivenessDenialShallowFields}
  ${SbaPppForgivenessEzFormQualificationsShallowFields}
  ${SbaLoanInfoShallowFields}
  ${SbaPppInstitutionShallowFields}
  ${SbaPppLoanFinancialsShallowFields}
  ${SbaPppApplicantOwnershipShallowFields}
  ${DealClosingWorkflowShallowFields}
  ${LoanFundingShallowFields}
  ${AdditionalSbaPppBorrowerInformationShallowFields}
`;

type Props = { dealId: string | null | undefined };

export const SbaPppForgivenessWorkflowQuery = standardQueryBuilder(
  SbaPppForgivenessWorkflowQueryDefinition,
  {
    typename: 'Deal',
    includePollingMethods: true,
    disableCache: true,
    skip: ({ dealId }: Props) => !dealId,
    variables: ({ dealId }: Props) => ({ id: dealId }),
    results: (data: { deal: DealType | null | undefined }) => {
      const { deal } = data;
      const { sbaPppForgivenessWorkflow } = deal;
      return {
        deal,
        sbaPppForgivenessWorkflow,
      };
    },
  },
);

export const SbaPppForgivenessWorkflowFormQuery = formQueryBuilder(
  SbaPppForgivenessWorkflowQueryDefinition,
  {
    typename: 'Deal',
    disableCache: true,
    skip: ({ dealId }: Props) => !dealId,
    variables: ({ dealId }: Props) => ({ id: dealId }),
    results: (data: { deal: DealType | null | undefined }) => {
      const { deal } = data;
      const { sbaPppForgivenessWorkflow, ...restOfDeal } = deal;
      const {
        folders,
        ...restOfForgivenessWorkflow
      } = sbaPppForgivenessWorkflow;
      return {
        ...restOfDeal,
        canEdit: restOfForgivenessWorkflow.canEdit,
        sbaPppForgivenessWorkflow: { ...restOfForgivenessWorkflow },
      };
    },
  },
);

const SbaPppForgivenessWorkflowFoldersQueryDefinition: DocumentNode = gql`
  query DealQuery($id: ID) {
    deal(id: $id) {
      ...DealShallowFields
      sbaPppForgivenessWorkflow {
        ...SbaPppForgivenessWorkflowShallowFields
        validTransitions
        folders {
          name
          value
        }
      }
    }
  }
  ${DealShallowFields}
  ${SbaPppForgivenessWorkflowShallowFields}
`;

export const SbaPppForgivenessWorkflowFoldersQuery = standardQueryBuilder(
  SbaPppForgivenessWorkflowFoldersQueryDefinition,
  {
    typename: 'Deal',
    includePollingMethods: true,
    disableCache: true,
    skip: ({ dealId }: Props) => !dealId,
    variables: ({ dealId }: Props) => ({ id: dealId }),
    results: (data: { deal: DealType | null | undefined }) => {
      const { deal } = data;
      const { sbaPppForgivenessWorkflow } = deal;
      return { folders: sbaPppForgivenessWorkflow.folders };
    },
  },
);
