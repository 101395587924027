import uuid from 'uuid/v4';
import { CancelLoanMutationMethods } from '../../../graphql';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import { CancelLoanType } from 'types';

const initialCancelLoan: CancelLoanType = {
  id: uuid(),
  __typename: 'CancelLoanType',
} as any;

const lifecycle = {
  mutations: CancelLoanMutationMethods,
  validators: {},
};

export default createForm<CancelLoanType, CancelLoanType>(
  Directory.CancelLoanKey,
  initialCancelLoan,
  lifecycle,
);
