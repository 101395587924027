import { fromJS } from 'immutable';
import { generateTable } from '../../generate';
import { TOGGLE_SECTION } from '../../actions/main';

import standardReducerFactory from '../standardReducer';

const open = {
  Deals: true,
  Events: true,
};

const initialState = fromJS({
  open,
  viewRange: 'week',
  showWeekends: true,
  sortBy: { column: 'Star', reverse: false },
  dealsCategoryFilterIgnoreMap: {},
  eventsCategoryFilterIgnoreMap: {},
  dealsSectionDrawerClosedMap: {},
});

const reducerMap: {
  [key: string]: (state: any, action: any) => Record<string, any>;
} = {
  ...generateTable.reducerMap('main_deals'),
};

reducerMap[TOGGLE_SECTION] = (state, action) =>
  state.setIn(
    ['open', action.payload.sectionName],
    !state.getIn(['open', action.payload.sectionName]),
  );

export default standardReducerFactory(reducerMap, initialState);
