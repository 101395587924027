/*
  Components should not need to know about business logic.
  To create coherent error displays, raiseAlert is imported.
  As a result, we need to disable no-restricted-import.
  TODO: Error handling needs to be refactored when splitting
  this off into a stand-alone component library
*/
import * as React from 'react';
import Dropzone, { FileWithPreview } from 'react-dropzone';
import { Button, Icon } from '@loanstreet-usa/design-system';
import cx from 'classnames';
import { invariant } from 'utils';
import raiseAlert from 'lsredux/raiseAlert'; // eslint-disable-line no-restricted-imports

import './UploadDropzone.scss';

type Props = {
  alternatePrompt?: string;
  alternateText?: string;
  buttonText?: string;
  className?: string;
  delayUpload?: boolean;
  dropzoneLabel?: string;
  isUploading: boolean;
  setStagedFiles?: (files: Array<File>, callBack: (arg0: any) => void) => void;
  setUploading: (uploadType: string) => void;
  singleFileOnly?: boolean;
  stagedFiles?: Array<File>;
  uploadFiles?: (file: File | Array<File>) => void;
  uploadTarget: string;
  withoutIcon?: boolean;
};

export default class UploadDropzone extends React.Component<Props> {
  static defaultProps = {
    singleFileOnly: false,
    dropzoneLabel: 'File',
  };

  static toFile(file: FileWithPreview): File {
    if (file.preview) {
      /*
        react-dropzone requires us to explictly kill preview URLs
        to avoid a mem leak
      */
      window.URL.revokeObjectURL(file.preview);
      // eslint-disable-next-line
      delete file.preview;
    }
    return file;
  }

  dropzoneRef: Dropzone | null | undefined;

  handleDrop = (
    accepted: Array<FileWithPreview>,
    rejected: Array<FileWithPreview>,
  ) => {
    const {
      delayUpload,
      uploadFiles,
      isUploading,
      setUploading,
      uploadTarget,
      singleFileOnly,
    } = this.props;
    if (delayUpload) this.stageFiles(accepted);
    const toUpload = accepted.map(e => UploadDropzone.toFile(e));

    const validAcceptedLength = singleFileOnly
      ? toUpload.length === 1
      : toUpload.length >= 1;

    if (!delayUpload) {
      invariant(uploadFiles, '`uploadFiles` method missing');
      if (validAcceptedLength && !isUploading) {
        setUploading(uploadTarget);
        const acceptedFiles = singleFileOnly ? toUpload[0] : toUpload;
        uploadFiles(acceptedFiles);
      } else if (singleFileOnly) {
        raiseAlert('Must be a single file.', 'error');
      } else {
        raiseAlert(
          `Rejected file(s): ${rejected.map(file => file.name).join(', ')}`,
          'error',
        );
      }
    }
  };

  handleSelectClick = () => {
    if (this.dropzoneRef) {
      this.dropzoneRef.open();
    }
  };

  stageFiles(files: Array<FileWithPreview>) {
    const { stagedFiles, setStagedFiles } = this.props;
    if (stagedFiles && setStagedFiles) {
      const [firstFile] = files;
      if (!firstFile) return;
      const stagedFilesWithoutDuplicates = stagedFiles.filter(
        stagedFile => stagedFile.name !== firstFile.name,
      );
      setStagedFiles([...stagedFilesWithoutDuplicates, firstFile], () => {
        this.stageFiles(files.slice(1));
      });
      if (stagedFilesWithoutDuplicates.length !== stagedFiles.length) {
        raiseAlert(
          `${firstFile.name} has replaced ${firstFile.name}.`,
          'success',
          'File Replaced',
        );
      }
    }
  }

  render() {
    const {
      singleFileOnly,
      dropzoneLabel,
      alternatePrompt,
      alternateText,
      buttonText,
      withoutIcon,
      className,
      stagedFiles,
    } = this.props;
    const classes = cx('UploadDropzone__Dropzone', className);
    return (
      <span className="UploadDropzone">
        <Dropzone
          activeClassName="active"
          className={classes}
          onDrop={this.handleDrop}
          ref={node => {
            this.dropzoneRef = node;
          }}
          rejectClassName="reject"
        >
          {!withoutIcon && (
            <Icon color="loanstreetGreen" icon="file-upload" size="lg" />
          )}
          {alternateText === null || alternateText === undefined ? (
            <div>{dropzoneLabel} not yet uploaded.</div>
          ) : (
            alternateText && <div>{alternateText}</div>
          )}
          <div>
            {alternatePrompt ||
              `Drag and drop your file${singleFileOnly ? '' : '(s)'} here or`}
          </div>
          <Button.Primary
            label={buttonText || 'Select'}
            onClick={this.handleSelectClick}
          />
          {stagedFiles && Boolean(stagedFiles.length) && (
            <div className="UploadDropzone__StagedFiles">
              <span>
                Selected file
                {stagedFiles.length > 1 ? 's' : ''}:{'\u00A0'}
              </span>
              {stagedFiles.map((stagedFile, idx) => {
                const { name } = stagedFile;
                const spanContent =
                  idx < stagedFiles.length - 1 ? `${name},\u00A0` : name;
                return <span key={spanContent}>{spanContent}</span>;
              })}
            </div>
          )}
        </Dropzone>
      </span>
    );
  }
}
