import { PAYROLL_REQUIREMENT } from './constants';
import { DealType } from 'types';
import { parseDecimal } from 'utils';

const getCalculatedValues = (data: DealType) => {
  const {
    sbapppforgivenesscalculation,
    isSFormEligible,
  } = data.sbaPppForgivenessWorkflow;

  /** Calculate payrollCosts */
  const {
    showCalculation,
    useEzForm,
    payrollCosts,
    cashCompensationLte100,
    cashCompensationGt100,
    healthInsuranceContributions,
    retirementPlanContributions,
    stateAndLocalPayrollTaxes,
    totalAmountPaidCompensationToOwners,
  } = sbapppforgivenesscalculation;

  const useSFormCalculation = isSFormEligible && showCalculation;

  /** We want to round to 2 decimal places after summing */
  const payrollCostsCalculated =
    useEzForm || useSFormCalculation
      ? parseDecimal(payrollCosts)
      : parseDecimal(
          parseDecimal(cashCompensationLte100) +
            parseDecimal(cashCompensationGt100) +
            parseDecimal(healthInsuranceContributions) +
            parseDecimal(retirementPlanContributions) +
            parseDecimal(stateAndLocalPayrollTaxes) +
            parseDecimal(totalAmountPaidCompensationToOwners),
          { numDecimals: 2 },
        );

  /** Calculate totalAverageFteFteReduction */
  const { averageFteLte100, averageFteGt100 } = sbapppforgivenesscalculation;
  const totalAverageFteFteReduction = parseDecimal(
    parseDecimal(averageFteLte100) + parseDecimal(averageFteGt100),
    { numDecimals: 1 },
  );

  /** Calculate fteReductionQuotientFteReduction */
  const {
    averageFteFteReduction,
    noFteReduction,
    safeHarbor1HasBeenMetFteReduction,
    safeHarbor2HasBeenMetFteReduction,
  } = sbapppforgivenesscalculation;

  let fteReductionQuotientFteReduction: number;

  if (
    noFteReduction ||
    safeHarbor1HasBeenMetFteReduction ||
    safeHarbor2HasBeenMetFteReduction
  ) {
    fteReductionQuotientFteReduction = 1;
  } else if (averageFteFteReduction && parseDecimal(averageFteFteReduction)) {
    fteReductionQuotientFteReduction = parseDecimal(
      totalAverageFteFteReduction / parseDecimal(averageFteFteReduction),
      { numDecimals: 2 },
    );
  }

  /** Calculate forgivenessAmount (min of 3 values) */
  const {
    salaryHourlyWageReductionLte100,
    mortgageInterest,
    rentOrLease,
    utilities,
    operationsExpenditures,
    propertyDamageCosts,
    supplierCosts,
    workerProtectionExpenditures,
  } = sbapppforgivenesscalculation;

  const modifiedTotal = parseDecimal(
    (payrollCostsCalculated +
      (parseDecimal(mortgageInterest) +
        parseDecimal(rentOrLease) +
        parseDecimal(utilities) +
        parseDecimal(operationsExpenditures) +
        parseDecimal(propertyDamageCosts) +
        parseDecimal(supplierCosts) +
        parseDecimal(workerProtectionExpenditures)) -
      parseDecimal(salaryHourlyWageReductionLte100)) *
      (useEzForm || useSFormCalculation ? 1 : fteReductionQuotientFteReduction),
    { numDecimals: 2 },
  );
  const pppLoanAmount = parseDecimal(data?.totalCommitmentAmount, {
    numDecimals: 2,
  });
  const payrollCostRequirement = parseDecimal(
    payrollCostsCalculated / PAYROLL_REQUIREMENT,
    {
      numDecimals: 2,
    },
  );
  const forgivenessAmount = Math.min(
    modifiedTotal,
    payrollCostRequirement,
    parseDecimal(pppLoanAmount),
  );
  return {
    modifiedTotal: modifiedTotal?.toString(),
    payrollCostRequirement: payrollCostRequirement?.toString(),
    pppLoanAmount: pppLoanAmount?.toString(),
    payrollCostsCalculated: payrollCostsCalculated?.toString(),
    totalAverageFteFteReduction: totalAverageFteFteReduction?.toString(),
    fteReductionQuotientFteReduction: fteReductionQuotientFteReduction?.toString(),
    forgivenessAmount: forgivenessAmount?.toString(),
  };
};

export default getCalculatedValues;
