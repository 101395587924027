import * as React from 'react';
import capitalize from 'lodash/capitalize';
import './Debug.scss';

import Toggle from './Toggle';
import DealGenerator from './DealGenerator/DealGeneratorContainer';

type Content =
  | 'invitation'
  | 'alerts'
  | 'debug_mode'
  | 'load_test'
  | 'data_generator'
  | 'deal_generator';

/* eslint-disable react/no-multi-comp */
type Props = {};
type State = { content: Content };

class Debug extends React.Component<Props, State> {
  state = {
    content: 'debug_mode' as Content,
  };

  renderContent() {
    switch (this.state.content) {
      case 'debug_mode':
        return <Toggle />;
      case 'deal_generator':
        return <DealGenerator />;
      default:
        return <div>No Content</div>;
    }
  }

  renderMenuItem(content: Content) {
    /* eslint-disable react/jsx-no-bind */
    return (
      <a
        className={content === this.state.content ? 'active item' : 'item'}
        onClick={() => {
          this.setState({ content });
        }}
      >
        {capitalize(content.replace('_', ' '))}
      </a>
    );
  }

  render() {
    return (
      <div className="Debug">
        <div className="DebugMenu">
          <div className="ui secondary vertical menu">
            {this.renderMenuItem('debug_mode')}
            {this.renderMenuItem('deal_generator')}
            <a
              className="item"
              href="https://loanstreet-usa.github.io/design-system/?path=/story/introduction-welcome--page"
            >
              Storybook (Components)
            </a>
          </div>
        </div>
        <div className="DebugContent">{this.renderContent()}</div>
      </div>
    );
  }
}

export default Debug;
