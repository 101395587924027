import * as React from 'react';
import moment from 'moment';
import { noop } from 'lodash';
import { Prompt, withRouter, RouteComponentProps } from 'react-router-dom';
import { navigateAwayText, confirmSubmitText } from '../utils';
import AssociatedFiles from '../../../associatedFiles/AssociatedFiles';
import {
  Form,
  FormFrame,
  StandardModal,
  Icon,
  WrappedMediatorFormProps,
} from 'components';
import { RouteTable } from 'routing';
import { DealType, PaymentDueType, DocumentType } from 'types';

import { PaymentRecordLenderTypeWithPrefillFlag } from 'lsredux/reducer/forms/paymentRecordLender';
import {
  DealPerspectivePermissions,
  MountGuard,
  AccessDeniedGuard,
  DisabledChildProps,
} from 'security';

const objectType = 'PaymentRecordLender';

type Props = RouteComponentProps & {
  deal: DealType;
  dealId: string;
  documents: Array<DocumentType>;
  saving: boolean;
} & WrappedMediatorFormProps<PaymentRecordLenderTypeWithPrefillFlag>;

type State = {
  isSubmitModalOpen: boolean;
};

function getInstructionText(
  deal: DealType,
  paymentDue: PaymentDueType | null | undefined,
  isSubmitted: boolean | null | undefined,
) {
  let borrowerName = deal.borrowerName || '';

  if (deal.borrower && deal.borrower.name) {
    borrowerName = deal.borrower.name;
  }
  if (isSubmitted) {
    return `Verify the payment information submitted by ${borrowerName} below.`;
  }
  let dateDue = '';
  if (paymentDue && paymentDue.date) {
    const dateDueMoment = moment(paymentDue.date);
    dateDue = ` on ${dateDueMoment.format('LL')}.`;
  }

  return `Below are the payment details for the payment to be made by ${borrowerName}${dateDue ||
    '.'}`;
}

const width = 'eight';
const textAreaWidth = 'sixteen';

class LenderPaymentView extends React.Component<Props, State> {
  state = {
    isSubmitModalOpen: false,
  };

  paymentDueFields: {
    [key: string]: any;
  };

  fields: {
    [key: string]: any;
  };

  constructor(props: Props) {
    super(props);
    this.paymentDueFields = {
      paymentDueAmount: {
        id: 'paymentDueAmount',
        fieldName: 'Scheduled Amount',
        propertyName: 'totalAmount',
        onChange: noop,
        width,
      },
      paymentDueDate: {
        id: 'paymentDueDate',
        fieldName: 'Scheduled Date',
        propertyName: 'date',
        onChange: noop,
        width,
      },
    };

    this.fields = {
      borrowerAmount: {
        id: 'borrowerAmount',
        fieldName: 'Borrower: Amount',
        propertyName: 'borrowerAmount',
        onChange: noop,
        width,
      },
      borrowerDate: {
        id: 'borrowerAmount',
        fieldName: 'Borrower: Date',
        propertyName: 'borrowerDate',
        onChange: noop,
        width,
      },
      borrowerReference: {
        id: 'borrowerReference',
        fieldName: 'Borrower: Reference Number',
        propertyName: 'borrowerReference',
        onChange: noop,
        width,
      },
      borrowerNotes: {
        id: 'borrowerNotes',
        fieldName: 'Notes',
        propertyName: 'borrowerNotes',
        onChange: noop,
        width: 'eight',
      },
      lenderAmount: {
        id: 'lenderAmount',
        className: 'paymentRecord__AmountInput',
        fieldName: 'Amount',
        propertyName: 'lenderAmount',
        onChange: this.handleMutateProperty,
        width,
      },
      lenderAmountReadOnly: {
        id: 'lenderAmountReadOnly',
        className: 'paymentRecord__AmountInput',
        fieldName: 'Lender: Amount',
        propertyName: 'lenderAmountReadOnly',
        onChange: noop,
        width,
      },
      lenderDate: {
        id: 'lenderDate',
        fieldName: 'Date',
        propertyName: 'lenderDate',
        onChange: this.handleMutateProperty,
        width,
      },
      lenderDateReadOnly: {
        id: 'lenderDateReadOnly',
        fieldName: 'Lender: Date',
        propertyName: 'lenderDateReadOnly',
        onChange: noop,
        width,
      },
      lenderReference: {
        id: 'lenderReference',
        fieldName: 'Reference Number',
        propertyName: 'lenderReference',
        onChange: this.handleMutateProperty,
        width,
      },
      lenderReferenceReadOnly: {
        id: 'lenderReferenceReadOnly',
        fieldName: 'Lender: Reference Number',
        propertyName: 'lenderReferenceReadOnly',
        onChange: noop,
        width,
      },
      lenderNotes: {
        id: 'lenderNotes',
        fieldName: 'Notes (Optional)',
        propertyName: 'lenderNotes',
        onChange: this.handleMutateProperty,
        width: textAreaWidth,
      },
    };
  }

  componentDidUpdate = () => {
    this.prefillData(this.props);
  };

  prefillData = (props: Props) => {
    const { data, mutateProperty, toggleDirtyFlag } = props;
    // SERVICING-TODO: submitted is stubbed
    const submitted = false;
    if (
      data.paymentDue &&
      submitted &&
      data.wasDataPrefilled &&
      !(data.lenderAmount || data.lenderDate)
    ) {
      if (!data.lenderAmount || data.borrowerAmount)
        mutateProperty(data.borrowerAmount, 'lenderAmount');
      if (!data.lenderDate || data.borrowerDate)
        mutateProperty(data.borrowerDate, 'lenderDate');
      if (data.__typename !== (objectType as any))
        mutateProperty(objectType, '__typename');

      toggleDirtyFlag([], false, false);
    }
  };

  handleMutateProperty = (
    value: string | null | undefined,
    fieldId: string,
  ) => {
    if (fieldId === 'lenderDate') {
      if (Form.Calendar.isValidDate(value)) {
        this.props.mutateProperty(value, fieldId);
      }
    } else {
      this.props.mutateProperty(value, fieldId);
    }
  };

  renderUnsubmittedBorrowerFields = () => {
    const { data } = this.props;
    const paymentDueProps = { ...this.props, data: data.paymentDue };
    const paymentDueId =
      data.paymentDue && data.paymentDue.id ? data.paymentDue.id : 'temp';
    return (
      <Form.Group>
        {Form.FieldRenderer(
          Form.MoneyReadOnly,
          this.paymentDueFields.paymentDueAmount,
          paymentDueProps,
          objectType,
          paymentDueId,
        )}
        {Form.FieldRenderer(
          Form.ReadOnly,
          this.paymentDueFields.paymentDueDate,
          paymentDueProps,
          objectType,
          paymentDueId,
        )}
      </Form.Group>
    );
  };

  renderSubmitSummary = () => {
    const { data } = this.props;
    const { paymentDue } = data;
    const paymentDueId = paymentDue && paymentDue.id ? paymentDue.id : 'temp';
    const paymentDueProps = { ...this.props, data: paymentDue };
    return (
      <Form id="submitSummaryLender" onSubmit={this.handleSave}>
        <Form.Group>
          {Form.FieldRenderer(
            Form.MoneyReadOnly,
            this.paymentDueFields.paymentDueAmount,
            paymentDueProps,
            objectType,
            paymentDueId,
          )}
          {Form.FieldRenderer(
            Form.MoneyReadOnly,
            this.fields.borrowerAmount,
            this.props,
            objectType,
            paymentDueId,
          )}
          {Form.FieldRenderer(
            Form.MoneyReadOnly,
            {
              ...this.fields.lenderAmountReadOnly,
              value: data && data.lenderAmount,
            },
            this.props,
            objectType,
            paymentDueId,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.ReadOnly,
            this.paymentDueFields.paymentDueDate,
            paymentDueProps,
            objectType,
            paymentDueId,
          )}
          {Form.FieldRenderer(
            Form.ReadOnly,
            this.fields.borrowerDate,
            this.props,
            objectType,
            paymentDueId,
          )}

          {Form.FieldRenderer(
            Form.ReadOnly,
            {
              ...this.fields.lenderDateReadOnly,
              value: data && data.lenderDate,
            },
            this.props,
            objectType,
            paymentDueId,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.ReadOnly,
            {
              ...this.fields.lenderReferenceReadOnly,
              value: data && data.lenderReference,
            },
            this.props,
            objectType,
            paymentDueId,
          )}
          {data &&
            data.borrowerReference &&
            Form.FieldRenderer(
              Form.ReadOnly,
              {
                ...this.fields.borrowerReference,
                fieldName: 'Borrower: Reference Number',
              },
              this.props,
              objectType,
              paymentDueId,
            )}
          {/* For design purposes */}
          <div className={`${width} wide field`} />
        </Form.Group>
        <div>{confirmSubmitText}</div>
      </Form>
    );
  };

  renderBorrowerFields = () => {
    const { data } = this.props;
    // SERVICING-TODO: submitted is stubbed
    const submitted = false;

    const paymentDueProps = { ...this.props, data: data.paymentDue };
    const paymentDueId =
      data.paymentDue && data.paymentDue.id ? data.paymentDue.id : 'temp';
    return (
      <>
        <AssociatedFiles data={this.props} viewOnly />
        <Form.Group>
          {Form.FieldRenderer(
            Form.MoneyReadOnly,
            {
              ...this.paymentDueFields.paymentDueAmount,
              fieldName: submitted ? 'Scheduled Amount' : 'Amount Due',
            },
            paymentDueProps,
            objectType,
            paymentDueId,
          )}
          {Form.FieldRenderer(
            Form.ReadOnly,
            {
              ...this.paymentDueFields.paymentDueDate,
              fieldName: submitted ? 'Scheduled Date' : 'Date Due',
            },
            paymentDueProps,
            objectType,
            paymentDueId,
          )}
        </Form.Group>
        {submitted && (
          <>
            <Form.Group>
              {Form.FieldRenderer(
                Form.MoneyReadOnly,
                this.fields.borrowerAmount,
                this.props,
                objectType,
                data.id,
              )}
              {Form.FieldRenderer(
                Form.ReadOnly,
                this.fields.borrowerDate,
                this.props,
                objectType,
                data.id,
              )}
            </Form.Group>
            <Form.Group>
              {data &&
                data.borrowerReference &&
                Form.FieldRenderer(
                  Form.ReadOnly,
                  this.fields.borrowerReference,
                  this.props,
                  objectType,
                  data.id,
                )}
            </Form.Group>
            <Form.Group>
              {data &&
                data.borrowerNotes &&
                Form.FieldRenderer(
                  Form.ReadOnly,
                  this.fields.borrowerNotes,
                  this.props,
                  objectType,
                  data.id,
                )}
            </Form.Group>
          </>
        )}
      </>
    );
  };

  renderLenderFields = () => {
    const { data } = this.props;
    const { isSubmitModalOpen } = this.state;
    return (
      <MountGuard
        permission={DealPerspectivePermissions.administer_payments_lender}
      >
        <div className="paymentRecord__Divider">
          <Form.Divider />
        </div>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Money,
            this.fields.lenderAmount,
            this.props,
            objectType,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Calendar,
            this.fields.lenderDate,
            this.props,
            objectType,
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Input,
            this.fields.lenderReference,
            this.props,
            objectType,
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.TextArea,
            this.fields.lenderNotes,
            this.props,
            objectType,
            data.id,
          )}
        </Form.Group>
        <StandardModal
          confirmButtonText="Submit"
          header="Confirm Payment"
          isOpen={isSubmitModalOpen}
          onClose={this.handleCloseModal}
          onConfirm={this.handleSave}
        >
          {this.renderSubmitSummary()}
        </StandardModal>
        <Prompt message={navigateAwayText} when={this.props.isDirty} />
      </MountGuard>
    );
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push(RouteTable.deal.toDealPayments(this.props.dealId));
  };

  handleSave = () => {
    this.props.mutateProperty(true, 'approved');
    this.props.onSave();
    this.handleCloseModal();
  };

  handleOpenModal = () => {
    this.setState({ isSubmitModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isSubmitModalOpen: false });
  };

  handleSubmit = () => {
    this.handleOpenModal();
  };

  renderFormBody = (security: DisabledChildProps) => {
    const {
      deal,
      data: { paymentDue },
      disabled,
      isDirty,
      loading,
      saving,
      onSave,
    } = this.props;

    // SERVICING-TODO: submitted is stubbed
    const submitted = false;

    const instructionsText = getInstructionText(deal, paymentDue, submitted);

    return (
      <FormFrame
        disabled={disabled || !submitted || security.accessDenied}
        formHeader="Payment"
        formId="lenderPaymentView"
        isDirty={isDirty}
        loading={loading}
        onCancel={this.handleCancel}
        onSave={onSave}
        onSubmit={this.handleSubmit}
        saving={saving}
      >
        <div className="paymentRecord__Form">
          <div className="paymentRecord__Label">Instructions</div>
          <div className="paymentRecord__InstructionText">
            {instructionsText}
          </div>
          {this.renderBorrowerFields()}
          {submitted ? (
            this.renderLenderFields()
          ) : (
            <div className="lenderPaymentView__AmountWarning">
              <Icon color="sunflower" icon="exclamation-triangle" />
              <div className="lenderPaymentView__AmountWarningText">
                Payment record has not been submitted by borrower. Borrower must
                submit payment record for approval.
              </div>
            </div>
          )}
        </div>
      </FormFrame>
    );
  };

  render() {
    return (
      <AccessDeniedGuard
        permission={DealPerspectivePermissions.administer_payments_lender}
      >
        {this.renderFormBody}
      </AccessDeniedGuard>
    );
  }
}

export default withRouter(LenderPaymentView);
