import * as React from 'react';
import { noop } from 'lodash';
import { LoanTrancheFormProps } from '../../types';
import {
  calculateRepeatsFor,
  deleteAdjustableRatePeriodsAndLTAInterestPeriods,
  parseDayCountText,
} from '../utils';
import AdjustableRatePeriodTable from './AdjustableRatePeriodTable';
import { Form, Button, TypeQueryResult } from 'components';
import { invariant, isWholeNumber } from 'utils';
import {
  BenchmarkInterval,
  LoanTrancheAdjustableRateDataTreasuryRateSource,
  LoanTrancheAmortizationInterestPeriodType,
  LoanTrancheAdjustableRatePeriodType,
} from 'types';

const width = '158px';
const loanTranche = 'LoanTranche';
const loantrancheAdjustableRateData = 'LoanTrancheAdjustableRateData';
const BUSINESS_DAY = 'BUSINESS_DAY';
const CALENDAR_DAY = 'CALENDAR_DAY';
const ON_THE_RUN = 'ON_THE_RUN';
const CONSTANT_MATURITY_TREASURY = 'CONSTANT_MATURITY_TREASURY';
const emptyArray = [];

type State = {
  enableNotices: boolean;
  shouldShowPeriods: boolean;
};

interface Props extends LoanTrancheFormProps {
  disabled?: boolean;
}

class AdjustableRateInterestFields extends React.Component<Props, State> {
  benchmarkFields: any;

  fields: any;

  constructor(props: Props) {
    super(props);
    const liborBenchmarks =
      this.props.benchmarksReferenceData &&
      this.props.benchmarksReferenceData.liborBenchmarks
        ? this.props.benchmarksReferenceData.liborBenchmarks.map(benchmark => ({
            text: benchmark.text || '',
            value: benchmark.value,
          }))
        : [];
    const treasuryBenchmarks =
      (this.props.benchmarksReferenceData &&
        this.props.benchmarksReferenceData.treasuryBenchmarks.map(
          benchmark => ({
            text: benchmark.text || '',
            value: benchmark.value,
          }),
        )) ||
      [];

    const benchmarks = [...treasuryBenchmarks, ...liborBenchmarks];

    this.state = {
      shouldShowPeriods: false,
      enableNotices: this.enableNotices(),
    };

    this.benchmarkFields = {
      indicativeBenchmark: {
        id: 'initialIndicativeBenchmarkId',
        propertyName: 'initialIndicativeBenchmarkId',
        fieldName: 'Initial Indicative Benchmark',
        onChange: this.handleMutateBenchmark,
        options: benchmarks,
        width,
        tabIndex: this.props.tabIndex,
      },
      resetBenchmark: {
        id: 'resetBenchmarkId',
        propertyName: 'resetBenchmarkId',
        fieldName: 'Reset Benchmark',
        onChange: this.handleMutateBenchmark,
        options: benchmarks,
        width,
        tabIndex: this.props.tabIndex,
      },
    };
    this.fields = {
      indicativeMargin: {
        id: 'indicativeMargin',
        propertyName: 'indicativeFixedSpread',
        fieldName: 'Indicative Margin',
        onChange: this.props.mutateProperty,
        width,
        tabIndex: this.props.tabIndex,
      },
      indicativeInitialRate: {
        id: 'indicativeFixedRate',
        propertyName: 'indicativeFixedRate',
        fieldName: 'Indicative Initial Rate',
        onChange: this.props.mutateProperty,
        width,
        tabIndex: this.props.tabIndex,
      },
      initialPeriod: {
        id: 'initialPeriod',
        propertyName: 'initialPeriod',
        fieldName: 'Initial Period',
        onChange: this.mutateAdjustableRateData,
        width,
        tabIndex: this.props.tabIndex,
        suffix: 'years',
      },
      resetMargin: {
        id: 'resetMargin',
        propertyName: 'resetMargin',
        fieldName: 'Reset Margin',
        onChange: this.mutateAdjustableRateData,
        width,
        tabIndex: this.props.tabIndex,
      },
      calculationDay: {
        id: 'calculationDay',
        propertyName: 'calculationDay',
        fieldName: 'Calculation Day',
        onChange: this.mutateAdjustableRateData,
        width,
        tabIndex: this.props.tabIndex,
        suffix: 'days',
      },
      calculationDayType: {
        id: 'calculationDayType',
        propertyName: 'calculationDayType',
        options: [
          {
            id: BUSINESS_DAY,
            label: 'Business Day',
          },
          {
            id: CALENDAR_DAY,
            label: 'Calendar Day',
          },
        ],
        onChange: this.mutateAdjustableRateData,
        width,
        tabIndex: this.props.tabIndex,
      },
      enableNotices: {
        id: 'enableNotices',
        propertyName: 'enableNotices',
        label: 'Enable Notices',
        onChange: this.handleChangeEnableNotices,
        width,
        tabIndex: this.props.tabIndex,
      },
      noticeDay: {
        id: 'noticeDay',
        propertyName: 'noticeDay',
        fieldName: 'Notice Day',
        onChange: this.mutateAdjustableRateData,
        width,
        tabIndex: this.props.tabIndex,
        suffix: 'days',
      },
      noticeDayType: {
        id: 'noticeDayType',
        propertyName: 'noticeDayType',
        options: [
          {
            id: BUSINESS_DAY,
            label: 'Business Day',
          },
          {
            id: CALENDAR_DAY,
            label: 'Calendar Day',
          },
        ],
        onChange: this.mutateAdjustableRateData,
        width,
        tabIndex: this.props.tabIndex,
      },
      resetPeriod: {
        id: 'resetPeriod',
        propertyName: 'resetPeriod',
        fieldName: 'Reset Every',
        onChange: this.mutateAdjustableRateData,
        width,
        tabIndex: this.props.tabIndex,
        suffix: 'years',
      },
      rateDuration: {
        id: 'rateDuration',
        propertyName: 'rateDuration',
        fieldName: 'Repeats For',
        onChange: noop,
        width,
        tabIndex: this.props.tabIndex,
      },
      treasuryRateSource: {
        id: 'treasuryRateSource',
        propertyName: 'treasuryRateSource',
        options: [
          {
            id: CONSTANT_MATURITY_TREASURY,
            label: 'Constant Maturity Treasury',
          },
          {
            id: ON_THE_RUN,
            label: 'On The Run',
          },
        ],
        onChange: this.handleChangeRateSource,
        width,
        tabIndex: this.props.tabIndex,
      },
      dayCountConvention: {
        id: 'dayCountConvention',
        propertyName: 'dayCountConvention',
        fieldName: 'Day Count Rule',
        onChange: this.props.mutateProperty,
        typeName: 'LoanTrancheDayCountConvention',
        width,
        tabIndex: this.props.tabIndex,
      },
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const { loantrancheadjustableratedata } = data;

    if (
      loantrancheadjustableratedata &&
      !loantrancheadjustableratedata.treasuryRateSource
    ) {
      this.mutateAdjustableRateData(
        CONSTANT_MATURITY_TREASURY,
        'treasuryRateSource',
      );
    }
  }

  mutateAdjustableRateData = (
    value: (string | null | undefined) | (boolean | null | undefined),
    propertyName: string,
  ) => {
    this.props.mutateProperty(value, [
      'loantrancheadjustableratedata',
      propertyName,
    ]);
  };

  addAdjustableRatePeriodSet = (
    periods: Array<LoanTrancheAdjustableRatePeriodType>,
  ) => {
    this.props.addEntities(
      ['loantrancheadjustableratedata', 'loantrancheadjustablerateperiodSet'],
      periods,
    );
  };

  enableNotices = (): boolean => {
    const { loantrancheadjustableratedata } = this.props.data;
    return loantrancheadjustableratedata
      ? Boolean(
          loantrancheadjustableratedata.noticeDay ||
            loantrancheadjustableratedata.noticeDayType,
        )
      : false;
  };

  deletePeriods = () => {
    const { data, deleteCollection } = this.props;
    deleteAdjustableRatePeriodsAndLTAInterestPeriods(data, deleteCollection);
  };

  getBenchmarkIdFromInterval = (
    treasuryRateSource:
      | LoanTrancheAdjustableRateDataTreasuryRateSource
      | null
      | undefined,
    interval: string,
  ) => {
    invariant(treasuryRateSource, 'Treasury Rate Source was not found!');
    const benchmarkMap =
      treasuryRateSource === ON_THE_RUN
        ? this.props.benchmarksReferenceData.treasuryOTRIntervalMap
        : this.props.benchmarksReferenceData.treasuryCMTIntervalMap;
    const benchmark = benchmarkMap[interval];
    if (benchmark) {
      return benchmark.id;
    }
    return null;
  };

  addAmortizationPeriodSet = (
    periodSet: Array<LoanTrancheAmortizationInterestPeriodType>,
  ) => {
    const { addEntities, toggleDirtyFlag } = this.props;
    toggleDirtyFlag(['loantrancheamortizationSet', '0'], true, false);
    addEntities(
      [
        'loantrancheamortizationSet',
        '0',
        'loantrancheamortizationinterestperiodSet',
      ],
      periodSet,
    );
  };

  handleMutateBenchmark = (
    value: (string | null | undefined) | BenchmarkInterval,
    propertyName: string,
  ) => {
    if (isWholeNumber(value || '')) {
      // LIBOR benchmark
      this.mutateAdjustableRateData(value, propertyName);
    } else {
      const { loantrancheadjustableratedata } = this.props.data;
      invariant(
        loantrancheadjustableratedata,
        'Adjustable Rate Data was not found!',
      );
      invariant(
        loantrancheadjustableratedata.treasuryRateSource,
        'Treasury Rate Source was not set!',
      );
      // US Treasury benchmark
      const benchmarkId = this.getBenchmarkIdFromInterval(
        loantrancheadjustableratedata.treasuryRateSource,
        value || '',
      );
      this.mutateAdjustableRateData(benchmarkId, propertyName);
    }
  };

  handleChangeEnableNotices = (value: boolean | null | undefined) => {
    this.setState({ enableNotices: value || false });

    if (!value) {
      this.props.mutateProperties(['loantrancheadjustableratedata'], {
        noticeDay: null,
        noticeDayType: null,
      });
    }
  };

  handleChangeRuleType = (
    value: boolean | null | undefined,
    propertyName: string,
    ruleType: string,
  ) => {
    this.mutateAdjustableRateData(propertyName, ruleType);
  };

  handleToggleShowPeriods = () => {
    this.setState(state => ({
      shouldShowPeriods: !state.shouldShowPeriods,
    }));
  };

  handleChangeRateSource = (value: string /* propertyName: string */) => {
    const { loantrancheadjustableratedata } = this.props.data;

    invariant(
      loantrancheadjustableratedata,
      'Adjustable Rate Data was not found!',
    );
    const { treasuryRateSource } = loantrancheadjustableratedata;
    if (treasuryRateSource !== value) {
      this.mutateAdjustableRateData(value, 'treasuryRateSource');

      const {
        resetBenchmarkId,
        initialIndicativeBenchmarkId,
      } = loantrancheadjustableratedata as any;
      const { benchmarksReferenceData } = this.props;

      invariant(
        benchmarksReferenceData,
        'Benchmarks Reference Data was not found!',
      );
      if (initialIndicativeBenchmarkId) {
        const interval =
          benchmarksReferenceData.benchmarkIdIntervalMap[
            initialIndicativeBenchmarkId
          ];
        if (interval) {
          const benchmarkId = this.getBenchmarkIdFromInterval(
            (value as any) as
              | LoanTrancheAdjustableRateDataTreasuryRateSource
              | null
              | undefined,
            interval,
          );

          if (benchmarkId) {
            this.mutateAdjustableRateData(
              benchmarkId,
              'initialIndicativeBenchmarkId',
            );
          }
        }
      }

      if (resetBenchmarkId) {
        const interval =
          benchmarksReferenceData.benchmarkIdIntervalMap[resetBenchmarkId];
        if (interval) {
          const benchmarkId = this.getBenchmarkIdFromInterval(
            (value as any) as
              | LoanTrancheAdjustableRateDataTreasuryRateSource
              | null
              | undefined,
            interval,
          );

          if (benchmarkId) {
            this.mutateAdjustableRateData(benchmarkId, 'resetBenchmarkId');
          }
        }
      }
    }
  };

  render() {
    const { data, disabled } = this.props;
    const { shouldShowPeriods, enableNotices } = this.state;
    const { loantrancheadjustableratedata } = data;

    invariant(
      loantrancheadjustableratedata,
      'Adjustable Rate Data was not found!',
    );

    const loantrancheadjustableratedataId = loantrancheadjustableratedata.id;

    const repeatsFor = calculateRepeatsFor(
      data.originalTerm,
      loantrancheadjustableratedata.initialPeriod,
    );
    const adjustableRateDataProps = {
      ...this.props,
      data: this.props.data.loantrancheadjustableratedata,
    };
    const benchmarkIdIntervalMap = this.props.benchmarksReferenceData
      ? this.props.benchmarksReferenceData.benchmarkIdIntervalMap
      : {};

    const indicativeBenchmarkValue =
      benchmarkIdIntervalMap[
        (loantrancheadjustableratedata as any).initialIndicativeBenchmarkId
      ] || (loantrancheadjustableratedata as any).initialIndicativeBenchmarkId;
    const resetBenchmarkValue =
      benchmarkIdIntervalMap[
        (loantrancheadjustableratedata as any).resetBenchmarkId
      ] || (loantrancheadjustableratedata as any).resetBenchmarkId;

    return (
      <>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Select,
            {
              ...this.benchmarkFields.indicativeBenchmark,
              value: indicativeBenchmarkValue,
            },
            adjustableRateDataProps,
            loantrancheAdjustableRateData,
            loantrancheadjustableratedataId,
          )}
          {Form.FieldRenderer(
            Form.Percentage,
            this.fields.indicativeMargin,
            adjustableRateDataProps,
            loanTranche,
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Percentage,
            this.fields.indicativeInitialRate,
            adjustableRateDataProps,
            loanTranche,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Decimal,
            this.fields.initialPeriod,
            adjustableRateDataProps,
            loantrancheAdjustableRateData,
            loantrancheadjustableratedataId,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Select,
            {
              ...this.benchmarkFields.resetBenchmark,
              value: resetBenchmarkValue,
            },
            adjustableRateDataProps,
            loantrancheAdjustableRateData,
            loantrancheadjustableratedataId,
          )}
          {Form.FieldRenderer(
            Form.Percentage,
            this.fields.resetMargin,
            adjustableRateDataProps,
            loantrancheAdjustableRateData,
            loantrancheadjustableratedataId,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Decimal,
            this.fields.resetPeriod,
            adjustableRateDataProps,
            loantrancheAdjustableRateData,
            loantrancheadjustableratedataId,
          )}
          {Form.FieldRenderer(
            Form.ReadOnly,
            { ...this.fields.rateDuration, value: repeatsFor },
            adjustableRateDataProps,
            loantrancheAdjustableRateData,
            loantrancheadjustableratedataId,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Radio,
            {
              ...this.fields.treasuryRateSource,
              value: loantrancheadjustableratedata.treasuryRateSource,
            },
            adjustableRateDataProps,
            loantrancheAdjustableRateData,
            loantrancheadjustableratedataId,
          )}
        </Form.Group>
        <Form.Group>
          {shouldShowPeriods && (
            <AdjustableRatePeriodTable
              addAdjustableRatePeriodSet={this.addAdjustableRatePeriodSet}
              addAmortizationPeriodSet={this.addAmortizationPeriodSet}
              adjustableRatePeriodSet={
                loantrancheadjustableratedata.loantrancheadjustablerateperiodSet ||
                emptyArray
              }
              deletePeriods={this.deletePeriods}
              disabled={disabled || false}
              setAdjustableRatePeriodOverflowAlert={
                this.props.setAdjustableRatePeriodOverflowAlert
              }
            />
          )}
        </Form.Group>
        <Button.Text
          label={
            shouldShowPeriods ? '- Hide All Periods' : '+ Show All Periods'
          }
          onClick={this.handleToggleShowPeriods}
        />
        <Form.Group>
          {Form.FieldRenderer(
            Form.ReferenceSelect,
            {
              ...this.fields.dayCountConvention,
              resultFilter: (queryResults: Array<TypeQueryResult>) =>
                queryResults.map(parseDayCountText),
            },
            adjustableRateDataProps,
            loanTranche,
            data.id,
          )}
        </Form.Group>
        <Form.Group className="InterestRateResetRulesRow">
          {Form.FieldRenderer(
            Form.Offset,
            this.fields.calculationDay,
            adjustableRateDataProps,
            loantrancheAdjustableRateData,
            loantrancheadjustableratedataId,
          )}
          {Form.FieldRenderer(
            Form.Radio,
            {
              ...this.fields.calculationDayType,
              value: loantrancheadjustableratedata.calculationDayType,
            },
            adjustableRateDataProps,
            loantrancheAdjustableRateData,
            loantrancheadjustableratedataId,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Checkbox,
            {
              ...this.fields.enableNotices,
              value: enableNotices,
            },
            adjustableRateDataProps,
            loantrancheAdjustableRateData,
            loantrancheadjustableratedataId,
          )}
        </Form.Group>
        {enableNotices && !adjustableRateDataProps.disabled && (
          <Form.Group className="InterestRateResetRulesRow">
            {Form.FieldRenderer(
              Form.Offset,
              {
                ...this.fields.noticeDay,
              },
              adjustableRateDataProps,
              loantrancheAdjustableRateData,
              loantrancheadjustableratedataId,
            )}
            {Form.FieldRenderer(
              Form.Radio,
              {
                ...this.fields.noticeDayType,
                value: loantrancheadjustableratedata.noticeDayType,
              },
              adjustableRateDataProps,
              loantrancheAdjustableRateData,
              loantrancheadjustableratedataId,
            )}
          </Form.Group>
        )}
      </>
    );
  }
}

export default AdjustableRateInterestFields;
