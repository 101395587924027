import React from 'react';
import { FontAwesomePrefixedIconType } from '@loanstreet-usa/design-system/build/lib/components/icon/FontAwesomeRegistry';
import { DataTable, Icon } from 'components';
import { haltBubble, getDealStage, getDealCategory } from 'utils';
import { AssigneeCell, DateStampedStatusCell } from './partials';

export const star = {
  favorite: ['fas', 'star'] as FontAwesomePrefixedIconType,
  notFavorite: ['fal', 'star'] as FontAwesomePrefixedIconType,
};

export const columnProps = {
  borrowerName: {
    id: 'borrowerName',
    CellType: DataTable.Cell,
    header: 'Borrower',
  },
  category: {
    id: 'category',
    CellType: DataTable.Cell,
    header: 'PPP Draw',
    renderChild: function DealCategory(props: {
      cellId: string;
      value: string;
    }) {
      return (
        <div className="Cell" key={props.cellId}>
          {getDealCategory(props.value) || '—'}
        </div>
      );
    },
  },
  created: {
    id: 'created',
    CellType: DataTable.DateCell,
    header: 'Created',
    props: {
      dateFormat: 'MM/DD/YY - hh:mm A',
    },
  },
  'dealassignee.name': {
    id: 'dealassignee.name',
    CellType: DataTable.DropdownCell,
    header: 'Assignee',
    renderChild: function Assignee(props: any) {
      return <AssigneeCell key={`assignee_${props.deal.id}`} {...props} />;
    },
  },
  identity: {
    id: 'identity',
    CellType: DataTable.Cell,
    header: 'LS Loan ID',
  },
  isFavorite: (
    createDealFavorite: (dealId: string) => void,
    deleteDealFavorite: (dealId: string) => void,
  ) => {
    return {
      id: 'isFavorite',
      CellType: DataTable.Cell,
      header: <Icon className="favoriteStar" icon={star.favorite} />,
      renderChild: function FavoriteStar(props: any) {
        const { deal } = props;
        const { isFavorite, id } = deal;
        return (
          <DataTable.Cell
            className="favoriteStar"
            key={`star_${id}`}
            onClick={e => {
              isFavorite ? deleteDealFavorite(id) : createDealFavorite(id);
              haltBubble(e);
            }}
          >
            <Icon icon={isFavorite ? star.favorite : star.notFavorite} />
          </DataTable.Cell>
        );
      },
      headerProps: { className: 'favoriteStar' },
    };
  },
  lenderName: {
    id: 'lenderName',
    CellType: DataTable.Cell,
    header: 'Lender',
  },
  'sbaPppForgivenessWorkflow.sbapppforgivenesscalculation.forgivenessPercentage': {
    id:
      'sbaPppForgivenessWorkflow.sbapppforgivenesscalculation.forgivenessPercentage',
    CellType: DataTable.PercentageCell,
    header: '% of Loan',
    props: {
      percentageSign: false,
    },
    headerProps: {
      suffix: '',
    },
  },
  'sbaPppForgivenessWorkflow.modified': {
    id: 'sbaPppForgivenessWorkflow.modified',
    CellType: DataTable.Cell,
    header: 'Status',
    props: {
      withPlaceholder: true,
    },
    renderChild: function Status(props: any) {
      return (
        <DateStampedStatusCell
          date={props?.deal?.sbaPppForgivenessWorkflow?.modified}
          key={props?.deal?.id}
          status={props?.deal?.status}
        />
      );
    },
  },
  'sbappploanapplication.sbaLoanNumber': {
    id: 'sbappploanapplication.sbaLoanNumber',
    CellType: DataTable.Cell,
    header: 'SBA Loan Number',
    props: {
      withPlaceholder: true,
    },
  },
  stage: {
    id: 'stage',
    CellType: DataTable.Cell,
    header: 'Stage',
    renderChild: function Stage(props: { cellId: string; value: string }) {
      return (
        <div className="Cell" key={props.cellId}>
          {getDealStage(props.value)}
        </div>
      );
    },
  },
  status: {
    id: 'status',
    CellType: DataTable.Cell,
    header: 'Status',
    props: {
      withPlaceholder: true,
    },
  },
  'sbaPppForgivenessWorkflow.sbapppforgivenesscalculation.forgivenessAmountCalculated': {
    id:
      'sbaPppForgivenessWorkflow.sbapppforgivenesscalculation.forgivenessAmountCalculated',
    CellType: DataTable.MoneyCell,
    header: 'Forgiveness Amount',
    total: true,
  },
  totalCommitmentAmount: {
    id: 'totalCommitmentAmount',
    CellType: DataTable.MoneyCell,
    header: 'Loan Amount',
    total: true,
  },
  view: {
    id: 'view',
    CellType: DataTable.Cell,
    renderChild: function viewCell(props: any) {
      return (
        <DataTable.Cell className="view" key={`view_${props.deal.id}`}>
          View
        </DataTable.Cell>
      );
    },
    header: '',
    headerProps: { onSort: undefined },
  },
};

export const statusFilterOptions = [
  // { label: 'PPP Application Received', id: 'PPP Application Received' },
  { label: 'New Application', id: 'New Application' },
  { label: 'Review Requested', id: 'Review Requested' },
  { label: 'Signature Requested', id: 'Signature Requested' },
  { label: 'Change Requested', id: 'Change Requested' },
  { label: 'Change Submitted', id: 'Change Submitted' },
  { label: 'Ready for SBA', id: 'Ready for SBA' },
  { label: 'Pending SBA Response', id: 'Pending SBA Response' },
  { label: 'Action Required', id: 'Action Required' },
  { label: 'SBA Approved', id: 'SBA Approved' },
  { label: 'SBA Denied', id: 'SBA Denied' },
  // { label: 'Signature Requested', id: 'Signature Requested' },
  { label: 'Signature Received', id: 'Signature Received' },
  { label: 'Funded', id: 'Funded' },
  { label: 'Forgiveness Requested', id: 'Forgiveness Requested' },
  { label: 'Awaiting Signature', id: 'Awaiting Signature' },
  { label: 'Denied', id: 'Denied' },
  // { label: 'Change Requested', id: 'Change Requested' },
  // { label: 'Change Submitted', id: 'Change Submitted' },
  { label: 'Signed', id: 'Signed' },
  { label: 'Pending Validation', id: 'Pending Validation' },
  { label: 'Under Review', id: 'Under Review' },
  { label: 'Fully Approved', id: 'Fully Approved' },
  { label: 'Not Approved', id: 'Not Approved' },
  { label: 'Partially Approved', id: 'Partially Approved' },
  {
    label: 'Lender Additional Info Requested',
    id: 'Lender Additional Info Requested',
  },
  { label: 'Payment Sent', id: 'Payment Sent' },
  { label: 'Payment Confirmed', id: 'Payment Confirmed' },
  { label: 'Payment Failed', id: 'Payment Failed' },
];

export const forgivenessStatusFilterOptions = [
  { label: 'Forgiveness Requested', id: 'Forgiveness Requested' },
  { label: 'Awaiting Signature', id: 'Awaiting Signature' },
  { label: 'Denied', id: 'Denied' },
  { label: 'Action Required', id: 'Action Required' },
  { label: 'Change Requested', id: 'Change Requested' },
  { label: 'Change Submitted', id: 'Change Submitted' },
  { label: 'Signed', id: 'Signed' },
  { label: 'Pending Validation', id: 'Pending Validation' },
  { label: 'Under Review', id: 'Under Review' },
  { label: 'Fully Approved', id: 'Fully Approved' },
  { label: 'Not Approved', id: 'Not Approved' },
  { label: 'Partially Approved', id: 'Partially Approved' },
  {
    label: 'Lender Additional Info Requested',
    id: 'Lender Additional Info Requested',
  },
  { label: 'Payment Sent', id: 'Payment Sent' },
  { label: 'Payment Confirmed', id: 'Payment Confirmed' },
  { label: 'Payment Failed', id: 'Payment Failed' },
];

export const categoryFilterOptions = [
  { label: '2021 2nd Draw', id: 'SBA_PPP_ROUND_TWO_SECOND_DRAW' },
  { label: '2021 1st Draw', id: 'SBA_PPP_ROUND_TWO_FIRST_DRAW' },
  { label: '2020 1st Draw', id: 'SBA_PP' },
  // { label: '', id: 'STANDARD' },
];
