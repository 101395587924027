import React, { useState, useMemo, useEffect, useReducer } from 'react';
import {
  Tiler,
  DataTable,
  Filter,
  Header,
  Summary,
  Button,
  TextLink,
} from '@loanstreet-usa/design-system';
import { DateRangeProps } from '@loanstreet-usa/design-system/build/lib/components/inputs/datePicker/types';
import { FilterOptionProps } from '@loanstreet-usa/design-system/build/lib/components/filter/types';
import { ListChildComponentProps } from 'react-window';
import DownloadButton from './DownloadButton';
import { EmptyDocuments } from './EmptyDocuments';
import { lsSort } from 'utils';
import {
  DealType,
  SbaPppForgivenessTrackerType,
  SbaPppForgivenessWorkflowType,
} from 'types';
import { RouteTable } from 'routing';

import './ForgivenessTracker.scss';

function shouldSortAscending(
  selectedColumn: string,
  defaultToAscSort: boolean,
  currentSort?: { column: string; reverse: boolean | 'none' },
) {
  return currentSort?.column === selectedColumn
    ? !currentSort.reverse
    : !!defaultToAscSort;
}

export interface ColumnShape {
  id: keyof SbaPppForgivenessTrackerType;
  CellType: React.ComponentType;
  header?: React.ReactNode;
  total?: boolean;
  props?: { [key: string]: any };
  headerProps?: { [key: string]: any };
  defaultToAscSort?: boolean;
  renderChild?: (props: any) => React.ReactElement;
}

const columns: ColumnShape[] = [
  {
    id: 'eventTimestamp',
    CellType: DataTable.DateCell,
    header: 'Date',
  },
  {
    id: 'event',
    CellType: DataTable.Cell,
    header: 'Event',
    defaultToAscSort: true,
    renderChild: function SpecialCell(props: SbaPppForgivenessTrackerType) {
      const { event, forgivenessCertificate } = props;

      return (
        <div className="ForgivenessTracker__EventCell">
          <span>{event}</span>
          {forgivenessCertificate?.downloadLink && (
            <TextLink
              className="TrackerDownloadLink"
              label="Download Certificate"
              to={forgivenessCertificate?.downloadLink}
            />
          )}
        </div>
      );
    },
  },
  {
    id: 'amount',
    CellType: DataTable.MoneyCell,
    header: 'Amount',
  },
];

type StateType = {
  sort: {
    column: keyof SbaPppForgivenessTrackerType;
    reverse: boolean | 'none';
  };
  eventTimestamp: DateRangeProps;
  event: string[];
};

type ActionType =
  | { type: 'sort'; payload: StateType['sort'] }
  | { type: 'eventTimestamp'; payload: StateType['eventTimestamp'] }
  | { type: 'event'; payload: StateType['event'] };

const initialState: StateType = {
  sort: { column: 'eventTimestamp', reverse: false },
  eventTimestamp: { startDate: null, endDate: null },
  event: [],
};

function reducer(state: StateType, action: ActionType) {
  return { ...state, [action.type]: action.payload };
}

interface ForgivenessTrackerProps {
  deal: DealType;
  sbaPppForgivenessWorkflow: SbaPppForgivenessWorkflowType;
}

function ForgivenessTracker(props: ForgivenessTrackerProps) {
  const {
    deal = {} as DealType,
    // sbaPppForgivenessWorkflow = {} as SbaPppForgivenessWorkflowType,
  } = props;
  const { sbapppforgivenesstrackerSet: trackerRows } = deal;
  // const { sbaRemainingPrincipal } = sbaPppForgivenessWorkflow;
  const [state, dispatch] = useReducer(reducer, initialState);

  const eventOptions = new Set<FilterOptionProps>();
  trackerRows.forEach(row =>
    eventOptions.add({ id: row.event, label: row.event }),
  );

  const filteredRows = useMemo(
    () =>
      trackerRows.filter(row => {
        const dateMatch =
          !state.eventTimestamp.startDate ||
          (state.eventTimestamp.startDate <= row.eventTimestamp &&
            state.eventTimestamp.endDate >= row.eventTimestamp);
        const eventMatch =
          state.event.length === 0 || state.event.includes(row.event);
        return dateMatch && eventMatch;
      }),
    [trackerRows, state.eventTimestamp, state.event],
  );

  const [sortedRows, setSortedRows] = useState(filteredRows);

  useEffect(() => {
    setSortedRows(lsSort(filteredRows, state.sort));
  }, [filteredRows, state.sort]);

  function handleSort(defaultToAscSort?: boolean) {
    return (columnId: string) => {
      const column = columns.filter(c => c.id === columnId)[0];
      column?.id &&
        dispatch({
          type: 'sort',
          payload: {
            column: column.id,
            reverse: shouldSortAscending(
              column.id,
              !!defaultToAscSort,
              state.sort,
            ),
          },
        });
    };
  }

  function handleFilterChange(filterId: keyof StateType) {
    return function(value: any) {
      dispatch({ type: filterId, payload: value });
    };
  }

  if (trackerRows.length === 0) {
    return (
      <Tiler className="ForgivenessTracker">
        <Tiler.Tile className="NoForgiveness" title="">
          <div>
            <EmptyDocuments />
          </div>
          <Header as="h2" className="steel">
            There is no forgiveness activity to display
          </Header>
          <div className="Space-1" />
          <Header as="h4" className="steel">
            This page will display forgiveness status updates when the borrower
            has submitted their application.
          </Header>
          <div className="Space-5_5" />
          <Button
            label="Go to Application"
            to={RouteTable.deal.toSbaPppForgivenessWorkflow(deal.id)}
          />
        </Tiler.Tile>
      </Tiler>
    );
  }
  return (
    <Tiler className="ForgivenessTracker">
      <Tiler.Tile
        secondarySection={() => (
          <>
            {false ? ( // change `false` to `sbaRemainingPrincipal`
              <Summary.MoneyField
                label="Post-Forgiveness Principal"
                value={null} // sbaRemainingPrincipal
              />
            ) : (
              // SBA-HACK for styling/formatting
              <div />
            )}
            <div className="FilterSection">
              <Filter.DatePicker
                align="right"
                id="eventTimestamp"
                onChange={handleFilterChange('eventTimestamp')}
                placeholder="Date"
                value={state.eventTimestamp}
              />
              <Filter.CheckboxGroup
                align="right"
                id="event"
                onChange={handleFilterChange('event')}
                options={Array.from(eventOptions)}
                placeholder="Event"
                value={state.event}
              />
              <DownloadButton dealName={deal.name} rows={trackerRows} />
            </div>
          </>
        )}
        title="Forgiveness"
      >
        <DataTable>
          <DataTable.Header>
            {columns.map(column => {
              const { sort } = state;
              const sorted = sort?.column === column.id;
              const direction = sort?.reverse ? 'up' : 'down';

              return (
                <DataTable.HeaderCell
                  id={column.id}
                  key={column.id}
                  onSort={handleSort(column.defaultToAscSort)}
                  sort={sorted ? direction : undefined}
                  type={column.CellType}
                >
                  {column.header}
                </DataTable.HeaderCell>
              );
            })}
          </DataTable.Header>
          <DataTable.Body
            batchSize={100}
            hasDropdownCells
            itemsLoadedCount={sortedRows.length}
            lazyRendering
            loadingThreshold={75}
            maxHeight="100vh - 120px"
            totalRows={sortedRows.length}
          >
            {(renderProps: ListChildComponentProps) => {
              const { index: rowIndex, style } = renderProps;
              return (
                <DataTable.Row
                  key={rowIndex}
                  numColumns={columns.length}
                  style={style}
                >
                  {columns.map(column => (
                    <column.CellType key={`${column.id}_${rowIndex}`}>
                      {column.renderChild
                        ? column.renderChild(sortedRows[rowIndex])
                        : sortedRows[rowIndex][column.id]}
                    </column.CellType>
                  ))}
                </DataTable.Row>
              );
            }}
          </DataTable.Body>
        </DataTable>
      </Tiler.Tile>
    </Tiler>
  );
}

export default ForgivenessTracker;
