import * as React from 'react';

import moment from 'moment';
import { noop } from 'lodash';
import {
  CalendarEventTableDealContainer,
  CalendarEventTableContainer,
} from './CalendarEventViewTableContainer';
import { Form, momentDateFormat } from 'components';

type Props = {
  allDeals?: boolean;
  resetListViewDates: () => void;
  setViewEnd: (date: Date) => void;
  setViewStart: (date: Date) => void;
  viewEnd: Date;
  viewStart: Date;
};

// TODO can pull this out of Calendar View into a parent element so there's no need for two calendarItemQuery trips
class CalendarEventView extends React.Component<Props> {
  componentDidMount = () => {
    const { resetListViewDates } = this.props;
    resetListViewDates();
  };

  handleChangeStartDate = (value: string | null | undefined) => {
    if (value !== null && value !== undefined) {
      const { viewEnd, setViewStart } = this.props;
      const endDate = moment(viewEnd);
      const startDate = moment(value);
      if (startDate.isSameOrBefore(endDate, 'day')) {
        setViewStart(startDate.toDate());
      }
    }
  };

  handleChangeEndDate = (value: string | null | undefined) => {
    if (value !== null && value !== undefined) {
      const { viewStart, setViewEnd } = this.props;
      const endMoment = moment(value);
      const startMoment = moment(viewStart);
      if (endMoment.isSameOrAfter(startMoment, 'day')) {
        setViewEnd(endMoment.toDate());
      }
    }
  };

  render() {
    const { allDeals } = this.props;
    const { viewEnd, viewStart } = this.props;

    const startDate = moment(viewStart).format(momentDateFormat);
    const endDate = moment(viewEnd).format(momentDateFormat);

    return (
      <div className="calendarEventView">
        <Form
          className="calendarEventView__DateRanges"
          id="calendarEventView__DateRangeInputs"
          onSubmit={noop}
        >
          <Form.Group>
            <Form.Calendar
              fieldName="From Date"
              id="calendarEventViewFromDate"
              onChange={this.handleChangeStartDate}
              propertyName="startDate"
              value={startDate}
              width="four"
            />
            <Form.Calendar
              fieldName="To Date"
              id="calendarEventViewToDate"
              onChange={this.handleChangeEndDate}
              propertyName="endDate"
              value={endDate}
              width="four"
            />
          </Form.Group>
        </Form>
        {allDeals ? (
          <CalendarEventTableContainer />
        ) : (
          <CalendarEventTableDealContainer />
        )}
      </div>
    );
  }
}

export default CalendarEventView;
