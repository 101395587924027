import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';

/* eslint-disable */
const DocumentExpiringUrlQueryDefinition: DocumentNode = gql`
  query DocumentExpiringUrlQuery($id: ID) {
    documentExpiringUrl(id: $id)
  }
`;

const DocumentExpiringUrlQuery = standardQueryBuilder(
  DocumentExpiringUrlQueryDefinition,
  {
    disableCache: true,
    skip: (props: { id: string | null | undefined }) => !props.id,
    variables: (props: { id: string | null | undefined }) => {
      const { id } = props;
      return { id };
    },
    typename: 'StubDocumentExpiringUrl',
    results: (data: { documentExpiringUrl: string | null | undefined }) => {
      return data;
    },
  },
);

export default DocumentExpiringUrlQuery;
