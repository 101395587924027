import uuid from 'uuid/v4';
import { SbaPppApplicationResubmitRequestMutationMethods } from '../../../graphql';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import { SbaPppApplicationResubmitRequestType } from 'types';

const initialSbaPppApplicationResubmitRequest: SbaPppApplicationResubmitRequestType = {
  id: uuid(),
  __typename: 'SbaPppApplicationResubmitRequestType',
} as any;

const lifecycle = {
  mutations: SbaPppApplicationResubmitRequestMutationMethods,
  validators: {},
};

export default createForm<
  SbaPppApplicationResubmitRequestType,
  SbaPppApplicationResubmitRequestType
>(
  Directory.SbaPppApplicationResubmitRequestKey,
  initialSbaPppApplicationResubmitRequest,
  lifecycle,
);
