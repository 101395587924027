import * as React from 'react';
import { noop } from 'lodash';
import {
  getRoundedString,
  getSuffixFromPaymentFrequency,
  parseDayCountText,
} from '../sections/utils';
import CreateAmortizationForm, {
  GenericSolveForRowProps,
  GenericAmortizationFormProps,
  Args,
} from './createLoanTrancheAmortizationForm/CreateLoanTrancheAmortizationForm';

import PaymentRow from './createLoanTrancheAmortizationForm/PaymentRow';
import SolveForRow from './solveForButtons/SolveFor';
import {
  AmortizationColumns,
  TrancheFieldRenderer,
  AmortFieldRenderer,
  validAmortizationTypes,
} from './shared';
import { CurrentBenchmarksQuery } from 'lsgql/queries/BenchmarkRatesQuery';
import { BenchmarkRateType, FormFieldsType } from 'types';
import { shiftDecimal, removeTrailingZeros } from 'utils';
import { Form, TypeQueryResult } from 'components';

const FieldError = ' ';

class AdjustableRateSolveForRow extends React.Component<
  GenericSolveForRowProps
> {
  handleSolveForClick = (value: string) => {
    this.props.handleSolveFor(value);
  };

  render() {
    return (
      <>
        <SolveForRow>
          <SolveForRow.Button
            onChange={this.handleSolveForClick}
            text="Calculate"
            value="fixed_payment_amount"
          />
        </SolveForRow>
      </>
    );
  }
}

type FloatingRateFormState = {
  benchmarkCurrentRateMap:
    | {
        [key: string]: BenchmarkRateType;
      }
    | null
    | undefined;
};
class FloatingRateForm extends React.Component<
  GenericAmortizationFormProps,
  FloatingRateFormState
> {
  fields: FormFieldsType = {};

  constructor(props: GenericAmortizationFormProps) {
    super(props);

    this.fields = {
      originalBalance: {
        id: 'initialDrawAmount',
        propertyName: 'initialDrawAmount',
        fieldName: 'Original Balance',
        onChange: this.props.mutateProperty,
        width: 'four',
      },
      initialIndicativeBenchmark: {
        id: 'initialIndicativeBenchmark',
        propertyName: 'initialIndicativeBenchmark',
        fieldName: 'Initial Benchmark',
        onChange: this.handleInitialBenchmarkChange,
        width: 'four',
      },
      indicativeMargin: {
        id: 'indicativeMargin',
        propertyName: 'indicativeFixedSpread',
        fieldName: 'Margin',
        onChange: this.props.mutateProperty,
        width: 'four',
      },
      calculatedInterest: {
        id: 'calculatedInterest',
        propertyName: 'calculatedInterest',
        fieldName: 'Calculated Interest',
        width: 'four',
        onChange: noop,
      },
      originalTerm: {
        id: 'originalTerm',
        propertyName: 'originalTerm',
        fieldName: 'Original Term',
        onChange: this.props.mutateProperty,
        width: 'four',
        suffix: 'years',
      },
      balloonPayment: {
        id: 'balloonPayment',
        propertyName: 'balloonPayment',
        fieldName: 'Final Payment/Balloon',
        onChange: this.props.mutateAmortization,
        width: 'three',
      },
      amortizationType: {
        id: 'amortizationType',
        propertyName: 'amortizationType',
        fieldName: 'Amortization Type',
        onChange: this.props.mutateAmortization,
        typeName: 'LoanTrancheAmortizationAmortizationType',
        width: 'four',
        resultFilter: (res: Array<TypeQueryResult>) =>
          res.filter((e: any) => validAmortizationTypes.has(e.value)),
      },
      paymentFrequency: {
        id: 'paymentFrequency',
        propertyName: 'paymentFrequency',
        fieldName: 'Payment Frequency',
        onChange: this.props.mutateProperty,
        typeName: 'LoanTranchePaymentFrequency',
        width: 'four',
      },
      interestOnlyPeriods: {
        id: 'interestOnlyPeriods',
        propertyName: 'interestOnlyPeriods',
        fieldName: 'Interest Only Periods',
        onChange: this.props.mutateAmortization,
        width: 'three',
      },
      fixedInterestRate: {
        id: 'fixedInterestRate',
        propertyName: 'fixedInterestRate',
        fieldName: 'Fixed Interest Rate',
        onChange: this.props.mutateProperty,
        width: 'three',
        tabIndex: this.props.tabIndex,
      },
      dayCountConvention: {
        id: 'dayCountConvention',
        propertyName: 'dayCountConvention',
        fieldName: 'Day Count',
        onChange: this.props.mutateProperty,
        typeName: 'LoanTrancheDayCountConvention',
        width: 'four',
      },
      fixedPayment: {
        id: 'fixedPaymentAmount',
        propertyName: 'fixedPaymentAmount',
        fieldName: 'Fixed Payment',
        onChange: this.props.mutateAmortization,
        width: 'four',
      },
      initialFundingDate: {
        id: 'initialFundingDate',
        propertyName: 'initialFundingDate',
        fieldName: 'Origination Date',
        onChange: this.props.mutateProperty,
        width: 'four',
        tabIndex: this.props.tabIndex,
      },
      firstPaymentDate: {
        id: 'firstPaymentDate',
        propertyName: 'firstPaymentDate',
        fieldName: 'First Payment Date',
        onChange: this.props.mutateAmortization,
        width: 'four',
        tabIndex: this.props.tabIndex,
      },
    };
    this.state = {
      benchmarkCurrentRateMap: null,
    };
  }

  componentDidMount = () => {
    const benchmarkIdSet = (this.props.data.benchmarkoptionSet || []).map(
      benchmarkOption => benchmarkOption.benchmarkId,
    );

    CurrentBenchmarksQuery(benchmarkIdSet).then(value => {
      this.setState({
        benchmarkCurrentRateMap: value,
      });
    });
  };

  handleInitialBenchmarkChange = (value: string | null | undefined) => {
    const { mutateProperty, mutateAmortization } = this.props;

    const { benchmarkCurrentRateMap } = this.state;

    if (value) {
      const benchmark = {
        id: value,
        __typename: 'BenchmarkType',
      };

      mutateAmortization(benchmark as any, 'initialIndicativeBenchmark');
      if (value && benchmarkCurrentRateMap && benchmarkCurrentRateMap[value]) {
        const benchmarkRate = benchmarkCurrentRateMap[value];
        mutateProperty(
          Number(
            shiftDecimal(removeTrailingZeros(benchmarkRate.rate), -2),
          ).toFixed(17),
          'indicativeFixedRate',
        );
      }
    } else {
      mutateAmortization(null, 'initialIndicativeBenchmark');
    }
  };

  getCalculatedInterest = () => {
    const { data } = this.props;
    const { indicativeFixedRate, indicativeFixedSpread } = data;
    const baseRate = Number.isNaN(Number(indicativeFixedRate))
      ? 0
      : Number(indicativeFixedRate);
    const margin = Number.isNaN(Number(indicativeFixedSpread))
      ? 0
      : Number(indicativeFixedSpread);
    return getRoundedString(baseRate + margin);
  };

  render() {
    const { amortization, data } = this.props;
    const { benchmarkCurrentRateMap } = this.state;
    if (benchmarkCurrentRateMap === null) return null;
    const allBenchmarkMap =
      this.props.benchmarksReferenceData &&
      this.props.benchmarksReferenceData.allBenchmarkMap
        ? this.props.benchmarksReferenceData.allBenchmarkMap
        : {};

    const amortProps = { ...this.props, data: amortization };
    const queryError = Boolean(
      amortization.readerData && (amortization.readerData as any).hasErrors,
    );
    const { readerData } = amortization;
    const initialBenchmarkOptions = (data.benchmarkoptionSet || [])
      .filter(
        benchmarkOption =>
          benchmarkOption.benchmarkId &&
          allBenchmarkMap[benchmarkOption.benchmarkId],
      )
      .map(benchmarkOption => ({
        text: allBenchmarkMap[benchmarkOption.benchmarkId || ''].text || '',
        value: allBenchmarkMap[benchmarkOption.benchmarkId || ''].value,
      }));
    const calculatedInterest = this.getCalculatedInterest();

    return (
      <>
        <Form.Group unstackable>
          {TrancheFieldRenderer(
            Form.Money,
            this.fields.originalBalance,
            this.props,
            amortization.id,
            readerData,
          )}
          {Form.FieldRenderer(
            Form.Select,
            {
              ...this.fields.initialIndicativeBenchmark,
              value:
                amortization && amortization.initialIndicativeBenchmark
                  ? amortization.initialIndicativeBenchmark.id
                  : null,
              options: initialBenchmarkOptions,
            },
            this.props,
            'LoanTranche',
            data.id,
          )}
          {TrancheFieldRenderer(
            Form.Percentage,
            this.fields.indicativeMargin,
            this.props,
            amortization.id,
            readerData,
          )}
          {Form.FieldRenderer(
            Form.PercentageReadOnly,
            {
              ...this.fields.calculatedInterest,
              value: calculatedInterest,
            },
            this.props,
            'LoanTranche',
            data.id,
          )}
        </Form.Group>
        <Form.Group unstackable>
          {TrancheFieldRenderer(
            Form.Decimal,
            this.fields.originalTerm,
            this.props,
            amortization.id,
            readerData,
          )}
          {AmortFieldRenderer(
            Form.Numeric,
            {
              ...(!queryError
                ? {
                    ...this.fields.interestOnlyPeriods,
                    suffix: getSuffixFromPaymentFrequency(data),
                  }
                : {
                    ...this.fields.interestOnlyPeriods,
                    error: FieldError,
                  }),
              width: 'four',
            },
            amortProps,
            amortization.id,
            readerData,
          )}
          {AmortFieldRenderer(
            Form.Money,
            {
              ...(!queryError
                ? this.fields.balloonPayment
                : {
                    ...this.fields.balloonPayment,
                    error: FieldError,
                  }),
              width: 'four',
            },
            amortProps,
            amortization.id,
            readerData,
          )}
        </Form.Group>
        <Form.Group unstackable>
          {Form.FieldRenderer(
            Form.Calendar,
            this.fields.initialFundingDate,
            this.props,
            'LoanTranche',
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Calendar,
            this.fields.firstPaymentDate,
            amortProps,
            'LoanTrancheAmortization',
            data.id,
          )}
          {TrancheFieldRenderer(
            Form.ReferenceSelect,
            { ...this.fields.paymentFrequency, width: 'four' },
            this.props,
            amortization.id,
            readerData,
          )}
          {TrancheFieldRenderer(
            Form.ReferenceSelect,
            {
              ...this.fields.dayCountConvention,
              width: 'four',
              resultFilter: (queryResults: Array<TypeQueryResult>) =>
                queryResults.map(parseDayCountText),
            },
            this.props,
            amortization.id,
            readerData,
          )}
        </Form.Group>
      </>
    );
  }
}

const args: Args = {
  columns: [
    AmortizationColumns.count,
    AmortizationColumns.date,
    AmortizationColumns.beginningBalance,
    AmortizationColumns.interestAmount,
    AmortizationColumns.principalPayment,
    AmortizationColumns.endingBalance,
    AmortizationColumns.errorColumn,
  ],
  paymentRowComponent: PaymentRow as any,
  formComponent: FloatingRateForm,
  solveForRowComponent: AdjustableRateSolveForRow,
};

const AdjustableRateAmortizationModalForm = CreateAmortizationForm(args);

export default AdjustableRateAmortizationModalForm;
