import * as React from 'react';
import { Form } from 'components';
import { FormFieldsType, SbaPppApplicantOwnershipBusinessType } from 'types';
import { states } from 'resources';

import './RoundTwoOwnership.scss';

function getBizTypeOptions(): {
  text: string;
  value: SbaPppApplicantOwnershipBusinessType;
}[] {
  return [
    { text: 'Sole Proprietorship', value: 'SOLE_PROPRIETORSHIP' },
    { text: 'Partnership', value: 'PARTNERSHIP' },
    // REMOVED IN ROUND TWO: { text: 'Corporation', value: 'CORPORATION' },
    { text: 'LLC', value: 'LLC' },
    { text: 'Joint Venture', value: 'JOINT_VENTURE' },
    { text: 'Professional Association', value: 'PROFESSIONAL_ASSOCIATION' },
    { text: 'Trust', value: 'TRUST' },
    { text: 'Cooperative', value: 'COOPERATIVE' },
    { text: 'LLP', value: 'LLP' },
    { text: 'S Corp', value: 'S_CORP' },
    { text: 'Non-Profit', value: 'NON_PROFIT' },
    { text: 'ESOP', value: 'ESOP' },
    { text: 'Non Profit Childcare Center', value: 'CHILDCARE_CENTER' },
    { text: 'ROBS', value: 'ROBS' },
    { text: 'Self-Employed', value: 'SELF_EMPLOYED' },
    { text: 'Independent Contractor', value: 'INDEPENDENT_CONTRACTOR' },
    { text: 'C Corp', value: 'C_CORP' },
    { text: 'NPO', value: 'NPO' },
    { text: 'Tenant in Common', value: 'TENANT_IN_COMMON' },
    { text: '501(c)(3) Nonprofit', value: 'NONPROFIT_C_3_ORGANIZTION' },
    { text: '501(c)(6) Organization', value: 'NONPROFIT_C_6_ORGANIZTION' },
    { text: '501(c)(19) Veterans Organization', value: 'VETERANS_ORG' },
    {
      text: 'Tribal Business (sec. 31(b)(2)(C) of Small Business Act)',
      value: 'TRIBAL',
    },
    { text: 'Housing Cooperative', value: 'HOUSING_COOPERATIVE' },
  ];
}

// Can be either SSN/EIN: 'SOLE_PROPRIETORSHIP', 'LLC', 'TRUST', 'TENANT_IN_COMMON',
const SSN_EXCLUSIVES: Array<SbaPppApplicantOwnershipBusinessType> = [
  'SELF_EMPLOYED',
  'INDEPENDENT_CONTRACTOR',
];
const EIN_EXCLUSIVES: Array<SbaPppApplicantOwnershipBusinessType> = [
  'PARTNERSHIP',
  'C_CORP',
  'JOINT_VENTURE',
  'PROFESSIONAL_ASSOCIATION',
  'COOPERATIVE',
  'LLP',
  'S_CORP',
  'NON_PROFIT',
  'ESOP',
  // 'NONPROFIT_CHILD_CARE' ?? 'CHILDCARE_CENTER'
  'ROBS',
  'NONPROFIT_C_3_ORGANIZTION',
  'NONPROFIT_C_6_ORGANIZTION',
  'VETERANS_ORG',
  'TRIBAL',
  'HOUSING_COOPERATIVE',
  'NPO',
];

const fields: FormFieldsType = {
  ownerType: {
    id: 'ownerType',
    propertyName: 'ownerType',
    fieldName: 'Owner Type',
    required: true,
    options: [
      { text: 'Business', value: 'BUSINESS' },
      { text: 'Person', value: 'PERSON' },
    ],
    width: '120px',
  },
  firstName: {
    id: 'firstName',
    propertyName: 'firstName',
    fieldName: 'First Name',
    width: '120px',
    required: true,
  },
  lastName: {
    id: 'lastName',
    propertyName: 'lastName',
    fieldName: 'Last Name',
    width: '120px',
    required: true,
  },
  ownerName: {
    id: 'ownerName',
    propertyName: 'ownerName',
    fieldName: 'Business Name',
    width: '392px',
    required: true,
    placeholder: 'If nonprofit enter authorized signatory',
  },
  title: {
    id: 'title',
    propertyName: 'title',
    fieldName: 'Title',
    width: '120px',
    required: true,
  },
  ownershipPercent: {
    id: 'ownershipPercent',
    propertyName: 'ownershipPercent',
    fieldName: '% Owner',
    width: '60px',
    required: true,
  },
  businessTinType: {
    id: 'businessTinType',
    propertyName: 'businessTinType',
    fieldName: '',
    required: true,
    options: [
      { text: 'EIN', value: 'EIN' },
      { text: 'SSN', value: 'SSN' },
    ],
    width: '67px',
  },
  tinEinSsn: {
    id: 'tinEinSsn',
    propertyName: 'tinEinSsn',
    fieldName: '',
    width: '256px',
    required: true,
  },
  addressOne: {
    id: 'addressLineOne',
    propertyName: 'addressLineOne',
    fieldName: 'Owner Address',
    width: '332px',
    required: true,
  },
  addressTwo: {
    id: 'addressLineTwo',
    propertyName: 'addressLineTwo',
    fieldName: 'Address Line 2',
    width: '332px',
  },
  city: {
    id: 'city',
    propertyName: 'city',
    fieldName: 'City',
    width: '158px',
    required: true,
  },
  state: {
    id: 'state',
    propertyName: 'state',
    fieldName: 'State',
    placeholder: '--',
    typeName: 'SbaPppInstitutionState',
    width: '52px',
    options: states.map(state => ({
      text: state.abbreviation,
      value: state.abbreviation,
    })),
    required: true,
  },
  zip: {
    id: 'zip',
    propertyName: 'zip',
    fieldName: 'Zip',
    width: '98px',
    required: true,
    className: 'zip-code',
    placeholder: '12345-1234',
    helpText: '+ 4 digits is optional',
  },
  phone: {
    id: 'phone',
    propertyName: 'phone',
    fieldName: 'Business Phone',
    width: '168px',
    required: true,
  },
  businessType: {
    id: 'businessType',
    propertyName: 'businessType',
    fieldName: 'Business Type',
    options: getBizTypeOptions(),
    required: true,
    placeholder: 'Please Select',
    width: '256px',
  },
  veteranStatus: {
    id: 'veteranStatus',
    propertyName: 'veteranStatus',
    fieldName: 'Veteran Status',
    options: [
      { text: 'Non-Veteran', value: 'NON_VETERAN' },
      { text: 'Veteran', value: 'VETERAN' },
      { text: 'Service-Disabled Veteran', value: 'SERVICE_DISABLED_VETERAN' },
      { text: 'Spouse of Veteran', value: 'SPOUSE_OF_VETERAN' },
      { text: 'Not Disclosed', value: 'NOT_DISCLOSED' },
    ],
    width: '158px',
  },
  gender: {
    id: 'gender',
    propertyName: 'gender',
    fieldName: 'Gender',
    options: [
      { text: 'Male', value: 'MALE' },
      { text: 'Female', value: 'FEMALE' },
      { text: 'Not Disclosed', value: 'NOT_DISCLOSED' },
    ],
  },
  ethnicity: {
    id: 'ethnicity',
    propertyName: 'ethnicity',
    fieldName: 'Ethnicity',
    options: [
      { text: 'Hispanic or Latino', value: 'HISPANIC_OR_LATINO' },
      { text: 'Not Hispanic or Latino', value: 'NOT_HISPANIC_OR_LATINO' },
      { text: 'Not Disclosed', value: 'NOT_DISCLOSED' },
    ],
  },
  race: {
    id: 'race',
    propertyName: 'race',
    fieldName: 'Race (more than 1 may be selected)',
    options: [
      {
        text: 'American Indian or Alaska Native',
        value: 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
      },
      { text: 'Asian', value: 'ASIAN' },
      { text: 'Black or African-American', value: 'BLACK_OR_AFRICAN_AMERICAN' },
      {
        text: 'Native Hawaiian or Pacific Islander',
        value: 'NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER',
      },
      { text: 'White', value: 'WHITE' },
      { text: 'Not Disclosed', value: 'NOT_DISCLOSED' },
    ],
    multiple: true,
  },
  citizenship: {
    id: 'citizenship',
    propertyName: 'citizenship',
    fieldName: 'Citizenship',
    options: [
      { text: 'U.S.', value: 'US' },
      { text: 'Other', value: 'OTHER' },
      { text: 'Not Answered', value: 'UNANSWERED' },
    ],
    required: true,
    placeholder: 'Please Select',
    width: '188px',
  },
};

const SbaPppApplicantOwnership = 'SbaPppApplicantOwnership';

function OwnershipForm(props: any) {
  const { data } = props;
  const placeholderTinEinSsn = data.tinEinSsn
    ? '*********'
    : 'Enter numbers only, no dashes or spaces';

  const isBusiness = Boolean(data.ownerType === 'BUSINESS');

  const setByBizType =
    data.ownerType === 'PERSON' ||
    [...SSN_EXCLUSIVES, ...EIN_EXCLUSIVES].includes(data.businessType);

  if (setByBizType) {
    if (EIN_EXCLUSIVES.includes(data.businessType)) {
      props.mutateProperty('EIN', 'businessTinType');
    } else {
      // if (data.ownerType === 'PERSON' || SSN_EXCLUSIVES.includes(data.businessType))
      props.mutateProperty('SSN', 'businessTinType');
    }
  }

  return (
    <div className="RoundTwoOwnership">
      <Form.Group>
        {Form.FieldRenderer(
          Form.Select,
          { ...fields.ownerType, onChange: props.mutateProperty },
          props,
          SbaPppApplicantOwnership,
          data.id,
        )}
        {isBusiness &&
          Form.FieldRenderer(
            Form.Select,
            {
              ...fields.businessType,
              onChange: props.mutateProperty,
            },
            props,
            SbaPppApplicantOwnership,
            data.id,
          )}
        {!isBusiness &&
          Form.FieldRenderer(
            Form.Select,
            {
              ...fields.citizenship,
              onChange: props.mutateProperty,
            },
            props,
            SbaPppApplicantOwnership,
            data.id,
          )}
      </Form.Group>
      <Form.Group>
        {!isBusiness && (
          <>
            {Form.FieldRenderer(
              Form.Input,
              { ...fields.firstName, onChange: props.mutateProperty },
              props,
              SbaPppApplicantOwnership,
              data.id,
            )}
            {Form.FieldRenderer(
              Form.Input,
              { ...fields.lastName, onChange: props.mutateProperty },
              props,
              SbaPppApplicantOwnership,
              data.id,
            )}
            {Form.FieldRenderer(
              Form.Input,
              { ...fields.title, onChange: props.mutateProperty },
              props,
              SbaPppApplicantOwnership,
              data.id,
            )}
          </>
        )}
        {isBusiness &&
          Form.FieldRenderer(
            Form.Input,
            { ...fields.ownerName, onChange: props.mutateProperty },
            props,
            SbaPppApplicantOwnership,
            data.id,
          )}
        {Form.FieldRenderer(
          Form.Percentage,
          { ...fields.ownershipPercent, onChange: props.mutateProperty },
          props,
          SbaPppApplicantOwnership,
          data.id,
        )}
      </Form.Group>
      <Form.FieldLabel
        fieldName="Taxpayer Identification Number (TIN)"
        htmlFor={fields.businessTinType.id}
        propertyName={fields.businessTinType.propertyName}
      />
      <Form.Group>
        {Form.FieldRenderer(
          Form.Select,
          {
            ...fields.businessTinType,
            onChange: props.mutateProperty,
            disabled: setByBizType,
            disabledReadable: setByBizType,
          },
          props,
          SbaPppApplicantOwnership,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Numeric,
          {
            ...fields.tinEinSsn,
            onChange: props.mutateProperty,
            maxLength: 9,
            placeholder: placeholderTinEinSsn,
            helpText: setByBizType
              ? 'TIN Type preset based on Owner/Business Type'
              : undefined,
          },
          props,
          SbaPppApplicantOwnership,
          data.id,
        )}
      </Form.Group>
      {Form.FieldRenderer(
        Form.Input,
        { ...fields.addressOne, onChange: props.mutateProperty },
        props,
        SbaPppApplicantOwnership,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Input,
        { ...fields.addressTwo, onChange: props.mutateProperty },
        props,
        SbaPppApplicantOwnership,
        data.id,
      )}
      <Form.Group>
        {Form.FieldRenderer(
          Form.Input,
          { ...fields.city, onChange: props.mutateProperty },
          props,
          SbaPppApplicantOwnership,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Select,
          { ...fields.state, onChange: props.mutateProperty },
          props,
          SbaPppApplicantOwnership,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Zip,
          { ...fields.zip, onChange: props.mutateProperty },
          props,
          SbaPppApplicantOwnership,
          data.id,
        )}
      </Form.Group>
      <Form.Text className="steel" value="Optional Demographic Information" />
      <Form.Text
        className="steel voluntary-disclosure"
        value="(Disclosure is voluntary and will have no bearing on the loan application decision)"
      />
      <Form.Group>
        {Form.FieldRenderer(
          Form.Select,
          {
            ...fields.veteranStatus,
            onChange: props.mutateProperty,
          },
          props,
          SbaPppApplicantOwnership,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Select,
          { ...fields.gender, onChange: props.mutateProperty },
          props,
          SbaPppApplicantOwnership,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Select,
          { ...fields.ethnicity, onChange: props.mutateProperty },
          props,
          SbaPppApplicantOwnership,
          data.id,
        )}
      </Form.Group>
      {Form.FieldRenderer(
        Form.Select,
        { ...fields.race, onChange: props.mutateProperty },
        props,
        SbaPppApplicantOwnership,
        data.id,
      )}
    </div>
  );
}

export default OwnershipForm;
