// Polyfill ES2016/2017 items for IE and other browsers, as needed.
import '@babel/polyfill';
import 'whatwg-fetch';
import './index.scss';
import * as React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import Promise from 'promise-polyfill';
import Raven from 'raven-js';

import store from './redux/reducer';

import VersionCop from './features/versionCop/VersionCop';
import MobileCop from './features/mobileCop/MobileCop';
import ErrorBoundary from './features/errorBoundaries/ErrorBoundary';

import ApplicationSetup from './features/applicationSetup/ApplicationSetup';
import ApplicationContext from './features/applicationContext/ApplicationContext';

import authConfigs from './auth_config.json';
import { Auth0Provider } from 'security';
import {
  history,
  isProduction,
  isResolutionOutofbounds,
  logMessageToSentry,
} from 'utils';

(function startupSanityCheck() {
  // Setup Sentry First
  if (isProduction()) {
    /* eslint-disable no-console */
    try {
      if (process.env.REACT_APP_RAVEN_PUBLIC_DSN) {
        Raven.config(process.env.REACT_APP_RAVEN_PUBLIC_DSN).install();
        console.log('Setup sentry!');
      } else {
        console.error(
          'Expected Sentry config to be present; Sentry setup has failed',
        );
      }
    } catch (e) {
      console.error('Sentry setup failed!', e);
    }
    /* eslint-enable */
  }

  // Check env vars
  const {
    NODE_ENV,
    REACT_APP_RAVEN_PUBLIC_DSN,
    REACT_APP_GIT_COMMIT,
    REACT_APP_DJANGO_LOGOUT_URL,
    REACT_APP_AUTHENTICATION_CHECK_URL,
    REACT_APP_CSRF_URL,
    REACT_APP_DJANGO_LOGIN_URL,
    REACT_APP_DJANGO_INVITE_URL,
  } = process.env;

  const errors: Array<string> = [];

  const isDefined = (name: string, e?: string | null | undefined) => {
    if (e === null || typeof e === 'undefined') {
      errors.push(
        `The env variable ${name} was not defined!  This variable is required`,
      );
    }
  };

  isDefined('NODE_ENV', NODE_ENV);

  if (isProduction()) {
    isDefined('REACT_APP_RAVEN_PUBLIC_DSN', REACT_APP_RAVEN_PUBLIC_DSN);
    isDefined('REACT_APP_GIT_COMMIT', REACT_APP_GIT_COMMIT);
    isDefined('REACT_APP_DJANGO_LOGOUT_URL', REACT_APP_DJANGO_LOGOUT_URL);
    isDefined('REACT_APP_DJANGO_LOGIN_URL', REACT_APP_DJANGO_LOGIN_URL);
    isDefined(
      'REACT_APP_AUTHENTICATION_CHECK_URL',
      REACT_APP_AUTHENTICATION_CHECK_URL,
    );
    isDefined('REACT_APP_CSRF_URL', REACT_APP_CSRF_URL);
    isDefined('REACT_APP_CSRF_URL', REACT_APP_DJANGO_INVITE_URL);
  }

  if (errors.length > 0) {
    const message = `FOUND MULTIPLE ERRORS ON STARTUP: ${errors.join(', ')}`;
    if (isProduction()) {
      // TODO: Report production failure to Slack!
    }

    logMessageToSentry(message);

    // eslint-disable-next-line
    console.error(message);
  }
})();

if (!window.Promise) {
  window.Promise = Promise;
}

const rootElement = document.getElementById('ApplicationRoot');
const authConfig = getDeploymentEnvironment();

const onRedirectCallback = appState => {
  const requestedUrl = appState?.targetUrl;
  const defaultUrl = window.location.pathname;
  history.replace(
    !requestedUrl || requestedUrl === '/v2/logout' ? defaultUrl : requestedUrl,
  );
};

function initializeApplication() {
  if (rootElement) {
    ReactDOM.render(
      <BrowserRouter>
        <LastLocationProvider>
          <Provider store={store}>
            <>
              <VersionCop />
              {isResolutionOutofbounds() && <MobileCop />}
              <ErrorBoundary>
                <Auth0Provider
                  audience={authConfig.audience}
                  client_id={authConfig.clientId}
                  domain={authConfig.domain}
                  onRedirectCallback={onRedirectCallback}
                  redirect_uri={window.location.origin}
                >
                  <ApplicationSetup>
                    <ApplicationContext />
                  </ApplicationSetup>
                </Auth0Provider>
              </ErrorBoundary>
            </>
          </Provider>
        </LastLocationProvider>
      </BrowserRouter>,
      rootElement,
    );
  }
}

function getDeploymentEnvironment() {
  if (!isProduction()) return authConfigs.development;
  if (
    /^(next|lender|joel)-(staging|dev)\.loan-street\.com$/.test(
      window.location.host,
    )
  )
    return authConfigs.staging;
  return authConfigs.production;
}

if (isProduction()) {
  Raven.context(initializeApplication);
} else {
  initializeApplication();
}

const ascii = `%c
______                        _____________                     _____ 
___  / ______ ______ ________ __  ___/__  /______________ _____ __  /_
__  /  _  __ \\_  __ \`/__  __ \\_____ \\ _  __/__  ___/_  _ \\_  _ \\_  __/
_  /___/ /_/ // /_/ / _  / / /____/ / / /_  _  /    /  __//  __// /_  
/_____/\\____/ \\__,_/  /_/ /_/ /____/  \\__/  /_/     \\___/ \\___/ \\__/ 
-----------------------------------------------------------------------`;
// eslint-disable-next-line
console.log(ascii, 'font-family:monospace; color: #419572; font-weight: bold');
