import * as React from 'react';
import {
  FontAwesomeIconTypes,
  IconButton,
} from '@loanstreet-usa/design-system';
import { hoverMethods } from '../../utils';
import DataGridCell from './DataGridCell';
import defaultAccessor from './defaults';
import { Column, Value, DataGridRowState } from './types';

import './DataGridRow.scss';

const baseColumnProps = {
  columnName: '',
  displayName: '',
  readOnly: true,
};

/**
 * A single row of a data grid.
 */

type Props<T, K extends keyof T> = {
  /**
   * The column configurations for the row.
   */
  childRowAccessor?: (
    row: T,
    column: Array<Column<T, K>>,
    index: number,
  ) => Array<T>;
  /**
   * The row value itself.
   */
  columns: Array<Column<T, K>>;
  /**
   * The callback issued when a cell of a row is changed.
   */
  disabled?: boolean;
  index: number;
  isEditing: boolean;
  onBeginEdit: () => void;
  onEndEdit: () => void;
  /**
   * A value that uniquely identifies the row within the entire
   * data grid.
   */
  onRowDelete?: (row: T) => void;
  onValueChanged?: (
    RowKey: K,
    Column: Column<T, K>,
    Value: Value,
    row: T,
    entity?: any,
  ) => void;
  row: T;
  rowKey: K;
  rowKeyValue: any;
};

class DataGridRow<T, K extends keyof T> extends React.Component<
  Props<T, K>,
  DataGridRowState
> {
  handleHoverLeave: () => void;

  handleMouseEnter: () => void;

  handleMouseLeave: () => void;

  handleMouseMove: (e: React.MouseEvent) => void;

  rowElement: React.RefObject<HTMLDivElement> | null | undefined;

  constructor(props: Props<T, K>) {
    super(props);
    this.handleHoverLeave = hoverMethods.handleHoverLeave.bind(this);
    this.handleMouseEnter = hoverMethods.handleMouseEnter.bind(this);
    this.handleMouseLeave = hoverMethods.handleMouseLeave.bind(this);
    this.handleMouseMove = hoverMethods.handleMouseMove.bind(this);

    this.rowElement = React.createRef();
  }

  state = {
    open: false,
    isHovered: false,
  };

  handleOnValueChanged: (
    column: Column<T, K>,
    value: Value,
    entity?: any,
  ) => void = (column: Column<T, K>, value: Value, entity?: any) => {
    if (this.props.onValueChanged) {
      this.props.onValueChanged(
        this.props.rowKeyValue,
        column,
        value,
        this.props.row,
        entity,
      );
    }
  };

  rotation = this.state.open ? undefined : { rotate: -90 };

  handleToggle = () => this.setState(({ open }) => ({ open: !open }));

  handleClickDeleteIcon = () => {
    if (this.props.onRowDelete) this.props.onRowDelete(this.props.row);
  };

  deleteIcon: FontAwesomeIconTypes = 'trash';

  deleteColumn = {
    ...baseColumnProps,
    className: 'DataGridCell-DeleteColumn',
    icon: this.deleteIcon,
    onClick: () => {
      this.handleClickDeleteIcon();
    },
  };

  collapseColumn = {
    ...baseColumnProps,
    renderer: () =>
      this.props.row &&
      // @ts-ignore
      this.props.row.children && (
        <div
          className={`DataGridRow-CollapseColumn DataGridRow-CollapseColumn-Arrow ${
            this.state.open ? 'DataGridRow-CollapseColumn--Open' : ''
          }`}
          onClick={this.handleToggle}
        >
          <IconButton
            alt="▼"
            className="DataGridRow-CollapseColumn-Arrow"
            icon={'caret-down' as FontAwesomeIconTypes}
            onClick={this.handleToggle}
            transform={this.rotation}
          />
        </div>
      ),
  };

  render() {
    const {
      columns,
      disabled,
      row,
      index,
      isEditing,
      onBeginEdit,
      onRowDelete,
      onEndEdit,
      childRowAccessor,
      rowKey,
    } = this.props;
    const { isHovered } = this.state;
    const childRows: T[] = childRowAccessor
      ? childRowAccessor(row, columns, index)
      : [];
    const cellProps = {
      disabled,
      index,
      isEditing,
      onBeginEdit,
      onEndEdit,
      row,
      rowKey,
    };
    return (
      <>
        <div
          className="DataGridRow"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          ref={this.rowElement}
        >
          {childRowAccessor && (
            <DataGridCell {...cellProps} column={this.collapseColumn} />
          )}
          {columns.map((column: Column<T, K>) => (
            <DataGridCell
              {...cellProps}
              column={column}
              key={column.columnName}
              onValueChanged={this.handleOnValueChanged}
              value={
                column.accessor
                  ? column.accessor(row, column, index, this.state)
                  : defaultAccessor(row, column)
              }
            />
          ))}
          {onRowDelete && !disabled && (
            <DataGridCell
              {...cellProps}
              column={this.deleteColumn}
              visible={isHovered}
            />
          )}
        </div>
        {childRows &&
          childRows.map((childRow: T, i: number) => (
            <DataGridRow
              key={childRow[rowKey] as any}
              {...this.props}
              index={i}
              row={childRow}
              rowKey={rowKey}
              rowKeyValue={childRow[rowKey]}
            />
          ))}
      </>
    );
  }
}

export default DataGridRow;
