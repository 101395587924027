import * as React from 'react';
import { noop } from 'lodash';
import { Form } from 'components';
import { PrepaymentPenaltyRangeType } from 'types';

type Props = {
  penaltyRangeSet: ReadonlyArray<PrepaymentPenaltyRangeType> | null | undefined;
};

class FeePrepaymentDisplay extends React.Component<Props> {
  render() {
    const { penaltyRangeSet } = this.props;
    const ranges = penaltyRangeSet || [];
    return (
      <>
        {ranges.map((penaltyRange, index) => (
          <Form.Group
            className="feePrepaymentDisplay"
            key={penaltyRange.id}
            unstackable
          >
            <Form.PercentageReadOnly
              fieldName={index === 0 ? 'Percentage' : ''}
              id={`PenaltyRange${penaltyRange.id}__Penalty`}
              onChange={noop}
              propertyName="penalty"
              value={penaltyRange.penalty}
              width="eight"
            />
            <Form.ReadOnly
              fieldName={index === 0 ? 'Months' : ''}
              id={`PenaltyRange${penaltyRange.id}__Month`}
              onChange={noop}
              propertyName="month"
              value={penaltyRange.month}
              width="eight"
            />
          </Form.Group>
        ))}
      </>
    );
  }
}

export default FeePrepaymentDisplay;
