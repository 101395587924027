import * as React from 'react';
import { LoanTrancheFormProps } from '../../types';
import IndividualGuarantorFields from './IndividualGuarantorFields';
import BusinessGuarantorFields from './BusinessGuarantorFields';
import { Form, IconButton, ConfirmModal } from 'components';
import { ID, LoanTrancheGuarantorType } from 'types';
import { KeyPath } from 'lsredux';

const loanTrancheGuarantor = 'LoanTrancheGuarantor';
const width = '158px';
const largeWidth = '332px';
const custom = 'CUSTOM';

type GuarantorFieldsProps = LoanTrancheFormProps & {
  addEntity: (keyPath: KeyPath, entity: any) => void;
  data: LoanTrancheGuarantorType | null | undefined;
  isSaving: boolean;
  newGuarantorId: ID;
  removeEntity: (keyPath: KeyPath, entity: any) => void;
  replaceEntity: (keyPath: KeyPath, entity: any) => void;
  tabIndex: number;
};

type InputField = {
  fieldName: string;
  onChange: (value: (string | boolean) | null | undefined, fieldId: ID) => void;
  propertyName: string;
  tabIndex: number;
  width: 'eight' | 'sixteen';
};

type SelectField = InputField & {
  typeName: string;
};

type State = {
  isModalOpen: boolean;
};

const initialState: State = {
  isModalOpen: false,
};

class GuarantorFields extends React.Component<GuarantorFieldsProps, State> {
  state = initialState;

  sharedFields: {
    [propName: string]: any;
  };

  constructor(props: GuarantorFieldsProps) {
    super(props);
    const { tabIndex } = this.props;

    this.sharedFields = {
      appliesToAllTranches: {
        propertyName: 'appliesToAllTranches',
        label: 'Applies to new tranches',
        onChange: this.handleChange,
        width,
        tabIndex,
      },
      customGuaranteeText: {
        propertyName: 'customGuaranteeText',
        fieldName: 'Custom Guarantee',
        onChange: this.handleChange,
        width,
        tabIndex,
      },
      customLiabilityText: {
        propertyName: 'customLiabilityText',
        fieldName: 'Custom Liability',
        onChange: this.handleChange,
        width,
        tabIndex,
      },
      contactEmail: {
        fieldName: 'Contact Email',
        onChange: (value?: string) => this.handleChange(value, 'contactEmail'),
        propertyName: 'contactEmail',
        width,
        tabIndex,
      },
      formOfGuarantee: {
        propertyName: 'formOfGuarantee',
        fieldName: 'Form of Guarantee',
        typeName: 'LoanTrancheGuarantorFormOfGuarantee',
        onChange: value =>
          this.handleCustomSelectChange(
            value,
            'formOfGuarantee',
            'customGuaranteeText',
          ),
        width,
        tabIndex,
      },
      guaranteeLimit: {
        propertyName: 'guaranteeLimit',
        fieldName: 'Guarantee Limit',
        onChange: this.handleChange,
        width,
        tabIndex,
      },
      guarantorId: {
        fieldName: 'Guarantor ID',
        onChange: this.handleChange,
        propertyName: 'guarantorId',
        width,
        tabIndex,
      },
      isPerson: {
        propertyName: 'isPerson',
        options: [
          {
            id: 'isPerson',
            label: 'Is a Person',
          },
          {
            id: 'isBusiness',
            label: 'Is a Business Entity',
          },
        ],
        onChange: (value, fieldId) =>
          this.handleChange(value === 'isPerson', fieldId),
        width,
        tabIndex,
      },
      liabilitySharing: {
        propertyName: 'liabilitySharing',
        fieldName: 'Liability Sharing',
        typeName: 'LoanTrancheGuarantorLiabilitySharing',
        onChange: value =>
          this.handleCustomSelectChange(
            value,
            'liabilitySharing',
            'customLiabilityText',
          ),
        width,
        tabIndex,
      },
      notes: {
        propertyName: 'notes',
        fieldName: 'Notes',
        onChange: this.handleChange,
        fluid: true,
        width: largeWidth,
        tabIndex,
      },
    };
  }

  getDefaultGuarantor = (): LoanTrancheGuarantorType => ({
    id: this.props.newGuarantorId,
    isPerson: true,
    appliesToAllTranches: false,
    __typename: 'LoanTrancheGuarantorType',
  });

  getGuarantorData = (): LoanTrancheGuarantorType =>
    this.props.data ? this.props.data : this.getDefaultGuarantor();

  handleCancelDelete = () => this.setState({ isModalOpen: false });

  handleChange = (
    value: (string | null | undefined) | (boolean | null | undefined),
    fieldId: ID,
  ) => {
    const { data } = this.props;

    const field = fieldId.split('_')[0];

    if (data) {
      // edit
      this.props.replaceEntity('loantrancheguarantorSet', {
        ...data,
        [field]: value,
      });
    } else {
      // add
      this.props.addEntity('loantrancheguarantorSet', {
        ...{
          id: this.props.newGuarantorId,
          __typename: 'LoanTrancheGuarantorType',
        },
        [field]: value,
      });
    }
  };

  handleConfirmDelete = () => this.setState({ isModalOpen: true });

  handleCustomSelectChange = (
    value: (string | null | undefined) | (boolean | null | undefined),
    propertyName: string,
    customField: string,
  ) => {
    const { data } = this.props;

    if (data && data[propertyName] !== custom) {
      this.props.replaceEntity('loantrancheguarantorSet', {
        ...data,
        [propertyName]: value,
        [customField]: null,
      });
    } else {
      this.handleChange(value, propertyName);
    }
  };

  handleDelete = () => {
    this.props.removeEntity('loantrancheguarantorSet', this.props.data);
  };

  render() {
    const { cleanData } = this.props;
    const data = this.getGuarantorData();
    const props = { ...this.props, data };
    const id: ID = data ? data.id : props.newGuarantorId;

    return (
      <div className="GuarantorsRow">
        <Form.Group>
          {Form.FieldRenderer(
            Form.ReferenceSelect,
            {
              id: 'formOfGuarantee',
              ...this.sharedFields.formOfGuarantee,
            },
            props,
            loanTrancheGuarantor,
            id,
          )}
          {Form.FieldRenderer(
            Form.Money,
            {
              id: 'guaranteeLimit',
              ...this.sharedFields.guaranteeLimit,
            },
            props,
            loanTrancheGuarantor,
            id,
          )}
          {id !== this.props.newGuarantorId && (
            <IconButton
              alt="Remove Guarantor"
              className="GuarantorFields-DeleteButton"
              icon="trash"
              onClick={this.handleConfirmDelete}
            />
          )}
          <ConfirmModal
            header="Warning"
            isOpen={this.state.isModalOpen}
            message={
              cleanData.name
                ? `Are you sure you want to permanently remove this guarantor from ${cleanData.name}?`
                : 'Are you sure you want to permanently remove this guarantor?'
            }
            onConfirm={this.handleDelete}
            onReject={this.handleCancelDelete}
          />
        </Form.Group>
        <Form.Group>
          {data.formOfGuarantee === custom
            ? Form.FieldRenderer(
                Form.Input,
                {
                  id: 'customGuaranteeText',
                  ...this.sharedFields.customGuaranteeText,
                },
                props,
                loanTrancheGuarantor,
                id,
              )
            : null}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.ReferenceSelect,
            {
              id: 'liabilitySharing',
              ...this.sharedFields.liabilitySharing,
            },
            props,
            loanTrancheGuarantor,
            id,
          )}
          {data.liabilitySharing === custom
            ? Form.FieldRenderer(
                Form.Input,
                {
                  id: 'customLiabilityText',
                  ...this.sharedFields.customLiabilityText,
                },
                props,
                loanTrancheGuarantor,
                id,
              )
            : null}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Radio,
            {
              id: 'isPerson',
              value: data.isPerson ? 'isPerson' : 'isBusiness',
              ...this.sharedFields.isPerson,
            },
            props,
            loanTrancheGuarantor,
            id,
          )}
        </Form.Group>
        {data.isPerson !== false ? (
          <IndividualGuarantorFields {...props} onChange={this.handleChange} />
        ) : (
          <BusinessGuarantorFields {...props} onChange={this.handleChange} />
        )}
        {props.isFirstTranche ? (
          <Form.Group>
            {Form.FieldRenderer(
              Form.Checkbox,
              {
                id: 'appliesToAllTranches',
                value: data.appliesToAllTranches,
                ...this.sharedFields.appliesToAllTranches,
              },
              props,
              loanTrancheGuarantor,
              id,
            )}
          </Form.Group>
        ) : null}
        <Form.Group>
          {Form.FieldRenderer(
            Form.TextArea,
            {
              id: 'notes',
              ...this.sharedFields.notes,
            },
            props,
            loanTrancheGuarantor,
            id,
          )}
        </Form.Group>
      </div>
    );
  }
}

export default GuarantorFields;
