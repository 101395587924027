import * as React from 'react';
import uuid from 'uuid/v4';
import { Auth0Context } from 'security';
import { DocumentType } from 'types';

import { uploadDataroomFile } from 'lsgql';

/* eslint-disable @typescript-eslint/no-unused-vars */

const statusDuration = 5000;
const empty = [];

type State = {
  newFileSet: Set<string>;
  percentMap: Map<string, number>;
  uploadQueue: Array<[File, string]>;
  uploading: boolean;
  uploadingFiles: Array<DocumentType>;
};

export default function DocumentMutationHoC(Wrapped: any) {
  return class DocumentMutationWrapped extends React.Component<any, State> {
    static contextType = Auth0Context;
    uploadFile = uploadDataroomFile;

    constructor(props: any) {
      super(props);
      this.state = {
        uploading: false,
        uploadQueue: [...empty],
        uploadingFiles: empty,
        percentMap: new Map(),
        newFileSet: new Set(),
      };

      this.uploadFile = this.uploadFile.bind(this);
    }

    onCreateDocument = async (
      file: File,
      parentId: string,
      uploadingDocumentId = '',
    ) => {
      const {
        fetchDataroomFiles,
        setAlert,
        fetchDealLatestDocuments,
      } = this.props;

      try {
        const newDocId = await this.uploadFile(
          { file, parentId },
          (ev: ProgressEvent, percent: number) => {
            this.onProgress(uploadingDocumentId, percent);
          },
        );

        this.setState(
          prevState => ({
            newFileSet: new Set(prevState.newFileSet).add(newDocId.toString()),
          }),
          () => {
            setTimeout(() => {
              this.onStatusExpire(newDocId.toString());
            }, statusDuration);
          },
        );

        await fetchDataroomFiles();

        this.setState(prevState => {
          const percentMap = new Map(prevState.percentMap);
          percentMap.delete(uploadingDocumentId);

          return {
            uploadingFiles: prevState.uploadingFiles.filter(
              file => file.id !== uploadingDocumentId,
            ),
            percentMap,
          };
        });

        return Promise.resolve(newDocId);
      } catch (error) {
        setAlert(error, 'error');
        return Promise.resolve();
      }
    };

    setStateAsync(input: any): Promise<void> {
      return new Promise(resolve => {
        this.setState(input, resolve);
      });
    }

    onCreateDocuments = async (files: Array<File>, parentId: string) => {
      const newQueue = files.map(file => [
        file,
        parentId,
        this.addUploadingDocumentToState(file, parentId),
      ]);
      await this.setStateAsync(prevState => ({
        uploadQueue: [...prevState.uploadQueue, ...newQueue],
      }));
      return this.processUploadQueue();
    };

    processUploadQueue = async () => {
      const { uploadQueue, uploading } = this.state;
      if (!uploading) {
        if (uploadQueue.length) {
          const [first] = uploadQueue;
          await this.onCreateDocument(...first);
          await this.setStateAsync(prevState => ({
            uploadQueue: prevState.uploadQueue.slice(1),
          }));
          return this.processUploadQueue();
        }
        await this.setStateAsync({ uploading: false });
        return Promise.resolve();
      }
      return Promise.resolve();
    };

    onProgress = (id: string, percent: number) => {
      this.setState(prevState => ({
        percentMap: new Map(prevState.percentMap).set(id, percent),
      }));
    };

    onStatusExpire = (id: string) => {
      this.setState(prevState => {
        const newFileSet: Set<string> = new Set(prevState.newFileSet);
        newFileSet.delete(id);
        return { newFileSet };
      });
    };

    addUploadingDocumentToState = (document: File, parentId: string) => {
      const documentUUID: string = uuid();

      const parent: any = {
        id: parentId,
        __typename: 'FolderType',
        permissions: [],
      };

      const documentToBeUploaded: DocumentType = {
        id: documentUUID,
        name: document.name,
        parent,
        __typename: 'DocumentType',
        description: '',
        downloadLink: '',
        fileType: '',
        folderPath: '',
        modified: '',
        path: '',
      };

      this.setState(prevState => ({
        uploadingFiles: [...prevState.uploadingFiles, documentToBeUploaded],
        percentMap: new Map(prevState.percentMap).set(documentUUID, 0),
      }));

      return documentUUID;
    };

    render() {
      const dataroomFiles = this.props.dataroomFiles || empty;
      const files = this.state.uploadingFiles
        ? [...this.state.uploadingFiles, ...dataroomFiles]
        : dataroomFiles;

      return (
        <Wrapped
          {...this.props}
          createDocuments={this.onCreateDocuments}
          dataroomFiles={files}
          newFileSet={this.state.newFileSet}
          percentMap={this.state.percentMap}
        />
      );
    }
  };
}
