import React from 'react';
import uuid from 'uuid/v4';
import { Header, Form } from 'components';
import { SbaAccountType } from 'types';

const SbaAccount = 'SbaAccount';
const fields = {
  username: {
    id: 'username',
    propertyName: 'username',
    fieldName: 'Username',
    placeholder: 'Please enter the SBA username',
    width: '392px',
  },
  password: {
    id: 'password',
    propertyName: 'password',
    fieldName: 'Password',
    width: '392px',
  },
  sbaPartnerId: {
    id: 'sbaPartnerId',
    propertyName: 'sbaPartnerId',
    fieldName: 'SBA Partner ID',
    width: '188px',
  },
  lenderLocationId: {
    id: 'lenderLocationId',
    propertyName: 'lenderLocationId',
    fieldName: 'Lender Location ID',
    width: '188px',
    required: true,
  },
  forgivenessAuthToken: {
    id: 'forgivenessAuthToken',
    propertyName: 'forgivenessAuthToken',
    fieldName: 'PPP Forgiveness Authorization Token',
    width: '392px',
  },
};

const initialSbaAccount = {
  id: uuid(),
  __typename: 'SbaAccountType',
};

function SbaAccountForm(props: any) {
  const { data, children } = props;

  const sbaAccount: SbaAccountType = data ?? initialSbaAccount;
  const sbaAccountProps = { ...props, data: sbaAccount };

  // This is 100% copy-pasted from InstitutionBankAccountForm - who knows what is happening
  const handleMutateSbaAccount = (
    value: string | null | undefined,
    fieldId: string,
  ) => {
    if (data) {
      props.replaceEntity('sbaAccount', { ...data, [fieldId]: value });
    } else {
      props.addEntity('sbaAccount', { ...initialSbaAccount, [fieldId]: value });
    }
  };

  const placeholderSbaAccountPassword = sbaAccount.password
    ? '**********'
    : 'Please enter the SBA associated password';

  return (
    <>
      <Form.Group>
        {Form.FieldRenderer(
          Form.Input,
          {
            ...fields.sbaPartnerId,
            onChange: handleMutateSbaAccount,
          },
          sbaAccountProps,
          SbaAccount,
          sbaAccount.id,
        )}
        {Form.FieldRenderer(
          Form.Input,
          {
            ...fields.lenderLocationId,
            onChange: handleMutateSbaAccount,
          },
          sbaAccountProps,
          SbaAccount,
          sbaAccount.id,
        )}
      </Form.Group>
      {/* TODO: SBA-HACK: To render an Institution property within SbaAccount form */}
      {children}
      <Form.Divider />
      <Header as="h5" className="caps steel">
        SBA Account Login
      </Header>
      {Form.FieldRenderer(
        Form.Input,
        {
          ...fields.username,
          onChange: handleMutateSbaAccount,
        },
        sbaAccountProps,
        SbaAccount,
        sbaAccount.id,
      )}
      {Form.FieldRenderer(
        Form.Input,
        {
          ...fields.password,
          onChange: handleMutateSbaAccount,
          placeholder: placeholderSbaAccountPassword,
          required: sbaAccount.username && false,
        },
        sbaAccountProps,
        SbaAccount,
        sbaAccount.id,
      )}
      <Form.Divider />
      <Header as="h5" className="caps steel">
        PPP Info
      </Header>
      {Form.FieldRenderer(
        Form.Input,
        {
          ...fields.forgivenessAuthToken,
          onChange: handleMutateSbaAccount,
        },
        sbaAccountProps,
        SbaAccount,
        sbaAccount.id,
      )}
    </>
  );
}

export default SbaAccountForm;
