import { compose } from 'recompose';
import SbaPppForgivenessWorkflow from './SbaPppForgivenessWorkflow';
import { withRouteParams, RouteParams } from 'routing';
import { SbaPppForgivenessWorkflowQuery } from 'lsgql';
import { withLoadingIndicator } from 'components';

const workflow = compose(
  withRouteParams([RouteParams.dealId]),
  SbaPppForgivenessWorkflowQuery,
  withLoadingIndicator('loading'),
)(SbaPppForgivenessWorkflow);

export default workflow;
