import React from 'react';
import cx from 'classnames';
import { links } from '../utils';
import { TextLink, Icon } from 'components';

const ResourceLink = () => (
  <TextLink.External
    inline
    label="SBA's Loan Forgiveness Application"
    target="newTab"
    to={links.sbaForm.standard}
  />
);

const ForgivenessEzFormLink = () => (
  <TextLink.External
    inline
    label="Loan Forgiveness Application Form 3508EZ"
    target="newTab"
    to={links.sbaForm.ez}
  />
);

const InstructionsForBorrowerFormLink = () => (
  <TextLink.External
    inline
    label="Form 3508 and Instructions"
    target="newTab"
    to={links.instructions.standard}
  />
);

const InstructionsForBorrowerEzFormLink = () => (
  <TextLink.External
    inline
    label="Form 3508EZ and Instructions"
    target="newTab"
    to={links.instructions.ez}
  />
);

const InstructionsForBorrowerSFormLink = () => (
  <TextLink.External
    inline
    label="Form 3508S and Instructions"
    target="newTab"
    to={links.instructions.sForm}
  />
);

const ExcelWorksheetLink = (props: {
  label?: string;
  icon?: boolean;
  link?: string;
}) => {
  const { label, link, icon } = props;
  const className = cx('ExcelLink', { withIcon: icon });

  return (
    <span className={className}>
      {icon && <Icon icon={['fal', 'file-excel']} />}
      <TextLink
        inline={!icon}
        label={label || 'Excel Worksheet'}
        to={link || links.excelWorkbook.standard}
      />
    </span>
  );
};

const ExcelEzWorksheetLink = (props: { label?: string; icon?: boolean }) => (
  <ExcelWorksheetLink link={links.excelWorkbook.ez} {...props} />
);

const SFormExcelLink = (props: { label?: string; icon?: boolean }) => (
  <ExcelWorksheetLink link={links.excelWorkbook.sForm} {...props} />
);

ResourceLink.StandardForm = ResourceLink;
ResourceLink.EzForm = ForgivenessEzFormLink;
ResourceLink.InstructionsForBorrowerForm = InstructionsForBorrowerFormLink;
ResourceLink.InstructionsForBorrowerEzForm = InstructionsForBorrowerEzFormLink;
ResourceLink.InstructionsForBorrowersSForm = InstructionsForBorrowerSFormLink;
ResourceLink.StandardExcel = ExcelWorksheetLink;
ResourceLink.EzExcel = ExcelEzWorksheetLink;
ResourceLink.SFormExcel = SFormExcelLink;

export default ResourceLink;
