import * as React from 'react';
import { RouteTable, RoutePortal, RoutingContext } from 'routing';
import { RenderWithRouter } from 'routing/routes/RouteRenderers';
import { DealHeader, HomeHeader, ProfileHeader, TitleHeader } from './headers';

import './TopbarNavigation.scss';

/* eslint-disable react/display-name */
const routes: RoutingContext = {
  routes: [
    {
      render: () => <TitleHeader name="New Deal" withBreadcrumbs />,
      path: RouteTable.application.toCreateDeal,
    },
    {
      render: RenderWithRouter(DealHeader),
      path: RouteTable.deal.toDeal(),
    },
    {
      render: () => <TitleHeader name="Debug" />,
      path: RouteTable.application.toDebug,
    },
    {
      render: () => <TitleHeader name="Help" />,
      path: RouteTable.application.toHelp,
    },
    {
      render: () => <ProfileHeader />,
      path: RouteTable.application.toProfile,
      exact: true,
    },
    {
      render: () => <TitleHeader name="Profile" withBreadcrumbs />,
      path: RouteTable.user.toUserProfile,
    },
    {
      render: () => <TitleHeader name="Institution" withBreadcrumbs />,
      path: RouteTable.institution.toEdit(),
    },
    {
      render: () => <TitleHeader name="Reports" />,
      path: RouteTable.application.toReports,
    },
    {
      render: () => null,
      path: RouteTable.application.to404,
    },
    {
      render: () => <HomeHeader />,
      path: RouteTable.application.toHome,
    },
  ],
};

function TopbarNavigation() {
  return (
    <>
      <RoutePortal context={routes} />
    </>
  );
}

export default TopbarNavigation;
