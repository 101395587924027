import React from 'react';
import noop from 'lodash/noop';
import { WrappedMediatorFormProps, Form } from 'components';
import { DealType } from 'types';

type DealFormProps = WrappedMediatorFormProps<DealType>;

const width = '158px';

const fields = {
  startDate: {
    id: 'startDate',
    propertyName: 'startDate',
    fieldName: 'Start',
    width,
    helpText: 'The PPP Loan Disbursement date',
    // Always disabled and just set to "Disbursment Date"
    disabled: true,
    disabledReadable: true,
    onChange: noop,
  },
  endDate: {
    id: 'endDate',
    propertyName: 'endDate',
    fieldName: 'End',
    width,
    helpText: 'Between 8 to 24 weeks after Start date',
  },
};

const TypeName = 'SbaPppForgivenessLoanInformation';

function CoveredPeriodSection(props: DealFormProps) {
  const { data } = props;
  const { sbaPppForgivenessWorkflow } = data;
  const { sbapppforgivenessloaninformation } = sbaPppForgivenessWorkflow;

  if (!sbaPppForgivenessWorkflow || !sbapppforgivenessloaninformation) {
    return null;
  }

  const updatedProps = {
    ...props,
    data: sbapppforgivenessloaninformation,
  };

  if (sbapppforgivenessloaninformation.startDate == null) {
    props.mutateProperty(data.fundingDate, 'startDate');
  }

  return (
    <div>
      <Form.Group>
        {Form.FieldRenderer(
          Form.Calendar,
          { ...fields.startDate, onChange: noop },
          updatedProps,
          TypeName,
          updatedProps.data.id,
        )}
        {Form.FieldRenderer(
          Form.Calendar,
          { ...fields.endDate, onChange: props.mutateProperty },
          updatedProps,
          TypeName,
          updatedProps.data.id,
        )}
      </Form.Group>
    </div>
  );
}

export default CoveredPeriodSection;
