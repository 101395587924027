import { mutationBuilder, MutationExecutor } from './builders';

import { CreateInstitutionInvitationsMutationDefinition } from './definitions';
import { inputScrubber } from './utils';
import { InstitutionInvitationsInput, InstitutionInvitationsType } from 'types';

/*
Creates invitations for
    * existing user to be added to the deal as co-lender
    * new user to be added to the deal as co-lender -> creates new institution
    It is not possible to invite a new user to an existing organization
*/
const InstitutionInvitationMutationMethods = {
  createInstitutionInvitations: mutationBuilder<
    InstitutionInvitationsInput,
    InstitutionInvitationsType
  >(CreateInstitutionInvitationsMutationDefinition, {
    build: (
      mutate: MutationExecutor<InstitutionInvitationsType>,
    ) => invitationData => {
      const { invitees, ...rest } = invitationData;

      return mutate({
        variables: {
          invitationData: { ...inputScrubber(rest), id: undefined },
          invitees: invitees.map(i => inputScrubber(i)),
        },
      });
    },
  }),
};

export default InstitutionInvitationMutationMethods;
