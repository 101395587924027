import * as React from 'react';
import { LoanTrancheFormProps } from '../types';
import { isAdjustableRate, isFloatingRate, isLetterOfCredit } from './utils';
import TrancheGroup from './TrancheGroup';
import FixedInterestFields from './interestForm/FixedInterestFields';
import FloatingInterestFields from './interestForm/FloatingInterestFields';
import AdjustableRateInterestFields from './interestForm/AdjustableRateInterestFields';
import { Form } from 'components';

const loanTranche = 'LoanTranche';
const width = 'eight';

class InterestFields extends React.Component<LoanTrancheFormProps> {
  /* eslint-disable-next-line */
  handleUpdateProperty = (
    value: string | null | undefined,
    fieldId: string,
  ) => {
    this.props.onUpdateLoanTrancheRevolverSwinglineLOCData({
      ...this.props.data.loantrancherevolverswinglinelocdata,
      [fieldId]: value,
    });
  };

  fields = {
    drawFee: {
      id: 'drawFee',
      propertyName: 'drawFee',
      fieldName: 'Draw Fee',
      onChange: this.handleUpdateProperty,
      width,
    },
  };

  renderContent = () => {
    const { data } = this.props;

    if (isLetterOfCredit(data)) {
      return (
        <TrancheGroup>
          <Form.Group>
            {Form.FieldRenderer(
              Form.Percentage,
              this.fields.drawFee,
              {
                ...this.props,
                data: this.props.data.loantrancherevolverswinglinelocdata,
              },
              loanTranche,
              data.id,
            )}
          </Form.Group>
        </TrancheGroup>
      );
    }
    if (isFloatingRate(data)) {
      return <FloatingInterestFields {...this.props} />;
    }

    if (isAdjustableRate(data)) {
      return <AdjustableRateInterestFields {...this.props} />;
    }

    return <FixedInterestFields {...this.props} />;
  };

  render() {
    return <TrancheGroup>{this.renderContent()}</TrancheGroup>;
  }
}

export default InterestFields;
