import { mutationBuilder } from './builders';
import {
  CreateSbaPppLoanApplicationMutationDefinition,
  SetSbaPppLoanApplicationMutationDefinition,
} from './definitions';
import {
  SbaPppLoanApplicationInput,
  SbaPppLoanApplicationType,
  SbaPppRoundTwoLenderApplicationInput,
} from 'types';

function scrub(input: SbaPppRoundTwoLenderApplicationInput) {
  return {
    ...input,
    sbaLoanNumber: undefined,
  };
}

const PrivateSbaPppLoanApplicationMutationMethods = {
  createSbaPppLoanApplication: mutationBuilder<
    SbaPppLoanApplicationInput,
    SbaPppLoanApplicationType
  >(CreateSbaPppLoanApplicationMutationDefinition, {
    build: mutate => sbaPppLoanApplicationData =>
      mutate({
        variables: {
          sbaPppLoanApplicationData: scrub(sbaPppLoanApplicationData),
        },
      }),
  }),
  setSbaPppLoanApplication: mutationBuilder<
    SbaPppLoanApplicationInput,
    SbaPppLoanApplicationType
  >(SetSbaPppLoanApplicationMutationDefinition, {
    build: mutate => sbaPppLoanApplicationData =>
      mutate({
        variables: {
          sbaPppLoanApplicationData: scrub(sbaPppLoanApplicationData),
        },
      }),
  }),
};

export default PrivateSbaPppLoanApplicationMutationMethods;
