/**
 * Checks if a string is a valid git commit hash, which is an 8 or 40 digit hexadecimal number
 * @param maybeHash A given string that may or may not be a commit hash
 */
function isCommitHash(maybeHash: string | null | undefined): boolean {
  if (!maybeHash) return false;
  return /\b([a-f0-9]{8}|[a-f0-9]{40})\b/.test(maybeHash);
}

export default isCommitHash;
