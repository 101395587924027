import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import {
  PrincipalTrackersShallowFields,
  LoanServicingMoneyShallowFields,
} from '../fragments';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { PrincipalTrackersType } from 'types';

type OwnProps = {
  dealId: string;
};

const PrincipalTrackersQueryDefinition: DocumentNode = gql`
  query PrincipalTrackersQuery($dealId: ID) {
    principalTrackers(dealId: $dealId) {
      ...PrincipalTrackersShallowFields
      amount {
        ...LoanServicingMoneyShallowFields
      }
      startingBalance {
        ...LoanServicingMoneyShallowFields
      }
      endingBalance {
        ...LoanServicingMoneyShallowFields
      }
    }
  }
  ${PrincipalTrackersShallowFields}
  ${LoanServicingMoneyShallowFields}
`;

export const PrincipalTrackersQuery = standardQueryBuilder(
  PrincipalTrackersQueryDefinition,
  {
    typename: 'PrincipalTrackers',
    skip: (props: OwnProps) => {
      const { dealId } = props;
      return !dealId;
    },
    variables: (props: OwnProps) => {
      return props;
    },
    results: (data: {
      principalTrackers: PrincipalTrackersType | null | undefined;
    }) => {
      const { principalTrackers } = data;
      return { principalTrackers };
    },
  },
);
