import * as React from 'react';
import { FolderWithDate, DataroomContext, ContextPropTypes } from '../../types';
import { MenuRenderProps } from '../../dataroomRow/DataroomRow';
import {
  AccessDeniedGuard,
  FolderPerspectivePermissions,
  DisabledChildProps,
} from 'security';

/* eslint-disable jsx-a11y/label-has-associated-control */

type Props = MenuRenderProps<FolderWithDate> & {
  canDelete?: boolean;
  isOpen?: boolean;
  level?: number;
};

const cursorPointerStyle = {
  cursor: 'pointer',
};

class FolderRowMenu extends React.Component<Props> {
  static contextTypes = ContextPropTypes;

  static defaultProps = {
    canDelete: false,
  };

  getContext = () => this.context as DataroomContext;

  handleCreateNewFolder = () => {
    if (this.props.entity) {
      this.getContext().openFolderModal(
        this.props.entity ? this.props.entity.id : '',
        undefined,
      );
    }
  };

  handleOpenFolderShareModal = () => {
    if (this.props.entity)
      this.getContext().openFolderShareModal(this.props.entity);
  };

  handleDeleteFolder = () => {
    if (this.props.entity)
      this.getContext().deleteFolderModal(this.props.entity);
  };

  handleToggleFolder = () => {
    if (!this.props.isOpen) this.getContext().openFolder(this.props.entity.id);
    else this.getContext().closeFolder(this.props.entity.id);
  };

  renderAdministerMenu = (security: DisabledChildProps) => {
    const { renderItem, level, entity } = this.props;
    return level === 0 && !security.accessDenied && entity.isPrivate
      ? renderItem('Share', this.handleOpenFolderShareModal)
      : null;
  };

  renderDeleteMenu = (security: DisabledChildProps) => {
    const { renderItem } = this.props;
    return !security.accessDenied
      ? renderItem('Delete', this.handleDeleteFolder)
      : null;
  };

  renderChangeMenu = (security: DisabledChildProps) => {
    const { entity, renderItem, onBeginEdit } = this.props;
    return !security.accessDenied ? (
      <>
        <label
          className="item"
          htmlFor={`uploadDataroomFiles+${entity.id}`}
          style={cursorPointerStyle}
        >
          Upload
        </label>
        {renderItem('+ Subfolder', this.handleCreateNewFolder)}
        {renderItem('Rename', onBeginEdit)}
      </>
    ) : null;
  };

  render() {
    const { canDelete } = this.props;
    return (
      <>
        <AccessDeniedGuard
          permission={FolderPerspectivePermissions.administer_folder}
        >
          {this.renderAdministerMenu}
        </AccessDeniedGuard>
        {canDelete && (
          <AccessDeniedGuard
            permission={FolderPerspectivePermissions.delete_folder}
          >
            {this.renderDeleteMenu}
          </AccessDeniedGuard>
        )}
        <AccessDeniedGuard
          permission={FolderPerspectivePermissions.change_folder}
        >
          {this.renderChangeMenu}
        </AccessDeniedGuard>
      </>
    );
  }
}

export default FolderRowMenu;
