import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { alerts } from '../../resources';
import DealForm from './DealForm';
import { DealType, CreditRatingType } from 'types';
import { RouteParams, withRouteParams } from 'routing';
import { RouteTable } from 'routing/RouteTable';
import { FormMediator } from 'components';
import {
  DealPerspectivePermissions,
  GlobalPerspectivePermissions,
} from 'security';
import { DealFormQuery } from 'lsgql';
import dealFormRedux from 'lsredux/reducer/forms/deal';
import { DispatchMethods } from 'lsredux/genericForms';

type CreditRatingMethods = {
  handleRemoveCreditRating: (rating: CreditRatingType) => void;
  updateCreditRating: (rating: CreditRatingType) => void;
};

const mapDispatchToProps: (dispatch: any, ownProps: any) => any = (
  dispatch: any,
) => {
  const bound: DispatchMethods<DealType> = dealFormRedux.actions.generateActions(
    dispatch,
  );

  const additional: CreditRatingMethods = {
    updateCreditRating(rating: CreditRatingType): void {
      bound.replaceEntity('creditratingSet', rating);
    },
    handleRemoveCreditRating(rating: CreditRatingType): void {
      bound.removeEntity('creditratingSet', rating);
    },
  };
  return {
    ...bound,
    ...additional,
  };
};

/* eslint-disable import/prefer-default-export */

export const EditDealFormContainer: React.ComponentType<RouteComponentProps> = compose(
  withRouteParams([RouteParams.dealId]),
  DealFormQuery,
  connect(dealFormRedux.mapStateToProps, mapDispatchToProps),
  FormMediator({
    formId: 'dealForm',
    successAlert: alerts.saveDealSuccess,
    postSaveRoutePath: RouteTable.deal.toLoanOverviewSummary,
    editSecurity: DealPerspectivePermissions.administer_deal,
    baseObjectTypeName: 'Deal',
  }),
)(DealForm);

export const CreateDealFormContainer: React.ComponentType<RouteComponentProps> = compose(
  DealFormQuery,
  connect(dealFormRedux.mapStateToProps, mapDispatchToProps),
  FormMediator({
    formId: 'dealForm',
    successAlert: alerts.saveDealSuccess,
    postSaveRoutePath: RouteTable.deal.toLoanOverviewSummary,
    editSecurity: GlobalPerspectivePermissions.add_deal,
    baseObjectTypeName: 'Deal',
  }),
)(DealForm);
