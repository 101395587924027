import * as React from 'react';
import { getSuffixFromPaymentFrequency } from '../sections/utils';
import CreateAmortizationForm, {
  Args,
  GenericSolveForRowProps,
  GenericAmortizationFormProps,
} from './createLoanTrancheAmortizationForm/CreateLoanTrancheAmortizationForm';

import PaymentRow from './createLoanTrancheAmortizationForm/PaymentRow';
import SolveForRow from './solveForButtons/SolveFor';
import {
  AmortizationColumns,
  TrancheFieldRenderer,
  AmortFieldRenderer,
} from './shared';
import { LoanTrancheAmortizationAmortizationType, FormFieldsType } from 'types';
import { Form, TypeQueryResult } from 'components';

const FieldError = ' ';

/* eslint-disable react/no-multi-comp */

const validAmortizationTypes = new Set<LoanTrancheAmortizationAmortizationType>(
  ['EQUAL_AMORTIZATION', 'LINEAR_AMORTIZATION'],
);

class EqualPrincipalSolveForRow extends React.Component<
  GenericSolveForRowProps
> {
  handleSolveForClick = (value: string) => {
    this.props.handleSolveFor(value);
  };

  render() {
    return (
      <SolveForRow>
        <SolveForRow.Button
          onChange={this.handleSolveForClick}
          text="Calculate"
          value=""
        />
      </SolveForRow>
    );
  }
}

class EqualPrincipalForm extends React.Component<GenericAmortizationFormProps> {
  fields: FormFieldsType = {};

  constructor(props: GenericAmortizationFormProps) {
    super(props);

    this.fields = {
      originalBalance: {
        id: 'initialDrawAmount',
        propertyName: 'initialDrawAmount',
        fieldName: 'Original Balance',
        onChange: this.props.mutateProperty,
        width: 'four',
      },
      originalTerm: {
        id: 'originalTerm',
        propertyName: 'originalTerm',
        fieldName: 'Original Term',
        onChange: this.props.mutateProperty,
        width: 'four',
        suffix: 'years',
      },

      balloonPayment: {
        id: 'balloonPayment',
        propertyName: 'balloonPayment',
        fieldName: 'Final Payment/Balloon',
        onChange: this.props.mutateAmortization,
        width: 'four',
      },
      amortizationType: {
        id: 'amortizationType',
        propertyName: 'amortizationType',
        fieldName: 'Amortization Type',
        onChange: this.props.mutateAmortization,
        typeName: 'LoanTrancheAmortizationAmortizationType',
        width: 'four',
        resultFilter: (res: Array<TypeQueryResult>) =>
          res.filter(e => validAmortizationTypes.has(e.value as any)),
      },
      paymentFrequency: {
        id: 'paymentFrequency',
        propertyName: 'paymentFrequency',
        fieldName: 'Payment Frequency',
        onChange: this.props.mutateProperty,
        typeName: 'LoanTranchePaymentFrequency',
        width: 'four',
      },
      interestOnlyPeriods: {
        id: 'interestOnlyPeriods',
        propertyName: 'interestOnlyPeriods',
        fieldName: 'Interest Only Periods',
        onChange: this.props.mutateAmortization,
        width: 'four',
      },
    };
  }

  render() {
    const { amortization, data } = this.props;
    const amortProps = { ...this.props, data: amortization };
    const queryError = Boolean(
      amortization.readerData && (amortization.readerData as any).hasErrors,
    );

    const { readerData } = amortization;

    return (
      <>
        <Form.Group unstackable>
          {TrancheFieldRenderer(
            Form.Money,
            this.fields.originalBalance,
            this.props,
            amortization.id,
            readerData,
          )}
          {AmortFieldRenderer(
            Form.ReferenceSelect,
            !queryError
              ? this.fields.amortizationType
              : {
                  ...this.fields.amortizationType,
                  error: FieldError,
                },
            amortProps,
            amortization.id,
            readerData,
          )}
        </Form.Group>
        <Form.Group unstackable>
          {TrancheFieldRenderer(
            Form.Decimal,
            this.fields.originalTerm,
            this.props,
            amortization.id,
            readerData,
          )}
          {TrancheFieldRenderer(
            Form.ReferenceSelect,
            this.fields.paymentFrequency,
            this.props,
            amortization.id,
            readerData,
          )}
          {AmortFieldRenderer(
            Form.Money,
            !queryError
              ? this.fields.balloonPayment
              : {
                  ...this.fields.balloonPayment,
                  error: FieldError,
                },
            amortProps,
            amortization.id,
            readerData,
          )}
          {AmortFieldRenderer(
            Form.Numeric,
            !queryError
              ? {
                  ...this.fields.interestOnlyPeriods,
                  suffix: getSuffixFromPaymentFrequency(data),
                }
              : {
                  ...this.fields.interestOnlyPeriods,
                  suffix: getSuffixFromPaymentFrequency(data),
                  error: FieldError,
                },
            amortProps,
            amortization.id,
            readerData,
          )}
        </Form.Group>
      </>
    );
  }
}

const args: Args = {
  columns: [
    AmortizationColumns.count,
    AmortizationColumns.date,
    AmortizationColumns.beginningBalance,
    AmortizationColumns.interestAmount,
    AmortizationColumns.principalPayment,
    AmortizationColumns.endingBalance,
    AmortizationColumns.errorColumn,
  ],
  paymentRowComponent: PaymentRow as any,
  formComponent: EqualPrincipalForm,
  solveForRowComponent: EqualPrincipalSolveForRow,
};

const EqualPrincipalAmortizationModalForm = CreateAmortizationForm(args);

export default EqualPrincipalAmortizationModalForm;
