import { mutationBuilder } from './builders';
import {
  CreateInstitutionBankAccountMutationDefinition,
  SetInstitutionBankAccountMutationDefinition,
  DeleteInstitutionBankAccountMutationDefinition,
} from './definitions';
import {
  ID,
  InstitutionBankAccountInput,
  InstitutionBankAccountType,
} from 'types';

function parseAccountNumberField(data) {
  /*
  The form is using `hiddenAccountNumber` to display the hidden number,
  but the input expects to take `accountNumber` instead. That's why we need
  to parse this before sending it to the backend.
  */

  return {
    ...data,
    accountNumber: data.hiddenAccountNumber,
    hiddenAccountNumber: undefined,
  };
}

const InstitutionBankAccountMutationMethods = {
  createInstitutionBankAccount: mutationBuilder<
    InstitutionBankAccountInput,
    InstitutionBankAccountType
  >(CreateInstitutionBankAccountMutationDefinition, {
    build: mutate => institutionBankAccountData =>
      mutate({
        variables: {
          institutionBankAccountData: parseAccountNumberField(
            institutionBankAccountData,
          ),
        },
      }),
  }),
  setInstitutionBankAccount: mutationBuilder<
    InstitutionBankAccountInput,
    InstitutionBankAccountType
  >(SetInstitutionBankAccountMutationDefinition, {
    build: mutate => institutionBankAccountData =>
      mutate({
        variables: {
          institutionBankAccountData: parseAccountNumberField(
            institutionBankAccountData,
          ),
        },
      }),
  }),
  deleteInstitutionBankAccount: mutationBuilder<ID, void>(
    DeleteInstitutionBankAccountMutationDefinition,
    {
      build: mutate => (id: string) => mutate({ variables: { id } }),
    },
  ),
};

export default InstitutionBankAccountMutationMethods;
