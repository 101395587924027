import React, { useState, useEffect } from 'react';
import { ListChildComponentProps } from 'react-window';
import {
  DealFilters,
  DealRow,
  DealsFooter,
  DealsHeader,
  DownloadButton,
} from '../partials';
import { DealsTableColumnShape, DealsProps, FilterColumnProps } from '../types';
import {
  columnProps,
  statusFilterOptions,
  categoryFilterOptions,
} from '../constants';
import { DataTable, Tiler } from 'components';
import { lsSort, getDealStage, getDealCategory } from 'utils';

import './Deals.scss';

const initialFilterColumns: FilterColumnProps[] = [
  {
    id: 'category',
    placeholder: 'PPP Draw',
    options: categoryFilterOptions,
    value: [],
    idToLabel: (id: string) => getDealCategory(id),
  },
  {
    id: 'stage',
    placeholder: 'Stage',
    options: [],
    value: [],
    idToLabel: (id: string) => getDealStage(id),
  },
  {
    id: 'dealassignee.name',
    placeholder: 'Assignee',
    options: [],
    value: [],
  },
  {
    id: 'status',
    placeholder: 'Status',
    options: statusFilterOptions,
    value: [],
  },
];

const Deals = (props: DealsProps) => {
  const {
    deals = [],
    createDealFavorite,
    deleteDealFavorite,
    sortBy,
    changeSortField,
  } = props;

  const columns: DealsTableColumnShape[] = [
    { ...columnProps.isFavorite(createDealFavorite, deleteDealFavorite) },
    { ...columnProps.created },
    { ...columnProps.borrowerName },
    { ...columnProps.identity },
    { ...columnProps.category },
    { ...columnProps['dealassignee.name'] },
    { ...columnProps.stage },
    { ...columnProps.status },
    { ...columnProps.totalCommitmentAmount },
    { ...columnProps.view },
  ];

  const [filteredDeals, setFilteredDeals] = useState(deals);
  const [sortedDeals, setSortedDeals] = useState(deals);

  useEffect(() => {
    setSortedDeals(lsSort(filteredDeals, sortBy));
  }, [filteredDeals, sortBy]);

  if (!deals?.length) return null;

  const dateRangeProps = {
    placeholder: 'Created',
    propertyName: 'created',
  };

  return (
    deals && (
      <div className="mainSection Deals-MainSection">
        <Tiler className="AllLoans">
          <Tiler.Tile
            minWidth="OneThird"
            secondarySection={() => (
              <>
                <DealFilters
                  dateRangeProps={dateRangeProps}
                  deals={deals}
                  initialColumns={initialFilterColumns}
                  onChange={setFilteredDeals}
                />
                <DownloadButton
                  deals={filteredDeals}
                  lenderName={props.user.institution.name}
                  type="lender"
                />
              </>
            )}
            title="Loans"
          >
            <DataTable>
              <DealsHeader
                columns={columns}
                deals={deals}
                onChangeSortField={changeSortField}
                sortBy={sortBy}
              />
              {sortedDeals.length && (
                <DataTable.Body
                  hasDropdownCells
                  lazyRendering
                  maxHeight="100vh - 316px"
                  totalRows={sortedDeals.length}
                >
                  {(renderProps: ListChildComponentProps) => (
                    <DealRow
                      {...renderProps}
                      columns={columns}
                      deals={sortedDeals}
                    />
                  )}
                </DataTable.Body>
              )}
              <DealsFooter columns={columns} deals={sortedDeals} />
            </DataTable>
            <div className="totalLoans">
              {sortedDeals.length} {sortedDeals.length === 1 ? 'loan' : 'loans'}
            </div>
          </Tiler.Tile>
        </Tiler>
      </div>
    )
  );
};

export default Deals;
