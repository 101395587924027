import React from 'react';
import { Modal, Text } from 'components';

interface Props {
  isOpen: boolean;
  toEzForm: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmSwitchModal = (props: Props) => {
  const { isOpen, toEzForm, onConfirm, onClose } = props;

  const header = toEzForm
    ? 'Confirm Switch to EZ Form'
    : 'Confirm Switch to Standard Form';

  const body = toEzForm ? (
    <>
      <Text>Switching between forms may result in lost data.</Text>
      <br />
      <Text>
        Only entries into the Nonpayroll Business Payment fields will be
        transferred.
      </Text>
    </>
  ) : (
    <>
      <Text>
        The amount you’ve entered into Payroll Costs will not transfer because
        it is a calculated field on the Standard Calculation form.
      </Text>
      <br />
      <Text>
        Entries into the Nonpayroll Business Payment fields will be transferred.
      </Text>
    </>
  );
  return (
    <Modal
      className="steel"
      confirmButtonText="Continue"
      header={header}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      requireResponse
      width="OneThird"
    >
      {body}
    </Modal>
  );
};

export default ConfirmSwitchModal;
