import * as React from 'react';
import { compose } from 'recompose';
import { MutationFunction } from '../types';
import { mutationBuilderHoC, mutationBuilder } from './builders';

import {
  CreateFolderMutationDefinition,
  SetFolderMutationDefinition,
  DeleteFolderMutationDefinition,
} from './definitions';
import { FolderInput, FolderType, ID } from 'types';

/* eslint-disable import/prefer-default-export */

export const FolderMutationMethods = {
  createFolder: mutationBuilder<FolderInput, FolderType>(
    CreateFolderMutationDefinition,
    {
      build: mutate => (folderData: FolderInput) =>
        mutate({
          variables: {
            folderData,
          },
          refetchQueries: ['DataroomFiles'],
        }),
    },
  ),
  setFolder: mutationBuilder<FolderInput, FolderType>(
    SetFolderMutationDefinition,
    {
      build: mutate => (folderData: FolderInput) =>
        mutate({
          variables: {
            folderData,
          },
          refetchQueries: ['DataroomFiles'],
        }),
    },
  ),
  deleteFolder: mutationBuilder<ID, void>(DeleteFolderMutationDefinition, {
    build: mutate => (id: ID) =>
      mutate({
        variables: {
          id,
        },
        refetchQueries: ['DataroomFiles'],
      }),
  }),
};

const CreateFolder = mutationBuilderHoC(CreateFolderMutationDefinition, {
  type: 'CreateFolder',
  cr: (mutate: MutationFunction) => ({
    createFolder: (folder: FolderInput) =>
      mutate({
        variables: {
          folderData: folder,
        },
        refetchQueries: ['DataroomFiles'],
      }),
  }),
});

const SetFolder = mutationBuilderHoC(SetFolderMutationDefinition, {
  type: 'SetFolder',
  cr: (mutate: MutationFunction) => ({
    setFolder: (folder: FolderType) => {
      const folderData: FolderInput = {
        id: folder.id,
        name: folder.name,
        description: folder.description,
        parentId: (folder as any).parentId,
      } as any;

      return mutate({
        variables: {
          folderData,
        },
        refetchQueries: ['DataroomFiles'],
      });
    },
  }),
});

const DeleteFolder = mutationBuilderHoC(DeleteFolderMutationDefinition, {
  type: 'DeleteFolder',
  cr: (mutate: MutationFunction) => ({
    deleteFolder: (id: string) =>
      mutate({
        variables: {
          id,
        },
        refetchQueries: ['DataroomFiles'],
      }),
  }),
});
export const FolderMutations: (arg0: any) => React.ComponentType<{}> = compose(
  CreateFolder,
  SetFolder,
  DeleteFolder,
);
