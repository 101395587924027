import { MutationMethod } from '../types';
import { inputScrubber } from './utils';
import { mutationBuilder } from './builders';
import { MutationExecutor } from './builders/mutationBuilder';
import {
  CreateDealClosingWorkflowMutationDefinition,
  SetDealClosingWorkflowMutationDefinition,
} from './definitions';
import { DealClosingWorkflowInput, DealClosingWorkflowType } from 'types';

const setDealClosingWorkflow: MutationMethod<
  DealClosingWorkflowInput,
  DealClosingWorkflowType
> = mutationBuilder<DealClosingWorkflowInput, DealClosingWorkflowType>(
  SetDealClosingWorkflowMutationDefinition,
  {
    build: (mutate: MutationExecutor<DealClosingWorkflowType>) => (
      dealClosingWorkflowData: DealClosingWorkflowInput,
    ) =>
      mutate({
        variables: {
          dealClosingWorkflowData: inputScrubber(dealClosingWorkflowData),
        },
      }),
  },
);

const createDealClosingWorkflow: MutationMethod<
  DealClosingWorkflowInput,
  DealClosingWorkflowType
> = mutationBuilder<DealClosingWorkflowInput, DealClosingWorkflowType>(
  CreateDealClosingWorkflowMutationDefinition,
  {
    build: (mutate: MutationExecutor<DealClosingWorkflowType>) => (
      dealClosingWorkflowData: DealClosingWorkflowInput,
    ) =>
      mutate({
        variables: {
          dealClosingWorkflowData: inputScrubber(dealClosingWorkflowData),
        },
      }),
  },
);

const DealMutationMethods = {
  createDealClosingWorkflow,
  setDealClosingWorkflow,
};

export default DealMutationMethods;
