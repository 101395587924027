import { mutationBuilder } from './builders';
import { SetPaymentRecordLenderMutationDefinition } from './definitions';
import { PaymentRecordLenderInput, PaymentRecordType } from 'types';

const PaymentRecordLenderMutationMethods = {
  setPaymentRecordLender: mutationBuilder<
    PaymentRecordLenderInput,
    PaymentRecordType
  >(SetPaymentRecordLenderMutationDefinition, {
    build: mutate => paymentRecordLenderData =>
      mutate({ variables: { paymentRecordLenderData } }),
  }),
};

export default PaymentRecordLenderMutationMethods;
