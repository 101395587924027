import { gql } from '@apollo/client';
import { EventDueShallowFields } from '../fragments';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import formQueryBuilder from './queryBuilders/formQueryBuilder';
import { EventDueType } from 'types';

const EventDueQueryDefinition = gql`
  query EventDueQuery($id: ID!) {
    eventDue(id: $id) {
      ...EventDueShallowFields
      loanTranche {
        id
        name
      }
      eventSchedule {
        id
        name
        description
        initialDueDatetime
        initialDueTimezone
        eventFrequency
        reminderOffset
        overdueAlertOffset
        category {
          id
          name
        }
        assignedPeople {
          id
          fullName
        }
      }
    }
  }
  ${EventDueShallowFields}
`;

type OwnProps = {
  dealId: string | null | undefined;
  taskId: string | null | undefined;
};

export const EventDueStandardQuery = standardQueryBuilder(
  EventDueQueryDefinition,
  {
    typename: 'EventDue',
    disableCache: true,
    variables: ({ dealId, taskId }: OwnProps) => {
      return {
        dealId: dealId,
        id: taskId,
      };
    },
    results: (data: { eventDue: EventDueType | null | undefined }) => {
      const { eventDue } = data;
      return { eventDue };
    },
  },
);

export const EventDueFormQuery = formQueryBuilder(EventDueQueryDefinition, {
  typename: 'EventDue',
  variables: ({ dealId, taskId }: OwnProps) => {
    return {
      dealId: dealId,
      id: taskId,
    };
  },
  results: (data: { eventDue: EventDueType | null | undefined }) => {
    const { eventDue } = data;
    return eventDue;
  },
});
