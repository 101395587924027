import React, { useState } from 'react';
import { compose } from 'recompose';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  isFloatingRate,
  isLetterOfCredit,
} from '../../loanTrancheForm/sections/utils';
import { StandardModal, Form, withLoadingIndicator } from 'components';
import {
  DealPerspectivePermissions,
  PermissionsGuard,
  PermissionRequest,
} from 'security';
import { RouteTable, RouteParams, withRouteParams } from 'routing';
import { DealQuery } from 'lsgql';
import { isDealClosed } from 'utils';

import { DealType, PermissionFlags, ID } from 'types';
import {
  ADMINISTER_APPLICABLE_MARGIN_CHANGES,
  ADMINISTER_DRAW_REQUESTS_LENDER,
  ADMINISTER_INTEREST_PERIOD_ELECTIONS,
} from 'security/constants';

import './AddEventModal.scss';

type Props = RouteComponentProps & {
  /* eslint-disable react/no-unused-prop-types */
  deal: DealType;
  dealId: ID;

  /* eslint-enable react/no-unused-prop-types */
  isOpen: boolean;
  toggleModal: () => void;
};

const APPLICABLE_MARGIN_CHANGE = 'APPLICABLE_MARGIN_CHANGE';
const INTEREST_PERIOD_ELECTION = 'INTEREST_PERIOD_ELECTION';
const GENERAL_NOTICE = 'GENERAL_NOTICE';
const DRAW_REQUEST = 'DRAW_REQUEST';

const eventModalPermissionRequest: PermissionRequest[] = [
  {
    definition: DealPerspectivePermissions.administer_applicable_margin_changes,
    flag: ADMINISTER_APPLICABLE_MARGIN_CHANGES,
  },
  {
    definition: DealPerspectivePermissions.administer_draw_requests_lender,
    flag: ADMINISTER_DRAW_REQUESTS_LENDER,
  },
  {
    definition: DealPerspectivePermissions.administer_interest_period_elections,
    flag: ADMINISTER_INTEREST_PERIOD_ELECTIONS,
  },
];

const getRoute = (eventType: string | null | undefined, props: Props) => {
  const dealId = props.dealId;
  switch (eventType) {
    case GENERAL_NOTICE:
      return RouteTable.deal.toGeneralNoticeCreate(dealId);
    default:
      return '';
  }
};

const dealHasFloatingRateTrancheWithMultipleApplicableMargins = (
  props: Props,
) =>
  props.deal &&
  props.deal.loantrancheSet &&
  props.deal.loantrancheSet.some(
    loanTranche =>
      isFloatingRate(loanTranche) &&
      loanTranche.applicablemarginSet &&
      loanTranche.applicablemarginSet.length > 1,
  );
const dealHasNonLetterOfCreditTranche = (props: Props) =>
  props.deal &&
  props.deal.loantrancheSet &&
  props.deal.loantrancheSet.some(loanTranche => !isLetterOfCredit(loanTranche));

const dealHasFloatingRateTrancheWithMultipleBenchmarks = (props: Props) =>
  props.deal &&
  props.deal.loantrancheSet &&
  props.deal.loantrancheSet.some(
    loanTranche =>
      isFloatingRate(loanTranche) &&
      loanTranche.benchmarkoptionSet &&
      loanTranche.benchmarkoptionSet.length > 1,
  );

function AddEventModal(props: Props) {
  const [selectedEventType, toggleSelectedEventType] = useState(null);
  const { isOpen, toggleModal, deal } = props;

  function handleEventTypeChange(value: string | null | undefined) {
    toggleSelectedEventType(value || null);
  }

  const hasFloatingRateTrancheWithMultipleApplicableMargins = dealHasFloatingRateTrancheWithMultipleApplicableMargins(
    props,
  );
  const hasFloatingRateTrancheWithMultipleBenchmarks = dealHasFloatingRateTrancheWithMultipleBenchmarks(
    props,
  );
  const hasNonLetterOfCreditTranche = dealHasNonLetterOfCreditTranche(props);

  const eventTypeOptions = isDealClosed(deal.stage)
    ? [
        {
          text: 'General Notice',
          value: GENERAL_NOTICE,
        },
        hasFloatingRateTrancheWithMultipleApplicableMargins && {
          text: 'Applicable Margin Change',
          value: APPLICABLE_MARGIN_CHANGE,
        },
        hasNonLetterOfCreditTranche && {
          text: 'Draw Request',
          value: DRAW_REQUEST,
        },
        hasFloatingRateTrancheWithMultipleBenchmarks && {
          text: 'Interest Period Election',
          value: INTEREST_PERIOD_ELECTION,
        },
      ].filter(Boolean)
    : [
        {
          text: 'General Notice',
          value: GENERAL_NOTICE,
        },
      ];

  const field = {
    id: 'eventTypeSelect',
    width: '158px',
    onChange: handleEventTypeChange,
    options: eventTypeOptions,
    propertyName: 'eventType',
    fieldName: 'Event Type',
    value: selectedEventType,
    openDirection: 'down',
  };

  function renderSelect(permissionsMap?: Map<PermissionFlags, boolean>) {
    const viewableFields = { ...field };
    const hasOptions = viewableFields && viewableFields.options;

    if (permissionsMap && hasOptions) {
      if (permissionsMap.get(ADMINISTER_APPLICABLE_MARGIN_CHANGES)) {
        viewableFields.options = viewableFields.options.filter(
          option => option.value !== APPLICABLE_MARGIN_CHANGE,
        );
      }

      if (permissionsMap.get(ADMINISTER_DRAW_REQUESTS_LENDER)) {
        viewableFields.options = viewableFields.options.filter(
          option => option.value !== DRAW_REQUEST,
        );
      }

      if (permissionsMap.get(ADMINISTER_INTEREST_PERIOD_ELECTIONS)) {
        viewableFields.options = viewableFields.options.filter(
          option => option.value !== INTEREST_PERIOD_ELECTION,
        );
      }
    }

    return <Form.Select {...(viewableFields as any)} />;
  }

  function renderBody() {
    return (
      <Form.Group>
        <PermissionsGuard permissionRequests={eventModalPermissionRequest}>
          {renderSelect}
        </PermissionsGuard>
      </Form.Group>
    );
  }

  function handleConfirm() {
    const newRoute = getRoute(selectedEventType, props);
    if (newRoute) props.history.push(newRoute);
  }

  return (
    <StandardModal
      className="AddEventModal"
      denyConfirm={!selectedEventType}
      header="Add Event"
      isOpen={isOpen}
      onClose={toggleModal}
      onConfirm={handleConfirm}
      width="OneThird"
    >
      {isOpen && renderBody()}
    </StandardModal>
  );
}

export default compose(
  withRouteParams([RouteParams.dealId]),
  DealQuery,
  withLoadingIndicator('loading'),
)(withRouter(AddEventModal));
