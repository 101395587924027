import React, { useState, useEffect } from 'react';
import { ListChildComponentProps } from 'react-window';
import { DataTable, Tiler } from 'components';
import { lsSort } from 'utils';
import { DealRow, DealsFooter, DealsHeader, DownloadButton } from '../partials';
import { DealsTableColumnShape, DealsProps } from '../types';
import { columnProps } from '../constants';

import './BorrowerDeals.scss';

export function BorrowerDeals(props: DealsProps) {
  const { deals = [], sortBy, changeSortField, user } = props;

  const columns: DealsTableColumnShape[] = [
    { ...columnProps.created },
    { ...columnProps.lenderName },
    { ...columnProps.identity },
    { ...columnProps.category },
    { ...columnProps['sbappploanapplication.sbaLoanNumber'] },
    { ...columnProps.stage },
    { ...columnProps.status },
    { ...columnProps.totalCommitmentAmount },
  ];

  const [sortedDeals, setSortedDeals] = useState(deals);

  useEffect(() => {
    setSortedDeals(lsSort(deals, sortBy));
  }, [deals, sortBy]);

  if (!deals?.length) {
    return null;
  }
  return (
    deals && (
      <div className="mainSection BorrowerDeals-MainSection">
        <Tiler className="AllLoans">
          <Tiler.Tile
            minWidth="OneThird"
            secondarySection={() => (
              <DownloadButton
                deals={sortedDeals}
                lenderName={user?.institution?.name}
                type="borrower"
              />
            )}
            title="PPP Loans"
          >
            <DataTable>
              <DealsHeader
                columns={columns}
                deals={deals}
                onChangeSortField={changeSortField}
                sortBy={sortBy}
              />
              {sortedDeals.length && (
                <DataTable.Body
                  hasDropdownCells
                  lazyRendering
                  maxHeight="100vh - 316px"
                  totalRows={sortedDeals.length}
                >
                  {(renderProps: ListChildComponentProps) => (
                    <DealRow
                      {...renderProps}
                      columns={columns}
                      deals={sortedDeals}
                    />
                  )}
                </DataTable.Body>
              )}
              <DealsFooter columns={columns} deals={sortedDeals} />
            </DataTable>
            <div className="totalLoans">
              {sortedDeals.length} {sortedDeals.length === 1 ? 'loan' : 'loans'}
            </div>
          </Tiler.Tile>
        </Tiler>
      </div>
    )
  );
}
