import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import { InvitationType } from '../types';
import { DealJob, InvitationTypes } from '../constants';
import BorrowerInvitationForm from './BorrowerInvitationForm';
import { RouteParams, withRouteParams } from 'routing';
import { isDealClosed } from 'utils';
import { RoleType, ID, DealType, MyUserType } from 'types';
import institutionInviteFormRedux from 'lsredux/reducer/forms/institutionInvitation';
import { FormMediator, withLoadingIndicator } from 'components';
import { DealQuery, RolesQuery } from 'lsgql';

type OwnProps = {
  currentUser: MyUserType;
  deal: DealType;
  dealId: ID;
  dealRole: RoleType;
  invitationTypeSelected: InvitationType;
  isOpen: boolean;
  onClose: () => void;
  onInvitationTypeChange: (arg0: string | null | undefined) => void;
  organizationRole: RoleType;
};

const successAlert = {
  message: 'Successsfully invited borrower to the deal',
  title: 'Invitation Sent',
};

const filterBorrowerRole = (ownProps: any) => {
  const { deal, roles } = ownProps;
  const postClosing = isDealClosed(deal.stage);
  const dealRoleSlug = postClosing
    ? 'deal_borrower_administrator_post_closing'
    : 'deal_borrower_administrator';
  const dealRole = roles.find(role => role.slug === dealRoleSlug);
  const organizationRole = roles.find(
    role => role.slug === 'institution_administrator',
  );
  return {
    dealRole,
    organizationRole,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => {
  const bound = institutionInviteFormRedux.actions.generateActions(dispatch);
  const initializeState = () => {
    const {
      invitationTypeSelected,
      currentUser,
      organizationRole,
      dealRole,
      dealId,
    } = ownProps;

    const inviteeInstitutionId =
      invitationTypeSelected === InvitationTypes.MY_TEAM
        ? currentUser.institution && currentUser.institution.id
        : undefined;

    bound.mutateProperty(dealId, 'dealId');
    bound.addEntity('invitees', {
      id: uuid(),
      dealRoleId: dealRole.id,
      organizationRoleId: organizationRole.id,
      dealJob: DealJob.BORROWER,
      inviteeInstitutionId,
      __typename: 'InstitutionInviteeInput',
    });
  };
  const save = async (refetch: any) => {
    /*
      If the user simply clicks 'Save', rather than 'Done',
      they will not be routed away from the form.  Since this
      form does not have the ability to edit an existing invitation
      we re-initialize redux with blank data after the mutation
      response.  This allows them to create another invitation
    */
    const res = await bound.save(refetch);
    if (res.success) {
      bound.clearState();
      initializeState();
    }

    return res;
  };

  const onClose = async () => {
    ownProps.onClose();
    bound.clearState();
    initializeState();
  };

  const additional = {
    onClose,
    save,
    initializeState,
  };

  return { ...bound, ...additional };
};

const Composed = compose(
  withRouteParams([RouteParams.dealId]),
  RolesQuery,
  DealQuery,
  withLoadingIndicator('loading'),
  withProps(filterBorrowerRole),
  withProps(() => ({
    formQueryRefetch: () => {
      const res = {
        data: {},
        ok: true,
      };

      return Promise.resolve(res);
    },
  })),
  connect(institutionInviteFormRedux.mapStateToProps, mapDispatchToProps),
  FormMediator({
    successAlert,
    formHeader: 'Send Invitation',
    disableFrame: true,
    formId: 'borrowerInvitation',
    baseObjectTypeName: 'InstitutionInvitationsInput' as any,
  }),
)(BorrowerInvitationForm);

export default Composed;
