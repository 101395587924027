import { List, fromJS, isIndexed } from 'immutable';
import { getKeyPath, idKey, flagDirty, entityValidator } from '../methods';
import { EntityPayloadAction } from '../types';
import { invariant } from 'utils';

/* eslint-disable import/prefer-default-export */

/**
 * Add an entity to the collection at the specified keyPath
 * @param {*} state
 * @param {*} action
 */
export function handleAddEntity(
  state: any,
  action: EntityPayloadAction,
): Record<string, any> {
  const { keyPath: pendingKeyPath, entity } = action.payload;
  const keyPath = getKeyPath(pendingKeyPath);
  invariant(entity, 'Cannot add a null/undefined entity');
  entityValidator(entity);

  const pending = flagDirty(entity);

  const target = state.getIn(keyPath);

  /*
  invariant(
  target !== undefined,
  `Property does not exist ${keyPath.join(', ')}`,
  );
  */
  if (isIndexed(target)) {
    const collection: List<any> = state.getIn(keyPath);

    const index = collection.findIndex(x => x.get(idKey) === pending.id);

    invariant(
      index < 0,
      'Found an object with a matching id when attempting to add, use replace for existing objects',
    );
    const updated = collection.push(fromJS(pending));

    return state.setIn(keyPath, updated);
  }

  // treat it like a map
  return state.setIn(keyPath, fromJS(pending));
}
