// !!!!! THIS IS AN AUTO-GENERATED FILE, DO NOT EDIT !!!!!

import { gql } from '@apollo/client';
import { SbaPppApplicantOwnershipInput } from 'types';
import { invariant, strictConvertToInput } from 'utils';
import {
  default as validationBuilder,
  ValidationData,
  AdditionalOptions,
} from './builder/validationBuilder';
import { ApolloQueryResult } from '../types';
import { inputScrubber } from '../mutations/utils';

/* eslint-disable import/prefer-default-export */

const SbaPppApplicantOwnershipValidatorDefinition = gql`
  query validateSbaPppApplicantOwnership(
    $sbaPppApplicantOwnershipData: SbaPppApplicantOwnershipInput
  ) {
    validateSbaPppApplicantOwnership(
      sbaPppApplicantOwnershipData: $sbaPppApplicantOwnershipData
    ) {
      errors
      errorFields {
        name
        value
      }
      warnings
      warningFields {
        name
        value
      }
    }
  }
`;

const propName = 'validateSbaPppApplicantOwnership';

/**
 * Auto-Generated from the GraphQL Schema
 */
export const SbaPppApplicantOwnershipValidator = validationBuilder<
  SbaPppApplicantOwnershipInput
>(SbaPppApplicantOwnershipValidatorDefinition, {
  build: (
    validation: (
      options: AdditionalOptions,
    ) => Promise<ApolloQueryResult<ValidationData>>,
  ) => async (entity: SbaPppApplicantOwnershipInput) => {
    const res: ApolloQueryResult<ValidationData> = await validation({
      variables: {
        sbaPppApplicantOwnershipData: { id: entity.id },
      },
    });
    invariant(
      res.data[propName],
      'Expected res.data to contain validateSbaPppApplicantOwnership',
    );
    return res.data[propName];
  },
});

export const SbaPppApplicantOwnershipEntityValidator = validationBuilder<
  SbaPppApplicantOwnershipInput
>(SbaPppApplicantOwnershipValidatorDefinition, {
  build: (
    validation: (
      options: AdditionalOptions,
    ) => Promise<ApolloQueryResult<ValidationData>>,
  ) => async (entity: SbaPppApplicantOwnershipInput) => {
    const res: ApolloQueryResult<ValidationData> = await validation({
      variables: {
        sbaPppApplicantOwnershipData: strictConvertToInput({
          ...inputScrubber(entity),
          submitted: undefined,
          approved: undefined,
        }),
      },
    });
    invariant(
      res.data[propName],
      'Expected res.data to contain validateSbaPppApplicantOwnership',
    );
    return res.data[propName];
  },
});
