import { MutationMethod } from '../types';
import { mutationBuilder } from './builders';
import {
  SetAlternateBaseRateBenchmarkMutationDefinition,
  CreateAlternateBaseRateBenchmarkMutationDefinition,
  DeleteAlternateBaseRateBenchmarkMutationDefinition,
} from './definitions';
import {
  AlternateBaseRateBenchmarkInput,
  AlternateBaseRateBenchmarkType,
  ID,
} from 'types';

const createAlternateBaseRateBenchmark: MutationMethod<
  AlternateBaseRateBenchmarkInput,
  AlternateBaseRateBenchmarkType
> = mutationBuilder<
  AlternateBaseRateBenchmarkInput,
  AlternateBaseRateBenchmarkType
>(CreateAlternateBaseRateBenchmarkMutationDefinition, {
  build: mutate => alternateBaseRateBenchmarkData =>
    mutate({
      variables: {
        alternateBaseRateBenchmarkData,
      },
    }),
});

const setAlternateBaseRateBenchmark: MutationMethod<
  AlternateBaseRateBenchmarkInput,
  AlternateBaseRateBenchmarkType
> = mutationBuilder<
  AlternateBaseRateBenchmarkInput,
  AlternateBaseRateBenchmarkType
>(SetAlternateBaseRateBenchmarkMutationDefinition, {
  build: mutate => alternateBaseRateBenchmarkData =>
    mutate({
      variables: {
        alternateBaseRateBenchmarkData,
      },
    }),
});

const deleteAlternateBaseRateBenchmark: MutationMethod<
  ID,
  void
> = mutationBuilder<ID, void>(
  DeleteAlternateBaseRateBenchmarkMutationDefinition,
  {
    build: mutate => (id: ID) =>
      mutate({
        variables: {
          id,
        },
      }),
  },
);

const AlternateBaseRateBenchmarkMutationMethods = {
  createAlternateBaseRateBenchmark,
  setAlternateBaseRateBenchmark,
  deleteAlternateBaseRateBenchmark,
};

export default AlternateBaseRateBenchmarkMutationMethods;
