import * as React from 'react';
import { InvitedUsersSection } from '../../../types';
import InviteeRow from './inviteeRow/InviteeRow';
import { lsSort } from 'utils';
import { DisplayTable, Header } from 'components';
import { SortBy, InvitationType } from 'types';

const sectionBaseClass = 'Section';
const sectionRowBaseClass = `${sectionBaseClass}-SectionRow`;

type Props = {
  invitedSection?: InvitedUsersSection;
  sortBy: SortBy;
};

function InvitedSection(props: Props) {
  const { invitedSection, sortBy } = props;

  if (!invitedSection) return null;

  const { invitations = [] } = invitedSection;

  const sortedSet = lsSort(invitations, sortBy);

  return (
    <>
      <DisplayTable.TR className={sectionRowBaseClass}>
        <DisplayTable.TD
          className={`${sectionRowBaseClass}-Contact`}
          colSpan={3}
        >
          <Header as="h2" className={`${sectionRowBaseClass}-Name`}>
            Invited
          </Header>
        </DisplayTable.TD>
      </DisplayTable.TR>
      {sortedSet.map((invitation: InvitationType, index: number) => (
        <InviteeRow index={index} invitation={invitation} key={invitation.id} />
      ))}
    </>
  );
}

export default InvitedSection;
