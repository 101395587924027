import * as React from 'react';
import { noop } from 'lodash';
import cx from 'classnames';
import { ux } from 'utils';
import { Form, IconButton } from 'components';
import { ID, InstitutionInviteeInput, ValidationMap } from 'types';
import { KeyPath } from 'lsredux';

const parentType = 'InstitutionInvitee';

const leadLenderAccess = {
  admin: 'Administrator',
  viewOnly: 'View Only',
};

const colenderAccess = {
  admin: 'Co-Lender Administrator',
  viewOnly: 'Co-Lender View Only',
};

type Props = {
  data: InstitutionInviteeInput;
  disabled?: boolean;
  errors: ValidationMap | null | undefined;
  getRoleId: (args: { context: string; name: string }) => ID;
  isHeader: boolean;
  isSaving: boolean;
  lead?: boolean;
  removeEntity: (keyPath: KeyPath, entity: any) => void;
  replaceEntity: (keyPath: KeyPath, entity: any) => void;
};

class LenderInvitationRow extends React.Component<Props> {
  fields: any;

  constructor(props: Props) {
    super(props);
    const { getRoleId, lead } = this.props;
    const adminRoleId = getRoleId({
      name: lead ? leadLenderAccess.admin : colenderAccess.admin,
      context: 'deal',
    });
    const viewOnlyRoleId = getRoleId({
      name: lead ? leadLenderAccess.viewOnly : colenderAccess.viewOnly,
      context: 'deal',
    });
    this.fields = {
      firstName: {
        id: 'firstName',
        fieldName: 'First Name',
        propertyName: 'firstName',
        width: 'three',
        onChange: this.handleInviteeChange,
      },
      lastName: {
        id: 'lastName',
        fieldName: 'Last Name',
        propertyName: 'lastName',
        width: 'three',
        onChange: this.handleInviteeChange,
      },
      email: {
        id: 'email',
        fieldName: 'Email',
        propertyName: 'email',
        width: 'six',
        onChange: this.handleInviteeChange,
      },
      accessLevelHeader: {
        id: 'accessLevelHeader',
        propertyName: 'accessLevelHeader',
        fieldName: <span>Access</span>,
        onChange: noop,
        width: 'three',
      },
      accessLevel: {
        id: 'dealRoleId',
        propertyName: 'dealRoleId',
        options: [
          {
            id: adminRoleId,
            label: 'Admin',
          },
          {
            id: viewOnlyRoleId,
            label: 'View Only',
          },
        ],
        width: 'eight',
        onChange: this.handleInviteeChange,
      },
    };
  }

  handleInviteeChange = (value: string | null | undefined, fieldId: string) => {
    const { data } = this.props;
    const field = fieldId.split('_')[0];

    this.props.replaceEntity('invitees', { ...data, [field]: value });
  };

  handleDelete = () => {
    const { data } = this.props;
    this.props.removeEntity('invitees', data);
  };

  render() {
    const { data, isHeader, lead } = this.props;
    const isExistingUser = Boolean(data.userId);

    const computedClassName = cx(
      'LenderInvitationRow',
      ux(isHeader, 'isHeader'),
    );

    return (
      <>
        <Form.Group className={computedClassName}>
          {Form.FieldRenderer(
            Form.Input,
            {
              ...this.fields.firstName,
              fieldName: isHeader ? this.fields.firstName.fieldName : '',
              disabled: isExistingUser,
            },
            this.props,
            parentType,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Input,
            {
              ...this.fields.lastName,
              fieldName: isHeader ? this.fields.lastName.fieldName : '',
              disabled: isExistingUser,
            },
            this.props,
            parentType,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Input,
            {
              ...this.fields.email,
              fieldName: isHeader ? this.fields.email.fieldName : '',
              disabled: isExistingUser,
            },
            this.props,
            parentType,
            data.id,
          )}
          <div className="accessLevels">
            {isHeader && (
              <Form.Group className="lsFormGroup--Lowered">
                {Form.FieldRenderer(
                  Form.InputHeader,
                  this.fields.accessLevelHeader,
                  this.props,
                  parentType,
                  data.id,
                )}
              </Form.Group>
            )}
            <Form.Group>
              {Form.FieldRenderer(
                Form.Radio,
                {
                  ...this.fields.accessLevel,
                  value: data.dealRoleId,
                },
                this.props,
                parentType,
                data.id,
              )}
            </Form.Group>
          </div>
          <IconButton.Delete
            alt={lead ? 'Remove Lead Lender' : 'Remove Co-Lender'}
            onClick={this.handleDelete}
          />
        </Form.Group>
      </>
    );
  }
}

export default LenderInvitationRow;
