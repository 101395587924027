import React, { useState } from 'react';
import { compose } from 'recompose';
import { useLocation } from 'react-router-dom';
import InvitationModal from './InvitationModal';
import { Button } from 'components';
import { RouteParams, withRouteParams } from 'routing';
import {
  DealPerspectivePermissions,
  DealPerspectivePermissionSets,
  MountGuard,
  PermissionsGuard,
} from 'security';
import {
  ADMINISTER_COLENDERS,
  ADMINISTER_BORROWERS,
  ADMINISTER_LEAD_LENDERS,
  ADMINISTER_OWN_TEAM,
  ADMINISTER_SERVICING_AGENTS,
} from 'security/constants';
import {
  MyUserQuery,
  DealOrganizationDealRolesQuery,
  QueryResult,
} from 'lsgql';
import {
  ID,
  PermissionFlags,
  MyUserType,
  OrganizationDealRoleType,
} from 'types';

type Props = {
  dealId: string;
  dealOrganizationDealRoles: Array<OrganizationDealRoleType>;
  user: MyUserType;
  fetchDealOrganizationDealRoles: () => Promise<QueryResult<any>>;
};

function getPermissions(permissionsDenied: Map<PermissionFlags, boolean>) {
  const canAdministerColenders = !permissionsDenied.get(ADMINISTER_COLENDERS);
  const canAdministerBorrowers = !permissionsDenied.get(ADMINISTER_BORROWERS);
  const canAdministerOwnTeam = !permissionsDenied.get(ADMINISTER_OWN_TEAM);
  const canAdministerServicingAgents = !permissionsDenied.get(
    ADMINISTER_SERVICING_AGENTS,
  );
  const canAdministerLeadLenders = !permissionsDenied.get(
    ADMINISTER_LEAD_LENDERS,
  );

  return {
    canAdministerColenders,
    canAdministerLeadLenders,
    canAdministerServicingAgents,
    canAdministerBorrowers,
    canAdministerOwnTeam,
  };
}

function buildPermissionRequests(dealId: ID) {
  return [
    {
      definition: DealPerspectivePermissions.administer_colenders,
      flag: ADMINISTER_COLENDERS,
      perspectiveId: dealId,
    },
    {
      definition: DealPerspectivePermissions.administer_borrowers,
      flag: ADMINISTER_BORROWERS,
      perspectiveId: dealId,
    },
    {
      definition: DealPerspectivePermissions.administer_own_team,
      flag: ADMINISTER_OWN_TEAM,
      perspectiveId: dealId,
    },
    {
      definition: DealPerspectivePermissions.administer_servicing_agents,
      flag: ADMINISTER_SERVICING_AGENTS,
      perspectiveId: dealId,
    },
    {
      definition: DealPerspectivePermissions.administer_lead_lenders,
      flag: ADMINISTER_LEAD_LENDERS,
      perspectiveId: dealId,
    },
  ];
}

function InvitationModalWrapper(props: Props) {
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const {
    dealId,
    user,
    dealOrganizationDealRoles,
    fetchDealOrganizationDealRoles,
  } = props;
  const permissionRequests = buildPermissionRequests(dealId);

  function handleOnClose() {
    setOpen(false);
  }

  function handleClick() {
    setOpen(true);
  }

  function renderBody(permissionsDenied: Map<PermissionFlags, boolean>) {
    const currentTab = location.pathname.split('/').slice(-1)[0];
    const permissions = getPermissions(permissionsDenied);

    if (!permissions.canAdministerOwnTeam) return null;
    return (
      <>
        <Button
          className="peopleDealHeaderAddLink"
          label="Invite"
          onClick={handleClick}
        />
        <InvitationModal
          currentTab={currentTab}
          dealOrganizationDealRoles={dealOrganizationDealRoles}
          fetchDealOrganizationDealRoles={fetchDealOrganizationDealRoles}
          isOpen={isOpen}
          onClose={handleOnClose}
          permissions={permissions}
          user={user}
        />
      </>
    );
  }

  return (
    <MountGuard permission={DealPerspectivePermissionSets.administer_people}>
      <PermissionsGuard permissionRequests={permissionRequests as any}>
        {renderBody}
      </PermissionsGuard>
    </MountGuard>
  );
}

export default compose(
  withRouteParams([RouteParams.dealId]),
  MyUserQuery,
  DealOrganizationDealRolesQuery,
)(InvitationModalWrapper);
