import React, { useState } from 'react';
import { compose, withProps } from 'recompose';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { formatDate } from '../../utils';
import { Button, ConfirmModal } from 'components';
import { DealPerspectivePermissions, MountGuard } from 'security';
import {
  DealOrganizationDealRolesQuery,
  ResendInvitationMutation,
  QueryResult,
} from 'lsgql';
import raiseAlert from 'lsredux/raiseAlert';
import { InvitationType } from 'types';

interface Props extends RouteComponentProps {
  fetchDealOrganizationDealRoles: () => Promise<QueryResult<any>>;
  invitation: InvitationType;
}

const permissionMap = {
  myteam: DealPerspectivePermissions.administer_own_team,
  colenders: DealPerspectivePermissions.administer_colenders,
  borrower: DealPerspectivePermissions.administer_borrowers,
  servicingagents: DealPerspectivePermissions.administer_servicing_agents,
  leadlender: DealPerspectivePermissions.administer_lead_lenders,
};

const successAlert = {
  message: 'Your invitation to the deal has been re-sent via email',
  title: 'Sent!',
};

const errorAlert = {
  message: 'Try again, and if unsuccessful please contact LoanStreet support.',
  title: 'Deal Invite Failed.',
};

const sectionBaseClass = 'Section';
const inviteeRowBaseClass = `${sectionBaseClass}-UserRow`;

function renderInfo(invitation: InvitationType) {
  let infoMessage;

  if (invitation && invitation.resent) {
    infoMessage = `Invite re-sent: ${formatDate(invitation.sent)}`;
  } else if (invitation && invitation.sent) {
    infoMessage = `Invite sent: ${formatDate(invitation.sent)}`;
  }

  return (
    <span className={`${inviteeRowBaseClass}-Contact--Info`}>
      {infoMessage}
    </span>
  );
}

function InviteeContactCell(props: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { location, invitation, fetchDealOrganizationDealRoles } = props as any;

  const currentTab = location.pathname.split('/').slice(-1)[0];

  const modalMessage = `Confirm that you would like to re-send the invite via email to ${invitation.fullName ||
    ''} at ${invitation.email || ''}`;

  function handleResendInvite() {
    return () => setIsModalOpen(true);
  }

  function handleSubmit() {
    return async () => {
      const response = await ResendInvitationMutation(invitation.id);

      if (
        response.data &&
        response.data.resendInvitation &&
        response.data.resendInvitation.ok
      ) {
        raiseAlert(successAlert.message, 'success', successAlert.title);
        await fetchDealOrganizationDealRoles();
      } else {
        raiseAlert(errorAlert.message, 'error', errorAlert.title);
      }
    };
  }

  function handleOnClose() {
    return () => setIsModalOpen(false);
  }

  return (
    <>
      {invitation.fullName || invitation.email}
      <MountGuard permission={permissionMap[currentTab]}>
        <Button.Text
          className={`${inviteeRowBaseClass}-Contact--ResendButton`}
          label="Re-send"
          onClick={handleResendInvite()}
        />
        <ConfirmModal
          header="Resend Invite"
          isOpen={isModalOpen}
          message={modalMessage}
          onConfirm={handleSubmit()}
          onReject={handleOnClose()}
        />
      </MountGuard>
      {renderInfo(invitation)}
    </>
  );
}

export default compose(
  withRouter,
  withProps(ownProps => ({ dealId: ownProps.invitation.deal.id })),
  DealOrganizationDealRolesQuery,
)(InviteeContactCell);
