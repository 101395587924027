import * as React from 'react';
import { compose } from 'recompose';
import { CommonProps, getRoleOptions } from './common';
import { RouteParams, withRouteParams } from 'routing';
import { Dropdown } from 'components';
import {
  RolesQuery,
  DealQuery,
  DealOrganizationDealRolesQuery,
  MyUserQuery,
} from 'lsgql';
import { ID } from 'types';

type Props = CommonProps & {
  className?: string;
  inline?: boolean;
  loading?: boolean;
  onChange: (arg0: ID) => void;
  selected: ID;
};

const empty = [];

/**
 * RoleSelect is a Dropdown component that accepts a Permissions context,
 * specified by the roleContext prop, and allows selection of all
 * possible roles for that context
 */
class RoleSelect extends React.Component<Props, { open: boolean }> {
  componentDidMount() {
    this.forceSelection(this.props);
  }
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  handleOnChange = (e: React.ChangeEvent, dropdownData: any) => {
    this.props.onChange(dropdownData.value);
  };

  forceSelection = (localProps: Props) => {
    const { loading, selected } = localProps;

    if (!loading && !selected) {
      const options = getRoleOptions(localProps);

      if (options && options.length > 0) {
        localProps.onChange(options[0].value);
      }
    }
  };

  render() {
    const { selected, className, loading, inline } = this.props;
    const { open } = this.state;
    return (
      <Dropdown
        className={className}
        id="role-select-dropdown"
        inline={inline}
        isOpen={open}
        onChange={this.handleOnChange}
        onToggle={() => this.setState({ open: !open })}
        options={!loading ? getRoleOptions(this.props) : empty}
        value={!loading ? selected : null}
      />
    );
  }
}

const Composed = compose(
  withRouteParams([RouteParams.dealId]),
  DealOrganizationDealRolesQuery,
  MyUserQuery,
  RolesQuery,
  DealQuery,
)(RoleSelect);
export default Composed;
