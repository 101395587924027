import * as React from 'react';
import * as Immutable from 'immutable';

/* eslint-disable react/no-multi-comp,class-methods-use-this */

export type toJS = { toJS: () => any };

type RawImmutable = {
  rawData: any;
};

type LocalState<TConv> = {
  previousConversion: TConv;
  previousRaw: any;
};

/**
 * Accept raw ImmutableJS objects from Redux state, invoke `toJS()` on them,
 * and pass the result as props into the final component.  toJS should never be
 * invoked within `mapStateToProps` asthe performance improvements
 * gained from Immutable + Redux would be lost (See: https://redux.js.org/docs/recipes/UsingImmutableJS.html)
 * @param {*} WrappedComponent
 */
function withToJS<TIn extends RawImmutable, TOut>(WrappedComponent: any) {
  return class WithToJS extends React.Component<TIn, LocalState<TOut>> {
    static convert(rawData: any): LocalState<TOut> {
      const converted: TOut = rawData.toJS();
      return { previousConversion: converted, previousRaw: rawData };
    }

    constructor(props: TIn) {
      super(props);

      // perform the initial conversion
      this.state = WithToJS.convert(props.rawData);
    }

    UNSAFE_componentWillReceiveProps(nextProps: TIn) {
      if (!Immutable.is(nextProps.rawData, this.state.previousRaw)) {
        // if the Immutable object has changed, convert and store
        this.setState(WithToJS.convert(nextProps.rawData));
      }
    }

    render() {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const { rawData, ...rest } = this.props;
      const { previousConversion } = this.state;

      return <WrappedComponent {...rest} {...previousConversion} />;
    }
  };
}

/* eslint-enable */
export default withToJS;
