import { compose } from 'recompose';
import Instructions from './Instructions';
import { SbaPppForgivenessWorkflowQuery } from 'lsgql';
import { withRouteParams, RouteParams } from 'routing';
import { withLoadingIndicator } from 'components';

const container = compose(
  withRouteParams([RouteParams.dealId]),
  SbaPppForgivenessWorkflowQuery,
  withLoadingIndicator('loading'),
)(Instructions);

export default container;
