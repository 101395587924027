import * as React from 'react';
import { LoanTrancheFormProps } from '../types';
import { isLetterOfCredit } from './utils';
import TrancheGroup from './TrancheGroup';
import OtherCovenantItem from './covenantForm/OtherCovenantItem';
import {
  LoanTrancheFormType,
  CovenantType,
  CovenantCovenantType,
  FormFieldsType,
} from 'types';
import { Form, Button } from 'components';
import './CovenantFields.scss';

const loanTranche = 'LoanTranche';
const OTHER = 'OTHER';

class CovenantFields extends React.Component<LoanTrancheFormProps> {
  fields: FormFieldsType = {};

  constructor(props: LoanTrancheFormProps) {
    super(props);

    this.fields = {
      leverageRatio: {
        id: 'leverageRatio',
        propertyName: 'leverageRatio',
        label: 'Leverage Ratio',
        onChange: this.handleChangeLeverageRatio,
        tabIndex: this.props.tabIndex,
      },
      totalIndebtedness: {
        id: 'totalIndebtedness',
        propertyName: 'totalIndebtedness',
        label: 'Total Indebtedness',
        onChange: this.handleChangeTotalIndebtedness,
        tabIndex: this.props.tabIndex,
      },
      interestCoverageRatio: {
        id: 'interestCoverageRatio',
        propertyName: 'interestCoverageRatio',
        label: 'Interest Coverage Ratio',
        onChange: this.handleChangeInterestCoverage,
        tabIndex: this.props.tabIndex,
      },
    };
  }

  hasCovenant = (covenant: CovenantCovenantType, data: LoanTrancheFormType) => {
    if (!data.covenantSet) return false;
    if (data.covenantSet.findIndex(x => x.covenantType === covenant) > -1) {
      return true;
    }
    return false;
  };

  handleChangeTotalIndebtedness = (value: boolean | null | undefined) =>
    this.toggleCovenant(value, 'TOTAL_INDEBTEDNESS');

  handleChangeInterestCoverage = (value: boolean | null | undefined) =>
    this.toggleCovenant(value, 'INTEREST_COVERAGE_RATIO');

  handleChangeLeverageRatio = (value: boolean | null | undefined) =>
    this.toggleCovenant(value, 'LEVERAGE_RATIO');

  toggleCovenant = (
    checked: boolean | null | undefined,
    covenant: CovenantCovenantType,
  ) => {
    if (checked) {
      this.props.addCovenant(covenant, covenant);
    } else {
      // flow fails to identify the type of 'data'
      const { data } = this.props;
      const toRemove = data.covenantSet
        ? data.covenantSet.find(x => x.covenantType === covenant)
        : null;
      if (toRemove) this.props.removeCovenant(toRemove);
    }
  };

  handleAddCovenant = () => {
    this.props.addCovenant(OTHER, '');
  };

  handleMutateCovenant = (covenant: CovenantType) => (
    value: string | null | undefined,
  ) => {
    if (value) {
      this.props.mutateCovenant({ ...covenant, description: value });
    } else {
      this.props.removeCovenant(covenant);
    }
  };

  getOtherCovenants = (): Array<CovenantType> =>
    this.props.data.covenantSet
      ? this.props.data.covenantSet.filter(
          covenant => covenant.covenantType === OTHER,
        )
      : [];

  render() {
    const { data } = this.props;
    const totalIndebtedness = this.hasCovenant('TOTAL_INDEBTEDNESS', data);
    const interestCoverage = this.hasCovenant('INTEREST_COVERAGE_RATIO', data);
    const leverageRatio = this.hasCovenant('LEVERAGE_RATIO', data);
    const otherCovenants = this.getOtherCovenants();

    if (isLetterOfCredit(data)) return null;

    return (
      <TrancheGroup className="Covenant">
        <Form.Group grouped>
          {Form.FieldRenderer(
            Form.Checkbox,
            {
              ...this.fields.totalIndebtedness,
              value: totalIndebtedness,
            },
            this.props,
            loanTranche,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Checkbox,
            { ...this.fields.leverageRatio, value: leverageRatio },
            this.props,
            loanTranche,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Checkbox,
            {
              ...this.fields.interestCoverageRatio,
              value: interestCoverage,
            },
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
        {otherCovenants.map((covenant, idx) => (
          <OtherCovenantItem
            addCovenant={this.props.addCovenant}
            data={covenant}
            disabled={this.props.disabled}
            errors={this.props.errors}
            fieldName={idx ? '' : 'Custom Covenants'}
            isSaving={this.props.isSaving}
            key={covenant.id}
            mutateCovenant={this.props.mutateCovenant}
            removeCovenant={this.props.removeCovenant}
            tabIndex={this.props.tabIndex}
          />
        ))}
        <Button.Text
          label="+ Add Financial Covenant"
          onClick={this.handleAddCovenant}
        />
      </TrancheGroup>
    );
  }
}

export default CovenantFields;
