import React from 'react';
import moment from 'moment';
import { DataTable } from 'components';

interface Props {
  date: string;
  dateFormat?: string;
  status: string;
}
const DateStampedStatusCell = (props: Props) => {
  const { date, status, dateFormat = 'MM/DD/YY' } = props;
  return (
    <DataTable.Cell>
      {moment(date).format(dateFormat)} - {status}
    </DataTable.Cell>
  );
};

export default DateStampedStatusCell;
