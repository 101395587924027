import { gql } from '@apollo/client';
import {
  AdditionalSbaPppBorrowerInformationShallowFields,
  SbaPppLoanApplicationShallowFields,
  DealShallowFields,
  SbaPppApplicantOwnershipShallowFields,
  SbaPppLoanFinancialsShallowFields,
} from '../fragments';
import { DocumentNode } from '../types';
import formQueryBuilder from './queryBuilders/formQueryBuilder';
import { AdditionalSbaPppBorrowerInformationType, DealType } from 'types';
import standardQueryBuilder from 'lsgql/queries/queryBuilders/standardQueryBuilder';

const AdditionalSbaPppBorrowerInformationQueryDefinition: DocumentNode = gql`
  query DealQuery($id: ID) {
    deal(id: $id) {
      ...DealShallowFields
      additionalSbaPppBorrowerInformation {
        ...AdditionalSbaPppBorrowerInformationShallowFields
      }
      sbaPppLoanApplication {
        ...SbaPppLoanApplicationShallowFields
        sbapppapplicantownershipSet {
          ...SbaPppApplicantOwnershipShallowFields
          race
        }
        sbappploanfinancials {
          ...SbaPppLoanFinancialsShallowFields
        }
      }
    }
  }
  ${DealShallowFields}
  ${AdditionalSbaPppBorrowerInformationShallowFields}
  ${SbaPppLoanApplicationShallowFields}
  ${SbaPppApplicantOwnershipShallowFields}
  ${SbaPppLoanFinancialsShallowFields}
`;

export const AdditionalSbaPppBorrowerInformationFormQuery = formQueryBuilder(
  AdditionalSbaPppBorrowerInformationQueryDefinition,
  {
    typename: 'Deal',
    skip: (props: { dealId: string | null | undefined }) => {
      const { dealId } = props;
      return !dealId;
    },
    variables: (props: { dealId: string | null | undefined }) => {
      const { dealId } = props;
      return { id: dealId };
    },
    results: (data: {
      deal: AdditionalSbaPppBorrowerInformationType | null | undefined;
    }) => {
      const { deal } = data;
      return deal;
    },
  },
);

export const AdditionalSbaPppBorrowerInformationQuery = standardQueryBuilder(
  AdditionalSbaPppBorrowerInformationQueryDefinition,
  {
    typename: 'AdditionalSbaPppBorrowerInformationType',
    skip: (props: { dealId: string | null | undefined }) => {
      const { dealId } = props;
      return !dealId;
    },
    variables: (props: { dealId: string | null | undefined }) => {
      const { dealId } = props;
      return { id: dealId };
    },
    results: (data: { deal: DealType | null | undefined }) => {
      return {
        additionalSbaPppBorrowerInformation:
          data?.deal?.additionalSbaPppBorrowerInformation,
      };
    },
  },
);
