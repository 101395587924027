/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import noop from 'lodash/noop';
import { Button } from 'components';
import { DealType } from 'types';
import { haltBubble } from 'utils';
import CancelLoanForm from 'features/cancelLoan/CancelLoanFormContainer';
import {
  MountGuard,
  DealPerspectivePermissions,
  PermissionDefinition,
} from 'security';
import AssignToDropdown from 'features/dealFlowDropdowns/AssignToDropdown';

type MenuOptionID = 'CHANGE_STAGE' | 'ASSIGN_TO' | 'CANCEL_LOAN';

interface MenuOptionProps {
  /** Used for click handling */
  id: MenuOptionID;
  /** Display text of menu item */
  displayText: string;
  /** Mount guard will check for appropriate permission */
  permission: PermissionDefinition;
  /** Element to render if menu item clicked */
  subMenu: React.ReactNode;
}
interface Props {
  deal: DealType;
}

/**
 * This is a menu of collapsed actions that sits in the Header.
 * Clicking on the menu icon will open a Menu of options.
 * Clicking on each menu option will trigger an action, in some cases
 * opening a dropdown sub-menu, and in other cases, opening a modal.
 *
 * Please note that we are not currently using the design system's Popup
 * component, because it does not support click handling within a sub-menu.
 * In contrast, the current implementation of ActionMenu supports
 * sub-menu click handling, but does not portal the popup to the body,
 * as a more versatile component should.
 *
 * Once a solution that incorporates portalling & sub-menu functionality is
 * developed, we can move the popup logic to the design system.
 */
const ActionMenu = (props: Props) => {
  const { deal } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  /** Only one of the subMenu options should be open at once */
  const [subMenuOpen, setSubMenuOpen] = useState<MenuOptionID>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const menuOptions: MenuOptionProps[] = [
    {
      id: 'ASSIGN_TO',
      displayText: 'Assign to...',
      permission: DealPerspectivePermissions.administer_deal_dealassignee,
      subMenu: (
        <AssignToDropdown
          deal={deal}
          dealId={deal.id}
          isOpen
          onEditChange={() => setSubMenuOpen(null)}
          onToggle={noop}
          shouldFetch={subMenuOpen === 'ASSIGN_TO'}
          value={deal.dealassignee?.name}
        />
      ),
    },
    {
      id: 'CANCEL_LOAN',
      displayText: 'Cancel loan',
      permission: DealPerspectivePermissions.administer_cancel_loan,
      subMenu: null,
    },
  ];

  const handleMenuClick = (e: React.SyntheticEvent<any>) => {
    setMenuOpen(!menuOpen);
    haltBubble(e);
  };

  const menuNode = useRef() as React.MutableRefObject<HTMLInputElement>;
  /** Listen for mouseDown & so that we can close the menu
   * if the user clicks outside of the menu
   */
  useEffect(() => {
    const handleMouseDown = (e: any) => {
      /** Check if click is outside of the menu component */
      if (
        !menuNode?.current?.contains(e.target) &&
        subMenuOpen !== 'CANCEL_LOAN'
      ) {
        setMenuOpen(false);
      }
    };
    if (menuOpen) {
      document.addEventListener('mousedown', handleMouseDown, true);
      return () => {
        document.removeEventListener('mousedown', handleMouseDown, true);
        /** Submenu should collapse if parent menu does */
        setSubMenuOpen(null);
      };
    }
  }, [menuOpen]);

  /** subMenuOption changes to null if user selects an item from
   * a subMenu, in which case the menu should also close.
   */
  useEffect(() => {
    if (!subMenuOpen) setMenuOpen(false);
  }, [subMenuOpen]);

  const handleSubMenuClick = (
    e: React.SyntheticEvent<any>,
    option: MenuOptionID,
  ) => {
    haltBubble(e);
    setSubMenuOpen(option);
    if (option === 'CANCEL_LOAN') {
      /** Since modal is portalled, the mouse event listner overrides
       * modal actions unless we close the menu / remove the listener first */
      setMenuOpen(false);
      setIsModalOpen(true);
    }
  };
  return (
    <>
      <MountGuard permission={menuOptions.map(option => option.permission)}>
        <div className="ActionMenu" ref={menuNode}>
          <Button.Secondary
            className="MenuIconButton"
            icon={['fal', 'ellipsis-v']}
            id="MenuIconButton"
            onClick={handleMenuClick}
          />

          {menuOpen && (
            <div className="Popup">
              {menuOptions.map(({ permission, id, displayText, subMenu }) => (
                <MountGuard key={id} permission={permission}>
                  <div
                    className="MenuItem"
                    onClick={e => handleSubMenuClick(e, id)}
                  >
                    {displayText}
                  </div>
                  {subMenuOpen === id && subMenu}
                </MountGuard>
              ))}
            </div>
          )}
        </div>
      </MountGuard>
      <CancelLoanForm
        dealName={deal.name}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default ActionMenu;
