import { gql } from '@apollo/client';
import {
  InstitutionShallowFields,
  EventScheduleTemplateShallowFields,
} from '../fragments';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import formQueryBuilder from './queryBuilders/formQueryBuilder';
import { MyUserType, EventScheduleTemplateType } from 'types';
import { invariant } from 'utils';

const MyUserQueryDefinition = gql`
  query MyUserQuery {
    user {
      id
      dateJoined
      email
      fax
      firstName
      fullName
      isActive
      isStaff
      isSuperuser
      lastLogin
      lastName
      permissions
      primaryPhone
      secondaryPhone
      title
      username
      requiresSecondDrawOnboarding
      institution {
        ...InstitutionShallowFields
      }
      isSingleDealBorrower
      isDealBorrower
      activePortfolioWorkflows
    }
  }
  ${InstitutionShallowFields}
`;

const TemplatesFromMyUserQueryDefinition = gql`
  query MyUserQuery {
    user {
      id
      institution {
        ...InstitutionShallowFields
        eventscheduletemplateSet {
          ...EventScheduleTemplateShallowFields
          eventRepeatsOnWeekly
          eventRepeatsOnQuarterly
        }
      }
    }
  }
  ${InstitutionShallowFields}
  ${EventScheduleTemplateShallowFields}
`;

function convertArrayFieldToString(
  array: ReadonlyArray<any> | null | undefined,
) {
  if (array) {
    return array.map(element => element.toString());
  }
  return [];
}

function cleanEventScheduleTemplates(
  templates: ReadonlyArray<EventScheduleTemplateType>,
) {
  return templates.map(eventScheduleTemplate => ({
    ...eventScheduleTemplate,
    eventRepeatsOnQuarterly: convertArrayFieldToString(
      eventScheduleTemplate.eventRepeatsOnQuarterly,
    ),
    eventRepeatsOnWeekly: convertArrayFieldToString(
      eventScheduleTemplate.eventRepeatsOnWeekly,
    ),
  }));
}

export const TemplatesFromMyUserQuery = standardQueryBuilder(
  TemplatesFromMyUserQueryDefinition,
  {
    addFetchMethod: true,
    typename: 'EventScheduleTemplateType',

    results: (data: { user: MyUserType | null | undefined }) => {
      const { user } = data;
      if (user) {
        invariant(
          user.institution,
          'A user is expected to be a member of an Institution. Current user has none.',
        );

        if (user.institution) {
          return {
            eventscheduletemplateSet: cleanEventScheduleTemplates(
              user.institution.eventscheduletemplateSet || [],
            ),
          };
        }
      }
      return { eventscheduletemplateSet: [] };
    },
  },
);

export const MyUserQuery = standardQueryBuilder(MyUserQueryDefinition, {
  typename: 'MyUser',
  // disableCache: true,
  includePollingMethods: true,
  results: (data: { user: MyUserType | null | undefined }) => {
    const { user } = data;
    if (user) {
      invariant(
        user.institution,
        'A user is expected to be a member of an Institution. Current user has none.',
      );

      /**
       * Change the typename so that mutationsDispatch still works
       */
      return {
        user: { ...user, __typename: 'UserType' } as MyUserType,
      };
    }
    return { user: null };
  },
});

export const MyUserFormQuery = formQueryBuilder(MyUserQueryDefinition, {
  typename: 'User',
  results: (data: { user: MyUserType | null | undefined }) => {
    const { user } = data;
    if (user) {
      /**
       * Change the typename so that mutationsDispatch still works
       */
      return { ...user, __typename: 'UserType' } as MyUserType;
    }
    return null;
  },
});
