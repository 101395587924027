import isEqual from 'lodash/isEqual';
import transform from 'lodash/transform';

import { BaseType } from 'types';

/* eslint-disable no-param-reassign */

/**
 * Shallow diff between two object, using lodash
 * @param  {Object} dirty Object compared
 * @param  {Object} clean   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
function difference<T extends BaseType>(
  dirty: T,
  clean: T,
  whitelistSet?: Set<string> | null | undefined,
): T {
  // @ts-ignore
  return transform(dirty, (result, value, key) => {
    if (
      (whitelistSet && whitelistSet.has(key as any)) ||
      !isEqual(value, clean[key])
    ) {
      result[key] = value;
    }
  });
}

export default difference;
