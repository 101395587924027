export const UPLOAD_START = 'uploading_UPLOAD_START';
export const UPLOAD_SUCCESS_OR_FAILURE = 'uploading_UPLOAD_SUCCESS_OR_FAILURE';
export const uploadingActions = {
  UPLOAD_START,
  UPLOAD_SUCCESS_OR_FAILURE,
};

export function setUploading(uploadType: string) {
  return {
    type: UPLOAD_START,
    payload: {
      uploadType,
    },
  };
}

export function uploadSuccessOrFailure(uploadType: string) {
  return {
    type: UPLOAD_SUCCESS_OR_FAILURE,
    payload: {
      uploadType,
    },
  };
}

export type Action = { payload: Record<string, any>; type: string };
