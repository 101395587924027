import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import CalendarTaskFormModal from '../calendarEventForms/CalendarTaskFormContainer';
import AddEventModal from './AddEventModal';
import { ReduxDirectory } from 'lsredux';
import { Button, ButtonGroup } from 'components';
import { MyUserQuery } from 'lsgql';
import { withToJS } from 'utils';
import { toggleListView as toggleListViewAction } from 'lsredux/actions/calendar';
import { MyUserType } from 'types';

type Props = {
  isListView: boolean;
  onCalendarPage?: boolean;
  toggleListView: () => void;
  user: MyUserType;
};

function CalendarActionButtons(props: Props) {
  const [addEventModalOpen, toggleAddEventModal] = useState(false);
  const [isTaskModalOpen, toggleTaskModalOpen] = useState(false);

  function handleNavigation(id: string) {
    const { isListView, toggleListView } = props;
    if (id === 'CALENDAR') {
      if (isListView) {
        toggleListView();
      }
    } else {
      if (!isListView) {
        toggleListView();
      }
    }
  }

  function handleAddEventClick() {
    toggleAddEventModal(!addEventModalOpen);
  }

  function handleToggleTaskModal() {
    toggleTaskModalOpen(!isTaskModalOpen);
  }

  const { isListView, onCalendarPage, user } = props;

  const toggleProps = {
    options: [
      {
        label: 'Calendar',
        id: 'CALENDAR',
      },
      {
        label: 'List',
        id: 'LIST',
      },
    ],
    onClick: handleNavigation,
    active: isListView ? 'LIST' : 'CALENDAR',
  };

  return (
    <div className="ActionButtons ActionButtons--Calendar">
      <div className="ActionButtons__Item ActionButtons__Item--ListToggle">
        <ButtonGroup {...toggleProps} />
      </div>
      {onCalendarPage && (
        <>
          <Button
            className="ActionButtons__Item"
            label="Add Event"
            onClick={handleAddEventClick}
          />
          <AddEventModal
            isOpen={addEventModalOpen}
            toggleModal={handleAddEventClick}
          />
          <Button
            className="ActionButtons__Item"
            label="Add Task"
            onClick={handleToggleTaskModal}
          />
          <CalendarTaskFormModal
            isOpen={isTaskModalOpen}
            toggleModal={handleToggleTaskModal}
            user={user}
          />
        </>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  rawData: state.getIn(ReduxDirectory.CalendarKeyPath),
});

const mapDispatchToProps = {
  toggleListView: toggleListViewAction,
};

export default compose(
  MyUserQuery,
  connect(mapStateToProps, mapDispatchToProps),
  withToJS,
)(CalendarActionButtons);
