import React from 'react';
import { compose } from 'recompose';

import RoundTwoSecondDrawLenderApplicationForm from './RoundTwoSecondDrawLenderApplicationContainer';
import RoundTwoFirstDrawLenderApplicationForm from '../roundTwoSbaPppLenderApplication/RoundTwoLenderApplicationContainer';

import { withLoadingIndicator } from 'components';
import { RouteParams, withRouteParams } from 'routing';
import { DealQuery } from 'lsgql';
import { DealCategory, DealType } from 'types';

const OriginalAppCategory: DealCategory = 'SBA_PP';
const SecondDraw: DealCategory = 'SBA_PPP_ROUND_TWO_SECOND_DRAW';

function PrivateLenderApplicationFork(props: { deal: DealType }) {
  if (props.deal.category === OriginalAppCategory) {
    return null;
  }
  if (props.deal.category === SecondDraw) {
    return <RoundTwoSecondDrawLenderApplicationForm />;
  }

  return <RoundTwoFirstDrawLenderApplicationForm />;
}

const LenderApplicationForkContainer: any = compose(
  withRouteParams([RouteParams.dealId]),
  DealQuery,
  withLoadingIndicator('loading', 'deal'),
)(PrivateLenderApplicationFork);

export default LenderApplicationForkContainer;
