import * as React from 'react';
import { connect } from 'react-redux';
import { RouteTo } from 'components';
import { RouteTable } from 'routing/RouteTable';
import { ReduxDirectory } from 'lsredux';
import { isBorroweradmin } from 'utils';

type Props = {
  className?: string;
  detailOnly?: boolean;
  headerLogo?: string | null | undefined;
  headerLogoSvg?: string | null | undefined;
  withRouteTo: boolean;
};

function Logo(props: Props) {
  const {
    detailOnly,
    headerLogo,
    headerLogoSvg,
    withRouteTo,
    className,
  } = props;
  if (isBorroweradmin() && !headerLogoSvg && !headerLogo) return null;
  const path = detailOnly
    ? '/img/logo/loan-street-logo-color-detail.svg'
    : '/img/logo/loan-street-logo-white-w-color-detail.svg';

  return withRouteTo ? (
    <RouteTo className={className} to={RouteTable.application.toHome}>
      <img alt="logo" src={headerLogoSvg || headerLogo || path} />
    </RouteTo>
  ) : (
    <img
      alt="logo"
      className={className}
      src={headerLogoSvg || headerLogo || path}
    />
  );
}

const mapStateToProps = state => ({
  headerLogo: state.getIn([
    ...ReduxDirectory.LenderCustomKeyPath,
    'headerLogo',
  ]),
  headerLogoSvg: state.getIn([
    ...ReduxDirectory.LenderCustomKeyPath,
    'headerLogoSvg',
  ]),
});

type OwnProps = {
  className: string;
  detailOnly: boolean;
  withRouteTo: boolean;
};
export default connect(mapStateToProps)(Logo);
