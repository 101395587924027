import uuid from 'uuid/v4';
import { KeyPath } from 'lsredux';
import { invariant } from 'utils';
import {
  FeeSharing,
  FeeType,
  TierFeePercentageType,
  FeeKindType,
  LoanTrancheType,
  SelectOptionType,
} from 'types';

type SelectOption = {
  disabled?: boolean;
  text: string;
  value: string;
};
export const feeSharingDisplayEnum = {
  PRO_RATA: 'Pro Rata',
  NON_PRO_RATA: 'Non-Pro Rata',
  NOT_SHARED: 'Not Shared',
};

export function getFeeSharingDisplayText(
  sharing: FeeSharing | null | undefined,
) {
  if (sharing) {
    return feeSharingDisplayEnum[sharing] || 'Unknown';
  }
  return 'Unknown';
}
const sharingOptions = ['PRO_RATA', 'NON_PRO_RATA', 'NOT_SHARED'];

export const getFeeSharingOptions: (
  feeKind: FeeKindType,
) => Array<SelectOption> = (feeKind: FeeKindType) => {
  const availableOptions = feeKind.sharingOptions || [];
  return sharingOptions.map(shareOption => ({
    text: feeSharingDisplayEnum[shareOption],
    value: shareOption,
    disabled: !availableOptions.includes(shareOption),
  }));
};

const alwaysDisabledFeeKinds = new Set(['discount-to-par', 'fee-to-draw']);

export const getFeeKindOptions = (
  feeKindSlugMap: Map<string, FeeKindType>,
  trancheFeeGroupSetMap: Map<string, Set<string>>,
  loanTranche: LoanTrancheType | null | undefined,
  currentFeeKind: FeeKindType | null | undefined,
) => {
  if (loanTranche && feeKindSlugMap) {
    const currentFeeGroup = currentFeeKind ? currentFeeKind.group : '';
    const feeGroupSetMap = trancheFeeGroupSetMap.get(loanTranche.id);
    if (feeGroupSetMap) {
      return Array.from(feeKindSlugMap.values()).map<SelectOptionType>(
        feeKind => {
          const enabled =
            !alwaysDisabledFeeKinds.has(feeKind.slug) &&
            (feeKind.group === currentFeeGroup ||
              !feeGroupSetMap.has(feeKind.group || '') ||
              feeKind.slug === 'agent');

          return {
            text: feeKind.label || '',
            value: feeKind.slug || '',
            disabled: !enabled,
          };
        },
      );
    }
  }
  return [];
};

export function getKeyPath(
  trancheIndex: string | null | undefined,
  feeIndex?: string,
) {
  invariant(trancheIndex, 'Tranche Index was not found!');
  const keyPath = ['loantrancheSet', trancheIndex, 'feeSet'];

  if (feeIndex) {
    keyPath.push(feeIndex.toString());
  }

  return keyPath;
}

// startdate and applied against source are current mocked
export function getCleanCalculationRule(id?: string) {
  const calculationRule: any = {
    amount: '0',
    appliedRate: '0',
    __typename: 'FeeCalculationRuleType',
    id: id || uuid(),
    isDirty: true,
    startDate: '2018-11-15',
    appliedAgainstSource: 'PRINCIPAL_BALANCE',
  };

  return calculationRule;
}

export const mapTierFeePercentageByTierId = (
  feePercentages: ReadonlyArray<TierFeePercentageType>,
) =>
  feePercentages.reduce(
    (map, feePercentage) =>
      map.set(
        feePercentage.loanTrancheTier ? feePercentage.loanTrancheTier.id : '',
        feePercentage.appliedRate,
      ),
    new Map<string, string | null | undefined>(),
  );

export const generateProRataHandlers = (
  type: 'AMOUNT' | 'PERCENT',
  replaceEntity: (keyPath: KeyPath, entity: FeeType) => void,
) => (trancheKeyPath: KeyPath, fee: FeeType, newValue: string) => {
  const { feecalculationrule } = fee;

  const updatedCalculationRule: any = feecalculationrule
    ? { ...feecalculationrule, isDirty: true }
    : getCleanCalculationRule();

  if (type === 'AMOUNT') {
    updatedCalculationRule.amount = newValue;
  } else if (type === 'PERCENT') {
    updatedCalculationRule.appliedRate = newValue;
  }

  replaceEntity(trancheKeyPath, {
    ...fee,
    feecalculationrule: updatedCalculationRule,
  });
};

export function transformTranchesToFeeGroupSet(
  tranches: ReadonlyArray<LoanTrancheType>,
  feeKindSlugMap: Map<string, FeeKindType>,
) {
  return tranches.reduce((map, tranche) => {
    const feeGroupSet = new Set<string>();
    if (tranche && tranche.feeSet) {
      tranche.feeSet.forEach(fee => {
        const feeKind = feeKindSlugMap.get(fee.feeKindSlug || '');
        if (feeKind) {
          feeGroupSet.add(feeKind.group || '');
        }
      });
    }

    return map.set(tranche.id, feeGroupSet) as Map<string, Set<string>>;
  }, new Map<string, Set<string>>());
}

export const isFeeValid = (
  tranche: LoanTrancheType,
  fee: FeeType | null | undefined,
  feeKind: FeeKindType | null | undefined,
  numberOfTiers: number,
) => {
  if (feeKind && fee) {
    if (feeKind.slug === 'pre-payment-optional') {
      const prepaymentpenaltyrangeSet = tranche.prepaymentpenaltyrangeSet || [];

      let monthsAdded = new Set<string>();
      // make sure all months are unique and all penalty/month values are nonzero
      const prepaymentRangesValid = prepaymentpenaltyrangeSet.every(range => {
        if (
          range.penalty &&
          range.month &&
          range.penalty !== '0' &&
          range.month !== '0' &&
          !monthsAdded.has(String(range.month))
        ) {
          monthsAdded = monthsAdded.add(String(range.month));
          return true;
        }
        return false;
      });

      if (!prepaymentRangesValid || prepaymentpenaltyrangeSet.length === 0) {
        return false;
      }
    }

    if (feeKind.slug === 'agent') {
      if (!fee.customLabel) {
        return false;
      }
    }

    if (fee.sharing === 'NON_PRO_RATA') {
      const tierfeepercentageSet = fee.tierfeepercentageSet || [];
      if (tierfeepercentageSet.length === numberOfTiers) {
        return tierfeepercentageSet.every(tierfeepercentage =>
          Boolean(tierfeepercentage.appliedRate),
        );
      }
      return false;
    }

    if (fee.feecalculationrule) {
      const calculationRule = fee.feecalculationrule;

      return (
        (!feeKind.hasPercentage || Boolean(calculationRule.appliedRate)) &&
        (!feeKind.hasAmount || Boolean(calculationRule.amount)) &&
        (!feeKind.hasFrequency || Boolean(fee.paymentFrequency))
      );
    }
    return !feeKind.hasPercentage && !feeKind.hasAmount;
  }
  return false;
};

export function removeExcessDataFromFee(fee: FeeType, feeKind: FeeKindType) {
  const cleanFee = { ...fee };
  if (fee.sharing !== 'NON_PRO_RATA') {
    cleanFee.tierfeepercentageSet = [];
  }
  if (cleanFee.feecalculationrule) {
    cleanFee.feecalculationrule = {
      ...cleanFee.feecalculationrule,
      amount: feeKind.hasAmount ? cleanFee.feecalculationrule.amount : null,
      appliedRate: feeKind.hasPercentage
        ? cleanFee.feecalculationrule.appliedRate
        : null,
      isDirty:
        !(feeKind.hasAmount && cleanFee.feecalculationrule.amount) ||
        !(feeKind.hasPercentage && cleanFee.feecalculationrule.appliedRate),
    } as any;
  }

  if (!feeKind.hasFrequency) {
    cleanFee.paymentFrequency = undefined;
  }

  if (feeKind.slug !== 'agent') {
    cleanFee.customLabel = '';
  }

  return cleanFee;
}
