// !!!!! THIS IS AN AUTO-GENERATED FILE, DO NOT EDIT !!!!!

import { gql } from '@apollo/client';
import { LoanTrancheInstitutionOwnershipPortionInput } from 'types';
import { invariant, strictConvertToInput } from 'utils';
import {
  default as validationBuilder,
  ValidationData,
  AdditionalOptions,
} from './builder/validationBuilder';
import { ApolloQueryResult } from '../types';
import { inputScrubber } from '../mutations/utils';

/* eslint-disable import/prefer-default-export */

const LoanTrancheInstitutionOwnershipPortionValidatorDefinition = gql`
  query validateLoanTrancheInstitutionOwnershipPortion(
    $loanTrancheInstitutionOwnershipPortionData: LoanTrancheInstitutionOwnershipPortionInput
  ) {
    validateLoanTrancheInstitutionOwnershipPortion(
      loanTrancheInstitutionOwnershipPortionData: $loanTrancheInstitutionOwnershipPortionData
    ) {
      errors
      errorFields {
        name
        value
      }
      warnings
      warningFields {
        name
        value
      }
    }
  }
`;

const propName = 'validateLoanTrancheInstitutionOwnershipPortion';

/**
 * Auto-Generated from the GraphQL Schema
 */
export const LoanTrancheInstitutionOwnershipPortionValidator = validationBuilder<
  LoanTrancheInstitutionOwnershipPortionInput
>(LoanTrancheInstitutionOwnershipPortionValidatorDefinition, {
  build: (
    validation: (
      options: AdditionalOptions,
    ) => Promise<ApolloQueryResult<ValidationData>>,
  ) => async (entity: LoanTrancheInstitutionOwnershipPortionInput) => {
    const res: ApolloQueryResult<ValidationData> = await validation({
      variables: {
        loanTrancheInstitutionOwnershipPortionData: { id: entity.id },
      },
    });
    invariant(
      res.data[propName],
      'Expected res.data to contain validateLoanTrancheInstitutionOwnershipPortion',
    );
    return res.data[propName];
  },
});

export const LoanTrancheInstitutionOwnershipPortionEntityValidator = validationBuilder<
  LoanTrancheInstitutionOwnershipPortionInput
>(LoanTrancheInstitutionOwnershipPortionValidatorDefinition, {
  build: (
    validation: (
      options: AdditionalOptions,
    ) => Promise<ApolloQueryResult<ValidationData>>,
  ) => async (entity: LoanTrancheInstitutionOwnershipPortionInput) => {
    const res: ApolloQueryResult<ValidationData> = await validation({
      variables: {
        loanTrancheInstitutionOwnershipPortionData: strictConvertToInput({
          ...inputScrubber(entity),
          submitted: undefined,
          approved: undefined,
        }),
      },
    });
    invariant(
      res.data[propName],
      'Expected res.data to contain validateLoanTrancheInstitutionOwnershipPortion',
    );
    return res.data[propName];
  },
});
