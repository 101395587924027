import { mutationBuilder } from './builders';
import {
  CreateProspectMutationDefinition,
  SetProspectMutationDefinition,
  DeleteProspectMutationDefinition,
} from './definitions';
import { ProspectInput, ProspectType, ID } from 'types';

const ProspectMutationMethods = {
  createProspect: mutationBuilder<ProspectInput, ProspectType>(
    CreateProspectMutationDefinition,
    {
      build: mutate => prospectData =>
        mutate({
          variables: {
            prospectData,
          },
        }),
    },
  ),
  setProspect: mutationBuilder<ProspectInput, ProspectType>(
    SetProspectMutationDefinition,
    {
      build: mutate => prospectData =>
        mutate({
          variables: {
            prospectData,
          },
        }),
    },
  ),
  deleteProspect: mutationBuilder<ID, void>(DeleteProspectMutationDefinition, {
    build: mutate => id =>
      mutate({
        variables: {
          id,
        },
      }),
  }),
};

export default ProspectMutationMethods;
