import lowerCase from 'lodash/lowerCase';
import words from 'lodash/words';
import upperFirst from 'lodash/upperFirst';
import memoize from 'lodash/memoize';

/**
 * Convert GraphQL enum values in a user friendly format for display
 * @param {*} value
 */
const parseEnum: (valToBeParsed: any) => string = memoize((value: any) => {
  if (!value) return '';

  return words(lowerCase(value))
    .map(x => upperFirst(x))
    .join(' ');
});

export default parseEnum;
