import { gql } from '@apollo/client';
import { DocumentNode } from '../types';

import {
  LoanTrancheAmortizationShallowFields,
  LoanTrancheAmortizationPrincipalPaymentShallowFields,
} from './generated';

const LoanTrancheAmortizationFields: DocumentNode = gql`
  fragment LoanTrancheAmortizationFields on LoanTrancheAmortizationType {
    ...LoanTrancheAmortizationShallowFields
    initialIndicativeBenchmark {
      id
    }
    loantrancheamortizationprincipalpaymentSet {
      ...LoanTrancheAmortizationPrincipalPaymentShallowFields
    }
  }
  ${LoanTrancheAmortizationShallowFields}
  ${LoanTrancheAmortizationPrincipalPaymentShallowFields}
`;

export default LoanTrancheAmortizationFields;
