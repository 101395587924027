import * as React from 'react';
import { compose } from 'recompose';
import { Popup } from 'components';
import { FolderOrganizationFolderRolesQuery } from 'lsgql';
import { OrganizationFolderRoleType } from 'types';

type Props = {
  accessLevel: string;
  folderOrganizationFolderRoles:
    | Array<OrganizationFolderRoleType>
    | null
    | undefined;
};

class FolderAccessPopup extends React.Component<Props> {
  renderTrigger = () => {
    const { accessLevel } = this.props;
    return <span className="folderAccessPopup__Trigger">{accessLevel}</span>;
  };

  renderContent = () => {
    const { folderOrganizationFolderRoles } = this.props;

    if ((folderOrganizationFolderRoles || []).length === 0) {
      return <div>None</div>;
    }

    const commaSeparatedList = (folderOrganizationFolderRoles || []).reduce(
      (final, folderOrg, index) => {
        if (folderOrg && folderOrg.institution) {
          const orgName = folderOrg.institution.name;
          return `${index === 0 ? '' : `${final},`} ${orgName || ''}`;
        }
        return final;
      },
      '',
    );

    return <div>{commaSeparatedList}</div>;
  };

  render() {
    const { accessLevel, folderOrganizationFolderRoles } = this.props;
    if (accessLevel === 'Not Shared' || !folderOrganizationFolderRoles) {
      return this.renderTrigger();
    }

    return (
      <Popup on="hover" position="right center" trigger={this.renderTrigger()}>
        {this.renderContent()}
      </Popup>
    );
  }
}

export default compose(FolderOrganizationFolderRolesQuery)(FolderAccessPopup);
