import * as React from 'react';
import './InvitationContentFrame.scss';
import { connect } from 'react-redux';
import cx from 'classnames';
import Logo from '../logo/Logo';
import { ReduxDirectory } from 'lsredux';
import { ux } from 'utils';

type Props = {
  actionContent: React.ReactNode;
  informationalContent: React.ReactNode;
  isSubdomain: boolean;
};

type OwnProps = {
  actionContent: React.ReactNode;
  informationalContent: React.ReactNode;
};

class InvitationContentFrame extends React.Component<Props> {
  render() {
    const { actionContent, informationalContent, isSubdomain } = this.props;
    const customHeaderClass = cx(
      'invitationContentFrame__LogoBackground',
      ux(isSubdomain, 'invitationContentFrame__LogoBackground--custom'),
    );
    return (
      <div className="invitationContentFrame">
        <div className="invitationContentFrame__ActionContent">
          <div className={customHeaderClass}>
            <div className="logo-wrapper">
              <Logo className="logo" withRouteTo={false} />
            </div>
          </div>
          <div className="invitationContentFrame__Form">{actionContent}</div>
        </div>

        <div className="invitationContentFrame__InformationalContent">
          {informationalContent}
        </div>
      </div>
    );
  }
}

const mapStateToProps: (state: any) => { isSubdomain: boolean } = state => ({
  isSubdomain: state.getIn([
    ...ReduxDirectory.LenderCustomKeyPath,
    'isSubdomain',
  ]),
});

export default connect(mapStateToProps)(InvitationContentFrame);
