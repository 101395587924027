import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import {
  GeneralNoticeShallowFields,
  DocumentShallowFields,
  LoanTrancheBasicFields,
} from '../fragments';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import formQueryBuilder from './queryBuilders/formQueryBuilder';
import { GeneralNoticeType, ID } from 'types';

type OwnProps = {
  generalNoticeId: string;
};

type GeneralNoticeWithWritableArray = GeneralNoticeType & {
  loanTranches: Array<ID>;
};

const GeneralNoticeQueryDefinition: DocumentNode = gql`
  query GeneralNoticeQuery($id: ID) {
    generalNotice(id: $id) {
      ...GeneralNoticeShallowFields
      loanTranches {
        ...LoanTrancheBasicFields
        name
      }
      documents {
        ...DocumentShallowFields
      }
    }
  }
  ${DocumentShallowFields}
  ${GeneralNoticeShallowFields}
  ${LoanTrancheBasicFields}
`;

export const GeneralNoticeQuery = standardQueryBuilder(
  GeneralNoticeQueryDefinition,
  {
    typename: 'GeneralNotice',
    skip: ({
      generalNoticeId,
    }: {
      generalNoticeId: string | null | undefined;
    }) => !generalNoticeId,
    variables: ({
      generalNoticeId,
    }: {
      generalNoticeId: string | null | undefined;
    }) => ({
      id: generalNoticeId,
    }),
    results: (data: {
      generalNotice: GeneralNoticeWithWritableArray | null | undefined;
    }) => {
      const { generalNotice } = data;
      let loanTranches = [];
      if (generalNotice) {
        loanTranches =
          generalNotice.loanTranches &&
          generalNotice.loanTranches.map(loanTranche => {
            if (typeof loanTranche === 'string') return loanTranche;
            return loanTranche.id;
          });
      }
      return { generalNotice, loanTranches };
    },
  },
);

export const GeneralNoticeFormQuery = formQueryBuilder(
  GeneralNoticeQueryDefinition,
  {
    typename: 'GeneralNotice',
    skip: (props: OwnProps) => {
      const { generalNoticeId } = props;
      return !generalNoticeId;
    },
    variables: (props: OwnProps) => {
      const { generalNoticeId } = props;
      return { id: generalNoticeId };
    },
    results: (data: {
      generalNotice: GeneralNoticeWithWritableArray | null | undefined;
    }) => {
      const { generalNotice } = data;
      let loanTranches = [];
      if (generalNotice) {
        loanTranches =
          generalNotice.loanTranches &&
          generalNotice.loanTranches.map(loanTranche => {
            if (typeof loanTranche === 'string') return loanTranche;
            return loanTranche.id;
          });
      }
      return { ...generalNotice, loanTranches, isLeaf: true };
    },
  },
);
