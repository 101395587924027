import {
  ADMINISTER_COLENDERS,
  ADMINISTER_BORROWERS,
  ADMINISTER_LEAD_LENDERS,
  ADMINISTER_OWN_TEAM,
  ADMINISTER_SERVICING_AGENTS,
  ADMINISTER_APPLICABLE_MARGIN_CHANGES,
  ADMINISTER_CALENDAR_INTERNAL,
  ADMINISTER_CALENDAR_PUBLIC,
  ADMINISTER_CANCEL_LOAN,
  ADMINISTER_CLOSING,
  ADMINISTER_DATAROOM_PUBLIC,
  ADMINISTER_DATAROOM_PRIVATE,
  ADMINISTER_DEAL,
  ADMINISTER_DEAL_DEALASSIGNEE,
  ADMINISTER_DEAL_BORROWER,
  ADMINISTER_DEAL_STAGE,
  ADMINISTER_DRAW_REQUESTS_BORROWER,
  ADMINISTER_DRAW_REQUESTS_LENDER,
  ADMINISTER_FEES,
  ADMINISTER_INTEREST_PERIOD_ELECTIONS,
  ADMINISTER_INVESTOR_BOOK,
  ADMINISTER_LENDER_REGISTRY,
  ADMINISTER_LOAN_TRANCHES,
  ADMINISTER_PAYMENTS_BORROWER,
  ADMINISTER_PAYMENTS_LENDER,
  ADMINISTER_TERM_SHEET,
  PERFORM_BORROWER_INTEREST_SELECTIONS,
  PERFORM_BORROWER_UPLOADS,
  VIEW_APPLICABLE_MARGIN_CHANGES,
  VIEW_BORROWERS,
  VIEW_CALENDAR_INTERNAL,
  VIEW_CALENDAR_PUBLIC,
  VIEW_CLOSING,
  VIEW_DATAROOM_PRIVATE,
  VIEW_DATAROOM_PUBLIC,
  VIEW_DEAL,
  VIEW_DEAL_DEALASSIGNEE,
  VIEW_DRAW_REQUESTS,
  VIEW_FEES,
  VIEW_OWN_TEAM,
  VIEW_PAYMENTS,
  VIEW_COLENDERS,
  VIEW_SERVICING_AGENTS,
  VIEW_LEAD_LENDERS,
  VIEW_INVESTOR_BOOK,
  VIEW_LENDER_REGISTRY,
  VIEW_LOAN_TRANCHES,
  VIEW_TERM_SHEET,
  VIEW_HOME_BUTTON,
  ADMINISTER_SBA_PPP_APPLICATION,
  VIEW_SBA_PPP_APPLICATION,
  ADMINISTER_SBA_PPP_FORGIVENESS,
} from '../../constants';
import { PermissionDefinitions } from './types';

const perspective = 'deal';

export const DealPerspectivePermissions: PermissionDefinitions = {
  administer_sba_ppp_forgiveness: {
    flag: ADMINISTER_SBA_PPP_FORGIVENESS,
    perspective,
  },
  administer_sba_ppp_application: {
    flag: ADMINISTER_SBA_PPP_APPLICATION,
    perspective,
  },
  view_sba_ppp_application: {
    flag: VIEW_SBA_PPP_APPLICATION,
    perspective,
  },
  administer_applicable_margin_changes: {
    flag: ADMINISTER_APPLICABLE_MARGIN_CHANGES,
    perspective,
  },
  administer_borrowers: {
    flag: ADMINISTER_BORROWERS,
    perspective,
  },
  administer_cancel_loan: {
    flag: ADMINISTER_CANCEL_LOAN,
    perspective,
  },
  administer_calendar_internal: {
    flag: ADMINISTER_CALENDAR_INTERNAL,
    perspective,
  },
  administer_calendar_public: {
    flag: ADMINISTER_CALENDAR_PUBLIC,
    perspective,
  },
  administer_closing: {
    flag: ADMINISTER_CLOSING,
    perspective,
  },
  administer_dataroom_public: {
    flag: ADMINISTER_DATAROOM_PUBLIC,
    perspective,
  },
  administer_dataroom_private: {
    flag: ADMINISTER_DATAROOM_PRIVATE,
    perspective,
  },
  administer_deal: {
    perspective,
    flag: ADMINISTER_DEAL,
  },
  administer_deal_dealassignee: {
    perspective,
    flag: ADMINISTER_DEAL_DEALASSIGNEE,
  },
  administer_deal_borrower: {
    perspective,
    flag: ADMINISTER_DEAL_BORROWER,
  },
  administer_deal_stage: {
    perspective,
    flag: ADMINISTER_DEAL_STAGE,
  },
  administer_draw_requests_borrower: {
    perspective,
    flag: ADMINISTER_DRAW_REQUESTS_BORROWER,
  },
  administer_draw_requests_lender: {
    perspective,
    flag: ADMINISTER_DRAW_REQUESTS_LENDER,
  },
  administer_fees: {
    flag: ADMINISTER_FEES,
    perspective,
  },
  administer_colenders: {
    flag: ADMINISTER_COLENDERS,
    perspective,
  },
  administer_lead_lenders: {
    flag: ADMINISTER_LEAD_LENDERS,
    perspective,
  },
  administer_servicing_agents: {
    flag: ADMINISTER_SERVICING_AGENTS,
    perspective,
  },
  administer_interest_period_elections: {
    perspective,
    flag: ADMINISTER_INTEREST_PERIOD_ELECTIONS,
  },
  administer_investor_book: {
    perspective,
    flag: ADMINISTER_INVESTOR_BOOK,
  },
  administer_lender_registry: {
    perspective,
    flag: ADMINISTER_LENDER_REGISTRY,
  },
  administer_loan_tranches: {
    perspective,
    flag: ADMINISTER_LOAN_TRANCHES,
  },
  administer_own_team: {
    perspective,
    flag: ADMINISTER_OWN_TEAM,
  },
  administer_payments_borrower: {
    flag: ADMINISTER_PAYMENTS_BORROWER,
    perspective,
  },
  administer_payments_lender: {
    flag: ADMINISTER_PAYMENTS_LENDER,
    perspective,
  },
  administer_term_sheet: {
    flag: ADMINISTER_TERM_SHEET,
    perspective,
  },
  perform_borrower_interest_selections: {
    perspective,
    flag: PERFORM_BORROWER_INTEREST_SELECTIONS,
  },
  perform_borrower_uploads: {
    flag: PERFORM_BORROWER_UPLOADS,
    perspective,
  },
  view_applicable_margin_changes: {
    flag: VIEW_APPLICABLE_MARGIN_CHANGES,
    perspective,
  },
  view_borrowers: {
    flag: VIEW_BORROWERS,
    perspective,
  },
  view_calendar_internal: {
    flag: VIEW_CALENDAR_INTERNAL,
    perspective,
  },
  view_calendar_public: {
    flag: VIEW_CALENDAR_PUBLIC,
    perspective,
  },
  view_closing: {
    flag: VIEW_CLOSING,
    perspective,
  },
  view_dataroom_private: {
    flag: VIEW_DATAROOM_PRIVATE,
    perspective,
  },
  view_dataroom_public: {
    flag: VIEW_DATAROOM_PUBLIC,
    perspective,
  },
  view_deal: {
    flag: VIEW_DEAL,
    perspective,
  },
  view_draw_requests: {
    flag: VIEW_DRAW_REQUESTS,
    perspective,
  },
  view_fees: {
    flag: VIEW_FEES,
    perspective,
  },
  view_own_team: {
    flag: VIEW_OWN_TEAM,
    perspective,
  },
  view_payments: {
    flag: VIEW_PAYMENTS,
    perspective,
  },
  view_colenders: {
    flag: VIEW_COLENDERS,
    perspective,
  },
  view_deal_dealassignee: {
    flag: VIEW_DEAL_DEALASSIGNEE,
    perspective,
  },
  view_servicing_agents: {
    flag: VIEW_SERVICING_AGENTS,
    perspective,
  },
  view_lead_lenders: {
    flag: VIEW_LEAD_LENDERS,
    perspective,
  },
  view_investor_book: {
    flag: VIEW_INVESTOR_BOOK,
    perspective,
  },
  view_lender_registry: {
    flag: VIEW_LENDER_REGISTRY,
    perspective,
  },
  view_loan_tranches: {
    flag: VIEW_LOAN_TRANCHES,
    perspective,
  },
  view_term_sheet: {
    flag: VIEW_TERM_SHEET,
    perspective,
  },
  view_home_button: {
    flag: VIEW_HOME_BUTTON,
    perspective,
  },
};

export const DealPerspectivePermissionSets = {
  administer_people: [
    DealPerspectivePermissions.administer_borrowers,
    DealPerspectivePermissions.administer_colenders,
    DealPerspectivePermissions.administer_own_team,
  ],
  view_dataroom: [
    DealPerspectivePermissions.view_dataroom_public,
    DealPerspectivePermissions.view_dataroom_private,
  ],
  administer_dataroom: [
    DealPerspectivePermissions.administer_dataroom_public,
    DealPerspectivePermissions.administer_dataroom_private,
  ],
  view_sales: [
    DealPerspectivePermissions.view_lender_registry,
    DealPerspectivePermissions.view_investor_book,
  ],
  view_people: [
    DealPerspectivePermissions.view_borrowers,
    DealPerspectivePermissions.view_colenders,
    DealPerspectivePermissions.view_lead_lenders,
    DealPerspectivePermissions.view_servicing_agents,
    DealPerspectivePermissions.view_own_team,
  ],
};
