import uuid from 'uuid/v4';
import {
  PublicSbaPppLoanApplicationMutationMethods,
  PublicSbaPppInstitutionMutationMethods,
  PublicSbaPppApplicantOwnershipMutationMethods,
  PublicSbaPppLoanFinancialsMutationMethods,
  SbaPppLoanApplicationValidator,
} from '../../../graphql';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import { SbaPppLoanApplicationType } from 'types';

const initialSbaPppLoanApplication: SbaPppLoanApplicationType = {
  id: uuid(),
  __typename: 'SbaPppLoanApplicationType',
  sbappploanfinancials: {
    id: uuid(),
    __typename: 'SbaPppLoanFinancialsType',
    isLeaf: true,
  },
  sbapppapplicantownershipSet: [],
  sbapppinstitution: {
    id: uuid(),
    __typename: 'SbaPppInstitutionType',
  },
} as any;

function sbaPppLoanApplicationDataConverter(data: SbaPppLoanApplicationType) {
  return {
    ...data,
    sbappploanfinancials: {
      ...data.sbappploanfinancials,
      isLeaf: true,
    },
  };
}

const lifecycle = {
  mutations: {
    ...PublicSbaPppLoanApplicationMutationMethods,
    ...PublicSbaPppInstitutionMutationMethods,
    ...PublicSbaPppApplicantOwnershipMutationMethods,
    ...PublicSbaPppLoanFinancialsMutationMethods,
  },
  validators: {
    SbaPppLoanApplication: SbaPppLoanApplicationValidator,
  },
};

export default createForm<SbaPppLoanApplicationType, SbaPppLoanApplicationType>(
  Directory.SbaPppLoanApplicationKey,
  initialSbaPppLoanApplication,
  lifecycle,
  {
    enableAutoSave: false,
    dataConverter: sbaPppLoanApplicationDataConverter,
  },
);
