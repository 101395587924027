import * as React from 'react';
import TitleHeader from './TitleHeader';

export default function HomeHeader() {
  return (
    <TitleHeader name="Portfolio">
      <div className="ActionButtons" />
    </TitleHeader>
  );
}
