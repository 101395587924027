// !!!!! THIS IS AN AUTO-GENERATED FILE, DO NOT EDIT !!!!!

import { gql } from '@apollo/client';
import { LoanTrancheAmortizationInput } from 'types';
import { invariant, strictConvertToInput } from 'utils';
import {
  default as validationBuilder,
  ValidationData,
  AdditionalOptions,
} from './builder/validationBuilder';
import { ApolloQueryResult } from '../types';
import { inputScrubber } from '../mutations/utils';

/* eslint-disable import/prefer-default-export */

const LoanTrancheAmortizationValidatorDefinition = gql`
  query validateLoanTrancheAmortization(
    $loanTrancheAmortizationData: LoanTrancheAmortizationInput
  ) {
    validateLoanTrancheAmortization(
      loanTrancheAmortizationData: $loanTrancheAmortizationData
    ) {
      errors
      errorFields {
        name
        value
      }
      warnings
      warningFields {
        name
        value
      }
    }
  }
`;

const propName = 'validateLoanTrancheAmortization';

/**
 * Auto-Generated from the GraphQL Schema
 */
export const LoanTrancheAmortizationValidator = validationBuilder<
  LoanTrancheAmortizationInput
>(LoanTrancheAmortizationValidatorDefinition, {
  build: (
    validation: (
      options: AdditionalOptions,
    ) => Promise<ApolloQueryResult<ValidationData>>,
  ) => async (entity: LoanTrancheAmortizationInput) => {
    const res: ApolloQueryResult<ValidationData> = await validation({
      variables: {
        loanTrancheAmortizationData: { id: entity.id },
      },
    });
    invariant(
      res.data[propName],
      'Expected res.data to contain validateLoanTrancheAmortization',
    );
    return res.data[propName];
  },
});

export const LoanTrancheAmortizationEntityValidator = validationBuilder<
  LoanTrancheAmortizationInput
>(LoanTrancheAmortizationValidatorDefinition, {
  build: (
    validation: (
      options: AdditionalOptions,
    ) => Promise<ApolloQueryResult<ValidationData>>,
  ) => async (entity: LoanTrancheAmortizationInput) => {
    const res: ApolloQueryResult<ValidationData> = await validation({
      variables: {
        loanTrancheAmortizationData: strictConvertToInput({
          ...inputScrubber(entity),
          submitted: undefined,
          approved: undefined,
        }),
      },
    });
    invariant(
      res.data[propName],
      'Expected res.data to contain validateLoanTrancheAmortization',
    );
    return res.data[propName];
  },
});
