const numericRegex = /^\d*(\.\d+)?$/;

/**
 * Determine if a value is a whole number
 * @param {*} value
 */
function isNumeric(value: string): boolean {
  return numericRegex.test(value);
}

export default isNumeric;
