import { SelectOptionType } from 'types';

/* eslint-disable import/prefer-default-export */

export function getConvertToSelectOption(displayText: string) {
  return function mapToSelectOption(entity: any): SelectOptionType {
    return { value: entity.id, text: entity[displayText] || '' };
  };
}
/* eslint-enable import/prefer-default-export */
