import * as React from 'react';
import { isEmpty } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import { LastLocationType, withLastLocation } from 'react-router-last-location';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import { compose } from 'recompose';
import { tabIndexDirectory } from '../../resources';
import LoanTrancheFormContainer from './LoanTrancheFormContainer';
import MultiTrancheForm from './MultiTrancheForm';
import { LoanTrancheFormProps } from './types';
import { ReduxDirectory } from 'lsredux';

import {
  AlertType,
  DealType,
  LoanTrancheCollateralType,
  LoanTrancheGuarantorType,
  LoanTrancheType,
  LoanTrancheFormType,
} from 'types';
import { invariant, Composer, isWholeNumber } from 'utils';
import { RouteTable, RouteParams, withRouteParams } from 'routing';
import { withLoadingIndicator } from 'components';
import { DealQuery } from 'lsgql';
import { setAlert } from 'lsredux/actions/alerts';
import loanTrancheRedux from 'lsredux/reducer/forms/loanTranche';

type Props = RouteComponentProps & {
  createNewState: (entity: LoanTrancheType) => void;
  deal: DealType;
  lastLocation: LastLocationType;
  setAlert: (message: string, type?: AlertType, title?: string) => void;
  trancheState: any;
};

const letterOfCreditChargeableDrawTypes = new Set([
  'REVOLVER',
  'SWINGLINE',
  'MULTIPLE_DRAW',
]);

class MultiTranche extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    invariant(
      props.history && props.history !== undefined,
      'MultiTrancheEdit expects access to router history',
    );
  }

  getLoanTrancheSet = () =>
    (this.props.deal && this.props.deal.loantrancheSet) || [];

  handleAddNewTranche = () => {
    const { createNewState } = this.props;

    // Check to see if there are any guarantors that need to be copied
    const tranches = this.getQueryTranches();
    const copiedGuarantors: Array<LoanTrancheGuarantorType> = [];
    const copiedCollaterals: Array<LoanTrancheCollateralType> = [];

    // Need to check every tranche because the tranches may not always
    // be in the same order
    tranches.forEach(e => {
      const { loantrancheguarantorSet = [], loantranchecollateralSet = [] } = e;

      loantrancheguarantorSet.forEach(guarantor => {
        if (guarantor.appliesToAllTranches) {
          copiedGuarantors.push({
            ...guarantor,
            id: uuid(),
            created: undefined,
            modified: undefined,
            // Remove appliesToAllTranches check, so we don't get duplicates
            // in new tranches
            appliesToAllTranches: false,
          });
        }
      });

      loantranchecollateralSet.forEach(collateral => {
        if (collateral.appliesToAllTranches) {
          copiedCollaterals.push({
            ...collateral,
            id: uuid(),
            created: undefined,
            modified: undefined,
            // Remove appliesToAllTranches check, so we don't get duplicates
            // in new tranches
            appliesToAllTranches: false,
          });
        }
      });
    });

    const newTranche: any = {
      loanTranche: {
        dealId: this.props.deal.id,
        loantranchecollateralSet: copiedCollaterals,
        loantrancheguarantorSet: copiedGuarantors,
      },
    };
    createNewState(newTranche);
  };

  handleOnCancel = () => this.exitTrancheEditRoute();

  handleOnSubmit = () => this.exitTrancheEditRoute();

  exitTrancheEditRoute = () => {
    const nextPath = this.props.lastLocation
      ? this.props.lastLocation.pathname
      : RouteTable.deal.toLoanOverviewSummary(this.props.deal.id);
    this.props.history.push(nextPath);
  };

  renderComposed = (composedProps: Array<LoanTrancheFormProps>) => {
    const letterOfCreditChargeableLoanTranches = this.getLoanTrancheSet().filter(
      lt => letterOfCreditChargeableDrawTypes.has(lt.commitmentDrawType),
    );
    return (
      <MultiTrancheForm
        letterOfCreditChargeableLoanTranches={
          letterOfCreditChargeableLoanTranches
        }
        onAddNewTranche={this.handleAddNewTranche}
        onCancelEdit={this.handleOnCancel}
        onSubmit={this.handleOnSubmit}
        setAlert={this.props.setAlert}
        tranches={composedProps}
      />
    );
  };

  getQueryTranches = () => {
    const tranches = this.props.deal.loantrancheSet || [];
    const sorted = [...tranches].sort((a, b) => a.id.localeCompare(b.id));
    return sorted;
  };

  render() {
    const { trancheState } = this.props;
    const tranches = this.getQueryTranches() || [];
    const trancheStateObj: {
      [key: string]: any;
    } = trancheState.toJS();

    const trancheIds =
      isEmpty(trancheStateObj) ||
      Object.keys(trancheStateObj).filter(id => isWholeNumber(id)).length <
        tranches.length
        ? tranches.map(e => e.id)
        : Object.keys(trancheStateObj);

    /*
        Warning: We MUST guarantee a consistent sort order for this array
        When transitioning from the query results to the redux results, a difference
        in sort order can cause a Mediator to unmount, leading to that tranche not displaying
         In LoanTrancheFormContainer below, the key value is used to make a determination
        on whether to unmount a container, if the sort order changes, we will unmount
        a tranche immediately after mounting due to key change
    */
    trancheIds.sort((a, b) => +a - +b);

    const isOnlyTranche = trancheIds.length < 2;

    return (
      <Composer
        components={trancheIds.map((e, index) => {
          const isFirstTranche = !index;

          return (
            <LoanTrancheFormContainer
              isFirstTranche={isFirstTranche}
              isOnlyTranche={isOnlyTranche}
              key={e}
              tabIndex={index + tabIndexDirectory.LoanTranche}
              trancheId={e}
            />
          );
        })}
      >
        {this.renderComposed}
      </Composer>
    );
  }
}

const mapStateToProps: (state: any) => { trancheState: any } = state => ({
  trancheState: state.getIn(ReduxDirectory.LoanTrancheFormKeyPath),
});

const mapDispatchToProps = (dispatch: any) => ({
  setAlert: (message: string, type: AlertType, title?: string) => {
    dispatch(setAlert(message, type, title));
  },
  createNewState: (entity: LoanTrancheFormType) => {
    const actions = loanTrancheRedux.actions.generateActions(dispatch, '');
    actions.createNewState(entity);
  },
});

const MultiTrancheContainer: React.Component<{}> = compose(
  withLastLocation,
  withRouteParams([RouteParams.dealId]),
  DealQuery,
  withLoadingIndicator('loading', 'deal'),
  connect(mapStateToProps, mapDispatchToProps),
)(MultiTranche);

export default MultiTrancheContainer;
