import * as React from 'react';
import { IconButton } from 'components';

type Props = {
  canOpen: boolean;
  isOpen: boolean;
  onClick: () => void;
};

const caretTransformations = { size: 15, x: 7 };

function FolderIcon(props: Props) {
  const caretIcon = props.isOpen ? 'caret-down' : 'caret-right';
  return (
    <span className="FolderIcon">
      <IconButton
        alt="toggle folder"
        color="ink"
        disabled={!props.canOpen}
        icon="folder"
        onClick={props.onClick}
        stackedIcon={caretIcon}
        stackedIconColor={!props.canOpen ? 'steel' : null}
        stackedIconTransform={caretTransformations}
      />
    </span>
  );
}

export default FolderIcon;
