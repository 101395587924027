import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { AssignableUsersQueryDefinition } from './definitions';
import { AssignableUserType } from 'types';

type OwnProps = {
  dealId: string;
  shouldFetch: boolean;
};

const AssignableUsersQuery = standardQueryBuilder(
  AssignableUsersQueryDefinition,
  {
    typename: 'AssignableUsers',
    skip: (props: OwnProps) => {
      return !props.shouldFetch;
    },
    variables: (props: OwnProps) => {
      return props;
    },
    results: (data: {
      assignableUsers: Array<AssignableUserType> | null | undefined;
    }) => {
      const { assignableUsers } = data;
      return { assignableUsers };
    },
  },
);

export default AssignableUsersQuery;
