import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/link-batch-http';

import { invariant } from '../utils';

import { REACT_APP_PUBLIC_API_URL } from '../url';
import { isBatchingEnabled } from './batchToggle';
import AuthLink from './authLink';

/**
 * Setup the public GraphQL client
 * @param {*} enableBatching
 */
function getPublicClient() {
  const publicHttpSettings = {
    uri: REACT_APP_PUBLIC_API_URL,
    credentials: 'include',
  };
  const enableBatching = isBatchingEnabled();
  invariant(
    enableBatching !== null,
    'The batching flag must be set prior to client creation',
  );
  let httpLink: HttpLink | BatchHttpLink;
  if (enableBatching === true) {
    httpLink = new BatchHttpLink({ ...publicHttpSettings, batchMax: 20 });
  } else {
    httpLink = new HttpLink(publicHttpSettings);
  }

  const link = ApolloLink.from([AuthLink(), httpLink]);

  const publicClient = new ApolloClient<any>({
    connectToDevTools: true,
    link,
    cache: new InMemoryCache(),
  });

  return publicClient;
}

export default getPublicClient;
