import formQueryBuilder from './queryBuilders/formQueryBuilder';
import { SbaPppApplicationResubmitRequestType } from 'types';
import standardQueryBuilder from 'lsgql/queries/queryBuilders/standardQueryBuilder';
import { SbaPppApplicationResubmitRequestQueryDefinition } from 'lsgql/queries/definitions';

export const SbaPppApplicationResubmitRequestFormQuery = formQueryBuilder(
  SbaPppApplicationResubmitRequestQueryDefinition,
  {
    typename: 'SbaPppApplicationResubmitRequestType' as any,
    skip: (props: { dealId: string | null | undefined }) => {
      const { dealId } = props;
      return !dealId;
    },
    variables: (props: { dealId: string | null | undefined }) => {
      const { dealId } = props;
      return { id: dealId };
    },
    refetchVariables: (props: { dealId: string | null | undefined }) => {
      const { dealId } = props;
      return { id: dealId };
    },
    results: (data: {
      sbaPppApplicationResubmitRequest:
        | SbaPppApplicationResubmitRequestType
        | null
        | undefined;
    }) => {
      const { sbaPppApplicationResubmitRequest } = data;
      return sbaPppApplicationResubmitRequest;
    },
  },
);

export const SbaPppApplicationResubmitRequestQuery = standardQueryBuilder(
  SbaPppApplicationResubmitRequestQueryDefinition,
  {
    typename: 'SbaPppApplicationResubmitRequestType',
    skip: (props: { dealId: string | null | undefined }) => {
      const { dealId } = props;
      return !dealId;
    },
    variables: (props: { dealId: string | null | undefined }) => {
      const { dealId } = props;
      return { id: dealId };
    },
    results: (data: {
      sbaPppApplicationResubmitRequest:
        | SbaPppApplicationResubmitRequestType
        | null
        | undefined;
    }) => {
      return data;
    },
  },
);
