import * as React from 'react';
import uuid from 'uuid/v4';
import { fromJS } from 'immutable';
import InstitutionBankAccountForm from './InstitutionBankAccountForm';
import { Button, Divider, WrappedMediatorFormProps } from 'components';
import { InstitutionType, InstitutionBankAccountType } from 'types';
import { MountGuard, InstitutionPerspectivePermissions } from 'security';

type BankAccountCollectionProps = WrappedMediatorFormProps<InstitutionType>;

class InstitutionBankAccountCollection extends React.Component<
  BankAccountCollectionProps
> {
  newBankAccountId: string;

  constructor(props: BankAccountCollectionProps) {
    super(props);
    this.newBankAccountId = uuid();
  }

  handleAddBankAccount = () => {
    // Generate a new id for the new BankAccount
    this.newBankAccountId = uuid();

    this.props.addEntity('institutionbankaccountSet', {
      id: this.newBankAccountId,
      isPrimary: false,
      __typename: 'InstitutionBankAccountType',
    });
  };

  handleChangeIsPrimary = (id: string) => {
    const { data } = this.props;

    const institutionbankaccountSet = data.institutionbankaccountSet || [];

    this.props.mutateProperty(
      fromJS(
        institutionbankaccountSet.map((e: InstitutionBankAccountType) => {
          if (e.isPrimary) {
            return {
              ...e,
              isPrimary: false,
              isDirty: true,
            };
          }
          if (e.id === id) {
            return {
              ...e,
              isPrimary: true,
              isDirty: true,
            };
          }
          return e;
        }),
      ),
      'institutionbankaccountSet',
    );
  };

  canAddBankAccount = () => {
    const { data } = this.props;

    const institutionbankaccountSet = data.institutionbankaccountSet || [];

    const lastBankAccount: InstitutionBankAccountType | null | undefined =
      institutionbankaccountSet.length > 0
        ? institutionbankaccountSet[institutionbankaccountSet.length - 1]
        : null;

    return (
      lastBankAccount &&
      lastBankAccount.hiddenAccountNumber &&
      lastBankAccount.abaNumber &&
      lastBankAccount.invoicePartyName
    );
  };

  renderBankAccounts() {
    const { data, ...rest } = this.props;

    const institutionbankaccountSet = data.institutionbankaccountSet || [];

    if (institutionbankaccountSet.length > 0) {
      return institutionbankaccountSet.map<any>(
        (account: InstitutionBankAccountType) => (
          <React.Fragment key={account.id}>
            <InstitutionBankAccountForm
              {...rest}
              data={account as any}
              onChangeIsPrimary={this.handleChangeIsPrimary}
            />
            <Divider />
          </React.Fragment>
        ),
      );
    }

    return (
      <>
        <InstitutionBankAccountForm
          {...rest}
          data={null}
          emptyForm
          newBankAccountId={this.newBankAccountId}
        />
        <Divider />
      </>
    );
  }

  render() {
    return (
      <div className="MyInstitution__institutionForm">
        {this.renderBankAccounts()}
        <MountGuard
          permission={InstitutionPerspectivePermissions.administer_institution}
        >
          <Button
            disabled={!this.canAddBankAccount()}
            label="Add Bank Account"
            onClick={this.handleAddBankAccount}
          />
        </MountGuard>
      </div>
    );
  }
}

export default InstitutionBankAccountCollection;
