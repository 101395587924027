import { Map } from 'immutable';
import * as React from 'react';
import Loadable from 'react-loadable';
import { LoadingIndicator } from 'components';

type PdfViewProps = {
  changePage: (arg0: { id: string; pageIndex: number }) => void;
  changeRotations: (arg0: {
    id: string;
    rotations: Record<string, any>;
  }) => void;
  changeScale: (arg0: { id: string; scale: number }) => void;
  documentExpiringUrl: string | null | undefined;
  id: string;
  pageIndex: number;
  renderError?: () => React.ReactNode;
  rotations: Map<number, number>;
  scale: number;
};

/**
 * Supports async load of the PDF.js libraries, so we don't
 * need them in our main bundle
 */
const LoadablePdfViewer = Loadable<PdfViewProps, React.ElementType>({
  loader: () => import('./PdfViewer'),
  loading() {
    return <LoadingIndicator />;
  },
});

export default LoadablePdfViewer;
