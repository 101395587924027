import { mutationBuilder } from './builders';
import {
  CreateFeeMutationDefinition,
  SetFeeMutationDefinition,
  DeleteFeeMutationDefinition,
} from './definitions';
import { FeeInput, FeeType, ID } from 'types';

const FeeMutationMethods = {
  createFee: mutationBuilder<FeeInput, FeeType>(CreateFeeMutationDefinition, {
    build: mutate => feeData => mutate({ variables: { feeData } }),
  }),
  setFee: mutationBuilder<FeeInput, FeeType>(SetFeeMutationDefinition, {
    build: mutate => feeData => mutate({ variables: { feeData } }),
  }),
  deleteFee: mutationBuilder<ID, void>(DeleteFeeMutationDefinition, {
    build: mutate => (id: string) => mutate({ variables: { id } }),
  }),
};

export default FeeMutationMethods;
