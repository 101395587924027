import formQueryBuilder from './queryBuilders/formQueryBuilder';
import permissionQueryBuilder from './queryBuilders/permissionQueryBuilder';
import { FolderQueryDefinition } from './definitions';
import { FolderInput } from 'types';

export const FolderQuery = permissionQueryBuilder(FolderQueryDefinition, {
  typename: 'Folder',
  skip: (props: { folderId: string | null | undefined }) => !props.folderId,
  variables: (props: { folderId: string | null | undefined }) => {
    const { folderId } = props;
    return { id: folderId };
  },
  results: (data: { folder: FolderInput | null | undefined }) => {
    const { folder } = data;
    return { folder };
  },
});
export const FolderFormQuery = formQueryBuilder(FolderQueryDefinition, {
  typename: 'Folder',
  skip: (props: { folderId: string | null | undefined }) => !props.folderId,
  variables: (props: { folderId: string | null | undefined }) => {
    const { folderId } = props;
    return { id: folderId };
  },
  results: (data: { folder: FolderInput | null | undefined }) => {
    const { folder } = data;
    return folder;
  },
});
