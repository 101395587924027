import { mutationBuilder } from './builders';

import {
  CreateLoanTrancheOwnershipPortionMutationDefinition,
  SetLoanTrancheOwnershipPortionMutationDefinition,
  DeleteLoanTrancheOwnershipPortionMutationDefinition,
} from './definitions';
import {
  LoanTrancheOwnershipPortionInput,
  LoanTrancheOwnershipPortionType,
  ID,
} from 'types';

function displayInfoScrubber(e: any) {
  return {
    ...e,
    institutionName: undefined,
    index: undefined,
    portion: undefined,
    parentId: undefined,
  };
}

const LoanTrancheOwnershipPortionMutationMethods = {
  createLoanTrancheOwnershipPortion: mutationBuilder<
    LoanTrancheOwnershipPortionInput,
    LoanTrancheOwnershipPortionType
  >(CreateLoanTrancheOwnershipPortionMutationDefinition, {
    build: mutate => loanTrancheOwnershipPortionData =>
      mutate({
        variables: {
          loanTrancheOwnershipPortionData: displayInfoScrubber(
            loanTrancheOwnershipPortionData,
          ),
        },
      }),
  }),
  setLoanTrancheOwnershipPortion: mutationBuilder<
    LoanTrancheOwnershipPortionInput,
    LoanTrancheOwnershipPortionType
  >(SetLoanTrancheOwnershipPortionMutationDefinition, {
    build: mutate => loanTrancheOwnershipPortionData =>
      mutate({
        variables: {
          loanTrancheOwnershipPortionData: displayInfoScrubber(
            loanTrancheOwnershipPortionData,
          ),
        },
      }),
  }),
  deleteLoanTrancheOwnershipPortion: mutationBuilder<ID, void>(
    DeleteLoanTrancheOwnershipPortionMutationDefinition,
    {
      build: mutate => (id: string) => mutate({ variables: { id } }),
    },
  ),
};

export default LoanTrancheOwnershipPortionMutationMethods;
