import { mutationBuilder } from './builders';

import {
  CreateLoanTrancheInstitutionOwnershipPortionMutationDefinition,
  SetLoanTrancheInstitutionOwnershipPortionMutationDefinition,
  DeleteLoanTrancheInstitutionOwnershipPortionMutationDefinition,
} from './definitions';
import {
  LoanTrancheInstitutionOwnershipPortionInput,
  LoanTrancheInstitutionOwnershipPortionType,
  ID,
} from 'types';

function displayInfoScrubber(e: any) {
  return {
    ...e,
    institutionName: undefined,
  };
}

const LoanTrancheInstitutionOwnershipPortionMutationMethods = {
  createLoanTrancheInstitutionOwnershipPortion: mutationBuilder<
    LoanTrancheInstitutionOwnershipPortionInput,
    LoanTrancheInstitutionOwnershipPortionType
  >(CreateLoanTrancheInstitutionOwnershipPortionMutationDefinition, {
    build: mutate => loanTrancheInstitutionOwnershipPortionData =>
      mutate({
        variables: {
          loanTrancheInstitutionOwnershipPortionData: displayInfoScrubber(
            loanTrancheInstitutionOwnershipPortionData,
          ),
        },
      }),
  }),
  setLoanTrancheInstitutionOwnershipPortion: mutationBuilder<
    LoanTrancheInstitutionOwnershipPortionInput,
    LoanTrancheInstitutionOwnershipPortionType
  >(SetLoanTrancheInstitutionOwnershipPortionMutationDefinition, {
    build: mutate => loanTrancheInstitutionOwnershipPortionData =>
      mutate({
        variables: {
          loanTrancheInstitutionOwnershipPortionData: displayInfoScrubber(
            loanTrancheInstitutionOwnershipPortionData,
          ),
        },
      }),
  }),
  deleteLoanTrancheInstitutionOwnershipPortion: mutationBuilder<ID, void>(
    DeleteLoanTrancheInstitutionOwnershipPortionMutationDefinition,
    {
      build: mutate => (id: string) => mutate({ variables: { id } }),
    },
  ),
};

export default LoanTrancheInstitutionOwnershipPortionMutationMethods;
