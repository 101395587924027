import { mutationBuilder } from './builders';
import {
  CreateSbaPppForgivenessResubmitRequestMutationDefinition,
  SetSbaPppForgivenessResubmitRequestMutationDefinition,
} from './definitions';
import {
  SbaPppForgivenessResubmitRequestInput,
  SbaPppForgivenessResubmitRequestType,
} from 'types';

const SbaPppForgivenessResubmitRequestMutationMethods = {
  createSbaPppForgivenessResubmitRequest: mutationBuilder<
    SbaPppForgivenessResubmitRequestInput,
    SbaPppForgivenessResubmitRequestType
  >(CreateSbaPppForgivenessResubmitRequestMutationDefinition, {
    build: mutate => sbaPppForgivenessResubmitRequestData =>
      mutate({ variables: { sbaPppForgivenessResubmitRequestData } }),
  }),
  setSbaPppForgivenessResubmitRequest: mutationBuilder<
    SbaPppForgivenessResubmitRequestInput,
    SbaPppForgivenessResubmitRequestType
  >(SetSbaPppForgivenessResubmitRequestMutationDefinition, {
    build: mutate => sbaPppForgivenessResubmitRequestData =>
      mutate({ variables: { sbaPppForgivenessResubmitRequestData } }),
  }),
};

export default SbaPppForgivenessResubmitRequestMutationMethods;
