import * as React from 'react';
import { FolderWithDate } from '../../types';
import ShareModalContent from './shareModalContent/ShareModalContent';
import ShareInstitutionItem from './shareInstitutionItem/ShareInstitutionItem';
import { ID, SetOrganizationFolderRoleType, UserType } from 'types';
import { Button, Modal, FontAwesomeIconTypes } from 'components';

type Props = {
  dealOrganizationDealRoles: Array<any>;
  deleteOrganizationFolderRole: (arg0: SetOrganizationFolderRoleType) => void;
  fetchDataroomFiles: () => void;
  folder: FolderWithDate;
  folderOrganizationFolderRoles: Array<any>;
  isOpen: boolean;
  onClose: () => void;
  onPermissionChange: (message: string, folderName: string) => void;
  setOrganizationFolderRole: (arg0: SetOrganizationFolderRoleType) => void;
  user: UserType;
};

interface FolderRole {
  folderId: string;
  institutionId?: string | null;
  roleId?: string | null;
}
export default class FolderShareModal extends React.Component<Props> {
  static timesIcon: FontAwesomeIconTypes = ['fal', 'times'];

  handleCloseClick = () => {
    this.props.onClose();
  };

  handlePermissionChange = (roleId: ID, id: ID) => {
    const { setOrganizationFolderRole } = this.props;

    let folderRole = this.getInstitutionRole(id);

    folderRole = {
      ...folderRole,
      roleId,
    };

    setOrganizationFolderRole(folderRole as SetOrganizationFolderRoleType);
    this.permissionChangeNotification('Changed permission for');
  };

  handleAddInstitute = (id: string) => {
    const { setOrganizationFolderRole, fetchDataroomFiles } = this.props;

    let folderRole = this.getInstitutionRole(id);
    const defaultRole = '3'; // view
    folderRole = {
      ...folderRole,
      roleId: defaultRole,
    };
    setOrganizationFolderRole(folderRole as SetOrganizationFolderRoleType);
    this.permissionChangeNotification('Added institution in');
    fetchDataroomFiles();
  };

  getInstitutionRole = (id: string) => {
    const { folder } = this.props;
    let folderRole: FolderRole = {
      folderId: folder.id,
    };
    folderRole = { ...folderRole, institutionId: id };
    return folderRole;
  };

  handlePermissionDelete = (id: string) => {
    const { deleteOrganizationFolderRole, fetchDataroomFiles } = this.props;
    let folderRole = this.getInstitutionRole(id);

    folderRole = {
      ...folderRole,
      roleId: null,
    };
    deleteOrganizationFolderRole(folderRole as SetOrganizationFolderRoleType);
    this.permissionChangeNotification('Removed institution in');
    fetchDataroomFiles();
  };

  permissionChangeNotification = (message: string) => {
    const { folder, onPermissionChange } = this.props;
    const folderName = folder.name ? folder.name : 'Generic';

    onPermissionChange(message, folderName);
  };

  renderModalHeader = () => {
    const { folder } = this.props;
    return (
      <div className="folderShareModal--headerContentName">
        {folder ? folder.name : ''}
      </div>
    );
  };

  renderModalFooter() {
    const actionsCx = 'StandardModal-Actions folderShareModal__ModalFooter';
    return (
      <Modal.Actions className={actionsCx}>
        <Button.Secondary label="Ok" onClick={this.handleCloseClick} />
      </Modal.Actions>
    );
  }

  render() {
    const { isOpen } = this.props;
    const { folder } = this.props;

    const {
      dealOrganizationDealRoles,
      folderOrganizationFolderRoles,
      user,
    } = this.props;
    let userInst = {
      id: '',
      typeName: '',
    };

    if (
      user.institution &&
      user.institution.id &&
      user.institution.__typename
    ) {
      userInst = {
        id: user.institution.id,
        typeName: user.institution.__typename,
      };
    }

    return (
      <Modal
        className="folderShareModal"
        footerless
        header={folder && folder.name ? folder.name : ''}
        isOpen={isOpen}
        onClose={this.handleCloseClick}
      >
        <ShareModalContent
          institutionOptions={dealOrganizationDealRoles}
          onAddInstitute={this.handleAddInstitute}
          userInstitution={userInst}
        />
        <div className="folderShareModal--institutionList">
          {folderOrganizationFolderRoles &&
            folderOrganizationFolderRoles.reduce(
              (accumulator, folderOrganizationFolderRole) => {
                if (
                  folderOrganizationFolderRole.role.slug !==
                  'folder_administrator'
                ) {
                  accumulator.push(
                    <ShareInstitutionItem
                      institution={folderOrganizationFolderRole.institution}
                      key={folderOrganizationFolderRole.id}
                      onChange={this.handlePermissionChange}
                      onDelete={this.handlePermissionDelete}
                      role={folderOrganizationFolderRole.role.id}
                    />,
                  );
                }
                return accumulator;
              },
              [],
            )}
        </div>
        {this.renderModalFooter()}
      </Modal>
    );
  }
}
