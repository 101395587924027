import { fromJS } from 'immutable';

import treeWalker from '../../../graphql/mutationsDispatch/utils/treeWalker';
import { KeyPathAction } from '../types';
import { rootKey, cleanDataKey, isDirty, pendingDeleteKey } from '../methods';
import { invariant, getTypeName } from 'utils';

/* eslint-disable import/prefer-default-export */

function hasPendingDeletes(pendingDelete: {}): boolean {
  let res = false;

  Object.keys(pendingDelete).forEach(key => {
    if (pendingDelete[key] && pendingDelete[key].length > 0) {
      res = true;
    }
  });

  return res;
}

/**
 * Examine a GraphQL Mutation response object and populate state appropriately
 * If the reponse contained errors, these will populate into the 'errors' property,
 * otherwise 'data' will be refreshed.
 * @param {*} state
 * @param {*} action
 */
export function handleUndo(
  state: any, // This is an Immutable version of FormReduxState<T>
  action: KeyPathAction,
): Record<string, any> {
  const { keyPath } = action.payload;
  invariant(keyPath, 'A keypath is required for undo');

  const asArray = Array.isArray(keyPath) ? keyPath : [keyPath];

  const cleanKeyPath = [cleanDataKey, ...asArray];
  const dataKeyPath = [rootKey, ...asArray];

  if (state.get(isDirty) === false) {
    return state;
  }

  /*
    After undo, should we walk the object tree and check the status of isDirty?
  */
  const updated = state.setIn(dataKeyPath, state.getIn(cleanKeyPath));

  let hasPendingEdits = false;

  // keys should be of type ObjectBaseTypeNames
  const toRestore: {
    [key: string]: Set<string>;
  } = {};

  const { data } = updated.toJS();
  invariant(data, 'Failed to find data');
  // Check isDirty
  treeWalker(data, (e: any) => {
    if (e.isDirty === true) {
      hasPendingEdits = true;
    }

    const typeKey = getTypeName(e);

    if (!toRestore[typeKey]) toRestore[typeKey] = new Set<string>();

    toRestore[typeKey].add(e.id);
  });

  // Remove restored entities from pendingDeletes
  const toCleanse = updated.toJS().pendingDelete;

  Object.keys(toCleanse).forEach(key => {
    if (toRestore[key]) {
      // type exists in the delete collection
      toCleanse[key] = toCleanse[key].filter(v => !toRestore[key].has(v.id));

      if (toCleanse[key].length === 0) delete toCleanse[key];
    }
  });

  const hasDeletes = hasPendingDeletes(toCleanse);

  return updated
    .set(isDirty, hasPendingEdits || hasDeletes)
    .set(pendingDeleteKey, fromJS(toCleanse));
}
