import toLower from 'lodash/toLower';
import { PermissionKey } from '../types';
import { PermissionScopes } from '../definitions/types';
import { Global } from '../constants';
import { invariant, getTypeName } from 'utils';
import {
  TypeAliases,
  ID,
  PermissionsBaseType,
  ObjectBaseTypeNames,
} from 'types';

const ScopeError = 'Non-GlobalScope permission objects must have an id';

/**
 * Get the unique identifier user for storing and reading permissions
 * of a particuler ID & TypeName combiniation
 * @param {*} scope
 * @param {*} id
 */
export function getPermissionKey(
  scope: PermissionScopes,
  id?: ID | null | undefined,
): PermissionKey {
  if (scope === Global) {
    return (Global as any) as PermissionKey;
  }
  invariant(id, ScopeError);
  return (`${id}_${toLower(scope)}` as any) as PermissionKey;
}

/**
 * Get the unique identifer used for storing and reading the permissions
 * set of a particular entity: `id_typename`
 * @param {*} subject
 */
export function getEntityPermissionKey<T extends PermissionsBaseType>(
  subject: T,
): PermissionKey {
  const type: ObjectBaseTypeNames = getTypeName(subject);
  return getPermissionKey(
    (toLower(
      TypeAliases[type] ? TypeAliases[type] : type,
    ) as any) as PermissionScopes,
    subject.id,
  );
}
