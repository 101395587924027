import { gql } from '@apollo/client';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { PublicSbaPppLoanApplicationType } from 'types';
import getPublicClient from 'lsgql/publicClient';

/* eslint-disable */
const PublicInstitutionQueryDefinition = gql`
  query sbaPppLoanApplication($id: ID) {
    sbaPppLoanApplication(id: $id) {
      institutionName
      institutionPhoneNumber
      confirmationNumber
    }
  }
`;

/* eslint-enable */
type Props = { sbaPppApplicationId: string | null | undefined };

const PublicSbaPppLoanApplicationQuery = standardQueryBuilder(
  PublicInstitutionQueryDefinition,
  {
    typename: 'PublicSbaPppLoanApplicationType',
    skip: ({ sbaPppApplicationId }: Props) => !sbaPppApplicationId,
    variables: ({ sbaPppApplicationId }: Props) => ({
      id: sbaPppApplicationId,
    }),
    results: (data: {
      sbaPppLoanApplication: PublicSbaPppLoanApplicationType | null | undefined;
    }) => {
      const { sbaPppLoanApplication } = data;
      return { sbaPppLoanApplication: sbaPppLoanApplication };
    },
  },
  getPublicClient(),
);

export default PublicSbaPppLoanApplicationQuery;
