import { WatchQueryOptions } from '@apollo/client';
import { DocumentNode, ApolloQueryResult } from '../../types';
import { getAuthenticatedClient } from '../../authenticatedClient';
import { ValidationType } from 'types';
import { invariant } from 'utils';

export type ValidationData = {
  [key: string]: ValidationType;
};

export type AdditionalOptions = { variables: {} };

/**
 * Method passed to transform that can create and invoke the mutation
 */
export type ValidationExecutor = (
  additionalOptions: AdditionalOptions,
) => Promise<ApolloQueryResult<ValidationData>>;

type SimpleArgs<T> = {
  // pass a method to the child that allows them to define the final method
  build: (
    validation: ValidationExecutor,
  ) => (input: T) => Promise<ValidationType>;
};

/**
 * Build a mutation method according to standard rules and behavior
 * @param {*} mutation
 * @param {*} args
 */
function validationBuilder<T>(
  validator: DocumentNode,
  args: SimpleArgs<T>,
): (input: T) => Promise<ValidationType> {
  invariant(validator, 'Validator definition is required');

  const validationExecutor: (
    arg0: AdditionalOptions,
  ) => Promise<ApolloQueryResult<ValidationData>> = (
    additionalOptions: AdditionalOptions,
  ) => {
    const options: WatchQueryOptions = {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      query: validator,
      ...additionalOptions,
    };
    return getAuthenticatedClient().query(options as any);
  };

  return args.build(validationExecutor);
}

export default validationBuilder;
