import { noop } from 'lodash';
import * as React from 'react';
import { Prompt, withRouter, RouteComponentProps } from 'react-router-dom';
import { navigateAwayText, confirmSubmitText } from '../utils';
import AssociatedFiles from '../../../associatedFiles/AssociatedFiles';
import { RouteTable } from 'routing';
import {
  Form,
  FormFrame,
  StandardModal,
  Icon,
  WrappedMediatorFormProps,
} from 'components';
import { DocumentType, AlertType } from 'types';
import { PaymentRecordBorrowerTypeWithPrefillFlag } from 'lsredux/reducer/forms/paymentRecordBorrower';

import { QueryResult } from 'lsgql';

import {
  DealPerspectivePermissions,
  MountGuard,
  AccessDeniedGuard,
  DisabledChildProps,
} from 'security';

const instructionsText =
  'Send payment amount listed and fill out information below.';
const attachDocumentText =
  'Confirm Completed payment by uploading relevant document(s).';
const amountWarningText = 'This amount does not match the amount listed';

const width = 'eight';
const textAreaWidth = 'sixteen';
const objectType = 'PaymentRecordBorrower';

type Props = RouteComponentProps & {
  dealId: string;
  deleteDocument: (id: number) => Promise<QueryResult<any>>;
  documents: Array<DocumentType>;
  refetchDocuments: (data: any) => Promise<any>;
  saving: boolean;
  setAlert: (alert: string, type: AlertType) => void;
  setStagedFiles: (files: Array<File>) => void;
  stagedFiles: Array<File>;
} & WrappedMediatorFormProps<PaymentRecordBorrowerTypeWithPrefillFlag>;

type State = {
  isSubmitModalOpen: boolean;
};

class BorrowerPaymentView extends React.Component<Props, State> {
  state = {
    isSubmitModalOpen: false,
  };

  fields: {
    [key: string]: any;
  };

  constructor(props: Props) {
    super(props);

    this.fields = {
      paymentDueAmount: {
        id: 'paymentDueAmount',
        fieldName: 'Amount Due',
        propertyName: 'totalAmount',
        onChange: noop,
        width,
      },
      paymentDueDate: {
        id: 'paymentDueDate',
        fieldName: 'Date Due',
        propertyName: 'date',
        onChange: noop,
        width,
      },
      borrowerAmount: {
        id: 'borrowerAmount',
        className: 'paymentRecord__AmountInput',
        fieldName: 'Amount',
        propertyName: 'borrowerAmount',
        onChange: this.handleMutateProperty,
        width,
      },
      borrowerAmountReadOnly: {
        id: 'borrowerAmountReadOnly',
        className: 'paymentRecord__AmountInput',
        fieldName: 'Amount Paid',
        propertyName: 'borrowerAmountReadOnly',
        onChange: noop,
        width,
      },
      borrowerDate: {
        id: 'borrowerDate',
        fieldName: 'Date',
        propertyName: 'borrowerDate',
        onChange: this.handleMutateProperty,
        width,
      },
      borrowerDateReadOnly: {
        id: 'borrowerDateReadOnly',
        fieldName: 'Date Paid',
        propertyName: 'borrowerDateReadOnly',
        onChange: noop,
        width,
      },
      borrowerReference: {
        id: 'borrowerReference',
        fieldName: 'Reference Number (Optional)',
        propertyName: 'borrowerReference',
        onChange: this.handleMutateProperty,
        width,
      },
      borrowerNotes: {
        id: 'borrowerNotes',
        fieldName: 'Notes (Optional)',
        propertyName: 'borrowerNotes',
        onChange: this.handleMutateProperty,
        width: textAreaWidth,
      },
    };
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidUpdate() {
    this.prefillData(this.props);
  }

  prefillData = (props: Props) => {
    const { data, mutateProperty, toggleDirtyFlag } = props;
    const { paymentDue } = data;

    if (data.wasDataPrefilled && !(data.borrowerAmount || data.borrowerDate)) {
      if (paymentDue) {
        mutateProperty(paymentDue.date, 'borrowerDate');
        mutateProperty(paymentDue.totalAmount, 'borrowerAmount');
        toggleDirtyFlag([], false, false);
      }
    }
  };

  renderSubmitSummary = () => {
    const { data } = this.props;
    const { paymentDue } = data;
    const paymentDueId = paymentDue && paymentDue.id ? paymentDue.id : 'temp';
    const paymentDueProps = { ...this.props, data: paymentDue };
    return (
      <div className="ui form lsForm">
        <Form.Group>
          {Form.FieldRenderer(
            Form.MoneyReadOnly,
            this.fields.paymentDueAmount,
            paymentDueProps,
            objectType,
            paymentDueId,
          )}
          {Form.FieldRenderer(
            Form.ReadOnly,
            this.fields.paymentDueDate,
            paymentDueProps,
            objectType,
            paymentDueId,
          )}
          {Form.FieldRenderer(
            Form.MoneyReadOnly,
            {
              ...this.fields.borrowerAmountReadOnly,
              value: data && data.borrowerAmount,
            },
            this.props,
            objectType,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.ReadOnly,
            {
              ...this.fields.borrowerDateReadOnly,
              value: data && data.borrowerDate,
            },
            this.props,
            objectType,
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {Form.FieldRenderer(
            Form.ReadOnly,
            { ...this.fields.borrowerReference, width },
            this.props,
            objectType,
            data.id,
          )}
        </Form.Group>
        <div>{confirmSubmitText}</div>
      </div>
    );
  };

  handleMutateProperty = (
    value: string | null | undefined,
    fieldId: string,
  ) => {
    if (fieldId === 'borrowerDate') {
      if (Form.Calendar.isValidDate(value)) {
        this.props.mutateProperty(value, fieldId);
      }
    } else {
      this.props.mutateProperty(value, fieldId);
    }
  };

  handleSave = () => {
    const { onSave, data, toggleDirtyFlag, mutateProperty } = this.props;
    if (!this.props.isDirty && data.wasDataPrefilled) {
      // Data was prefilled, we need to set isDirty
      toggleDirtyFlag([], true, false);
    }
    mutateProperty(true, 'submitted');
    onSave();
    this.handleCloseModal();
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push(RouteTable.deal.toDealPayments(this.props.dealId));
  };

  handleCloseModal = () => {
    this.setState({ isSubmitModalOpen: false });
  };

  handleOpenSubmitModal = () => {
    this.setState({ isSubmitModalOpen: true });
  };

  renderEditableFields = () => {
    const {
      data,
      deleteDocument,
      refetchDocuments,
      setStagedFiles,
      stagedFiles,
    } = this.props;
    const amountDue = data.paymentDue ? data.paymentDue.totalAmount : '';
    return (
      <MountGuard
        permission={DealPerspectivePermissions.administer_payments_borrower}
      >
        <Form.Group>
          {Form.FieldRenderer(
            Form.Money,
            this.fields.borrowerAmount,
            this.props,
            objectType,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Calendar,
            this.fields.borrowerDate,
            this.props,
            objectType,
            data.id,
          )}
        </Form.Group>
        {data.borrowerAmount &&
          (data.borrowerAmount as any) !== 0 &&
          data.borrowerAmount !== amountDue && (
            <div className="borrowerPaymentView__AmountWarning">
              <Icon color="sunflower" icon="exclamation-triangle" />
              <div className="borrowerPaymentView__AmountWarningText">
                {amountWarningText}
              </div>
            </div>
          )}
        <Form.Group>
          {Form.FieldRenderer(
            Form.Input,
            this.fields.borrowerReference,
            this.props,
            objectType,
            data.id,
          )}
        </Form.Group>
        <AssociatedFiles
          data={data}
          deleteDocument={deleteDocument}
          note={attachDocumentText}
          refetchDocuments={refetchDocuments}
          setStagedFiles={setStagedFiles}
          stagedFiles={stagedFiles}
          uploadTarget="Borrower Payment Record"
        />
        <Form.Group>
          {Form.FieldRenderer(
            Form.TextArea,
            this.fields.borrowerNotes,
            this.props,
            objectType,
            data.id,
          )}
        </Form.Group>
      </MountGuard>
    );
  };

  renderReadOnlyFields = () => {
    const { data } = this.props;
    return (
      <>
        <div className="paymentRecord__Divider">
          <Form.Divider />
        </div>
        <Form.Group>
          {Form.FieldRenderer(
            Form.MoneyReadOnly,
            {
              ...this.fields.borrowerAmount,
              fieldName: 'Submitted Amount',
            },
            this.props,
            objectType,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.ReadOnly,
            {
              ...this.fields.borrowerDate,
              fieldName: 'Submitted Date',
            },
            this.props,
            objectType,
            data.id,
          )}
        </Form.Group>
        <Form.Group>
          {data &&
            data.borrowerReference &&
            Form.FieldRenderer(
              Form.ReadOnly,
              {
                ...this.fields.borrowerReference,
                fieldName: 'Submitted Reference Number',
              },
              this.props,
              objectType,
              data.id,
            )}
        </Form.Group>
        <AssociatedFiles data={data} viewOnly />
        {this.props.data.borrowerNotes && (
          <Form.Group>
            {Form.FieldRenderer(
              Form.ReadOnly,
              {
                ...this.fields.borrowerNotes,
                fieldName: 'Submitted Notes',
              },
              this.props,
              objectType,
              data.id,
            )}
          </Form.Group>
        )}
      </>
    );
  };

  renderFormBody = (security: DisabledChildProps) => {
    const {
      data: { paymentDue },
      isDirty,
      loading,
      saving,
      disabled,
    } = this.props;

    // SERVICING-TODO: submitted is stubbed
    const submitted = false;

    const { isSubmitModalOpen } = this.state;
    const paymentDueId = paymentDue && paymentDue.id ? paymentDue.id : 'temp';
    const paymentDueProps = { ...this.props, data: paymentDue };

    return (
      <FormFrame
        disabled={disabled || submitted || security.accessDenied}
        formHeader="Payment"
        formId="submitSummaryBorrower"
        isDirty={isDirty}
        loading={loading}
        onCancel={this.handleCancel}
        onSubmit={this.handleOpenSubmitModal}
        saving={saving}
      >
        <div className="paymentRecord__Form">
          <div className="paymentRecord__Label">Instructions</div>
          <div className="paymentRecord__InstructionText">
            {instructionsText}
          </div>
          <Form.Group>
            {Form.FieldRenderer(
              Form.MoneyReadOnly,
              this.fields.paymentDueAmount,
              paymentDueProps,
              objectType,
              paymentDueId,
            )}
            {Form.FieldRenderer(
              Form.ReadOnly,
              this.fields.paymentDueDate,
              paymentDueProps,
              objectType,
              paymentDueId,
            )}
          </Form.Group>
          {!submitted
            ? this.renderEditableFields()
            : this.renderReadOnlyFields()}

          {!submitted && (
            <>
              <StandardModal
                confirmButtonText="Submit"
                header="Confirm Payment"
                isOpen={isSubmitModalOpen}
                onClose={this.handleCloseModal}
                onConfirm={this.handleSave}
              >
                {this.renderSubmitSummary()}
              </StandardModal>
              <Prompt message={navigateAwayText} when={this.props.isDirty} />
            </>
          )}
        </div>
      </FormFrame>
    );
  };

  render() {
    return (
      <AccessDeniedGuard
        permission={DealPerspectivePermissions.administer_payments_borrower}
      >
        {this.renderFormBody}
      </AccessDeniedGuard>
    );
  }
}

export default withRouter(BorrowerPaymentView);
