import { TransitionClosingWorkflowMutationDefinition } from './definitions';

import { mutationBuilder } from './builders';
import { ID } from 'types';

type TriggerClosingWorkflowTransitionInput = {
  workflowId: ID;
  state: string;
};

type TriggerClosingWorkflowTransitionType = {
  ok: boolean;
};

const triggerClosingWorkflowTransition = mutationBuilder<
  TriggerClosingWorkflowTransitionInput,
  TriggerClosingWorkflowTransitionType
>(TransitionClosingWorkflowMutationDefinition, {
  build: mutate => (data: TriggerClosingWorkflowTransitionInput) => {
    // eslint-disable-next-line
    console.log('Invoke with data:', data);
    return mutate({
      variables: {
        workflowId: data.workflowId,
        state: data.state,
      },
      refetchQueries: ['DealQuery'],
    });
  },
});

export default triggerClosingWorkflowTransition;
