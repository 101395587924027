import * as React from 'react';
import Secured from './Secured';

import { PermissionDefinition } from './definitions/types';

/* eslint-disable import/prefer-default-export */

type AccessGuardProps = {
  permission: PermissionDefinition | Array<PermissionDefinition>;
} & { children: React.ReactNode };

/**
 * Check that the user has the required permissions.  If the user
 * lacks permissions, a standard `NoAccess` splash will be rendered
 * in place of children, otherwise children are rendered as normal
 */
export class AccessGuard extends React.Component<AccessGuardProps> {
  renderChildren = () => this.props.children;

  render() {
    return (
      <Secured denyAction="no_access" permission={this.props.permission}>
        {this.renderChildren}
      </Secured>
    );
  }
}
