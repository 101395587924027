import { compose } from 'recompose';
import { connect } from 'react-redux';
import ForgivenessResubmissionForm from './ForgivenessResubmissionForm';
import { SbaPppForgivenessWorkflowFormQuery } from 'lsgql';
import { withRouteParams, RouteParams } from 'routing';
import { FormMediator } from 'components';
import sbaPppForgivenessResubmitRequestFormRedux from 'lsredux/reducer/forms/sbaPppForgivenessResubmitRequest';

export default compose(
  withRouteParams([RouteParams.dealId]),
  SbaPppForgivenessWorkflowFormQuery,
  connect(
    sbaPppForgivenessResubmitRequestFormRedux.mapStateToProps,
    sbaPppForgivenessResubmitRequestFormRedux.actions.generateActions,
  ),
  FormMediator({
    formId: 'sbaPppForgivenessResubmitRequestForm',
    baseObjectTypeName: 'Deal',
    disableFrame: true,
    disableAlerts: true,
  }),
)(ForgivenessResubmissionForm);
