import * as React from 'react';
import { StepProps } from '../StepProps';
import WorkflowStep from '../WorkflowStep';
import { RouteTable } from 'routing';
import { invariant } from 'utils';
import { Text, TextLink, RouteTo } from 'components';

function BorrowerSignApplication(props: StepProps) {
  const { step, dealId, isDimmed, canEdit } = props;
  invariant(step.slug === 'aw_borrower_sign_application', 'invalid slug');

  return (
    <WorkflowStep
      canEdit={canEdit}
      header="SIGN APPLICATION"
      isDimmed={isDimmed}
      step={step}
    >
      {!isDimmed && !canEdit ? (
        <Text>
          You have signed your application. The PDF is always available in the{' '}
          <TextLink
            as={RouteTo}
            label="Dataroom"
            to={RouteTable.deal.toDealDataroom(dealId)}
          />
          .
        </Text>
      ) : (
        <Text>
          Sign the approved application and return to your lender. Please check
          your E-Mail for a signature request from DocuSign
        </Text>
      )}
      {/*
      canEdit && (
        <div className="Group left padded">
          <Button.Primary
            onClick={onButtonClick}
            label="Sign and Submit Application"
          />
        </div>
      )
      */}
    </WorkflowStep>
  );
}

export default BorrowerSignApplication;
