import { gql } from '@apollo/client';
import { DocumentNode } from '../types';

import {
  DealBasicFields,
  CovenantShallowFields,
  CreditRatingShallowFields,
  EscrowAccountShallowFields,
  LoanTrancheShallowFields,
  ReserveShallowFields,
  LoanTrancheAmortizationShallowFields,
  LoanTrancheFloatingRateDataShallowFields,
  LoanTrancheMultiDrawDataShallowFields,
  LoanTrancheRevolverSwinglineLOCDataShallowFields,
  LoanTrancheAdjustableRateDataShallowFields,
  LoanTrancheAdjustableRatePeriodShallowFields,
  LoanTrancheAmortizationPrincipalPaymentShallowFields,
  LoanTrancheAmortizationInterestPeriodShallowFields,
  LoanTrancheCollateralShallowFields,
  LoanTrancheGuarantorShallowFields,
  BenchmarkOptionShallowFields,
  BenchmarkShallowFields,
  AlternateBaseRateBenchmarkShallowFields,
  ApplicableMarginShallowFields,
  FeeShallowFields,
  FeeCalculationRuleShallowFields,
} from './generated';

const LoanTrancheFormFields: DocumentNode = gql`
  fragment LoanTrancheFormFields on LoanTrancheType {
    ...LoanTrancheShallowFields
    loantrancheamortizationSet {
      ...LoanTrancheAmortizationShallowFields
      initialIndicativeBenchmark {
        id
      }
      loantrancheamortizationprincipalpaymentSet {
        ...LoanTrancheAmortizationPrincipalPaymentShallowFields
      }
      loantrancheamortizationinterestperiodSet {
        ...LoanTrancheAmortizationInterestPeriodShallowFields
      }
    }
    loantranchecollateralSet {
      ...LoanTrancheCollateralShallowFields
    }
    covenantSet {
      ...CovenantShallowFields
    }
    creditratingSet {
      ...CreditRatingShallowFields
    }
    deal {
      ...DealBasicFields
    }
    escrowaccountSet {
      ...EscrowAccountShallowFields
    }
    reserveSet {
      ...ReserveShallowFields
    }
    benchmarkoptionSet {
      ...BenchmarkOptionShallowFields
      benchmark {
        ...BenchmarkShallowFields
      }
    }
    alternatebaseratebenchmarkSet {
      ...AlternateBaseRateBenchmarkShallowFields
    }
    loantranchefloatingratedata {
      ...LoanTrancheFloatingRateDataShallowFields
    }
    loantrancheguarantorSet {
      ...LoanTrancheGuarantorShallowFields
    }
    loantranchemultidrawdata {
      ...LoanTrancheMultiDrawDataShallowFields
    }
    loantrancherevolverswinglinelocdata {
      ...LoanTrancheRevolverSwinglineLOCDataShallowFields
      loanTrancheChargedAgainst {
        ...LoanTrancheShallowFields
      }
    }
    loantrancheadjustableratedata {
      ...LoanTrancheAdjustableRateDataShallowFields
      loantrancheadjustablerateperiodSet {
        ...LoanTrancheAdjustableRatePeriodShallowFields
      }
      initialIndicativeBenchmark {
        id
      }
      resetBenchmark {
        id
      }
    }
    applicablemarginSet {
      ...ApplicableMarginShallowFields
    }
    feeSet {
      ...FeeShallowFields
      feecalculationrule {
        ...FeeCalculationRuleShallowFields
      }
    }
    presentApplicableMargin {
      ...ApplicableMarginShallowFields
    }
  }
  ${CovenantShallowFields}
  ${CreditRatingShallowFields}
  ${DealBasicFields}
  ${EscrowAccountShallowFields}
  ${LoanTrancheShallowFields}
  ${LoanTrancheAmortizationShallowFields}
  ${LoanTrancheAmortizationPrincipalPaymentShallowFields}
  ${LoanTrancheAmortizationInterestPeriodShallowFields}
  ${LoanTrancheCollateralShallowFields}
  ${LoanTrancheGuarantorShallowFields}
  ${ReserveShallowFields}
  ${BenchmarkOptionShallowFields}
  ${BenchmarkShallowFields}
  ${AlternateBaseRateBenchmarkShallowFields}
  ${LoanTrancheFloatingRateDataShallowFields}
  ${LoanTrancheMultiDrawDataShallowFields}
  ${LoanTrancheAdjustableRateDataShallowFields}
  ${ApplicableMarginShallowFields}
  ${FeeShallowFields}
  ${FeeCalculationRuleShallowFields}
  ${LoanTrancheAdjustableRatePeriodShallowFields}
  ${LoanTrancheRevolverSwinglineLOCDataShallowFields}
`;

export default LoanTrancheFormFields;
