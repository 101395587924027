import * as React from 'react';
import { LoanTrancheFormProps, SelectedBenchmarkOptions } from '../../types';
import { getBenchmarkOptions } from '../utils';
import LiborBenchmarkOptionsFields from './LiborBenchmarkOptionsFields';
import OtherBenchmarkOptionsFields from './OtherBenchmarkOptionsFields';
import AbrBenchmarkOptionsFields from './AbrBenchmarkOptionsFields';
import { BenchmarkOptionType, ApplicableMarginType } from 'types';
import './FloatingInterestFields.scss';

const emptyArray = [];

type State = {
  selectedSet: Set<SelectedBenchmarkOptions>;
};
class FloatingInterestFields extends React.Component<
  LoanTrancheFormProps,
  State
> {
  constructor(props: LoanTrancheFormProps) {
    super(props);

    const {
      liborBenchmarkOptions,
      primeBenchmarkOption,
      fedFundsBenchmarkOption,
      isAbrSelected,
    } = getBenchmarkOptions(this.props);

    let selectedSet: Set<SelectedBenchmarkOptions> = new Set();

    if (liborBenchmarkOptions.length) selectedSet = selectedSet.add('libor');
    if (isAbrSelected) selectedSet = selectedSet.add('abr');
    if (primeBenchmarkOption.benchmarkId)
      selectedSet = selectedSet.add('prime');
    if (fedFundsBenchmarkOption.benchmarkId)
      selectedSet = selectedSet.add('fedFunds');

    this.state = {
      selectedSet,
    };
  }

  handleToggleSelected = (option: SelectedBenchmarkOptions) => {
    let { selectedSet } = this.state;
    const { data, handleRemoveApplicableMargin } = this.props;
    if (selectedSet.has(option)) {
      selectedSet.delete(option);
    } else {
      selectedSet = selectedSet.add(option);
    }
    const newSet = new Set(selectedSet);
    this.setState({
      selectedSet: newSet,
    });

    if (newSet.size === 0) {
      (data.applicablemarginSet || []).forEach(
        (applicableMargin: ApplicableMarginType, index: number) => {
          if (index === 0) {
            this.props.replaceEntity('applicablemarginSet', {
              ...applicableMargin,
              name: null,
              primeMargin: null,
              liborMargin: null,
              fedFundsMargin: null,
              abrMargin: null,
            });
          } else {
            handleRemoveApplicableMargin(applicableMargin);
          }
        },
      );
    }
  };

  getFirstSelectedBenchmarkOption = (
    liborBenchmarkOptions: ReadonlyArray<BenchmarkOptionType>,
    isAbrSelected: boolean,
    primeBenchmarkOption: BenchmarkOptionType,
    fedFundsBenchmarkOption: BenchmarkOptionType,
  ) => {
    const { selectedSet } = this.state;
    if (liborBenchmarkOptions.length) return 'libor';
    if (selectedSet.has('abr') || isAbrSelected) return 'abr';
    if (selectedSet.has('prime') || primeBenchmarkOption.benchmarkId)
      return 'prime';
    if (selectedSet.has('fedFunds') || fedFundsBenchmarkOption.benchmarkId)
      return 'fedFunds';
    return 'libor';
  };

  render() {
    const {
      data,
      disabled,
      benchmarksReferenceData,
      blurEventForm,
      onUpdateApplicableMargin,
      handleRemoveApplicableMargin,
      isSaving,
      errors,
    } = this.props;
    const { applicablemarginSet } = data;
    const {
      liborBenchmarks,
      primeBenchmark,
      fedFundsBenchmark,
      abrBenchmarkMap,
    } = benchmarksReferenceData;

    const { selectedSet } = this.state;

    const {
      liborBenchmarkOptions,
      liborBenchmarkOptionsBenchmarkIds,
      primeBenchmarkOption,
      fedFundsBenchmarkOption,
      abrBenchmarkOptionsMap,
      isAbrSelected,
    } = getBenchmarkOptions(this.props);

    const firstSelectedBenchmarkOption = this.getFirstSelectedBenchmarkOption(
      liborBenchmarkOptions,
      isAbrSelected,
      primeBenchmarkOption,
      fedFundsBenchmarkOption,
    );

    return (
      <div className="FloatingInterestFields">
        <LiborBenchmarkOptionsFields
          applicablemarginSet={applicablemarginSet || emptyArray}
          benchmarkOptionsBenchmarkIds={liborBenchmarkOptionsBenchmarkIds}
          benchmarks={liborBenchmarks}
          blurEventForm={blurEventForm}
          data={liborBenchmarkOptions}
          disabled={disabled}
          errors={errors}
          handleRemoveApplicableMargin={handleRemoveApplicableMargin}
          isFirstSelected={firstSelectedBenchmarkOption === 'libor'}
          isSaving={isSaving}
          isSelected={selectedSet.has('libor')}
          onChange={this.props.onUpdateBenchmarkOption}
          onDelete={this.props.handleRemoveBenchmarkOption}
          onToggleSelected={this.handleToggleSelected}
          onUpdateApplicableMargin={onUpdateApplicableMargin}
          trancheId={data.id}
        />
        <AbrBenchmarkOptionsFields
          applicablemarginSet={applicablemarginSet || emptyArray}
          benchmarks={abrBenchmarkMap}
          blurEventForm={blurEventForm}
          data={abrBenchmarkOptionsMap}
          disabled={disabled}
          errors={errors}
          handleRemoveApplicableMargin={handleRemoveApplicableMargin}
          isFirstSelected={firstSelectedBenchmarkOption === 'abr'}
          isSaving={isSaving}
          isSelected={selectedSet.has('abr')}
          onChange={this.props.onUpdateAbrOption}
          onDelete={this.props.handleRemoveAbrOption}
          onToggleSelected={this.handleToggleSelected}
          onUpdateApplicableMargin={onUpdateApplicableMargin}
          trancheId={data.id}
        />
        <OtherBenchmarkOptionsFields
          applicablemarginSet={applicablemarginSet || emptyArray}
          benchmark={primeBenchmark}
          blurEventForm={blurEventForm}
          data={primeBenchmarkOption}
          disabled={disabled}
          errors={errors}
          fieldName="Prime"
          handleRemoveApplicableMargin={handleRemoveApplicableMargin}
          isFirstSelected={firstSelectedBenchmarkOption === 'prime'}
          isSaving={isSaving}
          isSelected={selectedSet.has('prime')}
          onChange={this.props.onUpdateBenchmarkOption}
          onDelete={this.props.handleRemoveBenchmarkOption}
          onToggleSelected={this.handleToggleSelected}
          onUpdateApplicableMargin={onUpdateApplicableMargin}
          trancheId={data.id}
        />
        <OtherBenchmarkOptionsFields
          applicablemarginSet={applicablemarginSet || emptyArray}
          benchmark={fedFundsBenchmark}
          blurEventForm={blurEventForm}
          data={fedFundsBenchmarkOption}
          disabled={disabled}
          errors={errors}
          fieldName="Federal Funds"
          handleRemoveApplicableMargin={handleRemoveApplicableMargin}
          isFirstSelected={firstSelectedBenchmarkOption === 'fedFunds'}
          isSaving={isSaving}
          isSelected={selectedSet.has('fedFunds')}
          onChange={this.props.onUpdateBenchmarkOption}
          onDelete={this.props.handleRemoveBenchmarkOption}
          onToggleSelected={this.handleToggleSelected}
          onUpdateApplicableMargin={onUpdateApplicableMargin}
          trancheId={data.id}
        />
      </div>
    );
  }
}

export default FloatingInterestFields;
