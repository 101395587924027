import * as React from 'react';
import cx from 'classnames';
import { getPortionFromAmount, getAmountFromPortion } from '../../utils';
import LendingEntityGroup from '../LendingEntityGroup/LendingEntityGroup';
import { hoverMethods, ux, invariant } from 'utils';
import {
  LoanTrancheOwnershipWithIdType,
  InstitutionOwnershipPortionTypeWithDisplay,
  SortBy,
  ValidationMap,
  LoanTrancheOwnershipPortionInputWithDisplayInfo,
} from 'types';
import {
  IconButton,
  Form,
  ConfirmModal,
  FontAwesomeIconTypes,
} from 'components';
import { KeyPath } from 'lsredux';

type Props = {
  calculateAndUpdateOwnership: () => void;
  disabled: boolean;
  errors: ValidationMap | null | undefined;
  institutionPortion: InstitutionOwnershipPortionTypeWithDisplay;
  institutionPortionIndex: number;
  onOpenAddLendingEntityModal: (id: string) => void;
  ownership: LoanTrancheOwnershipWithIdType;
  removeEntity: (keyPath: KeyPath, entity: any) => void;
  replaceEntity: (keyPath: KeyPath, entity: any) => void;
  sortBy: SortBy;
  totalAmount: string;
  trancheIndex: number;
};

type State = {
  isDeleteModalOpen: boolean;
  isExpanded: boolean;
  isHovered: boolean;
};

type HandlerType = 'COMMITMENT' | 'OWNERSHIP';

class LenderRegistryInstitutionRow extends React.Component<Props, State> {
  static PlusIcon: FontAwesomeIconTypes = ['fal', 'plus'];

  static MinusIcon: FontAwesomeIconTypes = ['fal', 'minus'];

  static AddLendingEntityIcon: FontAwesomeIconTypes = ['far', 'plus-circle'];

  fields: {
    [key: string]: any;
  };

  state = {
    isExpanded: true,
    isHovered: false,
    isDeleteModalOpen: false,
  };

  handleHoverLeave: () => void;

  handleMouseEnter: () => void;

  handleMouseLeave: () => void;

  handleMouseMove: (e: React.MouseEvent<any>) => void;

  handleCommitmentChange: (value: string | null | undefined) => void;

  handlePortionChange: (value: string | null | undefined) => void;

  constructor(props: Props) {
    super(props);
    this.handleHoverLeave = hoverMethods.handleHoverLeave.bind(this);
    this.handleMouseEnter = hoverMethods.handleMouseEnter.bind(this);
    this.handleMouseLeave = hoverMethods.handleMouseLeave.bind(this);
    this.handleMouseMove = hoverMethods.handleMouseMove.bind(this);

    this.handleCommitmentChange = this.getInputChangeHandler('COMMITMENT').bind(
      this,
    );
    this.handlePortionChange = this.getInputChangeHandler('OWNERSHIP').bind(
      this,
    );

    this.fields = {
      portion: {
        id: 'institutionTotalPortion',
        propertyName: 'institutionTotalPortion',
        allowNegative: false,
        className:
          'lenderRegistryInstitutionRow__InputColumn lenderRegistryInstitutionRow__InputColumn--Bold',
        onChange: this.handlePortionChange,
      },
      amount: {
        id: 'institutionTotalAmount',
        propertyName: 'institutionTotalAmount',
        allowNegative: false,
        className:
          'lenderRegistryInstitutionRow__InputColumn lenderRegistryInstitutionRow__InputColumn--Bold',
        onChange: this.handleCommitmentChange,
      },
    };
  }

  componentDidUpdate = (prevProps: Props) => {
    const { calculateAndUpdateOwnership } = this.props;
    if (
      this.props.institutionPortion &&
      this.props.institutionPortion.institutionTotalAmount !==
        prevProps.institutionPortion.institutionTotalAmount
    ) {
      calculateAndUpdateOwnership();
    }
  };

  getInputChangeHandler = (type: HandlerType) => (
    value: string | null | undefined,
  ) => {
    if (value) {
      const {
        institutionPortion,
        ownership,
        replaceEntity,
        trancheIndex,
        totalAmount,
      } = this.props;
      invariant(ownership, 'Ownership Object is not present!');
      let amount;
      let portion;

      if (type === 'COMMITMENT') {
        amount = Number(value).toFixed(2);
        portion = getPortionFromAmount(totalAmount, amount);
      } else {
        portion = value;
        amount = getAmountFromPortion(totalAmount, portion);
      }

      replaceEntity(
        [
          'loantrancheSet',
          String(trancheIndex),
          'loantrancheinstitutionownershipportionSet',
        ],
        {
          ...institutionPortion,
          institutionTotalAmount: amount,
          institutionTotalPortion: portion,
        },
      );
    }
  };

  handleOpenAddEntityModal = () => {
    const { onOpenAddLendingEntityModal, institutionPortion } = this.props;
    onOpenAddLendingEntityModal(institutionPortion.id);
  };

  renderNameColumn = () => {
    const { institutionPortion } = this.props;

    const hasSubsidiaries =
      institutionPortion.loantrancheownershipportionSet &&
      institutionPortion.loantrancheownershipportionSet.length > 0;

    const { isExpanded } = this.state;
    let icon = null;
    if (isExpanded) {
      icon = LenderRegistryInstitutionRow.MinusIcon;
    } else {
      icon = LenderRegistryInstitutionRow.PlusIcon;
    }

    if (!hasSubsidiaries) {
      return (
        <div className="lenderRegistryInstitutionRow__NameColumn">
          <div className="lenderRegistryInstitutionRow__Icon" />
          <div className="lenderRegistryInstitutionRow__NameColumnText">
            {institutionPortion.institutionName}
          </div>
        </div>
      );
    }

    return (
      <div className="lenderRegistryInstitutionRow__NameColumn">
        <IconButton
          alt="Expand Accordion Icon"
          className="lenderRegistryInstitutionRow__Icon"
          icon={icon}
          onClick={this.handleToggleExpanded}
        />
        <div className="lenderRegistryInstitutionRow__NameColumnText">
          {institutionPortion.institutionName}
        </div>
      </div>
    );
  };

  renderIconColumn = () => {
    const { isHovered } = this.state;
    const { disabled } = this.props;
    return (
      <div className="lenderRegistryInstitutionRow__IconColumn">
        {isHovered && (
          <>
            <IconButton
              alt="Add Lending Entity Icon"
              className="lenderRegistryInstitutionRow__Icon"
              disabled={disabled}
              icon={LenderRegistryInstitutionRow.AddLendingEntityIcon}
              onClick={this.handleOpenAddEntityModal}
            />
            <IconButton
              alt="Delete Icon"
              disabled={disabled}
              icon="trash"
              onClick={this.handleToggleDeleteModal}
            />
          </>
        )}
      </div>
    );
  };

  handleToggleExpanded = () => {
    this.setState(state => ({ isExpanded: !state.isExpanded }));
  };

  handleToggleDeleteModal = () => {
    this.setState(state => ({ isDeleteModalOpen: !state.isDeleteModalOpen }));
  };

  getLoanTrancheOwnershipPortionSetKeyPath = (): KeyPath => {
    const { trancheIndex, institutionPortionIndex } = this.props;

    return [
      'loantrancheSet',
      String(trancheIndex),
      'loantrancheinstitutionownershipportionSet',
      String(institutionPortionIndex),
      'loantrancheownershipportionSet',
    ];
  };

  handleDelete = () => {
    const { removeEntity, trancheIndex, institutionPortion } = this.props;

    removeEntity(
      [
        'loantrancheSet',
        String(trancheIndex),
        'loantrancheinstitutionownershipportionSet',
      ],
      institutionPortion,
    );
  };

  renderLendingEntities = () => {
    const {
      institutionPortion,
      disabled,
      removeEntity,
      replaceEntity,
      totalAmount,
      sortBy,
      errors,
    } = this.props;
    const { isExpanded } = this.state;
    const hasSubsidiaries =
      institutionPortion.loantrancheownershipportionSet &&
      institutionPortion.loantrancheownershipportionSet.length > 0;
    if (hasSubsidiaries && isExpanded) {
      return (
        <LendingEntityGroup
          disabled={disabled}
          errors={errors}
          institutionPortionId={institutionPortion.id}
          institutionTotalAmount={
            institutionPortion.institutionTotalAmount || '0.00'
          }
          institutionTotalPortion={
            institutionPortion.institutionTotalPortion || '0.00'
          }
          lendingEntityKeyPath={this.getLoanTrancheOwnershipPortionSetKeyPath()}
          ownershipPortions={
            institutionPortion.loantrancheownershipportionSet as LoanTrancheOwnershipPortionInputWithDisplayInfo[]
          }
          removeEntity={removeEntity}
          replaceEntity={replaceEntity}
          sortBy={sortBy}
          trancheTotalAmount={totalAmount}
        />
      );
    }
    return null;
  };

  render() {
    const { institutionPortion } = this.props;

    const { isExpanded, isDeleteModalOpen } = this.state;
    const hasSubsidiaries =
      institutionPortion.loantrancheownershipportionSet &&
      institutionPortion.loantrancheownershipportionSet.length > 0;

    const rowClass = cx(
      'lenderRegistryInstitutionRow',
      ux(
        !hasSubsidiaries || !isExpanded,
        'lenderRegistryInstitutionRow--Collapsed',
      ),
    );

    return (
      <>
        <div
          className={rowClass}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {this.renderNameColumn()}
          {Form.FieldRenderer(
            Form.Percentage,
            this.fields.portion,
            {
              ...this.props,
              data: institutionPortion,
            },
            'LoanTrancheInstitutionOwnershipPortion',
            institutionPortion.id,
          )}
          {Form.FieldRenderer(
            Form.Money,
            this.fields.amount,
            {
              ...this.props,
              data: institutionPortion,
            },
            'LoanTrancheInstitutionOwnershipPortion',
            institutionPortion.id,
          )}
          <div className="lenderRegistryTranche__MoneyDisplay">-</div>
          <div className="lenderRegistryTranche__MoneyDisplay">-</div>
          {this.renderIconColumn()}
        </div>
        {this.renderLendingEntities()}
        <ConfirmModal
          confirmButtonText="Delete"
          deleteAction
          header="Delete"
          isOpen={isDeleteModalOpen}
          message={`Are you sure you want to delete ${institutionPortion.institutionName}? All of it's lending entities will also be deleted`}
          onConfirm={this.handleDelete}
          onReject={this.handleToggleDeleteModal}
        />
      </>
    );
  }
}

export default LenderRegistryInstitutionRow;
