import { BaseType } from 'types';
import { isWholeNumber } from 'utils';

/**
 * Determine if the entity being mutated requires a create or set mutation.
 * We assume that if the Id property is numeric, that we are editing a pre-existing
 * entity
 * @param {*} e
 */
function isEntity<T extends BaseType>(e: T): boolean {
  return isWholeNumber(e.id);
}

export default isEntity;
