import { StepMap, createStepRenderer } from '../StepRenderFactory';
import LenderBorrowerLoanApplication from './LenderBorrowerLoanApplication';
import LenderLoanApplication from './LenderLoanApplication';
import LenderLoanFunding from './LenderLoanFunding';
import LenderPromissoryNote from './LenderPromissoryNote';

/*
Steps

    aw_lender_borrower_loan_application = "aw_lender_borrower_loan_application"
    aw_lender_lender_loan_application = "aw_lender_lender_loan_application"
    aw_lender_promissory_note = "aw_lender_promissory_note"
    aw_lender_loan_funding = "aw_lender_loan_funding"
    # aw_lender_request_borrower_signature = "aw_lender_request_borrower_signature"
    aw_lender_resend_borrower_signature_request = "aw_lender_resend_borrower_signature_request"
    aw_lender_complete_lender_application = "aw_lender_complete_lender_application"
    aw_lender_sign_lender_application = "aw_lender_sign_lender_application"
    aw_lender_submit_to_sba = "aw_lender_submit_to_sba"
    aw_lender_sba_denied_lender_loan_application = "aw_lender_sba_denied_lender_loan_application"
    aw_lender_sba_approved_lender_loan_application = "aw_lender_sba_approved_lender_loan_application"
    aw_lender_request_promissory_note_signature = "aw_lender_request_promissory_note_signature"
    aw_lender_resend_promissory_note_signature_request = "aw_lender_resend_promissory_note_signature_request"
    aw_lender_fund_loan = "aw_lender_fund_loan"
*/

const stepMap: StepMap = {
  aw_lender_borrower_loan_application: LenderBorrowerLoanApplication,
  aw_lender_lender_loan_application: LenderLoanApplication,
  aw_lender_loan_funding: LenderLoanFunding,
  aw_lender_promissory_note: LenderPromissoryNote,
};

/**
 * Map Workflow Step slugs to the components that render them
 */
const LenderStepFactory = createStepRenderer(stepMap);

export default LenderStepFactory;
