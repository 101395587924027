import { merge } from 'immutable';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ReportView from './reportView/ReportView';
import { withToJS } from 'utils';
import { RouteParams, withRouteParams } from 'routing';
import { AuditTrailQuery } from 'lsgql';
import { ReduxDirectory } from 'lsredux';
import { withLoadingIndicator } from 'components';
import {
  setChangedItem,
  setSection,
  setUser,
  setViewStart,
  setViewEnd,
  resetFilters,
} from 'lsredux/actions/auditTrailFilters';

import './Report.scss';

const mapStateToProps: (state: any) => { rawData: any } = state => ({
  rawData: merge(
    state.getIn(ReduxDirectory.AuditTrailKeyPath),
    state.getIn(ReduxDirectory.AuditTrailFiltersKeyPath),
  ),
});

const params = [RouteParams.dealId];
export default compose(
  withRouteParams(params),
  connect(mapStateToProps, {
    setChangedItem,
    setSection,
    setUser,
    setViewStart,
    setViewEnd,
    dealId: params,
    resetFilters,
  }),
  withToJS,
  AuditTrailQuery,
  withLoadingIndicator('loading'),
)(ReportView);
