import { compose, withProps } from 'recompose';
import uuid from 'uuid/v4';
import { connect } from 'react-redux';
import { PublicInstitutionQuery, PublicIsValidJobQuery } from 'lsgql';
import SecondDRawOnboardingRedux from 'lsredux/reducer/forms/secondDrawOnboarding';
import { FormMediator, withLoadingIndicator } from 'components';
import { RouteParams, withRouteParams } from 'routing';

import { SecondDrawModal } from './SecondDrawModal';

const mapDispatchToProps = (dispatch: any) => {
  const generated = SecondDRawOnboardingRedux.actions.generateActions(dispatch);
  return generated;
};

const RoundTwoPublicLoanApplicationFormContainer = compose(
  withRouteParams([RouteParams.institutionTicker, RouteParams.institutionJob]),
  PublicInstitutionQuery,
  PublicIsValidJobQuery,
  withLoadingIndicator('loading'),

  withProps((props: any) => ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formQueryRefetch: async ({ id }) => {
      const res = {
        data: {
          id: uuid(),
          __typename: 'SbaSecondDrawOnboarding',
        },
        ok: true,
      };
      props.onClose();
      return Promise.resolve(res);
    },
  })),
  connect(SecondDRawOnboardingRedux.mapStateToProps, mapDispatchToProps),
  FormMediator({
    formId: 'SecondDrawOnboarding',
    baseObjectTypeName: 'SbaSecondDrawOnboardingInput' as any,
    // postSaveRoutePath: RouteTable.application.toSbaPppLoanApplicationSuccess,
    disableFrame: true,
    successAlert: {
      message:
        'PPP platform access request sent. Please check your email for next steps.',
    },
  }),
)(SecondDrawModal);

export default RoundTwoPublicLoanApplicationFormContainer;
