import { mutationBuilder } from './builders';
import { SetPaymentRecordBorrowerMutationDefinition } from './definitions';
import { PaymentRecordBorrowerInput, PaymentRecordType } from 'types';

const PaymentRecordBorrowerMutationMethods = {
  setPaymentRecordBorrower: mutationBuilder<
    PaymentRecordBorrowerInput,
    PaymentRecordType
  >(SetPaymentRecordBorrowerMutationDefinition, {
    build: mutate => paymentRecordBorrowerData =>
      mutate({ variables: { paymentRecordBorrowerData } }),
  }),
};

export default PaymentRecordBorrowerMutationMethods;
