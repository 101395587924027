import uuid from 'uuid/v4';
import SbaPppRoundTwoSecondDrawLenderApplicationMutations from '../../../graphql/mutations/SbaPppRoundTwoSecondDrawLenderApplicationMutations';
import SbaPppLoanApplicationMutations from '../../../graphql/mutations/PrivateSbaPppLoanApplicationMutations';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import { DealType } from 'types';

const initialSbaPppLenderClosingWorkflowQuestions = {
  id: uuid(),
  sbaPppLoanApplication: {
    id: uuid(),
    __typename: 'SbaPppLoanApplicationType',
  },
  sbaPppRoundTwoLenderApplication: {
    id: uuid(),
    __typename: 'SbaPppRoundTwoSecondDrawLenderApplicationType',
  },
  __typename: 'DealType',
};

const lifecycle = {
  mutations: {
    ...SbaPppRoundTwoSecondDrawLenderApplicationMutations,
    ...SbaPppLoanApplicationMutations,
  },
  validators: {},
};

export default createForm<DealType, DealType>(
  Directory.SbaPppRoundTwoLenderApplicationKey,
  initialSbaPppLenderClosingWorkflowQuestions as any,
  lifecycle,
);
