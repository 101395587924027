import { fromJS } from 'immutable';
import { TOGGLE_MENU } from '../../actions/menu';

import standardReducerFactory from '../standardReducer';

const initialState = fromJS({
  menuOpen: true,
});

const reducerMap = {};

reducerMap[TOGGLE_MENU] = state =>
  state.set('menuOpen', !state.get('menuOpen'));

export default standardReducerFactory(reducerMap, initialState);
