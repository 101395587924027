/**
 * Datarooms-related reducer.
 */
import { fromJS } from 'immutable';
import {
  OPEN_FOLDER,
  CLOSE_FOLDER,
  SORT_BY,
} from '../../actions/dataroomPresentationalActions';

import Directory from '../../directory';
import standardReducerFactory from '../standardReducer';

// Assumes a single folder view at any given time (no scoping).
const initialState = fromJS({
  openFolders: {},
  sortBy: { column: 'name', reverse: false },
});

const reducerMap: {
  [key: string]: (arg0: any, arg1: any) => Record<string, any>;
} = {};
reducerMap[OPEN_FOLDER] = (state, action) =>
  state.setIn([Directory.OpenFoldersKey, action.payload.folderId], true);

reducerMap[CLOSE_FOLDER] = (state, action) =>
  state.setIn([Directory.OpenFoldersKey, action.payload.folderId], false);

reducerMap[SORT_BY] = (state, action) => state.set('sortBy', action.payload);

export default standardReducerFactory(reducerMap, initialState);
