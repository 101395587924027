import { MutationOptions } from '@apollo/client';
import { DocumentNode, MutationMethod, MutationResult } from '../../types';
import { getAuthenticatedClient } from '../../authenticatedClient';
import { invariant } from 'utils';

type AdditionalOptions = { refetchQueries?: any; variables?: {} };

/**
 * Method passed to transform that can create and invoke the mutation
 */
export type MutationExecutor<TOut> = (
  additionalOptions: AdditionalOptions,
) => Promise<MutationResult<TOut>>;

/**
 * The final usable mutation method
 */
// export type MutationMethod<T: BaseType> = T => Promise<FetchResult<Result<T>>>;
type SimpleArgs<TIn, TOut> = {
  // pass a method to the child that allows them to define the final method
  build: (mutate: MutationExecutor<TOut>) => MutationMethod<TIn, TOut>;
};

/* A method that only accepts additional mutation options */

/**
 * Build a mutation method according to standard rules and behavior
 * @param {*} mutation
 * @param {*} args
 */
function mutationBuilder<TIn, TOut>(
  mutation: DocumentNode,
  args: SimpleArgs<TIn, TOut>,
): MutationMethod<TIn, TOut> {
  invariant(mutation, 'mutation');
  invariant(args, 'args');
  invariant(args.build, 'args.build');

  const mutationExecutor: MutationExecutor<TOut> = (
    additionalOptions: AdditionalOptions,
  ) => {
    const options: MutationOptions = {
      errorPolicy: 'all',
      mutation,
      ...additionalOptions,
    };
    const client = getAuthenticatedClient();
    return client.mutate(options);
  };

  return args.build(mutationExecutor);
}

export default mutationBuilder;
