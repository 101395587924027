import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import WorkflowContentShell from '../WorkflowContentShell';
import { WorkflowSection } from '../workflowList';
import { fields } from './utils';
import {
  CalculatedValuesType,
  HelpBoxType,
  ForgivenessFormProps,
} from './types';
import { LessThanFullForgivenessCauses } from './partials';
import { EzFormQualificationsModal } from './modals';
import { Text, Form, Button, Tooltip } from 'components';
import { haltBubble } from 'utils';
import { HelpBoxCallbackType } from 'features/sbaPppForgivenessWorkflow/workflowList/WorkflowHelpBox';

interface Props extends ForgivenessFormProps {
  calculatedValues: CalculatedValuesType;
  onChange: (
    value:
      | (string | null | undefined)
      | (boolean | null | undefined)
      | (Array<any> | null | undefined),
    fieldId: string,
  ) => void;
  onSwitchForms: () => void;
  renderCtaButtons: () => React.ReactElement;
  renderHelpBox: (
    id: HelpBoxType,
    focusId?: HelpBoxType,
    callback?: HelpBoxCallbackType,
  ) => React.ReactElement;
  TypeName: string;
}

const EzForm = (props: Props) => {
  const {
    calculatedValues,
    onChange,
    onSwitchForms,
    renderCtaButtons,
    renderHelpBox,
    TypeName,
  } = props;

  const [focusedSection, setFocusedSection] = useState<HelpBoxType>(
    HelpBoxType.ezMain,
  );

  const [isQualificationsModalOpen, setIsQualificationsModalOpen] = useState(
    false,
  );

  const {
    sbapppforgivenesscalculation,
  } = props?.data?.sbaPppForgivenessWorkflow;

  const handleOpenQualificationsModal = (e: React.SyntheticEvent<any>) => {
    haltBubble(e);
    setIsQualificationsModalOpen(true);
  };

  return (
    <WorkflowContentShell
      className="ForgivenessCalculationForm"
      ctaButtons={renderCtaButtons}
      helpBox={renderHelpBox(
        HelpBoxType.ezMain,
        focusedSection,
        handleOpenQualificationsModal,
      )}
      title="PPP Loan Forgiveness EZ Calculation"
    >
      <Prompt
        message="There are unsaved changes on this form that will be lost if you continue."
        when={props.isDirty}
      />
      <div className="FormController">
        <Text className="bold">Not sure you can use the EZ form?</Text>
        <Button.Text
          label="Check qualifications"
          onClick={handleOpenQualificationsModal}
        />
        <Button.Text
          label="Or, switch to standard form"
          onClick={onSwitchForms}
        />
      </div>
      <WorkflowSection
        header="Total payroll costs"
        helpBox={renderHelpBox(HelpBoxType.payrollTotalEz, focusedSection)}
        onClick={() => setFocusedSection(HelpBoxType.payrollTotalEz)}
      >
        <Form.Group className="Field-Tooltip">
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.payrollCosts,
              onChange,
            },
            props,
            TypeName,
            props.data.id,
          )}
          <Tooltip>
            Total eligible payroll costs incurred or paid during the selected
            covered period. These costs are the sum of cash compensation,
            benefits contributions and taxes paid.
          </Tooltip>
        </Form.Group>
      </WorkflowSection>
      <WorkflowSection
        header="Nonpayroll business payments"
        helpBox={renderHelpBox(HelpBoxType.nonPayroll, focusedSection)}
        onClick={() => setFocusedSection(HelpBoxType.nonPayroll)}
      >
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.mortgageInterest,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.rentOrLease,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.utilities,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.operationsExpenditures,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.propertyDamageCosts,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.supplierCosts,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.workerProtectionExpenditures,
            onChange,
          },
          props,
          TypeName,
          props.data.id,
        )}
      </WorkflowSection>
      <WorkflowSection
        className="ForgivenessAmount"
        header="Forgiveness"
        helpBox={renderHelpBox(HelpBoxType.forgivenessAmount, focusedSection)}
        onClick={() => setFocusedSection(HelpBoxType.forgivenessAmount)}
      >
        <Form.Group className="Field-Tooltip">
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.forgivenessAmount,
              onChange,
            },
            props,
            TypeName,
            props.data.id,
          )}
        </Form.Group>
        <Text className="ForgivenessIsSmallest">
          The Forgiveness Amount is the smallest of:
        </Text>
        <Form.Group className="Field-Tooltip ForgivenessAmountSubValue">
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.modifiedTotal,
              onChange,
            },
            props,
            TypeName,
            props.data.id,
          )}
          <Text>Payroll Costs + Nonpayroll Costs</Text>
        </Form.Group>
        <Form.Group className="Field-Tooltip ForgivenessAmountSubValue">
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.pppLoanAmount,
              onChange,
            },
            props,
            TypeName,
            props.data.id,
          )}
          <Text>PPP Loan Amount</Text>
        </Form.Group>
        <Form.Group className="Field-Tooltip ForgivenessAmountSubValue">
          {Form.FieldRenderer(
            Form.Money,
            {
              ...fields.payrollCostRequirement,
              onChange,
            },
            props,
            TypeName,
            props.data.id,
          )}
          <Text>Payroll Cost 60% Requirement</Text>
        </Form.Group>
        <LessThanFullForgivenessCauses
          calculatedValues={calculatedValues}
          sbapppforgivenesscalculation={sbapppforgivenesscalculation}
        />
      </WorkflowSection>
      <EzFormQualificationsModal
        isOpen={isQualificationsModalOpen}
        onClose={() => setIsQualificationsModalOpen(false)}
      />
    </WorkflowContentShell>
  );
};

export default EzForm;
