import React, { useState } from 'react';
import { StepProps } from '../StepProps';
import WorkflowStep from '../WorkflowStep';
import { LenderRequestResubmissionForm as RequestResubmissionForm } from '../modals';
import { invariant, haltBubble } from 'utils';
import { Text, Button, RouteTo, TextLink } from 'components';
import triggerClosingWorkflowTransition from 'lsgql/mutations/TransitionClosingWorkflow';
import { RouteTable } from 'routing';
import raiseAlert from 'lsredux/raiseAlert';

function LenderBorrowerLoanApplication(props: StepProps) {
  const { step, canEdit, isDimmed, dealClosingWorkflow, dealId } = props;
  const { state } = dealClosingWorkflow;
  invariant(
    step.slug === 'aw_lender_borrower_loan_application',
    'invalid slug',
  );

  const [isModalOpen, setIsModalOpen] = useState({
    additionalInfo: false,
    requestResubmission: false,
  });

  /*
  const handleAdditionalInfoToggle = (e?: React.SyntheticEvent) => {
    e && haltBubble(e);
    setIsModalOpen({
      ...isModalOpen,
      additionalInfo: !isModalOpen.additionalInfo,
    });
  };
  */

  const handleRequestSignature = async (e: React.SyntheticEvent) => {
    haltBubble(e);

    const res = await triggerClosingWorkflowTransition({
      workflowId: dealClosingWorkflow.id,
      state: 'borrower_application_signature_requested',
    });

    if (res.data.transitionClosingWorkflow.ok) {
      raiseAlert(
        'Your request for borrower signature has been sent',
        'success',
      );
    } else {
      raiseAlert(
        "The request has failed. Please try again. If that doesn't work, please contact technical support",
        'error',
      );
    }
  };

  const handleRequestResubmissionToggle = (e?: React.SyntheticEvent) => {
    e && haltBubble(e);
    setIsModalOpen({
      ...isModalOpen,
      requestResubmission: !isModalOpen.requestResubmission,
    });
  };

  const handleSkipAhead = (e: React.SyntheticEvent) => {
    haltBubble(e);
    triggerClosingWorkflowTransition({
      workflowId: dealClosingWorkflow.id,
      state: 'lender_approve_borrower_application',
    });
  };

  const handleAlreadySigned = (e: React.SyntheticEvent) => {
    haltBubble(e);
    triggerClosingWorkflowTransition({
      workflowId: dealClosingWorkflow.id,
      state: 'lender_complete_lender_application',
    });
  };

  const workflowStepProps = {
    header: 'BORROWER LOAN APPLICATION',
    step,
    canEdit,
    isDimmed,
  };

  if (state == 'borrower_finish_application') {
    return (
      <WorkflowStep {...workflowStepProps}>
        <div className="Group justified">
          <Text value="Please wait for the borrower to notify you they are ready for review." />
          <Button.Text label="Or, skip ahead" onClick={handleSkipAhead} />
        </div>
      </WorkflowStep>
    );
  } else if (!canEdit) {
    return (
      <WorkflowStep {...workflowStepProps}>
        {state == 'borrower_lender_rejected_application' && (
          <Text value="You have resent the borrower their signature notification." />
        )}
        <Text value="The borrower’s documents are in the dataroom." />
        <Text value="Review additional information about their business for the SBA." />
      </WorkflowStep>
    );
  } else if (canEdit && !isDimmed) {
    if (state === 'lender_approve_borrower_application') {
      return (
        <WorkflowStep {...workflowStepProps}>
          <div className="Group justified">
            <Text value="Review the borrower’s loan application." />
            <TextLink
              as={RouteTo}
              label="Edit"
              to={RouteTable.deal.toDealSbaApplication(dealId)}
            />
          </div>
          <div className="Group justified">
            <Text value="Review the borrower's supporting documentation." />
            <TextLink
              as={RouteTo}
              label="Go to Dataroom"
              to={RouteTable.deal.toDealDataroom(dealId)}
            />
          </div>
          {/*
          <div className="Group justified">
            <Text value="Review additional information about their business for the SBA." />
            <Button.Text
              className="noMinWidth"
              label="Edit"
              onClick={handleAdditionalInfoToggle}
            />
          </div>
          */}
          <Text value="Click below when you’re ready for the borrower to sign their application." />
          <div className="Group left padded">
            <Button
              // disabled={props.deal.category === 'SBA_PPP_ROUND_TWO_SECOND_DRAW'}
              label="Request Borrower Signature"
              onClick={handleRequestSignature}
            />
            <Button.Secondary
              label="Request Resubmission"
              onClick={handleRequestResubmissionToggle}
            />
            <Button.Text label="Already Signed" onClick={handleAlreadySigned} />
          </div>
          {/*
          <BorrowerAdditionalInformationForm
            canEdit={true}
            dealClosingWorkflow={dealClosingWorkflow}
            isOpen={isModalOpen.additionalInfo}
            onClose={handleAdditionalInfoToggle}
          />
          */}
          <RequestResubmissionForm
            handleRequestResubmissionToggle={handleRequestResubmissionToggle}
            isOpen={isModalOpen.requestResubmission}
            onClose={handleRequestResubmissionToggle}
            workflowId={dealClosingWorkflow.id}
          />
        </WorkflowStep>
      );
    } else if (state === 'borrower_application_signature_requested') {
      return (
        <WorkflowStep {...workflowStepProps}>
          <Text value="You will receive an email when the borrower’s application been signed. You’ll then be able to complete and sign the lender loan application for submission to the SBA." />
          <Text value="If you think the borrower has not received their request for signature, you can send it to them again:" />
          <div className="Group padded">
            <Button.Secondary
              label="Resend Signature Request"
              onClick={handleRequestSignature}
            />
            <Button.Text label="Already Signed" onClick={handleAlreadySigned} />
          </div>
        </WorkflowStep>
      );
    }
  }
}

export default LenderBorrowerLoanApplication;
