/* eslint-disable @typescript-eslint/no-unused-vars */
import { compose, withState, withProps } from 'recompose';
import uuid from 'uuid/v4';
import { connect } from 'react-redux';
import { setFormDisabled } from 'lsredux';
import PrivateSbaPppLoanApplication from 'lsredux/reducer/forms/privateSbaPppLoanApplication';
import { FormMediator } from 'components';
import { RouteParams, withRouteParams } from 'routing';
import { SbaPppApplicantOwnershipType, SbaPppLoanApplicationType } from 'types';
import { RoundTwoPrivateSbaPppLoanApplicationForm } from './RoundTwoLoanApplicationForm';
import { buildMockedFormQueryRefetch } from './BuildMockedFormQueryRefetch';
import NewUserOnboardSecondDrawApplication from 'lsgql/mutations/TriggerNewUserSecondDrawOnboardingMutation';
import { RouteTable } from 'routing';

import './RoundTwoLoanApplication.scss';

function merge(
  stale?: SbaPppLoanApplicationType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fresh?: SbaPppLoanApplicationType,
) {
  const toUpdate = stale || fresh;
  return {
    ...toUpdate,
    category: 'SBA_PPP_ROUND_TWO_SECOND_DRAW',
    id: uuid(),

    sbapppinstitution: {
      ...toUpdate.sbapppinstitution,
      category: 'SBA_PPP_ROUND_TWO_SECOND_DRAW',
      id: uuid(),
      businessTinEinSsn: undefined,
      businessTinType: undefined,
      sbaPppLoanApplication: undefined,
      sbaPppLoanApplicationId: undefined,
      created: undefined,
      modified: undefined,
      institutionType: undefined,
    },
    sbappploanfinancials: {
      ...toUpdate.sbappploanfinancials,
      category: 'SBA_PPP_ROUND_TWO_SECOND_DRAW',
      id: uuid(),
      created: undefined,
      modified: undefined,
      firstDrawSbaLoanNumber: undefined,
      sbaPppLoanApplication: undefined,
      sbaPppLoanApplicationId: undefined,
      loanAmountDecimal: undefined,
      averageMonthlyPayroll: undefined,
      numberOfJobs: undefined,
      purposeOfLoan: [],
      otherReason: undefined,
      eidlAmount: undefined,
    },
    sbapppapplicantownershipSet: [
      {
        id: uuid(),
        category: 'SBA_PPP_ROUND_TWO_SECOND_DRAW',
        __typename: 'SbaPppApplicantOwnershipType',
      },
    ],
    question1: undefined,
    question2: undefined,
    question3: undefined,
    question4: undefined,
    question5: undefined,
    question6: undefined,
    question7: undefined,
    question8: undefined,
    question9: undefined,
    dealId: undefined,
    deal: undefined,
    confirmationNumber: undefined,
    canEditState: undefined,
    created: undefined,
    modified: undefined,
    submittedToSba: undefined,
    termsOfService: undefined,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  const generated = PrivateSbaPppLoanApplication.actions.generateActions(
    dispatch,
  );

  const onInstitutionChange = (value: string, fieldId: string) =>
    generated.mutateProperty(value, ['sbapppinstitution', fieldId]);

  const onLoanFinancialsChange = (value: string, fieldId: string) =>
    generated.mutateProperty(value, ['sbappploanfinancials', fieldId]);

  const addOwner = () =>
    generated.addEntity('sbapppapplicantownershipSet', {
      id: uuid(),
      category: 'SBA_PPP_ROUND_TWO_SECOND_DRAW',
      __typename: 'SbaPppApplicantOwnershipType',
    });

  const deleteOwner = (entity: SbaPppApplicantOwnershipType) =>
    generated.removeEntity('sbapppapplicantownershipSet', entity);

  const disableForm = (disable: boolean) =>
    dispatch(setFormDisabled('SbaPppLoanApplicationForm', disable));

  return {
    ...generated,
    onInstitutionChange,
    onLoanFinancialsChange,
    addOwner,
    deleteOwner,
    disableForm,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onboardingMethod = async (id: string, props: any) => {
  const onboardingResponse = await NewUserOnboardSecondDrawApplication(id);

  return Boolean(
    onboardingResponse?.data?.newUserOnboardSecondDrawApplication?.ok,
  );
};

const RoundTwoPrivateSbaPppLoanApplicationFormContainer = compose(
  withRouteParams([RouteParams.dealId]),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  withProps((props: any) => ({
    mergeApplications: merge,
  })),
  buildMockedFormQueryRefetch(
    'SBA_PPP_ROUND_TWO_SECOND_DRAW',
    onboardingMethod,
    (id: string) => RouteTable.application.toHome,
    'Save Successful!',
  ),
  connect(PrivateSbaPppLoanApplication.mapStateToProps, mapDispatchToProps),
  withState('confirmSubmitModalContent', 'setConfirmSubmitModalContent', {
    header: 'Confirm Changes',
    message:
      "Please confirm you want to save the changes you've made on your loan application.  If you have signed a previous version of your application, you will be required to sign again.",
  }),
  FormMediator({
    formId: 'RoundTwoSbaPppLoanApplicationForm',
    baseObjectTypeName: 'SbaPppLoanApplication',

    // must be removed for initial app
    // editSecurity: DealPerspectivePermissions.administer_sba_ppp_application,
    blurEventForm: true,
    confirmBeforeSubmit: true,
    simpleFooter: true,
    disableAlerts: true,
    disableRedirectOnSubmit: true,
  }),
)(RoundTwoPrivateSbaPppLoanApplicationForm);

export default RoundTwoPrivateSbaPppLoanApplicationFormContainer;
