import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import {
  AmortizationReaderShallowFields,
  LoanTrancheAmortizationShallowFields,
} from './generated';

const AmortizationReaderFields: DocumentNode = gql`
  fragment AmortizationReaderFields on AmortizationReaderType {
    ...AmortizationReaderShallowFields
    processedPrincipalPayments {
      count
      created
      date
      modified
      principalAfter
      principalAmount
      principalBefore
      interestAmount
    }
    loanTrancheAmortization {
      ...LoanTrancheAmortizationShallowFields
    }
  }
  ${AmortizationReaderShallowFields}
  ${LoanTrancheAmortizationShallowFields}
`;

export default AmortizationReaderFields;
