/**
 * Various default values for column configurations in a data grid.
 */

import { Column, Value } from './types';

function defaultAccessor<T, K extends keyof T>(
  row: T,
  column: Column<T, K>,
): Value {
  const rowkey: K = column.columnName as any;
  return (row[rowkey] as any) as string;
}

export default defaultAccessor;
