import React from 'react';
import InviteeContactCell from './InviteeContactCell';
import { DisplayTable } from 'components';
import { InvitationType } from 'types';

const sectionBaseClass = 'Section';
const inviteeRowBaseClass = `${sectionBaseClass}-UserRow`;

type Props = {
  index: number;
  invitation: InvitationType;
};

function UserRow(props: Props) {
  const { invitation, index } = props;

  return (
    <>
      <DisplayTable.TR
        className={`${inviteeRowBaseClass} ${inviteeRowBaseClass}-${
          index % 2 ? 'Even' : 'Odd'
        }`}
      >
        <DisplayTable.TD className={`${inviteeRowBaseClass}-Contact`}>
          <InviteeContactCell invitation={invitation} />
        </DisplayTable.TD>
        <DisplayTable.TD className={`${inviteeRowBaseClass}-Email`}>
          {invitation.email}
        </DisplayTable.TD>
        <DisplayTable.TD className={`${inviteeRowBaseClass}-DealAccess`}>
          {invitation.dealRole ? invitation.dealRole.name : '-'}
        </DisplayTable.TD>
        <DisplayTable.TD
          className={`${inviteeRowBaseClass}-Actions`}
        ></DisplayTable.TD>
      </DisplayTable.TR>
    </>
  );
}

export default UserRow;
