import React, { useEffect } from 'react';
import { Modal, Form, WrappedMediatorFormProps } from 'components';
import {
  ID,
  FormFieldsType,
  SbaPppApplicationResubmitRequestType,
} from 'types';

interface Props
  extends WrappedMediatorFormProps<SbaPppApplicationResubmitRequestType> {
  isOpen: boolean;
  onClose: (e?: React.SyntheticEvent) => void;
  dealId: ID;
  disableFormFields?: boolean;
  handleRequestResubmissionToggle: (e?: React.SyntheticEvent) => void;
}

const fields: FormFieldsType = {
  isApplicationError: {
    id: 'isApplicationError',
    propertyName: 'isApplicationError',
    label: 'Error on application',
  },
  isMissingDocs: {
    id: 'isMissingDocs',
    propertyName: 'isMissingDocs',
    label: 'Missing supporting documents',
  },
  isOther: {
    id: 'isOther',
    propertyName: 'isOther',
    label: 'Other',
  },
  comments: {
    id: 'comments',
    propertyName: 'comments',
    fieldName: 'Comments',
    rows: 4,
  },
};

const SbaPppLenderRequestResubmission = 'SbaPppLenderRequestResubmission';

const LenderRequestResubmissionFormFields = (props: Props) => {
  const { isOpen, data, mutateProperty, disableFormFields } = props;

  useEffect(() => {
    mutateProperty(props.dealId, 'dealId');
  });

  useEffect(() => {
    if (isOpen) {
      mutateProperty(null, 'isApplicationError');
      mutateProperty(null, 'isMissingDocs');
      mutateProperty(null, 'isOther');
      mutateProperty(null, 'comments');
    }
  }, [isOpen, mutateProperty]);

  return (
    <div className="LenderRequestResubmission">
      {Form.FieldRenderer(
        Form.Checkbox,
        {
          ...fields.isApplicationError,
          onChange: props.mutateProperty,
          disabled: disableFormFields,
          disabledReadable: disableFormFields,
        },
        props,
        SbaPppLenderRequestResubmission,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Checkbox,
        {
          ...fields.isMissingDocs,
          onChange: props.mutateProperty,
          disabled: disableFormFields,
          disabledReadable: disableFormFields,
        },
        props,
        SbaPppLenderRequestResubmission,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Checkbox,
        {
          ...fields.isOther,
          onChange: props.mutateProperty,
          disabled: disableFormFields,
          disabledReadable: disableFormFields,
        },
        props,
        SbaPppLenderRequestResubmission,
        data.id,
      )}
      <Form.Divider className="transparent" />
      {Form.FieldRenderer(
        Form.TextArea,
        {
          ...fields.comments,
          onChange: props.mutateProperty,
          disabled: disableFormFields,
          disabledReadable: disableFormFields,
        },
        props,
        SbaPppLenderRequestResubmission,
        data.id,
      )}
    </div>
  );
};

const LenderRequestResubmissionForm = (props: Props) => {
  const { isOpen, onClose, onSave, disableFormFields = false } = props;

  function handleSave() {
    // TODO: SBA-HACK: Need to toggle modal isOpen state and save
    props.handleRequestResubmissionToggle();
    onSave();
  }

  return disableFormFields ? (
    <LenderRequestResubmissionFormFields {...props} />
  ) : (
    <Modal
      className="steel LenderRequestResubmissionModal"
      confirmButtonText="Send Request"
      header="Request Resubmission"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSave}
      width="OneThird"
    >
      <Form.Text value="Please tell the borrower why the application is being returned." />
      <LenderRequestResubmissionFormFields {...props} />
    </Modal>
  );
};

export default LenderRequestResubmissionForm;
