import * as React from 'react';
import uuid from 'uuid/v4';
import { getCompleteFormsError } from '../Resources';
import { Modal, Text, Form, WrappedMediatorFormProps } from 'components';
import { DealType, FormFieldsType } from 'types';
import transitionForgivenessWorkflowMutation from 'lsgql/mutations/TransitionForgivenessWorkflow';

interface ModalFormProps extends WrappedMediatorFormProps<DealType> {
  isOpen: boolean;
  handleModalToggle: (e?: React.SyntheticEvent) => void;
  noModal?: boolean;
}

const fields: FormFieldsType = {
  isApplicationError: {
    id: 'isApplicationError',
    propertyName: 'isApplicationError',
    label: 'Error on application',
  },
  isMissingDocs: {
    id: 'isMissingDocs',
    propertyName: 'isMissingDocs',
    label: 'Missing supporting documents',
  },
  isOther: {
    id: 'isOther',
    propertyName: 'isOther',
    label: 'Other',
  },
  comments: {
    id: 'comments',
    propertyName: 'comments',
    fieldName: 'Comments (required if "other" selected)',
  },
};

const TypeName = 'SbaPppForgivenessResubmitRequest';

const ForgivenessResubmissionFormFields = (props: ModalFormProps) => {
  const { data, noModal = false } = props;
  const { sbaPppForgivenessWorkflow } = data;

  if (!sbaPppForgivenessWorkflow) {
    return null;
  }

  const initializeResubmitRequest = () => {
    const initialSbaPppForgivenessResubmitRequest = {
      id: uuid(),
      sbaPppForgivenessWorkflowId: sbaPppForgivenessWorkflow.id,
      __typename: TypeName,
    };
    props.addEntity(
      ['sbaPppForgivenessWorkflow', 'sbapppforgivenessresubmitrequest'],
      initialSbaPppForgivenessResubmitRequest,
    );
    return initialSbaPppForgivenessResubmitRequest;
  };

  const {
    isLender,
    sbapppforgivenessresubmitrequest,
  } = sbaPppForgivenessWorkflow;

  const updatedProps = {
    ...props,
    data: sbapppforgivenessresubmitrequest ?? initializeResubmitRequest(),
  };

  const onChange = (
    value:
      | (string | null | undefined)
      | (boolean | null | undefined)
      | (Array<any> | null | undefined),
    fieldId: string,
  ) => {
    const path = [
      'sbaPppForgivenessWorkflow',
      'sbapppforgivenessresubmitrequest',
      fieldId,
    ];
    props.mutateProperty(value, path);
  };

  const disabledProps = {
    disabled: noModal,
    disabledReadable: noModal,
  };

  return (
    <div className="CheckboxForm">
      <Text
        value={
          isLender
            ? 'Please tell the borrower why the application is being returned.'
            : 'Please correct the following items before resubmitting your application.'
        }
      />
      {Form.FieldRenderer(
        Form.Checkbox,
        { ...fields.isApplicationError, ...disabledProps, onChange },
        updatedProps,
        TypeName,
        updatedProps.data.id,
      )}
      {Form.FieldRenderer(
        Form.Checkbox,
        { ...fields.isMissingDocs, ...disabledProps, onChange },
        updatedProps,
        TypeName,
        updatedProps.data.id,
      )}
      {Form.FieldRenderer(
        Form.Checkbox,
        { ...fields.isOther, ...disabledProps, onChange },
        updatedProps,
        TypeName,
        updatedProps.data.id,
      )}
      {Form.FieldRenderer(
        Form.TextArea,
        { ...fields.comments, ...disabledProps, onChange },
        updatedProps,
        TypeName,
        updatedProps.data.id,
      )}
    </div>
  );
};

const ForgivenessResubmissionForm = (props: ModalFormProps) => {
  const { data, handleModalToggle, isOpen, noModal = false } = props;
  const { sbaPppForgivenessWorkflow } = data;

  if (!sbaPppForgivenessWorkflow) {
    return null;
  }

  const handleSave = async () => {
    const saveResult = await props.onSave();

    if (saveResult.success) {
      const res = await transitionForgivenessWorkflowMutation({
        workflowId: sbaPppForgivenessWorkflow.id,
        state: 'CHANGES_REQUESTED',
        successToast: 'The borrower has been notified of your change request.',
        errorToast: getCompleteFormsError(
          sbaPppForgivenessWorkflow.isSFormEligible,
        ),
      });

      if (res.data?.transitionForgivenessWorkflow?.ok) {
        handleModalToggle();
      }
    }
  };

  return noModal ? (
    <ForgivenessResubmissionFormFields {...props} />
  ) : (
    <Modal
      className="steel"
      confirmButtonText="Send Change Request"
      header="Request Resubmission"
      isOpen={isOpen}
      onClose={handleModalToggle}
      onConfirm={handleSave}
      width="OneThird"
    >
      <ForgivenessResubmissionFormFields {...props} />
    </Modal>
  );
};

export default ForgivenessResubmissionForm;
