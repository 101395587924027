import * as React from 'react';
import moment from 'moment';
import { DisplayTable, Icon, FontAwesomeIconTypes, RouteTo } from 'components';
import { lsSort } from 'utils';
import { CalendarItemType, DealType, SortBy } from 'types';

const columns = [
  { name: 'date' },
  { name: 'tranche' },
  { name: 'type' },
  { name: 'name' },
  { name: 'amount' },
  { name: 'status' },
];

const crossDealColumns = [
  { name: 'date' },
  { name: 'deal' },
  { name: 'name' },
  { name: 'type' },
  { name: 'description' },
  { name: 'value' },
];

const multiTrancheColumns = [
  { name: 'date' },
  { name: 'tranche' },
  { name: 'type' },
  { name: 'description' },
  { name: 'value' },
];

type Props = {
  allDeals?: boolean;
  calendarItems: Array<CalendarItemType>;
  changeSortField: (arg0: SortBy) => void;
  deal: DealType;
  sortBy: SortBy;
};

type CalendarItemWithCategoryName = CalendarItemType & {
  categoryName: string;
};

const columnMap = {
  type: 'categoryName',
};

const blankSymbol = String.fromCharCode(8211); // n-dash

type TDLinkProps = {
  to?: string;
  children?: React.ReactNode;
  className?: string;
};
function TDLink(props: TDLinkProps) {
  return (
    <DisplayTable.TDRouteTo
      as={RouteTo}
      className={props.className}
      to={props.to}
    >
      {props.children}
    </DisplayTable.TDRouteTo>
  );
}

class CalendarEventViewTable extends React.Component<Props> {
  renderMultiTranche(sortedItems: Array<CalendarItemWithCategoryName>) {
    const { changeSortField, sortBy } = this.props;
    return (
      <DisplayTable
        className="calendarEventTable"
        columns={multiTrancheColumns}
        hoverable
        onChangeSortField={changeSortField}
        sortBy={sortBy}
      >
        {sortedItems.map(item => {
          const itemValue = '-'; // TODO: item.value ? item.value : '-';
          const TD = item.absoluteUrl ? TDLink : DisplayTable.TD;
          return (
            <DisplayTable.TR
              className="DisplayTable-TableRowCell__Clickable"
              key={item.id}
            >
              <TD to={item.absoluteUrl}>
                {moment.utc(item.date).format('YYYY/MM/DD')}
              </TD>
              <TD to={item.absoluteUrl}>
                {item.loanTranche ? item.loanTranche.name : '-'}
              </TD>
              <TD to={item.absoluteUrl}>{item.categoryName}</TD>
              <TD to={item.absoluteUrl}>
                {item.category?.slug === 'holiday'
                  ? item.name
                  : item.description}
              </TD>
              <TD to={item.absoluteUrl}>{itemValue}</TD>
            </DisplayTable.TR>
          );
        })}
      </DisplayTable>
    );
  }

  renderStatus(status: string) {
    const overdueIcon: FontAwesomeIconTypes = ['fas', 'exclamation-triangle'];

    if (status?.toUpperCase() == 'ACTIVE' || status == '') {
      return blankSymbol;
    } else if (status?.toUpperCase() == 'OVERDUE') {
      return (
        <>
          {'Overdue '}
          <Icon
            alt="overdueIcon"
            className="EventItem__OverdueIcon"
            icon={overdueIcon}
          />
        </>
      );
    } else {
      return status;
    }
  }

  render() {
    const {
      calendarItems = [],
      sortBy,
      changeSortField,
      allDeals,
      deal,
    } = this.props;

    // TODO: can make this sort process more performant

    const itemsWithCategoryName: Array<CalendarItemWithCategoryName> = (
      calendarItems || []
    ).map(item => ({
      ...item,
      categoryName:
        item.category && item.category.name ? item.category.name : '',
    }));
    const sortedItems =
      sortBy.reverse === 'none'
        ? itemsWithCategoryName
        : lsSort(itemsWithCategoryName, sortBy, columnMap);

    const multiTranche =
      deal && deal.loantrancheSet && deal.loantrancheSet.length > 1;

    if (multiTranche && !allDeals) return this.renderMultiTranche(sortedItems);
    return !allDeals ? (
      <DisplayTable
        className="calendarEventViewTable"
        columns={columns}
        hoverable
        onChangeSortField={changeSortField}
        sortBy={sortBy}
      >
        {sortedItems.map(item => {
          const itemValue = '-'; // TODO: item.value ? item.value : '-';
          const TD = item.absoluteUrl ? TDLink : DisplayTable.TD;
          return (
            <DisplayTable.TR
              className="DisplayTable-TableRowCell__Clickable"
              key={item.id}
            >
              <TD to={item.absoluteUrl}>
                {moment.utc(item.date).format('YYYY/MM/DD')}
              </TD>
              <TD to={item.absoluteUrl}>
                {item.loanTranche?.name ?? blankSymbol}
              </TD>
              <TD to={item.absoluteUrl}>{item.categoryName ?? blankSymbol}</TD>
              <TD to={item.absoluteUrl}>
                {item.category?.slug === 'holiday'
                  ? item.name
                  : item.description}
              </TD>
              <TD
                className="calendarEventViewTable__Value"
                to={item.absoluteUrl}
              >
                {itemValue ? itemValue : blankSymbol}
              </TD>
              <TD to={item.absoluteUrl}>{this.renderStatus(item.status)}</TD>
            </DisplayTable.TR>
          );
        })}
      </DisplayTable>
    ) : (
      <DisplayTable
        className="calendarEventViewTable"
        columns={crossDealColumns}
        hoverable
        onChangeSortField={changeSortField}
        sortBy={sortBy}
      >
        {sortedItems.map(item => {
          const itemValue = '-'; // TODO: item.value ? item.value : '-';
          const TD = item.absoluteUrl ? TDLink : DisplayTable.TD;
          return (
            <DisplayTable.TR
              className={
                item.absoluteUrl ? 'DisplayTable-TableRowCell__Clickable' : ''
              }
              key={item.id}
            >
              <TD to={item.absoluteUrl}>
                {moment.utc(item.date).format('YYYY/MM/DD')}
              </TD>
              <TD to={item.absoluteUrl}>{item.deal ? item.deal.name : '-'}</TD>
              <TD to={item.absoluteUrl}>
                {item.loanTranche ? item.loanTranche.name : '-'}
              </TD>
              <TD to={item.absoluteUrl}>{item.categoryName}</TD>
              <TD to={item.absoluteUrl}>
                {item.category?.slug === 'holiday'
                  ? item.name
                  : item.description}
              </TD>
              <TD
                className="calendarEventViewTable__Value"
                to={item.absoluteUrl}
              >
                {itemValue}
              </TD>
            </DisplayTable.TR>
          );
        })}
      </DisplayTable>
    );
  }
}

export default CalendarEventViewTable;
