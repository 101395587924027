import uuid from 'uuid/v4';
import {
  LoanTrancheOwnershipPortionMutationMethods,
  LoanTrancheInstitutionOwnershipPortionMutationMethods,
  DealMutationMethods,
  LoanTrancheMutationMethods,
} from '../../../graphql/mutations';
import { LenderRegistryFetchMethod } from '../../../graphql/queries';
import {
  LoanTrancheInstitutionOwnershipPortionEntityValidator,
  LoanTrancheOwnershipPortionEntityValidator,
} from '../../../graphql/validators';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import {
  DealType,
  LoanTrancheType,
  LenderRegistryFormType,
  LoanTrancheOwnershipPortionInputWithDisplayInfo,
  LenderRegistryFormTrancheType,
  LoanTrancheOwnershipPortionType,
  InstitutionOwnershipPortionTypeWithDisplay,
  LoanTrancheInstitutionOwnershipPortionType,
} from 'types';

const initialLenderRegistry: DealType = {
  id: uuid(),
  __typename: 'DealType',
  loantrancheSet: [],
};

function transformOwnershipPortionSet(
  loantrancheownershipportionSet:
    | LoanTrancheOwnershipPortionType[]
    | null
    | undefined,
): LoanTrancheOwnershipPortionInputWithDisplayInfo[] {
  if (!loantrancheownershipportionSet) {
    return [];
  }
  return loantrancheownershipportionSet.map<
    LoanTrancheOwnershipPortionInputWithDisplayInfo
  >((ownershipPortion: LoanTrancheOwnershipPortionType) => ({
    ...ownershipPortion,
    institutionId: ownershipPortion.institution
      ? ownershipPortion.institution.id
      : '',
    institutionName: ownershipPortion.institution
      ? ownershipPortion.institution.name || ''
      : '',
  }));
}

function transformInstitutionPortionSet(
  institutionPortionSet:
    | LoanTrancheInstitutionOwnershipPortionType[]
    | null
    | undefined,
): InstitutionOwnershipPortionTypeWithDisplay[] {
  if (!institutionPortionSet) {
    return [];
  }

  return institutionPortionSet.map<InstitutionOwnershipPortionTypeWithDisplay>(
    (institutionPortion: LoanTrancheInstitutionOwnershipPortionType) => ({
      ...institutionPortion,

      loantrancheownershipportionSet: transformOwnershipPortionSet(
        institutionPortion.loantrancheownershipportionSet as any,
      ),
      institutionId: institutionPortion.institution
        ? institutionPortion.institution.id
        : '',
      institutionName: institutionPortion.institution
        ? institutionPortion.institution.name || ''
        : '',
    }),
  );
}

function transformLoanTrancheSet(
  loantrancheSet: LoanTrancheType[] | null | undefined,
): LenderRegistryFormTrancheType[] {
  if (!loantrancheSet) {
    return [];
  }
  return loantrancheSet.map<LenderRegistryFormTrancheType>(
    // @ts-ignore
    loanTranche => {
      let transformed = {
        id: loanTranche.id,
        commitmentDrawType: loanTranche.commitmentDrawType || '',
        initialDrawAmount: loanTranche.initialDrawAmount || '',
        name: loanTranche.name || '',
        ownership: {
          ...loanTranche.ownership,
          id: uuid(),
          __typename: 'StubLoanTrancheOwnershipType',
        },
        loantrancheinstitutionownershipportionSet: transformInstitutionPortionSet(
          loanTranche.loantrancheinstitutionownershipportionSet,
        ),
        loantranchemultidrawdata: loanTranche.loantranchemultidrawdata,
        loantrancherevolverswinglinelocdata:
          loanTranche.loantrancherevolverswinglinelocdata,
        __typename: 'StubLoanTrancheType',
      };
      if (!transformed.loantrancherevolverswinglinelocdata) {
        transformed = {
          ...transformed,
          loantrancherevolverswinglinelocdata: {
            id: uuid(),
            __typename: 'LoanTrancheRevolverSwinglineLOCDataType',
          },
        };
      }

      if (!transformed.loantranchemultidrawdata) {
        transformed = {
          ...transformed,
          loantranchemultidrawdata: {
            id: uuid(),
            __typename: 'LoanTrancheMultiDrawDataType',
          },
        };
      }
      return transformed;
    },
  );
}

function convertQueryInput(input: LenderRegistryFormType): any {
  return {
    ...input,
    loantrancheSet: transformLoanTrancheSet(input.loantrancheSet),
    __typename: 'DealType',
  };
}

function scrubInstitutionName(e: any) {
  return { ...e, institutionName: undefined };
}

const lifeCycle = {
  mutations: {
    ...DealMutationMethods,
    ...LoanTrancheMutationMethods,
    ...LoanTrancheOwnershipPortionMutationMethods,
    ...LoanTrancheInstitutionOwnershipPortionMutationMethods,
  },
  validators: {
    LoanTrancheInstitutionOwnershipPortion: LoanTrancheInstitutionOwnershipPortionEntityValidator,
    LoanTrancheOwnershipPortion: LoanTrancheOwnershipPortionEntityValidator,
  },
  fetch: LenderRegistryFetchMethod,
  inputScrubbers: {
    LoanTrancheInstitutionOwnershipPortion: scrubInstitutionName,
    LoanTrancheOwnershipPortion: scrubInstitutionName,
  },
};

export default createForm<DealType, DealType>(
  Directory.LenderRegistryKey,
  initialLenderRegistry,
  lifeCycle,
  {
    enableAutoSave: false,
    enableAutoValidate: true,

    dataConverter: convertQueryInput,
  },
);
