import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { alerts } from '../../../resources';
import { DealJob } from '../../invitations/constants';
import CreateFolderModal from './CreateFolderModal';
import { FormMediator } from 'components';
import {
  FolderInput,
  FolderType,
  MyUserType,
  OrganizationDealRoleType,
} from 'types';
import {
  DispatchResponse,
  FolderFormQuery,
  MyUserQuery,
  standardQueryBuilder,
  DealOrganizationDealRolesQuery,
} from 'lsgql';

import { openFolder as lsOpenFolder } from 'lsredux/actions/dataroomPresentationalActions';
import { FolderQueryDefinition } from 'lsgql/queries/definitions';
import folderFormRedux from 'lsredux/reducer/forms/folder';
import { DispatchMethods } from 'lsredux/genericForms';

type OwnProps = {
  dealOrganizationDealRoles: Array<OrganizationDealRoleType>;
  folderId: string | null | undefined;
  isOpen: boolean;
  onClose: () => void;
  parentFolderId: string;
  user: MyUserType;
};

type AdditionalMethods = {
  onCancelEdit: () => void;
  setParentFolderId: (parentFolderId: string) => void;
  openFolder: (folderId: string) => void;
};

type FolderDispatchMethods = DispatchMethods<FolderInput> & AdditionalMethods;

function propsMapper(ownProps: OwnProps) {
  const { user, dealOrganizationDealRoles } = ownProps;

  const userInstitutionId = user && user.institution && user.institution.id;
  const userDealOrganizationDealRole =
    dealOrganizationDealRoles &&
    dealOrganizationDealRoles.find(
      e => e.institution && e.institution.id === userInstitutionId,
    );

  const isBorrower =
    userDealOrganizationDealRole &&
    userDealOrganizationDealRole.dealJob &&
    DealJob[userDealOrganizationDealRole.dealJob] === DealJob.BORROWER;

  return { isBorrower };
}

const mapDispatchToProps: (
  dispatch: any,
  ownProps: OwnProps,
) => FolderDispatchMethods = (dispatch: any, ownProps: OwnProps) => {
  const bound = folderFormRedux.actions.generateActions(dispatch);

  const additional: AdditionalMethods = {
    setParentFolderId(parentFolderId: string) {
      bound.mutateProperty(parentFolderId, 'parentId');
    },

    openFolder(folderId: string) {
      dispatch(lsOpenFolder(folderId));
    },

    onCancelEdit() {
      ownProps.onClose();
    },
  };

  // hijack handleResponse so we can inject the close modal method
  const overriddenHandleResponse = (
    response: DispatchResponse<FolderInput>,
  ) => {
    bound.handleResponse(response);
    if (response.entity) ownProps.onClose();
  };

  return { ...bound, handleResponse: overriddenHandleResponse, ...additional };
};

export default compose(
  MyUserQuery,
  /*
  These queries are a bit redundant, since we do already have all the data
  we need for editing, but using the queries fits nicely into the pre-existing
  generic form structure and they do only pull from apollo cache, so here we are
*/
  standardQueryBuilder(FolderQueryDefinition, {
    typename: 'ParentFolder',
    skip: ({ parentFolderId }: { parentFolderId: string | null | undefined }) =>
      !parentFolderId,
    variables: ({
      parentFolderId,
    }: {
      parentFolderId: string | null | undefined;
    }) => ({
      id: parentFolderId,
    }),
    results: (data: { folder: FolderType | null | undefined }) => {
      const { folder } = data;
      return { parentFolder: folder };
    },
  }),
  FolderFormQuery,
  DealOrganizationDealRolesQuery,
  withProps(propsMapper),
  connect(folderFormRedux.mapStateToProps, mapDispatchToProps),
  FormMediator({
    formId: 'CreateFolderModalForm',
    disableFrame: true,
    successAlert: alerts.saveFolderSuccess,
    baseObjectTypeName: 'Folder',
  }),
)(CreateFolderModal);
