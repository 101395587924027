/**
 * Remove standard properties that are not acceptable mutation input
 * `__typename`
 * `created`
 * `modified`
 * @param {*} e
 */
function inputScrubber<T>(e: T): T {
  return {
    ...e,
    __typename: undefined,
    created: undefined,
    modified: undefined,
    isDirty: undefined,
    isLeaf: undefined,
    type: undefined,
  };
}

export default inputScrubber;
