import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, FormFieldProps } from '@loanstreet-usa/design-system';

type Props = FormFieldProps & {
  maskedDisplay?: string; // ********
  onVisibilityToggle?: (visible: boolean) => void;
  formIsDirty: boolean;
};

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/** This is a display field for read-only protected values
 * where users can toggle whether or not the value should be
 * visible. Use cases include: SSN, bank account no., etc.
 */
const ProtectedDisplayField = (props: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  const { maskedDisplay = '********', onVisibilityToggle, ...rest } = props;
  const { saving } = rest;

  const { value, onChange } = rest;

  const previousSaveFlag = usePrevious<boolean>(saving);

  if (previousSaveFlag && !saving) {
    if (!props.formIsDirty && isVisible) {
      setIsVisible(false);
      if (onVisibilityToggle) {
        onVisibilityToggle(false);
      }
    }
  }

  const handleOnChange = (value: string, keyPath: any) => {
    if (value && value.includes('*')) {
      return;
    }
    onChange(value, keyPath);
  };

  const handleVisibilityToggle = () => {
    const nextIsVisible = !isVisible;
    setIsVisible(nextIsVisible);

    if (onVisibilityToggle) {
      onVisibilityToggle(nextIsVisible);
    }
  };

  const handleEditMode = (nextEditMode: boolean) => {
    if (nextEditMode) {
      // When entering an edit state, trigger 'Show'
      setIsVisible(true);
      if (onVisibilityToggle) {
        onVisibilityToggle(true);
      }
    }
  };

  const fieldValue = isVisible ? value : undefined;
  const InputComponent = isVisible ? Form.Numeric : Form.Input;

  return (
    <Form.Group>
      <InputComponent
        {...rest}
        maxLength={9}
        onBlur={() => handleEditMode(false)}
        onChange={handleOnChange}
        onFocus={() => handleEditMode(true)}
        placeholder={maskedDisplay}
        value={fieldValue}
      />
      <Button.Text
        label={isVisible ? 'Hide' : 'Show'}
        onClick={handleVisibilityToggle}
      />
    </Form.Group>
  );
};
export default ProtectedDisplayField;
