import React, { useState, useEffect } from 'react';
import { ListChildComponentProps } from 'react-window';
import { DealsTableColumnShape, FilterColumnProps, DealsProps } from '../types';
import {
  columnProps,
  forgivenessStatusFilterOptions,
  categoryFilterOptions,
} from '../constants';
import { DealFilters, DealRow, DealsFooter, DealsHeader } from '../partials';
import { DataTable, Tiler } from 'components';
import { lsSort, getDealCategory } from 'utils';

import './ForgivenessRequests.scss';

const initialFilterColumns: FilterColumnProps[] = [
  {
    id: 'category',
    placeholder: 'PPP Draw',
    options: categoryFilterOptions,
    value: [],
    idToLabel: (id: string) => getDealCategory(id),
  },
  {
    id: 'dealassignee.name',
    placeholder: 'Assignee',
    options: [],
    value: [],
  },
  {
    id: 'status',
    placeholder: 'Status',
    options: forgivenessStatusFilterOptions,
    value: [],
  },
];

const ForgivenessRequests = (props: DealsProps) => {
  const {
    deals = [],
    createDealFavorite,
    deleteDealFavorite,
    sortBy,
    changeSortField,
  } = props;

  const columns: DealsTableColumnShape[] = [
    { ...columnProps.isFavorite(createDealFavorite, deleteDealFavorite) },
    { ...columnProps.borrowerName },
    { ...columnProps.identity },
    { ...columnProps.category },
    { ...columnProps['dealassignee.name'] },
    { ...columnProps['sbaPppForgivenessWorkflow.modified'] },
    {
      ...columnProps[
        'sbaPppForgivenessWorkflow.sbapppforgivenesscalculation.forgivenessAmountCalculated'
      ],
    },
    {
      ...columnProps[
        'sbaPppForgivenessWorkflow.sbapppforgivenesscalculation.forgivenessPercentage'
      ],
    },
    { ...columnProps.view },
  ];

  const [filteredDeals, setFilteredDeals] = useState(deals);
  const [sortedDeals, setSortedDeals] = useState(deals);

  useEffect(() => {
    setSortedDeals(lsSort(filteredDeals, sortBy));
  }, [filteredDeals, sortBy]);

  if (!deals?.length) return null;

  const dateRangeProps = {
    placeholder: 'Date Range',
    propertyName: 'sbaPppForgivenessWorkflow.modified',
  };

  return (
    deals && (
      <div className="mainSection Deals-MainSection">
        <Tiler className="PppForgivenessRequests">
          <Tiler.Tile
            minWidth="OneThird"
            secondarySection={() => (
              <DealFilters
                dateRangeProps={dateRangeProps}
                deals={deals}
                initialColumns={initialFilterColumns}
                onChange={setFilteredDeals}
              />
            )}
            title="Loans"
          >
            <DataTable>
              <DealsHeader
                columns={columns}
                deals={deals}
                onChangeSortField={changeSortField}
                sortBy={sortBy}
              />
              {sortedDeals.length && (
                <DataTable.Body
                  hasDropdownCells
                  lazyRendering
                  maxHeight="100vh - 316px"
                  totalRows={sortedDeals.length}
                >
                  {(renderProps: ListChildComponentProps) => (
                    <DealRow
                      {...renderProps}
                      columns={columns}
                      deals={sortedDeals}
                    />
                  )}
                </DataTable.Body>
              )}
              <DealsFooter columns={columns} deals={sortedDeals} />
            </DataTable>
            <div className="totalLoans">
              {sortedDeals.length} {sortedDeals.length === 1 ? 'loan' : 'loans'}
            </div>
          </Tiler.Tile>
        </Tiler>
      </div>
    )
  );
};

export default ForgivenessRequests;
