import React, { useEffect } from 'react';
import noop from 'lodash/noop';
import cx from 'classnames';
import {
  Form,
  Modal,
  Header,
  Text,
  WrappedMediatorFormProps,
  TextLink,
} from 'components';
import { ID, DealType, FormFieldsType, DealClosingWorkflowType } from 'types';
import { countries } from 'resources';
import { parseDecimal } from 'utils';
import './BorrowerAdditionalInformation.scss';
import { ParseDecimalOptions } from 'utils/parseDecimal';

interface Props extends WrappedMediatorFormProps<DealType> {
  dealClosingWorkflow: DealClosingWorkflowType;
  isOpen: boolean;
  onClose: (e?: React.SyntheticEvent) => void;
  dealId: ID;
  deal?: DealType;
  canEdit: boolean;
  canEditState?: boolean;
  disableForm: (disable: boolean) => void;
  changeAdditionalInformation: (value: any, fieldId: string) => void;
  changeOwnershipInfo: (value: any, fieldId: string[]) => void;
  initializeBorrowerInfo: (dealId?: string) => void;
}

const smallWidth = '158px';
const largeWidth = '332px';

const fields: FormFieldsType = {
  naicsCode: {
    id: 'naicsCode',
    propertyName: 'naicsCode',
    fieldName: 'NAICS Code',
    width: smallWidth,
    required: true,
    helpText: 'Code needs to be 6 digits',
  },
  businessAge: {
    id: 'businessAge',
    propertyName: 'businessAge',
    fieldName: 'Business Age',
    typeName: 'AdditionalSbaPppBorrowerInformationBusinessAge',
    width: largeWidth,
    options: [
      { text: 'Startup, loan funds will open business', value: 'STARTUP' },
      { text: 'Unanswered', value: 'UNANSWERED' },
      { text: 'New business or 2 years or less', value: 'NEW_BUSINESS' },
      { text: 'Existing or more than 2 years old', value: 'EXISTING' },
      { text: 'Change of ownership', value: 'CHANGE_OF_OWNERSHIP' },
    ],
    placeholder: 'Please Select',
    required: true,
  },
  dateBusinessEstablished: {
    id: 'dateBusinessEstablished',
    propertyName: 'dateBusinessEstablished',
    fieldName: 'Date Business Established',
    width: smallWidth,
    picking: 'week',
    required: true,
  },
  franchiseNumber: {
    id: 'franchiseNumber',
    propertyName: 'franchiseNumber',
    fieldName: 'Franchise Number',
    width: smallWidth,
  },
  franchiseTradeName: {
    id: 'franchiseTradeName',
    propertyName: 'franchiseTradeName',
    fieldName: 'Franchise Trade Name',
    width: largeWidth,
    helpText:
      'If Franchise Number cannot be found, please enter Franchise Trade Name.',
  },
  businessType: {
    id: 'businessType',
    propertyName: 'businessType',
    fieldName: 'Is the borrower a: ',
    options: [
      { text: 'Person', value: 'PERSON' },
      { text: 'Sole Proprietorship', value: 'SOLE_PROPRIETORSHIP' },
      { text: 'Partnership', value: 'PARTNERSHIP' },
      { text: 'Corporation', value: 'CORPORATION' },
      { text: 'LLC', value: 'LLC' },
      { text: 'Joint Venture', value: 'JOINT_VENTURE' },
      { text: 'Professional Association', value: 'PROFESSIONAL_ASSOCIATION' },
      { text: 'Trust', value: 'TRUST' },
      { text: 'Cooperative', value: 'COOPERATIVE' },
      { text: 'LLP', value: 'LLP' },
      { text: 'S Corp', value: 'S_CORP' },
      { text: 'Non-Profit', value: 'NON_PROFIT' },
      { text: 'Tenant In Common', value: 'TENANT_IN_COMMON' },
      { text: 'ESOP', value: 'ESOP' },
      { text: 'Childcare Center', value: 'CHILDCARE_CENTER' },
      { text: 'ROBS', value: 'ROBS' },
      { text: 'Self-Employed', value: 'SELF_EMPLOYED' },
      { text: 'Independent Contractor', value: 'INDEPENDENT_CONTRACTOR' },
    ],
    width: smallWidth,
    required: true,
    placeholder: 'Please select',
  },
  citizenship: {
    id: 'citizenship',
    propertyName: 'citizenship',
    fieldName: 'Citizenship',
    options: [
      ...countries.map(({ name, alpha2 }) => {
        return {
          text: name,
          value: alpha2,
        };
      }),
      { text: 'Not Answered', value: 'UNANSWERED' },
    ],
    width: smallWidth,
    required: true,
  },
  hasPriorSbaLoan: {
    id: 'hasPriorSbaLoan',
    propertyName: 'hasPriorSbaLoan',
    label: 'Borrower has a prior SBA Loan',
  },
  jobsCreated: {
    id: 'jobsCreated',
    propertyName: 'jobsCreated',
    fieldName: 'Number of Jobs Created',
    width: smallWidth,
    required: true,
    allowLeadingZeroes: false,
    placeholder: '0',
    helpText: 'Projected jobs that will be created within two years',
  },
  jobsRetained: {
    id: 'jobsRetained',
    propertyName: 'jobsRetained',
    fieldName: 'Number of Jobs Retained',
    width: smallWidth,
    required: true,
    placeholder: '0',
    allowLeadingZeroes: false,
    helpText: 'Projected jobs that will be lost without this loan',
  },
  payrollAmount: {
    id: 'payrollAmount',
    propertyName: 'payrollAmount',
    width: smallWidth,
    required: true,
    placeholder: '$ 0',
  },
  mortgageInterestAmount: {
    id: 'mortgageInterestAmount',
    propertyName: 'mortgageInterestAmount',
    width: smallWidth,
    required: true,
    placeholder: '$ 0',
  },
  rentAmount: {
    id: 'rentAmount',
    propertyName: 'rentAmount',
    width: smallWidth,
    required: true,
    placeholder: '$ 0',
  },
  utilitiesAmount: {
    id: 'utilitiesAmount',
    propertyName: 'utilitiesAmount',
    width: smallWidth,
    required: true,
    placeholder: '$ 0',
  },
  refinanceEidlAmount: {
    id: 'refinanceEidlAmount',
    propertyName: 'refinanceEidlAmount',
    width: smallWidth,
    required: true,
    placeholder: '$ 0',
  },
};

const AdditionalSbaPppBorrowerInformation =
  'AdditionalSbaPppBorrowerInformation';

function BorrowerAdditionalInformationForm(props: Props) {
  const {
    data,
    isOpen,
    onClose,
    changeOwnershipInfo,
    initializeBorrowerInfo,
    dealId,
    canEditState,
  } = props;

  const dataBorrowerInfo = data?.additionalSbaPppBorrowerInformation;
  const dataOwners =
    data?.sbaPppLoanApplication?.sbapppapplicantownershipSet ?? [];

  useEffect(() => {
    if (!dataBorrowerInfo) initializeBorrowerInfo(dealId);
  }, [dataBorrowerInfo, initializeBorrowerInfo, dealId]);

  const zero = '0';
  const roundProps: ParseDecimalOptions = {
    roundMethod: 'roundDown',
    numDecimals: 2,
  };
  const totalAmount: number =
    parseDecimal(dataBorrowerInfo?.payrollAmount || zero, roundProps) +
    parseDecimal(dataBorrowerInfo?.mortgageInterestAmount || zero, roundProps) +
    parseDecimal(dataBorrowerInfo?.rentAmount || zero, roundProps) +
    parseDecimal(dataBorrowerInfo?.utilitiesAmount || zero, roundProps) +
    parseDecimal(dataBorrowerInfo?.refinanceEidlAmount || zero, roundProps);

  const loanAmount = parseDecimal(
    totalAmount -
      parseDecimal(
        data?.sbaPppLoanApplication?.sbappploanfinancials?.loanAmountDecimal ||
          zero,
        roundProps,
      ),
    roundProps,
  );

  async function handleSave() {
    const res = await props.onSave();
    if (res.success) {
      props.onClose();
    }
  }

  if (!dataBorrowerInfo) return null;
  return (
    <Modal.Compact
      cancelButtonText={'Cancel'}
      className="steel BorrowerAdditionalInformationModal"
      confirmButtonText={'Save'}
      denyConfirm={!canEditState}
      header="Additional Information"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSave}
      requireResponse
    >
      <Text>
        Please provide this additional information needed for the SBA to process
        your loan.
      </Text>
      <Form.Divider />
      <Header as="h5" className="caps">
        Business Information
      </Header>
      <Form.Divider className="transparent mediumPad" />
      <Form.Group className="AdditionalInfo__Lookup">
        {Form.FieldRenderer(
          Form.Numeric,
          {
            ...fields.naicsCode,
            onChange: props.changeAdditionalInformation,
          },
          { ...props, data: dataBorrowerInfo },
          AdditionalSbaPppBorrowerInformation,
          dataBorrowerInfo.id,
        )}
        <TextLink.External
          label="Lookup"
          target="newTab"
          to="https://www.naics.com/search"
        />
      </Form.Group>
      {Form.FieldRenderer(
        Form.Select,
        {
          ...fields.businessAge,
          onChange: props.changeAdditionalInformation,
        },
        { ...props, data: dataBorrowerInfo },
        AdditionalSbaPppBorrowerInformation,
        dataBorrowerInfo.id,
      )}
      {Form.FieldRenderer(
        Form.Calendar,
        {
          ...fields.dateBusinessEstablished,
          onChange: props.changeAdditionalInformation,
          picking: 'day',
        },
        { ...props, data: dataBorrowerInfo },
        AdditionalSbaPppBorrowerInformation,
        dataBorrowerInfo.id,
      )}
      <Form.Group className="AdditionalInfo__Lookup">
        {Form.FieldRenderer(
          Form.Input,
          {
            ...fields.franchiseNumber,
            onChange: props.changeAdditionalInformation,
          },
          { ...props, data: dataBorrowerInfo },
          AdditionalSbaPppBorrowerInformation,
          dataBorrowerInfo.id,
        )}
        <TextLink.External
          label="Lookup"
          target="newTab"
          to="https://www.sba.gov/sba-franchise-directory"
        />
      </Form.Group>
      <Text className="AdditionalInfo__Franchise" value="— OR —" />
      {Form.FieldRenderer(
        Form.Input,
        {
          ...fields.franchiseTradeName,
          onChange: props.changeAdditionalInformation,
        },
        { ...props, data: dataBorrowerInfo },
        AdditionalSbaPppBorrowerInformation,
        dataBorrowerInfo.id,
      )}
      <Form.Group>
        {Form.FieldRenderer(
          Form.Select,
          {
            ...fields.businessType,
            onChange: props.changeAdditionalInformation,
          },
          { ...props, data: dataBorrowerInfo },
          AdditionalSbaPppBorrowerInformation,
          dataBorrowerInfo.id,
        )}
        {dataBorrowerInfo?.businessType === 'PERSON' &&
          Form.FieldRenderer(
            Form.Select,
            {
              ...fields.citizenship,
              onChange: props.changeAdditionalInformation,
            },
            { ...props, data: dataBorrowerInfo },
            AdditionalSbaPppBorrowerInformation,
            dataBorrowerInfo.id,
          )}
      </Form.Group>
      {Form.FieldRenderer(
        Form.Checkbox,
        {
          ...fields.hasPriorSbaLoan,
          onChange: props.changeAdditionalInformation,
        },
        { ...props, data: dataBorrowerInfo },
        AdditionalSbaPppBorrowerInformation,
        dataBorrowerInfo.id,
      )}
      <Form.Divider />
      <Header as="h5" className="caps">
        Ownership Information
      </Header>
      <Form.Divider className="transparent mediumPad" />
      <Text className="ownership">
        For{' '}
        <strong>
          <u>each</u>
        </strong>{' '}
        owner <strong>in the order</strong> you entered on the Borrower
        Application, please provide:
      </Text>
      {dataOwners.map((owner, index) => {
        return (
          <Form.Group className="BusinessType" key={owner.id}>
            {Form.FieldRenderer(
              Form.Select,
              {
                ...fields.businessType,
                onChange: (value: any, fieldId: string) =>
                  changeOwnershipInfo(value, [index.toString(), fieldId]),
                fieldName: `Owner #${+index + 1} Type`,
              },
              { ...props, data: owner },
              'SbaPppOwnership',
              owner.id,
            )}
            {owner.businessType &&
              (owner.businessType === 'PERSON'
                ? Form.FieldRenderer(
                    Form.Select,
                    {
                      ...fields.citizenship,
                      onChange: (value: any, fieldId: string) =>
                        changeOwnershipInfo(value, [index.toString(), fieldId]),
                    },
                    { ...props, data: owner },
                    'SbaPppOwnership',
                    owner.id,
                  )
                : Form.FieldRenderer(
                    Form.Checkbox,
                    {
                      ...fields.hasPriorSbaLoan,
                      onChange: (value: any, fieldId: string) =>
                        changeOwnershipInfo(value, [index.toString(), fieldId]),
                    },
                    { ...props, data: owner },
                    'SbaPppOwnership',
                    owner.id,
                  ))}
          </Form.Group>
        );
      })}
      <Form.Divider />
      <Header as="h5" className="caps">
        Job Creation and Retention
      </Header>
      <Form.Divider className="transparent mediumPad" />
      {Form.FieldRenderer(
        Form.Numeric,
        {
          ...fields.jobsCreated,
          onChange: props.changeAdditionalInformation,
          value:
            props.data.additionalSbaPppBorrowerInformation.jobsCreated || '0',
        },
        { ...props, data: dataBorrowerInfo },
        AdditionalSbaPppBorrowerInformation,
        dataBorrowerInfo.id,
      )}
      {Form.FieldRenderer(
        Form.Numeric,
        {
          ...fields.jobsRetained,
          onChange: props.changeAdditionalInformation,
          value:
            props.data.additionalSbaPppBorrowerInformation.jobsRetained || '0',
        },
        { ...props, data: dataBorrowerInfo },
        AdditionalSbaPppBorrowerInformation,
        dataBorrowerInfo.id,
      )}
      <Form.Divider />
      <Header as="h5" className="caps">
        Use of Proceeds
      </Header>
      <div className="UseOfProceeds">
        Estimate the amounts from the loan total that will be used for each of
        the purposes you selected on your application:
      </div>
      <Form.Group className="AdditionalInfo__UseOfProceeds">
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.payrollAmount,
            onChange: props.changeAdditionalInformation,
          },
          { ...props, data: dataBorrowerInfo },
          AdditionalSbaPppBorrowerInformation,
          dataBorrowerInfo.id,
        )}
        <Form.Text
          className="required"
          value="Payroll (must be at least 60% of total)"
        />
      </Form.Group>
      <Form.Group className="AdditionalInfo__UseOfProceeds">
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.mortgageInterestAmount,
            onChange: props.changeAdditionalInformation,
          },
          { ...props, data: dataBorrowerInfo },
          AdditionalSbaPppBorrowerInformation,
          dataBorrowerInfo.id,
        )}
        <Form.Text value="Mortgage Interest" />
      </Form.Group>
      <Form.Group className="AdditionalInfo__UseOfProceeds">
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.rentAmount,
            onChange: props.changeAdditionalInformation,
          },
          { ...props, data: dataBorrowerInfo },
          AdditionalSbaPppBorrowerInformation,
          dataBorrowerInfo.id,
        )}
        <Form.Text value="Rent" />
      </Form.Group>
      <Form.Group className="AdditionalInfo__UseOfProceeds">
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.utilitiesAmount,
            onChange: props.changeAdditionalInformation,
          },
          { ...props, data: dataBorrowerInfo },
          AdditionalSbaPppBorrowerInformation,
          dataBorrowerInfo.id,
        )}
        <Form.Text value="Utilities" />
      </Form.Group>
      <Form.Group className="AdditionalInfo__UseOfProceeds">
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.refinanceEidlAmount,
            onChange: props.changeAdditionalInformation,
          },
          { ...props, data: dataBorrowerInfo },
          AdditionalSbaPppBorrowerInformation,
          dataBorrowerInfo.id,
        )}
        <Form.Text value="Refinance EIDL Loan" />
      </Form.Group>
      <Form.Group className="AdditionalInfo__UseOfProceeds">
        <Form.Money
          disabled
          disabledReadable
          id="totalAmount"
          onChange={noop}
          propertyName="totalAmount"
          value={totalAmount.toString()}
          width={smallWidth}
        />
        <Form.Text className="bold" value="Total" />
      </Form.Group>
      <Form.Group className="AdditionalInfo__UseOfProceeds">
        <Form.Money
          allowNegative
          className={cx({ negative: loanAmount < 0 })}
          disabled
          disabledReadable
          id="loanAmount"
          onChange={noop}
          propertyName="loanAmount"
          value={loanAmount.toString()}
          width={smallWidth}
        />
        <Form.Text value="Difference from Loan Amount (must equal 0)" />
      </Form.Group>
    </Modal.Compact>
  );
}

export default BorrowerAdditionalInformationForm;
