import { compose } from 'recompose';
import * as React from 'react';
import BorrowerPaymentView from './BorrowerPaymentView/BorrowerPaymentContainer';
import LenderPaymentView from './LenderPaymentView/LenderPaymentViewContainer';
import PaymentRecordSummaryView from './PaymentRecordSummaryView/PaymentRecordSummaryView';
import { withLoadingIndicator } from 'components';
import { DealPerspectivePermissions, MountGuard } from 'security';
import { PaymentRecordQuery, PaymentRecordAttachedDocumentsQuery } from 'lsgql';
import { RouteParams, withRouteParams } from 'routing';
import { PaymentRecordType, DocumentType } from 'types';
import './PaymentRecord.scss';

type Props = {
  dealId: string;
  documents: Array<DocumentType>;
  paymentRecord: PaymentRecordType;
};

class PaymentRecordFork extends React.Component<Props> {
  render() {
    const { dealId, paymentRecord, documents } = this.props;
    // SERVICING-TODO: approved is stubbed
    const approved = false;
    if (paymentRecord && approved) {
      return (
        <PaymentRecordSummaryView
          documents={documents}
          paymentRecord={paymentRecord}
        />
      );
    }

    return (
      <>
        <MountGuard
          permission={DealPerspectivePermissions.administer_payments_borrower}
        >
          <BorrowerPaymentView dealId={dealId} documents={documents} />
        </MountGuard>
        <MountGuard
          permission={DealPerspectivePermissions.administer_payments_lender}
        >
          <LenderPaymentView documents={documents} />;
        </MountGuard>
      </>
    );
  }
}

export default compose(
  withRouteParams([RouteParams.dealId, RouteParams.paymentRecordId]),
  PaymentRecordQuery,
  PaymentRecordAttachedDocumentsQuery,
  withLoadingIndicator('loading'),
)(PaymentRecordFork);
