import { compose } from 'recompose';
import { connect } from 'react-redux';
import ForgivenessCalculationForm from './ForgivenessCalculationForm';
import { SbaPppForgivenessWorkflowFormQuery } from 'lsgql';
import { withRouteParams, RouteParams } from 'routing';
import { withLoadingIndicator, FormMediator } from 'components';
import sbaPppForgivnessCalculationFormRedux from 'lsredux/reducer/forms/sbaPppForgivenessCalculation';

const container = compose(
  withRouteParams([RouteParams.dealId]),
  SbaPppForgivenessWorkflowFormQuery,
  withLoadingIndicator('loading'),
  connect(
    sbaPppForgivnessCalculationFormRedux.mapStateToProps,
    sbaPppForgivnessCalculationFormRedux.actions.generateActions,
  ),
  FormMediator({
    formId: 'sbaPppForgivenessCalculationForm',
    baseObjectTypeName: 'Deal',
    disableFrame: true,
    blurEventForm: true,
  }),
)(ForgivenessCalculationForm);

export default container;
