import React, { useEffect } from 'react';
import { Form, Header, WrappedMediatorFormProps, Tiler } from 'components';
import { FormFieldsType, ID } from 'types';
import uuid from 'uuid/v4';

import './RoundTwoLenderApplication.scss';

const SbaPppLoanApplication = 'SbaPppLoanApplication';

interface QuestionFields extends FormFieldsType {
  question: string;
}

const fields: FormFieldsType = {
  sbaFranchiseIdentifierCode: {
    id: 'sbaFranchiseIdentifierCode',
    propertyName: 'sbaFranchiseIdentifierCode',
    required: true,
    fieldName: 'SBA Franchise Identifier Code',
    width: '162px',
  },
  generalEligibilityQuestion1: {
    id: 'generalEligibilityQuestion1',
    propertyName: 'generalEligibilityQuestion1',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    required: true,
    question:
      'The Applicant has certified to the Lender that (1) it was in operation on February 15, 2020, has not permanently closed, and was either an eligible self-employed individual, independent contractor, or sole proprietorship with no employees or had employees for whom it paid salaries and payroll taxes or paid independent contractors, as reported on Form(s) 1099-MISC; (2) current economic uncertainty makes this loan request necessary to support the ongoing operations of the Applicant; (3) the funds will be used to retain workers and maintain payroll, or make payments for mortgage interest, rent, utilities, covered operations expenditures, covered property damage costs, covered supplier costs, and covered worker protection expenditures; and (4) the Applicant has not and will not receive another loan under the Paycheck Protection Program, section 7(a)(36) of the Small Business Act (15 U.S.C. 636(a)(36)) (this does not include Paycheck Protection Program second draw loans, section 7(a)(37) of the Small Business Act (15 U.S.C. 636(a)(37)).',
  },
  generalEligibilityQuestion2: {
    id: 'generalEligibilityQuestion2',
    propertyName: 'generalEligibilityQuestion2',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    required: true,
    question:
      'The Applicant has certified to the Lender that the Applicant, together with its affiliates (if applicable), (1) is an independent contractor, self-employed individual, or sole proprietor with no employees; (2) if not a housing cooperative, eligible 501(c)(6) organization, or eligible destination marketing organization, employs no more than the greater of 500 employees or, if applicable, meets the size standard in number of employees established by the SBA in 13 C.F.R. 121.201 for the Applicant’s industry; (3) if a housing cooperative, eligible 501(c)(6) organization, or eligible destination marketing organization, employs no more than 300 employees; (4) if NAICS 72, employs no more than 500 employees per physical location; (5) if a news organization that is majority owned or controlled by a NAICS code 511110 or 5151 business or a nonprofit public broadcasting entity with a trade or business under NAICS code 511110 or 5151, employs no more than 500 employees (or, if applicable, the size standard in number of employees established by SBA in 13 C.F.R. 121.201 for the Applicant’s industry) per location; or (6) is a small business under the applicable revenue-based size standard established by SBA in 13 C.F.R. 121.201 for the Applicant’s industry or under the SBA alternative size standard.',
  },
  generalEligibilityQuestion3: {
    id: 'generalEligibilityQuestion3',
    propertyName: 'generalEligibilityQuestion3',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    required: true,
    question:
      'The Applicant has certified to the Lender that (1) it has not been approved for a Shuttered Venue Operator (SVO) grant from SBA as of the date of the loan application, and acknowledged that if the Applicant is approved for an SVO grant before SBA issues a loan number for the loan, the Applicant is ineligible for the loan and acceptance of any loan proceeds will be considered an unauthorized use; (2) the President, the Vice President, the head of an Executive department, or a Member of Congress, or the spouse of such person as determined under applicable common law, does not directly or indirectly hold a controlling interest in the Applicant, with such terms having the meanings provided in Section 322 of the Economic Aid to Hard-Hit Small Businesses, Nonprofits, and Venues Act; and (3) the Applicant is not an issuer, the securities of which are listed on an exchange registered as a national securities exchange under section 6 of the Securities Exchange Act of 1934 (15 U.S.C. 78f).',
  },
  applicantCertificationOfEligibility: {
    id: 'applicantCertificationOfEligibility',
    propertyName: 'applicantCertificationOfEligibility',
    label: 'True',
    required: true,
    question:
      'The Applicant has certified to the Lender that the Applicant is eligible under the Paycheck Protection Program Rules.',
  },
  franchiseIsFranchise: {
    id: 'franchiseIsFranchise',
    propertyName: 'franchiseIsFranchise',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    required: true,
    question:
      'The Applicant has represented to the Lender that it is a franchise.',
  },
  franchiseListedInSbaDirectory: {
    id: 'franchiseListedInSbaDirectory',
    propertyName: 'franchiseListedInSbaDirectory',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    required: true,
    question:
      'The Applicant has represented that the franchise is listed in SBA’s Franchise Directory.',
  },
  characterQuestion1: {
    id: 'characterQuestion1',
    propertyName: 'characterQuestion1',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    required: true,
    question:
      'The Applicant has represented to the Lender that neither the Applicant (if an individual) nor any individual owning 20% or more of the equity of the Applicant is presently incarcerated or, for any felony, presently subject to an indictment, criminal information, arraignment, or other means by which formal criminal charges are brought in any jurisdiction.',
  },
  characterQuestion2: {
    id: 'characterQuestion2',
    propertyName: 'characterQuestion2',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    required: true,
    question:
      'The Applicant has represented to the Lender that neither the Applicant (if an individual) nor any individual owning 20% or more of the equity of the Applicant has within the last 5 years, for any felony involving fraud, bribery, embezzlement, or a false statement in a loan application or an application for federal financial assistance, or within the last year for any other felony: 1) been convicted; 2) pleaded guilty; 3) pleaded nolo contendere; or 4) commenced any form of parole or probation (including probation before judgment).',
  },
  govtLossQuestion1: {
    id: 'govtLossQuestion1',
    propertyName: 'govtLossQuestion1',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    required: true,
    question:
      'The Applicant has certified to the Lender that neither the Applicant nor any owner is presently suspended, debarred, proposed for debarment, declared ineligible, voluntarily excluded from participation in this transaction by any Federal department or agency, or presently involved in any bankruptcy.',
  },
  govtLossQuestion2: {
    id: 'govtLossQuestion2',
    propertyName: 'govtLossQuestion2',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    required: true,
    question:
      'The Applicant has certified to the Lender that neither the Applicant nor any of its owners, nor any business owned or controlled by any of them, ever obtained a direct or guaranteed loan from SBA or any other Federal agency that is (a) currently delinquent, or (b) has defaulted in the last 7 years and caused a loss to the government.',
  },
  employeesUsResidents: {
    id: 'employeesUsResidents',
    propertyName: 'employeesUsResidents',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    required: true,
    question:
      'The Applicant has certified that the principal place of residence for all employees included in the Applicant’s payroll calculation is the United States.',
  },
  feesUsingThirdParty: {
    id: 'feesUsingThirdParty',
    propertyName: 'feesUsingThirdParty',
    options: [
      { label: 'Yes', id: true },
      { label: 'No', id: false },
    ],
    required: true,
    question:
      'Is the Lender using a third party to assist in the preparation of the loan application or application materials, or to perform other services in connection with this loan?',
  },
};

const SbaPppRoundTwoLenderApplication = 'SbaPppRoundTwoLenderApplication';

interface QuestionProps extends WrappedMediatorFormProps<any> {
  field: QuestionFields;

  onChangeLenderApplication: (
    value: string | null | undefined,
    keyPath: string,
  ) => void;
}

function Question(props: QuestionProps) {
  const { field, onChangeLenderApplication, data } = props;

  return (
    <>
      <Form.Text className="required" value={field.question} />
      {Form.FieldRenderer(
        Form.Radio,
        { ...field, onChange: onChangeLenderApplication },
        props,
        SbaPppRoundTwoLenderApplication,
        data.id,
      )}
    </>
  );
}

function Section(props: {
  className?: string;
  title: string;
  subTitle?: string;
  children: React.ReactNode;
}) {
  const { children, title, className, subTitle } = props;

  return (
    <>
      <div className={`section ${className}`}>
        <Form.Divider />
        <Header as="h5" className="caps">
          {title}
        </Header>
        {subTitle && (
          <Form.Text className="steel section__help-text" value={subTitle} />
        )}
        {children}
      </div>
    </>
  );
}

interface Props extends WrappedMediatorFormProps<any> {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onClose: () => void;
  dealId: ID;

  onChangeBorrowerApplication: (
    value: string | null | undefined,
    keyPath: string,
  ) => void;
  onChangeLenderApplication: (
    value: string | null | undefined,
    keyPath: string,
  ) => void;
}
function RoundTwoLenderApplication(props: Props) {
  const { onChangeLenderApplication, onChangeBorrowerApplication } = props;

  const lenderProps = {
    ...props,
    data: (props.data?.sbaPppRoundTwoLenderApplication as any) || {},
  };
  const borrowerProps = { ...props, data: props.data?.sbaPppLoanApplication };

  const requireFranchiseCode = Boolean(
    lenderProps.data?.franchiseListedInSbaDirectory,
  );

  useEffect(() => {
    if (!lenderProps.data) {
      props.addEntity('sbaPppRoundTwoLenderApplication', {
        // eslint-disable-next-line
        id: uuid(),
        __typename: 'SbaPppRoundTwoLenderApplicationType',
      });
    }
    // eslint-disable-next-line
  }, [props.data.id]);

  if (!lenderProps.data || !borrowerProps.data) {
    return null;
  }

  return (
    <Tiler>
      <Tiler.Column>
        <Tiler.Tile
          className="steel"
          minWidth="OneThird"
          title="Paycheck Protection Program Lender’s Application"
          width="Half"
        >
          <div className="RoundTwoLenderApplication">
            <Section
              subTitle={
                'If the answer is no to any, the loan cannot be approved.'
              }
              title={'General Eligibility'}
            >
              <Question
                {...lenderProps}
                field={fields.generalEligibilityQuestion1}
              />
              <Question
                {...lenderProps}
                field={fields.generalEligibilityQuestion2}
              />
              <Question
                {...lenderProps}
                field={fields.generalEligibilityQuestion3}
              />
            </Section>

            <Section
              subTitle={'If not true, the loan cannot be approved.'}
              title={'Applicant Certification of Eligibility'}
            >
              <Form.Text
                className="required"
                value="The Applicant has certified to the Lender that the Applicant is eligible under the Paycheck Protection Program Rule."
              />
              {Form.FieldRenderer(
                Form.Checkbox,
                {
                  ...fields.applicantCertificationOfEligibility,
                  onChange: onChangeLenderApplication,
                },
                lenderProps,
                SbaPppRoundTwoLenderApplication,
                lenderProps.data.id,
              )}
            </Section>

            <Section
              subTitle={
                'If a franchise and not listed in the SBA Franchise Directory, the loan cannot be approved.'
              }
              title={
                'Franchise / License / Jobber / Membership or Similar Agreement'
              }
            >
              <Question {...lenderProps} field={fields.franchiseIsFranchise} />
              <Question
                {...lenderProps}
                field={fields.franchiseListedInSbaDirectory}
              />
              {requireFranchiseCode &&
                Form.FieldRenderer(
                  Form.Input,
                  {
                    ...fields.sbaFranchiseIdentifierCode,
                    onChange: onChangeBorrowerApplication,
                  },
                  borrowerProps,
                  SbaPppLoanApplication,
                  borrowerProps.data.id,
                )}
            </Section>
            <Section
              subTitle={'If no, the loan cannot be approved.'}
              title={'Character Determination'}
            >
              <Question {...lenderProps} field={fields.characterQuestion1} />
              <Question {...lenderProps} field={fields.characterQuestion2} />
            </Section>

            <Section
              subTitle={'If no, the loan cannot be approved.'}
              title={'Prior Loss to Government / Delinquent Federal Debt'}
            >
              <Question {...lenderProps} field={fields.govtLossQuestion1} />

              <Question {...lenderProps} field={fields.govtLossQuestion2} />
            </Section>
            <Section
              subTitle={'If no, the loan cannot be approved.'}
              title={'U.S. Employees'}
            >
              <Question {...lenderProps} field={fields.employeesUsResidents} />
            </Section>
            <Section
              className={'fees'}
              subTitle={
                'If yes, Lender may not pass any agent fee through to the Applicant or offset or pay the fee with the proceeds of this loan.'
              }
              title={'Fees'}
            >
              <Question {...lenderProps} field={fields.feesUsingThirdParty} />
            </Section>
          </div>
        </Tiler.Tile>
      </Tiler.Column>
    </Tiler>
  );
}

export default RoundTwoLenderApplication;
