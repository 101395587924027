import { MutationMethod } from '../types';
import { mutationBuilder } from './builders';
import {
  SetBenchmarkOptionMutationDefinition,
  CreateBenchmarkOptionMutationDefinition,
  DeleteBenchmarkOptionMutationDefinition,
} from './definitions';
import { BenchmarkOptionInput, BenchmarkOptionType, ID } from 'types';

const createBenchmarkOption: MutationMethod<
  BenchmarkOptionInput,
  BenchmarkOptionType
> = mutationBuilder<BenchmarkOptionInput, BenchmarkOptionType>(
  CreateBenchmarkOptionMutationDefinition,
  {
    build: mutate => benchmarkOptionData =>
      mutate({
        variables: {
          benchmarkOptionData,
        },
      }),
  },
);

const setBenchmarkOption: MutationMethod<
  BenchmarkOptionInput,
  BenchmarkOptionType
> = mutationBuilder<BenchmarkOptionInput, BenchmarkOptionType>(
  SetBenchmarkOptionMutationDefinition,
  {
    build: mutate => benchmarkOptionData =>
      mutate({
        variables: {
          benchmarkOptionData,
        },
      }),
  },
);

const deleteBenchmarkOption: MutationMethod<ID, void> = mutationBuilder<
  ID,
  void
>(DeleteBenchmarkOptionMutationDefinition, {
  build: mutate => (id: ID) =>
    mutate({
      variables: {
        id,
      },
    }),
});

const BenchmarkOptionMutationMethods = {
  createBenchmarkOption,
  setBenchmarkOption,
  deleteBenchmarkOption,
};

export default BenchmarkOptionMutationMethods;
