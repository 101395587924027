import { compose } from 'recompose';
import { connect } from 'react-redux';
import FileRowRenameInput from './FileRowRenameInput';
import { FormMediator } from 'components';
import { DocumentInput } from 'types';
import { RouteParams, withRouteParams } from 'routing';
import { DocumentMutations, DocumentFormQuery, DispatchResponse } from 'lsgql';

import { FolderPerspectivePermissions } from 'security';
import documentFormRedux from 'lsredux/reducer/forms/document';
import { setAlert } from 'lsredux/actions/alerts';
import { DispatchMethods } from 'lsredux/genericForms';

import './FileRowRenameInput.scss';

type OwnProps = {
  isOpen: boolean;
  onClose: () => void;
  parentFolderId: string;
  preExistingEntity: any;
  setDocument: (arg0: DocumentInput) => void;
};

const successAlert = {
  message: 'File Saved',
};

type AdditionalMethods = {
  handleResponse: (response: any) => void;
  onCancel: () => void;
  setAlert: (arg0: string, arg1: 'success' | 'error') => void;
  setParentFolderId: (parentFolderId: string) => void;
  // setDocument: (document: DocumentType) => void,
};

type RenameDispatchMethods = DispatchMethods<DocumentInput> & AdditionalMethods;

const mapDispatchToProps: (
  dispatch: any,
  ownProps: OwnProps,
) => RenameDispatchMethods = (dispatch: any, ownProps: OwnProps) => {
  const bound = documentFormRedux.actions.generateActions(dispatch);
  const additional: AdditionalMethods = {
    setParentFolderId(parentFolderId: string) {
      bound.mutateProperty(parentFolderId, 'parentId');
    },
    onCancel() {
      ownProps.onClose();
    },
    setAlert: (error, type) => {
      dispatch(setAlert(error, type));
    },
    handleResponse: (response: DispatchResponse<DocumentInput>) => {
      bound.handleResponse(response);
      ownProps.onClose();
    },
  };

  const overriddenSave = async function save(formQueryRefetch: any) {
    const res = await bound.save(formQueryRefetch);
    ownProps.onClose();
    return res;
  };

  return { ...bound, save: overriddenSave, ...additional };
};

export default compose(
  withRouteParams([RouteParams.dealId]),
  DocumentMutations,
  DocumentFormQuery,
  connect(documentFormRedux.mapStateToProps, mapDispatchToProps),
  FormMediator({
    editSecurity: FolderPerspectivePermissions.change_folder,
    formId: 'FileRowRenameInput',
    successAlert,
    baseObjectTypeName: 'Document',
  }),
)(FileRowRenameInput);
