import { compose } from 'recompose';
import { connect } from 'react-redux';
import InstitutionForm from './InstitutionProfileForm';
import { withLoadingIndicator, FormMediator } from 'components';

import { RouteParams, withRouteParams } from 'routing';
import { InstitutionPermissionQuery, InstitutionFormQuery } from 'lsgql';
import institutionFormRedux from 'lsredux/reducer/forms/institution';
import { InstitutionPerspectivePermissions } from 'security';

const InstitutionFormContainer = compose(
  withRouteParams([RouteParams.institutionId, RouteParams.institutionSection]),
  InstitutionPermissionQuery,
  InstitutionFormQuery,
  withLoadingIndicator('loading'),
  connect(
    institutionFormRedux.mapStateToProps,
    institutionFormRedux.actions.generateActions,
  ),
  FormMediator({
    formId: 'institutionForm',
    baseObjectTypeName: 'Institution',
    editSecurity: InstitutionPerspectivePermissions.administer_institution,
    simpleFooter: true,
  }),
)(InstitutionForm);

export default InstitutionFormContainer;
