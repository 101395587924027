import * as React from 'react';
import { noop } from 'lodash';
import AssociatedFiles from '../../../associatedFiles/AssociatedFiles';
import { Form } from 'components';
import { PaymentRecordType, DocumentType } from 'types';
import { invariant } from 'utils';

type Props = {
  /* eslint-disable react/no-unused-prop-types */
  documents: Array<DocumentType>;

  /* eslint-enable */
  paymentRecord: PaymentRecordType;
};

const width = 'eight';
const textAreaWidth = 'sixteen';

class PaymentRecordSummaryView extends React.Component<Props> {
  render() {
    const { paymentRecord } = this.props;
    const { paymentDue } = paymentRecord;
    invariant(paymentRecord, 'paymentRecord was not found!');
    invariant(paymentDue, 'paymentDue was not found!');

    return (
      <Form
        className="paymentRecord__Form formFrame"
        id="paymentRecordSummaryView"
        onSubmit={noop}
      >
        <div className="formContent">
          <Form.Header as="h1" header="Payment" />
          <Form.Group>
            <Form.MoneyReadOnly
              fieldName="Scheduled Amount"
              id="scheduledAmount"
              onChange={noop}
              propertyName="scheduledAmount"
              value={paymentDue.totalAmount}
              width={width}
            />
            <Form.ReadOnly
              fieldName="Scheduled Date"
              id="scheduledDate"
              onChange={noop}
              propertyName="scheduledDate"
              value={paymentDue.date}
              width={width}
            />
          </Form.Group>
          <Form.Group>
            <Form.MoneyReadOnly
              fieldName="Lender: Amount"
              id="lenderAmount"
              onChange={noop}
              propertyName="scheduledAmount"
              value={paymentRecord.lenderAmount}
              width={width}
            />
            <Form.ReadOnly
              fieldName="Lender: Date"
              id="lenderDate"
              onChange={noop}
              propertyName="lenderDate"
              value={paymentRecord.lenderDate}
              width={width}
            />
          </Form.Group>
          <Form.Group>
            <Form.MoneyReadOnly
              fieldName="Borrower: Amount"
              id="borrowerAmount"
              onChange={noop}
              propertyName="scheduledAmount"
              value={paymentRecord.borrowerAmount}
              width={width}
            />
            <Form.ReadOnly
              fieldName="Borrower: Date"
              id="borrowerDate"
              onChange={noop}
              propertyName="borrowerDate"
              value={paymentRecord.borrowerDate}
              width={width}
            />
          </Form.Group>
          <Form.Group>
            <Form.ReadOnly
              fieldName="Lender: Reference Number"
              id="lenderReference"
              onChange={noop}
              propertyName="lenderReference"
              value={paymentRecord.lenderReference}
              width={width}
            />
            {paymentRecord.borrowerReference && (
              <Form.ReadOnly
                fieldName="Borrower: Reference Number"
                id="borrowerReference"
                onChange={noop}
                propertyName="borrowerReference"
                value={paymentRecord.borrowerReference}
                width={width}
              />
            )}
          </Form.Group>
          <AssociatedFiles data={this.props} viewOnly />
          {paymentRecord.borrowerNotes && (
            <Form.ReadOnly
              fieldName="Borrower: Notes"
              id="borrowerNotes"
              onChange={noop}
              propertyName="borrowerNotes"
              value={paymentRecord.borrowerNotes}
              width={textAreaWidth}
            />
          )}
          {paymentRecord.lenderNotes && (
            <Form.ReadOnly
              fieldName="Lender: Notes"
              id="lenderNotes"
              onChange={noop}
              propertyName="lenderNotes"
              value={paymentRecord.lenderNotes}
              width={textAreaWidth}
            />
          )}
        </div>
      </Form>
    );
  }
}

export default PaymentRecordSummaryView;
