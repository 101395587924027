import React from 'react';
import uuid from 'uuid/v4';
import {
  Modal,
  Text,
  Form,
  WrappedMediatorFormProps,
  Button,
} from 'components';
import { DealType, FormFieldsType } from 'types';
import './EzFormQualificationsModal.scss';

const TypeName = 'SbaPppForgivenessEzFormQualifications';
const KeyPath = [
  'sbaPppForgivenessWorkflow',
  'sbapppforgivenessezformqualifications',
];

const fields: FormFieldsType = {
  hasNoEmployees: {
    id: 'hasNoEmployees',
    propertyName: 'hasNoEmployees',
    control: function hasNoEmployeesLabel() {
      return (
        <div className="QualificationsCheckboxText">
          The Borrower is a self-employed individual, independent contractor, or
          sole proprietor who had no employees at the time of the PPP loan
          application and did not include any employee salaries in the
          computation of average monthly payroll in the Borrower Application
          Form (SBA Form 2483).
        </div>
      );
    },
  },
  noReductionSalaryGt25OrHours: {
    id: 'noReductionSalaryGt25OrHours',
    propertyName: 'noReductionSalaryGt25OrHours',
    control: function noReductionSalaryGt25OrHoursLabel() {
      return (
        <div className="QualificationsCheckboxText">
          The Borrower did not reduce annual salary or hourly wages of any
          employee by more than 25 percent during the Covered Period or the
          Alternative Payroll Covered Period (as defined below) compared to the
          period between January 1, 2020 and March 31, 2020 (for purposes of
          this statement, “employees” means only those employees that did not
          receive, during any single period during 2019, wages or salary at an
          annualized rate of pay in an amount more than $100,000);
          <div className="And">AND</div>
          The Borrower did not reduce the number of employees or the average
          paid hours of employees between January 1, 2020 and the end of the
          Covered Period. (Ignore reductions that arose from an inability to
          rehire individuals who were employees on February 15, 2020 if the
          Borrower was unable to hire similarly qualified employees for unfilled
          positions on or before December 31, 2020. Also ignore reductions in an
          employee’s hours that the Borrower offered to restore and the employee
          refused. See 85 FR 33004, 33007 (June 1, 2020) for more details.
        </div>
      );
    },
  },
  noReductionSalaryGt25AndNotOperating: {
    id: 'noReductionSalaryGt25AndNotOperating',
    propertyName: 'noReductionSalaryGt25AndNotOperating',
    control: function noReductionSalaryGt25AndNotOperatingLabel() {
      return (
        <div className="QualificationsCheckboxText">
          The Borrower did not reduce annual salary or hourly wages of any
          employee by more than 25 percent during the Covered Period or the
          Alternative Payroll Covered Period (as defined below) compared to the
          period between January 1, 2020 and March 31, 2020 (for purposes of
          this statement, “employees” means only those employees that did not
          receive, during any single period during 2019, wages or salary at an
          annualized rate of pay in an amount more than $100,000);
          <div className="And">AND</div>
          The Borrower was unable to operate during the Covered Period at the
          same level of business activity as before February 15, 2020, due to
          compliance with requirements established or guidance issued between
          March 1, 2020 and December 31, 2020 by the Secretary of Health and
          Human Services, the Director of the Centers for Disease Control and
          Prevention, or the Occupational Safety and Health Administration,
          related to the maintenance of standards of sanitation, social
          distancing, or any other work or customer safety requirement related
          to COVID-19.
        </div>
      );
    },
  },
};

interface Props extends WrappedMediatorFormProps<DealType> {
  isOpen: boolean;
  onClose: () => void;
}
const EzFormQualificationsModal = (props: Props) => {
  const { isOpen, onClose, onSave } = props;
  const sbaPppForgivenessWorkflow = props?.data?.sbaPppForgivenessWorkflow;
  if (!sbaPppForgivenessWorkflow) {
    return null;
  }
  const initializeData = () => {
    if (
      sbaPppForgivenessWorkflow &&
      !sbaPppForgivenessWorkflow.sbapppforgivenessezformqualifications
    ) {
      const initialData = {
        id: uuid(),
        sbaPppForgivenessWorkflowId: sbaPppForgivenessWorkflow.id,
        __typename: TypeName,
      };
      props.addEntity(KeyPath, initialData);
      return initialData;
    }
  };

  const handleChange = (
    value:
      | (string | null | undefined)
      | (boolean | null | undefined)
      | (Array<any> | null | undefined),
    fieldId: string,
  ) => {
    const path = [...KeyPath, fieldId];
    props.mutateProperty(value, path);
  };

  const handleSave = () => {
    onSave();
    onClose();
  };

  const updatedProps = {
    ...props,
    data:
      sbaPppForgivenessWorkflow.sbapppforgivenessezformqualifications ??
      initializeData(),
  };

  return (
    <Modal
      className="steel EzFormQualificationsModal"
      footer={<Button label="Close" onClick={handleSave} />}
      header="Checklist for Using SBA Form 3508EZ"
      isOpen={isOpen}
      onClose={handleSave}
      requireResponse
      width="TwoThirds"
    >
      <Text>
        If you (the Borrower) can <strong>check at least one</strong> of the
        three boxes below, you can apply for forgiveness of your PPP loan using
        the SBA Form 3508EZ. This checklist is for your information only and
        won’t be submitted with your SBA Form 3508EZ.
      </Text>
      {Form.FieldRenderer(
        Form.Checkbox,
        { ...fields.hasNoEmployees, onChange: handleChange },
        updatedProps,
        TypeName,
        updatedProps.data.id,
      )}
      <Form.Divider thin />
      {Form.FieldRenderer(
        Form.Checkbox,
        { ...fields.noReductionSalaryGt25OrHours, onChange: handleChange },
        updatedProps,
        TypeName,
        updatedProps.data.id,
      )}
      <Form.Divider thin />
      {Form.FieldRenderer(
        Form.Checkbox,
        {
          ...fields.noReductionSalaryGt25AndNotOperating,
          onChange: handleChange,
        },
        updatedProps,
        TypeName,
        updatedProps.data.id,
      )}
    </Modal>
  );
};

export default EzFormQualificationsModal;
