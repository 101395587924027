import { PayloadAction } from '../../types';
import { autoSave } from '../methods';

/* eslint-disable import/prefer-default-export */

/**
 * Examine a GraphQL Mutation response object and populate state appropriately
 * If the reponse contained errors, these will populate into the 'errors' property,
 * otherwise 'data' will be refreshed.
 * @param {*} state
 * @param {*} action
 */
export function handleToggleAutoSave(
  state: any, // This is an Immutable version of FormReduxState<T>
  action: PayloadAction<boolean>,
): Record<string, any> {
  const { payload } = action;
  if (state.get(autoSave) === payload) return state;

  return state.set(autoSave, payload);
}
