import * as React from 'react';

type Props = {};

class UsedInvitation extends React.Component<Props> {
  render() {
    return (
      <div>
        This invitation has expired. Please contact the person who invited you
        if you believe this is an error.
      </div>
    );
  }
}

export default UsedInvitation;
