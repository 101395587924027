import { mutationBuilder } from './builders';
import {
  CreateSbaPppLenderClosingWorkflowQuestionsMutationDefinition as CreateMutationDefinition,
  SetSbaPppLenderClosingWorkflowQuestionsMutationDefinition as SetMutationDefinition,
} from './definitions';
import {
  SbaPppLenderClosingWorkflowQuestionsType,
  SbaPppLenderClosingWorkflowQuestionsInput,
} from 'types';

const SbaPppLenderClosingWorkflowQuestionsMutations = {
  createSbaPppLenderClosingWorkflowQuestions: mutationBuilder<
    SbaPppLenderClosingWorkflowQuestionsInput,
    SbaPppLenderClosingWorkflowQuestionsType
  >(CreateMutationDefinition, {
    build: mutate => mutationData =>
      mutate({
        variables: { sbaPppLenderClosingWorkflowQuestionsData: mutationData },
      }),
  }),
  setSbaPppLenderClosingWorkflowQuestions: mutationBuilder<
    SbaPppLenderClosingWorkflowQuestionsInput,
    SbaPppLenderClosingWorkflowQuestionsType
  >(SetMutationDefinition, {
    build: mutate => mutationData =>
      mutate({
        variables: { sbaPppLenderClosingWorkflowQuestionsData: mutationData },
      }),
  }),
};

export default SbaPppLenderClosingWorkflowQuestionsMutations;
