/** @module MountGuard * */

import * as React from 'react';
import Secured from './Secured';

import { PermissionDefinition } from './definitions/types';

/* eslint-disable import/prefer-default-export */

interface MountGuardProps {
  permission: PermissionDefinition | Array<PermissionDefinition>;
  children: React.ReactNode;
}

/**
 * Check that the user has the required permissions.  If the user
 * lacks permissions, children of this component will never mount,
 * otherwise children are mounted and rendered as normal
 */
export class MountGuard extends React.Component<MountGuardProps> {
  renderChildren = () => this.props.children;

  render() {
    return (
      <Secured denyAction="no_mount" permission={this.props.permission}>
        {this.renderChildren}
      </Secured>
    );
  }
}
