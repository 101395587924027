import { mutationBuilder } from './builders';
import {
  CreateLoanTrancheAdjustableRatePeriodMutationDefinition,
  SetLoanTrancheAdjustableRatePeriodMutationDefinition,
  DeleteLoanTrancheAdjustableRatePeriodMutationDefinition,
} from './definitions';
import {
  LoanTrancheAdjustableRatePeriodType,
  LoanTrancheAdjustableRatePeriodInput,
  ID,
} from 'types';

const LoanTrancheAdjustableRatePeriodMutationMethods = {
  createLoanTrancheAdjustableRatePeriod: mutationBuilder<
    LoanTrancheAdjustableRatePeriodType,
    LoanTrancheAdjustableRatePeriodInput
  >(CreateLoanTrancheAdjustableRatePeriodMutationDefinition, {
    build: mutate => loanTrancheAdjustableRatePeriod =>
      mutate({
        variables: {
          loanTrancheAdjustableRatePeriodData: loanTrancheAdjustableRatePeriod,
        },
      }),
  }),
  setLoanTrancheAdjustableRatePeriod: mutationBuilder<
    LoanTrancheAdjustableRatePeriodInput,
    LoanTrancheAdjustableRatePeriodType
  >(SetLoanTrancheAdjustableRatePeriodMutationDefinition, {
    build: mutate => loanTrancheAdjustableRatePeriod =>
      mutate({
        variables: {
          loanTrancheAdjustableRatePeriodData: loanTrancheAdjustableRatePeriod,
        },
      }),
  }),
  deleteLoanTrancheAdjustableRatePeriod: mutationBuilder<ID, void>(
    DeleteLoanTrancheAdjustableRatePeriodMutationDefinition,
    {
      build: mutate => (id: string) => mutate({ variables: { id } }),
    },
  ),
};

export default LoanTrancheAdjustableRatePeriodMutationMethods;
