import { compose } from 'recompose';
import Summary from './Summary';
import { RouteParams, withRouteParams } from 'routing';
import { DealQuery, LoanSummaryQuery, MyUserQuery } from 'lsgql';
import { withLoadingIndicator } from 'components';

export default compose(
  withRouteParams([RouteParams.dealId]),
  DealQuery,
  LoanSummaryQuery,
  MyUserQuery,
  withLoadingIndicator('loading'),
)(Summary);
