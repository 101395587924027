import * as React from 'react';
import { getBenchmarkOptions } from '../utils';
import TrancheGroup from '../TrancheGroup';
import { LoanTrancheFormProps } from '../../types';
import FloorAndCapFieldsTotalItem from './FloorAndCapFieldsTotalItem';
import FloorAndCapFieldsLiborItem from './FloorAndCapFieldsLiborItem';
import FloorAndCapFieldsAbrItem from './FloorAndCapFieldsAbrItem';
import FloorAndCapFieldsOtherItem from './FloorAndCapFieldsOtherItem';
import FloorAndCapFieldsAdjustmentLimitItem from './FloorAndCapFieldsAdjustmentLimitItem';
import { AlternateBaseRateBenchmarkType } from 'types';
import { Form, Header } from 'components';

class FloorAndCapFields extends React.Component<LoanTrancheFormProps> {
  headers = {
    tranche: 'Tranche',
    floor: 'Floor',
    cap: 'Cap',
    benchmark: 'Benchmark',
  };

  render() {
    const { data, disabled, isSaving, errors } = this.props;

    const {
      liborBenchmarkOptions,
      primeBenchmarkOption,
      fedFundsBenchmarkOption,
      isAbrSelected,
      abrBenchmarkOptions,
    } = getBenchmarkOptions(this.props);

    return (
      <TrancheGroup>
        <Header as="h5" className="steel bold">
          {this.headers.tranche}
        </Header>
        <FloorAndCapFieldsTotalItem
          data={data}
          description="Total Interest Rate"
          disabled={disabled}
          errors={errors}
          isSaving={isSaving}
          mutateProperty={this.props.mutateProperty}
        />
        <Form.Divider className="transparent" />
        <Header as="h5" className="steel bold">
          {this.headers.benchmark}
        </Header>
        {Boolean(liborBenchmarkOptions.length) && (
          <FloorAndCapFieldsLiborItem
            data={liborBenchmarkOptions}
            description="LIBOR"
            disabled={disabled}
            errors={errors}
            isSaving={isSaving}
            onChange={this.props.onUpdateBenchmarkOption}
          />
        )}
        {isAbrSelected && (
          <FloorAndCapFieldsAbrItem
            data={
              (abrBenchmarkOptions as any) as ReadonlyArray<
                AlternateBaseRateBenchmarkType
              >
            }
            description="Alternate Base Rate"
            disabled={disabled}
            errors={errors}
            isSaving={isSaving}
            onChange={this.props.onUpdateAbrOption}
          />
        )}
        {Boolean(primeBenchmarkOption.benchmarkId) && (
          <FloorAndCapFieldsOtherItem
            data={primeBenchmarkOption}
            description="Prime"
            disabled={disabled}
            errors={errors}
            isSaving={isSaving}
            onChange={this.props.onUpdateBenchmarkOption}
          />
        )}
        {Boolean(fedFundsBenchmarkOption.benchmarkId) && (
          <FloorAndCapFieldsOtherItem
            data={fedFundsBenchmarkOption}
            description="Federal Funds"
            disabled={disabled}
            errors={errors}
            isSaving={isSaving}
            onChange={this.props.onUpdateBenchmarkOption}
          />
        )}
        <FloorAndCapFieldsAdjustmentLimitItem
          data={data}
          disabled={disabled}
          errors={errors}
          isSaving={isSaving}
          mutateProperty={this.props.mutateProperty}
        />
      </TrancheGroup>
    );
  }
}

export default FloorAndCapFields;
