import uuid from 'uuid/v4';

import {
  LoanTrancheFormProps,
  BenchmarkTextValueSortOrderType,
} from '../types';
import {
  LoanTrancheType,
  LoanTrancheFormType,
  LoanTrancheInterestType,
  LoanTrancheCommitmentDrawType,
  LoanTrancheAdjustableRatePeriodType,
  LoanTrancheAmortizationInterestPeriodType,
  LoanTranchePaymentFrequency,
  BenchmarkType,
  BenchmarkOptionType,
  ID,
  AmortizationWithReaderDataType,
  LoanTrancheAmortizationAmortizationType,
  AlternateBaseRateBenchmarkType,
} from 'types';
import { TypeQueryResult } from 'components';

import { invariant } from 'utils';
import { KeyPath } from 'lsredux';

const fixedPaymentflag: LoanTrancheInterestType = 'FIXED_PAYMENT';

export function isFixedPayment(tranche: LoanTrancheType): boolean {
  return tranche.interestType === fixedPaymentflag;
}

const fixedInterestflag: LoanTrancheInterestType = 'FIXED_RATE';

export function isFixedInterest(tranche: LoanTrancheType): boolean {
  return tranche.interestType === fixedInterestflag;
}

const floatingRateFlag: LoanTrancheInterestType = 'FLOATING';

export function isFloatingRate(tranche: LoanTrancheType): boolean {
  return tranche.interestType === floatingRateFlag;
}

const adjustableRateFlag = 'ADJUSTABLE_RATE';
export function isAdjustableRate(tranche: LoanTrancheType): boolean {
  return tranche.interestType === adjustableRateFlag;
}

const singleDrawFlag: LoanTrancheCommitmentDrawType = 'SINGLE_DRAW';

export function isSingleDraw(tranche: LoanTrancheType): boolean {
  return tranche.commitmentDrawType === singleDrawFlag;
}

const multiDrawFlag: LoanTrancheCommitmentDrawType = 'MULTIPLE_DRAW';

export function isMultiDraw(tranche: LoanTrancheType): boolean {
  return tranche.commitmentDrawType === multiDrawFlag;
}
const revolverDrawFlag: LoanTrancheCommitmentDrawType = 'REVOLVER';

export function isRevolver(tranche: LoanTrancheType): boolean {
  return tranche.commitmentDrawType === revolverDrawFlag;
}
const swinglineDrawFlag: LoanTrancheCommitmentDrawType = 'SWINGLINE';

export function isSwingline(tranche: LoanTrancheType): boolean {
  return tranche.commitmentDrawType === swinglineDrawFlag;
}
const letterOfCreditDrawFlag: LoanTrancheCommitmentDrawType =
  'LETTER_OF_CREDIT';

export function isLetterOfCredit(tranche: LoanTrancheType): boolean {
  return tranche.commitmentDrawType === letterOfCreditDrawFlag;
}

export function isExpiringBenchmark(benchmark: BenchmarkType): boolean {
  return benchmark.interval === null || benchmark.interval === undefined;
}

export function getAmortization(
  e: LoanTrancheFormType,
): AmortizationWithReaderDataType | null | undefined {
  if (
    e &&
    e.loantrancheamortizationSet &&
    e.loantrancheamortizationSet.length > 0
  ) {
    if (e.loantrancheamortizationSet.length > 1) {
      // eslint-disable-next-line
      console.warn(`Multiple amortization objects exist for tranche ${e.id}`);
    }

    return e.loantrancheamortizationSet[0];
  }
  return null;
}

function getPlaceholder() {
  return { id: uuid(), benchmarkId: '', __typename: 'BenchmarkOptionType' };
}

function getAbrPlaceholder() {
  return {
    id: uuid(),
    benchmarkId: '',
    margin: '0E-17',
    dayCountConvention: null,
    __typename: 'AlternateBaseRateBenchmarkType',
  };
}

function getLiborBenchmarkOptions(props: LoanTrancheFormProps) {
  const { benchmarksReferenceData, data } = props;
  const { liborBenchmarkMap } = benchmarksReferenceData;
  if (data.benchmarkoptionSet) {
    const options: Array<BenchmarkOptionType> = data.benchmarkoptionSet
      .filter(
        benchmark =>
          benchmark.benchmarkId && liborBenchmarkMap[benchmark.benchmarkId],
      )
      .sort((a, b) => {
        if (a.benchmarkId && b.benchmarkId) {
          return (
            liborBenchmarkMap[a.benchmarkId].sortOrder -
            liborBenchmarkMap[b.benchmarkId].sortOrder
          );
        }
        if (a.benchmarkId) return 1;
        if (b.benchmarkId) return -1;
        return 0;
      });
    return options;
  }
  return [];
}

function getLiborBenchmarkOptionsBenchmarkIds(
  benchmarkOptions: ReadonlyArray<BenchmarkOptionType>,
  liborBenchmarkMap: {
    [key: string]: BenchmarkTextValueSortOrderType;
  },
): ReadonlyArray<ID> {
  return benchmarkOptions
    .map(option => (option && option.benchmarkId) || '')
    .sort(
      (a, b) => liborBenchmarkMap[a].sortOrder - liborBenchmarkMap[b].sortOrder,
    );
}

function getOtherBenchmarkOption(
  props: LoanTrancheFormProps,
  otherBenchmark: BenchmarkTextValueSortOrderType | null | undefined,
) {
  const { data } = props;
  let otherBenchmarkOption;
  if (otherBenchmark && data.benchmarkoptionSet) {
    otherBenchmarkOption = data.benchmarkoptionSet.find(
      benchmarkOption => benchmarkOption.benchmarkId === otherBenchmark.value,
    );
  }
  if (!otherBenchmarkOption) otherBenchmarkOption = getPlaceholder();
  return otherBenchmarkOption;
}

function getAbrBenchmarkOption(
  props: LoanTrancheFormProps,
  benchmark: BenchmarkTextValueSortOrderType | null | undefined,
): AlternateBaseRateBenchmarkType {
  const { data } = props;
  let abrBenchmarkOption;
  if (benchmark && data.alternatebaseratebenchmarkSet) {
    abrBenchmarkOption = data.alternatebaseratebenchmarkSet.find(
      benchmarkOption => benchmarkOption.benchmarkId === benchmark.value,
    );
  }
  if (!abrBenchmarkOption) abrBenchmarkOption = getAbrPlaceholder();
  return abrBenchmarkOption;
}

function getAbrBenchmarkOptionsMap(
  props: LoanTrancheFormProps,
  primeBenchmark: BenchmarkTextValueSortOrderType | null | undefined,
  fedFundsBenchmark: BenchmarkTextValueSortOrderType | null | undefined,
  liborOneMonthBenchmark: BenchmarkTextValueSortOrderType | null | undefined,
) {
  return {
    prime: getAbrBenchmarkOption(props, primeBenchmark),
    fedFunds: getAbrBenchmarkOption(props, fedFundsBenchmark),
    liborOneMonth: getAbrBenchmarkOption(props, liborOneMonthBenchmark),
  };
}

export function getBenchmarkOptions(props: LoanTrancheFormProps) {
  const { benchmarksReferenceData } = props;
  const {
    primeBenchmark,
    fedFundsBenchmark,
    liborBenchmarkMap,
    liborOneMonthBenchmark,
  } = benchmarksReferenceData;

  const liborBenchmarkOptions = getLiborBenchmarkOptions(props);
  const liborBenchmarkOptionsBenchmarkIds = getLiborBenchmarkOptionsBenchmarkIds(
    liborBenchmarkOptions,
    liborBenchmarkMap,
  );
  const primeBenchmarkOption = getOtherBenchmarkOption(props, primeBenchmark);
  const fedFundsBenchmarkOption = getOtherBenchmarkOption(
    props,
    fedFundsBenchmark,
  );
  const abrBenchmarkOptionsMap = getAbrBenchmarkOptionsMap(
    props,
    primeBenchmark,
    fedFundsBenchmark,
    liborOneMonthBenchmark,
  );
  const abrBenchmarkOptions = Object.values(abrBenchmarkOptionsMap);

  const isAbrSelected =
    Boolean(abrBenchmarkOptionsMap.prime.benchmarkId) ||
    Boolean(abrBenchmarkOptionsMap.fedFunds.benchmarkId) ||
    Boolean(abrBenchmarkOptionsMap.liborOneMonth.benchmarkId);

  return {
    liborBenchmarkOptions,
    liborBenchmarkOptionsBenchmarkIds,
    primeBenchmarkOption,
    fedFundsBenchmarkOption,
    abrBenchmarkOptionsMap,
    abrBenchmarkOptions,
    isAbrSelected,
  };
}

const suffixMap: {
  [key in LoanTranchePaymentFrequency]: string;
} = {
  WEEKLY: 'weekly periods',
  BI_WEEKLY: 'bi-weekly periods',
  MONTHLY: 'monthly periods',
  QUARTERLY: 'quarterly periods',
  SEMI_ANNUALLY: 'semi-annual periods',
  ANNUALLY: 'annual periods',
};

export function getSuffixFromPaymentFrequency(data: LoanTrancheType) {
  if (!data.paymentFrequency) {
    return '';
  }
  return suffixMap[data.paymentFrequency];
}

export function calculateRepeatsFor(
  originalTerm: string | null | undefined,
  initialPeriod: string | null | undefined,
) {
  if (
    Number.isNaN(Number(originalTerm)) ||
    Number.isNaN(Number(initialPeriod))
  ) {
    return null;
  }

  const final = Number(originalTerm) - Number(initialPeriod);
  return final > 0 ? String(final) : null;
}

function isTruthyAndisNumber(value: string | null | undefined) {
  return value && !Number.isNaN(Number(value));
}

export function canGenerateAdjustableRatePeriods(
  originalTerm: string | null | undefined,
  initialPeriod: string | null | undefined,
  resetPeriod: string | null | undefined,
  indicativeFixedRate: string | null | undefined,
) {
  return (
    isTruthyAndisNumber(originalTerm) &&
    isTruthyAndisNumber(initialPeriod) &&
    isTruthyAndisNumber(resetPeriod) &&
    indicativeFixedRate !== null &&
    indicativeFixedRate !== undefined
  );
}

function getNewAdjustableRatePeriodType(period: number, totalRate: string) {
  return {
    id: uuid(),
    __typename: 'LoanTrancheAdjustableRatePeriodType',
    period: period.toString(),
    adjustmentRate: '0',
    totalRate,
  };
}

export function generateAdjustableRatePeriods(
  originalTerm: number,
  initialPeriod: number,
  resetPeriod: number,
  initialRate: string,
) {
  // we have to ensure before calculations that we have actual numbers
  invariant(
    !Number.isNaN(Number(originalTerm)),
    'Original Term is not a number!',
  );
  invariant(
    !Number.isNaN(Number(initialPeriod)),
    'Initial Period is not a number!',
  );
  invariant(
    !Number.isNaN(Number(resetPeriod)),
    'Reset Period is not a number!',
  );

  if (originalTerm - initialPeriod > 0 && resetPeriod > 0) {
    const periods: Array<LoanTrancheAdjustableRatePeriodType> = [];

    periods.push(
      getNewAdjustableRatePeriodType(initialPeriod, initialRate) as any,
    );

    for (let i = initialPeriod; i < originalTerm; i += resetPeriod) {
      periods.push(
        getNewAdjustableRatePeriodType(
          i + resetPeriod > originalTerm ? originalTerm : i + resetPeriod,
          initialRate,
        ) as any,
      );
    }

    return periods;
  }
  return [];
}

export function getRoundedString(value: number, places = 6) {
  const factor = 10 ** places;
  const roundedValue = String(Math.round(value * factor) / factor);
  return roundedValue;
}

export function createAmortizationInterestPeriodsFromAdjustableRatePeriods(
  periods: Array<LoanTrancheAdjustableRatePeriodType>,
) {
  let startPeriod = '0';

  const ltaInterestPeriods: Array<LoanTrancheAmortizationInterestPeriodType> = periods.map(
    period => {
      const ltaInterestPeriod: LoanTrancheAmortizationInterestPeriodType = {
        startPeriod,
        endPeriod: period.period,
        id: period.id,
        interestRate: period.totalRate,
        __typename: 'LoanTrancheAmortizationInterestPeriodType',
      };
      const startPeriodNumber = Number(period.period) + 1;
      invariant(
        !Number.isNaN(startPeriodNumber),
        'start Period Number is not a number',
      );
      startPeriod = String(startPeriodNumber);
      return ltaInterestPeriod;
    },
  );
  return ltaInterestPeriods;
}

export function deleteAdjustableRatePeriodsAndLTAInterestPeriods(
  data: LoanTrancheType,
  deleteCollection: (
    keyPath: KeyPath,
    entities: Array<any>,
    discard?: boolean,
  ) => void,
) {
  let entitiesToDelete =
    data.loantrancheadjustableratedata &&
    data.loantrancheadjustableratedata.loantrancheadjustablerateperiodSet
      ? data.loantrancheadjustableratedata.loantrancheadjustablerateperiodSet
      : [];
  if (entitiesToDelete.length > 0) {
    deleteCollection(
      ['loantrancheadjustableratedata', 'loantrancheadjustablerateperiodSet'],
      entitiesToDelete,
    );
  }

  entitiesToDelete =
    data.loantrancheamortizationSet &&
    data.loantrancheamortizationSet[0] &&
    data.loantrancheamortizationSet[0]
      .loantrancheamortizationinterestperiodSet &&
    data.loantrancheamortizationSet[0].loantrancheamortizationinterestperiodSet
      ? data.loantrancheamortizationSet[0]
          .loantrancheamortizationinterestperiodSet
      : [];
  if (entitiesToDelete.length > 0) {
    deleteCollection(
      [
        'loantrancheamortizationSet',
        '0',
        'loantrancheamortizationinterestperiodSet',
      ],
      entitiesToDelete,
    );
  }
}

/**
 * Expliclty name the enum type so that flow will raise an error if we ever change the enum defintion
 */
const fixedPrincipalAndInterestAmortization: LoanTrancheAmortizationAmortizationType =
  'FIXED_PRINCIPAL_AND_INTEREST';

/**
 * Investigate the `interestType` of a tranche object, and update the
 * amortization object with the correct `amortizationType`.
 *
 * TODO: When we bring some consistency to calculated data,
 * this function should likely be removed
 */
export function synchronizeInterestAndAmortizationTypes(
  props: LoanTrancheFormProps,
) {
  const amortization:
    | AmortizationWithReaderDataType
    | null
    | undefined = getAmortization(props.data);
  if (!amortization) {
    /*
    It's not the job of this method to handle the absence of the amortization object
    This error condition is enforced by the parent components
    */
    return;
  }
  if (
    (isFixedPayment(props.data) ||
      isFloatingRate(props.data) ||
      isAdjustableRate(props.data)) &&
    amortization.amortizationType !== fixedPrincipalAndInterestAmortization
  ) {
    props.mutateAmortization(
      'FIXED_PRINCIPAL_AND_INTEREST',
      'amortizationType',
    );
  }
}

/**
 * Returns an object of with the original value field, but the text field modified
 * to be more human friendly
 * @param {object} queryResult a dictionary with 'text' and 'value' fields
 */
export function parseDayCountText(queryResult: TypeQueryResult) {
  const { text, value } = queryResult;
  const convertedText =
    {
      ACTUAL_360_ADJUSTED: 'Actual/360 - Adjusted',
      ACTUAL_360: 'Actual/360',
      ACTUAL_365: 'Actual/365',
      ACTUAL_ACTUAL_ICMA: 'Actual/Actual - ICMA',
      ACTUAL_ACTUAL_ISDA: 'Actual/Actual - ISDA',
      THIRTY_360: '30/360',
    }[text] || text;

  return { text: convertedText, value };
}
