// !!!!! THIS IS AN AUTO-GENERATED FILE, DO NOT EDIT !!!!!

import { gql } from '@apollo/client';
import { LoanTrancheInput } from 'types';
import { invariant, strictConvertToInput } from 'utils';
import {
  default as validationBuilder,
  ValidationData,
  AdditionalOptions,
} from './builder/validationBuilder';
import { ApolloQueryResult } from '../types';
import { inputScrubber } from '../mutations/utils';

/* eslint-disable import/prefer-default-export */

const LoanTrancheValidatorDefinition = gql`
  query validateLoanTranche($loanTrancheData: LoanTrancheInput) {
    validateLoanTranche(loanTrancheData: $loanTrancheData) {
      errors
      errorFields {
        name
        value
      }
      warnings
      warningFields {
        name
        value
      }
    }
  }
`;

const propName = 'validateLoanTranche';

/**
 * Auto-Generated from the GraphQL Schema
 */
export const LoanTrancheValidator = validationBuilder<LoanTrancheInput>(
  LoanTrancheValidatorDefinition,
  {
    build: (
      validation: (
        options: AdditionalOptions,
      ) => Promise<ApolloQueryResult<ValidationData>>,
    ) => async (entity: LoanTrancheInput) => {
      const res: ApolloQueryResult<ValidationData> = await validation({
        variables: {
          loanTrancheData: { id: entity.id },
        },
      });
      invariant(
        res.data[propName],
        'Expected res.data to contain validateLoanTranche',
      );
      return res.data[propName];
    },
  },
);

export const LoanTrancheEntityValidator = validationBuilder<LoanTrancheInput>(
  LoanTrancheValidatorDefinition,
  {
    build: (
      validation: (
        options: AdditionalOptions,
      ) => Promise<ApolloQueryResult<ValidationData>>,
    ) => async (entity: LoanTrancheInput) => {
      const res: ApolloQueryResult<ValidationData> = await validation({
        variables: {
          loanTrancheData: strictConvertToInput({
            ...inputScrubber(entity),
            submitted: undefined,
            approved: undefined,
          }),
        },
      });
      invariant(
        res.data[propName],
        'Expected res.data to contain validateLoanTranche',
      );
      return res.data[propName];
    },
  },
);
