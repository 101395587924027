import { mutationBuilder } from './builders';
import {
  CreateSbaPppForgivenessDenialMutationDefinition,
  SetSbaPppForgivenessDenialMutationDefinition,
} from './definitions';
import {
  SbaPppForgivenessDenialInput,
  SbaPppForgivenessDenialType,
} from 'types';

const SbaPppForgivenessDenialMutationMethods = {
  createSbaPppForgivenessDenial: mutationBuilder<
    SbaPppForgivenessDenialInput,
    SbaPppForgivenessDenialType
  >(CreateSbaPppForgivenessDenialMutationDefinition, {
    build: mutate => sbaPppForgivenessDenialData =>
      mutate({ variables: { sbaPppForgivenessDenialData } }),
  }),
  setSbaPppForgivenessDenial: mutationBuilder<
    SbaPppForgivenessDenialInput,
    SbaPppForgivenessDenialType
  >(SetSbaPppForgivenessDenialMutationDefinition, {
    build: mutate => sbaPppForgivenessDenialData =>
      mutate({ variables: { sbaPppForgivenessDenialData } }),
  }),
};

export default SbaPppForgivenessDenialMutationMethods;
