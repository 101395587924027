import * as React from 'react';
import { StepProps } from '../StepProps';
import WorkflowStep from '../WorkflowStep';
import { invariant } from 'utils';
import { Text, TextLink, RouteTo } from 'components';
import { RouteTable } from 'routing';
/**
 * This component is meant to display the 'LOAN APPLICATION' step of the
 * borrower workflow, as shown in step 1 here:
 *
 * https://app.zeplin.io/project/5e9a4284b620a47b8cac95a9/screen/5e9a42e20221ab7d51dc1569
 *
 * This should handle the following states:
 *
 * - canEdit && !isDimmed - Borrower can take action
 * - !canEdit && !isDimmed - Borrower completed this step in the past
 * - !canEdit && isDimmed - Borrower can complete this step in the future
 *
 * @param props
 * @constructor
 */
function BorrowerLoanApplication(props: StepProps) {
  const { step, canEdit, isDimmed, dealId } = props;
  invariant(step.slug === 'aw_borrower_loan_application', 'invalid slug');
  const instructionalText = canEdit
    ? 'Make any changes or corrections to your application'
    : 'You completed and submitted your application for review';
  const linkText = canEdit ? 'Edit' : 'View';
  return (
    <WorkflowStep
      canEdit={canEdit}
      header="LOAN APPLICATION"
      isDimmed={isDimmed}
      step={step}
    >
      <div className="Group justified">
        <Text className="bold" value={instructionalText} />
        <TextLink
          as={RouteTo}
          label={linkText}
          to={RouteTable.deal.toDealSbaApplication(dealId)}
        />
      </div>
      <Text value="Your SBA Application is available in the left navigation bar." />
    </WorkflowStep>
  );
}

export default BorrowerLoanApplication;
