import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import {
  DealShallowFields,
  SbaPppForgivenessTrackerShallowFields,
  DocumentShallowFields,
} from '../fragments';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { SbaPppForgivenessTrackerType } from 'types';

const SbaPppForgivenessTrackerQueryDefinition: DocumentNode = gql`
  query DealQuery($id: ID) {
    deal(id: $id) {
      ...DealShallowFields
      sbapppforgivenesstrackerSet {
        ...SbaPppForgivenessTrackerShallowFields
        forgivenessCertificate {
          ...DocumentShallowFields
        }
      }
    }
  }
  ${DealShallowFields}
  ${SbaPppForgivenessTrackerShallowFields}
  ${DocumentShallowFields}
`;

export const SbaPppForgivenessTrackerQuery = standardQueryBuilder(
  SbaPppForgivenessTrackerQueryDefinition,
  {
    typename: 'SbaPppForgivenessTracker',
    skip: (props: { dealId?: string | null }) => !props.dealId,
    variables: (props: { dealId?: string | null }) => ({ id: props.dealId }),
    results: (data: {
      sbaPppForgivenessTracker?: SbaPppForgivenessTrackerType | null;
    }) => data,
  },
);
