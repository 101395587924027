import { MutationMethod } from '../types';
import { mutationBuilder } from './builders';
import {
  SetLoanTrancheMultiDrawDataMutationDefinition,
  CreateLoanTrancheMultiDrawDataMutationDefinition,
  DeleteLoanTrancheMultiDrawDataMutationDefinition,
} from './definitions';
import {
  LoanTrancheMultiDrawDataInput,
  LoanTrancheMultiDrawDataType,
  ID,
} from 'types';

const createLoanTrancheMultiDrawData: MutationMethod<
  LoanTrancheMultiDrawDataInput,
  LoanTrancheMultiDrawDataType
> = mutationBuilder<
  LoanTrancheMultiDrawDataInput,
  LoanTrancheMultiDrawDataType
>(CreateLoanTrancheMultiDrawDataMutationDefinition, {
  build: mutate => loanTrancheMultiDrawDataData =>
    mutate({
      variables: {
        loanTrancheMultiDrawDataData,
      },
    }),
});

const setLoanTrancheMultiDrawData: MutationMethod<
  LoanTrancheMultiDrawDataInput,
  LoanTrancheMultiDrawDataType
> = mutationBuilder<
  LoanTrancheMultiDrawDataInput,
  LoanTrancheMultiDrawDataType
>(SetLoanTrancheMultiDrawDataMutationDefinition, {
  build: mutate => loanTrancheMultiDrawDataData =>
    mutate({
      variables: {
        loanTrancheMultiDrawDataData,
      },
    }),
});

const deleteLoanTrancheMultiDrawData: MutationMethod<
  ID,
  void
> = mutationBuilder<ID, void>(
  DeleteLoanTrancheMultiDrawDataMutationDefinition,
  {
    build: mutate => (id: ID) =>
      mutate({
        variables: {
          id,
        },
      }),
  },
);

const LoanTrancheMultiDrawDataMutationMethods = {
  createLoanTrancheMultiDrawData,
  setLoanTrancheMultiDrawData,
  deleteLoanTrancheMultiDrawData,
};

export default LoanTrancheMultiDrawDataMutationMethods;
