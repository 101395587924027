import { compose } from 'recompose';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import * as Immutable from 'immutable';
import ContainerAggregator from './ContainerAggregator';
import { OrderBookInput, OrderBookProspect, LoanTrancheTierType } from 'types';
import { Column, Value, FormMediator } from 'components';

import { OrderBookFormQuery } from 'lsgql';
import { ReduxDirectory } from 'lsredux';
import { withRouteParams, RouteParams } from 'routing';
import { MultiDispatchMethods } from 'lsredux/genericForms/types';
import orderBookForm from 'lsredux/reducer/forms/orderBook';

type OwnProps = {
  dealId: string;
  loanTrancheId: string;
};

const mapStateToProps = (state: any, ownProps: OwnProps) => {
  const keyPath = [...ReduxDirectory.OrderBookKeyPath, ownProps.loanTrancheId]; // ['investorBook', ownProps.trancheId];
  const entity = state.hasIn(keyPath)
    ? state.getIn(keyPath)
    : Immutable.Map({});

  return {
    rawData: entity,
    enableMultiLenderView: state.getIn(ReduxDirectory.MultiLenderViewKeyPath),
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  const bound: MultiDispatchMethods<OrderBookInput> = orderBookForm.actions.generateActions(
    dispatch,
    ownProps.loanTrancheId,
  );

  const additional = {
    /* Tier methods */
    handleAddTierRow(): void {
      const emptyTrancheTier: LoanTrancheTierType = {
        id: uuid(),
        lowAmount: '0',
        highAmount: null,
        __typename: 'LoanTrancheTierType',
      };
      bound.addEntity('loantranchetierSet', emptyTrancheTier);
    },

    handleEditTierRow(
      rowKey: any,
      column: Column<LoanTrancheTierType, any>,
      value: Value,
      row: LoanTrancheTierType,
    ): void {
      const updated = {
        ...row,
        [column.columnName]: value,
      };
      bound.replaceEntity('loantranchetierSet', updated);
    },

    handleDeleteTierRow(tier: LoanTrancheTierType): void {
      bound.removeEntity('loantranchetierSet', tier);
    },

    /* Investors methods */
    addProspectRow(investorName = 'test'): void {
      const emptyProspectObject: OrderBookProspect = {
        id: uuid(),
        investorName,
        __typename: 'ProspectType',
      };
      bound.addEntity('prospectSet', emptyProspectObject);
    },

    deleteProspectRow(prospect: OrderBookProspect): void {
      bound.removeEntity('prospectSet', prospect);
    },

    editProspectRow(
      rowKey: any,
      column: Column<OrderBookProspect, any>,
      value: Value,
      row: OrderBookProspect,
    ): void {
      bound.replaceEntity('prospectSet', {
        ...row,
        [column.columnName]: value,
      });
    },
  };

  return {
    ...bound,
    ...additional,
  };
};

/**
 * OrderBookPropsBuilder functions as a container component that isolates data and functionality
 * to the scope of a single LoanTranche + OrderBook
 */
const OrderBookPropsBuilder = compose(
  withRouteParams([RouteParams.dealId]),
  OrderBookFormQuery,
  connect(mapStateToProps, mapDispatchToProps),
  FormMediator({
    formHeader: 'Edit Details',
    formId: 'orderBookForm',
    disableFrame: true,
    baseObjectTypeName: 'LoanTranche',
  }),
)(ContainerAggregator);

export default OrderBookPropsBuilder;
