import { compose } from 'recompose';
import { connect } from 'react-redux';
import UserProfileForm from './UserProfileForm';
import { FormMediator } from 'components';
import { MyUserFormQuery, UserMutations } from 'lsgql';
import UserProfileFormRedux from 'lsredux/reducer/forms/userProfile';

const UserProfileFormContainer = compose(
  UserMutations,
  MyUserFormQuery,
  connect(
    UserProfileFormRedux.mapStateToProps,
    UserProfileFormRedux.actions.generateActions,
  ),
  FormMediator({
    formId: 'UserProfileForm',
    baseObjectTypeName: 'User',
    simpleFooter: true,
  }),
)(UserProfileForm);

export default UserProfileFormContainer;
