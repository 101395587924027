import * as React from 'react';
import { Form } from 'components';
import { FeeType, FieldRendererGeneralPropsType } from 'types';
import { KeyPath } from 'lsredux';

const eight = 'eight';
const feeString = 'Fee';

type Props = FieldRendererGeneralPropsType & {
  data: FeeType;
  fieldName: 'Discount to Par' | 'Fee to Draw';
  replaceEntity: (keyPath: KeyPath, entity: any) => void;
};

class MultiDrawRulesFieldsFeeItem extends React.Component<Props> {
  mutateFee = (value: string | null | undefined) => {
    const { data } = this.props;
    this.props.replaceEntity('feeSet', {
      ...data,
      feecalculationrule: {
        ...data.feecalculationrule,
        appliedRate: value,
        isDirty: true,
      },
    });
  };

  /* eslint-enable react/sort-comp */

  field = {
    id: 'appliedRate',
    propertyName: 'appliedRate',
    fieldName: this.props.fieldName,
    onChange: this.mutateFee,
    width: eight,
  };

  render() {
    const { data } = this.props;

    const rate = data.feecalculationrule && data.feecalculationrule.appliedRate;

    return Form.FieldRenderer(
      Form.Percentage,
      { ...this.field, value: rate },
      this.props,
      feeString,
      data.id,
    );
  }
}

export default MultiDrawRulesFieldsFeeItem;
