/**
 * Within a component connected to React-Router context,
 * invoke routing to a new url
 *
 * This only works for components using withRouter HoC.
 * TODO: Make hooks compatible
 * @param {*} to
 */
export default function routeTo(to: string): void {
  const { history } = this.props;

  // https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/Link.js#L73
  history.push(to);
}
