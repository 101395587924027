import React from 'react';
import { useLazyQuery } from '@apollo/client';
import RevealValueQueryDefinition from 'lsgql/queries/RevealValueQuery';
import { ProtectedDisplayField } from 'components';
import { SbaPppInstitutionType } from 'types';
import { FormFieldProps } from '@loanstreet-usa/design-system';
import uuid from 'uuid/v4';

type Props = FormFieldProps & {
  institution: SbaPppInstitutionType;
  maskedDisplay?: string; // ********
  dealId: string;
  formIsDirty: boolean;
};

function ProtectedTINField(props: Props) {
  const { institution } = props;

  let placeholderBusinessTinEinSsn = 'Enter EIN or SSN, numbers only';

  if (institution.businessTinEinSsn) {
    placeholderBusinessTinEinSsn =
      institution.businessTinType === 'EIN' ? '**-*******' : '***-**-****';
  }

  const [getValue, {}] = useLazyQuery(RevealValueQueryDefinition, {
    onCompleted: (data: any) => {
      const { revealValue } = data;
      props.onChange(revealValue.value, props.propertyName);
    },
  });

  const handleVisibilityToggle = (visible: boolean) => {
    if (visible) {
      getValue({ variables: { id: props.dealId, cacheBuster: uuid() } });
    }
  };

  return (
    <ProtectedDisplayField
      {...props}
      maskedDisplay={placeholderBusinessTinEinSsn}
      onVisibilityToggle={handleVisibilityToggle}
    />
  );
}

export default ProtectedTINField;
