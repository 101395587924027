import * as React from 'react';
import { Button, Header } from 'components';

import './NotFound.scss';

const headerText = 'Something’s wrong here…';
const messageBody =
  'We can’t find the page you are looking for. Check out our Help Center or head back to home.';

/**
 * The default 404 Not Found page.
 */
const NotFound = () => (
  <div className="NotFound">
    <div className="content">
      <Header as="h1" className="NotFound__Header">
        {headerText}
      </Header>
      <div className="NotFound__Message">{messageBody}</div>

      <Button label="Home" to="/" />
    </div>
  </div>
);

export default NotFound;
