import React, { useEffect } from 'react';
import { Modal, Form, WrappedMediatorFormProps } from 'components';
import { ID, FormFieldsType, LoanFundingType } from 'types';

interface Props extends WrappedMediatorFormProps<LoanFundingType> {
  isOpen: boolean;
  onClose: (e?: React.SyntheticEvent) => void;
  dealId: ID;

  dealClosingWorkflowId: ID;
  disableFormFields?: boolean;
  editExisting?: boolean;
}

const fields: FormFieldsType = {
  sbaLoanNumber: {
    id: 'sbaLoanNumber',
    propertyName: 'sbaLoanNumber',
    fieldName: 'SBA Loan #',
    required: true,
    width: '120px',
  },
  loanAmount: {
    id: 'loanAmount',
    propertyName: 'loanAmount',
    fieldName: 'Loan Amount',
    required: true,
    width: '120px',
  },
  sbaApprovalDate: {
    id: 'sbaApprovalDate',
    propertyName: 'sbaApprovalDate',
    fieldName: 'SBA Approval Date',
    required: true,
    width: '120px',
  },
  acknowledgeChanges: {
    id: 'acknowledgeChanges',
    propertyName: 'acknowledgeChanges',
    label:
      'I acknowledge that changes made to these fields must also be made in the SBA system.',
    required: true,
  },
};

const LoanFunding = 'LoanFunding';

const LenderSbaManualApprovalFields = (props: Props) => {
  const { data, mutateProperty, disableFormFields, editExisting } = props;

  useEffect(() => {
    mutateProperty(props.dealClosingWorkflowId, 'dealClosingWorkflowId');
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    mutateProperty(!editExisting, 'acknowledgeChanges');
  }, [editExisting]);

  return (
    <>
      <Form.Group>
        {Form.FieldRenderer(
          Form.Numeric,
          {
            ...fields.sbaLoanNumber,
            onChange: props.mutateProperty,
            disabled: disableFormFields,
            disabledReadable: disableFormFields,
          },
          props,
          LoanFunding,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            ...fields.loanAmount,
            onChange: props.mutateProperty,
            disabled: disableFormFields,
            disabledReadable: disableFormFields,
          },
          props,
          LoanFunding,
          data.id,
        )}{' '}
        {Form.FieldRenderer(
          Form.Calendar,
          {
            ...fields.sbaApprovalDate,
            onChange: props.mutateProperty,
            disabled: disableFormFields,
            disabledReadable: disableFormFields,
          },
          props,
          LoanFunding,
          data.id,
        )}
      </Form.Group>
      {editExisting &&
        Form.FieldRenderer(
          Form.Checkbox,
          {
            ...fields.acknowledgeChanges,
            onChange: props.mutateProperty,
            disabled: disableFormFields,
            disabledReadable: disableFormFields,
          },
          props,
          LoanFunding,
          data.id,
        )}
    </>
  );
};

const LenderManualSbaApproval = (props: Props) => {
  const { isOpen, onClose, onSave, editExisting } = props;
  return (
    <Modal
      className="steel ManualApproval"
      confirmButtonText={editExisting ? 'Save' : 'Record Approval'}
      header={
        editExisting ? 'Edit SBA Approval Details' : 'Record SBA Approval'
      }
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onSave}
      requireResponse
      width="Half"
    >
      <div className="RecordSbaApproval">
        <Form.Text
          value={
            editExisting
              ? ''
              : 'If you already obtained SBA approval, enter the approved loan number and amount to continue.'
          }
        />
        <LenderSbaManualApprovalFields {...props} />
      </div>
    </Modal>
  );
};

export default LenderManualSbaApproval;
