import * as React from 'react';
import cx from 'classnames';
import { Tiler } from 'components';
import './SbaPppForgivenessWorkflow.scss';

type Props = {
  title: string;
  children: React.ReactNode;
  className?: string;
  ctaButtons?: () => React.ReactElement;
  helpBox?: React.ReactElement;
};

function WorkflowContentShell(props: Props) {
  return (
    <Tiler
      className={cx('SbaPppForgivenessWorkflow__Wrapper', props.className)}
    >
      <Tiler.Tile
        className="steel"
        ctaButtons={props.ctaButtons}
        secondarySection={() => props.helpBox}
        title={props.title}
        width="ThreeFourths"
      >
        {props.children}
      </Tiler.Tile>
    </Tiler>
  );
}

export default WorkflowContentShell;
