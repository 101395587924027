import treeWalker from './treeWalker';
import { BaseType } from 'types';
import { hasTypeName, invariant } from 'utils';

function isObjectValid(e: any): boolean {
  const res = hasTypeName(e);
  return res;
}

/**
 * Recursively enforce that every object in the object tree
 * contains a `__typename` property with a value.  If any objects
 * lack a valid __typename an error is raised and all invalid objects
 * are listed
 */
export default function validateObjectTree<T extends BaseType>(data: T) {
  const invalidObjects = [];

  treeWalker<T>(data, e => {
    if (!isObjectValid(e)) {
      invalidObjects.push(e);
    }
  });

  if (invalidObjects.length > 0) {
    if (process.env.NODE_ENV !== 'test') {
      // eslint-disable-next-line
      console.error(
        'MutationsDispatch cannot proceed because the following object(s) are missing __typename values:',
        invalidObjects,
      );
    }
    invariant(
      invalidObjects.length === 0,
      'MutationsDispatch failed because the object tree contained object(s) lacking __typename values',
    );
  }
}
