import * as React from 'react';
import cx from 'classnames';
import { ux } from 'utils';
import { Form, IconButton } from 'components';
import { InstitutionInviteeInput, ValidationMap } from 'types';
import { KeyPath } from 'lsredux';

import './ServicingAgentInvitationRow.scss';

const parentType = 'InstitutionInvitee';

type Props = {
  data: InstitutionInviteeInput;
  disabled?: boolean;
  errors: ValidationMap | null | undefined;
  isHeader: boolean;
  isSaving: boolean;
  removeEntity: (keyPath: KeyPath, entity: any) => void;
  replaceEntity: (keyPath: KeyPath, entity: any) => void;
};

class ServicingAgentInvitationRow extends React.Component<Props> {
  fields: any;

  constructor(props: Props) {
    super(props);

    this.fields = {
      firstName: {
        id: 'firstName',
        fieldName: 'First Name',
        propertyName: 'firstName',
        width: 'four',
        onChange: this.handleInviteeChange,
      },
      lastName: {
        id: 'lastName',
        fieldName: 'Last Name',
        propertyName: 'lastName',
        width: 'four',
        onChange: this.handleInviteeChange,
      },
      email: {
        id: 'email',
        fieldName: 'Email',
        propertyName: 'email',
        width: 'thirteen',
        onChange: this.handleInviteeChange,
      },
    };
  }

  handleInviteeChange = (value: string | null | undefined, fieldId: string) => {
    const { data } = this.props;
    const field = fieldId.split('_')[0];

    this.props.replaceEntity('invitees', { ...data, [field]: value });
  };

  handleDelete = () => {
    const { data } = this.props;
    this.props.removeEntity('invitees', data);
  };

  render() {
    const { data, isHeader } = this.props;
    const isExistingUser = Boolean(data.userId);

    const computedClassName = cx(
      'ServicingAgentInvitationRow',
      ux(isHeader, 'isHeader'),
    );

    return (
      <>
        <Form.Group className={computedClassName}>
          {Form.FieldRenderer(
            Form.Input,
            {
              ...this.fields.firstName,
              fieldName: isHeader ? this.fields.firstName.fieldName : '',
              disabled: isExistingUser,
            },
            this.props,
            parentType,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Input,
            {
              ...this.fields.lastName,
              fieldName: isHeader ? this.fields.lastName.fieldName : '',
              disabled: isExistingUser,
            },
            this.props,
            parentType,
            data.id,
          )}
          {Form.FieldRenderer(
            Form.Input,
            {
              ...this.fields.email,
              fieldName: isHeader ? this.fields.email.fieldName : '',
              disabled: isExistingUser,
            },
            this.props,
            parentType,
            data.id,
          )}
          <IconButton.Delete
            alt="Remove Loan Servicer"
            onClick={this.handleDelete}
          />
        </Form.Group>
      </>
    );
  }
}

export default ServicingAgentInvitationRow;
