import { gql } from '@apollo/client';
import moment from 'moment';
import { CalendarItemFields } from '../fragments';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { momentDateFormat, threeMonthConst, weekConst } from 'components';
import { CalendarItemType, CalendarItemCategoryType } from 'types';

const CalendarItemsQueryDefinition = gql`
  query CalendarItemsQuery(
    $id: String!
    $viewStart: String
    $viewEnd: String
    $categories: [String]
  ) {
    calendarItems(
      filters: [
        { name: "deal", value: $id }
        { name: "date__gte", value: $viewStart }
        { name: "date__lt", value: $viewEnd }
        { name: "category__slug__in", listValue: $categories }
      ]
    ) {
      ...CalendarItemFields
    }
  }
  ${CalendarItemFields}
`;

const empty = [];
type OwnProps = {
  calendarItemCategories: Array<CalendarItemCategoryType>;
  taskCategories: Array<CalendarItemCategoryType>;
  categoryFilterIgnoreMap: {
    [key: string]: boolean;
  };
  dealId: string | null | undefined;
  isListView?: boolean;
  viewEnd?: Date;
  viewRange: 'week' | 'month' | '3 month';
  viewStart: Date;
};

const CalendarItemsQuery = standardQueryBuilder(CalendarItemsQueryDefinition, {
  typename: 'DealCalendarItems',
  addFetchMethod: true,
  disableCache: true,
  skip: ({ calendarItemCategories }: OwnProps) => !calendarItemCategories,
  variables: ({
    dealId,
    viewStart,
    viewRange,
    viewEnd,
    isListView,
    calendarItemCategories,
    taskCategories,
    categoryFilterIgnoreMap,
  }: OwnProps) => {
    const startMoment = moment(viewStart);

    let endMoment;
    if (!isListView) {
      endMoment =
        viewRange === threeMonthConst
          ? moment(viewStart).add(3, 'months')
          : moment(viewStart).add(1, `${viewRange}s` as any);

      if (viewRange !== weekConst) {
        startMoment.startOf('month');
        startMoment.startOf('week');
        endMoment.endOf('week');
      }
    } else {
      endMoment = moment(viewEnd);
    }

    const startMomentString = startMoment.format(momentDateFormat);
    const endMomentString = endMoment.format(momentDateFormat);

    const allCategories = [
      ...(calendarItemCategories || empty),
      ...(taskCategories || empty),
    ];

    return {
      id: dealId,
      viewStart: startMomentString,
      viewEnd: endMomentString,
      categories: allCategories
        .map(category => category.slug)
        .filter(category => category && !categoryFilterIgnoreMap[category]),
    };
  },
  results: (data: {
    calendarItems: Array<CalendarItemType> | null | undefined;
  }) => {
    const { calendarItems } = data;
    return { calendarItems };
  },
});

export default CalendarItemsQuery;
