import * as React from 'react';

import LoanTrancheMutations from '../../graphql/mutations/LoanTrancheMutations';
import { alerts } from '../../resources';
import BaseFields from './sections/BaseFields';
import DatesFields from './sections/DatesFields';
import DetailsFields from './sections/DetailsFields';
import CollateralFieldsCollection from './sections/CollateralForm/CollateralFieldsCollection';
import CovenantFields from './sections/CovenantFields';
import EscrowFields from './sections/EscrowFields';
import InterestFields from './sections/InterestFields';
import FloorAndCapFields from './sections/FloorAndCapFields';
import GuarantorFieldsCollection from './sections/GuarantorsForm/GuarantorFieldsCollection';
import InterestElectionRulesFields from './sections/InterestElectionRulesFields';
import MultiDrawRulesFields from './sections/MultiDrawRulesFields';
import NotesFields from './sections/NotesFields';
import PaymentFields from './sections/PaymentFields';
import PrincipalFields from './sections/PrincipalFields';
import UnusedFeeFields from './sections/UnusedFeeFields';

import TrancheRow from './trancheRow/TrancheRow';
import { LoanTrancheFormProps } from './types';

import {
  isAdjustableRate,
  isFloatingRate,
  isLetterOfCredit,
  isMultiDraw,
  isRevolver,
  isSwingline,
} from './sections/utils';
import { AlertType, ID, LoanTrancheType } from 'types';
import { FormFrame, Tiler } from 'components';

import './MultiTrancheForm.scss';

/* eslint-disable react/no-multi-comp */

function RenderBaseFields(props: LoanTrancheFormProps) {
  return <BaseFields {...props} />;
}

function RenderDatesFields(props: LoanTrancheFormProps) {
  return <DatesFields {...props} />;
}

function RenderDetailsFields(props: LoanTrancheFormProps) {
  return <DetailsFields {...props} />;
}

function RenderCollateralFields(props: LoanTrancheFormProps) {
  return (
    <CollateralFieldsCollection
      {...(props as LoanTrancheFormProps & { newCollateralId: string })}
    />
  );
}

function RenderCovenantFields(props: LoanTrancheFormProps) {
  return <CovenantFields {...props} />;
}

function RenderEscrowFields(props: LoanTrancheFormProps) {
  return <EscrowFields {...props} />;
}

function RenderInterestFields(props: LoanTrancheFormProps) {
  return <InterestFields {...props} />;
}

function RenderFloorAndCapFields(props: LoanTrancheFormProps) {
  return <FloorAndCapFields {...props} />;
}

function RenderGuarantorFields(props: LoanTrancheFormProps) {
  return <GuarantorFieldsCollection {...props} />;
}

function RenderInterestElectionRulesFields(props: LoanTrancheFormProps) {
  return <InterestElectionRulesFields {...props} />;
}

function RenderNotesFields(props: LoanTrancheFormProps) {
  return <NotesFields {...props} />;
}

function RenderPaymentFields(props: LoanTrancheFormProps) {
  return <PaymentFields {...props} />;
}

function RenderPrincipalFields(props: LoanTrancheFormProps) {
  return <PrincipalFields {...props} />;
}

function RenderUnusedFeeFields(props: LoanTrancheFormProps) {
  return <UnusedFeeFields {...props} />;
}

type MultiProps = {
  letterOfCreditChargeableLoanTranches: Array<LoanTrancheType>;
  onAddNewTranche: () => void;
  onCancelEdit: () => void;
  onSubmit: () => void;
  setAlert: (message: string, type?: AlertType, title?: string) => void;
  tranches: Array<LoanTrancheFormProps>;
};

type State = { saving: boolean };

class MultiTrancheForm extends React.Component<MultiProps, State> {
  static _observer: () => void | null | undefined;

  static AddNewTranche = () => {
    if (MultiTrancheForm._observer) MultiTrancheForm._observer();
  };

  state = { saving: false };

  componentDidMount() {
    MultiTrancheForm._observer = () => {
      this.props.onAddNewTranche();
    };
  }

  componentWillUnmount() {
    MultiTrancheForm._observer = null;
  }

  onDeleteTranche = (id: ID) => {
    this.setState({ saving: true }, async () => {
      await this.handleSaveClick();
      await LoanTrancheMutations.deleteLoanTranche(id);
      this.setState({ saving: false });
    });
  };

  handleSave = async (isSubmit: boolean) => {
    try {
      const responses = await Promise.all(
        this.props.tranches.map(async e => e.onSave()),
      );
      const success = responses.every(response => response.success === true);
      if (success) {
        const { message, title } = alerts.saveTrancheSuccess;
        this.props.setAlert(message, 'success', title);
        if (isSubmit) {
          this.props.onSubmit();
        }
      }
    } catch (err) {
      const { message } = alerts.generalError;
      // eslint-disable-next-line
      console.error(err);
      this.props.setAlert(message, 'error');
    }
  };

  handleCancel = () => {
    Promise.all(this.props.tranches.map(e => e.onCancel()));
    this.props.onCancelEdit();
  };

  handleSaveClick: () => Promise<any> = () => this.handleSave(false);

  handleDoneClick: () => Promise<any> = () => this.handleSave(true);

  RenderMultiDrawRulesFields = (props: LoanTrancheFormProps) => (
    <MultiDrawRulesFields
      {...props}
      letterOfCreditChargeableLoanTranches={
        this.props.letterOfCreditChargeableLoanTranches
      }
    />
  );

  render() {
    const { tranches } = this.props;
    let isDirty = false;
    let loading = false;
    let { saving } = this.state;
    let anyLackingData = false;
    let disabled = true;
    let hasAdjustableRate = false;
    let hasFloatingRate = false;
    let hasLetterOfCredit = false;
    let hasNonLetterOfCredit = false;
    let hasMultiDraw = false;
    let hasRevolver = false;
    let hasSwingline = false;

    tranches.forEach(e => {
      if (!e.data || !e.data.id || e.loading) anyLackingData = true;
      if (e.isSaving) saving = true;
      if (e.loading) loading = true;
      if (e.isDirty) isDirty = true;
      if (!e.disabled) disabled = false;
      if (e.data && isMultiDraw(e.data)) hasMultiDraw = true;
      if (e.data && isFloatingRate(e.data)) hasFloatingRate = true;
      if (e.data && isAdjustableRate(e.data)) hasAdjustableRate = true;
      if (e.data && isLetterOfCredit(e.data)) hasLetterOfCredit = true;
      if (e.data && !isLetterOfCredit(e.data)) hasNonLetterOfCredit = true;
      if (e.data && isRevolver(e.data)) hasRevolver = true;
      if (e.data && isSwingline(e.data)) hasSwingline = true;
    });

    if (anyLackingData) {
      // otherwise row keys screw up and we get phantom cells
      return null;
    }

    return (
      <FormFrame
        disabled={disabled}
        formId="loanTranches"
        hasTabIndex
        isDirty={isDirty}
        loading={loading}
        onCancel={this.handleCancel}
        onSave={this.handleSaveClick}
        onSubmit={this.handleDoneClick}
        saving={saving}
        unstackable
      >
        <Tiler>
          <Tiler.Tile className="steel">
            <div className="SectionedForm-Rows">
              <TrancheRow
                handleDeleteTranche={this.onDeleteTranche}
                id="Basics"
                render={RenderBaseFields}
                tranches={tranches}
                undivided
              />
              <TrancheRow
                header="Principal/Balance"
                id="principal"
                render={RenderPrincipalFields}
                tranches={tranches}
              />
              <TrancheRow
                header="Dates"
                id="dates"
                render={RenderDatesFields}
                tranches={tranches}
              />
              <TrancheRow
                header="Interest"
                id="Interest"
                render={RenderInterestFields}
                tranches={tranches}
              />
              {hasFloatingRate && (
                <TrancheRow
                  header="Interest Election Rules"
                  id="InterestElectionRules"
                  render={RenderInterestElectionRulesFields}
                  tranches={tranches}
                />
              )}
              {(hasRevolver || hasSwingline || hasLetterOfCredit) && (
                <TrancheRow
                  header="Unused Fee"
                  id="UnusedFee"
                  render={RenderUnusedFeeFields}
                  tranches={tranches}
                />
              )}
              <TrancheRow
                header="Payments"
                id="payments"
                render={RenderPaymentFields}
                tranches={tranches}
              />
              {(hasFloatingRate || hasAdjustableRate) && (
                <TrancheRow
                  header="Floor & Cap"
                  id="FloorAndCap"
                  render={RenderFloorAndCapFields}
                  tranches={tranches}
                />
              )}
              <TrancheRow
                header="Collateral & Credit Enhancements"
                id="collaterals"
                render={RenderCollateralFields}
                tranches={tranches}
              />
              <TrancheRow
                header="Guarantors"
                id="guarantors"
                render={RenderGuarantorFields}
                tranches={tranches}
              />
              {hasNonLetterOfCredit && (
                <>
                  <TrancheRow
                    header="Risk Ratings"
                    id="Details"
                    render={RenderDetailsFields}
                    tranches={tranches}
                  />
                  <TrancheRow
                    header="Covenant Summary"
                    id="Covenant"
                    render={RenderCovenantFields}
                    tranches={tranches}
                  />
                </>
              )}
              {(hasLetterOfCredit ||
                hasMultiDraw ||
                hasRevolver ||
                hasSwingline) && (
                <TrancheRow
                  header="Draw/Repayment Rules"
                  id="MultiDrawRules"
                  render={this.RenderMultiDrawRulesFields}
                  tranches={tranches}
                />
              )}
              {hasNonLetterOfCredit && (
                <TrancheRow
                  header="Reserve & Escrow"
                  id="Escrow"
                  render={RenderEscrowFields}
                  tranches={tranches}
                />
              )}
              <TrancheRow
                id="notes"
                render={RenderNotesFields}
                tranches={tranches}
              />
            </div>
          </Tiler.Tile>
        </Tiler>
      </FormFrame>
    );
  }
}

export default MultiTrancheForm;
