/**
 * Checks if `expectedHash` is present in the current window.location.  If true,
 * scroll to the element with anm id of `expectedHash`.  if `scrollTargetId`
 * is specified, scroll to that element instead
 * @param {*} expectedHash - Expected location.hash
 * @param {*} scrollTargetId - Optionally scroll to this element instead of expectedHash
 */
function scrollToElementIfAnchored(
  expectedHash: string,
  scrollTargetId?: string,
  retryCount = 0,
) {
  if (expectedHash.startsWith('#')) {
    // eslint-disable-next-line
    console.warn("'expectedHash' should not contain the # prefix");
  }
  if (window.location.hash && window.location.hash === `#${expectedHash}`) {
    let targetId = scrollTargetId || window.location.hash;

    if (!targetId.startsWith('#')) {
      targetId = `#${targetId}`;
    }

    const hashElement = document.querySelector(targetId); // getElementById(targetId);

    if (hashElement) {
      hashElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    } else if (retryCount < 5) {
      setTimeout(() => {
        scrollToElementIfAnchored(expectedHash, scrollTargetId, retryCount + 1);
      }, 1000);
    } else {
      // eslint-disable-next-line
      console.warn(
        `Attempted auto-scroll for ${targetId} but failed to find element`,
      );
    }
  }
}

export default scrollToElementIfAnchored;
