import * as React from 'react';

import { AmortizationColumns } from './shared';
import PaymentRow from './createLoanTrancheAmortizationForm/PaymentRow';
import { Button, Money } from 'components';

import {
  LoanTrancheAmortizationPrincipalPaymentType,
  AmortizationReaderRefType,
} from 'types';

type Props = {
  disabled: boolean;
  finalPayment: LoanTrancheAmortizationPrincipalPaymentType;
  index: number;
  onApplyToFinalPayment: () => void;
  onPaymentChange: (
    value: string | null | undefined,
    fieldId: string,
    index: number,
  ) => void;
  readerData: AmortizationReaderRefType;
};

function AmortizationTableFooter(props: Props) {
  const {
    finalPayment,
    index,
    readerData,
    onPaymentChange,
    onApplyToFinalPayment,
    disabled,
  } = props;

  const principalMap =
    readerData && (readerData as any).principalMap
      ? (readerData as any).principalMap
      : {};

  const principalMapEntry =
    finalPayment.count && principalMap[finalPayment.count]
      ? principalMap[finalPayment.count]
      : null;

  const isOutOfBalance =
    readerData.outOfBalance !== undefined &&
    readerData.outOfBalance !== null &&
    +readerData.outOfBalance !== 0;

  return (
    <>
      <PaymentRow
        className="AmortizationFooter__FirstRow"
        disabled={disabled}
        errored={isOutOfBalance}
        index={index}
        onChange={onPaymentChange}
        payment={finalPayment}
        principalAfter={
          principalMapEntry ? principalMapEntry.principalAfter : ''
        }
        principalBefore={
          principalMapEntry ? principalMapEntry.principalBefore : ''
        }
      />
      <tr className="AmortizationFooter-Row">
        <td className={AmortizationColumns.count.className} />
        <td className={AmortizationColumns.date.className} />
        <td className={AmortizationColumns.beginningBalance.className}>
          Total
        </td>
        <td className={AmortizationColumns.interestAmount.className} />
        <td className={AmortizationColumns.principalPayment.className}>
          <span>$</span>{' '}
          {PaymentRow.stRenderMoneyCell(readerData.totalPrincipal)}
        </td>
        <td className={AmortizationColumns.endingBalance.className} />
        <td className={AmortizationColumns.errorColumn.className} />
      </tr>
      <tr className="AmortizationFooter-Row">
        <td className={AmortizationColumns.count.className} />
        <td className={AmortizationColumns.date.className} />
        <td className={AmortizationColumns.beginningBalance.className}>
          Out of Balance
        </td>
        <td className={AmortizationColumns.interestAmount.className} />
        <td className={AmortizationColumns.principalPayment.className}>
          {isOutOfBalance ? (
            <Money decimalPlaces={2} value={readerData.outOfBalance} />
          ) : (
            '-'
          )}
        </td>
        <td className={AmortizationColumns.endingBalance.className}>
          <Button.Text
            disabled={props.disabled || !isOutOfBalance}
            label="Apply to final payment"
            onClick={onApplyToFinalPayment}
          />
        </td>
        <td className={AmortizationColumns.errorColumn.className} />
      </tr>
    </>
  );
}

export default AmortizationTableFooter;
