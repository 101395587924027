import { gql } from '@apollo/client';
import { DocumentNode } from '../types';

/* eslint-disable */
const RevealValueQueryDefinition: DocumentNode = gql`
  query RevealValueQuery($id: ID, $cacheBuster: String) {
    revealValue(id: $id, cacheBuster: $cacheBuster) {
      name
      value
    }
  }
`;

export default RevealValueQueryDefinition;
