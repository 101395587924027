import { BaseType } from 'types';

const typeNameField = '__typename';
const input = 'Input';

/* eslint-disable import/prefer-default-export */

/**
 * Recursively Converts a standard `Type` object to an `Input`-Ish object (e.g. DealType => DealInput)
 * @param {*} source
 */
export function convertToInput<TIn extends BaseType, TOut extends BaseType>(
  source: TIn,
): TOut {
  if (source[typeNameField] && source[typeNameField].indexOf(input) >= 0) {
    // this is already an Input type
    return (source as any) as TOut;
  }
  const res = {};
  const isStub = !!(source && (source as any).shouldStub);
  /*
    source && source[typeNameField]
      ? source[typeNameField].startsWith(StubPrefix)
      : false;
*/
  Object.keys(source).forEach(k => {
    const value = source[k];
    if (!value) {
      res[k] = value;
      return;
    }

    if (Array.isArray(value)) {
      // recur!
      res[k] = (value as Array<any>)
        .filter(child => typeof child === 'object')
        .map(child => convertToInput(child));
    } else if (!isStub && typeof value === 'object') {
      // covert to Id property
      // prettier clobbers this next line
      // eslint-disable-next-line
      const idPropertyName = k + 'Id';

      res[idPropertyName] = value.id;
    } else if (isStub && typeof value === 'object') {
      res[k] = convertToInput(value);
    } else {
      // string, number, date, etc.
      res[k] = value;
    }
  });

  if (source[typeNameField] && source[typeNameField].indexOf('Input') < 0) {
    res[typeNameField] = source[typeNameField].replace('Type', 'Input');
  }

  return (res as any) as TOut;
}

/**
 * Strictly converts a `Type` object to an `Input` object
 * - Child objects are removed and replaced with an Id property
 * - Collection properties are discarded
 * - __typename is converted
 * - Shallow properties are preserved
 * @param {*} source
 */
export function strictConvertToInput<
  TIn extends BaseType,
  TOut extends BaseType
>(source: TIn): TOut {
  if (source[typeNameField] && source[typeNameField].indexOf(input) >= 0) {
    // this is already an Input type
    return (source as any) as TOut;
  }
  const res = {};

  /*
    source && source[typeNameField]
      ? source[typeNameField].startsWith(StubPrefix)
      : false;
*/
  Object.keys(source).forEach(k => {
    const value = source[k];
    if (!value) {
      res[k] = value;
      return;
    }

    if (typeof value === 'object') {
      // covert to Id property
      // prettier clobbers this next line
      // eslint-disable-next-line
      const idPropertyName = k + 'Id';

      res[idPropertyName] = value.id;
    } else {
      // string, number, date, etc.
      res[k] = value;
    }
  });

  if (source[typeNameField] && source[typeNameField].indexOf('Input') < 0) {
    res[typeNameField] = source[typeNameField].replace('Type', 'Input');
  }

  return (res as any) as TOut;
}
