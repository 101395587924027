import * as React from 'react';
import DebugBar from '../DebugBar';
import BorrowerStepFactory from './borrowerSteps/BorrowerStepFactory';
import LenderStepFactory from './lenderSteps/LenderStepFactory';
import GenericWorkflowHeader from './GenericWorkflowHeader';
import { DealClosingWorkflowType, ID, DealType } from 'types';
import { Tiler } from 'components';

interface Props {
  dealClosingWorkflow: DealClosingWorkflowType;
  borrower: boolean;
  dealId?: ID;

  deal: DealType;
}

function GenericWorkflow(props: Props) {
  const { dealClosingWorkflow, borrower, dealId, deal } = props;

  return (
    <Tiler>
      <Tiler.Tile
        className="DealClosingWorkflow__Wrapper steel"
        minWidth="OneThird"
        width="Half"
      >
        <DebugBar dealClosingWorkflow={dealClosingWorkflow} />
        {dealClosingWorkflow.state && (
          <GenericWorkflowHeader
            dealClosingWorkflow={dealClosingWorkflow}
            dealId={dealId}
          />
        )}
        <div className="DealClosingWorkflow">
          {dealClosingWorkflow.steps.map(step => {
            const canEdit = step.enabled;
            // States available in the future are dimmed
            const isDimmed = !canEdit && !step.isComplete;

            if (borrower) {
              return (
                <BorrowerStepFactory
                  canEdit={canEdit}
                  deal={deal}
                  dealClosingWorkflow={dealClosingWorkflow}
                  dealId={dealId}
                  isDimmed={isDimmed}
                  key={step.slug}
                  step={step}
                />
              );
            }
            return (
              <LenderStepFactory
                canEdit={canEdit}
                deal={deal}
                dealClosingWorkflow={dealClosingWorkflow}
                dealId={dealId}
                isDimmed={isDimmed}
                key={step.slug}
                step={step}
              />
            );
          })}
        </div>
      </Tiler.Tile>
    </Tiler>
  );
}

export default GenericWorkflow;
