import { SortBy } from 'types';

export const SET_VIEW_RANGE = 'calendar_SET_VIEW_RANGE';
export const TOGGLE_WEEKENDS = 'calendar_TOGGLE_WEEKENDS';
export const TOGGLE_LIST_VIEW = 'calendar_TOGGLE_LIST_VIEW';
export const SORT_BY = 'calendar_SORT_BY';
export const TOGGLE_SORT_FLIP = 'calendar_TOGGLE_SORT_FLIP';
export const TOGGLE_FILTER_CATEGORY = 'calendar_TOGGLE_FILTER_CATEGORY';
export const RESET_FILTERS = 'calendar_RESET_FILTERS';

export function setViewRange(viewRange: string) {
  return {
    type: SET_VIEW_RANGE,
    payload: {
      viewRange,
    },
  };
}
export function toggleWeekends() {
  return {
    type: TOGGLE_WEEKENDS,
  };
}

export function toggleListView() {
  return {
    type: TOGGLE_LIST_VIEW,
  };
}

export function changeSortField(payload: SortBy) {
  return {
    type: SORT_BY,
    payload,
  };
}
export function toggleFilterCategory(payload: string) {
  return {
    type: TOGGLE_FILTER_CATEGORY,
    payload,
  };
}
export function resetFilters() {
  return {
    type: RESET_FILTERS,
  };
}

export type Action = { payload: Record<string, any>; type: string };
