import { fromJS } from 'immutable';
import treeWalker from '../../../graphql/mutationsDispatch/utils/treeWalker';
import { getKeyPath } from '../methods';

import { ToggleDirtyFlagAction } from '../types';
import { invariant } from 'utils';

/* eslint-disable import/prefer-default-export */

function filterProperty(root: Record<string, any>, isDirty: boolean) {
  const res: { isDirty?: boolean } = {};

  Object.keys(root).forEach(key => {
    if (!root[key]) {
      res[key] = root[key];
    } else if (Array.isArray(root[key])) {
      res[key] = root[key].map(child => filterProperty(child, isDirty));
    } else if (typeof root[key] === 'object') {
      // object
      res[key] = filterProperty(root[key], isDirty);
    } else {
      res[key] = root[key];
    }
  });

  res.isDirty = isDirty === true ? true : undefined;
  return res;
}

function isStillDirty(tree: Record<string, any>): boolean {
  let res = false;

  treeWalker(tree, (e: any) => {
    if (e.isDirty === true) {
      res = true;
    }
  });

  return res;
}

/**
 * Examine a GraphQL Mutation response object and populate state appropriately
 * If the reponse contained errors, these will populate into the 'errors' property,
 * otherwise 'data' will be refreshed.
 * @param {*} state
 * @param {*} action
 */
export function handleToggleDirtyFlag(
  state: any, // This is an Immutable version of FormReduxState<T>
  action: ToggleDirtyFlagAction,
): Record<string, any> {
  const { keyPath, isDirty, recursive } = action.payload;

  const rootPath = getKeyPath(keyPath);

  invariant(
    state.hasIn(rootPath),
    `Failed to find keyPath ${rootPath.join(', ')}`,
  );

  let updatedState;
  if (!recursive) {
    updatedState = state.setIn([...rootPath, 'isDirty'], isDirty || undefined);
    // simple case
  } else {
    const objectTree = state.getIn(rootPath).toJS();

    const res = filterProperty(objectTree, isDirty);

    updatedState = state.setIn(rootPath, fromJS(res));
  }

  const stillDirty = isStillDirty(updatedState.toJS().data);

  return updatedState.set('isDirty', stillDirty);
}
