import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { TabNavigationFrame } from '@loanstreet-usa/design-system';
import RouteTo from '../routeTo/RouteTo';
import { RoutingContext, RoutePortal } from 'routing';

export interface TabItemType {
  text: string;
  to?: string;
}

interface Props {
  tabs: Array<TabItemType>;
  routeContext: RoutingContext;
}

export default function TabNavigationFrameWrapper(props: Props) {
  const { tabs, routeContext } = props;
  const location = useLocation();

  return (
    <div className="DealPeopleView">
      <TabNavigationFrame as={RouteTo} location={location} tabs={tabs}>
        <RoutePortal context={routeContext} />
      </TabNavigationFrame>
    </div>
  );
}
