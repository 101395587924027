import * as React from 'react';
import InvitationGuard, { InvitationGuardChildProps } from './InvitationGuard';

import ExpiredInvitation from './expiredInvitation/ExpiredInvitation';
import UsedInvitation from './usedInvitation/UsedInvitation';
import InvalidInvitation from './invalidInvitation/InvalidInvitation';

import AcceptInviteForm from './acceptInviteForm/AcceptInviteForm';
import InvitationContentFrame from './InvitationContentFrame';
import Splash from './splash/Splash';
import { invariant } from 'utils';
import { PublicInvitationType } from 'types';

type Props = Readonly<{
  fetchPublicInvitationType: () => Promise<any>;
  invitation: PublicInvitationType | null | undefined;
  invitationKey: string;
}>;

export default class InvitationFork extends React.Component<Props> {
  renderAcceptInviteForm = (childProps: InvitationGuardChildProps) => {
    const { invitation, invitationKey, fetchPublicInvitationType } = this.props;
    invariant(invitation, 'Expected invitation');

    return (
      <AcceptInviteForm
        {...childProps}
        canRequestAccess={childProps.isSharable}
        invitation={invitation}
        invitationKey={invitationKey}
        refetchInvitation={fetchPublicInvitationType}
        successRoute="Unknown Route"
      />
    );
  };

  renderInvitation = (childProps: InvitationGuardChildProps) => {
    const { invitation } = this.props;

    invariant(invitation, 'Expected invite');

    if (invitation.isExpired) {
      return (
        <InvitationContentFrame
          actionContent={<ExpiredInvitation />}
          informationalContent={<Splash />}
        />
      );
    }
    if (!invitation.isUsable) {
      return (
        <InvitationContentFrame
          actionContent={<UsedInvitation />}
          informationalContent={<Splash />}
        />
      );
    }

    return (
      <InvitationContentFrame
        actionContent={this.renderAcceptInviteForm(childProps)}
        informationalContent={<Splash />}
      />
    );
  };

  render() {
    const { invitation } = this.props;

    if (!invitation) {
      return <InvalidInvitation />;
    }

    return (
      <InvitationGuard invitation={invitation}>
        {this.renderInvitation}
      </InvitationGuard>
    );
  }
}
