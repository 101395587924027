import * as React from 'react';

import { EnumerationValue, Value } from './types';

/**
 * A dropdown input for the data grid.
 */

type Props = {
  /**
   * The enumeration of possible values for the dropdown.
   */
  defaultEnumerationValue?: string;
  /**
   * The default value of the dropdown, if any.
   */
  enumeration?: Array<EnumerationValue>;
  /**
   * The currently selected value of the dropdown.
   */
  onCancel?: (x0: void) => void;
  /**
   * The callback issued when canceling a dropdown edit.
   */
  onValueChanged?: (x0: Value) => void;
  /**
   * The callback issued when the dropdown value changes.  Dropdown
   * is a controlled component, so this callback is responsible
   * for updating the value passed into this component if the
   * value is allowed to change.
   */
  value?: string;
};

type State = {
  value?: string;
};

class DataGridDropdownInput extends React.Component<Props, State> {
  static defaultProps = {
    enumeration: [],
    defaultEnumerationValue: '',
    value: '',
    onCancel: () => undefined,
    onValueChanged: () => undefined,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value || props.defaultEnumerationValue,
    };
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    if (this.props.value === props.value) return;
    this.setState({
      value: props.value || props.defaultEnumerationValue,
    });
  }

  handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    this.setState({
      value: e.currentTarget.value,
    });
  };

  handleOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.handleOnPressEnter();
    } else if (e.key === 'Escape') {
      this.handleOnCancel();
    }
  };

  handleOnCancel() {
    this.setState({
      value: this.props.value,
    });
    if (this.props.onCancel) this.props.onCancel();
  }

  handleOnPressEnter() {
    const { value = '' } = this.state;
    if (this.props.onValueChanged) this.props.onValueChanged(value);
  }

  render() {
    const { enumeration = [] } = this.props;
    const options = enumeration.map(({ text, value }) => (
      <option key={value} value={value}>
        {text}
      </option>
    ));
    return (
      <div className="DataGridDropdownInput" onKeyUp={this.handleOnKeyUp}>
        <select onChange={this.handleOnChange} value={this.state.value}>
          {options}
        </select>
      </div>
    );
  }
}

export default DataGridDropdownInput;
