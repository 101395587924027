import { mutationBuilder } from './builders';
import {
  CreateSbaPppApplicationResubmitRequestMutationDefinition,
  SetSbaPppApplicationResubmitRequestMutationDefinition,
} from './definitions';
import {
  SbaPppApplicationResubmitRequestInput,
  SbaPppApplicationResubmitRequestType,
} from 'types';

const SbaPppApplicationResubmitRequestMutationMethods = {
  createSbaPppApplicationResubmitRequest: mutationBuilder<
    SbaPppApplicationResubmitRequestInput,
    SbaPppApplicationResubmitRequestType
  >(CreateSbaPppApplicationResubmitRequestMutationDefinition, {
    build: mutate => sbaPppApplicationResubmitRequestData =>
      mutate({ variables: { sbaPppApplicationResubmitRequestData } }),
  }),
  setSbaPppApplicationResubmitRequest: mutationBuilder<
    SbaPppApplicationResubmitRequestInput,
    SbaPppApplicationResubmitRequestType
  >(SetSbaPppApplicationResubmitRequestMutationDefinition, {
    build: mutate => sbaPppApplicationResubmitRequestData =>
      mutate({ variables: { sbaPppApplicationResubmitRequestData } }),
  }),
};

export default SbaPppApplicationResubmitRequestMutationMethods;
