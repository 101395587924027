import * as React from 'react';
import { Header, Text, Form } from 'components';
import './InstructionsPopup.scss';

const InstructionsPopup = () => {
  return (
    <div className="RoundOneInstructionsWrapper">
      <div className="Instructions">
        <Header as="h1" className="bold ink">
          SBA PPP Loan Amount Instructions
        </Header>
        <Form.Divider />
        <Header as="h4" className="bold ink">
          The following methodology, which is one of the methodologies contained
          in the Act, will be most useful for many applicants.
        </Header>
        <Text className="steel">
          Step 1: Aggregate payroll costs (defined in detail below, in{' '}
          <em>What qualifies as “payroll costs?”</em>) from the last twelve
          months for employees whose principal place of residence is the United
          States.
        </Text>
        <Text className="steel">
          Step 2: Subtract any compensation paid to an employee in excess of an
          annual salary of $100,000 and/or any amounts paid to an independent
          contractor or sole proprietor in excess of $100,000 per year.
        </Text>
        <Text className="steel">
          Step 3: Calculate average monthly payroll costs (divide the amount
          from Step 2 by 12).
        </Text>

        <Text className="steel">
          Step 4: Multiply the average monthly payroll costs from Step 3 by 2.5.
        </Text>
        <Text className="steel">
          Step 5: Add the outstanding amount of an Economic Injury Disaster Loan
          (EIDL) made between January 31, 2020 and April 3, 2020, less the
          amount of any “advance” under an EIDL COVID-19 loan (because it does
          not have to be repaid).
        </Text>
        <Header as="h4" className="bold ink">
          The examples below illustrate this methodology.
        </Header>
        <Text className="steel italic">
          Example 1 – No employees make more than $100,000
        </Text>
        <Text className="steel">
          Annual payroll: $120,000
          <br />
          Average monthly payroll: $10,000
          <br />
          Multiply by 2.5 = $25,000
          <br />
          Maximum loan amount is $25,000
        </Text>
        <Text className="steel italic">
          Example 2 – Some employees make more than $100,000
        </Text>
        <Text className="steel">
          Annual payroll: $1,500,000
          <br />
          Subtract compensation amounts in excess of an annual salary of
          $100,000: $1,200,000
          <br />
          Average monthly qualifying payroll: $100,000
          <br />
          Multiply by 2.5 = $250,000
          <br />
          Maximum loan amount is $250,000
        </Text>
        <Text className="steel italic">
          Example 3 – No employees make more than $100,000, outstanding EIDL
          loan of $10,000
        </Text>
        <Text className="steel">
          Annual payroll: $120,000
          <br />
          Average monthly payroll: $10,000
          <br />
          Multiply by 2.5 = $25,000
          <br />
          Add EIDL loan of $10,000 = $35,000
          <br />
          Maximum loan amount is $35,000
        </Text>
        <Text className="steel italic">
          Example 4 – Some employees make more than $100,000, outstanding EIDL
          loan of $10,000
        </Text>
        <Text className="steel">
          Annual payroll: $1,500,000
          <br />
          Subtract compensation amounts in excess of an annual salary of
          $100,000: $1,200,000
          <br />
          Average monthly qualifying payroll: $100,000
          <br />
          Multiply by 2.5 = $250,000
          <br />
          Add EIDL loan of $10,000 = $260,000
          <br />
          Maximum loan amount is $260,000
        </Text>
        <Header as="h4" className="bold ink">
          What qualifies as “payroll costs?”
        </Header>
        <Text className="steel">
          Payroll costs consist of compensation to employees (whose principal
          place of residence is the United States) in the form of salary, wages,
          commissions, or similar compensation; cash tips or the equivalent
          (based on employer records of past tips or, in the absence of such
          records, a reasonable, good-faith employer estimate of such tips);
          payment for vacation, parental, family, medical, or sick leave;
          allowance for separation or dismissal; payment for the provision of
          employee benefits consisting of group health care coverage, including
          insurance premiums, and retirement; payment of state and local taxes
          assessed on compensation of employees; and for an independent
          contractor or sole proprietor, wage, commissions, income, or net
          earnings from self-employment or similar compensation.
        </Text>
        <Header as="h4" className="bold ink">
          Is there anything that is expressly excluded from the definition of
          payroll costs?
        </Header>
        <Text className="steel">
          Yes. The Act expressly excludes the following:
        </Text>
        <Text className="steel">
          i. Any compensation of an employee whose principal place of residence
          is outside of the United States;
        </Text>
        <Text className="steel">
          ii. The compensation of an individual employee in excess of an annual
          salary of $100,000, prorated as necessary;
        </Text>
        <Text className="steel">
          iii. Federal employment taxes imposed or withheld between February 15,
          2020 and June 30, 2020, including the employee’s and employer’s share
          of FICA (Federal Insurance Contributions Act) and Railroad Retirement
          Act taxes, and income taxes required to be withheld from employees;
          and
        </Text>
        <Text className="steel">
          iv. Qualified sick and family leave wages for which a credit is
          allowed under sections 7001 and 7003 of the Families First Coronavirus
          Response Act (Public Law 116–127).
        </Text>

        <Header as="h4" className="bold ink">
          Do independent contractors count as employees for purposes of PPP loan
          calculations?
        </Header>
        <Text className="steel">
          No, independent contractors have the ability to apply for a PPP loan
          on their own so they do not count for purposes of a borrower’s PPP
          loan calculation.
        </Text>
      </div>
    </div>
  );
};

export default InstructionsPopup;
