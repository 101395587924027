import * as React from 'react';
import SecureMenuItem from './SecureMenuItem';
import { FontAwesomeIconTypes } from 'components';
import { RouteTable } from 'routing/RouteTable';
import { GlobalPerspectivePermissions } from 'security';

const chartBarIcon: FontAwesomeIconTypes = ['fal', 'chart-bar'];

type Props = {
  location: string;
};

function ReportsMenuItem(props: Props) {
  return (
    <SecureMenuItem
      activePath="reports"
      className="MenuItem--Reports"
      icon={chartBarIcon}
      location={props.location}
      permission={GlobalPerspectivePermissions.view_home_button}
      route={RouteTable.application.toReports}
      title="Reports"
    />
  );
}

export default ReportsMenuItem;
