import * as React from 'react';
import MultiTrancheForm from '../../../../loanTrancheForm/MultiTrancheForm';
import { Button } from 'components';
import { DealPerspectivePermissions, MountGuard } from 'security';

function LoanTrancheActionButtons() {
  function handleOnClick() {
    MultiTrancheForm.AddNewTranche();
  }

  return (
    <MountGuard
      permission={DealPerspectivePermissions.administer_loan_tranches}
    >
      <div className="ActionButtons ActionButtons--LoanTranche">
        <Button label="Add Tranche" onClick={handleOnClick} />
      </div>
    </MountGuard>
  );
}

export default LoanTrancheActionButtons;
