import * as React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { compose } from 'recompose';
import AlertSystem from './AlertSystem';
import { ReduxDirectory } from 'lsredux';
import { clearAlert } from 'lsredux/actions/alerts';
import { AlertEntityType } from 'types';

type Props = {
  alerts: Array<AlertEntityType>;
  clearAlert: (id: string) => void;
};

// TODO: Replace with HOC toJS Wrapper
function ImmutableConversion(props: Props) {
  const location = useLocation();
  return <AlertSystem {...props} location={location} />;
}

const mapStateToProps = state => ({
  alerts: state.getIn(ReduxDirectory.AlertsKeyPath).toJS(),
});

const mapDispatchToProps = { clearAlert };

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ImmutableConversion,
);
