export { Auth0Provider, Auth0Context, useAuth0 } from './auth0';

export { default as PermissionPerspective } from './authorization/PermissionPerspective';

export { default as GlobalPermissionPerspective } from './authorization/GlobalPermissionPerspective';
export {
  GlobalPermissionPerspectiveContext,
  useGlobalPermissionPerspective,
} from './authorization/GlobalPermissionPerspective';
export { default as Secured } from './authorization/Secured';
export { MountGuard } from './authorization/MountGuard';
export { AccessGuard } from './authorization/AccessGuard';
export { AccessDeniedGuard } from './authorization/AccessDeniedGuard';
export { default as PermissionsGuard } from './authorization/PermissionsGuard';

export * from './authorization/types';

export {
  CheckAuthentication,
  executeLogout,
} from './authentication/authMethods';

/* Permission Rule Definitions */
export {
  DealPerspectivePermissions,
  DealPerspectivePermissionSets,
} from './authorization/definitions/Deal';
export { default as FolderPerspectivePermissions } from './authorization/definitions/Folder';
export { default as GlobalPerspectivePermissions } from './authorization/definitions/Global';
export { default as InstitutionPerspectivePermissions } from './authorization/definitions/Institution';

/* eslint-enable */

/* Types */
export * from './authorization/definitions/types';
export * from './authorization/Secured';
