import { mutationBuilder } from './builders';
import {
  CreateLoanTrancheAdjustableRateDataMutationDefinition,
  SetLoanTrancheAdjustableRateDataMutationDefinition,
  DeleteLoanTrancheAdjustableRateDataMutationDefinition,
} from './definitions';
import {
  LoanTrancheAdjustableRateDataType,
  LoanTrancheAdjustableRateDataInput,
  ID,
} from 'types';

const LoanTrancheAdjustableRateDataMutationMethods = {
  createLoanTrancheAdjustableRateData: mutationBuilder<
    LoanTrancheAdjustableRateDataType,
    LoanTrancheAdjustableRateDataInput
  >(CreateLoanTrancheAdjustableRateDataMutationDefinition, {
    build: mutate => loanTrancheAdjustableRateDataData =>
      mutate({ variables: { loanTrancheAdjustableRateDataData } }),
  }),
  setLoanTrancheAdjustableRateData: mutationBuilder<
    LoanTrancheAdjustableRateDataInput,
    LoanTrancheAdjustableRateDataType
  >(SetLoanTrancheAdjustableRateDataMutationDefinition, {
    build: mutate => loanTrancheAdjustableRateDataData =>
      mutate({ variables: { loanTrancheAdjustableRateDataData } }),
  }),
  deleteLoanTrancheAdjustableRateData: mutationBuilder<ID, void>(
    DeleteLoanTrancheAdjustableRateDataMutationDefinition,
    {
      build: mutate => (id: string) => mutate({ variables: { id } }),
    },
  ),
};

export default LoanTrancheAdjustableRateDataMutationMethods;
