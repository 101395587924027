import React, { useState } from 'react';
import noop from 'lodash/noop';
import cx from 'classnames';
import {
  IconButton,
  Input,
  Icon,
  getFileIconName,
  Toggle,
  Form,
  FontAwesomeIconTypes,
  WrappedMediatorFormProps,
  Divider,
  Header,
} from 'components';
import {
  EventScheduleInput,
  MyUserType,
  DealInstitutionType,
  FormFieldsType,
} from 'types';

import { pascalCase } from 'utils';

type Props = WrappedMediatorFormProps<EventScheduleInput> & {
  dealOrganizations: ReadonlyArray<DealInstitutionType>;
  setStagedFiles: (files: Array<File>) => void;
  stagedFiles: Array<File>;
  user: MyUserType;
};

const width = '188px';
const timesIcon: FontAwesomeIconTypes = ['fal', 'times'];
const objectType = 'EventSchedule';

const fields: FormFieldsType = {
  assignedPeople: {
    id: 'assignedPeople',
    multiple: true,
    allowEmpty: false,
    propertyName: 'assignedPeople',
    width,
  },
};

function CalendarTaskFormSidebar(props: Props) {
  const { stagedFiles, setStagedFiles, data, mutateProperty } = props;
  const [searchQuery, setSearchQuery] = useState('');

  function handleStageFile(e: React.SyntheticEvent<HTMLInputElement>) {
    const newFiles: File[] = Array.from((e.currentTarget as any).files).filter(
      newFile =>
        !stagedFiles.some(
          stagedFile => stagedFile.name === (newFile as any).name,
        ),
    ) as any;
    setStagedFiles([...stagedFiles, ...newFiles]);
  }

  function handleShared(e: React.ChangeEvent<HTMLInputElement>) {
    const { target } = e;
    if (target instanceof window.HTMLInputElement) {
      mutateProperty(!data.shared, 'shared');
    }
  }

  function handleRemoveStagedFile(idx) {
    return function removeStagedFile() {
      const newFiles = [...stagedFiles];
      newFiles.splice(idx, 1);
      setStagedFiles(newFiles);
    };
  }

  function handleSetSearchQuery(e: React.KeyboardEvent<any>) {
    setSearchQuery(e.currentTarget.value);
  }

  function getAssigneeOptions() {
    const { dealOrganizations, user } = props;
    if (!dealOrganizations) return [];
    return dealOrganizations.reduce(
      (final, dealOrganization) => [
        ...final,
        ...((dealOrganization.dealUsers &&
          dealOrganization.dealUsers
            .map(dealUser => {
              if (dealUser.id !== user.id) {
                return {
                  text: dealUser.fullName || '',
                  value: dealUser.id || '',
                };
              }
              return null;
            })
            .filter(Boolean)) ||
          []),
      ],
      [],
    );
  }

  function renderStagedFiles() {
    if (!stagedFiles.length) return null;

    return (
      <ul className="calendarTaskFormSidebar__StagedFiles">
        {stagedFiles.reduce((final, file, idx) => {
          const { name } = file;
          if (name && name.includes(searchQuery)) {
            const fileIconName = getFileIconName(name);
            const className = cx(
              'calendarTaskFormSidebar__FileIcon',
              `calendarTaskFormSidebar__FileIcon--${pascalCase(fileIconName)}`,
            );
            final.push(
              <li className="calendarTaskFormSidebar__StagedFile" key={name}>
                <Icon className={className} icon={fileIconName} />
                <span className="calendarTaskFormSidebar__StagedFileName">
                  {name}
                </span>
                <IconButton
                  alt="Remove File"
                  className="calendarTaskFormSidebar__RemoveFileButton"
                  color="steel"
                  icon={timesIcon}
                  onClick={handleRemoveStagedFile(idx)}
                />
              </li>,
            );
          }
          return final;
        }, [])}
      </ul>
    );
  }

  return (
    <div className="calendarTaskFormSidebar">
      <Divider />
      <Header as="h5" className="caps">
        Assignees
      </Header>
      <Divider className="transparent" />

      <Form
        className="calendarTaskForm__Main"
        id="calendarTaskForm"
        onSubmit={noop}
      >
        {Form.FieldRenderer(
          Form.Select,
          {
            ...fields.assignedPeople,
            options: getAssigneeOptions(),
            value: data.assignedPeople || [],
            onChange: mutateProperty,
          },
          props,
          objectType,
          data.id,
        )}
      </Form>
      <Toggle
        checked={Boolean(data.shared)}
        className="calendarTaskFormSidebar__SharedToggle"
        id="calendarTaskFormSidebar__SharedToggle"
        label="Shared Event"
        onChange={handleShared}
      />
      <Divider />
      <Header as="h5" className="caps">
        Files
      </Header>
      <Divider className="transparent" />
      <Input
        className="calendarTaskFormSidebar__FileSearchInput"
        id="calendarTaskFormSidebar__FileSearch"
        name="calendarTaskFormSidebar__FileSearch"
        onChange={noop}
        onKeyUp={handleSetSearchQuery}
        placeholder="Search Files"
        value={searchQuery}
        width={width}
      />
      {renderStagedFiles()}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        className="calendarTaskFormSidebar__UploadButton ui button lsButton lsAction"
        htmlFor="calendarTaskFormSidebar__FileUpload"
      >
        Upload Files
      </label>
      <input
        className="FolderRow__UploadInput"
        id="calendarTaskFormSidebar__FileUpload"
        multiple
        name="selectFiles"
        onChange={handleStageFile}
        type="file"
        value=""
      />
    </div>
  );
}

export default CalendarTaskFormSidebar;
