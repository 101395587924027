import { MutationMethod } from '../../types';
import { invariant } from 'utils';

/**
 * Enforce that mutationMethods contains a valid property
 * with the name specified by `actionType`.  If the property
 * does not exist, an error is raised and reported
 * @param {*} mutationMethods
 * @param {*} actionType
 * @param {*} typename
 */
export default function enforceMutationPresence(
  mutationMethod: MutationMethod<any, any>,
  actionType: 'create' | 'set' | 'stub',
  typename: string,
) {
  if (!mutationMethod) {
    const msg = `Failed to find ${actionType} mutation for ${typename}`;
    // eslint-disable-next-line
    console.error(msg);

    // trigger exception
    invariant(mutationMethod, msg);
  }
}
