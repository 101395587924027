import { mutationBuilder } from './builders';
import {
  CreateSbaPppInstitutionMutationDefinition,
  SetSbaPppInstitutionMutationDefinition,
} from './definitions';
import { SbaPppInstitutionInput, SbaPppInstitutionType } from 'types';

const PrivateSbaPppInstitutionMutationMethods = {
  createSbaPppInstitution: mutationBuilder<
    SbaPppInstitutionInput,
    SbaPppInstitutionType
  >(CreateSbaPppInstitutionMutationDefinition, {
    build: mutate => sbaPppInstitutionData =>
      mutate({ variables: { sbaPppInstitutionData } }),
  }),
  setSbaPppInstitution: mutationBuilder<
    SbaPppInstitutionInput,
    SbaPppInstitutionType
  >(SetSbaPppInstitutionMutationDefinition, {
    build: mutate => sbaPppInstitutionData => {
      let data = sbaPppInstitutionData;
      if (data.businessTinEinSsn && data.businessTinEinSsn.includes('*')) {
        data = { ...data, businessTinEinSsn: undefined };
      }
      return mutate({ variables: { sbaPppInstitutionData: data } });
    },
  }),
};

export default PrivateSbaPppInstitutionMutationMethods;
