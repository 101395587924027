// eslint-disable-next-line
import * as React from 'react';

/* eslint-disable */
import {
  DropTarget,
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
} from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import HTML5Backend from 'react-dnd-html5-backend';

/* eslint-enable */

import { createDragSourceEntity } from '../../utils/dragDropMethods';
import { DropTypeValues, FolderWithDate } from '../../types';

import { DataRoomRowProps } from '../../dataroomRow/DataroomRow';
import { invariant } from 'utils';

const FolderDragSource = DragSource(
  DropTypeValues.FolderType,
  {
    beginDrag(props: DataRoomRowProps<FolderWithDate>) {
      invariant(
        props.entity,
        'FolderDragSource beginDrag received an undefined entity',
      );
      // This component is now being dragged, create the DragSourceEntity
      // object that will be passed to DropTargets
      return createDragSourceEntity(props.entity);
    },
    endDrag(props) {
      (props as any).leaveDragHover();
    },
    canDrag(props: DataRoomRowProps<FolderWithDate>) {
      return props.accessDenied !== true;
    },
  },
  (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }),
);

export default FolderDragSource;
