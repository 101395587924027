import React from 'react';
import { StateMap } from './Resources';
import {
  SbaPppForgivenessWorkflowType,
  SbaPppForgivenessWorkflowState,
} from 'types';
import { Button } from 'components';
import transitionForgivenessWorkflowMutation from 'lsgql/mutations/TransitionForgivenessWorkflow';
import { invariant } from 'utils';

export type TransitionButtonProps = {
  workflow: SbaPppForgivenessWorkflowType;
  targetState: SbaPppForgivenessWorkflowState;
  label: string;
  buttonStyle?: 'Primary' | 'Secondary';
  successToast?: string;
  errorToast?: string;
};

/**
 * A button that encapsulates transitioning between the different states of
 * the forgiveness workflow.  Will verify that the provided targetState
 * is a valid next state for the forgiveness worklow, and appropriately
 * set the disabled prop
 *
 * TODO: Would be nice to add the workflow query here as well so that
 * it does not need to be passed in
 */
export function TransitionButton(props: TransitionButtonProps) {
  const {
    targetState,
    workflow,
    label,
    buttonStyle = 'Primary',
    successToast,
    errorToast,
  } = props;

  invariant(targetState in StateMap, `Unknown state ${targetState}`);
  const enabled =
    workflow.validTransitions.includes(targetState) && workflow.canEdit;

  return (
    <Button
      disabled={!enabled}
      label={label}
      onClick={() =>
        transitionForgivenessWorkflowMutation({
          workflowId: workflow.id,
          state: targetState,
          successToast: successToast,
          errorToast: errorToast,
        })
      }
      style={buttonStyle}
    />
  );
}
