import noop from 'lodash/noop';

export const PAYROLL_REQUIREMENT = 0.6;

const width = '158px';

const disabledProps = {
  disabled: true,
  disabledReadable: true,
  onChange: noop,
};

export const fields = {
  averageFteFteReduction: {
    id: 'averageFteFteReduction',
    propertyName: 'averageFteFteReduction',
    fieldName: 'Average FTE',
    width,
    placeholder: 'Schedule A Line 11',
    helpText: 'of chosen reference period',
    required: true,
  },
  averageFteGt100: {
    id: 'averageFteGt100',
    propertyName: 'averageFteGt100',
    fieldName: 'Average FTE',
    width,
    placeholder: 'Schedule A Line 5',
    required: true,
  },
  averageFteLte100: {
    id: 'averageFteLte100',
    propertyName: 'averageFteLte100',
    fieldName: 'Average FTE',
    width,
    placeholder: 'Schedule A Line 2',
    required: true,
  },
  cashCompensationGt100: {
    id: 'cashCompensationGt100',
    propertyName: 'cashCompensationGt100',
    fieldName: 'Cash Compensation',
    placeholder: 'Schedule A Line 4',
    width,
    required: true,
  },
  cashCompensationLte100: {
    id: 'cashCompensationLte100',
    propertyName: 'cashCompensationLte100',
    fieldName: 'Cash Compensation',
    width,
    placeholder: 'Schedule A Line 1',
    required: true,
  },
  forgivenessAmount: {
    id: 'forgivenessAmount',
    propertyName: 'forgivenessAmount',
    fieldName: 'Forgiveness Amount',
    width,
    required: true,
    ...disabledProps,
  },
  forgivenessAmountInputted: {
    id: 'forgivenessAmountInputted',
    propertyName: 'forgivenessAmountInputted',
    fieldName: 'Forgiveness Amount',
    width,
    required: true,
  },
  fteReductionQuotientFteReduction: {
    id: 'fteReductionQuotientFteReduction',
    propertyName: 'fteReductionQuotientFteReduction',
    fieldName: 'FTE Reduction Quotient',
    width,
    required: true,
    ...disabledProps,
  },
  healthInsuranceContributions: {
    id: 'healthInsuranceContributions',
    propertyName: 'healthInsuranceContributions',
    fieldName: 'Health Insurance Contributions',
    width,
    placeholder: 'Schedule A Line 6',
    required: true,
  },
  mortgageInterest: {
    id: 'mortgageInterest',
    propertyName: 'mortgageInterest',
    fieldName: 'Mortgage Interest',
    width,
    placeholder: 'Calculation Form Line 2',
    required: true,
  },
  modifiedTotal: {
    id: 'modifiedTotal',
    propertyName: 'modifiedTotal',
    width,
    required: true,
    ...disabledProps,
  },
  noFteReduction: {
    id: 'noFteReduction',
    propertyName: 'noFteReduction',
    label: 'No reduction in employees or average paid hours ',
    width,
  },
  operationsExpenditures: {
    id: 'operationsExpenditures',
    propertyName: 'operationsExpenditures',
    fieldName: 'Operations Expenditures',
    width,
    placeholder: 'Calculation Form Line 5',
    required: true,
  },
  payrollCostRequirement: {
    id: 'payrollCostRequirement',
    propertyName: 'payrollCostRequirement',
    width,
    required: true,
    ...disabledProps,
  },
  payrollCosts: {
    id: 'payrollCosts',
    propertyName: 'payrollCosts',
    fieldName: 'Payroll Costs',
    width,
    required: true,
  },
  payrollCostsCalculated: {
    id: 'payrollCostsCalculated',
    propertyName: 'payrollCostsCalculated',
    fieldName: 'Payroll Costs',
    width,
    required: true,
    ...disabledProps,
  },
  pppLoanAmount: {
    id: 'pppLoanAmount',
    propertyName: 'pppLoanAmount',
    width,
    required: true,
    ...disabledProps,
  },
  propertyDamageCosts: {
    id: 'propertyDamageCosts',
    propertyName: 'propertyDamageCosts',
    fieldName: 'Property Damage Costs',
    width,
    placeholder: 'Calculation Form Line 6',
    required: true,
  },
  rentOrLease: {
    id: 'rentOrLease',
    propertyName: 'rentOrLease',
    fieldName: 'Rent or Lease',
    width,
    placeholder: 'Calculation Form Line 3',
    required: true,
  },
  retirementPlanContributions: {
    id: 'retirementPlanContributions',
    propertyName: 'retirementPlanContributions',
    fieldName: 'Retirement Plan Contributions',
    width,
    placeholder: 'Schedule A Line 7',
    required: true,
  },
  safeHarbor1HasBeenMetFteReduction: {
    id: 'safeHarbor1HasBeenMetFteReduction',
    propertyName: 'safeHarbor1HasBeenMetFteReduction',
    label: 'Safe Harbor 1 has been met',
    width,
  },
  safeHarbor2HasBeenMetFteReduction: {
    id: 'safeHarbor2HasBeenMetFteReduction',
    propertyName: 'safeHarbor2HasBeenMetFteReduction',
    label: 'Safe Harbor 2 has been met',
    width,
  },
  salaryHourlyWageReductionLte100: {
    id: 'salaryHourlyWageReductionLte100',
    propertyName: 'salaryHourlyWageReductionLte100',
    fieldName: 'Salary/Hourly Wage Reduction',
    placeholder: 'Schedule A Line 3',
    width,
    required: true,
  },
  stateAndLocalPayrollTaxes: {
    id: 'stateAndLocalPayrollTaxes',
    propertyName: 'stateAndLocalPayrollTaxes',
    fieldName: 'State and Local Payroll Taxes',
    width,
    placeholder: 'Schedule A Line 8',
    required: true,
  },
  supplierCosts: {
    id: 'supplierCosts',
    propertyName: 'supplierCosts',
    fieldName: 'Supplier Costs',
    width,
    placeholder: 'Calculation Form Line 7',
    required: true,
  },
  totalAmountPaidCompensationToOwners: {
    id: 'totalAmountPaidCompensationToOwners',
    propertyName: 'totalAmountPaidCompensationToOwners',
    fieldName: 'Total Amount Paid',
    width,
    placeholder: 'Schedule A Line 9',
    required: true,
  },
  totalAverageFteFteReduction: {
    id: 'totalAverageFteFteReduction',
    propertyName: 'totalAverageFteFteReduction',
    fieldName: 'Total Average FTE',
    width,
    required: true,
    ...disabledProps,
  },
  utilities: {
    id: 'utilities',
    propertyName: 'utilities',
    fieldName: 'Utilities',
    width,
    placeholder: 'Calculation Form Line 4',
    required: true,
  },
  workerProtectionExpenditures: {
    id: 'workerProtectionExpenditures',
    propertyName: 'workerProtectionExpenditures',
    fieldName: 'Worker Protection Expenditures',
    width,
    placeholder: 'Calculation Form Line 8',
    required: true,
  },
};

export const links = {
  excelWorkbook: {
    standard:
      'https://loanstreet-web-assets.s3.amazonaws.com/sba_ppp/v2/LoanStreet_PPP_Loan_Forgiveness_Worksheet.xlsx',
    ez:
      'https://loanstreet-web-assets.s3.amazonaws.com/sba_ppp/LoanStreet_PPP_Loan_Forgiveness_Worksheet_EZ.xlsx',
    sForm:
      'https://loanstreet-web-assets.s3.amazonaws.com/sba_ppp/3508S/LoanStreet_PPP_Loan_Forgiveness_Worksheet_S.xlsx',
  },
  sbaForm: {
    standard:
      'https://home.treasury.gov/system/files/136/3245-0407-SBA-Form-3508-PPP-Forgiveness-Application.pdf',
    ez:
      'https://www.sba.gov/sites/default/files/2020-06/PPP%20Forgiveness%20Application%203508EZ%20%28%20Revised%2006.16.2020%29%20Fillable-508.pdf',
  },
  instructions: {
    standard:
      'https://home.treasury.gov/system/files/136/PPP--Loan-Forgiveness-Application-and-Instructions--Form-3508-1192021.pdf',
    ez:
      'https://home.treasury.gov/system/files/136/PPP--Loan-Forgiveness-Application-Instructions--Form3508EZ-1192021.pdf',
    // 'https://home.treasury.gov/system/files/136/PPP-Loan-Forgiveness-Application-Form-EZ-Instructions.pdf',
    sForm:
      'https://home.treasury.gov/system/files/136/PPP--Loan-Forgiveness-Application-Instructions--Form-3508S-1192021.pdf',
    // 'https://www.sba.gov/sites/default/files/2020-10/PPP%20Loan%20Forgiveness%20Application%20Form%203508S%20Instructions-508.pdf',
  },
};
