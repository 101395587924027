import isEmpty from 'lodash/isEmpty';
import { MutationResult, MutationMethod } from '../types';
import { BatchEntry, Mutations } from './types';
import difference from './utils/difference';
import { extractMutationMethods } from './utils/extractMutationMethods';
import isObjectDirty from './utils/isObjectDirty';
import enforceMutationPresence from './utils/enforceMutationPresence';
import isEntity from './utils/isEntity';
import WrapMutation from './mutationWrapper';
import {
  typeNameField,
  invariant,
  isWholeNumber,
  isDebug,
  getTypeNameString,
} from 'utils';
import { BaseType, ObjectTypeNames, ObjectBaseTypeNames } from 'types';

const StubType = 'Stub';

function debugLog(msg: string, color = '#db2828'): void {
  if (isDebug()) {
    // eslint-disable-next-line
    console.group(
      `%c▓ ${msg}`,
      `font-family:monospace; font-weight: bold; color: ${color}`,
    );
  }
}

function debugExit() {
  if (isDebug()) {
    // eslint-disable-next-line
    console.groupEnd();
  }
}

const referencePrefix = 'Reference_';

type SliceMaskType = {
  __typename: string | null | undefined;
  isDirty: boolean | null | undefined;

  [key: string]: any;
};

/**
 * Recursively generate a chain of mutations for the entire object tree e.
 * The response of a parent mutation is passed into a child mutation.
 * For example, saving a DealType will consist of a root mutation for Deal
 * that returns a new Deal object, this object will be passed as the 'parent'
 * arg to the child mutations generated for deal.creditratingSet
 *
 * Generic handling requires that all objects in the tree contain
 * a valid typename field.  This is necessary at runtime for determining
 * the correct mutation methods and response objects
 *
 * @param {*} e
 * @param {*} props
 * @param {*} ignoreMask
 */
export default function mutationBuilder<T extends BaseType>(
  e: T & { isLeaf?: boolean },
  mutationMethods: {
    [key: string]: MutationMethod<any, any>;
  },
  clean?: T,
  propertyWhitelist?: Partial<
    {
      [key in ObjectBaseTypeNames]: Set<string>;
    }
  >,
): BatchEntry {
  invariant(
    mutationMethods && !isEmpty(mutationMethods),
    'mutationBuilder requires mutationMethods',
  );

  // enforce that object has a __typename

  invariant(e.id, `Entity ${e.__typename} lacks an id property.`);

  // determine if the user has changed the object
  const isDirty: boolean = isObjectDirty(e);
  const shouldStub = !isDirty || e[typeNameField].startsWith(StubType);

  // const isCreate: boolean = !isEntity(e);

  const mutations: Mutations<any, any> = extractMutationMethods(
    e,
    mutationMethods,
  );

  let mutation: (e: T) => Promise<MutationResult<T>>;

  // child mutations batch
  const childMutationsBatch: Array<BatchEntry> = [];

  if (shouldStub) {
    debugLog(`Stub - ${e[typeNameField]}`, '#0ea800');
    enforceMutationPresence(mutations.stub, 'stub', e[typeNameField]);

    // The user has not changed this object, just stub it
    mutation = mutations.stub;
  } else if (mutations && !isEntity(e)) {
    // the base object has a non-numeric Id value, this means we need to execute a create mutation.
    debugLog(`Create - ${e[typeNameField]}`);

    enforceMutationPresence(mutations.create, 'create', e[typeNameField]);
    mutation = mutations.create;
  } else if (mutations) {
    // base object contains a numeric Id value, this means we are editing
    // a pre-existing object
    debugLog(`Set - ${e[typeNameField]}`);
    enforceMutationPresence(mutations.edit, 'set', e[typeNameField]);
    mutation = mutations.edit;
  } else {
    throw new Error(
      `Failed to determine a mutation method for ${e[typeNameField]}`,
    );
  }

  /*
    sliceMask will be used to remove properties from the input object
      Will Remove:
      - __typename
      - isDirty
      - Any child objects for which a mutation will generate
  */
  const sliceMask: SliceMaskType = {
    __typename: undefined,
    isDirty: undefined,
    isLeaf: undefined,
  };

  // eslint-disable-next-line
  console.warn('Creating Mutation', e);

  const typename: ObjectTypeNames = e[typeNameField];

  // a child batch entry should close over the entity to be saved, requiring only
  // the parent object to be passed in
  if (!e.isLeaf) {
    Object.keys(e).forEach(key => {
      if (key === 'permissions') return;
      if (key === 'race') return;

      if (
        Array.isArray(e[key]) &&
        !e[key].some(entry => typeof entry !== 'object')
      ) {
        const localArray: Array<any> = e[key];
        // child arrays must be handled by their own mutations

        // set property as undefined on the mask, so it will be removed
        // from the base object prior to issuing the mutation
        sliceMask[key] = undefined;

        // recursively invoke batchCreator for each object present in the child collection

        // Attempt to find a matching clean object
        // by id in the clean collection
        localArray.forEach(child => {
          let cleanVersion: T | null | undefined;

          if (
            clean &&
            clean[key] &&
            Array.isArray(clean[key]) &&
            isWholeNumber(child.id)
          ) {
            cleanVersion = clean[key].find(byId => byId.id === child.id);
          }

          if (!cleanVersion && isWholeNumber(child.id)) {
            // eslint-disable-next-line
            console.warn(
              `Failed to find clean array entry slice for key ${key}`,
              child,
            );
          }
          childMutationsBatch.push(
            mutationBuilder(
              child,
              mutationMethods,
              cleanVersion || undefined,
              propertyWhitelist,
            ),
          );
        });
      } else if (
        !Array.isArray(e[key]) &&
        e[key] !== null &&
        typeof e[key] === 'object'
      ) {
        const childObject = e[key];

        // Remove child object from e, e.g. ProspectType.LoanTranche
        sliceMask[key] = undefined;

        if (childObject[typeNameField].startsWith(referencePrefix)) {
          debugLog(
            `Skipping ${childObject[typeNameField]} - Reference prefix was found`,
          );
          return;
        }

        let cleanVersion: T | null | undefined;
        if (
          clean &&
          clean[key] &&
          typeof clean[key] === 'object' &&
          isWholeNumber(childObject.id)
        ) {
          cleanVersion = clean[key];
        } else if (isWholeNumber(childObject.id)) {
          // eslint-disable-next-line
          console.warn(`Failed to find clean object slice ${key}`, e[key]);
        }

        childMutationsBatch.push(
          mutationBuilder(
            childObject,
            mutationMethods,
            cleanVersion || undefined,
            propertyWhitelist,
          ),
        );
        // get the clean object
      }
    });
  }

  // apply the mask to remove properties from the target object
  // slice off properties
  const filteredDirty: T = {
    ...e,
    ...sliceMask,
  };
  // apply the same slice to the clean object
  const filteredClean: T = { ...clean, ...sliceMask };

  // if we have a whitelist map, get the whitelisted properties for our current typename
  const baseTypeName = getTypeNameString(e.__typename);
  const whitelist =
    propertyWhitelist && propertyWhitelist[baseTypeName]
      ? propertyWhitelist[baseTypeName]
      : null;

  /*
    This diff object should now only contain user changes
  */
  const diff = {
    ...difference(filteredDirty, filteredClean, whitelist),
    id: filteredDirty.id,
    __typename: filteredDirty.__typename,
  };

  debugExit();
  return WrapMutation(diff, mutation, typename, childMutationsBatch);
}
