import * as React from 'react';
import { InvitationType } from '../types';
import { InvitationTypes } from '../constants';
import { Form, StandardModal, WrappedMediatorFormProps } from 'components';
import {
  InstitutionInvitationsInput,
  RoleType,
  ID,
  SelectOptionType,
} from 'types';
import { QueryResult } from 'lsgql';
import { invariant } from 'utils';

type Props = WrappedMediatorFormProps<InstitutionInvitationsInput> & {
  dealRole: RoleType;
  formId: string;
  initializeState: () => void;
  invitationTypeSelected: InvitationType;
  isOpen: boolean;
  onClose: () => void;
  fetchDealOrganizationDealRoles: () => Promise<QueryResult<any>>;
  onInvitationTypeChange: (arg0: string | null | undefined) => void;
  onSave: () => Promise<{ entityId: ID; success: boolean }>;
  options: Array<SelectOptionType>;
  organizationRole: RoleType;
  selected: InvitationType;
};

const parentType = 'InstitutionInvitee';

class BorrowerInvitationForm extends React.Component<Props> {
  fields: any;

  constructor(props: Props) {
    super(props);

    invariant(props.organizationRole, 'Failed to find the organization role');

    this.fields = {
      invitationType: {
        id: 'invitationType',
        fieldName: 'Invitation',
        propertyName: 'invitationType',
        width: 'six',
        onChange: this.handleInvitationTypeChange,
      },
      email: {
        id: 'email',
        fieldName: 'Email',
        propertyName: 'email',
        width: 'ten',
        onChange: this.handleInviteeChange,
      },
    };
  }

  componentDidMount() {
    this.props.initializeState();
  }

  handleSubmit = () => {
    this.props.onSave().then(res => {
      if (res.success) {
        this.props.fetchDealOrganizationDealRoles();
        this.handleOnClose();
      }
    });
  };

  handleOnClose = () => {
    this.props.onClose();
  };

  handleInviteeChange = (value: string | null | undefined, fieldId: string) => {
    const { data } = this.props;
    const invitee = data.invitees[0];
    this.props.replaceEntity('invitees', { ...invitee, [fieldId]: value });
  };

  handleInvitationTypeChange = (value?: string | null | undefined) => {
    this.props.onInvitationTypeChange(value);
  };

  render() {
    const { data, isOpen, options, invitationTypeSelected } = this.props;
    const inviteeProps = { ...this.props, data: this.props.data.invitees[0] };

    if (!inviteeProps.data) return null;

    let invitationType: InvitationType;
    if (invitationTypeSelected === InvitationTypes.BORROWER) {
      invitationType = InvitationTypes.BORROWER;
    } else {
      invitationType = InvitationTypes.MY_TEAM;
    }

    return (
      <StandardModal
        confirmButtonText="Send"
        header="Send Invitation"
        isOpen={isOpen}
        onClose={this.handleOnClose}
        onConfirm={this.handleSubmit}
      >
        <Form id="borrowerInvitationForm" onSubmit={this.handleOnClose}>
          <>
            <Form.Group>
              {Form.FieldRenderer(
                Form.Select,
                {
                  ...this.fields.invitationType,
                  options,
                  value: invitationType,
                  disabled: options.length <= 1,
                  allowEmpty: false,
                },
                this.props,
                parentType,
                data.id,
              )}
              {Form.FieldRenderer(
                Form.Input,
                this.fields.email,
                inviteeProps,
                parentType,
                data.id,
              )}
            </Form.Group>
          </>
        </Form>
      </StandardModal>
    );
  }
}

export default BorrowerInvitationForm;
