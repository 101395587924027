// !!!!! THIS IS AN AUTO-GENERATED FILE, DO NOT EDIT !!!!!

import { gql } from '@apollo/client';
import { AlternateBaseRateBenchmarkInput } from 'types';
import { invariant, strictConvertToInput } from 'utils';
import {
  default as validationBuilder,
  ValidationData,
  AdditionalOptions,
} from './builder/validationBuilder';
import { ApolloQueryResult } from '../types';
import { inputScrubber } from '../mutations/utils';

/* eslint-disable import/prefer-default-export */

const AlternateBaseRateBenchmarkValidatorDefinition = gql`
  query validateAlternateBaseRateBenchmark(
    $alternateBaseRateBenchmarkData: AlternateBaseRateBenchmarkInput
  ) {
    validateAlternateBaseRateBenchmark(
      alternateBaseRateBenchmarkData: $alternateBaseRateBenchmarkData
    ) {
      errors
      errorFields {
        name
        value
      }
      warnings
      warningFields {
        name
        value
      }
    }
  }
`;

const propName = 'validateAlternateBaseRateBenchmark';

/**
 * Auto-Generated from the GraphQL Schema
 */
export const AlternateBaseRateBenchmarkValidator = validationBuilder<
  AlternateBaseRateBenchmarkInput
>(AlternateBaseRateBenchmarkValidatorDefinition, {
  build: (
    validation: (
      options: AdditionalOptions,
    ) => Promise<ApolloQueryResult<ValidationData>>,
  ) => async (entity: AlternateBaseRateBenchmarkInput) => {
    const res: ApolloQueryResult<ValidationData> = await validation({
      variables: {
        alternateBaseRateBenchmarkData: { id: entity.id },
      },
    });
    invariant(
      res.data[propName],
      'Expected res.data to contain validateAlternateBaseRateBenchmark',
    );
    return res.data[propName];
  },
});

export const AlternateBaseRateBenchmarkEntityValidator = validationBuilder<
  AlternateBaseRateBenchmarkInput
>(AlternateBaseRateBenchmarkValidatorDefinition, {
  build: (
    validation: (
      options: AdditionalOptions,
    ) => Promise<ApolloQueryResult<ValidationData>>,
  ) => async (entity: AlternateBaseRateBenchmarkInput) => {
    const res: ApolloQueryResult<ValidationData> = await validation({
      variables: {
        alternateBaseRateBenchmarkData: strictConvertToInput({
          ...inputScrubber(entity),
          submitted: undefined,
          approved: undefined,
        }),
      },
    });
    invariant(
      res.data[propName],
      'Expected res.data to contain validateAlternateBaseRateBenchmark',
    );
    return res.data[propName];
  },
});
