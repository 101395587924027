import uuid from 'uuid/v4';

import {
  InstitutionMutationMethods,
  InstitutionBankAccountMutationMethods,
  SbaAccountMutationMethods,
} from '../../../graphql/mutations';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import { InstitutionType } from 'types';

const initialInstitution: InstitutionType = {
  id: uuid(),
  name: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: null,
  zipCode: '',
  website: '',
  notes: '',
  institutionbankaccountSet: [],
  subsidiaries: [],
  __typename: 'InstitutionType',
};

const DealInstitutionMutationMethods = {
  createDealInstitution: InstitutionMutationMethods.createInstitution,
  setDealInstitution: InstitutionMutationMethods.setInstitution,
  deleteDealInstitution: InstitutionMutationMethods.deleteInstitution,
};

const lifecycle = {
  mutations: {
    ...InstitutionMutationMethods,
    ...InstitutionBankAccountMutationMethods,
    ...DealInstitutionMutationMethods,
    ...SbaAccountMutationMethods,
  },
  validators: {},
};

export default createForm<InstitutionType, InstitutionType>(
  Directory.InstitutionKey,
  initialInstitution,
  lifecycle,
);
