import isProduction from './isProduction';

let isDebugMode = !isProduction();
let isPermissionDebugFlag = false;

/**
 * Toggle debug mode on/off
 */
export function toggleDebug(): void {
  isDebugMode = !isDebugMode;
}

export function togglePermissionDebug(): void {
  isPermissionDebugFlag = !isPermissionDebugFlag;
}

/**
 * Check if debug mode is enabled
 */
export function isDebug(): boolean {
  return isDebugMode;
}

export function isPermissionDebug(): boolean {
  return isPermissionDebugFlag;
}

/**
 * Log to console if debug mode is currently enabled
 */
export function debug(msg: string, ...optional: Array<any>) {
  if (!isDebug()) return;
  if (optional && optional.length > 0) {
    // eslint-disable-next-line
    console.log(msg, ...optional);
    return;
  }
  // eslint-disable-next-line
  console.log(msg);
}
