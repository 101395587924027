import * as React from 'react';
import { LoanTrancheFormProps } from '../../types';
import { Form } from 'components';

const loanTranche = 'LoanTranche';
const width = '158px';
class FixedInterestFields extends React.Component<LoanTrancheFormProps> {
  fields = {
    interest: {
      id: 'fixedInterestRate',
      propertyName: 'fixedInterestRate',
      fieldName: 'Fixed Interest Rate',
      onChange: this.props.mutateProperty,
      width,
      tabIndex: this.props.tabIndex,
    },
    defaultInterest: {
      id: 'defaultInterestRate',
      propertyName: 'defaultInterestRate',
      fieldName: 'Default Interest Rate',
      onChange: this.props.mutateProperty,
      width,
      tabIndex: this.props.tabIndex,
    },
  };

  render() {
    const { data } = this.props;
    return (
      <Form.Group>
        {Form.FieldRenderer(
          Form.Percentage,
          this.fields.interest,
          this.props,
          loanTranche,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Percentage,
          this.fields.defaultInterest,
          this.props,
          loanTranche,
          data.id,
        )}
      </Form.Group>
    );
  }
}

export default FixedInterestFields;
