import { ID } from '../../../types/primitives';
import standardReducerFactory from '../standardReducer';
import { ENTER_DRAGHOVER, LEAVE_DRAGHOVER } from '../../actions/dataroomrow';

type DataroomRowState = {
  canDropInRootFolder: boolean;
  dragHoverRootId: ID;
};

const initialState: DataroomRowState = {
  dragHoverRootId: '',
  canDropInRootFolder: false,
};

const reducerMap: {
  [key: string]: (arg0: any, arg1: any) => Record<string, any>;
} = {};

reducerMap[ENTER_DRAGHOVER] = (state, action) => {
  if (state.dragHoverRootId !== action.payload) {
    return {
      dragHoverRootId: action.payload.id,
      canDropInRootFolder: action.payload.canDropInRootFolder,
    };
  }
  return state;
};

reducerMap[LEAVE_DRAGHOVER] = () => ({
  dragHoverRootId: '',
  canDropInRootFolder: false,
});

export default standardReducerFactory(reducerMap, initialState);
