import * as React from 'react';
import { LoanTrancheFormProps } from '../types';
import { isAdjustableRate, isFloatingRate, isLetterOfCredit } from './utils';
import AdjustableRateFloorAndCapFields from './floorAndCapForm/AdjustableRateFloorAndCapFields';
import FloatingRateFloorAndCapFields from './floorAndCapForm/FloatingRateFloorAndCapFields';
import './FloorAndCapFields.scss';

function FloorAndCapFields(props: LoanTrancheFormProps) {
  const { data } = props;

  if (isLetterOfCredit(data)) {
    return null;
  }

  if (isAdjustableRate(data))
    return <AdjustableRateFloorAndCapFields {...props} />;

  if (isFloatingRate(data)) return <FloatingRateFloorAndCapFields {...props} />;

  return null;
}

export default FloorAndCapFields;
