import React from 'react';
import { Header, Form } from 'components';
import { InstitutionType, FormFieldsType } from 'types';

function SbaAccountView(props: InstitutionType) {
  const { sbaPrimaryContact, sbaAccount } = props;

  const fields: FormFieldsType = {
    sbaPartnerId: {
      id: 'sbaPartnerId',
      propertyName: 'sbaPartnerId',
      fieldName: 'SBA Partner ID',
      width: '188px',
    },
    lenderLocationId: {
      id: 'lenderLocationId',
      propertyName: 'lenderLocationId',
      fieldName: 'Lender Location ID',
      width: '188px',
    },
    username: {
      id: 'sbaAccountUsername',
      propertyName: 'sbaAccountUsername',
      fieldName: 'Username',
      width: '392px',
    },
    password: {
      id: 'sbaAccountPassword',
      propertyName: 'sbaAccountPassword',
      fieldName: 'Password',
      width: '392px',
    },
    forgivenessAuthToken: {
      id: 'forgivenessAuthToken',
      propertyName: 'forgivenessAuthToken',
      fieldName: 'PPP Forgiveness Authorization Token',
      width: '392px',
    },
  };

  const fieldsSbaPrimaryContact: FormFieldsType = {
    fullName: {
      id: 'fullName',
      propertyName: 'fullName',
      fieldName: 'Contact Name',
      width: '392px',
    },
    title: {
      id: 'title',
      propertyName: 'title',
      fieldName: 'Title',
      width: '392px',
    },
    email: {
      id: 'email',
      propertyName: 'email',
      fieldName: 'Email',
      width: '392px',
    },
    primaryPhone: {
      id: 'primaryPhone',
      propertyName: 'primaryPhone',
      fieldName: 'Primary Phone',
      width: '188px',
    },
    secondaryPhone: {
      id: 'secondaryPhone',
      propertyName: 'secondaryPhone',
      fieldName: 'Secondary Phone',
      width: '188px',
    },
    fax: {
      id: 'fax',
      propertyName: 'fax',
      fieldName: 'Fax',
      width: '188px',
    },
  };

  return (
    <>
      <Form.Group>
        <Form.ReadOnly
          {...fields.sbaPartnerId}
          value={sbaAccount?.sbaPartnerId}
        />
        <Form.ReadOnly
          {...fields.lenderLocationId}
          value={sbaAccount?.lenderLocationId}
        />
      </Form.Group>
      {sbaPrimaryContact && (
        <>
          <Form.Divider />
          <Header as="h5" className="caps steel">
            Primary Contact for SBA
          </Header>
          <p>
            <b>{sbaPrimaryContact?.fullName}</b>
          </p>
          <p>{sbaPrimaryContact?.title}</p>
          <p>{sbaPrimaryContact?.email}</p>
          <br />
          {(sbaPrimaryContact?.primaryPhone ||
            sbaPrimaryContact?.secondaryPhone ||
            sbaPrimaryContact?.fax) && (
            <>
              <Form.Group>
                <Form.ReadOnly
                  {...fieldsSbaPrimaryContact.primaryPhone}
                  value={sbaPrimaryContact?.primaryPhone}
                />
                <Form.ReadOnly
                  {...fieldsSbaPrimaryContact.secondaryPhone}
                  value={sbaPrimaryContact?.secondaryPhone}
                />
              </Form.Group>
              <Form.ReadOnly
                {...fieldsSbaPrimaryContact.fax}
                value={sbaPrimaryContact?.fax}
              />
            </>
          )}
        </>
      )}
      {sbaAccount?.username && (
        <>
          <Form.Divider />
          <Header as="h5" className="caps steel">
            SBA Account Login
          </Header>
          <br />
          <Form.ReadOnly {...fields.username} value={sbaAccount?.username} />
          <Form.ReadOnly {...fields.password} value={sbaAccount?.password} />
        </>
      )}
      {sbaAccount?.forgivenessAuthToken && (
        <>
          <Form.Divider />
          <Header as="h5" className="caps steel">
            PPP Info
          </Header>
          <br />
          <Form.ReadOnly
            {...fields.forgivenessAuthToken}
            value={sbaAccount?.forgivenessAuthToken}
          />
        </>
      )}
    </>
  );
}

export default SbaAccountView;
