import {
  CHANGE_FOLDER,
  ADMINISTER_FOLDER,
  DELETE_FOLDER,
  VIEW_FOLDER,
} from '../../constants';
import { PermissionDefinitions } from './types';

const perspective = 'folder';

const FolderPerspectivePermissions: PermissionDefinitions = {
  change_folder: {
    flag: CHANGE_FOLDER,
    perspective,
  },
  administer_folder: {
    flag: ADMINISTER_FOLDER,
    perspective,
  },
  delete_folder: {
    flag: DELETE_FOLDER,
    perspective,
  },
  view_folder: {
    flag: VIEW_FOLDER,
    perspective,
  },
};

export default FolderPerspectivePermissions;
