import { ApolloQueryResult } from '../types';
import {
  filterKeyValueBuilders,
  executeFilterQuery,
  FilterKeyValue,
} from './queryBuilders/filterKeyValueBuilders';
import { IndustryCodesQueryDefinition } from './definitions';

type IndustryCodeType = {
  code: string;
  description: string;
  id: string;
};

type IndustryCodesResult = { industryCodes: Array<IndustryCodeType> };
export default function IndustryCodeSearch(
  code?: string,
  description?: string,
): Promise<ApolloQueryResult<IndustryCodesResult>> {
  const valueFilters: Array<FilterKeyValue> = [];

  if (code) {
    valueFilters.push(filterKeyValueBuilders.propertyStartsWith('code', code));
  }

  if (description) {
    valueFilters.push(
      filterKeyValueBuilders.propertyContains('description', description),
    );
  }

  return executeFilterQuery(IndustryCodesQueryDefinition, valueFilters);
}
