import { connect } from 'react-redux';
import RowFork from './RowFork';
import { ReduxDirectory } from 'lsredux';

const mapStateToProps = state => ({
  dragHoverRootId: state.getIn(ReduxDirectory.DataroomRowKeyPath)
    .dragHoverRootId,
});

export default connect(mapStateToProps)(RowFork);
