import { compose } from 'recompose';
import { connect } from 'react-redux';
import DealGenerator from './DealGenerator';
import generateFormDealRedux from 'lsredux/reducer/debugForms/generateDeal';
import { FormMediator } from 'components';
import { setAlert } from 'lsredux/actions/alerts';
import { AlertType } from 'types';

import {
  TestDealsQuery,
  TestTranchesQuery,
  InstitutionsQueryWithUsers,
  RolesQuery,
  MyUserQuery,
} from 'lsgql';

const mapDispatchToProps = (dispatch: any) => {
  const bound = generateFormDealRedux.actions.generateActions(dispatch);
  const res: { setAlert: (alert: string, type?: AlertType) => void } = {
    setAlert: (alert: string, type?: AlertType) => {
      dispatch(setAlert(alert, type || 'success'));
    },
  };
  return { ...bound, ...res };
};

const generateDealForm = compose(
  MyUserQuery,
  TestDealsQuery,
  TestTranchesQuery,
  InstitutionsQueryWithUsers,
  RolesQuery,
  connect(generateFormDealRedux.mapStateToProps, mapDispatchToProps),
  FormMediator({
    formHeader: 'Generate Deal',
    formId: 'generateDealForm',
    baseObjectTypeName: 'Deal',
    disableFrame: true,
  }),
)(DealGenerator);

export default generateDealForm;
