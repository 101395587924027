import { MutationResult } from '../types';
import parseResponse from './parseResponse';
import { BatchEntry, DispatchResponse } from './types';
import { BaseType, ObjectTypeNames, ValidationMap } from 'types';
import { getCamelTypeNameString, invariant } from 'utils';

/* eslint-disable no-param-reassign */

const IdProperty = 'Id';
const StubPrefix = 'Stub';

/**
 * WrapMutation creates a function that fully encapsulates a node
 * in the Mutations Tree
 *
 * For example,
 * @param {*} difference - Will contain Id, __typename, and any properties that have changed
 * @param {*} mutation
 * @param {*} typename
 * @param {*} childMutationsBatch
 */
export default function WrapMutation<T extends BaseType>(
  difference: T,
  mutation: (e: T) => Promise<MutationResult<T>>,
  typename: ObjectTypeNames,
  childMutationsBatch: Array<BatchEntry>,
): BatchEntry {
  return async function wrappedMutation(
    parent?: any | null | undefined,
    parentType?: ObjectTypeNames | null | undefined,
    validationMap?: ValidationMap,
  ): Promise<DispatchResponse<T>> {
    // set context
    invariant(validationMap, 'mutationWrapper requires a ValidationMap');
    let resultObject: DispatchResponse<T> | null | undefined;
    let response: MutationResult<T>;

    let childResults = [];
    let ok: boolean | null | undefined = null;
    try {
      // if we have a parent, set the parentId property of the child;
      // E.g. deal.id => { dealId: deal.id }
      if (parent && parentType && !parentType.startsWith(StubPrefix)) {
        // we have a parent value, set the 'id' property of the
        // current child object
        const parentCamelTypename: string = getCamelTypeNameString(parentType);
        // Prettier and/or eslint really screws up the next line

        const parentIdProperty: string = parentCamelTypename + IdProperty;

        difference[parentIdProperty] = parent.id;
      }

      // invoke the root mutation
      response = await mutation(difference);

      // get the mutation result object.  If the mutation was a create,
      // we will need the generated Id value in order to update pending
      // child mutations

      // Entity id can either be retrieved from the clean slice
      // or the mutation response.  Which is better?
      resultObject = parseResponse(
        typename,
        response,
        validationMap,
        difference.id,
      );

      // eslint-disable-next-line
      ok = resultObject.ok === true ? true : false;
      // const hasErrors = resultObject.errors && resultObject.errors.length > 0;

      if (
        !resultObject.errors &&
        resultObject.entity &&
        childMutationsBatch.length > 0
      ) {
        const { entity } = resultObject;

        // invoke pending children, passing in the parent mutation result
        childResults = await Promise.all(
          childMutationsBatch.map(childMutation =>
            childMutation(entity, typename, validationMap),
          ),
        );

        ok = ok && childResults.every(childRes => childRes.ok);
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }

    invariant(resultObject, 'rootResult was undefined');
    invariant(ok !== null && ok !== undefined, 'ok was undefined');
    return { ...resultObject, ok };
  };
}
