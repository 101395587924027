import * as React from 'react';
import cx from 'classnames';
import { Header, Divider, Tooltip } from 'components';
import './WorkflowSection.scss';

type Props = {
  header?: React.ReactNode;
  headerCase?: 'caps' | 'auto';
  /** Optionally add tooltip text at end of header */
  tooltipText?: string;
  children: React.ReactNode;
  className?: string;
  /** Optionally add blue helpbox for section */
  helpBox?: React.ReactNode;
  onClick?: (e?: React.SyntheticEvent) => void;
};

function WorkflowSection(props: Props) {
  const {
    header,
    children,
    className,
    helpBox,
    onClick,
    tooltipText,
    headerCase = 'caps',
  } = props;
  const lower = headerCase !== 'caps' || !!tooltipText;

  const handleTooltipToggle = (
    isOpen: boolean,
    e?: React.MouseEvent<any, MouseEvent>,
  ) => {
    isOpen && onClick && onClick(e);
  };
  return (
    <div className={cx(className, 'WorkflowSection')} onClick={onClick}>
      <div className="MainContent">
        <Divider thin />
        <Header as="h5" className={cx({ caps: !lower })}>
          {header}
          {tooltipText && (
            <Tooltip onToggle={handleTooltipToggle}>{tooltipText}</Tooltip>
          )}
        </Header>
        <Divider className="transparent" thin />
        <div className="WorkflowSection--Items">{children}</div>
      </div>
      {helpBox}
    </div>
  );
}

export default WorkflowSection;
