import * as React from 'react';
import { isWholeNumber, invariant } from 'utils';
import { ID, AlertType } from 'types';
import { QueryResult } from 'lsgql';

type Props = {
  deleteDocument: (id: number) => Promise<QueryResult<any>>;
  formQueryRefetch: (params: { id: ID }) => Promise<QueryResult<any>>;
  handleResponse: (arg0: any) => void;
  refetchDocuments: (data: any) => Promise<any>;
  save: (formQueryRefetch: any) => Promise<any>;
  setAlert: (alert: string, type: AlertType, title?: string) => void;
  stagedFiles: Array<File>;
  uploadType: string;
};

type UploadResultType = {
  failedUploads: Array<string>;
  numUploaded: number;
};

function withStagedFilesHoC(
  sendFileToBackend: (
    uploadType: string,
  ) => (file: File, entityId: string) => Promise<any>,
) {
  invariant(
    sendFileToBackend,
    'An sendFileToBackend function must be provided',
  );

  return function StagedFilesWrapper(
    WrappedComponent: React.ComponentType<Props>,
  ) {
    return function HOC(props: Props) {
      const {
        save,
        setAlert,
        handleResponse,
        stagedFiles,
        formQueryRefetch,
        uploadType,
      } = props;

      invariant(save, 'save not found in props');
      invariant(setAlert, 'setAlert not found in props');
      invariant(handleResponse, 'handleResponse not found in props');
      invariant(stagedFiles, 'stagedFiles not found in props');
      invariant(uploadType, 'ploadType not found in props');
      invariant(formQueryRefetch, 'formQueryRefetch not found in props');

      async function refetchDocuments(data) {
        invariant(data, 'data must be provided as an argument');

        const res = await formQueryRefetch({ id: data.id as string });
        const parsedResponse = {
          entity: res.data,
          errors: null,
          ok: true,
        };
        handleResponse(parsedResponse);
      }

      function uploadFiles(
        entityId: string,
        files: Array<File>,
        numberOfFilesUploaded = 0,
        failedFileNames: Array<string> = [],
      ) {
        const file = files.shift();
        if (file) {
          return sendFileToBackend(uploadType)(file, entityId).then(
            // success
            () =>
              uploadFiles(
                entityId,
                files,
                numberOfFilesUploaded + 1,
                failedFileNames,
              ), // error
            () =>
              uploadFiles(entityId, files, numberOfFilesUploaded, [
                ...failedFileNames,
                file.name,
              ]),
          );
        }
        const results: UploadResultType = {
          numUploaded: numberOfFilesUploaded,
          failedUploads: failedFileNames,
        };

        return Promise.resolve(results);
      }

      async function overloadedSave(refetch: any) {
        const res = await save(refetch);
        const entityId = res.entityId || '';
        if (stagedFiles && stagedFiles.length && isWholeNumber(entityId)) {
          const results = await uploadFiles(entityId, stagedFiles);
          if (results.numUploaded > 0) {
            setAlert(
              `${results.numUploaded} file(s) were successfully uploaded!`,
              'success',
            );

            const refetchedRes = await refetch({ id: entityId });
            const parsedResponse = {
              entity: refetchedRes.data,
              errors: null,
              ok: true,
            };
            handleResponse(parsedResponse);
          }

          if (results.failedUploads.length > 0) {
            setAlert(
              `The following file(s) failed to upload: ${results.failedUploads.join(
                ' , ',
              )}`,
              'error',
            );
          }
        }
        return res;
      }

      return (
        <WrappedComponent
          {...props}
          refetchDocuments={refetchDocuments}
          save={overloadedSave}
        />
      );
    };
  };
}

export default withStagedFilesHoC;
