import * as React from 'react';
import { Form, Header } from 'components';
import { LoanTrancheFormType, ValidationMap } from 'types';

type Props = {
  data: LoanTrancheFormType;

  disabled?: boolean;

  /* eslint-disable react/no-unused-prop-types */
  errors?: ValidationMap | null | undefined;
  isSaving: boolean;

  /* eslint-enable react/no-unused-prop-types */

  mutateProperty: (value: any, property: string) => void;
};

const loanTranche = 'LoanTranche';
const width = '120px';
const adjustmentLimitDown = 'adjustmentLimitDown';
const adjustmentLimitUp = 'adjustmentLimitUp';

class FloorAndCapFieldsAdjustmentLimitItem extends React.Component<Props> {
  /* eslint-enable react/sort-comp */

  fields = {
    decrease: {
      id: adjustmentLimitDown,
      propertyName: adjustmentLimitDown,
      onChange: this.props.mutateProperty,
      fieldName: 'Decrease',
      disabled: Boolean(this.props.disabled),
      width,
    },
    increase: {
      id: adjustmentLimitUp,
      propertyName: adjustmentLimitUp,
      onChange: this.props.mutateProperty,
      fieldName: 'Increase',
      disabled: Boolean(this.props.disabled),
      width,
    },
  };

  constructor(props: Props) {
    super(props);
  }

  render() {
    const { data, disabled } = this.props;

    const PercentageComponent = disabled
      ? Form.PercentageReadOnly
      : Form.Percentage;

    return (
      <>
        <Form.Divider className="transparent" />
        <Header as="h5" className="steel bold">
          Adjustment limit
        </Header>
        <Form.Group>
          {Form.FieldRenderer(
            PercentageComponent,
            this.fields.decrease,
            this.props,
            loanTranche,
            data.id,
          )}
          {Form.FieldRenderer(
            PercentageComponent,
            this.fields.increase,
            this.props,
            loanTranche,
            data.id,
          )}
        </Form.Group>
      </>
    );
  }
}

export default FloorAndCapFieldsAdjustmentLimitItem;
