import * as React from 'react';
import { useHistory } from 'react-router-dom';
import MenuItem from './MenuItem';
import { FontAwesomeIconTypes } from 'components';

const arrowLeftIcon: FontAwesomeIconTypes = ['far', 'long-arrow-left'];

/**
 * If there is previous history entry to return to,
 * render a MenuItem that, when clicked, will take the user
 * to their previous location.  If there is not a previous location,
 * the MenuItem will not render
 */
function GoBackMenuItem() {
  const history = useHistory();

  if (history.length < 2) {
    // Theres nowhere to go back to
    return null;
  }

  return (
    <MenuItem
      className="MenuItem--Home"
      icon={arrowLeftIcon}
      onClick={() => history.goBack()}
      title="Back"
    />
  );
}

export default GoBackMenuItem;
