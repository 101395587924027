import { withHandlers } from 'recompose';
import { gql } from '@apollo/client';
import getPublicClient from '../publicClient';
import { invariant } from 'utils';

/* eslint-disable */
const AcceptInvitationMutationDefinition = gql`
  mutation AcceptInvitationMutation($invitationKey: String!) {
    acceptInvitation(invitationKey: $invitationKey) {
      ok
    }
  }
`;

/* eslint-enable */

export function AcceptInvitationMutation(invitationKey: string) {
  invariant(invitationKey, 'Invitation key is required');
  const client = getPublicClient();
  invariant(client, 'Failed to create Public Client');

  return client.mutate<any>({
    mutation: AcceptInvitationMutationDefinition,
    variables: {
      invitationKey,
    },
  });
}

/* eslint-disable @typescript-eslint/no-unused-vars */

/**
 * Public Invitation Mutation methods
 */
export const AcceptInvitationMutations = withHandlers<
  any,
  { acceptInvitation: (arg0: any) => (arg0: string) => Promise<any> }
>({
  acceptInvitation: (props: any) => (invitationKey: string) =>
    AcceptInvitationMutation(invitationKey),
});
