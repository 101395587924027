import { PermissionScopeMap, PermissionKey, WeightScope } from '../types';
import getKey from './getKey';
import { PermissionsBaseType } from 'types';

/**
 * Dispose PermissionPerspectives that have gone out of scope.
 * If multiple instances of the same Perspective exist, decrement
 * reference count by 1.  Otherwise delete the Perspective completely
 *
 * @param {*} existingMap
 * @param {*} perspective
 */
export default function disposePermissionPerspective(
  existingMap: PermissionScopeMap,
  perspective: PermissionsBaseType,
): PermissionScopeMap {
  const scope: PermissionKey = getKey(perspective);

  // console.warn(`Attempt to dipose Scope: ${scope} P: ${perspective}`);

  if (existingMap[scope]) {
    if (existingMap[scope].referenceCount > 1) {
      /*
        Multiple PermissionPerspective instances
        instantiated the same scope, decrement the
        referenceCount by 1, but allow the Perspective
        to continue to exist
      */

      // eslint-disable-next-line
      existingMap[scope].referenceCount -= 1;

      /*
      console.warn(
        `(${String(scope)}) Reducing reference count (b: ${before} - rc: ${
          existingMap[scope].referenceCount
        })`,
      );
      */
    } else {
      /*
        The requestor is the last instance of this scope,
        its time to to nuke the perspective
      */
      const toDispose: {
        [key in PermissionKey]: WeightScope | null | undefined;
      } = {};
      toDispose[scope] = undefined;

      // console.warn(`(${String(scope)} Disposing permissions)`);
      return { ...existingMap, ...toDispose } as PermissionScopeMap;
    }
  }
  // console.warn('dispose result', existingMap);
  return existingMap;
}
