import * as React from 'react';

import noop from 'lodash/noop';
import AmortizationTableFooter from '../AmortizationTableFooter';
import { PaymentRowProps } from './PaymentRow';
import {
  LoanTrancheAmortizationPrincipalPaymentType,
  AmortizationWithReaderDataType,
} from 'types';
import { DisplayTable, TableColumn } from 'components';

type Props = {
  amortization?: AmortizationWithReaderDataType | null | undefined;
  onPaymentChange: (
    value: string | null | undefined,
    fieldId: string,
    index: number,
  ) => void;
  principalMap: {
    [id: string]: LoanTrancheAmortizationPrincipalPaymentType;
  };
};

type Args = {
  columns: Array<TableColumn>;
  paymentRowComponent: React.ComponentType<PaymentRowProps>;
};

const sortBy = {
  column: 'Period',
  reverse: false,
};

const EmptyPayment = {
  count: '-',
  principalBefore: '-',
  principalAfter: '-',
  principalAmount: '-',
  id: '',
  __typename: 'LoanTrancheAmortizationPrincipalPaymentType',
};

const EmptyReaderData = {
  targetPrincipal: '-',
  totalPrincipal: '-',
  outOfBalance: '0',
  numberOfPayments: '-',
};

function createPaymentScheduleTable(args: Args) {
  const PaymentRowComponent = args.paymentRowComponent;
  return class PaymentScheduleTable extends React.PureComponent<Props> {
    getFinalPayment = (
      amortization?: AmortizationWithReaderDataType | null | undefined,
    ) => {
      const payments =
        amortization &&
        amortization.loantrancheamortizationprincipalpaymentSet &&
        amortization.loantrancheamortizationprincipalpaymentSet.length > 0
          ? amortization.loantrancheamortizationprincipalpaymentSet
          : [];

      const index = payments.length > 0 ? payments.length - 1 : -1;

      const finalPayment = index >= 0 ? payments[index] : EmptyPayment;

      return { finalPayment, index };
    };

    handleApplyToFinalPayment = () => {
      const { amortization, onPaymentChange } = this.props;
      const { finalPayment, index } = this.getFinalPayment(amortization);

      const outOfBalance = amortization.readerData
        ? amortization.readerData.outOfBalance
        : '0';

      onPaymentChange(
        `${+outOfBalance + +finalPayment.principalAmount}`,
        'principalAmount',
        index,
      );
    };

    renderFooter = () => {
      const { amortization, onPaymentChange } = this.props;

      const readerData = amortization.readerData
        ? amortization.readerData
        : EmptyReaderData;

      const { finalPayment, index } = this.getFinalPayment(amortization);

      return (
        <AmortizationTableFooter
          disabled
          finalPayment={finalPayment as any}
          index={index}
          onApplyToFinalPayment={this.handleApplyToFinalPayment}
          onPaymentChange={onPaymentChange}
          readerData={readerData as any}
        />
      );
    };

    render() {
      const { amortization, principalMap, onPaymentChange } = this.props;
      const payments =
        amortization && amortization.loantrancheamortizationprincipalpaymentSet
          ? amortization.loantrancheamortizationprincipalpaymentSet
          : [];

      return (
        <DisplayTable
          className="AmortizationTable__DisplayTable"
          columns={args.columns}
          footer={this.renderFooter}
          hoverable
          onChangeSortField={noop}
          sortBy={sortBy}
        >
          {payments &&
            payments.slice(0, payments.length - 1).map((r, i) => {
              const principalMapEntry =
                r.count && principalMap[r.count] ? principalMap[r.count] : null;

              return (
                <PaymentRowComponent
                  disabled
                  index={i}
                  key={r.count}
                  onChange={onPaymentChange}
                  payment={r}
                  principalAfter={
                    principalMapEntry ? principalMapEntry.principalAfter : null
                  }
                  principalBefore={
                    principalMapEntry ? principalMapEntry.principalBefore : null
                  }
                />
              );
            })}
        </DisplayTable>
      );
    }
  };
}

export default createPaymentScheduleTable;
