import uuid from 'uuid/v4';
import { FormProps } from './types';
import { undoKeyPath } from './shared';
import {
  LoanTrancheAmortizationInput,
  LoanTrancheAmortizationType,
  LoanTrancheAmortizationPrincipalPaymentType,
  AmortizationReaderType,
  LoanTrancheType,
  LoanTrancheInput,
  AmortizationResponseType,
  ValidationMap,
  ValidationType,
  PaymentCalculationStates,
} from 'types';

import { AmortizationPrincipalPaymentsQuery } from 'lsgql';
import { strictConvertToInput, arrayToObject, invariant } from 'utils';
import validationMapBuilder from 'lsgql/mutationsDispatch/utils/validationMapBuilders';

type AmortizationBatchEntry = { action: () => void; id: string };

const rootKeyPath = ['nonpersisted', 'loanTranche'];

const formatLog = (m: string) => [
  `%c▓ ${m}`,
  'font-family:monospace; font-weight: bold; color: #419572',
];

const paymentsPostProcessing = (
  queryResult: AmortizationResponseType | null | undefined,
  currentAmortization: LoanTrancheAmortizationType,
  stalePayments: ReadonlyArray<LoanTrancheAmortizationPrincipalPaymentType>,
  props: FormProps,
) => {
  // Temporary allow of console for debugging

  /* eslint-disable no-console */

  /*
    A few things must always happen:
     1) Error status *MUST* be updated.  We always need the latest feedback from the
      query as to whether or not our data is valid
     2) The Reader data *MUST* be set
  */

  // Get Reader Data
  const result: AmortizationReaderType | null | undefined =
    queryResult && queryResult.result ? queryResult.result : null;

  // Get Fresh Payments
  const freshPayments: ReadonlyArray<LoanTrancheAmortizationPrincipalPaymentType> =
    result && result.processedPrincipalPayments
      ? result.processedPrincipalPayments
      : [];

  // Get Validation info
  const validationErrors: ValidationType | null | undefined =
    queryResult && queryResult.amortizationErrors
      ? queryResult.amortizationErrors
      : null;

  // Extract the reader's reference data
  const readerData = {
    ...result,
    loanTrancheAmortization: undefined,
    processedPrincipalPayments: undefined,
    __typename: 'Reference_StubAmortizationReaderData',
    id: 'NotUsed',
    principalMap: {
      ...arrayToObject(freshPayments as any, 'count'),
      __typename: 'PrincipalMap',
    },
    calculationState: validationErrors ? 'NONE' : 'CALCULATED',
  };

  // Queue of actions to fully update the amortization table
  const actionQueue: Array<AmortizationBatchEntry> = [];

  // Step 1: Determine how to update validation data
  if (validationErrors) {
    /*
      The query returned validation errors, implying that
      it could not generate payments, and should not have a
      valid amortization
    */

    // Translate the ValidationType response into a ValidationMap
    const validationResults: ValidationMap = validationMapBuilder(
      'LoanTrancheAmortization',
      currentAmortization.id,
      validationErrors,
    );

    actionQueue.push({
      id: 'SetValidation',
      action: () =>
        props.handleResponse({
          entity: null,
          errors: validationResults,
          ok: true,
        }),
    });
  } else {
    // In the absence of errors, we must always clear
    actionQueue.push({
      id: 'ClearValidation',
      action: () =>
        props.handleResponse({ entity: null, errors: null, ok: true }),
    });
  }

  let balloonPayment =
    result && result.loanTrancheAmortization
      ? result.loanTrancheAmortization.balloonPayment
      : null;
  if (readerData.solvedFor && readerData.solvedFor === 'balloonPayment') {
    const { solvedForValue } = readerData;
    balloonPayment = solvedForValue;
  }

  // We need to update amortization data
  const updatedAmortization = {
    ...currentAmortization,
    // Only update our local amortization if we got a successful response

    ...(!validationErrors && result && result.loanTrancheAmortization
      ? result.loanTrancheAmortization
      : {}),
    loantrancheamortizationprincipalpaymentSet: freshPayments.map(p => ({
      ...p,
      id: uuid(),
    })),
    loantrancheamortizationinterestperiodSet: [],
    id: currentAmortization.id,
    isLeaf: true,
    // Doing this twice?
    readerData,
    balloonPayment,
    // Preserve this value on query failure
    firstPaymentDate: currentAmortization.firstPaymentDate,
  };

  if (readerData.solvedFor) {
    const { solvedFor, solvedForValue } = readerData;

    if (solvedFor === 'initialDrawAmount') {
      actionQueue.push({
        id: 'Set SolvedFor: initialDrawAmount',
        action: () => props.mutateProperty(solvedForValue, solvedFor),
      });
    }

    if (solvedFor === 'fixedInterestRate') {
      actionQueue.push({
        id: 'Set SolvedFor: fixedInterestRate',
        action: () => props.mutateProperty(solvedForValue, solvedFor),
      });
    }

    if (solvedFor === 'fixedPaymentAmount') {
      actionQueue.push({
        id: 'Set SolvedFor: fixedPaymentAmount',
        action: () => props.mutateAmortization(solvedForValue, solvedFor),
      });
    }

    if (solvedFor === 'balloonPayment') {
      actionQueue.push({
        id: 'Set SolvedFor: balloonPmt',
        action: () => props.mutateAmortization(solvedForValue, solvedFor),
      });
    }
  }
  // Update the amortization in redux, preserving id
  actionQueue.push({
    id: 'UpdateAmortization',
    action: () => {
      props.replaceEntity(undoKeyPath, updatedAmortization);
    },
  });

  // Step 4: Sanity Checks
  const unique: Set<string> = new Set(actionQueue.map(a => a.id));

  invariant(actionQueue.length > 0, 'No Amortization actions to perform');
  invariant(actionQueue.length === unique.size, 'Duplicate actions queued');

  // Doesn't make sense to both Clear and Set validation
  invariant(
    unique.has('SetValidation') !== unique.has('ClearValidation'),
    'Either SetValidation or ClearValidation must be performed (But not both)',
  );

  // Something has to alway set the reader data
  invariant(
    unique.has('SetReaderData') !== unique.has('UpdateAmortization'),
    'Either SetReaderData or UpdateAmortization must be performed (But not both)',
  );

  // Step 5: Execute the Queue

  console.log();
  console.log(
    ...formatLog('======================================================='),
  );
  console.group(
    ...formatLog(`Applying ${actionQueue.length} Amortization actions`),
  );
  actionQueue.forEach(a => {
    // console.log(a.id);

    console.log(...formatLog(a.id));
    try {
      a.action();
    } catch (ex) {
      console.warn(`Action ${a.id} Failed!`, ex);
    }
  });
  console.groupEnd();
  console.log(
    ...formatLog('======================================================='),
  );
  /* eslint-enable */
};

function toggleCalculating(props: any) {
  const state: PaymentCalculationStates = 'CALCULATING';
  props.mutateAmortization(state, ['readerData', 'calculationState']);
}

/**
 *
 * @param {*} freshAmortization -

 * @param {*} props
 * @param {*} freshTranche
 */
async function runPaymentsQuery( // has changes that require query exec
  freshAmortization: LoanTrancheAmortizationType,
  props: FormProps, // has changes that require query exec
  freshTranche: LoanTrancheType,
  includePayments: boolean,
  solveFor?: string | null | undefined,
): Promise<AmortizationReaderType | null | undefined> {
  toggleCalculating(props);
  const stalePayments: ReadonlyArray<LoanTrancheAmortizationPrincipalPaymentType> =
    freshAmortization.loantrancheamortizationprincipalpaymentSet || [];

  const payments = includePayments ? stalePayments : [];

  const interestPeriods =
    freshAmortization.loantrancheamortizationinterestperiodSet || [];

  const amortizationResponse:
    | AmortizationResponseType
    | null
    | undefined = await AmortizationPrincipalPaymentsQuery(
    {
      ...strictConvertToInput<
        LoanTrancheAmortizationType,
        LoanTrancheAmortizationInput
      >(freshAmortization),
      // @ts-ignore
      isLeaf: undefined,
      readerDataId: undefined,
    }, // Send payments, if we have any
    strictConvertToInput<LoanTrancheType, LoanTrancheInput>(freshTranche),
    payments,
    interestPeriods,
    solveFor,
  );

  paymentsPostProcessing(
    // Fresh Amort should be query response
    amortizationResponse, // Stale Amort should be the previous data set
    freshAmortization,
    stalePayments,
    props,
  );

  const res: AmortizationReaderType | null | undefined =
    amortizationResponse && amortizationResponse.result
      ? amortizationResponse.result
      : null;

  return res;
}

export default function amortizationThunk(
  trancheId: string,
  props: any,
  includePayments: boolean,
  solveFor?: string | null | undefined,
): (
  dispatch: any,
  getState: any,
) => Promise<AmortizationReaderType | null | undefined> {
  return async function calculateAmortization(
    dispatch: any,
    getState: any,
  ): Promise<AmortizationReaderType | null | undefined> {
    const currentState = getState();

    const trancheFormRootPath = [...rootKeyPath, trancheId];

    if (!currentState.hasIn(trancheFormRootPath)) {
      // eslint-disable-next-line
      console.warn(`Could not find tranche data for ${trancheId}`);
      return null;
    }

    // Need Amortization object that contains changes
    const pendingTranche: LoanTrancheType = currentState
      .getIn([...trancheFormRootPath, 'data'])
      .toJS();

    const amortizations:
      | ReadonlyArray<LoanTrancheAmortizationType>
      | null
      | undefined =
      pendingTranche && pendingTranche.loantrancheamortizationSet
        ? pendingTranche.loantrancheamortizationSet
        : null;

    if (!amortizations) return null;

    const pendingAmortization = amortizations[0];
    const res:
      | AmortizationReaderType
      | null
      | undefined = await runPaymentsQuery(
      pendingAmortization,
      props,
      pendingTranche,
      includePayments,
      solveFor,
    );

    return res;
  };
}
