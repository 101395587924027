import { MutationMethod } from '../types';
import { inputScrubber } from './utils';
import { mutationBuilder } from './builders';
import { MutationExecutor } from './builders/mutationBuilder';
import {
  CloseDealMutationDefinition,
  CreateDealMutationDefinition,
  SetDealMutationDefinition,
} from './definitions';
import { DealInput, DealType } from 'types';

const setDeal: MutationMethod<DealInput, DealType> = mutationBuilder<
  DealInput,
  DealType
>(SetDealMutationDefinition, {
  build: (mutate: MutationExecutor<DealType>) => (dealData: DealInput) =>
    mutate({
      variables: {
        dealData: inputScrubber(dealData),
      },
    }),
});

const createDeal: MutationMethod<DealInput, DealType> = mutationBuilder<
  DealInput,
  DealType
>(CreateDealMutationDefinition, {
  build: (mutate: MutationExecutor<DealType>) => (dealData: DealInput) =>
    mutate({
      variables: {
        dealData: inputScrubber(dealData),
      },
    }),
});

const closeDeal: MutationMethod<DealInput, DealType> = mutationBuilder<
  DealInput,
  DealType
>(CloseDealMutationDefinition, {
  build: (mutate: MutationExecutor<DealType>) => (dealData: DealInput) =>
    mutate({
      variables: {
        dealData: inputScrubber(dealData),
      },
      refetchQueries: ['DealQuery'],
    }),
});

const DealMutationMethods = {
  createDeal,
  closeDeal,
  setDeal,
};

export default DealMutationMethods;
