import * as React from 'react';
import { Form, WrappedMediatorFormProps } from 'components';

import { FolderInput } from 'types';
import { HotkeyWrapper } from 'utils';

const nameField = {
  id: 'name',
  fieldName: 'Folder Name',
  propertyName: 'name',
};

type Props = WrappedMediatorFormProps<FolderInput> & {
  onClose: () => void;
  setAlert: (alert: React.ReactNode, type?: 'success' | 'error') => void;
};

const all = 'all';

export default class FolderRowRenameInput extends React.Component<Props> {
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { props } = this;
    if (nextProps.errors) {
      const error = Form.parseError(
        nextProps.data.id,
        'Folder',
        all,
        nextProps.errors,
      );

      if (error) {
        props.setAlert(
          `Folder with name '${nextProps.data.name ||
            ''}' already exists in this directory.`,
          'error',
        );
        props.onClose();
      }
    }
  }

  handleSubmit = () => {
    this.props.onSave().then(this.props.onClose);
  };

  handleCancel = () => {
    this.props.onClose();
  };

  render() {
    const { errors, mutateProperty, data } = this.props;

    const name = data.name || '';
    const error = errors
      ? Form.parseError(data.id, 'Folder', nameField.id, errors)
      : null;
    return (
      <HotkeyWrapper hotkey="Escape" onPress={this.handleCancel}>
        <Form.Input
          autoFocus
          error={error}
          fieldName="Folder Name"
          {...nameField}
          onBlur={this.handleSubmit}
          onChange={mutateProperty}
          onChangeEventType="onKeyPress"
          value={name}
        />
      </HotkeyWrapper>
    );
  }
}
