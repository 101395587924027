import { fromJS } from 'immutable';
import { SET_FORM_DISABLED } from '../../actions/disabledForms';
import standardReducerFactory from '../standardReducer';

const initialState = fromJS({});

const reducerMap: {
  [key: string]: (state: any, action: any) => Record<string, any>;
} = {};

reducerMap[SET_FORM_DISABLED] = (state, action) =>
  state.set(action.payload.formId, action.payload.value);

export default standardReducerFactory(reducerMap, initialState);
