import { gql } from '@apollo/client';
import { CalendarItemCategoryShallowFields } from '../fragments';

import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { CalendarItemCategoryType } from 'types';

const CalendarItemCategoriesQueryDefinition = gql`
  query CalendarItemCategories {
    calendarItemCategories {
      ...CalendarItemCategoryShallowFields
    }
  }
  ${CalendarItemCategoryShallowFields}
`;

const CalendarItemCategoriesQuery = standardQueryBuilder(
  CalendarItemCategoriesQueryDefinition,
  {
    typename: 'CalendarItemCategories',
    results: (data: {
      calendarItemCategories:
        | Array<CalendarItemCategoryType>
        | null
        | undefined;
    }) => {
      const { calendarItemCategories = [] } = data;
      return { calendarItemCategories };
    },
  },
);

export default CalendarItemCategoriesQuery;
