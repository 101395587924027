import * as React from 'react';
import moment from 'moment';
import CalendarHeaderDateDisplay from './CalendarHeaderDateDisplay';
import {
  dayConst,
  weekConst,
  monthConst,
  threeMonthConst,
  ButtonGroup,
} from 'components';

type Props = {
  changeViewStart: (arg0: Date) => void;
  resetCalendarViewDates: (viewRange: string) => void;
  setViewRange: (arg0: string) => void;
  viewRange: 'week' | 'month' | '3 month';
  viewStart: Date;
};

export default class CalendarHeader extends React.Component<Props> {
  handleSetViewWeek: () => void;

  handleSetViewMonth: () => void;

  handleSetViewThreeMonth: () => void;

  handleNavigateForward: () => void;

  handleNavigateBackward: () => void;

  constructor(props: Props) {
    super(props);
    this.handleNavigateForward = this.createNavigateHandler('FORWARD').bind(
      this,
    );
    this.handleNavigateBackward = this.createNavigateHandler('BACKWARD').bind(
      this,
    );
    this.handleNavigation = this.handleNavigation.bind(this);
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
  }

  handleDateChange = (date: Date) => {
    const { changeViewStart } = this.props;
    changeViewStart(date);
  };

  operateViewStart = (
    direction: 'FORWARD' | 'BACKWARD',
    amount: number,
    range: 'months' | 'weeks',
  ) => {
    const { viewStart } = this.props;
    if (direction === 'FORWARD') {
      this.handleDateChange(
        moment(viewStart)
          .add(amount, range)
          .toDate(),
      );
    } else if (direction === 'BACKWARD') {
      this.handleDateChange(
        moment(viewStart)
          .subtract(amount, range)
          .toDate(),
      );
    }
  };

  createNavigateHandler: (direction: 'FORWARD' | 'BACKWARD') => () => void = (
    direction: 'FORWARD' | 'BACKWARD',
  ) => () => {
    const { viewRange } = this.props;

    switch (viewRange) {
      case weekConst:
        this.operateViewStart(direction, 1, 'weeks');
        break;
      default:
        this.operateViewStart(direction, 1, 'months');
        break;
    }
  };

  handleClickToday = () => {
    const { viewRange, changeViewStart } = this.props;

    const viewStart = moment()
      .startOf(viewRange === weekConst ? weekConst : dayConst)
      .toDate();

    changeViewStart(viewStart);
  };

  componentDidMount = () => {
    this.props.resetCalendarViewDates(this.props.viewRange);
  };
  handleNavigation = (id: string) => {
    if (id === 'BackNavigation') {
      this.handleNavigateBackward();
    } else if (id === 'Today') {
      this.handleClickToday();
    } else {
      this.handleNavigateForward();
    }
  };
  handleDateRangeChange = (id: string) => {
    const { setViewRange } = this.props;
    setViewRange(id);
  };
  render() {
    const { viewRange, viewStart } = this.props;

    /** The navigation buttons are temporarily using the ButtonGroup component through legacy,
     * but they will be updated to match the new UX specs in a future sprint! */
    const navigationProps = {
      options: [
        {
          label: '<',
          id: 'BackNavigation',
        },
        {
          label: 'Today',
          id: 'Today',
        },
        {
          label: '>',
          id: 'ForwardNavigation',
        },
      ],
      onClick: this.handleNavigation,
    };

    const dateRangeProps = {
      options: [
        {
          label: 'Week',
          id: weekConst,
        },
        {
          label: 'Month',
          id: monthConst,
        },
        {
          label: '3-Month',
          id: threeMonthConst,
        },
      ],
      active: viewRange,
      onClick: this.handleDateRangeChange,
    };
    return (
      <div className="calendarHeader">
        <ButtonGroup {...navigationProps} />
        <ButtonGroup {...dateRangeProps} />
        <CalendarHeaderDateDisplay
          onDateChange={this.handleDateChange}
          viewRange={viewRange}
          viewStart={viewStart}
        />
      </div>
    );
  }
}
