import * as React from 'react';
import { LoanTrancheFormProps } from '../types';
import TrancheGroup from './TrancheGroup';
import { Form } from 'components';

const loanTranche = 'LoanTranche';
const width = 'eight';

class UnusedFeeFields extends React.Component<LoanTrancheFormProps> {
  /* eslint-disable-next-line */
  handleUpdateProperty = (
    value: string | null | undefined,
    fieldId: string,
  ) => {
    this.props.onUpdateLoanTrancheRevolverSwinglineLOCData({
      ...this.props.data.loantrancherevolverswinglinelocdata,
      [fieldId]: value,
    });
  };

  fields = {
    unusedFee: {
      id: 'unusedFee',
      propertyName: 'unusedFee',
      fieldName: 'Unused Fee',
      onChange: this.handleUpdateProperty,
      tabIndex: this.props.tabIndex,
      width,
    },
  };

  render() {
    const { data, disabled } = this.props;

    const PercentageComponent = disabled
      ? Form.PercentageReadOnly
      : Form.Percentage;

    return (
      <TrancheGroup>
        <Form.Group>
          {Form.FieldRenderer(
            PercentageComponent,
            this.fields.unusedFee,
            {
              ...this.props,
              data: this.props.data.loantrancherevolverswinglinelocdata,
            },
            loanTranche,
            data.id,
          )}
        </Form.Group>
      </TrancheGroup>
    );
  }
}

export default UnusedFeeFields;
