import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import {
  InterestTrackersShallowFields,
  LoanServicingMoneyShallowFields,
} from '../fragments';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { InterestTrackersType } from 'types';

type OwnProps = {
  dealId: string;
};

const InterestTrackersQueryDefinition: DocumentNode = gql`
  query InterestTrackersQuery($dealId: ID) {
    interestTrackers(dealId: $dealId) {
      ...InterestTrackersShallowFields
      amount {
        ...LoanServicingMoneyShallowFields
      }
      accruedInterestBalance {
        ...LoanServicingMoneyShallowFields
      }
      principal {
        ...LoanServicingMoneyShallowFields
      }
    }
  }
  ${InterestTrackersShallowFields}
  ${LoanServicingMoneyShallowFields}
`;

export const InterestTrackersQuery = standardQueryBuilder(
  InterestTrackersQueryDefinition,
  {
    typename: 'InterestTrackers',
    skip: (props: OwnProps) => {
      const { dealId } = props;
      return !dealId;
    },
    variables: (props: OwnProps) => {
      return props;
    },
    results: (data: {
      interestTrackers: InterestTrackersType | null | undefined;
    }) => {
      const { interestTrackers } = data;
      return { interestTrackers };
    },
  },
);
