import React, { useState } from 'react';
import { LoanTrancheFormProps } from '../../types';
import { ID } from 'types';
import { Form, IconButton, ConfirmModal, AddressFields } from 'components';

import { KeyPath } from 'lsredux';

type CollateralFieldsProps = LoanTrancheFormProps & {
  addEntity: (keyPath: KeyPath, entity: any) => void;
  data: any;
  newCollateralId: ID;
  removeEntity: (keyPath: KeyPath, entity: any) => void;
  replaceEntity: (keyPath: KeyPath, entity: any) => void;
};

const largeWidth = '332px';
const loanTrancheCollateral = 'LoanTrancheCollateral';
const realEstate = 'REAL_ESTATE';
const width = '158px';

const getDefaultCollateral = (newCollateralId: ID) => ({
  id: newCollateralId,
  appliesToAllTranches: false,
  __typename: 'LoanTrancheCollateralType',
});

const getCollateralData = (props: CollateralFieldsProps) => {
  const { data, newCollateralId } = props;
  return data || getDefaultCollateral(newCollateralId);
};

const handleDelete = (props: CollateralFieldsProps) => () =>
  props.removeEntity('loantranchecollateralSet', props.data);

const toggleModal = (
  isModalOpen: boolean,
  setIsModalOpen: (arg0: boolean) => void,
) => () => setIsModalOpen(!isModalOpen);

const CollateralFields = (props: CollateralFieldsProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { cleanData, tabIndex } = props;
  const data = getCollateralData(props);
  const collateralProps = { ...props, data };
  const id: ID = data ? data.id : collateralProps.newCollateralId;

  function handleChange(
    value: (string | null | undefined) | (boolean | null | undefined),
    fieldId: ID,
  ) {
    const field = fieldId.split('_')[0];

    if (data) {
      // edit
      props.replaceEntity('loantranchecollateralSet', {
        ...data,
        [field]: value,
      });
    } else {
      // add
      props.addEntity('loantranchecollateralSet', {
        id: props.newCollateralId,
        __typename: 'LoanTrancheCollateralType',
        [field]: value,
      });
    }
  }

  const collateralFields = {
    appliesToAllTranches: {
      propertyName: 'appliesToAllTranches',
      label: 'Applies to new tranches',
      onChange: handleChange,
      tabIndex,
      width,
    },
    collateral: {
      propertyName: 'collateral',
      fieldName: 'Collateral',
      typeName: 'LoanTrancheCollateralCollateral',
      onChange: handleChange,
      tabIndex,
      width,
    },
    collateralValue: {
      propertyName: 'collateralValue',
      fieldName: 'Collateral Value',
      onChange: handleChange,
      tabIndex,
      width,
    },
    collateralDescription: {
      propertyName: 'collateralDescription',
      fieldName: 'Collateral Description',
      fluid: true,
      onChange: handleChange,
      tabIndex,
      width: largeWidth,
    },
  };

  return (
    <div className="CollateralsRow">
      <Form.Group>
        {Form.FieldRenderer(
          Form.ReferenceSelect,
          {
            id: 'collateral',
            ...collateralFields.collateral,
          },
          collateralProps,
          loanTrancheCollateral,
          id,
        )}
        {Form.FieldRenderer(
          Form.Money,
          {
            id: 'collateralValue',
            ...collateralFields.collateralValue,
          },
          collateralProps,
          loanTrancheCollateral,
          id,
        )}
        {id !== collateralProps.newCollateralId && (
          <IconButton.Delete
            alt="Remove Collateral"
            className="CollateralFields-DeleteButton"
            onClick={toggleModal(isModalOpen, setIsModalOpen)}
          />
        )}
        <ConfirmModal
          header="Warning"
          isOpen={isModalOpen}
          message={
            cleanData.name
              ? `Are you sure you want to permanently remove this collateral from ${cleanData.name}?`
              : 'Are you sure you want to permanently remove this collateral?'
          }
          onConfirm={handleDelete(collateralProps)}
          onReject={toggleModal(isModalOpen, setIsModalOpen)}
        />
      </Form.Group>
      {data.collateral === realEstate && (
        <AddressFields
          data={data}
          disabled={props.disabled}
          handleChange={handleChange}
          isSaving={props.isSaving}
          parentType={loanTrancheCollateral}
          typeName="LoanTrancheCollateralState"
        />
      )}
      <Form.Group>
        {Form.FieldRenderer(
          Form.TextArea,
          {
            id: 'notes',
            ...collateralFields.collateralDescription,
          },
          collateralProps,
          loanTrancheCollateral,
          id,
        )}
      </Form.Group>
      {collateralProps.isFirstTranche && (
        <Form.Group>
          {Form.FieldRenderer(
            Form.Checkbox,
            {
              id: 'appliesToAllTranches',
              value: data.appliesToAllTranches,
              ...collateralFields.appliesToAllTranches,
            },
            collateralProps,
            loanTrancheCollateral,
            id,
          )}
        </Form.Group>
      )}
    </div>
  );
};

export default CollateralFields;
