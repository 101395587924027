import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import WorkflowContentShell from '../WorkflowContentShell';
import { WorkflowSection } from '../workflowList';
import BusinessInformationSection from './BusinessInformationSection';
import LoanInformationSection from './LoanInformationSection';
import CoveredPeriodSection from './CoveredPeriodSection';
import DemographicsForm from './modals/DemographicsFormContainer';
import { SbaHelpBox } from './sbaHelpBox/SbaHelpBox';
import {
  WrappedMediatorFormProps,
  Button,
  Text,
  RouteTo,
  Form,
} from 'components';
import { DealType } from 'types';
import { RouteTable } from 'routing/RouteTable';
import { haltBubble } from 'utils';

import './LoanInformationForm.scss';

type DealFormProps = WrappedMediatorFormProps<DealType>;

function LoanInformationForm(props: DealFormProps) {
  const { data, disabled } = props;
  const { sbaPppLoanApplication, sbaPppForgivenessWorkflow } = data;
  const [isDemographicModalOpen, setIsDemographicModalOpen] = useState(false);
  /** Ideally, we would only change the button to Edit & list principals once
   * the user has filled in information, but triggering refetching from one
   * container to another isn't something we currently support.
   */
  const [wasDemographicModalOpened, setWasDemographicModalOpened] = useState(
    false,
  );

  if (!sbaPppLoanApplication) {
    return null;
  }

  const owners = sbaPppLoanApplication.sbapppapplicantownershipSet;

  const handleMutateProperty = (
    value:
      | (string | null | undefined)
      | (boolean | null | undefined)
      | (Array<any> | null | undefined),
    fieldId: string,
  ) => {
    const path = [
      'sbaPppForgivenessWorkflow',
      'sbapppforgivenessloaninformation',
      fieldId,
    ];
    props.mutateProperty(value, path);
  };

  const handleDemographicModalToggle = (e?: React.SyntheticEvent<any>) => {
    e && haltBubble(e);
    setIsDemographicModalOpen(!isDemographicModalOpen);
    setWasDemographicModalOpened(true);
  };

  const renderCtaButtons = () => (
    <div className="CtaButtons__Wrapper">
      <Button disabled={disabled} label="Save" onClick={props.onSave} />
      <Button.Secondary
        as={RouteTo}
        label={`Next: Forgiveness ${
          sbaPppForgivenessWorkflow?.isSFormEligible ? 'Amount' : 'Calculation'
        }`}
        to={RouteTable.deal.toSbaPppForgivenessWorkflowCalculation(data.id)}
      />
    </div>
  );

  return (
    <WorkflowContentShell
      className="LoanInformationForm"
      ctaButtons={renderCtaButtons}
      helpBox={!disabled ? <SbaHelpBox {...props} /> : undefined}
      title="Loan Information"
    >
      <Prompt
        message="There are unsaved changes on this form that will be lost if you continue."
        when={props.isDirty}
      />
      <div className="Stack">
        <Text className="ConstrictedContent">
          Some fields have been pre-filled from your loan application. Contact
          your lender if any of this information has changed or is incorrect.
        </Text>
        <Text>Please complete any remaining applicable fields.</Text>
      </div>
      <WorkflowSection header="BUSINESS INFORMATION">
        <BusinessInformationSection {...props} />
      </WorkflowSection>
      <WorkflowSection header="SBA PPP LOAN INFORMATION">
        <LoanInformationSection
          {...props}
          mutateProperty={handleMutateProperty}
          rawMutateProperty={props.mutateProperty}
        />
      </WorkflowSection>
      {/* REPLACED "Payroll Information" IN ROUND TWO */}
      <WorkflowSection header="COVERED PERIOD">
        <CoveredPeriodSection
          {...props}
          mutateProperty={handleMutateProperty}
        />
      </WorkflowSection>
      {/* REMOVED IN ROUND TWO */}
      {/* <WorkflowSection header="EIDL INFORMATION"><EidlInformationSection {...props} mutateProperty={handleMutateProperty} /></WorkflowSection> */}
      {!!owners.length && (
        <WorkflowSection header="DEMOGRAPHIC INFORMATION (OPTIONAL)">
          <Form.Group className="Principals">
            {owners.length > 0 && (
              <Text>
                {owners.length} principal{owners.length > 1 && 's'}
              </Text>
            )}
            <Button.Secondary
              label={wasDemographicModalOpened ? 'Edit' : 'Provide Info'}
              onClick={handleDemographicModalToggle}
            />
            {isDemographicModalOpen && (
              <DemographicsForm
                isOpen={isDemographicModalOpen}
                onClose={handleDemographicModalToggle}
              />
            )}
          </Form.Group>
        </WorkflowSection>
      )}
    </WorkflowContentShell>
  );
}

export default LoanInformationForm;
