import * as React from 'react';

type Props = {};

class ExpiredInvitation extends React.Component<Props> {
  render() {
    return <div>Expired Invitation</div>;
  }
}

export default ExpiredInvitation;
