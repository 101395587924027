import * as React from 'react';
import noop from 'lodash/noop';

import './LoanTrancheAmortization.scss';

import { LoanTrancheFormProps } from '../../types';
import { PaymentRowProps } from './PaymentRow';
import { InterestScheduleRowProps } from './InterestScheduleRow';
import createInterestScheduleTable from './InterestScheduleTable';
import createPaymentScheduleTable from './PaymentScheduleTable';
import { invariant } from 'utils';
import { DealPerspectivePermissions, MountGuard } from 'security';
import {
  AmortizationReaderRefType,
  LoanTrancheFormType,
  LoanTrancheAmortizationPrincipalPaymentType,
  AmortizationWithReaderDataType,
} from 'types';
import { Form, LoadingIndicator, Tab, TableColumn } from 'components';

/* eslint-disable react/no-multi-comp */

export type GenericSolveForRowProps = {
  handleSolveFor: (arg0: string) => void;
};

export type GenericAmortizationFormProps = LoanTrancheFormProps & {
  amortization: AmortizationWithReaderDataType;
};

export type Args = {
  columns: Array<TableColumn>;
  formComponent: React.ComponentType<GenericAmortizationFormProps>;

  interestSchedule?: {
    columns: ReadonlyArray<TableColumn>;
    rowComponent: React.ComponentType<InterestScheduleRowProps>;
  };
  paymentRowComponent: React.ComponentClass<PaymentRowProps>;
  solveForRowComponent: React.ComponentType<GenericSolveForRowProps>;
};

type LoanTrancheAmortizationProps = LoanTrancheFormProps;

function CreateAmortizationForm(args: Args) {
  const FormComponent = args.formComponent;
  const SolveForRowComponent = args.solveForRowComponent;
  const PaymentScheduleTable = createPaymentScheduleTable({
    columns: args.columns,
    paymentRowComponent: args.paymentRowComponent,
  });

  const InterestScheduleTable = args.interestSchedule
    ? createInterestScheduleTable({
        columns: args.interestSchedule.columns,
        interestRowComponent: args.interestSchedule.rowComponent,
      })
    : null;

  return class LoanTrancheAmortization extends React.Component<
    LoanTrancheAmortizationProps
  > {
    static stGetAmortization(
      data: LoanTrancheFormType,
    ): AmortizationWithReaderDataType | null | undefined {
      if (
        !data ||
        !data.loantrancheamortizationSet ||
        data.loantrancheamortizationSet.length === 0
      )
        return null;

      if (data.loantrancheamortizationSet.length > 1) {
        // eslint-disable-next-line
        console.warn(
          `Multiple amortization objects exist for tranche ${data.id}`,
        );
      }
      const amortization = data.loantrancheamortizationSet[0];
      return amortization;
    }

    panes = [];

    constructor(props: LoanTrancheAmortizationProps) {
      super(props);

      invariant(
        props.data.loantrancheamortizationSet &&
          props.data.loantrancheamortizationSet.length > 0,
        'Amortization object does not exist',
      );
      invariant(
        props.data.loantrancheamortizationSet &&
          props.data.loantrancheamortizationSet.length === 1,
        'More than one amortization object exists, this is an unhandled scenario',
      );

      this.panes = [
        {
          menuItem: 'Interest Rate Schedule',
          render: this.renderInterestTable,
        },
        {
          menuItem: 'Payment Schedule',
          render: this.renderPaymentsTable,
        },
      ];
    }

    componentDidMount() {
      /*
      We lack complete data without running the paymentsQuery,
      so always run it onMount
      */
      const amortization = LoanTrancheAmortization.stGetAmortization(
        this.props.data,
      );
      // Include payments and check if they're the same
      // eslint-disable-next-line
      if (!amortization) console.warn('Failed to find an amortization obejct');
    }

    getAmortization = () => {
      const amortization = LoanTrancheAmortization.stGetAmortization(
        this.props.data,
      );

      const isStaged = Boolean((amortization as any).isDirty);

      return { amortization, isStaged };
    };

    handlePaymentChange = (
      value: string | null | undefined,
      fieldId: string,
      index: number,
    ) => {
      const { amortization } = this.getAmortization();
      if (!amortization) return;

      const {
        loantrancheamortizationprincipalpaymentSet: initialPayments,
      } = amortization;

      if (index >= (initialPayments || []).length) return;

      const payments: ReadonlyArray<LoanTrancheAmortizationPrincipalPaymentType> = (initialPayments as any) as ReadonlyArray<
        LoanTrancheAmortizationPrincipalPaymentType
      >;

      // Update the payment object
      const updatedPayment = { ...payments[index], [fieldId]: value };

      // Send the update to redux
      this.props.mutatePayment(updatedPayment);
    };

    handleRateChange = (
      value: string | null | undefined,
      fieldId: string,
      index: number,
    ) => {
      const { amortization } = this.getAmortization();
      if (!amortization) return;

      const {
        loantrancheamortizationinterestperiodSet: rates = [],
      } = amortization;

      if (index >= rates.length) return;

      // Update the payment object
      const updatedRate = { ...rates[index], [fieldId]: value };

      // Send the update to redux
      this.props.mutateInterestPeriod(updatedRate);
    };

    renderPaymentsTable = () => {
      const { amortization } = this.getAmortization();

      if (!amortization) return null;
      const { readerData } = amortization;

      const principalMap =
        readerData && (readerData as any).principalMap
          ? (readerData as any).principalMap
          : {};
      return (
        <PaymentScheduleTable
          amortization={amortization}
          onPaymentChange={this.handlePaymentChange}
          principalMap={principalMap}
        />
      );
    };

    renderInterestTable = () => {
      const { amortization } = this.getAmortization();

      if (!amortization) return null;

      return (
        <InterestScheduleTable
          amortization={amortization}
          onRateChange={this.handleRateChange}
        />
      );
    };

    renderGeneralErrors = (
      readerData: AmortizationReaderRefType | null | undefined,
    ) => {
      const { errors } = this.props;

      return (
        <div className="LoanTrancheAmortization__CalculationStatus">
          {errors && (
            <Form.ErrorList
              className="LoanTrancheAmortization__FormErrorList"
              errors={errors}
              reserveSpace
            />
          )}
          {!errors &&
            readerData &&
            readerData.calculationState === 'CALCULATED' &&
            'Calculation Complete.'}
        </div>
      );
    };

    render() {
      const { data, handleSolveFor } = this.props;
      const { amortization } = this.getAmortization();

      if (!amortization) return null;

      const { readerData } = amortization;

      return (
        <div className="LoanTrancheAmortization">
          <Form
            className="LoanTrancheAmortization--Form"
            id={`AmortizationForm_${data.id}`}
            onSubmit={noop}
          >
            <FormComponent {...this.props} amortization={amortization} />
            <MountGuard
              permission={DealPerspectivePermissions.administer_loan_tranches}
            >
              <SolveForRowComponent handleSolveFor={handleSolveFor} />
            </MountGuard>
            {this.renderGeneralErrors(readerData)}
            <div className="AmortizationTable__Container">
              {readerData && readerData.calculationState === 'CALCULATING' && (
                <LoadingIndicator absolute dimmed />
              )}
              {args.interestSchedule ? (
                <Tab panes={this.panes} />
              ) : (
                this.renderPaymentsTable()
              )}
            </div>
          </Form>
        </div>
      );
    }
  };
}

export default CreateAmortizationForm;
