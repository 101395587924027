import { gql } from '@apollo/client';
import {
  DealBasicFields,
  UserShallowFields,
  InstitutionBasicFields,
} from '../fragments/generated';
import getTestClient from '../testClient';

/* eslint-disable */
const SetupDealLeadLenderMutationDefinition = gql`
  mutation SetupDealLeadLenderMutation {
    setupDealLeadLender {
      ok
      deal {
        ...DealBasicFields
      }
      user {
        ...UserShallowFields
      }
      institution {
        ...InstitutionBasicFields
      }
    }
  }
  ${DealBasicFields}
  ${UserShallowFields}
  ${InstitutionBasicFields}
`;

/* eslint-enable */

function SetupDealLeadLenderMutation() {
  const client = getTestClient();
  return client.mutate({
    mutation: SetupDealLeadLenderMutationDefinition,
  });
}

export default SetupDealLeadLenderMutation;
