import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import {
  InstitutionShallowFields,
  InstitutionBankAccountShallowFields,
  UserShallowFields,
  SbaAccountShallowFields,
} from '../fragments';
import formQueryBuilder from './queryBuilders/formQueryBuilder';
import permissionQueryBuilder from './queryBuilders/permissionQueryBuilder';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { InstitutionQueryDefinition } from './definitions';
import { InstitutionInput, InstitutionType } from 'types';

const InstitutionFormQueryDefinition: DocumentNode = gql`
  query InstitutionQuery($id: ID) {
    institution(id: $id) {
      ...InstitutionShallowFields
      institutionbankaccountSet {
        ...InstitutionBankAccountShallowFields
      }
      subsidiaries {
        id
        name
        institutionbankaccountSet {
          id
          accountNickname
          hiddenAccountNumber
          trimmedAccountNumber
        }
      }
      sbaPrimaryContact {
        ...UserShallowFields
      }
      userSet {
        ...UserShallowFields
      }
      sbaAccount {
        ...SbaAccountShallowFields
      }
    }
  }
  ${InstitutionShallowFields}
  ${InstitutionBankAccountShallowFields}
  ${UserShallowFields}
  ${SbaAccountShallowFields}
`;

export const InstitutionFormQuery = formQueryBuilder(
  InstitutionFormQueryDefinition,
  {
    typename: 'Institution',
    skip: (props: { institutionId: string | null | undefined }) => {
      const { institutionId } = props;
      return !institutionId;
    },
    variables: (props: { institutionId: string | null | undefined }) => {
      const { institutionId } = props;
      return { id: institutionId };
    },
    results: (data: { institution: InstitutionInput | null | undefined }) => {
      const { institution } = data;
      return institution;
    },
  },
);

export const InstitutionQuery = standardQueryBuilder(
  InstitutionFormQueryDefinition,
  {
    typename: 'Institution',
    skip: (props: { institutionId: string | null | undefined }) => {
      const { institutionId } = props;
      return !institutionId;
    },
    variables: (props: { institutionId: string | null | undefined }) => {
      const { institutionId } = props;
      return { id: institutionId };
    },
    results: (data: { institution: InstitutionInput | null | undefined }) => {
      const { institution } = data;
      return { institution };
    },
  },
);

export const InstitutionPermissionQuery = permissionQueryBuilder(
  InstitutionQueryDefinition,
  {
    typename: 'Institution',
    skip: (props: { institutionId: string | null | undefined }) => {
      const { institutionId } = props;
      return !institutionId;
    },
    variables: (props: { institutionId: string | null | undefined }) => {
      const { institutionId } = props;
      return { id: institutionId };
    },
    results: (data: { institution: InstitutionType | null | undefined }) => {
      const { institution } = data;
      return { institution };
    },
  },
);

const InstitutionSubsidiariesQueryDefinition: DocumentNode = gql`
  query InstitutionQuery($id: ID) {
    institution(id: $id) {
      subsidiaries {
        id
        name
      }
    }
  }
`;

export const InstitutionSubsidiariesQuery = standardQueryBuilder(
  InstitutionSubsidiariesQueryDefinition,
  {
    typename: 'Institution',
    skip: (props: { institutionId: string | null | undefined }) => {
      const { institutionId } = props;
      return !institutionId;
    },
    variables: (props: { institutionId: string | null | undefined }) => {
      const { institutionId } = props;
      return { id: institutionId };
    },
    results: (data: { institution: InstitutionType | null | undefined }) => {
      const { institution } = data;
      if (institution) {
        return {
          subsidiaries: institution.subsidiaries,
        };
      }
      return { subsidiaries: null };
    },
  },
);
