import * as React from 'react';
import { Button, Form, RouteTo } from 'components';
import { DealClosingWorkflowStepType } from 'types';

import '../DealClosingWorkflow.scss';

type Props = {
  data: DealClosingWorkflowStepType;
  updateStep: (stepData: DealClosingWorkflowStepType) => void;
  disabled: boolean;
};

const DealClosingWorkflowStep = 'dealClosingChecklist';

function DealClosingWorkflow(props: Props) {
  const { data } = props;
  if (data) {
    const formField = {
      id: 'isComplete',
      propertyName: 'isComplete',
      label: 'Complete',
      onChange: (value: boolean) => {
        props.updateStep({ ...data, isComplete: value, userOverridden: true });
      },
      disabled: props.disabled || !data.isEditable,
    };

    return (
      <div className="DealClosingWorkflowStep">
        <div className="StepCheckbox">
          {data.label && data.labelLink && (
            <Button.Text as={RouteTo} label={data.label} to={data.labelLink} />
          )}
          {Form.FieldRenderer(
            Form.Checkbox,
            formField,
            props,
            DealClosingWorkflowStep,
            data.id,
          )}
        </div>
      </div>
    );
  }
  return null;
}

export default DealClosingWorkflow;
