import * as React from 'react';
import cx from 'classnames';
import { haltBubble, ux } from '../../utils';
import './DataGridHeaderCell.scss';

/**
 * A data grid header cell.
 */

export interface DataGridHeaderCellProps {
  /**
   * The name of the column as it will be rendered in the header.
   */
  className?: string;
  /**
   * The column name for the header.
   */
  columnName: string;
  /**
   * A function to call when the header cell is clicked.
   */
  customHeaderCell?: (name: string) => React.ReactNode;
  displayName: string;
  onClickHeaderCell?: (x0: string) => void;
}

class DataGridHeaderCell extends React.Component<DataGridHeaderCellProps> {
  static defaultProps = {
    onClickHeaderCell: () => undefined,
  };

  handleClickHeaderCell = (e: React.SyntheticEvent) => {
    haltBubble(e);
    e.persist();
    if (this.props.onClickHeaderCell)
      this.props.onClickHeaderCell(this.props.columnName);
  };

  render() {
    const computedClass = cx(
      'DataGridHeaderCell',
      ux(this.props.className, this.props.className),
    );

    return (
      <div className={computedClass} onClick={this.handleClickHeaderCell}>
        {this.props.customHeaderCell
          ? this.props.customHeaderCell(this.props.displayName)
          : this.props.displayName}
      </div>
    );
  }
}

export default DataGridHeaderCell;
