import { sortBy } from 'lodash';
import { SelectOptionType } from 'types';

/**
 * Sort a list of options to be used by Form.Select (dropdown)
 * @param  {Array<Object>} options    Array containing option object(s)
 * @return {Array<Object>}            Return a new object that represents the diff
 */
function sortDropdownOptions(
  options: Array<SelectOptionType>,
): Array<SelectOptionType> {
  return sortBy(options, 'text');
}

export default sortDropdownOptions;
