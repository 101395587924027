import {
  REACT_APP_AUTHENTICATION_CHECK_URL,
  REACT_APP_DJANGO_LOGOUT_URL,
} from '../../url';

/* eslint-disable import/prefer-default-export */
const logoutHeader: RequestInit = {
  method: 'GET',
  credentials: 'include',
  mode: 'cors',
};

/**
 * Send a Logout request to the backend
 */
export function executeLogout() {
  return fetch(REACT_APP_DJANGO_LOGOUT_URL, logoutHeader);
}

const requestHeader: RequestInit = {
  method: 'GET',
  credentials: 'include',
  mode: 'cors',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
};

/**
 * Ask the backend if the current user has successfully authenticated
 */
export function CheckAuthentication(): Promise<{
  is_authenticated: boolean;
}> {
  return fetch(REACT_APP_AUTHENTICATION_CHECK_URL, requestHeader).then(
    response => response.json(),
    failure => {
      // eslint-disable-next-line
      console.warn('CheckAuthentication failure: ', failure);
      return {
        is_authenticated: false,
      };
    },
  );
}

/* eslint-enable */
