import { isIndexed, isKeyed, fromJS, isImmutable } from 'immutable';
import { EntityPayloadAction } from '../types';
import { entityValidator, flagDirty, getKeyPath, idKey } from '../methods';
import { invariant } from 'utils';

/* eslint-disable import/prefer-default-export */

/**
 * Within a collection at the specified action.keyPath, replace
 * an entity by id
 * @param {*} state
 * @param {*} action
 */
export function handleReplaceEntity(
  state: any,
  action: EntityPayloadAction,
): Record<string, any> {
  invariant(
    action.payload.entity,
    'Cannot replace with a null/undefined entity, if you are attempting to delete, use the delete action instead',
  );
  const { keyPath: pendingKeyPath, entity } = action.payload;
  const keyPath = getKeyPath(pendingKeyPath);

  entityValidator(entity);

  const pending = flagDirty(entity);

  const target = state.getIn(keyPath);

  invariant(
    target,
    `Nothing found for keypath ${keyPath.join(
      ',',
    )}.  Replace should not be used to create new entries`,
  );

  let didReplace = false;
  if (isIndexed(target)) {
    const collection: any = target;

    const updatedCollection = collection
      .map(x => {
        if (x.get(idKey) === pending.id) {
          didReplace = true;
          return fromJS(pending);
        }
        return x;
      })
      .toList();

    return didReplace
      ? state.setIn(keyPath, updatedCollection)
      : state.setIn(keyPath, collection.push(fromJS(pending)));
  }
  if (isKeyed(target)) {
    const map: Map<string, any> = target as any;
    invariant(
      map.get(idKey) === pending.id,
      'Can only replace if Id values match',
    );

    return state.setIn(keyPath, fromJS(pending));
  }

  invariant(
    isImmutable(target),
    `KeyPath ${keyPath.join(',')} did not contain an Immutable collection`,
  );
  throw new Error('Failed to replace');
}
