import * as React from 'react';
import { MoneyInput } from '@loanstreet-usa/design-system';
import { haltBubble } from '../../utils';
import { valueLimits } from '../../resources';
import { DataGridInputProps } from './types';

import './DataGridMoneyInput.scss';

type Props<T, K extends keyof T> = DataGridInputProps<T, K> & {
  addDollarSymbol?: boolean;
  disabled?: boolean;
  maxValue?: number;
  minValue?: number;
};

class DataGridMoneyInput<T, K extends keyof T> extends React.Component<
  Props<T, K>
> {
  static defaultProps = {
    addDollarSymbol: true,
    maxValue: valueLimits.maxDollarAmount,
    minValue: 0,
    value: '',
    onCancel: () => undefined,
    onValueChanged: () => undefined,
  };

  inputIdentifier: string;

  constructor(props: Props<T, K>) {
    super(props);

    this.inputIdentifier = `${props.columnName}_${props.rowKey}_${props.index}`;
  }

  handleOnChange = (e: React.SyntheticEvent) => {
    haltBubble(e);
    if (this.props.onValueChanged)
      //@ts-ignore
      this.props.onValueChanged(e.currentTarget.value);
  };

  handleOnCancel = (e: React.SyntheticEvent) => {
    haltBubble(e);
    if (this.props.onCancel) this.props.onCancel();
  };

  handleOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    haltBubble(e);
    if (e.key === 'Escape') {
      if (this.props.onCancel) this.props.onCancel();
    }
  };

  render() {
    const { addDollarSymbol, disabled } = this.props;

    return (
      <MoneyInput
        addDollarSymbol={addDollarSymbol}
        autoFocus={false}
        className="DataGridMoneyInput"
        disabled={disabled}
        id={this.inputIdentifier}
        name={this.inputIdentifier}
        onBlur={this.handleOnCancel}
        onChange={this.handleOnChange}
        onChangeEventType="onBlur"
        onKeyUp={this.handleOnKeyUp}
        placeholder="$"
        value={this.props.value}
      />
    );
  }
}

export default DataGridMoneyInput;
