import * as React from 'react';
import SecureMenuItem from './SecureMenuItem';
import { FontAwesomeIconTypes, Divider } from 'components';
import { RouteTable } from 'routing/RouteTable';
import { GlobalPerspectivePermissions } from 'security';

const homeIcon: FontAwesomeIconTypes = ['fal', 'home'];

type Props = {
  location: string;
};

function PortfolioMenuItem(props: Props) {
  return (
    <>
      <SecureMenuItem
        active={props.location === '/'}
        className="MenuItem--Home"
        icon={homeIcon}
        permission={GlobalPerspectivePermissions.view_home_button}
        route={RouteTable.application.toHome}
        title="Portfolio"
      />
      <Divider className="transparent" />
    </>
  );
}

export default PortfolioMenuItem;
