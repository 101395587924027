/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import Form from '../wrappers/Form';
import { ValidationMap, FormFieldsType } from 'types';

type Props = {
  data: any;
  disabled?: boolean;
  errors?: ValidationMap | null | undefined;
  handleChange: (
    value: (boolean | string) | null | undefined,
    fieldId: any,
  ) => void;
  isSaving: boolean;
  parentType: any;
  small?: boolean;
  typeName: string;
};

const fields: FormFieldsType = {
  addressLine1: {
    id: 'addressLine1',
    propertyName: 'addressLine1',
    fieldName: 'Address',
    width: '256px',
  },
  addressLine2: {
    id: 'addressLine2',
    propertyName: 'addressLine2',
    fieldName: 'Address Line 2',
    placeholder: 'Department / Floor / Suite...',
    width: '256px',
  },
  state: {
    id: 'state',
    propertyName: 'state',
    fieldName: 'State',
    resultFilter: res =>
      res.map(option => ({ text: option.value, value: option.value })),
    placeholder: '--',
    width: '52px',
  },
  city: {
    id: 'city',
    propertyName: 'city',
    fieldName: 'City',
    width: '188px',
  },
  zipCode: {
    id: 'zipCode',
    propertyName: 'zipCode',
    fieldName: 'Zip Code',
    width: '120px',
  },
};

function AddressFields(props: Props) {
  const { data, handleChange, parentType, typeName } = props;
  if (!data) return null;

  return (
    <>
      <Form.Group>
        {Form.FieldRenderer(
          Form.Input,
          {
            ...fields.addressLine1,
            onChange: handleChange,
          },
          props,
          parentType,
          data.id,
        )}
      </Form.Group>
      <Form.Group>
        {Form.FieldRenderer(
          Form.Input,
          {
            ...fields.addressLine2,
            onChange: handleChange,
          },
          { ...props, saving: false },
          parentType,
          data.id,
        )}
      </Form.Group>
      <Form.Group>
        {Form.FieldRenderer(
          Form.Input,
          {
            ...fields.city,
            onChange: handleChange,
          },
          props,
          parentType,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.ReferenceSelect,
          {
            ...fields.state,
            onChange: handleChange,
            typeName,
          },
          props,
          parentType,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Zip,
          {
            ...fields.zipCode,
            onChange: handleChange,
          },
          props,
          parentType,
          data.id,
        )}
      </Form.Group>
    </>
  );
}

export default AddressFields;
