import * as React from 'react';
import RoleSelect from '../../../../../roleSelect/RoleSelect';
import { OrganizationPermissions } from '../../../../types';
import { ID, SetUserDealRoleType, DealUserType } from 'types';
import { AccessDeniedGuard, DisabledChildProps } from 'security';

import { RolePermissionMutationMethods } from 'lsgql/mutations';

type Props = {
  currentUserId: ID;
  dealId: ID;
  isBorrower: boolean;
  permissions: OrganizationPermissions;
  user: DealUserType;
};

class UserRoleCell extends React.Component<Props> {
  handleRoleChange = (dealRoleId: ID) => {
    const { dealId, user } = this.props;

    if (user.dealRole && user.dealRole.id === dealRoleId) return;

    const updatedRole: SetUserDealRoleType = {
      dealId,
      roleId: dealRoleId,
      userId: user.id,
    };

    RolePermissionMutationMethods.setUserDealRole(updatedRole);
  };

  renderEdit = () => {
    const { user, isBorrower } = this.props;
    const { dealRole } = user;

    const dealRoleId: ID = dealRole ? dealRole.id : '';

    return (
      <RoleSelect
        inline
        onChange={this.handleRoleChange}
        roleContext={isBorrower ? 'deal_borrower' : 'deal_institution'}
        selected={dealRoleId}
      />
    );
  };

  renderName = () => {
    const { user } = this.props;

    return user && user.dealRole && user.dealRole.name
      ? user.dealRole.name
      : '-';
  };

  renderCell = (childProps: DisabledChildProps) => {
    const { accessDenied } = childProps;
    const { user, currentUserId } = this.props;

    return accessDenied || currentUserId === user.id
      ? this.renderName()
      : this.renderEdit();
  };

  render() {
    const { permissions } = this.props;
    return (
      <AccessDeniedGuard permission={permissions.adminPermission}>
        {this.renderCell}
      </AccessDeniedGuard>
    );
  }
}

export default UserRoleCell;
