/* eslint-disable react/default-props-match-prop-types */

import uuid from 'uuid/v4';
import * as React from 'react';
import { creditRatings } from '../../resources';
import CreditRatingFields from './CreditRatingFields';
import { Button } from 'components';
import { CreditRatingType, ValidationMap } from 'types';

import './CreditRatingsForm.scss';

const agencies = [
  creditRatings.fitch.name,
  creditRatings.moodys.name,
  creditRatings.sAndP.name,
];

const agenciesMap = {};

agenciesMap[creditRatings.moodys.name] = true;
agenciesMap[creditRatings.sAndP.name] = true;
agenciesMap[creditRatings.fitch.name] = true;

type Props = {
  creditratingSet: ReadonlyArray<CreditRatingType>;

  disabled?: boolean;

  /**
    flags if component is used in a fixed width form
  */
  errors?: ValidationMap | null | undefined;

  isFixedWidth: boolean;

  isSaving: boolean;
  onChange: (rating: CreditRatingType) => void;
  onDelete: (rating: CreditRatingType) => void;
  tabIndex?: number;
  width: 'four' | 'eight' | 'three';
};

class CreditRatingsForm extends React.Component<Props> {
  static defaultProps = {
    creditratingSet: [],
    width: 'eight',
    isFixedWidth: false,
  };

  getPlaceholder = () => ({
    id: uuid(),
    agency: '',
    rating: '',
    __typename: 'CreditRatingType',
  });

  handleAddNewAgencyRating = () => {
    const { creditratingSet } = this.props;
    // find the first unused
    // this has to get the first unused agency and explicitly set it
    const usedAgencies = new Set(
      creditratingSet
        .filter(
          (agencyObj: CreditRatingType) => agenciesMap[agencyObj.agency || ''],
        )
        .map(e => e.agency),
    );

    const nextAgency = agencies.find(e => !usedAgencies.has(e));
    if (nextAgency) {
      this.props.onChange({
        ...this.getPlaceholder(),
        agency: nextAgency,
      } as any);
    } else {
      // Add a custom rating if we can't find the next
      this.props.onChange({ ...this.getPlaceholder(), agency: '' } as any);
    }
  };

  handleInitializeCustomRatingField = () => {
    this.props.onChange({
      id: uuid(),
      agency: '',
      rating: '',
      __typename: 'CreditRatingType',
    });
  };

  render() {
    const {
      creditratingSet,
      isFixedWidth,
      disabled,
      errors,
      isSaving,
    } = this.props;

    const selectedAgencies: Set<string> = new Set(
      creditratingSet.filter(e => Boolean(e.agency)).map(e => e.agency),
    );

    return (
      <>
        {(creditratingSet && creditratingSet.length > 0
          ? creditratingSet
          : ([this.getPlaceholder()] as any)
        ).map((e: any) => (
          <CreditRatingFields
            data={e}
            disabled={disabled}
            errors={errors}
            isCustomRating={
              !agenciesMap[e.agency || ''] && creditratingSet.length > 0
            }
            isFixedWidth={isFixedWidth}
            isSaving={isSaving}
            isStub={creditratingSet.length === 0}
            key={e.id}
            onChange={this.props.onChange}
            onDelete={this.props.onDelete}
            selectedAgencies={selectedAgencies}
            tabIndex={this.props.tabIndex}
            width={this.props.width}
          />
        ))}
        {creditratingSet && creditratingSet.length > 0 && (
          <Button.Text
            className="CreditRatingsForm--Button"
            disabled={disabled}
            label="+ Add Another Rating"
            onClick={this.handleAddNewAgencyRating}
            tabIndex={this.props.tabIndex}
          />
        )}
      </>
    );
  }
}

export default CreditRatingsForm;
