const minimumWidth = 768;
const minimumHeight = 600;

function isResolutionOutofbounds(): boolean {
  return (
    window.screen.width < minimumWidth || window.screen.height < minimumHeight
  );
}

export default isResolutionOutofbounds;
