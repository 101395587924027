import * as React from 'react';
import { FileWithDate, DataroomContext, ContextPropTypes } from '../types';
import { MenuRenderProps } from '../dataroomRow/DataroomRow';
import {
  AccessDeniedGuard,
  FolderPerspectivePermissions,
  DisabledChildProps,
} from 'security';

import { getDownloadLink } from 'utils';

class FileRowMenu extends React.Component<MenuRenderProps<FileWithDate>> {
  static contextTypes = ContextPropTypes;

  getContext = () => this.context as DataroomContext;

  handleDeleteFile = () => {
    if (this.props.entity) {
      this.getContext().deleteFileModal(this.props.entity);
    }
  };

  renderMenuWithPermissions = (security: DisabledChildProps) => {
    const { renderItem, onBeginEdit } = this.props;
    return !security.accessDenied ? (
      <>
        {renderItem('Rename', onBeginEdit)}
        {renderItem('Delete', this.handleDeleteFile)}
      </>
    ) : null;
  };

  renderMenuWithoutPermissions = () => {
    const { entity, renderItem } = this.props;
    return <>{renderItem('Download', undefined, getDownloadLink(entity))}</>;
  };

  render() {
    return (
      <>
        <AccessDeniedGuard
          permission={FolderPerspectivePermissions.change_folder}
        >
          {this.renderMenuWithPermissions}
        </AccessDeniedGuard>
        {this.renderMenuWithoutPermissions()}
      </>
    );
  }
}

export default FileRowMenu;
