import { AcceptInvitation } from '../../features/acceptInvitation';
import sbaPppLoanApplicationForm from '../../features/roundTwoSbaPppLoanApplication/RoundTwoPublicLoanApplicationFormContainer';
import sbaPppSuccess from '../../features/roundOneSbaPppLoanApplication/Success';
import sbaPppInstructions from '../../features/roundOneSbaPppLoanApplication/InstructionsPopup/InstructionsPopup';
// import sbaPppSupportDocs from '../../features/roundOneSbaPppLoanApplication/SupportDocsPopup/SupportDocsPopup';
import sbaPppSupportDocs from '../../features/roundTwoSbaPppLoanApplication/SupportDocsPopup/RoundTwoSupportDocsPopup';
import NotFound from '../../features/404/NotFound';
import { RouteTable } from '../RouteTable';
import { RoutingContext } from '../types';

const authenticationRoutes: RoutingContext = {
  routes: [
    {
      component: AcceptInvitation,
      path: RouteTable.application.toAcceptInvitation(),
    },
    {
      component: sbaPppLoanApplicationForm,
      path: RouteTable.application.toSbaPppLoanApplicationOnboardingDisabled(),
      exact: true,
    },
    {
      component: sbaPppLoanApplicationForm,
      path: RouteTable.application.toSbaPppLoanApplication(),
      exact: true,
    },
    {
      component: sbaPppSuccess,
      path: RouteTable.application.toSbaPppLoanApplicationSuccess(),
    },
    {
      component: NotFound,
      path: RouteTable.application.to404,
    },
    {
      component: sbaPppInstructions,
      path: RouteTable.application.toSbaPppApplicationInstructions,
      exact: true,
    },
    {
      component: sbaPppSupportDocs,
      path: RouteTable.application.toSbaPppSupportingDocuments,
      exact: true,
    },
  ],
};

export default authenticationRoutes;
