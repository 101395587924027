import { MutationMethod } from '../types';
import { mutationBuilder } from './builders';
import {
  SetLoanTrancheFloatingRateDataMutationDefinition,
  CreateLoanTrancheFloatingRateDataMutationDefinition,
  DeleteLoanTrancheFloatingRateDataMutationDefinition,
} from './definitions';
import {
  LoanTrancheFloatingRateDataInput,
  LoanTrancheFloatingRateDataType,
  ID,
} from 'types';

const createLoanTrancheFloatingRateData: MutationMethod<
  LoanTrancheFloatingRateDataInput,
  LoanTrancheFloatingRateDataType
> = mutationBuilder<
  LoanTrancheFloatingRateDataInput,
  LoanTrancheFloatingRateDataType
>(CreateLoanTrancheFloatingRateDataMutationDefinition, {
  build: mutate => loanTrancheFloatingRateDataData =>
    mutate({
      variables: {
        loanTrancheFloatingRateDataData,
      },
    }),
});

const setLoanTrancheFloatingRateData: MutationMethod<
  LoanTrancheFloatingRateDataInput,
  LoanTrancheFloatingRateDataType
> = mutationBuilder<
  LoanTrancheFloatingRateDataInput,
  LoanTrancheFloatingRateDataType
>(SetLoanTrancheFloatingRateDataMutationDefinition, {
  build: mutate => loanTrancheFloatingRateDataData =>
    mutate({
      variables: {
        loanTrancheFloatingRateDataData,
      },
    }),
});

const deleteLoanTrancheFloatingRateData: MutationMethod<
  ID,
  void
> = mutationBuilder<ID, void>(
  DeleteLoanTrancheFloatingRateDataMutationDefinition,
  {
    build: mutate => (id: ID) =>
      mutate({
        variables: {
          id,
        },
      }),
  },
);

const LoanTrancheFloatingRateDataMutationMethods = {
  createLoanTrancheFloatingRateData,
  setLoanTrancheFloatingRateData,
  deleteLoanTrancheFloatingRateData,
};

export default LoanTrancheFloatingRateDataMutationMethods;
