import * as React from 'react';
import { StepProps } from '../StepProps';
import WorkflowStep from '../WorkflowStep';
import { invariant } from 'utils';
import { /* Button, */ Text } from 'components';
//TODO: SBA-HACK Button is disabled for now
/* import triggerClosingWorkflowTransition from 'lsgql/mutations/TransitionClosingWorkflow';
import raiseAlert from 'lsredux/raiseAlert'; */

function BorrowerPromissoryNote(props: StepProps) {
  const { step, isDimmed, canEdit /* dealClosingWorkflow */ } = props;
  invariant(step.slug === 'aw_borrower_sign_promissory_note', 'invalid slug');
  /*  const handleSendNote = async () => {
    const res = await triggerClosingWorkflowTransition({
      workflowId: dealClosingWorkflow.id,
      state: 'borrower_sign_promissory_note',
    });
    if (res.data.transitionClosingWorkflow.ok) {
      raiseAlert(
        'An email has been sent through Docusign requesting your signature. Please check your email.',
        'success',
      );
    } else {
      raiseAlert(
        "The request has failed. Please try again. If that doesn't work, please contact technical support",
        'error',
      );
    }
  }; */
  return (
    <WorkflowStep
      canEdit={canEdit}
      header="PROMISSORY NOTE"
      isDimmed={isDimmed}
      step={step}
    >
      <Text>
        {!isDimmed && !canEdit
          ? 'You signed your Promissory Note or other documents required by the lender.'
          : 'Sign the Promissory Note and any other loan documents required by the Lender.'}
      </Text>
      {canEdit && (
        <div className="Group left padded">
          {/*  TODO: SBA-HACK Replace with "Sign Promissory Note" primary button &
          in app tool once ESign tool ready */}
          {/*  <Button.Secondary
            onClick={handleSendNote}
            label="Email Not Received, Re-send"
          /> */}
        </div>
      )}
    </WorkflowStep>
  );
}

export default BorrowerPromissoryNote;
