import uuid from 'uuid/v4';
import Directory from '../../directory';

import { createForm } from '../../genericForms';
import { GenerateDebugDealInput, DealInput } from 'types';

const initialDeal: DealInput = {
  id: uuid(),
  name: '',
  __typename: 'DealInput',
};

const initialState: GenerateDebugDealInput = {
  id: uuid(),
  __typename: 'StubDealInput',
  dealData: initialDeal,
  leadLenderRoleList: [],
  servicingAgentRoleList: [],
  borrowerRoleList: [],
  borrowerId: '',
  leadLenderId: '',
  servicingAgentId: '',
  templateDealId: '',
  templatetrancheIdSet: [],
};

const lifecycleMethods = {
  mutations: {},
  validators: {},
};

const generateDeal = createForm<GenerateDebugDealInput, GenerateDebugDealInput>(
  Directory.GenerateDealKey,
  initialState,
  lifecycleMethods,
);

export default generateDeal;
