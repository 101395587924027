import { compose } from 'recompose';
import { connect } from 'react-redux';
import LoanInformationForm from './LoanInformationForm';
import { SbaPppForgivenessWorkflowFormQuery } from 'lsgql';
import { withRouteParams, RouteParams } from 'routing';
import { withLoadingIndicator, FormMediator } from 'components';
import sbaPppForgivnessLoanInformationFormRedux from 'lsredux/reducer/forms/sbaPppForgivenessLoanInformation';

const mapDispatchToProps = (dispatch: any) => {
  const bound = sbaPppForgivnessLoanInformationFormRedux.actions.generateActions(
    dispatch,
  );

  const updatedSave = async (refetch: any) => {
    // SBA Hack for Round One applications: Mark "NAICS Code"/"Primary First/Last Name" as dirty
    bound.toggleDirtyFlag(
      ['sbaPppLoanApplication', 'sbapppinstitution'],
      true,
      false,
    );

    return await bound.save(refetch);
  };

  return {
    ...bound,
    save: updatedSave,
  };
};

const container = compose(
  withRouteParams([RouteParams.dealId]),
  SbaPppForgivenessWorkflowFormQuery,
  withLoadingIndicator('loading'),
  connect(
    sbaPppForgivnessLoanInformationFormRedux.mapStateToProps,
    mapDispatchToProps,
  ),
  FormMediator({
    formId: 'sbaPppForgivenessLoanInformationForm',
    baseObjectTypeName: 'Deal',
    disableFrame: true,
  }),
)(LoanInformationForm);

export default container;
