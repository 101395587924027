import * as React from 'react';
import { compose } from 'recompose';
import CalendarItemsAllDealsQuery from './CalendarItemsAllDealsQuery';
import { CalendarItemCategoryType } from 'types';

type CalendarItemsQueryProps = {
  calendarItemCategories: Array<CalendarItemCategoryType>;
  categoryFilterIgnoreMap: {
    [key: string]: boolean;
  };
  isListView?: boolean;
  viewEnd?: Date;
  viewRange: 'week' | 'month' | '3 month';
  viewStart: Date;
};

/* eslint-disable react/no-multi-comp */
function HOCBefore(WrappedComponent: any) {
  return class HolidaysQueryBeforeWrappedComponent extends React.Component<
    any
  > {
    render() {
      const { calendarItemCategories = [], ...rest } = this.props;
      const holidaysOnlyCategories = [
        ...calendarItemCategories.filter(
          category => category.slug === 'holiday',
        ),
      ];
      return (
        <WrappedComponent
          {...rest}
          calendarItemCategories={holidaysOnlyCategories}
          fullCategoriesList={calendarItemCategories}
        />
      );
    }
  };
}

function HOCAfter(WrappedComponent: any) {
  return class HolidaysQueryAfterWrappedComponent extends React.Component<any> {
    render() {
      const {
        fullCategoriesList,

        /* eslint-disable @typescript-eslint/no-unused-vars */
        calendarItemCategories,
        ...rest
      } = this.props;
      return (
        <WrappedComponent
          {...rest}
          calendarItemCategories={fullCategoriesList}
        />
      );
    }
  };
}

export const CalendarItemsHolidaysQuery: (
  arg0: any,
) => React.ComponentType<CalendarItemsQueryProps> = compose(
  HOCBefore,
  CalendarItemsAllDealsQuery,
  HOCAfter,
);

export default CalendarItemsHolidaysQuery;
/* eslint-enable */
