import { compose, withState, withProps } from 'recompose';
import uuid from 'uuid/v4';
import { connect } from 'react-redux';
import { PrivateSbaApplicationFormQuery } from 'lsgql/queries/SbaPppLoanApplicationQuery';
import { setFormDisabled } from 'lsredux';
import PrivateSbaPppLoanApplication from 'lsredux/reducer/forms/privateSbaPppLoanApplication';
import { FormMediator, withLoadingIndicator } from 'components';
import { RouteParams, withRouteParams } from 'routing';
import { DealPerspectivePermissions } from 'security/authorization/definitions/Deal';
import { SbaPppApplicantOwnershipType } from 'types';
import { isWholeNumber } from 'utils';
import { RoundTwoPrivateSbaPppLoanApplicationForm } from './RoundTwoLoanApplicationForm';

import './RoundTwoLoanApplication.scss';

const mapDispatchToProps = (dispatch: any) => {
  const generated = PrivateSbaPppLoanApplication.actions.generateActions(
    dispatch,
  );

  const onInstitutionChange = (value: string, fieldId: string) =>
    generated.mutateProperty(value, ['sbapppinstitution', fieldId]);

  const onLoanFinancialsChange = (value: string, fieldId: string) =>
    generated.mutateProperty(value, ['sbappploanfinancials', fieldId]);

  const addOwner = () =>
    generated.addEntity('sbapppapplicantownershipSet', {
      id: uuid(),
      __typename: 'SbaPppApplicantOwnershipType',
    });

  const deleteOwner = (entity: SbaPppApplicantOwnershipType) =>
    generated.removeEntity('sbapppapplicantownershipSet', entity);

  const disableForm = (disable: boolean) =>
    dispatch(setFormDisabled('SbaPppLoanApplicationForm', disable));

  return {
    ...generated,
    onInstitutionChange,
    onLoanFinancialsChange,
    addOwner,
    deleteOwner,
    disableForm,
  };
};

const RoundTwoPrivateSbaPppLoanApplicationFormContainer = compose(
  withRouteParams([RouteParams.dealId]),
  PrivateSbaApplicationFormQuery,
  withLoadingIndicator('loading'),
  connect(PrivateSbaPppLoanApplication.mapStateToProps, mapDispatchToProps),
  withState('confirmSubmitModalContent', 'setConfirmSubmitModalContent', {
    header: 'Confirm Changes',
    message:
      "Please confirm you want to save the changes you've made on your loan application.  If you have signed a previous version of your application, you will be required to sign again.",
  }),
  withProps((ownProps: any) => {
    // This can only tell us something if the query returned something
    // eslint-disable-next-line
    console.log(ownProps.preExistingEntity);

    let canEdit = true;

    if (ownProps && ownProps.preExistingEntity) {
      // We have an object
      const data = ownProps.preExistingEntity;
      if (isWholeNumber(data.id)) {
        canEdit = !!data.canEditState;
      }
    }

    // eslint-disable-next-line
    console.log(`Query Res CanEdit: ${canEdit}`);
    return { canEditState: canEdit };
  }),
  FormMediator({
    formId: 'RoundTwoSbaPppLoanApplicationForm',
    baseObjectTypeName: 'SbaPppLoanApplication',
    editSecurity: DealPerspectivePermissions.administer_sba_ppp_application,
    blurEventForm: true,
    confirmBeforeSubmit: true,
    simpleFooter: true,
  }),
)(RoundTwoPrivateSbaPppLoanApplicationForm);

export default RoundTwoPrivateSbaPppLoanApplicationFormContainer;
