import React, { useState } from 'react';
import { compose } from 'recompose';
import { DropdownProps } from 'semantic-ui-react';
import { DealClosingWorkflowType, UserType } from 'types';
import { Header, Dropdown } from 'components';
import triggerClosingWorkflowTransition from 'lsgql/mutations/TransitionClosingWorkflow';
import { MyUserQuery } from 'lsgql/queries';

interface Props {
  dealClosingWorkflow: DealClosingWorkflowType;
  user?: UserType;
}

const options = [
  {
    text: 'Step 1 - BORROWER_FINISH_APPLICATION',
    value: 'borrower_finish_application',
  },
  {
    text: 'Step 2 - LENDER_APPROVE_BORROWER_APPLICATION',
    value: 'lender_approve_borrower_application',
  },
  {
    text: 'Step 2c - BORROWER_LENDER_REJECTED_APPLICATION',
    value: 'borrower_lender_rejected_application',
  },
  {
    text: 'Step 3 - BORROWER_APPLICATION_SIGNATURE_REQUESTED',
    value: 'borrower_application_signature_requested',
  },
  {
    text: 'Step 4a - LENDER_COMPLETE_LENDER_APPLICATION',
    value: 'lender_complete_lender_application',
  },
  {
    text: 'Step 4b - LENDER_SIGN_LENDER_APPLICATION',
    value: 'lender_sign_lender_application',
  },
  { text: 'Step 4c - LENDER_SUBMIT_TO_SBA', value: 'lender_submit_to_sba' },
  {
    text: 'Step 5 - LENDER_AWAITING_SBA_RESPONSE',
    value: 'lender_awaiting_sba_response',
  },
  {
    text: 'Step 6 - LENDER_REVIEW_PROMISSORY_NOTE',
    value: 'lender_review_promissory_note',
  },
  { text: 'Step 6a - LENDER_REJECTED_BY_SBA', value: 'lender_rejected_by_sba' },
  {
    text: 'Step 7 - BORROWER_SIGN_PROMISSORY_NOTE',
    value: 'borrower_sign_promissory_note',
  },
  { text: 'Step 8 - LENDER_ORIGINATE_LOAN', value: 'lender_originate_loan' },
  { text: 'Workflow Complete - LOAN_ORIGINATED', value: 'loan_originated' },
];

function DebugBar(props: Props) {
  const { dealClosingWorkflow, user } = props;
  const [isOpen, setIsOpen] = useState(false);

  function handleStateChange(e: React.ChangeEvent, data: DropdownProps) {
    const newState = data.value;
    // eslint-disable-next-line
    console.log(`Attempt Trransition to: ${newState}`);
    triggerClosingWorkflowTransition({
      workflowId: dealClosingWorkflow.id,
      state: newState as string,
    });
  }

  return (
    user &&
    user.isSuperuser && (
      <>
        <Header as="h5" className="caps steel">
          <div>
            {`Current State: ${dealClosingWorkflow.state}`}
            <Dropdown
              id="debug-state-toggle"
              isOpen={isOpen}
              onChange={handleStateChange}
              onToggle={() => {
                setIsOpen(!isOpen);
              }}
              options={options}
              value={dealClosingWorkflow.state}
            />
          </div>
        </Header>
      </>
    )
  );
}

const DebugBarContainer = compose(MyUserQuery)(DebugBar);

export default DebugBarContainer;
