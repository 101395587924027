import { mutationBuilder } from './builders';
import {
  CreateLoanTrancheTierMutationDefinition,
  SetLoanTrancheTierMutationDefinition,
  DeleteLoanTrancheTierMutationDefinition,
} from './definitions';
import { LoanTrancheTierInput, LoanTrancheTierType, ID } from 'types';

const LoanTrancheTierMutationMethods = {
  createLoanTrancheTier: mutationBuilder<
    LoanTrancheTierInput,
    LoanTrancheTierType
  >(CreateLoanTrancheTierMutationDefinition, {
    build: mutate => loanTrancheTierData =>
      mutate({ variables: { loanTrancheTierData } }),
  }),

  setLoanTrancheTier: mutationBuilder<
    LoanTrancheTierInput,
    LoanTrancheTierType
  >(SetLoanTrancheTierMutationDefinition, {
    build: mutate => loanTrancheTierData =>
      mutate({ variables: { loanTrancheTierData } }),
  }),

  deleteLoanTrancheTier: mutationBuilder<ID, void>(
    DeleteLoanTrancheTierMutationDefinition,
    {
      build: mutate => (id: string) => mutate({ variables: { id } }),
    },
  ),
};

export default LoanTrancheTierMutationMethods;
