import { SbaPppInstitutionType } from 'types';

/**
 * Function to determine if the SbaPppInstitution has a Business type that requires
 * a Legal name or First & Last name
 *
 * @param sbaPppInstitution The SbaPppInstitution to check
 */
export function isUseSbaBusinessLegalName(
  sbaPppInstitution: SbaPppInstitutionType,
): boolean {
  // Logic not applicable to Round One applications
  if (sbaPppInstitution.category == 'SBA_PP') {
    return true; // Is always "Business Legal Name"
  }

  // Returns the NOT of the "Business First/Last Name" logic
  return !(
    sbaPppInstitution.institutionType == 'SOLE_PROPRIETOR' ||
    sbaPppInstitution.businessTinType == 'SSN'
  );
}
