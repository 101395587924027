import { gql } from '@apollo/client';
import { CreditRatingShallowFields, DealFields } from '../fragments';
import { DocumentNode } from '../types';
import formQueryBuilder from './queryBuilders/formQueryBuilder';
import permissionQueryBuilder from './queryBuilders/permissionQueryBuilder';
import { DealQueryDefinition } from './definitions';
import { DealType } from 'types';

export const DealQuery = permissionQueryBuilder(DealQueryDefinition, {
  typename: 'Deal',
  // disableCache: true,
  skip: ({ dealId }: { dealId: string | null | undefined }) => !dealId,
  variables: ({ dealId }: { dealId: string | null | undefined }) => ({
    id: dealId,
  }),
  results: (data: { deal: DealType | null | undefined }) => {
    const { deal } = data;
    return { deal };
  },
});

export const DealQueryWithDisableCache = permissionQueryBuilder(
  DealQueryDefinition,
  {
    typename: 'Deal',
    disableCache: true,
    skip: ({ dealId }: { dealId: string | null | undefined }) => !dealId,
    variables: ({ dealId }: { dealId: string | null | undefined }) => ({
      id: dealId,
    }),
    results: (data: { deal: DealType | null | undefined }) => {
      const { deal } = data;
      return { deal };
    },
  },
);

const DealFormQueryDefinition: DocumentNode = gql`
  query DealQuery($id: ID) {
    deal(id: $id) {
      ...DealFields
      creditratingSet {
        ...CreditRatingShallowFields
      }
    }
  }
  ${DealFields}
  ${CreditRatingShallowFields}
`;

export const DealFormQuery = formQueryBuilder(DealFormQueryDefinition, {
  typename: 'Deal',
  // disableCache: true,
  skip: (props: { dealId: string | null | undefined }) => {
    const { dealId } = props;
    return !dealId;
  },
  variables: (props: { dealId: string | null | undefined }) => {
    const { dealId } = props;
    return { id: dealId };
  },
  results: (data: { deal: DealType | null | undefined }) => {
    const { deal } = data;
    return deal;
  },
});
