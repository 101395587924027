import { compose } from 'recompose';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import { Map } from 'immutable';
import loanTrancheRedux from '../../redux/reducer/forms/loanTranche';
import { KeyPath, MultiDispatchMethods } from '../../redux/genericForms';
import {
  canGenerateAdjustableRatePeriods,
  deleteAdjustableRatePeriodsAndLTAInterestPeriods,
  generateAdjustableRatePeriods,
  createAmortizationInterestPeriodsFromAdjustableRatePeriods,
} from './sections/utils';
import amortizationPaymentsQueryThunk from './amortization/amortizationPaymentsQueryThunk';
import LoanTrancheEvents from './LoanTrancheFormEvents';
import ContainerAggregator from './ContainerAggregator';
import { DealPerspectivePermissions } from 'security';
import { withLoadingIndicator, FormMediator } from 'components';

import { RouteParams, withRouteParams } from 'routing';
import {
  ApplicableMarginType,
  CovenantCovenantType,
  CovenantType,
  LoanTrancheType,
  CreditRatingType,
  BenchmarkOptionType,
  AlternateBaseRateBenchmarkType,
  AmortizationReaderType,
  LoanTrancheAmortizationPrincipalPaymentType,
  LoanTrancheFormType,
  LoanTrancheFloatingRateDataType,
  LoanTrancheRevolverSwinglineLOCDataType,
  LoanTrancheAmortizationInterestPeriodType,
} from 'types';
import { ReduxDirectory } from 'lsredux';
import { setAlert } from 'lsredux/actions/alerts';
import { invariant, incrementString } from 'utils';

import {
  NextBusinessDayQuery,
  queryWrapper,
  LoanTrancheFormQuery,
  FloatingRateBenchmarksQuery,
} from 'lsgql';

type OwnProps = {
  dealId: string;
  trancheId: string;
};

const mapStateToProps: (state: any, ownProps: OwnProps) => { rawData: any } = (
  state,
  ownProps,
) => {
  const keyPath = [
    ...ReduxDirectory.LoanTrancheFormKeyPath,
    ownProps.trancheId,
  ];
  const rawData = state.hasIn(keyPath) ? state.getIn(keyPath) : Map({});
  return {
    rawData,
  };
};

type LoanTrancheDispatchMethods = MultiDispatchMethods<LoanTrancheFormType> & {
  addCovenant: (covenant: CovenantCovenantType, description: string) => void;

  handleRemoveAbrOption: (option: AlternateBaseRateBenchmarkType) => void;

  handleRemoveApplicableMargin: (margin: ApplicableMarginType) => void;

  handleRemoveBenchmarkOption: (option: BenchmarkOptionType) => void;

  handleRemoveCreditRating: (rating: CreditRatingType) => void;

  handleRemoveLoanTrancheFloatingRateData: (
    option: LoanTrancheFloatingRateDataType,
  ) => void;

  handleResetAmortization: () => void;

  handleSetNewTrancheName: () => void;

  handleSolveFor: (arg0: string) => void;

  mutateAmortization: (
    value: (string | null | undefined) | boolean,
    path: KeyPath,
  ) => void;

  mutateCovenant: (covenant: CovenantType) => void;

  mutateInterestPeriod: (
    arg0: LoanTrancheAmortizationInterestPeriodType,
  ) => void;

  mutatePayment: (arg0: LoanTrancheAmortizationPrincipalPaymentType) => void;

  onUpdateAbrOption: (option: AlternateBaseRateBenchmarkType) => void;

  onUpdateApplicableMargin: (margin: ApplicableMarginType) => void;

  onUpdateBenchmarkOption: (option: BenchmarkOptionType) => void;

  onUpdateCreditRating: (rating: CreditRatingType) => void;

  onUpdateLoanTrancheFloatingRateData: (
    option: LoanTrancheFloatingRateDataType,
  ) => void;

  onUpdateLoanTrancheRevolverSwinglineLOCData: (
    option: LoanTrancheRevolverSwinglineLOCDataType,
  ) => void;

  removeCovenant: (covenant: CovenantType) => void;
  setAdjustableRatePeriodOverflowAlert: () => void;
};

const rootKeyPath = ['nonpersisted', 'loanTranche'];

function setNewTrancheName(
  mutateProperty: (value: string, fieldId: string) => void,
) {
  function generateTrancheName(
    currentNumberOfTranches: number,
    currentTrancheNames: Array<string>,
  ) {
    if (!currentNumberOfTranches) return '';

    // First we sort by string comparison
    currentTrancheNames.sort((a, b) => b.localeCompare(a));

    /*
     * Then, we sort by string length. This will make sure that
     * "Tranche AA" is evaluated to be greater than "Tranche Z"
     */
    currentTrancheNames.sort((a, b) => {
      if (a.length < b.length) return 1;
      if (a.length > b.length) return -1;
      return 0;
    });

    if (!currentTrancheNames.length) {
      /*
       * We didn't find any tranche names with the prefix "Tranche", so
       * the new tranche should be "Tranche A"
       */

      return 'Tranche A';
    }

    return `${incrementString(currentTrancheNames[0])}`;
  }

  return function setNewTrancheNameWithState(dispatch, getState) {
    const currentState = getState();
    const currentTrancheNames = [];

    if (currentState.hasIn(rootKeyPath)) {
      const numberToGenerateName = currentState.getIn(rootKeyPath).size - 1;
      currentState.getIn(rootKeyPath).forEach(loanTranche => {
        /*
         * While the `incrementString` function is removing any excess whitespace,
         * we also need to remove them here for comparison purposes. When sorting
         * tranche names, the length of the string is taken into account which may lead
         * to incorrect sorting if the excess whitespaces are not removed.
         */
        const loanTrancheName = loanTranche
          .getIn(['data', 'name'])
          .trim()
          .replace(/ +/g, ' ');
        const loanTrancheRegex = new RegExp(/^tranche\s*[a-z0-9]+$/g);

        // Will store all tranche names that begin with "Tranche" or "tranche"
        if (loanTrancheRegex.test(loanTrancheName.toLowerCase()))
          currentTrancheNames.push(loanTrancheName);
      });

      const trancheName = Number.isNaN(numberToGenerateName)
        ? ''
        : generateTrancheName(numberToGenerateName, currentTrancheNames);
      mutateProperty(trancheName, 'name');
    }
  };
}

function shouldGenerateAdjustableRatePeriods(fieldId: KeyPath) {
  if (Array.isArray(fieldId)) {
    if (
      fieldId.length === 2 &&
      fieldId[0] === 'loantrancheadjustableratedata'
    ) {
      const property = fieldId[1];
      return property === 'resetPeriod' || property === 'initialPeriod';
    }
    return false;
  }
  return fieldId === 'indicativeFixedRate' || fieldId === 'originalTerm';
}

const mapDispatchToProps: (
  dispatch: any,
  ownProps: OwnProps,
) => LoanTrancheDispatchMethods = (dispatch: any, ownProps: OwnProps) => {
  const generated: MultiDispatchMethods<LoanTrancheFormType> = loanTrancheRedux.actions.generateActions(
    dispatch,
    ownProps.trancheId,
  );
  const bound: MultiDispatchMethods<LoanTrancheFormType> & {
    mutateAmortization: (
      value: (string | null | undefined) | boolean,
      path: KeyPath,
    ) => void;
  } = {
    ...generated,

    /*
    The Payments query thunk needs access to this method
    */
    mutateAmortization: (
      value: (string | null | undefined) | boolean,
      path: KeyPath,
    ) => {
      const root = ['loantrancheamortizationSet', '0'];

      const keyPath = !Array.isArray(path)
        ? [...root, path]
        : [...root, ...path];

      bound.mutateProperty(value, keyPath);
    },
  };

  const getAdjustableRatePeriods = () =>
    function generatePeriods(thunkDispatch, getState) {
      const keyPath = [
        ...ReduxDirectory.LoanTrancheFormKeyPath,
        ownProps.trancheId,
        'data',
      ];
      const state = getState();
      if (state.hasIn(keyPath)) {
        const data: LoanTrancheFormType = state.getIn(keyPath).toJS();
        if (data.interestType === 'ADJUSTABLE_RATE') {
          const { loantrancheadjustableratedata } = data;
          invariant(
            loantrancheadjustableratedata,
            'Loan Tranche Adjustable Rate Data not found',
          );
          if (
            canGenerateAdjustableRatePeriods(
              data.originalTerm,
              loantrancheadjustableratedata.initialPeriod,
              loantrancheadjustableratedata.resetPeriod,
              data.indicativeFixedRate,
            )
          ) {
            const ratePeriods = generateAdjustableRatePeriods(
              Number(data.originalTerm),
              Number(loantrancheadjustableratedata.initialPeriod),
              Number(loantrancheadjustableratedata.resetPeriod),
              data.indicativeFixedRate || '',
            );

            const amortizationPeriods = createAmortizationInterestPeriodsFromAdjustableRatePeriods(
              ratePeriods,
            );
            deleteAdjustableRatePeriodsAndLTAInterestPeriods(
              data,
              bound.deleteCollection,
            );

            bound.addEntities(
              [
                'loantrancheadjustableratedata',
                'loantrancheadjustablerateperiodSet',
              ],
              ratePeriods,
            );
            bound.toggleDirtyFlag(
              ['loantrancheamortizationSet', '0'],
              true,
              false,
            );
            bound.addEntities(
              [
                'loantrancheamortizationSet',
                '0',
                'loantrancheamortizationinterestperiodSet',
              ],
              amortizationPeriods,
            );
          } else {
            deleteAdjustableRatePeriodsAndLTAInterestPeriods(
              data,
              bound.deleteCollection,
            );
          }
        }
      }
    };

  const getAmortization: (
    arg0: boolean,
    arg1?: string,
  ) => Promise<AmortizationReaderType | null | undefined> = (
    includePayments: boolean,
    solveFor?: string | null | undefined,
  ) =>
    dispatch(
      amortizationPaymentsQueryThunk(
        ownProps.trancheId,
        bound,
        includePayments,
        solveFor,
      ),
    );

  const additional = {
    setAdjustableRatePeriodOverflowAlert: () => {
      dispatch(
        setAlert(
          ' Updating this Adjustment Rate would cause the Total to go over 1000% ',
          'error',
        ),
      );
    },
    handleSolveFor(field: string) {
      getAmortization(false, field);
    },
    mutatePayment(updatedPayment: LoanTrancheAmortizationPrincipalPaymentType) {
      const paymentsKeyPath = [
        'loantrancheamortizationSet',
        '0',
        'loantrancheamortizationprincipalpaymentSet',
      ];

      bound.replaceEntity(paymentsKeyPath, updatedPayment);

      // Include payments, but don't check to see if there are no changes
      getAmortization(true);
    },

    mutateInterestPeriod(
      updatedPeriod: LoanTrancheAmortizationInterestPeriodType,
    ) {
      const periodsKeyPath = [
        'loantrancheamortizationSet',
        '0',
        'loantrancheamortizationinterestperiodSet',
      ];

      bound.replaceEntity(periodsKeyPath, updatedPeriod);
    },

    mutateProperty(
      value:
        | (string | null | undefined)
        | (boolean | null | undefined)
        | (Array<any> | null | undefined),
      fieldId: KeyPath,
    ) {
      bound.mutateProperty(value, fieldId);
      if (shouldGenerateAdjustableRatePeriods(fieldId))
        dispatch(getAdjustableRatePeriods());
    },
    handleSetNewTrancheName() {
      dispatch(setNewTrancheName(bound.mutateProperty));
    },
    handleResetAmortization() {
      const trancheFields: Array<keyof LoanTrancheType> = [
        'initialDrawAmount',
        'originalTerm',
        'paymentFrequency',
        'loantrancheamortizationSet',
      ];

      trancheFields.forEach(e => bound.undo(e));
    },
    addCovenant(covenant: CovenantCovenantType, description: string): void {
      const entity: CovenantType = {
        id: uuid(),
        covenantType: covenant,
        description,
        __typename: 'CovenantType',
      };

      bound.addEntity('covenantSet', entity);
    },
    mutateCovenant(covenant: CovenantType): void {
      bound.replaceEntity('covenantSet', covenant);
    },
    onUpdateCreditRating(rating: CreditRatingType): void {
      bound.replaceEntity('creditratingSet', rating);
    },
    onUpdateBenchmarkOption(option: BenchmarkOptionType): void {
      bound.replaceEntity('benchmarkoptionSet', option);
    },
    onUpdateApplicableMargin(margin: ApplicableMarginType): void {
      bound.replaceEntity('applicablemarginSet', margin);
    },
    onUpdateLoanTrancheFloatingRateData(
      data: LoanTrancheFloatingRateDataType,
    ): void {
      bound.replaceEntity('loantranchefloatingratedata', data);
    },
    onUpdateLoanTrancheRevolverSwinglineLOCData(
      data: LoanTrancheRevolverSwinglineLOCDataType,
    ): void {
      bound.replaceEntity('loantrancherevolverswinglinelocdata', data);
    },
    onUpdateAbrOption(option: AlternateBaseRateBenchmarkType): void {
      bound.replaceEntity('alternatebaseratebenchmarkSet', option);
    },
    removeCovenant(covenant: CovenantType): void {
      if (!covenant) {
        return;
      }
      bound.removeEntity('covenantSet', covenant);
    },

    handleRemoveCreditRating(rating: CreditRatingType): void {
      bound.removeEntity('creditratingSet', rating);
    },
    handleRemoveBenchmarkOption(option: BenchmarkOptionType): void {
      bound.removeEntity('benchmarkoptionSet', option);
    },
    handleRemoveApplicableMargin(margin: ApplicableMarginType): void {
      bound.removeEntity('applicablemarginSet', margin);
    },
    handleRemoveLoanTrancheFloatingRateData(
      data: LoanTrancheFloatingRateDataType,
    ): void {
      bound.removeEntity('loantranchefloatingratedata', data);
    },
    handleRemoveAbrOption(option: AlternateBaseRateBenchmarkType): void {
      bound.removeEntity('alternatebaseratebenchmarkSet', option);
    },
    save: async (formQueryRefetch: any) => {
      const res = await bound.save(formQueryRefetch);
      return res;
    },
  };

  return {
    ...bound,
    ...additional,
  };
};

const Composed: any = compose(
  withRouteParams([RouteParams.dealId]),
  LoanTrancheFormQuery,
  FloatingRateBenchmarksQuery,
  connect(mapStateToProps, mapDispatchToProps),
  withLoadingIndicator('loading'),
  queryWrapper(NextBusinessDayQuery, 'fetchNextBusinessDay', 'businessDay'),
  FormMediator({
    baseObjectTypeName: 'LoanTranche',
    formId: 'loanTrancheForm',
    events: LoanTrancheEvents,
    disableFrame: true,
    disableAlerts: true,
    blurEventForm: true,
    editSecurity: DealPerspectivePermissions.administer_loan_tranches,
  }),
)(ContainerAggregator);

export default Composed;
