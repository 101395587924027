import * as React from 'react';
import { StepProps } from '../StepProps';
import WorkflowStep from '../WorkflowStep';
import { invariant } from 'utils';
import { Text } from 'components';

function BorrowerNextSteps(props: StepProps) {
  const { step, dealClosingWorkflow, isDimmed, canEdit, deal } = props;
  invariant(step.slug === 'aw_borrower_next_steps', 'invalid slug');
  const sbaOrigination = deal?.sbaoriginationstate;

  const renderStepText = () => {
    switch (dealClosingWorkflow?.state) {
      case 'borrower_finish_application':
        return 'After your lender reviews and approves your application materials, they will send you a request for signature.';
      case 'lender_approve_borrower_application':
        return 'Your request for review has been submitted to your lender. Please wait for an email requesting your signature or other steps.';
      case 'borrower_lender_rejected_application':
        return 'After your lender reviews and approves your application materials, they will send you a request for signature.';
      case 'borrower_application_signature_requested':
        return 'After you’ve signed your application, please wait for notification from your lender of SBA approval and final signatures.';
      case 'lender_complete_lender_application':
      case 'lender_sign_lender_application':
      case 'lender_submit_to_sba':
        return 'After you’ve signed your application, please wait for notification from your lender of SBA approval and final signatures.';
      case 'lender_awaiting_sba_response':
      case 'lender_review_promissory_note':
        return (
          <>
            <b>
              Your application has been submitted by your lender to the SBA.
            </b>{' '}
            Please wait for notification from your lender of SBA approval and
            final signatures.
          </>
        );
      case 'lender_rejected_by_sba':
        return (
          <>
            <b>
              Your loan application was{' '}
              {sbaOrigination?.isErrored ? 'returned with errors' : 'denied'} by
              the SBA.
            </b>{' '}
            Please contact your lender for more details.
          </>
        );
      case 'borrower_sign_promissory_note':
        return 'Sign and submit your Promissory Note, then wait for your lender to provide funding information.';
      case 'lender_originate_loan':
      case 'loan_originated':
        return 'Please wait for your lender to let you know when the loan is funded!';
      default:
        return '';
    }
  };

  return (
    <WorkflowStep
      canEdit={canEdit}
      header="NEXT STEPS"
      isDimmed={isDimmed}
      step={step}
    >
      <Text>{renderStepText()}</Text>
    </WorkflowStep>
  );
}

export default BorrowerNextSteps;
