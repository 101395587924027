import React from 'react';
import {
  WrappedMediatorFormProps,
  Header,
  Text,
  Button,
  Icon,
  Summary,
} from 'components';
import { DealType } from 'types';

import './SbaHelpBox.scss';

type SbaHelpBoxProps = WrappedMediatorFormProps<DealType>;

export function SbaHelpBox(props: SbaHelpBoxProps) {
  const { data } = props;
  const sbaloaninfo = data?.sbaPppForgivenessWorkflow?.sbaloaninfo;
  const loanfunding = data?.dealClosingWorkflow?.loanfunding;

  // Missing data - do not display component
  if (!sbaloaninfo || !loanfunding) {
    return null;
  }

  const isFundingDateDiff =
    sbaloaninfo.fundingDate != sbaloaninfo.userFundingDate;
  const isLoanAmountDiff =
    sbaloaninfo.forgiveLoanAmount != sbaloaninfo.userForgiveLoanAmount;

  // All LoanStreet and SBA data matches - do not display component
  if (!(isFundingDateDiff || isLoanAmountDiff)) {
    return null;
  }

  const handleUpdatingFundingDate = (
    value: string | null | undefined,
  ) => (): void => props.mutateProperty(value, 'fundingDate');

  const handleUpdatingLoanAmount = (
    value: string | null | undefined,
  ) => (): void =>
    props.mutateProperty(value, [
      'dealClosingWorkflow',
      'loanfunding',
      'loanAmount',
    ]);

  return (
    <div className="SbaHelpBox">
      <div className="Contents">
        <div className="SbaHelpBox__Header spreadChildren">
          <Header as="h4" className="bold">
            SBA Data Update
          </Header>
          <Icon color="sunflower" icon="exclamation-triangle" />
        </div>

        <Text>
          We’ve received data from the SBA that differs from some of the entries
          on this form. Using this data could help avoid errors in processing
          the application.
        </Text>

        {isFundingDateDiff && (
          <div className="SbaHelpBox__Section">
            <Summary.DateField
              label="PPP Loan Disbursement Date"
              value={sbaloaninfo.fundingDate}
            />
            {data.fundingDate != sbaloaninfo.fundingDate ? (
              <Button.Text
                label="Click here to use this date"
                onClick={handleUpdatingFundingDate(sbaloaninfo.fundingDate)}
              />
            ) : (
              <div className="spreadChildren">
                Date inserted in form
                <Button.Text
                  className="rightAlign"
                  label="Revert"
                  onClick={handleUpdatingFundingDate(
                    sbaloaninfo.userFundingDate,
                  )}
                />
              </div>
            )}
          </div>
        )}

        {/* Removed EIDL stuff for Round Two */}

        {isLoanAmountDiff && (
          <div className="SbaHelpBox__Section">
            <Summary.MoneyField
              label="PPP Loan Amount"
              value={sbaloaninfo.forgiveLoanAmount}
            />
            {loanfunding?.loanAmount != sbaloaninfo.forgiveLoanAmount ? (
              <Button.Text
                label="Click here to use this date"
                onClick={handleUpdatingLoanAmount(
                  sbaloaninfo.forgiveLoanAmount,
                )}
              />
            ) : (
              <div className="spreadChildren">
                Amount inserted in form
                <Button.Text
                  className="rightAlign"
                  label="Revert"
                  onClick={handleUpdatingLoanAmount(
                    sbaloaninfo.userForgiveLoanAmount,
                  )}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
