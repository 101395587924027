import * as React from 'react';
import noop from 'lodash/noop';
import { Form } from 'components';
import { LoanTrancheFormType, ValidationMap } from 'types';

type Props = {
  data: LoanTrancheFormType;
  description: string;
  disabled?: boolean;

  /* eslint-disable react/no-unused-prop-types */
  errors?: ValidationMap | null | undefined;
  isSaving: boolean;

  /* eslint-enable react/no-unused-prop-types */

  mutateProperty: (value: any, propertyName: string) => void;
};

const loanTranche = 'LoanTranche';
const width = '90px';
const totalRateMinimum = 'totalRateMinimum';
const totalRateMaximum = 'totalRateMaximum';

class FloorAndCapFieldsOtherItem extends React.Component<Props> {
  fields = {
    description: {
      id: 'description',
      propertyName: 'description',
      value: this.props.description,
      onChange: noop,
      width,
      disabled: true,
      disabledReadable: true,
    },
    floor: {
      id: totalRateMinimum,
      propertyName: totalRateMinimum,
      onChange: this.props.mutateProperty,
      disabled: Boolean(this.props.disabled),
      width,
      fieldName: 'Floor',
    },
    cap: {
      id: totalRateMaximum,
      propertyName: totalRateMaximum,
      onChange: this.props.mutateProperty,
      disabled: Boolean(this.props.disabled),
      width,
      fieldName: 'Cap',
    },
  };

  render() {
    const { data, disabled } = this.props;

    const PercentageComponent = disabled
      ? Form.PercentageReadOnly
      : Form.Percentage;

    return (
      <Form.Group className="FloorAndCapGroup">
        {Form.FieldRenderer(
          Form.Text,
          this.fields.description,
          this.props,
          loanTranche,
          data.id,
        )}
        {Form.FieldRenderer(
          PercentageComponent,
          this.fields.floor,
          this.props,
          loanTranche,
          data.id,
        )}
        {Form.FieldRenderer(
          PercentageComponent,
          this.fields.cap,
          this.props,
          loanTranche,
          data.id,
        )}
      </Form.Group>
    );
  }
}

export default FloorAndCapFieldsOtherItem;
