import { MutationMethod } from '../types';
import { inputScrubber } from './utils';
import { mutationBuilder } from './builders';
import { MutationExecutor } from './builders/mutationBuilder';
import {
  CreateDealClosingWorkflowStepMutationDefinition,
  SetDealClosingWorkflowStepMutationDefinition,
} from './definitions';
import {
  DealClosingWorkflowStepInput,
  DealClosingWorkflowStepType,
} from 'types';

const setDealClosingWorkflowStep: MutationMethod<
  DealClosingWorkflowStepInput,
  DealClosingWorkflowStepType
> = mutationBuilder<DealClosingWorkflowStepInput, DealClosingWorkflowStepType>(
  SetDealClosingWorkflowStepMutationDefinition,
  {
    build: (mutate: MutationExecutor<DealClosingWorkflowStepType>) => (
      dealClosingWorkflowStepData: DealClosingWorkflowStepInput,
    ) =>
      mutate({
        variables: {
          dealClosingWorkflowStepData: inputScrubber(
            dealClosingWorkflowStepData,
          ),
        },
      }),
  },
);

const createDealClosingWorkflowStep: MutationMethod<
  DealClosingWorkflowStepInput,
  DealClosingWorkflowStepType
> = mutationBuilder<DealClosingWorkflowStepInput, DealClosingWorkflowStepType>(
  CreateDealClosingWorkflowStepMutationDefinition,
  {
    build: (mutate: MutationExecutor<DealClosingWorkflowStepType>) => (
      dealClosingWorkflowStepData: DealClosingWorkflowStepInput,
    ) =>
      mutate({
        variables: {
          dealClosingWorkflowStepData: inputScrubber(
            dealClosingWorkflowStepData,
          ),
        },
      }),
  },
);

const DealMutationMethods = {
  createDealClosingWorkflowStep,
  setDealClosingWorkflowStep,
};

export default DealMutationMethods;
