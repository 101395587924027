import { fromJS } from 'immutable';
import { ReduxKeys } from '../directory';
import { createFormReducer } from './reducerFactory';
import {
  MultiEntityActionBaseType,
  InitializeStateAction,
  FormReduxArgs,
} from './types';
import { createActionTypes } from './createActionTypes';
import { handleInitializeState } from './handlers/handleInitializeState';
import { invariant } from 'utils';
import { BaseType } from 'types';

/**
 * Wrap the generic reducer, baking in the object id
 *
 * This will determine the 'id' slice, and then invoke the generic form actions
 */

/*
    Redux shape:
    type: Map{
        [IdValue]: FormReduxState<TOut> {...}
    }

    - this reducer handles the base action type,
    - the payload must include an entity id
    - This reducer will determine the correct 'slice of the slice'
    - the child slice is then handled by the generic reducer as normal
*/
const initialState = fromJS({});

export default function createMultiEntityFormReducer<
  TOut extends BaseType,
  TIn extends BaseType
>(
  reduxKey: ReduxKeys,
  initialEntityState: TIn,
  args?: FormReduxArgs<TOut, TIn>,
) {
  const wrappedReducer = createFormReducer<TOut, TIn>(
    reduxKey,
    initialEntityState,
    args,
  );
  const actionTypes = createActionTypes(reduxKey);

  return function reducer(
    state: any = initialState,
    action: MultiEntityActionBaseType,
  ) {
    if (action && action.type) {
      // for performance's sake, determine if we can handle
      // the hydrate/initialiaztion action will lack a type property
      // so skip
      if (!action.type.startsWith(reduxKey)) return state;
    }

    if (action.type === actionTypes.createNewStateActionType) {
      // because it is a createNewStateActionType we know the action is of type InitializeStateAction.
      const newStateAction: InitializeStateAction<TOut> = action as any;

      const newEntityState = handleInitializeState(
        newStateAction,
        fromJS(initialEntityState),
        args,
      );

      const newEntityId = newEntityState.getIn(['data', 'id']);
      invariant(newEntityId, 'Failed to find a valid id for newEntityState');

      return state.set(newEntityId, newEntityState);
    }

    if (action.type === actionTypes.removeSelfActionType) {
      return state.remove(action.entityId);
    }

    const { entityId } = action;

    if (!entityId) {
      return state;
    }

    /* find slice */
    // already have 'type' slice here, type slice is a map;

    if (action.type === actionTypes.clearStateActionType) {
      /*
        Froms invoke clearState on unmount, we can't
        leave items indexed by id in redux state
      */
      return state.remove(entityId);
    }

    // not yet FormReduxState<TOut>
    const subSlice = state.has(action.entityId)
      ? state.get(action.entityId)
      : undefined;

    if (action.type !== actionTypes.initializeStateActionType && !subSlice)
      return state;

    /* slice */

    const alteredSubSlice = wrappedReducer(subSlice, action as any);

    /* alteredSubSlice is an immutable object, we just need to apply it to our state */
    // apply slice
    const final = state.set(action.entityId, alteredSubSlice);

    return final;
  };
}
