import { compose } from 'recompose';
import { connect } from 'react-redux';
import LenderRequestResubmissionForm from './LenderRequestResubmissionForm';
import { RouteParams, withRouteParams } from 'routing';
import { FormMediator, withLoadingIndicator } from 'components';
import SbaLenderRequestResubmission from 'lsredux/reducer/forms/sbaPppApplicationResubmitRequest';
import { SbaPppApplicationResubmitRequestFormQuery, DealQuery } from 'lsgql';

const LenderRequestResubmissionFormContainer = compose(
  withRouteParams([RouteParams.dealId]),
  DealQuery,
  SbaPppApplicationResubmitRequestFormQuery,
  withLoadingIndicator('loading'),
  connect(
    SbaLenderRequestResubmission.mapStateToProps,
    SbaLenderRequestResubmission.actions.generateActions,
  ),
  FormMediator({
    formId: 'SbaPppLenderRequestResubmission',
    baseObjectTypeName: 'SbaPppApplicationResubmitRequest',
    blurEventForm: true,
    disableFrame: true,
    successAlert: { message: 'Request successfully sent!' },
  }),
)(LenderRequestResubmissionForm);

export default LenderRequestResubmissionFormContainer;
