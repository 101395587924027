import React from 'react';
import LoanTrancheSummary from './LoanTrancheSummary';
import { DealType, LoanSummaryType, MyUserType } from 'types';
import { Tiler } from 'components';

import './Summary.scss';

type Props = {
  deal: DealType;
  loanSummary: LoanSummaryType[];
  user: MyUserType;
};

function Summary(props: Props) {
  const { deal, loanSummary } = props;

  return (
    <div className="Summary">
      {loanSummary && (
        <div className="TranchesSection">
          <Tiler>
            <Tiler.Row>
              {loanSummary.map(tranche => (
                <LoanTrancheSummary
                  deal={deal}
                  key={tranche.totalCommitmentAmount}
                  summary={tranche}
                />
              ))}
            </Tiler.Row>
          </Tiler>
        </div>
      )}
    </div>
  );
}

export default Summary;
