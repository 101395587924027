import memoize from 'lodash/memoize';

/* eslint-disable no-param-reassign */

function removeTrailingZeros(value: string | number): string {
  if (value === null || value === undefined) return '';
  let convert: string = typeof value === 'string' ? value : value.toString();

  if (convert.indexOf('.') === -1) {
    return convert;
  }

  // as long as the last character is a 0 or a dot, remove it
  while (
    (convert.slice(-1) === '0' || convert.slice(-1) === '.') &&
    convert.indexOf('.') !== -1
  ) {
    convert = convert.substr(0, convert.length - 1);
  }
  return convert;
}

export default memoize(removeTrailingZeros);
