import { mutationBuilder } from './builders';
import {
  CreateFeeCalculationRuleMutationDefinition,
  SetFeeCalculationRuleMutationDefinition,
  DeleteFeeCalculationRuleMutationDefinition,
} from './definitions';
import { FeeCalculationRuleInput, FeeCalculationRuleType, ID } from 'types';

const FeeCalculationRuleMutationMethods = {
  createFeeCalculationRule: mutationBuilder<
    FeeCalculationRuleInput,
    FeeCalculationRuleType
  >(CreateFeeCalculationRuleMutationDefinition, {
    build: mutate => feeCalculationRuleData =>
      mutate({
        variables: { feeCalculationRuleData },
      }),
  }),
  setFeeCalculationRule: mutationBuilder<
    FeeCalculationRuleInput,
    FeeCalculationRuleType
  >(SetFeeCalculationRuleMutationDefinition, {
    build: mutate => feeCalculationRuleData =>
      mutate({
        variables: { feeCalculationRuleData },
      }),
  }),
  deleteFeeCalculationRule: mutationBuilder<ID, void>(
    DeleteFeeCalculationRuleMutationDefinition,
    {
      build: mutate => (id: string) =>
        mutate({
          variables: { id },
        }),
    },
  ),
};

export default FeeCalculationRuleMutationMethods;
