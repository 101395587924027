import uuid from 'uuid/v4';
import moment from 'moment';
import { fromJS } from 'immutable';
import * as React from 'react';
import { noop } from 'lodash';
import { getRelatedFieldsFromEventFrequency } from './utils';
import CalendarTaskFrequencyBody from './CalendarTaskFrequencyBody';
import CalendarTaskFormSidebar from './CalendarTaskFormSidebar/CalendarTaskFormSidebar';
import {
  StandardModal,
  Form,
  LoadingIndicator,
  WrappedMediatorFormProps,
} from 'components';
import { timeZoneOptions } from 'resources';

import {
  EventScheduleInput,
  EventScheduleTemplateType,
  EventScheduleType,
  CalendarItemCategoryType,
  DealType,
  DealInstitutionType,
  MyUserType,
  EventScheduleTemplateInput,
  SelectOptionType,
} from 'types';
import { invariant } from 'utils';
import { EventScheduleEntityValidator } from 'lsgql';

const objectType = 'EventSchedule';
const dateFormat = 'YYYY-MM-DD';
const timeFormat = 'HH:mm';
const smallWidth = '90px';
const smallMedWidth = '120px';
const width = '158px';
const largeWidth = '188px';
const maxWidth = '332px';

type PromiseResponse = {
  data: {
    createEventScheduleTemplate: {
      eventScheduleTemplate: EventScheduleTemplateType;
      ok: boolean;
    };
  };
};

type Props = WrappedMediatorFormProps<EventScheduleInput> & {
  createEventScheduleTemplate: (
    eventScheduleInputData: EventScheduleTemplateInput,
  ) => Promise<PromiseResponse>;
  deal: DealType;
  dealOrganizations: ReadonlyArray<DealInstitutionType>;
  eventSchedules: ReadonlyArray<EventScheduleType>;
  eventscheduletemplateSet: Array<EventScheduleTemplateType>;
  taskCategories: Array<CalendarItemCategoryType>;
  fetchDealCalendarItems: () => void;
  fetchEventScheduleTemplateType: () => void;
  isOpen: boolean;
  setStagedFiles: (files: Array<File>) => void;
  stagedFiles: Array<File>;
  toggleModal: () => void;
  user: MyUserType;
};

type State = {
  isSaving: boolean;
  shouldSaveAsTemplate: boolean;
  templateName: string;
};

class CalendarTaskForm extends React.Component<Props, State> {
  fields: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      shouldSaveAsTemplate: false,
      templateName: '',
      isSaving: false,
    };

    this.fields = {
      template: {
        id: 'templateId',
        propertyName: 'templateId',
        fieldName: 'Template',
        onChange: this.handleTemplateChange,
        width: largeWidth,
      },
      category: {
        id: 'categoryId',
        propertyName: 'categoryId',
        fieldName: 'Category',
        onChange: this.handleCategoryChange,
        allowEmpty: false,
        width: largeWidth,
      },
      name: {
        id: 'name',
        propertyName: 'name',
        fieldName: 'Task Name',
        onChange: this.props.mutateProperty,
        width: maxWidth,
        required: true,
      },
      frequency: {
        id: 'eventFrequency',
        propertyName: 'eventFrequency',
        fieldName: 'Frequency',
        onChange: this.handleEventFrequencyChange,
        typeName: 'EventScheduleEventFrequency',
        width,
        required: true,
      },
      reminder: {
        id: 'reminderOffset',
        propertyName: 'reminderOffset',
        fieldName: 'Reminder',
        onChange: this.props.mutateProperty,
        width: smallWidth,
        positive: false,
        suffix: 'days',
      },
      overdueAlert: {
        id: 'overdueAlertOffset',
        propertyName: 'overdueAlertOffset',
        fieldName: 'Overdue Alert',
        onChange: this.props.mutateProperty,
        width: smallWidth,
        positive: true,
        suffix: 'days',
        required: true,
      },
      loanTranches: {
        id: 'loanTranches',
        multiple: true,
        allowEmpty: false,
        propertyName: 'loanTranches',
        fieldName: 'Tranche',
        onChange: this.props.mutateProperty,
        width: maxWidth,
      },
      initialDueDate: {
        id: 'initialDueDate',
        propertyName: 'initialDueDatetime',
        fieldName: 'Initial Due Date',
        onChange: this.handleInitialDueDateChange,
        width,
        required: true,
      },
      initialDueTime: {
        id: 'initialDueTime',
        propertyName: 'initialDueDatetime',
        fieldName: 'Time',
        onChange: this.handleInitialDueTimeChange,
        width,
        required: true,
      },
      initialDueTimezone: {
        id: 'initialDueTimezone',
        propertyName: 'initialDueTimezone',
        fieldName: 'Time Zone',
        onChange: this.props.mutateProperty,
        options: timeZoneOptions,
        width: smallMedWidth,
        required: true,
      },
      description: {
        id: 'description',
        propertyName: 'description',
        fieldName: 'Description',
        onChange: this.props.mutateProperty,
        width: maxWidth,
      },
      saveAsTemplate: {
        id: 'shouldSaveAsTemplate',
        propertyName: 'shouldSaveAsTemplate',
        label: 'Save as Template',
        onChange: this.handleToggleShouldSaveAsTemplate,
        width: largeWidth,
      },
      templateName: {
        id: 'templateName',
        propertyName: 'templateName',
        fieldName: 'Template Name',
        onChange: this.handleTemplateNameChange,
        width: largeWidth,
      },
    };
  }

  componentDidUpdate = (prevProps: Props) => {
    if (
      this.props.isOpen &&
      this.props.isOpen !== prevProps.isOpen &&
      this.props.deal
    ) {
      this.initializeTaskModal();
    }
  };

  initializeTaskModal = () => {
    const { deal, mutateProperty, user } = this.props;
    mutateProperty(deal.id, 'dealId');
    if (user.institution) mutateProperty(user.institution.id, 'institutionId');
  };

  handleEventFrequencyChange = (value: string | null | undefined) => {
    const { data, mutateProperty } = this.props;
    const newFrequencyFields = getRelatedFieldsFromEventFrequency(value);
    const prevFrequencyFields = getRelatedFieldsFromEventFrequency(
      data.eventFrequency,
    );

    const newData = { ...data };
    prevFrequencyFields.forEach(fieldName => {
      if (!newFrequencyFields.includes(fieldName)) {
        newData[fieldName] = undefined;
      }
    });
    if (value === 'QUARTERLY') {
      newData.eventRepeatsOnQuarterly = ['1', '2', '3', '4'];
    }

    mutateProperty(fromJS({ ...newData, eventFrequency: value }), []);
  };

  handleToggleShouldSaveAsTemplate = (value: boolean | null | undefined) => {
    if (value) {
      this.setState({
        shouldSaveAsTemplate: Boolean(value),
        templateName: this.props.data.name || '',
      });
      this.props.mutateProperty('', 'templateId');
    } else {
      this.setState({
        shouldSaveAsTemplate: Boolean(value),
        templateName: '',
      });
    }
  };

  handleTemplateNameChange = (value: string | null | undefined) => {
    this.setState({ templateName: value || '' });
  };

  handleTemplateChange = (value: string | null | undefined) => {
    const { mutateProperty, data, eventscheduletemplateSet } = this.props;

    if (value) {
      const template = eventscheduletemplateSet.find(et => et.id === value);
      invariant(template, 'Template was not found!');

      const cleanedTemplate = {
        ...template,
      };

      delete cleanedTemplate.__typename;
      delete cleanedTemplate.name;
      delete cleanedTemplate.id;
      delete cleanedTemplate.created;
      delete cleanedTemplate.modified;

      mutateProperty(
        fromJS({ ...data, ...cleanedTemplate, templateId: value }),
        [],
      );
    } else {
      mutateProperty(value, 'templateId');
    }
  };

  handleCategoryChange = (value: string | null | undefined) => {
    const { mutateProperty } = this.props;
    mutateProperty(value, 'categoryId');
  };

  handleInitialDueDateChange = (value: string | null | undefined) => {
    const { data, mutateProperty } = this.props;
    if (value) {
      const newMoment = moment(value);
      if (data.initialDueDatetime) {
        const prevMoment = moment(data.initialDueDatetime);
        newMoment.hours(prevMoment.hours());
        newMoment.minutes(prevMoment.minutes());
      }
      mutateProperty(newMoment.toISOString(), 'initialDueDatetime');
    }
  };

  handleInitialDueTimeChange = (value: string | null | undefined) => {
    const { data, mutateProperty } = this.props;
    if (value) {
      const time = value.split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      invariant(!Number.isNaN(hours), 'Hours entered was not valid!');
      invariant(!Number.isNaN(minutes), 'Minutes entered was not valid!');

      const newMoment = data.initialDueDatetime
        ? moment(data.initialDueDatetime)
        : moment();
      newMoment.hours(hours);
      newMoment.minutes(minutes);
      mutateProperty(newMoment.toISOString(), 'initialDueDatetime');
    }
  };

  getLoanTrancheOptions = () => {
    const { deal } = this.props;
    return (deal.loantrancheSet || []).map<SelectOptionType>(loanTranche => ({
      text: loanTranche.name || '',
      value: loanTranche.id,
    }));
  };

  getTemplateOptions = () => {
    const { eventscheduletemplateSet } = this.props;
    return eventscheduletemplateSet.map<SelectOptionType>(template => ({
      text: template.name || '',
      value: template.id,
    }));
  };

  getCategoryOptions = () => {
    const { taskCategories } = this.props;
    const cleanedTaskCategories = taskCategories.map<SelectOptionType>(
      category => ({
        text: category.name || '',
        value: category.id,
      }),
    );
    return cleanedTaskCategories.sort((a, b) => a.text.localeCompare(b.text));
  };

  getLoanTrancheValues = () => {
    const { data, deal } = this.props;
    if (!this.isEventFrequencyAfterCompletedEvent())
      return data.loanTranches || [];
    const { triggeringEventScheduleId } = data;
    if (!triggeringEventScheduleId) return [];
    const triggeringEventSchedule =
      deal.eventscheduleSet &&
      deal.eventscheduleSet.find(
        eventSchedule => eventSchedule.id === triggeringEventScheduleId,
      );
    if (!triggeringEventSchedule || !triggeringEventSchedule.loanTranches)
      return [];
    return (
      triggeringEventSchedule.loanTranches.map<string>(tranche => tranche.id) ||
      []
    );
  };

  handleSave = () => {
    const {
      onSave,
      createEventScheduleTemplate,
      data,
      mutateProperty,
      fetchEventScheduleTemplateType,
      fetchDealCalendarItems,
    } = this.props;
    const { shouldSaveAsTemplate, templateName } = this.state;

    if (shouldSaveAsTemplate) {
      const cleanedData: any = { ...data };
      delete cleanedData.isLeaf;
      delete cleanedData.__typename;

      delete cleanedData.isDirty;
      delete cleanedData.id;

      EventScheduleEntityValidator(cleanedData).then(validatorRes => {
        if (!validatorRes.errors) {
          const eventScheduleTemplateData = {
            id: uuid(),
            __typename: 'EventScheduleTemplateInput',
            name: templateName,
            dealId: data.dealId,
            description: data.description,
            eventDefaultsTo: data.eventDefaultsTo,
            eventFrequency: data.eventFrequency,
            eventFrequencyPeriod: data.eventFrequencyPeriod,
            eventRepeatsOnMonthly: data.eventRepeatsOnMonthly,
            eventRepeatsOnQuarterly: data.eventRepeatsOnQuarterly,
            eventRepeatsOnWeekly: data.eventRepeatsOnWeekly,
            institutionId: data.institutionId,
            numberOfOccurrences: data.numberOfOccurrences,
            overdueAlertOffset: data.overdueAlertOffset,
            reminderOffset: data.reminderOffset,
          };

          createEventScheduleTemplate(eventScheduleTemplateData as any).then(
            templateRes => {
              const responseData = templateRes.data.createEventScheduleTemplate;
              if (responseData && responseData.ok) {
                const templateId = responseData.eventScheduleTemplate.id;
                mutateProperty(templateId, 'templateId');
              }
              this.setState({ isSaving: true });
              onSave().then(
                saveRes => {
                  if (saveRes.success) {
                    fetchEventScheduleTemplateType();
                    fetchDealCalendarItems();
                    this.handleCloseModal();
                  }
                  this.setState({ isSaving: false });
                },
                () => this.setState({ isSaving: false }),
              );
            },
          );
        } else {
          // it fails but we want to get the errors
          onSave();
        }
      });
    } else {
      this.setState({ isSaving: true });
      onSave().then(
        res => {
          if (res.success) {
            fetchEventScheduleTemplateType();
            fetchDealCalendarItems();
            this.handleCloseModal();
          }
          this.setState({ isSaving: false });
        },
        () => this.setState({ isSaving: false }),
      );
    }
  };

  handleCloseModal = () => {
    const { toggleModal, clearState } = this.props;

    clearState();
    this.setState({ shouldSaveAsTemplate: false, templateName: '' });
    toggleModal();
  };

  isEventFrequencyAfterCompletedEvent = () =>
    this.props.data.eventFrequency === 'AFTER_COMPLETED_EVENT';

  render() {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const {
      isOpen,
      toggleModal,
      data,
      deal,
      user,
      createEventScheduleTemplate,
      eventSchedules,
      eventscheduletemplateSet,
      fetchEventScheduleTemplateType,
      fetchDealCalendarItems,
      stagedFiles,
      setStagedFiles,
      dealOrganizations,
      loading,
      ...rest
    } = this.props;

    if (loading) return null;

    /* eslint-enable @typescript-eslint/no-unused-vars */
    const { shouldSaveAsTemplate, templateName, isSaving } = this.state;

    let dueDate = '';
    let dueTime = '';

    if (data.initialDueDatetime) {
      const duedateMoment = moment(data.initialDueDatetime);
      dueDate = duedateMoment.format(dateFormat);
      dueTime = duedateMoment.format(timeFormat);
    }

    const loanTrancheOptions = this.getLoanTrancheOptions();
    const templateOptions = this.getTemplateOptions();
    const categoryOptions = this.getCategoryOptions();
    const miscellaneousCategory = categoryOptions.find(
      e => e.text === 'Miscellaneous',
    );
    const categoryOptionDefault =
      data?.categoryId ?? miscellaneousCategory?.value;

    return (
      <StandardModal
        className="calendarTaskModal steel"
        header="Add Task"
        isOpen={isOpen}
        onClose={this.handleCloseModal}
        onConfirm={this.handleSave}
        requireResponse
      >
        {loading || isSaving ? (
          <LoadingIndicator />
        ) : (
          <div className="calendarTaskForm">
            <Form
              className="calendarTaskForm__Main"
              id="calendarTaskForm"
              onSubmit={noop}
            >
              <Form.Group>
                {Form.FieldRenderer(
                  Form.Select,
                  {
                    ...this.fields.template,
                    options: templateOptions,
                    disabled: shouldSaveAsTemplate,
                  },
                  this.props,
                  objectType,
                  data.id,
                )}
                {Form.FieldRenderer(
                  Form.Select,
                  {
                    ...this.fields.category,
                    options: categoryOptions,
                    value: categoryOptionDefault,
                  },
                  this.props,
                  objectType,
                  data.id,
                )}
              </Form.Group>
              {Form.FieldRenderer(
                Form.Input,
                this.fields.name,
                this.props,
                objectType,
                data.id,
              )}
              <Form.Group>
                {Form.FieldRenderer(
                  Form.ReferenceSelect,
                  this.fields.frequency,
                  this.props,
                  objectType,
                  data.id,
                )}
                {!this.isEventFrequencyAfterCompletedEvent() &&
                  Form.FieldRenderer(
                    Form.Offset,
                    this.fields.reminder,
                    this.props,
                    objectType,
                    data.id,
                  )}
                {Form.FieldRenderer(
                  Form.Offset,
                  this.fields.overdueAlert,
                  this.props,
                  objectType,
                  data.id,
                )}
              </Form.Group>
              <Form.Group>
                {Form.FieldRenderer(
                  Form.Select,
                  {
                    ...this.fields.loanTranches,
                    options: loanTrancheOptions,
                    value: this.getLoanTrancheValues(),
                    disabled: this.isEventFrequencyAfterCompletedEvent(),
                  },
                  this.props,
                  objectType,
                  data.id,
                )}
              </Form.Group>
              {!this.isEventFrequencyAfterCompletedEvent() && (
                <Form.Group>
                  {Form.FieldRenderer(
                    Form.Calendar,
                    {
                      ...this.fields.initialDueDate,
                      value: dueDate,
                    },
                    this.props,
                    objectType,
                    data.id,
                  )}
                  {Form.FieldRenderer(
                    Form.Time,
                    {
                      ...this.fields.initialDueTime,
                      value: dueTime,
                    },
                    this.props,
                    objectType,
                    data.id,
                  )}
                  {Form.FieldRenderer(
                    Form.Select,
                    this.fields.initialDueTimezone,
                    this.props,
                    objectType,
                    data.id,
                  )}
                </Form.Group>
              )}
              {data.eventFrequency &&
                data.eventFrequency !== 'DOES_NOT_REPEAT' && (
                  <CalendarTaskFrequencyBody
                    {...rest}
                    data={data}
                    eventSchedules={eventSchedules}
                    loading={loading}
                  />
                )}
              {Form.FieldRenderer(
                Form.TextArea,
                this.fields.description,
                this.props,
                objectType,
                data.id,
              )}
              <Form.Group>
                {Form.FieldRenderer(
                  Form.Checkbox,
                  {
                    ...this.fields.saveAsTemplate,
                    value: shouldSaveAsTemplate,
                  },
                  this.props,
                  objectType,
                  data.id,
                )}
                {shouldSaveAsTemplate &&
                  Form.FieldRenderer(
                    Form.Input,
                    {
                      ...this.fields.templateName,
                      value: templateName,
                    },
                    this.props,
                    objectType,
                    data.id,
                  )}
              </Form.Group>
            </Form>
            <CalendarTaskFormSidebar
              {...rest}
              data={data}
              dealOrganizations={dealOrganizations}
              loading={loading}
              setStagedFiles={setStagedFiles}
              stagedFiles={stagedFiles}
              user={user}
            />
          </div>
        )}
      </StandardModal>
    );
  }
}

export default CalendarTaskForm;
