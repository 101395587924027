import { DocumentNode, ApolloQueryResult } from '../../types';
import { getAuthenticatedClient } from '../../authenticatedClient';

/* eslint-disable import/prefer-default-export */
export type FilterKeyValue = {
  excludeValue?: string | null | undefined;
  listValue?: Array<string> | null | undefined;
  name: string;
  value?: string | null | undefined;
};

type PropertyBuilder = (
  property: string,
  value: string | null | undefined,
) => FilterKeyValue;

function BuildKeyValue(
  property: string,
  value: string | null | undefined,
  suffix: string,
): FilterKeyValue {
  const res: FilterKeyValue = {
    name: `${property}${suffix}`,
    value,
  };
  return res;
}

const containsSuffix = '__icontains';
const startsWithSuffix = '__istartswith';

const empty = '';

const propertyContains: PropertyBuilder = (
  property: string,
  value: string | null | undefined,
) => BuildKeyValue(property, value, containsSuffix);

const propertyStartsWith: PropertyBuilder = (
  property: string,
  value: string | null | undefined,
) => BuildKeyValue(property, value, startsWithSuffix);

const propertyExactMatch: PropertyBuilder = (
  property: string,
  value: string | null | undefined,
) => BuildKeyValue(property, value, empty);

export const filterKeyValueBuilders = {
  propertyContains,
  propertyStartsWith,
  propertyExactMatch,
};

export function executeFilterQuery<T>(
  query: DocumentNode,
  filters: Array<FilterKeyValue>,
): Promise<ApolloQueryResult<T>> {
  const variables: {
    [key: string]: any;
  } = { filters };
  return getAuthenticatedClient().query({
    query,
    variables,
    fetchPolicy: 'network-only',
  });
}
