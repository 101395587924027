import * as React from 'react';

type Props = {};

class InvalidInvitation extends React.Component<Props> {
  render() {
    return <div>Invitation Not Found</div>;
  }
}

export default InvalidInvitation;
