import { is } from 'immutable';
import Directory from '../directory';
import { DehydratedStateType } from '../types';

/**
 * Persist the `persisted` slice of the state tree to localStorage,
 * but only if the slice has changed
 * @param {*} store - Redux Store
 */
const localStorageMiddleware = (store: any) => (next: any) => (action: any) => {
  // get before state
  const staleSlice = store.getState().get(Directory.PersistedKey);

  // invoke action
  next(action);

  // get the after state
  const freshSlice = store.getState().get(Directory.PersistedKey);

  // only persist if the immutable slice is different,
  // TODO: This could be faster if the persisted slice included
  //  a uuid stamp that is updated when any item under persisted is
  // updated, then this comparison becomes a simple check on uuid equality

  if (!is(staleSlice, freshSlice)) {
    const dehydrated: DehydratedStateType = {
      hash: process.env.REACT_APP_GIT_COMMIT,
      data: freshSlice.toJS(),
    };

    localStorage.setItem(
      (Directory.LocalStorageKey as any) as string,
      JSON.stringify(dehydrated),
    );
  }
};

export default localStorageMiddleware;
