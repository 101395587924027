import { mutationBuilder } from './builders';
import {
  CreateLoanTrancheCollateralMutationDefinition,
  SetLoanTrancheCollateralMutationDefinition,
  DeleteLoanTrancheCollateralMutationDefinition,
} from './definitions';
import {
  LoanTrancheCollateralInput,
  LoanTrancheCollateralType,
  ID,
} from 'types';

const LoanTrancheCollateralMutationMethods = {
  createLoanTrancheCollateral: mutationBuilder<
    LoanTrancheCollateralInput,
    LoanTrancheCollateralType
  >(CreateLoanTrancheCollateralMutationDefinition, {
    build: mutate => loanTrancheCollateralData =>
      mutate({ variables: { loanTrancheCollateralData } }),
  }),
  setLoanTrancheCollateral: mutationBuilder<
    LoanTrancheCollateralInput,
    LoanTrancheCollateralType
  >(SetLoanTrancheCollateralMutationDefinition, {
    build: mutate => loanTrancheCollateralData =>
      mutate({ variables: { loanTrancheCollateralData } }),
  }),
  deleteLoanTrancheCollateral: mutationBuilder<ID, void>(
    DeleteLoanTrancheCollateralMutationDefinition,
    {
      build: mutate => (id: string) => mutate({ variables: { id } }),
    },
  ),
};

export default LoanTrancheCollateralMutationMethods;
