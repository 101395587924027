import * as React from 'react';
import Secured, { DisabledChildProps } from './Secured';
import { PermissionRequest } from './types';
import { PermissionDefinition } from './definitions/types';
import { PermissionFlags, ID } from 'types';
import { Composer } from 'utils';

type ChildProps = {
  accessDenied?: boolean;
  flag: PermissionFlags;
};

interface PermissionEvaluationWrapperProps {
  permission: PermissionDefinition;
  perspectiveId?: ID;
  children?: (childProps: ChildProps) => React.ReactNode;
  flag: PermissionFlags;
}

/* eslint-disable import/prefer-default-export, react/no-multi-comp  */
class PermissionEvaluationWrapper extends React.Component<
  PermissionEvaluationWrapperProps
> {
  renderChildren = (childProps: DisabledChildProps) =>
    this.props.children({ ...childProps, flag: this.props.flag });

  render() {
    return (
      <Secured
        denyAction="disable"
        permission={this.props.permission}
        perspectiveId={this.props.perspectiveId}
      >
        {this.renderChildren}
      </Secured>
    );
  }
}

type Props = {
  children: (
    permissionsDenied: Map<PermissionFlags, boolean>,
  ) => React.ReactNode;
  permissionRequests: Array<PermissionRequest>;
};

/**
 *  The PermissionsGuard is a wrapper component that checks the access of a set of permissions for it's children.
 *
 *  Props:
 *  children: This is the render function used to render the children. The permissionsDenied param is the result of the
 *  permissions check that we did in a form of a map. They key is the type of permission(PermissionFlags) and the value
 *  is a boolean of whether or not the permission was denied(true: access denied, false: access granted).
 *
 *  For example if I wanted to see if I have permissions for a flag "Foo",
 *  I would check permissionsDenied.get("Foo") === false.
 *
 *  permissionRequests: This is the array of the Permissions that would be checked and passed on to the children
 * @deprecated All Guard components can accept multiple permissions
 */
class PermissionsGuard extends React.Component<Props> {
  renderChildren = (permissions: Array<ChildProps>) => {
    const { children } = this.props;

    const permissionDeniedMap = permissions.reduce((map, childProps) => {
      map.set(childProps.flag, childProps.accessDenied);
      return map;
    }, new Map());

    return children(permissionDeniedMap);
  };

  render() {
    const { permissionRequests } = this.props;
    return (
      <Composer
        components={permissionRequests.map(request => (
          <PermissionEvaluationWrapper
            flag={request.flag}
            key={request.flag}
            permission={request.definition}
            perspectiveId={request.perspectiveId}
          />
        ))}
      >
        {this.renderChildren}
      </Composer>
    );
  }
}

export default PermissionsGuard;
