import * as React from 'react';
import { invariant } from '../utils';

type ActiveEvent = {
  hidden: () => void;
  id: string;
  visible: () => void;
};

type Props = {};

const event = 'visibilitychange';

/**
 * ActiveWindowObserver uses the Page Visibility API to determine
 * if the current browser tab/window is visible to the user.  The
 * Observable pattern is implemented as a method for notifying Observers
 * whether the current window is visible.
 *
 * Observers can observe via the `Register` method by providing an `ActiveEvent`
 * object.  When page visibility transitions, this component will invoke either
 * the `visible` or `hidden` method of each Observer, according to whether or not the
 * Page is currently visible.
 *
 * !!!!
 * You must be sure to `Unregister` your Observer as well, otherwise you introduce
 * a memory leak
 * !!!!
 *
 */
class ActiveWindowObserver extends React.Component<Props> {
  /**
   * The collection of events that are to be start/stopped
   * when the page gains or loses visibility
   */
  static Observers: Array<ActiveEvent> = [];

  /**
   * If the page currently visible
   */
  static isHidden = false;

  /**
   * Has the component mounted and start to manage visibility lifecycle
   */
  static hasMounted = false;

  /**
   * Register an event to be toggled with window visibility
   */
  static Register = (e: ActiveEvent) => {
    invariant(
      e.id,
      'ActiveWindowObserver events require a unique Id.  Use UUID',
    );
    invariant(
      !ActiveWindowObserver.Observers.find(o => o.id === e.id),
      `Attempted to register an ActiveWindowObserver event twice`,
    );

    invariant(
      e.visible && e.hidden,
      'ActiveWindowObserver events require a visible and hidden method',
    );

    if (ActiveWindowObserver.hasMounted && !ActiveWindowObserver.isHidden) {
      // If we are actively managing visibility state, and are currently visible, start this event
      e.visible();
    }

    ActiveWindowObserver.Observers.push(e);
  };

  /**
   * Unregister an event by id
   */
  static Unregister = (id: string) => {
    ActiveWindowObserver.Observers = ActiveWindowObserver.Observers.filter(
      e => e.id !== id,
    );
  };

  componentDidMount() {
    // Set our initial visibility state
    this.handleVisibilityChange();
    document.addEventListener(event, this.handleVisibilityChange, false);
    ActiveWindowObserver.hasMounted = true;
  }

  componentWillUnmount() {
    // complete tear down

    // 1: Remove the listener
    document.removeEventListener(event, this.handleVisibilityChange);

    // 2: Stop any active events
    if (!ActiveWindowObserver.isHidden) {
      this.stopObservers();
    }

    // 3: Reset flags
    ActiveWindowObserver.isHidden = false;
    ActiveWindowObserver.hasMounted = false;
    ActiveWindowObserver.Observers = [];
  }

  startObservers = () => {
    ActiveWindowObserver.Observers.forEach(e => {
      try {
        e.visible();
      } catch (error) {
        // eslint-disable-next-line
        console.warn(
          `Received an error when invoking visible on ActiveObserver ${e.id}.  Did you forget to unregister an observer?`,
        );
        // eslint-disable-next-line
        console.error(error);
      }
    });
  };

  stopObservers = () => {
    ActiveWindowObserver.Observers.forEach(e => {
      try {
        e.hidden();
      } catch (error) {
        // eslint-disable-next-line
        console.warn(
          `Received an error when invoking hidden on ActiveObserver ${e.id}.  Did you forget to unregister an observer?`,
        );
        // eslint-disable-next-line
        console.error(error);
      }
    });
  };

  handleVisibilityChange = () => {
    /*
     * The initial isHidden state is false, so this method
     * will correctly set the flag and invoke events when mounted
     */

    // eslint-disable-next-line
    const hidden = document.hidden;
    if (hidden === ActiveWindowObserver.isHidden) {
      return;
    }

    ActiveWindowObserver.isHidden = hidden;

    if (ActiveWindowObserver.isHidden) {
      this.stopObservers();
    } else {
      this.startObservers();
    }
  };

  render() {
    return null;
  }
}

export default ActiveWindowObserver;
