import { compose } from 'recompose';
import { connect } from 'react-redux';
import Deals from './Deals';
import { withLoadingIndicator } from 'components';
import { DealsQuery, DealFavoriteMutations } from 'lsgql';
import { generateTable } from 'lsredux/generate';
import { ReduxDirectory } from 'lsredux';
import { withToJS } from 'utils';

const { changeSortField } = generateTable.methods('main_deals');

const mapStateToProps: (state: any) => { rawData: any } = state => ({
  rawData: state.getIn([ReduxDirectory.PersistedKey, ReduxDirectory.MainKey]),
});

export default compose(
  connect(mapStateToProps, {
    changeSortField,
  }),
  withToJS,
  DealsQuery,
  withLoadingIndicator('loading'),
  DealFavoriteMutations,
)(Deals);
