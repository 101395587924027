import * as React from 'react';
import CreateReportView from './CreateReportView';
import { Tiler } from 'components';
import './ReportsView.scss';

const PortfolioReports = CreateReportView(
  'MONTHLY_PORTFOLIO',
  'PPP Portfolio Reports',
);

const WeeklySba1502Reports = CreateReportView(
  'WEEKLY_SBA_1502',
  'SBA 1502 New Distribution Reports',
);

const MonthlySba1502Reports = CreateReportView(
  'MONTHLY_SBA_1502',
  'SBA 1502 Reports',
);

const DailyLoanExtract = CreateReportView(
  'DAILY_LOAN_EXTRACT',
  'Daily Loan Extract',
  true,
);

function ReportsView() {
  return (
    <div className="PortfolioReportView">
      <Tiler>
        <Tiler.Row>
          <PortfolioReports />
          <WeeklySba1502Reports />
          <MonthlySba1502Reports />
          <DailyLoanExtract />
        </Tiler.Row>
      </Tiler>
    </div>
  );
}

export default ReportsView;
