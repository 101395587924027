import * as React from 'react';

import { LoadingIndicator } from '@loanstreet-usa/design-system';
import {
  GlobalPermissionPerspectiveContextType,
  PermissionKey,
  PermissionPerspectiveContextType,
  GlobalPermissionPerspectiveContextPropTypes,
  PermissionPerspectiveContextPropTypes,
} from './types';
import { getEntityPermissionKey } from './methods/getEntityKeyMap';
import { PermissionsBaseType, ID } from 'types';
import { invariant, isWholeNumber } from 'utils';

type Props = {
  children: React.ReactNode;

  data: PermissionsBaseType | null | undefined;

  disableLoader?: boolean;
};

type State = {
  hasLoaded: boolean;
  perspectiveId: ID | null | undefined;
};

/**
 * Establishes a PermissionPerspective for a GraphQL object
 * with a permissions property.  This component must be a
 * descendant of GlobalPermissionPerspective, as it initializes the Permissions
 * context through the Global context
 */
class PermissionPerspective extends React.Component<Props, State> {
  initalizationCount = 0;

  permissionKey: PermissionKey | null | undefined = undefined;

  hasInitialized = false;

  static contextTypes = GlobalPermissionPerspectiveContextPropTypes;

  static childContextTypes = PermissionPerspectiveContextPropTypes;

  static defaultProps = {
    disableLoader: false,
  };

  constructor(props: Props, context: GlobalPermissionPerspectiveContextType) {
    super(props);
    invariant(
      context && context.isPermitted,
      'PermissionPerspective received an undefined context, this component must be nested under GlobalPermissionPerspective',
    );
  }

  state = {
    hasLoaded: false,
    perspectiveId: null,
  };

  getChildContext: () => PermissionPerspectiveContextType = () => ({
    perspectiveId: this.state.perspectiveId,
  });

  componentDidMount() {
    if (this.props.data) this.initializePermissionPerspective(this.props.data);
    /*
    debug(
      `Mounted PermissionPerspective (${this.identifier}) (${(this
        .permissionKey: any)})`,
    );
    */
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!this.props.data && nextProps.data && this.state.hasLoaded === false)
      this.initializePermissionPerspective(nextProps.data);
  }

  componentWillUnmount() {
    // if (this.state.hasLoaded) {
    this.disposePermissionPerspective(this.props.data);
    // }
  }

  getContext = () => this.context as GlobalPermissionPerspectiveContextType;

  setContextState = (perspective: PermissionsBaseType | null | undefined) => {
    if (perspective) {
      this.setState({
        hasLoaded: true,
        perspectiveId: perspective.id,
      });
    }
  };

  // isUnmounting: boolean = false;

  disposePermissionPerspective(data: PermissionsBaseType | null | undefined) {
    // if (data) {
    if (data && this.state.hasLoaded) {
      const context = this.getContext();
      context.disposePermissionSet(data);
    }
    /* } else {
      invariant(
        data,
        'Attempted to dispose a PermissionPerspective, but the data object was undefined',
      );
    }
    */
  }

  initializePermissionPerspective(
    data: PermissionsBaseType | null | undefined,
  ) {
    if (!this.hasInitialized && data) {
      // only raise once
      invariant(
        isWholeNumber(data.id),
        'Cannot initialize a PermissionPerspective for objects lacking an id',
      );

      invariant(
        data.__typename !== undefined && data.__typename !== null,
        'Cannot initialize a PermissionPerspective for objects lacking a __typename value',
      );
      this.hasInitialized = true;
      this.initalizationCount += 1;
      invariant(
        this.initalizationCount < 2,
        'initializePermissionPerspective should only raise once',
      );

      const context = this.getContext();
      this.permissionKey = getEntityPermissionKey(data);
      invariant(this.permissionKey, 'undefined permission key');
      context.initPermissionSet(data, () => {
        this.setContextState(data);
      });
    }
  }

  render() {
    if (this.state.hasLoaded) return this.props.children;
    if (this.props.disableLoader) return null;
    return <LoadingIndicator alt="Loading PermissionPerspective..." />;
  }
}

export default PermissionPerspective;
