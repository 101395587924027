import { isImmutable, isKeyed } from 'immutable';
import { isDirty } from './constants';
import { invariant } from 'utils';

/**
 * Flag an Immutable Map as Dirty
 * @param {*} state
 */
export function flagImmutableDirty(state: any): any {
  invariant(isKeyed(state), 'Expected an Immutable map');
  if (state.get(isDirty) === true) {
    return state;
  }
  return state.set(isDirty, true);
}

/**
 * Flag a standard JS object as isDirty: true
 * @param {*} entity
 */
export function flagDirty<TOut>(entity: TOut): TOut {
  invariant(!isImmutable(entity), 'Expected non-Immutable objects');
  return { ...entity, isDirty: true };
}

/**
 * Flag a standard JS object as isDirty: undefined
 * @param {*} entity
 */
export function flagClean<TOut>(entity: TOut): TOut {
  invariant(!isImmutable(entity), 'Expected non-Immutable objects');
  return { ...entity, isDirty: undefined };
}

/**
 * Flag a collection of standard JS objects as isDirty: true
 * @param {*} entities
 */
export function flagManyDirty<TOut>(entities: Array<TOut>): Array<TOut> {
  invariant(
    entities.every(e => !isImmutable(e)),
    'Expected non-Immutable objects',
  );
  return entities.map((e: any) => flagDirty(e));
}
