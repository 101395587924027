import React, { useState } from 'react';
import {
  Form,
  TabNavigationMenu,
  Tiler,
  WrappedMediatorFormProps,
} from 'components';

import { UserType, FormFieldsType } from 'types';

const tabs = ['Contact', 'Login Info'];
const user = 'User';
const fields: FormFieldsType = {
  username: {
    id: 'username',
    propertyName: 'username',
    fieldName: 'Username',
    width: 'twelve',
    helpText: 'Contact support@loan-street.com for help with your username',
  },
  firstName: {
    id: 'firstName',
    propertyName: 'firstName',
    fieldName: 'First',
    required: true,
    width: 'six',
  },
  lastName: {
    id: 'lastName',
    propertyName: 'lastName',
    fieldName: 'Last',
    required: true,
    width: 'six',
  },
  email: {
    id: 'email',
    propertyName: 'email',
    fieldName: 'Email',
    required: true,
    width: 'twelve',
    helpText: 'You will receive notification messages at this address',
  },
  title: {
    id: 'title',
    propertyName: 'title',
    fieldName: 'Title',
    width: 'twelve',
  },
  primaryPhone: {
    id: 'primaryPhone',
    propertyName: 'primaryPhone',
    fieldName: 'Primary Phone',
    width: '158px',
  },
  secondaryPhone: {
    id: 'secondaryPhone',
    propertyName: 'secondaryPhone',
    fieldName: 'Secondary Phone',
    width: '158px',
  },
  fax: {
    id: 'fax',
    propertyName: 'fax',
    fieldName: 'Fax',
    width: '158px',
  },
};

function UserProfileForm(props: WrappedMediatorFormProps<UserType>) {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <Tiler>
      <Tiler.Tile title="My Information" width="Half">
        <TabNavigationMenu
          activeTab={activeTab}
          onClick={setActiveTab}
          tabs={tabs}
        />
        {activeTab === 'Contact' && <Contact {...props} />}
        {activeTab === 'Login Info' && <LoginInfo {...props} />}
      </Tiler.Tile>
    </Tiler>
  );
}

function Contact(props) {
  const { data } = props;
  return (
    <>
      <Form.Group>
        {Form.FieldRenderer(
          Form.Input,
          {
            ...fields.firstName,
            onChange: props.mutateProperty,
          },
          props,
          user,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Input,
          {
            ...fields.lastName,
            onChange: props.mutateProperty,
          },
          props,
          user,
          data.id,
        )}
      </Form.Group>
      {Form.FieldRenderer(
        Form.Input,
        {
          ...fields.title,
          onChange: props.mutateProperty,
        },
        props,
        user,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Input,
        {
          ...fields.email,
          onChange: props.mutateProperty,
        },
        props,
        user,
        data.id,
      )}
      <Form.Divider />
      <Form.Group>
        {Form.FieldRenderer(
          Form.Phone,
          {
            ...fields.primaryPhone,
            onChange: props.mutateProperty,
          },
          props,
          user,
          data.id,
        )}
        {Form.FieldRenderer(
          Form.Phone,
          {
            ...fields.secondaryPhone,
            onChange: props.mutateProperty,
          },
          props,
          user,
          data.id,
        )}
      </Form.Group>
      {Form.FieldRenderer(
        Form.Phone,
        {
          ...fields.fax,
          onChange: props.mutateProperty,
        },
        props,
        user,
        data.id,
      )}
    </>
  );
}

function LoginInfo(props) {
  const { data } = props;

  return (
    <>
      <Form.ReadOnly {...fields.username} value={data.username} />
    </>
  );
}

export default UserProfileForm;
