import { isProduction } from 'utils';

let shouldEnable: boolean | null | undefined = null;

export function toggleBatching(enable: boolean) {
  shouldEnable = enable;
}

export function isBatchingEnabled(): boolean {
  return isProduction() || Boolean(shouldEnable);
}
