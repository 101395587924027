import invariant from './invariant';

/**
 * Universal string incrementation function. It increments the last word of a sentence (ie. "Tranche A" => "Tranche B")
 * @param {string}    sentence: the string to be incremented
 * @return {string}   Return a new string with the last character incremented
 */
function incrementString(sentence: string) {
  // Trims any excess whitespace
  const cleanSentence = sentence.trim().replace(/ +/g, ' ');
  const words = cleanSentence.split(' ');
  // We only care about the last word of the sentence
  const lastWord = words[words.length - 1];
  const prefixes = words.slice(0, words.length - 1);
  const alphaNumericRegex = new RegExp(/^[a-zA-Z0-9]*$/g);

  invariant(
    alphaNumericRegex.test(lastWord),
    'Last word must only contain alphanumeric characters',
  );

  let carry = 1;

  /*
   * We default to a carry prefix of 'A'. This means that if the incremented letter is 'Z',
   * it will replace 'Z' with 'A' and append an 'A' to the result. (ie. "A" => "AA")
   */
  let carryPrefix = 'A';
  let incrementedSuffix = '';

  for (let i = lastWord.length - 1; i >= 0; i--) {
    let char = lastWord.charCodeAt(i);
    char += carry;

    if (char === 58) {
      // Numbers: [0-9] range from 48 to 57 inclusive
      char = 48;
      carry = 1;
      // This carry falls under the number range, which means that if we have a carried value,
      // the prefix will have to be a '1'. (ie. '9' => '10')
      carryPrefix = '1';
    } else if (char === 91) {
      // Capital Letters: [A-Z] range from 65 to 90 inclusive
      char = 65;
      carry = 1;
    } else if (char === 123) {
      // Lowercase Letters: [a-z] range from 97 to 122 inclusive
      char = 97;
      carry = 1;
    } else {
      carry = 0;
    }

    incrementedSuffix = String.fromCharCode(char) + incrementedSuffix;
  }

  if (carry) {
    incrementedSuffix = `${carryPrefix}${incrementedSuffix}`;
  }

  if (prefixes.length > 0) {
    return `${prefixes.join(' ')} ${incrementedSuffix}`;
  }

  return incrementedSuffix;
}

export default incrementString;
