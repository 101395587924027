import { gql } from '@apollo/client';
import { DealBasicFields, LoanTrancheBasicFields } from '../fragments';
import { DocumentNode } from '../types';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';

const TestDealsQueryDefinition: DocumentNode = gql`
  query TestDealsQuery {
    testDeals {
      ...DealBasicFields
    }
  }
  ${DealBasicFields}
`;

const TestTranchesQueryDefinition: DocumentNode = gql`
  query TestTranchesQUery {
    testTranches {
      ...LoanTrancheBasicFields
    }
  }
  ${LoanTrancheBasicFields}
`;

export const TestDealsQuery = standardQueryBuilder(TestDealsQueryDefinition, {
  typename: 'StubTestDeals',
  disableCache: true,
  results: (data: any) => {
    const { testDeals } = data;
    return { testDeals } as any;
  },
});

export const TestTranchesQuery = standardQueryBuilder(
  TestTranchesQueryDefinition,
  {
    typename: 'StubTestLoanTranche',
    disableCache: true,
    results: (data: any) => {
      const { testTranches } = data;

      return { testTranches } as any;
    },
  },
);

export default TestDealsQuery;
