import isEntity from './isEntity';
import { BaseType } from 'types';

export const isDirtyFIeld = 'isDirty';

/**
 * Determine if an object is dirty, thus requiring a mutation.
 * An object is considered dirty if e['isDirty'] === true
 * or if e.id is non-numeric
 *
 * @param {*} e
 */
function isObjectDirty<T extends BaseType>(e: T): boolean {
  // is is nonnumeric, or explicitly marked as dirty
  return !!(e as any)[isDirtyFIeld] || !isEntity(e);
}

export default isObjectDirty;
