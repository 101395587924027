import React from 'react';

import {
  Modal,
  Text,
  Form,
  WrappedMediatorFormProps,
  Button,
} from 'components';
import { useParams } from 'react-router-dom';

type Params = {
  institutionTicker?: string;
};

type SecondDrawModalProps = WrappedMediatorFormProps<any> & {
  onClose: () => void;
};

export function SecondDrawModal(props: SecondDrawModalProps) {
  const { institutionTicker } = useParams<Params>();
  const { data, onClose, mutateProperty, onSave } = props;

  if (!(data as any).ticker) {
    mutateProperty(institutionTicker, 'ticker');
  }
  /*
  const [inputError, setInputError] = useState<string>(null);


  async function handleSubmission() {
    // HACK
    if (!/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(emailAddress)) {
      setInputError('Not a valid email address');
      return;
    }

    setInputError(null);
    const res = await sendRequestEmail(emailAddress);

    if (res) {
      raiseAlert(
        'PPP platform access request sent. Please check your email for next steps.',
        'success',
      );
      onClose();
    }
  }
  */

  function CustomModalFooter() {
    return (
      <>
        <Button.Text
          disabled={props.disabled || props.isSaving}
          label={'Cancel'}
          onClick={props.onClose}
        />
        <Button
          disabled={props.disabled}
          label="Request Access"
          loading={props.isSaving}
          onClick={props.onSave}
        />
      </>
    );
  }

  return (
    <Modal
      cancelButtonText="Cancel"
      className="SecondDrawModal"
      confirmButtonText="Request Access"
      footer={<CustomModalFooter />}
      header="Request LoanStreet PPP Platform Access"
      isOpen
      onClose={onClose}
      onConfirm={onSave}
      width="Half"
    >
      <Text>
        If you don’t have an account on the LoanStreet PPP platform, enter your
        email address below. You will receive an email with further instructions
        to set up your access.
      </Text>
      {Form.FieldRenderer(
        Form.Input,
        {
          onChange: mutateProperty,
          id: 'email',
          propertyName: 'email',
          fieldName: 'Email Address',
          width: '256px',
        },
        props,
        'SbaSecondDrawOnboarding',
        props.data.id,
      )}
    </Modal>
  );
}
