import { gql } from '@apollo/client';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { PublicInstitutionType } from 'types';

/* eslint-disable */
const PublicInstitutionQueryDefinition = gql`
  query publicInstitution($ticker: String) {
    publicInstitution(ticker: $ticker) {
      name
    }
  }
`;

/* eslint-enable */
type Props = { institutionTicker: string | null | undefined };

const PublicInstitutionQuery = standardQueryBuilder(
  PublicInstitutionQueryDefinition,
  {
    typename: 'PublicInstitutionType',
    skip: ({ institutionTicker }: Props) => !institutionTicker,
    variables: ({ institutionTicker }: Props) => ({
      ticker: institutionTicker,
    }),
    results: (data: {
      publicInstitution: PublicInstitutionType | null | undefined;
    }) => {
      const { publicInstitution } = data;
      return { institution: publicInstitution };
    },
  },
);

export default PublicInstitutionQuery;
