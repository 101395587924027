import * as React from 'react';
import cx from 'classnames';
import {
  FontAwesomeIconTypes,
  IconButton,
} from '@loanstreet-usa/design-system';
import { ux } from '../../utils';
import './DataGridIconCell.scss';

const DataGridIconCell = ({
  className = '',
  icon,
  onClick,
  visible,
}: {
  className: string;
  icon: FontAwesomeIconTypes;
  onClick: () => void;
  visible?: boolean;
}) => {
  const computedClass = cx(
    'DataGridIconCell',
    ux(className, className),
    visible ? '' : 'DataGridIconCell-Hidden',
  );
  return (
    <div className={computedClass}>
      <IconButton alt="" centered icon={icon} onClick={onClick} />
    </div>
  );
};

DataGridIconCell.defaultProps = {
  visible: true,
};

export default DataGridIconCell;
