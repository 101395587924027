export const OPEN_FOLDER = 'datarooms_presentational_OPEN_FOLDER';
export const CLOSE_FOLDER = 'datarooms_presentational_CLOSE_FOLDER';
export const SORT_BY = 'datarooms_presentational_SORT_BY';

export function openFolder(folderId: string) {
  return {
    type: OPEN_FOLDER,
    payload: {
      folderId,
    },
  };
}

export function closeFolder(folderId: string) {
  return {
    type: CLOSE_FOLDER,
    payload: {
      folderId,
    },
  };
}

export function changeSortField(sortBy: {
  column: string;
  reverse: boolean | 'none';
}) {
  return {
    type: SORT_BY,
    payload: sortBy,
  };
}

export type Action = { payload: Record<string, any>; type: string };
