import * as React from 'react';
import noop from 'lodash/noop';
import { WorkflowSection } from '../workflowList';
import { StateMap } from '../Resources';
import { ForgivenessResubmissionForm, ForgivenessDenialForm } from './modals';
import { InstructionsPropsType } from './types';
import { Text, Form } from 'components';

function LenderApplicationSubmission(props: InstructionsPropsType) {
  const { sbaPppForgivenessWorkflow } = props;
  const {
    state,
    sbapppforgivenesscalculation,
    sbaForgivenessErrors,
  } = sbaPppForgivenessWorkflow;
  const {
    forgivenessAmountCalculated: forgivenessAmount,
  } = sbapppforgivenesscalculation;

  let footerText;
  if (state == StateMap.REJECTED) {
    footerText = "You have denied the Borrower's loan forgiveness application.";
  } else if (state == StateMap.BORROWER_SIGNATURE_REQUESTED) {
    footerText =
      "Once signature is requested, the Borrower will be prompted to review and sign the Forgiveness Application. LoanStreet will notify you of the SBA's decision or any request for additional information.";
  } else if (
    [
      StateMap.APPROVED,
      StateMap.SBA_PENDING_VALIDATION,
      StateMap.SBA_UNDER_REVIEW,
      StateMap.SBA_FULLY_APPROVED,
      StateMap.SBA_NOT_APPROVED,
      StateMap.SBA_PARTIALLY_APPROVED,
      StateMap.SBA_LENDER_ADDITIONAL_INFO_REQUESTED,
      StateMap.SBA_PAYMENT_SENT,
      StateMap.SBA_PAYMENT_CONFIRMED,
      StateMap.SBA_PAYMENT_FAILED,
    ].includes(state)
  ) {
    footerText =
      "Your loan forgiveness request has been submitted to the SBA. LoanStreet will notify you of the SBA's decision or any request for additional information. The current status of your forgiveness request can be found on the Loan Overview page.";
  } else {
    footerText =
      "Upon Lender approval, the Borrower will be prompted to DocuSign the forgiveness application. The signed request will then be sent directly to the SBA. LoanStreet will notify you of the SBA's decision or any request for additional information.";
  }

  return (
    <>
      <Form.Money
        disabled
        disabledReadable
        fieldName="Forgiveness Amount"
        id="forgivenessAmount"
        onChange={noop}
        value={forgivenessAmount}
        width="158px"
      />
      {sbaForgivenessErrors?.length > 0 && (
        <ForgivenessErrors errors={sbaForgivenessErrors} />
      )}
      <Text value={footerText} />
    </>
  );
}

function BorrowerApplicationSubmission(props: InstructionsPropsType) {
  const { sbaPppForgivenessWorkflow } = props;
  const { state, sbaForgivenessErrors } = sbaPppForgivenessWorkflow;

  const inPreSubmission = [
    StateMap.UNSUBMITTED,
    StateMap.CHANGES_REQUESTED,
  ].includes(state);

  const inChangedRequested = state == StateMap.CHANGES_REQUESTED;

  const inDenied = state == StateMap.REJECTED;

  if (sbaPppForgivenessWorkflow?.sbapppforgivenessdenial && inDenied) {
    return <ForgivenessDenialForm noModal />;
  }

  if (
    sbaPppForgivenessWorkflow?.sbapppforgivenessresubmitrequest &&
    inChangedRequested
  ) {
    return <ForgivenessResubmissionForm noModal />;
  }

  return (
    <>
      {sbaForgivenessErrors?.length > 0 && (
        <ForgivenessErrors errors={sbaForgivenessErrors} />
      )}
      {inPreSubmission ? (
        <Text value="After submitting your application to your Lender, please wait for their signature request or other next steps." />
      ) : (
        <Text value="Your loan forgiveness request has been submitted to the Lender. LoanStreet will notify you of the SBA's decision or any request for additional information." />
      )}
    </>
  );
}

export function ApplicationSubmissionSection(props: InstructionsPropsType) {
  const { sbaPppForgivenessWorkflow } = props;
  const { isLender } = sbaPppForgivenessWorkflow;

  return (
    <WorkflowSection
      header={`3. APPLICATION ${isLender ? 'LENDER APPROVAL' : 'SUBMISSION'}`}
    >
      {isLender ? (
        <LenderApplicationSubmission {...props} />
      ) : (
        <BorrowerApplicationSubmission {...props} />
      )}
    </WorkflowSection>
  );
}

function ForgivenessErrors(props: { errors: Array<string> }) {
  const { errors } = props;

  return (
    <div className="ForgivenessErrors">
      <Text>
        Your loan forgiveness request has been submitted to the SBA and returned
        the following message(s):
      </Text>
      {errors?.map((error: string, index: number) => (
        <p className="ForgivenessErrors__Error" key={`${error}_${index}`}>
          SBA Status Message: <span>{error}</span>
        </p>
      ))}
      <Text>These errors will persist until the SBA deems them satisfied.</Text>
    </div>
  );
}
