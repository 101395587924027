import React, { createContext, useContext } from 'react';
import Raven from 'raven-js';
import { List } from 'immutable';
import { LoadingIndicator } from '@loanstreet-usa/design-system';
import { ApolloError } from '../../graphql/types';
import { MyUserQuery } from '../../graphql/queries/MyUserQuery';

import {
  GlobalPermissionPerspectiveContextType,
  PermissionKey,
  PermissionScopeMap,
  GlobalPermissionPerspectiveContextPropTypes,
} from './types';

import { Superuser } from './constants';

import canDoAction from './methods/canDoAction';
import initializePermissionPerspective from './methods/initializePermissions';
import disposePermissionPerspective from './methods/disposePermissions';
import { invariant, isProduction } from 'utils';
import { MyUserType, PermissionsBaseType, PermissionFlags } from 'types';

/* eslint-disable react/no-unused-prop-types */
type Props = {
  children: Node;
  error?: ApolloError;
  loading: boolean;
  user?: MyUserType;
};

type State = {
  hasInitialized: boolean;
  isSuperUser: boolean;
  permissionScopeMap: PermissionScopeMap;
};

export const GlobalPermissionPerspectiveContext = createContext<GlobalPermissionPerspectiveContextType | null>(
  null,
);

export const useGlobalPermissionPerspective = () =>
  useContext(GlobalPermissionPerspectiveContext);

/**
 * Establishes the Global Permission Context.  This is mounted after a user
 * has successfully authenticated.  `PermissionPerspective` and `Secured` components
 * must be descendants of this component.
 */
class GlobalPermissionPerspective extends React.PureComponent<Props, State> {
  static computeSuperUser = (user?: MyUserType) => {
    let isSuperUser = false;

    if (user && user.permissions && user.permissions.includes(Superuser)) {
      isSuperUser = true;
    }
    return isSuperUser;
  };

  pendingDisposeQueue: List<PermissionsBaseType> = List();

  static childContextTypes = GlobalPermissionPerspectiveContextPropTypes;

  constructor(props: Props) {
    super(props);

    this.state = {
      isSuperUser: GlobalPermissionPerspective.computeSuperUser(props.user),
      permissionScopeMap: {},
      hasInitialized: false,
    };
  }

  getChildContext: () => GlobalPermissionPerspectiveContextType = () => ({
    // this will never be called until user is present,
    // so cast to hide the fact that user is undef'able
    user: (this.props.user as any) as MyUserType,
    isSuperUser: this.state.isSuperUser,
    initPermissionSet: this.initializePermissionSet,
    disposePermissionSet: this.disposePermissionSet,
    isPermitted: this.isPermitted,
    hasKey: this.hasKey,
  });

  componentDidMount() {
    if (this.props.user) {
      this.initializeGlobalContext(this.props.user);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.user && !this.props.user) {
      this.initializeGlobalContext(nextProps.user);
    }
  }

  isPermitted: (
    perspectiveKey: PermissionKey,
    flag: PermissionFlags,
  ) => boolean = (perspectiveKey: PermissionKey, flag: PermissionFlags) => {
    const res = canDoAction(
      this.state.permissionScopeMap,
      perspectiveKey,
      flag,
    );

    /* Not to be re-enabled until release
    if (this.state.isSuperUser) {
      /*
        !!! DO NOT MOVE BEFORE canDoAction INVOCATION !!!
         canDoAction checks an error condtion: that key is always present in
        the permissionMap, no matter what.  The absence of a key implies that
        a required PermissionPerspective is absent.  Devs pretty much always work
        as superUser, so if we do not allow canDoAction to check this condition,
        devs are potentially missing error conditions
      */

    /*
      debug(
        `canDoAction: true (superuser) - perspectiveKey: ${String(
          perspectiveKey,
        )} subject: ${subject} level: ${level}`,
      );
      *
      return true;
    }
    */

    /*
    debug(
      `canDoAction: ${res.toString()} - perspectiveKey: ${String(
        perspectiveKey,
      )} subject: ${subject} level: ${level}`,
    );
    */
    return res;
  };

  hasKey = (key: PermissionKey) =>
    this.state.permissionScopeMap[key] !== undefined;

  initializeGlobalContext = (user: MyUserType) => {
    invariant(
      user,
      'Attempted to initialize global permissions with an undefined user',
    );
    const isSuperUser = GlobalPermissionPerspective.computeSuperUser(user);

    this.setState(staleState => {
      const stale = staleState.permissionScopeMap;

      let fresh = initializePermissionPerspective(
        stale,
        (user as any) as PermissionsBaseType,
      );

      /*
      We might as well always have the user's institution
      permissions in scope, since we have the information here
      */
      if (user.institution) {
        fresh = initializePermissionPerspective(fresh, user.institution);
      }

      // debug('Initialize Global Context');
      return {
        isSuperUser,
        permissionScopeMap: fresh,
        hasInitialized: true,
      };
    });

    if (isProduction()) {
      Raven.setUserContext({
        username: user.username || 'Unknown user',
        email: user.email || 'Unknown user',
      });
    }
  };

  initializePermissionSet: (
    subject: PermissionsBaseType,
    callback: () => void,
  ) => void = (subject: PermissionsBaseType, callback: () => void) => {
    invariant(subject, 'Cannot initialize permissions of an undefined subject');
    // debug(`init Permissions T: ${subject.__typename} Id: ${subject.id}`);

    let pending;

    if (this.pendingDisposeQueue.count() > 0) {
      pending = this.pendingDisposeQueue;
      this.pendingDisposeQueue = List();
    }
    this.setState(prev => {
      let updated = initializePermissionPerspective(
        prev.permissionScopeMap,
        subject,
      );

      if (pending) {
        pending.forEach(e => {
          updated = disposePermissionPerspective(updated, e);
        });
      }

      return {
        permissionScopeMap: updated,
      };
    }, callback);
  };

  disposePermissionSet: (subject: PermissionsBaseType) => void = (
    subject: PermissionsBaseType,
  ) => {
    invariant(subject, 'Cannot dispose permissions of an undefined subject');

    this.pendingDisposeQueue = this.pendingDisposeQueue.push(subject);
  };

  render() {
    return !this.state.hasInitialized ? (
      <LoadingIndicator />
    ) : (
      <GlobalPermissionPerspectiveContext.Provider
        value={this.getChildContext()}
      >
        {this.props.children}
      </GlobalPermissionPerspectiveContext.Provider>
    );
  }
}

export default MyUserQuery(GlobalPermissionPerspective);
