import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import TermSheet from './TermSheet';
import { Auth0Context } from 'security';
import { invariant, isPdf } from 'utils';
import { RouteParams, withRouteParams } from 'routing';
import { withLoadingIndicator } from 'components';
import { uploadTermSheet, TermSheetQuery, TermSheetUploadData } from 'lsgql';
import { ReduxDirectory } from 'lsredux';
import raiseAlert from 'lsredux/raiseAlert';
import {
  setUploading,
  uploadSuccessOrFailure,
} from 'lsredux/actions/uploading';

export function UploadTermSheetHoC(Wrapped: any) {
  return class UploadTermSheet extends React.Component<any> {
    static contextType = Auth0Context;
    uploadFile = uploadTermSheet;

    constructor(props: any) {
      super(props);
      this.uploadFile = this.uploadFile.bind(this);
    }

    uploadFiles = async (file: File) => {
      if (!isPdf(file.name, file.type)) {
        raiseAlert(
          'Unsupported File Format: Term Sheet has to be a PDF',
          'error',
        );
        this.props.uploadSuccessOrFailure('termsheet');
        return null;
      }

      const data: TermSheetUploadData = {
        dealId: this.props.dealId,
        file,
      };

      return this.uploadFile(data).then(() => {
        invariant(
          this.props.fetchTermSheet,
          'props.fetchTermSheet must be provided',
        );
        this.props.fetchTermSheet().then(() => {
          this.props.uploadSuccessOrFailure('termsheet');
        });
      });
    };

    render() {
      return <Wrapped {...this.props} uploadFiles={this.uploadFiles} />;
    }
  };
}

const mapStateToProps = state => ({
  isUploading: state.getIn(ReduxDirectory.UploadingTermSheetKeyPath),
});

export default compose(
  withRouteParams([RouteParams.dealId]),
  connect(mapStateToProps, {
    setUploading,
    uploadSuccessOrFailure,
  }),
  TermSheetQuery,
  UploadTermSheetHoC,
  withLoadingIndicator('loading'),
  withLoadingIndicator('isUploading'),
)(TermSheet);
