import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { BenchmarksQueryDefinition } from './definitions';
import { BenchmarkType } from 'types';

const treasuryCMTSource = 'US_TREASURY_CONSTANT_MATURITY_TREASURY';
const treasuryOTRSource = 'US_TREASURY_ON_THE_RUN';

const USTreasuryLbl = 'US Treasury';
const CMTLbl = 'Constant Maturity Treasury';

const BenchmarksQuery = standardQueryBuilder(BenchmarksQueryDefinition, {
  typename: 'Benchmarks',
  results: (data: { benchmarks: Array<BenchmarkType> | null | undefined }) => {
    const { benchmarks = [] } = data;
    return { benchmarks };
  },
});

const initialBenchmarkReference = {
  allBenchmarkMap: {},
  abrBenchmarkMap: {},
  fedFundsBenchmark: { text: '', value: '' },
  liborBenchmarkMap: {},
  liborBenchmarks: [],
  liborOneMonthBenchmark: { text: '', value: '' },
  primeBenchmark: { text: '', value: '' },
  treasuryOTRIntervalMap: {},
  treasuryCMTIntervalMap: {},
  treasuryBenchmarks: [],
  benchmarkIdIntervalMap: {},
};

function getBenchmarkTextValueSortOrder(benchmark: BenchmarkType) {
  return {
    text: (benchmark.name && benchmark.name) || '',
    value: benchmark.id || '',
    sortOrder: benchmark.sortOrder,
  };
}

function transformFloatingRateTrancheBenchmarks(
  benchmarks: Array<BenchmarkType>,
) {
  initialBenchmarkReference.liborBenchmarks = [];
  initialBenchmarkReference.treasuryBenchmarks = [];

  const reducedBenchmarks = benchmarks.reduce((final, benchmark) => {
    if (benchmark.name && benchmark.name.includes('LIBOR')) {
      const benchmarkTextAndValue = getBenchmarkTextValueSortOrder(benchmark);
      final.liborBenchmarks.push(benchmarkTextAndValue);

      /* eslint-disable no-param-reassign */
      final.liborBenchmarkMap[benchmark.id] = benchmarkTextAndValue;
      if (benchmark.name && benchmark.name.includes('LIBOR 1 Month')) {
        final.liborOneMonthBenchmark = getBenchmarkTextValueSortOrder(
          benchmark,
        );
      }
    } else if (benchmark.name && benchmark.name.includes('PRIME')) {
      final.primeBenchmark = getBenchmarkTextValueSortOrder(benchmark);
    } else if (benchmark.name && benchmark.name.includes('Fed Funds')) {
      final.fedFundsBenchmark = getBenchmarkTextValueSortOrder(benchmark);
    } else if (benchmark.source === treasuryCMTSource && benchmark.interval) {
      final.treasuryCMTIntervalMap[benchmark.interval] = benchmark;

      const text = `${USTreasuryLbl} ${(benchmark.name || '').substring(
        USTreasuryLbl.length + 1 + CMTLbl.length + 1,
      )}`;
      final.treasuryBenchmarks.push({
        text,
        value: benchmark.interval,
        sortOrder: benchmark.sortOrder,
      });

      final.benchmarkIdIntervalMap[benchmark.id] = benchmark.interval;
    } else if (benchmark.source === treasuryOTRSource && benchmark.interval) {
      final.treasuryOTRIntervalMap[benchmark.interval] = benchmark;
      final.benchmarkIdIntervalMap[benchmark.id] = benchmark.interval;
    }

    final.allBenchmarkMap[benchmark.id] = getBenchmarkTextValueSortOrder(
      benchmark,
    );

    /* eslint-enable no-param-reassign */
    return final;
  }, initialBenchmarkReference);

  reducedBenchmarks.liborBenchmarks.sort(
    (a, b) => Number(a.sortOrder) - Number(b.sortOrder),
  );
  reducedBenchmarks.treasuryBenchmarks.sort(
    (a, b) => Number(a.sortOrder) - Number(b.sortOrder),
  );
  reducedBenchmarks.abrBenchmarkMap = {
    prime: reducedBenchmarks.primeBenchmark,
    fedFunds: reducedBenchmarks.fedFundsBenchmark,
    liborOneMonth: reducedBenchmarks.liborOneMonthBenchmark,
  };
  return reducedBenchmarks;
}

export const FloatingRateBenchmarksQuery = standardQueryBuilder(
  BenchmarksQueryDefinition,
  {
    typename: 'Benchmarks',
    results: (data: {
      benchmarks: Array<BenchmarkType> | null | undefined;
    }) => {
      const benchmarksReferenceData =
        data && data.benchmarks
          ? transformFloatingRateTrancheBenchmarks(data.benchmarks)
          : initialBenchmarkReference;
      return { benchmarksReferenceData };
    },
  },
);

export default BenchmarksQuery;
