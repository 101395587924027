import { connect } from 'react-redux';
import { compose, withState, withProps } from 'recompose';
import { withStagedFilesHoC } from '../../HOCs';
import CalendarTaskForm from './CalendarTaskForm';
import taskFormRedux from 'lsredux/reducer/forms/task';
import { ReduxDirectory } from 'lsredux';
import { FormMediator } from 'components';
import {
  DealQuery,
  DealOrganizationsQuery,
  EventSchedulesQuery,
  EventScheduleFormQuery,
  EventScheduleTemplateMutationMethods,
  TemplatesFromMyUserQuery,
  CalendarItemsQuery,
  CalendarItemCategoriesQuery,
  TaskCategoriesQuery,
  uploadActionFormDocument,
} from 'lsgql';
import { setAlert } from 'lsredux/actions/alerts';
import { RouteParams, withRouteParams } from 'routing';
import { AlertType } from 'types';

const mapStateToProps = (state: any) => {
  const persistedCalendarState = state
    .getIn(ReduxDirectory.CalendarKeyPath)
    .toJS();
  const calendarViewStartEndState = state
    .getIn(ReduxDirectory.CalendarViewStartEndKeyPath)
    .toJS();

  return {
    ...taskFormRedux.mapStateToProps(state),
    ...persistedCalendarState,
    ...calendarViewStartEndState,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  const bound = taskFormRedux.actions.generateActions(dispatch);

  const res: {
    setAlert: (alert: string, type: AlertType, title?: string) => void;
  } = {
    setAlert: (alert: string, type: AlertType, title?: string) => {
      dispatch(setAlert(alert, type, title));
    },
  };

  return {
    ...bound,
    ...res,
    createEventScheduleTemplate:
      EventScheduleTemplateMutationMethods.createEventScheduleTemplate,
  };
};

export default compose(
  TemplatesFromMyUserQuery,
  withRouteParams([RouteParams.dealId]),
  withState('stagedFiles', 'setStagedFiles', []),
  EventSchedulesQuery,
  EventScheduleFormQuery,
  DealQuery,
  DealOrganizationsQuery,
  connect(mapStateToProps, mapDispatchToProps),
  withProps({ uploadType: 'event_schedule' }),
  withStagedFilesHoC(uploadActionFormDocument),
  TaskCategoriesQuery,
  CalendarItemCategoriesQuery,
  CalendarItemsQuery,
  FormMediator({
    formId: 'taskForm',
    formHeader: 'Create Task',
    baseObjectTypeName: 'EventSchedule',
    disableFrame: true,
  }),
)(CalendarTaskForm);
