import * as React from 'react';
import { compose } from 'recompose';
import { MutationFunction } from '../types';
import { mutationBuilderHoC, mutationBuilder } from './builders';

// MutationMethod
import {
  CreateUserMutationDefinition,
  SetUserMutationDefinition,
} from './definitions';
import { UserCreateInput, UserSetInput, UserType } from 'types';

/* eslint-disable import/prefer-default-export */

export const UserMutationMethods = {
  createUser: mutationBuilder<UserCreateInput, UserType>(
    CreateUserMutationDefinition,
    {
      build: mutate => userData => mutate({ variables: { userData } }),
    },
  ),
  setUser: mutationBuilder<UserSetInput, UserType>(SetUserMutationDefinition, {
    build: mutate => userData => mutate({ variables: { userData } }),
  }),
};

export const UserMutations: (arg0: any) => React.ComponentType<{}> = compose(
  mutationBuilderHoC(CreateUserMutationDefinition, {
    type: 'CreateUser',
    cr: (mutate: MutationFunction, { dealId }: { dealId: string }) => ({
      createUser: (userData: UserCreateInput) => {
        const filtered = {
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          institutionId: userData.institutionId
            ? userData.institutionId
            : undefined,
        };

        return mutate({
          variables: {
            userData: filtered,
            dealId,
          },
        });
      },
    }),
  }),
  mutationBuilderHoC(SetUserMutationDefinition, {
    type: 'SetUser',
    cr: (mutate: MutationFunction) => ({
      setUser: (userData: UserSetInput) =>
        mutate({
          variables: {
            userData,
          },
        }),
    }),
  }),
);
