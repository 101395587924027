/**
 * Termsheet-related actions.
 */

export const CHANGE_PAGE = 'panels_CHANGE_PAGE';
export const CHANGE_SCALE = 'panels_CHANGE_SCALE';
export const CHANGE_ROTATION = 'panels_CHANGE_ROTATION';

export function changePage({
  id,
  pageIndex,
}: {
  id: string;
  pageIndex: number;
}) {
  return {
    type: CHANGE_PAGE,
    payload: {
      id,
      pageIndex,
    },
  };
}

export function changeScale({ id, scale }: { id: string; scale: number }) {
  return {
    type: CHANGE_SCALE,
    payload: {
      id,
      scale,
    },
  };
}

export function changeRotations({
  id,
  rotations,
}: {
  id: string;
  rotations: Map<number, number>;
}) {
  return {
    type: CHANGE_ROTATION,
    payload: {
      id,
      rotations,
    },
  };
}

export type Action = { payload: Record<string, any>; type: string };
