import * as React from 'react';
import { compose, withState, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withSummaryPageHoC, withStagedFilesHoC } from '../HOCs';
import GeneralNoticeForm from './GeneralNoticeForm';
import GeneralNoticeSummary from './GeneralNoticeSummary';
import { withLoadingIndicator, FormMediator } from 'components';

import {
  DealQuery,
  DocumentMutations,
  GeneralNoticeFormQuery,
  GeneralNoticeQuery,
  uploadActionFormDocument,
} from 'lsgql';
import { RouteParams, withRouteParams } from 'routing';
import { RouteTable } from 'routing/RouteTable';
import GeneralNoticeRedux from 'lsredux/reducer/forms/generalNotice';
import { setAlert } from 'lsredux/actions/alerts';
import { AlertType } from 'types';

const mapStateToProps = (state: any) => ({
  ...GeneralNoticeRedux.mapStateToProps(state),
});

const mapDispatchToProps = (dispatch: any) => {
  const bound = GeneralNoticeRedux.actions.generateActions(dispatch);

  const res: {
    setAlert: (alert: string, type: AlertType, title?: string) => void;
  } = {
    setAlert: (alert: string, type: AlertType, title?: string) => {
      dispatch(setAlert(alert, type, title));
    },
  };
  return { ...bound, ...res };
};

const Composed: React.ComponentType<{}> = compose(
  withState('stagedFiles', 'setStagedFiles', []),
  withRouteParams([RouteParams.dealId, RouteParams.generalNoticeId]),
  GeneralNoticeQuery,
  GeneralNoticeFormQuery,
  DealQuery,
  withLoadingIndicator('loading'),
  DocumentMutations,
  connect(mapStateToProps, mapDispatchToProps),
  withProps({ uploadType: 'general_notice' }),
  withStagedFilesHoC(uploadActionFormDocument),
  withSummaryPageHoC(GeneralNoticeSummary, 'generalNotice'),
  FormMediator({
    formId: 'generalNoticeForm',
    formHeader: 'General Notice',
    baseObjectTypeName: 'GeneralNotice',
    blurEventForm: true,
    withSaveAsDraft: true,
    postSaveRoutePath: RouteTable.deal.toGeneralNoticeFork,
  }),
)(GeneralNoticeForm);

export default Composed;
