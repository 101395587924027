import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import organizationMapperHoC, {
  MappedOrganizations,
} from '../organizationMapperHoC';
import { InvitedUsersSection } from '../types';
import DealPeopleContent from './DealPeopleContent';
import { ViewType } from './types';
import { RouteParams, withRouteParams } from 'routing';
import { ReduxDirectory } from 'lsredux';
import { generateTable } from 'lsredux/generate';
import { withToJS } from 'utils';

import {
  DeleteUserDealRoleType,
  ID,
  UserType,
  DealInstitutionType,
} from 'types';
import { RolePermissionMutationMethods, MyUserQuery } from 'lsgql';

type OwnProps = MappedOrganizations & {
  user: UserType;
  view: ViewType;
};

type MappedProps = {
  currentUserId: ID;
  entities: Array<
    DealInstitutionType | (InvitedUsersSection | null | undefined)
  >;
  view: ViewType;
};

function getEntitiesForCurrentView(ownProps) {
  const {
    view,
    coLenders,
    servicingAgents,
    leadLenders,
    borrowers,
    userInstitution,
  } = ownProps;
  switch (view) {
    case 'CoLenders':
      return coLenders;
    case 'ServicingAgents':
      return servicingAgents;
    case 'LeadLender':
      return leadLenders;
    case 'Borrower':
      return borrowers;
    case 'MyTeam':
      return userInstitution;
    default:
      return [];
  }
}

function propsMapper(ownProps: OwnProps): MappedProps {
  const { view, user } = ownProps;

  return {
    entities: getEntitiesForCurrentView(ownProps),
    view,
    currentUserId: user.id,
  };
}

const mapStateToProps: (
  state: any,
  ownProps: any,
) => { onRemoveUser: (userId: ID) => any; rawData: any } = (
  state,
  ownProps,
) => ({
  rawData: state.getIn(ReduxDirectory.DealPeopleKeyPath),
  onRemoveUser: (userId: ID) => {
    const data: DeleteUserDealRoleType = {
      dealId: ownProps.dealId,
      userId,
    };
    return RolePermissionMutationMethods.deleteUserDealRole(data);
  },
});

const { changeSortField } = generateTable.methods('people');

export default compose(
  organizationMapperHoC,
  MyUserQuery,
  mapProps(propsMapper),
  withRouteParams([RouteParams.dealId]),
  connect(mapStateToProps, {
    changeSortField,
  }),
  withToJS,
)(DealPeopleContent);
