import uuid from 'uuid/v4';
import {
  SbaPppForgivenessEzFormQualificationsMutationMethods,
  SbaPppForgivenessEzFormQualificationsValidator,
} from '../../../graphql';
import Directory from '../../directory';
import { createForm } from '../../genericForms';
import { DealType } from 'types';

const initialDeal: DealType = {
  id: uuid(),
  __typename: 'DealType',
};

const lifecycleMethods = {
  mutations: SbaPppForgivenessEzFormQualificationsMutationMethods,
  validators: {
    SbaPppForgivenessEzFormQualifications: SbaPppForgivenessEzFormQualificationsValidator,
  },
};

const sbaPppForgivenessEzFormQualificationsFormRedux = createForm<
  DealType,
  DealType
>(
  Directory.SbaPppForgivenessEzFormQualificationsKey,
  initialDeal,
  lifecycleMethods,
);

export default sbaPppForgivenessEzFormQualificationsFormRedux;
