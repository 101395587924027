import uuid from 'uuid/v4';
import {
  InstitutionMutationMethods,
  UserMutationMethods,
} from '../../../graphql/mutations';

import Directory from '../../directory';
import { createForm } from '../../genericForms';
import {
  RegisterFormInputType,
  UserType,
  MyUserType,
  RegisterFormType,
} from 'types';

const initialUser: UserType = {
  email: '',
  firstName: '',
  lastName: '',
  id: uuid(),
  __typename: 'UserType',
};

const workaroundType: any = 'StubRegisterFormInputType';

const initial: RegisterFormInputType = {
  id: uuid(),
  user: initialUser as any,
  __typename: workaroundType,
} as any;

const lifecycle = {
  mutations: {
    ...InstitutionMutationMethods,
    ...UserMutationMethods,
  },
  validators: {},
};

function convertQueryInput(input: MyUserType): RegisterFormType {
  return {
    id: input.id,
    institution: input.institution,

    user: {
      ...input,
      institution: undefined,
      // Don't nest the user object
      user: undefined,
    } as any,
    __typename: 'StubUserType',
  };
}

export default createForm<RegisterFormType, MyUserType>(
  Directory.RegistrationKey,
  initial,
  lifecycle,
  {
    enableAutoSave: false,
    dataConverter: convertQueryInput,
  },
);
