import { gql } from '@apollo/client';
import { getAuthenticatedClient } from '../authenticatedClient';
import { invariant } from 'utils';
import { ID } from 'types';

/* eslint-disable */
const NewUserOnboardSecondDrawApplicationMutationDefinition = gql`
  mutation NewUserOnboardSbaApplicationMutation($applicationId: ID!) {
    newUserOnboardSecondDrawApplication(applicationId: $applicationId) {
      ok
    }
  }
`;
/* eslint-enable */

export function TriggerNewUserSbaSecondDrawOnboardingMutation(
  applicationId: ID,
) {
  invariant(applicationId, 'applicationId is required');

  const client = getAuthenticatedClient();
  invariant(client, 'Failed to create Public Client');

  return client.mutate<any>({
    mutation: NewUserOnboardSecondDrawApplicationMutationDefinition,
    variables: {
      applicationId,
    },
    refetchQueries: [
      'DealQuery',
      'DealsQuery',
      'UserQuery',
      'MyUserQuery',
      'LegacyDealsQuery',
    ],
  });
}

export default TriggerNewUserSbaSecondDrawOnboardingMutation;
