import { mutationBuilder } from './builders';

import {
  CreateLoanTrancheMutationDefinition,
  SetLoanTrancheMutationDefinition,
  DeleteLoanTrancheMutationDefinition,
} from './definitions';
import { ID, LoanTrancheInput, LoanTrancheType } from 'types';

const LoanTrancheMutations = {
  createLoanTranche: mutationBuilder<LoanTrancheInput, LoanTrancheType>(
    CreateLoanTrancheMutationDefinition,
    {
      build: mutate => loanTrancheData =>
        mutate({
          variables: {
            loanTrancheData,
          },
          refetchQueries: ['DealQuery'],
        }),
    },
  ),
  setLoanTranche: mutationBuilder<LoanTrancheInput, LoanTrancheType>(
    SetLoanTrancheMutationDefinition,
    {
      build: mutate => loanTrancheData =>
        mutate({
          variables: {
            loanTrancheData,
          },
        }),
    },
  ),
  deleteLoanTranche: mutationBuilder<ID, void>(
    DeleteLoanTrancheMutationDefinition,
    {
      build: mutate => (id: string) =>
        mutate({ variables: { id }, refetchQueries: ['DealQuery'] }),
    },
  ),
};

export default LoanTrancheMutations;
