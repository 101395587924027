/* eslint-disable import/prefer-default-export */

export { default as CloseDealMutationDefinition } from './CloseDeal';
export { default as CreateAdditionalSbaPppBorrowerInformationMutationDefinition } from './CreateAdditionalSbaPppBorrowerInformation';
export { default as CreateAlternateBaseRateBenchmarkMutationDefinition } from './CreateAlternateBaseRateBenchmark';
export { default as CreateApplicableMarginMutationDefinition } from './CreateApplicableMargin';
export { default as CreateBenchmarkOptionMutationDefinition } from './CreateBenchmarkOption';
export { default as CreateCancelLoanMutationDefinition } from './CreateCancelLoan';
export { default as CreateCovenantMutationDefinition } from './CreateCovenant';
export { default as CreateCreditRatingMutationDefinition } from './CreateCreditRating';
export { default as CreateDealMutationDefinition } from './CreateDeal';
export { default as CreateDealAssigneeMutationDefinition } from './CreateDealAssignee';
export { default as CreateDealClosingWorkflowMutationDefinition } from './CreateDealClosingWorkflow';
export { default as CreateDealClosingWorkflowStepMutationDefinition } from './CreateDealClosingWorkflowStep';
export { default as CreateDealFavoriteMutationDefinition } from './CreateDealFavorite';
export { default as CreateEscrowAccountMutationDefinition } from './CreateEscrowAccount';
export { default as CreateEventDueMutationDefinition } from './CreateEventDue';
export { default as CreateEventScheduleMutationDefinition } from './CreateEventSchedule';
export { default as CreateEventScheduleTemplateMutationDefinition } from './CreateEventScheduleTemplate';
export { default as CreateFeeMutationDefinition } from './CreateFee';
export { default as CreateFeeCalculationRuleMutationDefinition } from './CreateFeeCalculationRule';
export { default as CreateFolderMutationDefinition } from './CreateFolder';
export { default as CreateGeneralNoticeMutationDefinition } from './CreateGeneralNotice';
export { default as CreateGuaranteeMutationDefinition } from './CreateGuarantee';
export { default as CreateInstitutionMutationDefinition } from './CreateInstitution';
export { default as CreateInstitutionBankAccountMutationDefinition } from './CreateInstitutionBankAccount';
export { default as CreateInstitutionInvitationsMutationDefinition } from './CreateInstitutionInvitations';
export { default as CreateLoanFundingMutationDefinition } from './CreateLoanFunding';
export { default as CreateLoanSliceMutationDefinition } from './CreateLoanSlice';
export { default as CreateLoanTrancheMutationDefinition } from './CreateLoanTranche';
export { default as CreateLoanTrancheAdjustableRateDataMutationDefinition } from './CreateLoanTrancheAdjustableRateData';
export { default as CreateLoanTrancheAdjustableRatePeriodMutationDefinition } from './CreateLoanTrancheAdjustableRatePeriod';
export { default as CreateLoanTrancheAmortizationMutationDefinition } from './CreateLoanTrancheAmortization';
export { default as CreateLoanTrancheCollateralMutationDefinition } from './CreateLoanTrancheCollateral';
export { default as CreateLoanTrancheFloatingRateDataMutationDefinition } from './CreateLoanTrancheFloatingRateData';
export { default as CreateLoanTrancheGuarantorMutationDefinition } from './CreateLoanTrancheGuarantor';
export { default as CreateLoanTrancheInstitutionOwnershipPortionMutationDefinition } from './CreateLoanTrancheInstitutionOwnershipPortion';
export { default as CreateLoanTrancheMultiDrawDataMutationDefinition } from './CreateLoanTrancheMultiDrawData';
export { default as CreateLoanTrancheOwnershipPortionMutationDefinition } from './CreateLoanTrancheOwnershipPortion';
export { default as CreateLoanTrancheRevolverSwinglineLocDataMutationDefinition } from './CreateLoanTrancheRevolverSwinglineLocData';
export { default as CreateLoanTrancheTierMutationDefinition } from './CreateLoanTrancheTier';
export { default as CreatePrepaymentPenaltyRangeMutationDefinition } from './CreatePrepaymentPenaltyRange';
export { default as CreateProspectMutationDefinition } from './CreateProspect';
export { default as CreateReserveMutationDefinition } from './CreateReserve';
export { default as CreateSbaAccountMutationDefinition } from './CreateSbaAccount';
export { default as CreateSbaPppApplicantOwnershipMutationDefinition } from './CreateSbaPppApplicantOwnership';
export { default as CreateSbaPppApplicationResubmitRequestMutationDefinition } from './CreateSbaPppApplicationResubmitRequest';
export { default as CreateSbaPppForgivenessDenialMutationDefinition } from './CreateSbaPppForgivenessDenial';
export { default as CreateSbaPppForgivenessEzFormQualificationsMutationDefinition } from './CreateSbaPppForgivenessEzFormQualifications';
export { default as CreateSbaPppForgivenessResubmitRequestMutationDefinition } from './CreateSbaPppForgivenessResubmitRequest';
export { default as CreateSbaPppInstitutionMutationDefinition } from './CreateSbaPppInstitution';
export { default as CreateSbaPppLenderClosingWorkflowQuestionsMutationDefinition } from './CreateSbaPppLenderClosingWorkflowQuestions';
export { default as CreateSbaPppLoanApplicationMutationDefinition } from './CreateSbaPppLoanApplication';
export { default as CreateSbaPppLoanFinancialsMutationDefinition } from './CreateSbaPppLoanFinancials';
export { default as CreateSbaPppRoundTwoLenderApplicationMutationDefinition } from './CreateSbaPppRoundTwoLenderApplication';
export { default as CreateSbaPppRoundTwoSecondDrawLenderApplicationMutationDefinition } from './CreateSbaPppRoundTwoSecondDrawLenderApplication';
export { default as CreateTierFeePercentageMutationDefinition } from './CreateTierFeePercentage';
export { default as CreateUserMutationDefinition } from './CreateUser';
export { default as DeleteAdditionalSbaPppBorrowerInformationMutationDefinition } from './DeleteAdditionalSbaPppBorrowerInformation';
export { default as DeleteAlternateBaseRateBenchmarkMutationDefinition } from './DeleteAlternateBaseRateBenchmark';
export { default as DeleteApplicableMarginMutationDefinition } from './DeleteApplicableMargin';
export { default as DeleteBenchmarkOptionMutationDefinition } from './DeleteBenchmarkOption';
export { default as DeleteCancelLoanMutationDefinition } from './DeleteCancelLoan';
export { default as DeleteCovenantMutationDefinition } from './DeleteCovenant';
export { default as DeleteCreditRatingMutationDefinition } from './DeleteCreditRating';
export { default as DeleteDealMutationDefinition } from './DeleteDeal';
export { default as DeleteDealAssigneeMutationDefinition } from './DeleteDealAssignee';
export { default as DeleteDealClosingWorkflowMutationDefinition } from './DeleteDealClosingWorkflow';
export { default as DeleteDealClosingWorkflowStepMutationDefinition } from './DeleteDealClosingWorkflowStep';
export { default as DeleteDealFavoriteMutationDefinition } from './DeleteDealFavorite';
export { default as DeleteDocumentMutationDefinition } from './DeleteDocument';
export { default as DeleteEscrowAccountMutationDefinition } from './DeleteEscrowAccount';
export { default as DeleteEventDueMutationDefinition } from './DeleteEventDue';
export { default as DeleteEventScheduleMutationDefinition } from './DeleteEventSchedule';
export { default as DeleteEventScheduleTemplateMutationDefinition } from './DeleteEventScheduleTemplate';
export { default as DeleteFeeMutationDefinition } from './DeleteFee';
export { default as DeleteFeeCalculationRuleMutationDefinition } from './DeleteFeeCalculationRule';
export { default as DeleteFolderMutationDefinition } from './DeleteFolder';
export { default as DeleteGuaranteeMutationDefinition } from './DeleteGuarantee';
export { default as DeleteInstitutionMutationDefinition } from './DeleteInstitution';
export { default as DeleteInstitutionBankAccountMutationDefinition } from './DeleteInstitutionBankAccount';
export { default as DeleteInvitationMutationDefinition } from './DeleteInvitation';
export { default as DeleteLoanFundingMutationDefinition } from './DeleteLoanFunding';
export { default as DeleteLoanSliceMutationDefinition } from './DeleteLoanSlice';
export { default as DeleteLoanTrancheMutationDefinition } from './DeleteLoanTranche';
export { default as DeleteLoanTrancheAdjustableRateDataMutationDefinition } from './DeleteLoanTrancheAdjustableRateData';
export { default as DeleteLoanTrancheAdjustableRatePeriodMutationDefinition } from './DeleteLoanTrancheAdjustableRatePeriod';
export { default as DeleteLoanTrancheAmortizationMutationDefinition } from './DeleteLoanTrancheAmortization';
export { default as DeleteLoanTrancheCollateralMutationDefinition } from './DeleteLoanTrancheCollateral';
export { default as DeleteLoanTrancheFloatingRateDataMutationDefinition } from './DeleteLoanTrancheFloatingRateData';
export { default as DeleteLoanTrancheGuarantorMutationDefinition } from './DeleteLoanTrancheGuarantor';
export { default as DeleteLoanTrancheInstitutionOwnershipPortionMutationDefinition } from './DeleteLoanTrancheInstitutionOwnershipPortion';
export { default as DeleteLoanTrancheMultiDrawDataMutationDefinition } from './DeleteLoanTrancheMultiDrawData';
export { default as DeleteLoanTrancheOwnershipPortionMutationDefinition } from './DeleteLoanTrancheOwnershipPortion';
export { default as DeleteLoanTrancheTierMutationDefinition } from './DeleteLoanTrancheTier';
export { default as DeletePrepaymentPenaltyRangeMutationDefinition } from './DeletePrepaymentPenaltyRange';
export { default as DeleteProspectMutationDefinition } from './DeleteProspect';
export { default as DeleteReserveMutationDefinition } from './DeleteReserve';
export { default as DeleteSbaAccountMutationDefinition } from './DeleteSbaAccount';
export { default as DeleteSbaPppApplicantOwnershipMutationDefinition } from './DeleteSbaPppApplicantOwnership';
export { default as DeleteSbaPppApplicationResubmitRequestMutationDefinition } from './DeleteSbaPppApplicationResubmitRequest';
export { default as DeleteSbaPppInstitutionMutationDefinition } from './DeleteSbaPppInstitution';
export { default as DeleteSbaPppLenderClosingWorkflowQuestionsMutationDefinition } from './DeleteSbaPppLenderClosingWorkflowQuestions';
export { default as DeleteSbaPppLoanApplicationMutationDefinition } from './DeleteSbaPppLoanApplication';
export { default as DeleteSbaPppLoanFinancialsMutationDefinition } from './DeleteSbaPppLoanFinancials';
export { default as DeleteTierFeePercentageMutationDefinition } from './DeleteTierFeePercentage';
export { default as GenerateDebugDealMutationDefinition } from './GenerateDebugDeal';
export { default as GenerateSbaBorrowerPdfMutationDefinition } from './GenerateSbaBorrowerPdf';
export { default as GenerateSbaLenderPdfMutationDefinition } from './GenerateSbaLenderPdf';
export { default as NewUserOnboardSecondDrawApplicationMutationDefinition } from './NewUserOnboardSecondDrawApplication';
export { default as OnboardExistingUserFirstDrawApplicationMutationDefinition } from './OnboardExistingUserFirstDrawApplication';
export { default as OnboardSecondDrawApplicationMutationDefinition } from './OnboardSecondDrawApplication';
export { default as OriginateLoanMutationDefinition } from './OriginateLoan';
export { default as ResendInvitationMutationDefinition } from './ResendInvitation';
export { default as SendLenderApplicationMutationDefinition } from './SendLenderApplication';
export { default as SendPromissoryNoteMutationDefinition } from './SendPromissoryNote';
export { default as SetAdditionalSbaPppBorrowerInformationMutationDefinition } from './SetAdditionalSbaPppBorrowerInformation';
export { default as SetAlternateBaseRateBenchmarkMutationDefinition } from './SetAlternateBaseRateBenchmark';
export { default as SetApplicableMarginMutationDefinition } from './SetApplicableMargin';
export { default as SetBenchmarkOptionMutationDefinition } from './SetBenchmarkOption';
export { default as SetCancelLoanMutationDefinition } from './SetCancelLoan';
export { default as SetCovenantMutationDefinition } from './SetCovenant';
export { default as SetCreditRatingMutationDefinition } from './SetCreditRating';
export { default as SetDealMutationDefinition } from './SetDeal';
export { default as SetDealClosingWorkflowMutationDefinition } from './SetDealClosingWorkflow';
export { default as SetDealClosingWorkflowStepMutationDefinition } from './SetDealClosingWorkflowStep';
export { default as SetDocumentMutationDefinition } from './SetDocument';
export { default as SetEscrowAccountMutationDefinition } from './SetEscrowAccount';
export { default as SetEventDueMutationDefinition } from './SetEventDue';
export { default as SetEventScheduleMutationDefinition } from './SetEventSchedule';
export { default as SetEventScheduleTemplateMutationDefinition } from './SetEventScheduleTemplate';
export { default as SetFeeMutationDefinition } from './SetFee';
export { default as SetFeeCalculationRuleMutationDefinition } from './SetFeeCalculationRule';
export { default as SetFolderMutationDefinition } from './SetFolder';
export { default as SetGeneralNoticeMutationDefinition } from './SetGeneralNotice';
export { default as SetGuaranteeMutationDefinition } from './SetGuarantee';
export { default as SetInstitutionMutationDefinition } from './SetInstitution';
export { default as SetInstitutionBankAccountMutationDefinition } from './SetInstitutionBankAccount';
export { default as SetLoanFundingMutationDefinition } from './SetLoanFunding';
export { default as SetLoanSliceMutationDefinition } from './SetLoanSlice';
export { default as SetLoanTrancheMutationDefinition } from './SetLoanTranche';
export { default as SetLoanTrancheAdjustableRateDataMutationDefinition } from './SetLoanTrancheAdjustableRateData';
export { default as SetLoanTrancheAdjustableRatePeriodMutationDefinition } from './SetLoanTrancheAdjustableRatePeriod';
export { default as SetLoanTrancheAmortizationMutationDefinition } from './SetLoanTrancheAmortization';
export { default as SetLoanTrancheCollateralMutationDefinition } from './SetLoanTrancheCollateral';
export { default as SetLoanTrancheFloatingRateDataMutationDefinition } from './SetLoanTrancheFloatingRateData';
export { default as SetLoanTrancheGuarantorMutationDefinition } from './SetLoanTrancheGuarantor';
export { default as SetLoanTrancheInstitutionOwnershipPortionMutationDefinition } from './SetLoanTrancheInstitutionOwnershipPortion';
export { default as SetLoanTrancheMultiDrawDataMutationDefinition } from './SetLoanTrancheMultiDrawData';
export { default as SetLoanTrancheOwnershipPortionMutationDefinition } from './SetLoanTrancheOwnershipPortion';
export { default as SetLoanTrancheRevolverSwinglineLocDataMutationDefinition } from './SetLoanTrancheRevolverSwinglineLocData';
export { default as SetLoanTrancheTierMutationDefinition } from './SetLoanTrancheTier';
export { default as SetOrganizationDealRoleMutationDefinition } from './SetOrganizationDealRole';
export { default as SetOrganizationFolderRoleMutationDefinition } from './SetOrganizationFolderRole';
export { default as SetPaymentRecordBorrowerMutationDefinition } from './SetPaymentRecordBorrower';
export { default as SetPaymentRecordLenderMutationDefinition } from './SetPaymentRecordLender';
export { default as SetPrepaymentPenaltyRangeMutationDefinition } from './SetPrepaymentPenaltyRange';
export { default as SetProspectMutationDefinition } from './SetProspect';
export { default as SetReserveMutationDefinition } from './SetReserve';
export { default as SetSbaAccountMutationDefinition } from './SetSbaAccount';
export { default as SetSbaPppApplicantOwnershipMutationDefinition } from './SetSbaPppApplicantOwnership';
export { default as SetSbaPppApplicationResubmitRequestMutationDefinition } from './SetSbaPppApplicationResubmitRequest';
export { default as SetSbaPppForgivenessCalculationMutationDefinition } from './SetSbaPppForgivenessCalculation';
export { default as SetSbaPppForgivenessDenialMutationDefinition } from './SetSbaPppForgivenessDenial';
export { default as SetSbaPppForgivenessEzFormQualificationsMutationDefinition } from './SetSbaPppForgivenessEzFormQualifications';
export { default as SetSbaPppForgivenessLoanInformationMutationDefinition } from './SetSbaPppForgivenessLoanInformation';
export { default as SetSbaPppForgivenessResubmitRequestMutationDefinition } from './SetSbaPppForgivenessResubmitRequest';
export { default as SetSbaPppForgivenessWorkflowMutationDefinition } from './SetSbaPppForgivenessWorkflow';
export { default as SetSbaPppInstitutionMutationDefinition } from './SetSbaPppInstitution';
export { default as SetSbaPppLenderClosingWorkflowQuestionsMutationDefinition } from './SetSbaPppLenderClosingWorkflowQuestions';
export { default as SetSbaPppLoanApplicationMutationDefinition } from './SetSbaPppLoanApplication';
export { default as SetSbaPppLoanFinancialsMutationDefinition } from './SetSbaPppLoanFinancials';
export { default as SetSbaPppRoundTwoLenderApplicationMutationDefinition } from './SetSbaPppRoundTwoLenderApplication';
export { default as SetSbaPppRoundTwoSecondDrawLenderApplicationMutationDefinition } from './SetSbaPppRoundTwoSecondDrawLenderApplication';
export { default as SetTierFeePercentageMutationDefinition } from './SetTierFeePercentage';
export { default as SetUserMutationDefinition } from './SetUser';
export { default as SetUserDealRoleMutationDefinition } from './SetUserDealRole';
export { default as TransitionClosingWorkflowMutationDefinition } from './TransitionClosingWorkflow';
export { default as TransitionForgivenessWorkflowMutationDefinition } from './TransitionForgivenessWorkflow';
