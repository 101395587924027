import * as React from 'react';
import { LoanTrancheFormProps } from '../types';
import TrancheGroup from './TrancheGroup';
import { getBenchmarkOptions, isFloatingRate, isLetterOfCredit } from './utils';
import { timeZoneOptions } from 'resources';
import { Form, Header } from 'components';

import './InterestElectionRulesFields.scss';

const loanTranche = 'LoanTranche';
const width = '158px';
const widthMinusOne = '120px';
const tMinusOptions = [
  { value: '1', text: 'T - 1' },
  { value: '2', text: 'T - 2' },
  { value: '3', text: 'T - 3' },
  { value: '4', text: 'T - 4' },
  { value: '5', text: 'T - 5' },
];

class InterestElectionRulesFields extends React.Component<
  LoanTrancheFormProps
> {
  fields: any;

  constructor(props: LoanTrancheFormProps) {
    super(props);

    this.fields = {
      minimumElectionNoticePeriod: {
        libor: {
          select: {
            id: 'liborRateElectionMinimumNoticePeriod',
            propertyName: 'liborRateElectionMinimumNoticePeriod',
            fieldName: 'LIBOR Election',
            onChange: this.handleUpdateProperty,
            options: tMinusOptions,
            width,
            placeholder: 'N/A',
          },
        },
        convertToOvernightAbr: {
          select: {
            id: 'convertToOvernightAbrMinimumNoticePeriod',
            propertyName: 'convertToOvernightAbrMinimumNoticePeriod',
            fieldName: 'Convert to Overnight/ABR',
            onChange: this.handleUpdateProperty,
            options: tMinusOptions,
            width,
            placeholder: 'N/A',
          },
        },
      },
      time: {
        id: 'interestElectionTime',
        propertyName: 'interestElectionTime',
        fieldName: 'Time',
        onChange: this.handleUpdateProperty,
      },
      interestElectionTimeZone: {
        id: 'interestElectionTimeZone',
        propertyName: 'interestElectionTimeZone',
        fieldName: 'Time Zone',
        onChange: this.handleUpdateProperty,
        options: timeZoneOptions,
        width,
      },
      effectiveDay: {
        isNextPaymentPeriod: {
          id: 'effectiveDayIsNextPaymentPeriod',
          propertyName: 'effectiveDayIsNextPaymentPeriod',
          options: [
            {
              id: 'notNextPaymentPeriod',
              label: 'Enter days:',
            },
            {
              id: 'nextPaymentPeriod',
              label: 'Next Payment Period',
            },
          ],
          onChange: this.handleUpdateIsNextPaymentPeriod,
          width,
        },
        offset: {
          id: 'effectiveDayOffset',
          propertyName: 'effectiveDayOffset',
          fieldName: '',
          onChange: this.handleUpdateProperty,
          suffix: 'days',
          positive: true,
          width: widthMinusOne,
        },
      },
      liborRateCalculationDate: {
        select: {
          id: 'liborRateCalculationDate',
          propertyName: 'liborRateCalculationDate',
          fieldName: 'LIBOR',
          onChange: this.handleUpdateProperty,
          options: tMinusOptions,
          width,
          placeholder: 'N/A',
        },
      },
      noNoticeReceived: {
        id: 'noNoticeReceivedFallback',
        propertyName: 'noNoticeReceivedFallback',
        fieldName: 'No Notice Received',
        onChange: this.handleUpdateProperty,
        width,
        typeName: 'LoanTrancheFloatingRateDataNoNoticeReceivedFallback',
      },
    };
  }

  handleUpdateProperty = (
    value: string | null | undefined | boolean,
    fieldId: string,
  ) => {
    const {
      onUpdateLoanTrancheFloatingRateData,
      data: { loantranchefloatingratedata },
    } = this.props;

    onUpdateLoanTrancheFloatingRateData({
      ...loantranchefloatingratedata,
      [fieldId]: value,
    });
  };

  handleUpdateIsNextPaymentPeriod = (
    value: string | null | undefined,
    fieldId: string,
  ) => {
    const {
      onUpdateLoanTrancheFloatingRateData,
      data: { loantranchefloatingratedata },
    } = this.props;

    onUpdateLoanTrancheFloatingRateData({
      ...loantranchefloatingratedata,
      [fieldId]: value === 'nextPaymentPeriod',
      effectiveDayOffset: null,
    });
  };

  render() {
    const { data } = this.props;

    if (!isFloatingRate(data) || isLetterOfCredit(data)) return null;

    const { loantranchefloatingratedata } = data;
    const {
      liborBenchmarkOptions,
      primeBenchmarkOption,
      fedFundsBenchmarkOption,
      isAbrSelected,
    } = getBenchmarkOptions(this.props);

    const existAtLeastOneLibor = liborBenchmarkOptions.length > 0;
    const existAtLeastTwoLibor = liborBenchmarkOptions.length > 1;
    const existAtLeastOneNonLibor =
      Boolean(primeBenchmarkOption.benchmarkId) ||
      Boolean(fedFundsBenchmarkOption.benchmarkId) ||
      isAbrSelected;

    const id = loantranchefloatingratedata
      ? loantranchefloatingratedata.id
      : '';

    return (
      <TrancheGroup>
        {existAtLeastOneLibor && (
          <Header as="h5" className="steel bold">
            Minimum Election Notice Period
          </Header>
        )}
        {existAtLeastTwoLibor && (
          <Form.Group>
            {Form.FieldRenderer(
              Form.Select,
              {
                ...this.fields.minimumElectionNoticePeriod.libor.select,
                value:
                  loantranchefloatingratedata &&
                  loantranchefloatingratedata.liborRateElectionMinimumNoticePeriod,
              },
              this.props,
              loanTranche,
              id,
            )}
          </Form.Group>
        )}
        {existAtLeastOneLibor && existAtLeastOneNonLibor && (
          <Form.Group>
            {Form.FieldRenderer(
              Form.Select,
              {
                ...this.fields.minimumElectionNoticePeriod.convertToOvernightAbr
                  .select,
                value:
                  loantranchefloatingratedata &&
                  loantranchefloatingratedata.convertToOvernightAbrMinimumNoticePeriod,
              },
              this.props,
              loanTranche,
              id,
            )}
          </Form.Group>
        )}
        {existAtLeastOneLibor && (
          <Form.Group>
            {Form.FieldRenderer(
              Form.Time,
              {
                ...this.fields.time,
                value:
                  loantranchefloatingratedata &&
                  loantranchefloatingratedata.interestElectionTime,
              },
              this.props,
              loanTranche,
              id,
            )}
            {Form.FieldRenderer(
              Form.Select,
              {
                ...this.fields.interestElectionTimeZone,
                value:
                  loantranchefloatingratedata &&
                  loantranchefloatingratedata.interestElectionTimeZone,
              },
              this.props,
              loanTranche,
              id,
            )}
          </Form.Group>
        )}
        <Form.Divider className="transparent" />
        <Header as="h5" className="steel bold">
          Rate Calculation Date
        </Header>
        <Form.Group>
          {Form.FieldRenderer(
            Form.Select,
            {
              ...this.fields.liborRateCalculationDate.select,
              value:
                loantranchefloatingratedata &&
                loantranchefloatingratedata.liborRateCalculationDate,
            },
            this.props,
            loanTranche,
            id,
          )}
          {existAtLeastOneLibor &&
            existAtLeastOneNonLibor &&
            Form.FieldRenderer(
              Form.ReferenceSelect,
              {
                ...this.fields.noNoticeReceived,
                value:
                  loantranchefloatingratedata &&
                  loantranchefloatingratedata.noNoticeReceivedFallback,
                resultFilter: !isAbrSelected
                  ? res =>
                      res.filter(
                        option => option.value !== 'ALTERNATE_BASE_RATE',
                      )
                  : null,
              },
              this.props,
              loanTranche,
              id,
            )}
        </Form.Group>

        <Form.Divider className="transparent" />
        <Header as="h5" className="steel bold">
          Effective Day
        </Header>
        <Form.Group className="EffectiveDayRow">
          {Form.FieldRenderer(
            Form.Radio,
            {
              ...this.fields.effectiveDay.isNextPaymentPeriod,
              value: loantranchefloatingratedata?.effectiveDayIsNextPaymentPeriod
                ? 'nextPaymentPeriod'
                : 'notNextPaymentPeriod',
            },
            this.props,
            loanTranche,
            id,
          )}
          {!loantranchefloatingratedata.effectiveDayIsNextPaymentPeriod &&
            Form.FieldRenderer(
              Form.Offset,
              {
                ...this.fields.effectiveDay.offset,

                value:
                  loantranchefloatingratedata &&
                  loantranchefloatingratedata.effectiveDayOffset,
              },
              this.props,
              loanTranche,
              id,
            )}
        </Form.Group>
      </TrancheGroup>
    );
  }
}

export default InterestElectionRulesFields;
