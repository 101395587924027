import * as React from 'react';
import { OrganizationPermissions } from '../../../../types';
import { ID, DealUserType } from 'types';
import { IconButton } from 'components';
import { MountGuard } from 'security';

type Props = {
  currentUserId: ID;
  onRemoveUser: (dealUser: DealUserType) => void;
  permissions: OrganizationPermissions;
  user: DealUserType;
};

class RemoveUserCell extends React.Component<Props> {
  handleRemoveUser = () => {
    this.props.onRemoveUser(this.props.user);
  };

  render() {
    const { permissions, currentUserId, user } = this.props;
    return (
      <MountGuard permission={permissions.adminPermission}>
        {currentUserId !== user.id && (
          <IconButton.Delete
            alt="Remove User From Deal"
            onClick={this.handleRemoveUser}
          />
        )}
      </MountGuard>
    );
  }
}

export default RemoveUserCell;
