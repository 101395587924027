import isCommitHash from './isCommitHash';

/**
 * Gets local commit hash of the frontend bundle (only available in
 * production). Returns it if it's an SHA1 hash or null otherwise.
 */
function getLocalHash(): string | null | undefined {
  const gitCommit = process.env.REACT_APP_GIT_COMMIT;
  if (isCommitHash(gitCommit)) return gitCommit;
  return null;
}

export default getLocalHash;
