import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import {
  LoanTrancheTierBasicFields,
  FeeBasicFields,
  TierFeePercentageShallowFields,
} from './generated';

const TierFeePercentageFields: DocumentNode = gql`
  fragment TierFeePercentageFields on TierFeePercentageType {
    ...TierFeePercentageShallowFields
    loanTrancheTier {
      ...LoanTrancheTierBasicFields
    }
    fee {
      ...FeeBasicFields
    }
  }
  ${LoanTrancheTierBasicFields}
  ${FeeBasicFields}
  ${TierFeePercentageShallowFields}
`;

export default TierFeePercentageFields;
