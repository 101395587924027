import { gql } from '@apollo/client';
import standardQueryBuilder from './queryBuilders/standardQueryBuilder';
import { VersionType } from 'types';

const VersionsQueryDefinition = gql`
  query AuditQuery($dealId: ID) {
    versions(dealId: $dealId) {
      id
      serializedData
    }
  }
`;

const AuditTrailQuery = standardQueryBuilder(VersionsQueryDefinition, {
  typename: 'AuditTrail',
  disableCache: true,
  skip: ({ dealId }: { dealId: string | null | undefined }) => !dealId,
  variables: ({ dealId }: { dealId: string | null | undefined }) => ({
    dealId,
  }),
  results: (data: { versions: VersionType | null | undefined }) => {
    const { versions } = data;
    return { versions };
  },
});

export default AuditTrailQuery;
