/* eslint-disable import/prefer-default-export */

export function formatTitleCase(
  input: string | null | undefined,
  splitOn?: string,
  joinOn?: string,
) {
  if (input) {
    return input
      .split(splitOn || ' ')
      .map(
        word =>
          word.charAt(0).toUpperCase() +
          word.substring(1, word.length).toLowerCase(),
      )
      .join(joinOn || ' ');
  }
  return '';
}

/* eslint-enable */
