import * as React from 'react';
import { compose } from 'recompose';
import { withLoadingIndicator, DisplayTable, Money, Date } from 'components';
import { LoanEventType, SortBy } from 'types';
import { PaymentActivityQuery } from 'lsgql';
import { lsSort } from 'utils';

type Props = {
  paymentActivity: Array<LoanEventType>;
};

type State = {
  sortBy: SortBy;
};

const columns = [
  { name: 'date' },
  { name: 'type' },
  { name: 'amount' },
  { name: 'balance' },
];

class PaymentSchedule extends React.Component<Props, State> {
  state = {
    sortBy: {
      column: 'date',
      reverse: false,
    },
  };

  handleChangeSortField = (sortBy: SortBy) => {
    this.setState({ sortBy });
  };

  renderTableRow = (paymentActivity: LoanEventType) => {
    const loanEventKey = `${paymentActivity.date || ''}${paymentActivity.type ||
      ''}${paymentActivity.amount || ''}${paymentActivity.balance || ''}`;

    return (
      <DisplayTable.TR key={loanEventKey}>
        <DisplayTable.TD>
          <Date format="YYYY/MM/DD" value={paymentActivity.date} />
        </DisplayTable.TD>
        <DisplayTable.TD>{paymentActivity.type}</DisplayTable.TD>
        <DisplayTable.TD>
          <Money value={paymentActivity.amount} />
        </DisplayTable.TD>
        <DisplayTable.TD>
          {paymentActivity.balance ? (
            <Money value={paymentActivity.balance} />
          ) : (
            '-'
          )}
        </DisplayTable.TD>
      </DisplayTable.TR>
    );
  };

  render() {
    const { paymentActivity } = this.props;
    const { sortBy } = this.state;

    const sortedItems = lsSort(paymentActivity, sortBy);
    return (
      <DisplayTable
        columns={columns}
        hoverable
        onChangeSortField={this.handleChangeSortField}
        sortBy={sortBy}
      >
        {sortedItems.map(activity => this.renderTableRow(activity))}
      </DisplayTable>
    );
  }
}

export default compose(
  PaymentActivityQuery,
  withLoadingIndicator('loading'),
)(PaymentSchedule);
