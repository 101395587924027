import { fromJS } from 'immutable';
import { SetValidationErrorsAction } from '../types';

/* eslint-disable import/prefer-default-export */

/**
 * Set validation errors for a form
 * @param {*} state
 * @param {*} action
 */
export function handleSetValidationErrors(
  state: any,
  action: SetValidationErrorsAction,
): Record<string, any> {
  const { errors } = action.payload;
  return state.setIn(['errors'], fromJS(errors));
}
