import formQueryBuilder from './queryBuilders/formQueryBuilder';
import { CancelLoanType } from 'types';
import { CancelLoanQueryDefinition } from 'lsgql/queries/definitions';

export const CancelLoanFormQuery = formQueryBuilder(CancelLoanQueryDefinition, {
  typename: 'CancelLoanType' as any,
  skip: (props: { dealId: string | null | undefined }) => {
    const { dealId } = props;
    return !dealId;
  },
  variables: (props: { dealId: string | null | undefined }) => {
    const { dealId } = props;
    return { id: dealId };
  },
  results: (data: { cancelLoan: CancelLoanType | null | undefined }) => {
    const { cancelLoan } = data;
    return cancelLoan;
  },
});
