import * as React from 'react';
import { Form, IconButton } from 'components';
import { PrepaymentPenaltyRangeType } from 'types';
import { hoverMethods } from 'utils';

type Props = {
  onDelete: (range: PrepaymentPenaltyRangeType) => void;
  onMonthChange: (
    range: PrepaymentPenaltyRangeType,
    value: string | null | undefined,
  ) => void;
  onPenaltyChange: (
    range: PrepaymentPenaltyRangeType,
    value: string | null | undefined,
  ) => void;
  prepaymentPenaltyRange: PrepaymentPenaltyRangeType;
  withLabels: boolean;
};
type State = {
  isHovered: boolean;
};

class FeePrepaymentItem extends React.Component<Props, State> {
  handleHoverLeave: () => void;

  handleMouseEnter: () => void;

  handleMouseLeave: () => void;

  handleMouseMove: (e: React.MouseEvent<any>) => void;

  constructor(props: Props) {
    super(props);
    this.handleHoverLeave = hoverMethods.handleHoverLeave.bind(this);
    this.handleMouseEnter = hoverMethods.handleMouseEnter.bind(this);
    this.handleMouseLeave = hoverMethods.handleMouseLeave.bind(this);
    this.handleMouseMove = hoverMethods.handleMouseMove.bind(this);
  }

  state = {
    isHovered: false,
  };

  handleMonthChange = (value: string | null | undefined) => {
    const { prepaymentPenaltyRange, onMonthChange } = this.props;
    onMonthChange(prepaymentPenaltyRange, value);
  };

  handlePenaltyChange = (value: string | null | undefined) => {
    const { prepaymentPenaltyRange, onPenaltyChange } = this.props;
    onPenaltyChange(prepaymentPenaltyRange, value);
  };

  handleDelete = () => {
    const { prepaymentPenaltyRange, onDelete } = this.props;
    onDelete(prepaymentPenaltyRange);
  };

  render() {
    const { withLabels, prepaymentPenaltyRange } = this.props;
    const { isHovered } = this.state;
    return (
      <div
        className="feePrepaymentItem"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Form.Group className="feePrepaymentItem__Fields" unstackable>
          <Form.Percentage
            fieldName={`${withLabels ? 'Percentage' : ''}`}
            id={`prepaymentPenalty__${prepaymentPenaltyRange.id}`}
            onChange={this.handlePenaltyChange}
            propertyName="penalty"
            value={prepaymentPenaltyRange.penalty}
            width="eight"
          />
          <Form.Numeric
            fieldName={`${withLabels ? 'Months' : ''}`}
            id={`prepaymentMonth__${prepaymentPenaltyRange.id}`}
            onChange={this.handleMonthChange}
            propertyName="month"
            value={prepaymentPenaltyRange.month}
            width="eight"
          />
        </Form.Group>
        <div
          className={`feePrepaymentItem__DeleteIcon ${
            withLabels ? 'feePrepaymentItem__DeleteIcon--WithLabel' : ''
          }`}
        >
          {isHovered && (
            <IconButton.Delete
              alt="Remove Penalty Range Icon"
              onClick={this.handleDelete}
            />
          )}
        </div>
      </div>
    );
  }
}

export default FeePrepaymentItem;
