import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

/**
 * Converts any stringe into PascalCase
 * @param string A given string
 */
function pascalCase(string: string): string {
  return upperFirst(camelCase(string));
}

export default pascalCase;
