import React from 'react';
import { DealsTableColumnShape } from '../types';
import { DealType } from 'types';
import { RouteTable } from 'routing';
import { RouteTo, DataTable } from 'components';
import { accessProperty } from 'utils';

export interface DealRowProps {
  index: number;
  style: object;
  deals: DealType[];
  columns: DealsTableColumnShape[];
}

const DealRow = (props: DealRowProps) => {
  const { index, style, deals, columns } = props;
  const deal = deals[index];

  // TODO: SBA-HACK  If the deal has closed, send the user to Loan Overview instead of Closing Checklist
  const toRoute =
    deal?.status == 'FUNDED'
      ? RouteTable.deal.toLoanOverviewSummary(deal.id)
      : RouteTable.deal.toDealClosingWorkflow(deal.id);

  return (
    <DataTable.Row key={deal.id} route={RouteTo} style={style} to={toRoute}>
      {columns.map(column => {
        const { CellType, props, id, renderChild } = column;
        const cellId = `${deal.id}_${id}`;
        if (renderChild) {
          return renderChild({
            deal,
            cellId,
            value: accessProperty(id, deal),
          });
        }
        return (
          <CellType key={id} {...props}>
            {accessProperty(id, deal)}
          </CellType>
        );
      })}
    </DataTable.Row>
  );
};

export default DealRow;
