import uuid from 'uuid/v4';
import {
  DealMutationMethods,
  DealClosingWorkflowMutationMethods,
  DealClosingWorkflowStepTypeMutationMethods,
  DealClosingWorkflowFetchMethod,
  LoanFundingMutationMethods,
} from '../../../graphql';
import Directory from '../../directory';

import { createForm } from '../../genericForms';
import { DealType } from 'types';

const initialDeal: DealType = {
  id: uuid(),
  name: '',
  category: 'STANDARD',
  creditratingSet: [],
  __typename: 'DealType',
};

const lifecycleMethods = {
  mutations: {
    ...DealMutationMethods,
    ...DealClosingWorkflowMutationMethods,
    ...DealClosingWorkflowStepTypeMutationMethods,
    ...LoanFundingMutationMethods,
  },
  validators: {},
  fetch: DealClosingWorkflowFetchMethod,
};

function dealClosingWorkflowDataConverter(data: DealType): DealType {
  /*
   * If the dealClosingWorkflowSteps exist, apply the isLeaf: true property
   * to them so that the mutationDispatch will not build mutations
   * for the bodyLink string array
   */

  if (!(data.dealClosingWorkflow && data.dealClosingWorkflow.steps)) {
    return data;
  }

  const leafedSteps = data.dealClosingWorkflow.steps.map(e => {
    return {
      ...e,
      isLeaf: true,
    };
  });

  return {
    ...data,
    dealClosingWorkflow: {
      ...data.dealClosingWorkflow,
      steps: leafedSteps,
    },
  };
}

const dealClosingWorkflow = createForm<DealType, DealType>(
  Directory.DealClosingWorkflowKey,
  initialDeal,
  lifecycleMethods,
  {
    enableAutoSave: true,
    dataConverter: dealClosingWorkflowDataConverter,
  },
);

export default dealClosingWorkflow;
