import uuid from 'uuid/v4';
import { EventScheduleMutationMethods } from '../../../graphql';
import { createForm } from '../../genericForms';
import Directory from '../../directory';
import { EventScheduleType } from 'types';

const initialTaskForm: EventScheduleType = {
  id: uuid(),
  __typename: 'EventScheduleType',
  isLeaf: true,
} as any;

const lifecycleMethods = {
  mutations: { ...EventScheduleMutationMethods },
  validators: {},
};

const taskFormRedux = createForm<EventScheduleType, EventScheduleType>(
  Directory.TaskKey,
  initialTaskForm,
  lifecycleMethods,
  {
    enableAutoSave: false,
  },
);

export default taskFormRedux;
