/* eslint-disable import/prefer-default-export */

import formQueryBuilder from './queryBuilders/formQueryBuilder';
import { EventScheduleQueryDefinition } from './definitions';
import { EventScheduleType } from 'types';

export const EventScheduleFormQuery = formQueryBuilder(
  EventScheduleQueryDefinition,
  {
    typename: 'EventSchedule',
    skip: (props: { eventScheduleId: string | null | undefined }) => {
      const { eventScheduleId } = props;
      return !eventScheduleId;
    },
    variables: (props: { eventScheduleId: string | null | undefined }) => {
      const { eventScheduleId } = props;
      return { id: eventScheduleId };
    },
    results: (data: {
      eventSchedule: EventScheduleType | null | undefined;
    }) => {
      const { eventSchedule } = data;
      return eventSchedule;
    },
  },
);
