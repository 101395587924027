import { MutationMethod } from '../types';
import { mutationBuilder } from './builders';
import { MutationExecutor } from './builders/mutationBuilder';
import {
  CreateGeneralNoticeMutationDefinition,
  SetGeneralNoticeMutationDefinition,
} from './definitions';
import { GeneralNoticeInput, GeneralNoticeType } from 'types';

const createGeneralNotice: MutationMethod<
  GeneralNoticeInput,
  GeneralNoticeType
> = mutationBuilder<GeneralNoticeInput, GeneralNoticeType>(
  CreateGeneralNoticeMutationDefinition,
  {
    build: (mutate: MutationExecutor<GeneralNoticeType>) => (
      generalNoticeData: GeneralNoticeInput,
    ) =>
      mutate({
        variables: {
          generalNoticeData,
        },
      }),
  },
);

const setGeneralNotice: MutationMethod<
  GeneralNoticeInput,
  GeneralNoticeType
> = mutationBuilder<GeneralNoticeInput, GeneralNoticeType>(
  SetGeneralNoticeMutationDefinition,
  {
    build: (mutate: MutationExecutor<GeneralNoticeType>) => generalNoticeData =>
      mutate({
        variables: {
          generalNoticeData,
        },
      }),
  },
);

const GeneralNoticeMutationMethods = {
  createGeneralNotice,
  setGeneralNotice,
};

export default GeneralNoticeMutationMethods;
