import { PermissionKey } from '../types';

import { Global } from '../constants';
import { getPermissionKey, getEntityPermissionKey } from './getEntityKeyMap';
import { PermissionsBaseType } from 'types';

export default function getKey<T extends PermissionsBaseType>(
  context?: T,
): PermissionKey {
  if (!context || context.__typename === 'UserType') {
    return getPermissionKey(Global);
  }
  return getEntityPermissionKey(context);
}
