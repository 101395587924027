import {
  ValidationType,
  ObjectBaseTypeNames,
  ID,
  StringPair,
  ValidationMap,
} from 'types';

const errors = 'errors';
const warnings = 'warnings';

/* eslint-disable no-param-reassign */
function addEntry(
  target: ValidationMap,
  typeName: ObjectBaseTypeNames,
  id: ID,
  pair: StringPair,
  validationType: 'errors' | 'warnings',
) {
  if (!pair.name || !pair.value) return;

  if (!target[typeName]) {
    target[typeName] = {};
  }
  if (!target[typeName][id]) {
    target[typeName][id] = { errors: {}, warnings: {} };
  }

  target[typeName][id][validationType] = {
    ...target[typeName][id][validationType],
    ...{
      [pair.name]: pair.value,
    },
  };
}

export default function validationMapBuilder(
  typeName: ObjectBaseTypeNames,
  entityId: ID,
  response: ValidationType,
  map: ValidationMap = {},
): ValidationMap {
  if (response && response.errors && response.errorFields)
    response.errorFields.forEach(e =>
      addEntry(map, typeName, entityId, e, errors),
    );

  if (response && response.warnings && response.warningFields)
    response.warningFields.forEach(e =>
      addEntry(map, typeName, entityId, e, warnings),
    );

  return map;
}
