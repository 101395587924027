// eslint-disable-next-line
import * as React from 'react';

/* eslint-disable */
import { DropTarget, DragSource } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import HTML5Backend from 'react-dnd-html5-backend';

/* eslint-enable */
import { DropTypeValues, FileWithDate } from '../../types';
import { createDragSourceEntity } from '../../utils/dragDropMethods';

import { DataRoomRowProps } from '../../dataroomRow/DataroomRow';
import { invariant } from 'utils';

// Decorators break lint, use as function
const FileDragSource = DragSource(
  DropTypeValues.FileType,
  {
    beginDrag(props: DataRoomRowProps<FileWithDate>) {
      invariant(
        props.entity,
        'FileDragSource beginDrag received an undefined entity',
      );
      // This component is now being dragged, create the DragSourceEntity
      // object that will be passed to DropTargets
      return createDragSourceEntity(props.entity);
    },
    endDrag(props) {
      (props as any).leaveDragHover();
    },
    canDrag(props: DataRoomRowProps<FileWithDate>) {
      return props.accessDenied !== true;
    },
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }),
);

export default FileDragSource;
