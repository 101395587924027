import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import accessProperty from './accessProperty';
import { SortBy } from 'types';

// Treat these properties as numbers in DataTable sorting
const NUMBER_PROPS = [
  'totalCommitmentAmount',
  'sbaPppForgivenessWorkflow.sbapppforgivenesscalculation.forgivenessAmountCalculated',
  'amount',
];

/**
 * Universal sort method for use with tables. Sorts entities according to the sortBy object, and decides whether the column is iniitally ascending or descending based on the prop type; non-numeric strings are ascending, bools and numbers are descending.
 * @param {*} array: the entities to be sorted
 * @param {*} sortBy: the sortBy object used by tables to describe which column to sort around and whether the column is being reverse-sorted
 * @param {*} propMap: object describing the relation between the column header string and the associated prop on the data object in the array.
 */
const lsSort = (
  array: Array<any> = [],
  sortBy: SortBy,
  propMap: {
    [key: string]: any;
  } = {},
) => {
  if (sortBy.reverse === 'none') return array;

  const mappedSortBy =
    sortBy.column in propMap ? propMap[sortBy.column] : sortBy.column;

  const order: 'asc' | 'desc' = sortBy.reverse ? 'desc' : 'asc';

  const sortedArray: Array<any> = _orderBy<any>(
    array,
    item => {
      const rawValue = accessProperty(mappedSortBy, item);

      let adjustedValue = rawValue;
      if (NUMBER_PROPS.includes(mappedSortBy)) {
        adjustedValue = parseFloat(rawValue);
      } else if (mappedSortBy === 'sbaPppForgivenessWorkflow.modified') {
        // Serious hack for SBA PPP Forgiveness
        const dateStr = moment(String(rawValue)).format('MM/DD/YY');
        const statusStr = String(
          accessProperty('status', item) || '',
        ).toUpperCase();
        adjustedValue = `${dateStr} - ${statusStr}`;
      } else if (typeof rawValue == 'string') {
        adjustedValue = rawValue?.toUpperCase() || '';
      }

      return adjustedValue;
    },
    order,
  );

  return sortedArray;
};

export default lsSort;
