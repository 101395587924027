import { PayloadAction } from '../types';
import { AlertType } from 'types';

export const SET_ALERT = 'SET_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';

export const alertsActions = {
  SET_ALERT,
  CLEAR_ALERT,
};

export function setAlert(
  message: string,
  type: AlertType,
  title?: string,
  linkText?: string,
  linkTo?: string,
): PayloadAction<{
  linkText?: string;
  linkTo?: string;
  message: string;
  title?: string;
  type: AlertType;
}> {
  return {
    type: SET_ALERT,
    payload: {
      linkText,
      linkTo,
      message,
      title,
      type,
    },
  };
}

export function clearAlert(id: string): PayloadAction<{ id: string }> {
  return {
    type: CLEAR_ALERT,
    payload: {
      id,
    },
  };
}
