import React from 'react';
import { compose } from 'recompose';
import { useLocation } from 'react-router-dom';
import { Header, Text, Tooltip } from 'components';
import { RouteParams, RoutePortal, withRouteParams } from 'routing';
import { RouteTable } from 'routing/RouteTable';
import DealActionButtonRoutes from 'routing/routes/DealActionButtonRoutes';
import { DealQuery } from 'lsgql';
import { DealType } from 'types';
import { MountGuard, DealPerspectivePermissions } from 'security';
import { getDealCategory } from 'utils';
import ActionMenu from './DealHeaderActions/ActionMenu';
import SBASecondDrawButton from './SBASecondDrawButton';
import SBAFirstDrawButton from './SBAFirstDrawButton';

type Props = {
  deal: DealType;
};

enum actionType {
  stage = 'stage',
  assignee = 'assignee',
}

function DealHeader(props: Props) {
  const { deal } = props;
  const location = useLocation();

  if (!deal) {
    return null;
  }

  const toRoute =
    deal.id &&
    RouteTable.deal.toLoanOverviewSummary(deal.id) === location.pathname
      ? undefined
      : RouteTable.deal.toLoanOverviewSummary(deal.id);

  const renderActionButtons = () => {
    return (
      <div className="ActionButtons">
        <RoutePortal context={DealActionButtonRoutes} />
        <ActionMenu deal={deal} />
      </div>
    );
  };

  const showFirstDrawButton = deal.isRoundTwoFirstDrawEligible;
  const showSecondDrawButton = deal.isRoundTwoSecondDrawEligible;

  // SBA HACK
  if (location.pathname.endsWith('first-draw')) {
    return (
      <div className="TopbarNavigation TopbarNaviation--Deal">
        <div className="TopbarNavigation__Header" id="DealHeader">
          <div className="DealInfo">
            <Header as="h3">New Application</Header>
          </div>
        </div>
      </div>
    );
  }
  if (location.pathname.endsWith('second-draw')) {
    return (
      <div className="TopbarNavigation TopbarNaviation--Deal">
        <div className="TopbarNavigation__Header" id="DealHeader">
          <div className="DealInfo">
            <Header as="h3">Apply for PPP Second Draw Loan</Header>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="TopbarNavigation TopbarNaviation--Deal">
      <div className="TopbarNavigation__Header" id="DealHeader">
        <div className="DealInfo">
          <Header as="h3" to={toRoute}>
            {deal.sbappploanapplication && 'SBA PPP - '}
            {deal.borrowerName}
          </Header>
          <Text.Label
            className="PppDrawTag"
            type={actionType.stage}
            value={getDealCategory(deal.category)}
          />
          <Text.Label type={actionType.stage} value={deal.status} />
          <MountGuard
            permission={DealPerspectivePermissions.view_deal_dealassignee}
          >
            <Text.Label
              type={actionType.assignee}
              value={deal.dealassignee?.name}
            />
          </MountGuard>
        </div>
      </div>
      <div className="TopbarNavigation__Footer">
        {(showFirstDrawButton || showSecondDrawButton) && (
          <Tooltip parentClass="TopbarNavigation" position="left">
            <Text>First Draw Loan is for first time program participants.</Text>
            <Text>
              Second Draw Loan is for certain businesses who have previously
              received a PPP loan.
            </Text>
            <Text>
              If you are uncertain which application to complete, ask your
              lender.
            </Text>
          </Tooltip>
        )}
        <SBAFirstDrawButton deal={deal} />
        <SBASecondDrawButton deal={deal} />
        {renderActionButtons()}
      </div>
    </div>
  );
}

export default compose(
  withRouteParams([RouteParams.dealId]),
  DealQuery,
)(DealHeader);
