import isProduction from './isProduction';
import invariant from './invariant';

type DownloadableType = Readonly<{
  downloadLink?: string | null;
  relativeDownloadLink?: string | null;
}>;

export default function getDownloadLink(doc: DownloadableType) {
  invariant(doc, 'Document was not found!');
  // use relative paths if we are in production
  if (isProduction()) {
    return doc.relativeDownloadLink || '';
  }

  return doc.downloadLink || '';
}
