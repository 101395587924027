import { compose } from 'recompose';
import { connect } from 'react-redux';
import EzFormQualificationsModal from './EzFormQualificationsModal';
import { SbaPppForgivenessWorkflowFormQuery } from 'lsgql';
import { withRouteParams, RouteParams } from 'routing';
import { FormMediator } from 'components';
import sbaPppForgivenessEzFormQualificationsFormRedux from 'lsredux/reducer/forms/sbaPppForgivenessEzFormQualifications';

export default compose(
  withRouteParams([RouteParams.dealId]),
  SbaPppForgivenessWorkflowFormQuery,
  connect(
    sbaPppForgivenessEzFormQualificationsFormRedux.mapStateToProps,
    sbaPppForgivenessEzFormQualificationsFormRedux.actions.generateActions,
  ),
  FormMediator({
    formId: 'sbaPppForgivenessEzFormQualificationsForm',
    baseObjectTypeName: 'Deal',
    disableFrame: true,
    disableAlerts: true,
  }),
)(EzFormQualificationsModal);
