import React from 'react';
import TitleHeader from './TitleHeader';
import { Button } from 'components';
import { RouteTable } from 'routing/RouteTable';
import { useGlobalPermissionPerspective } from 'security';
import { ADMINISTER_INSTITUTION } from 'security/constants';

function canEditInstitutionProfile(user) {
  return (
    user.institution &&
    user.institution.permissions.includes(ADMINISTER_INSTITUTION)
  );
}

function getUserInstitutionId(user) {
  return user.institution && user.institution.id;
}

function getInstitutionProfileRoute(user) {
  const userInstitutionId = getUserInstitutionId(user);
  return user.institution && RouteTable.institution.toEdit(userInstitutionId);
}

export default function ProfileHeader() {
  const { user } = useGlobalPermissionPerspective();
  const institutionProfileRoute = getInstitutionProfileRoute(user);
  const canEditInstitution = canEditInstitutionProfile(user);
  return (
    <TitleHeader name="Profile" withBreadcrumbs>
      <div className="ActionButtons">
        <Button
          className="ActionButtons__Item"
          label="Edit Profile"
          to={RouteTable.user.toUserProfile}
        />
        {canEditInstitution && (
          <Button
            className="ActionButtons__Item"
            label="Edit Institution"
            to={institutionProfileRoute}
          />
        )}
      </div>
    </TitleHeader>
  );
}
