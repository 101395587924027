import { noop } from 'lodash';
import React from 'react';
import cx from 'classnames';
import {
  calculateUnallocatedAmount,
  calculateUnallocatedPortion,
} from '../utils';
import { LoanTrancheOwnershipWithIdType } from 'types';
import { Form, Money } from 'components';
import { ux } from 'utils';

type Props = {
  ownership: LoanTrancheOwnershipWithIdType;
  totalAmount: string;
};
function LenderRegistryTrancheFooter(props: Props) {
  const { ownership, totalAmount } = props;

  const unAllocatedPortion = calculateUnallocatedPortion(
    ownership.totalPortion || '0',
  );

  const unAllocatedAmount = calculateUnallocatedAmount(
    totalAmount,
    ownership.totalAmount || '0',
  );

  const isOverAllocated = unAllocatedAmount.startsWith('-');

  const unAllocatedPortionClassName = cx(
    'lenderRegistryTranche__ColumnItem',
    'lenderRegistryTrancheFooter__PercentColumn',
    ux(
      isOverAllocated,
      'lenderRegistryTrancheFooter__PercentColumn--OverAllocated',
    ),
    ux(!isOverAllocated, 'lenderRegistryTrancheFooter__PercentColumn--Normal'),
  );

  return (
    <div className="lenderRegistryTrancheFooter">
      <div className="lenderRegistryTranche__ColumnItem--LeftAlignText lenderRegistryTrancheFooter__NameColumn">
        Unallocated
      </div>
      <Form.Percentage
        className={unAllocatedPortionClassName}
        customIntegerLimit={10}
        disabled
        id="unallocatedPortionTranche"
        onChange={noop}
        propertyName="portion"
        value={unAllocatedPortion}
      />
      <Money
        className={`lenderRegistryTranche__MoneyDisplay ${
          isOverAllocated ? 'lenderRegistryTrancheFooter--OverAllocated' : ''
        }`}
        value={unAllocatedAmount}
      />
      <div className="lenderRegistryTranche__MoneyDisplay">-</div>
      <div className="lenderRegistryTranche__MoneyDisplay">-</div>
    </div>
  );
}

export default LenderRegistryTrancheFooter;
