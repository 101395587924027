import React from 'react';
import noop from 'lodash/noop';
import moment from 'moment';
import AssociatedFiles from '../associatedFiles/AssociatedFiles';
import { Form } from 'components';
import {
  ID,
  DealType,
  GeneralNoticeType,
  SelectOptionType,
  LoanTrancheType,
  FormFieldsType,
} from 'types';
import { invariant, sortDropdownOptions } from 'utils';

type GeneralNoticeTypeWithLoanTrancheArray = GeneralNoticeType & {
  loanTranches: Array<ID>;
};

type Props = {
  deal: DealType;
  generalNotice: GeneralNoticeTypeWithLoanTrancheArray;
};

const width = 'sixteen';

const fields: FormFieldsType = {
  loanTranches: {
    id: 'loanTranches',
    fieldName: 'Tranche',
    propertyName: 'loanTranches',
    onChange: noop,
    width,
    disabled: true,
    multiple: true,
  },
  noticeType: {
    id: 'noticeType',
    fieldName: 'Notice Type',
    propertyName: 'noticeType',
    onChange: noop,
    width,
    disabled: true,
  },
  agreementSectionReference: {
    id: 'agreementSectionReference',
    fieldName: 'Agreement Section Reference',
    propertyName: 'agreementSectionReference',
    onChange: noop,
    width,
    disabled: true,
  },
  notes: {
    id: 'notes',
    propertyName: 'notes',
    onChange: noop,
    width,
    disabled: true,
  },
  submittedByUserName: {
    id: 'submittedByUserName',
    fieldName: 'Submitted By',
    propertyName: 'submittedByUserName',
    onChange: noop,
    width,
    disabled: true,
  },
  submittedTime: {
    id: 'submittedTimestamp',
    fieldName: 'Submitted Time',
    propertyName: 'submittedTimestamp',
    onChange: noop,
    width,
    disabled: true,
  },
};

function getLoanTranches(props: Props): ReadonlyArray<LoanTrancheType> {
  const loanTrancheSet = props.deal && props.deal.loantrancheSet;
  invariant(loanTrancheSet && loanTrancheSet.length, 'No loan tranches found');
  return loanTrancheSet;
}

function getLoanTrancheById(
  id: string,
  props: Props,
): LoanTrancheType | { id: string; name: string } {
  const loanTranches = getLoanTranches(props);
  return (
    loanTranches.find(tranche => tranche.id === id) || { name: '', id: '' }
  );
}

function getLoanTrancheOptions(
  loanTranches: Array<ID> = [],
  props: Props,
): Array<SelectOptionType> {
  const loanTrancheOptions: Array<SelectOptionType> = loanTranches.map(
    loanTrancheId => {
      const loanTranche = getLoanTrancheById(loanTrancheId, props);

      return {
        text: loanTranche.name || '',
        value: loanTranche.id,
      };
    },
  );

  return sortDropdownOptions(loanTrancheOptions);
}

function GeneralNoticeSummary(props: Props) {
  const { generalNotice } = props;

  const {
    agreementSectionReference,
    noticeType,
    notes,
    loanTranches,
    submittedByUserName,
    submittedTimestamp,
  } = generalNotice;

  const loanTrancheOptions = getLoanTrancheOptions(loanTranches, props);
  const loanTrancheValues = loanTrancheOptions.map(
    loanTranche => loanTranche.value,
  );

  return (
    <div className="GeneralNoticeSummary__Container ui form">
      <Form.Header as="h1" header="General Notice" />
      <Form.Header as="h3" header="Summary" />
      <Form.Group>
        {loanTrancheOptions.length > 0 && (
          <Form.Select
            {...fields.loanTranches}
            options={loanTrancheOptions}
            value={loanTrancheValues}
          />
        )}
      </Form.Group>
      <Form.Group>
        {noticeType && <Form.Text {...fields.noticeType} value={noticeType} />}
      </Form.Group>
      <Form.Group>
        {agreementSectionReference && (
          <Form.Text
            {...fields.agreementSectionReference}
            value={agreementSectionReference}
          />
        )}
      </Form.Group>
      <Form.Group>
        {submittedByUserName && (
          <Form.Text
            {...fields.submittedByUserName}
            value={submittedByUserName}
          />
        )}
      </Form.Group>
      <Form.Group>
        {submittedTimestamp && (
          <Form.Text
            {...fields.submittedTime}
            value={moment(submittedTimestamp)
              .local()
              .format('YYYY/MM/DD, hh:mm A')}
          />
        )}
      </Form.Group>
      <AssociatedFiles data={generalNotice} viewOnly />
      {notes && (
        <>
          <Form.Header as="h3" header="Notes" />
          <Form.Group>
            <Form.TextArea {...fields.notes} value={notes} />
          </Form.Group>
        </>
      )}
    </div>
  );
}

export default GeneralNoticeSummary;
