import * as React from 'react';
import PropTypes from 'prop-types';
import { GraphQLError } from 'graphql';
import { NetworkStatus } from '@apollo/client';
import { ConnectDropTarget } from 'react-dnd';
import {
  FolderType,
  DocumentType,
  DealType,
  OpenPDFProps,
  PermissionsBaseType,
} from 'types';

/**
 * We need to display the 'modified' property on all rows,
 * but the format is not user friendly.  Thus we pre-format
 * the 'modified' property and store it into this property.
 * This is the value actually displayed.
 */
interface RowDisplayInfo {
  dateString: string | null | undefined;
  isNew?: boolean;
  percent?: number;
}

// Adds `dateString` to FolderType
export type FolderWithDate = FolderType & RowDisplayInfo;

// Adds `dateString` and `percent` to DocumentTypeType
export type FileWithDate = DocumentType & RowDisplayInfo;

// a Row object can be a Folder or File
// export type UnionType = FolderWithDate | FileWithDate;

export type DataRoomBase = FolderWithDate | FileWithDate;

/**
 * The object type created by a drag source (a FolderRow or FileRow component),
 * this object is received by the `drop` and `canDrop` handlers of a DropTarget
 */
export type DragSourceEntity = {
  entity: DataRoomBase | null | undefined;
  files: Array<any> | null | undefined;
};

export type ApolloQueryResult<T> = {
  data: T;
  errors?: Array<GraphQLError>;
  loading: boolean;
  networkStatus: NetworkStatus;
  stale: boolean;
};

/**
 * Sorting instructions
 */
export type SortBy = {
  column: string;
  reverse: true | false | 'none';
};

/**
 * A Map of open/closed folder ids
 */
export type OpenMap = {
  [key: string]: boolean;
};

/* eslint-disable import/prefer-default-export */

// The types of objects that can be handled by Drag + Drop
export const DropTypeValues = {
  // a FileRow Component
  FileType: 'FileType',
  // a FolderRow Component
  FolderType: 'FolderType',
  // a File dropped into the browser window
  __NATIVE_FILE__: '__NATIVE_FILE__',
};

export type DropTypeValues = 'FileType' | 'FolderType' | '__NATIVE_FILE__';

// The set of mutations used across the Dataroom component tree
export type MutationMethodsProps = {
  changeSortField: (toApply: SortBy) => void;
  closeFolder: (folderId: string) => void;
  createDocuments: (
    files: Array<any>,
    parentId: string,
  ) => Promise<ApolloQueryResult<any>>;
  dataroomFiles: DataRoomBase[];
  deleteDocument: (id: string) => Promise<ApolloQueryResult<any>>;
  deleteFolder: (folderId: string) => Promise<ApolloQueryResult<any>>;
  openFolder: (folderId: string) => void;
  openFolders: OpenMap;
  setAlert: (alert: React.ReactNode, type?: string) => void;
  setDocument: (documentData: DocumentType) => Promise<ApolloQueryResult<any>>;
  setFolder: (folder: FolderType) => Promise<ApolloQueryResult<any>>;
};

export type DropType = keyof DropTypeValues;

/**
 * The object type created by a DropTarget when it is the direct
 * target of a drop event.  The DropTarget then returns this object from the `drop`
 * method, which will allow the object to propagate up the tree to the root Dataroom component
 * All mutations are handled within the Dataroom
 */
export type DropTargetEntity = {
  dropTargetId: string;
  dropType: DropType;
  entity: DragSourceEntity;
};

/**
 * A FolderMap contains complete information for rendering a FolderRow component
 * children: all direct child objects of the Folder, sorted and `dateString` precomputed
 * fileCount: The recursive count of all files that are descendants of this folder
 * folderCount: The count of direct folder children
 */
export type FolderMap<T extends DataRoomBase> = {
  /**
   * all direct child objects of the Folder, sorted and `dateString` precomputed
   */
  children: T[];

  /**
   * Recursive child file count, includes all descendants
   */
  completedUploadCount: number;

  /**
   * Direct child folder count
   */
  fileCount: number;

  /**
   * Count of child files currently uploading
   */

  folderCount: number;

  /**
   * Count of child files that completed uploading
   */
  uploadingCount: number;
};

/**
 * A RoomMap contains the complete tree information necessary for building
 * the Dataroom.  Properties are folderIds, each contains a FolderMap object
 */
export type RoomMap<T extends DataRoomBase> = {
  [folderId: string]: FolderMap<T>;
};

/**
 * The set of shared props for FileRow & FolderRow components
 */
export type RowProps = {
  level: number;
  openMap: OpenMap;
};

export type DataroomRowData = {
  isOpen: boolean;
  item: DataRoomBase;
  level: number;
  permissionsObject: PermissionsBaseType;
};

export type TransformedDataroom = {
  dataList: Array<DataroomRowData>;
  levelMap: {
    [key: string]: number;
  };
  roomMap: RoomMap<DataRoomBase>;
};

/**
 * These objects are placed in Context by the Dataroom component
 * and made available as context to all child components
 */
export type DataroomContext = {
  closeFolder: (folderId: string) => void;
  data: TransformedDataroom;
  dealId: string;
  deleteFileModal: (folder: FileWithDate) => void;
  deleteFolder: (folderId: string) => Promise<ApolloQueryResult<any>>;
  deleteFolderModal: (folder: FolderWithDate) => void;
  handleUploadFiles: (folderId: string, newFiles: Array<File>) => void;
  openFolder: (folderId: string) => void;
  openFolderModal: (parentId: string, child?: FolderWithDate) => void;
  openFolderShareModal: (folder: FolderWithDate) => void;
  openPdfViewer: (pdf: FileWithDate) => void;
};

export const ContextPropTypes: any = {
  data: PropTypes.object,
  closeFolder: PropTypes.func,
  deleteFolder: PropTypes.func,
  openFolder: PropTypes.func,
  openFolderModal: PropTypes.func,
  openFolderShareModal: PropTypes.func,
  deleteFolderModal: PropTypes.func,
  deleteFileModal: PropTypes.func,
  dealId: PropTypes.string,
  onVisible: PropTypes.func,
  onHidden: PropTypes.func,
  handleUploadFiles: PropTypes.func,
  openPdfViewer: PropTypes.func,
};

/* eslint-disable react/no-unused-prop-types */
export type DataroomProps = MutationMethodsProps & {
  canDrop: boolean;
  closeFile: () => void;
  connectDropTarget: ConnectDropTarget;
  createDocuments: (
    files: Array<File>,
    parentId: string,
  ) => Promise<ApolloQueryResult<any>>;
  dataroomFiles: Array<DataRoomBase>;
  deal: DealType;
  dealId: string;
  fetchDataroomFiles: () => void;
  isDrawerOpen: boolean;
  isOver: boolean;
  leaveDragHover: () => void;
  newFileSet: Set<string>;
  openDrawer: () => void;
  openFile: (data: OpenPDFProps) => void;
  percentMap: Map<string, number>;
  sortBy: SortBy;
};

/* eslint-enable */

export type FixedSizeListProps = {
  data: any;
  index: number;
  isScrolling?: boolean;
  style: any;
};

/* When a user is dragging a file over a folder group we need to know the potential locations of the row borders */

export type DragHoveredStateType = 'Top' | 'Middle' | 'Bottom' | '';
