import { mergeWith } from 'lodash';

/* eslint-disable consistent-return */
function customizer(objValue: any, srcValue: any): any {
  /**
   * Default merge behavior is to deep-merge array elements.
   * Disable this and replace with existence comparison.
   */
  if (Array.isArray(objValue) || Array.isArray(srcValue)) {
    return srcValue !== undefined ? srcValue : objValue;
  }
}
/* eslint-enable */

function mergeEntities<
  T extends Record<string, any>,
  A extends Record<string, any>
>(object: T, ...sources: Array<A>): Record<string, any> {
  return mergeWith(object, ...sources, customizer);
}

export default mergeEntities;
