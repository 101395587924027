import * as React from 'react';
import { Form } from 'components';
import { LoanFundingType, ValidationMap } from 'types';

type Props = {
  data: LoanFundingType;
  disabled: boolean;
  updateLoanFunding: (property: string, value: string) => void;
  errors: ValidationMap;
};

const LoanFundingTypeName = 'LoanFunding';

function LoanFunding(props: Props) {
  if (!props.data) {
    return null;
  }

  const { data } = props;

  const fields = {
    sbaLoanNumber: {
      id: 'sbaLoanNumber',
      propertyName: 'sbaLoanNumber',
      fieldName: 'SBA Loan Number',
      onChange: props.updateLoanFunding,
      disabled: props.disabled,
      required: true,
      onChangeEventType: 'onBlur',
    },
    fundingDate: {
      id: 'fundingDate',
      propertyName: 'fundingDate',
      fieldName: 'Funding Date',
      onChange: props.updateLoanFunding,
      disabled: props.disabled,
      required: true,
      onChangeEventType: 'onBlur',
    },
    loanAmount: {
      id: 'loanAmount',
      propertyName: 'loanAmount',
      fieldName: 'Loan Amount',
      onChange: props.updateLoanFunding,
      disabled: props.disabled,
      required: true,
      onChangeEventType: 'onBlur',
    },
  };

  const autoSaveProps = { ...props, autoSave: true };
  return (
    <div>
      {Form.FieldRenderer(
        Form.Input,
        fields.sbaLoanNumber,
        autoSaveProps,
        LoanFundingTypeName,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Money,
        fields.loanAmount,
        autoSaveProps,
        LoanFundingTypeName,
        data.id,
      )}
      {Form.FieldRenderer(
        Form.Calendar,
        fields.fundingDate,
        autoSaveProps,
        LoanFundingTypeName,
        data.id,
      )}
    </div>
  );
}

export default LoanFunding;
