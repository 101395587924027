import React from 'react';
import { DealsTableColumnShape } from '../types';
import { DataTable } from 'components';
import { inferSortReverse } from 'utils';
import { SortBy, DealType } from 'types';

export interface DealsHeaderProps {
  columns: DealsTableColumnShape[];
  sortBy: SortBy;
  onChangeSortField: (sortBy: SortBy) => void;
  deals: DealType[];
}
const DealsHeader = (props: DealsHeaderProps) => {
  const { columns, sortBy, onChangeSortField, deals } = props;

  return (
    <DataTable.Header key="header">
      {columns.map(column => {
        const { id, header, CellType, headerProps } = column;
        let sort;
        if (column.id === sortBy.column) {
          sort = sortBy.reverse ? 'down' : 'up';
        }
        return (
          <DataTable.HeaderCell
            id={id}
            key={id}
            onSort={() =>
              onChangeSortField(
                inferSortReverse(deals, sortBy, {
                  column: column.id,
                  reverse: !sortBy.reverse,
                }),
              )
            }
            sort={sort}
            type={CellType}
            {...headerProps}
          >
            {header || ''}
          </DataTable.HeaderCell>
        );
      })}
    </DataTable.Header>
  );
};
export default DealsHeader;
