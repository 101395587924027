import { gql } from '@apollo/client';
import { DocumentNode } from '../types';
import { LoanTrancheBasicFields, ProspectShallowFields } from './generated';

const ProspectFields: DocumentNode = gql`
  fragment ProspectFields on ProspectType {
    ...ProspectShallowFields
    customTier {
      id
      tierNumber
    }
    tier {
      id
      tierNumber
    }
    loanTranche {
      ...LoanTrancheBasicFields
    }
  }
  ${ProspectShallowFields}
  ${LoanTrancheBasicFields}
`;

export default ProspectFields;
