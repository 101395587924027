import { OpenPDFProps } from 'types';

export const DEALPDF_CHANGE_PAGE = 'DEALPDF_CHANGE_PAGE';
export const DEALPDF_CHANGE_SCALE = 'DEALPDF_CHANGE_SCALE';
export const DEALPDF_CHANGE_ROTATION = 'DEALPDF_CHANGE_ROTATION';
export const DEALPDF_OPEN_FILE = 'DEALPDF_OPEN_FILE';
export const DEALPDF_CLOSE_FILE = 'DEALPDF_CLOSE_FILE';

export function openFile(data: OpenPDFProps) {
  return {
    type: DEALPDF_OPEN_FILE,
    payload: data,
  };
}

export function closeFile() {
  return {
    type: DEALPDF_CLOSE_FILE,
  };
}

export function changePage(data: { id: string; pageIndex: number }) {
  return {
    type: DEALPDF_CHANGE_PAGE,
    payload: data,
  };
}

export function changeScale(data: { id: string; scale: number }) {
  return {
    type: DEALPDF_CHANGE_SCALE,
    payload: data,
  };
}

export function changeRotations(data: {
  id: string;
  rotations: Map<number, number>;
}) {
  return {
    type: DEALPDF_CHANGE_ROTATION,
    payload: data,
  };
}
