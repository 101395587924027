import React, { useState, useEffect } from 'react';
import fetch from 'isomorphic-fetch';
import Cookies from 'js-cookie';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { detect } from 'detect-browser';
import { logToSentry } from 'utils';
import { toggleBatching } from 'lsgql/batchToggle';
import raiseAlert from 'lsredux/raiseAlert';
import { createAuthenticatedClient } from 'lsgql/authenticatedClient';

import { useAuth0 } from 'security';
import getPublicClient from 'lsgql/publicClient';

type Props = {
  children: React.ReactNode;
};

function raiseDebugAlert(msg: string, title?: string) {
  raiseAlert(msg, 'debug', title);
}

function ApplicationSetup(props: Props) {
  const { isAuthenticated, isInitializing, getTokenSilently } = useAuth0();
  const [authenticationToken, setAuthenticationToken] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [shouldBatch, setShouldBatch] = useState(null);

  useEffect(() => {
    const browser = detect();

    if (browser && browser.name === 'ie') {
      raiseAlert(
        'We support Chrome as our browser of choice. If you are having issues with the software, try downloading Chrome and resuming your work.',
        'info',
      );
    }
  }, []);

  useEffect(() => {
    async function getCsrfToken() {
      return fetch(
        process.env.REACT_APP_CSRF_URL || 'http://localhost:8001/csrf/',
        {
          mode: 'cors',
          credentials: 'include',
        },
      )
        .then(
          success => {
            setCsrfToken(Cookies.get('csrftoken'));
            return success.json();
          },
          failure => {
            /* eslint-disable no-console */
            console.error('Failed to set CSRF Token', failure);
            setHasError(true);
            setCsrfToken(Cookies.get(null));
            logToSentry(failure);
            return { enable_gql_batching: null };
          },
        )
        .then(res => {
          setCsrfToken(Cookies.get('csrftoken'));
          setShouldBatch(Boolean(res.enable_gql_batching));
        });
    }

    async function getToken() {
      await getCsrfToken();
    }

    getToken();
  }, []);

  useEffect(() => {
    if (isInitializing) return;

    async function getAuthToken() {
      const token = await getTokenSilently();
      setAuthenticationToken(token);
    }

    if (isAuthenticated) {
      getAuthToken();
    }
  }, [isInitializing, getTokenSilently, isAuthenticated]);

  useEffect(() => {
    toggleBatching(shouldBatch);
  }, [shouldBatch]);

  if (hasError) throw new Error('Could not fetch CSRF token');

  if (shouldBatch === null || shouldBatch === undefined) {
    return null;
  }
  if (!csrfToken) return null;
  if (isInitializing) return null;
  if (isAuthenticated && !authenticationToken) return null;

  const client: ApolloClient<any> = isAuthenticated
    ? createAuthenticatedClient(raiseDebugAlert, authenticationToken)
    : getPublicClient();

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}

export default ApplicationSetup;
