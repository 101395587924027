import { fromJS } from 'immutable';
import {
  SET_VIEW_RANGE,
  TOGGLE_WEEKENDS,
  SORT_BY,
  TOGGLE_FILTER_CATEGORY,
  RESET_FILTERS,
  TOGGLE_LIST_VIEW,
} from '../../actions/calendar';
import standardReducerFactory from '../standardReducer';

const initialState = fromJS({
  isListView: false,
  viewRange: 'week',
  showWeekends: true,
  sortBy: { column: 'date', reverse: false },
  categoryFilterIgnoreMap: {},
});

const calendarReducerMap: {
  [key: string]: (state: any, action: any) => Record<string, any>;
} = {};

calendarReducerMap[TOGGLE_LIST_VIEW] = state =>
  state.set('isListView', !state.get('isListView'));
calendarReducerMap[SET_VIEW_RANGE] = (state, action) =>
  state.set('viewRange', action.payload.viewRange);
calendarReducerMap[TOGGLE_WEEKENDS] = state =>
  state.set('showWeekends', !state.get('showWeekends'));
calendarReducerMap[SORT_BY] = (state, action) =>
  state.set('sortBy', fromJS(action.payload));
calendarReducerMap[TOGGLE_FILTER_CATEGORY] = (state, { payload }) =>
  [true, false].includes(state.getIn(['categoryFilterIgnoreMap', payload]))
    ? state.setIn(
        ['categoryFilterIgnoreMap', payload],
        !state.getIn(['categoryFilterIgnoreMap', payload]),
      )
    : state.setIn(['categoryFilterIgnoreMap', payload], true);
calendarReducerMap[RESET_FILTERS] = state =>
  state.set('categoryFilterIgnoreMap', fromJS({}));

export default standardReducerFactory(calendarReducerMap, initialState);
