import * as React from 'react';
import { compose } from 'recompose';
import OrderBookPropsBuilder from './OrderBookPropsBuilder';
import OrderBooksForm from './orderBooksForm/OrderBooksForm';
import { OrderBookContainerProps } from './types';
import { Composer } from 'utils';

import { RouteParams, withRouteParams } from 'routing';
import { withLoadingIndicator } from 'components';
import { DealType } from 'types';
import { DealQuery } from 'lsgql';

type Props = {
  deal: DealType;
};

function RenderOrderBooksForm(composedProps: Array<OrderBookContainerProps>) {
  return <OrderBooksForm orderBooks={composedProps} />;
}

class OrderBooks extends React.Component<Props> {
  render() {
    const { deal } = this.props;
    const { loantrancheSet = [] } = deal;
    // Build the complete set of props for each LoanTranche+OrderBook
    // then pass them as an array to the OrderBooksForm
    // Each element in the array is the full set of
    // props for one LoanTranche
    return (
      <Composer
        components={loantrancheSet.map(e => (
          <OrderBookPropsBuilder key={e.id} loanTrancheId={e.id} />
        ))}
      >
        {RenderOrderBooksForm}
      </Composer>
    );
  }
}

const OrderBooksContainer = compose(
  withRouteParams([RouteParams.dealId]),
  DealQuery,
  withLoadingIndicator('loading', 'deal'),
)(OrderBooks);

export default OrderBooksContainer;
