import * as React from 'react';
import { useQuery, FetchPolicy } from '@apollo/client';
import { DocumentNode, QueryResult, FetchMethod } from '../../types';
import { raiseAllErrorsOption } from './shared';
import { BaseType, ObjectBaseTypeNames } from 'types';
import { invariant, isWholeNumber } from 'utils';

type Args<RIn extends BaseType, TVariables, PIn, ROut extends BaseType> = {
  refetchVariables?: (props: PIn) => TVariables;
  results: (
    data: Partial<{
      [key: string]: RIn | null | undefined;
    }>,
  ) => ROut | null | undefined;
  skip?: (props: PIn) => boolean;
  typename: ObjectBaseTypeNames;
  variables?: (props: PIn) => TVariables;
  disableCache?: boolean;
};

/**
 * A Form query will raise all errors so that
 * validation errors can be parsed and displayed
 * @param {*} query
 * @param {*} optionsObj
 * @param {*} propsObj
 * @param {*} skip
 */
function formQueryBuilder<
  RIn extends BaseType, // Response Data Type
  TData extends {},
  TVariables,
  PIn,
  ROut extends BaseType
>(query: DocumentNode, args: Args<RIn, TVariables, PIn, ROut>) {
  invariant(query, 'GraphQL query must be provided');
  invariant(args, 'formQueryBuilder.args must be provided');
  invariant(args.results, 'Results method must be provided');
  invariant(args.typename, 'args.typename must be provided');

  const displayName = `${args.typename}FormQuery`;

  const fetchPolicy: FetchPolicy = args.disableCache
    ? 'network-only'
    : undefined;

  return function QueryBuilderHoC(
    Wrapped: React.ComponentType<PIn>,
  ): React.ComponentType<any> {
    invariant(Wrapped, 'A component must be provided');
    return function QueryBuilder(props: PIn) {
      let refetchMethod:
        | {
            [key: string]: FetchMethod<ROut>;
          }
        | null
        | undefined;

      function shouldSkip() {
        if (args.variables) {
          const vars: { id?: string } = args.variables(props);
          if (vars && vars.id) {
            if (!isWholeNumber(vars.id)) return true;
          }
        }

        return args.skip !== undefined ? args.skip(props) : false;
      }

      const skip = shouldSkip();
      const vars = skip || !args.variables ? undefined : args.variables(props);

      const { loading, error, data, client } = useQuery(query, {
        variables: vars,
        errorPolicy: raiseAllErrorsOption,
        skip: skip,
        displayName: displayName,
        fetchPolicy,
      });

      const preExistingEntity: ROut | null | undefined =
        data && args.results ? args.results(data) : null;

      const isLoading = (!skip && loading) || Boolean((props as any).loading);

      /* eslint-disable prefer-destructuring */
      // result returns loading === true if skip was true

      if (!refetchMethod) {
        refetchMethod = {
          formQueryRefetch: async ({ id }): Promise<any> => {
            /*
              ToDo:
                The refetch provided by the Query hoc does not work if
                the query was skipped.  This is breaking change from the
                previous major release.  As a temporary work around
                I'm invoking the client directly.
                 https://github.com/apollographql/react-apollo/issues/1909
              */
            const refetchVars: any = !args.refetchVariables
              ? { id }
              : args.refetchVariables(props);

            const refetchRes: QueryResult<RIn> = await client.query({
              query,
              variables: refetchVars,
              fetchPolicy: 'network-only',
              errorPolicy: 'ignore',
            });

            return {
              ...refetchRes,
              data:
                args.results && refetchRes && refetchRes.data
                  ? args.results(refetchRes.data)
                  : null,
            };
          },
        };
      }

      return (
        <Wrapped
          {...props}
          {...refetchMethod}
          error={error}
          loading={isLoading}
          preExistingEntity={preExistingEntity}
        />
      );
    };
  };
}

export default formQueryBuilder;
