import { MutationMethod } from '../types';
import { inputScrubber } from './utils';
import { mutationBuilder } from './builders';
import { MutationExecutor } from './builders/mutationBuilder';
import {
  CreateLoanFundingMutationDefinition,
  SetLoanFundingMutationDefinition,
} from './definitions';
import { LoanFundingInput, LoanFundingType } from 'types';

const setLoanFunding: MutationMethod<
  LoanFundingInput,
  LoanFundingType
> = mutationBuilder<LoanFundingInput, LoanFundingType>(
  SetLoanFundingMutationDefinition,
  {
    build: (mutate: MutationExecutor<LoanFundingType>) => (
      loanFundingData: LoanFundingInput,
    ) =>
      mutate({
        variables: {
          loanFundingData: inputScrubber(loanFundingData),
        },
      }),
  },
);

const createLoanFunding: MutationMethod<
  LoanFundingInput,
  LoanFundingType
> = mutationBuilder<LoanFundingInput, LoanFundingType>(
  CreateLoanFundingMutationDefinition,
  {
    build: (mutate: MutationExecutor<LoanFundingType>) => (
      loanFundingData: LoanFundingInput,
    ) =>
      mutate({
        variables: {
          loanFundingData: inputScrubber(loanFundingData),
        },
      }),
  },
);

const LoanFundingMutationMethods = {
  createLoanFunding,
  setLoanFunding,
};

export default LoanFundingMutationMethods;
