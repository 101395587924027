import { fromJS } from 'immutable';
import { ActionBaseType } from '../types';
import { ReduxKeys } from '../directory';
import { FormReduxState, FormReduxArgs } from './types';

// Support Methpds
import { entityValidator } from './methods/entityValidator';
import { flagImmutableDirty, flagDirty } from './methods/flagDirtyStatus';
import { getFormShape } from './methods/getFormShape';
import { createActionTypes } from './createActionTypes';

// Handlers
import { handleAddEntity } from './handlers/handleAddEntity';
import { handleAddEntities } from './handlers/handleAddEntities';
import { handleDeleteCollection } from './handlers/handleDeleteCollection';
import { handleClearState } from './handlers/handleClearState';
import { handleInitializeState } from './handlers/handleInitializeState';
import { handleMutateProperties } from './handlers/handleMutateProperties';
import { handleMutateProperty } from './handlers/handleMutateProperty';
import { handleMutationResponse } from './handlers/handleMutationResponse';
import { handleRemoveEntity } from './handlers/handleRemoveEntity';
import { handleReplaceEntity } from './handlers/handleReplaceEntity';
import { handleToggleDirtyFlag } from './handlers/handleToggleDirtyFlag';
import { handleSetValidationErrors } from './handlers/handleSetValidationErrors';
import { handleToggleSave } from './handlers/handleToggleSave';
import { handleUndo } from './handlers/handleUndo';
import { handleToggleAutoSave } from './handlers/handleToggleAutoSave';
import { invariant } from 'utils';
import { BaseType } from 'types';

/* eslint-disable import/prefer-default-export */

/*
    Possible Improvements:

      We don't necessarily require a state slice per formtype.  Currently
      we have something like:
      {
        deal: FormReduxState<DealInput>
        loanTranche: FormReduxState<LoanTrancheInput>
        ...
      }
      ...where all types are always in state

      This is leading to unnecessarily complex state tree. Instead
      we could have a single 'forms' slice, that subdivides currently visible
      forms into { id } slices.  So that when an action is dispatched the
      reducer will be responsbile for updating the correct FormReduxState<T> object

      {
        forms: {
                  uniqueIdA: FormReduxState<DealInput>
                  uniqueIdB: FormReduxState<LoanTrancheInput>
              }
      }
      ... or something similar.  MultiEntityReducerFactory already kind of
      does this.
  */
// TODO: These types are not correct

/**
 * Generate action types for a key.  This differentiates action handling
 * for different types. e.g. 'deal' vs 'loanTranche'
 * @param {*} key
 */

/**
 * Generate a Form Reducer using the specified key and initial entity object.
 * To create actions, this should be used in tandem with ../actions/genericFormActionsFactory
 *
 */
export function createFormReducer<TOut extends BaseType, TIn extends BaseType>(
  key: ReduxKeys,
  initialEntityState: TIn,
  args?: FormReduxArgs<TOut, TIn>,
) {
  invariant(key, 'key is required');
  invariant(initialEntityState, 'initialEntityState is required');
  entityValidator(initialEntityState);

  // convert initial entity into the FormReduxState
  const convertedEntity =
    args && args.dataConverter
      ? args.dataConverter(initialEntityState)
      : ((initialEntityState as any) as TOut);

  const initialFormState: FormReduxState<TOut> = getFormShape<TOut, TIn>(
    flagDirty(convertedEntity),
    args,
  );

  // ... then convert to immutable
  const initialState = fromJS(flagDirty(initialFormState));
  const initialEntity = fromJS(flagDirty(initialEntityState));

  // generate unique action types using the key
  const actionTypes = createActionTypes(key);

  const reducerMap: {
    [index: string]: (state: any, action: any) => Record<string, any>;
  } = {};

  reducerMap[actionTypes.toggleDirtyFlagActionType] = handleToggleDirtyFlag;
  reducerMap[actionTypes.toggleAutoSaveActionType] = handleToggleAutoSave;
  reducerMap[actionTypes.toggleSaveActionType] = handleToggleSave;
  reducerMap[actionTypes.addEntitiesActionType] = handleAddEntities;
  reducerMap[actionTypes.editActionType] = handleMutateProperty;
  reducerMap[actionTypes.replaceEntityActionType] = handleReplaceEntity;
  reducerMap[actionTypes.handleResponseActionType] = (
    state: any,
    action: any,
  ) => handleMutationResponse(state, action, args);
  reducerMap[actionTypes.mutatePropertiesActionType] = handleMutateProperties;
  reducerMap[actionTypes.addEntityActionType] = handleAddEntity;
  reducerMap[actionTypes.removeEntityActionType] = handleRemoveEntity;
  reducerMap[actionTypes.undoActionType] = handleUndo;
  reducerMap[actionTypes.setValidationErrors] = handleSetValidationErrors;
  reducerMap[actionTypes.initializeStateActionType] = (state, action) =>
    handleInitializeState(action, initialEntity, args);

  // eslint-disable-next-line
  reducerMap[actionTypes.clearStateActionType] = (state: any, action: any) =>
    handleClearState(action, initialEntity, args);
  reducerMap[actionTypes.deleteCollectionActionType] = handleDeleteCollection;

  const dirtyFlagExclusions = new Set([
    actionTypes.initializeStateActionType,
    actionTypes.handleResponseActionType,
    actionTypes.removeEntityActionType,
    actionTypes.deleteCollectionActionType,
    actionTypes.toggleSaveActionType,
    actionTypes.undoActionType,
    actionTypes.toggleDirtyFlagActionType,
    actionTypes.setValidationErrors,
  ]);

  return function reducer<A extends ActionBaseType>(
    state: any = initialState,
    action: A,
  ) {
    if (reducerMap[action.type]) {
      return dirtyFlagExclusions.has(action.type)
        ? reducerMap[action.type](state, action)
        : flagImmutableDirty(reducerMap[action.type](state, action));
    }
    return state;
  };
}
