import * as React from 'react';
import { OrganizationPermissions } from '../../../types';
import CompanyRow from './companyRow/CompanyRow';
import UserRow from './userRow/UserRow';
import { PermissionPerspective } from 'security';
import { lsSort } from 'utils';
import { DealInstitutionType, ID, DealUserType, SortBy } from 'types';
import '../Section.scss';

type Props = {
  currentUserId: ID;
  dealId: ID;
  editOrganizationRoute: (organizationId: ID) => string;
  isBorrower: boolean;
  onRemoveUser: (user: DealUserType) => void;
  organization?: DealInstitutionType;
  permissions: OrganizationPermissions;
  sortBy: SortBy;
  userSet: Array<any>;
};

function OrganizationSection(props: Props) {
  const {
    dealId,
    organization,
    sortBy,
    onRemoveUser,
    permissions,
    userSet = [],
    editOrganizationRoute,
    isBorrower,
    currentUserId,
  } = props;

  const sortedSet = lsSort(userSet, sortBy);

  return organization ? (
    <PermissionPerspective data={organization} disableLoader>
      <CompanyRow
        editOrganizationRoute={editOrganizationRoute}
        organization={organization}
        permissions={permissions}
      />
      {sortedSet.map((u: DealUserType, i: number) => (
        <UserRow
          currentUserId={currentUserId}
          dealId={dealId}
          index={i}
          isBorrower={isBorrower}
          key={u.id}
          onRemoveUser={onRemoveUser}
          permissions={permissions}
          user={u}
        />
      ))}
    </PermissionPerspective>
  ) : null;
}

export default OrganizationSection;
