import { compose } from 'recompose';
import ForgivenessTracker from './ForgivenessTracker';
import { RouteParams, withRouteParams } from 'routing';
import {
  SbaPppForgivenessWorkflowQuery,
  SbaPppForgivenessTrackerQuery,
} from 'lsgql';
import { withLoadingIndicator } from 'components';

export default compose(
  withRouteParams([RouteParams.dealId]),
  SbaPppForgivenessWorkflowQuery,
  SbaPppForgivenessTrackerQuery,
  withLoadingIndicator('loading'),
)(ForgivenessTracker);
