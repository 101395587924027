import React, { useEffect } from 'react';
import ProtectedTINField from './ProtectedTINField';
import { WrappedMediatorFormProps, Form } from 'components';
import { DealType, FormFieldsType } from 'types';
import { states } from 'resources';
import { isUseSbaBusinessLegalName } from 'utils';

type DealFormProps = WrappedMediatorFormProps<DealType>;

const width = '158px';
const maxWidth = '332px';

const fields: FormFieldsType = {
  businessLegalName: {
    id: 'businessLegalName',
    propertyName: 'businessLegalName',
    fieldName: 'Business Legal Name',
    width: maxWidth,
    required: true,
  },
  businessFirstName: {
    id: 'businessFirstName',
    propertyName: 'businessFirstName',
    fieldName: 'Business First Name',
    width,
    required: true,
  },
  businessLastName: {
    id: 'businessLastName',
    propertyName: 'businessLastName',
    fieldName: 'Business Last Name',
    width,
    required: true,
  },
  naicsCode: {
    id: 'naicsCode',
    propertyName: 'naicsCode',
    fieldName: 'NAICS Code',
    width,
    required: true,
  },
  addressOne: {
    id: 'addressOne',
    propertyName: 'addressOne',
    fieldName: 'Business Street Address (no P.O. Box Addresses allowed)',
    width: maxWidth,
    required: true,
  },
  addressTwo: {
    id: 'addressTwo',
    propertyName: 'addressTwo',
    fieldName: 'Business Address Line 2 (optional)',
    width: maxWidth,
  },
  city: {
    id: 'city',
    propertyName: 'city',
    fieldName: 'City',
    width,
    required: true,
  },
  state: {
    id: 'state',
    propertyName: 'state',
    fieldName: 'State',
    placeholder: '--',
    required: true,
    typeName: 'SbaPppInstitutionState',
    options: states.map(state => ({
      text: state.abbreviation,
      value: state.abbreviation,
    })),
  },
  zip: {
    id: 'zip',
    propertyName: 'zip',
    fieldName: 'Zip',
    width: '90px',
  },
  phone: {
    id: 'phone',
    propertyName: 'phone',
    fieldName: 'Business Phone',
    width,
    required: true,
  },
  dbaOrTradename: {
    id: 'dbaOrTradename',
    propertyName: 'dbaOrTradename',
    fieldName: 'DBA or Tradename (if applicable)',
    width: maxWidth,
  },
  businessTinType: {
    id: 'businessTinType',
    propertyName: 'businessTinType',
    fieldName: 'Business Taxpayer Identification Number (TIN)',
    width: maxWidth,
    required: true,
    options: [
      { label: 'EIN', id: 'EIN' },
      { label: 'SSN', id: 'SSN' },
    ],
  },
  businessTinEinSsn: {
    id: 'businessTinEinSsn',
    propertyName: 'businessTinEinSsn',
    fieldName: '',
    width,
    required: true,
    className: 'BusinessTinEinSsn',
  },
  primaryContact: {
    id: 'primaryContact',
    propertyName: 'primaryContact',
    fieldName: 'Primary Contact Name',
    width: maxWidth,
    required: true,
  },
  primaryContactFirstName: {
    id: 'primaryContactFirstName',
    propertyName: 'primaryContactFirstName',
    fieldName: 'Primary Contact First Name',
    width,
    required: true,
  },
  primaryContactLastName: {
    id: 'primaryContactLastName',
    propertyName: 'primaryContactLastName',
    fieldName: 'Primary Contact Last Name',
    width,
    required: true,
  },
  email: {
    id: 'email',
    propertyName: 'email',
    fieldName: 'Email Address',
    width: maxWidth,
    required: true,
  },
};

const SbaPppInstitution = 'SbaPppInstitution';

function BusinessInformationSection(props: DealFormProps) {
  const { data } = props;
  const { sbaPppLoanApplication } = data;
  const sbapppinstitution = sbaPppLoanApplication?.sbapppinstitution;

  const handleMutateProperty = (
    value:
      | (string | null | undefined)
      | (boolean | null | undefined)
      | (Array<any> | null | undefined),
    fieldId: string,
  ) => {
    const path = ['sbaPppLoanApplication', 'sbapppinstitution', fieldId];
    props.mutateProperty(value, path);
  };

  // SBA Hack for Round One applications: Pre-populate "NAICS Code" on load if applicable
  useEffect(() => {
    const additionalInfoNaicsCode =
      data?.additionalSbaPppBorrowerInformation?.naicsCode;
    if (!sbapppinstitution?.naicsCode && additionalInfoNaicsCode) {
      handleMutateProperty(additionalInfoNaicsCode, 'naicsCode');
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  if (!sbaPppLoanApplication || !sbapppinstitution) {
    return null;
  }

  const updatedProps = {
    ...props,
    data: { ...sbapppinstitution },
    mutateProperty: handleMutateProperty,
  };

  const useBusinessLegalName: boolean = isUseSbaBusinessLegalName(
    sbapppinstitution,
  );

  return (
    <div>
      {useBusinessLegalName ? (
        Form.FieldRenderer(
          Form.Input,
          { ...fields.businessLegalName, onChange: handleMutateProperty },
          updatedProps,
          SbaPppInstitution,
          sbapppinstitution.id,
        )
      ) : (
        <Form.Group>
          {Form.FieldRenderer(
            Form.Input,
            { ...fields.businessFirstName, onChange: handleMutateProperty },
            updatedProps,
            SbaPppInstitution,
            sbapppinstitution.id,
          )}
          {Form.FieldRenderer(
            Form.Input,
            { ...fields.businessLastName, onChange: handleMutateProperty },
            updatedProps,
            SbaPppInstitution,
            sbapppinstitution.id,
          )}
        </Form.Group>
      )}
      {Form.FieldRenderer(
        Form.Input,
        { ...fields.dbaOrTradename, onChange: handleMutateProperty },
        updatedProps,
        SbaPppInstitution,
        sbapppinstitution.id,
      )}
      {Form.FieldRenderer(
        Form.Numeric,
        { ...fields.naicsCode, onChange: handleMutateProperty },
        updatedProps,
        SbaPppInstitution,
        sbapppinstitution.id,
      )}
      {Form.FieldRenderer(
        Form.Input,
        { ...fields.addressOne, onChange: handleMutateProperty },
        updatedProps,
        SbaPppInstitution,
        sbapppinstitution.id,
      )}
      {Form.FieldRenderer(
        Form.Input,
        { ...fields.addressTwo, onChange: handleMutateProperty },
        updatedProps,
        SbaPppInstitution,
        sbapppinstitution.id,
      )}
      <Form.Group>
        {Form.FieldRenderer(
          Form.Input,
          { ...fields.city, onChange: handleMutateProperty },
          updatedProps,
          SbaPppInstitution,
          sbapppinstitution.id,
        )}
        {Form.FieldRenderer(
          Form.Select,
          { ...fields.state, onChange: handleMutateProperty },
          updatedProps,
          SbaPppInstitution,
          sbapppinstitution.id,
        )}
        {Form.FieldRenderer(
          Form.Zip,
          { ...fields.zip, onChange: handleMutateProperty },
          updatedProps,
          SbaPppInstitution,
          sbapppinstitution.id,
        )}
      </Form.Group>
      {Form.FieldRenderer(
        Form.Phone,
        { ...fields.phone, onChange: handleMutateProperty },
        updatedProps,
        SbaPppInstitution,
        sbapppinstitution.id,
      )}
      {Form.FieldRenderer(
        Form.Radio,
        { ...fields.businessTinType, onChange: handleMutateProperty },
        updatedProps,
        SbaPppInstitution,
        sbapppinstitution.id,
      )}
      {Form.FieldRenderer(
        ProtectedTINField,
        {
          ...fields.businessTinEinSsn,
          institution: sbapppinstitution,
          dealId: data.id,
          onChange: handleMutateProperty,
          formIsDirty: props.isDirty,
        },
        updatedProps,
        SbaPppInstitution,
        sbapppinstitution.id,
      )}
      {/* REMOVED IN ROUND TWO FORGIVENESS */}
      {/* {Form.FieldRenderer(
        Form.Input,
        { ...fields.primaryContact, onChange: handleMutateProperty },
        updatedProps,
        SbaPppInstitution,
        sbapppinstitution.id,
      )} */}
      <Form.Group>
        {Form.FieldRenderer(
          Form.Input,
          { ...fields.primaryContactFirstName, onChange: handleMutateProperty },
          updatedProps,
          SbaPppInstitution,
          sbapppinstitution.id,
        )}
        {Form.FieldRenderer(
          Form.Input,
          { ...fields.primaryContactLastName, onChange: handleMutateProperty },
          updatedProps,
          SbaPppInstitution,
          sbapppinstitution.id,
        )}
      </Form.Group>
      {Form.FieldRenderer(
        Form.Input,
        { ...fields.email, onChange: handleMutateProperty },
        updatedProps,
        SbaPppInstitution,
        sbapppinstitution.id,
      )}
    </div>
  );
}

export default BusinessInformationSection;
