import * as React from 'react';
import './Splash.scss';
import { isBorroweradmin } from 'utils/index';

class Splash extends React.Component<{}> {
  render() {
    let welcomeText = null;
    if (!isBorroweradmin()) {
      welcomeText = (
        <div className="invitationSplash__HeroText">
          <div>Welcome to</div>
          <div>LoanStreet.</div>
        </div>
      );
    }

    return (
      <div className="invitationSplash">
        <div className="invitationSplash__Gradient" />
        {welcomeText}
      </div>
    );
  }
}

export default Splash;
