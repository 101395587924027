import * as React from 'react';
import uuid from 'uuid/v4';
import { noop } from 'lodash';
import ActiveWindowObserver from '../../graphql/ActiveWindowObserver';
import { Modal } from 'components';

type Props = {};
type State = {
  isModalOpen: boolean;
};

const emptyHeader = <div />;
const mobileCopMessage = ` This website is not optimized for mobile browsers.
 For the best performance, access the site on a personal computer`;

class MobileCop extends React.Component<Props, State> {
  state = { isModalOpen: true };

  guid: string;

  constructor(props: Props) {
    super(props);
    this.guid = uuid();
  }

  componentDidMount() {
    this.register();
  }

  componentWillUnmount() {
    ActiveWindowObserver.Unregister(this.guid);
  }

  showModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  register = () => {
    ActiveWindowObserver.Register({
      id: this.guid,
      visible: this.showModal,
      hidden: noop,
    });
  };

  render() {
    const { isModalOpen } = this.state;
    return (
      <Modal.Compact
        header={emptyHeader}
        isOpen={isModalOpen}
        onConfirm={this.handleCloseModal}
      >
        {mobileCopMessage}
      </Modal.Compact>
    );
  }
}

export default MobileCop;
